unit Forms.CSR.Legalcase;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Legalcase, pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRLegalcase = class(TfrmLegalcase)
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRLegalcase: TfrmCSRLegalcase;

implementation

{$R *.dfm}

{ TfrmCSRLegalcase }

function TfrmCSRLegalcase.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRLegalcase.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRLegalcase.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRLegalcase.WebFormCreate(Sender: TObject);
begin
  Inherited;
  dcLegalCase.Columns[7].ButtonURL:='#/CSR/{{lecdossierfk}}/legalcase/edit/{{lecid}}';
  dcLegalCase.Columns[8].ButtonURL:='#/CSR/{{lecdossierfk}}/legalcase/edit/{{lecid}}';
end;

procedure TfrmCSRLegalcase.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcLegalcase.BeforeLoadDFMValues;
  try
    dcLegalcase.Columns.Clear;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecamount';
      Title := 'Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'leccause';
      Title := 'Cause';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecreference';
      Title := 'Reference';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecprimary';
      Title := 'Primary Party';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecopponent';
      Title := 'Opponent';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'leclawyer';
      Title := 'Lawyer';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'leciscomplete';
      Title := 'Is Completed';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'disabled';
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{lecdossierfk}}/legalcase/edit/{{lecid}}';
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{lecdossierfk}}/legalcase/delete/{{lecid}}';
    end;
    dcLegalcase.IsResponsive := True;
  finally
    dcLegalcase.AfterLoadDFMValues;
  end;
end;

end.