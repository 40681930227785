unit Forms.CSR.TrustAccount.Movements;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, Units.Types, pas2web.datatables, pas2web.dadatasethelper;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmTrustAccountMovements = class(TfrmBase)
    dcMovements: TP2WDatatable;
    dsMovements: TP2WDADataset;
    procedure dsMovementsAfterOpen(DataSet: TDataSet);
    procedure OnCheckAccountClicked(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FAccountID : NativeInt;

    FCheckBalanceJobID : NativeInt;
    FTimerId : NativeInt;

    procedure checkAccountBalance;
    procedure startCheckBalanceTimer;
    procedure DoCheckBalanceCheckJob;
    procedure StopCheckBalanceCheckJob;

    procedure RecoverCheckBalanceJob;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmTrustAccountMovements: TfrmTrustAccountMovements;

implementation

{$R *.dfm}

uses
  Modules.Server, libjquery, Units.Strings;

procedure TfrmTrustAccountMovements.WebFormDestroy(Sender: TObject);
begin
  StopCheckBalanceCheckJob;
  inherited;
end;

procedure TfrmTrustAccountMovements.checkAccountBalance;

  procedure OnResponse(anID : Int64; aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
      Server.ShowError(anError)
    else
    begin
      FCheckBalanceJobID := anID;
      Server.SetLocalData('CheckBalance_' + inttostr(FAccountID), InttoStr(FCheckBalanceJobID));
      Server.ShowOK(SStartedCheckBalanceEmpty);
      startCheckBalanceTimer;
    end;
  end;
begin
  if FCheckBalanceJobID > -1 then
    Server.ShowError(SCheckBalanceAlreadyStarted)
  else
  begin
    Server.checkAccountTransactions(FAccountID, @OnResponse);
  end;
end;

procedure TfrmTrustAccountMovements.DoCheckBalanceCheckJob;

  procedure OnResponse(Status : Int64; aSuccess: Boolean; anError: String);
  var
    aStatus : TJobQueueStatus;
  begin
    if not aSuccess then
    begin
      Server.ShowError(anError);
      StopCheckBalanceCheckJob;
    end
    else
    begin
       aStatus.AsInteger := Status;
       if Status >= Ord(jqsFinishOK) then
       begin
        FCheckBalanceJobID := -1;
        Server.DeleteLocalData('CheckBalance_' + inttostr(FAccountID));
        case aStatus of
          jqsFinishOK:
            begin
              Server.showOK(Format(SCheckBalanceMessageEmpty, [
                  aStatus.AsDisplayString
                ]));
              dsMovements.Close;
              dsMovements.Load([], nil);
            end;
          else
            Server.showError(Format(SCheckBalanceMessageEmpty, [
              aStatus.AsDisplayString
            ]));
        end;

        StopCheckBalanceCheckJob;
       end;
    end;
  end;

begin
  if FCheckBalanceJobID > -1 then
  begin
    Server.GetJobStatusEx(FCheckBalanceJobID, @OnResponse);
  end;
end;

function TfrmTrustAccountMovements.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmTrustAccountMovements.dsMovementsAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsMovements.GetNewRows(False);
  dcMovements.Data := aResult;
  dcMovements.RenderTranslated;
  if not DataSet.isEmpty then
  begin
    if DataSet.FieldByName('bampontoid').asString <> '' then
    begin
      jQuery('#btnCheck').prop('disabled', false);
      RecoverCheckBalanceJob;
    end;
  end;
end;

procedure TfrmTrustAccountMovements.OnCheckAccountClicked(Sender: TObject;
    Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  checkAccountBalance;
end;

procedure TfrmTrustAccountMovements.RecoverCheckBalanceJob;
begin
  FCheckBalanceJobID := StrToIntDef(
    Server.GetLocalData('CheckBalance_' + inttostr(FAccountID)), -1);
  if FCheckBalanceJobID > -1 then
     startCheckBalanceTimer;
end;

procedure TfrmTrustAccountMovements.setParams(const Params: TStrings);
begin
  inherited;
  If Assigned(Params) and (Params.indexOfName('ACCOUNTID')<>-1) then
  begin
    FAccountID := StrToIntDef(Params.Values['ACCOUNTID'],-1);

    jQuery('#returnBack').attr('href', '#/CSR/' + InttoStr(DossierID) + '/trustaccount/');
  end;
  dsMovements.ParamByName('DOSSIERID').asInteger := DossierID;
  dsMovements.ParamByName('BACID').asInteger := FAccountID;
  dsMovements.Load([], nil);
end;

procedure TfrmTrustAccountMovements.startCheckBalanceTimer;
begin
  FTimerId := window.setInterval(@DoCheckBalanceCheckJob, 1000);
end;

procedure TfrmTrustAccountMovements.StopCheckBalanceCheckJob;
begin
  if FTimerId > -1 then
    window.clearInterval(FTimerId);
  FTimerId := -1;
end;

procedure TfrmTrustAccountMovements.WebFormCreate(Sender: TObject);
begin
  inherited;
  FAccountID := -1;
  FCheckBalanceJobID := -1;
  FTimerId := -1;

  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsMovements.DAConnection := Server.CompanyConnection;
end;

procedure TfrmTrustAccountMovements.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dcMovements := TP2WDatatable.Create(Self);
  dsMovements := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dcMovements.BeforeLoadDFMValues;
  dsMovements.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnCheck';
      Name := 'btnCheck';
      SetEvent(Self, 'OnExecute', 'OnCheckAccountClicked');
    end;
    dcMovements.SetParentComponent(Self);
    dcMovements.Name := 'dcMovements';
    dcMovements.Columns.Clear;
    with dcMovements.Columns.Add do
    begin
      FieldName := 'bameffectiveon';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcMovements.Columns.Add do
    begin
      FieldName := 'bamamount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcMovements.Columns.Add do
    begin
      FieldName := 'bamotherdescr';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcMovements.Columns.Add do
    begin
      FieldName := 'bamotherbankaccount';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcMovements.Columns.Add do
    begin
      FieldName := 'bamid';
      RenderMode := rmButton;
      ButtonType := btCustom;
      ButtonIconClass := 'fas fa-info';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/{{bambankaccountfk}}/movement/{{bamid}}';
    end;
    dcMovements.DataSet := dsMovements;
    dcMovements.Language := lEnglish;
    dcMovements.IsResponsive := True;
    dcMovements.GridID := 'grdMovements';
    dcMovements.UseFieldIndex := True;
    dcMovements.ShowSearch := False;
    dcMovements.ShowNumberOfEntries := False;
    dcMovements.ShowEntriesInfo := False;
    dcMovements.Paginate := True;
    dcMovements.DisplayReadOnly := False;
    dcMovements.CalculateTableWidth := True;
    dcMovements.Left := 40;
    dcMovements.Top := 168;
    dsMovements.SetParentComponent(Self);
    dsMovements.Name := 'dsMovements';
    dsMovements.TableName := 'BankMovement';
    dsMovements.Params.Clear;
    with dsMovements.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsMovements.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BACID';
      ParamType := ptInput;
    end;
    dsMovements.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>bambankaccountfk</field><parameter type="LargeInt">BACID</parameter></binaryoperation></binaryoperation></where></query>';
    dsMovements.DAOptions := [doRefreshAllFields];
    dsMovements.AfterOpen := dsMovementsAfterOpen;
    dsMovements.Left := 40;
    dsMovements.Top := 88;
  finally
    alForm.AfterLoadDFMValues;
    dcMovements.AfterLoadDFMValues;
    dsMovements.AfterLoadDFMValues;
  end;
end;

end.