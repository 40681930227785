unit Module.Contacts;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Modules,
  pas2web.bootstrap,
  Modules.Server,
  System.Generics.Collections,
  Units.Types,
  WEBLib.Controls,
  WEBLib.Actions,
  Units.Params,
  pas2web.dadataset,
  Data.DB,
  Datasnap.DBClient,
  Units.DocumentBox,
  Module.Country,
  Types,
  Units.Contacts;

{ --
  Note on the workings of this datamodule:
 * The data module is responsible for showing the data in the (calling) application form
 * alForm is meant for elements (buttons, edits) on the *calling* form.
 It is filled with actionelements for every configured control.
 * All action elements that are part of the search modal dialog are in bmSearchContact.Actions, not in alForm !

 When editing a contact in the modal dialog, a second data module instance is created.
 The second data module is then responsible for showing the data in the modal edit dialog.
 The configuration is done in the ConfigureModalNNN methods.
 * If it is editing an existing record, it then passes its own datasets to the second datamodule.
 * If it is a new record, then nothing is passed, and the data is reloaded from server using the own datasets
 when the dialog is closed.
 This is done in order to avoid having to maintain 2 sets of field-edit controls.
 --
}

type
  TAllowedContactType = (actBoth, actPerson, actOrganisation);

  TdmContacts = class(TDataModule)
    bmSearchContact: TP2WBSModal;
    alForm: TElementActionList;
    dsContactId: TP2WDADataset;
    dsPhones: TP2WDADataset;
    dsWPAddress: TP2WDADataset;
    dsContactPic: TP2WDADataset;
    dsContact: TP2WDADataset;
    dsContactcntid: TLargeintField;
    dsContactcntcreatedon: TDateTimeField;
    dsContactcntcreatedbyfk: TLargeintField;
    dsContactcntchangedon: TDateTimeField;
    dsContactcntchangedbyfk: TLargeintField;
    dsContactcntcompanyfk: TLargeintField;
    dsContactcntfirstname: TStringField;
    dsContactcntlastname: TStringField;
    dsContactcntprofession: TStringField;
    dsContactcntbirthdateon: TDateTimeField;
    dsContactcntisbirthdateunknown: TBooleanField;
    dsContactcntgender: TStringField;
    dsContactcntcityofbirthfk: TLargeintField;
    dsContactcntcityofbirthname: TStringField;
    dsContactcntcityofbirthzip: TStringField;
    dsContactcntnationalityfk: TLargeintField;
    dsContactcntnationality2: TStringField;
    dsContactcntnationality: TStringField;
    dsContactcntpicturefk: TLargeintField;
    dsContactcntkbonr: TStringField;
    dsContactcntremark: TStringField;
    dsContactcntpersonfk: TLargeintField;
    dsContactcntsalutation: TStringField;
    dsContactcntsearchname: TStringField;
    dsContactcntprefixes: TStringField;
    dsContactcntfriendlytitle: TStringField;
    dsDossierPerson: TP2WDADataset;
    dsVPaddress: TP2WDADataset;
    bmEditContactData: TP2WBSModal;
    procedure WebDataModuleCreate(Sender: TObject);
    procedure WebDataModuleDestroy(Sender: TObject);
    procedure bmSearchContactHide(Sender: TObject; CloseEl: TJSHTMLElement; Values: TStrings);
    procedure DoSearchContactsClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoResultRowDoubleClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoResultRowSingleClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoShowContactDetails(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoContactSearchKeyUp(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoKeyUp(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    // Dataset events
    procedure dsContactIdAfterOpen(DataSet: TDataSet);
    procedure dsPhonesAfterOpen(DataSet: TDataSet);
    procedure dsDossierPersonAfterOpen(DataSet: TDataSet);
    procedure dsContactAfterOpen(DataSet: TDataSet);
    procedure dsContactPicAfterOpen(DataSet: TDataSet);
    procedure dsWPAddressAfterOpen(DataSet: TDataSet);
    procedure dsVPAddressAfterOpen(DataSet: TDataSet);
    // Dossier
    procedure dsVPAddressAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsContactIdAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsPhonesAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsDossierPersonAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsWPAddressAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsContactAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure DoSearchNewContact(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoSaveEditsClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoEditDialogHide(Sender: TObject; CloseEl: TJSHTMLElement; Values: TStrings);
    procedure OnSelectEntityType(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure bmEditContactDataShow(Sender: TObject);
  private
    FConfiguredParts: TContactDataParts;
    FShowParts: TContactDataParts;
    FEditParts: TContactDataParts;
    FPartsToLoad: TContactDataParts;
    FPartsToSave: TContactDataParts;
    FContactID: NativeInt;
    FDossierID: NativeInt;
    FPhotoID: NativeInt;
    FPhoto: TJSObject;
    FPhotoChanged: Boolean;
    FOnSearchResult: TSearchContactResultCallBack;
    FGender: TDictionary<string, string>;
    FPersonalFieldInfo: TPersonalFieldEdits;
    FWPAddressFieldInfo: TAddressFieldEdits;
    FVPAddressFieldInfo: TAddressFieldEdits;
    FDossierContactFieldInfo: TDossierContactFieldEdits;
    FTelecomFieldInfo: TTelecomFieldEdits;
    FContactIDFieldInfo: TContactIDFieldEdits;
    FDocumentBox: TDocumentBox;
    FOKClicked: Boolean;
    FCivilStatus: string;
    FMarCon: string;
    FSearchButtonID: string;
    FAvatarFileID: string;
    FDossierPersonType: string;
    FOnAllSaved: TNotifyEvent;
    FOnSaveError: TSaveErrorNotifyEvent;
    FContactDescription: string;
    FAvatarImageID: string;
    FOnSearchResultEx: TSearchContactResultCallExBack;
    FCountryData: TDMCountry;
    FIDRequired: Boolean;
    FOnAllLoaded: TNotifyEvent;
    FOnPartLoaded: TPartLoadNotifyEvent;
    FContactAddressID: NativeInt;
    FDisableEditingExisting: Boolean;
    FContactSource: TContactSource;
    FEditContactButtonID: string;
    FFormIsReadOnly: Boolean;
    FInEditDialog: Boolean;
    FOnCancelEdit: TNotifyEvent;
    FEntityType: TEntityType;
    FDossierConnection: TP2WDAConnection;
    FPredefinedNIDNR: string;
    FAllowedContactType: TAllowedContactType;
    FContactChanged: TNotifyEvent;
    procedure SetSearchButtonID(const Value: string);
    procedure SetEditContactButtonID(const Value: string);
    procedure SetPersonalFieldInfo(const Value: TPersonalFieldEdits);
    procedure SetWPAddressFieldInfo(const Value: TAddressFieldEdits);
    procedure SetVPAddressFieldInfo(const Value: TAddressFieldEdits);
    procedure SetTelecomFieldInfo(const Value: TTelecomFieldEdits);
    procedure SetContactIDFieldInfo(const Value: TContactIDFieldEdits);
    procedure SetDossierContactFieldInfo(const Value: TDossierContactFieldEdits);
    procedure SetDocumentBox(const Value: TDocumentBox);
    function GetPartPrefix(aPart: TContactDataPart): string;
    procedure SetDossierID(const Value: NativeInt);
    procedure bindZipLookup(const zipElmId, cityElmId: string);
    procedure setCityName(const zipElmId, cityElmId: string);
    procedure OnGetCountryData(DataSet: TDataSet);
    function InvalidAddressFields(aEdits: TAddressFieldEdits; ShowErrors: Boolean): TAddressFields;
    function InvalidPersonalFields(ShowErrors: Boolean): TPersonalFields;
    function InvalidDossierContactFields(ShowErrors: Boolean): TDossierContactFields;
    function InvalidIDFields(ShowErrors: Boolean): TContactIDFields;
    function InvalidTelecomFields(ShowErrors: Boolean): TAddressFields;
    procedure FillParamCombobox(const aElName, aParamType: string);
    procedure FillRadioListBlock(const aElName, aGroupName, aParamType: string; aSelected: string = ''; aDefault: string = ''; aLimit: array of string = []);
    procedure LinkRadioListBlock(const aElName, aGroupName, aParamType: string);
    procedure LoadContactDetailData(aContactID: NativeInt; Force: Boolean = True; ForceWPAddress: Boolean = False);
    procedure DoDataLoadFail(DataSet: TDataSet; ID: Integer; const ErrorMsg: string);
    procedure SetupDataset(aDataset: TDataSet);
    procedure CheckEnabledParts;
    procedure DisenablePart(aPart: TContactDataPart; Disable: Boolean);
    procedure DisEnableAddressFields(Disable: Boolean; aEdits: TAddressFieldEdits);
    procedure DisEnableContactIDFields(Disable: Boolean);
    procedure DisEnableDossierPersonFields(Disable: Boolean);
    procedure DisEnablePersonalFields(Disable: Boolean);
    procedure DisEnableTelecomFields(Disable: Boolean);
    procedure DisEnablePictureFields(Disable: Boolean);
    procedure DoEditContact(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure SetFormIsReadOnly(const Value: Boolean);
    procedure CheckSearchButton;
    // When simply editing
    procedure ConfigureModalEdits;
    procedure ConfigureModalContactFields;
    procedure ConfigureModalIDFields;
    procedure ConfigureModalTelecomFields;
    procedure ConfigureModalWPAddressFields;
    procedure ShowEditDialog(aContactID: NativeInt);
    procedure ReloadData(aContactID: NativeInt);
    procedure ConfigEntityType(IsNew: Boolean);
    procedure DoCheckSearchName(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure SetDossierConnection(const Value: TP2WDAConnection);
    procedure SetDossierDatasetConnections;

    procedure SetParts(const aShowParts, aEditParts: TContactDataParts);
  protected
    // Auxiliary methods
    procedure DoClearError(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    function FieldIsEmpty(aField: TPersonalField; aShowError: Boolean): Boolean; overload;
    function FieldIsEmpty(aField: TContactIDField; aShowError: Boolean): Boolean; overload;
    function FieldIsEmpty(aField: TDossierContactField; aShowError: Boolean): Boolean; overload;
    function FieldIsEmpty(aField: TTelecomField; aShowError: Boolean): Boolean; overload;
    function FieldIsEmpty(aEdits: TAddressFieldEdits; aField: TAddressField; aShowError: Boolean): Boolean; overload;
    function FieldIsEmpty(aID: string; aShowError: Boolean; IsRadio: Boolean = False): Boolean; overload;
    function Locate(DataSet: TP2WDADataset; fieldName: string; Value: Int64): Boolean; overload;
    function Locate(DataSet: TP2WDADataset; fieldName: string; Value: string): Boolean; overload;

    // Search contact functionality
    procedure DoContactSelected;
    procedure SetElementEnabled(const elementId: string; isEnabled: Boolean; ByName: Boolean = False);
    procedure SetElementVisible(const elementId: string; isVisible: Boolean);
    procedure FinishSearchResult;
    procedure NoContactFound;
    procedure ShowSelectContactDlg(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure onGetGenderItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string; ValueType: Integer; Extra: string; isLast: Boolean);
    procedure getSearchResultItem(contactData: TContactSearchResult; ContactTelecomItems: TContactTelecomItems; ContactAddressItems: TContactAddressItems; isLast: Boolean);
    procedure DoSearch;
    // Loading data
    procedure LoadContactPicture(aContactID: NativeInt; Force: Boolean = True);
    procedure LoadContactID(aContactID: NativeInt; Force: Boolean = True);
    procedure LoadContactTelecom(aContactID: NativeInt; Force: Boolean = True);
    procedure LoadContactDetail(aContactID: NativeInt; Force: Boolean = True);
    procedure LoadDossierPerson(aDossierID: NativeInt; aType: string; Force: Boolean = False); overload;
    procedure LoadDossierPerson(aDossierID, aContactID: NativeInt; Force: Boolean = False); overload;
    procedure LoadContactVPAddress(aContactID: NativeInt; Force: Boolean = True);
    procedure LoadContactWPAddress(aContactID: NativeInt; Force: Boolean = True);
    procedure PartLoaded(aPart: TContactDataPart; PartIsEmpty: Boolean);
    procedure DoAllLoaded;
    // Showing loaded data
    procedure DefaultShowField(aAction: TElementAction; Fld: TField);
    procedure ShowContactIDFields(aDataset: TDataSet);
    procedure ShowAddressFields(aDataset: TDataSet; aEdits: TAddressFieldEdits);
    procedure ShowContactFields(aDataset: TDataSet);
    procedure ShowDossierPersonFields(aDataset: TDataSet);
    // Clear data
    procedure ClearContactFields;
    procedure ClearContactIDFields;
    procedure ClearAddressFields(aEdits: TAddressFieldEdits);
    procedure ClearDossierPersonFields;
    procedure ClearTelecomFields;
    procedure ClearPictureFields;
    procedure ClearParts(aParts: TContactDataParts);
    // Check data empty
    function IsContactIDEmpty: Boolean;
    function IsPersonalEmpty: Boolean;
    function IsAddressEmpty(aEdits: TAddressFieldEdits): Boolean;
    function IsTelecomEmpty: Boolean;
    function IsDossierPersonEmpty: Boolean;

    // Set field info
    procedure SetupAddressFields(aEdit: TAddressFieldEdits);
    procedure SetupPersonalFields;
    procedure SetupTelecomFields;
    procedure SetupIDFields;
    procedure SetupDossierContactFields;
    // Save loop
    procedure PartSavedEnd(aPart: TContactDataPart; Info: TResolveResults); overload;
    procedure PartSavedEnd(aPart: TContactDataPart); overload;
    procedure CheckNextPartSave; overload;
    procedure DoAllSaved;
    procedure DoSaveError(aPart: TContactDataPart);
    // Actually save things
    procedure SaveDossierPerson;
    procedure SaveContact;
    procedure SaveResidentialAddress;
    procedure SaveOfficialAddress;
    procedure SaveAddressData(aEdits: TAddressFieldEdits; aDataset: TDataSet; aType: string; aPart: TContactDataPart);
    procedure SavePhoto;
    procedure SaveTelecom;
    procedure SaveCardInfo;
    // Save form data to dataset fields
    procedure DefaultFieldCopy(F: TField; aAction: TElementAction);
    procedure SaveContactIDFields(aDataset: TDataSet);
    procedure SaveContactFields(aDataset: TDataSet);
    procedure SaveAddressFields(aDataset: TDataSet; aType: string; aEdits: TAddressFieldEdits);
    procedure SaveDossierContactFields(aDataset: TDataSet);
    procedure SaveTelecomFields(aDataset: TDataSet; aEdit, aType: string);
    procedure OnContactPhotoChanged;
    procedure ShowContact(const SelectedContact: TContactSearchResult);
    procedure CheckNewContactButton;
  public
    { Public declarations }
    procedure SearchContact;
    // Call this to load all data parts. If aParts contains a not yet configured part, an exception is raised.
    procedure LoadContactData(aContactID: NativeInt; aParts: TContactDataParts); overload;
    procedure LoadContactData(aContactID: NativeInt; aShowParts, aEditParts: TContactDataParts); overload;
    procedure LoadDossierPersonData(aDossierID: NativeInt; aType: string; aParts: TContactDataParts); overload;
    procedure LoadDossierPersonData(aDossierID: NativeInt; aType: string; aShowParts, aEditParts: TContactDataParts); overload;
    procedure LoadDossierPersonData(aDossierID: NativeInt; aContactID: NativeInt; aParts: TContactDataParts); overload;
    procedure LoadDossierPersonData(aDossierID: NativeInt; aContactID: NativeInt; aShowParts, aEditParts: TContactDataParts); overload;
    // Show the contact data edit dialog. Data is taken from the current dataset.
    procedure EditContact(aContactID: NativeInt);
    // Check all fields for configured parts
    function ContactFieldsValid(ShowErrors: Boolean): Boolean;
    // Save data;
    procedure SaveContactData;
    // Calls ContactFieldsValid(True), if true calls SaveContactData
    procedure SaveIfValid;
    // Clear contact data
    procedure ClearContactData;
    // Prepare to enter new contact with existing parts.
    procedure StartNewContact; overload;
    // Prepare to enter new contact with new parts.
    procedure StartNewContact(const aShowParts, aEditParts: TContactDataParts); overload;
    // Get data
    function GetFieldValue(aField: TPersonalField): string;
    // Call this to set up handling avatar file saving.
    procedure SetUpPhoto(const aAvatarEditID, aAvatarImageID, aEvent: string; aDataset: TP2WDADataset = nil);
    // Delete dossierperson, if any.
    procedure DeleteDossierPerson(ClearContact: Boolean);
    // Check if a part is completely empty.
    function IsPartEmpty(aPart: TContactDataPart): Boolean;
    // Set up dossier ID.
    property DossierID: NativeInt read FDossierID write SetDossierID;
    // Set this to handle the 'search contact' button.
    property SearchButtonID: string read FSearchButtonID write SetSearchButtonID;
    // Set this to handle the 'edit contact' button.
    property EditContactButtonID: string read FEditContactButtonID write SetEditContactButtonID;
    // What kind of dossier person are we handling ?
    property DossierPersonType: string read FDossierPersonType write FDossierPersonType;
    // These various parts must be configured, the IDs of the edits in the form must be specified.
    property PersonalFieldInfo: TPersonalFieldEdits read FPersonalFieldInfo write SetPersonalFieldInfo;
    property WPAddressFieldInfo: TAddressFieldEdits read FWPAddressFieldInfo write SetWPAddressFieldInfo;
    property VPAddressFieldInfo: TAddressFieldEdits read FVPAddressFieldInfo write SetVPAddressFieldInfo;
    property TelecomFieldInfo: TTelecomFieldEdits read FTelecomFieldInfo write SetTelecomFieldInfo;
    property ContactIDFieldInfo: TContactIDFieldEdits read FContactIDFieldInfo write SetContactIDFieldInfo;
    property DossierContactFieldInfo: TDossierContactFieldEdits read FDossierContactFieldInfo write SetDossierContactFieldInfo;
    // Set to true if entering an ID nr is required or optional.
    property IDRequired: Boolean read FIDRequired write FIDRequired;
    // To handle saving picture
    property PictureDocumentBox: TDocumentBox read FDocumentBox write SetDocumentBox;
    // Read-only for information
    property ContactSource: TContactSource read FContactSource;
    property CivilStatus: string read FCivilStatus;
    property MarCon: string read FMarCon;
    property AvatarFileID: string read FAvatarFileID;
    property AvatarImageID: string read FAvatarImageID;
    property ContactID: NativeInt read FContactID;
    property ContactAddressID: NativeInt read FContactAddressID;
    property EntityType: TEntityType read FEntityType;
    property InEditDialog: Boolean read FInEditDialog;
    property ShowParts: TContactDataParts read FShowParts;
    property EditParts: TContactDataParts read FEditParts;
    // Connection to use as Petition/CSR connection.
    property DossierConnection: TP2WDAConnection read FDossierConnection write SetDossierConnection;
    // Disable editing of existing contact.
    property DisableEditingExisting: Boolean read FDisableEditingExisting write FDisableEditingExisting;
    // Form is readonly: Uses in checks for buttons.
    property FormIsReadOnly: Boolean read FFormIsReadOnly write SetFormIsReadOnly;
    // Contact search callback
    property OnSearchResult: TSearchContactResultCallBack read FOnSearchResult write FOnSearchResult;
    property OnSearchResultEx: TSearchContactResultCallExBack read FOnSearchResultEx write FOnSearchResultEx;
    // Called when all is saved.
    property OnAllSaved: TNotifyEvent read FOnAllSaved write FOnAllSaved;
    // Called when a part is loaded and shown in the UI
    property OnPartLoaded: TPartLoadNotifyEvent read FOnPartLoaded write FOnPartLoaded;
    // Called when all requested parts are loaded.
    property OnAllLoaded: TNotifyEvent read FOnAllLoaded write FOnAllLoaded;
    // Called when edit dialog is cancelled.
    property OnCancelEdit: TNotifyEvent read FOnCancelEdit write FOnCancelEdit;
    // Called when an error occurs during saving. An error message has been shown already.
    property OnSaveError: TSaveErrorNotifyEvent read FOnSaveError write FOnSaveError;
    property ContactDescription: string read FContactDescription write FContactDescription;
    property AllowedContactType: TAllowedContactType read FAllowedContactType write FAllowedContactType;
    property PredefinedNIDNR: string write FPredefinedNIDNR;
    property OnContactChanged: TNotifyEvent read FContactChanged write FContactChanged;
  protected procedure LoadDFMValues; override; end;

  TDataSaveSuccess = Reference to procedure(const RecordID: Int64);
  TDataSaveFail = Reference to procedure(const error: string);

var
  dmContact2: TdmContacts;

implementation

uses
  StrUtils,
  Units.Logging,
  TypInfo,
  Units.Strings,
  pas2web.dadatasetHelper,
  Units.PageHandler,
  libjquery,
  lib.bootstrap,
  Units.ActionUtils,
  lib.formtranslation,
  Units.HTMLUtils;

const
  btnSearchContact = 'btnSearchContact';
  btnSelectContact = 'btnSelectContact';
  edtSearchContact = 'edtSearchContact';
  mdlSearchContact = 'mdlSearchContact';
  divInitialContactScreen = 'initialContactScreen';
  divEmptyContactSearchResult = 'emptyContactSearchResult';
  divSearchContactResults = 'searchContactResults';
  btnShowSearchContact = 'btnShowSearchContact';
  divSearchContactResultData = 'searchContactResultData';
  lblSearchContactTerm = 'lblSearchContactTerm';
  tbodysearchContactResultData = 'searchContactResultData';
  btnEditContact = 'btnEditContact';

  divEntityChooser = 'cntEntityChooser';
  divEntityPerson = 'cntEntityPerson';
  divEntityCompany = 'cntEntityCompany';
  divModalTitle = 'lblEditContactTitle';

  {
const
   pfLastName,pfFirstName,pfDateOfBirth,pfBirthCityZip,pfBirthCityName,
   pfGender,pfNationality,pfKBO,pfProfession,pfSalutation,pfSearchname,
   pfPrefixes,pfFriendlytitle,pfPicturefk
  }

{%CLASSGROUP 'Vcl.Controls.TControl'}
{$R *.dfm}
  { TdmContact }

procedure TdmContacts.bmEditContactDataShow(Sender: TObject);
begin
  // mask is blijkbeer nog niet toegevoegd aan de libjquery
  asm
    $('.nationalNumber').mask("000000-000.00", { placeholder: "______-___.__" });
    $('.zip').mask("00000", { placeholder: "_____" });
    $('.cardNo').mask("000-0000000-00", {placeholder: "___-_______-__"});
  end;
end;

procedure TdmContacts.bmSearchContactHide(Sender: TObject; CloseEl: TJSHTMLElement; Values: TStrings);
begin
  if Assigned(CloseEl) and (CloseEl.ID = btnSelectContact) then
    DoContactSelected;
end;

procedure TdmContacts.setCityName(const zipElmId, cityElmId: string);

  procedure OnGetCityResult(aResult: string; anID: Int64);
  begin
    alForm[cityElmId].Value := aResult;
    jQuery('#' + cityElmId).attr('data-cid', intToStr(anID));
    if Trim(alForm[cityElmId].Value) <> '' then
      jQuery('#' + cityElmId).parent().find('.invalid-feedback').css('display', 'none');
  end;

var
  zip: string;
begin
  zip := Trim(alForm[zipElmId].Value);
  if (zip = '0') or (zip = '') then
    jQuery('#' + cityElmId).parent().find('.invalid-feedback').css('display', 'none')
  else
    if Server.IsZipCodeValid(zip) then
      Server.getCityFromZip(zip, @OnGetCityResult);
end;

procedure TdmContacts.bindZipLookup(const zipElmId, cityElmId: string);
var
  el: TJSElement;

  procedure onKeyUp;
  begin
    setCityName(zipElmId, cityElmId);
  end;

begin
  Log.Log(ltDebug, ClassName, 'BindZipLookup', '(%s,%s)', [zipElmId, cityElmId]);
  el := document.getElementById(zipElmId);
  el.addEventListener('keyup', @onKeyUp);
  document.getElementById(cityElmId).setAttribute('data-cid', '0');
  Log.Log(ltDebug, ClassName, 'BindZipLookup done', '(%s,%s)', [zipElmId, cityElmId]);
end;

procedure TdmContacts.DoContactSelected;
var
  selectedRow, profession: string;
  aSearchName, bcId, bcName, bcZip: string;
  Contact: TContactSearchResult;
  AllowSelect: Boolean;
  qRow: TJQuery;
begin
  Contact := default (TContactSearchResult);
  qRow := jQuery('#searchContactResults tr[role="row"].table-primary');
  profession := {jQuery('#searchContactResults tr[role="row"].table-primary')}qRow.attr('data-profession');
  bcId := {jQuery('#searchContactResults tr[role="row"].table-primary')}qRow.attr('data-bcId');
  bcName := {jQuery('#searchContactResults tr[role="row"].table-primary')}qRow.attr('data-bcName');
  bcZip := {jQuery('#searchContactResults tr[role="row"].table-primary')}qRow.attr('data-bcZip');
  aSearchName := {jQuery('#searchContactResults tr[role="row"].table-primary')}qRow.attr('data-searchName');
  selectedRow := qRow.attr('id');
  if selectedRow = 'undefined' then
    exit;
  Contact.RecordID := StrToInt(selectedRow);
  Contact.FirstName := jQuery('#' + selectedRow).find('td:nth-child(1)').text();
  Contact.Lastname := jQuery('#' + selectedRow).find('td:nth-child(2)').text();

  Contact.profession := profession;

  Contact.BirthDateOn := TJSDate.new(jQuery('#' + selectedRow).find('td:nth-child(4)').attr('data-date'));
  Contact.IsBirthDateUnknown := False;

  Contact.Gender := jQuery('#' + selectedRow).find('td:nth-child(3)').attr('data-gender');

  Contact.CityOfBirthID := StrToInt(bcId);
  Contact.CityOfBirthZIP := bcZip;
  Contact.CityOfBirthName := bcName;

  Contact.NationalityID := StrToInt(jQuery('#' + selectedRow).find('td:nth-child(5)').attr('data-natId'));
  Contact.Nationality2 := jQuery('#' + selectedRow).find('td:nth-child(5)').text();
  Contact.Nationality := jQuery('#' + selectedRow).find('td:nth-child(5)').attr('data-natName');

  Contact.KBONR := jQuery('#' + selectedRow).find('td:nth-child(6)').text();
  Contact.Remark := '';
  Contact.Salutation := '';
  Contact.SearchName := aSearchName;
  Contact.Prefixes := '';
  Contact.FriendlyTitle := '';
  AllowSelect := True;

  if Assigned(OnSearchResult) then
    OnSearchResult(Contact);
  if Assigned(OnSearchResultEx) then
    OnSearchResultEx(Contact, AllowSelect);

  if AllowSelect and (([cdpPersonal, cdpDossierPerson] * ShowParts) <> []) then
    ShowContact(Contact);
end;

procedure TdmContacts.ShowContact(const SelectedContact: TContactSearchResult);

  function ShowField(aField: TPersonalField; aValue: string): Boolean;
  begin
    Result := aField in FPersonalFieldInfo.Fields;
    if Result then
      alForm[FPersonalFieldInfo.Names[aField]].Value := aValue;
  end;

begin
  ClearContactData;
  ShowField(pfLastName, SelectedContact.Lastname);
  ShowField(pfFirstName, SelectedContact.FirstName);
  ShowField(pfDateOfBirth, FormatHTMLDate(JSDateToDateTime(SelectedContact.BirthDateOn)));
  ShowField(pfBirthCityZip, SelectedContact.CityOfBirthZIP);
  if ShowField(pfBirthCityName, SelectedContact.CityOfBirthName) then
    jQuery('#' + FPersonalFieldInfo.Names[pfBirthCityName]).attr('data-cid', intToStr(SelectedContact.CityOfBirthID));
  ShowField(pfProfession, SelectedContact.profession);
  ShowField(pfNationality, SelectedContact.Nationality2);
  ShowField(pfSearchName, SelectedContact.SearchName);
  if pfGender in FPersonalFieldInfo.Fields then
    setRadiogroupSelectedElement(FPersonalFieldInfo.Names[pfGender], SelectedContact.Gender);

  if (FContactID <> SelectedContact.RecordID) then
  begin
    FContactID := SelectedContact.RecordID;
    if Assigned(FContactChanged) then
      FContactChanged(Self);
  end;

  CheckNewContactButton;
  LoadContactDetailData(FContactID, True, True);
  PartLoaded(cdpPersonal, False);
end;

procedure TdmContacts.DoKeyUp(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if TJSKeyboardEvent(Event.JSEvent).key = 'Escape' then
    bmSearchContact.Hide;
end;

procedure TdmContacts.DoShowContactDetails(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  jQuery('tr[id^="details-"]').css('display', 'none');
  jQuery('tr[id^="details-"]').removeClass('table-primary');
  jQuery(jQuery(Event.JSEvent.currenttarget).attr('data-open')).css('display', '');
  jQuery(jQuery(Event.JSEvent.currenttarget).attr('data-open')).closest('tr').addClass('table-primary');
end;

procedure TdmContacts.getSearchResultItem(contactData: TContactSearchResult; ContactTelecomItems: TContactTelecomItems; ContactAddressItems: TContactAddressItems; isLast: Boolean);
var
  trGender, secret, correspondence, officeAddress, html, aDate: string;
  DT: TDatetime;
  i: Integer;
begin
  if not FGender.TryGetValue(contactData.Gender, trGender) then
    trGender := contactData.Gender;

  officeAddress := '';
  for i := low(ContactAddressItems) to high(ContactAddressItems) do
  begin
    if ContactAddressItems[i].AddressType = 'WP' then
      officeAddress := ContactAddressItems[i].Street + ' ' + ContactAddressItems[i].StreetNr + ', ' + ContactAddressItems[i].CityName + ' ' + ContactAddressItems[i].CityZIP;
  end;
  DT := JSDateToDateTime(contactData.BirthDateOn);
  if DT < 100 then
    aDate := ''
  else
    aDate := FormatDateTime('dd"/"mm"/"yyyy', DT);
  html := '<tr role="row" id="' + intToStr(contactData.RecordID) + '" data-profession="' + contactData.profession + '" data-bcId="' + intToStr(contactData.CityOfBirthID) + '" ' + 'data-bcName="' +
    contactData.CityOfBirthName + '" data-bcZip="' + contactData.CityOfBirthZIP + '" data-searchName="' + contactData.SearchName + '"><td>' + contactData.FirstName + '</td><td>' + contactData.Lastname
    + '</td><td data-gender="' + contactData.Gender + '">' + trGender + '</td>' + '<td data-date="' + contactData.BirthDateOn.toString + '">' + aDate + '</td><td data-natId="' +
    intToStr(contactData.NationalityID) + '" data-natName="' + contactData.Nationality + '">' + contactData.Nationality2 + '</td><td>' + contactData.KBONR + '</td><td>' + officeAddress + '</td><td>';
  if (Length(ContactAddressItems) > 0) or (Length(ContactTelecomItems) > 0) then
    html := html + '<a role="contactAddress" ' + 'class="btn btn-sm btn-icon btn-secondary" data-open="#details-' + intToStr(contactData.RecordID) + '"><i class="fas fa-address-book"></i></a>';
  html := html + '</td></tr>';

  html := html + '<tr style="display:none" id="details-' + intToStr(contactData.RecordID) + '"><td colspan="13">';
  if Length(ContactAddressItems) > 0 then
  begin
    html := html + '<div class="card w-100"><h6 class="card-header" data-translate="npeAddress">Address</h6>' + '<div class="card-body"><table class="table table-hover w-100">' + '<thead><tr>' +
      '<th data-translate="npeAddressType">Address Type</th>' + '<th data-translate="npeStreet">Street</th>' + '<th data-translate="npeHouseNo">House No</th>' +
      '<th data-translate="npeCity">City</th>' + '<th data-translate="npeCityZip">City Zip</th>' + '<th data-translate="npeRemark">Remark</th>' + '<th data-translate="npeSecret">Secret</th>' +
      '<th data-translate="npeCorrespondence">Correspondence</th></tr></thead><tbody>';
    for i := low(ContactAddressItems) to high(ContactAddressItems) do
    begin
      secret := 'No';
      if ContactAddressItems[i].secret then
        secret := 'Yes';
      correspondence := 'No';
      if ContactAddressItems[i].correspondence then
        correspondence := 'Yes';

      html := html + '<tr><td>' + ContactAddressItems[i].AddressType + '</td>' + '<td>' + ContactAddressItems[i].Street + '</td>' + '<td>' + ContactAddressItems[i].StreetNr + '</td>' + '<td>' +
        ContactAddressItems[i].CityName + '</td>' + '<td>' + ContactAddressItems[i].CityZIP + '</td>' + '<td>' + ContactAddressItems[i].Remark + '</td>' + '<td>' + secret + '</td>' + '<td>' +
        correspondence + '</td></tr>';
    end;
    html := html + '</tbody></table></div></div>';
  end;

  if Length(ContactTelecomItems) > 0 then
  begin
    html := html + '<div class="card w-100"><h6 class="card-header" data-translate="npeContacts">Contacts</h6>' + '<div class="card-body"><table class="table table-hover w-100">' + '<thead><tr>' +
      '<th data-translate="npeContactType">Contact Type</th>' + '<th data-translate="npeContact">Contact</th>' + '<th data-translate="npeRemark">Remark</th><tbody>';
    for i := low(ContactTelecomItems) to high(ContactTelecomItems) do
    begin
      html := html + '<tr><td>' + ContactTelecomItems[i].TelecomType + '</td>' + '<td>' + ContactTelecomItems[i].Data + '</td>' + '<td>' + ContactTelecomItems[i].Remark + '</td></tr>';
    end;
    html := html + '</tbody></table></div></div>';
  end;

  html := html + '</td></tr>';
  with bmSearchContact.Actions[divSearchContactResultData] do
  begin
    innerHTML := innerHTML + html;
  end;
  if isLast then
    FinishSearchResult;
end;

procedure TdmContacts.LoadContactTelecom(aContactID: NativeInt; Force: Boolean = True);
begin
  with dsPhones do
    if (State = dsInactive) or Force then
    begin
      Close;
      ParamByName('CNTID').AsInteger := aContactID;
      Load([], nil);
    end;
end;

procedure TdmContacts.LoadDossierPerson(aDossierID: NativeInt; aType: string; Force: Boolean = True);
const
  WhereClauseFilter = '<?xml version="1.0"?>' + '<query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0">' + '<where>' + '<binaryoperation operator="And">' +
    '<binaryoperation operator="Equal">' + '<field>dosid</field>' + '<parameter type="LargeInt">DOSSIERID</parameter>' + '</binaryoperation>' + '<binaryoperation operator="Equal">' +
    '<field>docpersontype</field>' + '<constant type="String">%s</constant>' + '</binaryoperation>' + '</binaryoperation>' + '</where>' + '</query>';
begin
  with dsDossierPerson do
    if (State = dsInactive) or Force then
    begin
      Close;
      WhereClause := Format(WhereClauseFilter, [aType]);
      ParamByName('DossierID').AsInteger := aDossierID;
      Load([], nil);
    end;
end;

procedure TdmContacts.LoadDossierPerson(aDossierID, aContactID: NativeInt; Force: Boolean = False);
const
  WhereClauseFilter = '<?xml version="1.0"?>' + '<query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0">' + '<where>' + '<binaryoperation operator="And">' +
    '<binaryoperation operator="Equal">' + '<field>dosid</field>' + '<parameter type="LargeInt">DOSSIERID</parameter>' + '</binaryoperation>' + '<binaryoperation operator="Equal">' +
    '<field>cntid</field>' + '<parameter type="LargeInt">CNTID</parameter>' + '</binaryoperation>' + '</binaryoperation>' + '</where>' + '</query>';
var
  P: TParam;
begin
  with dsDossierPerson do
    if (State = dsInactive) or Force then
    begin
      Close;
      WhereClause := WhereClauseFilter;
      ParamByName('DossierID').AsLargeInt := aDossierID;
      P := Params.FindParam('CNTID');
      if (P = nil) then
      begin
        P := Params.Add;
        P.Name := 'CNTID';
        P.DataType := ftLargeInt;
      end;
      P.AsLargeInt := aContactID;
      Load([], nil);
    end;
end;

procedure TdmContacts.LoadDossierPersonData(aDossierID, aContactID: NativeInt; aParts: TContactDataParts);
begin
  LoadDossierPersonData(aDossierID, aContactID, aParts, aParts);
end;

procedure TdmContacts.LoadDossierPersonData(aDossierID, aContactID: NativeInt; aShowParts, aEditParts: TContactDataParts);
var
  NotConfigured: TContactDataParts;
begin
  NotConfigured := aShowParts - FConfiguredParts;
  if (NotConfigured <> []) then
    raise EContact.CreateFmt('Trying to load unconfigured contact parts: %s', [NotConfigured.AsString]);
  NotConfigured := aEditParts - FConfiguredParts;
  if (NotConfigured <> []) then
    raise EContact.CreateFmt('Trying to load unconfigured contact edit parts: %s', [NotConfigured.AsString]);
  FContactSource := csDossierPersonContact;
  FShowParts := aShowParts;
  FEditParts := aEditParts;
  FPartsToLoad := aShowParts - [cdpPersonal];
  FDossierID := aDossierID;
  FDossierPersonType := '';
  CheckEnabledParts;
  LoadDossierPerson(aDossierID, aContactID);
  // In AfterOpen, the rest will be loaded...
end;

procedure TdmContacts.LoadDossierPersonData(aDossierID: NativeInt; aType: string; aParts: TContactDataParts);
begin
  LoadDossierPersonData(aDossierID, aType, aParts, aParts);
end;

procedure TdmContacts.CheckEnabledParts;
var
  CDP: TContactDataPart;
begin
  for CDP in TContactDataPart do
    if CDP in ShowParts then
      DisenablePart(CDP, not(CDP in EditParts));
end;

procedure TdmContacts.LoadDossierPersonData(aDossierID: NativeInt; aType: string; aShowParts, aEditParts: TContactDataParts);
begin
  SetParts(aShowParts, aEditParts);
  FContactSource := csDossierPersonType;
  FPartsToLoad := aShowParts - [cdpPersonal];
  FDossierID := aDossierID;
  FDossierPersonType := aType;
  CheckEnabledParts;
  LoadDossierPerson(aDossierID, aType);
  // In AfterOpen, the rest will be loaded...
end;

procedure TdmContacts.LoadContactID(aContactID: NativeInt; Force: Boolean = True);
begin
  with dsContactId do
    if (State = dsInactive) or Force then
    begin
      Close;
      ParamByName('CNTID').AsInteger := aContactID;
      Load([], nil);
    end;
end;

procedure TdmContacts.LoadContactWPAddress(aContactID: NativeInt; Force: Boolean = True);
begin
  with dsWPAddress do
    if (State = dsInactive) or Force then
    begin
      Close;
      ParamByName('CNTID').AsInteger := aContactID;
      Load([], nil);
    end;
end;

procedure TdmContacts.LoadContactVPAddress(aContactID: NativeInt; Force: Boolean = True);
begin
  with dsVPaddress do
    if (State = dsInactive) or Force then
    begin
      Close;
      ParamByName('CNTID').AsInteger := aContactID;
      Load([], nil);
    end;
end;

procedure TdmContacts.LoadContactDetail(aContactID: NativeInt; Force: Boolean = True);
begin
  Log.Log(ltDebug, ClassName, 'LoadContactDetail', 'Loading contact ID %d', [aContactID]);
  with dsContact do
    if (State = dsInactive) or Force then
    begin
      Close;
      ParamByName('CNTID').AsInteger := aContactID;
      Load([], nil);
    end;
end;

procedure TdmContacts.LoadContactPicture(aContactID: NativeInt; Force: Boolean = True);
begin
  with dsContactPic do
    if (State = dsInactive) or Force then
    begin
      ParamByName('DOSSIERID').AsLargeInt := DossierID;
      ParamByName('CNTID').AsInteger := aContactID;
      Load([], nil);
    end;
end;

procedure TdmContacts.LoadContactData(aContactID: NativeInt; aParts: TContactDataParts);
begin
  LoadContactData(aContactID, aParts, aParts);
end;

procedure TdmContacts.LoadContactData(aContactID: NativeInt; aShowParts, aEditParts: TContactDataParts);
begin
  SetParts(aShowParts, aEditParts);
  FContactSource := csPersonal;
  FContactID := aContactID;
  // cdpPersonal,cdpAddress,cdpID,cdpEmail,cdpTelephone,cdpPicture
  CheckEnabledParts;
  if cdpPersonal in FShowParts then
    LoadContactDetail(aContactID);
  LoadContactDetailData(aContactID, True, True);
end;

procedure TdmContacts.LoadContactDetailData(aContactID: NativeInt; Force: Boolean = True; ForceWPAddress: Boolean = False);
begin
  if cdpID in FShowParts then
    LoadContactID(aContactID, Force);
  if cdpTelecom in FShowParts then
    LoadContactTelecom(aContactID, Force);
  if (ForceWPAddress or not(cdpDossierPerson in FShowParts)) and (cdpWPAddress in FShowParts) then
    LoadContactWPAddress(aContactID, Force);
  if cdpVPAddress in FShowParts then
    LoadContactVPAddress(aContactID, Force);
  if cdpPicture in FShowParts then
    LoadContactPicture(aContactID, Force);
end;

procedure TdmContacts.DoContactSearchKeyUp(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  srcht: string;
begin
  srcht := bmSearchContact.Actions[edtSearchContact].Value;
  SetElementEnabled(btnSearchContact, Length(srcht) > 1);
  with TJSKeyboardEvent(Event.JSEvent) do
  begin
    if key = 'Escape' then
      bmSearchContact.Hide
    else
      if key = 'Enter' then
      begin
        DoSearch;
        PreventDefault;
        StopPropagation;
      end;
  end;
end;

procedure TdmContacts.FinishSearchResult;
begin
  bmSearchContact.Actions['searchResultRowDoubleClick'].Bind;
  bmSearchContact.Actions['searchResultRowClick'].Bind;
  bmSearchContact.Actions['ShowContactDetails'].Bind;
  SetElementVisible(divSearchContactResults, True);
  FormTranslator.TranslateBelow(TJSHTMLElement(bmSearchContact.Actions[tbodysearchContactResultData].elementHandle), 'searchresult');
end;

procedure TdmContacts.NoContactFound;
begin
  bmSearchContact.Actions[lblSearchContactTerm].Value := bmSearchContact.Actions[edtSearchContact].Value;
  SetElementVisible(divEmptyContactSearchResult, True);
end;

procedure TdmContacts.DoSearchContactsClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  DoSearch;
end;

procedure TdmContacts.DoSearchNewContact(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  bmSearchContact.Hide;
  EditContact(-1);
end;

procedure TdmContacts.DoSaveError(aPart: TContactDataPart);
begin
  if Assigned(FOnSaveError) then
    FOnSaveError(self, aPart);
end;

procedure TdmContacts.DoSearch;
var
  aSearchTerm: string;
begin
  // clear previous results
  SetElementVisible(divSearchContactResults, False);
  SetElementVisible(divEmptyContactSearchResult, False);
  SetElementVisible(divInitialContactScreen, False);
  bmSearchContact.Actions[tbodysearchContactResultData].innerHTML := '';
  aSearchTerm := bmSearchContact.Actions[edtSearchContact].Value;
  Server.SimpleSearchContacts(aSearchTerm, True, @getSearchResultItem, @NoContactFound);
end;

procedure TdmContacts.DoResultRowDoubleClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  DoContactSelected;
  bmSearchContact.Hide;
end;

procedure TdmContacts.DoEditContact(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  EditContact(ContactID)
end;

procedure TdmContacts.DoEditDialogHide(Sender: TObject; CloseEl: TJSHTMLElement; Values: TStrings);
begin
  if not FOKClicked then
    if Assigned(OnCancelEdit) then
      OnCancelEdit(self);
end;

procedure TdmContacts.SetElementVisible(const elementId: string; isVisible: Boolean);
begin
  if isVisible then
    jQuery('#' + elementId).css('display', '')
  else
    jQuery('#' + elementId).css('display', 'none');
end;

procedure TdmContacts.SetFormIsReadOnly(const Value: Boolean);
begin
  FFormIsReadOnly := Value;
  CheckNewContactButton;
  CheckSearchButton;
end;

procedure TdmContacts.SetEditContactButtonID(const Value: string);
var
  aEdit: TElementAction;
begin
  FEditContactButtonID := Value;
  if FEditContactButtonID = '' then
    exit;
  aEdit := alForm.FindByName(btnEditContact);
  if (aEdit = nil) then
  begin
    aEdit := alForm.Actions.Add;
    aEdit.Event := heClick;
    aEdit.Name := btnEditContact;
    aEdit.OnExecute := DoEditContact;
  end;
  aEdit.ID := FEditContactButtonID;
  aEdit.Bind;
  CheckNewContactButton;
end;

procedure TdmContacts.SetParts(const aShowParts, aEditParts: TContactDataParts);
var
  NotConfigured: TContactDataParts;
begin
  NotConfigured := aShowParts - FConfiguredParts;
  if (NotConfigured <> []) then
    raise EContact.CreateFmt('Trying to load unconfigured contact parts', [NotConfigured.AsString]);
  NotConfigured := aEditParts - FConfiguredParts;
  if (NotConfigured <> []) then
    raise EContact.CreateFmt('Trying to load unconfigured edit contact parts', [NotConfigured.AsString]);
  FShowParts := aShowParts;
  FEditParts := aEditParts;
end;

procedure TdmContacts.SetPersonalFieldInfo(const Value: TPersonalFieldEdits);
begin
  FPersonalFieldInfo := Value;
  dmServer.DoOnParamsLoaded(@SetupPersonalFields);
  Include(FConfiguredParts, cdpPersonal);
end;

procedure TdmContacts.SetContactIDFieldInfo(const Value: TContactIDFieldEdits);
begin
  FContactIDFieldInfo := Value;
  dmServer.DoOnParamsLoaded(@SetupIDFields);
  Include(FConfiguredParts, cdpID);
end;

procedure TdmContacts.SetDocumentBox(const Value: TDocumentBox);
begin
  if Assigned(FDocumentBox) then
    FDocumentBox.RemoveFreeNotification(self);
  FDocumentBox := Value;
  if Assigned(FDocumentBox) then
    FDocumentBox.FreeNotification(self);
end;

procedure TdmContacts.SetDossierConnection(const Value: TP2WDAConnection);
begin
  if FDossierConnection = Value then
    exit;
  FDossierConnection := Value;
  SetDossierDatasetConnections;
end;

procedure TdmContacts.SetDossierContactFieldInfo(const Value: TDossierContactFieldEdits);
begin
  FDossierContactFieldInfo := Value;

  dmServer.DoOnParamsLoaded(@SetupDossierContactFields);
  Include(FConfiguredParts, cdpDossierPerson);
end;

procedure TdmContacts.SetDossierID(const Value: NativeInt);
begin
  FDossierID := Value;
  dsDossierPerson.ParamByName('DOSSIERID').AsLargeInt := DossierID;
  // dsSpouse.Load([], nil);
end;

procedure TdmContacts.SetElementEnabled(const elementId: string; isEnabled: Boolean; ByName: Boolean = False);
var
  Qry: string;
begin
  Log.Log(ltDebug, ClassName, 'SetElementEnabled', 'Setting Element %s enabled (By name: %s) : %s', [elementId, BoolToStr(ByName, 'True', 'False'), BoolToStr(isEnabled, 'True', 'False')]);
  if ByName then
    Qry := 'input[name="' + elementId + '"]'
  else
    Qry := '#' + elementId;
  jQuery(Qry).prop('disabled', not isEnabled)
end;

procedure TdmContacts.DoResultRowSingleClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  bmSearchContact.Actions['searchResultRowClick'].removeClass('table-primary');
  jQuery(Event.JSEvent.target).closest('tr').addClass('table-primary');
  SetElementEnabled(btnSelectContact, True);
end;

procedure TdmContacts.SearchContact;
begin
  // if (FSearchButtonID='') then
  // Raise EContact.Create('Internal error: SearchButtonID not set');
  bmSearchContact.Render;
  FormTranslator.TranslateBelow(TJSHTMLElement(bmSearchContact.Actions[mdlSearchContact].elementHandle), 'searchresult');
  bmSearchContact.Actions[edtSearchContact].Value := '';
  TJSHTMLElement(bmSearchContact.Actions[edtSearchContact].elementHandle).Focus;
  // These must be done after the render, obviously
  SetElementVisible(divSearchContactResults, False);
  SetElementVisible(divEmptyContactSearchResult, False);
  SetElementVisible(divInitialContactScreen, True);
  SetElementEnabled(btnSearchContact, False);
  SetElementEnabled(btnSelectContact, False);
  // jQuery('#searchContactModal').modal(new(['backdrop', 'static']));
  // FShowingModal := True;
  // register var to call it later
  // FSearchContactResultCallBack := searchResultCallBack;
end;

procedure TdmContacts.ShowSelectContactDlg(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  SearchContact;
end;

procedure TdmContacts.StartNewContact;
begin
  StartNewContact(FShowParts, FEditParts);
end;

procedure TdmContacts.StartNewContact(const aShowParts, aEditParts: TContactDataParts);
begin
  SetParts(aShowParts, aEditParts);
  ClearContactData;
  CheckEnabledParts;
  FMarCon := '';
  FCivilStatus := '';
  FContactID := 0;
  FContactAddressID := 0;
  case FContactSource of
    csPersonal:
      LoadContactData(-1, ShowParts, EditParts);
    csDossierPersonType:
      begin
        if not dsDossierPerson.isEmpty then
          dsDossierPerson.Delete;
        LoadContactDetailData(-1);
      end;
    csDossierPersonContact:
      begin
        if not dsDossierPerson.isEmpty then
          dsDossierPerson.Delete;
        LoadContactDetailData(-1);
      end;
  end;
end;

procedure TdmContacts.SetDossierDatasetConnections;
begin
  dsContactId.DAConnection := DossierConnection;
  dsPhones.DAConnection := DossierConnection;
  dsWPAddress.DAConnection := DossierConnection;
  dsVPaddress.DAConnection := DossierConnection;
  dsDossierPerson.DAConnection := DossierConnection;
  dsContactPic.DAConnection := DossierConnection;
end;

procedure TdmContacts.WebDataModuleCreate(Sender: TObject);
var
  aList: TDezqParameterArray;
  aParam: TDezqParameter;
  i: Integer;
begin
  FDisableEditingExisting := True;
  bmSearchContact.TemplateName := TPL_SEARCHCONTACT;
  bmSearchContact.elementId := 'searchContactModal';
  bmEditContactData.elementId := 'editContactModal';
  FGender := TDictionary<string, string>.Create;
  FContactDescription := SContact;
  FEntityType := etCompany;
  aList := Server.GetParamList('GENDER');
  for aParam in aList do
    with aParam do
      if not FGender.ContainsKey(name) then
        FGender.Add(name, Value);
  dsContact.DAConnection := Server.ContactConnection;
  DossierConnection := Server.PetitionConnection;
  SetDossierDatasetConnections;
  FCountryData := TDMCountry.Create(self);
  for i := 0 to ComponentCount - 1 do
  begin
    if Components[i] is TDataSet then
      SetupDataset(Components[i] as TDataSet);
  end;
  AllowedContactType := actBoth;
  FPredefinedNIDNR := '';
end;

procedure TdmContacts.SetupDataset(aDataset: TDataSet);
begin
  if aDataset.OnLoadFail = nil then
    aDataset.OnLoadFail := @DoDataLoadFail;
end;

procedure TdmContacts.DoDataLoadFail(DataSet: TDataSet; ID: Integer; const ErrorMsg: string);
var
  Msg, aDetail, aName: string;
begin
  aName := DataSet.Name;
  if aName = '' then
    aName := DataSet.ClassName;
  if DataSet is TP2WDADataset then
    aName := aName + ' (' + TP2WDADataset(DataSet).TableName + ')';
  aDetail := '(Detail: ' + ErrorMsg + ')';
  Msg := Format(SErrFailedToLoadDataset, [aName, aDetail]);
  Log.Log(ltError, ClassName, 'DoDataLoadFail', Msg);
  dmServer.ShowError(Msg);
end;

procedure TdmContacts.onGetGenderItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string; ValueType: Integer; Extra: string; isLast: Boolean);
begin
  if not FGender.ContainsKey(name) then
    FGender.Add(name, Value);
end;

procedure TdmContacts.WebDataModuleDestroy(Sender: TObject);
begin
  FreeAndNil(FGender);
end;

procedure TdmContacts.SetSearchButtonID(const Value: string);
var
  aSearch: TElementAction;
begin
  FSearchButtonID := Value;
  aSearch := alForm.FindByName(btnShowSearchContact);
  if (aSearch = nil) then
  begin
    aSearch := alForm.Actions.Add;
    aSearch.Event := heClick;
    aSearch.Name := btnShowSearchContact;
    aSearch.OnExecute := ShowSelectContactDlg;
  end;
  aSearch.ID := FSearchButtonID;
  aSearch.Bind;
end;

procedure TdmContacts.SetTelecomFieldInfo(const Value: TTelecomFieldEdits);
var
  T: TTelecomField;
begin
  FTelecomFieldInfo := Value;
  dmServer.DoOnParamsLoaded(@SetupTelecomFields);
  for T in TTelecomField do
    Include(FConfiguredParts, cdpTelecom);
end;

procedure TdmContacts.DoAllSaved;
begin
  if FInEditDialog then
  begin
    bmEditContactData.Hide;
    FInEditDialog := False;
  end;
  if Assigned(FOnAllSaved) then
    FOnAllSaved(self);
end;

procedure TdmContacts.DoCheckSearchName(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  aName: string;
begin
  aName := Trim(GetFieldValue(pfLastName));
  if EntityType = etPerson then
    aName := Trim(GetFieldValue(pfFirstName) + ' ' + aName);
  alForm[FPersonalFieldInfo.Names[pfSearchName]].Value := aName;
end;

procedure TdmContacts.DoClearError(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if Element.Element.ID = '' then
    exit;
  ClearValidStatus(Element.Element.ID, Element.Element.getAttribute('type') = 'radio');
  {  jQuery('#' + Element.element.id).closest('div[class^="col-"]').find('.invalid-feedback').css('display', 'none');
    jQuery('#' + Element.element.id).closest('div[class^="form-group"]').find('.invalid-feedback').css('display', 'none');

   if Element.element.getAttribute('type') = 'radio' then
   begin
   jQuery('#' + Element.element.id).closest('div[class^="form-row"]').find('.invalid-feedback').css('display', 'none');
   jQuery('#' + Element.element.id).closest('div[class^="form-row"]').find('.incorrect-value').css('display', 'none');
   end;}
end;

procedure TdmContacts.SetupPersonalFields;
const
  ZipAndName = [pfBirthCityZip, pfBirthCityName];
  FirstAndLastName = [pfFirstName, pfLastName, pfSearchName];
  Lastname = [pfLastName, pfSearchName];

  procedure CreateChangeAction(aField: TPersonalField);
  var
    aFld, aName: string;
    aAction: TElementAction;
  begin
    aFld := FPersonalFieldInfo.Names[aField];
    aName := aFld + 'Change';
    aAction := alForm.FindByName(aName);
    if aAction = nil then
    begin
      aAction := alForm.Actions.Add;
      aAction.Name := aName;
      aAction.Event := heChange;
      aAction.OnExecute := @DoCheckSearchName;
    end;
    aAction.ID := aFld;
    aAction.Bind;
  end;

var
  T: TPersonalField;
  aName: string;
  aAction: TElementAction;
  SearchFields: TPersonalFields;
begin
  for T in TPersonalFields do
    if (T in FPersonalFieldInfo.Fields) and (FPersonalFieldInfo.Names[T] <> '') then
    begin
      aName := FPersonalFieldInfo.Names[T];
      aAction := alForm.FindByName(aName);
      if aAction = nil then
      begin
        aAction := alForm.Actions.Add;
        aAction.Name := aName;
        aAction.Event := heFocus;
        aAction.OnExecute := @DoClearError;
      end;
      aAction.ID := aName;
      aAction.Bind;
    end;
  with FPersonalFieldInfo do
    if (ZipAndName * Fields) = ZipAndName then
      bindZipLookup(Names[pfBirthCityZip], Names[pfBirthCityName]);
  if pfNationality in FPersonalFieldInfo.Fields then
    FCountryData.LoadData(@OnGetCountryData);
  if FInEditDialog then
  begin
    if EntityType = etCompany then
      SearchFields := Lastname
    else
      SearchFields := FirstAndLastName;
    if (SearchFields * FPersonalFieldInfo.Fields) = SearchFields then
      for T in TPersonalField do
        if (T <> pfSearchName) and (T in SearchFields) then
          CreateChangeAction(T);
  end;
end;

procedure TdmContacts.SetupAddressFields(aEdit: TAddressFieldEdits);
var
  AF: TAddressField;
  aName: string;
  aAction: TElementAction;
begin
  for AF in TAddressField do
    if (AF in aEdit.Fields) and (aEdit.Names[AF] <> '') then
    begin
      aName := aEdit.Names[AF];
      aAction := alForm.FindByName(aName);
      if (aAction = nil) then
      begin
        aAction := alForm.Actions.Add;
        aAction.Name := aName;
        aAction.Event := heFocus;
        aAction.OnExecute := @DoClearError;
      end;
      aAction.ID := aName;
      aAction.Bind;
    end;
  if ([afCityZip, afCityName] * aEdit.Fields) = ([afCityZip, afCityName]) then
    bindZipLookup(aEdit.Names[afCityZip], aEdit.Names[afCityName]);
  // bindZipLookup('edtPartAddressZip', 'edtPartAddressTown');
  // bindZipLookup('edtPartResAddressZip', 'edtPartResAddressTown');
end;

procedure TdmContacts.SetupDossierContactFields;

  procedure DoList(aField: TDossierContactField; aType, aSelected, aDefault: string; aLimit: array of string = []);
  begin
    with FDossierContactFieldInfo do
    begin
      if not(aField in Fields) then
        exit;
      if not(aField in UseExistingBlocks) then
        FillRadioListBlock(BlockNames[aField], Names[aField], aType, aSelected, aDefault, aLimit)
      else
        LinkRadioListBlock(BlockNames[aField], Names[aField], aType);
    end;
  end;

var
  DCF: TDossierContactField;
  aName: string;
  aAction: TElementAction;
begin
  for DCF in TDossierContactField do
    if (DCF in FDossierContactFieldInfo.Fields) and (FDossierContactFieldInfo.Names[DCF] <> '') then
    begin
      aName := FDossierContactFieldInfo.Names[DCF];
      aAction := alForm.FindByName(aName);
      if (aAction = nil) then
      begin
        aAction := alForm.Actions.Add;
        aAction.Name := aName;
        aAction.Event := heFocus;
        aAction.OnExecute := @DoClearError;
      end;
      aAction.ID := aName;
      aAction.Bind;
    end;
  DoList(dcfMarriageContract, 'MARCON', MarCon, 'NA');
  DoList(dcfCivilStatus, 'PERSBURG', CivilStatus, 'ALONE');
  DoList(dcfPersonType, 'PERSTYP', DossierPersonType, 'ANDERE', FDossierContactFieldInfo.PersonTypeLimit)
end;

procedure TdmContacts.OnContactPhotoChanged;
var
  fileList: JSValue;
begin
  if FAvatarFileID <> '' then
  begin
    if FAvatarFileID[1] <> '#' then
      fileList := jQuery('#' + FAvatarFileID).prop('files')
    else
      fileList := jQuery(FAvatarFileID).prop('files');
    FPhoto := TJSObject(TJSArray(fileList)[0]);
    FPhotoChanged := True;
  end;
end;

procedure TdmContacts.SetUpPhoto(const aAvatarEditID, aAvatarImageID, aEvent: string; aDataset: TP2WDADataset = nil);
var
  el: TJSElement;
begin
  FAvatarFileID := aAvatarEditID;
  FAvatarImageID := aAvatarImageID;
  el := document.getElementById(FAvatarFileID);
  el.addEventListener(aEvent, @OnContactPhotoChanged);
  Include(FConfiguredParts, cdpPicture);
end;

procedure TdmContacts.SetupTelecomFields;
var
  T: TTelecomField;
  aName: string;
  aAction: TElementAction;
begin
  for T in TTelecomFields do
    if (T in FTelecomFieldInfo.Fields) and (FTelecomFieldInfo.Names[T] <> '') then
    begin
      aName := FTelecomFieldInfo.Names[T];
      aAction := alForm.FindByName(aName);
      if aAction = nil then
      begin
        aAction := alForm.Actions.Add;
        aAction.Name := aName;
        aAction.Event := heFocus;
        aAction.OnExecute := @DoClearError;
      end;
      aAction.ID := aName;
      aAction.Bind;
    end;
end;

procedure TdmContacts.SetupIDFields;
var
  F: TContactIDField;
  aName: string;
  aAction: TElementAction;
begin
  for F in TContactIDField do
    if (F in FContactIDFieldInfo.Fields) and (FContactIDFieldInfo.Names[F] <> '') then
    begin
      aName := FContactIDFieldInfo.Names[F];
      aAction := alForm.FindByName(aName);
      if aAction = nil then
      begin
        aAction := alForm.Actions.Add;
        aAction.Name := aName;
        aAction.Event := heFocus;
        aAction.OnExecute := @DoClearError;
      end;
      aAction.ID := aName;
      aAction.Bind;
    end;
  if (cifCardType in FContactIDFieldInfo.Fields) then
    FillParamCombobox(FContactIDFieldInfo.Names[cifCardType], 'IDTYP');
end;

procedure TdmContacts.FillParamCombobox(const aElName, aParamType: string);
var
  aList: TDezqParameterArray;
  aParam: TDezqParameter;
  aHTML: string;
begin
  aHTML := '';
  aList := dmServer.GetParamList(aParamType);
  for aParam in aList do
    with aParam do
      aHTML := aHTML + Format('<option data-id="%d" value="%s">%s</option>', [RecordID, name, Value]);
  alForm[aElName].innerHTML := aHTML
end;

procedure TdmContacts.FillRadioListBlock(const aElName, aGroupName, aParamType: string; aSelected: string = ''; aDefault: string = ''; aLimit: array of string = []);
const
  html = '<div class="custom-control custom-control-inline custom-radio">' + '  <input data-id="%d" type="radio" class="custom-control-input" name="%s" id="msp%s%d" %s value="%s"> ' +
    '  <label class="custom-control-label" for="msp%s%d">%s</label>' + '</div>';
var
  aList: TDezqParameterArray;
  aParam: TDezqParameter;
  aChecked, aHTML: string;
  aAction: TElementAction;

  function AllowParam: Boolean;
  begin
    Result := (Length(aLimit) = 0) or (IndexText(aParam.Name, aLimit) <> -1);
  end;

begin
  aHTML := jQuery('#' + aElName).html;
  aList := dmServer.GetParamList(aParamType);
  for aParam in aList do
    if AllowParam then
      with aParam do
      begin
        if (aSelected = name) or ((aSelected = '') and (aDefault <> '') and (name = aDefault)) then
          aChecked := 'checked=""'
        else
          aChecked := '';
        aHTML := aHTML + Format(html, [RecordID, aGroupName, aGroupName, RecordID, aChecked, name, aGroupName, RecordID, Value]);
      end;
  // group: rdPMSystem
  // aElName: PartMarriageSystemBlock
  jQuery('#' + aElName).html(aHTML);
  aAction := alForm.Actions.Add;
  aAction.Name := aElName;
  aAction.Event := heNone;
  aAction.StopPropagation := False;
  aAction.PreventDefault := False;
  aAction.Selector := '#' + aElName + ' input[name="' + aGroupName + '"]';
  aAction.Bind;
end;

procedure TdmContacts.LinkRadioListBlock(const aElName, aGroupName, aParamType: string);
var
  aAction: TElementAction;
  aEl: TJSElement;
  aValue: string;
  aID: NativeInt;
begin
  aAction := alForm.Actions.Add;
  aAction.Name := aElName;
  aAction.Event := heNone;
  aAction.Selector := '#' + aElName + ' input[name="' + aGroupName + '"]';
  aAction.Bind;
  for aEl in aAction.ElementHandles do
  begin
    aValue := string(aEl['value']);
    aID := dmServer.GetParamID(aParamType, aValue);
    aEl['data-id'] := intToStr(aID);
  end;
end;

procedure TdmContacts.SetVPAddressFieldInfo(const Value: TAddressFieldEdits);
begin
  FVPAddressFieldInfo := Value;
  dmServer.DoOnParamsLoaded(
    procedure
    begin
      SetupAddressFields(FVPAddressFieldInfo);
    end);
  Include(FConfiguredParts, cdpVPAddress);
end;

procedure TdmContacts.SetWPAddressFieldInfo(const Value: TAddressFieldEdits);
begin
  FWPAddressFieldInfo := Value;
  dmServer.DoOnParamsLoaded(
    procedure
    begin
      SetupAddressFields(FWPAddressFieldInfo);
    end);
  Include(FConfiguredParts, cdpWPAddress);
end;

procedure TdmContacts.SaveCardInfo;
var
  HaveCardData: Boolean;
  edtNIDNR: string;
  aDataset: TP2WDADataset;
begin
  aDataset := dsContactId;
  Log.Log(ltDebug, ClassName, 'SaveCardInfo', 'Start');
  edtNIDNR := FContactIDFieldInfo.Names[cifNidnr];
  HaveCardData := not FieldIsEmpty(edtNIDNR, False);
  if not HaveCardData then
  begin
    if aDataset.isEmpty then
    begin
      Exclude(FPartsToSave, cdpID);
      CheckNextPartSave;
      exit;
    end;
    aDataset.Delete;
  end
  else
  begin
    if aDataset.isEmpty then
      aDataset.Append
    else
      aDataset.edit;
    SaveContactIDFields(aDataset);
    aDataset.Post;
  end;
  aDataset.ApplyUpdates;
end;

procedure TdmContacts.DefaultFieldCopy(F: TField; aAction: TElementAction);
begin
  case F.DataType of
    ftString, ftWideString:
      F.AsString := aAction.Value;
    ftDate, ftDateTime, ftTime:
      begin
        if aAction.Value = '' then
          F.Clear
        else
          F.AsDatetime := ExtractDate(aAction.Value);
      end;
    ftInteger:
      F.AsInteger := StrToIntDef(aAction.Value, 0);
    ftLargeInt:
      F.AsLargeInt := StrToInt64Def(aAction.Value, 0);
  end;
end;

procedure TdmContacts.SaveContactIDFields(aDataset: TDataSet);
var
  aValue, aName, S: string;
  F: TContactIDField;
  aAction: TElementAction;
  Fld: TField;
begin
  if aDataset.State = dsInsert then
  begin
    aDataset.FieldByName('cticreatedon').AsDatetime := now;
    aDataset.FieldByName('cticreatedbyfk').AsLargeInt := Server.UserID;
    aDataset.FieldByName('cticontactfk').AsInteger := ContactID;
  end;
  aDataset.FieldByName('ctichangedon').AsDatetime := now;
  aDataset.FieldByName('ctichangedbyfk').AsLargeInt := Server.UserID;
  for F in TContactIDField do
  begin
    Fld := aDataset.FieldByName(IDFieldNames[F]);
    if F in FContactIDFieldInfo.Fields then
    begin
      aName := FContactIDFieldInfo.Names[F];
      aAction := alForm[aName];
      aValue := aAction.Value;
      case F of
        cifNidnr:
          Fld.AsString := Server.reformatNationalNo(aValue, True);
        cifCardType:
          begin
            DefaultFieldCopy(Fld, aAction);
            // ID field
            S := string(jQuery('#' + aName).find('option[value="' + aValue + '"]').attr('data-id'));
            if (S <> undefined) and (S <> '') then
              aDataset.FieldByName('cticardtypefk').AsLargeInt := StrToInt64Def(S, 0)
            else
              console.log('Not a valid card type: "' + aValue + '" ');
          end;
        cifValidFrom, cifValidTo:
          Fld.AsDatetime := dmServer.ValidityFromToDate(aValue, (F = cifValidFrom));
      else
        DefaultFieldCopy(Fld, aAction)
      end;
    end;
  end;
end;

procedure TdmContacts.SaveContactFields(aDataset: TDataSet);
var
  aName, aValue, Country: string;
  CountryId: NativeInt;
  CityID: NativeInt;
  PF: TPersonalField;
  aAction: TElementAction;
  F: TField;
  QOpt: TJQuery;
begin
  // insert ?
  if aDataset.State = dsInsert then
  begin
    aDataset.FieldByName('cntcreatedon').AsDatetime := now;
    aDataset.FieldByName('cntcreatedbyfk').AsLargeInt := Server.UserID;
    aDataset.FieldByName('cntcompanyfk').AsInteger := Server.UserInfo.OfficeID;
    aDataset.FieldByName('cntisbirthdateunknown').asBoolean := False;
    aDataset.FieldByName('cntpicturefk').AsInteger := 0;
    aDataset.FieldByName('cntkbonr').AsString := '';
    aDataset.FieldByName('cntgender').AsString := 'C';
    aDataset.FieldByName('cntCityOfBirthName').AsString := '';
    aDataset.FieldByName('cntCityOfBirthZip').AsString := '';
    aDataset.FieldByName('cntLastName').AsString := '';
    aDataset.FieldByName('cntFirstName').AsString := '';
    aDataset.FieldByName('cntNationality2').AsString := '';
    aDataset.FieldByName('cntnationalityfk').AsLargeInt := 0;
    aDataset.FieldByName('cntnationality').AsString := '';
    aDataset.FieldByName('cntprofession').AsString := '';
    aDataset.FieldByName('cntsalutation').AsString := '';
    aDataset.FieldByName('cntsearchname').AsString := '';
    aDataset.FieldByName('cntprefixes').AsString := '';
    aDataset.FieldByName('cntfriendlytitle').AsString := '';
    aDataset.FieldByName('cntcityofbirthfk').AsLargeInt := 0;
  end;
  aDataset.FieldByName('cntchangedon').AsDatetime := now;
  aDataset.FieldByName('cntchangedbyfk').AsLargeInt := Server.UserID;
  for PF in TPersonalField do
  begin
    F := aDataset.FieldByName(ContactFieldNames[PF]);
    if PF in FPersonalFieldInfo.Fields then
    begin
      aName := FPersonalFieldInfo.Names[PF];
      aAction := alForm[aName];
      if Assigned(aAction.elementHandle) then
        aValue := aAction.Value
      else
        aValue := '';
      // Special cases
      case PF of
        pfGender:
          F.AsString := getRadioGroupValue(aName);
        pfNationality:
          begin
            QOpt := jQuery('#' + aName).find('option[value="' + aValue + '"]');
            CountryId := StrToIntDef(string(QOpt.attr('data-id')), 0);
            Country := string(QOpt.text());
            DefaultFieldCopy(F, aAction);
            aDataset.FieldByName('cntnationalityfk').AsLargeInt := CountryId;
            aDataset.FieldByName('cntnationality').AsString := Country;
          end;
        pfBirthCityName:
          begin
            DefaultFieldCopy(F, aAction);
            CityID := StrToIntDef(string(jQuery('#' + aName).attr('data-cid')), 0);
            aDataset.FieldByName('cntcityofbirthfk').AsLargeInt := CityID;
          end
      else
        DefaultFieldCopy(F, aAction);
      end;
    end;
  end;
end;

procedure TdmContacts.SaveAddressFields(aDataset: TDataSet; aType: string; aEdits: TAddressFieldEdits);
var
  CityID: NativeInt;
  AF: TAddressField;
  aName: string;
  aAction: TElementAction;
  F: TField;
begin
  if aDataset.State = dsInsert then
  begin
    aDataset.FieldByName('ctacreatedon').AsDatetime := now;
    aDataset.FieldByName('ctacreatedbyfk').AsLargeInt := Server.UserID;
    aDataset.FieldByName('ctacontactfk').AsInteger := ContactID;
    aDataset.FieldByName('ctaaddresstypefk').AsLargeInt := Server.GetParamID('ADTYP', aType);
    aDataset.FieldByName('ctaaddresstype').AsString := aType;
    aDataset.FieldByName('ctacorrespondence').asBoolean := False;
    aDataset.FieldByName('ctasecret').asBoolean := False;
  end;
  aDataset.FieldByName('ctachangedon').AsDatetime := now;
  aDataset.FieldByName('ctachangedbyfk').AsLargeInt := Server.UserID;
  for AF in TAddressField do
  begin
    F := aDataset.FieldByName(AddressFieldNames[AF]);
    if AF in aEdits.Fields then
    begin
      aName := aEdits.Names[AF];
      aAction := alForm[aName];
      if Assigned(aAction.elementHandle) then
        DefaultFieldCopy(F, aAction);
      if AF = afCityName then
      begin
        CityID := StrToInt64Def(string(jQuery('#' + aName).attr('data-cid')), 0);
        aDataset.FieldByName('ctacityfk').AsLargeInt := CityID;
      end;
    end;
  end;
end;

procedure TdmContacts.SaveDossierContactFields(aDataset: TDataSet);
var
  Q: TJQuery;
  aName: string;
  aJS: JSValue;
begin
  if (aDataset.State = dsInsert) then
  begin
    aDataset.FieldByName('doccreatedon').AsDatetime := now;
    aDataset.FieldByName('doccreatedbyfk').AsLargeInt := Server.UserID;
    aDataset.FieldByName('docdossierfk').AsLargeInt := DossierID;
    aDataset.FieldByName('docpersontype').AsString := DossierPersonType;
    aName := DossierPersonType;
    if aName = '' then
      aName := 'ANDERE';
    aDataset.FieldByName('docpersontypefk').AsInteger := Server.GetParamID('PERSTYP', aName);
    aDataset.FieldByName('docissecondpetitioner').asBoolean := False;
    aDataset.FieldByName('doccivilstatusfk').AsLargeInt := 0;
    aDataset.FieldByName('doccivilstatus').AsString := '';
    aDataset.FieldByName('docmarconfk').AsLargeInt := 0;
    aDataset.FieldByName('docmarcon').AsString := '';
  end;
  aDataset.FieldByName('docchangedon').AsDatetime := now;
  aDataset.FieldByName('docchangedbyfk').AsLargeInt := Server.UserID;

  if dcfCivilStatus in FDossierContactFieldInfo.Fields then
  begin
    aName := FDossierContactFieldInfo.Names[dcfCivilStatus];
    Q := jQuery('input[name="' + aName + '"]:checked');
    aJS := Q.attr('data-id');
    if aJS <> undefined then
      aDataset.FieldByName('doccivilstatusfk').AsLargeInt := StrToInt64Def(string(aJS), 0);
    aDataset.FieldByName('doccivilstatus').AsString := getRadioGroupValue(aName);
  end;
  if dcfMarriageContract in FDossierContactFieldInfo.Fields then
  begin
    aName := FDossierContactFieldInfo.Names[dcfMarriageContract];
    Q := jQuery('input[name="' + aName + '"]:checked');
    aJS := Q.attr('data-id');
    if aJS <> undefined then
      aDataset.FieldByName('docmarconfk').AsLargeInt := StrToInt64Def(string(aJS), 0);
    aDataset.FieldByName('docmarcon').AsString := getRadioGroupValue(aName);
  end;
  if dcfSecondPetitioner in FDossierContactFieldInfo.Fields then
  begin
    aName := FDossierContactFieldInfo.Names[dcfSecondPetitioner];
    aDataset.FieldByName('docissecondpetitioner').asBoolean := getRadioGroupValue(aName) = 'Y';
  end;
  if dcfPersonType in FDossierContactFieldInfo.Fields then
  begin
    aName := FDossierContactFieldInfo.Names[dcfPersonType];
    Q := jQuery('input[name="' + aName + '"]:checked');
    aJS := Q.attr('data-id');
    if aJS <> undefined then
      aDataset.FieldByName('docpersontypefk').AsLargeInt := StrToInt64Def(string(aJS), 0);
    aDataset.FieldByName('docpersontype').AsString := getRadioGroupValue(aName);
  end;
end;

procedure TdmContacts.SaveContact;
begin
  if not dsContact.isEmpty then
    dsContact.edit
  else
  begin
    dsContact.Append;
    dsContact.FieldByName('cntcreatedon').AsDatetime := now;
    dsContact.FieldByName('cntcreatedbyfk').AsLargeInt := Server.UserID;
    dsContact.FieldByName('cntcompanyfk').AsInteger := Server.UserInfo.OfficeID;
  end;
  dsContact.FieldByName('cntchangedon').AsDatetime := now;
  dsContact.FieldByName('cntchangedbyfk').AsLargeInt := Server.UserID;
  SaveContactFields(dsContact);
  dsContact.Post;
  dsContact.ApplyUpdates;
end;

procedure TdmContacts.SaveContactData;
begin
  Log.Log(ltDebug, ClassName, 'SaveContactData', 'Start');
  // Initialize what needs to be saved.
  FPartsToSave := FEditParts;
  CheckNextPartSave;
end;

procedure TdmContacts.CheckSearchButton;
begin
  if SearchButtonID = '' then
    exit;
  SetElementEnabled(SearchButtonID, not FormIsReadOnly);
end;

procedure TdmContacts.CheckNewContactButton;
begin
  if EditContactButtonID = '' then
    exit;
  SetElementEnabled(EditContactButtonID, not FormIsReadOnly);
end;

procedure TdmContacts.CheckNextPartSave;
{
 This routine is called successively after the ApplyUpdates of a dataset returns through PartSavedEnd.
 As soon as the 'PartsToSave' is done, all parts were saved and we can do the 'DoAllSaved' to notify the owner.
 The order in which things are saved is determined here.
}
begin
  Log.Log(ltDebug, ClassName, 'CheckNextPartSave', 'Start');
  if FPartsToSave = [] then
  begin
    DoAllSaved;
    exit;
  end;
  if cdpDossierPerson in FPartsToSave then
    SaveDossierPerson
  else
    if cdpPersonal in FPartsToSave then
      SaveContact
    else
      if cdpID in FPartsToSave then
        SaveCardInfo
      else
        if cdpVPAddress in FPartsToSave then
          SaveResidentialAddress
        else
          if cdpWPAddress in FPartsToSave then
            SaveOfficialAddress
          else
            if cdpTelecom in FPartsToSave then
              SaveTelecom
            else
              if cdpPicture in FPartsToSave then
                SavePhoto;
end;

procedure TdmContacts.SaveDossierPerson;
var
  aDataset: TP2WDADataset;
begin
  aDataset := dsDossierPerson;
  Log.Log(ltDebug, ClassName, 'SaveDossierPerson', 'Start');
  // Check if there was a change in contact. If so, we delete the old record first.
  if (ContactID > 0) and (not aDataset.isEmpty) and (ContactID <> aDataset.FieldByName('cntID').AsLargeInt) then
    aDataset.Delete;
  if not aDataset.isEmpty then
    aDataset.edit
  else
  begin
    aDataset.Append;
    if (ContactID > 0) then
    begin
      aDataset.FieldByName('cntID').AsLargeInt := ContactID;
      aDataset.FieldByName('doccontactfk').AsLargeInt := ContactID;
    end;
    if (ContactAddressID > 0) then
    begin
      aDataset.FieldByName('ctaID').AsLargeInt := ContactAddressID;
    end;
  end;
  SaveContactFields(aDataset);
  SaveAddressFields(aDataset, 'WP', WPAddressFieldInfo);
  SaveDossierContactFields(aDataset);
  aDataset.Post;
  aDataset.ApplyUpdates;
end;

procedure TdmContacts.SaveIfValid;
var
  CheckedNIDNR, canSave: Boolean;
  aDate, aNIDNR, aGender: string;

  procedure GSMNoCheckResult(aSuccess: Boolean; anError: string);
  begin
    canSave := canSave and aSuccess;
    if not aSuccess then
    begin
      if Trim(anError) = '' then
        anError := 'Invalid mobile phone';
      DisplayError(FTelecomFieldInfo.Names[tfMobile], '', anError)
    end;
    if canSave then
      SaveContactData;
  end;

  procedure PhoneNoCheckResult(aSuccess: Boolean; anError: string);
  begin
    canSave := canSave and aSuccess;
    if not aSuccess then
    begin
      if Trim(anError) = '' then
        anError := 'Invalid phone';
      DisplayError(FTelecomFieldInfo.Names[tfTelephone], '', anError);
    end;
    if (cdpTelecom in FEditParts) and (tfMobile in FTelecomFieldInfo.Fields) and not FieldIsEmpty(tfMobile, False) then
      Server.isPhoneNumberValid(dmServer.reformatPhoneNo(alForm[FTelecomFieldInfo.Names[tfMobile]].Value), @GSMNoCheckResult)
    else
      GSMNoCheckResult(True, '');
  end;

  procedure NationalNoCheckResult(aSuccess: Boolean; anError: string);
  begin
    canSave := canSave and aSuccess;
    if not aSuccess then
    begin
      if Trim(anError) = '' then
        anError := 'Invalid national number';
      DisplayError(FContactIDFieldInfo.Names[cifNidnr], '', anError);
    end;
    if (cdpTelecom in FEditParts) and (tfTelephone in FTelecomFieldInfo.Fields) and not FieldIsEmpty(tfTelephone, False) then
      Server.isPhoneNumberValid(dmServer.reformatPhoneNo(alForm[FTelecomFieldInfo.Names[tfTelephone]].Value), @PhoneNoCheckResult)
    else
      PhoneNoCheckResult(True, '');
  end;

var
  aBirthDate, aUTCBirthDate: TJSDate;
begin
  CheckedNIDNR := False;
  if ContactFieldsValid(True) then
  begin
    canSave := True;
    jQuery('.invalid-feedback').css('display', 'none');
    if (cdpID in FEditParts) and (cifNidnr in FContactIDFieldInfo.Fields) then
    begin
      if pfGender in FPersonalFieldInfo.Fields then
        aGender := getRadioGroupValue(FPersonalFieldInfo.Names[pfGender]);
      if pfDateOfBirth in FPersonalFieldInfo.Fields then
        aDate := alForm[FPersonalFieldInfo.Names[pfDateOfBirth]].Value;
      if not FieldIsEmpty(cifNidnr, False) then
      begin
        CheckedNIDNR := True;
        aNIDNR := Trim(alForm[FContactIDFieldInfo.Names[cifNidnr]].Value);
        aBirthDate := TJSDate.new(aDate);
        aUTCBirthDate := TJSDate.new(aBirthDate.UTCFullYear, aBirthDate.UTCMonth, aBirthDate.UTCDate);
        Server.isNationalNoValid(aNIDNR, aGender, aUTCBirthDate, @NationalNoCheckResult);
      end;
    end;
  end
  else
    console.log('Contact Fields not valid');

  if not CheckedNIDNR then
    NationalNoCheckResult(True, '');
end;

function TdmContacts.FieldIsEmpty(aField: TPersonalField; aShowError: Boolean): Boolean;
begin
  Result := FieldIsEmpty(FPersonalFieldInfo.Names[aField], aShowError, (aField = pfGender));
end;

function TdmContacts.FieldIsEmpty(aField: TContactIDField; aShowError: Boolean): Boolean;
begin
  Result := FieldIsEmpty(FContactIDFieldInfo.Names[aField], aShowError);
end;

function TdmContacts.FieldIsEmpty(aField: TDossierContactField; aShowError: Boolean): Boolean; overload;
var
  aRadioGroup: Boolean;
begin
  aRadioGroup := (aField in [dcfPersonType, dcfMarriageContract, dcfCivilStatus]) and (aField in FDossierContactFieldInfo.Fields);
  Result := FieldIsEmpty(FDossierContactFieldInfo.Names[aField], aShowError, aRadioGroup);
end;

function TdmContacts.FieldIsEmpty(aField: TTelecomField; aShowError: Boolean): Boolean; overload;
begin
  Result := FieldIsEmpty(FTelecomFieldInfo.Names[aField], aShowError);
end;

function TdmContacts.FieldIsEmpty(aEdits: TAddressFieldEdits; aField: TAddressField; aShowError: Boolean): Boolean; overload;
begin
  Result := FieldIsEmpty(aEdits.Names[aField], aShowError);
end;

function TdmContacts.FieldIsEmpty(aID: string; aShowError: Boolean; IsRadio: Boolean = False): Boolean;
begin
  if aID = '' then
    exit(True);
  Result := False;
  if IsRadio then
  begin
    Result := getRadioGroupValue(aID) = '';
    if aShowError then
      if Result then
        ShowValidStatus(alForm[aID].ID, False, True)
        // DisplayRGError(aID)
      else
        // ShowValidStatus(alForm[aID].ID,True,True);
        HideRGError(aID, []);
  end
  else
  begin
    if Trim(alForm[aID].Value) = '' then
    begin
      if aShowError then
        ShowValidStatus(alForm[aID].ID, False);
      // DisplayError();
      Result := True;
    end
    else
      if aShowError then
        ShowValidStatus(alForm[aID].ID, True);
    // HideError(alForm[aId].ID,[]);
  end;
end;

(*
 procedure TdmContact.SaveAdditionalInfo;
 begin
 // here info is saved only when the contact id is available
 if (cdpID in Parts) then
 begin
 if not (FieldIsEmpty('edtPartCardno', False)
 AND FieldIsEmpty('ddtPartCardValidFrom', False)
 AND FieldIsEmpty('ddtPartCardValidTill', False)
 AND FieldIsEmpty('edtPartNationalNumber', False)) then
 SaveCardInfo
 else
 begin
 if not ContactDatasetId.isEmpty then
 begin
 ContactDatasetId.Delete;
 ContactDatasetId.ApplyUpdates;
 end
 else
 SavePhones;
 end;
 end;
 end;
*)

procedure TdmContacts.SaveTelecomFields(aDataset: TDataSet; aEdit, aType: string);
begin
  if aDataset.State = dsInsert then
  begin
    aDataset.FieldByName('cttcreatedon').AsDatetime := now;
    aDataset.FieldByName('cttcreatedbyfk').AsLargeInt := Server.UserID;
  end;
  aDataset.FieldByName('cttchangedon').AsDatetime := now;
  aDataset.FieldByName('cttchangedbyfk').AsLargeInt := Server.UserID;
  aDataset.FieldByName('cttcontactfk').AsInteger := ContactID;
  aDataset.FieldByName('cttcontacttypefk').AsInteger := Server.GetParamID('CONTYP', aType);
  aDataset.FieldByName('cttcontacttype').AsString := aType;
  aDataset.FieldByName('cttdata').AsString := Trim(alForm[aEdit].Value);
  aDataset.FieldByName('cttremark').AsString := '';
  aDataset.FieldByName('cttcorrespondence').asBoolean := False;
end;

function TdmContacts.Locate(DataSet: TP2WDADataset; fieldName: string; Value: Int64): Boolean;
begin
  Result := False;
  if not DataSet.isEmpty then
  begin
    if DataSet.FieldByName(fieldName).AsLargeInt = Value then // some optimization cause I can be already on that record
      Result := True
    else
    begin
      DataSet.First;
      while not DataSet.Eof do
      begin
        if DataSet.FieldByName(fieldName).AsLargeInt = Value then
        begin
          Result := True;
          break;
        end;
        DataSet.Next;
      end;
    end;
  end;
end;

function TdmContacts.Locate(DataSet: TP2WDADataset; fieldName: string; Value: string): Boolean;
begin
  Result := False;
  if not DataSet.isEmpty then
  begin
    if DataSet.FieldByName(fieldName).AsString = Value then // some optimization cause I can be already on that record
      Result := True
    else
    begin
      DataSet.First;
      while not DataSet.Eof do
      begin
        if DataSet.FieldByName(fieldName).AsString = Value then
        begin
          Result := True;
          break;
        end;
        DataSet.Next;
      end;
    end;
  end;
end;

procedure TdmContacts.OnSelectEntityType(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if getRadioGroupValue('rdEntity') = 'C' then
    FEntityType := etCompany
  else
    FEntityType := etPerson;
  ConfigEntityType(ContactID <= 0);
end;

procedure TdmContacts.DoSaveEditsClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  FOKClicked := True;
  SaveIfValid;
end;

procedure TdmContacts.SaveTelecom;

  function DoSaveTelecom(aDataset: TP2WDADataset; aEdit, aType: string): Boolean;
  begin
    Log.Log(ltDebug, ClassName, 'SavePhones.DoSaveTelecom', 'Start %s: %s', [aEdit, aType]);
    Result := False;
    if not FieldIsEmpty(aEdit, False) then
    begin
      if Locate(aDataset, 'cttcontacttype', aType) then
        aDataset.edit
      else
        dsPhones.Append;
      SaveTelecomFields(aDataset, aEdit, aType);
      aDataset.Post;
      Result := True;
    end
    else
      if Locate(aDataset, 'cttcontacttype', aType) then
      begin
        aDataset.Delete;
        Result := True;
      end;
  end;

var
  hasChanges: Boolean;
  aDataset: TP2WDADataset;
begin
  aDataset := dsPhones;
  Log.Log(ltDebug, ClassName, 'SaveTelecom', 'Start');
  hasChanges := False;
  if cdpTelecom in FEditParts then
  begin
    if tfTelephone in FTelecomFieldInfo.Fields then
      hasChanges := DoSaveTelecom(aDataset, FTelecomFieldInfo.Names[tfTelephone], 'TEL') or hasChanges;
    if tfMobile in FTelecomFieldInfo.Fields then
      hasChanges := DoSaveTelecom(aDataset, FTelecomFieldInfo.Names[tfMobile], 'GSM') or hasChanges;
    if tfEmail in FTelecomFieldInfo.Fields then
      hasChanges := DoSaveTelecom(aDataset, FTelecomFieldInfo.Names[tfEmail], 'EMAIL') or hasChanges;
  end;
  if hasChanges then
    aDataset.ApplyUpdates
  else
    PartSavedEnd(cdpTelecom);
end;

procedure TdmContacts.SaveOfficialAddress;
begin
  Log.Log(ltDebug, ClassName, 'SaveOfficialAddress', 'Start');
  SaveAddressData(FWPAddressFieldInfo, dsWPAddress, 'WP', cdpWPAddress)
end;

procedure TdmContacts.SaveResidentialAddress;
begin
  Log.Log(ltDebug, ClassName, 'SaveResidentialAddress', 'Start');
  SaveAddressData(FVPAddressFieldInfo, dsVPaddress, 'VP', cdpVPAddress)
end;

procedure TdmContacts.SaveAddressData(aEdits: TAddressFieldEdits; aDataset: TDataSet; aType: string; aPart: TContactDataPart);
var
  edtStreet: string;
begin
  Log.Log(ltDebug, ClassName, 'SaveAddressData', 'Start');
  edtStreet := aEdits.Names[afStreet];
  if Trim(alForm[edtStreet].Value) = '' then
  begin
    if aDataset.isEmpty then
    begin
      PartSavedEnd(aPart);
      exit;
    end;
    aDataset.Delete;
  end
  else
  begin
    if aDataset.isEmpty then
      aDataset.Append
    else
      aDataset.edit;
    SaveAddressFields(aDataset, aType, aEdits);
    aDataset.Post;
  end;
  aDataset.ApplyUpdates;
//  PartSavedEnd(aPart);
end;

procedure TdmContacts.SavePhoto;

  procedure OnSaveFileComplete(const aResult, isCancelled: Boolean; fileID: Int64; refId: string);
  begin
    if aResult then
    begin
      FPhotoChanged := False;
      PartSavedEnd(cdpPicture);
    end
    else
    begin
      dmServer.ShowError(Format(SPhotoSaveFailed, [ContactDescription]));
      DoSaveError(cdpPicture);
    end;
  end;

begin
  Log.Log(ltDebug, ClassName, 'SavePhoto', 'Start');
  if FPhotoChanged then
    PictureDocumentBox.SaveFile(FPhoto, DossierID, ContactID, FPhotoID, 2, 'IDPICT', @OnSaveFileComplete)
  else
    PartSavedEnd(cdpPicture);
end;

procedure TdmContacts.ShowAddressFields(aDataset: TDataSet; aEdits: TAddressFieldEdits);
var
  aName: string;
  AF: TAddressField;
  Fld: TField;
  aAction: TElementAction;
begin
  for AF in TAddressField do
  begin
    Fld := aDataset.FieldByName(AddressFieldNames[AF]);
    if AF in aEdits.Fields then
    begin
      aName := aEdits.Names[AF];
      aAction := alForm[aName];
      DefaultShowField(aAction, Fld);
      if AF = afCityName then
        jQuery('#' + aName).attr('data-cid', Fld.AsString);
    end;
  end;
end;

procedure TdmContacts.dsVPAddressAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
    ShowAddressFields(DataSet, FVPAddressFieldInfo)
  else
    ClearAddressFields(FVPAddressFieldInfo);
  PartLoaded(cdpVPAddress, DataSet.isEmpty);
end;

procedure TdmContacts.ShowContactFields(aDataset: TDataSet);
var
  aName: string;
  PF: TPersonalField;
  aAction: TElementAction;
  Fld: TField;
begin
  for PF in TPersonalField do
  begin
    Fld := aDataset.FieldByName(ContactFieldNames[PF]);
    if PF in FPersonalFieldInfo.Fields then
    begin
      aName := FPersonalFieldInfo.Names[PF];
      aAction := alForm[aName];
      // Special cases
      case PF of
        pfGender:
          setRadiogroupSelectedElement(aName, Fld.AsString);
        pfBirthCityName:
          begin
            DefaultShowField(aAction, Fld);
            jQuery('#' + aName).attr('data-cid', Fld.AsString);
          end
      else
        DefaultShowField(aAction, Fld);
      end;
    end;
  end;
end;

procedure TdmContacts.ClearAddressFields(aEdits: TAddressFieldEdits);
var
  aName: string;
  AF: TAddressField;
  aAction: TElementAction;
begin
  for AF in TAddressField do
    if AF in aEdits.Fields then
    begin
      aName := aEdits.Names[AF];
      aAction := alForm[aName];
      aAction.Value := '';
      if AF = afCityName then
        jQuery('#' + aName).attr('data-cid', '');
    end;
end;

procedure TdmContacts.ClearContactData;
begin
  ClearParts(FShowParts);
end;

procedure TdmContacts.ClearContactFields;
var
  aName: string;
  PF: TPersonalField;
  aAction: TElementAction;
begin
  for PF in TPersonalField do
    if PF in FPersonalFieldInfo.Fields then
    begin
      aName := FPersonalFieldInfo.Names[PF];
      aAction := alForm[aName];
      // Special cases
      case PF of
        pfGender:
          setRadiogroupSelectedElement(aName, '');
        pfBirthCityName:
          begin
            aAction.Value := '';
            jQuery('#' + aName).attr('data-cid', '');
          end
      else
        aAction.Value := '';
      end;
    end;
end;

procedure TdmContacts.ClearDossierPersonFields;
var
  aName: string;
  DCF: TDossierContactField;
  aAction: TElementAction;
begin
  for DCF in TDossierContactField do
    if DCF in FDossierContactFieldInfo.Fields then
    begin
      aName := FDossierContactFieldInfo.Names[DCF];
      aAction := alForm[aName];
      case DCF of
        dcfMarriageContract:
          begin
            setRadiogroupSelectedElement(aName, '');
            FMarCon := '';
          end;
        dcfCivilStatus:
          begin
            setRadiogroupSelectedElement(aName, '');
            FCivilStatus := '';
          end;
        dcfPersonType:
          begin
            setRadiogroupSelectedElement(aName, '');
            FDossierPersonType := '';
          end;
        dcfSecondPetitioner:
          setRadiogroupSelectedElement(aName, '')
      else
        aAction.Value := '';
      end;
    end;
end;

procedure TdmContacts.ClearParts(aParts: TContactDataParts);
begin
  aParts := aParts * FShowParts;
  if (cdpID in aParts) then
    ClearContactIDFields;
  if ([cdpPersonal, cdpDossierPerson] * aParts) <> [] then
    ClearContactFields;
  if (cdpDossierPerson in aParts) then
    ClearDossierPersonFields;
  if cdpTelecom in aParts then
    ClearTelecomFields;
  if (cdpVPAddress in aParts) then
    ClearAddressFields(FVPAddressFieldInfo);
  if (cdpWPAddress in aParts) then
    ClearAddressFields(FWPAddressFieldInfo);
  if (cdpPicture in aParts) then
    ClearPictureFields;
end;

procedure TdmContacts.ClearPictureFields;
begin
  FPhotoID := 0;
  FPhotoChanged := False;
  jQuery('#' + FAvatarImageID).attr('src', UnknownProfileURL);
end;

procedure TdmContacts.ClearTelecomFields;
var
  aName: string;
  tf: TTelecomField;
begin
  for tf in TTelecomField do
    if tf in FTelecomFieldInfo.Fields then
    begin
      aName := FTelecomFieldInfo.Names[tf];
      alForm[aName].Value := '';
    end;
end;

procedure TdmContacts.dsDossierPersonAfterOpen(DataSet: TDataSet);
begin
  FContactID := -1;
  if DataSet.isEmpty then
  begin
    ClearContactFields;
    ClearAddressFields(FWPAddressFieldInfo);
    ClearDossierPersonFields;
    ConfigEntityType(True);
  end
  else
  begin
    FContactID := DataSet.FieldByName('doccontactfk').AsLargeInt;
    FContactAddressID := DataSet.FieldByName('ctaid').AsLargeInt;
    if (FDossierPersonType = '') then
      FDossierPersonType := DataSet.FieldByName('docpersontype').AsString;
    ShowContactFields(DataSet);
    ShowDossierPersonFields(DataSet);
    ShowAddressFields(DataSet, FWPAddressFieldInfo);
    LoadContactDetailData(FContactID);
  end;
  CheckNewContactButton;
  PartLoaded(cdpDossierPerson, DataSet.isEmpty);
  PartLoaded(cdpWPAddress, DataSet.isEmpty);
  PartLoaded(cdpPersonal, DataSet.isEmpty);
end;

procedure TdmContacts.dsContactPicAfterOpen(DataSet: TDataSet);

  procedure onGetUrlResult(aResult: Boolean; aURL: string);
  begin
    if aResult then
      jQuery('#' + FAvatarImageID).attr('src', aURL);
  end;

begin
  // if the picture is available then load the image
  if not DataSet.isEmpty then
  begin
    FPhotoID := DataSet.FieldByName('dofid').AsInteger;
    Server.GetFileUrl(FPhotoID, @onGetUrlResult);
  end;
  PartLoaded(cdpPicture, DataSet.isEmpty);
end;

procedure TdmContacts.dsDossierPersonAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  Exclude(FPartsToSave, cdpPersonal);
  Exclude(FPartsToSave, cdpWPAddress);
  PartSavedEnd(cdpDossierPerson, Info);
end;

procedure TdmContacts.dsPhonesAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  PartSavedEnd(cdpTelecom, Info);
end;

procedure TdmContacts.dsVPAddressAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  PartSavedEnd(cdpVPAddress, Info);
end;

procedure TdmContacts.dsWPAddressAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  PartSavedEnd(cdpWPAddress, Info);
end;

procedure TdmContacts.dsWPAddressAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
  begin
    ShowAddressFields(DataSet, FWPAddressFieldInfo);
    FContactAddressID := DataSet.FieldByName('ctaid').AsLargeInt;
  end;
  PartLoaded(cdpWPAddress, DataSet.isEmpty);
end;

procedure TdmContacts.ConfigureModalIDFields;
begin
  FContactIDFieldInfo.SetupModalFields;
  Include(FConfiguredParts, cdpID);
  dmServer.DoOnParamsLoaded(SetupIDFields);
end;

procedure TdmContacts.ConfigureModalTelecomFields;
begin
  FTelecomFieldInfo.SetupModalFields;
  Include(FConfiguredParts, cdpTelecom);
  dmServer.DoOnParamsLoaded(SetupTelecomFields);
end;

procedure TdmContacts.ConfigureModalWPAddressFields;
begin
  FWPAddressFieldInfo.SetupModalFields;
  Include(FConfiguredParts, cdpWPAddress);
  dmServer.DoOnParamsLoaded(
    procedure
    begin
      SetupAddressFields(FWPAddressFieldInfo);
    end);
end;

procedure TdmContacts.ConfigureModalContactFields;
begin
  FPersonalFieldInfo.SetupModalFields;
  Include(FConfiguredParts, cdpPersonal);
  dmServer.DoOnParamsLoaded(SetupPersonalFields);
end;

procedure TdmContacts.ConfigureModalEdits;
begin
  ConfigureModalContactFields;
  ConfigureModalIDFields;
  ConfigureModalTelecomFields;
  ConfigureModalWPAddressFields;
  // SetupPhoto('','','',aSource.dsContactPic);
end;

procedure TdmContacts.ShowEditDialog(aContactID: NativeInt);
begin
  FInEditDialog := True;
  bmEditContactData.Render;
  FormTranslator.TranslateBelow(bmEditContactData.Element, 'index');
  ConfigureModalEdits;
  DisableEditingExisting := False;
  LoadContactData(aContactID, [cdpPersonal, cdpID, cdpWPAddress, cdpTelecom]);
end;

procedure TdmContacts.ReloadData(aContactID: NativeInt);
begin
  FContactID := aContactID;
  LoadContactDetail(aContactID);
  LoadContactDetailData(aContactID);
  if (cdpDossierPerson in FShowParts) then
    LoadContactWPAddress(aContactID, True); // need to load separately
  (*
   Case ContactSource of
   csDossierPersonType :
   LoadDossierPerson(DossierID,DossierPersonType);
   csDossierPersonContact :
   LoadDossierPerson(DossierID,ContactID);
   End;
  *)
end;

var
  DlgCount: Integer;

procedure TdmContacts.EditContact(aContactID: NativeInt);
var
  dmEdit: TdmContacts;

  procedure OnEditDone(Sender: TObject);
  begin
    Log.Log(ltDebug, ClassName, name + ': EditContact.onEditDone', 'Edit done, reloading data ');
    if (aContactID > 0) and (aContactID <> dmEdit.ContactID) then
      Log.Log(ltError, ClassName, name + ': EditContact.onEditDone', 'Edit Contact ID (%d) differs from returned ID (%d)', [aContactID, dmEdit.ContactID]);
    if Assigned(dmEdit.OnContactChanged) and (aContactID <> dmEdit.ContactID) then
      dmEdit.OnContactChanged(self);
    ReloadData(dmEdit.ContactID);
    dmEdit.Free;

    DoAllSaved;
  end;

  procedure OnEditCancel(Sender: TObject);
  begin
    Log.Log(ltDebug, ClassName, name + ': EditContact.onEditDone', 'Edit cancelled, not reloading data ');
    dmEdit.Free;
  end;

var
  aName: string;
begin
  Inc(DlgCount);
  aName := 'dmContacts' + intToStr(DlgCount);
  dmEdit := TdmContacts.Create(self);

  dmEdit.AllowedContactType := AllowedContactType;
  dmEdit.PredefinedNIDNR := FPredefinedNIDNR;

  dmEdit.DossierConnection := self.DossierConnection;
  dmEdit.Name := aName;
  dmEdit.OnAllSaved := @OnEditDone;
  dmEdit.OnCancelEdit := @OnEditCancel;
  dmEdit.OnContactChanged := OnContactChanged;
  try
    dmEdit.ShowEditDialog(aContactID)
  except
    dmEdit.Free;
    raise;
  end;
end;

procedure TdmContacts.dsContactAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  PartSavedEnd(cdpPersonal, Info);
end;

procedure TdmContacts.ConfigEntityType(IsNew: Boolean);
var
  isCompany: Boolean;
  aName: string;
begin
  if FAllowedContactType = actPerson then
  begin
    FEntityType := etPerson;
    setRadiogroupSelectedElement('rdEntity', 'P');
  end
  else
    if FAllowedContactType = actOrganisation then
    begin
      FEntityType := etCompany;
      setRadiogroupSelectedElement('rdEntity', 'C');
    end;

  if not FInEditDialog then
    exit;
  isCompany := (FEntityType = etCompany);
  SetElementVisible(divEntityChooser, IsNew and (FAllowedContactType = actBoth));
  SetElementVisible(divEntityPerson, not isCompany);
  SetElementVisible(divEntityCompany, isCompany);
  if not IsNew then
    if isCompany then
      bmEditContactData.Actions[divModalTitle].Value := SEditCompanyContact
    else
      bmEditContactData.Actions[divModalTitle].Value := SEditPersonContact;

  FPersonalFieldInfo.SetupModalFields(FEntityType);
  dmServer.DoOnParamsLoaded(
    procedure
    begin
      SetupPersonalFields;
      if IsNew and (FPredefinedNIDNR <> '') then
      begin
        if Server.GetLocalData('csr-bdate') <> '' then
        begin
          aName := FPersonalFieldInfo.Names[pfDateOfBirth];
          alForm[aName].Value := Server.GetLocalData('csr-bdate');
        end;
        if Server.GetLocalData('csr-gender') <> '' then
        begin
          aName := FPersonalFieldInfo.Names[pfGender];
          setRadiogroupSelectedElement(aName, Server.GetLocalData('csr-gender'));
        end;
        aName := FContactIDFieldInfo.Names[cifNidnr];
        alForm[aName].Value := dmServer.reformatNationalNo(FPredefinedNIDNR, False);
      end;
    end);
end;

procedure TdmContacts.dsContactAfterOpen(DataSet: TDataSet);
begin
  if DataSet.isEmpty then
  begin
    Log.Log(ltDebug, ClassName, 'ContactDatasetAfterOpen', 'Empty dataset for contact ID %d', [FContactID]);
    FContactID := -1;
    ClearContactFields;
    CheckNewContactButton;
    FEntityType := etCompany;
    ConfigEntityType(True);
    exit;
  end;
  FContactID := DataSet.FieldByName('cntid').AsLargeInt;
  if DataSet.FieldByName('cntGender').AsString = 'C' then
    FEntityType := etCompany
  else
    FEntityType := etPerson;
  Log.Log(ltDebug, ClassName, 'ContactDatasetAfterOpen', 'Dataset for contact ID %d, entity type: %d', [FContactID, Ord(EntityType)]);
  ConfigEntityType(False);
  CheckNewContactButton;
  ShowContactFields(DataSet);
  PartLoaded(cdpPersonal, DataSet.isEmpty);
  // Don't load details, they were loaded simultaneously
  // LoadContactDetailData(FContactID);
end;

procedure TdmContacts.dsContactIdAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  PartSavedEnd(cdpID, Info);
end;

procedure TdmContacts.DefaultShowField(aAction: TElementAction; Fld: TField);
begin
  if aAction = nil then
    Log.Log(ltDebug, ClassName, 'DefaultShowField', 'Empty action for field %s value: %s', [Fld.fieldName, Fld.AsString])
  else
    Log.Log(ltDebug, ClassName, 'DefaultShowField', 'Filling %s with field %s value: %s', [aAction.Name, Fld.fieldName, Fld.AsString]);
  case Fld.DataType of
    ftDate, ftDateTime, ftTime:
      begin
        if not IsEmptyDate(Fld.AsDatetime) then
          aAction.Value := FormatHTMLDate(Fld.AsDatetime);
      end
  else
    aAction.Value := Fld.AsString
  end;
end;

procedure TdmContacts.DeleteDossierPerson(ClearContact: Boolean);
var
  aDataset: TP2WDADataset;
begin
  aDataset := dsDossierPerson;
  if not aDataset.isEmpty then
  begin
    aDataset.Delete;
    aDataset.ApplyUpdates;
  end;
  ClearContactData;
  FMarCon := '';
  FCivilStatus := '';
  if ClearContact then
  begin
    FContactID := 0;
    FContactAddressID := 0;
  end;
end;

procedure TdmContacts.ShowContactIDFields(aDataset: TDataSet);
var
  aName: string;
  cif: TContactIDField;
  Fld: TField;
  aAction: TElementAction;
begin
  for cif in TContactIDField do
  begin
    Fld := aDataset.FieldByName(IDFieldNames[cif]);
    if cif in FContactIDFieldInfo.Fields then
    begin
      aName := FContactIDFieldInfo.Names[cif];
      aAction := alForm[aName];
      case cif of
        cifNidnr:
          aAction.Value := dmServer.reformatNationalNo(Fld.AsString, False);
      else
        DefaultShowField(aAction, Fld);
      end;
    end;
  end;
end;

procedure TdmContacts.ShowDossierPersonFields(aDataset: TDataSet);
var
  aName: string;
  DCF: TDossierContactField;
  Fld: TField;
  aAction: TElementAction;
begin
  for DCF in TDossierContactField do
  begin
    Fld := aDataset.FieldByName(DossierContactFieldNames[DCF]);
    if DCF in FDossierContactFieldInfo.Fields then
    begin
      aName := FDossierContactFieldInfo.Names[DCF];
      aAction := alForm[aName];
      case DCF of
        dcfMarriageContract:
          begin
            setRadiogroupSelectedElement(aName, Fld.AsString);
            FMarCon := Fld.AsString;
          end;
        dcfCivilStatus:
          begin
            setRadiogroupSelectedElement(aName, Fld.AsString);
            FCivilStatus := Fld.AsString;
          end;
        dcfPersonType:
          begin
            setRadiogroupSelectedElement(aName, Fld.AsString);
            FCivilStatus := Fld.AsString;
          end;
        dcfSecondPetitioner:
          if Fld.asBoolean then
            setRadiogroupSelectedElement(aName, 'Y')
          else
            setRadiogroupSelectedElement(aName, 'N');
      else
        DefaultShowField(aAction, Fld);
      end;
    end;
  end;
end;

procedure TdmContacts.dsContactIdAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
    ShowContactIDFields(DataSet)
  else
    ClearContactIDFields;
  PartLoaded(cdpID, DataSet.isEmpty);
end;

procedure TdmContacts.ClearContactIDFields;
var
  aName: string;
  cif: TContactIDField;
begin
  for cif in TContactIDField do
    if cif in FContactIDFieldInfo.Fields then
    begin
      aName := FContactIDFieldInfo.Names[cif];
      if (FPredefinedNIDNR <> '') and (cif = cifNidnr) then
        continue;
      alForm[aName].Value := '';
    end;
end;

procedure TdmContacts.dsPhonesAfterOpen(DataSet: TDataSet);

  function DoCheckField(aType: string; aTelecom: TTelecomField): Boolean;
  begin
    Result := (DataSet.FieldByName('cttcontacttype').AsString = aType);
    if Result and (aTelecom in FTelecomFieldInfo.Fields) then
      alForm[FTelecomFieldInfo.Names[aTelecom]].Value := DataSet.FieldByName('cttdata').AsString;
  end;

  function MaybeClearField(aTelecom: TTelecomField): Boolean;
  begin
    Result := (aTelecom in FTelecomFieldInfo.Fields);
    if Result then
      alForm[FTelecomFieldInfo.Names[aTelecom]].Value := '';
  end;

begin
  MaybeClearField(tfTelephone);
  MaybeClearField(tfMobile);
  MaybeClearField(tfEmail);
  if not DataSet.isEmpty then
  begin
    while not DataSet.Eof do
    begin
      if not DoCheckField('TEL', tfTelephone) then
        if not DoCheckField('GSM', tfMobile) then
          DoCheckField('EMAIL', tfEmail);
      DataSet.Next;
    end;
  end;
  PartLoaded(cdpTelecom, DataSet.isEmpty);
end;

function TdmContacts.GetFieldValue(aField: TPersonalField): string;
begin
  if aField in FPersonalFieldInfo.Fields then
    Result := alForm[FPersonalFieldInfo.Names[aField]].Value
  else
    Result := '';
end;

function TdmContacts.GetPartPrefix(aPart: TContactDataPart): string;
begin
  case aPart of
    cdpPersonal:
      Result := 'persoonsgegevens';
    cdpDossierPerson:
      Result := 'persoonsgegevens';
    cdpTelecom:
      Result := 'telecom gegevens';
    cdpWPAddress:
      Result := 'officieel adres gegevens';
    cdpVPAddress:
      Result := 'verblijfsadres gegevens';
    cdpPicture:
      Result := 'foto';
    cdpID:
      Result := 'identiteitskaart gegevens';
  end;
  if FContactDescription <> '' then
    Result := FContactDescription + ' ' + Result;
end;

procedure TdmContacts.PartLoaded(aPart: TContactDataPart; PartIsEmpty: Boolean);
var
  WasNotLoaded: Boolean;
begin
  {
   We only need to trigger if we cross the edge of not loaded -> loaded.
   PartLoaded can also be called later when additional data is loaded e.g. after selecting a contact.
  }
  WasNotLoaded := (FPartsToLoad <> []);
  Exclude(FPartsToLoad, aPart);
  if Assigned(FOnPartLoaded) then
    FOnPartLoaded(self, aPart);
  if WasNotLoaded and (FPartsToLoad = []) then
    DoAllLoaded;
end;

procedure TdmContacts.DisenablePart(aPart: TContactDataPart; Disable: Boolean);
begin
  case aPart of
    cdpPersonal:
      DisEnablePersonalFields(Disable);
    cdpDossierPerson:
      DisEnableDossierPersonFields(Disable);
    cdpWPAddress:
      DisEnableAddressFields(Disable, FWPAddressFieldInfo);
    cdpVPAddress:
      DisEnableAddressFields(Disable, FVPAddressFieldInfo);
    cdpID:
      DisEnableContactIDFields(Disable);
    cdpPicture:
      DisEnablePictureFields(Disable);
    cdpTelecom:
      DisEnableTelecomFields(Disable);
  end;
end;

procedure TdmContacts.DisEnablePersonalFields(Disable: Boolean);
const
  RGFields = [pfGender];
var
  F: TPersonalField;
begin
  for F in TPersonalField do
    if F in FPersonalFieldInfo.Fields then
      SetElementEnabled(FPersonalFieldInfo.Names[F], not Disable, (F in RGFields));
end;

procedure TdmContacts.DisEnableDossierPersonFields(Disable: Boolean);
const
  RGFields = [dcfMarriageContract, dcfCivilStatus, dcfPersonType, dcfSecondPetitioner];
var
  F: TDossierContactField;
begin
  for F in TDossierContactField do
    if F in FDossierContactFieldInfo.Fields then
      SetElementEnabled(FDossierContactFieldInfo.Names[F], not Disable, (F in RGFields));
end;

procedure TdmContacts.DisEnableAddressFields(Disable: Boolean; aEdits: TAddressFieldEdits);
var
  F: TAddressField;
begin
  for F in TAddressField do
    if F in aEdits.Fields then
      SetElementEnabled(aEdits.Names[F], not Disable);
end;

procedure TdmContacts.DisEnableContactIDFields(Disable: Boolean);
var
  F: TContactIDField;
begin
  for F in TContactIDField do
    if F in FContactIDFieldInfo.Fields then
      SetElementEnabled(FContactIDFieldInfo.Names[F], not Disable);
end;

procedure TdmContacts.DisEnableTelecomFields(Disable: Boolean);
var
  F: TTelecomField;
begin
  for F in TTelecomField do
    if F in FTelecomFieldInfo.Fields then
      SetElementEnabled(FTelecomFieldInfo.Names[F], not Disable);
end;

procedure TdmContacts.DisEnablePictureFields(Disable: Boolean);
begin
  if FAvatarFileID <> '' then
    SetElementEnabled(FAvatarFileID, not Disable);
end;

procedure TdmContacts.DoAllLoaded;
begin
  if Assigned(FOnAllLoaded) then
    FOnAllLoaded(self);
end;

procedure TdmContacts.PartSavedEnd(aPart: TContactDataPart);
begin
  Log.Log(ltDebug, ClassName, 'PartSavedEnd', 'Part %s ended', [aPart.AsString]);
  Exclude(FPartsToSave, aPart);
  if aPart = cdpDossierPerson then
  begin
    FContactID := dsDossierPerson.FieldByName('docContactFK').AsLargeInt;
    FContactAddressID := dsDossierPerson.FieldByName('ctaid').AsLargeInt;
  end;
  if aPart = cdpPersonal then
    FContactID := dsContact.FieldByName('cntid').AsLargeInt;
  CheckNextPartSave;
end;

procedure TdmContacts.PartSavedEnd(aPart: TContactDataPart; Info: TResolveResults);
var
  aPrefix: string;
begin
  Log.Log(ltDebug, ClassName, 'PartSavedEnd (+Info)', 'Part %s ended : %s', [aPart.AsString, Info.AsString]);
  aPrefix := GetPartPrefix(aPart);
  if dmServer.CheckResolveResults(Info, aPrefix) then
    PartSavedEnd(aPart)
  else
    DoSaveError(aPart);
end;

procedure TdmContacts.OnGetCountryData(DataSet: TDataSet);
var
  S, aName: string;
  FID, FISO2, FldName: TField;
  aDataset: TP2WDADataset;
begin
  S := '';
  with DataSet do
  begin
    FID := DataSet.FieldByName('conID');
    FISO2 := DataSet.FieldByName('conISO2');
    FldName := DataSet.FieldByName('conName');
    while not Eof do
    begin
      S := S + Format('<option data-id="%s" value="%s">%s</option>', [FID.AsString, FISO2.AsString, FldName.AsString]);
      DataSet.Next;
    end;
  end;
  if (pfNationality in FPersonalFieldInfo.Fields) then
  begin
    aName := FPersonalFieldInfo.Names[pfNationality];
    alForm[aName].innerHTML := S;
    if cdpDossierPerson in FShowParts then
      aDataset := dsDossierPerson
    else
      aDataset := dsContact;
    with aDataset do
    begin
      if not((State = dsInactive) or isEmpty) then
        alForm[aName].Value := FieldByName('cntnationality2').AsString;
    end;
  end;
end;

function TdmContacts.InvalidPersonalFields(ShowErrors: Boolean): TPersonalFields;

  procedure TestField(aField: TPersonalField);
  begin
    if aField in FPersonalFieldInfo.Fields then
      if FieldIsEmpty(aField, ShowErrors) then
        Include(Result, aField);
  end;

var
  aDate: string;
  DP: TDateParts;
  aName, Err: string;
begin
  Result := [];
  TestField(pfLastName);
  if not FPersonalFieldInfo.FirstNameNotRequired then
    TestField(pfFirstName);
  if (FEntityType <> etCompany) then
  begin
    TestField(pfGender);
    if pfDateOfBirth in FPersonalFieldInfo.Fields then
    begin
      aName := FPersonalFieldInfo.Names[pfDateOfBirth];
      aDate := alForm[aName].Value;
      if aDate <> '' then
      begin
        DP := GetDateParts(aDate);
        Err := '';

        if not IsValidDate(DP) then
          Err := Format(SErrInvalidDate, [aDate])
        else
          if EncodeDate(DP.Y, DP.M, DP.D) > Date then
            Err := Format(SErrDateInFuture, [aDate]);

        if Err <> '' then
        begin
          Include(Result, pfDateOfBirth);
          DisplayError(alForm[aName].ID, '', Err, True)
        end;
      end;
    end;

    {TestField(pfDateOfBirth);
     TestField(pfBirthCityZip);
     TestField(pfBirthCityName);
     TestField(pfNationality);
     TestField(pfProfession);}
  end;
end;

function TdmContacts.InvalidDossierContactFields(ShowErrors: Boolean): TDossierContactFields;

  procedure TestField(aField: TDossierContactField);
  begin
    if aField in FDossierContactFieldInfo.Fields then
      if FieldIsEmpty(aField, ShowErrors) then
        Include(Result, aField);
  end;

begin
  Result := [];
  if DossierPersonType = '' then
  begin
    TestField(dcfPersonType);
    {TestField(pfDateOfBirth);
     TestField(pfBirthCityZip);
     TestField(pfBirthCityName);
     TestField(pfNationality);
     TestField(pfProfession);}
  end;
end;

function TdmContacts.InvalidAddressFields(aEdits: TAddressFieldEdits; ShowErrors: Boolean): TAddressFields;

  procedure TestField(aField: TAddressField);
  begin
    if aField in aEdits.Fields then
      if FieldIsEmpty(aEdits, aField, ShowErrors) then
        Include(Result, aField);
  end;

begin
  Result := [];
  TestField(afStreet);
  TestField(afHouseNr);
  TestField(afCityZip);
  TestField(afCityName);
end;

function TdmContacts.InvalidTelecomFields(ShowErrors: Boolean): TAddressFields;

  procedure TestField(aField: TTelecomField);
  begin
    if (aField in FTelecomFieldInfo.Fields) then
      if aField in FTelecomFieldInfo.RequiredFields then
        if FieldIsEmpty(aField, ShowErrors) then
          Include(Result, aField);
  end;

begin
  Result := [];
  TestField(tfTelephone);
  TestField(tfMobile);
  TestField(tfEmail);
end;

function TdmContacts.InvalidIDFields(ShowErrors: Boolean): TContactIDFields;

  procedure TestField(aField: TContactIDField);
  begin
    if aField in FContactIDFieldInfo.Fields then
      if FieldIsEmpty(aField, ShowErrors) then
        Include(Result, aField);
  end;

var
  Date1, Date2: TDatetime;
  DatesOK: Boolean;
begin
  Result := [];
  if FieldIsEmpty(FContactIDFieldInfo.Names[cifNidnr], False) and not IDRequired then
    exit;
  TestField(cifNidnr);
//  TestField(cifCardType);
//  TestField(cifCardNo);
  if (cifValidFrom in FContactIDFieldInfo.Fields) then
  begin
    if not(FieldIsEmpty(cifValidFrom, False) and FieldIsEmpty(cifValidTo, False)) then
    begin
      Date1 := ExtractDate(alForm[FContactIDFieldInfo.Names[cifValidFrom]].Value);
      Date2 := ExtractDate(alForm[FContactIDFieldInfo.Names[cifValidTo]].Value);
      DatesOK := Date1 < Date2;
      if not DatesOK then
      begin
        if ShowErrors then
          DisplayError(FContactIDFieldInfo.Names[cifValidTo], '', SErrTillAfterFrom);
        Include(Result, cifValidFrom);
      end;
    end;
  end;
end;

function TdmContacts.ContactFieldsValid(ShowErrors: Boolean): Boolean;
var
  edtStreet: string;
begin
  Result := True;
  if (cdpDossierPerson in EditParts) and (InvalidDossierContactFields(ShowErrors) <> []) then
    exit(False);
  if (cdpPersonal in EditParts) and (InvalidPersonalFields(ShowErrors) <> []) then
    exit(False);
  if (cdpWPAddress in EditParts) then
  begin
    edtStreet := FWPAddressFieldInfo.Names[afStreet];
    if Trim(alForm[edtStreet].Value) <> '' then
      if (InvalidAddressFields(FWPAddressFieldInfo, ShowErrors) <> []) then
        exit(False);
  end;
  if (cdpVPAddress in EditParts) then
  begin
    edtStreet := FVPAddressFieldInfo.Names[afStreet];
    if Trim(alForm[edtStreet].Value) <> '' then
      if (InvalidAddressFields(FVPAddressFieldInfo, ShowErrors) <> []) then
        exit(False);
  end;
  if (cdpTelecom in EditParts) and (InvalidTelecomFields(ShowErrors) <> []) then
    exit(False);
  if (cdpID in EditParts) and (InvalidIDFields(ShowErrors) <> []) then
    exit(False);

  // Result := not FieldIsEmpty('edtCardType') and Result;
end;

function TdmContacts.IsPersonalEmpty: Boolean;
var
  PF: TPersonalField;
begin
  Result := True;
  for PF in TPersonalField do
    if PF in FPersonalFieldInfo.Fields then
      Result := Result and FieldIsEmpty(PF, False);
end;

function TdmContacts.IsContactIDEmpty: Boolean;
var
  cif: TContactIDField;
begin
  Result := True;
  for cif in TContactIDField do
    if cif in FContactIDFieldInfo.Fields then
      Result := Result and FieldIsEmpty(cif, False);
end;

function TdmContacts.IsDossierPersonEmpty: Boolean;
var
  CF: TDossierContactField;
begin
  Result := True;
  for CF in TDossierContactField do
    if CF in FDossierContactFieldInfo.Fields then
      Result := Result and FieldIsEmpty(CF, False);
end;

function TdmContacts.IsAddressEmpty(aEdits: TAddressFieldEdits): Boolean;
var
  AF: TAddressField;
begin
  Result := True;
  for AF in TAddressField do
    if AF in aEdits.Fields then
      Result := Result and FieldIsEmpty(aEdits, AF, False);
end;

function TdmContacts.IsTelecomEmpty: Boolean;
var
  tf: TTelecomField;
begin
  Result := True;
  for tf in TTelecomField do
    if tf in FTelecomFieldInfo.Fields then
      Result := Result and FieldIsEmpty(tf, False);
end;

function TdmContacts.IsPartEmpty(aPart: TContactDataPart): Boolean;
begin
  case aPart of
    cdpPersonal:
      Result := IsPersonalEmpty;
    cdpDossierPerson:
      Result := IsDossierPersonEmpty;
    cdpWPAddress:
      Result := IsAddressEmpty(FWPAddressFieldInfo);
    cdpVPAddress:
      Result := IsAddressEmpty(FVPAddressFieldInfo);
    cdpID:
      Result := IsContactIDEmpty;
    cdpTelecom:
      Result := IsTelecomEmpty;
  end;
end;

procedure TdmContacts.LoadDFMValues;
begin
  inherited LoadDFMValues;

  bmSearchContact := TP2WBSModal.Create(Self);
  alForm := TElementActionList.Create(Self);
  dsContactId := TP2WDADataset.Create(Self);
  dsPhones := TP2WDADataset.Create(Self);
  dsWPAddress := TP2WDADataset.Create(Self);
  dsContactPic := TP2WDADataset.Create(Self);
  dsContact := TP2WDADataset.Create(Self);
  dsContactcntid := TLargeintField.Create(Self);
  dsContactcntcreatedon := TDateTimeField.Create(Self);
  dsContactcntcreatedbyfk := TLargeintField.Create(Self);
  dsContactcntchangedon := TDateTimeField.Create(Self);
  dsContactcntchangedbyfk := TLargeintField.Create(Self);
  dsContactcntcompanyfk := TLargeintField.Create(Self);
  dsContactcntfirstname := TStringField.Create(Self);
  dsContactcntlastname := TStringField.Create(Self);
  dsContactcntprofession := TStringField.Create(Self);
  dsContactcntbirthdateon := TDateTimeField.Create(Self);
  dsContactcntisbirthdateunknown := TBooleanField.Create(Self);
  dsContactcntgender := TStringField.Create(Self);
  dsContactcntcityofbirthfk := TLargeintField.Create(Self);
  dsContactcntcityofbirthname := TStringField.Create(Self);
  dsContactcntcityofbirthzip := TStringField.Create(Self);
  dsContactcntnationalityfk := TLargeintField.Create(Self);
  dsContactcntnationality2 := TStringField.Create(Self);
  dsContactcntnationality := TStringField.Create(Self);
  dsContactcntpicturefk := TLargeintField.Create(Self);
  dsContactcntkbonr := TStringField.Create(Self);
  dsContactcntremark := TStringField.Create(Self);
  dsContactcntpersonfk := TLargeintField.Create(Self);
  dsContactcntsalutation := TStringField.Create(Self);
  dsContactcntsearchname := TStringField.Create(Self);
  dsContactcntprefixes := TStringField.Create(Self);
  dsContactcntfriendlytitle := TStringField.Create(Self);
  dsDossierPerson := TP2WDADataset.Create(Self);
  dsVPaddress := TP2WDADataset.Create(Self);
  bmEditContactData := TP2WBSModal.Create(Self);

  bmSearchContact.BeforeLoadDFMValues;
  alForm.BeforeLoadDFMValues;
  dsContactId.BeforeLoadDFMValues;
  dsPhones.BeforeLoadDFMValues;
  dsWPAddress.BeforeLoadDFMValues;
  dsContactPic.BeforeLoadDFMValues;
  dsContact.BeforeLoadDFMValues;
  dsContactcntid.BeforeLoadDFMValues;
  dsContactcntcreatedon.BeforeLoadDFMValues;
  dsContactcntcreatedbyfk.BeforeLoadDFMValues;
  dsContactcntchangedon.BeforeLoadDFMValues;
  dsContactcntchangedbyfk.BeforeLoadDFMValues;
  dsContactcntcompanyfk.BeforeLoadDFMValues;
  dsContactcntfirstname.BeforeLoadDFMValues;
  dsContactcntlastname.BeforeLoadDFMValues;
  dsContactcntprofession.BeforeLoadDFMValues;
  dsContactcntbirthdateon.BeforeLoadDFMValues;
  dsContactcntisbirthdateunknown.BeforeLoadDFMValues;
  dsContactcntgender.BeforeLoadDFMValues;
  dsContactcntcityofbirthfk.BeforeLoadDFMValues;
  dsContactcntcityofbirthname.BeforeLoadDFMValues;
  dsContactcntcityofbirthzip.BeforeLoadDFMValues;
  dsContactcntnationalityfk.BeforeLoadDFMValues;
  dsContactcntnationality2.BeforeLoadDFMValues;
  dsContactcntnationality.BeforeLoadDFMValues;
  dsContactcntpicturefk.BeforeLoadDFMValues;
  dsContactcntkbonr.BeforeLoadDFMValues;
  dsContactcntremark.BeforeLoadDFMValues;
  dsContactcntpersonfk.BeforeLoadDFMValues;
  dsContactcntsalutation.BeforeLoadDFMValues;
  dsContactcntsearchname.BeforeLoadDFMValues;
  dsContactcntprefixes.BeforeLoadDFMValues;
  dsContactcntfriendlytitle.BeforeLoadDFMValues;
  dsDossierPerson.BeforeLoadDFMValues;
  dsVPaddress.BeforeLoadDFMValues;
  bmEditContactData.BeforeLoadDFMValues;
  try
    Name := 'dmContacts';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 406;
    Width := 665;
    bmSearchContact.SetParentComponent(Self);
    bmSearchContact.Name := 'bmSearchContact';
    bmSearchContact.Actions.Actions.Clear;
    with bmSearchContact.Actions.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtSearchContact';
      Name := 'edtSearchContact';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      ID := 'btnSearchContact';
      Name := 'btnSearchContact';
      SetEvent(Self, 'OnExecute', 'DoSearchContactsClick');
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      Event := heNone;
      ID := 'searchContactResults';
      Name := 'searchContactResults';
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      ID := 'searchContactResultData';
      Name := 'searchContactResultData';
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      ID := 'btnCancelContact';
      Name := 'btnCancelContact';
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      ID := 'btnSelectContact';
      Name := 'btnSelectContact';
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      Event := heDblClick;
      ID := '';
      Name := 'searchResultRowDoubleClick';
      PreventDefault := False;
      Selector := '#searchContactResults tr[role="row"]';
      SetEvent(Self, 'OnExecute', 'DoResultRowDoubleClick');
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      ID := '';
      Name := 'searchResultRowClick';
      PreventDefault := False;
      Selector := '#searchContactResults tr[role="row"]';
      SetEvent(Self, 'OnExecute', 'DoResultRowSingleClick');
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      ID := '';
      Name := 'ShowContactDetails';
      PreventDefault := False;
      Selector := '#searchContactResults a[role="contactAddress"]';
      SetEvent(Self, 'OnExecute', 'DoShowContactDetails');
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      Event := heNone;
      ID := 'lblSearchContactTerm';
      Name := 'lblSearchContactTerm';
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      Event := heKeyup;
      ID := 'edtSearchContact';
      Name := 'edtSearchContactKeyUp';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'DoContactSearchKeyUp');
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      Event := heKeyup;
      ID := 'searchContactModal';
      Name := 'mdlSearchContact';
      SetEvent(Self, 'OnExecute', 'DoKeyUp');
    end;
    with bmSearchContact.Actions.Actions.Add do
    begin
      ID := 'btnSearchNewContact';
      Name := 'btnSearchNewContact';
      SetEvent(Self, 'OnExecute', 'DoSearchNewContact');
    end;
    bmSearchContact.ParentID := 'modals';
    bmSearchContact.ShowOnRender := True;
    bmSearchContact.BackDrop := True;
    bmSearchContact.Focus := True;
    bmSearchContact.OKButtonName := 'btnSelectContact';
    bmSearchContact.CancelButtonName := 'btnCancelContact';
    bmSearchContact.TemplateName := 'searchcontact';
    SetEvent(bmSearchContact, Self, 'OnHide', 'bmSearchContactHide');
    bmSearchContact.Left := 96;
    bmSearchContact.Top := 56;
    alForm.SetParentComponent(Self);
    alForm.Name := 'alForm';
    alForm.Left := 216;
    alForm.Top := 56;
    dsContactId.SetParentComponent(Self);
    dsContactId.Name := 'dsContactId';
    dsContactId.TableName := 'contactid';
    dsContactId.Params.Clear;
    with dsContactId.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsContactId.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>cticontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></where></query>';
    dsContactId.DAOptions := [doRefreshAllFields];
    dsContactId.AfterOpen := dsContactIdAfterOpen;
    dsContactId.AfterApplyUpdates := dsContactIdAfterApplyUpdates;
    dsContactId.Left := 96;
    dsContactId.Top := 136;
    dsPhones.SetParentComponent(Self);
    dsPhones.Name := 'dsPhones';
    dsPhones.TableName := 'contacttelecom';
    dsPhones.Params.Clear;
    with dsPhones.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsPhones.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>cttcontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></where></query>';
    dsPhones.DAOptions := [doRefreshAllFields];
    dsPhones.AfterOpen := dsPhonesAfterOpen;
    dsPhones.AfterApplyUpdates := dsPhonesAfterApplyUpdates;
    dsPhones.Left := 96;
    dsPhones.Top := 200;
    dsWPAddress.SetParentComponent(Self);
    dsWPAddress.Name := 'dsWPAddress';
    dsWPAddress.TableName := 'contactaddress';
    dsWPAddress.Params.Clear;
    with dsWPAddress.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsWPAddress.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>ctacontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation><binaryoperation operator="Equal"><field>ctaaddresstype</field><constant type="String">WP</constant></binaryoperation></binaryoperation></where></query>';
    dsWPAddress.DAOptions := [doRefreshAllFields];
    dsWPAddress.AfterOpen := dsWPAddressAfterOpen;
    dsWPAddress.AfterApplyUpdates := dsWPAddressAfterApplyUpdates;
    dsWPAddress.Left := 96;
    dsWPAddress.Top := 264;
    dsContactPic.SetParentComponent(Self);
    dsContactPic.Name := 'dsContactPic';
    dsContactPic.TableName := 'dossierfile';
    dsContactPic.Params.Clear;
    with dsContactPic.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsContactPic.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsContactPic.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">IDPICT</constant></binaryoperation></binaryoperation></where></query>';
    dsContactPic.DAOptions := [doRefreshAllFields];
    dsContactPic.AfterOpen := dsContactPicAfterOpen;
    dsContactPic.Left := 288;
    dsContactPic.Top := 136;
    dsContact.SetParentComponent(Self);
    dsContact.Name := 'dsContact';
    dsContact.TableName := 'ContactOverview';
    dsContact.Params.Clear;
    with dsContact.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsContact.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>cntid</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></where></query>';
    dsContact.DAOptions := [];
    dsContact.AfterOpen := dsContactAfterOpen;
    dsContact.AfterApplyUpdates := dsContactAfterApplyUpdates;
    dsContact.Left := 288;
    dsContact.Top := 192;
    dsContactcntid.SetParentComponent(dsContact);
    dsContactcntid.Name := 'dsContactcntid';
    dsContactcntid.FieldName := 'cntid';
    dsContactcntcreatedon.SetParentComponent(dsContact);
    dsContactcntcreatedon.Name := 'dsContactcntcreatedon';
    dsContactcntcreatedon.FieldName := 'cntcreatedon';
    dsContactcntcreatedbyfk.SetParentComponent(dsContact);
    dsContactcntcreatedbyfk.Name := 'dsContactcntcreatedbyfk';
    dsContactcntcreatedbyfk.FieldName := 'cntcreatedbyfk';
    dsContactcntchangedon.SetParentComponent(dsContact);
    dsContactcntchangedon.Name := 'dsContactcntchangedon';
    dsContactcntchangedon.FieldName := 'cntchangedon';
    dsContactcntchangedbyfk.SetParentComponent(dsContact);
    dsContactcntchangedbyfk.Name := 'dsContactcntchangedbyfk';
    dsContactcntchangedbyfk.FieldName := 'cntchangedbyfk';
    dsContactcntcompanyfk.SetParentComponent(dsContact);
    dsContactcntcompanyfk.Name := 'dsContactcntcompanyfk';
    dsContactcntcompanyfk.FieldName := 'cntcompanyfk';
    dsContactcntfirstname.SetParentComponent(dsContact);
    dsContactcntfirstname.Name := 'dsContactcntfirstname';
    dsContactcntfirstname.FieldName := 'cntfirstname';
    dsContactcntfirstname.Size := 64;
    dsContactcntlastname.SetParentComponent(dsContact);
    dsContactcntlastname.Name := 'dsContactcntlastname';
    dsContactcntlastname.FieldName := 'cntlastname';
    dsContactcntlastname.Size := 127;
    dsContactcntprofession.SetParentComponent(dsContact);
    dsContactcntprofession.Name := 'dsContactcntprofession';
    dsContactcntprofession.FieldName := 'cntprofession';
    dsContactcntprofession.Size := 64;
    dsContactcntbirthdateon.SetParentComponent(dsContact);
    dsContactcntbirthdateon.Name := 'dsContactcntbirthdateon';
    dsContactcntbirthdateon.FieldName := 'cntbirthdateon';
    dsContactcntisbirthdateunknown.SetParentComponent(dsContact);
    dsContactcntisbirthdateunknown.Name := 'dsContactcntisbirthdateunknown';
    dsContactcntisbirthdateunknown.FieldName := 'cntisbirthdateunknown';
    dsContactcntgender.SetParentComponent(dsContact);
    dsContactcntgender.Name := 'dsContactcntgender';
    dsContactcntgender.FieldName := 'cntgender';
    dsContactcntgender.FixedChar := True;
    dsContactcntgender.Size := 1;
    dsContactcntcityofbirthfk.SetParentComponent(dsContact);
    dsContactcntcityofbirthfk.Name := 'dsContactcntcityofbirthfk';
    dsContactcntcityofbirthfk.FieldName := 'cntcityofbirthfk';
    dsContactcntcityofbirthname.SetParentComponent(dsContact);
    dsContactcntcityofbirthname.Name := 'dsContactcntcityofbirthname';
    dsContactcntcityofbirthname.FieldName := 'cntcityofbirthname';
    dsContactcntcityofbirthname.Size := 100;
    dsContactcntcityofbirthzip.SetParentComponent(dsContact);
    dsContactcntcityofbirthzip.Name := 'dsContactcntcityofbirthzip';
    dsContactcntcityofbirthzip.FieldName := 'cntcityofbirthzip';
    dsContactcntcityofbirthzip.Size := 15;
    dsContactcntnationalityfk.SetParentComponent(dsContact);
    dsContactcntnationalityfk.Name := 'dsContactcntnationalityfk';
    dsContactcntnationalityfk.FieldName := 'cntnationalityfk';
    dsContactcntnationality2.SetParentComponent(dsContact);
    dsContactcntnationality2.Name := 'dsContactcntnationality2';
    dsContactcntnationality2.FieldName := 'cntnationality2';
    dsContactcntnationality2.Size := 2;
    dsContactcntnationality.SetParentComponent(dsContact);
    dsContactcntnationality.Name := 'dsContactcntnationality';
    dsContactcntnationality.FieldName := 'cntnationality';
    dsContactcntnationality.Size := 40;
    dsContactcntpicturefk.SetParentComponent(dsContact);
    dsContactcntpicturefk.Name := 'dsContactcntpicturefk';
    dsContactcntpicturefk.FieldName := 'cntpicturefk';
    dsContactcntkbonr.SetParentComponent(dsContact);
    dsContactcntkbonr.Name := 'dsContactcntkbonr';
    dsContactcntkbonr.FieldName := 'cntkbonr';
    dsContactcntkbonr.Size := 10;
    dsContactcntremark.SetParentComponent(dsContact);
    dsContactcntremark.Name := 'dsContactcntremark';
    dsContactcntremark.FieldName := 'cntremark';
    dsContactcntremark.Size := 255;
    dsContactcntpersonfk.SetParentComponent(dsContact);
    dsContactcntpersonfk.Name := 'dsContactcntpersonfk';
    dsContactcntpersonfk.FieldName := 'cntpersonfk';
    dsContactcntsalutation.SetParentComponent(dsContact);
    dsContactcntsalutation.Name := 'dsContactcntsalutation';
    dsContactcntsalutation.FieldName := 'cntsalutation';
    dsContactcntsalutation.Size := 32;
    dsContactcntsearchname.SetParentComponent(dsContact);
    dsContactcntsearchname.Name := 'dsContactcntsearchname';
    dsContactcntsearchname.FieldName := 'cntsearchname';
    dsContactcntsearchname.Size := 50;
    dsContactcntprefixes.SetParentComponent(dsContact);
    dsContactcntprefixes.Name := 'dsContactcntprefixes';
    dsContactcntprefixes.FieldName := 'cntprefixes';
    dsContactcntprefixes.Size := 32;
    dsContactcntfriendlytitle.SetParentComponent(dsContact);
    dsContactcntfriendlytitle.Name := 'dsContactcntfriendlytitle';
    dsContactcntfriendlytitle.FieldName := 'cntfriendlytitle';
    dsContactcntfriendlytitle.Size := 32;
    dsDossierPerson.SetParentComponent(Self);
    dsDossierPerson.Name := 'dsDossierPerson';
    dsDossierPerson.TableName := 'DossierPersons';
    dsDossierPerson.Params.Clear;
    with dsDossierPerson.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDossierPerson.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dosid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>docpersontype</field><constant type="String">PART</constant></binaryoperation></binaryoperation></where></query>';
    dsDossierPerson.DAOptions := [doRefreshAllFields];
    dsDossierPerson.AfterOpen := dsDossierPersonAfterOpen;
    dsDossierPerson.AfterApplyUpdates := dsDossierPersonAfterApplyUpdates;
    dsDossierPerson.Left := 448;
    dsDossierPerson.Top := 136;
    dsVPaddress.SetParentComponent(Self);
    dsVPaddress.Name := 'dsVPaddress';
    dsVPaddress.TableName := 'contactaddress';
    dsVPaddress.Params.Clear;
    with dsVPaddress.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsVPaddress.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>ctacontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation><binaryoperation operator="Equal"><field>ctaaddresstype</field><constant type="String">VP</constant></binaryoperation></binaryoperation></where></query>';
    dsVPaddress.DAOptions := [doRefreshAllFields];
    dsVPaddress.AfterOpen := dsVPAddressAfterOpen;
    dsVPaddress.AfterApplyUpdates := dsVPAddressAfterApplyUpdates;
    dsVPaddress.Left := 200;
    dsVPaddress.Top := 264;
    bmEditContactData.SetParentComponent(Self);
    bmEditContactData.Name := 'bmEditContactData';
    bmEditContactData.Actions.Actions.Clear;
    with bmEditContactData.Actions.Actions.Add do
    begin
      ID := 'btnCancelContact';
      Name := 'btnCancelContact';
    end;
    with bmEditContactData.Actions.Actions.Add do
    begin
      ID := 'btnSaveContact';
      Name := 'btnSaveContact';
      SetEvent(Self, 'OnExecute', 'DoSaveEditsClick');
    end;
    with bmEditContactData.Actions.Actions.Add do
    begin
      Event := heNone;
      ID := 'cntEntityChooser';
      Name := 'cntEntityChooser';
    end;
    with bmEditContactData.Actions.Actions.Add do
    begin
      Event := heNone;
      ID := 'cntEntityPerson';
      Name := 'cntEntityPerson';
    end;
    with bmEditContactData.Actions.Actions.Add do
    begin
      ID := 'cntEntityCompany';
      Name := 'cntEntityCompany';
    end;
    with bmEditContactData.Actions.Actions.Add do
    begin
      ID := '';
      Name := 'cntCompanyPerson';
      PreventDefault := False;
      Selector := 'input[name="rdEntity"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnSelectEntityType');
    end;
    with bmEditContactData.Actions.Actions.Add do
    begin
      Event := heNone;
      ID := 'editContactModal';
      Name := 'editContactModal';
    end;
    with bmEditContactData.Actions.Actions.Add do
    begin
      Event := heNone;
      ID := 'lblEditContactTitle';
      Name := 'lblEditContactTitle';
    end;
    bmEditContactData.ParentID := 'modals';
    bmEditContactData.ShowOnRender := True;
    bmEditContactData.BackDrop := True;
    bmEditContactData.Focus := True;
    bmEditContactData.CancelButtonName := 'btnCancelContact';
    bmEditContactData.TemplateName := 'editcontact';
    SetEvent(bmEditContactData, Self, 'OnHide', 'DoEditDialogHide');
    SetEvent(bmEditContactData, Self, 'OnShow', 'bmEditContactDataShow');
    bmEditContactData.Left := 544;
    bmEditContactData.Top := 24;
  finally
    bmSearchContact.AfterLoadDFMValues;
    alForm.AfterLoadDFMValues;
    dsContactId.AfterLoadDFMValues;
    dsPhones.AfterLoadDFMValues;
    dsWPAddress.AfterLoadDFMValues;
    dsContactPic.AfterLoadDFMValues;
    dsContact.AfterLoadDFMValues;
    dsContactcntid.AfterLoadDFMValues;
    dsContactcntcreatedon.AfterLoadDFMValues;
    dsContactcntcreatedbyfk.AfterLoadDFMValues;
    dsContactcntchangedon.AfterLoadDFMValues;
    dsContactcntchangedbyfk.AfterLoadDFMValues;
    dsContactcntcompanyfk.AfterLoadDFMValues;
    dsContactcntfirstname.AfterLoadDFMValues;
    dsContactcntlastname.AfterLoadDFMValues;
    dsContactcntprofession.AfterLoadDFMValues;
    dsContactcntbirthdateon.AfterLoadDFMValues;
    dsContactcntisbirthdateunknown.AfterLoadDFMValues;
    dsContactcntgender.AfterLoadDFMValues;
    dsContactcntcityofbirthfk.AfterLoadDFMValues;
    dsContactcntcityofbirthname.AfterLoadDFMValues;
    dsContactcntcityofbirthzip.AfterLoadDFMValues;
    dsContactcntnationalityfk.AfterLoadDFMValues;
    dsContactcntnationality2.AfterLoadDFMValues;
    dsContactcntnationality.AfterLoadDFMValues;
    dsContactcntpicturefk.AfterLoadDFMValues;
    dsContactcntkbonr.AfterLoadDFMValues;
    dsContactcntremark.AfterLoadDFMValues;
    dsContactcntpersonfk.AfterLoadDFMValues;
    dsContactcntsalutation.AfterLoadDFMValues;
    dsContactcntsearchname.AfterLoadDFMValues;
    dsContactcntprefixes.AfterLoadDFMValues;
    dsContactcntfriendlytitle.AfterLoadDFMValues;
    dsDossierPerson.AfterLoadDFMValues;
    dsVPaddress.AfterLoadDFMValues;
    bmEditContactData.AfterLoadDFMValues;
  end;
end;

end.
