unit Forms.CSR.Possession;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Possession, pas2web.bootstrap, pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset,
  WEBLib.Actions;

type
  TfrmCSRPossession = class(TfrmPossession)
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRPossession: TfrmCSRPossession;

implementation

{$R *.dfm}

{ TfrmCSRPossession }

function TfrmCSRPossession.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRPossession.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRPossession.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRPossession.WebFormCreate(Sender: TObject);
begin
  Inherited;
  dcPosession.Columns[5].ButtonURL:='#/CSR/{{prodossierfk}}/possession/edit/{{proid}}';
  dcPosession.Columns[6].ButtonURL:='#/CSR/{{prodossierfk}}/possession/delete/{{proid}}';
end;

procedure TfrmCSRPossession.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcPosession.BeforeLoadDFMValues;
  try
    dcPosession.Columns.Clear;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'proshortdescription';
      Title := 'Description';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'procount';
      Title := 'Count';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'procurrentvalue';
      Title := 'Current value';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '##0.00';
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'propropertytype';
      Title := 'Type';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'proiscomplete';
      Title := 'Is Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'disabled';
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'prodossierfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{prodossierfk}}/possession/edit/{{proid}}';
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'proid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{prodossierfk}}/possession/delete/{{proid}}';
    end;
  finally
    dcPosession.AfterLoadDFMValues;
  end;
end;

end.