unit Forms.Import.Mapping;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, Weblib.Grids, Vcl.Controls,
  Vcl.Grids, WebLib.Json, Forms.ImportContacts;

type
  TFileType = (ftJSON, ftCSV);

  TfrmImportMapping = class(TfrmBase)
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure onBtnCancelClick(Sender: TObject; Element: TJSHTMLElementRecord;
      Event: TJSEventParameter);
    procedure onBtnFinishClick(Sender: TObject; Element: TJSHTMLElementRecord;
      Event: TJSEventParameter);
  private
    FMappingFileColumnNames: TStringlist;
    FDelimiter: char;
    FStringToSendBack: string;
    procedure GetImportFile;
    procedure CreateDropDownLists;
    procedure CreateDropDownList(aElementName: string; aIndex: integer);
    procedure GoBackToImport(aMapping: Boolean);
    procedure ProcessFile;
    function VerifyMapping(var aErrorMessageStr: string): Boolean;
    procedure SplitTheString(aStringToSplit: string);
    function GetMappedColumnNames: string;
    function ForWhatSourceFieldHasThisMapFieldBeenSelected(aMapField: Integer): Integer;
    function FetchNameofSrcField(aSrcFieldNumber: Integer): string;
    procedure SetErrorMessage(const aMessageStr: string);
  public
    procedure setParams(const Params: TStrings); override;
    property Delimiter: char read FDelimiter;
    property MappingFileColumnNames: TStringlist read FMappingFileColumnNames write FMappingFileColumnNames;
  protected procedure LoadDFMValues; override; end;

var
  frmImportMapping: TfrmImportMapping;

const
  cNumberofFieldsinDB = 8; // this is the number of fields requested by the REST Server to put a new contact in the DB.
  cElementIdErrorMsg = 'lblerrormsg'; // id of the element where errormessage are supposed to go.

implementation

{$R *.dfm}

uses
  Units.ActionUtils, libjquery, Units.Types, Modules.Server;

procedure TfrmImportMapping.setParams(const Params: TStrings);
begin
  inherited;
  nop;
end;

procedure TfrmImportMapping.WebFormCreate(Sender: TObject);
begin
  inherited;
  FMappingFileColumnNames := TStringlist.Create;
  GetImportFile; // Retrieve file data from session storage
  CreateDropDownLists;
end;

procedure TfrmImportMapping.WebFormDestroy(Sender: TObject);
begin
  FMappingFileColumnNames.free;
  inherited;
end;

procedure TfrmImportMapping.WebFormShow(Sender: TObject);
begin
  inherited;
  nop;
end;

procedure TfrmImportMapping.GetImportFile;
var ls: string;
begin
  ls := Server.GetLocalData(cLocalDataImportFile);
  ls := ' ,'+ls;//adding an empty field makes clear when fields are not mapped yet.
  // Adding a space between names also causes StringList.CommaText to split them, that's why a separate procedure is needed.
  SplitTheString(ls);
end;

procedure TfrmImportMapping.SplitTheString(aStringToSplit: string);
var
  inputString: string;
  csv: TStringList;
  i: Integer;
begin
  // Create a new TStringList and load the input string
  csv := TStringList.Create;
  try
    csv.Text := aStringToSplit;
    // Split the first line into separate column names based on commas
    csv.StrictDelimiter := True; // Treat all delimiters as separators
    // if as delimiter a ; or a , is used. Than keep that delimiter through the processing.
    if Pos(';', csv[0]) > 0 then
      begin
        FDelimiter := ';';
      end
    else
      begin
        FDelimiter := ',';
      end;
    csv.Delimiter := FDelimiter;
    csv.DelimitedText := csv[0];

    // Loop through the column names and add them to a string list
    for i := 0 to csv.Count - 1 do
    begin
      FMappingFileColumnNames.Add(csv[i]);
    end;
  finally
    csv.Free;
  end;
end;


procedure TfrmImportMapping.CreateDropDownLists;
var
  i: integer;
  sElementName: string;
begin
  for i := 1 to cNumberofFieldsinDB do
  begin
    if i < 10 then
      sElementName := 'mapfield0' + IntToStr(i)
    else
      sElementName := 'mapfield' + IntToStr(i);
    CreateDropDownList(sElementName, i);
  end;
end;

procedure TfrmImportMapping.CreateDropDownList(aElementName: string; aIndex: integer);
var
  i: integer;
  lsInnerHtml: string;
begin
  lsInnerHtml := '<select onchange="updateMapping(' + IntToStr(aIndex) + ', this.value)">';
  for i := 0 to FMappingFileColumnNames.Count - 1 do
  begin
    lsInnerHtml := lsInnerHtml + '<option data-id="' +
      IntToStr(i) + '" value="' + FMappingFileColumnNames[i] + '">' +
      FMappingFileColumnNames[i] + '</option>';
  end;
  lsInnerHtml := lsInnerHtml + '</select>';
  document.getElementbyId(aElementName).innerHTML := lsInnerHtml;
end;

procedure TfrmImportMapping.onBtnCancelClick(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  inherited;
  GoBackToImport(False);
end;

procedure TfrmImportMapping.onBtnFinishClick(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  sErrorMessageStr: string;
begin
  inherited;
  nop;
  if VerifyMapping(sErrorMessageStr) then
    begin
      SetErrorMessage(EmptyStr);
      ProcessFile;
      GoBackToImport(True);
    end
  else
    begin
      //send the errostr to the page.
      SetErrorMessage(sErrorMessageStr);
    end;
end;

procedure TfrmImportMapping.SetErrorMessage(const aMessageStr: string);
var
  lblErrorMessage: TJSElement;
begin
  lblErrorMessage := document.getElementById(cElementIdErrorMsg);
  lblErrorMessage.innerHTML := aMessageStr;
end;


function TfrmImportMapping.VerifyMapping(var aErrorMessageStr: string): Boolean;
var
  iNOFDBCounter: Integer;
  mapfieldEl: TJSHTMLElement;
  selectEl: TJSHTMLSelectElement;
  selectedOption: TJSHTMLOptionElement;
  selectedIds: TStringList;
  sMapfieldID : string;
begin
  Result := True;
  selectedIds := TStringList.Create;
  try
    for iNOFDBCounter := 1 to cNumberofFieldsinDB do
    begin
      sMapfieldID := 'mapfield' + Format('%.2d', [iNOFDBCounter]);
      mapfieldEl := TJSHTMLElement(document.getElementById(sMapfieldID));
      selectEl := TJSHTMLSelectElement(mapfieldEl.querySelector('select'));
      selectedOption := TJSHTMLOptionElement(selectEl.options[selectEl.selectedIndex]);

      if (StrToIntDef(selectedOption.getAttribute('data-id'),0) = 0) then
      begin
        Result := False;
        aErrorMessageStr := 'Please select a Dezq field for all source fields.';
        Exit;
      end
      else if (selectedIds.IndexOf(selectedOption.getAttribute('data-id')) >= 0) then
      begin
        Result := False;
        aErrorMessageStr := 'Please select a unique Dezq field for each source field.';
        Exit;
      end
      else
      begin
        selectedIds.Add(selectedOption.getAttribute('data-id'));
      end;
    end;
  finally
    selectedIds.Free;
  end;
end;


procedure TfrmImportMapping.ProcessFile;
begin
  FStringToSendBack := GetMappedColumnNames;
end;

procedure TfrmImportMapping.GoBackToImport(aMapping: Boolean);
begin
  server.setLocalData(cLocaldataImportDelimiter, FDelimiter);
  if aMapping then
    begin
      server.setLocalData(cLocalDataImportFileReturn, FStringToSendBack);
      server.setLocalData(cLocalDataImportReturn, '1');
    end
  else
    begin
      server.setLocalData(cLocalDataImportReturn, '0');
    end;
  server.setLocalData(cLocalDataImportMapping, '1');
end;

function TfrmImportMapping.GetMappedColumnNames: string;
var
  iFieldNumberMappingFile: Integer;
  iSrcFieldNumber : Integer;
  sSrcFieldName: string;
begin
  Result := '';
  // This holds the original order of fieldnames of the importfile: FMappingFileColumnNames
  // The order needs to stay the same. However, every name needs to be translated with the Dezq fieldnames.
  // So, we start with the number of the first item of this stringlist, and search if this has been selected somewhere.
  // Then we we exchange the value of this item with the srcfield value, then we take on the second item of the
  // FMappingFileColumnNames stringlist and so on.
  for iFieldNumberMappingFile := 0 to FMappingFileColumnNames.count - 1 do
    begin
      // searching if there is a selected field with that fieldNumbermappingfile.
      iSrcFieldNumber := ForWhatSourceFieldHasThisMapFieldBeenSelected(iFieldNumberMappingFile); // searching if there is a selected field with that fieldNumbermappingfile.
      if (iSrcFieldNumber <> 0) then // if found then exchange columnname from mapped file to Dezq.
        begin
          sSrcFieldName := FetchNameofSrcField(iSrcFieldNumber);
          FMappingFileColumnNames[iFieldNumberMappingFile]:= sSrcFieldName;
        end;
    end;
  // Now create a delimited string with the now translated fields.
  //Starting from 1 because item 0 conatins an empty string (to have the combobox start with an empty field)
  for iFieldNumberMappingFile := 1 to FMappingFileColumnNames.count - 1 do
    begin
      Result := Result + FMappingFileColumnNames[iFieldNumberMappingFile] + FDelimiter;
    end;
  Delete(Result, Length(Result), 1); // take away the last delimiter of the line
end;

function TfrmImportMapping.ForWhatSourceFieldHasThisMapFieldBeenSelected(aMapField: Integer): Integer;
var
  sMapfieldID: string;
  mapfieldEl: TJSHTMLElement;
  selectEl: TJSHTMLSelectElement;
  selectedOption: TJSHTMLOptionElement;
  mappedColumnIdStr: string;
  mappedColumnIdInt: Integer;
  iSrcFieldNumber : Integer;
begin
  Result := 0; // since there is no mapfield starting with 0, when it returns zero means in hasn't found anything.
  //Loop through all the srcfields if it has a selected mapfield equals aMapField
  for iSrcFieldNumber := 1 to cNumberofFieldsinDB do
    begin
      sMapfieldID := 'mapfield' + Format('%.2d', [iSrcFieldNumber]);
      mapfieldEl := TJSHTMLElement(document.getElementById(sMapfieldID));
      selectEl := TJSHTMLSelectElement(mapfieldEl.querySelector('select'));
      selectedOption := TJSHTMLOptionElement(selectEl.options[selectEl.selectedIndex]);
      mappedColumnIdStr := Trim(selectedOption.getAttribute('data-id'));
      mappedColumnIdInt := StrToIntDef(mappedColumnIdStr, 0);
      if (mappedColumnIdInt = aMapField) then
        begin
          Result := iSrcFieldNumber;
          Break;
        end;
    end;
end;

function TfrmImportMapping.FetchNameofSrcField(aSrcFieldNumber: Integer): string;
var
  sSrcfieldID : string;
  srcfieldEl: TJSHTMLElement;
begin
  sSrcfieldID := 'srcfield' + Format('%.2d', [aSrcFieldNumber]);
  srcfieldEl := TJSHTMLElement(document.getElementById(sSrcfieldID));
  Result := Trim(srcfieldEl.textContent);
end;


procedure TfrmImportMapping.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnCancel';
      Name := 'btnCancel';
      SetEvent(Self, 'OnExecute', 'onBtnCancelClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnFinish';
      Name := 'btnFinish';
      SetEvent(Self, 'OnExecute', 'onBtnFinishClick');
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.