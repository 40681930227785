unit Forms.ExportContacts;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, Units.Types, pas2web.datatables,
  Forms.DossierDetail, pas2web.dadatasethelper, WEBLib.REST;

type
  TFrmBase = TFrmDossierDetail;
  TfrmExportContacts = class(TfrmBase)
    dcFiles: TP2WDatatable;
    dsFiles: TP2WDADataset;
    procedure dsFilesAfterOpen(DataSet: TDataSet);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure OnBtnSaveClick(Sender: TObject; Element: TJSHTMLElementRecord;
      Event: TJSEventParameter);
    procedure WebRESTClientResponse(Sender: TObject; AResponse: string);
  private
    FRoute : String;
    FDeleteID : Int64;
    FDoDelete : Boolean;
    FJsonExportString: string;
    FNumberOfRecords: integer;
    procedure FetchExportStringFromServer;
    procedure AddToTheListOfExportedFiles(aFilename: string; aNumberofRecords: Integer);
    procedure ExportFile;
  public
    procedure setParams(const Params: TStrings); override;
    property JsonExportString: string read FJsonExportString;
    property NumberOfRecords: integer read FNumberOfRecords;
  protected procedure LoadDFMValues; override; end;

var
  frmExportContacts: TfrmExportContacts;

const
  cExportBestandsnaam = 'DezqContactsExported';
  cJSON = 'json';
  cURL = 'http://127.0.0.1/restserver';
  cRESTCallExportContacts = 'fetchContactsExportString';

implementation

{$R *.dfm}

uses
  libjquery,  Modules.Server, Units.Strings, Units.ActionUtils;

procedure TfrmExportContacts.OnBtnSaveClick(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  sType: string;
begin
  inherited;

  sType := getRadioGroupValue('exporttype');
  window.location.href := Server.ServerURL + '../api/api/exportcontacts?ExportType=' + sType;

  sType := Server.ClientID;

  // ask for file to server
//  FetchExportStringFromServer;
//  FJsonExportString :='{bestandsnaam: test}'; // stub
end;

procedure TfrmExportContacts.FetchExportStringFromServer;
var
  headers: TCoreCloudHeaders;
  sToken_Access : string;
begin
  sToken_Access := ''; // stub
  AddHeader(headers, 'Authorization', 'Bearer ' + sToken_Access);
  AddHeader(headers,'Content-Type','application/json');
  AddHeader(headers,'Accept','application/json');
  //WebRESTClient.HttpsPost(cUrl, headers, cRESTCallExportContacts);
end;

procedure TfrmExportContacts.WebRESTClientResponse(Sender: TObject; AResponse: string);
begin
  inherited;
  FJsonExportString:= AResponse;
  FNumberOfRecords:=  0; // stub TJSON(AResponse);

  // export now the file
  ExportFile;
end;

procedure TfrmExportContacts.ExportFile;
var sFileName : string;
begin
  inherited;
  sFileName := Format(cExportBestandsnaam+'%s.json',[FormatDateTime('yyyymmddhhnnss', Now)]);
  // write in the downloads map of the clients' browser
  Application.DownloadTextFile(JsonExportString, sFileName);
  // add to the list of exported files
  // stub: AddToTheListOfExportedFiles(sFileName, iNumberofRecords);
end;

procedure TfrmExportContacts.AddToTheListOfExportedFiles(aFilename: string; aNumberOfRecords: integer);
begin
    dsFiles.Insert;
    dsFiles.FieldByName('exfcreatedon').asDateTime := now;
    dsFiles.FieldByName('exfcreatedbyfk').asLargeInt := Server.UserID;
    // dsFiles.FieldByName('exfcontactfk').asLargeInt := FContactID;  clientid? hoe doen we de clientseparation?
    dsFiles.FieldByName('exfchangedon').asDateTime := now;
    dsFiles.FieldByName('exfchangedbyfk').asLargeInt := Server.UserID;
    dsFiles.FieldByName('exffilename').asString := aFilename;
    dsFiles.FieldByName('exffiletype').asString := cJSON;
    dsFiles.FieldByName('exfnumberofrecords').asLargeInt := aNumberOfRecords;
    dsFiles.Post;
    dsFiles.ApplyUpdates;
end;

procedure TfrmExportContacts.dsFilesAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
//  aResult := dsFiles.GetNewRows(False);
//  dcFiles.Data := aResult;
//  dcFiles.RenderTranslated;
end;

procedure TfrmExportContacts.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmExportContacts.setParams(const Params: TStrings);
begin
  inherited;
  nop;
end;

procedure TfrmExportContacts.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsFiles.DAConnection := dmserver.ContactConnection;
end;

procedure TfrmExportContacts.WebFormShow(Sender: TObject);
begin
  inherited;
  dsFiles.Load([], nil);
end;

procedure TfrmExportContacts.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dcFiles := TP2WDatatable.Create(Self);
  dsFiles := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dcFiles.BeforeLoadDFMValues;
  dsFiles.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'OnBtnSaveClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'rbtncsv';
      Name := 'rbtnCSV';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'rbtnjson';
      Name := 'rbtnJSON';
      PreventDefault := False;
      StopPropagation := False;
    end;
    dcFiles.SetParentComponent(Self);
    dcFiles.Name := 'dcFiles';
    dcFiles.DataSet := dsFiles;
    dcFiles.Language := lEnglish;
    dcFiles.IsResponsive := True;
    dcFiles.GridID := 'grdFiles';
    dcFiles.UseFieldIndex := True;
    dcFiles.ShowSearch := False;
    dcFiles.ShowNumberOfEntries := False;
    dcFiles.ShowEntriesInfo := False;
    dcFiles.Paginate := True;
    dcFiles.DisplayReadOnly := False;
    dcFiles.CalculateTableWidth := True;
    dcFiles.Left := 32;
    dcFiles.Top := 184;
    dsFiles.SetParentComponent(Self);
    dsFiles.Name := 'dsFiles';
    dsFiles.TableName := 'contactaddress';
    dsFiles.DAConnection := dmServer.ContactConnection;
    dsFiles.DAOptions := [doRefreshAllFields];
    dsFiles.AfterOpen := dsFilesAfterOpen;
    dsFiles.Left := 40;
    dsFiles.Top := 96;
  finally
    alForm.AfterLoadDFMValues;
    dcFiles.AfterLoadDFMValues;
    dsFiles.AfterLoadDFMValues;
  end;
end;

end.