unit lib.eventqueue;

interface

uses
  Classes,
  SysUtils,
  Generics.Collections;

type
  TEventItem = class(TObject)
  protected
    procedure Execute; virtual; abstract;
  end;

  TArgumentEventItem<T> = class(TEventItem)
  public type
    TTEvent = reference to procedure(Sender: T);
  private
    FEvent: TTEvent;
    FArg: T;
  protected
    procedure Execute; override;
  public
    constructor Create(aEvent: TTEvent; aData: T);
  end;

  TObjectEventItem = TArgumentEventItem<TObject>;

  TSimpleEvent = reference to procedure;

  TSimpleEventItem = class(TEventItem)
  private
    FEvent: TSimpleEvent;
  protected
    procedure Execute; override;
  public
    constructor Create(aEvent: TSimpleEvent);
  end;

  TEventPair = record
    ID: Integer;
    Item: TEventItem;
  end;

  TEventPairList = TList<TEventPair>;

  TEventErrorBehaviour = (erbIgnore, erbStop, erbPassThrough);
  TEventErrorBehaviours = set of TEventErrorBehaviour;

  TEventQueue = class(TComponent)
  private
    FID: Integer;
    FEvents: TEventPairList;
    FClearOnExecute: Boolean;
    FErrorBehaviour: TEventErrorBehaviour;
    function IndexOfID(aID: Integer): Integer;
  public
    constructor Create(aOwner: TComponent); override;
    destructor Destroy; override;
    procedure Clear(aClearID: Boolean = False);
    function Execute(aClearOnExecute: Boolean): Integer; overload;
    function Execute: Integer; overload;
    function UnRegisterEvent(aID: Integer): Boolean;
    function RegisterEvent(aItem: TEventItem): Integer; overload;
    function RegisterEvent(aEvent: TSimpleEvent): Integer; overload;
    function RegisterEvent(aEvent: TObjectEventItem.TTEvent; aSender: TObject): Integer; overload;
    // Function RegisterArgEvent<T>(aEvent : TArgumentEventItem<T>.TTEvent; aSender : T) : Integer; overload;
    property ClearOnExecute: Boolean read FClearOnExecute write FClearOnExecute;
    property ErrorBehaviour: TEventErrorBehaviour read FErrorBehaviour write FErrorBehaviour;
  end;

implementation

uses
  JS;

{ TArgumentEventItem<T> }

constructor TArgumentEventItem<T>.Create(aEvent: TTEvent; aData: T);
begin
  FEvent := aEvent;
  FArg := aData;
end;

procedure TArgumentEventItem<T>.Execute;
begin
  FEvent(FArg);
end;

{ TEventQueue }

function TEventQueue.RegisterEvent(aItem: TEventItem): Integer;

var
  P: TEventPair;

begin
  Inc(FID);
  Result := FID;
  P.ID := Result;
  P.Item := aItem;
  FEvents.Add(P);
end;

function TEventQueue.RegisterEvent(aEvent: TSimpleEvent): Integer;
begin
  Result := RegisterEvent(TSimpleEventItem.Create(aEvent));
end;

(*
 function TEventQueue.RegisterArgEvent<t>(aEvent: TArgumentEventItem<T>.TTevent; aSender: T): Integer;
 begin
 Result:=RegisterEvent(TArgumentEventItem<T>.Create(aEvent,aSender));
 end;
*)

procedure TEventQueue.Clear(aClearID: Boolean = False);

var
  P: TEventPair;

begin
  for P in FEvents do
    P.Item.Free;
  FEvents.Clear;
  if aClearID then
    FID := 0;
end;

constructor TEventQueue.Create(aOwner: TComponent);
begin
  inherited;
  FEvents := TEventPairList.Create;
end;

destructor TEventQueue.Destroy;
begin
  Clear;
  FreeAndNil(FEvents);
  inherited;
end;

function TEventQueue.Execute: Integer;

begin
  Result := Execute(Self.ClearOnExecute);
end;

function TEventQueue.Execute(aClearOnExecute: Boolean): Integer;

var
  I: Integer;

begin
  Result := 0;
  I := 0;
  while (I < FEvents.Count) do
  begin
    try
      FEvents[I].Item.Execute;
      Inc(Result);
    except
      on E: Exception do
      begin
        case FErrorBehaviour of
          erbIgnore:
            ;
          erbStop:
            I := FEvents.Count;
          erbPassThrough:
            raise;
        end;
      end;
      on EJ: TJSObject do
      begin
        case FErrorBehaviour of
          erbIgnore:
            ;
          erbStop:
            I := FEvents.Count;
          erbPassThrough:
            raise;
        end;
      end;
    end;
    Inc(I);
  end;
  if aClearOnExecute then
    Clear;
end;

function TEventQueue.RegisterEvent(aEvent: TObjectEventItem.TTEvent; aSender: TObject): Integer;
begin
  Result := RegisterEvent(TObjectEventItem.Create(aEvent, aSender));
end;

function TEventQueue.IndexOfID(aID: Integer): Integer;

begin
  Result := FEvents.Count - 1;
  while (Result >= 0) and (FEvents[Result].ID <> aID) do
    Dec(Result);
end;

function TEventQueue.UnRegisterEvent(aID: Integer): Boolean;

var
  Idx: Integer;
begin
  Idx := IndexOfID(aID);
  Result := Idx >= 0;
  if Result then
    FEvents.Delete(Idx);
end;

{ TSimpleEventItem }

constructor TSimpleEventItem.Create(aEvent: TSimpleEvent);
begin
  FEvent := aEvent;
end;

procedure TSimpleEventItem.Execute;
begin
  FEvent
end;

end.
