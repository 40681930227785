unit Forms.CSR.CreateCSR;

interface

uses
  Types,
  System.Classes,
  System.SysUtils,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.NewPetition,
  WEBLib.Actions,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  Units.Contacts,
  Module.Contacts,
  Units.Params;

type
  TfrmCreateCSR = class(TfrmNewPetition)
    dsContactId: TP2WDADataset;
    dsCourt: TP2WDADataset;
    procedure dsContactIdAfterOpen(DataSet: TDataSet);
    procedure dsCourtAfterOpen(DataSet: TDataSet);
    procedure onSaveCSR(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject); override;
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FNationalID: string;
    FdmJudgeContact: TdmContacts;
    FContactID: NativeInt;
    FJudgeID: NativeInt;
    procedure AfterContactSetup(Sender: TObject);
    procedure SetupJudgeContact;
    procedure SetupContact;
    procedure setupForm;
    function CourtInfoIsValid: Boolean;
    procedure onContactSaved(Sender: TObject);
    procedure onJudgeSaved(Sender: TObject);
    procedure onJudgeSearchResult(const SelectedContact: TContactSearchResult);
    procedure onContactSearchResult(const SelectedContact: TContactSearchResult);
    procedure DoCreateCSR;
  public
    { Public declarations }
    class function GetHTMLFileName: string; override;
    class function MyRoutes: TStringDynArray; override;
    class function NeedsParams: Boolean; override;
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmCreateCSR: TfrmCreateCSR;

implementation

uses
  Modules.Server,
  libjquery,
  Units.Logging,
  Units.Strings,
  Units.ActionUtils;

{$R *.dfm}

procedure TfrmCreateCSR.onContactSaved(Sender: TObject);
begin
  FContactID := FdmContact.ContactId;
end;

procedure TfrmCreateCSR.onContactSearchResult(
  const SelectedContact: TContactSearchResult);
begin
  FContactID := SelectedContact.RecordID;
end;

procedure TfrmCreateCSR.onJudgeSaved(Sender: TObject);
begin
  FJudgeID := FdmJudgeContact.ContactId;
end;

procedure TfrmCreateCSR.onJudgeSearchResult(const SelectedContact: TContactSearchResult);
begin
  FJudgeID := SelectedContact.RecordID;
end;

procedure TfrmCreateCSR.onSaveCSR(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if not DataIsValid then
    jQuery('#first').click()
  else
    if not CourtInfoIsValid then
      jQuery('#second').click()
    else
      DoCreateCSR;
end;

procedure TfrmCreateCSR.setParams(const Params: TStrings);
begin
  FNationalID := '';
  if Assigned(Params) and (Params.indexOfName('NIDNR') <> -1) then
  begin
    FNationalID := Params.Values['NIDNR'];
  end;
end;

procedure TfrmCreateCSR.setupForm;
begin
  jQuery('#' + alForm['newCSRTitle'].ID).css('display', '');
  jQuery('#' + alForm['newPetitionTitle'].ID).css('display', 'none');
  jQuery('#' + alForm['csrTabs'].ID).css('display', '');
  alForm['btnSavePetition'].onExecute := onSaveCSR;
end;

procedure TfrmCreateCSR.SetupJudgeContact;
const
  JudgePersonalFields: TPersonalFieldNames = ('edtJudgeName', '', '', '', '', '', '', '', '', '', '', '', '', '', '');

  procedure HandleSearchResult(const SelectedContact: TContactSearchResult; var aAllow: Boolean);
  begin
    aAllow := (SelectedContact.Gender <> 'C');
    if not aAllow then
      dmServer.ShowError(SErrJudgeCanNotBeCompany);
  end;

var
  PersonalFields: TPersonalFieldEdits;
begin
  PersonalFields.Names := JudgePersonalFields;
  PersonalFields.SetFieldsFromNames;
  PersonalFields.FirstNameNotRequired := True;
  FdmJudgeContact.PersonalFieldInfo := PersonalFields;
  FdmJudgeContact.FormIsReadOnly := Self.FormIsReadOnly;
  FdmJudgeContact.OnSearchResultEx := HandleSearchResult;
  FdmJudgeContact.ContactDescription := SContactJudge;
  FdmJudgeContact.EditContactButtonID := 'btnNewJudgeContact';
  FdmJudgeContact.SearchButtonID := 'btnSelectJudgeContact';
  FdmJudgeContact.OnAllSaved := onJudgeSaved;
  FdmJudgeContact.OnSearchResult := onJudgeSearchResult;
  FdmJudgeContact.LoadContactData(-1, [cdpPersonal], []);
end;

procedure TfrmCreateCSR.SetupContact;
begin
  FdmContact.OnAllSaved := onContactSaved;
  FdmContact.OnSearchResult := onContactSearchResult;
end;

procedure TfrmCreateCSR.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FdmJudgeContact);
  inherited;
end;

procedure TfrmCreateCSR.AfterContactSetup(Sender: TObject);
begin
  FdmContact.OnAllSaved := onContactSaved;
  if FNationalID <> '' then
  begin
    dsContactId.ParamByName('NIDNR').asString := FNationalID;
    dsContactId.Load([], nil);
  end;
end;

function TfrmCreateCSR.CourtInfoIsValid: Boolean;
begin
  Result := not FieldIsEmpty('selCourt');
  Result := not FieldIsEmpty('edtJudgeName') and Result;
  Result := not FieldIsEmpty('ddtStart') and Result;
  Result := (DefaultDocumentBox.FileBase64 <> '') and Result;
  if DefaultDocumentBox.FileBase64 = '' then
    jQuery('#admissionFormRequired').css('display', 'block');
end;

procedure TfrmCreateCSR.DoCreateCSR;
var
  dataParam: TCSRCreateParams;

  procedure onCreationResult(anID: Int64; aSuccess: Boolean; anError: string);
  begin
    if aSuccess then
      window.location.href := '#/CSR/' + intToStr(anID) + '/identity'
    else
      Server.ShowError(anError);
  end;

begin
  dataParam.ContactId := FContactID;
  dataParam.Description := alForm['edtDescription'].value;
  dataParam.CourtID := StrToInT(alForm['selCourt'].value);
  dataParam.JudgeContactID := FJudgeID;
  dataParam.StartDate := TJSDate.New(alForm['ddtStart'].value);
  dataParam.CSRAdmissionFile := DefaultDocumentBox.FileBase64;

  Server.CreateCSR(dataParam, @onCreationResult);
end;

procedure TfrmCreateCSR.dsContactIdAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
  begin
    FContactID := DataSet.FieldByName('cticontactfk').asInteger;
    FdmContact.LoadContactData(FContactID, [cdpPersonal, cdpWPAddress, cdpID{, cdpPicture}], [])
  end;
end;

procedure TfrmCreateCSR.dsCourtAfterOpen(DataSet: TDataSet);
var
  aHTML: string;
begin
  aHTML := '';
  while not DataSet.Eof do
  begin
    aHTML := aHTML + Format('<option value="%s">%s</option>', [DataSet.fieldByName('corid').asString, DataSet.fieldByName('corname').asString]);
    DataSet.Next;
  end;
  alForm['selCourt'].InnerHTML := aHTML
end;

class function TfrmCreateCSR.GetHTMLFileName: string;
begin
  Result := TfrmNewPetition.GetHTMLFileName;
end;

class function TfrmCreateCSR.MyRoutes: TStringDynArray;
begin
  Result := ['/CSR/new/create/:NIDNR', '/CSR/new/create'];
end;

class function TfrmCreateCSR.NeedsParams: Boolean;
begin
  Result := True;
end;

procedure TfrmCreateCSR.WebFormCreate(Sender: TObject);
begin
  inherited;
  FContactID := -1;
  FJudgeID := -1;
  setupForm;
  FdmContact.AllowedContactType := actPerson;

  inherited onContactModuleSetUp := AfterContactSetup;

  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsContactId.DAConnection := Server.ContactConnection;

  DefaultDocumentBox.NeedBase64 := True;

  SetupContact;

  FdmJudgeContact := TdmContacts.Create(nil);
  FdmJudgeContact.AllowedContactType := actPerson;
  FdmJudgeContact.DossierConnection := Server.ContactConnection;
  FdmJudgeContact.Name := 'JudgeContact';
  SetupJudgeContact;

  Server.ParameterConnection.Message.ClientID := Server.ClientID;
  dsCourt.DAConnection := Server.ParameterConnection;
  dsCourt.Load([], nil);
end;

procedure TfrmCreateCSR.WebFormShow(Sender: TObject);
begin
  Server.DoOnParamsLoaded(
    procedure
    begin
      FdmContact.PredefinedNIDNR := FNationalID;
      SetupContactModule;
    end);
end;

procedure TfrmCreateCSR.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsContactId := TP2WDADataset.Create(Self);
  dsCourt := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsContactId.BeforeLoadDFMValues;
  dsCourt.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtName';
      Name := 'edtName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtFirstname';
      Name := 'edtFirstname';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtBirth';
      Name := 'ddtBirth';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtBirthplacePostcode';
      Name := 'edtBirthplacePostcode';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtBirthplace';
      Name := 'edtBirthplace';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNationalNumber';
      Name := 'edtNationalNumber';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNationality';
      Name := 'edtNationality';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtAddress';
      Name := 'edtAddress';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNumber';
      Name := 'edtNumber';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtZip';
      Name := 'edtZip';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtTown';
      Name := 'edtTown';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCardType';
      Name := 'edtCardType';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCardno';
      Name := 'edtCardno';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidFrom';
      Name := 'ddtValidFrom';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidTill';
      Name := 'ddtValidTill';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtChipNo';
      Name := 'edtChipNo';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSavePetition';
      Name := 'btnSavePetition';
      SetEvent(Self, 'OnExecute', 'SavePetitionClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtDescription';
      Name := 'edtDescription';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCardRemark';
      Name := 'edtCardRemark';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'errorMessage';
      Name := 'errorMessage';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'fileupload-avatar';
      Name := 'fileupload-avatar';
      PreventDefault := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'newCSRTitle';
      Name := 'newCSRTitle';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'newPetitionTitle';
      Name := 'newPetitionTitle';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'csrTabs';
      Name := 'csrTabs';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'first';
      Name := 'firstTab';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'second';
      Name := 'secondTab';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'selCourt';
      Name := 'selCourt';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtJudgeName';
      Name := 'edtJudgeName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtStart';
      Name := 'ddtStart';
      PreventDefault := False;
      StopPropagation := False;
    end;
    dsContactId.SetParentComponent(Self);
    dsContactId.Name := 'dsContactId';
    dsContactId.TableName := 'contactid';
    dsContactId.Params.Clear;
    with dsContactId.Params.Add do
    begin
      DataType := ftString;
      Name := 'NIDNR';
      ParamType := ptInput;
    end;
    dsContactId.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>ctinidnr</field><parameter type="String">NIDNR</parameter></binaryoperation></where></query>';
    dsContactId.DAOptions := [doRefreshAllFields];
    dsContactId.AfterOpen := dsContactIdAfterOpen;
    dsContactId.Left := 40;
    dsContactId.Top := 80;
    dsCourt.SetParentComponent(Self);
    dsCourt.Name := 'dsCourt';
    dsCourt.TableName := 'CourtOverview';
    dsCourt.DAOptions := [];
    dsCourt.AfterOpen := dsCourtAfterOpen;
    dsCourt.Left := 40;
    dsCourt.Top := 152;
  finally
    alForm.AfterLoadDFMValues;
    dsContactId.AfterLoadDFMValues;
    dsCourt.AfterLoadDFMValues;
  end;
end;

end.
