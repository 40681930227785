unit Forms.CSR.TrustAccount.Details;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  Forms.DossierDetail,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dataelementmapper;

type
  TfrmBase = class(TFrmDossierDetail);

  TfrmTrustAccountDetail = class(TfrmBase)
    dsBankAccount: TP2WDADataset;
    eacBanckAccount: TPW2DataElementMapper;
    procedure dsBankAccountAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsBankAccountAfterOpen(DataSet: TDataSet);
    procedure OnItemError(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
    procedure OnSaveBankAccount(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject);
    procedure ShowLastPontoDate(Sender: TObject; Source: TField; dest: TElementAction; var aHandled: Boolean);
  private
    { Private declarations }
    FAccountID: NativeInt;
    procedure trySaveBankAccount;
    procedure doSaveBankAccount;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix: string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmTrustAccountDetail: TfrmTrustAccountDetail;

implementation

{$R *.dfm}

uses
  Modules.Server,
  libjquery,
  Units.ActionUtils,
  Units.Strings,
  Units.Types,
  Units.HTMLUtils;

procedure TfrmTrustAccountDetail.doSaveBankAccount;
var
  companyDossierID: NativeInt;

  procedure saveData;
  begin
    // bank accounts are only created by Ponto, so this should never happen
    if FAccountID < 0 then
    begin
      dsBankAccount.Insert;
      dsBankAccount.FieldByName('baccreatedon').asDateTime := now;
      dsBankAccount.FieldByName('baccreatedbyfk').asInteger := Server.UserID;
      dsBankAccount.FieldByName('baccompanydossierfk').asInteger := companyDossierID;
      dsBankAccount.FieldByName('bacbalance').asFloat := 0.0;
      dsBankAccount.FieldByName('bactype').asInteger := 0;
      dsBankAccount.FieldByName('bacstatus').asInteger := 0;
      dsBankAccount.FieldByName('bacpontoid').asString := '';
    end
    else
      dsBankAccount.Edit;
    dsBankAccount.FieldByName('bacchangedon').asDateTime := now;
    dsBankAccount.FieldByName('bacchangedbyfk').asInteger := Server.UserID;
    eacBanckAccount.ElementsToDataset;
    dsBankAccount.Post;
    dsBankAccount.ApplyUpdates;
  end;

  procedure OnResponse(anID: Int64; aSuccess: Boolean; anError: string);
  begin
    if not aSuccess then
      Server.ShowError(anError)
    else
    begin
      companyDossierID := anID;
      saveData;
    end;

  end;

begin
  if FAccountID < 0 then
    Server.GetCompanyDossierID(DossierID, @OnResponse)
  else
    saveData;
end;

function TfrmTrustAccountDetail.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmTrustAccountDetail.dsBankAccountAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    DiscardChanges := True;
    dsBankAccountAfterOpen(dsBankAccount);
  end;
end;

procedure TfrmTrustAccountDetail.dsBankAccountAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
  begin
    FAccountID := DataSet.FieldByName('bacid').asInteger;
    eacBanckAccount.DatasetToElements;
  end;
end;

procedure TfrmTrustAccountDetail.ShowLastPontoDate(Sender: TObject; Source: TField; dest: TElementAction;
  var aHandled: Boolean);
begin
  if not Source.IsNull then
    dest.Value := FormatDateTime('dd"/"mm"/"yyyy hh":"nn":"ss', Source.asDateTime)
  else
    dest.Value := '?';
  aHandled := True;
end;

procedure TfrmTrustAccountDetail.OnItemError(Sender: TObject; Source: TElementAction; dest: TField;
  aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmTrustAccountDetail.OnSaveBankAccount(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  trySaveBankAccount;
end;

procedure TfrmTrustAccountDetail.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#returnBack').attr('href', DossierURL('/trustaccount'));

  if Assigned(Params) and (Params.indexOfName('ID') <> -1) then
  begin
    FAccountID := StrToIntDef(Params.Values['ID'], -1);
  end;
  dsBankAccount.ParamByName('DOSSIERID').asInteger := DossierID;
  dsBankAccount.ParamByName('BACID').asInteger := FAccountID;
  dsBankAccount.Load([], nil);
end;

procedure TfrmTrustAccountDetail.trySaveBankAccount;

  procedure OnResponse(aSuccess: Boolean; anError: string);
  begin
    if not aSuccess then
      DisplayError('edtIBAN', '', anError)
    else
      doSaveBankAccount;
  end;

begin
  ClearFormErrors;
  if eacBanckAccount.ValidateActions(True) then
  begin
    Server.isIBANValid(alForm['edtIBAN'].Value, @OnResponse);
  end;
end;

procedure TfrmTrustAccountDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  FAccountID := -1;

  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsBankAccount.DAConnection := Server.CompanyConnection;
  BindOnChangeEvent;
end;

procedure TfrmTrustAccountDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsBankAccount := TP2WDADataset.Create(Self);
  eacBanckAccount := TPW2DataElementMapper.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsBankAccount.BeforeLoadDFMValues;
  eacBanckAccount.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtIBAN';
      Name := 'edtIBAN';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtName';
      Name := 'edtName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtBalance';
      Name := 'edtBalance';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtLastPontoCheckOn';
      Name := 'edtLastPontoCheckOn';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'OnSaveBankAccount');
    end;
    dsBankAccount.SetParentComponent(Self);
    dsBankAccount.Name := 'dsBankAccount';
    dsBankAccount.TableName := 'BankAccount';
    dsBankAccount.Params.Clear;
    with dsBankAccount.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    with dsBankAccount.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BACID';
      ParamType := ptInput;
    end;
    dsBankAccount.WhereClause := '<?xml version="1.0" encoding="UTF-8"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>bacid</field><parameter type="LargeInt">BACID</parameter></binaryoperation></binaryoperation></where></query>';
    dsBankAccount.DAOptions := [];
    dsBankAccount.AfterOpen := dsBankAccountAfterOpen;
    dsBankAccount.AfterApplyUpdates := dsBankAccountAfterApplyUpdates;
    dsBankAccount.Left := 40;
    dsBankAccount.Top := 96;
    eacBanckAccount.SetParentComponent(Self);
    eacBanckAccount.Name := 'eacBanckAccount';
    eacBanckAccount.ActionLinks.Clear;
    with eacBanckAccount.ActionLinks.Add do
    begin
      ActionName := 'edtIBAN';
      FieldName := 'baciban';
      Options := [aoReadOnly];
      SetEvent(Self, 'OnValidationError', 'OnItemError');
    end;
    with eacBanckAccount.ActionLinks.Add do
    begin
      ActionName := 'edtName';
      FieldName := 'bacname';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnItemError');
    end;
    with eacBanckAccount.ActionLinks.Add do
    begin
      ActionName := 'edtBalance';
      FieldName := 'bacbalance';
      Options := [aoReadOnly];
      ClearValue := '0';
    end;
    with eacBanckAccount.ActionLinks.Add do
    begin
      ActionName := 'edtLastPontoCheckOn';
      FieldName := 'baclastpontocheckon';
      Options := [aoReadOnly];
      SetEvent(Self, 'OnFieldToAction', 'ShowLastPontoDate');
    end;
    eacBanckAccount.ActionList := alForm;
    eacBanckAccount.Dataset := dsBankAccount;
    eacBanckAccount.Left := 40;
    eacBanckAccount.Top := 160;
  finally
    alForm.AfterLoadDFMValues;
    dsBankAccount.AfterLoadDFMValues;
    eacBanckAccount.AfterLoadDFMValues;
  end;
end;

end.
