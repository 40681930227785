unit Forms.Family.Inmate;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Data.DB,
  Module.Country,
  pas2web.dataelementmapper,
  pas2web.dadataset,
  Units.Contacts,
  Module.Contacts,
  Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;

  TfrmFamilyInmate = class(TfrmBase)
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FRelationID: NativeInt;
    FdmContact: TdmContacts;
    procedure SetupInMateContact;
    procedure DoAllSaved(Sender: TObject);
    procedure DoAllLoaded(Sender: TObject);
    procedure DoCheckEditButton;
  public
    { Public declarations }
    procedure OnContactChanged(Sender: TObject); override;
    procedure MakeReadOnly; override;
    procedure setParams(const Params: TStrings); override;
    property dmContact: TdmContacts read FdmContact;
  protected procedure LoadDFMValues; override; end;

var
  frmFamilyInmate: TfrmFamilyInmate;

implementation

uses
  pas2web.dadatasethelper,
  Units.ActionUtils,
  Modules.Server,
  Units.Params,
  Units.Strings,
  Units.Types,
  Units.HTMLUtils,
  Units.Logging,
  StrUtils;

{$R *.dfm}

{ TfrmFamilyInmate }

procedure TfrmFamilyInmate.WebFormCreate(Sender: TObject);
begin
  inherited;
  FdmContact := CreateContactModule;
  BindOnChangeEvent;
  FdmContact.AllowedContactType := actPerson;

  // bindZipLookup('edtBirthPlaceZip', 'birthplace');
  // GetCivilStatus;
  // GetPersonRelation;
end;

procedure TfrmFamilyInmate.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FdmContact);
  inherited;
end;

procedure TfrmFamilyInmate.SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  dmContact.SaveIfValid;
end;

procedure TfrmFamilyInmate.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#backtoFamily').attr('href', DossierURL('/relation'));
  FRelationID := StrToIntDef(Params.Values['RELATIONID'], -1);
end;

procedure TfrmFamilyInmate.SetupInMateContact;
const
  IMPersonalFields: TPersonalFieldNames = ('edtLastName', 'edtFirstname', 'ddtBirth', 'edtBirthPlaceZip', 'birthplace',
    'rdGender', 'nationality', '', 'profession', '', '', '', '', '', 'mmRemark');
  IMIDFields: TContactIDFieldNames = ('regNumber', '', '', '', '', '', '');
  IMDossierFields: TDossierContactFieldNames = ('', 'rgCivilStatus', '', 'rgRelation');
  IMDossierBlocks: TDossierContactFieldNames = ('', 'CivilStatusBlock', '', 'relationShipBlock');
var
  PersonalFields: TPersonalFieldEdits;
  DossierFields: TDossierContactFieldEdits;
  IDFields: TContactIDFieldEdits;
begin
  PersonalFields.Names := IMPersonalFields;
  PersonalFields.SetFieldsFromNames;
  DossierFields.Names := IMDossierFields;
  DossierFields.SetFieldsFromNames;
  DossierFields.BlockNames := IMDossierBlocks;
  DossierFields.PersonTypeLimit := ['EEGA', 'OUDER', 'OMPA', 'VRIEND', 'VKIND', 'ANDERE'];
  IDFields.Names := IMIDFields;
  IDFields.SetFieldsFromNames;

  dmContact.ContactIDFieldInfo := IDFields;
  dmContact.PersonalFieldInfo := PersonalFields;
  dmContact.DossierContactFieldInfo := DossierFields;
  dmContact.FormIsReadOnly := Self.FormIsReadOnly;
  dmContact.SearchButtonID := 'btnSelectAdultContact';
  dmContact.EditContactButtonID := 'btnEditAdultContact';
  dmContact.ContactDescription := SContactInmate;
  dmContact.OnAllSaved := @DoAllSaved;
  dmContact.OnContactChanged := OnContactChanged;
  dmContact.OnAllLoaded := DoAllLoaded;
end;

procedure TfrmFamilyInmate.DoAllLoaded(Sender: TObject);
begin
  DoCheckEditButton;
end;

procedure TfrmFamilyInmate.DoAllSaved(Sender: TObject);
begin
//  dmServer.ShowOK(SInmateSavedOK);
  DiscardChanges := True;
  window.location.href:=DossierURL('/relation');
end;

procedure TfrmFamilyInmate.DoCheckEditButton;
begin
  Log.Log(ltDebug, ClassName, 'TfrmFamilyInmate', 'Hier ist');

  with alForm['btnEditAdultContact'] do
  begin
    if dmContact.ContactID <= 0 then
      addClass('d-none')
    else
      removeClass('d-none')
  end;
end;

procedure TfrmFamilyInmate.MakeReadOnly;
begin
  inherited;
  dmContact.FormIsReadOnly := Self.FormIsReadOnly;
end;

procedure TfrmFamilyInmate.OnContactChanged(Sender: TObject);
begin
  inherited;
  DoCheckEditButton;
end;

procedure TfrmFamilyInmate.WebFormShow(Sender: TObject);
begin
  inherited;
  SetupInMateContact;
  dmContact.LoadDossierPersonData(DossierID, FRelationID, [cdpDossierPerson, cdpID, cdpPersonal], [cdpDossierPerson])
end;

procedure TfrmFamilyInmate.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'btnEditAdultContact';
      Name := 'btnEditAdultContact';
      PreventDefault := False;
      StopPropagation := False;
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
