unit Forms.CSR.Family.Child;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Family.Child, WEBLib.Actions, pas2web.dadataset;

type
  TfrmCSRFamilyChild = class(TfrmFamilyChild)
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRFamilyChild: TfrmCSRFamilyChild;

implementation

{$R *.dfm}

{ TfrmCSRFamilyChild }

function TfrmCSRFamilyChild.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRFamilyChild.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRFamilyChild.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRFamilyChild.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.