unit lib.fullcalendar;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  jsdelphisystem,
  sysutils,
  types,
  js,
  web;

const
  fcViewMonth = 'dayGridMonth';
  fcViewWeek = 'timeGridWeek';
  fcViewDay = 'timeGridDay';

  // A list of plugin names, created from the plugin index page
  fcInteractionPlugin = 'interaction';
  fcDayGridPlugin = 'dayGrid';
  fcTimeGridPlugin = 'timeGrid';
  fcListPlugin = 'list';
  fcTimelinePlugin = 'timeline';
  fcResourceDayGridPlugin = 'resourceDayGrid';
  fcResourceTimeGridPlugin = 'resourceTimeGrid';
  fcResourceTimelinePlugin = 'resourceTimeline';
  fcBootstrapPlugin = 'bootstrap';
  fcGoogleCalendarPlugin = 'googleCalendar';
  fcRRulePlugin = 'rrule';
  fcMomentPlugin = 'moment';
  fcMomentTimezonePlugin = 'momentTimeZone';
  fcReactPlugin = 'react';
  fcAngularPlugin = 'angular';
  fcVuePlugin = 'vue';

  fcHeaderFooterTitle = 'title';
  fcHeaderFooterPrev = 'prev';
  fcHeaderFooterNext = 'next';
  fcHeaderFooterPrevYear = 'prevYear';
  fcHeaderFooterNextYear = 'nextYear';
  fcHeaderFooterToday = 'today';

  fcDateFormatNumeric = 'numeric';
  fcDateFormat2Digit = '2-digit';
  fcDateFormatLong = 'long';
  fcDateFormatShort = 'short';
  fcDateFormatNarrow = 'narrow';

  fcEventDisplayAuto = 'auto';
  fcEventDisplayBlock = 'block';
  fcEventDisplayListItem = 'list-item';
  fcEventDisplayBackground = 'background';
  fcEventDisplayInverseBackground = 'inverse-background';
  fcEventDisplayNone = 'none';

  fcSlotFuture = 'fc-slot-future';
  fcSlotPast = 'fc-slot-past';
  fcSlotFri = 'fc-slot-fri';
  fcSlotSat = 'fc-slot-sat';
  fcSlotSun = 'fc-slot-sun';
  fcSlotToday = 'fc-slot-today';

type
  TNativeIntDynArray = array of Int64;
  TProcedural = reference to procedure;
  TDateFunction = reference to function: TJSDate;

  TP2WFCDateFormatter = class external name 'Object'(TJSObject)
    // Use fcDateFormat* constants
    year: string;
    month: string;
    day: string;
    week: string;
    meridiem: string;
    weekday: string;
    hour: string;
    minute: string;
    second: string;
    hour12: Boolean;
    timeZoneName: string;
    omitZeroMinute: Boolean;
    omitCommas: Boolean;
    constructor new;
  end;

  TP2WFCDuration = class external name 'Object'(TJSObject)
    years: NativeInt;
    months: NativeInt;
    days: NativeInt;
    milliseconds: NativeInt;
    constructor new;
  end;

  TP2WFCDateRange = class external name 'Object' (TJSObject)
    start: TJSDate;
    startStr: string; external name 'start';
    startInt: NativeInt; external name 'start';
  end;

  TP2WFCCalendarHeaderFooterOptions = class external name 'Object' (TJSObject)
    // use the fcHeaderFooter consts
    start: string;
    end_: string; external name 'end';
    center: string;

    constructor new;
  end;

  TP2WFCDateFormatHandler = reference to function(aDate: TJSDate): string;

  TP2WFCButtonText = class external name 'Object' (TJSObject)
    today: string;
    month: string;
    week: string;
    day: string;
    list: string;
  end;

  TP2WFCButtonTextRec = record
    today: string;
    month: string;
    week: string;
    day: string;
    list: string;
  end;

  TP2WFCButtonIcons = class external name 'Object' (TJSObject)
    title: string;
    prev: string;
    next: string;
    prevYear: string;
    nextYear: string;
    today: string;
  end;

  TP2WFCButtonIconsRec = record
    today: string;
    title: string;
    prev: string;
    next: string;
    prevYear: string;
    nextYear: string;
  end;

  TP2WFCCustomButtonSpec = record
    text: string;
    click: TJSEventHandler;
  end;

  TP2WFCFontAwesomeSpec = class external name 'Object' (TJSObject)
    close: string;
    title: string;
    prev: string;
    next: string;
    prevYear: string;
    nextYear: string;
    today: string;
  end;

  TP2WFCFontAwesomeSpecRec = record
    close: string;
    title: string;
    prev: string;
    next: string;
    prevYear: string;
    nextYear: string;
    today: string;
  end;

  TP2WFCCustomButtonSpecs = class external name 'Object' (TJSObject)
  private
    function GetButton(Name: string): TP2WFCCustomButtonSpec; external name '[]';
    procedure SetButton(Name: string; const AValue: TP2WFCCustomButtonSpec); external name '[]';
  public
    property buttons[aIndex: string]: TP2WFCCustomButtonSpec read GetButton write SetButton;
  end;

  TP2WFCBusinessHoursSpec = class external name 'Object' (TJSObject)
    daysOfWeek: TIntegerDynArray;
    startTime: TP2WFCDuration;
    startTimeStr: string; external name 'startTime';
    startTimeInt: NativeInt; external name 'startTime';
    endTime: TP2WFCDuration;
    endTimeStr: string; external name 'endTime';
    endTimeInt: NativeInt; external name 'endTime';
  end;

  TP2WFCBusinessHoursSpecArray = array of TP2WFCBusinessHoursSpec;

  TP2WFCCalendarEvent = class;
  TP2WFCCalendarResource = class;
  TP2WFCFullCalendarView = class;

  TP2WFCAllowDropInfo = record
    allDay: Boolean;
    end_: TJSDate; external name 'end';
    resource: TP2WFCCalendarResource;
    start: TJSDate;
    startStr: string;
  end;

  TP2WFCAllowFunction = reference to function(dropInfo: TP2WFCAllowDropInfo; draggedEvent: TP2WFCCalendarEvent): Boolean;

  TP2WFCBaseCalendarResource = class external name 'Object' (TJSObject)
    id: string;
    title: string;
    eventColor: string;
    eventBackgroundColor: string;
    eventBorderColor: string;
    eventTextColor: string;
    eventClassNames: string;
    eventClassNamesArray: TStringDynArray; external name 'eventClassNames';
    eventOverlap: Boolean;
    eventAllow: TP2WFCAllowFunction;
  end;

  TP2WFCBaseCalendarResourceArray = array of TP2WFCBaseCalendarResource;

  TP2WFCCalendarResource = class external name 'Object' (TJSObject)
  end;

  TP2WFCCalendarResourceArray = array of TP2WFCCalendarResource;

  TP2WFCHeightHandler = reference to function: NativeInt;

  TP2WFCBaseCalendarEvent = class external name 'Object' (TJSObject)
    id: string;
    idInt: NativeInt; external name 'id';
    groupId: string;
    groupIdInt: NativeInt;  external name 'groupId';
    allDay: Boolean;
    start: TJSDate;
    startStr: string; external name 'start';
    startInt: NativeInt; external name 'start';
    end_: TJSDate; external name 'end';
    endStr: string; external name 'end';
    endInt: NativeInt; external name 'end';
    daysOfWeek: TIntegerDynArray;
    startTime: TP2WFCDuration;
    startTimeStr: string; external name 'startTime';
    endTime: TP2WFCDuration;
    endTimeStr: string;  external name 'endTime';
    startRecur: TJSDate;
    startRecurStr: string; external name 'startRecur';
    startRecurInt: NativeInt; external name 'startRecur';
    endRecur: TJSDate;
    endRecurStr: string;
    endRecurInt: NativeInt;
    title: string;
    url: string;
    classNames: string;
    classNamesArray: TStringDynArray; external name 'classNames';
    editable: Boolean;
    startEditable: Boolean;
    durationEditable: Boolean;
    resourceEditable: Boolean;
    resourceId: string;
    resourceIds: TStringDynArray;
    display: string;
    overlap: Boolean;
    constraint: string;
    constraintObj: TP2WFCBusinessHoursSpec;  external name 'constraint';
    color: string;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    extendedProps: TJSObject;
  end;

  TP2WFCBaseCalendarEventArray = array of TP2WFCBaseCalendarEvent;

  { TP2WFCBaseCalendarEventHelper }

  TP2WFCBaseCalendarEventHelper = class helper for TP2WFCBaseCalendarEvent
//    constructor new;
    class function event(const aTitle: string; aStart, aEnd: TDateTime): TP2WFCBaseCalendarEvent; static;
  end;

  { TP2WFCCalendarEvent }

  TP2WFCCalendarEvent = class external name 'Object' (TJSObject)
  private
    FAllDay: Boolean; external name 'allDay';
    FbackgroundColor: string; external name 'backgroundColor';
    FBorderColor: string; external name 'borderColor';
    FClassNames: TStringDynArray; external name 'classNames';
    FdurationEditable: Boolean; external name 'durationEditable';
    feditable: Boolean; external name 'editable';
    FEnd: TJSDate; external name 'end';
    fEventConstraint: string; external name 'eventConstraint';
    FExtendedProps: TJSObject; external name 'extendedProps';
    FGroupID: string; external name 'groupId';
    FID: string; external name 'id';
    FOverLap: boolean; external name 'overlap';
    FRendering: string; external name 'rendering';
    FresourceEditable: Boolean; external name 'resourceEditable';
    FSource: JSValue; external name 'source';
    FStart: TJSDate; external name 'start';
    FstartEditable: Boolean; external name 'startEditable';
    FTextColor: string; external name 'textColor';
    FTitle: string; external name 'title';
    FURL: string; external name 'url';
  public
    procedure setProp(const aName: string; AValue: JSValue);
    procedure setExtendedProp(const aName: string; AValue: JSValue);
    procedure setStart(aDate: TJSDate); overload;
    procedure setStart(aDate: string); overload;
    procedure setStart(aDate: NativeInt); overload;
    procedure setStart(aDate: TJSDate; Options: TJSObject); overload;
    procedure setStart(aDate: string; Options: TJSObject); overload;
    procedure setStart(aDate: NativeInt; Options: TJSObject); overload;
    procedure setEnd(aDate: TJSDate); overload;
    procedure setEnd(aDate: string); overload;
    procedure setEnd(aDate: NativeInt); overload;
    procedure setDates(aStart, aEnd: TJSDate); overload;
    procedure setDates(aStart, aEnd: string); overload;
    procedure setDates(aStart, aEnd: NativeInt); overload;
    procedure setDates(aStart, aEnd: TJSDate; Options: TJSObject); overload;
    procedure setDates(aStart, aEnd: string; Options: TJSObject); overload;
    procedure setDates(aStart, aEnd: NativeInt; Options: TJSObject); overload;
    procedure setAllDay(AValue: Boolean); overload;
    procedure setAllDay(AValue: Boolean; Options: TJSObject); overload;
    procedure moveStart(aDelta: TP2WFCDuration); overload;
    procedure moveStart(aDelta: string); overload;
    procedure moveStart(aDelta: NativeInt); overload;
    procedure moveEnd(aDelta: TP2WFCDuration); overload;
    procedure moveEnd(aDelta: string); overload;
    procedure moveEnd(aDelta: NativeInt); overload;
    procedure moveDates(aDelta: TP2WFCDuration); overload;
    procedure moveDates(aDelta: string); overload;
    procedure moveDates(aDelta: NativeInt); overload;
    procedure formatRange(formatter: TP2WFCDateFormatter);
    procedure remove;
    function getResources: TP2WFCCalendarResourceArray;
    procedure setResources(aResources: array of string); overload;
    procedure setResources(aResources: TP2WFCCalendarResourceArray); overload;

    property id: string read FID;
    property groupId: string read FGroupID;
    property allDay: Boolean read FAllDay;
    property start: TJSDate read FStart;
    property end_: TJSDate read FEnd;
    property title: string read FTitle;
    property url: string read FURL;
    property classNames: TStringDynArray read FClassNames;
    property editable: Boolean read feditable;
    property startEditable: Boolean read FstartEditable;
    property eventConstraint: string read fEventConstraint;
    property durationEditable: Boolean read FdurationEditable;
    property resourceEditable: Boolean read FresourceEditable;
    property rendering: string read FRendering;
    property overlap: Boolean read FOverLap;
    property backgroundColor: string read FbackgroundColor;
    property borderColor: string read FBorderColor;
    property textColor: string read FTextColor;
    property extendedProps: TJSObject read FExtendedProps;
    property source: JSValue read FSource;
  end;

  TP2WFCCalendarEventArray = array of TP2WFCCalendarEvent;

  TP2WFCGoogleCalendarEventsSpec = class external name 'Object' (TJSObject)
    googleCalendarId: string;
    // Other options can be specified
  end;

  TP2WFCJSONFeedSpec = class external name 'Object' (TJSObject)
    url: string;
    // Other options can be specified
  end;

  TP2WFCEventGeneratorInfo = record
    start: TJSDate;
    end_: TJSDate; external name 'end';
    startStr: string;
    endStr: string;
    timeZone: string;
  end;

  TP2WFCSelectInfo = record
    start: TJSDate;
    end_: TJSDate; external name 'end';
    resourceId: string;
  end;

  TP2WFCEventMouseInfo = record
    event: TP2WFCCalendarEvent;
    el: TJSHTMLElement;
    jsEvent: TJSEvent;
    view: TP2WFCFullCalendarView;
  end;

  TP2WFCGenerateEventsCallBack = procedure(Res: TP2WFCBaseCalendarEventArray);
  TP2WFCGenerateEventsFailureCallBack = procedure(Res: JSValue);
  TP2WFCCalendarEventGenerator = reference to procedure(info: TP2WFCEventGeneratorInfo; successCallBack: TP2WFCGenerateEventsCallBack; FailCallBack: TP2WFCGenerateEventsFailureCallBack);
  TP2WFCCalendarLoadingCallback = reference to procedure(isLoading: Boolean);
  TP2WFCSelectOverlapHandler = reference to function(event: TJSObject): Boolean;
  TP2WFCSelectAllowHandler = reference to function(info: TP2WFCSelectInfo): Boolean;
  TP2WFCEventMouseEventHandler = reference to procedure(info: TP2WFCEventMouseInfo);

  TP2WFCCalendarEventSource = class external name 'Object' (TJSObject)
    events: TP2WFCBaseCalendarEventArray;
    eventsStr: string; // JSON feed
    eventsFunc: TP2WFCCalendarEventGenerator; // JSON feed
    eventsJSONFeed: TP2WFCJSONFeedSpec; // JSON feed
    eventsArr: TP2WFCCalendarEventArray; // JSON feed
    eventsObjList: TJSObjectDynArray; // Roll your own
    procedure refetch;
    procedure remove;
  end;

  TP2WFCCalendarEventRenderInfo = record
    event: TP2WFCCalendarEvent;
    el: TJSHTMLElement;
    timeText: string;
    isStart: Boolean;
    isEnd: Boolean;
    isMirror: Boolean;
    isPast: Boolean;
    isFuture: Boolean;
    isToday: Boolean;
    view: TP2WFCFullCalendarView;
  end;

  TP2WFCCalendarEventContentObj = class external name 'Object' (TJSObject)
    html: string;
    domNodes: array of TJSHTMLElement;
  end;

  TP2WFCSlotLabelContentObj = TP2WFCCalendarEventContentObj;
  TP2WFCSlotLaneContentObj = TP2WFCCalendarEventContentObj;
  TP2WFCWeekNumberContentObj = TP2WFCCalendarEventContentObj;
  TP2WFCViewContentObj = TP2WFCCalendarEventContentObj;

  TP2WFCRevertHandler = reference to procedure;

  TP2WFCAddEventInfo = class external name 'Object' (TJSObject)
    event: TP2WFCCalendarEvent;
    relatedEvents: TP2WFCCalendarEventArray;
    revert: TP2WFCRevertHandler;
  end;

  TP2WFCChangeEventInfo = class external name 'Object' (TJSObject)
    event: TP2WFCCalendarEvent;
    oldevent: TP2WFCCalendarEvent;
    relatedEvents: TP2WFCCalendarEventArray;
    revert: TP2WFCRevertHandler;
  end;

  TP2WFCRemoveEventInfo = class external name 'Object' (TJSObject)
    event: TP2WFCCalendarEvent;
    relatedEvents: TP2WFCCalendarEventArray;
    revert: TP2WFCRevertHandler;
  end;

  TP2WFCCalendarViewRenderInfo = record
    view: TP2WFCFullCalendarView;
    el: TJSHTMLElement;
  end;

  TP2WFCDayHeaderRenderInfo = record
    date: TJSDate;
    text: string;
    isPast: Boolean;
    isFuture: Boolean;
    isToday: Boolean;
    isOther: Boolean;
    resource: TJSObject;
    el: TJSHTMLElement;
  end;

  TP2WFCDayCellRenderInfo = record
    date: TJSDate;
    dayNumberText: string;
    isPast: Boolean;
    isFuture: Boolean;
    isToday: Boolean;
    isOther: Boolean;
    resource: TJSObject;
    el: TJSHTMLElement;
  end;

  TP2WFCSlotLabelRenderInfo = record
    date: TJSDate;
    text: string;
    isPast: Boolean;
    isFuture: Boolean;
    isToday: Boolean;
    lebel: Integer;
    el: TJSHTMLElement;
  end;

  TP2WFCSlotLaneRenderInfo = TP2WFCSlotLabelRenderInfo;

  TP2WFCWeekNumberRenderInfo = record
    num: Integer;
    text: string;
    date: TJSDate;
  end;

  TP2WFCCalendarEventClassNamesCallback = reference to function(info: TP2WFCCalendarEventRenderInfo): string;
  TP2WFCCalendarEventContentCallback = reference to function(info: TP2WFCCalendarEventRenderInfo): TP2WFCCalendarEventContentObj;
  TP2WFCCalendarEventMountCallback = reference to procedure(info: TP2WFCCalendarEventRenderInfo);

  TP2WFCEventSortCallBack = reference to function(ev1, ev2: TP2WFCCalendarEvent): Integer;
  TP2WFCCalendarEventSourceArray = array of TP2WFCCalendarEventSource;
  TP2WFCAddEventCallBack = reference to procedure(addInfo: TP2WFCAddEventInfo);
  TP2WFCChangeEventCallBack = reference to procedure(changeInfo: TP2WFCChangeEventInfo);
  TP2WFCRemoveEventCallBack = reference to procedure(removeInfo: TP2WFCRemoveEventInfo);

  TP2WFCCalendarViewClassNamesCallback = reference to function(info: TP2WFCCalendarViewRenderInfo): string;
  TP2WFCCalendarViewMountCallback = reference to procedure(info: TP2WFCCalendarViewRenderInfo);

  TP2WFCDayHeaderClassNamesCallback = reference to function(info: TP2WFCDayHeaderRenderInfo): string;
  TP2WFCDayHeaderContentStrCallback = reference to function(info: TP2WFCDayHeaderRenderInfo): string;
  TP2WFCDayHeaderContentObjCallback = reference to function(info: TP2WFCDayHeaderRenderInfo): TP2WFCCalendarEventContentObj;
  TP2WFCDayHeaderMountCallback = reference to procedure(info: TP2WFCDayHeaderRenderInfo);

  TP2WFCDayCellClassNamesCallback = reference to function(info: TP2WFCDayCellRenderInfo): string;
  TP2WFCDayCellContentStrCallback = reference to function(info: TP2WFCDayCellRenderInfo): string;
  TP2WFCDayCellContentObjCallback = reference to function(info: TP2WFCDayCellRenderInfo): TP2WFCCalendarEventContentObj;
  TP2WFCDayCellMountCallback = reference to procedure(info: TP2WFCDayCellRenderInfo);

  TP2WFCSlotLabelClassNamesCallback = reference to function(info: TP2WFCSlotLabelRenderInfo): string;
  TP2WFCSlotLabelContentStrCallback = reference to function(info: TP2WFCSlotLabelRenderInfo): string;
  TP2WFCSlotLabelContentObjCallback = reference to function(info: TP2WFCSlotLabelRenderInfo): TP2WFCCalendarEventContentObj;
  TP2WFCSlotLabelMountCallback = reference to procedure(info: TP2WFCSlotLabelRenderInfo);

  TP2WFCSlotLaneClassNamesCallback = reference to function(info: TP2WFCSlotLaneRenderInfo): string;
  TP2WFCSlotLaneContentStrCallback = reference to function(info: TP2WFCSlotLaneRenderInfo): string;
  TP2WFCSlotLaneContentObjCallback = reference to function(info: TP2WFCSlotLaneRenderInfo): TP2WFCCalendarEventContentObj;
  TP2WFCSlotLaneMountCallback = reference to procedure(info: TP2WFCSlotLaneRenderInfo);

  TP2WFCWeekNumberClassNamesCallback = reference to function(info: TP2WFCWeekNumberRenderInfo): string;
  TP2WFCWeekNumberContentStrCallback = reference to function(info: TP2WFCWeekNumberRenderInfo): string;
  TP2WFCWeekNumberContentObjCallback = reference to function(info: TP2WFCWeekNumberRenderInfo): TP2WFCCalendarEventContentObj;
  TP2WFCWeekNumberMountCallback = reference to procedure(info: TP2WFCWeekNumberRenderInfo);

  TP2WFCFullCalendarOptions = class external name 'Object' (TJSObject)
    plugins: TStringDynArray;
    pluginRaw: TJSArray; external name 'plugins';
    rerenderDelay: NativeInt;
    initialDate: TJSDate;
    initialDateStr: string; external name 'initialDate';
    initialDateInt: NativeInt; external name 'initialDate';
    defaultAllDay: Boolean;
    defaultAllDayEventDuration: TP2WFCDuration;
    defaultAllDayEventDurationStr: string; external name 'defaultAllDayEventDuration';
    defaultTimedEventDuration: TP2WFCDuration;
    defaultTimedEventDurationStr: string; external name 'defaultTimedEventDuration';
    forceEventDuration: Boolean;
    eventDisplay: string;
    eventAdd: TP2WFCAddEventCallBack;
    eventChange: TP2WFCChangeEventCallBack;
    eventRemove: TP2WFCRemoveEventCallBack;
    eventColor: string;
    eventBorderColor: string;
    eventTextColor: string;
    eventBackgroundColor: string;
    dateIncrement: TP2WFCDuration;
    dateIncrementStr: string;
    dateAlignment: string;
    validRange: TP2WFCDateRange;
    headerToolbar: TP2WFCCalendarHeaderFooterOptions;
    headerToolbarBool: Boolean; external name 'headerToolbar';
    footerToolbar: TP2WFCCalendarHeaderFooterOptions;
    footerToolbarBool: Boolean; external name 'footerToolbar';
    titleFormat: TP2WFCDateFormatter;
    titleFormatStr: string; external name 'titleFormat';
    titleFormatFunc: TP2WFCDateFormatHandler; external name 'titleFormat';
    titleRangeSeparator: string;
    buttonText: TP2WFCButtonText;
    buttonTextRec: TP2WFCButtonTextRec;
    buttonIcons: TP2WFCButtonIcons;
    buttonIconsRec: TP2WFCButtonIconsRec;
    customButtons: TP2WFCCustomButtonSpecs;
    customButtonsObj: TJSObject;
    themeSystem: string;
    bootstrapFontAwesome: TP2WFCFontAwesomeSpec;
    bootstrapFontAwesomeRec: TP2WFCFontAwesomeSpecRec;
    weekends: Boolean;
    hiddenDays: TNativeIntDynArray;
    dayHeader: Boolean;
    dayHeaderFormat: TP2WFCDateFormatter;
    dayHeaderClassNames: string;
    dayHeaderClassNamesFunc: TP2WFCDayHeaderClassNamesCallback; external name 'dayHeaderClassNames';
    dayHeaderContent: TP2WFCDayHeaderClassNamesCallback;
    dayHeaderContentStr: TP2WFCDayHeaderContentStrCallback; external name 'dayHeaderContent';
    dayHeaderContentObj: TP2WFCDayHeaderContentObjCallback; external name 'dayHeaderContent';
    dayHeaderDidMount: TP2WFCDayHeaderMountCallback;
    dayHeaderWillUnmount: TP2WFCDayHeaderMountCallback;

    dayCellClassNames: string;
    dayCellClassNamesFunc: TP2WFCDayCellClassNamesCallback; external name 'dayCellClassNames';
    dayCellContent: TP2WFCDayCellClassNamesCallback;
    dayCellContentStr: TP2WFCDayCellContentStrCallback; external name 'dayCellContent';
    dayCellContentObj: TP2WFCDayCellContentObjCallback; external name 'dayCellContent';
    dayCellDidMount: TP2WFCDayCellMountCallback;
    dayCellWillUnmount: TP2WFCDayCellMountCallback;

    slotDuration: TP2WFCDuration;
    slotDurationStr: string; external name 'slotDuration';
    slotLabelInterval: TP2WFCDuration;
    slotLabelIntervalStr: string; external name 'slotLabelInterval';
    slotLabelFormat: TP2WFCDateFormatter;
    slotLabelFormatStr: string;
    slotMinTime: TP2WFCDuration;
    slotMinTimeStr: string; external name 'slotMinTime';
    slotMaxTime: TP2WFCDuration;
    slotMaxTimeStr: string; external name 'slotMaxTime';
    slotMinWidth: Integer;

    slotLabelClassNames: string;
    slotLabelClassNamesFunc: TP2WFCSlotLabelClassNamesCallback; external name 'slotLabelClassNames';
    slotLabelContent: string;
    slotLabelContentObj: TP2WFCSlotLabelContentObj; external name 'slotLabelContent';
    slotLabelContentObjFunc: TP2WFCSlotLabelContentObjCallback; external name 'slotLabelContent';
    slotLabelContentStrFunc: TP2WFCSlotLabelContentStrCallback; external name 'slotLabelContent';
    slotLabelDidMount: TP2WFCSlotLabelMountCallback;
    slotLabelWillUnmout: TP2WFCSlotLabelMountCallback;

    slotLaneClassNames: string;
    slotLaneClassNamesFunc: TP2WFCSlotLaneClassNamesCallback; external name 'slotLaneClassNames';
    slotLaneContent: string;
    slotLaneContentObj: TP2WFCSlotLaneContentObj; external name 'slotLaneContent';
    slotLaneContentObjFunc: TP2WFCSlotLaneContentObjCallback; external name 'slotLaneContent';
    slotLaneContentStrFunc: TP2WFCSlotLaneClassNamesCallback; external name 'slotLaneContent';
    slotLaneDidMount: TP2WFCSlotLaneMountCallback;
    slotLaneWillUnmout: TP2WFCSlotLaneMountCallback;

    weekText: string;
    weekNumberFormat: TP2WFCDateFormatter;
    weekNumberFormatStr: string; external name 'weekNumberFormat';
    weekNumberClassNames: string;
    weekNumberClassNamesFunc: TP2WFCWeekNumberClassNamesCallback; external name 'weekNumberClassNames';
    weekNumberContent: string;
    weekNumberContentObj: TP2WFCWeekNumberContentObj; external name 'weekNumberContent';
    weekNumberContentObjFunc: TP2WFCWeekNumberContentObjCallback; external name 'weekNumberContent';
    weekNumberContentStrFunc: TP2WFCWeekNumberClassNamesCallback; external name 'weekNumberContent';
    weekNumberDidMount: TP2WFCWeekNumberMountCallback;
    weekNumberWillUnmout: TP2WFCWeekNumberMountCallback;

    scrollTime: TP2WFCDuration;
    scrollTimeStr: string; external name 'scrollTime';
    firstDay: Integer;
    locale: string;
    dir: string;
    height: Integer;
    heightStr: string; external name 'height';
    heightFunc: TP2WFCHeightHandler; external name 'height';
    contentHeight: Integer;
    contentHeightStr: string; external name 'contentHeight';
    contentHeightFunc: TP2WFCHeightHandler; external name 'contentHeight';
    aspectRatio: Double;
    handleWindoResize: Boolean;
    windowResizeDelay: Integer;
    showNonCurrentDates: Boolean;
    fixedWeekCount: Boolean;
    businessHours: TP2WFCBusinessHoursSpec;
    businessHoursBool: Boolean; external name 'businessHours';
    businessHoursArray: TP2WFCBusinessHoursSpecArray; external name 'businessHours';
    nowIndicator: Boolean;
    now: TJSDate;
    nowStr: string; external name 'now';
    nowInt: NativeInt; external name 'now';
    nowFunc: TDateFunction; external name 'now';
    eventLimit: Boolean;
    eventLimitInt: Integer; external name 'eventLimit';
    events: TP2WFCBaseCalendarEventArray;
    eventsStr: string; external name 'events'; // JSON feed
    eventsFunc: TP2WFCCalendarEventGenerator; external name 'events'; // JSON feed
    eventsJSONFeed: TP2WFCJSONFeedSpec; external name 'events'; // JSON feed
    eventsArr: TP2WFCCalendarEventArray; external name 'events'; // JSON feed
    eventsObjList: TJSObjectDynArray; external name 'events'; // Roll your own
    eventSources: TP2WFCCalendarEventSourceArray;
    eventClassNames: string;
    eventClassNamesFunc: TP2WFCCalendarEventClassNamesCallback; external name 'eventClassNames';
    eventContent: string;
    eventContentObj: TP2WFCCalendarEventContentObj; external name 'eventContent';
    eventContentObjFunc: TP2WFCCalendarEventContentCallback; external name 'eventContent';
    eventContentStrFunc: TP2WFCCalendarEventClassNamesCallback; external name 'eventContent';
    eventDidMount: TP2WFCCalendarEventMountCallback;
    eventWillUnmout: TP2WFCCalendarEventMountCallback;
    eventTimeFormat: TP2WFCDateFormatter;
    eventOrder: string;
    eventOrderArr: TStringDynArray; external name 'eventOrder';
    eventOrderFunc: TP2WFCEventSortCallBack; external name 'eventOrder';
    progressiveEventRendering: Boolean;

    displayEventTime: Boolean;
    displayEventEnd: Boolean;
    nextDayTreshold: string;
    startParam: string;
    endParam: string;
    timeZoneParam: string;
    timeZone: string;
    lazyFetching: Boolean;
    loading: TP2WFCCalendarLoadingCallback;
    selectable: Boolean;
    selectMirror: Boolean;
    unselectAuto: Boolean;
    unselectCancel: string;
    selectOverlap: Boolean;
    selectOverlapFunc: TP2WFCSelectOverlapHandler; external name 'selectOverlap';
    selectAllow: TP2WFCSelectAllowHandler;
    selectMinDistance: Integer;
    selectConstraint: TP2WFCBusinessHoursSpec;
    eventClick: TP2WFCEventMouseEventHandler;
    eventMouseEnter: TP2WFCEventMouseEventHandler;
    eventMouseLeave: TP2WFCEventMouseEventHandler;
    listDayFormat: TP2WFCDateFormatter;
    listDayBool: Boolean; external name 'listDayFormat';
    listDayAltFormat: TP2WFCDateFormatter;
    listDayAltBool: Boolean; external name 'listDayAltFormat';
    noEventsMessage: string;
    weekNumbers: Boolean;
    dayMaxEvents: Integer;
    dayMaxEventsBool: Boolean;  external name 'dayMaxEvents';
    initialView: string;
    viewClassNames: string;
    viewClassNamesFunc: TP2WFCCalendarViewClassNamesCallback; external name 'eventClassNames';
    viewDidMount: TP2WFCCalendarViewMountCallback;
    viewWillUnmount: TP2WFCCalendarViewMountCallback;

    constructor new;
  end;

  { TP2WFCFullCalendarView }
  TP2WFCDateSelector = class  external name 'Object' (TJSObject)
    start: TJSDate;
    startStr: string;  external name 'start';
    startInt: NativeInt;  external name 'start';
    end_: TJSDate;
    endStr: string;  external name 'end';
    endInt: NativeInt; external name 'end';
    allDay: Boolean;
    resourceId: string;
  end;

  TP2WFCFullCalendarView = class external name 'Object' (TJSObject)
  private
    FType: string; external name 'type';
  public
    title: string;
    activeStart: TJSDate;
    activeEnd: TJSDate;
    currentStart: TJSDate;
    currentEnd: TJSDate;
    property type_: string read FType;
  end;

  TP2WFCFullCalendarEventInfo = record
    view: TP2WFCFullCalendarView;
    el: TJSHTMLElement;
  end;

  TP2WFCDayRenderInfo = record
    date: TJSDate;
    view: TP2WFCFullCalendarView;
    el: TJSHTMLElement;
  end;

  TP2WFCDateClickInfo = record
    date: TJSDate;
    dateStr: string;
    allDay: Boolean;
    dayEl: TJSHTMLElement;
    jsEvent: TJSEvent;
    view: TP2WFCFullCalendarView;
    resource: TP2WFCCalendarResource;
  end;

  TP2WFCDateSelectInfo = record
    start: TJSDate;
    startStr: string;
    end_: TJSDate; external name 'end';
    endStr: string;
    allDay: Boolean;
    jsEvent: TJSEvent;
    view: TP2WFCFullCalendarView;
    resource: TP2WFCCalendarResource;
  end;

  TP2WFCFullCalendarEvent = reference to function(info: TP2WFCFullCalendarEventInfo): Boolean;
  TP2WFCDayRenderEvent = reference to function(info: TP2WFCDayRenderInfo): Boolean;
  TP2WFCViewEvent = reference to function(view: TP2WFCFullCalendarView): Boolean;
  TP2WFCDateClickEvent = reference to procedure(info: TP2WFCDateClickInfo);
  TP2WFCDateSelectEvent = reference to procedure(info: TP2WFCDateSelectInfo);
  TP2WFCDateUnSelectEvent = reference to procedure(event: TJSEvent; view: TP2WFCFullCalendarView);

  TP2WFCFullCalendar = class external name 'FullCalendar.Calendar' (TJSObject)
    constructor new(el: TJSHTMLElement; Options: TP2WFCFullCalendarOptions); // ;
    class function Calendar(el: TJSHTMLElement; Options: TP2WFCFullCalendarOptions): TP2WFCFullCalendar; static;
    function getOption(aName: string): JSValue;
    procedure setOption(aName: string; AValue: JSValue);
    procedure render;
    procedure destroy; reintroduce;
    procedure batchRendering(aBatch: TProcedural);
    procedure prev;
    procedure next;
    procedure prevYear;
    procedure nextYear;
    procedure today;
    procedure gotoDate(aDate: string); overload;
    procedure gotoDate(aDate: NativeInt); overload;
    procedure gotoDate(aDate: TJSDate); overload;
    procedure incrementDate(aDuration: TP2WFCDuration); overload;
    procedure incrementDate(aDuration: string); overload;
    procedure incrementDate(aDuration: NativeInt); overload;
    procedure on_(eventName: string; aHandler: TP2WFCFullCalendarEvent); overload;
    procedure on_(eventName: string; aHandler: TP2WFCDayRenderEvent); overload;
    procedure on_(eventName: string; aHandler: TP2WFCViewEvent); overload;
    procedure on_(eventName: string; aHandler: TP2WFCDateSelectEvent); overload;
    procedure on_(eventName: string; aHandler: TP2WFCDateClickEvent); overload;
    procedure on_(eventName: string; aHandler: TP2WFCDateUnSelectEvent); overload;
    procedure off(eventName: string); overload;
    procedure off(eventName: string; aHandler: TP2WFCFullCalendarEvent); overload;
    procedure off(eventName: string; aHandler: TP2WFCDayRenderEvent); overload;
    procedure off(eventName: string; aHandler: TP2WFCViewEvent); overload;
    procedure off(eventName: string; aHandler: TP2WFCDateClickEvent); overload;
    procedure off(eventName: string; aHandler: TP2WFCDateUnSelectEvent); overload;
    function GetDate: TJSDate;
    function view: TP2WFCFullCalendarView;
    procedure changeView(aViewName: string); overload;
    procedure changeView(aViewName: string; aDate: TJSDate); overload;
    procedure changeView(aViewName: string; aDate: string); overload;
    procedure changeView(aViewName: string; aDate: NativeInt); overload;
    procedure changeView(aViewName: string; aDate: TP2WFCDateRange); overload;
    procedure scrollToTime(aTime: TP2WFCDuration); overload;
    procedure scrollToTime(aTime: string); overload;
    procedure scrollToTime(aTime: NativeInt); overload;
    procedure updateSize;
    function getEvents: TP2WFCCalendarEventArray;
    function getEvent(aID: NativeInt): TP2WFCCalendarEvent; overload;
    function getEvent(aID: string): TP2WFCCalendarEvent; overload;
    function addEvent(aEvent: TP2WFCBaseCalendarEvent): TP2WFCCalendarEvent; overload;
    function addEvent(aEvent: TP2WFCBaseCalendarEvent; source: string): TP2WFCCalendarEvent; overload;
    function addEvent(aEvent: TP2WFCBaseCalendarEvent; source: TP2WFCBaseCalendarEvent): TP2WFCCalendarEvent; overload;
    function getEventSources: TP2WFCCalendarEventSourceArray;
    function getEventSourcebyId(aID: string): TP2WFCCalendarEventSource;
    procedure addEventSource(aSource: TP2WFCCalendarEventGenerator); overload;
    procedure addEventSource(aSource: TP2WFCJSONFeedSpec); overload;
    procedure addEventSource(aSource: TP2WFCCalendarEventArray); overload;
    procedure addEventSource(aSource: TP2WFCBaseCalendarEventArray); overload;
    procedure addEventSource(aSource: string); overload;
    procedure refetchEvents;
    procedure Select(aStart: TJSDate; aEnd: TJSDate); overload;
    procedure Select(aStart: string; aEnd: string); overload;
    procedure Select(aStart: NativeInt; aEnd: NativeInt); overload;
    procedure Select(aStart: TJSDate); overload;
    procedure Select(aStart: string); overload;
    procedure Select(aStart: NativeInt); overload;
    procedure Select(aSel: TP2WFCDateSelector); overload;
    procedure unselect;

    function getAvailableLocaleCodes: TStringDynArray;
    property date: TJSDate read GetDate write gotoDate;
  end;

implementation

class function TP2WFCBaseCalendarEventHelper.event(const aTitle: string; aStart, aEnd: TDateTime): TP2WFCBaseCalendarEvent;
begin
  Result := nil;
end;

end.
