unit Forms.Identity;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, Data.DB,
  Module.Country, Units.Params, pas2web.dadatasethelper, pas2web.dadataset, Units.Contacts, Module.Contacts,
  Datasnap.DBClient, System.Generics.Collections, Units.Types,
  Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmIdentity = class(TfrmBase)
    dsPetition: TP2WDADataset;
    dsPetitiondosid: TLargeintField;
    dsPetitiondoscreatedon: TDateTimeField;
    dsPetitiondoscreatedbyfk: TLargeintField;
    dsPetitiondoschangedon: TDateTimeField;
    dsPetitiondoschangedbyfk: TLargeintField;
    dsPetitiondosclosedon: TDateTimeField;
    dsPetitiondosdescription: TWideStringField;
    dsPetitiondoscompanyfk: TLargeintField;
    dsPetitiondosstatus: TSmallintField;
    dsPetitiondosdatesent: TDateTimeField;
    dsPetitiondosclosedbyfk: TLargeintField;
    dsPetitiondoscloseddescription: TWideStringField;
    dsPetitiondosisbankrupt: TBooleanField;
    dsPetitiondosbankrupton: TDateTimeField;
    dsPetitiondosiskbocancel: TBooleanField;
    dsPetitiondoskbocancelon: TDateTimeField;
    dsPetitiondosloginfk: TLargeintField;
    dsPetitiondosremarks: TMemoField;
    dsPetitiondosreopenedon: TDateTimeField;
    dsPetitiondosflags: TIntegerField;
    dsPetitiondosmonthlyamount: TFMTBCDField;
    dsPetitiondosiscomplete: TBooleanField;
    dsPetitiondoscasefilecounter: TIntegerField;
    dsPetitiondosdebtcauses: TMemoField;
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure SaveApplicant(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure SavePartner(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure firstTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure secondTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure PetitionAfterOpen(DataSet: TDataSet);
    procedure dsPetitionAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
  private
    { Private declarations }
    FPartnerContact: TDMContacts;
    FPetitionerContact: TDMContacts;

    procedure DoSaveDossierData;
    function DossierDataChanged: Boolean;
    Procedure SetupPartnerContact;
    Procedure SetupPetitionerContact;
    procedure DoPartnerSaved(Sender: TObject);
    procedure DoPetitionerSaved(Sender: TObject);
  public
    class function NeedsParams: Boolean; override;
    Property PartnerContact : TDMContacts Read FPartnerContact;
    Property PetitionerContact : TDMContacts Read FPetitionerContact;
  protected procedure LoadDFMValues; override; end;

var
  frmIdentity: TfrmIdentity;

implementation

uses
  System.DateUtils, Units.Strings, Units.ActionUtils, Modules.Server, Units.Logging,
  Units.DADatasetUtils,
  Units.HTMLUtils;

{$R *.dfm}


procedure TfrmIdentity.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FPartnerContact);
  FreeAndNil(FPetitionerContact);
  inherited;
end;

procedure TfrmIdentity.WebFormShow(Sender: TObject);
begin
  inherited;
  dsPetition.ParamByName('DOSSIERID').AsLargeInt := DossierID;
  dsPetition.Load([], nil);
  PetitionerContact.LoadDossierPersonData(DossierID,'SCH',[cdpDossierPerson,cdpPersonal,cdpWPaddress,cdpVPaddress,cdpTelecom,cdpID],[cdpDossierPerson,cdpVPaddress]);
  PartnerContact.LoadDossierPersonData(DossierID,'PART',[cdpDossierPerson,cdpPersonal,cdpWPaddress,cdpVPaddress,cdpTelecom,cdpID],[cdpDossierPerson,cdpVPaddress]);
end;

procedure TfrmIdentity.DoPartnerSaved(Sender : TObject);

begin
  dmServer.ShowOK(SPartnerSavedOK);
  DiscardChanges := not DossierDataChanged;
end;

procedure TfrmIdentity.DoPetitionerSaved(Sender : TObject);

begin
  if DossierDataChanged then
    DoSaveDossierData
  else
    dmServer.ShowOK(SPetitionerSavedOK);
end;


procedure TfrmIdentity.SetupPartnerContact;

Const
  PartnerPersonalFields : TPersonalFieldNames = (
    'edtPartLastName','edtPartFirstname','ddtPartBirth','edtPartBirthPlaceZip', 'edtPartBirthplace',
    'rdPartGender','edtPartNationality','','edtPartProfession','','',
    '','','','');

  PartnerIDFields : TContactIDFieldNames = (
    'edtPartNationalNumber','edtPartCardno','edtCardType','edtPartChipNo',
    'ddtPartCardValidFrom','ddtPartCardValidTill','edtPartCardRemark'
  );

  PartnerAddressFields : TAddressFieldNames = (
    'edtPartAddress','edtPartAddressHouseNo','edtPartAddressZip','edtPartAddressTown','','',''
  );
  PartnerResAddressFields : TAddressFieldNames = (
    'edtPartResAddress','edtPartResAddressHouseNo','edtPartResAddressZip','edtPartResAddressTown','','',''
  );

  PartnerTelecomFields : TTelecomFieldNames = (
    'edtPartTel','edtPartGSM','edtPartEmail'
  );

  PartnerDossierFields : TDossierContactFieldNames = (
    'rdPMSystem','rdPartCivilStatus','rdPettitioner',''
  );

  PartnerDossierBlocks : TDossierContactFieldNames = (
    'PartMarriageSystemBlock','PartCivilStatusBlock','',''
  );

  procedure HandleSearchResult(const SelectedContact: TContactSearchResult; var aAllow : Boolean);
  begin
    aAllow:=(SelectedContact.Gender<>'C');
    if not aAllow  then
      dmServer.ShowError(SErrPartnerCanNotBeCompany);
  end;

Var
  PersonalFields : TPersonalFieldEdits;
  DossierFields : TDossierContactFieldEdits;
  AddressFields : TAddressFieldEdits;
  ResAddressFields : TAddressFieldEdits;
  TelecomFields : TTelecomFieldEdits;
  IDFields : TContactIDFieldEdits;

begin
  PartnerContact.SetupPhoto('fileupload-avatar','spPhoto','spousePhotoChanged');
  PersonalFields.Names:=PartnerPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names:=PartnerAddressFields;
  AddressFields.SetFieldsFromNames;
  ResAddressFields.Names:=PartnerResAddressFields;
  ResAddressFields.SetFieldsFromNames;
  IDFields.Names:=PartnerIDFields;
  IDFields.SetFieldsFromNames;
  TelecomFields.Names:=PartnerTelecomFields;
  TelecomFields.SetFieldsFromNames;
  DossierFields.Names:=PartnerDossierFields;
  DossierFields.SetFieldsFromNames;
  DossierFields.BlockNames:=PartnerDossierBlocks;
  // Pass on to partnercontact...
  PartnerContact.PersonalFieldInfo:=PersonalFields;
  PartnerContact.WPAddressFieldInfo:=AddressFields;
  PartnerContact.VPAddressFieldInfo:=ResAddressFields;
  PartnerContact.TelecomFieldInfo:=TelecomFields;
  PartnerContact.OnSearchResultEx:=@HandleSearchResult;
  PartnerContact.DossierContactFieldInfo:=DossierFields;
  PartnerContact.ContactIDFieldInfo:=IDFields;
  PartnerContact.FormIsReadOnly:=Self.FormIsReadOnly;
  PartnerContact.SearchButtonID:='btnSelectPartnerContact';
  PartnerContact.EditContactButtonID:='btnEditPartnerContact';
  PartnerContact.OnAllSaved:=@DoPartnerSaved;
  PartnerContact.OnContactChanged := OnContactChanged;
end;

procedure TfrmIdentity.SetupPetitionerContact;

Const
  PetitionerPersonalFields : TPersonalFieldNames = (
    'edtAppName','edtAppFirstname','ddtAppBirth','edtAppBirthPlaceZip', 'edtAppBirthPlace',
    'rdAppGender','edtAppNationality','','edtAppProfession','','',
    '','','','');

  PetitionerIDFields : TContactIDFieldNames = (
    'edtAppNationalNumber','edtAppCardNo','edtAppCardType','edtAppChipNo',
    'ddtAppCardValidFrom','ddtAppCardValidTo','edtAppCardRemark'
  );

  PetitionerAddressFields : TAddressFieldNames = (
    'edtAppAddress','edtAppHouseNo','edtAppAddressZip','edtAppAddressTown','','',''
  );
  PetitionerResAddressFields : TAddressFieldNames = (
    'edtAppResAddress','edtAppResHouseNo','edtAppResZip','edtAppResTown','','',''
  );

  PetitionerTelecomFields : TTelecomFieldNames = (
    'edtAddressTel','edtAppGSM','edtAppEmail'
  );

  PetitionerDossierFields : TDossierContactFieldNames = (
    'rdMSystem','rdAppCivilStatus','',''
  );

  PetitionerDossierBlocks : TDossierContactFieldNames = (
    'AppMarriageSystemBlock','AppCivilStatusBlock','',''
  );

Var
  PersonalFields : TPersonalFieldEdits;
  DossierFields : TDossierContactFieldEdits;
  AddressFields : TAddressFieldEdits;
  ResAddressFields : TAddressFieldEdits;
  TelecomFields : TTelecomFieldEdits;
  IDFields : TContactIDFieldEdits;

begin
  PetitionerContact.SetupPhoto('aap-fileupload-avatar','petPhoto','petitionerPhotoChanged');

  PersonalFields.Names:=PetitionerPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names:=PetitionerAddressFields;
  AddressFields.SetFieldsFromNames;
  ResAddressFields.Names:=PetitionerResAddressFields;
  ResAddressFields.SetFieldsFromNames;
  IDFields.Names:=PetitionerIDFields;
  IDFields.SetFieldsFromNames;
  TelecomFields.Names:=PetitionerTelecomFields;
  TelecomFields.SetFieldsFromNames;
  DossierFields.Names:=PetitionerDossierFields;
  DossierFields.SetFieldsFromNames;
  DossierFields.BlockNames:=PetitionerDossierBlocks;
  // Pass on to Petitionercontact...
  PetitionerContact.PersonalFieldInfo:=PersonalFields;
  PetitionerContact.WPAddressFieldInfo:=AddressFields;
  PetitionerContact.VPAddressFieldInfo:=ResAddressFields;
  PetitionerContact.TelecomFieldInfo:=TelecomFields;
  PetitionerContact.DossierContactFieldInfo:=DossierFields;
  PetitionerContact.ContactIDFieldInfo:=IDFields;
  PetitionerContact.EditContactButtonID:='btnEditPetitionerContact';
  PetitionerContact.SearchButtonID:='btnSelectPetitionerContact';
  PetitionerContact.OnAllSaved:=@DoPetitionerSaved;
  PetitionerContact.OnContactChanged:=OnContactChanged;
end;

procedure TfrmIdentity.WebFormCreate(Sender: TObject);

  Procedure ToggleTab(aName : String; aVisible : Boolean);

  begin
    if aVisible then
      begin
      jquery('a[href="#'+aName+'"]').addClass('active');
      jquery('#'+aName).addClass('show').addClass('active');
      end
   else
      begin
      jquery('a[href="#'+aName+'"]').removeClass('active');
      jquery('#'+aName).removeClass('show').removeClass('active');
      end
  end;

Var
  IsTab2 : Boolean;

begin
  inherited;
  dsPetition.DAConnection := DossierConnection;
  FPetitionerContact:=CreateContactModule;
  FPetitionerContact.AllowedContactType:=actPerson;
  FPetitionerContact.Name:='PetitionerContact';
  SetupPetitionerContact;
  FPartnerContact:=CreateContactModule;
  FPartnerContact.AllowedContactType:=actPerson;
  FPartnerContact.Name:='PartnerContact';
  SetupPartnerContact;
  // Show previously selected tab
  isTab2:=Server.GetLocalData('Identity') = '2';
  ToggleTab('first',Not IsTab2);
  ToggleTab('second',IsTab2);

  BindOnChangeEvent;
end;

function TfrmIdentity.DossierDataChanged: Boolean;

begin
  Result:=(alForm['edtDescription'].Value<>dsPetition.FieldByName('dosdescription').asString);
  Result:=Result or (alForm['edtAppRemarks'].Value<>dsPetition.FieldByName('dosremarks').asString);
end;

procedure TfrmIdentity.DoSaveDossierData;

begin
  dsPetition.edit;
  dsPetition.FieldByName('doschangedon').asDateTime := now;
  dsPetition.FieldByName('doschangedbyfk').asLargeInt := Server.UserID;
  dsPetition.FieldByName('dosdescription').asString:=alForm['edtDescription'].Value;
  dsPetition.FieldByName('dosremarks').asString:=alForm['edtAppRemarks'].Value;
  dsPetition.Post;
  dsPetition.ApplyUpdates;
  // Continues in afterApplyUpdates
end;

procedure TfrmIdentity.SaveApplicant(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

begin
  PetitionerContact.SaveIfValid;
end;

procedure TfrmIdentity.SavePartner(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

begin
  FPartnerContact.SaveIfValid;
end;

procedure TfrmIdentity.secondTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Identity', '2');
end;

procedure TfrmIdentity.PetitionAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
    begin
    alForm['edtDescription'].Value:=DataSet.FieldByName('dosdescription').asString;
    alForm['edtAppRemarks'].Value:=DataSet.FieldByName('dosremarks').asString;
    end
  else
    Server.ShowError(Format('No petition data for ID %d', [DossierID]));
end;

procedure TfrmIdentity.dsPetitionAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

begin
  if dmServer.CheckResolveResults(Info) then
  begin
    dmServer.ShowOK(SPetitionerSavedOK);
    DiscardChanges := True;
  end;
end;

procedure TfrmIdentity.firstTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Identity', '1');
end;

class function TfrmIdentity.NeedsParams: Boolean;
begin
  Result:=True;
end;


procedure TfrmIdentity.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsPetition := TP2WDADataset.Create(Self);
  dsPetitiondosid := TLargeintField.Create(Self);
  dsPetitiondoscreatedon := TDateTimeField.Create(Self);
  dsPetitiondoscreatedbyfk := TLargeintField.Create(Self);
  dsPetitiondoschangedon := TDateTimeField.Create(Self);
  dsPetitiondoschangedbyfk := TLargeintField.Create(Self);
  dsPetitiondosclosedon := TDateTimeField.Create(Self);
  dsPetitiondosdescription := TWideStringField.Create(Self);
  dsPetitiondoscompanyfk := TLargeintField.Create(Self);
  dsPetitiondosstatus := TSmallintField.Create(Self);
  dsPetitiondosdatesent := TDateTimeField.Create(Self);
  dsPetitiondosclosedbyfk := TLargeintField.Create(Self);
  dsPetitiondoscloseddescription := TWideStringField.Create(Self);
  dsPetitiondosisbankrupt := TBooleanField.Create(Self);
  dsPetitiondosbankrupton := TDateTimeField.Create(Self);
  dsPetitiondosiskbocancel := TBooleanField.Create(Self);
  dsPetitiondoskbocancelon := TDateTimeField.Create(Self);
  dsPetitiondosloginfk := TLargeintField.Create(Self);
  dsPetitiondosremarks := TMemoField.Create(Self);
  dsPetitiondosreopenedon := TDateTimeField.Create(Self);
  dsPetitiondosflags := TIntegerField.Create(Self);
  dsPetitiondosmonthlyamount := TFMTBCDField.Create(Self);
  dsPetitiondosiscomplete := TBooleanField.Create(Self);
  dsPetitiondoscasefilecounter := TIntegerField.Create(Self);
  dsPetitiondosdebtcauses := TMemoField.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsPetition.BeforeLoadDFMValues;
  dsPetitiondosid.BeforeLoadDFMValues;
  dsPetitiondoscreatedon.BeforeLoadDFMValues;
  dsPetitiondoscreatedbyfk.BeforeLoadDFMValues;
  dsPetitiondoschangedon.BeforeLoadDFMValues;
  dsPetitiondoschangedbyfk.BeforeLoadDFMValues;
  dsPetitiondosclosedon.BeforeLoadDFMValues;
  dsPetitiondosdescription.BeforeLoadDFMValues;
  dsPetitiondoscompanyfk.BeforeLoadDFMValues;
  dsPetitiondosstatus.BeforeLoadDFMValues;
  dsPetitiondosdatesent.BeforeLoadDFMValues;
  dsPetitiondosclosedbyfk.BeforeLoadDFMValues;
  dsPetitiondoscloseddescription.BeforeLoadDFMValues;
  dsPetitiondosisbankrupt.BeforeLoadDFMValues;
  dsPetitiondosbankrupton.BeforeLoadDFMValues;
  dsPetitiondosiskbocancel.BeforeLoadDFMValues;
  dsPetitiondoskbocancelon.BeforeLoadDFMValues;
  dsPetitiondosloginfk.BeforeLoadDFMValues;
  dsPetitiondosremarks.BeforeLoadDFMValues;
  dsPetitiondosreopenedon.BeforeLoadDFMValues;
  dsPetitiondosflags.BeforeLoadDFMValues;
  dsPetitiondosmonthlyamount.BeforeLoadDFMValues;
  dsPetitiondosiscomplete.BeforeLoadDFMValues;
  dsPetitiondoscasefilecounter.BeforeLoadDFMValues;
  dsPetitiondosdebtcauses.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSaveApplicant';
      Name := 'btnSaveApplicant';
      SetEvent(Self, 'OnExecute', 'SaveApplicant');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSavePartner';
      Name := 'btnSavePartner';
      SetEvent(Self, 'OnExecute', 'SavePartner');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'first';
      PreventDefault := False;
      Selector := 'a[href="#first"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'firstTabClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'second';
      PreventDefault := False;
      Selector := 'a[href="#second"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'secondTabClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtDescription';
      Name := 'edtDescription';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtAppRemarks';
      Name := 'edtAppRemarks';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    dsPetition.SetParentComponent(Self);
    dsPetition.Name := 'dsPetition';
    dsPetition.TableName := 'dossier';
    dsPetition.Params.Clear;
    with dsPetition.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsPetition.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>dosid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsPetition.DAOptions := [];
    dsPetition.AfterOpen := PetitionAfterOpen;
    dsPetition.AfterApplyUpdates := dsPetitionAfterApplyUpdates;
    dsPetition.Left := 32;
    dsPetition.Top := 104;
    dsPetitiondosid.SetParentComponent(dsPetition);
    dsPetitiondosid.Name := 'dsPetitiondosid';
    dsPetitiondosid.FieldName := 'dosid';
    dsPetitiondoscreatedon.SetParentComponent(dsPetition);
    dsPetitiondoscreatedon.Name := 'dsPetitiondoscreatedon';
    dsPetitiondoscreatedon.FieldName := 'doscreatedon';
    dsPetitiondoscreatedbyfk.SetParentComponent(dsPetition);
    dsPetitiondoscreatedbyfk.Name := 'dsPetitiondoscreatedbyfk';
    dsPetitiondoscreatedbyfk.FieldName := 'doscreatedbyfk';
    dsPetitiondoschangedon.SetParentComponent(dsPetition);
    dsPetitiondoschangedon.Name := 'dsPetitiondoschangedon';
    dsPetitiondoschangedon.FieldName := 'doschangedon';
    dsPetitiondoschangedbyfk.SetParentComponent(dsPetition);
    dsPetitiondoschangedbyfk.Name := 'dsPetitiondoschangedbyfk';
    dsPetitiondoschangedbyfk.FieldName := 'doschangedbyfk';
    dsPetitiondosclosedon.SetParentComponent(dsPetition);
    dsPetitiondosclosedon.Name := 'dsPetitiondosclosedon';
    dsPetitiondosclosedon.FieldName := 'dosclosedon';
    dsPetitiondosdescription.SetParentComponent(dsPetition);
    dsPetitiondosdescription.Name := 'dsPetitiondosdescription';
    dsPetitiondosdescription.FieldName := 'dosdescription';
    dsPetitiondosdescription.Size := 127;
    dsPetitiondoscompanyfk.SetParentComponent(dsPetition);
    dsPetitiondoscompanyfk.Name := 'dsPetitiondoscompanyfk';
    dsPetitiondoscompanyfk.FieldName := 'doscompanyfk';
    dsPetitiondosstatus.SetParentComponent(dsPetition);
    dsPetitiondosstatus.Name := 'dsPetitiondosstatus';
    dsPetitiondosstatus.FieldName := 'dosstatus';
    dsPetitiondosdatesent.SetParentComponent(dsPetition);
    dsPetitiondosdatesent.Name := 'dsPetitiondosdatesent';
    dsPetitiondosdatesent.FieldName := 'dosdatesent';
    dsPetitiondosclosedbyfk.SetParentComponent(dsPetition);
    dsPetitiondosclosedbyfk.Name := 'dsPetitiondosclosedbyfk';
    dsPetitiondosclosedbyfk.FieldName := 'dosclosedbyfk';
    dsPetitiondoscloseddescription.SetParentComponent(dsPetition);
    dsPetitiondoscloseddescription.Name := 'dsPetitiondoscloseddescription';
    dsPetitiondoscloseddescription.FieldName := 'doscloseddescription';
    dsPetitiondoscloseddescription.Size := 127;
    dsPetitiondosisbankrupt.SetParentComponent(dsPetition);
    dsPetitiondosisbankrupt.Name := 'dsPetitiondosisbankrupt';
    dsPetitiondosisbankrupt.FieldName := 'dosisbankrupt';
    dsPetitiondosbankrupton.SetParentComponent(dsPetition);
    dsPetitiondosbankrupton.Name := 'dsPetitiondosbankrupton';
    dsPetitiondosbankrupton.FieldName := 'dosbankrupton';
    dsPetitiondosiskbocancel.SetParentComponent(dsPetition);
    dsPetitiondosiskbocancel.Name := 'dsPetitiondosiskbocancel';
    dsPetitiondosiskbocancel.FieldName := 'dosiskbocancel';
    dsPetitiondoskbocancelon.SetParentComponent(dsPetition);
    dsPetitiondoskbocancelon.Name := 'dsPetitiondoskbocancelon';
    dsPetitiondoskbocancelon.FieldName := 'doskbocancelon';
    dsPetitiondosloginfk.SetParentComponent(dsPetition);
    dsPetitiondosloginfk.Name := 'dsPetitiondosloginfk';
    dsPetitiondosloginfk.FieldName := 'dosloginfk';
    dsPetitiondosremarks.SetParentComponent(dsPetition);
    dsPetitiondosremarks.Name := 'dsPetitiondosremarks';
    dsPetitiondosremarks.FieldName := 'dosremarks';
    dsPetitiondosremarks.BlobType := ftMemo;
    dsPetitiondosreopenedon.SetParentComponent(dsPetition);
    dsPetitiondosreopenedon.Name := 'dsPetitiondosreopenedon';
    dsPetitiondosreopenedon.FieldName := 'dosreopenedon';
    dsPetitiondosflags.SetParentComponent(dsPetition);
    dsPetitiondosflags.Name := 'dsPetitiondosflags';
    dsPetitiondosflags.FieldName := 'dosflags';
    dsPetitiondosmonthlyamount.SetParentComponent(dsPetition);
    dsPetitiondosmonthlyamount.Name := 'dsPetitiondosmonthlyamount';
    dsPetitiondosmonthlyamount.FieldName := 'dosmonthlyamount';
    dsPetitiondosmonthlyamount.Precision := 12;
    dsPetitiondosmonthlyamount.Size := 0;
    dsPetitiondosiscomplete.SetParentComponent(dsPetition);
    dsPetitiondosiscomplete.Name := 'dsPetitiondosiscomplete';
    dsPetitiondosiscomplete.FieldName := 'dosiscomplete';
    dsPetitiondoscasefilecounter.SetParentComponent(dsPetition);
    dsPetitiondoscasefilecounter.Name := 'dsPetitiondoscasefilecounter';
    dsPetitiondoscasefilecounter.FieldName := 'doscasefilecounter';
    dsPetitiondosdebtcauses.SetParentComponent(dsPetition);
    dsPetitiondosdebtcauses.Name := 'dsPetitiondosdebtcauses';
    dsPetitiondosdebtcauses.FieldName := 'dosdebtcauses';
    dsPetitiondosdebtcauses.BlobType := ftMemo;
  finally
    alForm.AfterLoadDFMValues;
    dsPetition.AfterLoadDFMValues;
    dsPetitiondosid.AfterLoadDFMValues;
    dsPetitiondoscreatedon.AfterLoadDFMValues;
    dsPetitiondoscreatedbyfk.AfterLoadDFMValues;
    dsPetitiondoschangedon.AfterLoadDFMValues;
    dsPetitiondoschangedbyfk.AfterLoadDFMValues;
    dsPetitiondosclosedon.AfterLoadDFMValues;
    dsPetitiondosdescription.AfterLoadDFMValues;
    dsPetitiondoscompanyfk.AfterLoadDFMValues;
    dsPetitiondosstatus.AfterLoadDFMValues;
    dsPetitiondosdatesent.AfterLoadDFMValues;
    dsPetitiondosclosedbyfk.AfterLoadDFMValues;
    dsPetitiondoscloseddescription.AfterLoadDFMValues;
    dsPetitiondosisbankrupt.AfterLoadDFMValues;
    dsPetitiondosbankrupton.AfterLoadDFMValues;
    dsPetitiondosiskbocancel.AfterLoadDFMValues;
    dsPetitiondoskbocancelon.AfterLoadDFMValues;
    dsPetitiondosloginfk.AfterLoadDFMValues;
    dsPetitiondosremarks.AfterLoadDFMValues;
    dsPetitiondosreopenedon.AfterLoadDFMValues;
    dsPetitiondosflags.AfterLoadDFMValues;
    dsPetitiondosmonthlyamount.AfterLoadDFMValues;
    dsPetitiondosiscomplete.AfterLoadDFMValues;
    dsPetitiondoscasefilecounter.AfterLoadDFMValues;
    dsPetitiondosdebtcauses.AfterLoadDFMValues;
  end;
end;

end.
