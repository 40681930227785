unit Forms.Debt;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, pas2web.dadataset,
  Data.DB, Datasnap.DBClient, WEBLib.DB, pas2web.datatables, pas2web.dadatasethelper,
  Forms.DossierDetail;

type
  TFrmBase = TFrmDossierDetail;
  TfrmDebt = class(TfrmBase)
    dcDebts: TP2WDatatable;
    dsDebt: TP2WDADataset;
    dsDebtdebid: TLargeintField;
    dsDebtdebcreatedon: TDateTimeField;
    dsDebtdebcreatedbyfk: TLargeintField;
    dsDebtdebchangedon: TDateTimeField;
    dsDebtdebchangedbyfk: TLargeintField;
    dsDebtdebdossierfk: TLargeintField;
    dsDebtdebrecordtype: TSmallintField;
    dsDebtdebcreditorfk: TLargeintField;
    dsDebtdebdebttypefk: TLargeintField;
    dsDebtdebdebttype: TStringField;
    dsDebtdebstarton: TDateTimeField;
    dsDebtdebreference: TStringField;
    dsDebtdeboriginalamount: TFMTBCDField;
    dsDebtdebcurrentamount: TFMTBCDField;
    dsDebtdebmonthlyamount: TFMTBCDField;
    dsDebtdebisdisputed: TBooleanField;
    dsDebtdebdisputereason: TStringField;
    dsDebtdebdisputecourt: TStringField;
    dsDebtdebdisputedamount: TFMTBCDField;
    dsDebtdebisdelay: TBooleanField;
    dsDebtdebdelayamount: TFMTBCDField;
    dsDebtdebguarantorfk: TLargeintField;
    dsDebtdebiscompleted: TBooleanField;
    dsDebtdebcreditor: TWideMemoField;
    dsDebtdebguarantor: TWideMemoField;
    procedure dsDebtAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure dsDebtAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure getdebttypeasstring(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
  private
    { Private declarations }
    FRoute : String;
    FDeleteID : Int64;
    FDoDelete : Boolean;
    procedure ConfirmDeleteDebt;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDelete(const DebtId: Int64);
  protected procedure LoadDFMValues; override; end;

var
  frmDebt: TfrmDebt;

implementation

uses
  Modules.Server, Units.ActionUtils, lib.bootstrap,  Units.Strings;

{$R *.dfm}

procedure TfrmDebt.DoDelete(const DebtId: Int64);
begin
  FDeleteID := DebtId;
  if dsDebt.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteDebt;
end;

procedure TfrmDebt.dsDebtAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
var
  aMsg : string;

begin
  aMsg := Info.AsString;

  if aMsg = ''  then
  begin
    window.location.href := FRoute;
  end
  else
    ShowError(aMsg);
end;

procedure TfrmDebt.dsDebtAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsDebt.GetNewRows(False);
  dcDebts.Data := aResult;
  dcDebts.RenderTranslated;
  if FDoDelete then
    ConfirmDeleteDebt;
end;

procedure TfrmDebt.getdebttypeasstring(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);

begin
  aOutput := Server.GetParamDisplayValue('DEBTTYP', string(Fields.GetValueByName('debdebttype')));
end;

procedure TfrmDebt.ConfirmDeleteDebt;

  Procedure DoDeleteRecord;

  begin
    if dsDebt.Locate('debid', FDeleteID,[]) then
      begin
      dsDebt.Delete;
      dsDebt.ApplyUpdates;
      end;
  end;

  Procedure DoCancelDelete;

  begin
    window.location.href := FRoute;
  end;

begin
  FDoDelete := False;
  if dsDebt.Locate('debid', FDeleteID,[]) then
    DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
end;


procedure TfrmDebt.WebFormCreate(Sender: TObject);
begin
  inherited;
  dsDebt.DAConnection := DossierConnection;
end;

procedure TfrmDebt.WebFormShow(Sender: TObject);
begin
  inherited;
  server.DoOnParamsLoaded(procedure
    begin
    dsDebt.Load([], nil);
    end);
end;

procedure TfrmDebt.setParams(const Params: TStrings);
begin
  Inherited;
  FRoute := DossierURL('debt');
  jQuery('#addDebt').attr('href',DossierURL('/debt/new'));
  dsDebt.ParamByName('DOSSIERID').asLargeInt := DossierID;
end;

procedure TfrmDebt.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dcDebts := TP2WDatatable.Create(Self);
  dsDebt := TP2WDADataset.Create(Self);
  dsDebtdebid := TLargeintField.Create(Self);
  dsDebtdebcreatedon := TDateTimeField.Create(Self);
  dsDebtdebcreatedbyfk := TLargeintField.Create(Self);
  dsDebtdebchangedon := TDateTimeField.Create(Self);
  dsDebtdebchangedbyfk := TLargeintField.Create(Self);
  dsDebtdebdossierfk := TLargeintField.Create(Self);
  dsDebtdebrecordtype := TSmallintField.Create(Self);
  dsDebtdebcreditorfk := TLargeintField.Create(Self);
  dsDebtdebdebttypefk := TLargeintField.Create(Self);
  dsDebtdebdebttype := TStringField.Create(Self);
  dsDebtdebstarton := TDateTimeField.Create(Self);
  dsDebtdebreference := TStringField.Create(Self);
  dsDebtdeboriginalamount := TFMTBCDField.Create(Self);
  dsDebtdebcurrentamount := TFMTBCDField.Create(Self);
  dsDebtdebmonthlyamount := TFMTBCDField.Create(Self);
  dsDebtdebisdisputed := TBooleanField.Create(Self);
  dsDebtdebdisputereason := TStringField.Create(Self);
  dsDebtdebdisputecourt := TStringField.Create(Self);
  dsDebtdebdisputedamount := TFMTBCDField.Create(Self);
  dsDebtdebisdelay := TBooleanField.Create(Self);
  dsDebtdebdelayamount := TFMTBCDField.Create(Self);
  dsDebtdebguarantorfk := TLargeintField.Create(Self);
  dsDebtdebiscompleted := TBooleanField.Create(Self);
  dsDebtdebcreditor := TWideMemoField.Create(Self);
  dsDebtdebguarantor := TWideMemoField.Create(Self);

  alForm.BeforeLoadDFMValues;
  dcDebts.BeforeLoadDFMValues;
  dsDebt.BeforeLoadDFMValues;
  dsDebtdebid.BeforeLoadDFMValues;
  dsDebtdebcreatedon.BeforeLoadDFMValues;
  dsDebtdebcreatedbyfk.BeforeLoadDFMValues;
  dsDebtdebchangedon.BeforeLoadDFMValues;
  dsDebtdebchangedbyfk.BeforeLoadDFMValues;
  dsDebtdebdossierfk.BeforeLoadDFMValues;
  dsDebtdebrecordtype.BeforeLoadDFMValues;
  dsDebtdebcreditorfk.BeforeLoadDFMValues;
  dsDebtdebdebttypefk.BeforeLoadDFMValues;
  dsDebtdebdebttype.BeforeLoadDFMValues;
  dsDebtdebstarton.BeforeLoadDFMValues;
  dsDebtdebreference.BeforeLoadDFMValues;
  dsDebtdeboriginalamount.BeforeLoadDFMValues;
  dsDebtdebcurrentamount.BeforeLoadDFMValues;
  dsDebtdebmonthlyamount.BeforeLoadDFMValues;
  dsDebtdebisdisputed.BeforeLoadDFMValues;
  dsDebtdebdisputereason.BeforeLoadDFMValues;
  dsDebtdebdisputecourt.BeforeLoadDFMValues;
  dsDebtdebdisputedamount.BeforeLoadDFMValues;
  dsDebtdebisdelay.BeforeLoadDFMValues;
  dsDebtdebdelayamount.BeforeLoadDFMValues;
  dsDebtdebguarantorfk.BeforeLoadDFMValues;
  dsDebtdebiscompleted.BeforeLoadDFMValues;
  dsDebtdebcreditor.BeforeLoadDFMValues;
  dsDebtdebguarantor.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'Yes';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'No';
    end;
    dcDebts.SetParentComponent(Self);
    dcDebts.Name := 'dcDebts';
    dcDebts.Columns.Clear;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debcreditor';
      Title := 'Creditor';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debdebttype';
      Title := 'Debt Type';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'getdebttypeasstring');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debstarton';
      Title := 'Start On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'deboriginalamount';
      Title := 'Original Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debcurrentamount';
      Title := 'Current Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debguarantor';
      Title := 'Guarantor';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debiscompleted';
      Title := 'Is Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{debdossierfk}}/debt/edit/{{debid}}';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{debdossierfk}}/debt/delete/{{debid}}';
    end;
    dcDebts.DataSet := dsDebt;
    dcDebts.Language := lEnglish;
    dcDebts.IsResponsive := True;
    dcDebts.GridID := 'grdDebt';
    dcDebts.UseFieldIndex := True;
    dcDebts.ShowSearch := False;
    dcDebts.ShowNumberOfEntries := False;
    dcDebts.ShowEntriesInfo := False;
    dcDebts.Paginate := True;
    dcDebts.DisplayReadOnly := False;
    dcDebts.CalculateTableWidth := True;
    dcDebts.Left := 32;
    dcDebts.Top := 184;
    dsDebt.SetParentComponent(Self);
    dsDebt.Name := 'dsDebt';
    dsDebt.TableName := 'debtoverview';
    dsDebt.Params.Clear;
    with dsDebt.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDebt.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>debdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>debrecordtype</field><constant type="Integer">1</constant></binaryoperation></binaryoperation></where></query>';
    dsDebt.DAOptions := [doRefreshAllFields];
    dsDebt.AfterOpen := dsDebtAfterOpen;
    dsDebt.AfterApplyUpdates := dsDebtAfterApplyUpdates;
    dsDebt.Left := 32;
    dsDebt.Top := 112;
    dsDebtdebid.SetParentComponent(dsDebt);
    dsDebtdebid.Name := 'dsDebtdebid';
    dsDebtdebid.FieldName := 'debid';
    dsDebtdebcreatedon.SetParentComponent(dsDebt);
    dsDebtdebcreatedon.Name := 'dsDebtdebcreatedon';
    dsDebtdebcreatedon.FieldName := 'debcreatedon';
    dsDebtdebcreatedbyfk.SetParentComponent(dsDebt);
    dsDebtdebcreatedbyfk.Name := 'dsDebtdebcreatedbyfk';
    dsDebtdebcreatedbyfk.FieldName := 'debcreatedbyfk';
    dsDebtdebchangedon.SetParentComponent(dsDebt);
    dsDebtdebchangedon.Name := 'dsDebtdebchangedon';
    dsDebtdebchangedon.FieldName := 'debchangedon';
    dsDebtdebchangedbyfk.SetParentComponent(dsDebt);
    dsDebtdebchangedbyfk.Name := 'dsDebtdebchangedbyfk';
    dsDebtdebchangedbyfk.FieldName := 'debchangedbyfk';
    dsDebtdebdossierfk.SetParentComponent(dsDebt);
    dsDebtdebdossierfk.Name := 'dsDebtdebdossierfk';
    dsDebtdebdossierfk.FieldName := 'debdossierfk';
    dsDebtdebrecordtype.SetParentComponent(dsDebt);
    dsDebtdebrecordtype.Name := 'dsDebtdebrecordtype';
    dsDebtdebrecordtype.FieldName := 'debrecordtype';
    dsDebtdebcreditorfk.SetParentComponent(dsDebt);
    dsDebtdebcreditorfk.Name := 'dsDebtdebcreditorfk';
    dsDebtdebcreditorfk.FieldName := 'debcreditorfk';
    dsDebtdebdebttypefk.SetParentComponent(dsDebt);
    dsDebtdebdebttypefk.Name := 'dsDebtdebdebttypefk';
    dsDebtdebdebttypefk.FieldName := 'debdebttypefk';
    dsDebtdebdebttype.SetParentComponent(dsDebt);
    dsDebtdebdebttype.Name := 'dsDebtdebdebttype';
    dsDebtdebdebttype.FieldName := 'debdebttype';
    dsDebtdebdebttype.Size := 10;
    dsDebtdebstarton.SetParentComponent(dsDebt);
    dsDebtdebstarton.Name := 'dsDebtdebstarton';
    dsDebtdebstarton.FieldName := 'debstarton';
    dsDebtdebreference.SetParentComponent(dsDebt);
    dsDebtdebreference.Name := 'dsDebtdebreference';
    dsDebtdebreference.FieldName := 'debreference';
    dsDebtdebreference.Size := 64;
    dsDebtdeboriginalamount.SetParentComponent(dsDebt);
    dsDebtdeboriginalamount.Name := 'dsDebtdeboriginalamount';
    dsDebtdeboriginalamount.FieldName := 'deboriginalamount';
    dsDebtdeboriginalamount.Precision := 12;
    dsDebtdeboriginalamount.Size := 0;
    dsDebtdebcurrentamount.SetParentComponent(dsDebt);
    dsDebtdebcurrentamount.Name := 'dsDebtdebcurrentamount';
    dsDebtdebcurrentamount.FieldName := 'debcurrentamount';
    dsDebtdebcurrentamount.Precision := 12;
    dsDebtdebcurrentamount.Size := 0;
    dsDebtdebmonthlyamount.SetParentComponent(dsDebt);
    dsDebtdebmonthlyamount.Name := 'dsDebtdebmonthlyamount';
    dsDebtdebmonthlyamount.FieldName := 'debmonthlyamount';
    dsDebtdebmonthlyamount.Precision := 12;
    dsDebtdebmonthlyamount.Size := 0;
    dsDebtdebisdisputed.SetParentComponent(dsDebt);
    dsDebtdebisdisputed.Name := 'dsDebtdebisdisputed';
    dsDebtdebisdisputed.FieldName := 'debisdisputed';
    dsDebtdebdisputereason.SetParentComponent(dsDebt);
    dsDebtdebdisputereason.Name := 'dsDebtdebdisputereason';
    dsDebtdebdisputereason.FieldName := 'debdisputereason';
    dsDebtdebdisputereason.Size := 127;
    dsDebtdebdisputecourt.SetParentComponent(dsDebt);
    dsDebtdebdisputecourt.Name := 'dsDebtdebdisputecourt';
    dsDebtdebdisputecourt.FieldName := 'debdisputecourt';
    dsDebtdebdisputecourt.Size := 127;
    dsDebtdebdisputedamount.SetParentComponent(dsDebt);
    dsDebtdebdisputedamount.Name := 'dsDebtdebdisputedamount';
    dsDebtdebdisputedamount.FieldName := 'debdisputedamount';
    dsDebtdebdisputedamount.Precision := 12;
    dsDebtdebdisputedamount.Size := 0;
    dsDebtdebisdelay.SetParentComponent(dsDebt);
    dsDebtdebisdelay.Name := 'dsDebtdebisdelay';
    dsDebtdebisdelay.FieldName := 'debisdelay';
    dsDebtdebdelayamount.SetParentComponent(dsDebt);
    dsDebtdebdelayamount.Name := 'dsDebtdebdelayamount';
    dsDebtdebdelayamount.FieldName := 'debdelayamount';
    dsDebtdebdelayamount.Precision := 12;
    dsDebtdebdelayamount.Size := 0;
    dsDebtdebguarantorfk.SetParentComponent(dsDebt);
    dsDebtdebguarantorfk.Name := 'dsDebtdebguarantorfk';
    dsDebtdebguarantorfk.FieldName := 'debguarantorfk';
    dsDebtdebiscompleted.SetParentComponent(dsDebt);
    dsDebtdebiscompleted.Name := 'dsDebtdebiscompleted';
    dsDebtdebiscompleted.FieldName := 'debiscompleted';
    dsDebtdebcreditor.SetParentComponent(dsDebt);
    dsDebtdebcreditor.Name := 'dsDebtdebcreditor';
    dsDebtdebcreditor.FieldName := 'debcreditor';
    dsDebtdebcreditor.BlobType := ftWideMemo;
    dsDebtdebguarantor.SetParentComponent(dsDebt);
    dsDebtdebguarantor.Name := 'dsDebtdebguarantor';
    dsDebtdebguarantor.FieldName := 'debguarantor';
    dsDebtdebguarantor.BlobType := ftWideMemo;
  finally
    alForm.AfterLoadDFMValues;
    dcDebts.AfterLoadDFMValues;
    dsDebt.AfterLoadDFMValues;
    dsDebtdebid.AfterLoadDFMValues;
    dsDebtdebcreatedon.AfterLoadDFMValues;
    dsDebtdebcreatedbyfk.AfterLoadDFMValues;
    dsDebtdebchangedon.AfterLoadDFMValues;
    dsDebtdebchangedbyfk.AfterLoadDFMValues;
    dsDebtdebdossierfk.AfterLoadDFMValues;
    dsDebtdebrecordtype.AfterLoadDFMValues;
    dsDebtdebcreditorfk.AfterLoadDFMValues;
    dsDebtdebdebttypefk.AfterLoadDFMValues;
    dsDebtdebdebttype.AfterLoadDFMValues;
    dsDebtdebstarton.AfterLoadDFMValues;
    dsDebtdebreference.AfterLoadDFMValues;
    dsDebtdeboriginalamount.AfterLoadDFMValues;
    dsDebtdebcurrentamount.AfterLoadDFMValues;
    dsDebtdebmonthlyamount.AfterLoadDFMValues;
    dsDebtdebisdisputed.AfterLoadDFMValues;
    dsDebtdebdisputereason.AfterLoadDFMValues;
    dsDebtdebdisputecourt.AfterLoadDFMValues;
    dsDebtdebdisputedamount.AfterLoadDFMValues;
    dsDebtdebisdelay.AfterLoadDFMValues;
    dsDebtdebdelayamount.AfterLoadDFMValues;
    dsDebtdebguarantorfk.AfterLoadDFMValues;
    dsDebtdebiscompleted.AfterLoadDFMValues;
    dsDebtdebcreditor.AfterLoadDFMValues;
    dsDebtdebguarantor.AfterLoadDFMValues;
  end;
end;

end.