unit Forms.TrustAccountList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  Forms.DossierDetail,
  Types,
  pas2web.datatables,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dadatasethelper, pas2web.bootstrap;

type
  TfrmTrustAccountList = class(TfrmBase)
    dsBankAccount: TP2WDADataset;
    dcBankAccount: TP2WDatatable;
    bmConnectAccount: TP2WBSModal;
    dsSettlements: TP2WDADataset;
    dsSettlementsdosid: TLargeintField;
    dsSettlementscodid: TLargeintField;
    dsSettlementsdoscreatedon: TDateTimeField;
    dsSettlementsdosclosedon: TDateTimeField;
    dsSettlementsdosdescription: TStringField;
    dsSettlementsdosstatus: TSmallintField;
    procedure WebFormDestroy(Sender: TObject);
    procedure dsBankAccountAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure alLinkPontoClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure dcBankAccountBtnEditRender(Sender: TObject; Data: JSValue;
      aType: string; row: TJSArray; meta: TJSObject; out aOutput: JSValue);
    procedure dcBankAccountBtnTransactionsRender(Sender: TObject; Data: JSValue;
      aType: string; row: TJSArray; meta: TJSObject; out aOutput: JSValue);
    procedure dcBankAccountBtnLinkRender(Sender: TObject; Data: JSValue;
      aType: string; row: TJSArray; meta: TJSObject; out aOutput: JSValue);
    procedure alLinkToDossierExecute(Sender: TObject;
      Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure dcBankAccountAfterDraw(Sender: TObject);
    procedure dsSettlementsAfterOpen(DataSet: TDataSet);
    procedure actConnAccountOKExecute(Sender: TObject;
      Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    sDossierList: string;
    procedure DisEnableLinkPonto(aDisable: Boolean);
    function GetID(aRow: TJSArray; aField: string): string;
    function GetDossierID(aRow: TJSArray): string;
    function GetCompanyDossierID(aRow: TJSArray): string;
    function GetBankAccountID(aRow: TJSArray): string;
    function GetBankAccountIBAN(aRow: TJSArray): string;
    { Private declarations }
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    class function MyRoutes: TStringDynArray; override;
  protected procedure LoadDFMValues; override; end;

var
  frmTrustAccountList: TfrmTrustAccountList;

implementation

{$R *.dfm}

uses
  rosdk,
  Modules.Server,
  Units.ActionUtils,
  libjquery,
  Units.Strings;

{ TfrmTrustAccountList }

procedure TfrmTrustAccountList.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmTrustAccountList.dcBankAccountAfterDraw(Sender: TObject);
begin
  inherited;
  alForm['actLinkToDossier'].Bind;
end;

procedure TfrmTrustAccountList.dcBankAccountBtnEditRender(Sender: TObject;
  Data: JSValue; aType: string; row: TJSArray; meta: TJSObject;
  out aOutput: JSValue);
begin
  inherited;
  // if no dossier then a disabled button instead of a <a href>
  if GetDossierID(row) = '' then
    aOutput := '<button class="btn btn-sm btn-icon btn-secondary" disabled><i class="fa fa-pencil-alt"></i></button>'
  else
    aOutput := '<a class="btn btn-sm btn-icon btn-secondary" href="#/CSR/' + GetDossierID(row) +
               '/trustaccount/edit/' + GetBankAccountID(row) +
               '" title="Edit"><i class="fa fa-pencil-alt"></i></a> ';
end;

procedure TfrmTrustAccountList.dcBankAccountBtnTransactionsRender(Sender: TObject;
  Data: JSValue; aType: string; row: TJSArray; meta: TJSObject;
  out aOutput: JSValue);
begin
//  #/CSR/{{coddossierid}}/trustaccount/{{bacid}}/movement

  inherited;
  // if no dossier then a disabled button instead of a <a href>
  if GetDossierID(row) = '' then
    aOutput := '<button class="btn btn-sm btn-icon btn-secondary" disabled><i class="fas fa-file-invoice-dollar"></i></button>'
  else
    aOutput := '<a class="btn btn-sm btn-icon btn-secondary" href="#/CSR/' + GetDossierID(row) +
               '/trustaccount/' + GetBankAccountID(row) + '/movement ' +
               '" title="Transactions"><i class="fas fa-file-invoice-dollar"></i></a> ';
end;

procedure TfrmTrustAccountList.dcBankAccountBtnLinkRender(Sender: TObject;
  Data: JSValue; aType: string; row: TJSArray; meta: TJSObject;
  out aOutput: JSValue);
begin
  inherited;
  aOutput := '<button class="btn btn-sm btn-icon btn-secondary" data-accountid="' + GetBankAccountID(row) +
             '" data-iban="' + GetBankAccountIBAN(row) +
             '" data-companydossierid="' + GetCompanyDossierID(row) +
             '"><i class="fas fa-link"></i></button>';
end;

procedure TfrmTrustAccountList.DisEnableLinkPonto(aDisable: Boolean);
begin
  TJSHTMLButtonElement(alForm['actLinkPonto'].ElementHandle).Disabled := aDisable;
end;

procedure TfrmTrustAccountList.WebFormShow(Sender: TObject);

  procedure DoOK(aValue: Boolean);
  begin
    // do nothing... for now
  end;

  procedure DoFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail, SErrFailedToGetPontoStatus);
    Server.ShowError(Msg);
  end;

begin
  inherited;
  dsBankAccount.ParamByname('CompanyID').AsLargeInt := Server.UserInfo.OfficeID;
  dsBankAccount.Load([], nil);
  Server.PontoService.GetIsPontoAccountLinked(Server.UserInfo.OfficeID, @DoOK, @DoFail);
end;

procedure TfrmTrustAccountList.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsBankAccount.DAConnection := Server.CompanyConnection;

  // dossiers ophalen voor het geval er iets wordt gekoppeld
  Server.CSRConnection.Message.ClientID := Server.ClientID;
  dsSettlements.DAConnection := Server.CSRConnection;
  dsSettlements.Load([], nil);
end;

procedure TfrmTrustAccountList.alLinkToDossierExecute(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  aButtonElement: TJSHTMLElement;
  sDossierID: string;
begin
  inherited;
  if Element.Element.localName = 'i' then
    aButtonElement := TJSHTMLElement(Element.Element.ParentElement);
  if Element.Element.localName = 'button' then
    aButtonElement := Element.Element;

  bmConnectAccount.Show;
  // vullen na de Show want anders is de modal nog niet bekend
  TJSHTMLInputElement(document.GetElementByID('edtAccount')).Value := string(aButtonElement.DataSet['iban']);
  TJSHTMLInputElement(document.GetElementByID('edtAccountId')).Value := string(aButtonElement.DataSet['accountid']);
  // vullen CSR's
  // eerst het juiste dossier "selecteren"
  sDossierID := string(aButtonElement.DataSet['companydossierid']);
  sDossierList := StringReplace(sDossierList, ' selected', '', [rfReplaceAll]);
  sDossierList := StringReplace(sDossierList, '<option value="' + sDossierID + '">', '<option value="' + sDossierID + '" selected>', [rfReplaceAll]);
  TJSHTMLElement(document.GetElementByID('cmbxDossier')).InnerHTML := sDossierList;
end;

procedure TfrmTrustAccountList.alLinkPontoClick(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);

  procedure DoOK(aValue: string);
  begin
    Window.Open(aValue, '_blank');
  end;

  procedure DoFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail, SErrFailedToGetPontoLink);
    Server.ShowError(Msg);
  end;

begin
  inherited;
  Server.PontoService.GetPontoLinkURL(@DoOK, @DoFail)
end;

procedure TfrmTrustAccountList.dsBankAccountAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsBankAccount.GetNewRows(False);
  dcBankAccount.Data := aResult;
  dcBankAccount.RenderTranslated;

  JQuery('#totalAccounts').css('display', '');
  JQuery('#total').text(
      IntToStr(dsBankAccount.RecordCount)
  );
end;

procedure TfrmTrustAccountList.dsSettlementsAfterOpen(DataSet: TDataSet);
var
  iID: Int64;
  sDescription: string;
begin
  inherited;
  sDossierList := '<option value="-1" data-translate="chooseoption">--Please choose an option--</option>';

  // hier de options vullen (combobox)
  dsSettlements.First;
  while not dsSettlements.EOF do
  begin
    iID := dsSettlementscodid.asLargeInt;
    sDescription := dsSettlementsdosdescription.AsString;

    sDossierList := sDossierList + '<option value="' + iID.ToString + '">' + sDescription + '</option>';
    dsSettlements.Next;
  end;
  dsSettlements.First;
end;

function TfrmTrustAccountList.GetBankAccountIBAN(aRow: TJSArray): string;
var
  iIdx: Integer;
begin
  Result := '';

  iIdx := dsBankAccount.FieldDefs.IndexOf('baciban');
  if iIdx > -1 then
    Result := string(aRow[iIdx]);
end;

function TfrmTrustAccountList.GetBankAccountID(aRow: TJSArray): string;
begin
  Result := GetID(aRow, 'bacid');
end;

function TfrmTrustAccountList.GetCompanyDossierID(aRow: TJSArray): string;
begin
  Result := GetID(aRow, 'baccompanydossierfk');
end;

function TfrmTrustAccountList.GetDossierID(aRow: TJSArray): string;
begin
  Result := GetID(aRow, 'coddossierid');
end;

function TfrmTrustAccountList.GetID(aRow: TJSArray; aField: string): string;
var
  iIdx: Integer;
  iID: Int64;
begin
  Result := '';

  iIdx := dsBankAccount.FieldDefs.IndexOf(aField);
  if iIdx > -1 then
  begin
    iID := Int64(aRow[iIdx]);

    if iID > 0 then
      Result := iID.ToString;
  end;
end;

procedure TfrmTrustAccountList.actConnAccountOKExecute(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  iDossierID: Int64;
  iAccountID: Int64;
  aOption: TJSHTMLOptionElement;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    Server.ShowError(Msg)
  end;

  procedure OnSuccess;
  begin
    dsBankAccount.Close;
    dsBankAccount.ParamByname('CompanyID').AsLargeInt := Server.UserInfo.OfficeID;
    dsBankAccount.Load([], nil);
  end;

begin
  inherited;
  with TJSHTMLSelectElement(document.GetElementByID('cmbxDossier')) do
    aOption := Item(SelectedIndex);

  if Assigned(aOption) then
    iDossierID := StrToInt64(aOption.Value);

  iAccountID := StrToInt64(TJSHTMLInputElement(document.GetElementByID('edtAccountId')).Value);

  dmServer.CompanyService.ConnectBankAccount(iAccountID, iDossierID, @OnSuccess, @OnFail)
end;

class function TfrmTrustAccountList.MyRoutes: TStringDynArray;
begin
  Result := ['trustaccount']
end;

procedure TfrmTrustAccountList.setParams(const Params: TStrings);
begin
  inherited;
end;

procedure TfrmTrustAccountList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsBankAccount := TP2WDADataset.Create(Self);
  dcBankAccount := TP2WDatatable.Create(Self);
  bmConnectAccount := TP2WBSModal.Create(Self);
  dsSettlements := TP2WDADataset.Create(Self);
  dsSettlementsdosid := TLargeintField.Create(Self);
  dsSettlementscodid := TLargeintField.Create(Self);
  dsSettlementsdoscreatedon := TDateTimeField.Create(Self);
  dsSettlementsdosclosedon := TDateTimeField.Create(Self);
  dsSettlementsdosdescription := TStringField.Create(Self);
  dsSettlementsdosstatus := TSmallintField.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsBankAccount.BeforeLoadDFMValues;
  dcBankAccount.BeforeLoadDFMValues;
  bmConnectAccount.BeforeLoadDFMValues;
  dsSettlements.BeforeLoadDFMValues;
  dsSettlementsdosid.BeforeLoadDFMValues;
  dsSettlementscodid.BeforeLoadDFMValues;
  dsSettlementsdoscreatedon.BeforeLoadDFMValues;
  dsSettlementsdosclosedon.BeforeLoadDFMValues;
  dsSettlementsdosdescription.BeforeLoadDFMValues;
  dsSettlementsdosstatus.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'total';
      Name := 'actTotal';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnLinkPonto';
      Name := 'actLinkPonto';
      SetEvent(Self, 'OnExecute', 'alLinkPontoClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'actLinkToDossier';
      PreventDefault := False;
      Selector := 'button[data-accountid]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'alLinkToDossierExecute');
    end;
    dsBankAccount.SetParentComponent(Self);
    dsBankAccount.Name := 'dsBankAccount';
    dsBankAccount.TableName := 'BankAccount';
    dsBankAccount.Params.Clear;
    with dsBankAccount.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
    end;
    dsBankAccount.DAOptions := [];
    dsBankAccount.AfterOpen := dsBankAccountAfterOpen;
    dsBankAccount.Left := 40;
    dsBankAccount.Top := 96;
    dcBankAccount.SetParentComponent(Self);
    dcBankAccount.Name := 'dcBankAccount';
    dcBankAccount.Columns.Clear;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacname';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'baciban';
      Title := 'Close Status';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacbalance';
      Title := 'Closed On';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'baclastpontocheckon';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Width := 'auto';
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacid';
      RenderMode := rmCustom;
      ButtonType := btEdit;
      SetEvent(Self, 'OnRender', 'dcBankAccountBtnEditRender');
      Width := '10px';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/edit/{{bacid}}';
      ExtraAttributes := 'title="Edit"';
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacid';
      RenderMode := rmCustom;
      ButtonType := btCustom;
      ButtonIconClass := 'fas fa-file-invoice-dollar';
      SetEvent(Self, 'OnRender', 'dcBankAccountBtnTransactionsRender');
      Width := '10px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/{{bacid}}/movement';
      ExtraAttributes := 'title="Transactions"';
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacid';
      RenderMode := rmCustom;
      ButtonType := btCustom;
      ButtonIconClass := 'fas fa-link';
      SetEvent(Self, 'OnRender', 'dcBankAccountBtnLinkRender');
      Width := '10px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'title="Link"';
    end;
    dcBankAccount.DataSet := dsBankAccount;
    dcBankAccount.Language := lEnglish;
    dcBankAccount.IsResponsive := True;
    dcBankAccount.GridID := 'grdTrustAccounts';
    dcBankAccount.UseFieldIndex := True;
    dcBankAccount.ShowSearch := False;
    dcBankAccount.ShowNumberOfEntries := False;
    dcBankAccount.ShowEntriesInfo := False;
    dcBankAccount.Paginate := True;
    dcBankAccount.DisplayReadOnly := False;
    dcBankAccount.CalculateTableWidth := True;
    dcBankAccount.AfterDraw := dcBankAccountAfterDraw;
    dcBankAccount.Left := 40;
    dcBankAccount.Top := 160;
    bmConnectAccount.SetParentComponent(Self);
    bmConnectAccount.Name := 'bmConnectAccount';
    bmConnectAccount.Actions.Actions.Clear;
    with bmConnectAccount.Actions.Actions.Add do
    begin
      ID := 'btnConnAccountOK';
      Name := 'actConnAccountOK';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'actConnAccountOKExecute');
    end;
    bmConnectAccount.ParentID := 'modals';
    bmConnectAccount.BackDrop := True;
    bmConnectAccount.KeyBoard := True;
    bmConnectAccount.Focus := True;
    bmConnectAccount.OKButtonName := 'btnOKConnAccount';
    bmConnectAccount.TemplateName := 'connectaccount';
    bmConnectAccount.Left := 240;
    bmConnectAccount.Top := 160;
    dsSettlements.SetParentComponent(Self);
    dsSettlements.Name := 'dsSettlements';
    dsSettlements.TableName := 'tblCompanyDossier';
    dsSettlements.DAConnection := dmServer.CSRConnection;
    dsSettlements.DAOptions := [];
    dsSettlements.AfterOpen := dsSettlementsAfterOpen;
    dsSettlements.Left := 240;
    dsSettlements.Top := 240;
    dsSettlementsdosid.SetParentComponent(dsSettlements);
    dsSettlementsdosid.Name := 'dsSettlementsdosid';
    dsSettlementsdosid.FieldName := 'dosid';
    dsSettlementscodid.SetParentComponent(dsSettlements);
    dsSettlementscodid.Name := 'dsSettlementscodid';
    dsSettlementscodid.FieldName := 'codid';
    dsSettlementsdoscreatedon.SetParentComponent(dsSettlements);
    dsSettlementsdoscreatedon.Name := 'dsSettlementsdoscreatedon';
    dsSettlementsdoscreatedon.FieldName := 'doscreatedon';
    dsSettlementsdoscreatedon.DisplayFormat := 'dd/mm/yyyy';
    dsSettlementsdosclosedon.SetParentComponent(dsSettlements);
    dsSettlementsdosclosedon.Name := 'dsSettlementsdosclosedon';
    dsSettlementsdosclosedon.FieldName := 'dosclosedon';
    dsSettlementsdosclosedon.DisplayFormat := 'dd/mm/yyyy';
    dsSettlementsdosdescription.SetParentComponent(dsSettlements);
    dsSettlementsdosdescription.Name := 'dsSettlementsdosdescription';
    dsSettlementsdosdescription.FieldName := 'dosdescription';
    dsSettlementsdosdescription.Size := 127;
    dsSettlementsdosstatus.SetParentComponent(dsSettlements);
    dsSettlementsdosstatus.Name := 'dsSettlementsdosstatus';
    dsSettlementsdosstatus.FieldName := 'dosstatus';
  finally
    alForm.AfterLoadDFMValues;
    dsBankAccount.AfterLoadDFMValues;
    dcBankAccount.AfterLoadDFMValues;
    bmConnectAccount.AfterLoadDFMValues;
    dsSettlements.AfterLoadDFMValues;
    dsSettlementsdosid.AfterLoadDFMValues;
    dsSettlementscodid.AfterLoadDFMValues;
    dsSettlementsdoscreatedon.AfterLoadDFMValues;
    dsSettlementsdosclosedon.AfterLoadDFMValues;
    dsSettlementsdosdescription.AfterLoadDFMValues;
    dsSettlementsdosstatus.AfterLoadDFMValues;
  end;
end;

end.
