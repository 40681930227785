unit Units.JSON;

{ ---------------------------------------------------------------------
  Cross-platform JSON handling.
 Use TJSData instead of TJSONObject
 --------------------------------------------------------------------- }

interface

uses
{$IFDEF PAS2JS}
  JS
{$ELSE}
    System.Generics.Collections, // To resolve inline hints
  System.JSON
{$ENDIF},
  SysUtils;

type
  TJSData = {$IFDEF PAS2JS}TJSObject{$ELSE}TJSONObject{$ENDIF};
  TJSArrayData = {$IFDEF PAS2JS}TJSArray{$ELSE}TJSONArray{$ENDIF};
{$IFNDEF PAS2JS}
  TInt64Array = TArray<Int64>;
  TStringArray = TArray<string>;
  LargeInt = Int64;
{$ELSE}
  TStringArray = array of string;
  TInt64Array = array of NativeInt;
  LargeInt = NativeInt;
{$ENDIF}

  TJSONHelper = class helper for TJSData
    function Get(const aName: string; aDefault: string): string; overload;
    function Get(const aName: string; aDefault: Boolean): Boolean; overload;
    function Get(const aName: string; aDefault: Integer): Integer; overload;
    function GetLargeInt(const aName: string; aDefault: LargeInt): LargeInt; overload;
    function Get(const aName: string; aDefault: TJSData): TJSData; overload;
    function Get(const aName: string; aDefault: TInt64Array): TInt64Array; overload;
    function Get(const aName: string; aDefault: Double): Double; overload;
    function Get(const aName: string; aDefault: TStringArray): TStringArray; overload;
    function Get(const aName: string; aDefault: TJSArrayData): TJSArrayData; overload;
    procedure Put(const aName: string; aValue: string); overload;
    procedure Put(const aName: string; aValue: Boolean); overload;
    procedure Put(const aName: string; aValue: Integer); overload;
    procedure Put(const aName: string; aValue: LargeInt); overload;
    procedure Put(const aName: string; aValue: TJSData); overload;
    procedure Put(const aName: string; aValue: TInt64Array); overload;
    procedure Put(const aName: string; aValue: TStringArray); overload;
    procedure Put(const aName: string; aValue: TJSArrayData); overload;
    procedure Put(const aName: string; aValue: Double); overload;
    function CloneData: TJSData;
    class function NewData: TJSData; static;
    class function NewArrayData: TJSArrayData; static;
    function AsJSONString: string;
    class function ParseData(aJSON: string): TJSData; static;
    class procedure FreeData(aData: TJSData); static;
  end;

implementation

{ TJSONHelper }

{$IFNDEF PAS2JS}

{ ---------------------------------------------------------------------
  Native implementation
 --------------------------------------------------------------------- }
function TJSONHelper.Get(const aName: string; aDefault: string): string;
begin
  if not Self.TryGetValue(aName, Result) then
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: Integer): Integer;
begin
  if not Self.TryGetValue(aName, Result) then
    Result := aDefault;
end;

function TJSONHelper.CloneData: TJSData;
begin
  Result := Self.Clone as TJSData
end;

class procedure TJSONHelper.FreeData(aData: TJSData);
begin
  aData.Free;
end;

function TJSONHelper.Get(const aName: string; aDefault: TJSArrayData): TJSArrayData;
begin
  if not Self.TryGetValue(aName, Result) then
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: TStringArray): TStringArray;
var
  Arr: TJSONArray;
  I: Integer;
begin
  if not Self.TryGetValue(aName, Arr) then
    Result := aDefault
  else
  begin
    SetLength(Result, Arr.Count);
    for I := 0 to Arr.Count - 1 do
      Arr.Items[I].TryGetValue(Result[I])
  end;
end;

function TJSONHelper.Get(const aName: string; aDefault: TArray<Int64>): TArray<Int64>;
var
  Arr: TJSONArray;
  I: Integer;
begin
  if not Self.TryGetValue(aName, Arr) then
    Result := aDefault
  else
  begin
    SetLength(Result, Arr.Count);
    for I := 0 to Arr.Count - 1 do
      Arr.Items[I].TryGetValue(Result[I])
  end;
end;

function TJSONHelper.Get(const aName: string; aDefault: Double): Double;
begin
  if not Self.TryGetValue<Double>(aName, Result) then
    Result := aDefault;
end;

function TJSONHelper.GetLargeInt(const aName: string; aDefault: LargeInt): LargeInt;
begin
  if not Self.TryGetValue<LargeInt>(aName, Result) then
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: TJSData): TJSData;
begin
  if not Self.TryGetValue(aName, Result) then
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: Boolean): Boolean;
begin
  if not Self.TryGetValue(aName, Result) then
    Result := aDefault;
end;

class function TJSONHelper.NewArrayData: TJSArrayData;
begin
  Result := TJSONArray.Create;
end;

class function TJSONHelper.NewData: TJSData;
begin
  Result := TJSONObject.Create;
end;

procedure TJSONHelper.Put(const aName: string; aValue: string);
begin
  Self.AddPair(aName, aValue);
end;

procedure TJSONHelper.Put(const aName: string; aValue: Integer);
begin
  Self.AddPair(aName, TJSONNumber.Create(aValue));
end;

class function TJSONHelper.ParseData(aJSON: string): TJSData;
var
  V: TJSONValue;
begin
  V := TJSONObject.ParseJSONValue(aJSON, True, True);
  if V is TJSData then
    Result := TJSData(V)
  else
    Result := nil;
end;

procedure TJSONHelper.Put(const aName: string; aValue: TArray<Int64>);
var
  A: TJSONArray;
  aID: Int64;
begin
  A := TJSONArray.Create;
  for aID in aValue do
    A.Add(aID);
  AddPair(aName, A);
end;

procedure TJSONHelper.Put(const aName: string; aValue: TArray<string>);
var
  A: TJSONArray;
  S: string;
begin
  A := TJSONArray.Create;
  for S in aValue do
    A.Add(S);
  AddPair(aName, A);
end;

procedure TJSONHelper.Put(const aName: string; aValue: Int64);
begin
  Self.AddPair(aName, TJSONNumber.Create(aValue));
end;

procedure TJSONHelper.Put(const aName: string; aValue: Double);
begin
  Self.AddPair(aName, TJSONNumber.Create(aValue));
end;

procedure TJSONHelper.Put(const aName: string; aValue: Boolean);
begin
  Self.AddPair(aName, TJSONBool.Create(aValue));
end;

procedure TJSONHelper.Put(const aName: string; aValue: TJSData);
begin
  Self.AddPair(aName, aValue);
end;

procedure TJSONHelper.Put(const aName: string; aValue: TJSArrayData);
begin
  Self.AddPair(aName, aValue);
end;

function TJSONHelper.AsJSONString: string;
begin
  Result := Self.ToString;
end;

{$ELSE}
{ ---------------------------------------------------------------------
  JS native implementation
 --------------------------------------------------------------------- }

{ TJSONHelper }

function TJSONHelper.Get(const aName: string; aDefault: Double): Double;
begin
  if hasOwnProperty(aName) and IsNumber(Properties[aName]) then
    Result := Double(Properties[aName])
  else
    Result := aDefault;
end;

function TJSONHelper.GetLargeInt(const aName: string; aDefault: LargeInt): LargeInt;
begin
  if hasOwnProperty(aName) and IsInteger(Properties[aName]) then
    Result := LargeInt(Properties[aName])
  else
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: string): string;
begin
  if hasOwnProperty(aName) and IsString(Properties[aName]) then
    Result := string(Properties[aName])
  else
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: Boolean): Boolean;
begin
  if hasOwnProperty(aName) and isBoolean(Properties[aName]) then
    Result := Boolean(Properties[aName])
  else
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: Integer): Integer;
begin
  if hasOwnProperty(aName) and IsInteger(Properties[aName]) then
    Result := Integer(Properties[aName])
  else
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: TJSData): TJSData;
begin
  if hasOwnProperty(aName) and isObject(Properties[aName]) then
    Result := TJSData(Properties[aName])
  else
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: TJSArrayData): TJSArrayData;
begin
  if hasOwnProperty(aName) and isArray(Properties[aName]) then
    Result := TJSArrayData(Properties[aName])
  else
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: TInt64Array): TInt64Array;
begin
  if hasOwnProperty(aName) and isArray(Properties[aName]) then
    Result := TInt64Array(Properties[aName])
  else
    Result := aDefault;
end;

function TJSONHelper.Get(const aName: string; aDefault: TStringArray): TStringArray; overload;
begin
  if hasOwnProperty(aName) and isArray(Properties[aName]) then
    Result := TStringArray(Properties[aName])
  else
    Result := aDefault;
end;

procedure TJSONHelper.Put(const aName: string; aValue: string);
begin
  Properties[aName] := aValue;
end;

procedure TJSONHelper.Put(const aName: string; aValue: Boolean);
begin
  Properties[aName] := aValue;
end;

procedure TJSONHelper.Put(const aName: string; aValue: Integer);
begin
  Properties[aName] := aValue;
end;

procedure TJSONHelper.Put(const aName: string; aValue: Int64);
begin
  Properties[aName] := aValue;
end;

procedure TJSONHelper.Put(const aName: string; aValue: Double);
begin
  Properties[aName] := aValue;
end;

procedure TJSONHelper.Put(const aName: string; aValue: TJSData);
begin
  Properties[aName] := aValue;
end;

procedure TJSONHelper.Put(const aName: string; aValue: TInt64Array);
begin
  Properties[aName] := aValue;
end;

procedure TJSONHelper.Put(const aName: string; aValue: TStringArray);
begin
  Properties[aName] := aValue;
end;

procedure TJSONHelper.Put(const aName: string; aValue: TJSArrayData);
begin
  Properties[aName] := aValue;
end;

function TJSONHelper.CloneData: TJSData;
begin
  Result := ParseData(AsJSONString);
end;

class function TJSONHelper.NewData: TJSData;
begin
  Result := TJSData.New;
end;

class function TJSONHelper.NewArrayData: TJSArrayData;
begin
  Result := TJSArray.New;
end;

function TJSONHelper.AsJSONString: string;
begin
  Result := TJSJSON.Stringify(Self)
end;

class function TJSONHelper.ParseData(aJSON: string): TJSData;
begin
  Result := TJSJSON.parseObject(aJSON);
end;

class procedure TJSONHelper.FreeData(aData: TJSData);
begin
  jsdelete(JS.New(['a', aData]), 'a');
end;

{$ENDIF}

end.
