unit Forms.CSR.Expenses;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Expenses, pas2web.datatables,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRExpenses = class(TfrmExpenses)
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRExpenses: TfrmCSRExpenses;

implementation

{$R *.dfm}

{ TfrmCSRExpenses }

function TfrmCSRExpenses.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRExpenses.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRExpenses.GetHTMLFileName: string;

begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRExpenses.WebFormCreate(Sender: TObject);
begin
  Inherited;
  dcExpense.Columns[4].ButtonURL:='#/CSR/{{expdossierfk}}/expense/edit/{{expid}}';
  dcExpense.Columns[5].ButtonURL:='#/CSR/{{expdossierfk}}/expense/delete/{{expid}}';
end;

procedure TfrmCSRExpenses.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcExpense.BeforeLoadDFMValues;
  try
    dcExpense.Columns.Clear;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expamount';
      Title := 'Amount ';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
      NumericFormat := '###,###.00';
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expcategory';
      Title := 'Category';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcExpenseGetCategoryValue');
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expkind';
      Title := 'Kind';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcExpenseGetKindValue');
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'exppaymentperiod';
      Title := 'Period';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcExpenseGetPeriodicityValue');
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{expdossierfk}}/expense/edit/{{expid}}';
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{expdossierfk}}/expense/delete/{{expid}}';
    end;
  finally
    dcExpense.AfterLoadDFMValues;
  end;
end;

end.