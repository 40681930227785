unit Units.Looper;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  js, libjquery;

Type
  TJSLooper = Class
    external name 'Theme'(TJSObject)Public colors: TJSObject;
    skins: TJSObject;
    skin: string;
    hasCompactMenu: boolean;
    autoInit: boolean;
    // next getPrototypeOf ...
    procedure init;
    procedure objToArray(arg1: JSValue);
    procedure placeholderShown;
    procedure objectFitFallback;
    procedure tooltips;
    procedure popovers;
    function nestedDropdown: TJSObject;
    procedure inputClearable;
    procedure inputGroup;
    procedure inputNumber;
    procedure fileInputBehavior;
    procedure togglePasswordVisibility;
    procedure indeterminateCheckboxes;
    procedure formValidation;
    procedure cardExpansion;
    procedure modalScrollable;
    procedure autofocusInputBehaviour;
    function getColors(color: string): TJSObject;
    function getMutedColor: string;
    function getLightColor: string;
    function getDarkColor: string;
    procedure setSkin(arg1: JSValue);
    procedure invertGrays;
    procedure asideBackdrop;
    function showAsideBackdrop: TJQuery;
    function hideAsideBackdrop: TJQuery;
    procedure showAside;
    procedure hideAside;
    procedure toggleAside;
    function getMenuOptions: TJSObject;
    procedure asideMenu;
    procedure collapseAsideMenu;
    procedure expandAsideMenu;
    procedure toggleAsideMenu;
    procedure watchAside;
    procedure handleAsideMenu;
    procedure showSidebar(arg1: JSValue);
    procedure hideSidebar(arg1: JSValue);
    procedure toggleSidebar(arg1: JSValue);
    procedure sidebarBackdrop;
    procedure sidebar;
    procedure pageExpander;
    procedure hamburger;
    procedure publisher;
    procedure tasksStyle;
    procedure filterList;
    procedure radioList;
    procedure checkboxList;
    procedure smoothScroll;
    procedure perfectScrollbar;
    procedure masonry;
    procedure chartjs;
    function flotDefaultOptions: TJSObject;
    procedure sparkline;
    procedure easypie;
    function knob: boolean;
    procedure sortable;
    procedure nestable;
    procedure plyr;
    function jsTreeTypes: TJSObject;
    procedure bootstrapSelect;
    procedure select2;
    procedure atwho;
    function tribute: String;
    function flatpickr(aFlatPickr: TJSObject): TJSObject;
    procedure colorpicker;
    procedure touchspin;
    procedure nouislider;
    procedure summernote;
    procedure quill;
    procedure simplemde;
    procedure maskInput;
    procedure headroom;
    procedure zxcvbn;
    procedure aos;
    procedure eventProps;
    procedure watchMQ;
    procedure watchIE;
    function isOpera: boolean;
    function isFirefox: boolean;
    function isSafari: boolean;
    function isIE: boolean;
    function isEdge: boolean;
    function isChrome: boolean;
    function isBlink: boolean;
    procedure browserFlagging;
    procedure osFlagging;
    function isToggleScreenUp: boolean;
    function isToggleScreenDown: boolean;
    procedure isExists(arg1: JSValue);
    function rgbToHex(r, g, b: Integer): String;
    function hexToRgb(aShort: string): string;
    function hexToRgba(aShort, aAlpha: String): String;
  end;

Var
  Looper: TJSLooper;
external name 'Looper';

implementation

end.
