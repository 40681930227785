unit Units.Logging;

interface

uses
{$IFDEF POSIX}
  Posix.UniStd, // getpid
{$ENDIF}
{$IFDEF MSWINDOWS}
  Winapi.Windows, // GetProcessID & Silence compiler warnings about not expanded inlines.
{$ENDIF}
  System.SysUtils, System.Classes
{$IFNDEF PAS2JS}, Syncobjs{$ENDIF}
    ;

{ $DEFINE USEGENERIC }

type
  TLogEvent = procedure(Sender: TObject; const Msg: string) of object;
  TLogTarget = (ltConsole, ltFile, ltSystem);
  TLogTargets = set of TLogTarget;
  TLogType = (ltInfo, ltSQL, ltTrace, ltCall, ltError, ltDebug);
  TLogTypes = set of TLogType;

{$IFDEF PAS2JS}

  TCriticalSection = class(TObject)
    procedure Enter;
    procedure Leave;
  end;
{$ENDIF}

  TLog = class(TObject)
  private
    Flock: TCriticalSection;
    FIndent: string;
    FOnLog: TLogEvent;
    FTargets: TLogTargets;
    FUseSequence: Boolean;
    FUseTimeStamp: Boolean;
    FBaseFileName: string;
    FPrependTimeStamp: Boolean;
    FDeleteFilesAfterDays: Integer;
    FEnabled: Boolean;
    FLogTypes: TLogTypes;
    FProgramName: string;
    FProcessID: Integer;
    FUseProcessData: Boolean;
{$IFNDEF PAS2JS}
    FLogDate: TDateTime;
    FLogFile: TFileStream;
{$ENDIF}
    procedure SetTargets(const Value: TLogTargets);
    procedure SetUseSequence(const Value: Boolean);
    procedure SetUseTimeStamp(const Value: Boolean);
    function GetFileName: string;
{$IFNDEF PAS2JS}
    procedure CheckRotateFile(aTime: TDateTime);
    procedure MaybeDeleteLogs;
    procedure OpenLog(aTime: TDateTime);
    procedure CloseLog;
    procedure Lock;
    procedure UnLock;
{$ENDIF}
    procedure SetEnabled(const Value: Boolean);
    procedure SetBaseFileName(const Value: string);
    procedure SetOnLog(const Value: TLogEvent);
  protected
    function AllowLog(aLogType: TLogType): Boolean; inline;
    procedure RawLog(aTime: TDateTime; aMessage: string);
  public
    constructor Create;
    destructor Destroy; override;
    class function TimeDiff(aThen, ANow: TDateTime): string;
    // Format prefix message
    function FormatPrefix(aLogType: TLogType; aTime: TDateTime; aClassName, aMethodName: string): string; overload;
    function FormatPrefix(aLogType: TLogType; aTime: TDateTime; aClassName, aMethodName: string; aGUID: TGUID)
      : string; overload;
    // Raw methods
{$IFDEF USEGENERIC}
    procedure Add<T>(aType: TLogType; const aClassName, aMethodName, aMessage: string; const aGeneric: T); overload;
{$ENDIF}
    procedure Add(aType: TLogType; const aClassName, aMethodName, aMessage: string; aSession: TGUID); overload;
    procedure Add(aType: TLogType; const aClassName, aMethodName, aMessage: string); overload;
    procedure Add(aType: TLogType; const aClassName, aMethodName, Fmt: string; const Args: array of const ); overload;

    procedure Log(aLogType: TLogType; const aClassName, aMethodName, aMessage: string); overload; inline;
    procedure Log(aLogType: TLogType; const aClassName, aMethodName, aFmt: string; const Args: array of const); overload;

    procedure LogTimeSince(const aClassName, aMethodName: string; aStartTime: TDateTime; const aMessage: string);
      overload; inline;
    procedure LogTimeSince(const aClassName, aMethodName: string; aStartTime: TDateTime; const Fmt: string;
      const Args: array of const); overload;

    // Easy access methods
    procedure EnterMethod(const aClassName, aMethodName, aMessage: string; aSession: TGUID); overload;
    procedure EnterMethod(const aClassName, aMethodName, aMessage: string); overload;
    procedure EnterMethod(const aClassName, aMethodName: string); overload;
    procedure ExitMethod(const aClassName, aMethodName, aMessage: string; aSession: TGUID); overload;
    procedure ExitMethod(const aClassName, aMethodName, aMessage: string); overload;
    procedure ExitMethod(const aClassName, aMethodName: string); overload;
    procedure Debug(const aClassName, aMethodName, aMessage: string); overload; inline;
    procedure Debug(const aClassName, aMethodName, Fmt: string; const Args: array of const); overload;
    procedure Error(const aClassName, aMethodName, aMessage: string); overload; inline;
    procedure Error(const aClassName, aMethodName, Fmt: string; const Args: array of const); overload;
    procedure AddException(const aClassName, aMethodName: string; E: Exception); overload;
    procedure AddException(const aClassName, aMethodName: string; aGUID: TGUID; E: Exception); overload;
    procedure AddException(const aClassName, aMethodName, aMessage: string; aGUID: TGUID; E: Exception); overload;
    procedure AddException(const aClassName, aMethodName, aMessage: string; E: Exception); overload;

  public
    // Configuration
    property DeleteFilesAfterDays: Integer read FDeleteFilesAfterDays write FDeleteFilesAfterDays;
    // Prepend timestamp to messages
    property PrependTimeStamp: Boolean read FPrependTimeStamp write FPrependTimeStamp;
    // Append timestamp to filename
    property UseTimeStamp: Boolean read FUseTimeStamp write SetUseTimeStamp;
    // Prepend process ID and name to message
    property UseProcessData: Boolean read FUseProcessData write FUseProcessData;
    // use sequence in filename
    property UseSequence: Boolean read FUseSequence write SetUseSequence;
    // base filename, will be transformed in accordance with other properties.
    property BaseFileName: string read FBaseFileName write SetBaseFileName;
    // Current filename
    property CurrentFileName: string read GetFileName;
    // Custom event logging hook. Enables logging when set.
    property OnLog: TLogEvent read FOnLog write SetOnLog;
    // Targets to which to log (OnLog is always called when set)
    property Targets: TLogTargets read FTargets write SetTargets;
    // Can be used to enable/Disable logging.
    property Enabled: Boolean read FEnabled write SetEnabled;
    // Message types to log.
    property LogTypes: TLogTypes read FLogTypes write FLogTypes;
    // Process ID to log.
    property ProcessID: Integer read FProcessID write FProcessID;
    // Program name to log.
    property ProgramName: string read FProgramName write FProgramName;
  end;

function Log: TLog;

procedure RemoveLog;

const
  LogFileExt = '.csl';

implementation

uses
  System.DateUtils, System.TypInfo, System.Variants;

var
  _Log: TLog;

function Log: TLog;
begin
  if not Assigned(_Log) then
    _Log := TLog.Create;
  Result := _Log;
end;

procedure RemoveLog;
begin
  FreeAndNil(_Log);
end;

{ TLog }

procedure TLog.RawLog(aTime: TDateTime; aMessage: string);
{$IFNDEF PAS2JS}
var
  uMsg: UTF8String;
{$ENDIF}
begin
{$IFNDEF PAS2JS}
  CheckRotateFile(aTime);
{$ENDIF}
  if Assigned(OnLog) then
    OnLog(Self, aMessage);
{$IFNDEF PAS2JS}
  if ltFile in FTargets then
  begin
    Lock;
    try
      if Assigned(FLogFile) then
      begin
        uMsg := UTF8Encode(aMessage + sLineBreak);
        FLogFile.WriteBuffer(uMsg[1], Length(uMsg));
      end;
    finally
      UnLock;
    end;
  end;
{$ENDIF}
  if ltConsole in FTargets then
    Writeln(aMessage);
end;

procedure TLog.Add(aType: TLogType; const aClassName, aMethodName, aMessage: string);
var
  aMsg: string;
  T: TDateTime;
begin
  if not Enabled then
    Exit;
  if not AllowLog(aType) then
    Exit;
  T := Now;
  aMsg := FormatPrefix(aType, T, aClassName, aMethodName) + FIndent + aMessage;
  RawLog(T, aMsg)
end;

procedure TLog.Add(aType: TLogType; const aClassName, aMethodName, Fmt: string; const Args: array of const);
var
  S: string;
begin
  try
    S := Format(Fmt, Args);
  except
    on E: Exception do
      S := Format('Error formatting log message "%s" with %d arguments: %s', [Fmt, Length(Args), E.Message]);
  end;
  Add(aType, aClassName, aMethodName, S);
end;

procedure TLog.Add(aType: TLogType; const aClassName,
  aMethodName, aMessage: string; aSession: TGUID);
var
  aMsg: string;
  T: TDateTime;
begin
  if not Enabled then
    Exit;
  if not AllowLog(aType) then
    Exit;
  T := Now;
  aMsg := FormatPrefix(aType, T, aClassName, aMethodName, aSession) + FIndent + aMessage;
  RawLog(T, aMsg)
end;

constructor TLog.Create;
begin
  FUseProcessData := False;
  Flock := TCriticalSection.Create;
  FBaseFileName := ChangeFileExt(ParamStr(0), '.log');
  FTargets := [];
  FUseSequence := true;
  FLogTypes := [ltError, ltInfo];
  FProgramName := ChangeFileExt(ExtractFileName(ParamStr(0)), '');
  FIndent := ' ';
{$IFDEF POSIX}
  FProcessID := getpid;
{$ELSE}
{$IFNDEF PAS2JS}
  FProcessID := GetCurrentProcessId();
{$ENDIF}
{$ENDIF}
end;

procedure TLog.Debug(const aClassName, aMethodName, Fmt: string; const Args: array of const);
begin
  Add(ltDebug, aClassName, aMethodName, Fmt, Args);
end;

procedure TLog.Debug(const aClassName, aMethodName, aMessage: string);
begin
  Add(ltDebug, aClassName, aMethodName, aMessage);
end;

destructor TLog.Destroy;
begin
{$IFNDEF PAS2JS}
  CloseLog;
{$ENDIF}
  FreeAndNil(Flock);
  inherited;
end;

function TLog.AllowLog(aLogType: TLogType): Boolean;
begin
  Result := (aLogType in FLogTypes);
end;

procedure TLog.EnterMethod(const aClassName, aMethodName, aMessage: string; aSession: TGUID);
begin
  Add(ltTrace, aClassName, aMethodName, 'Enter method: ' + aMessage, aSession);
  FIndent := FIndent + '->';
end;

procedure TLog.EnterMethod(const aClassName, aMethodName: string);
begin
  EnterMethod(aClassName, aMethodName, '');
end;

procedure TLog.EnterMethod(const aClassName, aMethodName, aMessage: string);
begin
  EnterMethod(aClassName, aMethodName, aMessage, default(TGUID));
end;

procedure TLog.Error(const aClassName, aMethodName, Fmt: string; const Args: array of const);
begin
  Log(ltError, aClassName, aMethodName, Fmt, Args);
end;

procedure TLog.Error(const aClassName, aMethodName, aMessage: string);
begin
  Log(ltError, aClassName, aMethodName, aMessage);
end;

procedure TLog.Log(aLogType: TLogType; const aClassName, aMethodName, aMessage: string);
begin
  Add(aLogType, aClassName, aMethodName, aMessage);
end;

procedure TLog.Log(aLogType: TLogType; const aClassName, aMethodName, aFmt: string; const Args: array of const);
begin
  Add(aLogType, aClassName, aMethodName, Format(aFmt, Args));
end;

class function TLog.TimeDiff(aThen, ANow: TDateTime): string;

  procedure AddR(S: string);
  begin
    if (Result <> '') then
      Result := Result + ', ';
    Result := Result + S;
  end;

var
  D: Integer;
begin
  if HourSpan(ANow, aThen) > 1 then
  begin
    D := HoursBetween(ANow, aThen);
    AddR(IntToStr(D) + ' h');
    ANow := IncHour(ANow, -D);
  end;
  if MinuteSpan(ANow, aThen) > 1 then
  begin
    D := MinutesBetween(ANow, aThen);
    Result := IntToStr(D) + ' m';
    ANow := IncMinute(ANow, -D);
  end;
  if SecondSpan(ANow, aThen) > 1 then
  begin
    D := SecondsBetween(ANow, aThen);
    AddR(IntToStr(D) + ' s');
    ANow := IncSecond(ANow, -D);
  end;
  if MilliSecondSpan(ANow, aThen) > 1 then
  begin
    D := MilliSecondsBetween(ANow, aThen);
    AddR(IntToStr(D) + ' ms');
  end;
  if Result = '' then
    Result := '0 ms';
end;

procedure TLog.LogTimeSince(const aClassName, aMethodName: string; aStartTime: TDateTime; const Fmt: string;
  const Args: array of const);
begin
  LogTimeSince(aClassName, aMethodName, aStartTime, Format(Fmt, Args))
end;

procedure TLog.LogTimeSince(const aClassName, aMethodName: string; aStartTime: TDateTime; const aMessage: string);
var
  N: TDateTime;
begin
  N := Now;
  Log(ltTrace, aClassName, aMethodName, 'Timing for %s: %s', [aMessage, TimeDiff(aStartTime, N)])
end;

procedure TLog.ExitMethod(const aClassName, aMethodName: string);
begin
  ExitMethod(aClassName, aMethodName, '');
end;

procedure TLog.ExitMethod(const aClassName, aMethodName, aMessage: string);
begin
  ExitMethod(aClassName, aMethodName, aMessage, default(TGUID));
end;

procedure TLog.ExitMethod(const aClassName, aMethodName, aMessage: string;
  aSession: TGUID);
begin
  if Length(FIndent) >= 3 then
    Delete(FIndent, 2, 2);
  Add(ltTrace, aClassName, aMethodName, 'Exit method: ' + aMessage, aSession);
end;

function TLog.FormatPrefix(aLogType: TLogType; aTime: TDateTime; aClassName, aMethodName: string): string;
var
  aGUID: TGUID;
begin
{$IFNDEF PAS2JS}
  aGUID := TGUID.Empty;
{$ENDIF}
  Result := FormatPrefix(aLogType, aTime, aClassName, aMethodName, aGUID);
end;

function TLog.FormatPrefix(aLogType: TLogType; aTime: TDateTime; aClassName, aMethodName: string; aGUID: TGUID): string;
const
  LogTypeNames: array [TLogType] of string = ('Info', 'SQL', 'Trace', 'Call', 'Error', 'Debug');
var
  aClassMethod: string;
begin
  Result := '';
  if FUseProcessData then
    Result := '(' + IntToStr(ProcessID) + ')[' + ProgramName + ']';

  aClassMethod := aClassName;
  if aClassMethod = '' then
    aClassMethod := 'Unknown';
  if aMethodName <> '' then
    aClassMethod := aClassMethod + '.' + aMethodName;
  if PrependTimeStamp then
    Result := Result + '(' + DateTimeToStr(aTime) + ')';
  Result := Result + Format('[%s](%s)', [LogTypeNames[aLogType], aClassMethod]);
  if (aGUID.D1 <> 0) then
    Result := Result + '[' + aGUID.ToString + ']';
end;

function TLog.GetFileName: string;
begin
{$IFNDEF PAS2JS}
  if Assigned(FLogFile) then
    Result := FLogFile.FileName
  else
{$ENDIF}
    Result := '';
end;

procedure TLog.SetBaseFileName(const Value: string);
begin
  FBaseFileName := Value;
{$IFNDEF PAS2JS}
  if Assigned(FLogFile) then
    CheckRotateFile(Now);
{$ENDIF}
end;

procedure TLog.SetEnabled(const Value: Boolean);
begin
  if FEnabled = Value then
    Exit;
  FEnabled := Value;
{$IFNDEF PAS2JS}
  if Enabled then
    CheckRotateFile(Now);
{$ENDIF}
end;

procedure TLog.SetOnLog(const Value: TLogEvent);
begin
  FOnLog := Value;
  if Assigned(FOnLog) then
    Enabled := true;
end;

procedure TLog.SetTargets(const Value: TLogTargets);
begin
  FTargets := Value;
{$IFNDEF PAS2JS}
  if Enabled then
    CheckRotateFile(Now);
{$ENDIF}
end;

procedure TLog.SetUseSequence(const Value: Boolean);
begin
  FUseSequence := Value;
{$IFNDEF PAS2JS}
  if Enabled then
    CheckRotateFile(Now);
{$ENDIF}
end;

procedure TLog.SetUseTimeStamp(const Value: Boolean);
begin
  FUseTimeStamp := Value;
{$IFNDEF PAS2JS}
  if Enabled then
    CheckRotateFile(Now);
{$ENDIF}
end;

{$IFNDEF PAS2JS}

procedure TLog.Lock;
begin
  Flock.Enter;
end;

procedure TLog.UnLock;
begin
  Flock.Leave;
end;

procedure TLog.CloseLog;
begin
  Lock;
  try
    FreeAndNil(FLogFile);
  finally
    UnLock;
  end;
end;

procedure TLog.CheckRotateFile(aTime: TDateTime);
begin
  if (FLogFile = nil) or ((FLogDate <> 0) and (FLogDate <> Trunc(aTime))) then
  begin
    CloseLog;
    MaybeDeleteLogs;
    if (ltFile in Targets) then
      OpenLog(aTime);
  end;
end;

procedure TLog.MaybeDeleteLogs;
var
  Info: TSearchRec;
  Dir, Ext: string;
begin
  if (FDeleteFilesAfterDays <= 0) then
    System.Exit;
  Ext := ExtractFileExt(BaseFileName);
  Dir := ExtractFilePath(BaseFileName);
  if FindFirst(Dir + '*' + Ext, faAnyFile, Info) <> 0 then
    System.Exit;
  repeat
    if DaysBetween(Now, Info.TimeStamp) > FDeleteFilesAfterDays then
      System.SysUtils.DeleteFile(Dir + Info.Name);
  until FindNext(Info) <> 0;
  FindClose(Info);
end;

procedure TLog.OpenLog(aTime: TDateTime);
const
  P: array [Boolean] of string = ('yyyy-mm-dd', 'yyyy-mm-dd-hh-nn-ss');
var
  N: Integer;
  aFileName: string;
  oExt, TS: string;
begin
  Lock;
  try
    FLogDate := Trunc(aTime);
    oExt := ExtractFileExt(BaseFileName);
    TS := TS;
    if not UseSequence then
    begin
      aFileName := ChangeFileExt(BaseFileName, TS + oExt);
    end
    else
    begin
      N := 1;
      repeat
        aFileName := ChangeFileExt(BaseFileName, TS + Format('-%.3d', [N]) + oExt);
        Inc(N);
      until not FileExists(aFileName);
    end;
    if not FileExists(aFileName) then
      FileClose(FileCreate(aFileName));
    FLogFile := TFileStream.Create(aFileName, fmOpenWrite or fmShareDenyWrite);
    FLogFile.Seek(0, soEnd);
  finally
    UnLock;
  end;
end;

{$ENDIF}
{$IFDEF USEGENERIC}

procedure TLog.Add<T>(aLogType: TLogType; const aClassName, aMethodName, aMessage: string; const aGeneric: T);
var
  pGeneric: PTypeInfo;
  pData: PTypeData;
  aMsg: string;
begin
  pGeneric := System.TypeInfo(T);
  aMsg := DateTimeToStr(Now) + ' - ' + aMessage;
  case pGeneric^.Kind of
    tkInteger:
      Add(aMsg + ' (' + IntToStr(PInteger(@aGeneric)^) + ')');
    tkChar:
      Add(aMsg + ' (' + PChar(@aGeneric)^ + ')');
    tkFloat:
      begin
        pData := GetTypeData(pGeneric);
        case pData^.FloatType of
          ftSingle:
            Add(aMsg + ' (' + FloatToStr(PSingle(@aGeneric)^) + ')');
          ftDouble:
            Add(aMsg + ' (' + FloatToStr(PDouble(@aGeneric)^) + ')');
          ftExtended:
            Add(aMsg + ' (' + FloatToStr(PExtended(@aGeneric)^) + ')');
          ftComp:
            Add(aMsg + ' (' + FloatToStr(PComp(@aGeneric)^) + ')');
          ftCurr:
            Add(aMsg + ' (' + FloatToStr(PCurrency(@aGeneric)^) + ')');
        end;
      end;
    tkString:
      Add(aMsg + ' (' + PString(@aGeneric)^ + ')');
    tkClass:
      Add(aMsg + ' (' + TObject(@aGeneric).ClassName + ')');
    tkWChar:
      Add(aMsg + ' (' + PWideChar(@aGeneric)^ + ')');
    tkLString:
      Add(aMsg + ' (' + UTF8ToString(PUTF8String(@aGeneric)^) + ')');
    tkWString:
      Add(aMsg + ' (' + PString(@aGeneric)^ + ')');
    tkVariant:
      Add(aMsg + ' (' + VarToStr(PVariant(@aGeneric)^) + ')');
    tkInt64:
      Add(aMsg + ' (' + IntToStr(PInt64(@aGeneric)^) + ')');
    tkUString:
      Add(aMsg + ' (' + PString(@aGeneric)^ + ')');
  else
    Add(aMsg);
  end;
end;
{$ENDIF}

procedure TLog.AddException(const aClassName, aMethodName: string; aGUID: TGUID; E: Exception);
begin
  AddException(aClassName, aMethodName, '', aGUID, E);
end;

procedure TLog.AddException(const aClassName, aMethodName, aMessage: string; aGUID: TGUID; E: Exception);
var
  Msg: string;
  aTime: TDateTime;
begin
  aTime := Now;
  Msg := FormatPrefix(ltError, aTime, aClassName, aMethodName, aGUID);
  Msg := Msg + Format('%s (Exception %s) : %s', [aMessage, E.ClassName, E.Message]);
  RawLog(aTime, Msg);
end;

procedure TLog.AddException(const aClassName, aMethodName: string; E: Exception);
begin
  AddException(aClassName, aMethodName, TGUID.NewGuid, E);
end;

procedure TLog.AddException(const aClassName, aMethodName, aMessage: string; E: Exception);
begin
  AddException(aClassName, aMethodName, aMessage, TGUID.NewGuid, E);
end;

{$IFDEF PAS2JS}

{ ---------------------------------------------------------------------
  Dummy implementations
  --------------------------------------------------------------------- }
procedure TCriticalSection.Enter;
begin
end;

procedure TCriticalSection.Leave;
begin
end;
{$ENDIF}

initialization
  _Log := nil;

{$IFNDEF PAS2JS}
finalization
  RemoveLog;
{$ENDIF}

end.
