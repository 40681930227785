unit Forms.Revenue.Debtor;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  Units.DADatasetUtils,
  Forms.DossierDetail,
  Module.Contacts;

type
  TfrmBase = TfrmDossierDetail;

  TfrmRevenueDebtor = class(TfrmBase)
    dsDebtor: TP2WDADataset;
    dsDocuments: TP2WDADataset;
    procedure dsDebtorAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsDebtorAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    FRevenueID: NativeInt;
    FdmContact: TdmContacts;
    function FieldsValid: Boolean;
    procedure DoSaveDebtorForm;

    procedure OnContactSaved(Sender: TObject);
    procedure SetupContact;
  public
    { Public declarations }
    procedure MakeReadOnly; override;
    procedure setParams(const Params: TStrings); override;
    property dmContact: TdmContacts read FdmContact;
    procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmRevenueDebtor: TfrmRevenueDebtor;

implementation

uses
  pas2web.dadatasethelper,
  DateUtils,
  Units.Strings,
  Units.PageHandler,
  Units.ActionUtils,
  Units.Params,
  Modules.Server,
  Units.HTMLUtils,
  Units.Contacts;

{$R *.dfm}
{ TfrmRevenueDebtor }

procedure TfrmRevenueDebtor.WebFormCreate(Sender: TObject);
begin
  inherited;
  FRevenueID := 0;
  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsDebtor.DAConnection := DossierConnection;
  FdmContact := CreateContactModule;
  BindOnChangeEvent;
end;

procedure TfrmRevenueDebtor.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FdmContact);
  inherited;
end;

procedure TfrmRevenueDebtor.WebFormShow(Sender: TObject);
begin
  inherited;
  dsDebtor.Close;
  dsDebtor.ParamByName('REVID').AsInteger := FRevenueID;
  dsDebtor.Load([], nil);
end;

procedure TfrmRevenueDebtor.canHandleRoute(previousURL: string; var allow: Boolean);
begin
  allow := DiscardChanges and ((DefaultDocumentBox.GetAwaitingUploadCount = 0) or DiscardFiles);
end;

procedure TfrmRevenueDebtor.DoSaveDebtorForm;
begin
  if FieldsValid then
    dmContact.SaveIfValid
  else
    dmContact.ContactFieldsValid(True);
end;

procedure TfrmRevenueDebtor.OnContactSaved(Sender: TObject);
begin
  // actual save
  if FRevenueID > 0 then
    dsDebtor.Edit
  else
  begin
    dsDebtor.Insert;
    dsDebtor.FieldByName('revcreatedon').asDateTime := now;
    dsDebtor.FieldByName('revcreatedbyfk').asLargeInt := Server.UserID;
    dsDebtor.FieldByName('revdossierfk').asLargeInt := DossierID;
    dsDebtor.FieldByName('cntlastname').asString := '';
    dsDebtor.FieldByName('cntfirstname').asString := '';
  end;
  dsDebtor.FieldByName('revchangedon').asDateTime := now;
  dsDebtor.FieldByName('revchangedbyfk').asLargeInt := Server.UserID;
  dsDebtor.FieldByName('revrevenuetypefk').asLargeInt := Server.GetParamId('REVTYP', 'DEBT');
  dsDebtor.FieldByName('revrevenuetype').asString := 'DEBT';
  // let op: bedrag moet geformat worden...
  dsDebtor.FieldByName('revamount').AsFloat := StrToFloatDef(Trim(alForm['edtAmount'].Value), 0, GetFormatSettings);
  dsDebtor.FieldByName('revperiodtypefk').asLargeInt := 0;
  dsDebtor.FieldByName('revperiodtype').asString := '';
  dsDebtor.FieldByName('revviatypefk').asLargeInt := 0;
  dsDebtor.FieldByName('revviatype').asString := '';
  dsDebtor.FieldByName('revremark').asString := Trim(alForm['edtRemark'].Value);
  dsDebtor.FieldByName('reviscomplete').asBoolean := False;
  dsDebtor.FieldByName('revfromfk').asLargeInt := dmContact.ContactID;

  dsDebtor.Post;
  dsDebtor.ApplyUpdates;
end;

procedure TfrmRevenueDebtor.dsDebtorAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
var
  aURL: string;

  procedure uploadsCompleted;
  begin
    DiscardChanges := True;
    window.location.href := DossierURL('/revenue');
  end;

begin
  if CheckResolveResults(Info) then
  begin
//    dmServer.ShowOK(SDataSavedOK);
    aURL := DossierURL('/revenue');
    if DefaultDocumentBox.GetAwaitingUploadCount = 0 then
      window.setTimeout(
        procedure
        begin
          DiscardChanges := True;
          window.location.href := aURL;
        end, 50)
    else
      DefaultDocumentBox.DoFileQueueUpload(Sender.FieldByName('revid').asLargeInt, '', @uploadsCompleted);
  end;
end;

procedure TfrmRevenueDebtor.dsDebtorAfterOpen(DataSet: TDataSet);
var
  ContactID, SourceID: NativeInt;
begin
  SourceID := -1;
  if not DataSet.isEmpty then
  begin
    alForm['edtAmount'].value := FloatToStr(DataSet.FieldByName('revamount').asFloat, GetFormatSettings);
    alForm['edtRemark'].Value := DataSet.FieldByName('revremark').asString;
    ContactID := DataSet.FieldByName('revfromfk').AsInteger;
    SourceID := DataSet.FieldByName('revid').asLargeInt;
  end
  else
    ContactID := -1;
  DefaultDocumentBox.LoadDocuments(dsDocuments, DossierID, SourceID, 'uploadList');
  DefaultDocumentBox.setDocBoxParams(13, SourceID, 'REVPROOF', DossierID);
  SetupContact;
  dmContact.LoadContactData(ContactID, [cdpPersonal, cdpWPAddress], []);
end;

function TfrmRevenueDebtor.FieldsValid: Boolean;
begin
  Result := isNumeric('edtAmount');
end;

procedure TfrmRevenueDebtor.MakeReadOnly;
begin
  inherited;
  dmContact.FormIsReadOnly := Self.FormIsReadOnly;
end;

procedure TfrmRevenueDebtor.SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  ClearFormErrors;
  if FieldsValid then
    dmServer.DoOnParamsLoaded(@DoSaveDebtorForm);
end;

procedure TfrmRevenueDebtor.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#returnBack').attr('href', DossierURL('/revenue'));
  FRevenueID := StrToIntDef(Params.Values['REVENUEID'], -1);
end;

procedure TfrmRevenueDebtor.SetupContact;
const
  FromPersonalFields: TPersonalFieldNames = ('', '', '', '', '', '', '', '', '', '', 'edtName', '', '', '', '');
  FromAddressFields: TAddressFieldNames = ('edtAddress', 'edtNumber', 'edtZip', 'edtTown', '', '', '');
var
  PersonalFields: TPersonalFieldEdits;
  AddressFields: TAddressFieldEdits;
begin
  PersonalFields.Names := FromPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names := FromAddressFields;
  AddressFields.SetFieldsFromNames;
  dmContact.PersonalFieldInfo := PersonalFields;
  dmContact.WPAddressFieldInfo := AddressFields;
  dmContact.FormIsReadOnly := Self.FormIsReadOnly;
  dmContact.SearchButtonID := 'btnSelectDebtorContact';
  dmContact.EditContactButtonID := 'btnNewDebtorContact';
  dmContact.ContactDescription := SContactDebtor;
  dmContact.OnAllSaved := @OnContactSaved;
  dmContact.OnContactChanged := @OnContactChanged;
end;

procedure TfrmRevenueDebtor.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsDebtor := TP2WDADataset.Create(Self);
  dsDocuments := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsDebtor.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtAmount';
      Name := 'edtAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSelectDebtorContact';
      Name := 'btnSelectDebtorContact';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtRemark';
      Name := 'edtRemark';
    end;
    dsDebtor.SetParentComponent(Self);
    dsDebtor.Name := 'dsDebtor';
    dsDebtor.TableName := 'revenue';
    dsDebtor.Params.Clear;
    with dsDebtor.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'REVID';
      ParamType := ptInput;
    end;
    dsDebtor.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="AND"><binaryoperation operator="Equal"><field>revid</field><parameter type="LargeInt">REVID</parameter></binaryoperation><binaryoperation operator="Equal"><field>revrevenuetype</field><constant type="String">DEBT</constant></binaryoperation></binaryoperation></where></query>';
    dsDebtor.DAOptions := [];
    dsDebtor.AfterOpen := dsDebtorAfterOpen;
    dsDebtor.AfterApplyUpdates := dsDebtorAfterApplyUpdates;
    dsDebtor.Left := 40;
    dsDebtor.Top := 96;
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">REVPROOF</constant></binaryoperation></binaryoperation></where></query>';
    dsDocuments.DAOptions := [];
    dsDocuments.Left := 560;
    dsDocuments.Top := 120;
  finally
    alForm.AfterLoadDFMValues;
    dsDebtor.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
  end;
end;

end.
