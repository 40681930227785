unit Forms.Contact.Detail;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dataelementmapper,
  Module.Country,
  Units.Types,
  pas2web.datatables,
  pas2web.dadatasethelper,
  lib.bootstrap;

type
  TfrmContactDetail = class(TfrmBase)
    dsContact: TP2WDADataset;
    dsContactcntid: TLargeintField;
    dsContactcntcreatedon: TDateTimeField;
    dsContactcntcreatedbyfk: TLargeintField;
    dsContactcntchangedon: TDateTimeField;
    dsContactcntchangedbyfk: TLargeintField;
    dsContactcntcompanyfk: TLargeintField;
    dsContactcntfirstname: TStringField;
    dsContactcntlastname: TStringField;
    dsContactcntprofession: TStringField;
    dsContactcntbirthdateon: TDateTimeField;
    dsContactcntisbirthdateunknown: TBooleanField;
    dsContactcntgender: TStringField;
    dsContactcntcityofbirthfk: TLargeintField;
    dsContactcntcityofbirthname: TStringField;
    dsContactcntcityofbirthzip: TStringField;
    dsContactcntnationalityfk: TLargeintField;
    dsContactcntnationality2: TStringField;
    dsContactcntnationality: TStringField;
    dsContactcntpicturefk: TLargeintField;
    dsContactcntkbonr: TStringField;
    dsContactcntremark: TStringField;
    dsContactcntpersonfk: TLargeintField;
    dsContactcntsalutation: TStringField;
    dsContactcntsearchname: TStringField;
    dsContactcntprefixes: TStringField;
    dsContactcntfriendlytitle: TStringField;
    eacContact: TPW2DataElementMapper;
    dsContactId: TP2WDADataset;
    eacContactId: TPW2DataElementMapper;
    dsContactPic: TP2WDADataset;
    dsAddresses: TP2WDADataset;
    dsTelecom: TP2WDADataset;
    grdAddresses: TP2WDatatable;
    grdTelecom: TP2WDatatable;
    procedure dsAddressesAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure OnContactTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure dsAddressesAfterOpen(DataSet: TDataSet);
    procedure OnSaveAndReturnClicked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure dsContactAfterOpen(DataSet: TDataSet);
    procedure dsContactIdAfterOpen(DataSet: TDataSet);
    procedure dsContactPicAfterOpen(DataSet: TDataSet);
    procedure dsTelecomAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsTelecomAfterOpen(DataSet: TDataSet);
    procedure grdTelecomColumns0GetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure OnAddressTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnCartTypeChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnCreateAddressClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnCreateTelecomClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure writeCardFromDateValue(Sender: TObject; Source: TElementAction; dest: TField; var aHandled: Boolean);
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);

    procedure ReadRadioGroupValue(Sender: TObject; Source: TElementAction; dest: TField; var aHandled: Boolean);
    procedure WriteRadioGroupValue(Sender: TObject; Source: TField; dest: TElementAction; var aHandled: Boolean);
    procedure OnRadioGroupValidation(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure OnSaveCliked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure ReadNationalNumber(Sender: TObject; Source: TElementAction; dest: TField; var aHandled: Boolean);
    procedure WriteNationalNumber(Sender: TObject; Source: TField; dest: TElementAction; var aHandled: Boolean);
    procedure setDateValue(Sender: TObject; Source: TField; dest: TElementAction; var aHandled: Boolean);
    procedure setCardFromDateValue(Sender: TObject; Source: TField; dest: TElementAction; var aHandled: Boolean);
    procedure setCardToDateValue(Sender: TObject; Source: TField; dest: TElementAction; var aHandled: Boolean);
    procedure OnFieldValidationError(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
    procedure OnTelecomTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure writeCardToDateValue(Sender: TObject; Source: TElementAction; dest: TField; var aHandled: Boolean);
    procedure OnValidateFirstName(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure ValidateBirthDay(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure OnNameChangeExecute(Sender: TObject;
      Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }
    FContactID: string;
    FCountryData: TdmCountry;
    FPhotoChanged: Boolean;
    FPhotoID: Int64;
    FPhoto: TJSObject;

    FAddressDeleteID: string;
    FTelecomDeleteID: string;
    FDoDeleteAddress: Boolean;
    FDoDeleteTelecom: Boolean;

    procedure OnPhotoChanged;
    procedure onGetCartTypeItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string;
      ValueType: Integer; Extra: string; isLast: Boolean);

    procedure onGetCountryData(DataSet: TDataSet);
    procedure OnSaveClicked(returnToList: Boolean);
    procedure LoadContactId(const contactId: Int64);
    procedure LoadAvatar(const contactId: Int64);
    procedure LoadAddresses(const contactId: Int64);
    procedure LoadTelecomDate(const contactId: Int64);
    procedure ConfirmDelete(isAddress: Boolean);
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDeleteAddress(const addressID: string);
    procedure DoDeleteTelecom(const telecomID: string);
  protected procedure LoadDFMValues; override; end;

var
  frmContactDetail: TfrmContactDetail;

implementation

{$R *.dfm}

uses
  Units.ActionUtils,
  Modules.Server,
  Units.DADatasetUtils,
  Units.Strings,
  Units.HTMLUtils;

procedure TfrmContactDetail.OnContactTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Contact', '1');
end;

procedure TfrmContactDetail.OnNameChangeExecute(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  sName: string;
begin
  inherited;
  sName := Trim(alForm['edtFirstName'].Value);
  alForm['edtSearchName'].Value := sName + ' ' + alForm['edtLastName'].Value;
end;

procedure TfrmContactDetail.ConfirmDelete(isAddress: Boolean);

  procedure DoDeleteRecord;

  begin
    if FDoDeleteAddress then
    begin
      FDoDeleteAddress := False;
      if Locate(dsAddresses, 'ctaid', FAddressDeleteID) then
      begin
        dsAddresses.Delete;
        dsAddresses.ApplyUpdates;
      end;
    end;

    if FDoDeleteTelecom then
    begin
      FDoDeleteTelecom := False;
      if Locate(dsTelecom, 'cttid', FTelecomDeleteID) then
      begin
        dsTelecom.Delete;
        dsTelecom.ApplyUpdates;
      end;
    end;
  end;

  procedure DoCancelDelete;
  begin
    FDoDeleteAddress := False;
    FDoDeleteTelecom := False;
    window.location.href := '#/contact/edit/' + FContactID + '/';
  end;

begin
  if isAddress then
  begin
    if Locate(dsAddresses, 'ctaid', FAddressDeleteID) then
      DefaultConfirmation.Execute('', @DoDeleteRecord, @DoCancelDelete);
  end
  else
  begin
    if Locate(dsTelecom, 'cttid', FTelecomDeleteID) then
      DefaultConfirmation.Execute('', @DoDeleteRecord, @DoCancelDelete);
  end;
end;

procedure TfrmContactDetail.DoDeleteAddress(const addressID: string);
begin
  FAddressDeleteID := addressID;
  if dsAddresses.State in [dsInactive] then
    FDoDeleteAddress := True
  else
    ConfirmDelete(True);
end;

procedure TfrmContactDetail.DoDeleteTelecom(const telecomID: string);
begin
  FTelecomDeleteID := telecomID;
  if dsTelecom.State in [dsInactive] then
    FDoDeleteTelecom := True
  else
    ConfirmDelete(False);
end;

procedure TfrmContactDetail.dsAddressesAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
var
  aMsg: string;
  i: Integer;
begin
  aMsg := '';
  for i := low(Info.Records) to high(Info.Records) do
    aMsg := aMsg + #13#10 + Info.Records[i].error;

  if Trim(aMsg) = '' then
  begin
    window.location.href := '#/contact/edit/' + FContactID + '/';;
  end
  else
    ShowError(aMsg);
end;

procedure TfrmContactDetail.dsAddressesAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;

  procedure makeReadonly;
  begin
    jquery('input[type="checkbox"]').attr('onclick', 'return false;');
  end;

begin
  inherited;
  aResult := dsAddresses.GetNewRows(False);
  grdAddresses.Data := aResult;
  grdAddresses.RenderTranslated;
  window.setTimeout(@makeReadonly, 300);
  if FDoDeleteAddress then
    ConfirmDelete(True);
end;

procedure TfrmContactDetail.OnSaveAndReturnClicked(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  OnSaveClicked(True);
end;

procedure TfrmContactDetail.dsContactAfterOpen(DataSet: TDataSet);
var
  cid: string;
begin
  if not DataSet.isEmpty then
  begin
    alForm['AddressTab'].removeClass('disabled');
    alForm['TelecomTab'].removeClass('disabled');
    LoadContactId(DataSet.FieldByName('cntid').asLargeInt);
    LoadAvatar(DataSet.FieldByName('cntid').asLargeInt);
    LoadAddresses(DataSet.FieldByName('cntid').asLargeInt);
    LoadTelecomDate(DataSet.FieldByName('cntid').asLargeInt);

    alForm['ddtBirthDate'].Value := FormatHTMLDate(dsContact.FieldByName('cntbirthdateon').AsDateTime);

    eacContact.DatasetToElements;
  end
  else
  begin
    alForm['AddressTab'].AddClass('disabled');
    alForm['TelecomTab'].AddClass('disabled');
    LoadContactId(-1);
    LoadAvatar(-1);
    cid := jquery('#edtBirthPlace').attr('data-cid');
    if (cid = undefined) or (cid = '') then
      jquery('#edtBirthPlace').attr('data-cid', '0');
  end;
end;

procedure TfrmContactDetail.dsContactIdAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
    eacContactId.DatasetToElements;
end;

procedure TfrmContactDetail.dsContactPicAfterOpen(DataSet: TDataSet);

  procedure onGetUrlResult(aResult: Boolean; aURL: string);
  begin
    if aResult then
      jquery('#petPhoto').attr('src', aURL);
  end;

begin
  // if the picture is available then load the image
  if not DataSet.isEmpty then
  begin
    FPhotoID := DataSet.FieldByName('dofid').asInteger;
    Server.GetFileUrl(FPhotoID, @onGetUrlResult);
  end;
end;

procedure TfrmContactDetail.dsTelecomAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
var
  aMsg: string;
  i: Integer;
begin
  aMsg := '';
  for i := low(Info.Records) to high(Info.Records) do
    aMsg := aMsg + #13#10 + Info.Records[i].error;

  if Trim(aMsg) = '' then
  begin
    window.location.href := '#/contact/edit/' + FContactID + '/';;
  end
  else
    ShowError(aMsg);
end;

procedure TfrmContactDetail.dsTelecomAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;

  procedure makeReadonly;
  begin
    jquery('input[type="checkbox"]').attr('onclick', 'return false;');
  end;

begin
  inherited;
  aResult := dsTelecom.GetNewRows(False);
  grdTelecom.Data := aResult;
  grdTelecom.RenderTranslated;
  window.setTimeout(@makeReadonly, 300);
  if FDoDeleteTelecom then
    ConfirmDelete(False);
end;

procedure TfrmContactDetail.ValidateBirthDay(Sender: TObject; Source: TElementAction; var aResult: Boolean);
var
  aDate: string;
begin
  aDate := Source.Value;
  aResult := (aDate = '');
  if not aResult then
  begin
    aResult := IsValidDate(aDate);
    if aResult then
      aResult := ExtractDate(aDate) < Date;
  end;
end;

procedure TfrmContactDetail.OnValidateFirstName(Sender: TObject; Source: TElementAction; var aResult: Boolean);
begin
  inherited;
  aResult := getRadioGroupValue('rdGender') = 'C';
  if not aResult then
    aResult := (alForm['edtFirstName'].Value <> '')
end;

procedure TfrmContactDetail.grdTelecomColumns0GetValue(Sender: TObject; Fields: TP2WDataTableFieldMap;
  out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('CONTYP', string(Fields.GetValueByName('cttcontacttype')));
end;

procedure TfrmContactDetail.writeCardFromDateValue(Sender: TObject; Source: TElementAction; dest: TField;
  var aHandled: Boolean);
begin
  if alForm[Source.Name].Value = '' then
  begin
    dest.asDateTime := NoStartDate;
    aHandled := True;
  end;
end;

procedure TfrmContactDetail.WebFormDestroy(Sender: TObject);
begin
  if Assigned(FCountryData) then
    FCountryData.Free;
  inherited;
end;

procedure TfrmContactDetail.LoadAddresses(const contactId: Int64);
begin
  dsAddresses.Close;
  dsAddresses.ParamByName('CNTID').asLargeInt := contactId;
  dsAddresses.Load([], nil);
end;

procedure TfrmContactDetail.LoadAvatar(const contactId: Int64);
begin
  dsContactPic.Close;
  dsContactPic.ParamByName('CNTID').asLargeInt := contactId;
  dsContactPic.ParamByName('DOSSIERID').asLargeInt := -1;
  dsContactPic.Load([], nil);
end;

procedure TfrmContactDetail.LoadContactId(const contactId: Int64);
begin
  dsContactId.Close;
  dsContactId.DAConnection := Server.ContactConnection;
  dsContactId.ParamByName('CNTID').asLargeInt := contactId;
  dsContactId.Load([], nil);
end;

procedure TfrmContactDetail.LoadTelecomDate(const contactId: Int64);
begin
  dsTelecom.Close;
  dsTelecom.ParamByName('CNTID').asLargeInt := contactId;
  dsTelecom.Load([], nil);
end;

procedure TfrmContactDetail.OnAddressTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Contact', '2');
end;

procedure TfrmContactDetail.OnCartTypeChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  jquery('#edtCardType').attr('data-id', jquery('#edtCardType')
    .find('option[value="' + string(jquery('#edtCardType').val()) + '"]').attr('data-id'));
end;

procedure TfrmContactDetail.OnCreateAddressClick(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  window.location.href := '#/contact/' + FContactID + '/address/new'
end;

procedure TfrmContactDetail.OnCreateTelecomClick(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  window.location.href := '#/contact/' + FContactID + '/telecom/new';
end;

procedure TfrmContactDetail.OnFieldValidationError(Sender: TObject; Source: TElementAction; dest: TField;
  aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmContactDetail.onGetCartTypeItem(RecordID: Integer; Name: string; Language: Integer;
  ParamType, Value: string; ValueType: Integer; Extra: string; isLast: Boolean);
begin
  if alForm['edtCardType'].Value = '' then
  begin
    jquery('#edtCardType').attr('data-id', inttostr(RecordID));
    alForm['edtCardType'].InnerHTML := alForm['edtCardType'].InnerHTML + '<option data-id="' + inttostr(RecordID) +
      '" value="' + name + '" selected>' + Value + '</option>';
  end
  else
    alForm['edtCardType'].InnerHTML := alForm['edtCardType'].InnerHTML + '<option data-id="' + inttostr(RecordID) +
      '" value="' + name + '">' + Value + '</option>';
end;

procedure TfrmContactDetail.onGetCountryData(DataSet: TDataSet);
begin
  while not DataSet.eof do
  begin
    alForm['edtNationality'].InnerHTML := alForm['edtNationality'].InnerHTML + '<option data-id="' +
      DataSet.FieldByName('conID').AsString + '" value="' + DataSet.FieldByName('conISO2').AsString + '">' +
      DataSet.FieldByName('conName').AsString + '</option>';
    DataSet.Next;
  end;
  // load contact data only when combobox is loaded
  dsContact.Load([], nil);
end;

procedure TfrmContactDetail.OnPhotoChanged;
var
  fileList: JSValue;
begin
  fileList := jquery('#fileupload-avatar').prop('files');
  FPhoto := TJSObject(TJSArray(fileList)[0]);
  FPhotoChanged := True;
end;

procedure TfrmContactDetail.OnRadioGroupValidation(Sender: TObject; Source: TElementAction; var aResult: Boolean);
begin
  aResult := isSelectedRadioGroup(Source.Name);
end;

procedure TfrmContactDetail.OnSaveClicked(returnToList: Boolean);
var
  sNationality, sNationalityfk, sGender: string;
  bIsOK: Boolean;
  dtStart, dtEnd: TDateTime;

  procedure OnSaveFileComplete(const aResult, isCancelled: Boolean; fileID: Int64; refId: string);
  begin
    if aResult then
    begin
      FPhotoChanged := False;
      if returnToList then
        window.location.href := '#/contact/list/A';
    end
    else
      ShowError('Failed to save contact photo');
  end;

  procedure SavePhoto;
  begin
    if FPhotoChanged then
    begin
      DefaultDocumentBox.SaveFile(FPhoto, -1, dsContact.FieldByName('cntid').asInteger, FPhotoID, 2, 'IDPICT',
        @OnSaveFileComplete);
    end
    else
    begin
      if returnToList then
        window.location.href := '#/contact/list/A';
    end;
  end;

  procedure dsContactIdAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
  var
    aMsg: string;
    i: Integer;
  begin
    aMsg := '';
    for i := low(Info.Records) to high(Info.Records) do
    begin
      aMsg := aMsg + #13#10 + Info.Records[i].error;
    end;

    if Trim(aMsg) = '' then
    begin
      // save image if any
      SavePhoto;
    end
    else
      ShowError(aMsg);
  end;

  procedure dsContactAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
  var
    aMsg: string;
    i: Integer;
  begin
    aMsg := '';
    for i := low(Info.Records) to high(Info.Records) do
    begin
      aMsg := aMsg + #13#10 + Info.Records[i].error;
    end;

    if Trim(aMsg) = '' then
    begin
      if reformatNationalNo(alForm['edtNationalNumber'].Value, True) <> '' then
      begin
        // save contact id data
        if dsContactId.isEmpty then
        begin
          dsContactId.insert;
          dsContactId.FieldByName('cticreatedon').asDateTime := now;
          dsContactId.FieldByName('cticreatedbyfk').asLargeInt := Server.UserID;
        end
        else
          dsContactId.edit;
        dsContactId.FieldByName('ctichangedon').asDateTime := now;
        dsContactId.FieldByName('ctichangedbyfk').asLargeInt := Server.UserID;
        dsContactId.FieldByName('cticontactfk').asLargeInt := Sender.FieldByName('cntid').asLargeInt;

        eacContactId.ElementsToDataset;

        dsContactId.AfterApplyUpdates := dsContactIdAfterApplyUpdates;
        dsContactId.Post;
        dsContactId.ApplyUpdates;
      end
      else
        SavePhoto;
    end
    else
      ShowError(aMsg);
  end;

  procedure NationalNoCheckResult(aSuccess: Boolean; anError: string);
  begin
    try
      if not aSuccess then
      begin
        if Trim(anError) = '' then
          FieldIsInvalid(npeNationalNumber, 'edtNationalNumber')
        else
          DisplayError('edtNationalNumber', '', anError);
      end
      else
      begin
        if dsContact.isEmpty then
        begin
          dsContact.insert;
          dsContact.FieldByName('cntcreatedon').asDateTime := now;
          dsContact.FieldByName('cntcreatedbyfk').asLargeInt := Server.UserID;
          dsContact.FieldByName('cntcompanyfk').asLargeInt := Server.UserInfo.OfficeID;
        end
        else
          dsContact.edit;
        dsContact.FieldByName('cntchangedon').asDateTime := now;
        dsContact.FieldByName('cntchangedbyfk').asLargeInt := Server.UserID;
        if alForm['ddtBirthDate'].Value = '' then
          dsContact.FieldByName('cntisbirthdateunknown').asBoolean := True
        else
          dsContact.FieldByName('cntisbirthdateunknown').asBoolean := False;
        sNationality := jquery('#edtNationality').find('option[value="' + alForm['edtNationality'].Value + '"]').text();
        if sNationality = undefined then
        begin
          sNationality := '';
          sNationalityfk := '';
        end
        else
        begin
          sNationalityfk := jquery('#edtNationality').find('option[value="' + alForm['edtNationality'].Value + '"]')
            .attr('data-id');
        end;
        dsContact.FieldByName('cntnationality').AsString := sNationality;
        dsContact.FieldByName('cntnationalityfk').asLargeInt := StrToIntDef(sNationalityfk, -1);
        dsContact.FieldByName('cntpicturefk').asLargeInt := 0;
        dsContact.FieldByName('cntpersonfk').asLargeInt := 0;
        dsContact.FieldByName('cntbirthdateon').AsDateTime := ExtractDate(alForm['ddtBirthDate'].Value);
  //      FormatSettings.ShortDateFormat := 'yyyy-mm-dd';
        eacContact.ElementsToDataset;
        dsContact.AfterApplyUpdates := dsContactAfterApplyUpdates;
        dsContact.Post;
        dsContact.ApplyUpdates;
      end;
    except
      on E:Exception do
        Console.Log('Error while saving contact: ' + E.Message);
    end;
  end;

var
  aDate: string;
  aBirthDate, aUTCBirthDate: TJSDate;
begin
  jquery('.invalid-feedback').css('display', 'none');

  bIsOK := eacContact.ValidateActions(True);

  if reformatNationalNo(alForm['edtNationalNumber'].Value, True) <> '' then
  begin
    bIsOK := bIsOK and eacContactId.ValidateActions(True);

    if (alForm['ddtCardValidFrom'].Value <> '') and (alForm['ddtCardValidTo'].Value <> '') then
    begin
      dtStart := ExtractDate(alForm['ddtCardValidFrom'].Value);
      dtEnd := ExtractDate(alForm['ddtCardValidTo'].Value);

      bIsOK := bIsOK and (dtStart < dtEnd);

      if (dtEnd < dtStart) then
        DisplayError('ddtCardValidTo', '', SErrTillAfterFrom);
    end;
  end;

  if bIsOK then
  begin
    aDate := alForm['ddtBirthDate'].Value;

    if (aDate <> '') and IsValidDate(aDate) and (reformatNationalNo(alForm['edtNationalNumber'].Value, True) <> '') then
    begin
      sGender := getRadioGroupValue('rdGender');
      aBirthDate := TJSDate.new(aDate);
      aUTCBirthDate := TJSDate.new(aBirthDate.UTCFullYear, aBirthDate.UTCMonth, aBirthDate.UTCDate);

      Server.isNationalNoValid(Trim(alForm['edtNationalNumber'].Value), sGender, aUTCBirthDate, @NationalNoCheckResult);
    end
    else
      NationalNoCheckResult(True, '');
  end;
end;

procedure TfrmContactDetail.OnSaveCliked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  OnSaveClicked(False);
end;

procedure TfrmContactDetail.OnTelecomTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Contact', '3');
end;

procedure TfrmContactDetail.ReadNationalNumber(Sender: TObject; Source: TElementAction; dest: TField;
  var aHandled: Boolean);
begin
  dest.AsString := reformatNationalNo(Source.Value, True);
  aHandled := True;
end;

procedure TfrmContactDetail.ReadRadioGroupValue(Sender: TObject; Source: TElementAction; dest: TField;
  var aHandled: Boolean);
begin
  dest.AsString := getRadioGroupValue(Source.Name);
  aHandled := True;
end;

procedure TfrmContactDetail.setCardFromDateValue(Sender: TObject; Source: TField; dest: TElementAction;
  var aHandled: Boolean);
begin
  if not IsEmptyDate(Source.asDateTime) then
    alForm['ddtCardValidFrom'].Value := FormatHTMLDate(Source.asDateTime);
  aHandled := True;
end;

procedure TfrmContactDetail.setCardToDateValue(Sender: TObject; Source: TField; dest: TElementAction;
  var aHandled: Boolean);
begin
  if not IsEmptyDate(Source.asDateTime) then
    alForm['ddtCardValidTo'].Value := FormatHTMLDate(Source.asDateTime);
  aHandled := True;
end;

procedure TfrmContactDetail.setDateValue(Sender: TObject; Source: TField; dest: TElementAction; var aHandled: Boolean);
begin
  if not IsEmptyDate(Source.asDateTime) then
    alForm['ddtBirthDate'].Value := FormatHTMLDate(Source.asDateTime);
  aHandled := True;
end;

procedure TfrmContactDetail.setParams(const Params: TStrings);
begin
  if Assigned(Params) then
  begin
    FContactID := Params.values['CONTACTID'];
    dsContact.ParamByName('CNTID').asLargeInt := StrToIntDef(FContactID, -1)
  end
  else
    dsContact.ParamByName('CNTID').asLargeInt := -1;
end;

procedure TfrmContactDetail.WebFormCreate(Sender: TObject);
var

  el: TJSElement;
begin
  inherited;
  FContactID := '';
  FPhotoChanged := False;
  FPhoto := nil;
  FPhotoID := -1;

  FAddressDeleteID := '';
  FTelecomDeleteID := '';
  FDoDeleteAddress := False;
  FDoDeleteTelecom := False;

  jquery('#totalContacts').css('display', 'none');

  FCountryData := TdmCountry.Create;

  bindZipLookup('edtBirthPlaceZip', 'edtBirthPlace');

  el := document.getElementById('fileupload-avatar');
  el.addEventListener('photoChanged', @OnPhotoChanged);

  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsContact.DAConnection := Server.ContactConnection;
  dsContactId.DAConnection := Server.ContactConnection;
  dsAddresses.DAConnection := Server.ContactConnection;
  dsTelecom.DAConnection := Server.ContactConnection;

  Server.PetitionConnection.Message.ClientID := Server.ClientID;
  dsContactPic.DAConnection := Server.PetitionConnection;

  Server.GetParameterList('IDTYP', Language, @onGetCartTypeItem);

  // get selected tab
  if Server.GetLocalData('Contact') = '3' then
  begin
    jquery('a[href="#first"]').removeClass('active');
    jquery('a[href="#second"]').removeClass('active');
    jquery('a[href="#third"]').AddClass('active');
    jquery('#first').removeClass('show');
    jquery('#first').removeClass('active');
    jquery('#second').removeClass('show');
    jquery('#second').removeClass('active');
    jquery('#third').AddClass('show');
    jquery('#third').AddClass('active');
  end
  else
    if Server.GetLocalData('Contact') = '2' then
    begin
      jquery('a[href="#first"]').removeClass('active');
      jquery('a[href="#third"]').removeClass('active');
      jquery('a[href="#second"]').AddClass('active');
      jquery('#first').removeClass('show');
      jquery('#first').removeClass('active');
      jquery('#third').removeClass('show');
      jquery('#third').removeClass('active');
      jquery('#second').AddClass('show');
      jquery('#second').AddClass('active');
    end
    else
    begin
      jquery('a[href="#second"]').removeClass('active');
      jquery('a[href="#third"]').removeClass('active');
      jquery('a[href="#first"]').AddClass('active');
      jquery('#second').removeClass('show');
      jquery('#second').removeClass('active');
      jquery('#third').removeClass('show');
      jquery('#third').removeClass('active');
      jquery('#first').AddClass('show');
      jquery('#first').AddClass('active');
    end;
end;

procedure TfrmContactDetail.WebFormShow(Sender: TObject);
begin
  inherited;
  FCountryData.LoadData(@onGetCountryData);
end;

procedure TfrmContactDetail.writeCardToDateValue(Sender: TObject; Source: TElementAction; dest: TField;
  var aHandled: Boolean);
begin
  if alForm[Source.Name].Value = '' then
  begin
    dest.asDateTime := NoEndDate;
    aHandled := True;
  end;
end;

procedure TfrmContactDetail.WriteNationalNumber(Sender: TObject; Source: TField; dest: TElementAction;
  var aHandled: Boolean);
begin
  dest.Value := reformatNationalNo(Source.AsString);
  aHandled := True;
end;

procedure TfrmContactDetail.WriteRadioGroupValue(Sender: TObject; Source: TField; dest: TElementAction;
  var aHandled: Boolean);
begin
  setRadiogroupSelectedElement(dest.DisplayName, Source.AsString);
  aHandled := True;
end;

procedure TfrmContactDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsContact := TP2WDADataset.Create(Self);
  dsContactcntid := TLargeintField.Create(Self);
  dsContactcntcreatedon := TDateTimeField.Create(Self);
  dsContactcntcreatedbyfk := TLargeintField.Create(Self);
  dsContactcntchangedon := TDateTimeField.Create(Self);
  dsContactcntchangedbyfk := TLargeintField.Create(Self);
  dsContactcntcompanyfk := TLargeintField.Create(Self);
  dsContactcntfirstname := TStringField.Create(Self);
  dsContactcntlastname := TStringField.Create(Self);
  dsContactcntprofession := TStringField.Create(Self);
  dsContactcntbirthdateon := TDateTimeField.Create(Self);
  dsContactcntisbirthdateunknown := TBooleanField.Create(Self);
  dsContactcntgender := TStringField.Create(Self);
  dsContactcntcityofbirthfk := TLargeintField.Create(Self);
  dsContactcntcityofbirthname := TStringField.Create(Self);
  dsContactcntcityofbirthzip := TStringField.Create(Self);
  dsContactcntnationalityfk := TLargeintField.Create(Self);
  dsContactcntnationality2 := TStringField.Create(Self);
  dsContactcntnationality := TStringField.Create(Self);
  dsContactcntpicturefk := TLargeintField.Create(Self);
  dsContactcntkbonr := TStringField.Create(Self);
  dsContactcntremark := TStringField.Create(Self);
  dsContactcntpersonfk := TLargeintField.Create(Self);
  dsContactcntsalutation := TStringField.Create(Self);
  dsContactcntsearchname := TStringField.Create(Self);
  dsContactcntprefixes := TStringField.Create(Self);
  dsContactcntfriendlytitle := TStringField.Create(Self);
  eacContact := TPW2DataElementMapper.Create(Self);
  dsContactId := TP2WDADataset.Create(Self);
  eacContactId := TPW2DataElementMapper.Create(Self);
  dsContactPic := TP2WDADataset.Create(Self);
  dsAddresses := TP2WDADataset.Create(Self);
  dsTelecom := TP2WDADataset.Create(Self);
  grdAddresses := TP2WDatatable.Create(Self);
  grdTelecom := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsContact.BeforeLoadDFMValues;
  dsContactcntid.BeforeLoadDFMValues;
  dsContactcntcreatedon.BeforeLoadDFMValues;
  dsContactcntcreatedbyfk.BeforeLoadDFMValues;
  dsContactcntchangedon.BeforeLoadDFMValues;
  dsContactcntchangedbyfk.BeforeLoadDFMValues;
  dsContactcntcompanyfk.BeforeLoadDFMValues;
  dsContactcntfirstname.BeforeLoadDFMValues;
  dsContactcntlastname.BeforeLoadDFMValues;
  dsContactcntprofession.BeforeLoadDFMValues;
  dsContactcntbirthdateon.BeforeLoadDFMValues;
  dsContactcntisbirthdateunknown.BeforeLoadDFMValues;
  dsContactcntgender.BeforeLoadDFMValues;
  dsContactcntcityofbirthfk.BeforeLoadDFMValues;
  dsContactcntcityofbirthname.BeforeLoadDFMValues;
  dsContactcntcityofbirthzip.BeforeLoadDFMValues;
  dsContactcntnationalityfk.BeforeLoadDFMValues;
  dsContactcntnationality2.BeforeLoadDFMValues;
  dsContactcntnationality.BeforeLoadDFMValues;
  dsContactcntpicturefk.BeforeLoadDFMValues;
  dsContactcntkbonr.BeforeLoadDFMValues;
  dsContactcntremark.BeforeLoadDFMValues;
  dsContactcntpersonfk.BeforeLoadDFMValues;
  dsContactcntsalutation.BeforeLoadDFMValues;
  dsContactcntsearchname.BeforeLoadDFMValues;
  dsContactcntprefixes.BeforeLoadDFMValues;
  dsContactcntfriendlytitle.BeforeLoadDFMValues;
  eacContact.BeforeLoadDFMValues;
  dsContactId.BeforeLoadDFMValues;
  eacContactId.BeforeLoadDFMValues;
  dsContactPic.BeforeLoadDFMValues;
  dsAddresses.BeforeLoadDFMValues;
  dsTelecom.BeforeLoadDFMValues;
  grdAddresses.BeforeLoadDFMValues;
  grdTelecom.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtLastName';
      Name := 'edtLastName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtFirstname';
      Name := 'edtFirstname';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtSearchName';
      Name := 'edtSearchName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtBirthDate';
      Name := 'ddtBirthDate';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtBirthPlaceZip';
      Name := 'edtBirthPlaceZip';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtBirthPlace';
      Name := 'edtBirthPlace';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := '';
      Name := 'rdGender';
      PreventDefault := False;
      Selector := 'input[name="rdGender"]';
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNationality';
      Name := 'edtNationality';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtKBO';
      Name := 'edtKBO';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtProfession';
      Name := 'edtProfession';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtSalutation';
      Name := 'edtSalutation';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtPrefixes';
      Name := 'edtPrefixes';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtFriendlytitle';
      Name := 'edtFriendlytitle';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtRemark';
      Name := 'edtRemark';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'AddressTab';
      Name := 'AddressTab';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'TelecomTab';
      Name := 'TelecomTab';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'OnSaveCliked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSaveReturn';
      Name := 'btnSaveReturn';
      SetEvent(Self, 'OnExecute', 'OnSaveAndReturnClicked');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNationalNumber';
      Name := 'edtNationalNumber';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCardType';
      Name := 'edtCardType';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCardNo';
      Name := 'edtCardNo';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtCardValidFrom';
      Name := 'ddtCardValidFrom';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtCardValidTo';
      Name := 'ddtCardValidTo';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtAppChipNo';
      Name := 'edtAppChipNo';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'createAddress';
      Name := 'createAddress';
      SetEvent(Self, 'OnExecute', 'OnCreateAddressClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'createTelecom';
      Name := 'createTelecom';
      SetEvent(Self, 'OnExecute', 'OnCreateTelecomClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := '';
      Name := 'contactTab';
      PreventDefault := False;
      Selector := 'a[href="#first"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnContactTabClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := '';
      Name := 'addressTab';
      PreventDefault := False;
      Selector := 'a[href="#second"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnAddressTabClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := '';
      Name := 'telecomTab';
      PreventDefault := False;
      Selector := 'a[href="#third"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnTelecomTabClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'btnNo';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'edtLastName';
      Name := 'edtLastNameChange';
      SetEvent(Self, 'OnExecute', 'OnNameChangeExecute');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'edtFirstname';
      Name := 'edtFirstnameChange';
      SetEvent(Self, 'OnExecute', 'OnNameChangeExecute');
    end;
    dsContact.SetParentComponent(Self);
    dsContact.Name := 'dsContact';
    dsContact.TableName := 'ContactOverview';
    dsContact.Params.Clear;
    with dsContact.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsContact.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>cntid</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></where></query>';
    dsContact.DAOptions := [];
    dsContact.AfterOpen := dsContactAfterOpen;
    dsContact.Left := 40;
    dsContact.Top := 80;
    dsContactcntid.SetParentComponent(dsContact);
    dsContactcntid.Name := 'dsContactcntid';
    dsContactcntid.FieldName := 'cntid';
    dsContactcntcreatedon.SetParentComponent(dsContact);
    dsContactcntcreatedon.Name := 'dsContactcntcreatedon';
    dsContactcntcreatedon.FieldName := 'cntcreatedon';
    dsContactcntcreatedbyfk.SetParentComponent(dsContact);
    dsContactcntcreatedbyfk.Name := 'dsContactcntcreatedbyfk';
    dsContactcntcreatedbyfk.FieldName := 'cntcreatedbyfk';
    dsContactcntchangedon.SetParentComponent(dsContact);
    dsContactcntchangedon.Name := 'dsContactcntchangedon';
    dsContactcntchangedon.FieldName := 'cntchangedon';
    dsContactcntchangedbyfk.SetParentComponent(dsContact);
    dsContactcntchangedbyfk.Name := 'dsContactcntchangedbyfk';
    dsContactcntchangedbyfk.FieldName := 'cntchangedbyfk';
    dsContactcntcompanyfk.SetParentComponent(dsContact);
    dsContactcntcompanyfk.Name := 'dsContactcntcompanyfk';
    dsContactcntcompanyfk.FieldName := 'cntcompanyfk';
    dsContactcntfirstname.SetParentComponent(dsContact);
    dsContactcntfirstname.Name := 'dsContactcntfirstname';
    dsContactcntfirstname.FieldName := 'cntfirstname';
    dsContactcntfirstname.Size := 64;
    dsContactcntlastname.SetParentComponent(dsContact);
    dsContactcntlastname.Name := 'dsContactcntlastname';
    dsContactcntlastname.FieldName := 'cntlastname';
    dsContactcntlastname.Size := 127;
    dsContactcntprofession.SetParentComponent(dsContact);
    dsContactcntprofession.Name := 'dsContactcntprofession';
    dsContactcntprofession.FieldName := 'cntprofession';
    dsContactcntprofession.Size := 64;
    dsContactcntbirthdateon.SetParentComponent(dsContact);
    dsContactcntbirthdateon.Name := 'dsContactcntbirthdateon';
    dsContactcntbirthdateon.FieldName := 'cntbirthdateon';
    dsContactcntisbirthdateunknown.SetParentComponent(dsContact);
    dsContactcntisbirthdateunknown.Name := 'dsContactcntisbirthdateunknown';
    dsContactcntisbirthdateunknown.FieldName := 'cntisbirthdateunknown';
    dsContactcntgender.SetParentComponent(dsContact);
    dsContactcntgender.Name := 'dsContactcntgender';
    dsContactcntgender.FieldName := 'cntgender';
    dsContactcntgender.FixedChar := True;
    dsContactcntgender.Size := 1;
    dsContactcntcityofbirthfk.SetParentComponent(dsContact);
    dsContactcntcityofbirthfk.Name := 'dsContactcntcityofbirthfk';
    dsContactcntcityofbirthfk.FieldName := 'cntcityofbirthfk';
    dsContactcntcityofbirthname.SetParentComponent(dsContact);
    dsContactcntcityofbirthname.Name := 'dsContactcntcityofbirthname';
    dsContactcntcityofbirthname.FieldName := 'cntcityofbirthname';
    dsContactcntcityofbirthname.Size := 100;
    dsContactcntcityofbirthzip.SetParentComponent(dsContact);
    dsContactcntcityofbirthzip.Name := 'dsContactcntcityofbirthzip';
    dsContactcntcityofbirthzip.FieldName := 'cntcityofbirthzip';
    dsContactcntcityofbirthzip.Size := 15;
    dsContactcntnationalityfk.SetParentComponent(dsContact);
    dsContactcntnationalityfk.Name := 'dsContactcntnationalityfk';
    dsContactcntnationalityfk.FieldName := 'cntnationalityfk';
    dsContactcntnationality2.SetParentComponent(dsContact);
    dsContactcntnationality2.Name := 'dsContactcntnationality2';
    dsContactcntnationality2.FieldName := 'cntnationality2';
    dsContactcntnationality2.Size := 2;
    dsContactcntnationality.SetParentComponent(dsContact);
    dsContactcntnationality.Name := 'dsContactcntnationality';
    dsContactcntnationality.FieldName := 'cntnationality';
    dsContactcntnationality.Size := 40;
    dsContactcntpicturefk.SetParentComponent(dsContact);
    dsContactcntpicturefk.Name := 'dsContactcntpicturefk';
    dsContactcntpicturefk.FieldName := 'cntpicturefk';
    dsContactcntkbonr.SetParentComponent(dsContact);
    dsContactcntkbonr.Name := 'dsContactcntkbonr';
    dsContactcntkbonr.FieldName := 'cntkbonr';
    dsContactcntkbonr.Size := 10;
    dsContactcntremark.SetParentComponent(dsContact);
    dsContactcntremark.Name := 'dsContactcntremark';
    dsContactcntremark.FieldName := 'cntremark';
    dsContactcntremark.Size := 255;
    dsContactcntpersonfk.SetParentComponent(dsContact);
    dsContactcntpersonfk.Name := 'dsContactcntpersonfk';
    dsContactcntpersonfk.FieldName := 'cntpersonfk';
    dsContactcntsalutation.SetParentComponent(dsContact);
    dsContactcntsalutation.Name := 'dsContactcntsalutation';
    dsContactcntsalutation.FieldName := 'cntsalutation';
    dsContactcntsalutation.Size := 32;
    dsContactcntsearchname.SetParentComponent(dsContact);
    dsContactcntsearchname.Name := 'dsContactcntsearchname';
    dsContactcntsearchname.FieldName := 'cntsearchname';
    dsContactcntsearchname.Size := 50;
    dsContactcntprefixes.SetParentComponent(dsContact);
    dsContactcntprefixes.Name := 'dsContactcntprefixes';
    dsContactcntprefixes.FieldName := 'cntprefixes';
    dsContactcntprefixes.Size := 32;
    dsContactcntfriendlytitle.SetParentComponent(dsContact);
    dsContactcntfriendlytitle.Name := 'dsContactcntfriendlytitle';
    dsContactcntfriendlytitle.FieldName := 'cntfriendlytitle';
    dsContactcntfriendlytitle.Size := 32;
    eacContact.SetParentComponent(Self);
    eacContact.Name := 'eacContact';
    eacContact.ActionLinks.Clear;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtLastName';
      FieldName := 'cntlastname';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtFirstname';
      FieldName := 'cntfirstname';
      Options := [];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
      SetEvent(Self, 'OnValidation', 'OnValidateFirstName');
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtSearchName';
      FieldName := 'cntsearchname';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtBirthPlaceZip';
      FieldName := 'cntcityofbirthzip';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtBirthPlace';
      FieldName := 'cntcityofbirthfk';
      DataAttribute := 'cid';
      Options := [];
      ClearValue := '0';
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'rdGender';
      FieldName := 'cntgender';
      DataAttribute := 'i';
      Options := [aoRequired];
      SetEvent(Self, 'OnFieldToAction', 'WriteRadioGroupValue');
      SetEvent(Self, 'OnActionToField', 'ReadRadioGroupValue');
      SetEvent(Self, 'OnValidation', 'OnRadioGroupValidation');
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtBirthPlace';
      FieldName := 'cntcityofbirthname';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtNationality';
      FieldName := 'cntnationality2';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtKBO';
      FieldName := 'cntkbonr';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtProfession';
      FieldName := 'cntprofession';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtSalutation';
      FieldName := 'cntsalutation';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtPrefixes';
      FieldName := 'cntprefixes';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtFriendlytitle';
      FieldName := 'cntfriendlytitle';
      Options := [];
    end;
    with eacContact.ActionLinks.Add do
    begin
      ActionName := 'edtRemark';
      FieldName := 'cntremark';
      Options := [];
    end;
    eacContact.ActionList := alForm;
    eacContact.Dataset := dsContact;
    eacContact.Left := 40;
    eacContact.Top := 152;
    dsContactId.SetParentComponent(Self);
    dsContactId.Name := 'dsContactId';
    dsContactId.TableName := 'contactid';
    dsContactId.Params.Clear;
    with dsContactId.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsContactId.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>cticontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></where></query>';
    dsContactId.DAOptions := [];
    dsContactId.AfterOpen := dsContactIdAfterOpen;
    dsContactId.Left := 40;
    dsContactId.Top := 224;
    eacContactId.SetParentComponent(Self);
    eacContactId.Name := 'eacContactId';
    eacContactId.ActionLinks.Clear;
    with eacContactId.ActionLinks.Add do
    begin
      ActionName := 'edtNationalNumber';
      FieldName := 'ctinidnr';
      Options := [aoRequired];
      SetEvent(Self, 'OnFieldToAction', 'WriteNationalNumber');
      SetEvent(Self, 'OnActionToField', 'ReadNationalNumber');
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacContactId.ActionLinks.Add do
    begin
      ActionName := 'edtCardType';
      FieldName := 'cticardtype';
      Options := [];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacContactId.ActionLinks.Add do
    begin
      ActionName := 'edtCardType';
      FieldName := 'cticardtypefk';
      DataAttribute := 'id';
      Options := [];
      ClearValue := '0';
    end;
    with eacContactId.ActionLinks.Add do
    begin
      ActionName := 'edtCardNo';
      FieldName := 'cticardnr';
      Options := [];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacContactId.ActionLinks.Add do
    begin
      ActionName := 'ddtCardValidFrom';
      FieldName := 'ctivalidfrom';
      Options := [];
      SetEvent(Self, 'OnFieldToAction', 'setCardFromDateValue');
      SetEvent(Self, 'OnActionToField', 'writeCardFromDateValue');
    end;
    with eacContactId.ActionLinks.Add do
    begin
      ActionName := 'ddtCardValidTo';
      FieldName := 'ctivalidto';
      Options := [];
      SetEvent(Self, 'OnFieldToAction', 'setCardToDateValue');
      SetEvent(Self, 'OnActionToField', 'writeCardToDateValue');
    end;
    with eacContactId.ActionLinks.Add do
    begin
      ActionName := 'edtAppChipNo';
      FieldName := 'ctichipnr';
      Options := [];
    end;
    eacContactId.ActionList := alForm;
    eacContactId.Dataset := dsContactId;
    eacContactId.Left := 40;
    eacContactId.Top := 288;
    dsContactPic.SetParentComponent(Self);
    dsContactPic.Name := 'dsContactPic';
    dsContactPic.TableName := 'dossierfile';
    dsContactPic.Params.Clear;
    with dsContactPic.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsContactPic.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsContactPic.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">IDPICT</constant></binaryoperation></binaryoperation></where></query>';
    dsContactPic.DAOptions := [doRefreshAllFields];
    dsContactPic.AfterOpen := dsContactPicAfterOpen;
    dsContactPic.Left := 40;
    dsContactPic.Top := 352;
    dsAddresses.SetParentComponent(Self);
    dsAddresses.Name := 'dsAddresses';
    dsAddresses.TableName := 'contactaddress';
    dsAddresses.Params.Clear;
    with dsAddresses.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
    end;
    dsAddresses.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>ctacontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></where></query>';
    dsAddresses.DAOptions := [];
    dsAddresses.AfterOpen := dsAddressesAfterOpen;
    dsAddresses.AfterApplyUpdates := dsAddressesAfterApplyUpdates;
    dsAddresses.Left := 472;
    dsAddresses.Top := 88;
    dsTelecom.SetParentComponent(Self);
    dsTelecom.Name := 'dsTelecom';
    dsTelecom.TableName := 'contacttelecom';
    dsTelecom.Params.Clear;
    with dsTelecom.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
    end;
    dsTelecom.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>cttcontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation></where></query>';
    dsTelecom.DAOptions := [];
    dsTelecom.AfterOpen := dsTelecomAfterOpen;
    dsTelecom.AfterApplyUpdates := dsTelecomAfterApplyUpdates;
    dsTelecom.Left := 472;
    dsTelecom.Top := 216;
    grdAddresses.SetParentComponent(Self);
    grdAddresses.Name := 'grdAddresses';
    grdAddresses.Columns.Clear;
    with grdAddresses.Columns.Add do
    begin
      FieldName := 'ctastreet';
      Title := 'Street';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdAddresses.Columns.Add do
    begin
      FieldName := 'ctastreetnr';
      Title := 'Nr.';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdAddresses.Columns.Add do
    begin
      FieldName := 'ctacityzip';
      Title := 'Zip';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdAddresses.Columns.Add do
    begin
      FieldName := 'ctacityname';
      Title := 'City';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdAddresses.Columns.Add do
    begin
      FieldName := 'ctacorrespondence';
      Title := 'Correspondence';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdAddresses.Columns.Add do
    begin
      FieldName := 'ctasecret';
      Title := 'Secret';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdAddresses.Columns.Add do
    begin
      RenderMode := rmButton;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/contact/{{ctacontactfk}}/address/edit/{{ctaid}}';
    end;
    with grdAddresses.Columns.Add do
    begin
      RenderMode := rmButton;
      ButtonType := btDelete;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/contact/{{ctacontactfk}}/address/delete/{{ctaid}}';
    end;
    grdAddresses.DataSet := dsAddresses;
    grdAddresses.Language := lEnglish;
    grdAddresses.IsResponsive := False;
    grdAddresses.GridID := 'grdAddresses';
    grdAddresses.UseFieldIndex := True;
    grdAddresses.ShowSearch := False;
    grdAddresses.ShowNumberOfEntries := False;
    grdAddresses.ShowEntriesInfo := False;
    grdAddresses.Paginate := True;
    grdAddresses.DisplayReadOnly := False;
    grdAddresses.CalculateTableWidth := True;
    grdAddresses.Left := 472;
    grdAddresses.Top := 152;
    grdTelecom.SetParentComponent(Self);
    grdTelecom.Name := 'grdTelecom';
    grdTelecom.Columns.Clear;
    with grdTelecom.Columns.Add do
    begin
      FieldName := 'cttcontacttype';
      Title := 'Type';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'grdTelecomColumns0GetValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdTelecom.Columns.Add do
    begin
      FieldName := 'cttdata';
      Title := 'Address/Nr.';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdTelecom.Columns.Add do
    begin
      FieldName := 'cttcorrespondence';
      Title := 'Correspondence';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with grdTelecom.Columns.Add do
    begin
      RenderMode := rmButton;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/contact/{{cttcontactfk}}/telecom/edit/{{cttid}}';
    end;
    with grdTelecom.Columns.Add do
    begin
      RenderMode := rmButton;
      ButtonType := btDelete;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/contact/{{cttcontactfk}}/telecom/delete/{{cttid}}';
    end;
    grdTelecom.DataSet := dsTelecom;
    grdTelecom.Language := lEnglish;
    grdTelecom.IsResponsive := False;
    grdTelecom.GridID := 'grdTelecom';
    grdTelecom.UseFieldIndex := True;
    grdTelecom.ShowSearch := False;
    grdTelecom.ShowNumberOfEntries := False;
    grdTelecom.ShowEntriesInfo := False;
    grdTelecom.Paginate := True;
    grdTelecom.DisplayReadOnly := False;
    grdTelecom.CalculateTableWidth := True;
    grdTelecom.Left := 472;
    grdTelecom.Top := 288;
  finally
    alForm.AfterLoadDFMValues;
    dsContact.AfterLoadDFMValues;
    dsContactcntid.AfterLoadDFMValues;
    dsContactcntcreatedon.AfterLoadDFMValues;
    dsContactcntcreatedbyfk.AfterLoadDFMValues;
    dsContactcntchangedon.AfterLoadDFMValues;
    dsContactcntchangedbyfk.AfterLoadDFMValues;
    dsContactcntcompanyfk.AfterLoadDFMValues;
    dsContactcntfirstname.AfterLoadDFMValues;
    dsContactcntlastname.AfterLoadDFMValues;
    dsContactcntprofession.AfterLoadDFMValues;
    dsContactcntbirthdateon.AfterLoadDFMValues;
    dsContactcntisbirthdateunknown.AfterLoadDFMValues;
    dsContactcntgender.AfterLoadDFMValues;
    dsContactcntcityofbirthfk.AfterLoadDFMValues;
    dsContactcntcityofbirthname.AfterLoadDFMValues;
    dsContactcntcityofbirthzip.AfterLoadDFMValues;
    dsContactcntnationalityfk.AfterLoadDFMValues;
    dsContactcntnationality2.AfterLoadDFMValues;
    dsContactcntnationality.AfterLoadDFMValues;
    dsContactcntpicturefk.AfterLoadDFMValues;
    dsContactcntkbonr.AfterLoadDFMValues;
    dsContactcntremark.AfterLoadDFMValues;
    dsContactcntpersonfk.AfterLoadDFMValues;
    dsContactcntsalutation.AfterLoadDFMValues;
    dsContactcntsearchname.AfterLoadDFMValues;
    dsContactcntprefixes.AfterLoadDFMValues;
    dsContactcntfriendlytitle.AfterLoadDFMValues;
    eacContact.AfterLoadDFMValues;
    dsContactId.AfterLoadDFMValues;
    eacContactId.AfterLoadDFMValues;
    dsContactPic.AfterLoadDFMValues;
    dsAddresses.AfterLoadDFMValues;
    dsTelecom.AfterLoadDFMValues;
    grdAddresses.AfterLoadDFMValues;
    grdTelecom.AfterLoadDFMValues;
  end;
end;

end.
