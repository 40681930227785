unit Forms.BudgetPlans;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, Data.DB, Datasnap.DBClient,
  pas2web.dadataset, WEBLib.Actions, pas2web.datatables, pas2web.dadatasethelper;

type
  TfrmBudgetPlans = class(TfrmBase)
    dsBudgetPlans: TP2WDADataset;
    dcBudgetPlans: TP2WDatatable;
    procedure dsBudgetPlansAfterOpen(DataSet: TDataSet);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmBudgetPlans: TfrmBudgetPlans;

implementation

Uses
  Modules.Server, Units.ActionUtils;

{$R *.dfm}

procedure TfrmBudgetPlans.dsBudgetPlansAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
  iCnt: Integer;
begin
  aResult := dsBudgetPlans.GetNewRows(False);
  dcBudgetPlans.Data := aResult;
  dcBudgetPlans.RenderTranslated;

  iCnt := dsBudgetPlans.RecordCount;
  alForm['actTotal'].Value := IntToStr(iCnt);
end;

procedure TfrmBudgetPlans.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmBudgetPlans.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.CSRConnection.Message.ClientID := Server.ClientID;
  dsBudgetPlans.DAConnection := Server.CSRConnection;
  dsBudgetPlans.Load([], nil);
end;

procedure TfrmBudgetPlans.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dcBudgetPlans := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dcBudgetPlans.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'total';
      Name := 'actTotal';
    end;
    dcBudgetPlans.SetParentComponent(Self);
    dcBudgetPlans.Name := 'dcBudgetPlans';
    dcBudgetPlans.Columns.Clear;
    with dcBudgetPlans.Columns.Add do
    begin
      FieldName := 'bplname';
      Title := 'Description';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcBudgetPlans.Columns.Add do
    begin
      FieldName := 'bplvalidfrom';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcBudgetPlans.Columns.Add do
    begin
      FieldName := 'bplvalidtill';
      Title := 'Closed On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcBudgetPlans.Columns.Add do
    begin
      FieldName := 'bplid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{bpldossierfk}}/budgetplan/{{bplid}}';
    end;
    dcBudgetPlans.Language := lEnglish;
    dcBudgetPlans.IsResponsive := False;
    dcBudgetPlans.GridID := 'grdBudgetPlans';
    dcBudgetPlans.UseFieldIndex := True;
    dcBudgetPlans.ShowSearch := False;
    dcBudgetPlans.ShowNumberOfEntries := False;
    dcBudgetPlans.ShowEntriesInfo := False;
    dcBudgetPlans.Paginate := True;
    dcBudgetPlans.DisplayReadOnly := False;
    dcBudgetPlans.CalculateTableWidth := True;
    dcBudgetPlans.Left := 32;
    dcBudgetPlans.Top := 184;
  finally
    alForm.AfterLoadDFMValues;
    dcBudgetPlans.AfterLoadDFMValues;
  end;
end;

end.