unit Forms.CSR.Files.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Files.Detail, Data.DB, Datasnap.DBClient,
  pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRFileDetails = class(TfrmFileDetails)
  private
    { Private declarations }
  public
    { Public declarations }
    class function GetHTMLFileName: string; override;
    function DossierPrefix : string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRFileDetails: TfrmCSRFileDetails;

implementation

{$R *.dfm}

function TfrmCSRFileDetails.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRFileDetails.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRFileDetails.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRFileDetails.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.
