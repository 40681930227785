unit Units.PageHandler;

{$IFDEF PAS2JS}
{$mode objfpc}
{$modeswitch externalclass}
{$ENDIF}

interface

uses
  Web,
  Classes,
  SysUtils,
  Units.Service.Dezq,
  WEBLib.Actions,
  WebRouter;

const
  TPL_HEADER = 'header';
  TPL_FOOTER = 'footer';
  TPL_MENU = 'menu';
  TPL_EXPIRATION = 'expiration';
  TPL_FASTADDPOSSESSIONS = 'fastaddpossessions';
  TPL_SEARCHCONTACT = 'searchcontact';
  TPL_EDITCONTACT = 'editcontact';
  TPL_CONFIRM = 'confirm';
  TPL_CONNECTACCOUNT = 'connectaccount';

type
  TJSMyURLSearchParams = class {$IFDEF PAS2JS} external name 'URLSearchParams' {$ENDIF} (TJSURLSearchParams) constructor new(aInit: string);
  end;

  { TPageHandler }
  TTimeoutResolution = (torLogout, torPing);
  TTimeoutResolver = reference to procedure(aResolution: TTimeoutResolution);
  TTimeoutPendingEvent = reference to procedure(Sender: TObject; resolveTimeout: TTimeoutResolver);

  TPageHandler = class(TComponent)
  private
    class var _instance: TPageHandler;
  private
    FHeader: TElementActionList;
    FMenu: TElementActionList;
    FFooter: TElementActionList;
    FMain: TJSHTMLElement;
    FEntryRoute: string;
    FHomeRoute: string;
    FLoginTime: TDateTime;
    FQuerySessionVar: string;
    FDefaultHomeRoute: string;
    FSkipLooper: Boolean;
    FTimeoutInterval: Integer;
    FInCheckTimeOut: Boolean;
    FOnTimeoutPending: TTimeoutPendingEvent;
    FLoggedIn: Boolean;
    procedure InitLooperMenu;
    procedure RenderFooter(const aUser: TDezqUser);
    procedure RenderHead(const aUser: TDezqUser);
    procedure RenderMenu(const aUser: TDezqUser);
    procedure HandleLoginFailed(Sender: TObject; aError: string);
    procedure HandleLogin(Sender: TObject);
    procedure HandleLogout(Sender: TObject);
    procedure DeleteHeader;
    procedure DeleteMenu;
    procedure DeleteFooter;
    procedure StartTimeOut;
    procedure RemoveTimeoutDialog;
    procedure CheckTimeout;
  public
    class constructor Create;
    class function Instance: TPageHandler;
    constructor Create(aOwner: TComponent); override;
    destructor Destroy; override;
    procedure SetupTemplates;
    procedure StartRouting;
    procedure ShowLogin;
    procedure LogoutRoute(URl: string; aRoute: TRoute; Params: TStrings);
    // Entry route
    property EntryRoute: string read FEntryRoute write FEntryRoute;
    property HomeRoute: string read FHomeRoute;
    property DefaultHomeRoute: string read FDefaultHomeRoute write FDefaultHomeRoute;
    property LoginTime: TDateTime read FLoginTime;
    property QuerySessionVar: string read FQuerySessionVar write FQuerySessionVar;
    property SkipLooper: Boolean read FSkipLooper write FSkipLooper;
    property InCheckTimeOut: Boolean read FInCheckTimeOut;
    property OnTimeoutPending: TTimeoutPendingEvent read FOnTimeoutPending write FOnTimeoutPending;
    // GUI helping property only, don't depend on it to do business logic
    property LoggedIn: Boolean read FLoggedIn;
  end;

function PageHandler: TPageHandler;

implementation

uses
  JS,
  Units.Logging,
  Units.Templates,
  Rtl.TemplateLoader,
  Units.Looper,
  Units.ActionUtils,
  Modules.Server,
  Units.FormManager,
  WEBLib.Controls,
  lib.formtranslation,
  Units.ServerConfig,
  lib.bootstrap,
  libjquery,
  Units.HTMLUtils,
  rosdk,
  rstranslate,
  pas2web.datatables,
  lib.jqueryhelpers;

function PageHandler: TPageHandler;
begin
  Result := TPageHandler.Instance;
end;

{ TPageHandler }

class constructor TPageHandler.Create;
begin
  _instance := TPageHandler.Create(nil);
end;

class function TPageHandler.Instance: TPageHandler;
begin
  if _instance = nil then
    _instance := TPageHandler.Create(nil);
  Result := _instance;
end;

procedure TPageHandler.LogoutRoute(URl: string; aRoute: TRoute; Params: TStrings);
begin
  Server.DoLogout;
end;

constructor TPageHandler.Create(aOwner: TComponent);

  procedure ClickLogout(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  begin
    FLoggedIn := True;
    Server.DoLogout;
  end;

var
  aLogout: TElementAction;
begin
  inherited Create(aOwner);
  FLoggedIn := False;
  FDefaultHomeRoute := 'start';
  FQuerySessionVar := 'SID';
  FMain := TJSHTMLElement(Document.getElementById('main'));
  FHeader := TElementActionList.Create(Self);
  FMenu := TElementActionList.Create(Self);
  FFooter := TElementActionList.Create(Self);
  with FHeader do
  begin
    AddAction('me', 'header-parent');
    AddAction('name', 'header-account-name');
    AddAction('description', 'header-account-description');
    AddAction('user', 'header-user-name');
    AddAction('avatar', 'header-account-avatar');
    aLogout := AddAction('logout', 'logout-link', heClick);
    aLogout.StopPropagation := False;
    aLogout.onExecute := @ClickLogout;
  end;
  FMenu.AddAction('me', 'menu-parent');
  FFooter.AddAction('me', 'footer-parent');
  FMenu.BindAll;
  FFooter.BindAll;
  FHeader.BindAll;
end;

procedure TPageHandler.DeleteFooter;
begin
  FFooter['me'].InnerHTML := '';
end;

procedure TPageHandler.DeleteHeader;
begin
  FHeader['me'].InnerHTML := '';
end;

procedure TPageHandler.DeleteMenu;
begin
  FMenu['me'].InnerHTML := '';
  FMenu['me'].ElementHandle.className := '';
end;

destructor TPageHandler.Destroy;
begin
  FreeAndNil(FHeader);
  FreeAndNil(FMenu);
  FreeAndNil(FFooter);
  inherited Destroy;
end;

procedure TPageHandler.InitLooperMenu;
{
 Initialize the various looper hooks that have effect on the header/menu:
 These have to be done after login, since the necessary tags are only present after the user has logged in:
 before login, they are not present so the hooks are not installed.
}
begin
  if not SkipLooper then
  begin
    Looper.toggleAside;
    Looper.asideMenu;
    Looper.hamburger;
  end;
  with FormTranslator do
  begin
    if Language <> '' then
    begin
      EnableInformation := True;
      TranslateBelow(TJSHTMLElement(FMenu['me'].ElementHandle), 'index');
      TranslateBelow(TJSHTMLElement(FHeader['me'].ElementHandle), 'index');
      TranslateBelow(TJSHTMLElement(FFooter['me'].ElementHandle), 'index');
    end;
  end;
end;

procedure TPageHandler.RenderHead(const aUser: TDezqUser);
begin
  FHeader['me'].InnerHTML := GlobalTemplates.Templates[TPL_HEADER];
  FHeader.BindAll;
  FHeader['user'].InnerHTML := aUser.FirstName;
  FHeader['name'].InnerHTML := aUser.FirstName + ' ' + aUser.LastName;
  FHeader['description'].InnerHTML := 'Gebruiker ' + aUser.UserID;
  FHeader['user'].InnerHTML := aUser.FirstName;
  FHeader['avatar'].Value := AbsoluteURL(ServerConfig.ServerURL, 'avatar/me?SessionID=' + dmServer.ClientID);
end;

procedure TPageHandler.RenderMenu(const aUser: TDezqUser);
begin
  FMenu['me'].InnerHTML := GlobalTemplates.Templates[TPL_MENU];
  FMenu.BindAll;
  FMenu['me'].ElementHandle.className := 'app-aside app-aside-expand-md app-aside-light';
end;

procedure TPageHandler.RemoveTimeoutDialog;
var
  aDialogDiv, aBody, aBackdrop: TJSHTMLElement;
begin
  if Document.getElementById('session-timeout-dialog') <> nil then
  begin
    aDialogDiv := TJSHTMLElement(Document.getElementById('session-timeout-dialog'));
    aBody := TJSHTMLElement(aDialogDiv.ParentNode);
    aBody.RemoveChild(aDialogDiv);
    aBackdrop := TJSHTMLElement(aBody.lastElementChild);
    if aBackdrop.HasClass('modal-backdrop') then
      aBody.RemoveChild(aBackdrop);
  end;
end;

procedure TPageHandler.RenderFooter(const aUser: TDezqUser);
begin
  FFooter['me'].InnerHTML := GlobalTemplates.Templates[TPL_FOOTER];
  FFooter.BindAll;
end;

procedure TPageHandler.CheckTimeout;

  procedure resolveTimeout(aResolution: TTimeoutResolution);
  begin
    FInCheckTimeOut := False;
    case aResolution of
      torLogout:
        dmServer.DoLogout;
      torPing:
        dmServer.DoPing;
    end;
  end;

begin
  // Do not put this under if InCheckTimeout - the clock must remain ticking...
  if dmServer.ServerTimeOut then
  begin
    FInCheckTimeOut := False; // In case we end up back here after user logs in again...
    dmServer.DoLogout
  end
  else
  begin
    if InCheckTimeOut then
      exit;
    if dmServer.ServerTimeOutPending then
    begin
      FInCheckTimeOut := True;
      if Assigned(FOnTimeoutPending) then
        FOnTimeoutPending(Self, @resolveTimeout);
    end;
  end;
end;

procedure TPageHandler.HandleLogin(Sender: TObject);

  procedure DoTemplate(Sender: TObject; const aTemplate: string);
  begin
    Log.Log(ltDebug, className, 'HandleLogin', 'Template %s available', [aTemplate]);
    case aTemplate of
      TPL_HEADER:
        RenderHead(Server.UserInfo);
      TPL_MENU:
        RenderMenu(Server.UserInfo);
      TPL_FOOTER:
        RenderFooter(Server.UserInfo);
    end;
  end;

var
  DTLanguage, ResStrings: TJSObject;
  Lang: string;
begin
  FLoginTime := Now;
  FLoggedIn := True;
  Log.Log(ltDebug, className, 'HandleLogin', 'Page manager handle login');
  StartTimeOut;
  Lang := Server.UserLanguage;
  if (Lang <> '') then
    with FormTranslator do
    begin
      Language := Lang;
      ResStrings := GetScope('__resourcestrings__');
      if (ResStrings <> nil) then
        rstranslate.Translate(ResStrings);
      DTLanguage := GetScope('__datatables__');
      if DTLanguage <> nil then
        TP2WDatatable.DefaultLanguage := DTLanguage;
    end;
  FMain.className := 'app-main';
  TemplateManager.ExecuteIfTemplate(TPL_HEADER, @DoTemplate);
  TemplateManager.ExecuteIfTemplate(TPL_MENU, @DoTemplate);
  TemplateManager.ExecuteIfTemplate(TPL_FOOTER, @DoTemplate);
  if (GlobalTemplates.Templates[TPL_MENU] <> '') and (GlobalTemplates.Templates[TPL_HEADER] <> '') then
  begin
    Log.Log(ltDebug, className, 'HandleLogin', 'Head: Header and menu OK, calling InitLooperMenu');
    InitLooperMenu;
  end;
  FHomeRoute := EntryRoute;
  Log.Log(ltInfo, className, 'SetupTemplates', ' HomeRoute: ' + FHomeRoute);
  if (FHomeRoute = '') or (Pos('login', LowerCase(FHomeRoute)) in [1, 2]) or (Pos('changepassword', LowerCase(FHomeRoute)) in [1, 2]) then
  begin
    case Server.UserType of
      utEmployee, utCustomer:
        FHomeRoute := FDefaultHomeRoute;
      utDebtor:
        FHomeRoute := FDefaultHomeRoute;
      utAdmin:
        FHomeRoute := FDefaultHomeRoute;
    end;
  end;
  Router.RouteRequest(FHomeRoute, True);
end;

procedure TPageHandler.SetupTemplates;
const
  HTMLExt = '.html';
begin
  Log.Log(ltDebug, className, 'HandleLogin', 'Page manager setup templates');
  GlobalTemplates.LoadTemplates([TPL_HEADER, TPL_HEADER + HTMLExt,
                                 TPL_FOOTER, TPL_FOOTER + HTMLExt,
                                 TPL_MENU, TPL_MENU + HTMLExt,
                                 TPL_EXPIRATION, TPL_EXPIRATION + HTMLExt,
                                 TPL_FASTADDPOSSESSIONS, TPL_FASTADDPOSSESSIONS + HTMLExt,
                                 TPL_SEARCHCONTACT, TPL_SEARCHCONTACT + HTMLExt,
                                 TPL_EDITCONTACT, TPL_EDITCONTACT + HTMLExt,
                                 TPL_CONFIRM, TPL_CONFIRM + HTMLExt,
                                 TPL_CONNECTACCOUNT, TPL_CONNECTACCOUNT + HTMLExt]);
end;

procedure TPageHandler.HandleLoginFailed(Sender: TObject; aError: string);
begin
  FLoggedIn := False;
  ShowLogin;
end;

procedure TPageHandler.ShowLogin;
begin
  JQuery('#session-timeout-dialog').ModalHide;
  DeleteHeader;
  DeleteMenu;
  DeleteFooter;
  FormManager.ShowRoute('login', nil, nil);
end;

procedure TPageHandler.HandleLogout(Sender: TObject);
begin
  FLoggedIn := False;
  FEntryRoute := '';
  Log.Log(ltDebug, className, 'HandleLogout', 'Page manager handle logout');
  RemoveTimeoutDialog;
  DeleteHeader;
  DeleteMenu;
  DeleteFooter;
  FMain.className := '';
  if (FTimeoutInterval <> 0) then
  begin
    Window.ClearInterval(FTimeoutInterval);
    FTimeoutInterval := 0;
  end;
  ShowLogin;
end;

procedure TPageHandler.StartRouting;
var
  sid: JSValue;
  S: string;
begin
  Log.Debug(className, 'StartRouting', 'Start routing');
  EntryRoute := Copy(Window.location.hash, 2, Length(Window.location.hash) - 1);
  Server.OnLogin := @HandleLogin;
  Server.OnLogout := @HandleLogout;
  Server.OnLoginFailed := @HandleLoginFailed;
  S := '';
  if Window.location.search <> '' then
    with TJSMyURLSearchParams.new(Window.location.search) do
    begin
      sid := Get(QuerySessionVar);
      if isString(sid) then
        S := string(sid);
    end;
  if S = '' then
    S := Server.GetLocalData(KeySessionID);
  if isString(S) and (S <> '') then
  begin
    Log.Debug(className, 'StartRouting', 'Checking session: ' + S);
    Server.CheckSession(S);
  end
  else
  begin
    if (EntryRoute = '') then
    begin
      Log.Debug(className, 'StartRouting', 'Initial route: routing to login page.');
      ShowLogin;
    end
    else
      if FormManager.RouteNeedsLogin(EntryRoute) then
      begin
        Log.Debug(className, 'StartRouting', 'No session, login needed for route: routing to login page.');
        ShowLogin;
      end
      else
      begin
        Log.Debug(className, 'StartRouting', 'No session, ,no login needed for route: routing to requested route.');
        Router.RouteRequest(EntryRoute, True);
      end;
  end;
end;

procedure TPageHandler.StartTimeOut;
begin
  // Check every three seconds
  FTimeoutInterval := Window.SetInterval(@CheckTimeout, 3 * 1000);
end;

end.
