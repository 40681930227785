program Dezq;

uses
  WEBLib.Forms,
  Forms.Base in '..\Forms.Base.pas'{*.html},
  Units.FormManager in '..\Units.FormManager.pas',
  Forms.Login in 'Forms.Login.pas'{*.html},
  Units.ActionUtils in '..\Units.ActionUtils.pas',
  Units.HTMLUtils in '..\Units.HTMLUtils.pas',
  Modules.Server in '..\Modules.Server.pas',
  Units.PageHandler in '..\Units.PageHandler.pas',
  Units.Routes in 'Units.Routes.pas',
  Units.Service.Dezq in '..\Units.Service.Dezq.pas',
  Units.Strings in '..\Units.Strings.pas',
  Forms.Error in '..\Forms.Error.pas'{*.html},
  Forms.Start in 'Forms.Start.pas'{*.html},
  Units.ServerConfig in '..\Units.ServerConfig.pas',
  Units.JSON in '..\..\Common\Units.JSON.pas',
  Units.Logging in '..\..\Common\Units.Logging.pas',
  Units.Start in '..\Units.Start.pas',
  Forms.ResetPassword in 'Forms.ResetPassword.pas'{*.html},
  Forms.Signon in 'Forms.Signon.pas'{*.html},
  Forms.NewPetition in 'Forms.NewPetition.pas'{*.html},
  Forms.ListPetitions in 'Forms.ListPetitions.pas'{*.html},
  Forms.Dossier in 'Forms.Dossier.pas'{*.html},
  Forms.Identity in 'Forms.Identity.pas'{*.html},
  Forms.Family in 'Forms.Family.pas'{*.html},
  Forms.Family.Child in 'Forms.Family.Child.pas'{*.html},
  Forms.Family.Inmate in 'Forms.Family.Inmate.pas'{*.html},
  Forms.Revenue in 'Forms.Revenue.pas'{*.html},
  Forms.Revenue.Income in 'Forms.Revenue.Income.pas'{*.html},
  Forms.Revenue.Debtor in 'Forms.Revenue.Debtor.pas'{*.html},
  Forms.Possession in 'Forms.Possession.pas'{*.html},
  Forms.Possession.Prop in 'Forms.Possession.Prop.pas'{*.html},
  Forms.Realestate in 'Forms.Realestate.pas'{*.html},
  Forms.Realestate.Prop in 'Forms.Realestate.Prop.pas'{*.html},
  Forms.Debt in 'Forms.Debt.pas'{*.html},
  Forms.Debt.Details in 'Forms.Debt.Details.pas'{*.html},
  Forms.Expenses in 'Forms.Expenses.pas'{*.html},
  Forms.Expense.Detail in 'Forms.Expense.Detail.pas'{*.html},
  Forms.Salarytransfer in 'Forms.Salarytransfer.pas'{*.html},
  Forms.Salarytransfer.Detail in 'Forms.Salarytransfer.Detail.pas'{*.html},
  Forms.Legalcase in 'Forms.Legalcase.pas'{*.html},
  Forms.Legalcase.Detail in 'Forms.Legalcase.Detail.pas'{*.html},
  Forms.Debtmediator in 'Forms.Debtmediator.pas'{*.html},
  Forms.Statement in 'Forms.Statement.pas'{*.html},
  Units.Params in '..\Units.Params.pas',
  Forms.Signonapproval in 'Forms.Signonapproval.pas'{*.html},
  Forms.ArchivePetition in 'Forms.ArchivePetition.pas'{*.html},
  Forms.Signonapproval.Detail in 'Forms.Signonapproval.Detail.pas'{*.html},
  Units.Types in '..\..\Common\Units.Types.pas',
  Module.Country in '..\Module.Country.pas',
  Forms.Contacts in 'Forms.Contacts.pas'{*.html},
  Units.DADatasetUtils in '..\Units.DADatasetUtils.pas',
  Forms.Customer in 'Forms.Customer.pas'{*.html},
  Forms.Contacts.List in 'Forms.Contacts.List.pas'{*.html},
  Forms.Contact.Detail in 'Forms.Contact.Detail.pas'{*.html},
  Forms.Contact.Address in 'Forms.Contact.Address.pas'{*.html},
  Forms.Contact.Telecom in 'Forms.Contact.Telecom.pas'{*.html},
  Units.SessionTimeout in '..\Units.SessionTimeout.pas',
  Forms.WIP in 'Forms.WIP.pas'{*.html},
  Forms.ChangePassword in 'Forms.ChangePassword.pas'{*.html},
  Forms.Profile in '..\Forms.Profile.pas'{*.html},
  Forms.SubmitPetition in 'Forms.SubmitPetition.pas'{*.html},
  Forms.DossierDetail in 'Forms.DossierDetail.pas',
  Forms.Representative in 'Forms.Representative.pas'{*.html},
  Forms.CompanyProfile in 'Forms.CompanyProfile.pas'{*.html},
  Forms.CompanyUsers in 'Forms.CompanyUsers.pas'{*.html},
  Forms.CompanyUser.Detail in 'Forms.CompanyUser.Detail.pas'{*.html},
  Module.Contacts in '..\Module.Contacts.pas',
  Units.DocumentBox in '..\Units.DocumentBox.pas',
  Units.Contacts in '..\Units.Contacts.pas',
  Units.ConfirmDialog in '..\Units.ConfirmDialog.pas',
  Forms.CSR in 'CSR\Forms.CSR.pas'{*.html},
  Forms.CSR.Identity in 'CSR\Forms.CSR.Identity.pas'{*.html},
  Forms.ListCSR in 'CSR\Forms.ListCSR.pas'{*.html},
  Forms.CSR.Family in 'CSR\Forms.CSR.Family.pas'{*.html},
  Forms.CSR.Family.Child in 'CSR\Forms.CSR.Family.Child.pas'{*.html},
  Forms.CSR.Family.Inmate in 'CSR\Forms.CSR.Family.Inmate.pas'{*.html},
  Forms.CSR.Revenue in 'CSR\Forms.CSR.Revenue.pas'{*.html},
  Forms.CSR.Revenue.Income in 'CSR\Forms.CSR.Revenue.Income.pas'{*.html},
  Forms.CSR.Revenue.Debtor in 'CSR\Forms.CSR.Revenue.Debtor.pas'{*.html},
  Forms.CSR.Realestate in 'CSR\Forms.CSR.Realestate.pas'{*.html},
  Forms.CSR.Realestate.Prop in 'CSR\Forms.CSR.Realestate.Prop.pas'{*.html},
  Forms.CSR.Possession in 'CSR\Forms.CSR.Possession.pas'{*.html},
  Forms.CSR.Possession.Prop in 'CSR\Forms.CSR.Possession.Prop.pas'{*.html},
  Forms.CSR.Debt in 'CSR\Forms.CSR.Debt.pas'{*.html},
  Forms.CSR.Debt.Details in 'CSR\Forms.CSR.Debt.Details.pas'{*.html},
  Forms.CSR.Expenses in 'CSR\Forms.CSR.Expenses.pas'{*.html},
  Forms.CSR.Expense.Detail in 'CSR\Forms.CSR.Expense.Detail.pas'{*.html},
  Forms.CSR.SalaryTransfer in 'CSR\Forms.CSR.SalaryTransfer.pas'{*.html},
  Forms.CSR.SalaryTransfer.Detail in 'CSR\Forms.CSR.SalaryTransfer.Detail.pas'{*.html},
  Forms.CSR.Legalcase in 'CSR\Forms.CSR.Legalcase.pas'{*.html},
  Forms.CSR.Legalcase.Detail in 'CSR\Forms.CSR.Legalcase.Detail.pas'{*.html},
  Forms.CSR.Representative in 'CSR\Forms.CSR.Representative.pas'{*.html},
  Forms.NewCSR in 'CSR\Forms.NewCSR.pas'{*.html},
  Forms.CSR.CreateCSR in 'CSR\Forms.CSR.CreateCSR.pas'{*.html},
  Units.DezqValidator in '..\..\Common\Units.DezqValidator.pas',
  Forms.CSR.Copy in 'CSR\Forms.CSR.Copy.pas'{*.html},
  Forms.CSR.SettlementPlanList in 'CSR\Forms.CSR.SettlementPlanList.pas'{*.html},
  Forms.CSR.SettlementPlan.New in 'CSR\Forms.CSR.SettlementPlan.New.pas'{*.html},
  Forms.CSR.BudgetPlan in 'CSR\Forms.CSR.BudgetPlan.pas'{*.html},
  Forms.CSR.SettlementPlan.Edit in 'CSR\Forms.CSR.SettlementPlan.Edit.pas'{*.html},
  Forms.CSR.SettlementPlan.Copy in 'CSR\Forms.CSR.SettlementPlan.Copy.pas'{*.html},
  Forms.TrustAccountList in 'Forms.TrustAccountList.pas'{*.html},
  Forms.CSR.TrustAccount in 'CSR\Forms.CSR.TrustAccount.pas'{*.html},
  Forms.CSR.TrustAccount.Details in 'CSR\Forms.CSR.TrustAccount.Details.pas'{*.html},
  Forms.CSR.TrustAccount.PaymentOrder in 'CSR\Forms.CSR.TrustAccount.PaymentOrder.pas'{*.html},
  Forms.CSR.TrustAccount.PaymentOrder.Detail in 'CSR\Forms.CSR.TrustAccount.PaymentOrder.Detail.pas'{*.html},
  Forms.CSR.TrustAccount.Movements in 'CSR\Forms.CSR.TrustAccount.Movements.pas'{*.html},
  Forms.CSR.TrustAccount.Movements.Detail in 'CSR\Forms.CSR.TrustAccount.Movements.Detail.pas'{*.html},
  Forms.CSR.TrustAccount.Recurringpayment in 'CSR\Forms.CSR.TrustAccount.Recurringpayment.pas'{*.html},
  Forms.CSR.TrustAccount.Recurringpayment.Detail in 'CSR\Forms.CSR.TrustAccount.Recurringpayment.Detail.pas'{*.html},
  Forms.Messages in 'Forms.Messages.pas'{*.html},
  Forms.CSR.Alarms in 'CSR\Forms.CSR.Alarms.pas'{*.html},
  Forms.CSR.Alarms.Detail in 'CSR\Forms.CSR.Alarms.Detail.pas'{*.html},
  Forms.Files in 'Forms.Files.pas'{*.html},
  Forms.Files.Detail in 'Forms.Files.Detail.pas'{*.html},
  Forms.CSR.Files in 'CSR\Forms.CSR.Files.pas'{*.html},
  Forms.CSR.Files.Detail in 'CSR\Forms.CSR.Files.Detail.pas'{*.html},
  Forms.BudgetPlans in 'CSR\Forms.BudgetPlans.pas'{*.html},
  Units.Looper in '..\..\Common\Units.Looper.pas',
  Forms.ExportContacts in 'Forms.ExportContacts.pas'{*.html},
  Forms.ImportContacts in 'Forms.ImportContacts.pas'{*.html},
  Forms.Import.Mapping in 'Forms.Import.Mapping.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  StartDezq;
  Application.Run;
end.
