unit Units.ActionUtils;

interface

uses SysUtils, Web, Units.HTMLUtils, WEBLib.Actions;

Type
  EWebAction = Class(Exception);
  TForeachCallBack = Reference to Procedure(aElement: TJSHTMLElement);

  TElementActionHelper = Class helper for TElementAction
  private
    function GetInnerHTML: String;
    procedure SetInnerHTML(const Value: String);
    function GetInnerText: String;
    procedure SetInnerText(const Value: String);
    function GetValue: String;
    procedure SetValue(const Value: String);
    function GetChecked: Boolean;
    procedure SetChecked(const Value: Boolean);
  Protected
    Function SingleEl(RaiseException: Boolean = False): TJSHTMLElement;
  Public
    function ForEach(aCallBack: TForeachCallBack): Integer;
    // Add a class to each element.
    procedure AddClass(Const aClass: String); overload;
    // Remove a class from each element.
    procedure RemoveClass(Const aClass: String); overload;
    // Add & Remove a class from each element.
    procedure AddRemoveClass(Const aAddClass, aRemoveClass: String); overload;
    // Get/Set inner html. Get only for 1, write for multiple.
    Property InnerHTML: String Read GetInnerHTML Write SetInnerHTML;
    // Get/Set inner text. Get only for 1, write for multiple.
    Property InnerText: String Read GetInnerText Write SetInnerText;
    // Get/Set value. Get only for 1, write for multiple.
    Property Value: String Read GetValue Write SetValue;
    // Get/Set checked. Get only for 1, write for multiple.
    Property Checked: Boolean Read GetChecked Write SetChecked;
  End;

  TElementActionListHelper = class helper for TElementActionList
  Public
    Function AddAction(aName, aID: String; aEvent: THTMLEvent = heNone; UseSelector: Boolean = False): TElementAction;
    Procedure BindAll;
  end;

implementation

{ TElementActionHelper }

procedure TElementActionHelper.AddClass(const aClass: String);
begin
  ForEach(
    procedure(el: TJSHTMLElement)
    begin
      Units.HTMLUtils.AddClass(el, aClass);
    end);
end;

procedure TElementActionHelper.AddRemoveClass(const aAddClass, aRemoveClass: String);
begin
  ForEach(
    procedure(el: TJSHTMLElement)
    begin
      Units.HTMLUtils.AddRemoveClass(el, aAddClass, aRemoveClass);
    end);
end;

function TElementActionHelper.ForEach(aCallBack: TForeachCallBack): Integer;

Var
  el: TJSHTMLElement;
  els: Array of TJSHTMLElement;

begin
  Result:=0;
  if (Self=Nil) then
    begin
    Writeln('Error: Self is nil');
    Exit;
    end;
  if (ID<>'') then
  begin
    el:=SingleEl(False);
    If Assigned(el) then
    begin
      aCallBack(el);
      Result:=1;
    end;
  end
  else if Selector<>'' then
  begin
    els:=nil;
    asm
      els=this.FElementHandles;
    end;
    For el in els do
      if (el<>Nil) then
      begin
        aCallBack(el);
        inc(Result);
      end;
  end;
end;

function TElementActionHelper.GetChecked: Boolean;

Var
  el: TJSHTMLElement;

begin
  el:=SingleEl;
  if (el is TJSHTMLInputElement) then
    Result:=TJSHTMLInputElement(el).Checked
  else
    Raise EWebAction.CreateFmt('Action %s is not an INPUT element', [Name]);
end;

function TElementActionHelper.GetInnerHTML: String;
begin
  Result:=SingleEl.InnerHTML;
end;

function TElementActionHelper.GetInnerText: String;
begin
  Result:=SingleEl.InnerText;
end;

function TElementActionHelper.GetValue: String;

begin
  Result:=GetInputValue(SingleEl);
end;

procedure TElementActionHelper.RemoveClass(const aClass: String);
begin
  ForEach(
    procedure(el: TJSHTMLElement)
    begin
      Units.HTMLUtils.RemoveClass(el, aClass);
    end);
end;

procedure TElementActionHelper.SetChecked(const Value: Boolean);

begin
  ForEach(
    procedure(el: TJSHTMLElement)
    begin
      if (el is TJSHTMLInputElement) then
        TJSHTMLInputElement(el).Checked:=Value;
    end);
end;

procedure TElementActionHelper.SetInnerHTML(const Value: String);

begin
  ForEach(
    Procedure(el: TJSHTMLElement)
    begin
      el.InnerHTML:=Value;
    end);
end;

procedure TElementActionHelper.SetInnerText(const Value: String);
begin
  ForEach(
    Procedure(el: TJSHTMLElement)
    begin
      el.InnerText:=Value
    end);
end;

procedure TElementActionHelper.SetValue(const Value: String);
begin
  ForEach(
    Procedure(el: TJSHTMLElement)
    begin
      SetInputValue(el, Value);
    end);
end;

function TElementActionHelper.SingleEl(RaiseException: Boolean = False): TJSHTMLElement;
begin
  Result:=TJSHTMLElement(ElementHandle);
  if (Result=Nil) and RaiseException then
    Raise EWebAction.CreateFmt('Element of action %s (ID: %s) is Nil', [Name, ID]);
end;

Function TElementActionListHelper.AddAction(aName, aID: String; aEvent: THTMLEvent = heNone; UseSelector: Boolean = False)
  : TElementAction;

begin
  Result:=Actions.Add;
  With Result do
  begin
    Name:=aName;
    if UseSelector then
      Selector:=aID
    else
      ID:=aID;
    Event:=aEvent;
  end;
end;

procedure TElementActionListHelper.BindAll;

Var
  I: Integer;

begin
  For I:=0 to Actions.Count-1 do
    Actions[I].Bind;
end;

end.
