unit lib.bootstrap;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  JS,
  web,
  libjquery;

type

  { TP2WBootstrap }

  TP2WBootstrapModalOptions = class external name 'Object'(TJSObject)
  public
    show: boolean;
    focus: boolean;
    keyboard: boolean;
    backdrop: boolean;
  end;

  TP2WBootstrapToastOptions = class external name 'Object'(TJSObject)
  public
    animation: boolean;
    autohide: boolean;
    delay: NativeInt;
  end;

  TSanitizeFunction = reference to function(arg: jsValue): jsValue;

  TP2WBootstrapTooltipOptions = class external name 'Object'(TJSObject)
  public
    animation: boolean;
    container: jsValue;
    delay: jsValue;
    html: boolean;
    placement: jsValue;
    selector: jsValue;
    template: string;
    title: jsValue;
    trigger: string;
    offset: jsValue;
    fallbackPlacement: jsValue;
    boundary: jsValue;
    sanitize: boolean;
    whiteList: TJSObject;
    sanitizeFn: TSanitizeFunction;
  end;

  TP2WBootstrapCarouselOptions = class external name 'Object'(TJSObject)
  public
    interval: NativeInt;
    keyboard: boolean;
    pause: boolean;
    pauseStr: string; external name 'pause';
    ride: string;
    wrap: boolean;
    touch: boolean;
  end;

  TP2WBootstrapDropDownOptions = class external name 'Object'(TJSObject)
  public
    offset: jsValue;
    flip: boolean;
    boundary: string;
    boundaryEl: TJSHTMLElement; external name 'boundary';
    reference: string;
    referenceEl: TJSHTMLElement; external name 'reference';
    display: string;
  end;

  TP2WBootstrapPopoverDelayOptions = class external name 'Object'(TJSObject)
  public
    show: NativeInt;
    hide: NativeInt;
  end;

  TPopoverContentFunction = reference to function(arg: jsValue): jsValue;
  TPopoverPlacementFunction = reference to function(popoverNode, triggeringNode: jsValue): jsValue;

  TP2WBootstrapPopoverOptions = class external name 'Object'(TJSObject)
  public
    animation: boolean;
    container: string;
    containerEl: TJSHTMLElement; external name 'container';
    content: string;
    contentEl: TJSHTMLElement; external name 'content';
    contentFn: TPopoverContentFunction; external name 'content';
    delay: NativeInt;
    delayObj: TP2WBootstrapPopoverDelayOptions; external name 'delay';
    html: boolean;
    placement: string;
    placementFn: TPopoverPlacementFunction; external name 'placement';
    selector: string;
    selectorBool: boolean; external name 'selector';
    template: string;
    title: string;
    titleEl: TJSHTMLElement; external name 'title';
    trigger: string;
    offset: string;
    offsetInt: Integer; external name 'offset';
    fallbackPlacement: string;
    boundary: string;
    boundaryEl: TJSHTMLElement; external name 'boundary';
  end;


  TSessionTimeoutOptions = Class;

  { TBootstrap }
  TTimeOutCallback = Reference to procedure(O : TSessionTimeoutOptions);

  TSessionTimeoutOptions = Class external name 'Object' (TJSObject)
    title: String;
    message: String;
    logoutButton: String;
    keepAliveButton:String;
    keepAliveUrl: String;
    ajaxType: String;
    ajaxData: String;
    redirUrl: String;
    logoutUrl: String;
    warnAfter: NativeInt;
    redirAfter: NativeInt;
    keepAliveInterval: NativeInt;
    keepAlive: Boolean;
    ignoreUserActivity: Boolean;
    countdownMessageBool: Boolean; external name 'countdownMessage';
    countdownMessage: String;
    countdownBar: Boolean;
    countdownSmart: Boolean;
    onStartBool: Boolean; external name 'onStart';
    onWarnBool: Boolean; external name 'onWarn';
    onRedirBool: Boolean; external name 'onRedir';
    onStart : TTimeoutCallBack;
    onWarn : TTimeoutCallBack;
    onRedir : TTimeoutCallBack;
  end;


  TJSDataEventHandler = function(Event: TEventListenerEvent; data: JSValue): Boolean of object;

implementation

end.
