unit Forms.NewCSR;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, Units.Service.Dezq;

type
  TIDParams = record
    dateOfBirth : TDateTime;
    gender: string;
    NIDNR: string;
  end;

  TfrmNewCSR = class(TfrmBase)
    procedure alFormActions3Execute(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure onCreateEmptyCSR(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); override;
    procedure onBtnSearchClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    FNIDNR : string;
    FDossierID: NativeInt;
    function isNoWellFormated(out idParams: TIDParams): Boolean;
    procedure LoadFormData(searchResult: TSearchPetitionResult);
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmNewCSR: TfrmNewCSR;

implementation

{$R *.dfm}

uses Units.ActionUtils, DateUtils, Modules.Server,
  libjquery, Units.HTMLUtils;

procedure TfrmNewCSR.alFormActions3Execute(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  window.location.href := '#/CSR/new/copy/' + intToStr(FDossierID) + '/' + FNIDNR;
end;

procedure TfrmNewCSR.onCreateEmptyCSR(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
   window.location.href := '#/CSR/new/create/' + FNIDNR;
end;

procedure TfrmNewCSR.WebFormCreate(Sender: TObject);
begin
  inherited;
  FNIDNR := '';
  FDossierID := -1;
end;

procedure TfrmNewCSR.LoadFormData(searchResult: TSearchPetitionResult);
var
  i, j : Integer;
  html : string;
  address : string;
begin
  alForm['edtName'].Value := searchResult.Person.FirstName + ' ' +
    searchResult.Person.LastName;
  alForm['edtBirthDate'].Value := FormatHTMLDate(JSDateToDateTime(searchResult.Person.BirthDateOn));

  for i := Low(searchResult.Person.Addresses) to High(searchResult.Person.Addresses) do
  begin
    if searchResult.Person.Addresses[i].AddressType = 'WP' then
    begin
      alForm['edtAddress'].Value := searchResult.Person.Addresses[i].Street;
      alForm['edtHouseNo'].Value := searchResult.Person.Addresses[i].StreetNr;
      alForm['edtCityZip'].Value := searchResult.Person.Addresses[i].CityZIP;
      alForm['edtCity'].Value := searchResult.Person.Addresses[i].CityName;
      break;
    end;
  end;

  for i := Low(searchResult.Creditors) to High(searchResult.Creditors) do
  begin
    html := html + Format('<tr><td>%f</td><td>%f</td><td>%s</td>', [
        searchResult.Creditors[i].CurrentAmount,
        searchResult.Creditors[i].Amount,
        searchResult.Creditors[i].Contact.FirstName + ' ' + searchResult.Creditors[i].Contact.LastName
      ]);
    address := '';

    for j := Low(searchResult.Creditors[i].Contact.Addresses) to High(searchResult.Creditors[i].Contact.Addresses) do
    begin
       if searchResult.Creditors[i].Contact.Addresses[j].AddressType = 'WP' then
       begin
        address := searchResult.Creditors[i].Contact.Addresses[j].CityZIP + ' ' +
          searchResult.Creditors[i].Contact.Addresses[j].CityName;
        break;
       end;
    end;
    html := html + Format('<td>%s</td></tr>', [address]);
    jQuery('#grdCreditors').find('tbody').html(html);
  end;
end;

procedure TfrmNewCSR.onBtnSearchClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
var
  idParams: TIDParams;

  procedure onSearchFail(anError: string);
  begin
    if anError <> '' then
      Server.ShowError(anError);
  end;

  procedure onSearchSuccess(searchResult: TSearchPetitionResult);
  begin
    if searchResult.DossierID > -1 then
    begin
      //navigate to second tab
      FNIDNR := idParams.NIDNR;
      FDossierID := searchResult.DossierID;
      jQuery('#secondTab').css('display', '');
      jQuery('#secondTab').find('a').click();
      LoadFormData(searchResult);
    end
    else
    begin
      Server.SetLocalData('csr-bdate', FormatDateTime('YYYY-MM-DD', idParams.dateOfBirth));
      Server.SetLocalData('csr-gender', idParams.gender);
      window.location.href := '#/CSR/new/create/' + idParams.NIDNR;
    end;
         
  end;

  procedure NationalNoCheckResult(aSuccess: Boolean; anError: string);
  begin
    if not aSuccess then
    begin
      DisplayError('edtNIDNR', '', anError);
    end
    else
    begin
      Server.CSRCheckExistingPetition(idParams.NIDNR, @onSearchSuccess, @onSearchFail);
    end;
  end;

var
  aUTCBirthDate: TJSDate;
begin
  Server.DeleteLocalData('csr-bdate');
  Server.DeleteLocalData('csr-gender');
  if not isNoWellFormated(idParams) then
    DisplayError('edtNIDNR')
  else
  begin
    aUTCBirthDate := TJSDate.new(YearOf(idParams.dateOfBirth), MonthOf(idParams.dateOfBirth), DayOfTheMonth(idParams.dateOfBirth));
    console.log(aUTCBirthDate);
    Server.isNationalNoValid(idParams.NIDNR, idParams.gender,
      aUTCBirthDate,
      @NationalNoCheckResult);
  end;
end;

function TfrmNewCSR.isNoWellFormated(out idParams: TIDParams): Boolean;
var
  number: string;
  num : NativeInt;
  checksum : Integer;
  year: Word;
begin
  number := alForm['edtNIDNR'].Value;
  number := StringReplace(number, '.', '', [rfReplaceAll]);
  number := StringReplace(number, '-', '', [rfReplaceAll]);
  if not TryStrToInt(Copy(number, 1, 9), num) then
    Exit(False);
  if not TryStrToInt(Copy(number, 10, 2), checksum) then
   Exit(False);
  //check century
  if (97 - (num mod 97)) = checksum then
    year := 1900
  else if (97 - ((num + 2000000000) mod 97)) = checksum then
    year := 2000
  else
    Exit(False);
  if (num mod 2) = 0 then
    idParams.gender := 'F'
  else
    idParams.gender := 'M';
  idParams.dateOfBirth := EncodeDate(year + StrToInt(Copy(number, 1, 2)),
    StrToInt(Copy(number, 3, 2)), StrToInt(Copy(number, 5, 2))
  );  
  idParams.NIDNR := number;
  Result := True;  
end;

procedure TfrmNewCSR.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmNewCSR.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'edtNIDNR';
      Name := 'edtNIDNR';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSearch';
      Name := 'btnSearch';
      SetEvent(Self, 'OnExecute', 'onBtnSearchClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtNIDNR';
      Name := 'editFocus';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnCopy';
      Name := 'btnCopy';
      SetEvent(Self, 'OnExecute', 'alFormActions3Execute');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnEmpty';
      Name := 'btnEmpty';
      SetEvent(Self, 'OnExecute', 'onCreateEmptyCSR');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtName';
      Name := 'edtName';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtBirthDate';
      Name := 'edtBirthDate';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtAddress';
      Name := 'edtAddress';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtHouseNo';
      Name := 'edtHouseNo';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtCityZip';
      Name := 'edtCityZip';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtCity';
      Name := 'edtCity';
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
