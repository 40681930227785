unit Forms.Salarytransfer;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.datatables, pas2web.dadatasethelper,
  Units.DADatasetUtils, Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmSalarytransfer = class(TfrmBase)
    dcSalary: TP2WDatatable;
    dsSalary: TP2WDADataset;
    dsSalarydebid: TLargeintField;
    dsSalarydebcreatedon: TDateTimeField;
    dsSalarydebcreatedbyfk: TLargeintField;
    dsSalarydebchangedon: TDateTimeField;
    dsSalarydebchangedbyfk: TLargeintField;
    dsSalarydebdossierfk: TLargeintField;
    dsSalarydebrecordtype: TSmallintField;
    dsSalarydebcreditorfk: TLargeintField;
    dsSalarydebdebttypefk: TLargeintField;
    dsSalarydebdebttype: TStringField;
    dsSalarydebstarton: TDateTimeField;
    dsSalarydebreference: TStringField;
    dsSalarydeboriginalamount: TFMTBCDField;
    dsSalarydebcurrentamount: TFMTBCDField;
    dsSalarydebmonthlyamount: TFMTBCDField;
    dsSalarydebisdisputed: TBooleanField;
    dsSalarydebdisputereason: TStringField;
    dsSalarydebdisputecourt: TStringField;
    dsSalarydebdisputedamount: TFMTBCDField;
    dsSalarydebisdelay: TBooleanField;
    dsSalarydebdelayamount: TFMTBCDField;
    dsSalarydebguarantorfk: TLargeintField;
    dsSalarydebiscompleted: TBooleanField;
    dsSalarydebcreditor: TWideMemoField;
    dsSalarydebguarantor: TWideMemoField;
    procedure dsSalaryAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure dsSalaryAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
  private
    { Private declarations }
    FDoDelete : Boolean;
    FDeleteID : NativeInt;
    FRoute : string;
    procedure ConfirmDeleteSalaryTransfer;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDelete(const debid: NativeInt);
  protected procedure LoadDFMValues; override; end;

var
  frmSalarytransfer: TfrmSalarytransfer;

implementation

{$R *.dfm}

uses
  Modules.Server, lib.bootstrap, Units.Strings;

{ TfrmSalarytransfer }

procedure TfrmSalarytransfer.ConfirmDeleteSalaryTransfer;
  Procedure DoDeleteRecord;

  begin
    if dsSalary.Locate('debid', FDeleteID,[]) then
      begin
      dsSalary.Delete;
      dsSalary.ApplyUpdates;
      end;
  end;

  Procedure DoCancelDelete;

  begin
    window.location.href := FRoute;
  end;

begin
  FDoDelete := False;
  if dsSalary.Locate('debid', FDeleteID,[]) then
    DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
end;

procedure TfrmSalarytransfer.DoDelete(const debid: NativeInt);
begin
  FDeleteID := debid;
  if dsSalary.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteSalaryTransfer;
end;

procedure TfrmSalarytransfer.dsSalaryAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);

begin
  CheckResolveResults(Info,FRoute);
end;

procedure TfrmSalarytransfer.dsSalaryAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsSalary.GetNewRows(False);
  dcSalary.Data := aResult;
  dcSalary.RenderTranslated;
  if FDoDelete then
    ConfirmDeleteSalaryTransfer;
end;

procedure TfrmSalarytransfer.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDoDelete := False;
  FDeleteID := -1;
  FRoute := '';

  dsSalary.DAConnection := DossierConnection;
end;

procedure TfrmSalarytransfer.setParams(const Params: TStrings);
begin
  Inherited;
  FRoute := DossierURL('/salarytransfer');
  jQuery('#addSalarytransfer').attr('href',FRoute+'/new');
  dsSalary.ParamByName('DOSSIERID').asLargeInt := DossierID;
end;


procedure TfrmSalarytransfer.WebFormShow(Sender: TObject);
begin
  inherited;
  dsSalary.Load([], nil);
end;

procedure TfrmSalarytransfer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dcSalary := TP2WDatatable.Create(Self);
  dsSalary := TP2WDADataset.Create(Self);
  dsSalarydebid := TLargeintField.Create(Self);
  dsSalarydebcreatedon := TDateTimeField.Create(Self);
  dsSalarydebcreatedbyfk := TLargeintField.Create(Self);
  dsSalarydebchangedon := TDateTimeField.Create(Self);
  dsSalarydebchangedbyfk := TLargeintField.Create(Self);
  dsSalarydebdossierfk := TLargeintField.Create(Self);
  dsSalarydebrecordtype := TSmallintField.Create(Self);
  dsSalarydebcreditorfk := TLargeintField.Create(Self);
  dsSalarydebdebttypefk := TLargeintField.Create(Self);
  dsSalarydebdebttype := TStringField.Create(Self);
  dsSalarydebstarton := TDateTimeField.Create(Self);
  dsSalarydebreference := TStringField.Create(Self);
  dsSalarydeboriginalamount := TFMTBCDField.Create(Self);
  dsSalarydebcurrentamount := TFMTBCDField.Create(Self);
  dsSalarydebmonthlyamount := TFMTBCDField.Create(Self);
  dsSalarydebisdisputed := TBooleanField.Create(Self);
  dsSalarydebdisputereason := TStringField.Create(Self);
  dsSalarydebdisputecourt := TStringField.Create(Self);
  dsSalarydebdisputedamount := TFMTBCDField.Create(Self);
  dsSalarydebisdelay := TBooleanField.Create(Self);
  dsSalarydebdelayamount := TFMTBCDField.Create(Self);
  dsSalarydebguarantorfk := TLargeintField.Create(Self);
  dsSalarydebiscompleted := TBooleanField.Create(Self);
  dsSalarydebcreditor := TWideMemoField.Create(Self);
  dsSalarydebguarantor := TWideMemoField.Create(Self);

  alForm.BeforeLoadDFMValues;
  dcSalary.BeforeLoadDFMValues;
  dsSalary.BeforeLoadDFMValues;
  dsSalarydebid.BeforeLoadDFMValues;
  dsSalarydebcreatedon.BeforeLoadDFMValues;
  dsSalarydebcreatedbyfk.BeforeLoadDFMValues;
  dsSalarydebchangedon.BeforeLoadDFMValues;
  dsSalarydebchangedbyfk.BeforeLoadDFMValues;
  dsSalarydebdossierfk.BeforeLoadDFMValues;
  dsSalarydebrecordtype.BeforeLoadDFMValues;
  dsSalarydebcreditorfk.BeforeLoadDFMValues;
  dsSalarydebdebttypefk.BeforeLoadDFMValues;
  dsSalarydebdebttype.BeforeLoadDFMValues;
  dsSalarydebstarton.BeforeLoadDFMValues;
  dsSalarydebreference.BeforeLoadDFMValues;
  dsSalarydeboriginalamount.BeforeLoadDFMValues;
  dsSalarydebcurrentamount.BeforeLoadDFMValues;
  dsSalarydebmonthlyamount.BeforeLoadDFMValues;
  dsSalarydebisdisputed.BeforeLoadDFMValues;
  dsSalarydebdisputereason.BeforeLoadDFMValues;
  dsSalarydebdisputecourt.BeforeLoadDFMValues;
  dsSalarydebdisputedamount.BeforeLoadDFMValues;
  dsSalarydebisdelay.BeforeLoadDFMValues;
  dsSalarydebdelayamount.BeforeLoadDFMValues;
  dsSalarydebguarantorfk.BeforeLoadDFMValues;
  dsSalarydebiscompleted.BeforeLoadDFMValues;
  dsSalarydebcreditor.BeforeLoadDFMValues;
  dsSalarydebguarantor.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'btnNo';
    end;
    dcSalary.SetParentComponent(Self);
    dcSalary.Name := 'dcSalary';
    dcSalary.Columns.Clear;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debcreditor';
      Title := 'Creditor';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'deboriginalamount';
      Title := 'Original Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,###.00';
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debmonthlyamount';
      Title := 'Monthly Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,###.00';
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debiscompleted';
      Title := 'Is Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{debdossierfk}}/salarytransfer/edit/{{debid}}';
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{debdossierfk}}/salarytransfer/delete/{{debid}}';
    end;
    dcSalary.DataSet := dsSalary;
    dcSalary.Language := lEnglish;
    dcSalary.IsResponsive := True;
    dcSalary.GridID := 'grdSalary';
    dcSalary.UseFieldIndex := True;
    dcSalary.ShowSearch := False;
    dcSalary.ShowNumberOfEntries := False;
    dcSalary.ShowEntriesInfo := False;
    dcSalary.Paginate := True;
    dcSalary.DisplayReadOnly := False;
    dcSalary.CalculateTableWidth := True;
    dcSalary.Left := 32;
    dcSalary.Top := 184;
    dsSalary.SetParentComponent(Self);
    dsSalary.Name := 'dsSalary';
    dsSalary.TableName := 'debtoverview';
    dsSalary.Params.Clear;
    with dsSalary.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsSalary.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>debdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>debrecordtype</field><constant type="Integer">2</constant></binaryoperation></binaryoperation></where></query>';
    dsSalary.DAOptions := [doRefreshAllFields];
    dsSalary.AfterOpen := dsSalaryAfterOpen;
    dsSalary.AfterApplyUpdates := dsSalaryAfterApplyUpdates;
    dsSalary.Left := 32;
    dsSalary.Top := 112;
    dsSalarydebid.SetParentComponent(dsSalary);
    dsSalarydebid.Name := 'dsSalarydebid';
    dsSalarydebid.FieldName := 'debid';
    dsSalarydebcreatedon.SetParentComponent(dsSalary);
    dsSalarydebcreatedon.Name := 'dsSalarydebcreatedon';
    dsSalarydebcreatedon.FieldName := 'debcreatedon';
    dsSalarydebcreatedbyfk.SetParentComponent(dsSalary);
    dsSalarydebcreatedbyfk.Name := 'dsSalarydebcreatedbyfk';
    dsSalarydebcreatedbyfk.FieldName := 'debcreatedbyfk';
    dsSalarydebchangedon.SetParentComponent(dsSalary);
    dsSalarydebchangedon.Name := 'dsSalarydebchangedon';
    dsSalarydebchangedon.FieldName := 'debchangedon';
    dsSalarydebchangedbyfk.SetParentComponent(dsSalary);
    dsSalarydebchangedbyfk.Name := 'dsSalarydebchangedbyfk';
    dsSalarydebchangedbyfk.FieldName := 'debchangedbyfk';
    dsSalarydebdossierfk.SetParentComponent(dsSalary);
    dsSalarydebdossierfk.Name := 'dsSalarydebdossierfk';
    dsSalarydebdossierfk.FieldName := 'debdossierfk';
    dsSalarydebrecordtype.SetParentComponent(dsSalary);
    dsSalarydebrecordtype.Name := 'dsSalarydebrecordtype';
    dsSalarydebrecordtype.FieldName := 'debrecordtype';
    dsSalarydebcreditorfk.SetParentComponent(dsSalary);
    dsSalarydebcreditorfk.Name := 'dsSalarydebcreditorfk';
    dsSalarydebcreditorfk.FieldName := 'debcreditorfk';
    dsSalarydebdebttypefk.SetParentComponent(dsSalary);
    dsSalarydebdebttypefk.Name := 'dsSalarydebdebttypefk';
    dsSalarydebdebttypefk.FieldName := 'debdebttypefk';
    dsSalarydebdebttype.SetParentComponent(dsSalary);
    dsSalarydebdebttype.Name := 'dsSalarydebdebttype';
    dsSalarydebdebttype.FieldName := 'debdebttype';
    dsSalarydebdebttype.Size := 10;
    dsSalarydebstarton.SetParentComponent(dsSalary);
    dsSalarydebstarton.Name := 'dsSalarydebstarton';
    dsSalarydebstarton.FieldName := 'debstarton';
    dsSalarydebreference.SetParentComponent(dsSalary);
    dsSalarydebreference.Name := 'dsSalarydebreference';
    dsSalarydebreference.FieldName := 'debreference';
    dsSalarydebreference.Size := 64;
    dsSalarydeboriginalamount.SetParentComponent(dsSalary);
    dsSalarydeboriginalamount.Name := 'dsSalarydeboriginalamount';
    dsSalarydeboriginalamount.FieldName := 'deboriginalamount';
    dsSalarydeboriginalamount.Precision := 12;
    dsSalarydeboriginalamount.Size := 0;
    dsSalarydebcurrentamount.SetParentComponent(dsSalary);
    dsSalarydebcurrentamount.Name := 'dsSalarydebcurrentamount';
    dsSalarydebcurrentamount.FieldName := 'debcurrentamount';
    dsSalarydebcurrentamount.Precision := 12;
    dsSalarydebcurrentamount.Size := 0;
    dsSalarydebmonthlyamount.SetParentComponent(dsSalary);
    dsSalarydebmonthlyamount.Name := 'dsSalarydebmonthlyamount';
    dsSalarydebmonthlyamount.FieldName := 'debmonthlyamount';
    dsSalarydebmonthlyamount.Precision := 12;
    dsSalarydebmonthlyamount.Size := 0;
    dsSalarydebisdisputed.SetParentComponent(dsSalary);
    dsSalarydebisdisputed.Name := 'dsSalarydebisdisputed';
    dsSalarydebisdisputed.FieldName := 'debisdisputed';
    dsSalarydebdisputereason.SetParentComponent(dsSalary);
    dsSalarydebdisputereason.Name := 'dsSalarydebdisputereason';
    dsSalarydebdisputereason.FieldName := 'debdisputereason';
    dsSalarydebdisputereason.Size := 127;
    dsSalarydebdisputecourt.SetParentComponent(dsSalary);
    dsSalarydebdisputecourt.Name := 'dsSalarydebdisputecourt';
    dsSalarydebdisputecourt.FieldName := 'debdisputecourt';
    dsSalarydebdisputecourt.Size := 127;
    dsSalarydebdisputedamount.SetParentComponent(dsSalary);
    dsSalarydebdisputedamount.Name := 'dsSalarydebdisputedamount';
    dsSalarydebdisputedamount.FieldName := 'debdisputedamount';
    dsSalarydebdisputedamount.Precision := 12;
    dsSalarydebdisputedamount.Size := 0;
    dsSalarydebisdelay.SetParentComponent(dsSalary);
    dsSalarydebisdelay.Name := 'dsSalarydebisdelay';
    dsSalarydebisdelay.FieldName := 'debisdelay';
    dsSalarydebdelayamount.SetParentComponent(dsSalary);
    dsSalarydebdelayamount.Name := 'dsSalarydebdelayamount';
    dsSalarydebdelayamount.FieldName := 'debdelayamount';
    dsSalarydebdelayamount.Precision := 12;
    dsSalarydebdelayamount.Size := 0;
    dsSalarydebguarantorfk.SetParentComponent(dsSalary);
    dsSalarydebguarantorfk.Name := 'dsSalarydebguarantorfk';
    dsSalarydebguarantorfk.FieldName := 'debguarantorfk';
    dsSalarydebiscompleted.SetParentComponent(dsSalary);
    dsSalarydebiscompleted.Name := 'dsSalarydebiscompleted';
    dsSalarydebiscompleted.FieldName := 'debiscompleted';
    dsSalarydebcreditor.SetParentComponent(dsSalary);
    dsSalarydebcreditor.Name := 'dsSalarydebcreditor';
    dsSalarydebcreditor.FieldName := 'debcreditor';
    dsSalarydebcreditor.BlobType := ftWideMemo;
    dsSalarydebguarantor.SetParentComponent(dsSalary);
    dsSalarydebguarantor.Name := 'dsSalarydebguarantor';
    dsSalarydebguarantor.FieldName := 'debguarantor';
    dsSalarydebguarantor.BlobType := ftWideMemo;
  finally
    alForm.AfterLoadDFMValues;
    dcSalary.AfterLoadDFMValues;
    dsSalary.AfterLoadDFMValues;
    dsSalarydebid.AfterLoadDFMValues;
    dsSalarydebcreatedon.AfterLoadDFMValues;
    dsSalarydebcreatedbyfk.AfterLoadDFMValues;
    dsSalarydebchangedon.AfterLoadDFMValues;
    dsSalarydebchangedbyfk.AfterLoadDFMValues;
    dsSalarydebdossierfk.AfterLoadDFMValues;
    dsSalarydebrecordtype.AfterLoadDFMValues;
    dsSalarydebcreditorfk.AfterLoadDFMValues;
    dsSalarydebdebttypefk.AfterLoadDFMValues;
    dsSalarydebdebttype.AfterLoadDFMValues;
    dsSalarydebstarton.AfterLoadDFMValues;
    dsSalarydebreference.AfterLoadDFMValues;
    dsSalarydeboriginalamount.AfterLoadDFMValues;
    dsSalarydebcurrentamount.AfterLoadDFMValues;
    dsSalarydebmonthlyamount.AfterLoadDFMValues;
    dsSalarydebisdisputed.AfterLoadDFMValues;
    dsSalarydebdisputereason.AfterLoadDFMValues;
    dsSalarydebdisputecourt.AfterLoadDFMValues;
    dsSalarydebdisputedamount.AfterLoadDFMValues;
    dsSalarydebisdelay.AfterLoadDFMValues;
    dsSalarydebdelayamount.AfterLoadDFMValues;
    dsSalarydebguarantorfk.AfterLoadDFMValues;
    dsSalarydebiscompleted.AfterLoadDFMValues;
    dsSalarydebcreditor.AfterLoadDFMValues;
    dsSalarydebguarantor.AfterLoadDFMValues;
  end;
end;

end.