unit dasdk;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  JS,
  ROSDK;

type
  TP2WDAUserInfo = class;
  TP2WDASuccessEvent = Reference to procedure(res: JSValue);
  TP2WDAFailedEvent = Reference to procedure(response: TROMessage; Err: TJSError);

  TP2WDALoginSuccessEvent = Reference to procedure(result: Boolean; UserInfo: TP2WDAUserInfo);

  TP2WDABaseLoginService = class external name 'RemObjects.DataAbstract.Server.SimpleLoginService'(TJSObject)
  private
    fMessage: TROMessage;
    fChannel: TROClientChannel;
    fServiceName: string;
  public
    constructor new(ch: TROHTTPClientChannel; msg: TROMessage; aServiceName: string);
    procedure LoginEx(aLoginString: string; aSuccess: TP2WDALoginSuccessEvent; aFailure: TP2WDAFailedEvent);
    procedure Logout(aSuccess: TP2WDASuccessEvent; aFailure: TP2WDAFailedEvent);
    property &message: TROMessage read fMessage;
    property Channel: TROClientChannel read fChannel;
    property ServiceName: string read fServiceName;
  end;

  TP2WDASimpleLoginService = class external name 'RemObjects.DataAbstract.Server.SimpleLoginService'(TP2WDABaseLoginService)
  public
    procedure Login(aUserId, aPassword: string; aSuccess: TP2WDALoginSuccessEvent; aFailure: TP2WDAFailedEvent);
  end;

  TP2WDAStringArray = class external name 'RemObjects.DataAbstract.Server.StringArray'
  public
    constructor new;
    procedure fromObject(aItems: array of string); overload;
  end;

  TP2WDADataParameterData = record
    Name: string;
    Value: JSValue;
  end;

  TP2WDADataParameterDataArray = array of TP2WDADataParameterData;

  TP2WDADataParameter = class external name 'RemObjects.DataAbstract.Server.DataParameter'(TROStructType)
  public
    constructor new;
    procedure fromObject(aItem: TP2WDADataParameterData); overload;
  public
    Name: TROValue;
    Value: TROValue;
  end;

  TP2WDADataParameterArray = class external name 'RemObjects.DataAbstract.Server.DataParameterArray'(TROArrayType)
  public
    constructor new;
    procedure fromObject(aItems: array of TP2WDADataParameterData); overload;
    function toObject: TP2WDADataParameterDataArray; reintroduce;
  public
    items: array of TP2WDADataParameter;
  end;

  TP2WDAColumnSortingData = record
    FieldName: string;
    SortDirection: string;
  end;

  TP2WDAColumnSortingDataArray = array of TP2WDAColumnSortingData;

  TP2WDAColumnSorting = class external name 'RemObjects.DataAbstract.Server.ColumnSorting'(TROStructType)
  public
    FieldName: TROValue;
    Direction: TROValue;
  end;

  TP2WDAColumnSortingArray = class external name 'RemObjects.DataAbstract.Server.ColumnSortingArray'(TROArrayType)
  public
    constructor new;
    procedure fromObject(aItems: array of TP2WDAColumnSortingData); overload;
    function toObject: TP2WDAColumnSortingDataArray; reintroduce;
  public
    items: array of TP2WDAColumnSorting;
  end;

  TP2WDATableRequestInfoData = record
    IncludeSchema: Boolean;
    MaxRecords: Integer;
    Parameters: TP2WDADataParameterDataArray;
    UserFilter: string;
  end;

  TP2WDATableRequestInfoDataArray = array of TP2WDATableRequestInfoData;

  TP2WDATableRequestInfo = class external name 'RemObjects.DataAbstract.Server.TableRequestInfo'(TROStructType)
  public
    constructor new;
    procedure fromObject(aItem: TP2WDATableRequestInfoData); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TP2WDATableRequestInfoData; reintroduce;
  public
    IncludeSchema: TROValue;
    MaxRecords: TROValue;
    Parameters: TROValue;
    UserFilter: TROValue;
  end;

  TP2WDATableRequestInfoV5Data = record
    DynamicSelectFieldNames: array of string;
    IncludeSchema: Boolean;
    MaxRecords: Integer;
    Parameters: array of TP2WDADataParameterData;
    UserFilter: string;
    Sorting: TP2WDAColumnSortingData;
    WhereClause: string;
  end;

  TP2WDATableRequestInfoV5 = class external name 'RemObjects.DataAbstract.Server.TableRequestInfoV5'(TROStructType)
  public
    constructor new;
    procedure fromObject(aItem: TP2WDATableRequestInfoV5Data); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TP2WDATableRequestInfoV5Data; reintroduce;
  public
    DynamicSelectFieldNames: TROValue;
    IncludeSchema: TROValue;
    MaxRecords: TROValue;
    Parameters: TROValue;
    Sorting: TROValue;
    UserFilter: TROValue;
    WhereClause: TROValue;
  end;

  TP2WDATableRequestInfoV6Data = record
    IncludeSchema: Boolean;
    MaxRecords: Integer;
    Parameters: array of TP2WDADataParameterData;
    SQL: string;
    UserFilter: string;
  end;

  TP2WDATableRequestInfoV6 = class external name 'RemObjects.DataAbstract.Server.TableRequestInfoV6'(TROStructType)
  public
    constructor new;
    procedure fromObject(aItem: TP2WDATableRequestInfoData); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TP2WDATableRequestInfoV6Data; reintroduce;
  public
    IncludeSchema: TROValue;
    MaxRecords: TROValue;
    Parameters: TP2WDADataParameterArray;
    SQL: TROValue;
    UserFilter: TROValue;
  end;

  TP2WDAUserInfoData = record
    Attributes: array of JSValue;
    Privileges: array of string;
    SessionID: string;
    UserData: JSValue;
    UserID: string;
  end;

  TP2WDAUserInfo = class external name 'RemObjects.DataAbstract.Server.UserInfo'(TROStructType)
  public
    constructor new;
    procedure fromObject(aItem: TP2WDAUserInfo); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TP2WDAUserInfoData; reintroduce;
    Attributes: TROValue;
    Privileges: TROValue;
    SessionID: TROValue;
    UserData: TROValue;
    UserID: TROValue;
  end;

  TP2WDATableRequestInfoArray = class external name 'RemObjects.DataAbstract.Server.TableRequestInfoArray'(TROArrayType)
    public constructor new;
    procedure fromObject(aItems: array of TP2WDATableRequestInfoData); overload;
    procedure fromObject(aItems: array of TP2WDATableRequestInfoV5Data); overload;
    procedure fromObject(aItems: array of TP2WDATableRequestInfoV6Data); overload;
  public
    items: array of TP2WDATableRequestInfo;
  end;

  TP2WDADataAbstractService = class external name 'RemObjects.DataAbstract.Server.DataAbstractService'(TJSObject)
  private
    fMessage: TROMessage;
    fChannel: TROHTTPClientChannel;
    fServiceName: string;
  public
    constructor new(ch: TROHTTPClientChannel; msg: TROMessage; aServiceName: string);
    property message: TROMessage read fMessage;
    property Channel: TROHTTPClientChannel read fChannel;
    property ServiceName: string read fServiceName;
    procedure GetSchema(aFilter: string; aSuccess: TP2WDASuccessEvent; aFailure: TP2WDAFailedEvent);
    procedure GetData(aTables: TP2WDAStringArray; info: TP2WDATableRequestInfoArray; aSuccess: TP2WDASuccessEvent;
      aFailure: TP2WDAFailedEvent);
    procedure UpdateData(aDelta: string; aSuccess: TP2WDASuccessEvent; aFailure: TP2WDAFailedEvent);
    procedure ExecuteCommand(aCommandName: string; params: TP2WDADataParameterArray; aSuccess: TP2WDASuccessEvent;
      aFailure: TP2WDAFailedEvent);
    procedure ExecuteCommandEx(aCommandName: string; params: TP2WDADataParameterArray; aSuccess: TP2WDASuccessEvent;
      aFailure: TP2WDAFailedEvent);
    procedure GetTableSchema(aTableNameArray: TP2WDAStringArray; aSuccess: TP2WDASuccessEvent; aFailure: TP2WDAFailedEvent);
    procedure GetCommandSchema(aCommandNameArray: TP2WDAStringArray; aSuccess: TP2WDASuccessEvent; aFailure: TP2WDAFailedEvent);
    procedure GetSQLData(aSQLText: string; aIncludeSchema: Boolean; aMaxRecords: Integer; aSuccess: TP2WDASuccessEvent;
      aFailure: TP2WDAFailedEvent);
    procedure GetSQLDataEx(aSQLText: string; aIncludeSchema: Boolean; aMaxRecords: Integer; aDynamicWhereXML: string;
      aSuccess: TP2WDASuccessEvent; aFailure: TP2WDAFailedEvent);
    procedure SQLExecuteCommand(aSQLText: string; aSuccess: TP2WDASuccessEvent; aFailure: TP2WDAFailedEvent);
    procedure SQLExecuteCommandEx(aSQLText, aDynamicWhereXML: string; aSuccess: TP2WDASuccessEvent;
      aFailure: TP2WDAFailedEvent);
    procedure getDatasetScripts(DatasetNames: string; aSuccess: TP2WDASuccessEvent; aFailure: TP2WDAFailedEvent);
    procedure RegisterForDataChangeNotification(aTableName: string; aSuccess: TP2WDASuccessEvent;
      aFailure: TP2WDAFailedEvent);
    procedure UnregisterForDataChangeNotification(aTableName: string; aSuccess: TP2WDASuccessEvent;
      aFailure: TP2WDAFailedEvent);
  end;

implementation

end.
