unit Units.DADatasetUtils;

interface

uses
  pas2web.dadataset, Units.Params, Data.DB, System.SysUtils, System.Classes, Forms.Base, Dialogs,
  JS, Web, libjquery, lib.bootstrap;

type

  TPacket = packed record
    a: array[0..3] of Byte;
  end;

  TBaseFormHelper = class helper for TfrmBase
    function Locate(DataSet: TP2WDADataset; fieldName, Value: String): Boolean; overload;
    function Locate(DataSet: TP2WDADataset; fieldName: String; Value: Int64): Boolean; overload;
    function Locate(DataSet: TP2WDADataset; fieldNames: array of String; Values: array of int64): Boolean; overload;
    function DecodeBase64(const Input: string): TBytes;
  end;

implementation

uses
  pas2web.dadatasethelper, Modules.Server, Units.Logging, Units.Strings;

function TBaseFormHelper.DecodeBase64(const Input: string): TBytes;
var
  StrLen: Integer;
const
  DecodeTable: array[#0..#127] of Integer = (
    61, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 62, 64, 62, 64, 63,
    52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 64, 64, 64, 64, 64, 64,
    64,  0,  1,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12, 13, 14,
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 64, 64, 64, 64, 63,
    64, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 64, 64, 64, 64, 64);

  function DecodePacket(Idx: Integer; var nChars: Integer): TPacket;
  begin
    Result.a[0] := (DecodeTable[Input[Idx + 0]] shl 2) or
      (DecodeTable[Input[Idx + 1]] shr 4);
    NChars := 1;
    if (Idx + 2 <= StrLen) and (Input[Idx + 2] <> '=') then
    begin
      Inc(NChars);
      Result.a[1] := ((DecodeTable[Input[Idx + 1]] shl 4) or (DecodeTable[Input[Idx + 2]] shr 2)) and $FF;
    end;
    if (Idx + 3 <= StrLen) and (Input[Idx + 3] <> '=') then
    begin
      Inc(NChars);
      Result.a[2] := ((DecodeTable[Input[Idx + 2]] shl 6) or DecodeTable[Input[Idx + 3]]) and $FF;
    end;
  end;

var
  I, J, K: Integer;
  Packet: TPacket;
  Len: integer;
begin
  SetLength(Result, ((Length(Input) + 2) div 4) * 3);
  StrLen := Length(Input);
  Len := 0;
  for I := 1 to (StrLen + 2) div 4 do
  begin
    Packet := DecodePacket((I - 1) * 4 + 1, J);
    K := 0;
    while J > 0 do
    begin
      Result[Len] := Packet.a[K];
      Inc(Len);
      Inc(K);
      Dec(J);
    end;
  end;
  SetLength(Result, Len);
end;

function TBaseFormHelper.Locate(DataSet: TP2WDADataset; fieldName : String; Value: Int64): Boolean;

begin
  Result:= False;
  if not DataSet.isEmpty then
  begin
    if DataSet.FieldByName(fieldName).asLargeInt = Value then //some optimization cause I can be already on that record
       Result := True
    else
    begin
      DataSet.First;
      while not DataSet.Eof do
      begin
        if DataSet.FieldByName(fieldName).asLargeInt = Value then
        begin
          Result:= True;
          break;
        end;
        DataSet.Next;
      end;
    end;
  end;
end;

function TBaseFormHelper.Locate(DataSet: TP2WDADataset; fieldName, Value: String): Boolean;
begin
  Result:= False;
  if not DataSet.isEmpty then
  begin
    if DataSet.FieldByName(fieldName).asString = Value then //some optimization cause I can be already on that record
       Result := True
    else
    begin
      DataSet.First;
      while not DataSet.Eof do
      begin
        if DataSet.FieldByName(fieldName).asString = Value then
        begin
          Result:= True;
          break;
        end;
        DataSet.Next;
      end;
    end;
  end;
end;

function TBaseFormHelper.Locate(DataSet: TP2WDADataset; fieldNames: array of String; Values: array of int64): Boolean;
var
  i, j : Integer;
begin
  if Length(fieldNames) <> Length(Values) then
    raise Exception.Create('Number of fields and values doesn''t match');
  Result:= False;
  if not DataSet.isEmpty then
  begin
    j := 0;
    for i := Low(fieldNames) to High(fieldNames) do
    begin
      if DataSet.FieldByName(fieldNames[i]).asLargeInt = Values[i] then
      begin
        Inc(j);
      end;
    end;

    if j = Length(fieldNames) then
      Result := True;

    if not Result then
    begin
      DataSet.First;
      while not DataSet.Eof do
      begin
        j := 0;
        for i := Low(fieldNames) to High(fieldNames) do
        begin
          if DataSet.FieldByName(fieldNames[i]).asLargeInt = Values[i] then
          begin
            inc(j);
          end;
        end;
        if j = Length(fieldNames) then
        begin
          Result := True;
          break;
        end;
        DataSet.Next;
      end;
    end;
  end;
end;


end.
