unit Forms.CSR.TrustAccount.PaymentOrder.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmCSRPaymentOrderDetail = class(TfrmBase)
    dsPaymentOrder: TP2WDADataset;
    eacPaymentOrder: TPW2DataElementMapper;
    procedure dsPaymentOrderAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure dsPaymentOrderAfterOpen(DataSet: TDataSet);
    procedure OnAmountValidation(Sender: TObject; Source:
        TElementAction; var aResult: Boolean);
    procedure OnItemError(Sender: TObject; aLink: TP2WDataElementLink; Source:
        TElementAction; dest: TField; aError: TElementError);
    procedure OnMessageValidation(Sender: TObject; Source: TElementAction; var
        aResult: Boolean);
    procedure OnSaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FAccountID : NativeInt;
    FRoute : string;
    function OnCheckBoxClicked(Evt: TEventListenerEvent): Boolean;
    procedure doSavePaymentOrder;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRPaymentOrderDetail: TfrmCSRPaymentOrderDetail;

implementation

{$R *.dfm}

uses
  Modules.Server, libjquery, Units.HTMLUtils, Units.ActionUtils, Units.Strings;

procedure TfrmCSRPaymentOrderDetail.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmCSRPaymentOrderDetail.doSavePaymentOrder;
begin
  if dsPaymentOrder.isEmpty then
  begin
    dsPaymentOrder.Insert;
    dsPaymentOrder.FieldByName('porcreatedon').asDateTime := now;
    dsPaymentOrder.FieldByName('porcreatedbyfk').asInteger := Server.UserID;
    dsPaymentOrder.FieldByName('porbankaccountfk').asInteger := FAccountID;
    dsPaymentOrder.FieldByName('porstatus').asInteger := 0;
    dsPaymentOrder.FieldByName('porprocessedjobfk').asInteger := 0;
    dsPaymentOrder.FieldByName('porconfirmedbankmovementfk').asInteger := 0;
    dsPaymentOrder.FieldByName('porconfirmedjobfk').asInteger := 0;
  end
  else
    dsPaymentOrder.Edit;
  dsPaymentOrder.FieldByName('porchangedon').asDateTime := now;
  dsPaymentOrder.FieldByName('porchangedbyfk').asInteger := Server.UserID;
  dsPaymentOrder.FieldByName('pormessagestructured').asBoolean := isCheckBoxChecked('chStructuredMessage');

  eacPaymentOrder.ElementsToDataset;
  dsPaymentOrder.Post;
  dsPaymentOrder.ApplyUpdates;
end;

function TfrmCSRPaymentOrderDetail.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmCSRPaymentOrderDetail.dsPaymentOrderAfterApplyUpdates(Sender:
    TDataSet; Info: TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    DiscardChanges := True;
    Server.ShowOK(SPaymentOrderSaveOK);
    window.location.href := FRoute;
  end;
end;

procedure TfrmCSRPaymentOrderDetail.dsPaymentOrderAfterOpen(DataSet: TDataSet);
begin
  if Dataset.isEmpty then
  begin
    jQuery('#ddtPayOn').attr('min', FormatHTMLDate(now));
  end
  else
  begin
    if (DataSet.FieldByName('porrecurringpaymentfk').asString <> '') and
      (DataSet.FieldByName('porrecurringpaymentfk').asInteger > 0) then
      makeReadOnly;
    eacPaymentOrder.DatasetToElements;
    if dsPaymentOrder.FieldByName('pormessagestructured').asBoolean then
    begin
      jquery('#chStructuredMessage').prop('checked', true);
      jquery('#edtMessage').trigger('mask');
    end;
  end;
end;

procedure TfrmCSRPaymentOrderDetail.OnAmountValidation(
    Sender: TObject; Source: TElementAction; var aResult: Boolean);
begin
  aResult := False;
  if StrToIntDef(alForm[Source.Name].value, 0) > 0 then
  begin
    aResult := True;
  end
  else
    DisplayError(Source.Name, '', SNegativeAmountError);
end;

function TfrmCSRPaymentOrderDetail.OnCheckBoxClicked(
  Evt: TEventListenerEvent): Boolean;
begin
  if isCheckBoxChecked('chStructuredMessage') then
  begin
    jQuery('#edtMessage').trigger('mask');
  end
  else
    jQuery('#edtMessage').trigger('unmask');
  Result := True;
end;

procedure TfrmCSRPaymentOrderDetail.OnItemError(Sender: TObject; aLink:
    TP2WDataElementLink; Source: TElementAction; dest: TField; aError:
    TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmCSRPaymentOrderDetail.OnMessageValidation(Sender: TObject;
    Source: TElementAction; var aResult: Boolean);
begin
  if isCheckBoxChecked('chStructuredMessage') then
  begin
    aResult := alForm[Source.Name].Value <> '';
    if aResult then
    begin
      aResult := isValidStructuredMessage(Source.Name, alForm[Source.Name].Value);
    end;
  end
  else
    aResult := alForm[Source.Name].Value <> '';
end;

procedure TfrmCSRPaymentOrderDetail.OnSaveBtnClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);

  procedure onResponse(aSuccess: Boolean; anError: string);
  begin
    if not aSuccess then
      DisplayError('edtIBAN', '', anError)
    else
      doSavePaymentOrder;
  end;

begin
  ClearFormErrors;
  if eacPaymentOrder.ValidateActions(True) then
  begin
     Server.isIBANValid(alForm['edtIBAN'].Value, @onResponse);
  end;
end;

procedure TfrmCSRPaymentOrderDetail.setParams(const Params: TStrings);
begin
  inherited;
  If Assigned(Params) and (Params.indexOfName('ACCOUNTID')<>-1) then
  begin
    FAccountID := StrToIntDef(Params.Values['ACCOUNTID'],-1);
    FRoute := '#/CSR/' + Inttostr(DossierID) +
      '/trustaccount/' + InttoStr(FAccountID) + '/paymentorder/';
    jQuery('#returnBack').attr('href', FRoute);
    dsPaymentOrder.ParamByName('DOSSIERID').asInteger := DossierID;
    dsPaymentOrder.ParamByName('BACID').asInteger := FAccountID;
    dsPaymentOrder.ParamByName('PORID').asInteger := StrToIntDef(Params.Values['ID'],-1);
    dsPaymentOrder.Load([], nil);
  end;
end;

procedure TfrmCSRPaymentOrderDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  FAccountID := -1;
  FRoute := '#';

  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsPaymentOrder.DAConnection := Server.CompanyConnection;
  jQuery('#chStructuredMessage').On_('change', @OnCheckBoxClicked);
  BindOnChangeEvent;
end;

procedure TfrmCSRPaymentOrderDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsPaymentOrder := TP2WDADataset.Create(Self);
  eacPaymentOrder := TPW2DataElementMapper.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsPaymentOrder.BeforeLoadDFMValues;
  eacPaymentOrder.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtPayOn';
      Name := 'ddtPayOn';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtAmount';
      Name := 'edtAmount';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtIBAN';
      Name := 'edtIBAN';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtMessage';
      Name := 'edtMessage';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'OnSaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCreditorname';
      Name := 'edtCreditorname';
    end;
    dsPaymentOrder.SetParentComponent(Self);
    dsPaymentOrder.Name := 'dsPaymentOrder';
    dsPaymentOrder.TableName := 'PaymentOrder';
    dsPaymentOrder.Params.Clear;
    with dsPaymentOrder.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsPaymentOrder.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BACID';
      ParamType := ptInput;
    end;
    with dsPaymentOrder.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'PORID';
      ParamType := ptInput;
    end;
    dsPaymentOrder.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>porbankaccountfk</field><parameter type="LargeInt">BACID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>porid</field><parameter type="LargeInt">PORID</parameter></binaryoperation></binaryoperation></where></query>';
    dsPaymentOrder.DAOptions := [doRefreshAllFields];
    dsPaymentOrder.AfterOpen := dsPaymentOrderAfterOpen;
    dsPaymentOrder.AfterApplyUpdates := dsPaymentOrderAfterApplyUpdates;
    dsPaymentOrder.Left := 40;
    dsPaymentOrder.Top := 88;
    eacPaymentOrder.SetParentComponent(Self);
    eacPaymentOrder.Name := 'eacPaymentOrder';
    eacPaymentOrder.ActionLinks.Clear;
    with eacPaymentOrder.ActionLinks.Add do
    begin
      ActionName := 'ddtPayOn';
      FieldName := 'porpayon';
      Options := [aoRequired];
    end;
    with eacPaymentOrder.ActionLinks.Add do
    begin
      ActionName := 'edtAmount';
      FieldName := 'poramount';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidation', 'OnAmountValidation');
      ClearValue := '0';
    end;
    with eacPaymentOrder.ActionLinks.Add do
    begin
      ActionName := 'edtIBAN';
      FieldName := 'poriban';
      Options := [aoRequired];
    end;
    with eacPaymentOrder.ActionLinks.Add do
    begin
      ActionName := 'edtMessage';
      FieldName := 'pormessage';
      Options := [];
      SetEvent(Self, 'OnValidation', 'OnMessageValidation');
    end;
    with eacPaymentOrder.ActionLinks.Add do
    begin
      ActionName := 'edtCreditorname';
      FieldName := 'porcreditorname';
      Options := [aoRequired];
    end;
    eacPaymentOrder.ActionList := alForm;
    eacPaymentOrder.Dataset := dsPaymentOrder;
    SetEvent(eacPaymentOrder, Self, 'OnValidationError', 'OnItemError');
    eacPaymentOrder.Left := 40;
    eacPaymentOrder.Top := 160;
  finally
    alForm.AfterLoadDFMValues;
    dsPaymentOrder.AfterLoadDFMValues;
    eacPaymentOrder.AfterLoadDFMValues;
  end;
end;

end.