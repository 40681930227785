unit Units.HTMLUtils;

interface

uses
  JS,
  web,
  sysutils,
  dateutils,
  types;

// Type
// TStringDynArray = Array of String;

procedure AddClass(EM: TJSHTMLElement; const aClass: string); overload;
procedure AddClass(aList: TJSNodeList; const aClass: string); overload;
procedure RemoveClass(EM: TJSHTMLElement; const aClass: string); overload;
procedure RemoveClass(aList: TJSNodeList; const aClass: string); overload;
procedure AddRemoveClass(EM: TJSHTMLElement; const aAddClass, aRemoveClass: string); overload;
procedure AddRemoveClass(aList: TJSNodeList; const aAddClass, aRemoveClass: string); overload;
function HasClass(aElement: TJSHTMLElement; const aClass: string): Boolean;

function UTCToLocalTime(aDateTime: TDateTime): TDateTime;
function LocalTimeToUTC(aDateTime: TDateTime): TDateTime;

type
  TDateParts = record
    Y, M, D: Integer;
  end;

function GetDateParts(S: string): TDateParts;
function IsValidDate(aDateParts: TDateParts): Boolean; overload;
function IsValidDate(S: string): Boolean; overload;
function ExtractDate(S: string): TDateTime;
function ExtractTime(S: string): TDateTime;

function FormatHTMLDate(aDateTime: TDateTime; ZeroAsEmpty: Boolean = True): string;

function GetFormatSettings: TFormatSettings;

function getRadioGroupValue(const groupName: string): string;
procedure setRadiogroupSelectedElement(const groupName, value: string);

function GetInputValue(el: TJSHTMLElement): string;
procedure SetInputValue(el: TJSHTMLElement; aValue: string);

function HtmlIze(aString: string): string;

function AbsoluteURL(aBaseURL, aURL: string): string;

procedure DisplayError(const ident: string; errorClass: string = ''; aMessage: string = ''; UseCol: Boolean = True);
procedure DisplayRGError(const aName: string; errorClass: string = ''; aMessage: string = ''; UseCol: Boolean = True);
procedure HideError(const ident: string; errorClasses: array of string; UseCol: Boolean = True);
procedure HideRGError(const aName: string; errorClasses: array of string; UseCol: Boolean = True);

procedure ClearValidStatus(const ident: string; ByName: Boolean = False);
procedure ShowValidStatus(const ident: string; IsValid: Boolean; ByName: Boolean = False);

type
  TJSHTMLElementHelper = class helper for TJSHTMLElement
  private
    function GetData(aName: string): string;
    procedure SetData(aName, aValue: string);
    function GetInputValue: string;
    procedure SetInputValue(const value: string);
  public
    procedure AddClass(const aClass: string); overload;
    procedure RemoveClass(const aClass: string); overload;
    procedure AddRemoveClass(const aAddClass, aRemoveClass: string); overload;
    function HasClass(const aClass: string): Boolean;
    property InputValue: string read GetInputValue write SetInputValue;
    property Data[index: string]: string read GetData write SetData;
  end;

function JSValueToInt(a: JSValue): Integer;

implementation

uses
  Units.logging,
  libjquery,
  StrUtils;

function GetFormatSettings: TFormatSettings;
begin
  Result.Thousandseparator := '';
  Result.Decimalseparator := '.';
  Result.CurrencyDecimals := 2;
end;

function JSValueToInt(a: JSValue): Integer;
begin
  if isNumber(a) then
    Result := Trunc(Double(a))
  else
    if isString(a) then
      Result := StrToIntDef(string(a), 0)
    else
      Result := 0;
end;

function FormatHTMLDate(aDateTime: TDateTime; ZeroAsEmpty: Boolean = True): string;
begin
  if (Trunc(aDateTime) = 0) and (ZeroAsEmpty) then
    Result := ''
  else
    Result := FormatDateTime('yyyy"-"mm"-"dd', aDateTime);
end;

function GetDateParts(S: string): TDateParts;
var
  p: Integer;
begin
  with Result do
  begin
    p := pos('-', S);
    Y := StrToIntDef(Copy(S, 1, p - 1), 0);
    M := StrToIntDef(Copy(S, p + 1, 2), 0);
    D := StrToIntDef(Copy(S, p + 4, 2), 0);
  end;
end;

function IsValidDate(aDateParts: TDateParts): Boolean; overload;
begin
  with aDateParts do
    Result := (Y > 0) and (Y < 10000) and (M > 0) and (M < 13) and (D > 0) and (D < 32);
end;

function IsValidDate(S: string): Boolean; overload;
var
  DP: TDateParts;
begin
  DP := GetDateParts(S);
  Result := IsValidDate(DP);
end;

function ExtractDate(S: string): TDateTime;
var
  DP: TDateParts;
begin
  DP := GetDateParts(S);
  if IsValidDate(DP) then
    Result := EncodeDate(DP.Y, DP.M, DP.D)
  else
    Result := 0;
end;

function ExtractTime(S: string): TDateTime;
var
  H, M, Sec: Word;
begin
  H := StrToIntDef(Copy(S, 1, 2), 0);
  M := StrToIntDef(Copy(S, 4, 2), 0);
  if Length(S) > 6 then
    Sec := StrToIntDef(Copy(S, 7, 2), 0)
  else
    Sec := 0;
  if (H >= 0) and (H < 25) and (M >= 0) and (M < 60) and (Sec >= 0) and (Sec < 60) then
    Result := EncodeTime(H, M, Sec, 0)
  else
    Result := 0;
end;

function UTCToLocalTime(aDateTime: TDateTime): TDateTime;
var
  Y, Mo, D, H, Mi, S, MS: Word;
  JD: TJSDate;
begin
  DecodeDateTime(aDateTime, Y, Mo, D, H, Mi, S, MS); // will be In UTC
  // Returns local time
  JD := TJSDate.New(TJSDate.UTC(Y, Mo - 1, D, H, Mi, S));
  Result := JSDateToDateTime(JD);
end;

function LocalTimeToUTC(aDateTime: TDateTime): TDateTime;
var
  JD: TJSDate;
begin
  // Local time
  JD := DateTimeToJSDate(aDateTime);
  Result := EncodeDateTime(JD.UTCFullYear, JD.UTCMonth + 1, JD.UTCDate, JD.UTCHours, JD.UTCMinutes, JD.UTCSeconds, 0);
end;

procedure RemoveClass(EM: TJSHTMLElement; const aClass: string);
begin
  AddRemoveClass(EM, '', aClass);
end;

procedure RemoveClass(aList: TJSNodeList; const aClass: string);
var
  I: Integer;
begin
  for I := 0 to aList.Length - 1 do
    if (aList[I] is TJSHTMLElement) then
      AddRemoveClass(TJSHTMLElement(aList[I]), '', aClass);
end;

procedure AddClass(aList: TJSNodeList; const aClass: string);
var
  I: Integer;
begin
  for I := 0 to aList.Length - 1 do
    if (aList[I] is TJSHTMLElement) then
      AddRemoveClass(TJSHTMLElement(aList[I]), aClass, '');
end;

procedure AddClass(EM: TJSHTMLElement; const aClass: string);
begin
  AddRemoveClass(EM, aClass, '');
end;

procedure AddRemoveClass(aList: TJSNodeList; const aAddClass, aRemoveClass: string); overload;
var
  I: Integer;
begin
  for I := 0 to aList.Length - 1 do
    if (aList[I] is TJSHTMLElement) then
      AddRemoveClass(TJSHTMLElement(aList[I]), aAddClass, aRemoveClass);
end;

procedure AddRemoveClass(EM: TJSHTMLElement; const aAddClass, aRemoveClass: string);
var
  S: string;
  List: TStringDynArray;
  idx: Integer;
begin
  if not Assigned(EM) then
    Exit;
  List := TJSString(EM.ClassName).split(' ');
  if (aRemoveClass <> '') then
  begin
    idx := TJSArray(List).indexOf(aRemoveClass);
    if idx <> -1 then
      Delete(List, idx, 1);
  end;
  if (aAddClass <> '') then
  begin
    idx := TJSArray(List).indexOf(aAddClass);
    if idx = -1 then
      TJSArray(List).Push(aAddClass);
  end;
  S := TJSArray(List).join(' ');
  EM.ClassName := S;
end;

function HasClass(aElement: TJSHTMLElement; const aClass: string): Boolean;
var
  List: TStringDynArray;
  aIndex: Integer;
begin
  if not Assigned(aElement) then
    Exit(False);
  if aClass = '' then
    Exit(False);

  List := TJSString(aElement.ClassName).split(' ');
  aIndex := TJSArray(List).indexOf(aClass);
  Result := (aIndex <> -1);
end;

function getRadioGroupValue(const groupName: string): string;
begin
  Result := string(jQuery('input[name="' + groupName + '"]:checked').val());
  if Result = 'undefined' then
    Result := '';
end;

procedure setRadiogroupSelectedElement(const groupName, value: string);
begin
  jQuery('input[name="' + groupName + '"]').prop('checked', False);
  jQuery('input[name="' + groupName + '"][value="' + value + '"]').prop('checked', True);
end;

function GetInputValue(el: TJSHTMLElement): string;
var
  inp: TJSHTMLInputElement absolute el;
  sel: TJSHTMLSelectElement absolute el;
  are: TJSHTMLTextAreaElement absolute el;
  S: string;
begin
  if el is TJSHTMLInputElement then
    S := inp.value
  else
    if el is TJSHTMLSelectElement then
      S := sel.value
    else
      if el is TJSHTMLTextAreaElement then
        S := are.value
      else
        if el = nil then
        begin
          S := '';
          log.log(ltDebug, 'Units.HTMLUnits', 'GetInputValue', 'Attempting to get value from empty element');
        end
        else
          S := el.InnerText;
  Result := S;
end;

procedure SetInputValue(el: TJSHTMLElement; aValue: string);
var
  inp: TJSHTMLInputElement absolute el;
  sel: TJSHTMLSelectElement absolute el;
  are: TJSHTMLTextAreaElement absolute el;
begin
  if el is TJSHTMLInputElement then
    inp.value := aValue
  else
    if el is TJSHTMLSelectElement then
      sel.value := aValue
    else
      if el is TJSHTMLTextAreaElement then
        are.value := aValue
      else
        if SameText(el.tagName, 'IMG') then
          el['src'] := aValue
        else
          el.InnerText := aValue
end;

{ ---------------------------------------------------------------------
  TJSHTMLElementHelper
 --------------------------------------------------------------------- }

procedure TJSHTMLElementHelper.AddClass(const aClass: string);
begin
  if Assigned(Self) then
    Units.HTMLUtils.AddClass(Self, aClass);
end;

procedure TJSHTMLElementHelper.AddRemoveClass(const aAddClass, aRemoveClass: string);
begin
  if Assigned(Self) then
    Units.HTMLUtils.AddRemoveClass(Self, aAddClass, aRemoveClass);
end;

function TJSHTMLElementHelper.GetData(aName: string): string;
begin
  if Assigned(Self) and isString(Self.Dataset[aName]) then
    Result := string(Self.Dataset[aName])
  else
    Result := ''
end;

function TJSHTMLElementHelper.GetInputValue: string;
begin
  if Assigned(Self) then
    Result := Units.HTMLUtils.GetInputValue(Self)
  else
    Result := '';
end;

function TJSHTMLElementHelper.HasClass(const aClass: string): Boolean;
begin
  Result := Units.HTMLUtils.HasClass(Self, aClass);
end;

procedure TJSHTMLElementHelper.RemoveClass(const aClass: string);
begin
  if Assigned(Self) then
    Units.HTMLUtils.RemoveClass(Self, aClass);
end;

procedure TJSHTMLElementHelper.SetData(aName, aValue: string);
begin
  if Assigned(Self) then
    Self.Dataset[aName] := aValue;
end;

procedure TJSHTMLElementHelper.SetInputValue(const value: string);
begin
  if Assigned(Self) then
    Units.HTMLUtils.SetInputValue(Self, value)
end;

function HtmlIze(aString: string): string;
begin
  Result := StringReplace(aString, #13#10, '<br>', [rfReplaceAll]);
  Result := StringReplace(Result, #13, '<br>', [rfReplaceAll]);
  Result := StringReplace(Result, #10, '<br>', [rfReplaceAll]);
  Result := StringReplace(Result, '<', '&lt;', [rfReplaceAll]);
  Result := StringReplace(Result, '>', '&gt;', [rfReplaceAll]);
end;

function AbsoluteURL(aBaseURL, aURL: string): string;
var
  R: TJSRegexp;
begin
  R := TJSRegexp.New('^https?://|^/', 'i');
  if R.Test(aURL) then
    Result := aURL
  else
  begin
    if (aBaseURL <> '') and (Copy(aBaseURL, Length(aBaseURL), 1) <> '/') then
      aBaseURL := aBaseURL + '/';
    Result := aBaseURL + aURL;
  end;
end;

procedure ClearValidStatus(const ident: string; ByName: Boolean = False);
var
  sel: string;
begin
  if ByName then
    sel := 'input[name="' + ident + '"]'
  else
    sel := '#' + ident;
  jQuery(sel).RemoveClass('is-valid is-invalid');
  if ByName then
  begin
    // Needed for radio group or checkbox
    jQuery(sel).closest('div[class^="col-"]').find('.invalid-feedback').css('display', 'none');
    jQuery(sel).closest('div[class^="form-group"]').find('.invalid-feedback').css('display', 'none');
  end;
end;

procedure ShowValidStatus(const ident: string; IsValid: Boolean; ByName: Boolean = False);
var
  sel: string;
begin
  if ByName then
    sel := 'input[name="' + ident + '"]'
  else
    sel := '#' + ident;
  jQuery(sel).RemoveClass('is-valid is-invalid').AddClass(IfThen(IsValid, 'is-valid', 'is-invalid'));
  if ByName then
  begin
    // Needed for radio group or checkbox
    jQuery(sel).closest('div[class^="col-"]').find('.invalid-feedback').css('display', 'block');
    jQuery(sel).closest('div[class^="form-group"]').find('.invalid-feedback').css('display', 'block');
  end;
end;

procedure DisplayError(const ident: string; errorClass, aMessage: string; UseCol: Boolean = True);
var
  erClass: string;
begin
  if errorClass = '' then
    erClass := 'invalid-feedback'
  else
    erClass := errorClass;
  if UseCol then
    jQuery('#' + ident).closest('div[class^="col-"]').find('.' + erClass).css('display', 'block');
  jQuery('#' + ident).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'block');
  if aMessage <> '' then
  begin
    if UseCol then
      jQuery('#' + ident).closest('div[class^="col-"]').find('.' + erClass).text(aMessage);
    jQuery('#' + ident).closest('div[class^="form-group"]').find('.' + erClass).text(aMessage);
  end;
  jQuery('li.step.active').AddClass('error');
end;

procedure DisplayRGError(const aName: string; errorClass, aMessage: string; UseCol: Boolean = True);
var
  erClass: string;
  aSel: string;
begin
  if errorClass = '' then
    erClass := 'invalid-feedback'
  else
    erClass := errorClass;
  aSel := 'input[name="' + aName + '"]';
  if UseCol then
    jQuery(aSel).closest('div[class^="col-"]').find('.' + erClass).css('display', 'block');
  jQuery(aSel).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'block');
  if aMessage <> '' then
  begin
    if UseCol then
      jQuery(aSel).closest('div[class^="col-"]').find('.' + erClass).text(aMessage);
    jQuery(aSel).closest('div[class^="form-group"]').find('.' + erClass).text(aMessage);
  end;
  jQuery('li.step.active').AddClass('error');
end;

procedure HideError(const ident: string; errorClasses: array of string; UseCol: Boolean = True);
var
  erClass: string;
begin
  if Length(errorClasses) = 0 then
    errorClasses := ['invalid-feedback', 'incorrect-value'];
  for erClass in errorClasses do
  begin
    if UseCol then
      jQuery('#' + ident).closest('div[class^="col-"]').find('.' + erClass).css('display', 'none');
    jQuery('#' + ident).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'none');
  end;
end;

procedure HideRGError(const aName: string; errorClasses: array of string; UseCol: Boolean = True);
var
  aSel, erClass: string;
begin
  if Length(errorClasses) = 0 then
    errorClasses := ['invalid-feedback', 'incorrect-value'];
  aSel := 'input[name="' + aName + '"]';
  for erClass in errorClasses do
  begin
    if UseCol then
      jQuery(aSel).closest('div[class^="col-"]').find('.' + erClass).css('display', 'none');
    jQuery(aSel).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'none');
  end;
end;

end.
