unit pas2web.da;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  Sysutils,
  Types,
  JS,
  DASDK;

type
  TP2WDADataType = (datUnknown, datString, datDateTime, datFloat, datCurrency, datAutoInc, datInteger, datLargeInt,
    datBoolean, datMemo, datBlob, datWideString, datWideMemo, datLargeAutoInc, datByte, datShortInt, datWord,
    datSmallInt, datCardinal, datLargeUInt, datGuid, datXml, datDecimal, datSingleFloat, datFixedChar, datFixedWideChar,
    datCursor);

  // Forward classes
  TP2WDADataTable = class;
  TP2WDABIN2DataStreamer = class;
  TP2WDAJSONDataStreamer = class;
  TP2WDARemoteDataAdapter = class;
  TP2WDAChange = class;
  TP2WDADelta = class;
  TP2WDADeltas = class;
  TP2WDAField = class;
  TP2WDALookupField = class;
  TP2WDADataTableRow = class;
  TP2WDAExpression = class;
  TP2WDADynamicWhere = class;
  TP2WDAConstantExpression = class;
  TP2WDAParameterExpression = class;
  TP2WDANullExpression = class;
  TP2WDAFieldExpression = class;
  TP2WDAMacroExpression = class;
  TP2WDAUnaryExpression = class;
  TP2WDABinaryExpression = class;
  TP2WDABetweenExpression = class;
  TP2WDAListExpression = class;
  TP2WDAUtil = class;
  TP2WDARemoteDataAdaptor = class;

  TP2WDAStream = string;

  TP2WDADataStreamer = class external name 'RemObjects.DataAbstract.DataStreamer'(TJSObject)
  public
    procedure initializeRead;
    procedure initializeWrite;
    procedure finalizeWrite;
    function getStream: TP2WDAStream;
    procedure setStream(aStream: TP2WDAStream);
    procedure readDataset(aDataset: TP2WDADataTable);
    function readDelta: TP2WDADeltas;
    procedure writeDelta(aDelta: TP2WDADeltas);
    property Stream: TP2WDAStream read getStream write setStream;
  end;

  TP2WDADataStreamerClass = class of TP2WDADataStreamer;

  TP2WDABIN2DataStreamer = class external name 'RemObjects.DataAbstract.Bin2DataStreamer'(TP2WDADataStreamer)
  public
    function readByte: Byte;
    function readInteger: NativeInt;
    function readAnsiStringWithLength: string;
    function readUtf8StringWithLength: string;
    function read(aType: string): TJSObject;
    function readParam(acount: Integer): TP2WDADataParameter;
    function readField(acount: Integer): TP2WDAField;
    procedure writeByte(aValue: Byte);
    procedure writeInteger(aValue: NativeInt);
    procedure writeAnsiStringWithLength(aValue: string);
    procedure write(aType: string; aValue: TJSObject);
  end;

  TP2WDAJSONDataStreamer = class external name 'RemObjects.DataAbstract.JSONDataStreamer'(TP2WDADataStreamer)
  end;

  TP2WDARemoteDataAdapter = class external name 'RemObjects.DataAbstract.RemoteDataAdapter'(TJSObject)
  public
    constructor New(const aURL, aDataServiceName, aLoginServiceName: string; aStreamerClass: TP2WDADataStreamerClass);
  end;

  TP2WDAChange = class external name 'RemObjects.DataAbstract.Change'(TJSObject)
  public
    recid: NativeInt;
    changetype: string;
    status: string;
    message: string;
    old: TJSValueDynArray;
    new_: TJSValueDynArray; external name 'new';
  end;

  TP2WDAChangeArray = array of TP2WDAChange;

  TLogField = record
    name: string;
    datatype: string; external name 'type';
  end;

  TLogFieldArray = array of TLogField;

  TP2WDADelta = class external name 'RemObjects.DataAbstract.Delta'(TJSObject)
  private
    FData: TP2WDAChangeArray; external name 'data';
    FKeyFields: TStringDynArray; external name 'keyfields';
    FLoggedFields: TLogFieldArray; external name 'loggedfields';
    FName: string; external name 'name';
  public
    function intFindId(anId: Integer): TP2WDAChange;
    property data: TP2WDAChangeArray read FData;
    property keyFields: TStringDynArray read FKeyFields write FKeyFields;
    property LoggedFields: TLogFieldArray read FLoggedFields write FLoggedFields;
    property name: string read FName write FName;
  end;

  TP2WDADeltaArray = array of TP2WDADelta;

  TP2WDADeltas = class external name 'RemObjects.DataAbstract.Deltas'(TJSObject)
  public
    deltas: TP2WDADeltaArray;
    function FindByName(const aName: string): TP2WDADelta;
  end;

  TP2WDATableRowNotifyEvent = reference to procedure(row: TP2WDADataTableRow);
  TP2WDADataTableRowArray = array of TP2WDADataTableRow;
  TP2WDAFieldArray = array of TP2WDAField;

  TP2WDADataTable = class external name 'RemObjects.DataAbstract.DataTable'(TJSObject)
  public
    name: string;
    rows: TP2WDADataTableRowArray;
    fields: TP2WDAFieldArray;
    deletedrows: TP2WDADataTableRowArray;
    frecordbuffer: TJSArray;
    fNextRecID: Integer;
    fIndex: Integer;
    bofFlag: Boolean;
    eofFlag: Boolean;
    dynamicWhere: TJSObject;
    onNewRecord: TP2WDATableRowNotifyEvent;
    onBeforeDelete: TP2WDATableRowNotifyEvent;
    onAfterDelete: TP2WDATableRowNotifyEvent;
    onBeforeScroll: TP2WDATableRowNotifyEvent;
    onAfterScroll: TP2WDATableRowNotifyEvent;
    procedure checkRequired;
    procedure locate(aName: string; aValue: JSValue);
    procedure addLookupField(const aName, aSourceField: string; aLookupTable: TP2WDADataTable; const aLookupKeyField, aLookupResultField: string);
    procedure getNextId;
    function appendRow: TP2WDADataTableRow;
    procedure deleteRow;
    procedure markDeleted;
    function fieldNumByName(const aName: string): Integer;
    function fieldByName(const aName: string): TP2WDAField;
    procedure setFieldValue(const aName: string; aValue: JSValue);
    function getFieldValue(const aName: string): JSValue;
    procedure setFieldAsString(const aName, aValue: string);
    function getFieldAsString(const aName: string): string;
    function currentRow: TP2WDADataTableRow;
    procedure first;
    procedure last;
    procedure next;
    procedure prev;
    function findId(anId: Integer): TP2WDADataTableRow;
    function eof: Boolean;
    function bof: Boolean;
    procedure post;
    procedure cancel;
  end;

  TP2WDAField = class external name 'RemObjects.DataAbstract.Field'(TJSObject)
  public
    alignment: string;
    blobtype: string;
    businessClassID: string;
    calculated: string;
    customAttributes: string;
    datatype: string;
    name: string;
    type_: string external name 'type';
    logChanges: Boolean;
    readOnly: Boolean;
    serverAutoRefresh: Boolean;
    serverCalculated: Boolean;
    description: string;
    decimalPrecision: Integer;
    decimalScale: Integer;
    defaultValue: string;
    dictionaryEntry: string;
    displayLabel: string;
    displayWidth: Integer;
    inPrimaryKey: string;
    visible: Boolean;
    required: Boolean;
    size: Integer;
    procedure checkReadOnly;
  end;

  TP2WDALookupField = class external name 'RemObjects.DataAbstract.LookupField'(TJSObject)
  public
    sourceField: string;
    lookupTable: TP2WDADataTable;
    lookupKeyField: string;
    lookupResultField: string;
  end;

  TP2WDADataTableRow = class external name 'RemObjects.DataAbstract.DataTableRow'(TJSObject)
  public
    recid: Integer;
    state: string;
    __oldValues: array of JSValue;
    __newValues: array of JSValue;
  end;

  TP2WDAExpression = class external name 'RemObjects.DataAbstract.Expression'(TJSObject)
  public
    function toXML: string;
  end;

  TP2WDADynamicWhere = class external name 'RemObjects.DataAbstract.DynamicWhere'(TJSObject)
  public
    constructor New(anExpression: TP2WDAExpression);
    function toXML: string;
  end;

  TP2WDAConstantExpression = class external name 'RemObjects.DataAbstract.ConstantExpression'(TP2WDAExpression)
  public
    constructor New(aType: string; aValue: JSValue; ANull: Byte);
  end;

  TP2WDAParameterExpression = class external name 'RemObjects.DataAbstract.ParameterExpression'(TP2WDAExpression)
  public
    constructor New(const aName, aType: string; aSize: Integer);
  end;

  TP2WDANullExpression = class external name 'RemObjects.DataAbstract.NullExpression'(TP2WDAExpression)
  public
    constructor New;
  end;

  TP2WDAFieldExpression = class external name 'RemObjects.DataAbstract.FieldExpression'(TP2WDAExpression)
  public
    constructor New(aName: string);
  end;

  TP2WDAMacroExpression = class external name 'RemObjects.DataAbstract.MacroExpression'(TP2WDAExpression)
  public
    constructor New(aName: string);
  end;

  TP2WDAUnaryExpression = class external name 'RemObjects.DataAbstract.UnaryExpression'(TP2WDAExpression)
  public
    constructor New(aNode: TP2WDAExpression; aOperator: string);
  end;

  TP2WDABinaryExpression = class external name 'RemObjects.DataAbstract.BinaryExpression'(TP2WDAExpression)
  public
    constructor New(aNode1, aNode2: TP2WDAExpression; aOperator: string);
  end;

  TP2WDABetweenExpression = class external name 'RemObjects.DataAbstract.BetweenExpression'(TP2WDAExpression)
  public
    constructor New(aNode1, aNode2, aNode3: TP2WDAExpression);
  end;

  TP2WDAListExpression = class external name 'RemObjects.DataAbstract.ListExpression'(TP2WDAExpression)
  public
    constructor New(aList: array of TP2WDAExpression);
  end;

  TP2WDABinaryOperator = (dboAnd, dboOr, dboXor, dboLess, dboLessOrEqual, dboGreater, dboGreaterOrEqual, dboNotEqual,
    dboEqual, dboLike, dboIn, dboAddition, dboSubtraction, dboMultiply, dboDivide, dboNotIn);

  TP2WDAUnaryOperator = (duoNot, duoMinus);

  TP2WDAUtil = class external name 'RemObjects.DataAbstract.Util'(TJSObject)
  public
    function createDataParameter(aName: string; aValue: JSValue): TJSObject;
    function createRequestInfo(IncludeSchema: Boolean; MaxRecords: Integer; UserFilter: string; Parameters: array of JSValue): TJSObject;
    function createRequestInfoV5(IncludeSchema: Boolean; MaxRecords: Integer; UserFilter: string; Parameters: array of JSValue): TJSObject;
    function createRequestInfoV6(SQL: string; MaxRecords: Integer; UserFilter: string; Parameters: array of JSValue): TJSObject;
    procedure setupScriptingCallBacks;
  end;

  TP2WDACallBack = procedure;
  TP2WDALoginNeededCallBack = reference to procedure(aCallBack: TP2WDACallBack);
  TP2WDAChangeFailHandler = reference to procedure(aData: TP2WDAChange);

  TP2WDARemoteDataAdaptor = class external name 'RemObjects.DataAbstract.RemoteDataAdapter'(TJSObject)
  private
    FSendReducedDelta: Boolean; external name 'sendReducedDelta';
  public
    onLoginNeeded: TP2WDALoginNeededCallBack;
    onChangeFail: TP2WDAChangeFailHandler;
    function getDataService(): TP2WDADataAbstractService;
    function getLoginService(): TP2WDASimpleLoginService;
    procedure login(aUserID, aPassword, aConnectionName: string; OnSuccess: TP2WDASuccessEvent; OnFailed: TP2WDAFailedEvent);
    procedure logout(OnSuccess: TP2WDASuccessEvent; OnFailed: TP2WDAFailedEvent);
    function createStreaer: TP2WDAJSONDataStreamer;
    procedure setSendReducedDelta(aValue: Boolean);
    procedure getSchema(aFilter: string; OnSuccess: TP2WDASuccessEvent; OnFailed: TP2WDAFailedEvent);
    function buildDelta(aTable: TP2WDADataTable): TP2WDADelta;
    procedure createTableFromSchema(const aTableName: string; aTable: TP2WDADataTable; CallBack: TP2WDACallBack);
    procedure executeCommand(const aName: string; Parameters: TP2WDADataParameterArray; OnSuccess: TP2WDASuccessEvent;
      OnFailed: TP2WDAFailedEvent);
    function getAutoGetScripts: Boolean;
    procedure setAutoGetScripts(aValue: Boolean);
    procedure getSQLData(aTable: TP2WDADataTable; const SQL: string; OnSuccess: TP2WDASuccessEvent; OnFailed: TP2WDAFailedEvent);
    procedure getData(aTable: TP2WDADataTable; aRequest: TP2WDATableRequestInfo; OnSuccess: TP2WDASuccessEvent;
      OnFailed: TP2WDAFailedEvent);
    procedure applyUpdates(aTable: TP2WDADataTable; OnSuccess: TP2WDASuccessEvent; OnFailed: TP2WDAFailedEvent);
    property sendReducedDelta: Boolean read FSendReducedDelta write setSendReducedDelta;
    property AutoGetScripts: Boolean read getAutoGetScripts write setAutoGetScripts;
  end;

  TP2WDAHTMLTableView = class external name 'RemObjects.DataAbstract.Views.HtmlTableView'
  public
    constructor New(aTable: TP2WDADataTable; aHTMLTableID: string);
  end;

  TP2WDAVerticalHTMLTableView = class external name 'RemObjects.DataAbstract.Views.VerticalHtmlTableView'
  public
    constructor New(aTable: TP2WDADataTable; aHTMLTableID: string);
  end;

const
  BinaryOperatorNames: array [TP2WDABinaryOperator] of string = ('And', 'Or', 'Xor', 'Less', 'LessOrEqual', 'Greater',
    'GreaterOrEqual', 'NotEqual', 'Equal', 'Like', 'In', 'Addition', 'Subtraction', 'Multiply', 'Divide', 'NotIn');

  UnaryOperatorNames: array [TP2WDAUnaryOperator] of string = ('Not', 'Minus');

  DataTypeNames: array [TP2WDADataType] of string = ('Unknown', 'String', 'DateTime', 'Float', 'Currency', 'AutoInc',
    'Integer', 'LargeInt', 'Boolean', 'Memo', 'Blob', 'WideString', 'WideMemo', 'LargeAutoInc', 'Byte', 'ShortInt',
    'Word', 'SmallInt', 'Cardinal', 'LargeUInt', 'Guid', 'Xml', 'Decimal', 'SingleFloat', 'FixedChar', 'FixedWideChar',
    'Cursor');

function JSValueToDataType(aValue: JSValue): TP2WDADataType;
function JSValueToDataTypeName(aValue: JSValue): string;

implementation

function JSValueToDataType(aValue: JSValue): TP2WDADataType;
begin
  if isNull(aValue) then
    Result := datUnknown
  else
    if isString(aValue) then
      Result := datWideString
    else
      if isBoolean(aValue) then
        Result := datBoolean
      else
        if isNumber(aValue) then
        begin
          if isInteger(aValue) then
            Result := datLargeInt
          else
            Result := datFloat
        end
        else
          if isDate(aValue) then
            Result := datDateTime
          else
            raise EConvertError.Create('Cannot convert JSValue to DADataType: Unknown/Unsupported type');
end;

function JSValueToDataTypeName(aValue: JSValue): string;
begin
  Result := DataTypeNames[JSValueToDataType(aValue)];
end;

end.
