unit Forms.CSR.TrustAccount.Recurringpayment.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper, Forms.DossierDetail;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmRecurringPaymentDetail = class(TfrmBase)
    eacRecPayment: TPW2DataElementMapper;
    dsRecPayment: TP2WDADataset;
    procedure dsRecPaymentAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure dsRecPaymentAfterOpen(DataSet: TDataSet);
    procedure OnMonthsToField(Sender: TObject; Source:
        TElementAction; dest: TField; var aHandled: Boolean);
    procedure OnMonthsToForm(Sender: TObject; Source:
        TField; Dest: TElementAction; var aHandled: Boolean);
    procedure OnValidTillValidation(Sender: TObject; Source:
        TElementAction; var aResult: Boolean);
    procedure OnAmountValidation(Sender: TObject; Source: TElementAction; var
        aResult: Boolean);
    procedure OnBtnSaveClick(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure OnCurrCountToField(Sender: TObject; Source: TElementAction; dest:
        TField; var aHandled: Boolean);
    procedure OnDateToAction(Sender: TObject; Source: TField; Dest: TElementAction;
        var aHandled: Boolean);
    procedure OnDayToField(Sender: TObject; Source: TElementAction; dest: TField;
        var aHandled: Boolean);
    procedure OnDaysToForm(Sender: TObject; Source: TField; Dest: TElementAction;
        var aHandled: Boolean);
    procedure OnDayValidation(Sender: TObject; Source: TElementAction; var aResult:
        Boolean);
    procedure OnItemError(Sender: TObject; Source: TElementAction; dest: TField;
        aError: TElementError);
    procedure OnMaxCountValidation(Sender: TObject; Source: TElementAction; var
        aResult: Boolean);
    procedure OnMonthValidation(Sender: TObject; Source: TElementAction; var
        aResult: Boolean);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FRoute : string;
    FAccountID : NativeInt;
    procedure initMonths;
    procedure initDays;
    function IntToBin(Value: LongWord): string;
    function BinToInt(Value: String): Integer;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmRecurringPaymentDetail: TfrmRecurringPaymentDetail;

implementation

{$R *.dfm}

uses
  Modules.Server, libjquery, Units.Strings, Units.Types, Units.HTMLUtils, Units.ActionUtils;

function TfrmRecurringPaymentDetail.BinToInt(Value: String): Integer;
var
  i, iValueSize: Integer;
begin
  Result := 0;
  iValueSize := Length(Value);
  for i := iValueSize downto 1 do
    if Value[i] = '1' then
      Result := Result + (1 shl (iValueSize - i));
end;

function TfrmRecurringPaymentDetail.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmRecurringPaymentDetail.dsRecPaymentAfterApplyUpdates(Sender:
    TDataSet; Info: TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    DiscardChanges := True;
    Server.ShowOK(SRecurringPaymentSaveOK);
    window.location.href := FRoute;
  end;
end;

procedure TfrmRecurringPaymentDetail.dsRecPaymentAfterOpen(DataSet: TDataSet);
begin
  eacRecPayment.DatasetToElements;
end;

procedure TfrmRecurringPaymentDetail.OnMonthsToField(
    Sender: TObject; Source: TElementAction; dest: TField; var aHandled:
    Boolean);
var
  i : Integer;
  val : string;
begin
  val := '';
  for I := 12 downto 1 do
  begin
    if isCheckBoxChecked('cbMonth' + inttostr(i)) then
      val := val + '1'
    else
      val := val + '0';
  end;
  dest.asInteger :=  BinToInt(val);
  aHandled := True;
end;

procedure TfrmRecurringPaymentDetail.OnMonthsToForm(
    Sender: TObject; Source: TField; Dest: TElementAction; var aHandled:
    Boolean);
var
  binValue : string;
  i : Integer;
begin
  binValue := IntToBin(Source.asInteger);
  for i := 1 to 12 do
    if binValue[Length(binValue) - (i - 1)] = '1' then
      jQuery('#cbMonth' + inttostr(i)).prop('checked', true);
end;

procedure TfrmRecurringPaymentDetail.OnValidTillValidation(
    Sender: TObject; Source: TElementAction; var aResult: Boolean);
var
  sDate, eDate : TDateTime;
begin
  aResult := False;
  sDate := ValidityFromToDate(alForm['ddtValidStart'].value, True);
  eDate := ValidityFromToDate(alForm[Source.Name].value, False);
  if sDate > NoStartDate then
    if eDate < NoEndDate then
      if sDate < eDate then
        aResult := True;
  if not aResult then
     DisplayError(Source.Name, '', SValidTillError);
end;

procedure TfrmRecurringPaymentDetail.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmRecurringPaymentDetail.initDays;
var
  html : string;
  i : Integer;
begin
  html := jQuery('#days').html;
  for i := 1 to 31 do
  begin
    html := html + '<div class="form-check form-check-inline">' +
      '<input role="cbDay" class="form-check-input" type="checkbox" id="cbDay' + intToStr(i) + '" value="' + intToStr(i) + '">' +
      '<label class="form-check-label" for="cbDay' + intToStr(i) + '"> ' + intToStr(i) + '</label>' +
      '</div>';
  end;
  jQuery('#days').html(html);
end;

procedure TfrmRecurringPaymentDetail.initMonths;
var
  html : string;
  i : Integer;
begin
  html := jQuery('#months').html;
  for i := 1 to 12 do
  begin
    html := html + '<div class="form-check form-check-inline">' +
      '<input role="cbMonth" class="form-check-input" type="checkbox" id="cbMonth' + intToStr(i) + '" value="' + intToStr(i) + '">' +
      '<label class="form-check-label" for="cbMonth' + intToStr(i) + '"> ' + FormatSettings.LongMonthNames[i] + '</label>' +
      '</div>';
  end;
  jQuery('#months').html(html);
end;

function TfrmRecurringPaymentDetail.IntToBin(Value: LongWord): string;
var
  i: Integer;
begin
  SetLength(Result, 32);
  for i := 1 to 32 do begin
    if ((Value shl (i-1)) shr 31) = 0 then
    begin
      Result[i] := '0'
    end
    else
    begin
      Result[i] := '1';
    end;
  end;
end;

procedure TfrmRecurringPaymentDetail.OnAmountValidation(Sender: TObject;
    Source: TElementAction; var aResult: Boolean);
begin
  aResult := False;
  if StrToIntDef(alForm[Source.Name].value, 0) > 0 then
  begin
    aResult := True;
  end
  else
    DisplayError(Source.Name, '', SNegativeAmountError);
end;

procedure TfrmRecurringPaymentDetail.OnBtnSaveClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  ClearFormErrors;
  if eacRecPayment.ValidateActions(True) then
  begin
    if dsRecPayment.isEmpty then
    begin
      dsRecPayment.Insert;
      dsRecPayment.FieldByName('rpacreatedon').asDateTime := now;
      dsRecPayment.FieldByName('rpacreatedbyfk').asInteger := Server.UserID;
      dsRecPayment.FieldByName('rpabankaccountfk').asInteger := FAccountID;
      dsRecPayment.FieldByName('rpalastorderfk').asInteger := 0;
    end
    else
      dsRecPayment.Edit;
    dsRecPayment.FieldByName('rpachangedon').asDateTime := now;
    dsRecPayment.FieldByName('rpachangedbyfk').asInteger := Server.UserID;
    eacRecPayment.ElementsToDataset;
    //TODO remove the line below
    dsRecPayment.FieldByName('rpapaymentplanitemfk').asInteger := 0;

    dsRecPayment.Post;
    dsRecPayment.ApplyUpdates;
  end;
end;

procedure TfrmRecurringPaymentDetail.OnCurrCountToField(Sender: TObject;
    Source: TElementAction; dest: TField; var aHandled: Boolean);
begin
  if alForm[Source.Name].value = '' then
    dest.asInteger := 0;
  aHandled := True;
end;

procedure TfrmRecurringPaymentDetail.OnDateToAction(Sender: TObject; Source:
    TField; Dest: TElementAction; var aHandled: Boolean);
begin
  alForm[dest.name].value := FormatHTMLDate(Source.asDateTime);
end;

procedure TfrmRecurringPaymentDetail.OnDayToField(Sender: TObject; Source:
    TElementAction; dest: TField; var aHandled: Boolean);
var
  i : Integer;
  val : string;
begin
  val := '';
  for I := 31 downto 1 do
  begin
    if isCheckBoxChecked('cbDay' + inttostr(i)) then
      val := val + '1'
    else
      val := val + '0';
  end;
  dest.asInteger :=  BinToInt(val);
  aHandled := True;
end;

procedure TfrmRecurringPaymentDetail.OnDaysToForm(Sender: TObject; Source:
    TField; Dest: TElementAction; var aHandled: Boolean);
var
  binValue : string;
  i : Integer;
begin
  binValue := IntToBin(Source.asInteger);
  for i := 1 to 31 do
    if binValue[Length(binValue) - (i - 1)] = '1' then
      jQuery('#cbDay' + inttostr(i)).prop('checked', true);
end;

procedure TfrmRecurringPaymentDetail.OnDayValidation(Sender: TObject; Source:
    TElementAction; var aResult: Boolean);
var
  i : Integer;
begin
  aResult := False;
  for i := 1 to 31 do
  begin
    if isCheckBoxChecked('cbDay' + inttostr(i)) then
    begin
      aResult := True;
      break;
    end;
  end;
end;

procedure TfrmRecurringPaymentDetail.OnItemError(Sender: TObject; Source:
    TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmRecurringPaymentDetail.OnMaxCountValidation(Sender: TObject;
    Source: TElementAction; var aResult: Boolean);
begin
  aResult := False;
  if StrToIntDef(alForm[Source.Name].value, 0) > 0 then
  begin
    aResult := True;
  end
  else
    DisplayError(Source.Name, '', SMaxCountAmountError);
end;

procedure TfrmRecurringPaymentDetail.OnMonthValidation(Sender: TObject; Source:
    TElementAction; var aResult: Boolean);
var
  i : Integer;
begin
  aResult := False;
  for i := 1 to 12 do
  begin
    if isCheckBoxChecked('cbMonth' + inttostr(i)) then
    begin
      aResult := True;
      break;
    end;
  end;
end;

procedure TfrmRecurringPaymentDetail.setParams(const Params: TStrings);
begin
  inherited;
  FRoute := '#';
  If Assigned(Params) and (Params.indexOfName('ACCOUNTID')<>-1) then
  begin
    FRoute := '#/CSR/' + InttoStr(DossierID) + '/trustaccount/' + Params.Values['ACCOUNTID'] + '/recurringpayment';

    FAccountID := StrToIntDef(Params.Values['ACCOUNTID'],-1);
    jQuery('#returnBack').attr('href', FRoute);
    dsRecPayment.ParamByName('DOSSIERID').asInteger := DossierID;
    dsRecPayment.ParamByName('BACID').asInteger := FAccountID;
    if Params.indexOfName('ID')<>-1 then
     dsRecPayment.ParamByName('RPAID').asInteger := StrToIntDef(Params.Values['ID'],-1);
  end;
  dsRecPayment.Load([], nil);
end;

procedure TfrmRecurringPaymentDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  initMonths;
  initDays;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsRecPayment.DAConnection := Server.CompanyConnection;
  BindOnChangeEvent;
end;

procedure TfrmRecurringPaymentDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  eacRecPayment := TPW2DataElementMapper.Create(Self);
  dsRecPayment := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  eacRecPayment.BeforeLoadDFMValues;
  dsRecPayment.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'cbPaymentPlanItemFK';
      Name := 'cbPaymentPlanItemFK';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtAmount';
      Name := 'edtAmount';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtMessage';
      Name := 'edtMessage';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidStart';
      Name := 'ddtValidStart';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidStop';
      Name := 'ddtValidStop';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtMaxCount';
      Name := 'edtMaxCount';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCurrCount';
      Name := 'edtCurrCount';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtLastOrderOn';
      Name := 'ddtLastOrderOn';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'OnBtnSaveClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'month';
      Name := 'month';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'day';
      Name := 'day';
      PreventDefault := False;
      StopPropagation := False;
    end;
    eacRecPayment.SetParentComponent(Self);
    eacRecPayment.Name := 'eacRecPayment';
    eacRecPayment.ActionLinks.Clear;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'cbPaymentPlanItemFK';
      FieldName := 'rpapaymentplanitemfk';
      Options := [];
      SetEvent(Self, 'OnValidationError', 'OnItemError');
      ClearValue := '0';
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'edtAmount';
      FieldName := 'rpaamount';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidation', 'OnAmountValidation');
      ClearValue := '0';
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'edtMessage';
      FieldName := 'rpamessage';
      Options := [];
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'ddtValidStart';
      FieldName := 'rpavalidstart';
      Options := [aoRequired];
      SetEvent(Self, 'OnFieldToAction', 'OnDateToAction');
      SetEvent(Self, 'OnValidationError', 'OnItemError');
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'ddtValidStop';
      FieldName := 'rpavalidstop';
      Options := [aoRequired];
      SetEvent(Self, 'OnFieldToAction', 'OnDateToAction');
      SetEvent(Self, 'OnValidation', 'OnValidTillValidation');
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'month';
      FieldName := 'rpapaymentmonths';
      Options := [aoRequired];
      SetEvent(Self, 'OnFieldToAction', 'OnMonthsToForm');
      SetEvent(Self, 'OnActionToField', 'OnMonthsToField');
      SetEvent(Self, 'OnValidationError', 'OnItemError');
      SetEvent(Self, 'OnValidation', 'OnMonthValidation');
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'day';
      FieldName := 'rpapaymentdays';
      Options := [aoRequired];
      SetEvent(Self, 'OnFieldToAction', 'OnDaysToForm');
      SetEvent(Self, 'OnActionToField', 'OnDayToField');
      SetEvent(Self, 'OnValidationError', 'OnItemError');
      SetEvent(Self, 'OnValidation', 'OnDayValidation');
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'edtMaxCount';
      FieldName := 'rpamaxcount';
      Options := [];
      SetEvent(Self, 'OnValidation', 'OnMaxCountValidation');
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'edtCurrCount';
      FieldName := 'rpacurrcount';
      Options := [];
      SetEvent(Self, 'OnActionToField', 'OnCurrCountToField');
    end;
    with eacRecPayment.ActionLinks.Add do
    begin
      ActionName := 'ddtLastOrderOn';
      FieldName := 'rpalastorderon';
      Options := [];
      SetEvent(Self, 'OnFieldToAction', 'OnDateToAction');
    end;
    eacRecPayment.ActionList := alForm;
    eacRecPayment.Dataset := dsRecPayment;
    eacRecPayment.Left := 40;
    eacRecPayment.Top := 160;
    dsRecPayment.SetParentComponent(Self);
    dsRecPayment.Name := 'dsRecPayment';
    dsRecPayment.TableName := 'RecurringPayment';
    dsRecPayment.Params.Clear;
    with dsRecPayment.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsRecPayment.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BACID';
      ParamType := ptInput;
    end;
    with dsRecPayment.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'RPAID';
      ParamType := ptInput;
    end;
    dsRecPayment.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>rpabankaccountfk</field><parameter type="LargeInt">BACID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>rpaid</field><parameter type="LargeInt">RPAID</parameter></binaryoperation></binaryoperation></where></query>';
    dsRecPayment.DAOptions := [doRefreshAllFields];
    dsRecPayment.AfterOpen := dsRecPaymentAfterOpen;
    dsRecPayment.AfterApplyUpdates := dsRecPaymentAfterApplyUpdates;
    dsRecPayment.Left := 40;
    dsRecPayment.Top := 88;
  finally
    alForm.AfterLoadDFMValues;
    eacRecPayment.AfterLoadDFMValues;
    dsRecPayment.AfterLoadDFMValues;
  end;
end;

end.