unit Forms.ResetPassword;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  WEBLib.Actions;

type
  TfrmResetPassword = class(TfrmBase)
    procedure DoPassResetClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure alFormActions3Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure alFormActions1Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }
  public
    { Public declarations }
    class function NeedsLogin: Boolean; override;
  protected procedure LoadDFMValues; override; end;

var
  frmResetPassword: TfrmResetPassword;

implementation

uses
  lib.formtranslation,
  Units.ActionUtils,
  Modules.Server;

{$R *.dfm}

procedure TfrmResetPassword.alFormActions1Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  alForm['inputEmail'].value := '';
end;

procedure TfrmResetPassword.alFormActions3Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  alForm['inputUser'].value := '';
end;

procedure TfrmResetPassword.DoPassResetClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

  procedure success(Sender: TObject);
  begin
    alForm['inputUser'].value := '';
    alForm['inputCompany'].value := '';
    alForm['inputEmail'].value := '';
  end;

var
  user, comp, email: string;
begin
  EnterMethod('DoPassResetClick');
  user := alForm['inputUser'].value;
  comp := alForm['inputCompany'].value;
  email := alForm['inputEmail'].value;
  Server.DoPasswordReset(user, comp, email, @success);
  ExitMethod('DoPassResetClick');
end;

class function TfrmResetPassword.NeedsLogin: Boolean;
begin
  Result := False;
end;

procedure TfrmResetPassword.WebFormCreate(Sender: TObject);
var
  aLang: string;
begin
  if FormTranslator.Language = '' then
  begin
    aLang := dmServer.GetLocalData(KeyLanguage);
    if aLang <> '' then
      FormTranslator.Language := aLang;
  end;
  inherited;
end;

procedure TfrmResetPassword.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnReset';
      Name := 'btnReset';
      SetEvent(Self, 'OnExecute', 'DoPassResetClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'inputUser';
      Name := 'inputUser';
      SetEvent(Self, 'OnExecute', 'alFormActions1Execute');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'inputCompany';
      Name := 'inputCompany';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'inputEmail';
      Name := 'inputEmail';
      SetEvent(Self, 'OnExecute', 'alFormActions3Execute');
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
