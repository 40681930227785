unit Units.Start;

interface

procedure StartDezq(aBaseDir: string = ''; aHomeRoute: string = '');

implementation

uses
  Units.ServerConfig,
  Units.Logging,
  Units.Templates,
  Modules.Server,
  Units.Routes,
  Units.PageHandler,
  pas2web.htmltranslator,
  lib.formtranslation,
  Units.Toasts;

procedure HookRODSKPost;
var
  origPost: JSValue;
begin
  origPost := nil;
  asm
    origPost=RemObjects.SDK.HTTPClientChannel.prototype.post;
    RemObjects.SDK.HTTPClientChannel.prototype.post = function (aMessage, isBinary, onSuccess, onError) {
    this.lastCall=new Date();
    console.debug('last call',this.lastCall);
    origPost.apply(this,arguments);
    }
  end;
  // Silence compiler warning
  if origPost = nil then;
end;

procedure StartDezq(aBaseDir: string = ''; aHomeRoute: string = '');

  procedure DoStartRouting(Sender: TObject; const aTemplate: string);
  begin
    PageHandler.StartRouting;
  end;

begin
  HookRODSKPost;
  if ServerConfig.DoDebugLog then
    Log.LogTypes := Log.LogTypes + [ltDebug];
  if ServerConfig.DoErrorLog then
    Log.LogTypes := Log.LogTypes + [ltError];
  Log.Enabled := ServerConfig.DoShowLog;
  Log.Targets := [ltConsole];
  Log.UseProcessData := False;
  Server.Initialize;
  SetupRoutes;
  TemplateManager.SetupTemplates(aBaseDir);
  PageHandler.SetupTemplates;
  if aHomeRoute <> '' then
    PageHandler.DefaultHomeRoute := aHomeRoute;
  Toasts.ElementID := 'toastarea-stack';
  Toasts.ToastAutoHide := True;
  Toasts.ToastHideDelay := 5000;
  // Toasts.ShowToast('Hello!', 'Welkom bij Dezq!', cPrimary, True);
  TemplateManager.ExecuteIfTemplate('menu', @DoStartRouting);
  FormTranslator.FileMode := fmSingle;
  FormTranslator.LanguageFileURL := aBaseDir + 'js/lang.json';

  // Initial language is english
  FormTranslator.Language := 'en';
  FormTranslator.SeeAlsoProperty := '__seealso__';
end;

end.
