unit Forms.Files;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, Units.Types, pas2web.datatables,
  Forms.DossierDetail, pas2web.dadatasethelper;

type
  TFrmBase = TFrmDossierDetail;
  TfrmFiles = class(TfrmBase)
    dcFiles: TP2WDatatable;
    dsFiles: TP2WDADataset;
    procedure dcFilesAfterDraw(Sender: TObject);
    procedure dsFilesAfterOpen(DataSet: TDataSet);
    procedure OnGetDocType(Sender: TObject; Fields: TP2WDataTableFieldMap; out
        aOutput: string);
    procedure OnGetSourceType(Sender: TObject; Fields: TP2WDataTableFieldMap; out
        aOutput: string);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FRoute : String;
    FDeleteID : Int64;
    FDoDelete : Boolean;
    procedure ConfirmDeleteFile;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDelete(const FileId: Int64);
  protected procedure LoadDFMValues; override; end;

var
  frmFiles: TfrmFiles;

implementation

{$R *.dfm}

uses
  libjquery,  Modules.Server, Units.Strings;

procedure TfrmFiles.ConfirmDeleteFile;

  procedure OnFileDeleteCallback(aResult: Boolean; Error: String);
  begin
    if aResult then
    begin
      window.location.href := FRoute;
    end
    else
      dmServer.ShowError(Error);
  end;

  Procedure DoDeleteRecord;
  begin
    Server.DeleteDocument(FDeleteID, @OnFileDeleteCallback)
  end;

  Procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;

begin
  FDoDelete := False;
  if dsFiles.Locate('dofid', FDeleteID,[]) then
    DefaultConfirmation.Execute(Format(SFileDeleteConfirmation, [dsFiles.FieldByName('doffilename').asString]),
      @DoDeleteRecord,@DoCancelDelete);
end;

procedure TfrmFiles.dcFilesAfterDraw(Sender: TObject);

  function processInfoButton(aIndex: Integer; AElement: TJSElement): Boolean;
  var
    source : TFileSourceType;
    id : string;

  begin
    id := jQuery(AElement).attr('source');
    source.AsInteger := StrToIntDef(jQuery(AElement).attr('source-type'), 0);
    case source of
      fstDossier: jQuery(AElement).attr('href', DossierURL('/identity'));
      fstContact: jQuery(AElement).attr('href', '#/contact/' + id);
      fstContactID,
      fstContactAddress,
      fstContactTelecom: jQuery(AElement).attr('href', '#/contact/edit/' + id);
      fstRevenue: jQuery(AElement).attr('href', DossierURL('/revenue/edit/' + id + '/income'));
      fstProperty: jQuery(AElement).attr('href', DossierURL('/possession/edit/' + id));
      fstLegalCase: jQuery(AElement).attr('href', DossierURL('/legalcase/edit/' + id));
      fstDebt: jQuery(AElement).attr('href', DossierURL('/debt/edit/' + id));
      fstSalaryTransfer: jQuery(AElement).attr('href', DossierURL('/salarytransfer/edit/' + id));
      fstRealEstate: jQuery(AElement).attr('href', DossierURL('/realestate/edit/' + id));
      fstIncomeDebt: jQuery(AElement).attr('href', DossierURL('/revenue/edit/' + id + '/debtor'));
      else
        jQuery(AElement).remove;
    end;
    Result := True;
  end;

begin
  jQuery('a[role="info"]').each(@processInfoButton);
end;

procedure TfrmFiles.DoDelete(const FileId: Int64);
begin
  FDeleteID := FileId;
  if dsFiles.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteFile;
end;

procedure TfrmFiles.dsFilesAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsFiles.GetNewRows(False);
  dcFiles.Data := aResult;
  dcFiles.RenderTranslated;
  if FDoDelete then
    ConfirmDeleteFile;
end;

procedure TfrmFiles.OnGetDocType(Sender: TObject; Fields: TP2WDataTableFieldMap;
    out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('DOCTYP', string(Fields.GetValueByName('dofdoctype')));
end;

procedure TfrmFiles.OnGetSourceType(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);
var
  source : TFileSourceType;
begin
  source.AsInteger := StrToIntDef(string(Fields.GetValueByName('dofsourcetype')), 0);
  aOutput := source.AsDisplayString;
end;

procedure TfrmFiles.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmFiles.setParams(const Params: TStrings);
begin
  inherited;
  FRoute := DossierURL('file');
  jQuery('#addDebt').attr('href',DossierURL('/file/new'));
  dsFiles.ParamByName('DOSSIERID').asLargeInt := DossierID;
end;

procedure TfrmFiles.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDeleteID := -1;
  FDoDelete := False;
  dsFiles.DAConnection := DossierConnection;
end;

procedure TfrmFiles.WebFormShow(Sender: TObject);
begin
  inherited;
  server.DoOnParamsLoaded(procedure
    begin
      dsFiles.Load([], nil);
    end);
end;

procedure TfrmFiles.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dcFiles := TP2WDatatable.Create(Self);
  dsFiles := TP2WDADataset.Create(Self);

  dcFiles.BeforeLoadDFMValues;
  dsFiles.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    dcFiles.SetParentComponent(Self);
    dcFiles.Name := 'dcFiles';
    dcFiles.Columns.Clear;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'doffilename';
      Title := 'Creditor';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofmime';
      Title := 'Debt Type';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofcreatedon';
      Title := 'Start On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofchangedon';
      Title := 'Original Amount';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofdoctype';
      Title := 'Current Amount';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGetDocType');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofsourcetype';
      Title := 'Guarantor';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGetSourceType');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofid';
      Title := 'Is Complete';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{dofdossierfk}}/file/edit/{{dofid}}';
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{dofdossierfk}}/file/delete/{{dofid}}';
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofdossierfk';
      RenderMode := rmButton;
      ButtonType := btCustom;
      ButtonIconClass := 'fas fa-info';
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'role="info" source="{{dofsourcefk}}" source-type="{{dofsourcetype}}"';
    end;
    dcFiles.DataSet := dsFiles;
    dcFiles.Language := lEnglish;
    dcFiles.IsResponsive := True;
    dcFiles.GridID := 'grdFiles';
    dcFiles.UseFieldIndex := True;
    dcFiles.ShowSearch := False;
    dcFiles.ShowNumberOfEntries := False;
    dcFiles.ShowEntriesInfo := False;
    dcFiles.Paginate := True;
    dcFiles.DisplayReadOnly := False;
    dcFiles.CalculateTableWidth := True;
    dcFiles.AfterDraw := dcFilesAfterDraw;
    dcFiles.Left := 32;
    dcFiles.Top := 184;
    dsFiles.SetParentComponent(Self);
    dsFiles.Name := 'dsFiles';
    dsFiles.TableName := 'dossierfile';
    dsFiles.Params.Clear;
    with dsFiles.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsFiles.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsFiles.DAOptions := [doRefreshAllFields];
    dsFiles.AfterOpen := dsFilesAfterOpen;
    dsFiles.Left := 40;
    dsFiles.Top := 96;
  finally
    dcFiles.AfterLoadDFMValues;
    dsFiles.AfterLoadDFMValues;
  end;
end;

end.