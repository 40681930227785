unit Forms.CSR.Legalcase.Detail;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Legalcase.Detail,
  pas2web.dataelementmapper,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  WEBLib.Actions;

type
  TfrmCSRLegalcaseDetail = class(TfrmLegalcaseDetail)
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection: TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRLegalcaseDetail: TfrmCSRLegalcaseDetail;

implementation

{$R *.dfm}
{ TfrmCSRLegalcaseDetail }

function TfrmCSRLegalcaseDetail.DossierConnection: TP2WDAConnection;
begin
  Result := CSRConnection;
end;

function TfrmCSRLegalcaseDetail.DossierPrefix: string;
begin
  Result := 'CSR';
end;

class function TfrmCSRLegalcaseDetail.GetHTMLFileName: string;
begin
  Result := FixCSRHTMLFile(inherited GetHTMLFileName);
end;

procedure TfrmCSRLegalcaseDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.
