unit Forms.Dossier;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Modules.Server,
  lib.bootstrap,
  Units.Params,
  types,
  Data.DB,
  WebRouter,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dadatasethelper,
  System.Generics.Collections;

type

  TPetitionRoute = (prUnknown, prIdentity, prRelation, prRevenue, prRealestate, prPossession, prDebt, prExpense, prSalarytransfer, prLegalcase, prDebtmediator, prRepresentative, prStatement,
    prSubmitPetition, prArchivePetition, prFile);

  TfrmDossier = class(TfrmBase)
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FCurrentForm: TfrmBase;
    FParams: TStrings;
    // FSearchContactResultCallBack: TSearchContactResultCallBack;
    FRoutePattern: string;
    FPropertyType: TDictionary<string, Int64>;
    FAllowNavigation: Boolean;
    FDestintionRoute: string;
    procedure HandleRoute(URl: string; aRoute: TRoute; Params: TStrings; IsReroute: Boolean); override;
    procedure setupMenu(const dossierId: string);
    procedure makeActive(const linkId: string);
    procedure createLinkForm(AInstanceClass: TFormClass; Params: TStrings; afterFormCreation: TNotifyEvent = nil);
    procedure saveForm(SaveType: TPetitionDetailSaveType; Params: THTMLFormParams; onErrorCallback: TOnFailCallBack);
    procedure showNotFoundError(const show: Boolean);
    procedure OnPetitionerFound(contactData: TContactSearchResult; ContactTelecomItems: TContactTelecomItems; ContactAddressItems: TContactAddressItems; isLast: Boolean);
    procedure OnPetitionerNotFound;
    procedure onGetPropTypeItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string; ValueType: Integer; Extra: string; isLast: Boolean);
    procedure ShowDetail;
  public
    { Public declarations }
    class function MyRoutes: TStringDynArray; override;
    procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmDossier: TfrmDossier;

implementation

uses
  Units.Logging,
  Units.ActionUtils,
  StrUtils,
  Units.Strings,
  lib.formtranslation,
  Forms.Identity,
  Forms.Family,
  Forms.Family.Child,
  Forms.Family.Inmate,
  Forms.Revenue,
  Forms.Revenue.Income,
  Forms.Revenue.Debtor,
  Forms.Possession,
  Forms.Possession.Prop,
  Forms.Realestate,
  Forms.Realestate.Prop,
  Forms.Debt,
  Forms.Debt.Details,
  Forms.Expenses,
  Forms.Expense.Detail,
  Forms.Salarytransfer,
  Forms.Salarytransfer.Detail,
  Forms.Legalcase,
  Forms.Legalcase.Detail,
  Forms.Debtmediator,
  Forms.Statement,
  Forms.SubmitPetition,
  Forms.Representative,
  Forms.ArchivePetition,
  Forms.Files,
  Forms.Files.Detail;

const
  SFormContainer = 'linkContent';

  LinkNames: array [TPetitionRoute] of string = ('', 'identity', 'family', 'income', 'realestate', 'property', 'debt', 'costs-expenses', 'wage', 'legal', 'counselor', 'representative', 'statement',
    'submit-petition', 'archive-petition', 'file');
  formRoutes: array [TPetitionRoute] of string = ('', 'identity', 'relation', 'revenue', 'realestate', 'possession', 'debt', 'expense', 'salarytransfer', 'legalcase', 'debtmediator', 'representative',
    'statement', 'submitpetition', 'archivepetition', 'file');

{$R *.dfm}
  { TfrmDossier }

procedure TfrmDossier.WebFormDestroy(Sender: TObject);
begin
  Log.Log(ltDebug, ClassName, 'WebFormDestroy', 'Destroying TDossierForm');
  FreeAndNil(FCurrentForm);
  FreeAndNil(FPropertyType);
  FreeAndNil(FParams);
  inherited;
end;

procedure TfrmDossier.WebFormCreate(Sender: TObject);
begin
  inherited;
  FAllowNavigation := True;
  FDestintionRoute := '';
  FParams := TStringList.Create;
  FPropertyType := TDictionary<string, Int64>.Create;
  Server.GetParameterList('PROPTYP', Language, @onGetPropTypeItem);
end;

procedure TfrmDossier.canHandleRoute(previousURL: string; var allow: Boolean);
begin
  inherited;
  if Assigned(FCurrentForm) then
  begin
    FDestintionRoute := previousURL;
    FCurrentForm.canHandleRoute(previousURL, allow);
    FAllowNavigation := allow;
  end;
end;

procedure TfrmDossier.createLinkForm(AInstanceClass: TFormClass; Params: TStrings; afterFormCreation: TNotifyEvent);
var
  lParams: TStrings;
begin
  if Assigned(Params) then
  begin
    lParams := TStringList.Create;
    lParams.AddStrings(Params);
  end;
  if Assigned(FCurrentForm) then
    FCurrentForm.Free;
  Application.CreateForm(AInstanceClass, SFormContainer, FCurrentForm,
    procedure(Sender: TObject)
    begin
      try
        FCurrentForm.setParams(lParams);
        FCurrentForm.onSave := saveForm;
      finally
        if Assigned(lParams) then
          lParams.Free;
      end;
      FCurrentForm.ShowForm;
      if Assigned(afterFormCreation) then
        afterFormCreation(FCurrentForm);
    end);
end;

procedure TfrmDossier.makeActive(const linkId: string);
begin
  jQuery('a.active').removeClass('active');
  jQuery('#' + linkId).addClass('active');
end;

class function TfrmDossier.MyRoutes: TStringDynArray;
begin
  Result := ['dossier/:DOSSIERID/identity', 'dossier/:DOSSIERID/relation',
  // child tab
  'dossier/:DOSSIERID/relation/new/child', 'dossier/:DOSSIERID/relation/edit/:RELATIONID/child', 'dossier/:DOSSIERID/relation/delete/:RELATIONID/child',
  // adults tabs
  'dossier/:DOSSIERID/relation/new/house', 'dossier/:DOSSIERID/relation/edit/:RELATIONID/house', 'dossier/:DOSSIERID/relation/delete/:RELATIONID/house',
  // revenue (income)
  'dossier/:DOSSIERID/revenue', 'dossier/:DOSSIERID/revenue/new/income', 'dossier/:DOSSIERID/revenue/edit/:REVENUEID/income', 'dossier/:DOSSIERID/revenue/delete/:REVENUEID/income',
    'dossier/:DOSSIERID/revenue/new/debtor', 'dossier/:DOSSIERID/revenue/edit/:REVENUEID/debtor', 'dossier/:DOSSIERID/revenue/delete/:REVENUEID/debtor',
  // realestate
  'dossier/:DOSSIERID/realestate', 'dossier/:DOSSIERID/realestate/new', 'dossier/:DOSSIERID/realestate/edit/:POSSESSIONID', 'dossier/:DOSSIERID/realestate/delete/:POSSESSIONID',
  // possession (movable-property)
  'dossier/:DOSSIERID/possession', 'dossier/:DOSSIERID/possession/new', 'dossier/:DOSSIERID/possession/edit/:POSSESSIONID', 'dossier/:DOSSIERID/possession/delete/:POSSESSIONID',

  // debts
  'dossier/:DOSSIERID/debt', 'dossier/:DOSSIERID/debt/new', 'dossier/:DOSSIERID/debt/edit/:DEBTID', 'dossier/:DOSSIERID/debt/delete/:DEBTID',
  // expenses
  'dossier/:DOSSIERID/expense', 'dossier/:DOSSIERID/expense/new', 'dossier/:DOSSIERID/expense/edit/:EXPENSEID', 'dossier/:DOSSIERID/expense/delete/:EXPENSEID',
  // salarytransfer
  'dossier/:DOSSIERID/salarytransfer', 'dossier/:DOSSIERID/salarytransfer/new', 'dossier/:DOSSIERID/salarytransfer/edit/:TRANSFERID', 'dossier/:DOSSIERID/salarytransfer/delete/:TRANSFERID',
  // legalcase
  'dossier/:DOSSIERID/legalcase', 'dossier/:DOSSIERID/legalcase/new', 'dossier/:DOSSIERID/legalcase/edit/:LEGALCASEID', 'dossier/:DOSSIERID/legalcase/delete/:LEGALCASEID',
  // debtmediator
  'dossier/:DOSSIERID/debtmediator',
  // Representative
  'dossier/:DOSSIERID/representative',
  // statement
  'dossier/:DOSSIERID/statement',
  // submit petition
  'dossier/:DOSSIERID/submitpetition',
  // archive petition
  'dossier/:DOSSIERID/archivepetition',
  // file list
  'dossier/:DOSSIERID/file', 'dossier/:DOSSIERID/file/new', 'dossier/:DOSSIERID/file/edit/:FILEID', 'dossier/:DOSSIERID/file/delete/:FILEID'];
end;

function GetPetitionRoute(S: string): TPetitionRoute;

begin
  Result := high(TPetitionRoute);
  while (Result <> prUnknown) and not SameText(S, formRoutes[Result]) do
    Result := Pred(Result);
end;

procedure TfrmDossier.onGetPropTypeItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string; ValueType: Integer; Extra: string; isLast: Boolean);
begin
  if not FPropertyType.ContainsKey(name) then
    FPropertyType.Add(name, RecordID);
end;

procedure TfrmDossier.OnPetitionerFound(contactData: TContactSearchResult; ContactTelecomItems: TContactTelecomItems; ContactAddressItems: TContactAddressItems; isLast: Boolean);
begin
  if contactData.RecordID > 0 then
  begin
    showNotFoundError(False);

    alForm['petitionerName'].Value := contactData.Lastname + ' ' + contactData.FirstName;

    // if Assigned(FCurrentForm) then
    // FCurrentForm.Free;

    setupMenu(FParams.Values['DOSSIERID']);
  end
  else
    showNotFoundError(True);
end;

procedure TfrmDossier.ShowDetail;

var
  bDeleteOnTabOne: Boolean;
  aRoute: TPetitionRoute;

  procedure AfterCreation(Sender: TObject);

  begin
    case aRoute of
      prRelation:
        if bDeleteOnTabOne then
          (Sender as TfrmFamily).DoDeleteChild(StrToIntDef(FParams.Values['RELATIONID'], -1))
        else
          (Sender as TfrmFamily).DoDeleteInmate(StrToIntDef(FParams.Values['RELATIONID'], -1));
      prRevenue:
        if bDeleteOnTabOne then
          (Sender as TfrmRevenue).DoDeleteIncome(StrToIntDef(FParams.Values['REVENUEID'], -1))
        else
          (Sender as TfrmRevenue).DoDeleteDebtor(StrToIntDef(FParams.Values['REVENUEID'], -1));
      prRealestate:
        (Sender as TfrmRealestate).DoDelete(StrToIntDef(FParams.Values['POSSESSIONID'], -1));
      prPossession:
        (Sender as TfrmPossession).DoDelete(StrToIntDef(FParams.Values['POSSESSIONID'], -1));
      prDebt:
        (Sender as TfrmDebt).DoDelete(StrToIntDef(FParams.Values['DEBTID'], -1));
      prExpense:
        (Sender as TfrmExpenses).DoDelete(StrToIntDef(FParams.Values['EXPENSEID'], -1));
      prSalarytransfer:
        (Sender as TfrmSalarytransfer).DoDelete(StrToIntDef(FParams.Values['TRANSFERID'], -1));
      prLegalcase:
        (Sender as TfrmLegalcase).DoDelete(StrToIntDef(FParams.Values['LEGALCASEID'], -1));
      prFile:
        (Sender as TfrmFiles).DoDelete(StrToIntDef(FParams.Values['FILEID'], -1));
  end;
end;

var
  S: array of string;
  aSubClass, formName: string;
  aOperation: TCrudOperation;
  aformClass: TBaseFormClass;
  doAfter: Boolean;

procedure DoCreateForm;
begin
  if doAfter then
    createLinkForm(aformClass, FParams, @AfterCreation)
  else
    createLinkForm(aformClass, FParams)
end;

begin
  if (Length(FRoutePattern) > 0) and (FRoutePattern[Length(FRoutePattern)] = '/') then
    FRoutePattern := Copy(FRoutePattern, 1, Length(FRoutePattern) - 1);
  S := FRoutePattern.Split('/');
  // Check first 2
  if (Length(S) < 3) or not(SameText(S[0], 'dossier') and SameText(S[1], ':DOSSIERID')) then
  begin
    FreeAndNil(FCurrentForm);
    exit;
  end;
  formName := LowerCase(S[2]);
  // Check operation, if any.
  if Length(S) > 3 then
    aOperation := GetCrudUperation(S[3])
  else
    aOperation := coRead;
  aformClass := nil;
  if Length(S) > 4 then
    aSubClass := S[Length(S) - 1]
  else
    aSubClass := '';
  doAfter := False;
  // Check form.
  aRoute := GetPetitionRoute(formName);
  case aRoute of
    prIdentity:
      begin
        aformClass := TfrmIdentity;
      end;
    prRelation:
      begin
        aformClass := TfrmFamily;
        if aOperation = coDelete then
        begin
          doAfter := True;
        end;
        if (aSubClass = 'child') then
        begin
          if aOperation <> coDelete then
            aformClass := TfrmFamilyChild
          else
            bDeleteOnTabOne := True;
        end
        else
          if (aSubClass = 'house') then
          begin
            if aOperation <> coDelete then
              aformClass := TfrmFamilyInmate
            else
              bDeleteOnTabOne := False;
          end;
      end;
    prRevenue:
      begin
        aformClass := TfrmRevenue;
        if aOperation = coDelete then
        begin
          doAfter := True;
        end;
        if aSubClass = 'income' then
        begin
          if aOperation <> coDelete then
            aformClass := TfrmRevenueIncome
          else
            bDeleteOnTabOne := True;
        end
        else
          if aSubClass = 'debtor' then
          begin
            if aOperation <> coDelete then
              aformClass := TfrmRevenueDebtor
            else
              bDeleteOnTabOne := False;
          end;
      end;
    prRealestate:
      begin
        aformClass := TfrmRealestate;
        doAfter := aOperation = coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass := TfrmRealestateProperty;
      end;
    prPossession:
      begin
        aformClass := TfrmPossession;
        doAfter := aOperation = coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass := TfrmPossessionProperty;
      end;
    prDebt:
      begin
        aformClass := TfrmDebt;
        doAfter := aOperation = coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass := TfrmDebtDetails;
      end;
    prExpense:
      begin
        aformClass := TfrmExpenses;
        doAfter := aOperation = coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass := TfrmExpenseDetail;
      end;
    prSalarytransfer:
      begin
        aformClass := TfrmSalarytransfer;
        doAfter := aOperation = coDelete;
        if aOperation in [coNew, coEdit] then
          aformClass := TfrmSalarytransferDetail;
      end;
    prLegalcase:
      begin
        aformClass := TfrmLegalcase;
        doAfter := aOperation = coDelete;
        if aOperation in [coNew, coEdit] then
          aformClass := TfrmLegalcaseDetail;
      end;
    prDebtmediator:
      begin
        aformClass := TfrmDebtmediator;
      end;
    prRepresentative:
      begin
        aformClass := TfrmRepresentative;
      end;
    prStatement:
      begin
        aformClass := TfrmStatement;
      end;
    prSubmitPetition:
      begin
        aformClass := TfrmSubmitPetition;
      end;
    prArchivePetition:
      begin
        aformClass := TfrmArchivePetition;
      end;
    prFile:
      begin
        aformClass := TfrmFiles;
        doAfter := aOperation = coDelete;
        if aOperation in [coNew, coEdit] then
          aformClass := TfrmFileDetails;
      end;
  end;
  if LinkNames[aRoute] <> '' then
    makeActive(LinkNames[aRoute]);
  if aformClass <> nil then
    if aformClass.NeedsParams then
      dmServer.DoOnParamsLoaded(@DoCreateForm)
    else
      DoCreateForm;
end;

procedure TfrmDossier.OnPetitionerNotFound;
begin
  showNotFoundError(True);
end;

procedure TfrmDossier.HandleRoute(URl: string; aRoute: TRoute; Params: TStrings; IsReroute: Boolean);

  procedure OnConfirm;
  begin
    if Assigned(FCurrentForm) then
    begin
      FCurrentForm.DiscardChanges := True;
      FCurrentForm.DiscardFiles := True;
    end;
    window.location.href := ('#/' + FDestintionRoute).Replace('//', '/');
  end;

begin

  if not FAllowNavigation then
  begin
    DefaultConfirmation.execute(SDiscardChangesMessage, @OnConfirm, nil);
    exit;
  end;

  inherited;
  FRoutePattern := aRoute.URLPattern;
  // check if the parameter is correct
  FParams.Clear;
  if Assigned(Params) then
  begin
    FParams.AddStrings(Params);
  end
  else
    showNotFoundError(True);
  if IsReroute then // if the form is already shown, we just need to call the new subform
    ShowDetail;
  // reset storage values if pettitioner changed
  if Server.GetLocalData('petitioner') <> Params.Values['DOSSIERID'] then
  begin
    Server.SetLocalData('petitioner', Params.Values['DOSSIERID']);
    Server.SetLocalData('Family', '1');
    Server.SetLocalData('Revenue', '1');
    Server.SetLocalData('Identity', '1');
  end;
end;

procedure TfrmDossier.saveForm(SaveType: TPetitionDetailSaveType; Params: THTMLFormParams; onErrorCallback: TOnFailCallBack);

begin
  alForm['petitionerName'].Value := (Params as TPersonIdentityParams).Lastname + ' ' + (Params as TPersonIdentityParams).FirstName;
end;

procedure TfrmDossier.setupMenu(const dossierId: string);

  procedure SetURL(aTag, aPage: string);

  begin
    jQuery('#' + aTag).attr('href', '#/dossier/' + dossierId + '/' + aPage);
  end;

var
  R: TPetitionRoute;

begin
  for R := Succ(low(TPetitionRoute)) to high(TPetitionRoute) do
    SetURL(LinkNames[R], formRoutes[R]);
end;

procedure TfrmDossier.showNotFoundError(const show: Boolean);
begin
  setElementVisible('petitonError', show);
  setElementVisible('header', not show);
  setElementVisible('petitionData', not show);
end;

(*
 procedure TfrmDossier.WebFormKeyDown(Sender: TObject; var Key: Word; Shift:
 TShiftState);
 begin
 if (Key = VK_ESCAPE) AND FShowingModal then
 begin
 jQuery('#searchContactModal').ModalHide;
 FShowingModal := False;
 end;
 end;
*)

procedure TfrmDossier.WebFormShow(Sender: TObject);
begin
  inherited;
  Server.GetPetitionContact(StrToInt(FParams.Values['DOSSIERID']), 'SCH', False, @OnPetitionerFound, @OnPetitionerNotFound);
  ShowDetail;
end;

procedure TfrmDossier.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'creditor';
      Name := 'creditor';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'petitionerName';
      Name := 'petitionerName';
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
