unit Forms.NewPetition;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  Data.DB,
  pas2web.dadataset,
  WEBLib.CDS,
  pas2web.datatables,
  Units.Contacts,
  Module.Contacts,
  Datasnap.DBClient,
  pas2web.dadatasethelper,
  Vcl.Controls,
  WEBLib.WebCtrls,
  libjquery;

type
  TfrmNewPetition = class(TfrmBase)
    procedure WebFormCreate(Sender: TObject); override;
    procedure SavePetitionClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    FContactSetUpDoneEvent: TNotifyEvent;
  protected
    FdmContact: TdmContacts;
    procedure SetupContactModule(bStartNewContact: Boolean = True);
    function DataIsValid: Boolean;
    property onContactModuleSetUp: TNotifyEvent read FContactSetUpDoneEvent write FContactSetUpDoneEvent;
  private
    { Private declarations }

    procedure DoPetitionerSaved(Sender: TObject);
    procedure DoSavePetition;
    procedure DoPartLoaded(Sender: TObject; aPart: TContactDataPart);
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmNewPetition: TfrmNewPetition;

implementation

uses
  roSDK,
  Units.ActionUtils,
  Modules.Server,
  Units.Params,
  Units.DADatasetUtils,
  Units.Strings,
  Units.Types;

{$R *.dfm}

procedure TfrmNewPetition.DoPetitionerSaved(Sender: TObject);
begin
  DoSavePetition;
end;

function TfrmNewPetition.DataIsValid: Boolean;
var
  valid, validfrom, validtill: Boolean;
  aDateFrom, aDateTill, zip: string;
  date1, date2: TDateTime;
begin
  ClearFormErrors;
  valid := True;
  validfrom := False;
  validtill := False;

  if not DateStringIsValid(alForm['ddtBirth'].Value, 'YYYY-MM-DD', '-') then
  begin
    DisplayError('ddtBirth');
    valid := False;
  end;

  aDateFrom := alForm['ddtValidFrom'].Value;
  if (aDateFrom <> '') then
  begin
    validfrom := DateStringIsValid(aDateFrom, 'YYYY-MM-DD', '-');
    if not validfrom then
    begin
      DisplayError('ddtValidFrom');
      valid := False;
    end;
  end;

  aDateTill := alForm['ddtValidTill'].Value;
  if (aDateTill <> '') then
  begin
    validtill := DateStringIsValid(aDateTill, 'YYYY-MM-DD', '-');
    if not validtill then
    begin
      DisplayError('ddtValidTill');
      valid := False;
    end;
  end;

  if validfrom and validtill then
  begin
    date1 := JSDateToDateTime(TJSDate.new(Trim(alForm['ddtValidFrom'].Value)));
    date2 := JSDateToDateTime(TJSDate.new(Trim(alForm['ddtValidTill'].Value)));
    valid := valid and (date1 < date2);
    if not(date1 < date2) then
      DisplayError('ddtValidTill', '', SErrTillAfterFrom);
  end;

  valid := not FieldIsEmpty('edtName') and valid;
  valid := not FieldIsEmpty('edtFirstname') and valid;
//  valid := not FieldIsEmpty('edtBirthplacePostcode') and valid;
//  valid := not FieldIsEmpty('edtBirthplace') and valid;
  valid := not FieldIsEmpty('edtNationalNumber') and valid;
//  valid := not FieldIsEmpty('edtNationality') and valid;
  valid := not FieldIsEmpty('edtAddress') and valid;
  valid := not FieldIsEmpty('edtNumber') and valid;
  valid := not FieldIsEmpty('edtZip') and valid;
  valid := not FieldIsEmpty('edtTown') and valid;
//  valid := not FieldIsEmpty('edtCardType') and valid;
//  valid := not FieldIsEmpty('edtCardno') and valid;

  // all fields are filled now check if all of the values are correct
  if valid then
  begin
//    zip := Trim(alForm['edtBirthplacePostcode'].Value);
//    if (zip <> '0') and (zip <> '') and (not zipCodeIsValid(zip)) then
//    begin
//      DisplayError('edtBirthplacePostcode', 'incorrect-value');
//      valid := False;
//    end;
    zip := Trim(alForm['edtZip'].Value);
    if (zip <> '') and not zipCodeIsValid(zip) then
    begin
      DisplayError('edtZip', 'incorrect-value');
      valid := False;
    end;
  end;
  Result := valid;
end;

procedure TfrmNewPetition.DoPartLoaded(Sender: TObject; aPart: TContactDataPart);
var
  aDate, aDescription: string;
begin
  if aPart = cdpPersonal then
  begin
    aDescription := Trim(alForm['edtDescription'].Value);
    if (aDescription = '') then
    begin
      aDescription := Trim(alForm['edtName'].Value + ' ' + alForm['edtFirstname'].Value);
      if (aDescription <> '') then
      begin
        aDate := alForm['ddtBirth'].Value;
        aDescription := aDescription + ' (' + StringReplace(aDate, '-', '.', [rfReplaceAll]) + ')';
        alForm['edtDescription'].Value := aDescription;
      end;
    end;
  end;
end;

procedure TfrmNewPetition.SetupContactModule(bStartNewContact: Boolean = True);
const
  PetitionerPersonalFields: TPersonalFieldNames = ('edtName', 'edtFirstname', 'ddtBirth', 'edtBirthplacePostcode', 'edtBirthplace', 'rdGroupGender', 'edtNationality', '', '', '', '', '', '', '', '');
  PetitionerIDFields: TContactIDFieldNames = ('edtNationalNumber', 'edtCardno', 'edtCardType', 'edtChipNo', 'ddtValidFrom', 'ddtValidTill', 'edtCardRemark');
  PetitionerAddressFields: TAddressFieldNames = ('edtAddress', 'edtNumber', 'edtZip', 'edtTown', '', '', '');
var
  PersonalFields: TPersonalFieldEdits;
  AddressFields: TAddressFieldEdits;
  IDFields: TContactIDFieldEdits;
begin
  FdmContact.SetupPhoto('fileupload-avatar', 'avatar', 'avatarChanged');

  PersonalFields.Names := PetitionerPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names := PetitionerAddressFields;
  AddressFields.SetFieldsFromNames;
  IDFields.Names := PetitionerIDFields;
  IDFields.SetFieldsFromNames;

  // Pass on to FdmContact...
  FdmContact.PersonalFieldInfo := PersonalFields;
  FdmContact.WPAddressFieldInfo := AddressFields;
  FdmContact.ContactIDFieldInfo := IDFields;
  FdmContact.EditContactButtonID := 'btnEditNewPetitionerContact';
  FdmContact.SearchButtonID := 'btnSelectNewPetitionerContact';
  FdmContact.OnAllSaved := @DoPetitionerSaved;
  FdmContact.OnPartLoaded := DoPartLoaded;

  if bStartNewContact then
    FdmContact.StartNewContact([cdpPersonal, cdpID, cdpWPAddress], []);

  if (Assigned(FContactSetUpDoneEvent)) then
    FContactSetUpDoneEvent(self);
end;

procedure TfrmNewPetition.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.PetitionConnection.Message.ClientID := Server.ClientID;
  Server.ContactConnection.Message.ClientID := Server.ClientID;
  FdmContact := TdmContacts.Create(self);
  FdmContact.AllowedContactType := actPerson;
  FdmContact.DossierConnection := dmServer.PetitionConnection;
end;

procedure TfrmNewPetition.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FdmContact);
  inherited;
end;

procedure TfrmNewPetition.WebFormShow(Sender: TObject);
begin
  Server.DoOnParamsLoaded(
    procedure
    begin
      SetupContactModule;
    end);
end;

procedure TfrmNewPetition.DoSavePetition();
var
  petitonID: Int64;

  procedure HandleResult(anID: Int64);
  begin
    petitonID := anID;
    window.location.href := '#/dossier/' + inttostr(petitonID) + '/identity';
  end;

  procedure DoFail(aResponse: TJSObject; aFail: TJSError);
  begin
    alForm['errorMessage'].Value := ExtractErrorMsg(aFail);
    SetElementVisible('errorBlock', True);
  end;

var
  aDescription, aDate: string;
begin
  aDescription := Trim(alForm['edtDescription'].Value);
  if aDescription = '' then
  begin
    aDate := alForm['ddtBirth'].Value;
    aDescription := Trim(alForm['edtName'].Value) + ' ' + Trim(alForm['edtFirstname'].Value) + ' (' + StringReplace(aDate, '-', '.', [rfReplaceAll]) + ')';
  end;
  Server.PetitionService.CreatePetitionFromContact(FdmContact.ContactID, aDescription, @HandleResult, @DoFail);
end;

procedure TfrmNewPetition.SavePetitionClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if DataIsValid then
    FdmContact.SaveIfValid;
  // Continues in DoSavePetition
end;

procedure TfrmNewPetition.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtName';
      Name := 'edtName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtFirstname';
      Name := 'edtFirstname';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtBirth';
      Name := 'ddtBirth';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtBirthplacePostcode';
      Name := 'edtBirthplacePostcode';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtBirthplace';
      Name := 'edtBirthplace';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNationalNumber';
      Name := 'edtNationalNumber';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNationality';
      Name := 'edtNationality';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtAddress';
      Name := 'edtAddress';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNumber';
      Name := 'edtNumber';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtZip';
      Name := 'edtZip';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtTown';
      Name := 'edtTown';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCardType';
      Name := 'edtCardType';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCardno';
      Name := 'edtCardno';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidFrom';
      Name := 'ddtValidFrom';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidTill';
      Name := 'ddtValidTill';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtChipNo';
      Name := 'edtChipNo';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSavePetition';
      Name := 'btnSavePetition';
      SetEvent(Self, 'OnExecute', 'SavePetitionClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtDescription';
      Name := 'edtDescription';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCardRemark';
      Name := 'edtCardRemark';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'errorMessage';
      Name := 'errorMessage';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'fileupload-avatar';
      Name := 'fileupload-avatar';
      PreventDefault := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'newCSRTitle';
      Name := 'newCSRTitle';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'newPetitionTitle';
      Name := 'newPetitionTitle';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'csrTabs';
      Name := 'csrTabs';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'first';
      Name := 'firstTab';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'second';
      Name := 'secondTab';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'selCourt';
      Name := 'selCourt';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtJudgeName';
      Name := 'edtJudgeName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtStart';
      Name := 'ddtStart';
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
