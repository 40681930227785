unit Forms.Legalcase;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset,
  pas2web.dadatasethelper, lib.bootstrap, Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmLegalcase = class(TfrmBase)
    dsLegalCase: TP2WDADataset;
    dcLegalcase: TP2WDatatable;
    procedure dsLegalCaseAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure dsLegalCaseAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
  private
    { Private declarations }
    FDoDelete : Boolean;
    FDeleteID : Int64;
    FRoute : string;
    procedure ConfirmDeleteLegalcase;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDelete(const legalCaseId: Int64);
  protected procedure LoadDFMValues; override; end;

var
  frmLegalcase: TfrmLegalcase;

implementation

uses
  Modules.Server , Units.Strings;

{$R *.dfm}

{ TfrmLegalcase }

procedure TfrmLegalcase.ConfirmDeleteLegalcase;

  Procedure DoDeleteRecord;

  begin
    if dsLegalcase.Locate('lecid', FDeleteID,[]) then
      begin
      dsLegalcase.Delete;
      dsLegalcase.ApplyUpdates;
      end;
  end;

  Procedure DoCancelDelete;

  begin
    window.location.href := FRoute;
  end;

begin
  FDoDelete := False;
  if dsLegalcase.Locate('lecid', FDeleteID,[]) then
    DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
end;

procedure TfrmLegalcase.DoDelete(const legalCaseId: Int64);
begin
  FDeleteID := legalCaseId;
  if dsLegalcase.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteLegalcase;
end;

procedure TfrmLegalcase.dsLegalCaseAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);

begin
  CheckResolveResults(Info,FRoute);
end;

procedure TfrmLegalcase.dsLegalCaseAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsLegalCase.GetNewRows(False);
  dcLegalCase.Data := aResult;
  dcLegalCase.RenderTranslated;
  if FDoDelete then
    ConfirmDeleteLegalcase;
end;

procedure TfrmLegalcase.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDoDelete := False;
  FDeleteID := -1;
  FRoute := '';
  dsLegalCase.DAConnection := DossierConnection;
end;

procedure TfrmLegalcase.setParams(const Params: TStrings);
begin
  Inherited;
  jQuery('#addLegalcase').attr('href',DossierURL('/legalcase/new'));
  FRoute := DossierURL('/legalcase');
  dsLegalCase.ParamByName('DOSSIERID').asLargeInt:= DossierID;
end;

procedure TfrmLegalcase.WebFormShow(Sender: TObject);
begin
  inherited;
  dsLegalCase.Load([], nil)
end;

procedure TfrmLegalcase.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsLegalCase := TP2WDADataset.Create(Self);
  dcLegalcase := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsLegalCase.BeforeLoadDFMValues;
  dcLegalcase.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'btnNo';
    end;
    dsLegalCase.SetParentComponent(Self);
    dsLegalCase.Name := 'dsLegalCase';
    dsLegalCase.TableName := 'legalcaseoverview';
    dsLegalCase.Params.Clear;
    with dsLegalCase.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsLegalCase.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>lecdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsLegalCase.DAOptions := [];
    dsLegalCase.AfterOpen := dsLegalCaseAfterOpen;
    dsLegalCase.AfterApplyUpdates := dsLegalCaseAfterApplyUpdates;
    dsLegalCase.Left := 40;
    dsLegalCase.Top := 88;
    dcLegalcase.SetParentComponent(Self);
    dcLegalcase.Name := 'dcLegalcase';
    dcLegalcase.Columns.Clear;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecamount';
      Title := 'Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'leccause';
      Title := 'Cause';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecreference';
      Title := 'Reference';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecprimary';
      Title := 'Primary Party';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecopponent';
      Title := 'Opponent';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'leclawyer';
      Title := 'Lawyer';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'leciscomplete';
      Title := 'Is Completed';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{lecdossierfk}}/legalcase/edit/{{lecid}}';
    end;
    with dcLegalcase.Columns.Add do
    begin
      FieldName := 'lecdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{lecdossierfk}}/legalcase/delete/{{lecid}}';
    end;
    dcLegalcase.DataSet := dsLegalCase;
    dcLegalcase.Language := lEnglish;
    dcLegalcase.IsResponsive := False;
    dcLegalcase.GridID := 'grdLegalcase';
    dcLegalcase.UseFieldIndex := True;
    dcLegalcase.ShowSearch := False;
    dcLegalcase.ShowNumberOfEntries := False;
    dcLegalcase.ShowEntriesInfo := False;
    dcLegalcase.Paginate := True;
    dcLegalcase.DisplayReadOnly := False;
    dcLegalcase.CalculateTableWidth := True;
    dcLegalcase.Left := 40;
    dcLegalcase.Top := 160;
  finally
    alForm.AfterLoadDFMValues;
    dsLegalCase.AfterLoadDFMValues;
    dcLegalcase.AfterLoadDFMValues;
  end;
end;

end.