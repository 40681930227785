unit Units.Routes;

{$mode objfpc}

interface

uses
  WebRouter,
  Forms.Base;

procedure SetupRoutes;

implementation

uses
  Units.PageHandler,
  Forms.Profile,
  Forms.Login,
  Forms.Start,
  Forms.ResetPassword,
  Forms.Signon,
  Forms.ListPetitions,
  Forms.NewPetition,
  Forms.Dossier,
  Forms.Signonapproval,
  Forms.Signonapproval.Detail,
  Forms.Contacts,
  Forms.Customer,
  Forms.ChangePassword,
  Forms.CompanyProfile,
  Forms.CompanyUsers,
  Forms.CompanyUser.Detail,
  Forms.CSR,
  Forms.ListCSR,
  Forms.NewCSR,
  Forms.CSR.CreateCSR,
  Forms.CSR.Copy,
  Forms.Messages,
  Forms.BudgetPlans,
  Forms.CSR.BudgetPlan,
  Forms.TrustAccountList,
  Forms.ImportContacts,
  Forms.Import.mapping,
  Forms.ExportContacts,
  // Keep thse as last form
  Forms.WIP,
  Forms.Error;

procedure SetupRoutes;
begin
  Router.InitHistory(hkHash);
  Router.RegisterRoute('logout', @PageHandler.LogoutRoute);
  TfrmLogin.RegisterForm;
  TfrmResetPassword.RegisterForm;
  TfrmChangePassword.RegisterForm;
  TfrmSignon.RegisterForm;
  TfrmStart.RegisterForm;

  // list internal pages
  TfrmPetition.RegisterForm;
  TfrmNewPetition.RegisterForm;
  TfrmDossier.RegisterForm;

  TfrmContact.RegisterForm;
  TfrmImportContacts.RegisterForm;
  TFrmImportMapping.RegisterForm;
  TfrmExportContacts.RegisterForm;
  TfrmCustomer.RegisterForm;

  TfrmWIP.RegisterForm;

  // list admin pages
  TfrmSignonapprovalDetail.RegisterForm;
  TfrmSignonapproval.RegisterForm;

  // Company
  TfrmProfile.RegisterForm;
  TfrmCompanyProfile.RegisterForm;
  TfrmCompanyUserList.RegisterForm;
  TfrmCompanyUserDetail.RegisterForm;
  TfrmTrustAccountList.RegisterForm;

  // CSR
  TfrmCSRList.RegisterForm;
  TfrmCSR.RegisterForm;
  TfrmNewCSR.RegisterForm;
  TfrmCreateCSR.RegisterForm;
  TfrmCSRCopy.RegisterForm;
  TfrmMessages.RegisterForm;
  TfrmBudgetPlans.RegisterForm;
  TfrmBudgetPlan.RegisterForm;

  // Last form
  TfrmError.RegisterForm;
  Router.Routes[Router.RouteCount - 1].Default := True;
end;

end.
