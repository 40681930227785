unit Forms.CSR.TrustAccount.PaymentOrder;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmCSRPaymentOrder = class(TfrmBase)
    dsPaymentOrders: TP2WDADataset;
    dcPaymentOrders: TP2WDatatable;
    procedure dsPaymentOrdersAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure OnGetRecuringFK(Sender: TObject; Fields:
        TP2WDataTableFieldMap; out aOutput: string);
    procedure dsPaymentOrdersAfterOpen(DataSet: TDataSet);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FDeleteID : Int64;
    FDoDelete : Boolean;
    FRoute : string;
    procedure ConfirmDeletePatmentOrder;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
    procedure DoDelete(const PaymentOrderId: Int64);
  protected procedure LoadDFMValues; override; end;

var
  frmCSRPaymentOrder: TfrmCSRPaymentOrder;

implementation

{$R *.dfm}

uses
  Modules.Server, libjquery, Units.Strings;

procedure TfrmCSRPaymentOrder.OnGetRecuringFK(Sender: TObject;
    Fields: TP2WDataTableFieldMap; out aOutput: string);
begin
  if StrToIntDef(string(Fields.GetValueByName('porrecurringpaymentfk')), -1) > 0 then
    aOutput := '<i class="fas fa-check-square"></i>'
  else
    aOutput := '<i class="far fa-square"></i>';
end;

procedure TfrmCSRPaymentOrder.ConfirmDeletePatmentOrder;

  Procedure DoDeleteRecord;
  begin
    if dsPaymentOrders.Locate('porid', FDeleteID,[]) then
    begin
      dsPaymentOrders.Delete;
      dsPaymentOrders.ApplyUpdates;
    end;
  end;

  Procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;
begin
  FDoDelete := False;
  if dsPaymentOrders.Locate('porid', FDeleteID,[]) then
  begin
    if (dsPaymentOrders.FieldByName('porrecurringpaymentfk').asString <> '') and
      (dsPaymentOrders.FieldByName('porrecurringpaymentfk').asInteger > 0) then
      Server.ShowError(SOrderDeletionError)
    else
      DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
  end;
end;

procedure TfrmCSRPaymentOrder.DoDelete(const PaymentOrderId: Int64);
begin
  FDeleteID := PaymentOrderId;
  if dsPaymentOrders.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeletePatmentOrder;
end;

function TfrmCSRPaymentOrder.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmCSRPaymentOrder.dsPaymentOrdersAfterApplyUpdates(Sender:
    TDataSet; Info: TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    window.location.href := FRoute;
  end;
end;

procedure TfrmCSRPaymentOrder.dsPaymentOrdersAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsPaymentOrders.GetNewRows(False);
  dcPaymentOrders.Data := aResult;
  dcPaymentOrders.RenderTranslated;
  if FDoDelete then
    ConfirmDeletePatmentOrder;
end;

procedure TfrmCSRPaymentOrder.setParams(const Params: TStrings);
var
  AccountID : NativeInt;
begin
  inherited;
  If Assigned(Params) and (Params.indexOfName('ACCOUNTID')<>-1) then
  begin
    AccountID := StrToIntDef(Params.Values['ACCOUNTID'],-1);
  end;
  dsPaymentOrders.ParamByName('DOSSIERID').asInteger := DossierID;
  dsPaymentOrders.ParamByName('BACID').asInteger := AccountID;
  dsPaymentOrders.Load([], nil);
  FRoute := '#/CSR/' + InttoStr(DossierID) + '/trustaccount/' + IntToStr(AccountID) +'/paymentorder/';

  jQuery('#returnBack').attr('href', '#/CSR/' + InttoStr(DossierID) + '/trustaccount/'); // 'edit/' + IntToStr(AccountID));
  jQuery('#btnAdd').attr('href', '#/CSR/' + InttoStr(DossierID) + '/trustaccount/' + IntToStr(AccountID) + '/paymentorder/new');
end;

procedure TfrmCSRPaymentOrder.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDoDelete := False;
  FRoute := '';
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsPaymentOrders.DAConnection := Server.CompanyConnection;
end;

procedure TfrmCSRPaymentOrder.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmCSRPaymentOrder.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsPaymentOrders := TP2WDADataset.Create(Self);
  dcPaymentOrders := TP2WDatatable.Create(Self);

  dsPaymentOrders.BeforeLoadDFMValues;
  dcPaymentOrders.BeforeLoadDFMValues;
  try
    dsPaymentOrders.SetParentComponent(Self);
    dsPaymentOrders.Name := 'dsPaymentOrders';
    dsPaymentOrders.TableName := 'PaymentOrder';
    dsPaymentOrders.Params.Clear;
    with dsPaymentOrders.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsPaymentOrders.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BACID';
      ParamType := ptInput;
    end;
    dsPaymentOrders.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>porbankaccountfk</field><parameter type="LargeInt">BACID</parameter></binaryoperation></binaryoperation></where></query>';
    dsPaymentOrders.DAOptions := [doRefreshAllFields];
    dsPaymentOrders.AfterOpen := dsPaymentOrdersAfterOpen;
    dsPaymentOrders.AfterApplyUpdates := dsPaymentOrdersAfterApplyUpdates;
    dsPaymentOrders.Left := 40;
    dsPaymentOrders.Top := 88;
    dcPaymentOrders.SetParentComponent(Self);
    dcPaymentOrders.Name := 'dcPaymentOrders';
    dcPaymentOrders.Columns.Clear;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'porpayon';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'poramount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'poriban';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'porstatus';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'porprocessedon';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'porrecurringpaymentfk';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGetRecuringFK');
      CSSClassName := 'text-center';
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'porconfirmedon';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'porbankaccountfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/{{porbankaccountfk}}/paymentorder/edit/{{porid}}';
    end;
    with dcPaymentOrders.Columns.Add do
    begin
      FieldName := 'porid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/{{porbankaccountfk}}/paymentorder/delete/{{porid}}';
    end;
    dcPaymentOrders.DataSet := dsPaymentOrders;
    dcPaymentOrders.Language := lEnglish;
    dcPaymentOrders.IsResponsive := True;
    dcPaymentOrders.GridID := 'grdPayments';
    dcPaymentOrders.UseFieldIndex := True;
    dcPaymentOrders.ShowSearch := False;
    dcPaymentOrders.ShowNumberOfEntries := False;
    dcPaymentOrders.ShowEntriesInfo := False;
    dcPaymentOrders.Paginate := True;
    dcPaymentOrders.DisplayReadOnly := False;
    dcPaymentOrders.CalculateTableWidth := True;
    dcPaymentOrders.Left := 40;
    dcPaymentOrders.Top := 168;
  finally
    dsPaymentOrders.AfterLoadDFMValues;
    dcPaymentOrders.AfterLoadDFMValues;
  end;
end;

end.