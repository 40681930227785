unit Forms.CSR.SalaryTransfer.Detail;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.SalaryTransfer.Detail,
  pas2web.dataelementmapper,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  WEBLib.Actions;

type
  TfrmCSRSalarytransferDetail = class(TfrmSalarytransferDetail)
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }
  public
    class function GetHTMLFileName: string; override;
    function DossierPrefix: string; override;
    function DossierConnection: TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRSalarytransferDetail: TfrmCSRSalarytransferDetail;

implementation

{$R *.dfm}
{ TfrmCSRSalarytransferDetail }

function TfrmCSRSalarytransferDetail.DossierConnection: TP2WDAConnection;
begin
  Result := CSRConnection;
end;

function TfrmCSRSalarytransferDetail.DossierPrefix: string;
begin
  Result := 'CSR';
end;

class function TfrmCSRSalarytransferDetail.GetHTMLFileName: string;
begin
  Result := FixCSRHTMLFile(inherited GetHTMLFileName);
end;

procedure TfrmCSRSalarytransferDetail.SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  inherited;
  //
end;

procedure TfrmCSRSalarytransferDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.
