unit Forms.Statement;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper, libjquery,
  Forms.DossierDetail, pas2web.dadatasethelper;

Type
  TfrmBase = TfrmDossierDetail;
  TfrmStatement = class(TfrmBase)
    dsPetition: TP2WDADataset;
    dsDocuments: TP2WDADataset;
    dsPetitiondosid: TLargeintField;
    dsPetitiondoscreatedon: TDateTimeField;
    dsPetitiondoscreatedbyfk: TLargeintField;
    dsPetitiondoschangedon: TDateTimeField;
    dsPetitiondoschangedbyfk: TLargeintField;
    dsPetitiondosclosedon: TDateTimeField;
    dsPetitiondosdescription: TWideStringField;
    dsPetitiondoscompanyfk: TLargeintField;
    dsPetitiondosstatus: TSmallintField;
    dsPetitiondosdatesent: TDateTimeField;
    dsPetitiondosclosedbyfk: TLargeintField;
    dsPetitiondoscloseddescription: TWideStringField;
    dsPetitiondosisbankrupt: TBooleanField;
    dsPetitiondosbankrupton: TDateTimeField;
    dsPetitiondosiskbocancel: TBooleanField;
    dsPetitiondoskbocancelon: TDateTimeField;
    dsPetitiondosloginfk: TLargeintField;
    dsPetitiondosremarks: TMemoField;
    dsPetitiondosreopenedon: TDateTimeField;
    dsPetitiondosflags: TIntegerField;
    dsPetitiondosmonthlyamount: TFMTBCDField;
    dsPetitiondosiscomplete: TBooleanField;
    dsPetitiondoscasefilecounter: TIntegerField;
    dsPetitiondosdebtcauses: TMemoField;
    procedure dsPetitionAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsPetitionAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure SaveBtnClick(Sender: TObject; Element:
        TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnNotBussinessmanClicked(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }

    function FieldsValid: Boolean;
    procedure DoSaveStatementForm;
    procedure DoClickNotBusinessman;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    Procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmStatement: TfrmStatement;

implementation

uses
  Units.ActionUtils, Units.Params, Modules.Server, Units.DADatasetUtils, Units.Strings, Units.HTMLUtils;

{$R *.dfm}

procedure TfrmStatement.WebFormCreate(Sender: TObject);

begin
  inherited;
  Server.PetitionConnection.Message.ClientID := Server.ClientID;
  dsPetition.DAConnection := Server.PetitionConnection;
  BindOnChangeEvent;
end;

procedure TfrmStatement.SaveBtnClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  ClearFormErrors;
  if FieldsValid then
    DoSaveStatementForm;
end;

procedure TfrmStatement.setParams(const Params: TStrings);
begin
  Inherited;
  dsPetition.ParamByName('DOSSIERID').asLargeInt := DossierID;
end;

procedure TfrmStatement.canHandleRoute(previousURL: string; var allow: Boolean);
begin
  allow := DiscardChanges and ((DefaultDocumentBox.GetAwaitingUploadCount = 0) OR DiscardFiles);
end;

procedure TfrmStatement.DoClickNotBusinessman;
begin
  setElementVisible('chNotBusinessmanBlock', alForm['chNotBusinessman'].Checked);
end;

procedure TfrmStatement.DoSaveStatementForm;

var
  flags : Integer;

begin
  flags := 0;
  if alForm['chNotBusinessman'].checked then
    flags := flags or 1;
  if alForm['chNotCause'].checked then
    flags := flags or 2;
  if alForm['chNotCSRCancelled'].checked then
    flags := flags or 4;
  if alForm['chAknowledgment'].checked then
    flags := flags or 8;

  dsPetition.Edit;
  dsPetition.FieldByName('doschangedon').asDateTime := now;
  dsPetition.FieldByName('doschangedbyfk').asLargeInt := Server.UserID;
  dsPetition.FieldByName('dosisbankrupt').asBoolean := alForm['chNotBusinessman'].checked AND alForm['chIsBankruptcy'].checked;
  if alForm['chNotBusinessman'].checked AND alForm['chIsBankruptcy'].checked then
    dsPetition.FieldByName('dosbankrupton').asDateTime := ExtractDate(alForm['ddtBankruptcyDate'].value);
  dsPetition.FieldByName('dosiskbocancel').asBoolean := alForm['chNotBusinessman'].checked AND alForm['chKBOCancelled'].checked;
  if alForm['chNotBusinessman'].checked AND alForm['chKBOCancelled'].checked then
    dsPetition.FieldByName('doskbocancelon').asDateTime := ExtractDate(alForm['ddtKBOCancelDate'].value);
  dsPetition.FieldByName('dosflags').asInteger := flags;
  if alForm['edtAppDebtCauses'].value<>'' then
    dsPetition.FieldByName('dosdebtcauses').asString := alForm['edtAppDebtCauses'].value
  else
    dsPetition.FieldByName('dosdebtcauses').Clear;
  dsPetition.FieldByName('dosmonthlyamount').asFloat := strtoFloatDef(alForm['edtMonthlyPayment'].value,0.0,getFormatSettings);
  dsPetition.FieldByName('dosiscomplete').asBoolean := getRadioGroupValue('rdGroupCompleted') = 'Yes';
  dsPetition.Post;
  dsPetition.ApplyUpdates;
end;

procedure TfrmStatement.dsPetitionAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);

  procedure OnUploaded;
  begin
    ShowSuccess(SStatementsSavedOK);
    DiscardChanges := True;
  end;

begin
  If CheckResolveResults(Info) then
  begin
    if DefaultDocumentBox.GetAwaitingUploadCount = 0 then
    begin
      ShowSuccess(SStatementsSavedOK);
      DiscardChanges := True;
    end
    else
      DefaultDocumentBox.DoFileQueueUpload(Sender.FieldByName('lecid').asLargeInt,
        '', @OnUploaded);
  end;
end;

procedure TfrmStatement.dsPetitionAfterOpen(DataSet: TDataSet);

Var
  DocSourceID : NativeInt;

begin
  DocSourceID:=-1;
  if not DataSet.isEmpty then
  begin
    if (DataSet.FieldByName('dosflags').asInteger and 1) > 0 then
    begin
      alForm['chNotBusinessman'].checked := True;
      DoClickNotBusinessman;
      if DataSet.FieldByName('dosiskbocancel').asBoolean then
      begin
        alForm['chKBOCancelled'].checked := True;
        alForm['ddtKBOCancelDate'].value:=FormatHTMLDate(DataSet.FieldByName('doskbocancelon').asDateTime);
      end;
      if DataSet.FieldByName('dosisbankrupt').asBoolean then
      begin
        alForm['chIsBankruptcy'].checked := True;
        alForm['ddtBankruptcyDate'].Value:=FormatHTMLDate(DataSet.FieldByName('dosbankrupton').asDateTime);
      end;
    end;
    alForm['chNotCause'].checked := (DataSet.FieldByName('dosflags').asInteger and 2) > 0;
    alForm['chNotCSRCancelled'].checked := (DataSet.FieldByName('dosflags').asInteger and 4) > 0;
    alForm['chAknowledgment'].checked := (DataSet.FieldByName('dosflags').asInteger and 8) > 0;
    alForm['edtMonthlyPayment'].value := FloatToStr(DataSet.FieldByName('dosmonthlyamount').asFloat);
    alForm['edtAppDebtCauses'].value:=dsPetition.FieldByName('dosdebtcauses').asString;
    if DataSet.FieldByName('dosiscomplete').asBoolean then
      setRadiogroupSelectedElement('rdGroupCompleted', 'Yes')
    else
      setRadiogroupSelectedElement('rdGroupCompleted', 'No');
    DocSourceID:=DataSet.FieldByName('dosid').asLargeInt;
  end;
  DefaultDocumentBox.LoadDocuments(dsDocuments, DossierID, DocSourceID, 'uploadList');
  DefaultDocumentBox.setDocBoxParams(1, DocSourceID, 'STATEMENT', DossierID);
end;

function TfrmStatement.FieldsValid: Boolean;
begin
  Result := True;
  if alForm['chNotBusinessman'].Checked then
  begin
    if alForm['chKBOCancelled'].Checked then
      Result := not FieldIsEmpty('ddtKBOCancelDate') and Result;
    if alForm['chIsBankruptcy'].Checked then
      Result := not FieldIsEmpty('ddtBankruptcyDate') and Result;
  end;
  Result := not FieldIsEmpty('edtMonthlyPayment') and Result;
  Result := isNumeric('edtMonthlyPayment') and Result;
  Result := isSelectedRadioGroup('rdGroupCompleted') and Result;
  //check bit fields
  if getRadioGroupValue('rdGroupCompleted') = 'Yes' then
  begin
    if not alForm['chNotCause'].Checked then
    begin
      Result := False;
      DisplayError('chNotCause');
    end;

    if not alForm['chNotCSRCancelled'].Checked then
    begin
      Result := False;
      DisplayError('chNotCSRCancelled');
    end;

    if not alForm['chAknowledgment'].Checked then
    begin
      Result := False;
      DisplayError('chAknowledgment');
    end;
  end;
end;


procedure TfrmStatement.OnNotBussinessmanClicked(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  DoClickNotBusinessman;
end;


procedure TfrmStatement.WebFormShow(Sender: TObject);
begin
  inherited;
  dsPetition.Load([], nil);
end;

procedure TfrmStatement.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsPetition := TP2WDADataset.Create(Self);
  dsPetitiondosid := TLargeintField.Create(Self);
  dsPetitiondoscreatedon := TDateTimeField.Create(Self);
  dsPetitiondoscreatedbyfk := TLargeintField.Create(Self);
  dsPetitiondoschangedon := TDateTimeField.Create(Self);
  dsPetitiondoschangedbyfk := TLargeintField.Create(Self);
  dsPetitiondosclosedon := TDateTimeField.Create(Self);
  dsPetitiondosdescription := TWideStringField.Create(Self);
  dsPetitiondoscompanyfk := TLargeintField.Create(Self);
  dsPetitiondosstatus := TSmallintField.Create(Self);
  dsPetitiondosdatesent := TDateTimeField.Create(Self);
  dsPetitiondosclosedbyfk := TLargeintField.Create(Self);
  dsPetitiondoscloseddescription := TWideStringField.Create(Self);
  dsPetitiondosisbankrupt := TBooleanField.Create(Self);
  dsPetitiondosbankrupton := TDateTimeField.Create(Self);
  dsPetitiondosiskbocancel := TBooleanField.Create(Self);
  dsPetitiondoskbocancelon := TDateTimeField.Create(Self);
  dsPetitiondosloginfk := TLargeintField.Create(Self);
  dsPetitiondosremarks := TMemoField.Create(Self);
  dsPetitiondosreopenedon := TDateTimeField.Create(Self);
  dsPetitiondosflags := TIntegerField.Create(Self);
  dsPetitiondosmonthlyamount := TFMTBCDField.Create(Self);
  dsPetitiondosiscomplete := TBooleanField.Create(Self);
  dsPetitiondoscasefilecounter := TIntegerField.Create(Self);
  dsPetitiondosdebtcauses := TMemoField.Create(Self);
  dsDocuments := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsPetition.BeforeLoadDFMValues;
  dsPetitiondosid.BeforeLoadDFMValues;
  dsPetitiondoscreatedon.BeforeLoadDFMValues;
  dsPetitiondoscreatedbyfk.BeforeLoadDFMValues;
  dsPetitiondoschangedon.BeforeLoadDFMValues;
  dsPetitiondoschangedbyfk.BeforeLoadDFMValues;
  dsPetitiondosclosedon.BeforeLoadDFMValues;
  dsPetitiondosdescription.BeforeLoadDFMValues;
  dsPetitiondoscompanyfk.BeforeLoadDFMValues;
  dsPetitiondosstatus.BeforeLoadDFMValues;
  dsPetitiondosdatesent.BeforeLoadDFMValues;
  dsPetitiondosclosedbyfk.BeforeLoadDFMValues;
  dsPetitiondoscloseddescription.BeforeLoadDFMValues;
  dsPetitiondosisbankrupt.BeforeLoadDFMValues;
  dsPetitiondosbankrupton.BeforeLoadDFMValues;
  dsPetitiondosiskbocancel.BeforeLoadDFMValues;
  dsPetitiondoskbocancelon.BeforeLoadDFMValues;
  dsPetitiondosloginfk.BeforeLoadDFMValues;
  dsPetitiondosremarks.BeforeLoadDFMValues;
  dsPetitiondosreopenedon.BeforeLoadDFMValues;
  dsPetitiondosflags.BeforeLoadDFMValues;
  dsPetitiondosmonthlyamount.BeforeLoadDFMValues;
  dsPetitiondosiscomplete.BeforeLoadDFMValues;
  dsPetitiondoscasefilecounter.BeforeLoadDFMValues;
  dsPetitiondosdebtcauses.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'chNotBusinessman';
      Name := 'chNotBusinessman';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'OnNotBussinessmanClicked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'chKBOCancelled';
      Name := 'chKBOCancelled';
      PreventDefault := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'ddtKBOCancelDate';
      Name := 'ddtKBOCancelDate';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'chIsBankruptcy';
      Name := 'chIsBankruptcy';
      PreventDefault := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'ddtBankruptcyDate';
      Name := 'ddtBankruptcyDate';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'chNotCause';
      Name := 'chNotCause';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'chNotCSRCancelled';
      Name := 'chNotCSRCancelled';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'chAknowledgment';
      Name := 'chAknowledgment';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtMonthlyPayment';
      Name := 'edtMonthlyPayment';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'statementCompleted';
      PreventDefault := False;
      Selector := 'input[name="rdGroupCompleted"]';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtAppDebtCauses';
      Name := 'edtAppDebtCauses';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    dsPetition.SetParentComponent(Self);
    dsPetition.Name := 'dsPetition';
    dsPetition.TableName := 'dossier';
    dsPetition.DAConnection := dmServer.PetitionConnection;
    dsPetition.Params.Clear;
    with dsPetition.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsPetition.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>dosid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsPetition.DAOptions := [];
    dsPetition.AfterOpen := dsPetitionAfterOpen;
    dsPetition.AfterApplyUpdates := dsPetitionAfterApplyUpdates;
    dsPetition.Left := 40;
    dsPetition.Top := 88;
    dsPetitiondosid.SetParentComponent(dsPetition);
    dsPetitiondosid.Name := 'dsPetitiondosid';
    dsPetitiondosid.FieldName := 'dosid';
    dsPetitiondoscreatedon.SetParentComponent(dsPetition);
    dsPetitiondoscreatedon.Name := 'dsPetitiondoscreatedon';
    dsPetitiondoscreatedon.FieldName := 'doscreatedon';
    dsPetitiondoscreatedbyfk.SetParentComponent(dsPetition);
    dsPetitiondoscreatedbyfk.Name := 'dsPetitiondoscreatedbyfk';
    dsPetitiondoscreatedbyfk.FieldName := 'doscreatedbyfk';
    dsPetitiondoschangedon.SetParentComponent(dsPetition);
    dsPetitiondoschangedon.Name := 'dsPetitiondoschangedon';
    dsPetitiondoschangedon.FieldName := 'doschangedon';
    dsPetitiondoschangedbyfk.SetParentComponent(dsPetition);
    dsPetitiondoschangedbyfk.Name := 'dsPetitiondoschangedbyfk';
    dsPetitiondoschangedbyfk.FieldName := 'doschangedbyfk';
    dsPetitiondosclosedon.SetParentComponent(dsPetition);
    dsPetitiondosclosedon.Name := 'dsPetitiondosclosedon';
    dsPetitiondosclosedon.FieldName := 'dosclosedon';
    dsPetitiondosdescription.SetParentComponent(dsPetition);
    dsPetitiondosdescription.Name := 'dsPetitiondosdescription';
    dsPetitiondosdescription.FieldName := 'dosdescription';
    dsPetitiondosdescription.Size := 127;
    dsPetitiondoscompanyfk.SetParentComponent(dsPetition);
    dsPetitiondoscompanyfk.Name := 'dsPetitiondoscompanyfk';
    dsPetitiondoscompanyfk.FieldName := 'doscompanyfk';
    dsPetitiondosstatus.SetParentComponent(dsPetition);
    dsPetitiondosstatus.Name := 'dsPetitiondosstatus';
    dsPetitiondosstatus.FieldName := 'dosstatus';
    dsPetitiondosdatesent.SetParentComponent(dsPetition);
    dsPetitiondosdatesent.Name := 'dsPetitiondosdatesent';
    dsPetitiondosdatesent.FieldName := 'dosdatesent';
    dsPetitiondosclosedbyfk.SetParentComponent(dsPetition);
    dsPetitiondosclosedbyfk.Name := 'dsPetitiondosclosedbyfk';
    dsPetitiondosclosedbyfk.FieldName := 'dosclosedbyfk';
    dsPetitiondoscloseddescription.SetParentComponent(dsPetition);
    dsPetitiondoscloseddescription.Name := 'dsPetitiondoscloseddescription';
    dsPetitiondoscloseddescription.FieldName := 'doscloseddescription';
    dsPetitiondoscloseddescription.Size := 127;
    dsPetitiondosisbankrupt.SetParentComponent(dsPetition);
    dsPetitiondosisbankrupt.Name := 'dsPetitiondosisbankrupt';
    dsPetitiondosisbankrupt.FieldName := 'dosisbankrupt';
    dsPetitiondosbankrupton.SetParentComponent(dsPetition);
    dsPetitiondosbankrupton.Name := 'dsPetitiondosbankrupton';
    dsPetitiondosbankrupton.FieldName := 'dosbankrupton';
    dsPetitiondosiskbocancel.SetParentComponent(dsPetition);
    dsPetitiondosiskbocancel.Name := 'dsPetitiondosiskbocancel';
    dsPetitiondosiskbocancel.FieldName := 'dosiskbocancel';
    dsPetitiondoskbocancelon.SetParentComponent(dsPetition);
    dsPetitiondoskbocancelon.Name := 'dsPetitiondoskbocancelon';
    dsPetitiondoskbocancelon.FieldName := 'doskbocancelon';
    dsPetitiondosloginfk.SetParentComponent(dsPetition);
    dsPetitiondosloginfk.Name := 'dsPetitiondosloginfk';
    dsPetitiondosloginfk.FieldName := 'dosloginfk';
    dsPetitiondosremarks.SetParentComponent(dsPetition);
    dsPetitiondosremarks.Name := 'dsPetitiondosremarks';
    dsPetitiondosremarks.FieldName := 'dosremarks';
    dsPetitiondosremarks.BlobType := ftMemo;
    dsPetitiondosreopenedon.SetParentComponent(dsPetition);
    dsPetitiondosreopenedon.Name := 'dsPetitiondosreopenedon';
    dsPetitiondosreopenedon.FieldName := 'dosreopenedon';
    dsPetitiondosflags.SetParentComponent(dsPetition);
    dsPetitiondosflags.Name := 'dsPetitiondosflags';
    dsPetitiondosflags.FieldName := 'dosflags';
    dsPetitiondosmonthlyamount.SetParentComponent(dsPetition);
    dsPetitiondosmonthlyamount.Name := 'dsPetitiondosmonthlyamount';
    dsPetitiondosmonthlyamount.FieldName := 'dosmonthlyamount';
    dsPetitiondosmonthlyamount.Precision := 12;
    dsPetitiondosmonthlyamount.Size := 0;
    dsPetitiondosiscomplete.SetParentComponent(dsPetition);
    dsPetitiondosiscomplete.Name := 'dsPetitiondosiscomplete';
    dsPetitiondosiscomplete.FieldName := 'dosiscomplete';
    dsPetitiondoscasefilecounter.SetParentComponent(dsPetition);
    dsPetitiondoscasefilecounter.Name := 'dsPetitiondoscasefilecounter';
    dsPetitiondoscasefilecounter.FieldName := 'doscasefilecounter';
    dsPetitiondosdebtcauses.SetParentComponent(dsPetition);
    dsPetitiondosdebtcauses.Name := 'dsPetitiondosdebtcauses';
    dsPetitiondosdebtcauses.FieldName := 'dosdebtcauses';
    dsPetitiondosdebtcauses.BlobType := ftMemo;
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">STATEMENT</constant></binaryoperation></binaryoperation></where></query>';
    dsDocuments.DAOptions := [];
    dsDocuments.Left := 544;
    dsDocuments.Top := 80;
  finally
    alForm.AfterLoadDFMValues;
    dsPetition.AfterLoadDFMValues;
    dsPetitiondosid.AfterLoadDFMValues;
    dsPetitiondoscreatedon.AfterLoadDFMValues;
    dsPetitiondoscreatedbyfk.AfterLoadDFMValues;
    dsPetitiondoschangedon.AfterLoadDFMValues;
    dsPetitiondoschangedbyfk.AfterLoadDFMValues;
    dsPetitiondosclosedon.AfterLoadDFMValues;
    dsPetitiondosdescription.AfterLoadDFMValues;
    dsPetitiondoscompanyfk.AfterLoadDFMValues;
    dsPetitiondosstatus.AfterLoadDFMValues;
    dsPetitiondosdatesent.AfterLoadDFMValues;
    dsPetitiondosclosedbyfk.AfterLoadDFMValues;
    dsPetitiondoscloseddescription.AfterLoadDFMValues;
    dsPetitiondosisbankrupt.AfterLoadDFMValues;
    dsPetitiondosbankrupton.AfterLoadDFMValues;
    dsPetitiondosiskbocancel.AfterLoadDFMValues;
    dsPetitiondoskbocancelon.AfterLoadDFMValues;
    dsPetitiondosloginfk.AfterLoadDFMValues;
    dsPetitiondosremarks.AfterLoadDFMValues;
    dsPetitiondosreopenedon.AfterLoadDFMValues;
    dsPetitiondosflags.AfterLoadDFMValues;
    dsPetitiondosmonthlyamount.AfterLoadDFMValues;
    dsPetitiondosiscomplete.AfterLoadDFMValues;
    dsPetitiondoscasefilecounter.AfterLoadDFMValues;
    dsPetitiondosdebtcauses.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
  end;
end;

end.
