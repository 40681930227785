unit Forms.Messages;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, types,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset,
  pas2web.dadatasethelper, WebRouter, WEBLib.JSON;

type
  TfrmMessages = class(TfrmBase)
    dsMessages: TP2WDADataset;
    dcMessages: TP2WDatatable;
    procedure dcMessagesAfterDraw(Sender: TObject);
    procedure OnMessageInfoFormat(Sender: TObject; Fields:
        TP2WDataTableFieldMap; out aOutput: string);
    procedure OnMessageHeaderFormat(Sender: TObject; Fields:
        TP2WDataTableFieldMap; out aOutput: string);
    procedure dsMessagesAfterOpen(DataSet: TDataSet);
    procedure OnActionFormat(Sender: TObject; Fields: TP2WDataTableFieldMap; out
        aOutput: string);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FRoute : string;
    FMode : string;
    FDataLoading : Boolean;
    Procedure HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute: Boolean); override;
    function MarkAsRead(Event: TEventListenerEvent): Boolean;
    function MarkAsUnRead(Event: TEventListenerEvent): Boolean;
    function DeleteMessage(Event: TEventListenerEvent): Boolean;
    procedure LoadDataset;
    procedure setTabs(mode:string);
  public
    { Public declarations }
    Class Function MyRoutes: TStringDynArray; override;

  protected procedure LoadDFMValues; override; end;

var
  frmMessages: TfrmMessages;

implementation

{$R *.dfm}

uses
  Modules.Server, libjquery, Units.Strings, Units.ActionUtils;

procedure hideDropDownMenu(node: TJQuery);
begin
  node.removeClass('show').find('button').attr('aria-expanded', 'false');
  node.find('div.dropdown-menu').removeClass('show').attr('style', '').attr('x-placement','');

end;

procedure TfrmMessages.OnMessageInfoFormat(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);
begin
  if string(Fields.GetValueByName('msgloginfk')) = inttostr(Server.UserID) then
    aOutput := '<span class="fa fa-star mr-3 ml-1" style="color:var(--yellow);"></span>'
  else
    aOutput := '<span class="fa fa-star mr-3 ml-1" style="color:var(--gray-icon); opacity: 0.4"></span>';
  aOutput := aOutput +
    '<div class="avatar has-badge"><img src="assets/images/message-origin/origin-' +
    string(Fields.GetValueByName('msgorigin')) + '.png" alt="" style="width:32px;">';

  case string(Fields.GetValueByName('msgtype')) of
    '1' : aOutput := aOutput + '<span class="tile tile-xs tile-circle bg-red"><i class="fas fa-times"></i></span>';
    '2' : aOutput := aOutput + '<span class="tile tile-xs tile-circle bg-yellow"><i class="fas fa-exclamation"></i></span>';
    '3' : aOutput := aOutput + '<span class="tile tile-xs tile-circle bg-blue"><i class="fas fa-info"></i></span>';
  end;

  aOutput := aOutput + '</div>';
end;

procedure TfrmMessages.setTabs(mode:string);
begin
  jQuery('div.nav-tabs').find('a').removeClass('active');
  case mode of
    'user': jQuery('div.nav-tabs').find('a[href="#tab3"]').addClass('active');
    'company': jQuery('div.nav-tabs').find('a[href="#tab2"]').addClass('active');
    else jQuery('div.nav-tabs').find('a[href="#tab1"]').addClass('active');
  end;
end;

procedure TfrmMessages.OnMessageHeaderFormat(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);
var
  json : TJSONObject;
begin
  if string(Fields.GetValueByName('msgshownon')) = 'null' then
    aOutput := '<h4 class="list-group-item-title"><strong>' + string(Fields.GetValueByName('msgtitle')) + '</strong></h4>'
  else
    aOutput := '<h4 class="list-group-item-title">' + string(Fields.GetValueByName('msgtitle')) + '</h4>';
  json := TJSONObject.ParseJSONValue(string(Fields.GetValueByName('msgdata'))) as TJSONObject;
  aOutput := aOutput + '<p class="list-group-item-text">' +
    json.Values['text'].Value + '</p>';
end;

procedure TfrmMessages.dcMessagesAfterDraw(Sender: TObject);
begin
  jquery('a[role="read"]').On_('click', @MarkAsRead);
  jquery('a[role="unread"]').On_('click', @MarkAsUnRead);
  jquery('a[role="delete"]').On_('click', @DeleteMessage);
end;

function TfrmMessages.DeleteMessage(Event: TEventListenerEvent): Boolean;
var
  id : NativeInt;

  procedure OnResponse(aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
      Server.ShowError(anError)
    else
    begin
      LoadDataset;
    end;
  end;

  procedure DoDeleteMessage;
  begin
    Server.DeleteMessage(id, @OnResponse);
  end;

begin
  //confirm delete
  id := StrToIntDef(jQuery(Event.target).closest('div').attr('data-id'), -1);
  if dsMessages.Locate('msgid', id, []) then
    DefaultConfirmation.Execute('',@DoDeleteMessage,nil);
  hideDropDownMenu(jQuery(Event.target).closest('div.dropdown'));
  Result := False;
end;

procedure TfrmMessages.dsMessagesAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsMessages.GetNewRows(False);
  dcMessages.Data := aResult;
  dcMessages.RenderTranslated;
  FDataLoading := False;
end;

procedure TfrmMessages.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

function TfrmMessages.MarkAsRead(Event: TEventListenerEvent): Boolean;
var
  id : Integer;

  procedure OnResponse(aSuccess: Boolean; anError: String);
  var
    title: string;
  begin
    if not aSuccess then
      Server.ShowError(anError)
    else
    begin
      jQuery(Event.target).attr('role', 'unread')
        .text(SMarkAsUnRead)
        .off('click')
        .On_('click', @MarkAsUnread);
      title := jQuery(Event.target).closest('tr').find('h4.list-group-item-title').text;
      jQuery(Event.target).closest('tr').find('h4.list-group-item-title').text(title);
      if dsMessages.Locate('msgid', id, []) then
      begin
        dsMessages.Edit;
        dsMessages.FieldByName('msgshownon').asDateTime := now;
        dsMessages.Post;
      end;
    end;
  end;

begin
  id := StrToIntDef(jQuery(Event.target).closest('div').attr('data-id'), -1);
  if id > 0 then
    Server.SetMessageStatus(id, True, @OnResponse);
  hideDropDownMenu(jQuery(Event.target).closest('div.dropdown'));
  Result := False;
end;

function TfrmMessages.MarkAsUnRead(Event: TEventListenerEvent): Boolean;
var
  id : Integer;

  procedure OnResponse(aSuccess: Boolean; anError: String);
  var
    title: string;
  begin
    if not aSuccess then
      Server.ShowError(anError)
    else
    begin
      jQuery(Event.target).attr('role', 'read')
        .text(SMarkAsRead)
        .off('click')
        .On_('click', @MarkAsRead);
      title := jQuery(Event.target).closest('tr').find('h4.list-group-item-title').text;
      jQuery(Event.target).closest('tr').find('h4.list-group-item-title').html('<strong>' + title + '</strong>');
      if dsMessages.Locate('msgid', id, []) then
      begin
        dsMessages.Edit;
        dsMessages.FieldByName('msgshownon').Clear;
        dsMessages.Post;
      end;
    end;
  end;

begin
  id := StrToIntDef(jQuery(Event.target).closest('div').attr('data-id'), -1);
  if id > 0 then
    Server.SetMessageStatus(id, False, @OnResponse);
  hideDropDownMenu(jQuery(Event.target).closest('div.dropdown'));
  Result := False;
end;

class function TfrmMessages.MyRoutes: TStringDynArray;
begin
  Result := [
    'Messages/:MODE',
    'Messages'
  ];
end;

Procedure TfrmMessages.HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute: Boolean);

  function OnChange(Event: TEventListenerEvent): Boolean;
  begin
    LoadDataset;
    Result := False;
  end;

  function HandleClick(Event: TEventListenerEvent): Boolean;
  begin
    case jquery(Event.target).attr('href') of
      '#tab1': window.location.href := '#/Messages';
      '#tab2': window.location.href := '#/Messages/company';
      '#tab3': window.location.href := '#/Messages/user';
    end;
    Result := False;
  end;

begin
  FMode := '';
  FRoute := '#/Messages';
  if Params.indexOfName('MODE') > -1 then
    FMode := Params.Values['MODE'];
  FRoute := FRoute + '/' + FMode;
  LoadDataset;
  setTabs(FMode);

  jQuery('#startDate').On_('change', @OnChange);
  jQuery('#endDate').On_('change', @OnChange);
  jQuery('#ckbPersonal').On_('change', @OnChange);
  jQuery('#ckbUnread').On_('change', @OnChange);

  jQuery('div.nav-tabs').find('a').On_('click', @HandleClick);
end;


procedure TfrmMessages.LoadDataset;

  procedure dsMessagesAfterClose(DataSet: TDataSet);
  var
    xmlQuery, query : string;
    counter : Integer;
  begin
    if FDataLoading then
      Exit;
    FDataLoading := True;
    dsMessages.Params.Clear;
    xmlQuery := '<?xml version="1.0"?>' +
      '<query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0">' +
      '<where>';
    query := '';
    case FMode  of
      'user':
        begin
          with dsMessages.Params.Add do
          begin
            DataType := ftLargeInt;
            Name := 'UserID';
            ParamType := ptInput;
          end;
          query := '<binaryoperation operator="Equal">' +
            '<field>msgloginfk</field>' +
            '<parameter type="LargeInt">UserID</parameter>' +
            '</binaryoperation>';
          dsMessages.ParamByName('UserID').asInteger := Server.UserId;
          inc(counter);
        end;
      'company':
        begin
          with dsMessages.Params.Add do
          begin
            DataType := ftLargeInt;
            Name := 'UserID';
            ParamType := ptInput;
          end;
          query := '<binaryoperation operator="NotEqual">' +
            '<field>msgloginfk</field>' +
            '<parameter type="LargeInt">UserID</parameter>' +
            '</binaryoperation>';
          dsMessages.ParamByName('UserID').asInteger := Server.UserId;
          inc(counter);
        end;
    end;
    if alForm['startDate'].Value <> '' then
    begin
      with dsMessages.Params.Add do
      begin
        DataType := ftDateTime;
        Name := 'startDate';
        ParamType := ptInput;
      end;
      query := query + '<binaryoperation operator="GreaterOrEqual">' +
            '<field>msgcreatedon</field>' +
            '<parameter type="DateTime">startDate</parameter>' +
            '</binaryoperation>';
      if counter > 0 then
        query := '<binaryoperation operator="And">' + query + '</binaryoperation>';
      dsMessages.ParamByName('startDate').asDateTime := ValidityFromToDate(alForm['startDate'].Value, True);
      inc(counter);
    end;
    if alForm['endDate'].Value <> '' then
    begin
      with dsMessages.Params.Add do
      begin
        DataType := ftDateTime;
        Name := 'endDate';
        ParamType := ptInput;
      end;
      query := query + '<binaryoperation operator="LessOrEqual">' +
            '<field>msgcreatedon</field>' +
            '<parameter type="DateTime">endDate</parameter>' +
            '</binaryoperation>';
      if counter > 0 then
        query := '<binaryoperation operator="And">' + query + '</binaryoperation>';
      dsMessages.ParamByName('endDate').asDateTime := ValidityFromToDate(alForm['endDate'].Value, false);
      inc(counter);
    end;

    if isCheckBoxChecked('ckbUnread') then
    begin
      query := query + '<binaryoperation operator="Equal">' +
        '<field>msgshownon</field>' +
        '<null/>' +
        '</binaryoperation>';
      if counter > 0 then
        query := '<binaryoperation operator="And">' + query + '</binaryoperation>';
      inc(counter);
    end;

    xmlQuery := xmlQuery + query + '</where></query>';

    if counter > 0 then
      dsMessages.WhereClause := xmlQuery
    else
      dsMessages.WhereClause := '';

    dsMessages.Load([], nil);
  end;

begin
  if dsMessages.State = dsInactive then
    dsMessagesAfterClose(dsMessages)
  else
  begin
    dsMessages.AfterClose := dsMessagesAfterClose;
    dsMessages.Close;
  end;
end;

procedure TfrmMessages.OnActionFormat(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);
var
  json : TJSONObject;
begin
  json := TJSONObject.ParseJSONValue(string(Fields.GetValueByName('msgdata'))) as TJSONObject;

  aOutput := '<div class="dropdown">' +
    '<button class="btn btn-sm btn-icon btn-secondary" data-toggle="dropdown" aria-expanded="false">' +
      '<i class="fa fa-ellipsis-h"></i>' +
    '</button>' +
    '<div class="dropdown-menu dropdown-menu-right" style="" data-id="' + string(Fields.GetValueByName('msgid')) + '">' +
        '<div class="dropdown-arrow mr-n1"></div>';
  if string(Fields.GetValueByName('msgshownon')) = 'null' then
    aOutput := aOutput + '<a href="#" role="read" class="dropdown-item">' + SMarkAsRead + '</a>'
  else
    aOutput := aOutput + '<a href="#" role="unread" class="dropdown-item">' + SMarkAsUnRead + '</a>';
  aOutput := aOutput + '<a href="#" role="delete" class="dropdown-item">' + SDelete + '</a>';
  if json.Values['route'] <> nil then
       aOutput := aOutput + '<a href="#' + json.Values['route'].Value + '" class="dropdown-item">' + json.Values['routeText'].Value + '</a>';
  aOutput := aOutput + '</div></div>';
end;

procedure TfrmMessages.WebFormCreate(Sender: TObject);
begin
  inherited;
  FMode := '';
  FDataLoading := False;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsMessages.DAConnection := Server.CompanyConnection;

end;

procedure TfrmMessages.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsMessages := TP2WDADataset.Create(Self);
  dcMessages := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsMessages.BeforeLoadDFMValues;
  dcMessages.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'startDate';
      Name := 'startDate';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'endDate';
      Name := 'endDate';
      PreventDefault := False;
      StopPropagation := False;
    end;
    dsMessages.SetParentComponent(Self);
    dsMessages.Name := 'dsMessages';
    dsMessages.TableName := 'systemmessages';
    dsMessages.DAOptions := [];
    dsMessages.AfterOpen := dsMessagesAfterOpen;
    dsMessages.Left := 40;
    dsMessages.Top := 80;
    dcMessages.SetParentComponent(Self);
    dcMessages.Name := 'dcMessages';
    dcMessages.Columns.Clear;
    with dcMessages.Columns.Add do
    begin
      FieldName := 'msgloginfk';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnMessageInfoFormat');
      Width := '95px';
      CSSClassName := 'text-nowrap mxw-10';
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcMessages.Columns.Add do
    begin
      FieldName := 'msgid';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnMessageHeaderFormat');
      Width := 'auto';
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcMessages.Columns.Add do
    begin
      FieldName := 'msgtype';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      SetEvent(Self, 'OnGetValue', 'OnActionFormat');
      Width := '40px';
      CSSClassName := 'mxw-10 text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    dcMessages.DataSet := dsMessages;
    dcMessages.Language := lEnglish;
    dcMessages.IsResponsive := False;
    dcMessages.GridID := 'grdMessages';
    dcMessages.UseFieldIndex := True;
    dcMessages.ShowSearch := False;
    dcMessages.ShowNumberOfEntries := False;
    dcMessages.ShowEntriesInfo := False;
    dcMessages.Paginate := True;
    dcMessages.DisplayReadOnly := False;
    dcMessages.CalculateTableWidth := True;
    dcMessages.AfterDraw := dcMessagesAfterDraw;
    dcMessages.Left := 32;
    dcMessages.Top := 144;
  finally
    alForm.AfterLoadDFMValues;
    dsMessages.AfterLoadDFMValues;
    dcMessages.AfterLoadDFMValues;
  end;
end;

end.