unit Forms.Possession;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  pas2web.datatables,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dadatasethelper,
  Forms.DossierDetail,
  pas2web.bootstrap;

type
  TfrmBase = TfrmDossierDetail;

  TfrmPossession = class(TfrmBase)
    dsPosession: TP2WDADataset;
    dsPosessionproid: TLargeintField;
    dsPosessionprocreatedon: TDateTimeField;
    dsPosessionprocreatedbyfk: TLargeintField;
    dsPosessionprochangedon: TDateTimeField;
    dsPosessionprochangedbyfk: TLargeintField;
    dsPosessionprodossierfk: TLargeintField;
    dsPosessionpropropertytype: TStringField;
    dsPosessionpropropertytypefk: TLargeintField;
    dsPosessionproowner: TStringField;
    dsPosessionproownerfk: TLargeintField;
    dsPosessionproisrealestate: TBooleanField;
    dsPosessionproshortdescription: TStringField;
    dsPosessionprodescription: TBlobField;
    dsPosessionprocount: TIntegerField;
    dsPosessionpropurchasedon: TDateTimeField;
    dsPosessionpronotaryname: TStringField;
    dsPosessionpropurchaseprice: TFMTBCDField;
    dsPosessionprocurrentvalue: TFMTBCDField;
    dsPosessionproisfinanced: TBooleanField;
    dsPosessionproisseized: TBooleanField;
    dsPosessionproisshared: TBooleanField;
    dsPosessionproisgone: TBooleanField;
    dsPosessionprogoneon: TDateTimeField;
    dsPosessionprobuyerfk: TLargeintField;
    dsPosessionproiscomplete: TBooleanField;
    dsPosessionproremark: TStringField;
    dcPosession: TP2WDatatable;
    dsPosessionprofinancing: TWideStringField;
    bmFastEntry: TP2WBSModal;
    procedure OnPossessionOwnerGetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure dsPosessionAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsPosessionAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure btnFastAddClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure ModalActionsActions2Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnPossessionValueChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }
    FDoDelete: Boolean;
    FDeleteID: NativeInt;
    FRoute: string;

    procedure ConfirmDeletePossession;
    function GetTableBodyHTML: string;
    function GetSelectedPossessions: Tarray<string>;
    procedure CreatePossessions(Possessions: Tarray<string>);
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDelete(const possessionId: NativeInt);
    procedure MakeReadonly; override;
  protected procedure LoadDFMValues; override; end;

var
  frmPossession: TfrmPossession;

implementation

{$R *.dfm}

uses
  libjquery,
  lib.bootstrap,
  rosdk,
  Units.Service.Dezq,
  Units.Params,
  Modules.Server,
  Units.DADatasetUtils,
  Units.Strings,
  lib.formtranslation;

{ TfrmPossession }
function FindParam(aTypes: TDezqParameterArray; aName: string): Integer;

begin
  Result := Length(aTypes) - 1;
  while (Result >= 0) and (aTypes[Result].Name <> aName) do
    Dec(Result);
end;

function TfrmPossession.GetTableBodyHTML: string;
const
  SRowHTML = '<tr>' + '<td class="fastentryrow fastentrytext">%s</td>' + '<td class="fastentryrow fastentrytext">%s</td>' + '<td class="fastentryrow fastentrycount">' +
    '  <input class="form-control fastentryinputcount" data-type="%s" type="number" value="0" min="0" step="0.01">' + '</td>' + '</tr>' + #10;
var
  aList, aTypes: TDezqParameterArray;
  P: TDezqParameter;
  Idx: Integer;
  aType: string;
begin
  Result := '';
  aTypes := dmServer.GetParamList('PROPTYP');
  aList := dmServer.GetParamList('PROPFAST');
  for P in aList do
  begin
    Idx := FindParam(aTypes, P.Extra);
    if Idx >= 0 then
      aType := aTypes[Idx].Value
    else
      aType := P.Extra;
    Result := Result + Format(SRowHTML, [P.Value, aType, P.Name]);
  end;
end;

function TfrmPossession.GetSelectedPossessions: Tarray<string>;
var
  El: TJSElement;
  Inp: TJSHTMLInputElement;
  aType: string;
  Possessions: Tarray<string>;
  aValue, aCount: Integer;
begin
  SetLength(Possessions, Length(bmFastEntry.Actions['inputs'].ElementHandles));
  aCount := 0;
  for El in bmFastEntry.Actions['inputs'].ElementHandles do
  begin
    Inp := TJSHTMLInputElement(El);
    aType := string(Inp.DataSet['type']);
    aValue := StrToIntDef(Inp.Value, 0);
    if (aValue > 0) and (aType <> '') then
    begin
      Possessions[aCount] := aType + '=' + IntToStr(aValue);
      Inc(aCount);
    end;
  end;
  SetLength(Possessions, aCount);
  Result := Possessions;
end;

procedure TfrmPossession.CreatePossessions(Possessions: Tarray<string>);

  procedure DoOK(aResult: NativeInt);
  begin
    dmServer.ShowOK(Format(SCountPossessionsCreated, [aResult]));
    dsPosession.Close;
    dsPosession.Load([], nil);
  end;

  procedure DoFail(aObject: TJSObject; aFail: TJSError);
  begin
    dmServer.ShowError(ExtractErrorMsg(aFail, SErrCannotSaveUser));
  end;

var
  arr: TROStringArray;
begin
  arr := TROStringArray.new;
  arr.FromArray(TJSArray(Possessions));
  dmServer.PetitionService.FastAddPossessions(DossierID, 'EIGEN', arr, @DoOK, @DoFail);
end;

procedure TfrmPossession.MakeReadonly;
begin
  inherited;
  jQuery('#btnFastAdd').prop('href', 'javascript:void(0)').addClass('disabled');
end;

procedure TfrmPossession.ModalActionsActions2Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  Possessions: Tarray<string>;
begin
  inherited;
  Possessions := GetSelectedPossessions;
  if Length(Possessions) = 0 then
    dmServer.ShowOK(SErrNoItemsSelectedNoPossessions)
  else
    CreatePossessions(Possessions);
end;

procedure TfrmPossession.btnFastAddClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  TableBodyHtml: string;
begin
  inherited;
  TableBodyHtml := GetTableBodyHTML;
  bmFastEntry.Show;
  FormTranslator.TranslateBelow(TJSHTMLElement(bmFastEntry.Actions['mdlFastAddPossessions'].ElementHandle), 'index');
  bmFastEntry.Actions['tblBodyFastPosessions'].ElementHandle.InnerHTML := TableBodyHtml;
  bmFastEntry.Actions['inputs'].Bind;
end;

procedure TfrmPossession.ConfirmDeletePossession;

  procedure DoDeleteRecord;
  begin
    if dsPosession.Locate('proid', FDeleteID, []) then
    begin
      dsPosession.Delete;
      dsPosession.ApplyUpdates;
    end;
  end;

  procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;

begin
  FDoDelete := False;
  if Locate(dsPosession, 'proid', FDeleteID) then
    DefaultConfirmation.Execute('', @DoDeleteRecord, @DoCancelDelete);
end;

procedure TfrmPossession.OnPossessionOwnerGetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('PROPTYP', string(Fields.GetValueByName('propropertytype')));
end;

procedure TfrmPossession.DoDelete(const possessionId: NativeInt);
begin
  FDeleteID := possessionId;
  if dsPosession.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeletePossession;
end;

procedure TfrmPossession.dsPosessionAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  CheckResolveResults(Info, FRoute);
end;

procedure TfrmPossession.dsPosessionAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsPosession.GetNewRows(False);
  dcPosession.Data := aResult;
  dcPosession.RenderTranslated;
  if FDoDelete then
    ConfirmDeletePossession;
end;

procedure TfrmPossession.OnPossessionValueChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if StrToIntDef(string(jQuery(Element.Element).val), -1) < 0 then
    jQuery(Element.Element).val('0');
end;

procedure TfrmPossession.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDoDelete := False;
  FDeleteID := -1;
  dsPosession.DAConnection := DossierConnection;
end;

procedure TfrmPossession.setParams(const Params: TStrings);
begin
  inherited;
  FRoute := DossierURL('/possession');
  jQuery('#addProperty').attr('href', FRoute + '/new');
  dsPosession.ParamByName('DossierID').asLargeInt := DossierID;
end;

procedure TfrmPossession.WebFormShow(Sender: TObject);
begin
  inherited;
  dsPosession.Load([], nil);
end;

procedure TfrmPossession.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsPosession := TP2WDADataset.Create(Self);
  dsPosessionproid := TLargeintField.Create(Self);
  dsPosessionprocreatedon := TDateTimeField.Create(Self);
  dsPosessionprocreatedbyfk := TLargeintField.Create(Self);
  dsPosessionprochangedon := TDateTimeField.Create(Self);
  dsPosessionprochangedbyfk := TLargeintField.Create(Self);
  dsPosessionprodossierfk := TLargeintField.Create(Self);
  dsPosessionpropropertytype := TStringField.Create(Self);
  dsPosessionpropropertytypefk := TLargeintField.Create(Self);
  dsPosessionproowner := TStringField.Create(Self);
  dsPosessionproownerfk := TLargeintField.Create(Self);
  dsPosessionproisrealestate := TBooleanField.Create(Self);
  dsPosessionproshortdescription := TStringField.Create(Self);
  dsPosessionprodescription := TBlobField.Create(Self);
  dsPosessionprocount := TIntegerField.Create(Self);
  dsPosessionpropurchasedon := TDateTimeField.Create(Self);
  dsPosessionpronotaryname := TStringField.Create(Self);
  dsPosessionpropurchaseprice := TFMTBCDField.Create(Self);
  dsPosessionprocurrentvalue := TFMTBCDField.Create(Self);
  dsPosessionproisfinanced := TBooleanField.Create(Self);
  dsPosessionproisseized := TBooleanField.Create(Self);
  dsPosessionproisshared := TBooleanField.Create(Self);
  dsPosessionproisgone := TBooleanField.Create(Self);
  dsPosessionprogoneon := TDateTimeField.Create(Self);
  dsPosessionprobuyerfk := TLargeintField.Create(Self);
  dsPosessionproiscomplete := TBooleanField.Create(Self);
  dsPosessionproremark := TStringField.Create(Self);
  dsPosessionprofinancing := TWideStringField.Create(Self);
  dcPosession := TP2WDatatable.Create(Self);
  bmFastEntry := TP2WBSModal.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsPosession.BeforeLoadDFMValues;
  dsPosessionproid.BeforeLoadDFMValues;
  dsPosessionprocreatedon.BeforeLoadDFMValues;
  dsPosessionprocreatedbyfk.BeforeLoadDFMValues;
  dsPosessionprochangedon.BeforeLoadDFMValues;
  dsPosessionprochangedbyfk.BeforeLoadDFMValues;
  dsPosessionprodossierfk.BeforeLoadDFMValues;
  dsPosessionpropropertytype.BeforeLoadDFMValues;
  dsPosessionpropropertytypefk.BeforeLoadDFMValues;
  dsPosessionproowner.BeforeLoadDFMValues;
  dsPosessionproownerfk.BeforeLoadDFMValues;
  dsPosessionproisrealestate.BeforeLoadDFMValues;
  dsPosessionproshortdescription.BeforeLoadDFMValues;
  dsPosessionprodescription.BeforeLoadDFMValues;
  dsPosessionprocount.BeforeLoadDFMValues;
  dsPosessionpropurchasedon.BeforeLoadDFMValues;
  dsPosessionpronotaryname.BeforeLoadDFMValues;
  dsPosessionpropurchaseprice.BeforeLoadDFMValues;
  dsPosessionprocurrentvalue.BeforeLoadDFMValues;
  dsPosessionproisfinanced.BeforeLoadDFMValues;
  dsPosessionproisseized.BeforeLoadDFMValues;
  dsPosessionproisshared.BeforeLoadDFMValues;
  dsPosessionproisgone.BeforeLoadDFMValues;
  dsPosessionprogoneon.BeforeLoadDFMValues;
  dsPosessionprobuyerfk.BeforeLoadDFMValues;
  dsPosessionproiscomplete.BeforeLoadDFMValues;
  dsPosessionproremark.BeforeLoadDFMValues;
  dsPosessionprofinancing.BeforeLoadDFMValues;
  dcPosession.BeforeLoadDFMValues;
  bmFastEntry.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
      PreventDefault := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'btnNo';
      PreventDefault := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnFastAdd';
      Name := 'btnFastAdd';
      SetEvent(Self, 'OnExecute', 'btnFastAddClick');
    end;
    dsPosession.SetParentComponent(Self);
    dsPosession.Name := 'dsPosession';
    dsPosession.TableName := 'property';
    dsPosession.Params.Clear;
    with dsPosession.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DossierID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsPosession.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>prodossierfk</field><parameter type="LargeInt">DossierID</parameter></binaryoperation><binaryoperation operator="NotEqual"><field>propropertytype</field><constant type="String" null="">ONRG</constant></binaryoperation></binaryoperation></where></query>';
    dsPosession.DAOptions := [];
    dsPosession.AfterOpen := dsPosessionAfterOpen;
    dsPosession.AfterApplyUpdates := dsPosessionAfterApplyUpdates;
    dsPosession.Left := 40;
    dsPosession.Top := 80;
    dsPosessionproid.SetParentComponent(dsPosession);
    dsPosessionproid.Name := 'dsPosessionproid';
    dsPosessionproid.FieldName := 'proid';
    dsPosessionprocreatedon.SetParentComponent(dsPosession);
    dsPosessionprocreatedon.Name := 'dsPosessionprocreatedon';
    dsPosessionprocreatedon.FieldName := 'procreatedon';
    dsPosessionprocreatedbyfk.SetParentComponent(dsPosession);
    dsPosessionprocreatedbyfk.Name := 'dsPosessionprocreatedbyfk';
    dsPosessionprocreatedbyfk.FieldName := 'procreatedbyfk';
    dsPosessionprochangedon.SetParentComponent(dsPosession);
    dsPosessionprochangedon.Name := 'dsPosessionprochangedon';
    dsPosessionprochangedon.FieldName := 'prochangedon';
    dsPosessionprochangedbyfk.SetParentComponent(dsPosession);
    dsPosessionprochangedbyfk.Name := 'dsPosessionprochangedbyfk';
    dsPosessionprochangedbyfk.FieldName := 'prochangedbyfk';
    dsPosessionprodossierfk.SetParentComponent(dsPosession);
    dsPosessionprodossierfk.Name := 'dsPosessionprodossierfk';
    dsPosessionprodossierfk.FieldName := 'prodossierfk';
    dsPosessionpropropertytype.SetParentComponent(dsPosession);
    dsPosessionpropropertytype.Name := 'dsPosessionpropropertytype';
    dsPosessionpropropertytype.FieldName := 'propropertytype';
    dsPosessionpropropertytype.Size := 10;
    dsPosessionpropropertytypefk.SetParentComponent(dsPosession);
    dsPosessionpropropertytypefk.Name := 'dsPosessionpropropertytypefk';
    dsPosessionpropropertytypefk.FieldName := 'propropertytypefk';
    dsPosessionproowner.SetParentComponent(dsPosession);
    dsPosessionproowner.Name := 'dsPosessionproowner';
    dsPosessionproowner.FieldName := 'proowner';
    dsPosessionproowner.Size := 10;
    dsPosessionproownerfk.SetParentComponent(dsPosession);
    dsPosessionproownerfk.Name := 'dsPosessionproownerfk';
    dsPosessionproownerfk.FieldName := 'proownerfk';
    dsPosessionproisrealestate.SetParentComponent(dsPosession);
    dsPosessionproisrealestate.Name := 'dsPosessionproisrealestate';
    dsPosessionproisrealestate.FieldName := 'proisrealestate';
    dsPosessionproshortdescription.SetParentComponent(dsPosession);
    dsPosessionproshortdescription.Name := 'dsPosessionproshortdescription';
    dsPosessionproshortdescription.FieldName := 'proshortdescription';
    dsPosessionproshortdescription.Size := 127;
    dsPosessionprodescription.SetParentComponent(dsPosession);
    dsPosessionprodescription.Name := 'dsPosessionprodescription';
    dsPosessionprodescription.FieldName := 'prodescription';
    dsPosessionprocount.SetParentComponent(dsPosession);
    dsPosessionprocount.Name := 'dsPosessionprocount';
    dsPosessionprocount.FieldName := 'procount';
    dsPosessionpropurchasedon.SetParentComponent(dsPosession);
    dsPosessionpropurchasedon.Name := 'dsPosessionpropurchasedon';
    dsPosessionpropurchasedon.FieldName := 'propurchasedon';
    dsPosessionpronotaryname.SetParentComponent(dsPosession);
    dsPosessionpronotaryname.Name := 'dsPosessionpronotaryname';
    dsPosessionpronotaryname.FieldName := 'pronotaryname';
    dsPosessionpronotaryname.Size := 100;
    dsPosessionpropurchaseprice.SetParentComponent(dsPosession);
    dsPosessionpropurchaseprice.Name := 'dsPosessionpropurchaseprice';
    dsPosessionpropurchaseprice.FieldName := 'propurchaseprice';
    dsPosessionpropurchaseprice.Precision := 12;
    dsPosessionpropurchaseprice.Size := 0;
    dsPosessionprocurrentvalue.SetParentComponent(dsPosession);
    dsPosessionprocurrentvalue.Name := 'dsPosessionprocurrentvalue';
    dsPosessionprocurrentvalue.FieldName := 'procurrentvalue';
    dsPosessionprocurrentvalue.Precision := 12;
    dsPosessionprocurrentvalue.Size := 0;
    dsPosessionproisfinanced.SetParentComponent(dsPosession);
    dsPosessionproisfinanced.Name := 'dsPosessionproisfinanced';
    dsPosessionproisfinanced.FieldName := 'proisfinanced';
    dsPosessionproisseized.SetParentComponent(dsPosession);
    dsPosessionproisseized.Name := 'dsPosessionproisseized';
    dsPosessionproisseized.FieldName := 'proisseized';
    dsPosessionproisshared.SetParentComponent(dsPosession);
    dsPosessionproisshared.Name := 'dsPosessionproisshared';
    dsPosessionproisshared.FieldName := 'proisshared';
    dsPosessionproisgone.SetParentComponent(dsPosession);
    dsPosessionproisgone.Name := 'dsPosessionproisgone';
    dsPosessionproisgone.FieldName := 'proisgone';
    dsPosessionprogoneon.SetParentComponent(dsPosession);
    dsPosessionprogoneon.Name := 'dsPosessionprogoneon';
    dsPosessionprogoneon.FieldName := 'progoneon';
    dsPosessionprobuyerfk.SetParentComponent(dsPosession);
    dsPosessionprobuyerfk.Name := 'dsPosessionprobuyerfk';
    dsPosessionprobuyerfk.FieldName := 'probuyerfk';
    dsPosessionproiscomplete.SetParentComponent(dsPosession);
    dsPosessionproiscomplete.Name := 'dsPosessionproiscomplete';
    dsPosessionproiscomplete.FieldName := 'proiscomplete';
    dsPosessionproremark.SetParentComponent(dsPosession);
    dsPosessionproremark.Name := 'dsPosessionproremark';
    dsPosessionproremark.FieldName := 'proremark';
    dsPosessionproremark.Size := 255;
    dsPosessionprofinancing.SetParentComponent(dsPosession);
    dsPosessionprofinancing.Name := 'dsPosessionprofinancing';
    dsPosessionprofinancing.FieldName := 'profinancing';
    dsPosessionprofinancing.Size := 255;
    dcPosession.SetParentComponent(Self);
    dcPosession.Name := 'dcPosession';
    dcPosession.Columns.Clear;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'proshortdescription';
      Title := 'Description';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'procount';
      Title := 'Count';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'procurrentvalue';
      Title := 'Current value';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '##0.00';
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'propropertytype';
      Title := 'Type';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnPossessionOwnerGetValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'proiscomplete';
      Title := 'Is Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'prodossierfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{prodossierfk}}/possession/edit/{{proid}}';
    end;
    with dcPosession.Columns.Add do
    begin
      FieldName := 'proid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{prodossierfk}}/possession/delete/{{proid}}';
    end;
    dcPosession.DataSet := dsPosession;
    dcPosession.Language := lEnglish;
    dcPosession.IsResponsive := True;
    dcPosession.GridID := 'grdPosession';
    dcPosession.UseFieldIndex := True;
    dcPosession.ShowSearch := False;
    dcPosession.ShowNumberOfEntries := False;
    dcPosession.ShowEntriesInfo := False;
    dcPosession.Paginate := True;
    dcPosession.DisplayReadOnly := False;
    dcPosession.CalculateTableWidth := True;
    dcPosession.Left := 48;
    dcPosession.Top := 152;
    bmFastEntry.SetParentComponent(Self);
    bmFastEntry.Name := 'bmFastEntry';
    bmFastEntry.Actions.Actions.Clear;
    with bmFastEntry.Actions.Actions.Add do
    begin
      ID := 'tblBodyFastPosessions';
      Name := 'tblBodyFastPosessions';
    end;
    with bmFastEntry.Actions.Actions.Add do
    begin
      Event := heChange;
      ID := '';
      Name := 'inputs';
      Selector := '#mdlFastAddPossessions input.fastentryinputcount';
      SetEvent(Self, 'OnExecute', 'OnPossessionValueChange');
    end;
    with bmFastEntry.Actions.Actions.Add do
    begin
      ID := 'btnFastAddOK';
      Name := 'btnFastAddOK';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'ModalActionsActions2Execute');
    end;
    with bmFastEntry.Actions.Actions.Add do
    begin
      ID := 'btnFastAddCancel';
      Name := 'btnFastAddCancel';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with bmFastEntry.Actions.Actions.Add do
    begin
      Event := heNone;
      ID := 'mdlFastAddPossessions';
      Name := 'mdlFastAddPossessions';
    end;
    bmFastEntry.ParentID := 'modals';
    bmFastEntry.BackDrop := True;
    bmFastEntry.KeyBoard := True;
    bmFastEntry.Focus := True;
    bmFastEntry.OKButtonName := 'btnAddPossessions';
    bmFastEntry.TemplateName := 'fastaddpossessions';
    bmFastEntry.Left := 152;
    bmFastEntry.Top := 88;
  finally
    alForm.AfterLoadDFMValues;
    dsPosession.AfterLoadDFMValues;
    dsPosessionproid.AfterLoadDFMValues;
    dsPosessionprocreatedon.AfterLoadDFMValues;
    dsPosessionprocreatedbyfk.AfterLoadDFMValues;
    dsPosessionprochangedon.AfterLoadDFMValues;
    dsPosessionprochangedbyfk.AfterLoadDFMValues;
    dsPosessionprodossierfk.AfterLoadDFMValues;
    dsPosessionpropropertytype.AfterLoadDFMValues;
    dsPosessionpropropertytypefk.AfterLoadDFMValues;
    dsPosessionproowner.AfterLoadDFMValues;
    dsPosessionproownerfk.AfterLoadDFMValues;
    dsPosessionproisrealestate.AfterLoadDFMValues;
    dsPosessionproshortdescription.AfterLoadDFMValues;
    dsPosessionprodescription.AfterLoadDFMValues;
    dsPosessionprocount.AfterLoadDFMValues;
    dsPosessionpropurchasedon.AfterLoadDFMValues;
    dsPosessionpronotaryname.AfterLoadDFMValues;
    dsPosessionpropurchaseprice.AfterLoadDFMValues;
    dsPosessionprocurrentvalue.AfterLoadDFMValues;
    dsPosessionproisfinanced.AfterLoadDFMValues;
    dsPosessionproisseized.AfterLoadDFMValues;
    dsPosessionproisshared.AfterLoadDFMValues;
    dsPosessionproisgone.AfterLoadDFMValues;
    dsPosessionprogoneon.AfterLoadDFMValues;
    dsPosessionprobuyerfk.AfterLoadDFMValues;
    dsPosessionproiscomplete.AfterLoadDFMValues;
    dsPosessionproremark.AfterLoadDFMValues;
    dsPosessionprofinancing.AfterLoadDFMValues;
    dcPosession.AfterLoadDFMValues;
    bmFastEntry.AfterLoadDFMValues;
  end;
end;

end.
