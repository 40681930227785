unit Forms.CSR.Files;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Files, Data.DB, Datasnap.DBClient,
  pas2web.dadataset, pas2web.datatables, WEBLib.Actions;

type
  TfrmCSRFiles = class(TfrmFiles)
    procedure OnGetDocType(Sender: TObject; Fields: TP2WDataTableFieldMap; out
        aOutput: string);
    procedure OnGetSourceType(Sender: TObject; Fields: TP2WDataTableFieldMap; out
        aOutput: string);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
    class function GetHTMLFileName: string; override;
    function DossierPrefix : string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRFiles: TfrmCSRFiles;

implementation

{$R *.dfm}

function TfrmCSRFiles.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRFiles.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRFiles.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRFiles.OnGetDocType(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);
begin
  inherited OnGetDocType(Sender, Fields, aOutput);
end;

procedure TfrmCSRFiles.OnGetSourceType(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);
begin
  inherited OnGetSourceType(Sender, Fields, aOutput);
end;

procedure TfrmCSRFiles.WebFormCreate(Sender: TObject);
begin
  inherited;
  dcFiles.Columns[6].ButtonURL:='#/CSR/{{dofdossierfk}}/file/edit/{{dofid}}';
  dcFiles.Columns[7].ButtonURL:='#/CSR/{{dofdossierfk}}/file/delete/{{dofid}}';
end;

procedure TfrmCSRFiles.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcFiles.BeforeLoadDFMValues;
  try
    dcFiles.Columns.Clear;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'doffilename';
      Title := 'Creditor';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofmime';
      Title := 'Debt Type';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofcreatedon';
      Title := 'Start On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofchangedon';
      Title := 'Original Amount';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofdoctype';
      Title := 'Current Amount';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGetDocType');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofsourcetype';
      Title := 'Guarantor';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGetSourceType');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofid';
      Title := 'Is Complete';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{dofdossierfk}}/file/edit/{{dofid}}';
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{dofdossierfk}}/file/delete/{{dofid}}';
    end;
    with dcFiles.Columns.Add do
    begin
      FieldName := 'dofdossierfk';
      RenderMode := rmButton;
      ButtonType := btCustom;
      ButtonIconClass := 'fas fa-info';
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
  finally
    dcFiles.AfterLoadDFMValues;
  end;
end;

end.
