unit Forms.CSR.SettlementPlan.New;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  Data.DB, Datasnap.DBClient, pas2web.dadataset;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmSetlementPlanNew = class(TfrmBase)
    dsSettlements: TP2WDADataset;
    dsPetitioner: TP2WDADataset;
    dsBudgetPlan: TP2WDADataset;
    procedure dsBudgetPlanAfterOpen(DataSet: TDataSet);
    procedure dsPetitionerAfterOpen(DataSet: TDataSet);
    procedure dsSettlementsAfterOpen(DataSet: TDataSet);
    procedure onBtnCreateClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure onBtnNextClick(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure onPreviousClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FCurrentStep: Integer;
    function OnPlanTypeSelection(Evt: TEventListenerEvent): Boolean;
    function OnBudgetPlanChanged(Evt: TEventListenerEvent): Boolean;
    function OnPetitionerSelected(Evt: TEventListenerEvent): Boolean;
    function OnTemplateTypeSelected(Evt: TEventListenerEvent): Boolean;
    function OnPlanToCopySelected(Evt: TEventListenerEvent): Boolean;
    function validateStepOne : boolean;
    function validateStepTwo : boolean;
    function validateStepThree : boolean;
    procedure onGetPlanCatItem(RecordID: Integer; Name: string;
      Language: Integer; ParamType, Value: string; ValueType: integer;
      Extra: string; isLast: Boolean);
    procedure ShowSummary;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmSetlementPlanNew: TfrmSetlementPlanNew;

implementation

{$R *.dfm}

uses
  libjquery, dateutils, Units.ActionUtils, Units.HTMLUtils, Modules.Server,
  Units.Service.Dezq, Units.Strings;

function TfrmSetlementPlanNew.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmSetlementPlanNew.dsBudgetPlanAfterOpen(DataSet: TDataSet);
var
  html : string;
begin
  if not DataSet.isEmpty then
  begin
    html := '';
    while not DataSet.eof do
    begin
      html := html + format('<option value="%s">%s</option>', [
        DataSet.FieldByName('bplid').asString,
        DataSet.FieldByName('bplvalidfrom').asString + ' - ' +
        DataSet.FieldByName('bplvalidtill').asString + ' - ' +
        DataSet.FieldByName('bplname').asString
      ]);
      DataSet.Next;
    end;
    jQuery('#rbCopyPlan').prop('disabled', false);
    jQuery('#selBudgetPlan').html(html);
  end;
end;

procedure TfrmSetlementPlanNew.dsPetitionerAfterOpen(DataSet: TDataSet);
var
  html : string;
begin
  if not DataSet.isEmpty then
  begin
    html := '<option value="-1"></option>';
    while not DataSet.eof do
    begin
      html := html + format('<option value="%s">%s</option>', [
        DataSet.FieldByName('dosid').asString,
        DataSet.FieldByName('dosdescription').asString
      ]);
      DataSet.Next;
    end;
    jQuery('#rbCopyPlan').prop('disabled', false);
    jQuery('#selDossier').html(html);
  end;
end;

procedure TfrmSetlementPlanNew.dsSettlementsAfterOpen(DataSet: TDataSet);
var
  html : string;
begin
  html := '';
  if not DataSet.isEmpty then
  begin
    while not DataSet.eof do
    begin
      html := html + format('<option value="%s">%s</option>', [
        DataSet.FieldByName('sepid').asString,
        DataSet.FieldByName('sepvalidfrom').asString + ' - ' +
        DataSet.FieldByName('sepvalidtill').asString + ' - ' +
        DataSet.FieldByName('sepdescription').asString
      ]);
      DataSet.Next;
    end;
    jQuery('#rbCopy').prop('disabled', false);
    jQuery('#selPlan').html(html);
  end;
end;

procedure TfrmSetlementPlanNew.onBtnCreateClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
var
  options: TSettlementPlanCreateOptions;
  budgetPlanMonthTemplate: TBudgetPlanMonthEntryArray;

  function collectItems(aIndex: Integer; AElement: TJSElement): Boolean;
  var
    amount: double;
  begin
    amount := StrToFloat(string(jQuery(AElement).closest('tr').find('td:last').find('input').val));

    SetLength(budgetPlanMonthTemplate, Length(budgetPlanMonthTemplate) + 1);

    budgetPlanMonthTemplate[Length(budgetPlanMonthTemplate) - 1].EntryType :=
      StrToInt(jQuery(AElement).closest('tr').find('td:last').find('input').attr('data-entrytype'));
    budgetPlanMonthTemplate[Length(budgetPlanMonthTemplate) - 1].Category :=
      jQuery(AElement).closest('tr').find('td:last').find('input').attr('data-category');
    budgetPlanMonthTemplate[Length(budgetPlanMonthTemplate) - 1].Amount := amount;

    Result := True;
  end;

  procedure OnFail(error: string);
  begin
    Server.ShowError(error);
    JQuery('#btnCreate').RemoveAttr('disabled');
    JQuery('#btnPrevious').RemoveAttr('disabled');
    JQuery('#lblPleaseWait').css('display','none');
  end;

  procedure OnSuccess(aResult: TSettlementPlanCreateResult);
  begin
    if aResult.BudgetPlanID > 0 then
      window.location.href := '#/CSR/' + InttoStr(DossierID) +
        '/settlementplan/' + InttoStr(aResult.SettlementPlanID) + '/budgetplan/' +
        inttostr(aResult.BudgetPlanID)
    else
     window.location.href := '#/CSR/' + InttoStr(DossierID) +
        '/settlementplan/edit/' + InttoStr(aResult.SettlementPlanID);
  end;

begin
  options.DossierID := DossierID;
  options.SourceID := 0;
  if getRadioGroupValue('rdPlanType') = '1' then
    options.SourceID := StrToIntDef(alForm['selPlan'].Value, 0);
  options.Description := alForm['edtDescription'].Value;
  options.PaymentOnDay := StrToInt(alForm['edtPaymentOn'].Value);
  options.ValidFrom := TJSDate.new(Trim(alForm['ddtValidFrom'].Value));
  if (getRadioGroupValue('rdBudgetPlan') = '1') and (getRadioGroupValue('rdPlanType') = '0') then
    options.BudgetPlanSourceID := StrToIntDef(alForm['selBudgetPlan'].Value, -1)
  else
    options.BudgetPlanSourceID := StrToIntDef(getRadioGroupValue('rdBudgetPlan'), -1);
  if (getRadioGroupValue('rdMonthTemplate') = '1') and (getRadioGroupValue('rdPlanType') = '0') then
  begin
    jQuery('input[role="template"]:checked').each(@collectItems);
    options.BudgetPlanMonthTemplate := budgetPlanMonthTemplate;
    options.CopyMonthTemplate := isCheckBoxChecked('cbCopyMonth');
  end
  else
  begin
    options.BudgetPlanMonthTemplate := budgetPlanMonthTemplate;
    options.CopyMonthTemplate := false;
  end;
  JQuery('#btnCreate').attr('disabled', 'true');
  JQuery('#btnPrevious').attr('disabled', 'true');
  JQuery('#lblPleaseWait').css('display','');
  Server.CreateNewSettlementPlan(options, @OnSuccess, @onFail);
end;

procedure TfrmSetlementPlanNew.onBtnNextClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
var
  validated : Boolean;
begin
  if FCurrentStep = 1 then
    validated := validateStepOne;
  if FCurrentStep = 2 then
    validated := validateStepTwo;
  if FCurrentStep = 3 then
    validated := validateStepThree;


  if validated then
  begin
    if (getRadioGroupValue('rdPlanType') = '1') OR (getRadioGroupValue('rdBudgetPlan') = '1') then
      FCurrentStep := 4
    else
      inc(FCurrentStep);

    jQuery('div[id^="step"]').css('display', 'none');
    jQuery('#step' + inttostr(FCurrentStep)).css('display', '');
    if (FCurrentStep > 1) then
      jQuery('#btnPrevious').css('display', '')
    else
      jQuery('#btnPrevious').css('display', 'none');
  end;

  if FCurrentStep = 4 then
  begin
    jQuery('#btnCreate').css('display', '');
    jQuery('#btnNext').css('display', 'none');
    ShowSummary;
  end
  else
  begin
    jQuery('#btnCreate').css('display', 'none');
    jQuery('#btnNext').css('display', '');
  end;
end;

function TfrmSetlementPlanNew.OnBudgetPlanChanged(
  Evt: TEventListenerEvent): Boolean;
begin
  if getRadioGroupValue('rdBudgetPlan') = '1' then
    jQuery('#budgetPlanDossierSelection').css('display', '')
  else
    jQuery('#budgetPlanDossierSelection').css('display', 'none');
  Result := True;
end;

procedure TfrmSetlementPlanNew.onGetPlanCatItem(RecordID: Integer; Name: string;
  Language: Integer; ParamType, Value: string; ValueType: integer;
  Extra: string; isLast: Boolean);
var
  html : string;
begin
  if Name <> '10' then
  begin
    html := jQuery('#grdMonthlyTemplate').find('tbody').html;
    if Pos('data-category="' + Name + '" data-entrytype="' + Extra + '"', html) < 1 then
    begin
      html := html + #13#10 + //
        '<tr>'  + #13#10 + //
        '  <td>'  + #13#10 + //
        '    <input type="checkbox" role="template" checked/>'  + #13#10 + //
        '  </td>'  + #13#10 + //
        '  <td>' + Value + '</td>' +
        '  <td>'  + #13#10 + //
        '    <input type="number" class="form-control form-control-sm" value="" step="0.01" data-category="' + Name + '" data-entrytype="' + Extra + '">'  + #13#10 + //
        '  </td>'  + #13#10 + //
        '</tr>';
      jQuery('#grdMonthlyTemplate').find('tbody').html(html);
    end;
  end;
end;

function TfrmSetlementPlanNew.OnPetitionerSelected(
  Evt: TEventListenerEvent): Boolean;
begin
  dsBudgetPlan.Close;
  dsBudgetPlan.ParamByName('DOSSIERID').asInteger := StrToIntDef(
    alForm['selDossier'].Value, -1 );
  dsBudgetPlan.Load([], nil);
  Result := True;
end;

function TfrmSetlementPlanNew.OnPlanToCopySelected(
  Evt: TEventListenerEvent): Boolean;
var
  desc : string;
begin
  desc := jQuery('#selPlan').find('option[value="' + string(jQuery(Evt.target).val) + '"]').text;
  jQuery('#edtDescription').val(SCopyOf + ' ' + desc);
  Result := True;
end;

function TfrmSetlementPlanNew.OnPlanTypeSelection(
  Evt: TEventListenerEvent): Boolean;
begin
  if getRadioGroupValue('rdPlanType') = '1' then
  begin
    jQuery('#planSelection').css('display', '');
    jQuery('#selPlan').trigger('change');

    jQuery('#rbMonthTemplate').RemoveAttr('checked');
    jQuery('#rbNoMonthTemplate').attr('checked', 'true');
    jQuery('input[role="template"]').RemoveAttr('checked');
  end
  else
  begin
    jQuery('#planSelection').css('display', 'none');

    jQuery('#rbMonthTemplate').attr('checked', 'true');
    jQuery('#rbNoMonthTemplate').RemoveAttr('checked');
    jQuery('input[role="template"]').attr('checked', 'true');
  end;
  Result := True;
end;

procedure TfrmSetlementPlanNew.onPreviousClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if FCurrentStep > 1 then
  begin
    if (FCurrentStep = 4) and (getRadioGroupValue('rdPlanType') = '1') then
      FCurrentStep := 1
    else if (FCurrentStep = 4) and (getRadioGroupValue('rdBudgetPlan') = '1') then
      FCurrentStep := 2
    else
      Dec(FCurrentStep);

    if (FCurrentStep > 1) then
      jQuery('#btnPrevious').css('display', '')
    else
      jQuery('#btnPrevious').css('display', 'none');
    jQuery('div[id^="step"]').css('display', 'none');
    jQuery('#step' + inttostr(FCurrentStep)).css('display', '');
    if FCurrentStep = 4 then
    begin
      jQuery('#btnCreate').css('display', '');
      jQuery('#btnNext').css('display', 'none');
    end
    else
    begin
      jQuery('#btnCreate').css('display', 'none');
      jQuery('#btnNext').css('display', '');
    end;
  end;
end;

function TfrmSetlementPlanNew.OnTemplateTypeSelected(
  Evt: TEventListenerEvent): Boolean;
begin
  if getRadioGroupValue('rdMonthTemplate') = '1' then
    jQuery('#tempalteSelection').css('display', '')
  else
    jQuery('#tempalteSelection').css('display', 'none');
  Result := True;
end;

procedure TfrmSetlementPlanNew.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmSetlementPlanNew.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#returnBack').attr('href',DossierURL('/settlementplan'));
  dsSettlements.ParamByName('DOSSIERID').asInteger := DossierID;
  dsSettlements.Load([], nil);
end;

procedure TfrmSetlementPlanNew.ShowSummary;
begin
  jQuery('#planName').text(alForm['edtDescription'].Value);
  jQuery('#planStartAt').text(alForm['ddtValidFrom'].Value);
  if getRadioGroupValue('rdPlanType') = '0' then
    jQuery('#lblNewPlan').css('display', '')
  else
    jQuery('#lblNewPlan').css('display', 'none');
  if getRadioGroupValue('rdPlanType') = '1' then
  begin
    jQuery('#lblCopyPlan').css('display', '');
    jQuery('#copyPlanName').text(
      jQuery('#selPlan').find('option:selected').text
    );
  end
  else
    jQuery('#lblCopyPlan').css('display', 'none');
  if getRadioGroupValue('rdBudgetPlan') = '-1' then
    jQuery('#lblNoBudgetPlan').css('display', '')
  else
    jQuery('#lblNoBudgetPlan').css('display', 'none');
  if getRadioGroupValue('rdBudgetPlan') = '0' then
    jQuery('#lblEmptyBudgetPlan').css('display', '')
  else
    jQuery('#lblEmptyBudgetPlan').css('display', 'none');
  if getRadioGroupValue('rdBudgetPlan') = '1' then
    jQuery('#lblCopyBudgetPlan').css('display', '')
  else
    jQuery('#lblCopyBudgetPlan').css('display', 'none');
  if getRadioGroupValue('rdMonthTemplate') = '0' then
    jQuery('#lblNoMonthlyTemplate').css('display', '')
  else
    jQuery('#lblNoMonthlyTemplate').css('display', 'none');
  if isCheckBoxChecked('cbCopyMonth') and (getRadioGroupValue('rdMonthTemplate') = '1') then
    jQuery('#lblCopyAllMonthlyTemplate').css('display', '')
  else
    jQuery('#lblCopyAllMonthlyTemplate').css('display', 'none');
  if getRadioGroupValue('rdMonthTemplate') = '1' then
    jQuery('#lblCopyMonthlyTemplate').css('display', '')
  else
    jQuery('#lblCopyMonthlyTemplate').css('display', 'none');
end;

function TfrmSetlementPlanNew.validateStepOne: boolean;

  function IsValidPaymentDay: Boolean;
  var
    iDay: Integer;
  begin
    Result := True;
    if (not TryStrToInt(Trim(alForm['edtPaymentOn'].value), iDay)) or ((iDay < 1) or (iDay > 28)) then
    begin
      DisplayError(alForm['edtPaymentOn'].ID);
      Result := False;
    end
    else
      HideError(alForm['edtPaymentOn'].ID, []);
  end;

begin
  Result := isSelectedRadioGroup('rdPlanType');
  Result := not FieldIsEmpty('ddtValidFrom') and Result;
  Result := not FieldIsEmpty('edtPaymentOn') and Result;
  Result := Result and IsValidPaymentDay;
  //Result := not FieldIsEmpty('ddtValidTill') and Result;
  Result := not FieldIsEmpty('edtDescription') and Result;
  if getRadioGroupValue('rdPlanType') = '1' then
    Result := not FieldIsEmpty('selPlan') and Result;
end;

function TfrmSetlementPlanNew.validateStepThree: boolean;
var
  count : Integer;

  function countItems(aIndex: Integer; AElement: TJSElement): Boolean;
  var
    val: double;
    amount: string;
  begin
    amount := string(jQuery(AElement).closest('tr').find('td:last').find('input').val);
    val := StrToFloatDef(amount, -1);
    if val > 0 then
      Inc(count);
    Result := True;
  end;

begin
  Result := isSelectedRadioGroup('rdMonthTemplate');
  if getRadioGroupValue('rdMonthTemplate') = '1' then
  begin
    count := 0;
    jQuery('input[role="template"]:checked').each(@countItems);
    Result := (count > 0) and Result;
    if count <= 0 then
      DisplayError('grdMonthlyTemplate');
  end;
end;

function TfrmSetlementPlanNew.validateStepTwo: boolean;
begin
  Result := isSelectedRadioGroup('rdBudgetPlan');
  if getRadioGroupValue('rdBudgetPlan') = '1' then
     Result := not FieldIsEmpty('selBudgetPlan') and Result;
end;

procedure TfrmSetlementPlanNew.WebFormCreate(Sender: TObject);
begin
  inherited;
  FCurrentStep := 1;

  //disable past selection
  jQuery('#ddtValidFrom').attr('min', FormatHTMLDate(now));
  jQuery('input[name="rdPlanType"]').On_('change', @OnPlanTypeSelection);
  jQuery('input[name="rdBudgetPlan"]').On_('change', @OnBudgetPlanChanged);
  jQuery('#selDossier').On_('change', @OnPetitionerSelected);
  jQuery('input[name="rdMonthTemplate"]').On_('change', @OnTemplateTypeSelected);
  jQuery('#selPlan').On_('change', @OnPlanToCopySelected);
  jQuery('#edtDescription').val(SDefaultPlan);

  Server.CSRConnection.Message.ClientID := Server.ClientID;
  dsSettlements.DAConnection := Server.CSRConnection;

  dsPetitioner.DAConnection := Server.CSRConnection;
  //dsPetitioner.Load([], nil);

  dsBudgetPlan.DAConnection := Server.CSRConnection;
  dsBudgetPlan.Load([], nil);

  Server.GetParameterList('PLANCAT', Language, @onGetPlanCatItem);

end;

procedure TfrmSetlementPlanNew.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsSettlements := TP2WDADataset.Create(Self);
  dsPetitioner := TP2WDADataset.Create(Self);
  dsBudgetPlan := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsSettlements.BeforeLoadDFMValues;
  dsPetitioner.BeforeLoadDFMValues;
  dsBudgetPlan.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'selPlan';
      Name := 'selPlan';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidFrom';
      Name := 'ddtValidFrom';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtDescription';
      Name := 'edtDescription';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNext';
      Name := 'btnNext';
      SetEvent(Self, 'OnExecute', 'onBtnNextClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnPrevious';
      Name := 'btnPrevious';
      SetEvent(Self, 'OnExecute', 'onPreviousClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'selDossier';
      Name := 'selDossier';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'selBudgetPlan';
      Name := 'selBudgetPlan';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnCreate';
      Name := 'btnCreate';
      SetEvent(Self, 'OnExecute', 'onBtnCreateClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtPaymentOn';
      Name := 'edtPaymentOn';
      PreventDefault := False;
      StopPropagation := False;
    end;
    dsSettlements.SetParentComponent(Self);
    dsSettlements.Name := 'dsSettlements';
    dsSettlements.TableName := 'settlementplan';
    dsSettlements.Params.Clear;
    with dsSettlements.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsSettlements.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>sepdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsSettlements.DAOptions := [doRefreshAllFields];
    dsSettlements.AfterOpen := dsSettlementsAfterOpen;
    dsSettlements.Left := 40;
    dsSettlements.Top := 88;
    dsPetitioner.SetParentComponent(Self);
    dsPetitioner.Name := 'dsPetitioner';
    dsPetitioner.TableName := 'dossierwithbudgetplans';
    dsPetitioner.DAOptions := [doRefreshAllFields];
    dsPetitioner.AfterOpen := dsPetitionerAfterOpen;
    dsPetitioner.Left := 40;
    dsPetitioner.Top := 152;
    dsBudgetPlan.SetParentComponent(Self);
    dsBudgetPlan.Name := 'dsBudgetPlan';
    dsBudgetPlan.TableName := 'budgetplan';
    dsBudgetPlan.DAOptions := [doRefreshAllFields];
    dsBudgetPlan.AfterOpen := dsBudgetPlanAfterOpen;
    dsBudgetPlan.Left := 40;
    dsBudgetPlan.Top := 232;
  finally
    alForm.AfterLoadDFMValues;
    dsSettlements.AfterLoadDFMValues;
    dsPetitioner.AfterLoadDFMValues;
    dsBudgetPlan.AfterLoadDFMValues;
  end;
end;

end.