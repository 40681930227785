unit Forms.CSR.Realestate.Prop;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Realestate.Prop, Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRRealestateProperty = class(TfrmRealestateProperty)
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRRealestateProperty: TfrmCSRRealestateProperty;

implementation

{$R *.dfm}

{ TfrmCSRRealestateProperty }

function TfrmCSRRealestateProperty.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRRealestateProperty.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRRealestateProperty.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRRealestateProperty.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dsRealestate.BeforeLoadDFMValues;
  try
    dsRealestate.Params.Clear;
    with dsRealestate.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'PROID';
      ParamType := ptInput;
    end;
  finally
    dsRealestate.AfterLoadDFMValues;
  end;
end;

end.