unit Forms.CompanyUser.Detail;

interface

uses
  System.SysUtils, System.Classes, Types, JS, Web, WEBLib.Graphics, WEBLib.Controls, pas2web.dadatasethelper,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, pas2web.dataelementmapper, Data.DB, Datasnap.DBClient, pas2web.dadataset,
  Modules.Server;

type
  TfrmCompanyUserDetail = class(TfrmBase)
    dsLogin: TP2WDADataset;
    eacLogin: TPW2DataElementMapper;
    dsLoginlogid: TLargeintField;
    dsLoginlogcreatedon: TDateTimeField;
    dsLoginlogcreatedbyfk: TLargeintField;
    dsLoginlogchangedon: TDateTimeField;
    dsLoginlogchangedbyfk: TLargeintField;
    dsLoginlogcompanyfk: TLargeintField;
    dsLoginlogpersonfk: TLargeintField;
    dsLoginlogrolefk: TLargeintField;
    dsLoginlogname: TWideStringField;
    dsLoginlogpassword: TWideStringField;
    dsLoginlogactive: TBooleanField;
    dsLoginloglastseen: TDateTimeField;
    dsLoginlogloginfails: TIntegerField;
    dsLoginlogisblocked15: TBooleanField;
    dsLoginlogblocked15time: TDateTimeField;
    dsLoginlogwasblocked15: TBooleanField;
    dsLoginlogisblocked: TBooleanField;
    dsPerson: TP2WDADataset;
    eacPerson: TPW2DataElementMapper;
    dsPersonperid: TLargeintField;
    dsPersonpercreatedon: TDateTimeField;
    dsPersonpercreatedbyfk: TLargeintField;
    dsPersonperchangedon: TDateTimeField;
    dsPersonperchangedbyfk: TLargeintField;
    dsPersonperfirstname: TWideStringField;
    dsPersonperlastname: TWideStringField;
    dsPersonperemail: TWideStringField;
    dsPersonpermobile: TWideStringField;
    dsRoles: TP2WDADataset;
    dsRoleslorid: TLargeintField;
    dsRoleslorname: TWideStringField;
    dsRoleslorauthentications: TLargeintField;
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure dsLoginAfterOpen(DataSet: TDataSet);
    procedure dsPersonAfterOpen(DataSet: TDataSet);
    procedure DoSaveForm(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure dsPersonAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsLoginAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsRolesAfterOpen(DataSet: TDataSet);
    procedure eacLoginValidationError(Sender: TObject; aLink: TP2WDataElementLink; Source: TElementAction; dest: TField;
      aError: TElementError);
    procedure eacPersonValidationError(Sender: TObject; aLink: TP2WDataElementLink; Source: TElementAction; dest: TField;
      aError: TElementError);
  private
    FRecordID : NativeInt;
    procedure SavePerson;
    procedure CheckData;
    procedure SaveLogin;
    procedure ShowRoles(aSelected : NativeInt);
  public
    { Public declarations }
    class function MyRoutes : TStringDynArray; override;
    class function NeedsParams : Boolean; override;
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmCompanyUserDetail: TfrmCompanyUserDetail;

implementation

uses roSDK, Units.Service.Dezq, Units.Strings, Units.ActionUtils, Units.DADatasetUtils;

{$R *.dfm}

procedure TfrmCompanyUserDetail.DoSaveForm(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

begin
  CheckData;
end;

procedure TfrmCompanyUserDetail.CheckData;

  Procedure DoOK(aResult : string);

  begin
    if aResult='' then
      SavePerson
    else
      dmServer.ShowError(Format(SErrCannotSaveUser,[aResult]));
  end;

  Procedure DoFail(aObject : TJSObject; aFail : TJSError);
  begin
    dmServer.ShowError(ExtractErrorMsg(aFail,SErrCannotSaveUser));
  end;

Var
  loginOK,PersonOK : Boolean;

begin
  // Separate, so shortcut evaluation does not kick in.
  loginOK:=eacLogin.ValidateActions(True);
  PersonOK:=eacPerson.ValidateActions(True);
  if LoginOK and PersonOK then
    begin
    if Not EmailIsValid(alForm['edtEmail'].value) then
      DisplayError('edtEmail','incorrect-value')
    else if Not UserNameIsValid(alForm['edtLogin'].value) then
      DisplayError('edtLogin','incorrect-value')
    else
      dmServer.CompanyService.CheckEmailAndUserName(FrecordID,alForm['edtLogin'].value,alForm['edtEmail'].value,@DoOK,@DoFail);
    end;
end;

procedure TfrmCompanyUserDetail.SaveLogin;

begin
  if (FRecordID=-1) and dsLogin.Isempty then
    begin
    dsLogin.Append;
    dsLoginlogcreatedon.AsDateTime:=Now;
    dsLoginlogcreatedbyfk.AsLargeint:=dmServer.UserID;
    dsLoginlogpersonfk.AsInteger:=dsPersonPerID.AsLargeInt;
    dsLoginlogcompanyfk.AsInteger:=dmServer.UserInfo.OfficeID;
    dsLoginlogrolefk.AsLargeInt:=StrToInt64Def(alForm['selRole'].Value,-1);
    dsLoginlogactive.asBoolean:=true;
    dsLoginlogpassword.asString:='';
    end;
  dsLoginlogchangedon.AsDateTime:=Now;
  dsLoginlogchangedbyfk.AsLargeint:=dmServer.UserID;
  eacLogin.ElementsToDataset;
  dsLogin.Post;
  dsLogin.ApplyUpdates;
end;

procedure TfrmCompanyUserDetail.SavePerson;

begin
  if (FRecordID=-1) and dsPerson.IsEmpty then
    begin
    dsPerson.Append;
    dsPersonpercreatedon.AsDateTime:=Now;
    dsPersonpercreatedbyfk.AsLargeint:=dmServer.UserID;
    end
  else
    dsPerson.Edit;
  dsPersonperchangedon.AsDateTime:=Now;
  dsPersonperchangedbyfk.AsLargeint:=dmServer.UserID;
  eacPerson.ElementsToDataset;
  dsPerson.Post;
  dsPerson.ApplyUpdates;
end;

procedure TfrmCompanyUserDetail.dsLoginAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

  Procedure ReturnToOverview;

  begin
    Window.Location.Hash:='#/companyuser/';
  end;

  Procedure DoOK(aID : NativeInt);

  begin
    dmServer.showOK(SUserPasswordResetSuccessMessage);
    ReturnToOverview;
  end;

  Procedure DoFail(aMessage : TJSObject; aFail : TJSError);

  begin
    dmServer.ShowError(ExtractErrorMsg(aFail,SErrPasswordResetRequestFailed));
  end;

  Procedure SendPasswordResetMail;

  var
    request : TPasswordResetRequest;
    ROrequest : TROPasswordResetRequest;

  begin
    dmServer.ShowOK(SDataSavedOK);
    request.UserName := dsLoginLogName.AsString;
    request.OfficeName := dmServer.UserInfo.Office;
    request.Email := '';
    RORequest:=TROPasswordResetRequest.New;
    RORequest.FromObject(request);
    dmServer.LoginService.RequestPasswordReset(RORequest, @DoOK, @DoFail);
  end;

begin
  CheckResolveResults(Info,@SendPasswordResetMail);
end;

procedure TfrmCompanyUserDetail.dsLoginAfterOpen(DataSet: TDataSet);

Var
  RoleID : NativeInt;

begin
  if not DataSet.isEmpty then
    begin
    eacLogin.DatasetToElements;
    dsPerson.ParamByName('ID').AsLargeInt:=dsLoginlogpersonfk.AsLargeInt;
    RoleID:=dsLoginLogRoleFK.AsLargeInt;
    end
  else
    begin
    dsPerson.ParamByName('ID').AsLargeInt:=-2;
    RoleID:=-1;
    end;
  dsPerson.Load([],Nil);
  if dsRoles.Active then
    ShowRoles(RoleID);
end;

procedure TfrmCompanyUserDetail.dsPersonAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

Var
  Msg: String;

begin
  Msg:=Info.AsString;
  if Msg='' then
    SaveLogin
  else
    dmServer.ShowError(Format(SErrCouldNotSavePersonData,[Msg]));
end;

procedure TfrmCompanyUserDetail.dsPersonAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
    eacPerson.DatasetToElements;
end;

procedure TfrmCompanyUserDetail.dsRolesAfterOpen(DataSet: TDataSet);
begin
  if dsLogin.Active then
    if dsLogin.IsEmpty then
      ShowRoles(-1)
    else
      ShowRoles(dsLoginlogrolefk.AsLargeInt);
end;

procedure TfrmCompanyUserDetail.eacLoginValidationError(Sender: TObject; aLink: TP2WDataElementLink; Source: TElementAction;
  dest: TField; aError: TElementError);
begin
  inherited;
  DisplayError(Source.ID);
end;

procedure TfrmCompanyUserDetail.eacPersonValidationError(Sender: TObject; aLink: TP2WDataElementLink; Source: TElementAction;
  dest: TField; aError: TElementError);
begin
  inherited;
  DisplayError(Source.ID);
end;

class function TfrmCompanyUserDetail.MyRoutes: TStringDynArray;
begin
  Result:=['companyuser/edit/:ID','companyuser/new'];
end;

class function TfrmCompanyUserDetail.NeedsParams: Boolean;
begin
  Result:=True;
end;

procedure TfrmCompanyUserDetail.setParams(const Params: TStrings);
begin
  inherited;
  if SameText(Params.Values['ID'],'new') then
    FRecordID:=-2
  else
    FRecordID:=StrToIntDef(Params.Values['ID'],-1);
end;

procedure TfrmCompanyUserDetail.WebFormCreate(Sender: TObject);

begin
  Inherited;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsPerson.DAConnection := Server.CompanyConnection;
  dsLogin.DAConnection := Server.CompanyConnection;
  dsRoles.DAConnection := Server.CompanyConnection;
end;

procedure TfrmCompanyUserDetail.WebFormDestroy(Sender: TObject);

begin
  Inherited;
  //
end;

procedure TfrmCompanyUserDetail.ShowRoles(aSelected : NativeInt);

Const
  Selected : Array[Boolean] of string = ('','selected');

Var
  aHTML : String;

begin
  aHTML:='';
  While not dsRoles.EOF do
    begin
    aHTML:=aHTML+'<option '+Selected[(aSelected=dsRolesLorId.AsLargeInt)]+' value="'+dsRolesLorId.AsString+'">'+dsRolesLorName.AsString+'</option>'+sLineBreak;
    dsRoles.Next;
    end;
  alForm['selRole'].ElementHandle.InnerHTML:=aHTML;
end;

procedure TfrmCompanyUserDetail.WebFormShow(Sender: TObject);

begin
  inherited;
  dsRoles.Load([],Nil);
  dsLogin.ParamByName('ID').AsLargeInt:=FRecordID;
  dsLogin.Load([],Nil);
end;

procedure TfrmCompanyUserDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsLogin := TP2WDADataset.Create(Self);
  dsLoginlogid := TLargeintField.Create(Self);
  dsLoginlogcreatedon := TDateTimeField.Create(Self);
  dsLoginlogcreatedbyfk := TLargeintField.Create(Self);
  dsLoginlogchangedon := TDateTimeField.Create(Self);
  dsLoginlogchangedbyfk := TLargeintField.Create(Self);
  dsLoginlogcompanyfk := TLargeintField.Create(Self);
  dsLoginlogpersonfk := TLargeintField.Create(Self);
  dsLoginlogrolefk := TLargeintField.Create(Self);
  dsLoginlogname := TWideStringField.Create(Self);
  dsLoginlogpassword := TWideStringField.Create(Self);
  dsLoginlogactive := TBooleanField.Create(Self);
  dsLoginloglastseen := TDateTimeField.Create(Self);
  dsLoginlogloginfails := TIntegerField.Create(Self);
  dsLoginlogisblocked15 := TBooleanField.Create(Self);
  dsLoginlogblocked15time := TDateTimeField.Create(Self);
  dsLoginlogwasblocked15 := TBooleanField.Create(Self);
  dsLoginlogisblocked := TBooleanField.Create(Self);
  eacLogin := TPW2DataElementMapper.Create(Self);
  dsPerson := TP2WDADataset.Create(Self);
  dsPersonperid := TLargeintField.Create(Self);
  dsPersonpercreatedon := TDateTimeField.Create(Self);
  dsPersonpercreatedbyfk := TLargeintField.Create(Self);
  dsPersonperchangedon := TDateTimeField.Create(Self);
  dsPersonperchangedbyfk := TLargeintField.Create(Self);
  dsPersonperfirstname := TWideStringField.Create(Self);
  dsPersonperlastname := TWideStringField.Create(Self);
  dsPersonperemail := TWideStringField.Create(Self);
  dsPersonpermobile := TWideStringField.Create(Self);
  eacPerson := TPW2DataElementMapper.Create(Self);
  dsRoles := TP2WDADataset.Create(Self);
  dsRoleslorid := TLargeintField.Create(Self);
  dsRoleslorname := TWideStringField.Create(Self);
  dsRoleslorauthentications := TLargeintField.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsLogin.BeforeLoadDFMValues;
  dsLoginlogid.BeforeLoadDFMValues;
  dsLoginlogcreatedon.BeforeLoadDFMValues;
  dsLoginlogcreatedbyfk.BeforeLoadDFMValues;
  dsLoginlogchangedon.BeforeLoadDFMValues;
  dsLoginlogchangedbyfk.BeforeLoadDFMValues;
  dsLoginlogcompanyfk.BeforeLoadDFMValues;
  dsLoginlogpersonfk.BeforeLoadDFMValues;
  dsLoginlogrolefk.BeforeLoadDFMValues;
  dsLoginlogname.BeforeLoadDFMValues;
  dsLoginlogpassword.BeforeLoadDFMValues;
  dsLoginlogactive.BeforeLoadDFMValues;
  dsLoginloglastseen.BeforeLoadDFMValues;
  dsLoginlogloginfails.BeforeLoadDFMValues;
  dsLoginlogisblocked15.BeforeLoadDFMValues;
  dsLoginlogblocked15time.BeforeLoadDFMValues;
  dsLoginlogwasblocked15.BeforeLoadDFMValues;
  dsLoginlogisblocked.BeforeLoadDFMValues;
  eacLogin.BeforeLoadDFMValues;
  dsPerson.BeforeLoadDFMValues;
  dsPersonperid.BeforeLoadDFMValues;
  dsPersonpercreatedon.BeforeLoadDFMValues;
  dsPersonpercreatedbyfk.BeforeLoadDFMValues;
  dsPersonperchangedon.BeforeLoadDFMValues;
  dsPersonperchangedbyfk.BeforeLoadDFMValues;
  dsPersonperfirstname.BeforeLoadDFMValues;
  dsPersonperlastname.BeforeLoadDFMValues;
  dsPersonperemail.BeforeLoadDFMValues;
  dsPersonpermobile.BeforeLoadDFMValues;
  eacPerson.BeforeLoadDFMValues;
  dsRoles.BeforeLoadDFMValues;
  dsRoleslorid.BeforeLoadDFMValues;
  dsRoleslorname.BeforeLoadDFMValues;
  dsRoleslorauthentications.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'DoSaveForm');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtEmail';
      Name := 'edtEmail';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtFirstName';
      Name := 'edtFirstName';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtLastName';
      Name := 'edtLastName';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtLogin';
      Name := 'edtLogin';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtPhone';
      Name := 'edtPhone';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'selRole';
      Name := 'selRole';
    end;
    dsLogin.SetParentComponent(Self);
    dsLogin.Name := 'dsLogin';
    dsLogin.TableName := 'CompanyLogin';
    dsLogin.Params.Clear;
    with dsLogin.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
    end;
    with dsLogin.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'ID';
      ParamType := ptInput;
    end;
    dsLogin.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>logid</field><parameter type="LargeInt">ID</parameter></binaryoperation></where></query>';
    dsLogin.DAOptions := [];
    dsLogin.AfterOpen := dsLoginAfterOpen;
    dsLogin.AfterApplyUpdates := dsLoginAfterApplyUpdates;
    dsLogin.Left := 40;
    dsLogin.Top := 96;
    dsLoginlogid.SetParentComponent(dsLogin);
    dsLoginlogid.Name := 'dsLoginlogid';
    dsLoginlogid.FieldName := 'logid';
    dsLoginlogcreatedon.SetParentComponent(dsLogin);
    dsLoginlogcreatedon.Name := 'dsLoginlogcreatedon';
    dsLoginlogcreatedon.FieldName := 'logcreatedon';
    dsLoginlogcreatedbyfk.SetParentComponent(dsLogin);
    dsLoginlogcreatedbyfk.Name := 'dsLoginlogcreatedbyfk';
    dsLoginlogcreatedbyfk.FieldName := 'logcreatedbyfk';
    dsLoginlogchangedon.SetParentComponent(dsLogin);
    dsLoginlogchangedon.Name := 'dsLoginlogchangedon';
    dsLoginlogchangedon.FieldName := 'logchangedon';
    dsLoginlogchangedbyfk.SetParentComponent(dsLogin);
    dsLoginlogchangedbyfk.Name := 'dsLoginlogchangedbyfk';
    dsLoginlogchangedbyfk.FieldName := 'logchangedbyfk';
    dsLoginlogcompanyfk.SetParentComponent(dsLogin);
    dsLoginlogcompanyfk.Name := 'dsLoginlogcompanyfk';
    dsLoginlogcompanyfk.FieldName := 'logcompanyfk';
    dsLoginlogpersonfk.SetParentComponent(dsLogin);
    dsLoginlogpersonfk.Name := 'dsLoginlogpersonfk';
    dsLoginlogpersonfk.FieldName := 'logpersonfk';
    dsLoginlogrolefk.SetParentComponent(dsLogin);
    dsLoginlogrolefk.Name := 'dsLoginlogrolefk';
    dsLoginlogrolefk.FieldName := 'logrolefk';
    dsLoginlogname.SetParentComponent(dsLogin);
    dsLoginlogname.Name := 'dsLoginlogname';
    dsLoginlogname.FieldName := 'logname';
    dsLoginlogname.Size := 100;
    dsLoginlogpassword.SetParentComponent(dsLogin);
    dsLoginlogpassword.Name := 'dsLoginlogpassword';
    dsLoginlogpassword.FieldName := 'logpassword';
    dsLoginlogpassword.Size := 100;
    dsLoginlogactive.SetParentComponent(dsLogin);
    dsLoginlogactive.Name := 'dsLoginlogactive';
    dsLoginlogactive.FieldName := 'logactive';
    dsLoginloglastseen.SetParentComponent(dsLogin);
    dsLoginloglastseen.Name := 'dsLoginloglastseen';
    dsLoginloglastseen.FieldName := 'loglastseen';
    dsLoginlogloginfails.SetParentComponent(dsLogin);
    dsLoginlogloginfails.Name := 'dsLoginlogloginfails';
    dsLoginlogloginfails.FieldName := 'logloginfails';
    dsLoginlogisblocked15.SetParentComponent(dsLogin);
    dsLoginlogisblocked15.Name := 'dsLoginlogisblocked15';
    dsLoginlogisblocked15.FieldName := 'logisblocked15';
    dsLoginlogblocked15time.SetParentComponent(dsLogin);
    dsLoginlogblocked15time.Name := 'dsLoginlogblocked15time';
    dsLoginlogblocked15time.FieldName := 'logblocked15time';
    dsLoginlogwasblocked15.SetParentComponent(dsLogin);
    dsLoginlogwasblocked15.Name := 'dsLoginlogwasblocked15';
    dsLoginlogwasblocked15.FieldName := 'logwasblocked15';
    dsLoginlogisblocked.SetParentComponent(dsLogin);
    dsLoginlogisblocked.Name := 'dsLoginlogisblocked';
    dsLoginlogisblocked.FieldName := 'logisblocked';
    eacLogin.SetParentComponent(Self);
    eacLogin.Name := 'eacLogin';
    eacLogin.ActionLinks.Clear;
    with eacLogin.ActionLinks.Add do
    begin
      ActionName := 'edtLogin';
      FieldName := 'logname';
      Options := [aoRequired];
    end;
    eacLogin.ActionList := alForm;
    eacLogin.Dataset := dsLogin;
    SetEvent(eacLogin, Self, 'OnValidationError', 'eacLoginValidationError');
    eacLogin.Left := 40;
    eacLogin.Top := 160;
    dsPerson.SetParentComponent(Self);
    dsPerson.Name := 'dsPerson';
    dsPerson.TableName := 'CompanyPerson';
    dsPerson.Params.Clear;
    with dsPerson.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'ID';
      ParamType := ptInput;
    end;
    with dsPerson.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
    end;
    dsPerson.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>perid</field><parameter type="LargeInt">ID</parameter></binaryoperation></where></query>';
    dsPerson.DAOptions := [];
    dsPerson.AfterOpen := dsPersonAfterOpen;
    dsPerson.AfterApplyUpdates := dsPersonAfterApplyUpdates;
    dsPerson.Left := 136;
    dsPerson.Top := 96;
    dsPersonperid.SetParentComponent(dsPerson);
    dsPersonperid.Name := 'dsPersonperid';
    dsPersonperid.FieldName := 'perid';
    dsPersonpercreatedon.SetParentComponent(dsPerson);
    dsPersonpercreatedon.Name := 'dsPersonpercreatedon';
    dsPersonpercreatedon.FieldName := 'percreatedon';
    dsPersonpercreatedbyfk.SetParentComponent(dsPerson);
    dsPersonpercreatedbyfk.Name := 'dsPersonpercreatedbyfk';
    dsPersonpercreatedbyfk.FieldName := 'percreatedbyfk';
    dsPersonperchangedon.SetParentComponent(dsPerson);
    dsPersonperchangedon.Name := 'dsPersonperchangedon';
    dsPersonperchangedon.FieldName := 'perchangedon';
    dsPersonperchangedbyfk.SetParentComponent(dsPerson);
    dsPersonperchangedbyfk.Name := 'dsPersonperchangedbyfk';
    dsPersonperchangedbyfk.FieldName := 'perchangedbyfk';
    dsPersonperfirstname.SetParentComponent(dsPerson);
    dsPersonperfirstname.Name := 'dsPersonperfirstname';
    dsPersonperfirstname.FieldName := 'perfirstname';
    dsPersonperfirstname.Size := 64;
    dsPersonperlastname.SetParentComponent(dsPerson);
    dsPersonperlastname.Name := 'dsPersonperlastname';
    dsPersonperlastname.FieldName := 'perlastname';
    dsPersonperlastname.Size := 64;
    dsPersonperemail.SetParentComponent(dsPerson);
    dsPersonperemail.Name := 'dsPersonperemail';
    dsPersonperemail.FieldName := 'peremail';
    dsPersonperemail.Size := 128;
    dsPersonpermobile.SetParentComponent(dsPerson);
    dsPersonpermobile.Name := 'dsPersonpermobile';
    dsPersonpermobile.FieldName := 'permobile';
    dsPersonpermobile.Size := 25;
    eacPerson.SetParentComponent(Self);
    eacPerson.Name := 'eacPerson';
    eacPerson.ActionLinks.Clear;
    with eacPerson.ActionLinks.Add do
    begin
      ActionName := 'edtEmail';
      FieldName := 'peremail';
      Options := [aoRequired];
    end;
    with eacPerson.ActionLinks.Add do
    begin
      ActionName := 'edtFirstName';
      FieldName := 'perfirstname';
      Options := [aoRequired];
    end;
    with eacPerson.ActionLinks.Add do
    begin
      ActionName := 'edtLastName';
      FieldName := 'perlastname';
      Options := [aoRequired];
    end;
    with eacPerson.ActionLinks.Add do
    begin
      ActionName := 'edtPhone';
      FieldName := 'permobile';
      Options := [aoRequired];
    end;
    eacPerson.ActionList := alForm;
    eacPerson.Dataset := dsPerson;
    SetEvent(eacPerson, Self, 'OnValidationError', 'eacPersonValidationError');
    eacPerson.Left := 136;
    eacPerson.Top := 160;
    dsRoles.SetParentComponent(Self);
    dsRoles.Name := 'dsRoles';
    dsRoles.TableName := 'CompanyRoles';
    dsRoles.DAConnection := dmServer.CompanyConnection;
    dsRoles.DAOptions := [];
    dsRoles.AfterOpen := dsRolesAfterOpen;
    dsRoles.Left := 240;
    dsRoles.Top := 104;
    dsRoleslorid.SetParentComponent(dsRoles);
    dsRoleslorid.Name := 'dsRoleslorid';
    dsRoleslorid.FieldName := 'lorid';
    dsRoleslorname.SetParentComponent(dsRoles);
    dsRoleslorname.Name := 'dsRoleslorname';
    dsRoleslorname.FieldName := 'lorname';
    dsRoleslorname.Size := 32;
    dsRoleslorauthentications.SetParentComponent(dsRoles);
    dsRoleslorauthentications.Name := 'dsRoleslorauthentications';
    dsRoleslorauthentications.FieldName := 'lorauthentications';
  finally
    alForm.AfterLoadDFMValues;
    dsLogin.AfterLoadDFMValues;
    dsLoginlogid.AfterLoadDFMValues;
    dsLoginlogcreatedon.AfterLoadDFMValues;
    dsLoginlogcreatedbyfk.AfterLoadDFMValues;
    dsLoginlogchangedon.AfterLoadDFMValues;
    dsLoginlogchangedbyfk.AfterLoadDFMValues;
    dsLoginlogcompanyfk.AfterLoadDFMValues;
    dsLoginlogpersonfk.AfterLoadDFMValues;
    dsLoginlogrolefk.AfterLoadDFMValues;
    dsLoginlogname.AfterLoadDFMValues;
    dsLoginlogpassword.AfterLoadDFMValues;
    dsLoginlogactive.AfterLoadDFMValues;
    dsLoginloglastseen.AfterLoadDFMValues;
    dsLoginlogloginfails.AfterLoadDFMValues;
    dsLoginlogisblocked15.AfterLoadDFMValues;
    dsLoginlogblocked15time.AfterLoadDFMValues;
    dsLoginlogwasblocked15.AfterLoadDFMValues;
    dsLoginlogisblocked.AfterLoadDFMValues;
    eacLogin.AfterLoadDFMValues;
    dsPerson.AfterLoadDFMValues;
    dsPersonperid.AfterLoadDFMValues;
    dsPersonpercreatedon.AfterLoadDFMValues;
    dsPersonpercreatedbyfk.AfterLoadDFMValues;
    dsPersonperchangedon.AfterLoadDFMValues;
    dsPersonperchangedbyfk.AfterLoadDFMValues;
    dsPersonperfirstname.AfterLoadDFMValues;
    dsPersonperlastname.AfterLoadDFMValues;
    dsPersonperemail.AfterLoadDFMValues;
    dsPersonpermobile.AfterLoadDFMValues;
    eacPerson.AfterLoadDFMValues;
    dsRoles.AfterLoadDFMValues;
    dsRoleslorid.AfterLoadDFMValues;
    dsRoleslorname.AfterLoadDFMValues;
    dsRoleslorauthentications.AfterLoadDFMValues;
  end;
end;

end.