unit Forms.Expenses;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  pas2web.dadataset,
  pas2web.datatables,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadatasethelper,
  Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;

  TfrmExpenses = class(TfrmBase)
    dsExpense: TP2WDADataset;
    dcExpense: TP2WDatatable;
    procedure dcExpenseGetCategoryValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure dcExpenseGetKindValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure dcExpenseGetPeriodicityValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure dsExpenseAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure dsExpenseAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
  private
    { Private declarations }
    FRoute: string;
    FDeleteID: Int64;
    FDoDelete: Boolean;
    procedure ConfirmDeleteExpense;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDelete(const expenseId: Int64);
  protected procedure LoadDFMValues; override; end;

var
  frmExpenses: TfrmExpenses;

implementation

uses
  Modules.Server,
  lib.bootstrap,
  Units.Strings;

{$R *.dfm}
{ TfrmExpenses }

procedure TfrmExpenses.ConfirmDeleteExpense;

  procedure DoDeleteRecord;
  begin
    if dsExpense.Locate('expid', FDeleteID, []) then
    begin
      dsExpense.Delete;
      dsExpense.ApplyUpdates;
    end;
  end;

  procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;

begin
  FDoDelete := False;
  if dsExpense.Locate('expid', FDeleteID, []) then
    DefaultConfirmation.Execute('', @DoDeleteRecord, @DoCancelDelete);
end;

procedure TfrmExpenses.dcExpenseGetCategoryValue(Sender: TObject; Fields: TP2WDataTableFieldMap;
  out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('EXCAT', string(Fields.GetValueByName('expcategory')));
end;

procedure TfrmExpenses.dcExpenseGetKindValue(Sender: TObject; Fields: TP2WDataTableFieldMap;
  out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('EXKIND', string(Fields.GetValueByName('expkind')));
end;

procedure TfrmExpenses.dcExpenseGetPeriodicityValue(Sender: TObject; Fields: TP2WDataTableFieldMap;
  out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('REVPER', string(Fields.GetValueByName('exppaymentperiod')));
end;

procedure TfrmExpenses.DoDelete(const expenseId: Int64);
begin
  FDeleteID := expenseId;
  if dsExpense.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteExpense;
end;

procedure TfrmExpenses.dsExpenseAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  CheckResolveResults(Info, FRoute);
end;

procedure TfrmExpenses.dsExpenseAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsExpense.GetNewRows(False);
  dcExpense.Data := aResult;
  dcExpense.RenderTranslated;
  if FDoDelete then
    ConfirmDeleteExpense;
end;

procedure TfrmExpenses.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDeleteID := -1;
  FDoDelete := False;
  dsExpense.DAConnection := DossierConnection;
end;

procedure TfrmExpenses.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#addExpense').attr('href', DossierURL('expense/new'));
  FRoute := DossierURL('expense');
  dsExpense.ParamByName('DOSSIERID').asLargeInt := DossierID;
end;

procedure TfrmExpenses.WebFormShow(Sender: TObject);
begin
  inherited;
  dsExpense.Load([], nil);
end;

procedure TfrmExpenses.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsExpense := TP2WDADataset.Create(Self);
  dcExpense := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsExpense.BeforeLoadDFMValues;
  dcExpense.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'btnNo';
    end;
    dsExpense.SetParentComponent(Self);
    dsExpense.Name := 'dsExpense';
    dsExpense.TableName := 'expense';
    dsExpense.Params.Clear;
    with dsExpense.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsExpense.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>expdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsExpense.DAOptions := [doRefreshAllFields];
    dsExpense.AfterOpen := dsExpenseAfterOpen;
    dsExpense.AfterApplyUpdates := dsExpenseAfterApplyUpdates;
    dsExpense.Left := 32;
    dsExpense.Top := 104;
    dcExpense.SetParentComponent(Self);
    dcExpense.Name := 'dcExpense';
    dcExpense.Columns.Clear;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expamount';
      Title := 'Amount ';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
      NumericFormat := '###,###.00';
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expcategory';
      Title := 'Category';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcExpenseGetCategoryValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expkind';
      Title := 'Kind';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcExpenseGetKindValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'exppaymentperiod';
      Title := 'Period';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcExpenseGetPeriodicityValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{expdossierfk}}/expense/edit/{{expid}}';
    end;
    with dcExpense.Columns.Add do
    begin
      FieldName := 'expdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{expdossierfk}}/expense/delete/{{expid}}';
    end;
    dcExpense.DataSet := dsExpense;
    dcExpense.Language := lEnglish;
    dcExpense.IsResponsive := True;
    dcExpense.GridID := 'tblExpenses';
    dcExpense.BaseLanguageURL := 'js/dtlang_';
    dcExpense.UseFieldIndex := True;
    dcExpense.ShowSearch := False;
    dcExpense.ShowNumberOfEntries := False;
    dcExpense.ShowEntriesInfo := False;
    dcExpense.Paginate := True;
    dcExpense.DisplayReadOnly := False;
    dcExpense.CalculateTableWidth := True;
    dcExpense.Left := 32;
    dcExpense.Top := 168;
  finally
    alForm.AfterLoadDFMValues;
    dsExpense.AfterLoadDFMValues;
    dcExpense.AfterLoadDFMValues;
  end;
end;

end.
