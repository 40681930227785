unit Forms.ArchivePetition;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, lib.bootstrap,
  Forms.DossierDetail, Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper;


type
  TfrmBase = TfrmDossierDetail;

  TfrmArchivePetition = class(TfrmBase)
    dsPetition: TP2WDADataset;
    dsPetitiondosid: TLargeintField;
    dsPetitiondoscreatedon: TDateTimeField;
    dsPetitiondoscreatedbyfk: TLargeintField;
    dsPetitiondoschangedon: TDateTimeField;
    dsPetitiondoschangedbyfk: TLargeintField;
    dsPetitiondosclosedon: TDateTimeField;
    dsPetitiondosdescription: TWideStringField;
    dsPetitiondoscompanyfk: TLargeintField;
    dsPetitiondosstatus: TSmallintField;
    dsPetitiondosdatesent: TDateTimeField;
    dsPetitiondosclosedbyfk: TLargeintField;
    dsPetitiondoscloseddescription: TWideStringField;
    dsPetitiondosisbankrupt: TBooleanField;
    dsPetitiondosbankrupton: TDateTimeField;
    dsPetitiondosiskbocancel: TBooleanField;
    dsPetitiondoskbocancelon: TDateTimeField;
    dsPetitiondosloginfk: TLargeintField;
    dsPetitiondosremarks: TMemoField;
    dsPetitiondosreopenedon: TDateTimeField;
    dsPetitiondosflags: TIntegerField;
    dsPetitiondosmonthlyamount: TFMTBCDField;
    dsPetitiondosiscomplete: TBooleanField;
    dsPetitiondoscasefilecounter: TIntegerField;
    dsPetitiondosdebtcauses: TMemoField;
    procedure DoClosePetition(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure OnCloseConfirm(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); override;
    procedure WebFormShow(Sender: TObject);
    procedure dsPetitionAfterOpen(DataSet: TDataSet);
    procedure ClearErrorsAndmsg(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }
    FDescription : String;
    function FieldsValid: Boolean;
  public
    procedure setParams(const Params: TStrings); override;
    Class Function NeedReadOnlyCheck : Boolean; override;
  protected procedure LoadDFMValues; override; end;

var
  frmArchivePetition: TfrmArchivePetition;

implementation

{$R *.dfm}

uses
  Units.Strings, StrUtils, Units.Types, Units.ActionUtils, Modules.Server, lib.jqueryhelpers;

procedure TfrmArchivePetition.ClearErrorsAndmsg(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  DoClearError(Sender,Element,Event);
  alForm['divArchiveErrorBlock'].AddClass('d-none');
end;

procedure TfrmArchivePetition.DoClosePetition(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);

    procedure OnCloseSuccess(anID : Int64; aSuccess: Boolean; anError: String);
    begin
      if not aSuccess then
        begin
        Server.ShowError(anError);
        alForm['divArchiveErrorBlock'].RemoveClass('d-none');
        alForm['lblArchiveErrorMessage'].Value:=anError;
        end
      else
        window.location.href := '#/petition';
    end;

    Procedure DoArchive;

    begin
      Server.DoClosePetition(getRadioGroupValue('rdGroupCloseReason'), DossierID, Trim(alForm['edtCloseRemark'].value), @OnCloseSuccess)
    end;

var
  aMsg : string;

begin
  alForm['divArchiveErrorBlock'].AddClass('d-none');
  if FieldsValid then
    begin
    aMsg:=Format(SConfirmArchivePetition,[FDescription]);
    DefaultConfirmation.Execute(aMsg,@DoArchive);
    end;
end;

procedure TfrmArchivePetition.dsPetitionAfterOpen(DataSet: TDataSet);

Var
  aStatus : TDossierStatus;

begin
  if DataSet.isEmpty then
    exit;
  FDescription:=dsPetitiondosdescription.AsString;
  alForm['edtCloseRemark'].value:=dsPetitiondoscloseddescription.asString;
  aStatus.AsInteger:=dsPetitiondosStatus.AsInteger;
  if aStatus in [dsArchivedCancelled, dsArchivedSubmitted] then
    setRadiogroupSelectedElement('rdGroupCloseReason',IfThen(aStatus=dsArchivedCancelled,'csCancelled','csCourtCase'));
end;

function TfrmArchivePetition.FieldsValid: Boolean;

begin
  Result := isSelectedRadioGroup('rdGroupCloseReason');
  if (Length(Trim(alForm['edtCloseRemark'].value)) < 10) then
  begin
    Result := False;
    DisplayError('edtCloseRemark');
  end;
end;

class function TfrmArchivePetition.NeedReadOnlyCheck: Boolean;
begin
  Result:=True;
end;

procedure TfrmArchivePetition.OnCloseConfirm(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);

    procedure OnCloseSuccess(anID : Int64; aSuccess: Boolean; anError: String);
    begin
      if not aSuccess then
      begin
        JQuery('#pCloseError').text(anError);
        JQuery('#closePetitionError').modal(New(['backdrop', 'static']));
      end
      else
      begin
        window.location.href := '#/petition';
      end;

    end;
begin
  Server.DoClosePetition(getRadioGroupValue('rdGroupCloseReason'), DossierID, Trim(alForm['edtCloseRemark'].value), @OnCloseSuccess)
end;


procedure TfrmArchivePetition.setParams(const Params: TStrings);
begin
  inherited;
  dsPetition.ParamByName('DOSSIERID').asLargeInt := DossierID;
end;

procedure TfrmArchivePetition.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.PetitionConnection.Message.ClientID := Server.ClientID;
  dsPetition.DAConnection := Server.PetitionConnection;
end;

procedure TfrmArchivePetition.WebFormShow(Sender: TObject);
begin
  inherited;
  dsPetition.Load([], nil);
end;

//   dsPetition.ParamByName('DOSSIERID').asLargeInt := DossierID;

procedure TfrmArchivePetition.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsPetition := TP2WDADataset.Create(Self);
  dsPetitiondosid := TLargeintField.Create(Self);
  dsPetitiondoscreatedon := TDateTimeField.Create(Self);
  dsPetitiondoscreatedbyfk := TLargeintField.Create(Self);
  dsPetitiondoschangedon := TDateTimeField.Create(Self);
  dsPetitiondoschangedbyfk := TLargeintField.Create(Self);
  dsPetitiondosclosedon := TDateTimeField.Create(Self);
  dsPetitiondosdescription := TWideStringField.Create(Self);
  dsPetitiondoscompanyfk := TLargeintField.Create(Self);
  dsPetitiondosstatus := TSmallintField.Create(Self);
  dsPetitiondosdatesent := TDateTimeField.Create(Self);
  dsPetitiondosclosedbyfk := TLargeintField.Create(Self);
  dsPetitiondoscloseddescription := TWideStringField.Create(Self);
  dsPetitiondosisbankrupt := TBooleanField.Create(Self);
  dsPetitiondosbankrupton := TDateTimeField.Create(Self);
  dsPetitiondosiskbocancel := TBooleanField.Create(Self);
  dsPetitiondoskbocancelon := TDateTimeField.Create(Self);
  dsPetitiondosloginfk := TLargeintField.Create(Self);
  dsPetitiondosremarks := TMemoField.Create(Self);
  dsPetitiondosreopenedon := TDateTimeField.Create(Self);
  dsPetitiondosflags := TIntegerField.Create(Self);
  dsPetitiondosmonthlyamount := TFMTBCDField.Create(Self);
  dsPetitiondosiscomplete := TBooleanField.Create(Self);
  dsPetitiondoscasefilecounter := TIntegerField.Create(Self);
  dsPetitiondosdebtcauses := TMemoField.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsPetition.BeforeLoadDFMValues;
  dsPetitiondosid.BeforeLoadDFMValues;
  dsPetitiondoscreatedon.BeforeLoadDFMValues;
  dsPetitiondoscreatedbyfk.BeforeLoadDFMValues;
  dsPetitiondoschangedon.BeforeLoadDFMValues;
  dsPetitiondoschangedbyfk.BeforeLoadDFMValues;
  dsPetitiondosclosedon.BeforeLoadDFMValues;
  dsPetitiondosdescription.BeforeLoadDFMValues;
  dsPetitiondoscompanyfk.BeforeLoadDFMValues;
  dsPetitiondosstatus.BeforeLoadDFMValues;
  dsPetitiondosdatesent.BeforeLoadDFMValues;
  dsPetitiondosclosedbyfk.BeforeLoadDFMValues;
  dsPetitiondoscloseddescription.BeforeLoadDFMValues;
  dsPetitiondosisbankrupt.BeforeLoadDFMValues;
  dsPetitiondosbankrupton.BeforeLoadDFMValues;
  dsPetitiondosiskbocancel.BeforeLoadDFMValues;
  dsPetitiondoskbocancelon.BeforeLoadDFMValues;
  dsPetitiondosloginfk.BeforeLoadDFMValues;
  dsPetitiondosremarks.BeforeLoadDFMValues;
  dsPetitiondosreopenedon.BeforeLoadDFMValues;
  dsPetitiondosflags.BeforeLoadDFMValues;
  dsPetitiondosmonthlyamount.BeforeLoadDFMValues;
  dsPetitiondosiscomplete.BeforeLoadDFMValues;
  dsPetitiondoscasefilecounter.BeforeLoadDFMValues;
  dsPetitiondosdebtcauses.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdCloseReason';
      PreventDefault := False;
      Selector := 'input[name="rdGroupCloseReason"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'ClearErrorsAndmsg');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtCloseRemark';
      Name := 'edtCloseRemark';
      SetEvent(Self, 'OnExecute', 'ClearErrorsAndmsg');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnClosePetition';
      Name := 'btnClosePetition';
      SetEvent(Self, 'OnExecute', 'DoClosePetition');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnCloseConfirm');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'lblArchiveErrorMessage';
      Name := 'lblArchiveErrorMessage';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'divArchiveErrorBlock';
      Name := 'divArchiveErrorBlock';
    end;
    dsPetition.SetParentComponent(Self);
    dsPetition.Name := 'dsPetition';
    dsPetition.TableName := 'dossier';
    dsPetition.DAConnection := dmServer.PetitionConnection;
    dsPetition.Params.Clear;
    with dsPetition.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsPetition.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>dosid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsPetition.DAOptions := [];
    dsPetition.AfterOpen := dsPetitionAfterOpen;
    dsPetition.Left := 40;
    dsPetition.Top := 88;
    dsPetitiondosid.SetParentComponent(dsPetition);
    dsPetitiondosid.Name := 'dsPetitiondosid';
    dsPetitiondosid.FieldName := 'dosid';
    dsPetitiondoscreatedon.SetParentComponent(dsPetition);
    dsPetitiondoscreatedon.Name := 'dsPetitiondoscreatedon';
    dsPetitiondoscreatedon.FieldName := 'doscreatedon';
    dsPetitiondoscreatedbyfk.SetParentComponent(dsPetition);
    dsPetitiondoscreatedbyfk.Name := 'dsPetitiondoscreatedbyfk';
    dsPetitiondoscreatedbyfk.FieldName := 'doscreatedbyfk';
    dsPetitiondoschangedon.SetParentComponent(dsPetition);
    dsPetitiondoschangedon.Name := 'dsPetitiondoschangedon';
    dsPetitiondoschangedon.FieldName := 'doschangedon';
    dsPetitiondoschangedbyfk.SetParentComponent(dsPetition);
    dsPetitiondoschangedbyfk.Name := 'dsPetitiondoschangedbyfk';
    dsPetitiondoschangedbyfk.FieldName := 'doschangedbyfk';
    dsPetitiondosclosedon.SetParentComponent(dsPetition);
    dsPetitiondosclosedon.Name := 'dsPetitiondosclosedon';
    dsPetitiondosclosedon.FieldName := 'dosclosedon';
    dsPetitiondosdescription.SetParentComponent(dsPetition);
    dsPetitiondosdescription.Name := 'dsPetitiondosdescription';
    dsPetitiondosdescription.FieldName := 'dosdescription';
    dsPetitiondosdescription.Size := 127;
    dsPetitiondoscompanyfk.SetParentComponent(dsPetition);
    dsPetitiondoscompanyfk.Name := 'dsPetitiondoscompanyfk';
    dsPetitiondoscompanyfk.FieldName := 'doscompanyfk';
    dsPetitiondosstatus.SetParentComponent(dsPetition);
    dsPetitiondosstatus.Name := 'dsPetitiondosstatus';
    dsPetitiondosstatus.FieldName := 'dosstatus';
    dsPetitiondosdatesent.SetParentComponent(dsPetition);
    dsPetitiondosdatesent.Name := 'dsPetitiondosdatesent';
    dsPetitiondosdatesent.FieldName := 'dosdatesent';
    dsPetitiondosclosedbyfk.SetParentComponent(dsPetition);
    dsPetitiondosclosedbyfk.Name := 'dsPetitiondosclosedbyfk';
    dsPetitiondosclosedbyfk.FieldName := 'dosclosedbyfk';
    dsPetitiondoscloseddescription.SetParentComponent(dsPetition);
    dsPetitiondoscloseddescription.Name := 'dsPetitiondoscloseddescription';
    dsPetitiondoscloseddescription.FieldName := 'doscloseddescription';
    dsPetitiondoscloseddescription.Size := 127;
    dsPetitiondosisbankrupt.SetParentComponent(dsPetition);
    dsPetitiondosisbankrupt.Name := 'dsPetitiondosisbankrupt';
    dsPetitiondosisbankrupt.FieldName := 'dosisbankrupt';
    dsPetitiondosbankrupton.SetParentComponent(dsPetition);
    dsPetitiondosbankrupton.Name := 'dsPetitiondosbankrupton';
    dsPetitiondosbankrupton.FieldName := 'dosbankrupton';
    dsPetitiondosiskbocancel.SetParentComponent(dsPetition);
    dsPetitiondosiskbocancel.Name := 'dsPetitiondosiskbocancel';
    dsPetitiondosiskbocancel.FieldName := 'dosiskbocancel';
    dsPetitiondoskbocancelon.SetParentComponent(dsPetition);
    dsPetitiondoskbocancelon.Name := 'dsPetitiondoskbocancelon';
    dsPetitiondoskbocancelon.FieldName := 'doskbocancelon';
    dsPetitiondosloginfk.SetParentComponent(dsPetition);
    dsPetitiondosloginfk.Name := 'dsPetitiondosloginfk';
    dsPetitiondosloginfk.FieldName := 'dosloginfk';
    dsPetitiondosremarks.SetParentComponent(dsPetition);
    dsPetitiondosremarks.Name := 'dsPetitiondosremarks';
    dsPetitiondosremarks.FieldName := 'dosremarks';
    dsPetitiondosremarks.BlobType := ftMemo;
    dsPetitiondosreopenedon.SetParentComponent(dsPetition);
    dsPetitiondosreopenedon.Name := 'dsPetitiondosreopenedon';
    dsPetitiondosreopenedon.FieldName := 'dosreopenedon';
    dsPetitiondosflags.SetParentComponent(dsPetition);
    dsPetitiondosflags.Name := 'dsPetitiondosflags';
    dsPetitiondosflags.FieldName := 'dosflags';
    dsPetitiondosmonthlyamount.SetParentComponent(dsPetition);
    dsPetitiondosmonthlyamount.Name := 'dsPetitiondosmonthlyamount';
    dsPetitiondosmonthlyamount.FieldName := 'dosmonthlyamount';
    dsPetitiondosmonthlyamount.Precision := 12;
    dsPetitiondosmonthlyamount.Size := 0;
    dsPetitiondosiscomplete.SetParentComponent(dsPetition);
    dsPetitiondosiscomplete.Name := 'dsPetitiondosiscomplete';
    dsPetitiondosiscomplete.FieldName := 'dosiscomplete';
    dsPetitiondoscasefilecounter.SetParentComponent(dsPetition);
    dsPetitiondoscasefilecounter.Name := 'dsPetitiondoscasefilecounter';
    dsPetitiondoscasefilecounter.FieldName := 'doscasefilecounter';
    dsPetitiondosdebtcauses.SetParentComponent(dsPetition);
    dsPetitiondosdebtcauses.Name := 'dsPetitiondosdebtcauses';
    dsPetitiondosdebtcauses.FieldName := 'dosdebtcauses';
    dsPetitiondosdebtcauses.BlobType := ftMemo;
  finally
    alForm.AfterLoadDFMValues;
    dsPetition.AfterLoadDFMValues;
    dsPetitiondosid.AfterLoadDFMValues;
    dsPetitiondoscreatedon.AfterLoadDFMValues;
    dsPetitiondoscreatedbyfk.AfterLoadDFMValues;
    dsPetitiondoschangedon.AfterLoadDFMValues;
    dsPetitiondoschangedbyfk.AfterLoadDFMValues;
    dsPetitiondosclosedon.AfterLoadDFMValues;
    dsPetitiondosdescription.AfterLoadDFMValues;
    dsPetitiondoscompanyfk.AfterLoadDFMValues;
    dsPetitiondosstatus.AfterLoadDFMValues;
    dsPetitiondosdatesent.AfterLoadDFMValues;
    dsPetitiondosclosedbyfk.AfterLoadDFMValues;
    dsPetitiondoscloseddescription.AfterLoadDFMValues;
    dsPetitiondosisbankrupt.AfterLoadDFMValues;
    dsPetitiondosbankrupton.AfterLoadDFMValues;
    dsPetitiondosiskbocancel.AfterLoadDFMValues;
    dsPetitiondoskbocancelon.AfterLoadDFMValues;
    dsPetitiondosloginfk.AfterLoadDFMValues;
    dsPetitiondosremarks.AfterLoadDFMValues;
    dsPetitiondosreopenedon.AfterLoadDFMValues;
    dsPetitiondosflags.AfterLoadDFMValues;
    dsPetitiondosmonthlyamount.AfterLoadDFMValues;
    dsPetitiondosiscomplete.AfterLoadDFMValues;
    dsPetitiondoscasefilecounter.AfterLoadDFMValues;
    dsPetitiondosdebtcauses.AfterLoadDFMValues;
  end;
end;

end.