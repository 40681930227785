unit Modules.Server;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  db,
  Units.Service.Dezq,
  rosdk,
  pas2web.dadataset,
  pas2web.dadatasethelper,
  lib.eventqueue,
  WEBLib.Modules,
  Units.Params,
  Units.ServerConfig,
  Datasnap.DBClient,
  Data.db;

const
  // DefaultServerURL = 'http://172.16.220.128:9090/';
  DefaultServerURL = '/Proxy/server/';
  KeySessionID = 'ROSessionID';
  KeyLanguage = 'UILanguage';

type
  TFailNotifyEvent = Reference to procedure(Sender: TObject; aError: string);
  TEmptyCallback = Reference to procedure;
  TStringEvent = Reference to procedure(aResult: string);
  TIDValueEvent = Reference to procedure(aVAlue: string; ID: Int64);
  TSuccessCallback = Reference to procedure(aSuccess: Boolean; anError: string);
  TIDResult = Reference to procedure(anID: Int64; aSuccess: Boolean; anError: string);
  TCSRSearchResult = Reference to procedure(searchPetitionResult: TSearchPetitionResult);
  TOnAfterOpenDataSet = Reference to procedure(Sender: TObject; Data: TJSArray);
  TReadOnlyPetitionCallback = Reference to procedure(Sender: TObject; aPetitionID: NativeInt; aIsReadOnly: Boolean);
  TCheckPetitionCopyResultCallback = Reference to procedure(PetitionCopyResult: TCheckPetitionCopyResult);
  TNewSettlementCreateCallback = Reference to procedure(aResult: TSettlementPlanCreateResult);
  TMessageStatisticsResult = Reference to procedure(aResult: TMessageStatistics);

  TUserType = (utNone, utDebtor, utEmployee, utCustomer, utAdmin);

  TParamCallback = Reference to procedure(RecordID: Integer; name: string; Language: Integer; ParamType: string; Value: string; ValueType: Integer; Extra: string; isLast: Boolean);

  TEmtyResultCallBack = Reference to procedure;

  TSimpleSearchCallback = Reference to procedure(contactData: TContactSearchResult; ContactTelecomItems: TContactTelecomItems; ContactAddressItems: TContactAddressItems; isLast: Boolean);
  TDataTableSimpleSearchCallback = Reference to procedure(contactDataArr: TJSArray);

  { TdmServer }

  TdmServer = class(TDataModule)
    CompanyConnection: TP2WDAConnection;
    DataConnection: TP2WDAConnection;
    ContactConnection: TP2WDAConnection;
    GeoConnection: TP2WDAConnection;
    PetitionConnection: TP2WDAConnection;
    tblContactAddress: TP2WDADataset;
    tblContactAddressctaid: TLargeintField;
    tblContactAddressctacreatedon: TDateTimeField;
    tblContactAddressctacreatedbyfk: TLargeintField;
    tblContactAddressctachangedon: TDateTimeField;
    tblContactAddressctachangedbyfk: TLargeintField;
    tblContactAddressctacontactfk: TLargeintField;
    tblContactAddressctaaddresstypefk: TLargeintField;
    tblContactAddressctaaddresstype: TStringField;
    tblContactAddressctastreet: TStringField;
    tblContactAddressctastreetnr: TStringField;
    tblContactAddressctacityzip: TStringField;
    tblContactAddressctacityname: TStringField;
    tblContactAddressctacityfk: TLargeintField;
    tblContactAddressctaremark: TStringField;
    tblContactAddressctacorrespondence: TBooleanField;
    tblContactAddressctasecret: TBooleanField;
    tblDebt: TP2WDADataset;
    tblDebtdebid: TLargeintField;
    tblDebtdebcreatedon: TDateTimeField;
    tblDebtdebcreatedbyfk: TLargeintField;
    tblDebtdebchangedon: TDateTimeField;
    tblDebtdebchangedbyfk: TLargeintField;
    tblDebtdebdossierfk: TLargeintField;
    tblDebtdebrecordtype: TSmallintField;
    tblDebtdebcreditorfk: TLargeintField;
    tblDebtdebdebttypefk: TLargeintField;
    tblDebtdebdebttype: TStringField;
    tblDebtdebstarton: TDateTimeField;
    tblDebtdebreference: TStringField;
    tblDebtdeboriginalamount: TFMTBCDField;
    tblDebtdebcurrentamount: TFMTBCDField;
    tblDebtdebmonthlyamount: TFMTBCDField;
    tblDebtdebisdisputed: TBooleanField;
    tblDebtdebdisputereason: TStringField;
    tblDebtdebdisputecourt: TStringField;
    tblDebtdebdisputedamount: TFMTBCDField;
    tblDebtdebisdelay: TBooleanField;
    tblDebtdebdelayamount: TFMTBCDField;
    tblDebtdebguarantorfk: TLargeintField;
    tblDebtdebiscompleted: TBooleanField;
    tblDossier: TP2WDADataset;
    tblDossierdosid: TLargeintField;
    tblDossierdoscreatedon: TDateTimeField;
    tblDossierdoscreatedbyfk: TLargeintField;
    tblDossierdoschangedon: TDateTimeField;
    tblDossierdoschangedbyfk: TLargeintField;
    tblDossierdosclosedon: TDateTimeField;
    tblDossierdosdescription: TStringField;
    tblDossierdoscompanyfk: TLargeintField;
    tblDossierdosstatus: TSmallintField;
    tblDossierdosdatesent: TDateTimeField;
    tblDossierdosclosedbyfk: TLargeintField;
    tblDossierdoscloseddescription: TStringField;
    tblDossierdosisbankrupt: TBooleanField;
    tblDossierdosbankrupton: TDateTimeField;
    tblDossierdosiskbocancel: TBooleanField;
    tblDossierdoskbocancelon: TDateTimeField;
    tblDossierdosloginfk: TLargeintField;
    tblDossierdosremarks: TBlobField;
    tblDossierdosreopenedon: TDateTimeField;
    tblDossierdosflags: TIntegerField;
    tblDossierdosmonthlyamount: TFMTBCDField;
    tblDossierdosiscomplete: TBooleanField;
    tblDossierdoscasefilecounter: TIntegerField;
    tblDossierFile: TP2WDADataset;
    tblDossierFiledofid: TLargeintField;
    tblDossierFiledofcreatedon: TDateTimeField;
    tblDossierFiledofcreatedbyfk: TLargeintField;
    tblDossierFiledofchangedon: TDateTimeField;
    tblDossierFiledofchangedbyfk: TLargeintField;
    tblDossierFiledofdossierfk: TLargeintField;
    tblDossierFiledofsourcefk: TLargeintField;
    tblDossierFiledofsourcetype: TSmallintField;
    tblDossierFiledofdoctypefk: TLargeintField;
    tblDossierFiledofdoctype: TStringField;
    tblDossierFiledoffilename: TStringField;
    tblDossierFiledofmime: TStringField;
    tblDossierFiledofdata: TBlobField;
    tblDossierFiledofisuploadcomplete: TBooleanField;
    tblDossierFiledofuploaduserfk: TLargeintField;
    tblDossierFiledofcasefilenumber: TIntegerField;
    tblDossierPersons: TP2WDADataset;
    tblDossierPersonscntid: TLargeintField;
    tblDossierPersonscntcreatedon: TDateTimeField;
    tblDossierPersonscntcreatedbyfk: TLargeintField;
    tblDossierPersonscntchangedon: TDateTimeField;
    tblDossierPersonscntchangedbyfk: TLargeintField;
    tblDossierPersonscntcompanyfk: TLargeintField;
    tblDossierPersonscntfirstname: TWideStringField;
    tblDossierPersonscntlastname: TWideStringField;
    tblDossierPersonscntprofession: TWideStringField;
    tblDossierPersonscntbirthdateon: TDateTimeField;
    tblDossierPersonscntisbirthdateunknown: TBooleanField;
    tblDossierPersonscntgender: TWideStringField;
    tblDossierPersonscntcityofbirthfk: TLargeintField;
    tblDossierPersonscntcityofbirthname: TWideStringField;
    tblDossierPersonscntcityofbirthzip: TWideStringField;
    tblDossierPersonscntnationalityfk: TLargeintField;
    tblDossierPersonscntnationality2: TWideStringField;
    tblDossierPersonscntnationality: TWideStringField;
    tblDossierPersonscntpicturefk: TLargeintField;
    tblDossierPersonscntkbonr: TWideStringField;
    tblDossierPersonscntremark: TWideStringField;
    tblDossierPersonscntpersonfk: TLargeintField;
    tblDossierPersonscntsalutation: TWideStringField;
    tblDossierPersonscntsearchname: TWideStringField;
    tblDossierPersonscntprefixes: TWideStringField;
    tblDossierPersonscntfriendlytitle: TWideStringField;
    tblDossierPersonsctaid: TLargeintField;
    tblDossierPersonsctacreatedon: TDateTimeField;
    tblDossierPersonsctacreatedbyfk: TLargeintField;
    tblDossierPersonsctachangedon: TDateTimeField;
    tblDossierPersonsctachangedbyfk: TLargeintField;
    tblDossierPersonsctacontactfk: TLargeintField;
    tblDossierPersonsctaaddresstypefk: TLargeintField;
    tblDossierPersonsctaaddresstype: TWideStringField;
    tblDossierPersonsctastreet: TWideStringField;
    tblDossierPersonsctastreetnr: TWideStringField;
    tblDossierPersonsctacityzip: TWideStringField;
    tblDossierPersonsctacityname: TWideStringField;
    tblDossierPersonsctacityfk: TLargeintField;
    tblDossierPersonsctaremark: TWideStringField;
    tblDossierPersonsctacorrespondence: TBooleanField;
    tblDossierPersonsctasecret: TBooleanField;
    tblDossierPersonsdocid: TLargeintField;
    tblDossierPersonsdoccivilstatus: TWideStringField;
    tblDossierPersonsdoccivilstatusfk: TLargeintField;
    tblDossierPersonsdocissecondpetitioner: TBooleanField;
    tblDossierPersonsdocmarcon: TWideStringField;
    tblDossierPersonsdocmarconfk: TLargeintField;
    tblDossierPersonsdocpersontype: TWideStringField;
    tblDossierPersonsdocpersontypefk: TLargeintField;
    tblExpense: TP2WDADataset;
    tblExpenseexpid: TLargeintField;
    tblExpenseexpcreatedon: TDateTimeField;
    tblExpenseexpcreatedbyfk: TLargeintField;
    tblExpenseexpchangedon: TDateTimeField;
    tblExpenseexpchangedbyfk: TLargeintField;
    tblExpenseexpdossierfk: TLargeintField;
    tblExpenseexpcategoryfk: TLargeintField;
    tblExpenseexpcategory: TStringField;
    tblExpenseexpkindfk: TLargeintField;
    tblExpenseexpkind: TStringField;
    tblExpenseexppaymenton: TSmallintField;
    tblExpenseexppaymentperiodfk: TLargeintField;
    tblExpenseexppaymentperiod: TStringField;
    tblExpenseexpamount: TFMTBCDField;
    tblExpenseexpremark: TStringField;
    tblLegalCase: TP2WDADataset;
    tblLegalCaselecid: TLargeintField;
    tblLegalCaseleccreatedon: TDateTimeField;
    tblLegalCaseleccreatedbyfk: TLargeintField;
    tblLegalCaselecchangedon: TDateTimeField;
    tblLegalCaselecchangedbyfk: TLargeintField;
    tblLegalCaselecdossierfk: TLargeintField;
    tblLegalCaseleccause: TStringField;
    tblLegalCaselecprimaryfk: TLargeintField;
    tblLegalCaselecopponentfk: TLargeintField;
    tblLegalCaseleclawyerfk: TLargeintField;
    tblLegalCaseleccourt: TStringField;
    tblLegalCaselecreference: TStringField;
    tblLegalCaselecremark: TStringField;
    tblLegalCaselecamount: TFMTBCDField;
    tblLegalCaseleciscomplete: TBooleanField;
    tblPaymentPlan: TP2WDADataset;
    tblPaymentPlanpplid: TLargeintField;
    tblPaymentPlanpplcreatedon: TDateTimeField;
    tblPaymentPlanpplcreatedbyfk: TLargeintField;
    tblPaymentPlanpplchangedon: TDateTimeField;
    tblPaymentPlanpplchangedbyfk: TLargeintField;
    tblPaymentPlanppldossierfk: TLargeintField;
    tblPaymentPlanDetail: TP2WDADataset;
    tblPaymentPlanDetailppdid: TLargeintField;
    tblPaymentPlanDetailppdcreatedon: TDateTimeField;
    tblPaymentPlanDetailppdcreatedbyfk: TLargeintField;
    tblPaymentPlanDetailppdchangedon: TDateTimeField;
    tblPaymentPlanDetailppdchangedbyfk: TLargeintField;
    tblPaymentPlanDetailppdpaymentplanfk: TLargeintField;
    tblPaymentPlanDetailppdamount: TFMTBCDField;
    tblPaymentPlanDetailppdtotalamount: TFMTBCDField;
    tblPaymentPlanDetailppdpaymenttofk: TLargeintField;
    tblPaymentPlanDetailppdiban: TStringField;
    tblPaymentPlanDetailppdname: TStringField;
    tblPaymentPlanDetailppdperiodicityfk: TLargeintField;
    tblPaymentPlanDetailppdperiodicity: TStringField;
    tblProperty: TP2WDADataset;
    tblPropertyproid: TLargeintField;
    tblPropertyprocreatedon: TDateTimeField;
    tblPropertyprocreatedbyfk: TLargeintField;
    tblPropertyprochangedon: TDateTimeField;
    tblPropertyprochangedbyfk: TLargeintField;
    tblPropertyprodossierfk: TLargeintField;
    tblPropertypropropertytype: TStringField;
    tblPropertypropropertytypefk: TLargeintField;
    tblPropertyproowner: TStringField;
    tblPropertyproownerfk: TLargeintField;
    tblPropertyproisrealestate: TBooleanField;
    tblPropertyproshortdescription: TStringField;
    tblPropertyprodescription: TBlobField;
    tblPropertyprocount: TIntegerField;
    tblPropertypropurchasedon: TDateTimeField;
    tblPropertypronotaryname: TStringField;
    tblPropertypropurchaseprice: TFMTBCDField;
    tblPropertyprocurrentvalue: TFMTBCDField;
    tblPropertyproisfinanced: TBooleanField;
    tblPropertyproisseized: TBooleanField;
    tblPropertyproisshared: TBooleanField;
    tblPropertyproisgone: TBooleanField;
    tblPropertyprogoneon: TDateTimeField;
    tblPropertyprobuyerfk: TLargeintField;
    tblPropertyproiscomplete: TBooleanField;
    tblPropertyproremark: TStringField;
    tblRevenue: TP2WDADataset;
    tblRevenuerevid: TLargeintField;
    tblRevenuerevcreatedon: TDateTimeField;
    tblRevenuerevcreatedbyfk: TLargeintField;
    tblRevenuerevchangedon: TDateTimeField;
    tblRevenuerevchangedbyfk: TLargeintField;
    tblRevenuerevdossierfk: TLargeintField;
    tblRevenuerevrevenuetypefk: TLargeintField;
    tblRevenuerevrevenuetype: TWideStringField;
    tblRevenuerevamount: TBCDField;
    tblRevenuerevperiodtypefk: TLargeintField;
    tblRevenuerevperiodtype: TWideStringField;
    tblRevenuerevviatypefk: TLargeintField;
    tblRevenuerevviatype: TWideStringField;
    tblRevenuerevfromfk: TLargeintField;
    tblRevenuerevremark: TWideStringField;
    tblRevenuereviscomplete: TBooleanField;
    tblRevenuerevorigin: TWideMemoField;
    tblAccountInfo: TP2WDADataset;
    tblCompany: TP2WDADataset;
    tblCompanyOverview: TP2WDADataset;
    tblCompanyOverviewcomid: TLargeintField;
    tblCompanyOverviewcomname: TWideStringField;
    tblCompanyOverviewcomstreet: TWideStringField;
    tblCompanyOverviewcomhousenr: TWideStringField;
    tblCompanyOverviewcomzip: TWideStringField;
    tblCompanyOverviewcomcity: TWideStringField;
    tblCompanyOverviewcomcountry: TWideStringField;
    tblCompanycomid: TLargeintField;
    tblCompanycomcreatedon: TDateTimeField;
    tblCompanycomcreatedbyfk: TLargeintField;
    tblCompanycomchangedon: TDateTimeField;
    tblCompanycomchangedbyfk: TLargeintField;
    tblCompanycomname: TStringField;
    tblCompanycomstreet: TStringField;
    tblCompanycomhousenr: TStringField;
    tblCompanycomhousenrextra: TStringField;
    tblCompanycomzip: TStringField;
    tblCompanycomcity: TStringField;
    tblCompanycomcityfk: TLargeintField;
    tblCompanycomcountry: TStringField;
    tblCompanycomcountryfk: TLargeintField;
    tblCompanycomemail: TStringField;
    tblCompanycombankaccount: TStringField;
    tblCompanycomvat: TStringField;
    tblCompanycomtelephone: TStringField;
    tblCompanycomstatus: TSmallintField;
    tblCompanycompriority: TSmallintField;
    tblCompanycomlanguagefk: TLargeintField;
    tblCompanycomdatabaseid: TSmallintField;
    tblCity: TP2WDADataset;
    tblCityOverview: TP2WDADataset;
    tblCountry: TP2WDADataset;
    tblCountryOverview: TP2WDADataset;
    tblContactAddressOverview: TP2WDADataset;
    tblContactOverview: TP2WDADataset;
    tblContactOverviewcntid: TLargeintField;
    tblContactOverviewcntcreatedon: TDateTimeField;
    tblContactOverviewcntcreatedbyfk: TLargeintField;
    tblContactOverviewcntchangedon: TDateTimeField;
    tblContactOverviewcntchangedbyfk: TLargeintField;
    tblContactOverviewcntcompanyfk: TLargeintField;
    tblContactOverviewcntfirstname: TStringField;
    tblContactOverviewcntlastname: TStringField;
    tblContactOverviewcntprofession: TStringField;
    tblContactOverviewcntbirthdateon: TDateTimeField;
    tblContactOverviewcntisbirthdateunknown: TBooleanField;
    tblContactOverviewcntgender: TStringField;
    tblContactOverviewcntcityofbirthfk: TLargeintField;
    tblContactOverviewcntcityofbirthname: TStringField;
    tblContactOverviewcntcityofbirthzip: TStringField;
    tblContactOverviewcntnationalityfk: TLargeintField;
    tblContactOverviewcntnationality2: TStringField;
    tblContactOverviewcntnationality: TStringField;
    tblContactOverviewcntpicturefk: TLargeintField;
    tblContactOverviewcntkbonr: TStringField;
    tblContactOverviewcntremark: TStringField;
    tblContactOverviewcntpersonfk: TLargeintField;
    tblContactOverviewcntsalutation: TStringField;
    tblContactOverviewcntsearchname: TStringField;
    tblContactOverviewcntprefixes: TStringField;
    tblContactOverviewcntfriendlytitle: TStringField;
    tblContactAddressOverviewctaid: TLargeintField;
    tblContactAddressOverviewctacreatedon: TDateTimeField;
    tblContactAddressOverviewctacreatedbyfk: TLargeintField;
    tblContactAddressOverviewctachangedon: TDateTimeField;
    tblContactAddressOverviewctachangedbyfk: TLargeintField;
    tblContactAddressOverviewctacontactfk: TLargeintField;
    tblContactAddressOverviewctaaddresstypefk: TLargeintField;
    tblContactAddressOverviewctaaddresstype: TStringField;
    tblContactAddressOverviewctastreet: TStringField;
    tblContactAddressOverviewctastreetnr: TStringField;
    tblContactAddressOverviewctacityzip: TStringField;
    tblContactAddressOverviewctacityname: TStringField;
    tblContactAddressOverviewctacityfk: TLargeintField;
    tblContactAddressOverviewctaremark: TStringField;
    tblContactAddressOverviewctacorrespondence: TBooleanField;
    tblContactAddressOverviewctasecret: TBooleanField;
    tblCountryOverviewconID: TLargeintField;
    tblCountryOverviewconISO2: TWideStringField;
    tblCountryOverviewconISO3: TWideStringField;
    tblCountryOverviewconName: TWideStringField;
    tblCountryconid: TLargeintField;
    tblCountryconcreatedon: TDateTimeField;
    tblCountryconcreatedbyfk: TLargeintField;
    tblCountryconchangedon: TDateTimeField;
    tblCountryconchangedbyfk: TLargeintField;
    tblCountryconiso2: TStringField;
    tblCountryconiso3: TStringField;
    tblCountryconname: TStringField;
    tblCityOverviewcitID: TLargeintField;
    tblCityOverviewcitZip: TWideStringField;
    tblCityOverviewcitName: TWideStringField;
    tblCityOverviewconID: TLargeintField;
    tblCityOverviewconISO2: TWideStringField;
    tblCityOverviewconISO3: TWideStringField;
    tblCityOverviewconName: TWideStringField;
    tblCitycitid: TLargeintField;
    tblCitycitcreatedon: TDateTimeField;
    tblCitycitcreatedbyfk: TLargeintField;
    tblCitycitchangedon: TDateTimeField;
    tblCitycitchangedbyfk: TLargeintField;
    tblCitycitcountryfk: TLargeintField;
    tblCitycitzip: TStringField;
    tblCitycitname: TStringField;
    tblContactRelationsOverview: TP2WDADataset;
    tblContactRelationsOverviewctrid: TLargeintField;
    tblContactRelationsOverviewctrcreatedon: TDateTimeField;
    tblContactRelationsOverviewctrcreatedbyfk: TLargeintField;
    tblContactRelationsOverviewctrchangedon: TDateTimeField;
    tblContactRelationsOverviewctrchangedbyfk: TLargeintField;
    tblContactRelationsOverviewctrfromcontactfk: TLargeintField;
    tblContactRelationsOverviewctrtocontactfk: TLargeintField;
    tblContactRelationsOverviewctrrelationtype: TStringField;
    tblContactRelationsOverviewctrrelationtypefk: TLargeintField;
    tblContactTelecomOverview: TP2WDADataset;
    tblContactTelecomOverviewcttid: TLargeintField;
    tblContactTelecomOverviewcttcreatedon: TDateTimeField;
    tblContactTelecomOverviewcttcreatedbyfk: TLargeintField;
    tblContactTelecomOverviewcttchangedon: TDateTimeField;
    tblContactTelecomOverviewcttchangedbyfk: TLargeintField;
    tblContactTelecomOverviewcttcontactfk: TLargeintField;
    tblContactTelecomOverviewcttcontacttypefk: TLargeintField;
    tblContactTelecomOverviewcttcontacttype: TStringField;
    tblContactTelecomOverviewcttdata: TStringField;
    tblContactTelecomOverviewcttremark: TStringField;
    tblContactTelecomOverviewcttcorrespondence: TBooleanField;
    dsParameters: TP2WDADataset;
    ParameterConnection: TP2WDAConnection;
    dsParametersparid: TLargeintField;
    dsParametersparcreatedon: TDateTimeField;
    dsParametersparcreatedbyfk: TLargeintField;
    dsParametersparchangedon: TDateTimeField;
    dsParametersparchangedbyfk: TLargeintField;
    dsParametersparparamtype: TWideStringField;
    dsParametersparvaluetype: TSmallintField;
    dsParametersparvaluename: TWideStringField;
    dsParametersparvalue: TWideStringField;
    dsParametersparextra: TWideStringField;
    dsParametersparlanguage: TSmallintField;
    CSRConnection: TP2WDAConnection;
  private
    class var _instance: TdmServer;
    function DoCreateDataset(aOwner: TComponent; aTableName: string; SkipLoad: Boolean): TP2WDADataset;
    procedure DoLoadOK(DataSet: TDataSet; Data: JSValue);
    procedure DoLoadFailed(DataSet: TDataSet; ID: Integer; const ErrorMsg: string);
    procedure DoLoginOK(result: Boolean; aUserInfo: TRODezqUser);
    procedure DoLogoutOK(res: JSValue);
  private
    FLoginService: TDezqLoginService;
    FOnLogin: TNotifyEvent;
    // FSessionID: String;
    FServerURL: string;
    FUserInfo: TDezqUser;
    FOnLoginFail: TFailNotifyEvent;
    FOnLogout: TNotifyEvent;
    FNumberService: TDezqNumberService;
    FMailService: TDezqMailService;
    FContactService: TDezqContactService;
    FCodaService: TDezqCodaService;
    FSettlementService: TDezqSettlementService;
    FJobService: TDezqJobService;
    FCompanyService: TDezqCompanyService;
    FDocumentService: TDezqDocumentService;
    FPetitionService: TDezqPetitionService;
    FRegisterService: TDezqRegisterService;
    FGeoService: TDezqGeoService;
    FParameterService: TDezqParameterService;
    FCSRService: TCSRService;
    FLoginLanguage: string;
    FDezqParams: TDezqParameterTypeArray;
    FOnParamsLoaded: TEventQueue;
    FReportService: TDezqReportService;
    FReadOnlyDossiers: TJSObject;
    FPontoService: TPontoService;
    function GetUserType: TUserType;
    procedure DoInitializeConnections;
    // Low-level handlers for connection component
    procedure DoLogoutFail(Msg: TROMessage; Err: TJSError);
    procedure DoLoginFail(Msg: TROMessage; Err: string);
    // All routed through this, call our event handlers
    procedure LoginFail(const Msg: string);
    procedure LogoutFail(const Msg: string);
    function GetClientID: string;
    function GetUserLanguage: string;
    procedure OnGetContactDataSucccess(dynArr: TROContactArray; callBack: TSimpleSearchCallback; onEmptyResult: TEmtyResultCallBack);
    procedure DoLoginNeeded(aCallBack: TROCallBack);
    procedure HandleException(aObject: TObject);
    function GetLastCallTime: TDateTime;
    function GetUserID: NativeInt;
    function GetNumericLanguage: Integer;
    function GetNumberService: TDezqNumberService;
    function GetDocumentService: TDezqDocumentService;
    function GetRegisterService: TDezqRegisterService;
    function GetSettlementService: TDezqSettlementService;
    function GetCompanyService: TDezqCompanyService;
    function GetPetitionService: TDezqPetitionService;
    function GetContactService: TDezqContactService;
    function GetMailService: TDezqMailService;
    function GetJobService: TDezqJobService;
    function GetGeoService: TDezqGeoService;
    function TransformContactsToData(Items: TContactArray): TJSArray;
  public
    constructor Create(aOwner: TComponent); override;
    class function Instance: TdmServer;
    procedure LoadDezqParamList;
    procedure Initialize;
    procedure SetConnectionIDS;
    procedure ApplyUserRights;
    procedure CheckSession(S: string);
    function GetLocalData(aKey: string): string;
    procedure SetLocalData(aKey, aVAlue: string);
    procedure DeleteLocalData(aKey: string);
    procedure ShowError(const aError: string; aTitle: string = '');
    procedure ShowOK(const aMessage: string);
    procedure DoLogin(const aUserName, aPassword, aOffice: string);
    procedure DoPasswordReset(const aUserName, aOffice, anEmail: string; successCallBack: TNotifyEvent);
    procedure DoLogout;
    function DoOnParamsLoaded(aEvent: TSimpleEvent): Integer; overload;
    function DoOnParamsLoaded(aEvent: TObjectEventItem.TTEvent; aSender: TObject): Integer; overload;
    function ReformatPhoneNo(phoneNo: string): string;
    procedure isPhoneNumberValid(const phoneNumber: string; callBack: TSuccessCallback);
    procedure isVATNumberValid(const vatNumber: string; callBack: TSuccessCallback);
    procedure isIBANValid(const ibanNumber: string; callBack: TSuccessCallback);
    procedure isNationalNoValid(aNumber, aGender: string; aDateTime: TJSDate; callBack: TSuccessCallback);
    function IsZipCodeValid(zipCode: string): Boolean;
    function reformatNationalNo(const number: string; RemoveFormat: Boolean): string;
    function ValidityFromToDate(aDate: string; IsStart: Boolean): TDateTime;
    function ValidityFromToJSDate(aDate: string; IsStart: Boolean): TJSDate;
    procedure getCityFromZip(zip: string; callBack: TIDValueEvent);
    procedure DoSignOn(Params: TSignonParams; callBack: TSuccessCallback);
    procedure DoCreatePetition(Params: TCreatePetitionParams; callBack: TIDResult);
    procedure DoClosePetition(aCloseStatus: string; aPetitionID: Int64; aRemarks: string; callBack: TIDResult);
    procedure GetParameterList(const aType: string; aLanguage: Integer; callBack: TParamCallback);
    procedure SimpleSearchContacts(const aSearchTerm: string; aRecurse: Boolean; callBack: TSimpleSearchCallback; onEmptyResult: TEmtyResultCallBack);
    procedure SimpleSearchContactsForDatatable(const aSearchTerm: string; SearchInitialLetter: Boolean; callBack: TDataTableSimpleSearchCallback);
    procedure SearchOtherContacts(callBack: TDataTableSimpleSearchCallback);
    procedure GetPetitionContact(petitionID: Int64; contactType: string; aRecurse: Boolean; callBack: TSimpleSearchCallback; onEmptyResult: TEmtyResultCallBack);
    procedure CreateNewFile(DossierID: Int64; SourceType: Integer; SourceID: Int64; DocumentType: string; FileName: string; callBack: TIDResult);
    procedure startUpload(fileID: Int64; onStartSuccess: TStringEvent; onStartFailed: TSuccessCallback);
    procedure endUpload(fileID: Int64; onEndUploadResult: TSuccessCallback);
    procedure GetFileUrl(fileID: Int64; onGetUrlResult: TSuccessCallback);
    procedure DeleteDocument(fileID: Int64; onDeleteCallback: TSuccessCallback);

    procedure CSRCheckExistingPetition(NIDNR: string; successCallBack: TCSRSearchResult; failCallBack: TStringEvent);
    procedure CreateCSR(dataParam: TCSRCreateParams; callBack: TIDResult);
    procedure GetPetitionCopyFields(petitionID: NativeInt; callBack: TIDResult);
    procedure GetJobStatus(JobId: NativeInt; callBack: TIDResult);
    procedure GetJobStatusEx(JobId: NativeInt; callBack: TIDResult);
    procedure OnPetitionCopyFieldsReady(JobId: NativeInt; successCallBack: TCheckPetitionCopyResultCallback; failCallBack: TStringEvent);
    procedure SetPetitionFieldsToCopy(JobId: NativeInt; fields: TSetPetitionCopyActionArray; callBack: TSuccessCallback);
    procedure StartCSRCopy(options: TCopyPetitionToCSROptions; callBack: TIDResult);
    procedure GetCopiedCSRID(copyJobID: NativeInt; callBack: TIDResult);

    procedure CreateNewSettlementPlan(options: TSettlementPlanCreateOptions; successCallBack: TNewSettlementCreateCallback; errorCallBak: TStringEvent);
    procedure DeleteSettlementPlan(SettlementId: NativeInt; callBack: TSuccessCallback);
    procedure CopySettlementPlan(options: TCopySettlementPlanOptions; callBack: TIDResult);
    procedure CertifyPayments(options: TCSRCertifyOptions; callBack: TSuccessCallback);

    procedure GetIsPontoAccountLinked(CompanyID: NativeInt; callBack: TSuccessCallback);
    procedure GetCompanyDossierID(DossierID: NativeInt; callBack: TIDResult);
    procedure checkAccountTransactions(AccountID: NativeInt; callBack: TIDResult);
    procedure LinkAccountToPonto(AccountID: NativeInt; callBack: TIDResult);
    procedure IsBankAccountLinkedToPonto(AccountID: NativeInt; callBack: TSuccessCallback);

    procedure GetMessagesStats(startDate, endDate: TDateTime; successCallBack: TMessageStatisticsResult; failCallBack: TSuccessCallback);
    procedure SetMessageStatus(aID: NativeInt; aIsRead: Boolean; callBack: TSuccessCallback);
    procedure DeleteMessage(aID: NativeInt; callBack: TSuccessCallback);

    procedure printReport(DossierID: NativeInt; reportName: string; Params: TReportParameterDynArray; callBack: TIDResult);

    procedure DeleteFileReference(fileID: Int64);
    procedure UpdateFileReference(fileID: Int64; FileName: string);
    procedure CheckPetitionReadOnly(aPetitionID: NativeInt; aCallBack: TReadOnlyPetitionCallback);
    procedure MarkPetitionReadOnly(aPetitionID: NativeInt; isReadonly: Boolean);
    function GetParamList(aParamType: string): TDezqParameterArray;
    function GetParamId(aParamType: string; ParamName: string): Int64;
    function GetParamDisplayValue(aParamType: string; ParamName: string): string;
    procedure CheckLegalReference(aDossier: NativeInt; aReference: string; aLecID: NativeInt; callBack: TSuccessCallback);
    function ServerTimeout: Boolean;
    function ServerTimeoutPending: Boolean;
    function SecondsTillTimeout: Integer;
    procedure DoPing;

    function CheckResolveResults(Info: TResolveResults; aPrefix: string = ''): Boolean; overload;
    function CheckResolveResults(Info: TResolveResults; AOnSuccessProcedure: TEmptyCallback; aPrefix: string = ''): Boolean; overload;

    function CreateDataset(aOwner: TComponent; aTableName: string; SkipLoad: Boolean = False): TP2WDADataset;
    property ServerURL: string read FServerURL;
    // Property SessionID: String Read FSessionID;
    property ClientID: string read GetClientID;
    property OnLogin: TNotifyEvent read FOnLogin write FOnLogin;
    property OnLogout: TNotifyEvent read FOnLogout write FOnLogout;
    property OnLoginFailed: TFailNotifyEvent read FOnLoginFail write FOnLoginFail;
    property UserID: NativeInt read GetUserID;
    property UserInfo: TDezqUser read FUserInfo;
    property UserType: TUserType read GetUserType;
    property NumericLanguage: Integer read GetNumericLanguage;
    property UserLanguage: string read GetUserLanguage;
    property LoginLanguage: string read FLoginLanguage write FLoginLanguage;
    // Services exposed by DEZQ server.
    property LoginService: TDezqLoginService read FLoginService;
    property NumberService: TDezqNumberService read GetNumberService;
    property RegisterService: TDezqRegisterService read GetRegisterService;
    property DocumentService: TDezqDocumentService read GetDocumentService;
    property SettlementService: TDezqSettlementService read GetSettlementService;
    property CompanyService: TDezqCompanyService read GetCompanyService;
    property PetitionService: TDezqPetitionService read GetPetitionService;
    property ContactService: TDezqContactService read GetContactService;
    property MailService: TDezqMailService read GetMailService;
    property JobService: TDezqJobService read GetJobService;
    property CodaService: TDezqCodaService read FCodaService;
    property GeoService: TDezqGeoService read GetGeoService;
    property ParameterService: TDezqParameterService read FParameterService;
    property ReportService: TDezqReportService read FReportService;
    property CSRService: TCSRService read FCSRService;
    property PontoService: TPontoService read FPontoService;
    property OnParamsLoaded: TEventQueue read FOnParamsLoaded;
  protected procedure LoadDFMValues; override; end;

function Server: TdmServer;
function dmServer: TdmServer;

implementation

uses
  Web,
  webrouter,
  Units.Logging,
  Units.Strings,
  Units.Toasts,
  Units.PageHandler,
  StrUtils,
  Units.Types,
  DateUtils;

{$R *.dfm}

function Server: TdmServer;
begin
  result := TdmServer.Instance;
end;

function dmServer: TdmServer;
begin
  result := TdmServer.Instance;
end;

{ TdmServer }

procedure TdmServer.DoLoginOK(result: Boolean; aUserInfo: TRODezqUser);

  procedure DoLangOK(res: Boolean);
  begin
    Log.Log(ltDebug, classname, 'ContinueLogin', 'Setting language OK');
  end;

  procedure DoLangFailed(aResponse: TJSObject; aFail: TJSError);
  begin
    Log.Log(ltDebug, classname, 'ContinueLogin', ExtractErrorMsg(aFail, 'Setting languag failed: %s'));
  end;

  procedure ContinueLogin;
  var
    i: Integer;
    Lang: string;
  begin
    FUserInfo := aUserInfo.toObject;
    for i := low(FUserInfo.Privileges) to high(FUserInfo.Privileges) do
      Log.Log(ltDebug, classname, 'ContinueLogin', 'Privilege : %s', [TJSJSON.Stringify(FUserInfo.Privileges[i])]);
    // get paramlist
    with Server do
    begin
      Lang := GetLocalData(KeyLanguage);
      if (Lang <> '') and (LoginLanguage = '') then
        LoginLanguage := Lang;
      Log.Log(ltDebug, classname, 'ContinueLogin', 'Sending login language: %s', [LoginLanguage]);
      LoginService.SetUserLanguage(LoginLanguage, @DoLangOK, @DoLangFailed);
    end;
    if Assigned(FOnLogin) then
      FOnLogin(Self);
    window.setTimeout(@LoadDezqParamList, 10);
  end;

begin
  Log.Log(ltDebug, classname, 'DologinOK', ' Result: ' + BoolToStr(result, 'False', 'true'));
  if not result then
  begin
    ShowError(SErrLoginFailedMessage, SErrLoginFailedTitle);
    exit;
  end;
  Toasts.Clear;
  // The rest is done in a timeout, so it is executed asynchronously:
  // any errors will not be part of the login callback.
  SetLocalData(KeySessionID, ClientID);
  window.setTimeout(@ContinueLogin, 50);
end;

procedure TdmServer.ApplyUserRights;

begin
  // Currently nothing
end;

procedure TdmServer.DoLoadOK(DataSet: TDataSet; Data: JSValue);

begin
  // Currently nothing
end;

procedure TdmServer.DoLoadFailed(DataSet: TDataSet; ID: Integer; const ErrorMsg: string);
var
  N: string;
begin
  Log.Log(ltDebug, classname, 'DoLoadFailed', 'Fetching data for dataset %s failed: %s', [DataSet.Name, ErrorMsg]);
  if DataSet is TP2WDADataset then
    N := TP2WDADataset(DataSet).TableName
  else
  begin
    N := DataSet.Name;
    if N = '' then
      N := DataSet.classname;
  end;
  ShowError(Format(SErrLoadingDatasetMsg, [N, ErrorMsg]), SErrLoadingDatasetTitle);
end;

procedure TdmServer.DoLogoutOK(res: JSValue);
begin
  if Assigned(FOnLogout) then
    FOnLogout(Self);

  FUserInfo := default (TDezqUser);
  Router.RouteRequest('login', True);
end;

function TdmServer.DoOnParamsLoaded(aEvent: TObjectEventItem.TTEvent; aSender: TObject): Integer;
begin
  if Length(FDezqParams) = 0 then
    FOnParamsLoaded.RegisterEvent(aEvent, aSender)
  else
  begin
    aEvent(aSender);
    result := -1;
  end;
end;

function TdmServer.DoOnParamsLoaded(aEvent: TSimpleEvent): Integer;
begin
  if Length(FDezqParams) = 0 then
    result := FOnParamsLoaded.RegisterEvent(aEvent)
  else
  begin
    aEvent;
    result := -1;
  end;
end;

procedure TdmServer.DoPasswordReset(const aUserName, aOffice, anEmail: string; successCallBack: TNotifyEvent);

  procedure DoResetFailed(aResponse: TJSObject; aFail: TJSError);
  begin
    ShowError(ExtractErrorMsg(aFail, SErrPasswordResetRequestFailed), SErrPasswordResetFailedTitle);
  end;

  procedure DoResetOK(aID: NativeInt);
  begin
    Toasts.ShowToast(SPasswordResetTitle, SPasswordResetSuccessMessage, cSuccess, True);
    successCallBack(Self);
  end;

var
  request: TPasswordResetRequest;
  ROrequest: TROPasswordResetRequest;

begin
  request.UserName := aUserName;
  request.OfficeName := aOffice;
  request.Email := anEmail;
  ROrequest := TROPasswordResetRequest.New;
  ROrequest.FromObject(request);
  FLoginService.RequestPasswordReset(ROrequest, @DoResetOK, @DoResetFailed);
end;

procedure TdmServer.DoPing;

  procedure PingOK(S: string);

  begin
    Log.Log(ltDebug, classname, 'DoPing.PingOK', S);
  end;

  procedure PingFailed(aResponse: TJSObject; aFail: TJSError);
  begin
    Log.Log(ltDebug, classname, 'DoPing.PingFailed', ExtractErrorMsg(aFail));
  end;

begin
  CompanyService.Ping(@PingOK, @PingFailed);
end;

procedure TdmServer.DoSignOn(Params: TSignonParams; callBack: TSuccessCallback);
var
  officeRec: TOffice;
  accountRec: TAccount;
  personalRec: TPersonal;
  registration: TRegistration;

  request: TRORegistration;

  procedure DoSignOnFailed(aResponse: TJSObject; aFail: TJSError);
  begin
    if Assigned(aFail) then
      callBack(False, ExtractErrorMsg(aFail))
    else
      callBack(False, 'An error occuring during processing your request');
  end;

  procedure DoSignOnOK(aResult: string);
  begin
    callBack(True, aResult);
  end;

begin
  officeRec.Additional := Params.AddressExtra;
  officeRec.Address := Params.Address;
  officeRec.BankAccount := Params.IBANNo;
  officeRec.Email := Params.OfficeEmail;
  officeRec.HouseNr := Params.HouseNo;
  officeRec.Name := Params.OfficeName;
  officeRec.Telephone := Params.OfficePhone;
  officeRec.Town := Params.Town;
  officeRec.VAT := Params.VATno;
  officeRec.zip := Params.zip;

  accountRec.Email := Params.Email;
  accountRec.MobileNr := Params.Phone;
  accountRec.UserName := Params.UserName;

  personalRec.FirstName := Params.FirstName;
  personalRec.LastName := Params.LastName;

  registration.Account := accountRec;
  registration.Office := officeRec;
  registration.Personal := personalRec;

  request := TRORegistration.New;
  request.FromObject(registration);
  FRegisterService.Signon(request, @DoSignOnOK, @DoSignOnFailed);
end;

procedure TdmServer.endUpload(fileID: Int64; onEndUploadResult: TSuccessCallback);

  procedure OnEndUploadFail(aResponse: TJSObject; aFail: TJSError);

  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail, SErrInitalizingDocumentUpload);
    Log.Log(ltError, classname, 'EndUpload', Msg);
    ShowError(Msg);
    if Assigned(onEndUploadResult) then
      onEndUploadResult(False, Msg)
  end;

  procedure OnEndUploadSuccess;
  begin
    onEndUploadResult(True, '');
  end;

begin
  FDocumentService.DocumentUploadDone(fileID, @OnEndUploadSuccess, @OnEndUploadFail)
end;

procedure TdmServer.getCityFromZip(zip: string; callBack: TIDValueEvent);

  procedure GetCityDataFromZipFailed(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail, SErrGetCityDataFromZip);
    Log.Log(ltError, classname, 'getCityFromZip', Msg);
    callBack('', -1);
  end;

  procedure GetCityDataFromZipSuccess(aResult: TROCity);
  var
    cityRec: TCity;
  begin
    cityRec := aResult.toObject;
    callBack(cityRec.Name, cityRec.RecordID);
  end;

begin
  FGeoService.GetCityDataFromZip('BE', zip, @GetCityDataFromZipSuccess, @GetCityDataFromZipFailed);
end;

function TdmServer.GetUserID: NativeInt;
begin
  result := -1;
  if Assigned(UserInfo) then
    result := StrToInt64Def(UserInfo.UserID, -1);
end;

function TdmServer.GetUserLanguage: string;
begin
  result := LoginLanguage;
  if (result = '') then
  begin
    result := GetLocalData(KeyLanguage);
    if (result = '') then
      result := 'nl'; // Default
  end;
end;

function TdmServer.GetUserType: TUserType;
begin
  result := utCustomer;
end;

procedure TdmServer.DeleteDocument(fileID: Int64; onDeleteCallback: TSuccessCallback);
  procedure OnDeleteSuccess;
  begin
    if Assigned(onDeleteCallback) then
      onDeleteCallback(True, '');
  end;

  procedure OnDeleteFail(response: TJSObject; fail: TJSError);
  begin
    if Assigned(onDeleteCallback) then
      onDeleteCallback(False, ExtractErrorMsg(fail));
  end;

begin
  FDocumentService.DocumentDelete(fileID, @OnDeleteSuccess, @OnDeleteFail);
end;

procedure TdmServer.DeleteFileReference(fileID: Int64);

  procedure AfterTableOpened(DataSet: TDataSet);
  begin
    if not DataSet.isEmpty then
    begin
      DataSet.Delete;
      DataSet.ApplyUpdates;
    end;
  end;

begin
  tblDossierFile.Close;
  PetitionConnection.Message.ClientID := ClientID;
  tblDossierFile.ParamByName('FILEID').asLargeInt := fileID;
  tblDossierFile.AfterOpen := AfterTableOpened;
  tblDossierFile.Load([], nil);
end;

procedure TdmServer.DoClosePetition(aCloseStatus: string; aPetitionID: Int64; aRemarks: string; callBack: TIDResult);
var
  closeData: TPetitionCloseData;
  request: TROPetitionCloseData;

  procedure OnPetitionCloseSuccess(aResult: NativeInt);
  begin
    callBack(aResult, True, '');
  end;

  procedure OnPetitionCloseFail(aResponse: TJSObject; aFail: TJSError);
  begin
    callBack(-1, False, ExtractErrorMsg(aFail));
  end;

begin
  closeData.CloseStatus := aCloseStatus;
  closeData.petitionID := aPetitionID;
  closeData.Remarks := aRemarks;

  request := TROPetitionCloseData.New;
  request.FromObject(closeData);

  FPetitionService.ArchivePetition(request, @OnPetitionCloseSuccess, @OnPetitionCloseFail);
end;

function TdmServer.DoCreateDataset(aOwner: TComponent; aTableName: string; SkipLoad: Boolean): TP2WDADataset;
begin
  if aOwner = nil then
    aOwner := Self;
  result := TP2WDADataset.Create(aOwner);
  result.DAConnection := DataConnection;
  result.TableName := aTableName;
  result.OnLoadFail := @DoLoadFailed;
  if not SkipLoad then
    result.Load([], @DoLoadOK);
end;

procedure TdmServer.DoCreatePetition(Params: TCreatePetitionParams; callBack: TIDResult);
var
  petitionRecord: TCreatePetition;
  roPetition: TROCreatePetition;

  procedure OnCreationFail(aResponse: TJSObject; aFail: TJSError);
  begin
    callBack(-1, False, ExtractErrorMsg(aFail));
  end;

  procedure OnCreationSuccess(aResult: NativeInt);
  begin
    callBack(aResult, True, '');
  end;

begin

  petitionRecord.AddressCityName := Params.AddressCityName;
  petitionRecord.AddressCityZip := Params.AddressCityZip;
  petitionRecord.AddressNr := Params.AddressNr;
  petitionRecord.AddressStreet := Params.AddressStreet;
  petitionRecord.BirthCityName := Params.BirthCityName;
  petitionRecord.BirthCityZip := Params.BirthCityZip;
  petitionRecord.CardNumber := Params.CardNumber;
  petitionRecord.CardRemark := Params.CardRemark;
  petitionRecord.CardType := Params.CardType;
  petitionRecord.CardChipNo := Params.CardChipNo;
  petitionRecord.CardValidFrom := Params.CardValidFrom;
  petitionRecord.CardValidTill := Params.CardValidTill;
  petitionRecord.DateOfBirth := Params.DateOfBirth;
  petitionRecord.Description := Params.Description;
  petitionRecord.Gender := Params.Gender;
  petitionRecord.FirstName := Params.FirstName;
  petitionRecord.LastName := Params.LastName;
  petitionRecord.Nationality := Params.Nationality;
  petitionRecord.NIDNR := Params.NIDNR;
  petitionRecord.Picture := Params.Picture;

  roPetition := TROCreatePetition.New;
  roPetition.FromObject(petitionRecord);
  FPetitionService.CreatePetition(roPetition, @OnCreationSuccess, @OnCreationFail);
end;

procedure TdmServer.DoLoginNeeded(aCallBack: TROCallBack);
// will return false if user not defined (befor login) or if user allows

begin
  // ShowError('Login expired');
  DoLogout;
end;

procedure TdmServer.DoInitializeConnections;

var
  UseJSON: Boolean;

  procedure CopySettings(var aConnection: TP2WDAConnection);
  begin
    if UseJSON then
    begin
      aConnection.MessageType := mtJSON;
      aConnection.URL := ServerURL + 'JSON'
    end
    else
    begin
      aConnection.MessageType := mtBIN;
      aConnection.URL := ServerURL + 'BIN';
    end;
    aConnection.OnLoginCallFailed := @DoLoginFail;
    aConnection.OnLogoutCallFailed := @DoLogoutFail;
    aConnection.OnLogout := @DoLogoutOK;
    aConnection.Channel.OnLoginNeeded := @DoLoginNeeded;
  end;

begin
  UseJSON := ServerConfig.DoUseJSONMessage;
  CopySettings(DataConnection);
  DataConnection.DataserviceName := 'DataService';

  CopySettings(CompanyConnection);
  CompanyConnection.DataserviceName := 'CompanyService';

  CopySettings(ContactConnection);
  ContactConnection.DataserviceName := 'ContactService';

  CopySettings(GeoConnection);
  GeoConnection.DataserviceName := 'GeoService';

  CopySettings(PetitionConnection);
  PetitionConnection.DataserviceName := 'PetitionService';

  CopySettings(ParameterConnection);
  ParameterConnection.DataserviceName := 'ParameterService';

  CopySettings(CSRConnection);
  CSRConnection.DataserviceName := 'CSRService';
end;

constructor TdmServer.Create(aOwner: TComponent);
begin
  inherited Create(aOwner);
  if Assigned(ServerConfig) and isString(ServerConfig.ServerURL) then
    FServerURL := ServerConfig.ServerURL;
  if (FServerURL = '') then
    FServerURL := DefaultServerURL;
  FOnParamsLoaded := TEventQueue.Create(Self);
  FOnParamsLoaded.ClearOnExecute := True;

  // setup default language
  if GetLocalData(KeyLanguage) = '' then
    SetLocalData(KeyLanguage, 'nl');
end;

procedure TdmServer.CreateCSR(dataParam: TCSRCreateParams; callBack: TIDResult);
var
  crsDataStruct: TCSRCreateData;
  crsRoData: TROCSRCreateData;

  procedure OnSaveFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSaveSuccess(aResult: NativeInt);
  begin
    callBack(aResult, True, '');
  end;

begin
  crsDataStruct.ContactID := dataParam.ContactID;
  crsDataStruct.Description := dataParam.Description;
  crsDataStruct.CourtID := dataParam.CourtID;
  crsDataStruct.JudgeContactID := dataParam.JudgeContactID;
  crsDataStruct.startDate := dataParam.startDate;
  crsDataStruct.CSRAdmissionFile := dataParam.CSRAdmissionFile;

  crsRoData := TROCSRCreateData.New;
  crsRoData.FromObject(crsDataStruct);
  // fromObject --> in RO gaat de datetime fout in de fromObject (zie Talk RO)
  crsRoData.startDate.Value := crsDataStruct.startDate;

  CSRService.CreateCSR(crsRoData, @OnSaveSuccess, @OnSaveFail);
end;

class function TdmServer.Instance: TdmServer;
begin
  if _instance = nil then
    _instance := TdmServer.Create(nil);
  result := _instance;
end;

procedure TdmServer.IsBankAccountLinkedToPonto(AccountID: NativeInt; callBack: TSuccessCallback);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(False, Msg)
  end;

  procedure OnSuccess(aResult: Boolean);
  begin
    callBack(aResult, '');
  end;

begin
  FPontoService.GetIsBankAccountLinkedToPonto(AccountID, @OnSuccess, @OnFail)
end;

procedure TdmServer.isIBANValid(const ibanNumber: string; callBack: TSuccessCallback);

  procedure OnSuccess(aResult: Boolean; aError: string);
  begin
    callBack(aResult, aError);
  end;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  begin
    callBack(False, ExtractErrorMsg(aFail));
  end;

begin
  FNumberService.IsValidIBAN(ibanNumber, @OnSuccess, @OnFail);
end;

procedure TdmServer.isNationalNoValid(aNumber, aGender: string; aDateTime: TJSDate; callBack: TSuccessCallback);

  procedure OnFailResult(aResponse: TJSObject; aFail: TJSError);
  begin
    callBack(False, ExtractErrorMsg(aFail));
  end;

  procedure OnSuccessResult(aResult: Boolean);
  begin
    callBack(aResult, '')
  end;

begin
  FNumberService.IsValidNIDNR(aNumber, aGender, aDateTime, @OnSuccessResult, @OnFailResult);
end;

procedure TdmServer.isPhoneNumberValid(const phoneNumber: string; callBack: TSuccessCallback);

  procedure OnSuccess(aResult: Boolean);
  begin
    callBack(aResult, '');
  end;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  begin
    callBack(False, ExtractErrorMsg(aFail));
  end;

begin
  FNumberService.IsValidPhoneNumber(phoneNumber, @OnSuccess, @OnFail);
end;

procedure TdmServer.isVATNumberValid(const vatNumber: string; callBack: TSuccessCallback);

  procedure OnSuccess(aResult: Boolean);
  begin
    callBack(aResult, '');
  end;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  begin
    callBack(False, ExtractErrorMsg(aFail));
  end;

begin
  FNumberService.IsValidVAT('BE', vatNumber, @OnSuccess, @OnFail);
end;

procedure TdmServer.HandleException(aObject: TObject);

var
  E: Exception;

begin
  if aObject is Exception then
    ShowError(Format('Unhandled exception %s : %s', [E.classname, E.Message]))
  else
    ShowError(Format('Unhandled error : %s', [TJSJSON.Stringify(aObject)]));
end;

procedure TdmServer.Initialize;
begin
  DoInitializeConnections;

  FLoginService := TDezqLoginService.New(DataConnection.Channel, DataConnection.Message, 'LoginService');
  FNumberService := TDezqNumberService.New(DataConnection.Channel, DataConnection.Message, 'NumberService');
  FMailService := TDezqMailService.New(DataConnection.Channel, DataConnection.Message, 'MailService');
  FContactService := TDezqContactService.New(DataConnection.Channel, DataConnection.Message, 'ContactService');
  FCodaService := TDezqCodaService.New(DataConnection.Channel, DataConnection.Message, 'CodaService');
  FSettlementService := TDezqSettlementService.New(DataConnection.Channel, DataConnection.Message, 'SettlementService');
  FJobService := TDezqJobService.New(DataConnection.Channel, DataConnection.Message, 'JobService');
  FCompanyService := TDezqCompanyService.New(DataConnection.Channel, DataConnection.Message, 'CompanyService');
  FDocumentService := TDezqDocumentService.New(DataConnection.Channel, DataConnection.Message, 'DocumentService');
  FPetitionService := TDezqPetitionService.New(DataConnection.Channel, DataConnection.Message, 'PetitionService');
  FRegisterService := TDezqRegisterService.New(DataConnection.Channel, DataConnection.Message, 'RegisterService');
  FGeoService := TDezqGeoService.New(DataConnection.Channel, DataConnection.Message, 'GeoService');
  FParameterService := TDezqParameterService.New(DataConnection.Channel, DataConnection.Message, 'ParameterService');
  FReportService := TDezqReportService.New(DataConnection.Channel, DataConnection.Message, 'ReportService');
  FCSRService := TCSRService.New(DataConnection.Channel, DataConnection.Message, 'CSRService');
  FPontoService := TPontoService.New(DataConnection.Channel, DataConnection.Message, 'PontoService');
  SetOnUnCaughtExceptionHandler(@HandleException);
end;

procedure TdmServer.CertifyPayments(options: TCSRCertifyOptions; callBack: TSuccessCallback);
var
  roOptions: TROCSRCertifyOptions;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(False, Msg);
  end;

  procedure OnSuccess(result: Boolean);
  begin
    callBack(result, '');
  end;

begin
  roOptions := TROCSRCertifyOptions.New;
  roOptions.FromObject(options);
  CSRService.Certify(roOptions, @OnSuccess, @OnFail);
end;

procedure TdmServer.checkAccountTransactions(AccountID: NativeInt; callBack: TIDResult);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSuccess(aResult: NativeInt);
  begin
    callBack(aResult, True, '');
  end;

begin
  FPontoService.CheckBankAccountTransactions(AccountID, @OnSuccess, @OnFail)
end;

procedure TdmServer.CheckLegalReference(aDossier: NativeInt; aReference: string; aLecID: NativeInt; callBack: TSuccessCallback);

  procedure OnCheckLegalReferenceSuccess(aResult: NativeInt);
  begin
    if aResult > 0 then
      callBack(False, 'This reference is already used in another legal case')
    else
      callBack(True, '')
  end;

  procedure OnCheckLegalReferenceFail(response: TJSObject; fail: TJSError);
  begin
    callBack(False, ExtractErrorMsg(fail));
  end;

begin
  FPetitionService.CheckLegalReference(aDossier, aReference, aLecID, @OnCheckLegalReferenceSuccess, @OnCheckLegalReferenceFail);
end;

procedure TdmServer.CheckPetitionReadOnly(aPetitionID: NativeInt; aCallBack: TReadOnlyPetitionCallback);

var
  PID: string;

  procedure DoOK(aResult: NativeInt);

  var
    S: TDossierStatus;
    RO: Boolean;

  begin
    Log.Log(ltDebug, classname, 'TdmServer.CheckPetitionReadOnly.DoOK', 'Result: %d', [aResult]);
    S.AsInteger := aResult;
    Log.Log(ltDebug, classname, 'TdmServer.CheckPetitionReadOnly.DoOK', 'Result translated: %s', [S.AsString]);
    RO := S.IsArchived;
    aCallBack(Self, aPetitionID, RO);
    MarkPetitionReadOnly(aPetitionID, RO);
  end;

  procedure DoFailed(resp: TJSObject; fail: TJSError);

  begin
    Log.Log(ltDebug, classname, 'CheckSession.DoSessionInfoFailed', ExtractErrorMsg(fail));
    aCallBack(Self, aPetitionID, True);
  end;

begin
  PID := IntToStr(aPetitionID);
  if Assigned(FReadOnlyDossiers) and FReadOnlyDossiers.HasOwnProperty(PID) then
    aCallBack(Self, aPetitionID, Boolean(FReadOnlyDossiers[PID]))
  else
    PetitionService.GetPetitionStatus(aPetitionID, @DoOK, @DoFailed);
end;

procedure TdmServer.CheckSession(S: string);

  procedure DoSessionInfoOK(result: Boolean; aUserInfo: TRODezqUser);

  begin
    Log.Log(ltDebug, classname, 'CheckSession.DoSessionInfoOK', BoolToStr(result, 'False', 'True'));
    if result then
      DoLoginOK(result, aUserInfo)
    else
    begin
      DeleteLocalData(KeySessionID);
      LoginFail('');
    end;
  end;

  procedure DoSessionInfoFailed(resp: TJSObject; fail: TJSError);

  begin
    Log.Log(ltDebug, classname, 'CheckSession.DoSessionInfoFailed', ExtractErrorMsg(fail));
    DeleteLocalData(KeySessionID);
    LoginFail('');
  end;

begin
  Log.Log(ltDebug, classname, 'CheckSession', S);
  FLoginService.Message.ClientID := S;
  FLoginService.GetSessionUserInfo(@DoSessionInfoOK, @DoSessionInfoFailed);
end;

procedure TdmServer.CopySettlementPlan(options: TCopySettlementPlanOptions; callBack: TIDResult);
var
  roOptions: TROCopySettlementPlanOptions;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSuccess(anID: NativeInt);
  begin
    callBack(anID, True, '');
  end;

begin
  roOptions := TROCopySettlementPlanOptions.New;
  roOptions.FromObject(options);
  CSRService.CopySettlementPlan(roOptions, @OnSuccess, @OnFail);
end;

function TdmServer.GetClientID: string;
begin
  result := LoginService.Message.ClientID;
end;

procedure TdmServer.GetCompanyDossierID(DossierID: NativeInt; callBack: TIDResult);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSuccess(anID: NativeInt);
  begin
    callBack(anID, True, '');
  end;

begin
  FCompanyService.GetCompanyDossierID(DossierID, @OnSuccess, @OnFail)
end;

function TdmServer.GetCompanyService: TDezqCompanyService;
begin
  result := FCompanyService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

function TdmServer.GetContactService: TDezqContactService;
begin
  result := FContactService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

procedure TdmServer.GetCopiedCSRID(copyJobID: NativeInt; callBack: TIDResult);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSuccess(anID: NativeInt);
  begin
    callBack(anID, True, '');
  end;

begin
  CSRService.GetCopiedCSRID(copyJobID, @OnSuccess, @OnFail);
end;

function TdmServer.GetDocumentService: TDezqDocumentService;
begin
  result := FDocumentService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

procedure TdmServer.GetFileUrl(fileID: Int64; onGetUrlResult: TSuccessCallback);

  procedure OnGetDocumentUrlFailed(aResponse: TJSObject; aFail: TJSError);

  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail, SErrGetFileURL);
    Log.Log(ltError, classname, 'GetFileURL', Msg);
    ShowError(Msg);
    if Assigned(onGetUrlResult) then
      onGetUrlResult(False, aFail.Message)
  end;

  procedure OnGetDocumentUrlSuccess(aURL: string);
  begin
    onGetUrlResult(True, aURL);
  end;

begin
  FDocumentService.GetDownloadUrl(fileID, @OnGetDocumentUrlSuccess, @OnGetDocumentUrlFailed);
end;

function TdmServer.GetGeoService: TDezqGeoService;
begin
  result := FGeoService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

procedure TdmServer.GetIsPontoAccountLinked(CompanyID: NativeInt; callBack: TSuccessCallback);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(False, Msg)
  end;

  procedure OnSuccess(aResult: Boolean);
  begin
    callBack(aResult, '');
  end;

begin
  FPontoService.GetIsPontoAccountLinked(CompanyID, @OnSuccess, @OnFail)
end;

function TdmServer.GetJobService: TDezqJobService;
begin
  result := FJobService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

procedure TdmServer.GetJobStatus(JobId: NativeInt; callBack: TIDResult);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSuccess(status: NativeInt);
  begin
    callBack(status, True, '');
  end;

begin
  FJobService.GetJobStatus(JobId, @OnSuccess, @OnFail);
end;

procedure TdmServer.GetJobStatusEx(JobId: NativeInt; callBack: TIDResult);
  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSuccess(aResult: NativeInt; aError: string);
  begin
    callBack(aResult, aError = '', aError);
  end;

begin
  FJobService.GetJobStatusEx(JobId, @OnSuccess, @OnFail);
end;

function TdmServer.GetLocalData(aKey: string): string;
begin
  result := window.localstorage.getItem(aKey);
  if not isString(result) then
    result := '';
end;

function TdmServer.GetMailService: TDezqMailService;
begin
  result := FMailService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

procedure TdmServer.GetMessagesStats(startDate, endDate: TDateTime; successCallBack: TMessageStatisticsResult; failCallBack: TSuccessCallback);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(failCallBack) then
      failCallBack(False, Msg)
  end;

  procedure OnSuccess(aResult: TROMessageStatistics);
  begin
    if Assigned(successCallBack) then
      successCallBack(aResult.toObject);
  end;

begin
  FCompanyService.GetMessageStatistics(TJSDate.New(FormatDateTime('yyyy-mm-dd', startDate) + ' 00:00:00'), TJSDate.New(FormatDateTime('yyyy-mm-dd', endDate) + ' 23:59:59'), @OnSuccess, @OnFail);
end;

function TdmServer.GetNumberService: TDezqNumberService;
begin
  result := FNumberService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

function TdmServer.GetNumericLanguage: Integer;
begin
  result := IndexText(UserLanguage, ['en', 'nl', 'fr']);
  if result = -1 then
    result := 1; // Default dutch
end;

function TdmServer.GetParamDisplayValue(aParamType, ParamName: string): string;
var
  i, j: Integer;
begin
  result := '';
  for i := low(FDezqParams) to high(FDezqParams) do
  begin
    if FDezqParams[i].ParamType = aParamType then
    begin
      for j := low(FDezqParams[i].ParamList) to high(FDezqParams[i].ParamList) do
      begin
        if FDezqParams[i].ParamList[j].Name = ParamName then
        begin
          result := FDezqParams[i].ParamList[j].Value;
          break;
        end;
      end;
      break;
    end;
  end;
  if result = '' then
  begin
    ShowError(Format('Could not find "%s" value "%s"', [aParamType, ParamName]));
    raise EDezq.CreateFmt('Could not find "%s" value "%s"', [aParamType, ParamName]);
  end;
end;

procedure TdmServer.GetParameterList(const aType: string; aLanguage: Integer; callBack: TParamCallback);

  procedure OnSuccess(dynArr: TROParameterArray);
  var
    arr: TParameterDynArray;
    i: Integer;
  begin
    arr := dynArr.toObject;
    for i := low(arr) to high(arr) do
      callBack(arr[i].RecordID, arr[i].Name, arr[i].Language, arr[i].ParamType, arr[i].Value, arr[i].ValueType, arr[i].Extra, high(arr) = i);
  end;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  begin
    ShowError(ExtractErrorMsg(aFail, Format(SErrLoadingDatasetMsg, ['parameters', aType]) + ': %s'));
  end;

begin
  FParameterService.GetParameterList(aType, aLanguage, @OnSuccess, @OnFail);
end;

function TdmServer.GetParamId(aParamType, ParamName: string): Int64;
var
  i, j: Integer;
begin
  result := 0;
  for i := low(FDezqParams) to high(FDezqParams) do
  begin
    if FDezqParams[i].ParamType = aParamType then
    begin
      for j := low(FDezqParams[i].ParamList) to high(FDezqParams[i].ParamList) do
      begin
        if FDezqParams[i].ParamList[j].Name = ParamName then
        begin
          result := FDezqParams[i].ParamList[j].RecordID;
          break;
        end;
      end;
      break;
    end;
  end;
  if result = 0 then
    raise EDezq.CreateFmt('Could not find param type "%s" value "%s".', [aParamType, ParamName]);
end;

function TdmServer.GetParamList(aParamType: string): TDezqParameterArray;
var
  i: Integer;
begin
  for i := low(FDezqParams) to high(FDezqParams) do
  begin
    if FDezqParams[i].ParamType = aParamType then
    begin
      result := FDezqParams[i].ParamList;
      break;
    end;
  end;
end;

procedure TdmServer.GetPetitionContact(petitionID: Int64; contactType: string; aRecurse: Boolean; callBack: TSimpleSearchCallback; onEmptyResult: TEmtyResultCallBack);

  procedure OnGetDossierContactSuccess(aResult: TROContactArray);
  begin
    OnGetContactDataSucccess(aResult, callBack, onEmptyResult);
  end;

  procedure OnGetDossierContactFail(response: TJSObject; fail: TJSError);
  begin
    ShowError(Format(SErrLoadingDatasetMsg, ['contactData (' + contactType + ')', ExtractErrorMsg(fail)]));
  end;

begin
  FPetitionService.GetDossierContact(petitionID, contactType, aRecurse, @OnGetDossierContactSuccess, @OnGetDossierContactFail);
end;

procedure TdmServer.GetPetitionCopyFields(petitionID: NativeInt; callBack: TIDResult);

  procedure OnRequestFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnRequestSuccess(JobId: NativeInt);
  begin
    callBack(JobId, True, '');
  end;

begin
  CSRService.CheckCopyPetition(petitionID, @OnRequestSuccess, @OnRequestFail)
end;

function TdmServer.GetPetitionService: TDezqPetitionService;
begin
  result := FPetitionService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

function TdmServer.GetRegisterService: TDezqRegisterService;
begin
  result := FRegisterService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

function TdmServer.GetSettlementService: TDezqSettlementService;
begin
  result := FSettlementService;
  if Self.ClientID <> '' then
    result.Message.ClientID := Self.ClientID;
end;

function TdmServer.GetLastCallTime: TDateTime;

{Var
 CT : TDateTime;

 Procedure Check(aChannel : TROHTTPClientChannel);

 var
 lc : JSValue;
 cd : TDateTime;

 begin
 if aChannel.HasOwnProperty('lastCall') then
 begin
 lc:=aChannel['lastCall'];
 if isObject(lc) and (TJSObject(lc) is TJSDate) then
 begin
 cd:=JSDateToDateTime(TJSDate(LC));
 if cd>CT then
 CT:=cd;
 end;
 end;
 end;

 Var
 I : Integer;}

begin
  result := 0;
  {CT:=0;
   For I:=0 to ComponentCount-1 do
   if Components[i] is TP2WDAConnection then
   Check((Components[i] as TP2WDAConnection).Channel);
   Result:=CT;}
  if GetLocalData('LastCall') <> '' then
    result := UnixToDateTime(StrToIntDef(GetLocalData('LastCall'), 0));
end;

function TdmServer.SecondsTillTimeout: Integer;
var
  D: TDateTime;
begin
  D := IncSecond(GetLastCallTime, ServerConfig.DoLogoutTimeout);
  result := SecondsBetween(D, Now);
end;

function TdmServer.ServerTimeout: Boolean;

var
  D: TDateTime;
  Left: Integer;

begin
  D := GetLastCallTime;
  Left := SecondsBetween(Now, D);
  result := Left >= ServerConfig.DoLogoutTimeout;
end;

function TdmServer.ServerTimeoutPending: Boolean;

var
  D: TDateTime;
  Left: Integer;

begin
  D := GetLastCallTime;
  Left := SecondsBetween(Now, D);
  result := Left >= ServerConfig.DoWarnLogoutTimeout;
end;

procedure TdmServer.DeleteLocalData(aKey: string);

begin
  window.localstorage.RemoveItem(aKey);
end;

procedure TdmServer.DeleteMessage(aID: NativeInt; callBack: TSuccessCallback);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(False, Msg)
  end;

  procedure OnSuccess;
  begin
    if Assigned(callBack) then
      callBack(True, '');
  end;

begin
  FCompanyService.DeleteMessage(aID, @OnSuccess, @OnFail);
end;

procedure TdmServer.DeleteSettlementPlan(SettlementId: NativeInt; callBack: TSuccessCallback);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(False, Msg);
  end;

  procedure OnSuccess(result: Boolean);
  begin
    if result then
      callBack(True, '')
    else
      callBack(False, 'Failed to delete settlement');
  end;

begin
  CSRService.DeleteSettlementPlan(SettlementId, @OnSuccess, @OnFail);
end;

procedure TdmServer.SetConnectionIDS;
var
  aID: string;
begin
  aID := ClientID;
  PetitionConnection.Message.ClientID := aID;
  ContactConnection.Message.ClientID := aID;
  ParameterConnection.Message.ClientID := aID;
  GeoConnection.Message.ClientID := aID;
  CompanyConnection.Message.ClientID := aID;
  DataConnection.Message.ClientID := aID;
end;

procedure TdmServer.SetLocalData(aKey, aVAlue: string);
begin
  window.localstorage.SetItem(aKey, aVAlue);
end;

procedure TdmServer.SetMessageStatus(aID: NativeInt; aIsRead: Boolean; callBack: TSuccessCallback);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(False, Msg)
  end;

  procedure OnSuccess;
  begin
    if Assigned(callBack) then
      callBack(True, '');
  end;

begin
  FCompanyService.SetMessageStatus(aID, aIsRead, @OnSuccess, @OnFail);
end;

procedure TdmServer.SetPetitionFieldsToCopy(JobId: NativeInt; fields: TSetPetitionCopyActionArray; callBack: TSuccessCallback);
var
  actions: TROSetPetitionCopyActionArray;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(False, Msg);
  end;

  procedure OnSuccess;
  begin
    callBack(True, '');
  end;

begin
  actions := TROSetPetitionCopyActionArray.New;
  actions.fromArray(fields);
  CSRService.SetPetitionCopyActions(JobId, actions, @OnSuccess, @OnFail);
end;

procedure TdmServer.ShowError(const aError: string; aTitle: string = '');
begin
  if aTitle = '' then
    aTitle := SError;
  // -1 disables autohide.
  Toasts.ShowToast(aTitle, aError, cDanger, True, -1);
end;

procedure TdmServer.ShowOK(const aMessage: string);
begin
  Toasts.ShowToast(SSucces, aMessage, cSuccess, True);
end;

procedure TdmServer.SimpleSearchContacts(const aSearchTerm: string; aRecurse: Boolean; callBack: TSimpleSearchCallback; onEmptyResult: TEmtyResultCallBack);

  procedure OnSimpleSearchContactsSucccess(aResult: TROContactArray);
  begin
    OnGetContactDataSucccess(aResult, callBack, onEmptyResult);
  end;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  begin
    // TODO implement error handling
    ShowError(ExtractErrorMsg(aFail, Format(SErrSearchOperationFailed, [aSearchTerm]) + ' %s'));
  end;

begin
  FContactService.SimpleSearchContacts(aSearchTerm, aRecurse, False, @OnSimpleSearchContactsSucccess, @OnFail);
end;

function TdmServer.TransformContactsToData(Items: TContactArray): TJSArray;
var
  row: TJSArray;
  i: Integer;
begin
  result := TJSArray.New(Length(Items));
  for i := low(Items) to high(Items) do
  begin
    row := TJSArray.New(26);

    row.Elements[0] := Items[i].RecordID;
    row.Elements[1] := null;
    row.Elements[2] := null;
    row.Elements[3] := null;
    row.Elements[4] := null;
    row.Elements[5] := null;
    row.Elements[6] := Items[i].FirstName;
    row.Elements[7] := Items[i].LastName;
    row.Elements[8] := Items[i].Profession;
    row.Elements[9] := Items[i].BirthDateOn;
    row.Elements[10] := Items[i].IsBirthDateUnknown;
    row.Elements[11] := Items[i].Gender;
    row.Elements[12] := Items[i].CityOfBirthID;
    row.Elements[13] := Items[i].CityOfBirthName;
    row.Elements[14] := Items[i].CityOfBirthZIP;
    row.Elements[15] := Items[i].NationalityID;
    row.Elements[16] := Items[i].Nationality2;
    row.Elements[17] := Items[i].Nationality;
    row.Elements[18] := 0;
    row.Elements[19] := Items[i].KBONR;
    row.Elements[20] := Items[i].Remark;
    row.Elements[21] := 0;
    row.Elements[22] := Items[i].Salutation;
    row.Elements[23] := Items[i].SearchName;
    row.Elements[24] := Items[i].Prefixes;
    row.Elements[25] := Items[i].FriendlyTitle;
    result.Elements[i] := row;
  end;
end;

procedure TdmServer.SearchOtherContacts(callBack: TDataTableSimpleSearchCallback);

  procedure OnSimpleSearchContactsSucccess(aResult: TROContactArray);
  var
    newRows: TJSArray;
    Items: TContactArray;

  begin
    Items := aResult.toObject;
    newRows := TransformContactsToData(Items);
    callBack(newRows);
  end;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  begin
    ShowError(ExtractErrorMsg(aFail, Format(SErrSearchOperationFailed, ['other']) + ' %s'));
  end;

var
  aOptions: TContactSearchOptions;
  aROOptions: TROContactSearchOptions;

begin
  // const aSearchTerm: String; const aOptions: TContactSearchOptions;
  // aSuccess: TContactArrayResult; OnFailed: TROFailedEvent);
  aOptions.IsRegexp := True;
  aOptions.CaseSensitive := False;
  aOptions.Recurse := False;
  aOptions.SearchAddress := False;
  aOptions.SearchTelecom := False;
  aOptions.LastNameInitialOnly := True;
  aOptions.StartOnly := True;
  aROOptions := TROContactSearchOptions.New;
  aROOptions.FromObject(aOptions);
  FContactService.SearchContacts('^[^[:alnum:]]', aROOptions, @OnSimpleSearchContactsSucccess, @OnFail);

end;

procedure TdmServer.SimpleSearchContactsForDatatable(const aSearchTerm: string; SearchInitialLetter: Boolean; callBack: TDataTableSimpleSearchCallback);

  procedure OnSimpleSearchContactsSucccess(aResult: TROContactArray);
  var
    newRows: TJSArray;
    Items: TContactArray;

  begin
    Items := aResult.toObject;
    newRows := TransformContactsToData(Items);
    callBack(newRows);
  end;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  begin
    ShowError(ExtractErrorMsg(aFail, Format(SErrSearchOperationFailed, [aSearchTerm]) + ' %s'));
  end;

begin
  FContactService.SimpleSearchContacts(aSearchTerm, False, SearchInitialLetter, @OnSimpleSearchContactsSucccess, @OnFail);
end;

procedure TdmServer.StartCSRCopy(options: TCopyPetitionToCSROptions; callBack: TIDResult);
var
  roOptions: TROCopyPetitionToCSROptions;

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSuccess(JobId: NativeInt);
  begin
    callBack(JobId, True, '');
  end;

begin
  roOptions := TROCopyPetitionToCSROptions.New;
  roOptions.FromObject(options);
  CSRService.CopyPetitionToCSR(roOptions, @OnSuccess, @OnFail);
end;

procedure TdmServer.startUpload(fileID: Int64; onStartSuccess: TStringEvent; onStartFailed: TSuccessCallback);

  procedure UploadInitFailed(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail, SErrInitalizingDocumentUpload);
    Log.Log(ltError, classname, 'CreateNewFile', Msg);
    ShowError(Msg);
    if Assigned(onStartFailed) then
      onStartFailed(False, Msg)
  end;

  procedure UploadInitSuccess(aResult: string);
  begin
    onStartSuccess(aResult);
  end;

begin
  FDocumentService.DocumentUploadInit(fileID, @UploadInitSuccess, @UploadInitFailed)
end;

procedure TdmServer.UpdateFileReference(fileID: Int64; FileName: string);

  procedure AfterTableOpened(DataSet: TDataSet);
  begin
    if not DataSet.isEmpty then
    begin
      DataSet.Edit;
      DataSet.FieldByName('doffilename').AsString := FileName;
      DataSet.Post;
      DataSet.ApplyUpdates;
    end;
  end;

begin
  tblDossierFile.Close;
  PetitionConnection.Message.ClientID := ClientID;
  tblDossierFile.ParamByName('FILEID').asLargeInt := fileID;
  tblDossierFile.AfterOpen := AfterTableOpened;
  tblDossierFile.Load([], nil);
end;

procedure TdmServer.DoLogin(const aUserName, aPassword, aOffice: string);

  procedure DoLoginFailed(aResponse: TJSObject; aFail: TJSError);
  begin
    ShowError(ExtractErrorMsg(aFail, SErrLoginRequestFailed), SErrLoginFailedTitle);
  end;

begin
  FLoginService.DezqLogin(aUserName, aPassword, aOffice, @DoLoginOK, @DoLoginFailed);
end;

procedure TdmServer.DoLoginFail(Msg: TROMessage; Err: string);
var
  aError: string;
begin
  aError := Err;
  LoginFail(aError);
end;

function TdmServer.CreateDataset(aOwner: TComponent; aTableName: string; SkipLoad: Boolean = False): TP2WDADataset;
begin
  result := DoCreateDataset(aOwner, aTableName, SkipLoad);
end;

procedure TdmServer.CreateNewFile(DossierID: Int64; SourceType: Integer; SourceID: Int64; DocumentType, FileName: string; callBack: TIDResult);

  procedure OnNewDocumentFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail, SErrCreatingDocument);
    Log.Log(ltError, classname, 'CreateNewFile', Msg);
    ShowError(Msg);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure onNewDocumentSuccess(aResult: NativeInt);
  begin
    callBack(aResult, True, '');
  end;

begin
  FDocumentService.CreateNewDocument(DossierID, SourceType, SourceID, DocumentType, FileName, @onNewDocumentSuccess, @OnNewDocumentFail);
end;

procedure TdmServer.CreateNewSettlementPlan(options: TSettlementPlanCreateOptions; successCallBack: TNewSettlementCreateCallback; errorCallBak: TStringEvent);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(errorCallBak) then
      errorCallBak(Msg);
  end;

  procedure OnSuccess(aResult: TROSettlementPlanCreateResult);
  begin
    if Assigned(successCallBack) then
      successCallBack(aResult.toObject);
  end;

var
  roOptions: TROSettlementPlanCreateOptions;
begin
  roOptions := TROSettlementPlanCreateOptions.New;
  roOptions.FromObject(options);
  roOptions.ValidFrom.Value := options.ValidFrom;
  CSRService.CreateSettlementPlan(roOptions, @OnSuccess, @OnFail);
end;

procedure TdmServer.CSRCheckExistingPetition(NIDNR: string; successCallBack: TCSRSearchResult; failCallBack: TStringEvent);
var
  searchStruct: TPetitionSearchCriteria;
  searchCriteria: TROPetitionSearchCriteria;

  procedure OnSearchFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(failCallBack) then
      failCallBack(Msg);
  end;

  procedure OnSearchSuccess(aResult: TROSearchPetitionResult);
  begin
    successCallBack(aResult.toObject);
  end;

begin
  searchStruct.NIDNR := NIDNR;
  searchCriteria := TROPetitionSearchCriteria.New;
  searchCriteria.FromObject(searchStruct);

  CSRService.CheckExistingPetition(searchCriteria, @OnSearchSuccess, @OnSearchFail);
end;

procedure TdmServer.DoLogout;
begin
  FLoginService.Logout(@DoLogoutOK, @DoLogoutFail);
end;

procedure TdmServer.DoLogoutFail(Msg: TROMessage; Err: TJSError);
var
  aError: string;
begin
  aError := ExtractErrorMsg(Err);
  LogoutFail(aError)
end;

procedure TdmServer.LinkAccountToPonto(AccountID: NativeInt; callBack: TIDResult);
  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg)
  end;

  procedure OnSuccess(aResult: NativeInt);
  begin
    callBack(aResult, True, '');
  end;

begin
  FPontoService.GetBankAccountPontoInfo(AccountID, @OnSuccess, @OnFail);
end;

procedure TdmServer.LoadDezqParamList;

  procedure AfterParamListLoad(DataSet: TDataSet);
  var
    prevType: string;
    i, j: Integer;
  begin
    prevType := '';
    i := 0;
    j := 0;
    if DataSet.isEmpty then
    begin
      Log.Log(ltError, classname, 'AfterParamListLoad', 'No parameters for language %s : %d', [UserLanguage, dsParameters.ParamByName('language').AsInteger]);
      ShowError('No parameters found for language: ' + UserLanguage);
    end;
    while not DataSet.Eof do
    begin
      if DataSet.FieldByName('parparamtype').AsString <> '' then
      begin
        if prevType <> DataSet.FieldByName('parparamtype').AsString then
        begin
          SetLength(FDezqParams, i + 1);
          FDezqParams[i].ParamType := DataSet.FieldByName('parparamtype').AsString;
          SetLength(FDezqParams[i].ParamList, 0);
          j := 0;
          inc(i);
        end;
        SetLength(FDezqParams[i - 1].ParamList, j + 1);
        FDezqParams[i - 1].ParamList[j].RecordID := DataSet.FieldByName('parid').AsInteger;
        FDezqParams[i - 1].ParamList[j].Name := DataSet.FieldByName('parvaluename').AsString;
        FDezqParams[i - 1].ParamList[j].Language := DataSet.FieldByName('parlanguage').AsInteger;
        FDezqParams[i - 1].ParamList[j].ParamType := DataSet.FieldByName('parparamtype').AsString;
        FDezqParams[i - 1].ParamList[j].Value := DataSet.FieldByName('parvalue').AsString;
        FDezqParams[i - 1].ParamList[j].ValueType := DataSet.FieldByName('parvaluetype').AsInteger;
        FDezqParams[i - 1].ParamList[j].Extra := DataSet.FieldByName('parextra').AsString;
        inc(j);
      end;
      prevType := DataSet.FieldByName('parparamtype').AsString;
      DataSet.Next;
    end;
{$IFDEF DEBUGPARAMS}
    for i := low(FDezqParams) to high(FDezqParams) do
    begin
      Log.Log(ltDebug, classname, 'LoadDezqParamList', 'paramtype :' + FDezqParams[i].ParamType);
      for j := low(FDezqParams[i].ParamList) to high(FDezqParams[i].ParamList) do
        with FDezqParams[i].ParamList[j] do
          Log.Log(ltDebug, classname, 'LoadDezqParamList', 'record Id: %d, Name: %s, Value: %s', [RecordID, name, Value]);
    end;
{$ENDIF}
    FOnParamsLoaded.Execute;
  end;

begin
  SetLength(FDezqParams, 0);
  ParameterConnection.Message.ClientID := ClientID;
  dsParameters.DAConnection := ParameterConnection;
  dsParameters.Close;
  dsParameters.AfterOpen := AfterParamListLoad;
  Log.Log(ltDebug, classname, 'LoadDezqParamList', 'Loading parameters with language %s->%s (%d)', [LoginLanguage, UserLanguage, NumericLanguage]);
  dsParameters.ParamByName('language').AsInteger := NumericLanguage;
  dsParameters.Load([], nil);
end;

procedure TdmServer.LoginFail(const Msg: string);
begin
  if Msg <> '' then
    ShowError(SErrLoginFailedMessage, SErrLoginFailedTitle);
  if Assigned(FOnLoginFail) then
    FOnLoginFail(Self, Msg);
end;

procedure TdmServer.LogoutFail(const Msg: string);
begin
  FUserInfo := default (TDezqUser);
  Log.Log(ltDebug, classname, 'LogoutFail', SErrLogoutFailedMessage, [Msg]);
  if Assigned(FOnLogout) then
    FOnLogout(Self);
end;

procedure TdmServer.MarkPetitionReadOnly(aPetitionID: NativeInt; isReadonly: Boolean);
begin
  if not Assigned(FReadOnlyDossiers) then
    FReadOnlyDossiers := TJSObject.New;
  FReadOnlyDossiers[IntToStr(aPetitionID)] := isReadonly;
end;

procedure TdmServer.OnGetContactDataSucccess(dynArr: TROContactArray; callBack: TSimpleSearchCallback; onEmptyResult: TEmtyResultCallBack);
var
  Items: TContactArray;
  CTIs: TContactTelecomItems;
  CAIs: TContactAddressItems;
  i, j: Integer;
  contactData: TContactSearchResult;
begin
  Items := dynArr.toObject;
  if Length(Items) = 0 then
  begin
    onEmptyResult;
    exit;
  end;
  for i := low(Items) to high(Items) do
  begin
    SetLength(CTIs, 0);
    SetLength(CAIs, 0);
    if Items[i].Telecom <> nil then
    begin
      for j := low(Items[i].Telecom) to high(Items[i].Telecom) do
      begin
        SetLength(CTIs, Length(CTIs) + 1);
        CTIs[Length(CTIs) - 1].RecordID := Items[i].Telecom[j].RecordID;
        CTIs[Length(CTIs) - 1].TelecomType := Items[i].Telecom[j].TelecomType;
        CTIs[Length(CTIs) - 1].Data := Items[i].Telecom[j].Data;
        CTIs[Length(CTIs) - 1].Remark := Items[i].Telecom[j].Remark;
      end;
    end;
    if Items[i].Addresses <> nil then
    begin
      for j := low(Items[i].Addresses) to high(Items[i].Addresses) do
      begin
        SetLength(CAIs, Length(CAIs) + 1);
        CAIs[Length(CAIs) - 1].RecordID := Items[i].Addresses[j].RecordID;
        CAIs[Length(CAIs) - 1].AddressType := Items[i].Addresses[j].AddressType;
        CAIs[Length(CAIs) - 1].Street := Items[i].Addresses[j].Street;
        CAIs[Length(CAIs) - 1].StreetNr := Items[i].Addresses[j].StreetNr;
        CAIs[Length(CAIs) - 1].CityZIP := Items[i].Addresses[j].CityZIP;
        CAIs[Length(CAIs) - 1].CityName := Items[i].Addresses[j].CityName;
        CAIs[Length(CAIs) - 1].CityID := Items[i].Addresses[j].CityID;
        CAIs[Length(CAIs) - 1].Remark := Items[i].Addresses[j].Remark;
        CAIs[Length(CAIs) - 1].Secret := Items[i].Addresses[j].Secret;
        CAIs[Length(CAIs) - 1].Correspondence := Items[i].Addresses[j].Correspondence;
      end;
    end;

    contactData.RecordID := Items[i].RecordID;
    contactData.FirstName := Items[i].FirstName;
    contactData.LastName := Items[i].LastName;
    contactData.Profession := Items[i].Profession;
    contactData.BirthDateOn := Items[i].BirthDateOn;
    contactData.IsBirthDateUnknown := Items[i].IsBirthDateUnknown;
    contactData.Gender := Items[i].Gender;
    contactData.CityOfBirthID := Items[i].CityOfBirthID;
    contactData.CityOfBirthZIP := Items[i].CityOfBirthZIP;
    contactData.CityOfBirthName := Items[i].CityOfBirthName;
    contactData.NationalityID := Items[i].NationalityID;
    contactData.Nationality2 := Items[i].Nationality2;
    contactData.Nationality := Items[i].Nationality;
    contactData.KBONR := Items[i].KBONR;
    contactData.Remark := Items[i].Remark;
    contactData.Salutation := Items[i].Salutation;
    contactData.SearchName := Items[i].SearchName;
    contactData.Prefixes := Items[i].Prefixes;
    contactData.FriendlyTitle := Items[i].FriendlyTitle;

    callBack(contactData, CTIs, CAIs, i = high(Items));
  end;
end;

procedure TdmServer.OnPetitionCopyFieldsReady(JobId: NativeInt; successCallBack: TCheckPetitionCopyResultCallback; failCallBack: TStringEvent);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(failCallBack) then
      failCallBack(Msg);
  end;

  procedure OnSuccess(result: TROCheckPetitionCopyResult);
  begin
    successCallBack(result.toObject);
  end;

begin
  CSRService.GetCheckCopyPetitionToCSRResult(JobId, @OnSuccess, @OnFail);
end;

procedure TdmServer.printReport(DossierID: NativeInt; reportName: string; Params: TReportParameterDynArray; callBack: TIDResult);

  procedure OnFail(aResponse: TJSObject; aFail: TJSError);
  var
    Msg: string;
  begin
    Msg := ExtractErrorMsg(aFail);
    if Assigned(callBack) then
      callBack(-1, False, Msg);
  end;

  procedure OnSuccess(JobId: NativeInt);
  begin
    callBack(JobId, True, '');
  end;

var
  roParams: TROReportParameterArray;
begin
  roParams := TROReportParameterArray.New;
  roParams.fromArray(Params);
  ReportService.RunReportByName(reportName, DossierID, FLoginLanguage, roParams, @OnSuccess, @OnFail);
end;

function TdmServer.reformatNationalNo(const number: string; RemoveFormat: Boolean): string;
begin
  result := StringReplace(number, '-', '', [rfReplaceAll]);
  result := StringReplace(result, '.', '', [rfReplaceAll]);
  if not RemoveFormat then
  begin
    result := Copy(result, 1, 6) + '-' + Copy(result, 7, 3) + '.' + Copy(result, 10, 2);
  end;
end;

function TdmServer.ValidityFromToDate(aDate: string; IsStart: Boolean): TDateTime;

begin
  aDate := Trim(aDate);
  if aDate <> '' then
    result := JSDateToDateTime(TJSDate.New(aDate))
  else
    if IsStart then
      result := NoStartDate
    else
      result := NoEndDate;
end;

function TdmServer.ValidityFromToJSDate(aDate: string; IsStart: Boolean): TJSDate;
begin
  aDate := Trim(aDate);
  if aDate <> '' then
    result := TJSDate.New(aDate)
  else
    if IsStart then
      result := DateTimeToJSDate(NoStartDate)
    else
      result := DateTimeToJSDate(NoEndDate);
end;

function TdmServer.CheckResolveResults(Info: TResolveResults; aPrefix: string = ''): Boolean;

var
  Msg: string;

begin
  Msg := Info.AsString;
  result := (Msg = '');
  if not result then
  begin
    if aPrefix <> '' then
      Msg := aPrefix + ': ' + Msg;
    ShowError(Msg);
  end;
end;

function TdmServer.CheckResolveResults(Info: TResolveResults; AOnSuccessProcedure: TEmptyCallback; aPrefix: string = ''): Boolean;

begin
  result := CheckResolveResults(Info, aPrefix);
  if result then
    AOnSuccessProcedure();
end;

function TdmServer.IsZipCodeValid(zipCode: string): Boolean;

var
  i: Integer;

begin
  result := True;
  zipCode := Trim(zipCode);
  if (Length(zipCode) < 4) or (Length(zipCode) > 5) then
    result := False
  else
    result := TryStrToInt(zipCode, i);
end;

function TdmServer.ReformatPhoneNo(phoneNo: string): string;

begin
  result := Trim(phoneNo);
  result := StringReplace(result, '+32', '', [rfReplaceAll]);
  result := StringReplace(result, '(', '', [rfReplaceAll]);
  result := StringReplace(result, ')', '', [rfReplaceAll]);
  result := StringReplace(result, ' ', '', [rfReplaceAll]);
end;

procedure TdmServer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CompanyConnection := TP2WDAConnection.Create(Self);
  DataConnection := TP2WDAConnection.Create(Self);
  ContactConnection := TP2WDAConnection.Create(Self);
  GeoConnection := TP2WDAConnection.Create(Self);
  PetitionConnection := TP2WDAConnection.Create(Self);
  tblContactAddress := TP2WDADataset.Create(Self);
  tblContactAddressctaid := TLargeintField.Create(Self);
  tblContactAddressctacreatedon := TDateTimeField.Create(Self);
  tblContactAddressctacreatedbyfk := TLargeintField.Create(Self);
  tblContactAddressctachangedon := TDateTimeField.Create(Self);
  tblContactAddressctachangedbyfk := TLargeintField.Create(Self);
  tblContactAddressctacontactfk := TLargeintField.Create(Self);
  tblContactAddressctaaddresstypefk := TLargeintField.Create(Self);
  tblContactAddressctaaddresstype := TStringField.Create(Self);
  tblContactAddressctastreet := TStringField.Create(Self);
  tblContactAddressctastreetnr := TStringField.Create(Self);
  tblContactAddressctacityzip := TStringField.Create(Self);
  tblContactAddressctacityname := TStringField.Create(Self);
  tblContactAddressctacityfk := TLargeintField.Create(Self);
  tblContactAddressctaremark := TStringField.Create(Self);
  tblContactAddressctacorrespondence := TBooleanField.Create(Self);
  tblContactAddressctasecret := TBooleanField.Create(Self);
  tblDebt := TP2WDADataset.Create(Self);
  tblDebtdebid := TLargeintField.Create(Self);
  tblDebtdebcreatedon := TDateTimeField.Create(Self);
  tblDebtdebcreatedbyfk := TLargeintField.Create(Self);
  tblDebtdebchangedon := TDateTimeField.Create(Self);
  tblDebtdebchangedbyfk := TLargeintField.Create(Self);
  tblDebtdebdossierfk := TLargeintField.Create(Self);
  tblDebtdebrecordtype := TSmallintField.Create(Self);
  tblDebtdebcreditorfk := TLargeintField.Create(Self);
  tblDebtdebdebttypefk := TLargeintField.Create(Self);
  tblDebtdebdebttype := TStringField.Create(Self);
  tblDebtdebstarton := TDateTimeField.Create(Self);
  tblDebtdebreference := TStringField.Create(Self);
  tblDebtdeboriginalamount := TFMTBCDField.Create(Self);
  tblDebtdebcurrentamount := TFMTBCDField.Create(Self);
  tblDebtdebmonthlyamount := TFMTBCDField.Create(Self);
  tblDebtdebisdisputed := TBooleanField.Create(Self);
  tblDebtdebdisputereason := TStringField.Create(Self);
  tblDebtdebdisputecourt := TStringField.Create(Self);
  tblDebtdebdisputedamount := TFMTBCDField.Create(Self);
  tblDebtdebisdelay := TBooleanField.Create(Self);
  tblDebtdebdelayamount := TFMTBCDField.Create(Self);
  tblDebtdebguarantorfk := TLargeintField.Create(Self);
  tblDebtdebiscompleted := TBooleanField.Create(Self);
  tblDossier := TP2WDADataset.Create(Self);
  tblDossierdosid := TLargeintField.Create(Self);
  tblDossierdoscreatedon := TDateTimeField.Create(Self);
  tblDossierdoscreatedbyfk := TLargeintField.Create(Self);
  tblDossierdoschangedon := TDateTimeField.Create(Self);
  tblDossierdoschangedbyfk := TLargeintField.Create(Self);
  tblDossierdosclosedon := TDateTimeField.Create(Self);
  tblDossierdosdescription := TStringField.Create(Self);
  tblDossierdoscompanyfk := TLargeintField.Create(Self);
  tblDossierdosstatus := TSmallintField.Create(Self);
  tblDossierdosdatesent := TDateTimeField.Create(Self);
  tblDossierdosclosedbyfk := TLargeintField.Create(Self);
  tblDossierdoscloseddescription := TStringField.Create(Self);
  tblDossierdosisbankrupt := TBooleanField.Create(Self);
  tblDossierdosbankrupton := TDateTimeField.Create(Self);
  tblDossierdosiskbocancel := TBooleanField.Create(Self);
  tblDossierdoskbocancelon := TDateTimeField.Create(Self);
  tblDossierdosloginfk := TLargeintField.Create(Self);
  tblDossierdosremarks := TBlobField.Create(Self);
  tblDossierdosreopenedon := TDateTimeField.Create(Self);
  tblDossierdosflags := TIntegerField.Create(Self);
  tblDossierdosmonthlyamount := TFMTBCDField.Create(Self);
  tblDossierdosiscomplete := TBooleanField.Create(Self);
  tblDossierdoscasefilecounter := TIntegerField.Create(Self);
  tblDossierFile := TP2WDADataset.Create(Self);
  tblDossierFiledofid := TLargeintField.Create(Self);
  tblDossierFiledofcreatedon := TDateTimeField.Create(Self);
  tblDossierFiledofcreatedbyfk := TLargeintField.Create(Self);
  tblDossierFiledofchangedon := TDateTimeField.Create(Self);
  tblDossierFiledofchangedbyfk := TLargeintField.Create(Self);
  tblDossierFiledofdossierfk := TLargeintField.Create(Self);
  tblDossierFiledofsourcefk := TLargeintField.Create(Self);
  tblDossierFiledofsourcetype := TSmallintField.Create(Self);
  tblDossierFiledofdoctypefk := TLargeintField.Create(Self);
  tblDossierFiledofdoctype := TStringField.Create(Self);
  tblDossierFiledoffilename := TStringField.Create(Self);
  tblDossierFiledofmime := TStringField.Create(Self);
  tblDossierFiledofdata := TBlobField.Create(Self);
  tblDossierFiledofisuploadcomplete := TBooleanField.Create(Self);
  tblDossierFiledofuploaduserfk := TLargeintField.Create(Self);
  tblDossierFiledofcasefilenumber := TIntegerField.Create(Self);
  tblDossierPersons := TP2WDADataset.Create(Self);
  tblDossierPersonscntid := TLargeintField.Create(Self);
  tblDossierPersonscntcreatedon := TDateTimeField.Create(Self);
  tblDossierPersonscntcreatedbyfk := TLargeintField.Create(Self);
  tblDossierPersonscntchangedon := TDateTimeField.Create(Self);
  tblDossierPersonscntchangedbyfk := TLargeintField.Create(Self);
  tblDossierPersonscntcompanyfk := TLargeintField.Create(Self);
  tblDossierPersonscntfirstname := TWideStringField.Create(Self);
  tblDossierPersonscntlastname := TWideStringField.Create(Self);
  tblDossierPersonscntprofession := TWideStringField.Create(Self);
  tblDossierPersonscntbirthdateon := TDateTimeField.Create(Self);
  tblDossierPersonscntisbirthdateunknown := TBooleanField.Create(Self);
  tblDossierPersonscntgender := TWideStringField.Create(Self);
  tblDossierPersonscntcityofbirthfk := TLargeintField.Create(Self);
  tblDossierPersonscntcityofbirthname := TWideStringField.Create(Self);
  tblDossierPersonscntcityofbirthzip := TWideStringField.Create(Self);
  tblDossierPersonscntnationalityfk := TLargeintField.Create(Self);
  tblDossierPersonscntnationality2 := TWideStringField.Create(Self);
  tblDossierPersonscntnationality := TWideStringField.Create(Self);
  tblDossierPersonscntpicturefk := TLargeintField.Create(Self);
  tblDossierPersonscntkbonr := TWideStringField.Create(Self);
  tblDossierPersonscntremark := TWideStringField.Create(Self);
  tblDossierPersonscntpersonfk := TLargeintField.Create(Self);
  tblDossierPersonscntsalutation := TWideStringField.Create(Self);
  tblDossierPersonscntsearchname := TWideStringField.Create(Self);
  tblDossierPersonscntprefixes := TWideStringField.Create(Self);
  tblDossierPersonscntfriendlytitle := TWideStringField.Create(Self);
  tblDossierPersonsctaid := TLargeintField.Create(Self);
  tblDossierPersonsctacreatedon := TDateTimeField.Create(Self);
  tblDossierPersonsctacreatedbyfk := TLargeintField.Create(Self);
  tblDossierPersonsctachangedon := TDateTimeField.Create(Self);
  tblDossierPersonsctachangedbyfk := TLargeintField.Create(Self);
  tblDossierPersonsctacontactfk := TLargeintField.Create(Self);
  tblDossierPersonsctaaddresstypefk := TLargeintField.Create(Self);
  tblDossierPersonsctaaddresstype := TWideStringField.Create(Self);
  tblDossierPersonsctastreet := TWideStringField.Create(Self);
  tblDossierPersonsctastreetnr := TWideStringField.Create(Self);
  tblDossierPersonsctacityzip := TWideStringField.Create(Self);
  tblDossierPersonsctacityname := TWideStringField.Create(Self);
  tblDossierPersonsctacityfk := TLargeintField.Create(Self);
  tblDossierPersonsctaremark := TWideStringField.Create(Self);
  tblDossierPersonsctacorrespondence := TBooleanField.Create(Self);
  tblDossierPersonsctasecret := TBooleanField.Create(Self);
  tblDossierPersonsdocid := TLargeintField.Create(Self);
  tblDossierPersonsdoccivilstatus := TWideStringField.Create(Self);
  tblDossierPersonsdoccivilstatusfk := TLargeintField.Create(Self);
  tblDossierPersonsdocissecondpetitioner := TBooleanField.Create(Self);
  tblDossierPersonsdocmarcon := TWideStringField.Create(Self);
  tblDossierPersonsdocmarconfk := TLargeintField.Create(Self);
  tblDossierPersonsdocpersontype := TWideStringField.Create(Self);
  tblDossierPersonsdocpersontypefk := TLargeintField.Create(Self);
  tblExpense := TP2WDADataset.Create(Self);
  tblExpenseexpid := TLargeintField.Create(Self);
  tblExpenseexpcreatedon := TDateTimeField.Create(Self);
  tblExpenseexpcreatedbyfk := TLargeintField.Create(Self);
  tblExpenseexpchangedon := TDateTimeField.Create(Self);
  tblExpenseexpchangedbyfk := TLargeintField.Create(Self);
  tblExpenseexpdossierfk := TLargeintField.Create(Self);
  tblExpenseexpcategoryfk := TLargeintField.Create(Self);
  tblExpenseexpcategory := TStringField.Create(Self);
  tblExpenseexpkindfk := TLargeintField.Create(Self);
  tblExpenseexpkind := TStringField.Create(Self);
  tblExpenseexppaymenton := TSmallintField.Create(Self);
  tblExpenseexppaymentperiodfk := TLargeintField.Create(Self);
  tblExpenseexppaymentperiod := TStringField.Create(Self);
  tblExpenseexpamount := TFMTBCDField.Create(Self);
  tblExpenseexpremark := TStringField.Create(Self);
  tblLegalCase := TP2WDADataset.Create(Self);
  tblLegalCaselecid := TLargeintField.Create(Self);
  tblLegalCaseleccreatedon := TDateTimeField.Create(Self);
  tblLegalCaseleccreatedbyfk := TLargeintField.Create(Self);
  tblLegalCaselecchangedon := TDateTimeField.Create(Self);
  tblLegalCaselecchangedbyfk := TLargeintField.Create(Self);
  tblLegalCaselecdossierfk := TLargeintField.Create(Self);
  tblLegalCaseleccause := TStringField.Create(Self);
  tblLegalCaselecprimaryfk := TLargeintField.Create(Self);
  tblLegalCaselecopponentfk := TLargeintField.Create(Self);
  tblLegalCaseleclawyerfk := TLargeintField.Create(Self);
  tblLegalCaseleccourt := TStringField.Create(Self);
  tblLegalCaselecreference := TStringField.Create(Self);
  tblLegalCaselecremark := TStringField.Create(Self);
  tblLegalCaselecamount := TFMTBCDField.Create(Self);
  tblLegalCaseleciscomplete := TBooleanField.Create(Self);
  tblPaymentPlan := TP2WDADataset.Create(Self);
  tblPaymentPlanpplid := TLargeintField.Create(Self);
  tblPaymentPlanpplcreatedon := TDateTimeField.Create(Self);
  tblPaymentPlanpplcreatedbyfk := TLargeintField.Create(Self);
  tblPaymentPlanpplchangedon := TDateTimeField.Create(Self);
  tblPaymentPlanpplchangedbyfk := TLargeintField.Create(Self);
  tblPaymentPlanppldossierfk := TLargeintField.Create(Self);
  tblPaymentPlanDetail := TP2WDADataset.Create(Self);
  tblPaymentPlanDetailppdid := TLargeintField.Create(Self);
  tblPaymentPlanDetailppdcreatedon := TDateTimeField.Create(Self);
  tblPaymentPlanDetailppdcreatedbyfk := TLargeintField.Create(Self);
  tblPaymentPlanDetailppdchangedon := TDateTimeField.Create(Self);
  tblPaymentPlanDetailppdchangedbyfk := TLargeintField.Create(Self);
  tblPaymentPlanDetailppdpaymentplanfk := TLargeintField.Create(Self);
  tblPaymentPlanDetailppdamount := TFMTBCDField.Create(Self);
  tblPaymentPlanDetailppdtotalamount := TFMTBCDField.Create(Self);
  tblPaymentPlanDetailppdpaymenttofk := TLargeintField.Create(Self);
  tblPaymentPlanDetailppdiban := TStringField.Create(Self);
  tblPaymentPlanDetailppdname := TStringField.Create(Self);
  tblPaymentPlanDetailppdperiodicityfk := TLargeintField.Create(Self);
  tblPaymentPlanDetailppdperiodicity := TStringField.Create(Self);
  tblProperty := TP2WDADataset.Create(Self);
  tblPropertyproid := TLargeintField.Create(Self);
  tblPropertyprocreatedon := TDateTimeField.Create(Self);
  tblPropertyprocreatedbyfk := TLargeintField.Create(Self);
  tblPropertyprochangedon := TDateTimeField.Create(Self);
  tblPropertyprochangedbyfk := TLargeintField.Create(Self);
  tblPropertyprodossierfk := TLargeintField.Create(Self);
  tblPropertypropropertytype := TStringField.Create(Self);
  tblPropertypropropertytypefk := TLargeintField.Create(Self);
  tblPropertyproowner := TStringField.Create(Self);
  tblPropertyproownerfk := TLargeintField.Create(Self);
  tblPropertyproisrealestate := TBooleanField.Create(Self);
  tblPropertyproshortdescription := TStringField.Create(Self);
  tblPropertyprodescription := TBlobField.Create(Self);
  tblPropertyprocount := TIntegerField.Create(Self);
  tblPropertypropurchasedon := TDateTimeField.Create(Self);
  tblPropertypronotaryname := TStringField.Create(Self);
  tblPropertypropurchaseprice := TFMTBCDField.Create(Self);
  tblPropertyprocurrentvalue := TFMTBCDField.Create(Self);
  tblPropertyproisfinanced := TBooleanField.Create(Self);
  tblPropertyproisseized := TBooleanField.Create(Self);
  tblPropertyproisshared := TBooleanField.Create(Self);
  tblPropertyproisgone := TBooleanField.Create(Self);
  tblPropertyprogoneon := TDateTimeField.Create(Self);
  tblPropertyprobuyerfk := TLargeintField.Create(Self);
  tblPropertyproiscomplete := TBooleanField.Create(Self);
  tblPropertyproremark := TStringField.Create(Self);
  tblRevenue := TP2WDADataset.Create(Self);
  tblRevenuerevid := TLargeintField.Create(Self);
  tblRevenuerevcreatedon := TDateTimeField.Create(Self);
  tblRevenuerevcreatedbyfk := TLargeintField.Create(Self);
  tblRevenuerevchangedon := TDateTimeField.Create(Self);
  tblRevenuerevchangedbyfk := TLargeintField.Create(Self);
  tblRevenuerevdossierfk := TLargeintField.Create(Self);
  tblRevenuerevrevenuetypefk := TLargeintField.Create(Self);
  tblRevenuerevrevenuetype := TWideStringField.Create(Self);
  tblRevenuerevamount := TBCDField.Create(Self);
  tblRevenuerevperiodtypefk := TLargeintField.Create(Self);
  tblRevenuerevperiodtype := TWideStringField.Create(Self);
  tblRevenuerevviatypefk := TLargeintField.Create(Self);
  tblRevenuerevviatype := TWideStringField.Create(Self);
  tblRevenuerevfromfk := TLargeintField.Create(Self);
  tblRevenuerevremark := TWideStringField.Create(Self);
  tblRevenuereviscomplete := TBooleanField.Create(Self);
  tblRevenuerevorigin := TWideMemoField.Create(Self);
  tblAccountInfo := TP2WDADataset.Create(Self);
  tblCompany := TP2WDADataset.Create(Self);
  tblCompanycomid := TLargeintField.Create(Self);
  tblCompanycomcreatedon := TDateTimeField.Create(Self);
  tblCompanycomcreatedbyfk := TLargeintField.Create(Self);
  tblCompanycomchangedon := TDateTimeField.Create(Self);
  tblCompanycomchangedbyfk := TLargeintField.Create(Self);
  tblCompanycomname := TStringField.Create(Self);
  tblCompanycomstreet := TStringField.Create(Self);
  tblCompanycomhousenr := TStringField.Create(Self);
  tblCompanycomhousenrextra := TStringField.Create(Self);
  tblCompanycomzip := TStringField.Create(Self);
  tblCompanycomcity := TStringField.Create(Self);
  tblCompanycomcityfk := TLargeintField.Create(Self);
  tblCompanycomcountry := TStringField.Create(Self);
  tblCompanycomcountryfk := TLargeintField.Create(Self);
  tblCompanycomemail := TStringField.Create(Self);
  tblCompanycombankaccount := TStringField.Create(Self);
  tblCompanycomvat := TStringField.Create(Self);
  tblCompanycomtelephone := TStringField.Create(Self);
  tblCompanycomstatus := TSmallintField.Create(Self);
  tblCompanycompriority := TSmallintField.Create(Self);
  tblCompanycomlanguagefk := TLargeintField.Create(Self);
  tblCompanycomdatabaseid := TSmallintField.Create(Self);
  tblCompanyOverview := TP2WDADataset.Create(Self);
  tblCompanyOverviewcomid := TLargeintField.Create(Self);
  tblCompanyOverviewcomname := TWideStringField.Create(Self);
  tblCompanyOverviewcomstreet := TWideStringField.Create(Self);
  tblCompanyOverviewcomhousenr := TWideStringField.Create(Self);
  tblCompanyOverviewcomzip := TWideStringField.Create(Self);
  tblCompanyOverviewcomcity := TWideStringField.Create(Self);
  tblCompanyOverviewcomcountry := TWideStringField.Create(Self);
  tblCity := TP2WDADataset.Create(Self);
  tblCitycitid := TLargeintField.Create(Self);
  tblCitycitcreatedon := TDateTimeField.Create(Self);
  tblCitycitcreatedbyfk := TLargeintField.Create(Self);
  tblCitycitchangedon := TDateTimeField.Create(Self);
  tblCitycitchangedbyfk := TLargeintField.Create(Self);
  tblCitycitcountryfk := TLargeintField.Create(Self);
  tblCitycitzip := TStringField.Create(Self);
  tblCitycitname := TStringField.Create(Self);
  tblCityOverview := TP2WDADataset.Create(Self);
  tblCityOverviewcitID := TLargeintField.Create(Self);
  tblCityOverviewcitZip := TWideStringField.Create(Self);
  tblCityOverviewcitName := TWideStringField.Create(Self);
  tblCityOverviewconID := TLargeintField.Create(Self);
  tblCityOverviewconISO2 := TWideStringField.Create(Self);
  tblCityOverviewconISO3 := TWideStringField.Create(Self);
  tblCityOverviewconName := TWideStringField.Create(Self);
  tblCountry := TP2WDADataset.Create(Self);
  tblCountryconid := TLargeintField.Create(Self);
  tblCountryconcreatedon := TDateTimeField.Create(Self);
  tblCountryconcreatedbyfk := TLargeintField.Create(Self);
  tblCountryconchangedon := TDateTimeField.Create(Self);
  tblCountryconchangedbyfk := TLargeintField.Create(Self);
  tblCountryconiso2 := TStringField.Create(Self);
  tblCountryconiso3 := TStringField.Create(Self);
  tblCountryconname := TStringField.Create(Self);
  tblCountryOverview := TP2WDADataset.Create(Self);
  tblCountryOverviewconID := TLargeintField.Create(Self);
  tblCountryOverviewconISO2 := TWideStringField.Create(Self);
  tblCountryOverviewconISO3 := TWideStringField.Create(Self);
  tblCountryOverviewconName := TWideStringField.Create(Self);
  tblContactAddressOverview := TP2WDADataset.Create(Self);
  tblContactAddressOverviewctaid := TLargeintField.Create(Self);
  tblContactAddressOverviewctacreatedon := TDateTimeField.Create(Self);
  tblContactAddressOverviewctacreatedbyfk := TLargeintField.Create(Self);
  tblContactAddressOverviewctachangedon := TDateTimeField.Create(Self);
  tblContactAddressOverviewctachangedbyfk := TLargeintField.Create(Self);
  tblContactAddressOverviewctacontactfk := TLargeintField.Create(Self);
  tblContactAddressOverviewctaaddresstypefk := TLargeintField.Create(Self);
  tblContactAddressOverviewctaaddresstype := TStringField.Create(Self);
  tblContactAddressOverviewctastreet := TStringField.Create(Self);
  tblContactAddressOverviewctastreetnr := TStringField.Create(Self);
  tblContactAddressOverviewctacityzip := TStringField.Create(Self);
  tblContactAddressOverviewctacityname := TStringField.Create(Self);
  tblContactAddressOverviewctacityfk := TLargeintField.Create(Self);
  tblContactAddressOverviewctaremark := TStringField.Create(Self);
  tblContactAddressOverviewctacorrespondence := TBooleanField.Create(Self);
  tblContactAddressOverviewctasecret := TBooleanField.Create(Self);
  tblContactOverview := TP2WDADataset.Create(Self);
  tblContactOverviewcntid := TLargeintField.Create(Self);
  tblContactOverviewcntcreatedon := TDateTimeField.Create(Self);
  tblContactOverviewcntcreatedbyfk := TLargeintField.Create(Self);
  tblContactOverviewcntchangedon := TDateTimeField.Create(Self);
  tblContactOverviewcntchangedbyfk := TLargeintField.Create(Self);
  tblContactOverviewcntcompanyfk := TLargeintField.Create(Self);
  tblContactOverviewcntfirstname := TStringField.Create(Self);
  tblContactOverviewcntlastname := TStringField.Create(Self);
  tblContactOverviewcntprofession := TStringField.Create(Self);
  tblContactOverviewcntbirthdateon := TDateTimeField.Create(Self);
  tblContactOverviewcntisbirthdateunknown := TBooleanField.Create(Self);
  tblContactOverviewcntgender := TStringField.Create(Self);
  tblContactOverviewcntcityofbirthfk := TLargeintField.Create(Self);
  tblContactOverviewcntcityofbirthname := TStringField.Create(Self);
  tblContactOverviewcntcityofbirthzip := TStringField.Create(Self);
  tblContactOverviewcntnationalityfk := TLargeintField.Create(Self);
  tblContactOverviewcntnationality2 := TStringField.Create(Self);
  tblContactOverviewcntnationality := TStringField.Create(Self);
  tblContactOverviewcntpicturefk := TLargeintField.Create(Self);
  tblContactOverviewcntkbonr := TStringField.Create(Self);
  tblContactOverviewcntremark := TStringField.Create(Self);
  tblContactOverviewcntpersonfk := TLargeintField.Create(Self);
  tblContactOverviewcntsalutation := TStringField.Create(Self);
  tblContactOverviewcntsearchname := TStringField.Create(Self);
  tblContactOverviewcntprefixes := TStringField.Create(Self);
  tblContactOverviewcntfriendlytitle := TStringField.Create(Self);
  tblContactRelationsOverview := TP2WDADataset.Create(Self);
  tblContactRelationsOverviewctrid := TLargeintField.Create(Self);
  tblContactRelationsOverviewctrcreatedon := TDateTimeField.Create(Self);
  tblContactRelationsOverviewctrcreatedbyfk := TLargeintField.Create(Self);
  tblContactRelationsOverviewctrchangedon := TDateTimeField.Create(Self);
  tblContactRelationsOverviewctrchangedbyfk := TLargeintField.Create(Self);
  tblContactRelationsOverviewctrfromcontactfk := TLargeintField.Create(Self);
  tblContactRelationsOverviewctrtocontactfk := TLargeintField.Create(Self);
  tblContactRelationsOverviewctrrelationtype := TStringField.Create(Self);
  tblContactRelationsOverviewctrrelationtypefk := TLargeintField.Create(Self);
  tblContactTelecomOverview := TP2WDADataset.Create(Self);
  tblContactTelecomOverviewcttid := TLargeintField.Create(Self);
  tblContactTelecomOverviewcttcreatedon := TDateTimeField.Create(Self);
  tblContactTelecomOverviewcttcreatedbyfk := TLargeintField.Create(Self);
  tblContactTelecomOverviewcttchangedon := TDateTimeField.Create(Self);
  tblContactTelecomOverviewcttchangedbyfk := TLargeintField.Create(Self);
  tblContactTelecomOverviewcttcontactfk := TLargeintField.Create(Self);
  tblContactTelecomOverviewcttcontacttypefk := TLargeintField.Create(Self);
  tblContactTelecomOverviewcttcontacttype := TStringField.Create(Self);
  tblContactTelecomOverviewcttdata := TStringField.Create(Self);
  tblContactTelecomOverviewcttremark := TStringField.Create(Self);
  tblContactTelecomOverviewcttcorrespondence := TBooleanField.Create(Self);
  dsParameters := TP2WDADataset.Create(Self);
  dsParametersparid := TLargeintField.Create(Self);
  dsParametersparcreatedon := TDateTimeField.Create(Self);
  dsParametersparcreatedbyfk := TLargeintField.Create(Self);
  dsParametersparchangedon := TDateTimeField.Create(Self);
  dsParametersparchangedbyfk := TLargeintField.Create(Self);
  dsParametersparparamtype := TWideStringField.Create(Self);
  dsParametersparvaluetype := TSmallintField.Create(Self);
  dsParametersparvaluename := TWideStringField.Create(Self);
  dsParametersparvalue := TWideStringField.Create(Self);
  dsParametersparextra := TWideStringField.Create(Self);
  dsParametersparlanguage := TSmallintField.Create(Self);
  ParameterConnection := TP2WDAConnection.Create(Self);
  CSRConnection := TP2WDAConnection.Create(Self);

  CompanyConnection.BeforeLoadDFMValues;
  DataConnection.BeforeLoadDFMValues;
  ContactConnection.BeforeLoadDFMValues;
  GeoConnection.BeforeLoadDFMValues;
  PetitionConnection.BeforeLoadDFMValues;
  tblContactAddress.BeforeLoadDFMValues;
  tblContactAddressctaid.BeforeLoadDFMValues;
  tblContactAddressctacreatedon.BeforeLoadDFMValues;
  tblContactAddressctacreatedbyfk.BeforeLoadDFMValues;
  tblContactAddressctachangedon.BeforeLoadDFMValues;
  tblContactAddressctachangedbyfk.BeforeLoadDFMValues;
  tblContactAddressctacontactfk.BeforeLoadDFMValues;
  tblContactAddressctaaddresstypefk.BeforeLoadDFMValues;
  tblContactAddressctaaddresstype.BeforeLoadDFMValues;
  tblContactAddressctastreet.BeforeLoadDFMValues;
  tblContactAddressctastreetnr.BeforeLoadDFMValues;
  tblContactAddressctacityzip.BeforeLoadDFMValues;
  tblContactAddressctacityname.BeforeLoadDFMValues;
  tblContactAddressctacityfk.BeforeLoadDFMValues;
  tblContactAddressctaremark.BeforeLoadDFMValues;
  tblContactAddressctacorrespondence.BeforeLoadDFMValues;
  tblContactAddressctasecret.BeforeLoadDFMValues;
  tblDebt.BeforeLoadDFMValues;
  tblDebtdebid.BeforeLoadDFMValues;
  tblDebtdebcreatedon.BeforeLoadDFMValues;
  tblDebtdebcreatedbyfk.BeforeLoadDFMValues;
  tblDebtdebchangedon.BeforeLoadDFMValues;
  tblDebtdebchangedbyfk.BeforeLoadDFMValues;
  tblDebtdebdossierfk.BeforeLoadDFMValues;
  tblDebtdebrecordtype.BeforeLoadDFMValues;
  tblDebtdebcreditorfk.BeforeLoadDFMValues;
  tblDebtdebdebttypefk.BeforeLoadDFMValues;
  tblDebtdebdebttype.BeforeLoadDFMValues;
  tblDebtdebstarton.BeforeLoadDFMValues;
  tblDebtdebreference.BeforeLoadDFMValues;
  tblDebtdeboriginalamount.BeforeLoadDFMValues;
  tblDebtdebcurrentamount.BeforeLoadDFMValues;
  tblDebtdebmonthlyamount.BeforeLoadDFMValues;
  tblDebtdebisdisputed.BeforeLoadDFMValues;
  tblDebtdebdisputereason.BeforeLoadDFMValues;
  tblDebtdebdisputecourt.BeforeLoadDFMValues;
  tblDebtdebdisputedamount.BeforeLoadDFMValues;
  tblDebtdebisdelay.BeforeLoadDFMValues;
  tblDebtdebdelayamount.BeforeLoadDFMValues;
  tblDebtdebguarantorfk.BeforeLoadDFMValues;
  tblDebtdebiscompleted.BeforeLoadDFMValues;
  tblDossier.BeforeLoadDFMValues;
  tblDossierdosid.BeforeLoadDFMValues;
  tblDossierdoscreatedon.BeforeLoadDFMValues;
  tblDossierdoscreatedbyfk.BeforeLoadDFMValues;
  tblDossierdoschangedon.BeforeLoadDFMValues;
  tblDossierdoschangedbyfk.BeforeLoadDFMValues;
  tblDossierdosclosedon.BeforeLoadDFMValues;
  tblDossierdosdescription.BeforeLoadDFMValues;
  tblDossierdoscompanyfk.BeforeLoadDFMValues;
  tblDossierdosstatus.BeforeLoadDFMValues;
  tblDossierdosdatesent.BeforeLoadDFMValues;
  tblDossierdosclosedbyfk.BeforeLoadDFMValues;
  tblDossierdoscloseddescription.BeforeLoadDFMValues;
  tblDossierdosisbankrupt.BeforeLoadDFMValues;
  tblDossierdosbankrupton.BeforeLoadDFMValues;
  tblDossierdosiskbocancel.BeforeLoadDFMValues;
  tblDossierdoskbocancelon.BeforeLoadDFMValues;
  tblDossierdosloginfk.BeforeLoadDFMValues;
  tblDossierdosremarks.BeforeLoadDFMValues;
  tblDossierdosreopenedon.BeforeLoadDFMValues;
  tblDossierdosflags.BeforeLoadDFMValues;
  tblDossierdosmonthlyamount.BeforeLoadDFMValues;
  tblDossierdosiscomplete.BeforeLoadDFMValues;
  tblDossierdoscasefilecounter.BeforeLoadDFMValues;
  tblDossierFile.BeforeLoadDFMValues;
  tblDossierFiledofid.BeforeLoadDFMValues;
  tblDossierFiledofcreatedon.BeforeLoadDFMValues;
  tblDossierFiledofcreatedbyfk.BeforeLoadDFMValues;
  tblDossierFiledofchangedon.BeforeLoadDFMValues;
  tblDossierFiledofchangedbyfk.BeforeLoadDFMValues;
  tblDossierFiledofdossierfk.BeforeLoadDFMValues;
  tblDossierFiledofsourcefk.BeforeLoadDFMValues;
  tblDossierFiledofsourcetype.BeforeLoadDFMValues;
  tblDossierFiledofdoctypefk.BeforeLoadDFMValues;
  tblDossierFiledofdoctype.BeforeLoadDFMValues;
  tblDossierFiledoffilename.BeforeLoadDFMValues;
  tblDossierFiledofmime.BeforeLoadDFMValues;
  tblDossierFiledofdata.BeforeLoadDFMValues;
  tblDossierFiledofisuploadcomplete.BeforeLoadDFMValues;
  tblDossierFiledofuploaduserfk.BeforeLoadDFMValues;
  tblDossierFiledofcasefilenumber.BeforeLoadDFMValues;
  tblDossierPersons.BeforeLoadDFMValues;
  tblDossierPersonscntid.BeforeLoadDFMValues;
  tblDossierPersonscntcreatedon.BeforeLoadDFMValues;
  tblDossierPersonscntcreatedbyfk.BeforeLoadDFMValues;
  tblDossierPersonscntchangedon.BeforeLoadDFMValues;
  tblDossierPersonscntchangedbyfk.BeforeLoadDFMValues;
  tblDossierPersonscntcompanyfk.BeforeLoadDFMValues;
  tblDossierPersonscntfirstname.BeforeLoadDFMValues;
  tblDossierPersonscntlastname.BeforeLoadDFMValues;
  tblDossierPersonscntprofession.BeforeLoadDFMValues;
  tblDossierPersonscntbirthdateon.BeforeLoadDFMValues;
  tblDossierPersonscntisbirthdateunknown.BeforeLoadDFMValues;
  tblDossierPersonscntgender.BeforeLoadDFMValues;
  tblDossierPersonscntcityofbirthfk.BeforeLoadDFMValues;
  tblDossierPersonscntcityofbirthname.BeforeLoadDFMValues;
  tblDossierPersonscntcityofbirthzip.BeforeLoadDFMValues;
  tblDossierPersonscntnationalityfk.BeforeLoadDFMValues;
  tblDossierPersonscntnationality2.BeforeLoadDFMValues;
  tblDossierPersonscntnationality.BeforeLoadDFMValues;
  tblDossierPersonscntpicturefk.BeforeLoadDFMValues;
  tblDossierPersonscntkbonr.BeforeLoadDFMValues;
  tblDossierPersonscntremark.BeforeLoadDFMValues;
  tblDossierPersonscntpersonfk.BeforeLoadDFMValues;
  tblDossierPersonscntsalutation.BeforeLoadDFMValues;
  tblDossierPersonscntsearchname.BeforeLoadDFMValues;
  tblDossierPersonscntprefixes.BeforeLoadDFMValues;
  tblDossierPersonscntfriendlytitle.BeforeLoadDFMValues;
  tblDossierPersonsctaid.BeforeLoadDFMValues;
  tblDossierPersonsctacreatedon.BeforeLoadDFMValues;
  tblDossierPersonsctacreatedbyfk.BeforeLoadDFMValues;
  tblDossierPersonsctachangedon.BeforeLoadDFMValues;
  tblDossierPersonsctachangedbyfk.BeforeLoadDFMValues;
  tblDossierPersonsctacontactfk.BeforeLoadDFMValues;
  tblDossierPersonsctaaddresstypefk.BeforeLoadDFMValues;
  tblDossierPersonsctaaddresstype.BeforeLoadDFMValues;
  tblDossierPersonsctastreet.BeforeLoadDFMValues;
  tblDossierPersonsctastreetnr.BeforeLoadDFMValues;
  tblDossierPersonsctacityzip.BeforeLoadDFMValues;
  tblDossierPersonsctacityname.BeforeLoadDFMValues;
  tblDossierPersonsctacityfk.BeforeLoadDFMValues;
  tblDossierPersonsctaremark.BeforeLoadDFMValues;
  tblDossierPersonsctacorrespondence.BeforeLoadDFMValues;
  tblDossierPersonsctasecret.BeforeLoadDFMValues;
  tblDossierPersonsdocid.BeforeLoadDFMValues;
  tblDossierPersonsdoccivilstatus.BeforeLoadDFMValues;
  tblDossierPersonsdoccivilstatusfk.BeforeLoadDFMValues;
  tblDossierPersonsdocissecondpetitioner.BeforeLoadDFMValues;
  tblDossierPersonsdocmarcon.BeforeLoadDFMValues;
  tblDossierPersonsdocmarconfk.BeforeLoadDFMValues;
  tblDossierPersonsdocpersontype.BeforeLoadDFMValues;
  tblDossierPersonsdocpersontypefk.BeforeLoadDFMValues;
  tblExpense.BeforeLoadDFMValues;
  tblExpenseexpid.BeforeLoadDFMValues;
  tblExpenseexpcreatedon.BeforeLoadDFMValues;
  tblExpenseexpcreatedbyfk.BeforeLoadDFMValues;
  tblExpenseexpchangedon.BeforeLoadDFMValues;
  tblExpenseexpchangedbyfk.BeforeLoadDFMValues;
  tblExpenseexpdossierfk.BeforeLoadDFMValues;
  tblExpenseexpcategoryfk.BeforeLoadDFMValues;
  tblExpenseexpcategory.BeforeLoadDFMValues;
  tblExpenseexpkindfk.BeforeLoadDFMValues;
  tblExpenseexpkind.BeforeLoadDFMValues;
  tblExpenseexppaymenton.BeforeLoadDFMValues;
  tblExpenseexppaymentperiodfk.BeforeLoadDFMValues;
  tblExpenseexppaymentperiod.BeforeLoadDFMValues;
  tblExpenseexpamount.BeforeLoadDFMValues;
  tblExpenseexpremark.BeforeLoadDFMValues;
  tblLegalCase.BeforeLoadDFMValues;
  tblLegalCaselecid.BeforeLoadDFMValues;
  tblLegalCaseleccreatedon.BeforeLoadDFMValues;
  tblLegalCaseleccreatedbyfk.BeforeLoadDFMValues;
  tblLegalCaselecchangedon.BeforeLoadDFMValues;
  tblLegalCaselecchangedbyfk.BeforeLoadDFMValues;
  tblLegalCaselecdossierfk.BeforeLoadDFMValues;
  tblLegalCaseleccause.BeforeLoadDFMValues;
  tblLegalCaselecprimaryfk.BeforeLoadDFMValues;
  tblLegalCaselecopponentfk.BeforeLoadDFMValues;
  tblLegalCaseleclawyerfk.BeforeLoadDFMValues;
  tblLegalCaseleccourt.BeforeLoadDFMValues;
  tblLegalCaselecreference.BeforeLoadDFMValues;
  tblLegalCaselecremark.BeforeLoadDFMValues;
  tblLegalCaselecamount.BeforeLoadDFMValues;
  tblLegalCaseleciscomplete.BeforeLoadDFMValues;
  tblPaymentPlan.BeforeLoadDFMValues;
  tblPaymentPlanpplid.BeforeLoadDFMValues;
  tblPaymentPlanpplcreatedon.BeforeLoadDFMValues;
  tblPaymentPlanpplcreatedbyfk.BeforeLoadDFMValues;
  tblPaymentPlanpplchangedon.BeforeLoadDFMValues;
  tblPaymentPlanpplchangedbyfk.BeforeLoadDFMValues;
  tblPaymentPlanppldossierfk.BeforeLoadDFMValues;
  tblPaymentPlanDetail.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdid.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdcreatedon.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdcreatedbyfk.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdchangedon.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdchangedbyfk.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdpaymentplanfk.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdamount.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdtotalamount.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdpaymenttofk.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdiban.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdname.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdperiodicityfk.BeforeLoadDFMValues;
  tblPaymentPlanDetailppdperiodicity.BeforeLoadDFMValues;
  tblProperty.BeforeLoadDFMValues;
  tblPropertyproid.BeforeLoadDFMValues;
  tblPropertyprocreatedon.BeforeLoadDFMValues;
  tblPropertyprocreatedbyfk.BeforeLoadDFMValues;
  tblPropertyprochangedon.BeforeLoadDFMValues;
  tblPropertyprochangedbyfk.BeforeLoadDFMValues;
  tblPropertyprodossierfk.BeforeLoadDFMValues;
  tblPropertypropropertytype.BeforeLoadDFMValues;
  tblPropertypropropertytypefk.BeforeLoadDFMValues;
  tblPropertyproowner.BeforeLoadDFMValues;
  tblPropertyproownerfk.BeforeLoadDFMValues;
  tblPropertyproisrealestate.BeforeLoadDFMValues;
  tblPropertyproshortdescription.BeforeLoadDFMValues;
  tblPropertyprodescription.BeforeLoadDFMValues;
  tblPropertyprocount.BeforeLoadDFMValues;
  tblPropertypropurchasedon.BeforeLoadDFMValues;
  tblPropertypronotaryname.BeforeLoadDFMValues;
  tblPropertypropurchaseprice.BeforeLoadDFMValues;
  tblPropertyprocurrentvalue.BeforeLoadDFMValues;
  tblPropertyproisfinanced.BeforeLoadDFMValues;
  tblPropertyproisseized.BeforeLoadDFMValues;
  tblPropertyproisshared.BeforeLoadDFMValues;
  tblPropertyproisgone.BeforeLoadDFMValues;
  tblPropertyprogoneon.BeforeLoadDFMValues;
  tblPropertyprobuyerfk.BeforeLoadDFMValues;
  tblPropertyproiscomplete.BeforeLoadDFMValues;
  tblPropertyproremark.BeforeLoadDFMValues;
  tblRevenue.BeforeLoadDFMValues;
  tblRevenuerevid.BeforeLoadDFMValues;
  tblRevenuerevcreatedon.BeforeLoadDFMValues;
  tblRevenuerevcreatedbyfk.BeforeLoadDFMValues;
  tblRevenuerevchangedon.BeforeLoadDFMValues;
  tblRevenuerevchangedbyfk.BeforeLoadDFMValues;
  tblRevenuerevdossierfk.BeforeLoadDFMValues;
  tblRevenuerevrevenuetypefk.BeforeLoadDFMValues;
  tblRevenuerevrevenuetype.BeforeLoadDFMValues;
  tblRevenuerevamount.BeforeLoadDFMValues;
  tblRevenuerevperiodtypefk.BeforeLoadDFMValues;
  tblRevenuerevperiodtype.BeforeLoadDFMValues;
  tblRevenuerevviatypefk.BeforeLoadDFMValues;
  tblRevenuerevviatype.BeforeLoadDFMValues;
  tblRevenuerevfromfk.BeforeLoadDFMValues;
  tblRevenuerevremark.BeforeLoadDFMValues;
  tblRevenuereviscomplete.BeforeLoadDFMValues;
  tblRevenuerevorigin.BeforeLoadDFMValues;
  tblAccountInfo.BeforeLoadDFMValues;
  tblCompany.BeforeLoadDFMValues;
  tblCompanycomid.BeforeLoadDFMValues;
  tblCompanycomcreatedon.BeforeLoadDFMValues;
  tblCompanycomcreatedbyfk.BeforeLoadDFMValues;
  tblCompanycomchangedon.BeforeLoadDFMValues;
  tblCompanycomchangedbyfk.BeforeLoadDFMValues;
  tblCompanycomname.BeforeLoadDFMValues;
  tblCompanycomstreet.BeforeLoadDFMValues;
  tblCompanycomhousenr.BeforeLoadDFMValues;
  tblCompanycomhousenrextra.BeforeLoadDFMValues;
  tblCompanycomzip.BeforeLoadDFMValues;
  tblCompanycomcity.BeforeLoadDFMValues;
  tblCompanycomcityfk.BeforeLoadDFMValues;
  tblCompanycomcountry.BeforeLoadDFMValues;
  tblCompanycomcountryfk.BeforeLoadDFMValues;
  tblCompanycomemail.BeforeLoadDFMValues;
  tblCompanycombankaccount.BeforeLoadDFMValues;
  tblCompanycomvat.BeforeLoadDFMValues;
  tblCompanycomtelephone.BeforeLoadDFMValues;
  tblCompanycomstatus.BeforeLoadDFMValues;
  tblCompanycompriority.BeforeLoadDFMValues;
  tblCompanycomlanguagefk.BeforeLoadDFMValues;
  tblCompanycomdatabaseid.BeforeLoadDFMValues;
  tblCompanyOverview.BeforeLoadDFMValues;
  tblCompanyOverviewcomid.BeforeLoadDFMValues;
  tblCompanyOverviewcomname.BeforeLoadDFMValues;
  tblCompanyOverviewcomstreet.BeforeLoadDFMValues;
  tblCompanyOverviewcomhousenr.BeforeLoadDFMValues;
  tblCompanyOverviewcomzip.BeforeLoadDFMValues;
  tblCompanyOverviewcomcity.BeforeLoadDFMValues;
  tblCompanyOverviewcomcountry.BeforeLoadDFMValues;
  tblCity.BeforeLoadDFMValues;
  tblCitycitid.BeforeLoadDFMValues;
  tblCitycitcreatedon.BeforeLoadDFMValues;
  tblCitycitcreatedbyfk.BeforeLoadDFMValues;
  tblCitycitchangedon.BeforeLoadDFMValues;
  tblCitycitchangedbyfk.BeforeLoadDFMValues;
  tblCitycitcountryfk.BeforeLoadDFMValues;
  tblCitycitzip.BeforeLoadDFMValues;
  tblCitycitname.BeforeLoadDFMValues;
  tblCityOverview.BeforeLoadDFMValues;
  tblCityOverviewcitID.BeforeLoadDFMValues;
  tblCityOverviewcitZip.BeforeLoadDFMValues;
  tblCityOverviewcitName.BeforeLoadDFMValues;
  tblCityOverviewconID.BeforeLoadDFMValues;
  tblCityOverviewconISO2.BeforeLoadDFMValues;
  tblCityOverviewconISO3.BeforeLoadDFMValues;
  tblCityOverviewconName.BeforeLoadDFMValues;
  tblCountry.BeforeLoadDFMValues;
  tblCountryconid.BeforeLoadDFMValues;
  tblCountryconcreatedon.BeforeLoadDFMValues;
  tblCountryconcreatedbyfk.BeforeLoadDFMValues;
  tblCountryconchangedon.BeforeLoadDFMValues;
  tblCountryconchangedbyfk.BeforeLoadDFMValues;
  tblCountryconiso2.BeforeLoadDFMValues;
  tblCountryconiso3.BeforeLoadDFMValues;
  tblCountryconname.BeforeLoadDFMValues;
  tblCountryOverview.BeforeLoadDFMValues;
  tblCountryOverviewconID.BeforeLoadDFMValues;
  tblCountryOverviewconISO2.BeforeLoadDFMValues;
  tblCountryOverviewconISO3.BeforeLoadDFMValues;
  tblCountryOverviewconName.BeforeLoadDFMValues;
  tblContactAddressOverview.BeforeLoadDFMValues;
  tblContactAddressOverviewctaid.BeforeLoadDFMValues;
  tblContactAddressOverviewctacreatedon.BeforeLoadDFMValues;
  tblContactAddressOverviewctacreatedbyfk.BeforeLoadDFMValues;
  tblContactAddressOverviewctachangedon.BeforeLoadDFMValues;
  tblContactAddressOverviewctachangedbyfk.BeforeLoadDFMValues;
  tblContactAddressOverviewctacontactfk.BeforeLoadDFMValues;
  tblContactAddressOverviewctaaddresstypefk.BeforeLoadDFMValues;
  tblContactAddressOverviewctaaddresstype.BeforeLoadDFMValues;
  tblContactAddressOverviewctastreet.BeforeLoadDFMValues;
  tblContactAddressOverviewctastreetnr.BeforeLoadDFMValues;
  tblContactAddressOverviewctacityzip.BeforeLoadDFMValues;
  tblContactAddressOverviewctacityname.BeforeLoadDFMValues;
  tblContactAddressOverviewctacityfk.BeforeLoadDFMValues;
  tblContactAddressOverviewctaremark.BeforeLoadDFMValues;
  tblContactAddressOverviewctacorrespondence.BeforeLoadDFMValues;
  tblContactAddressOverviewctasecret.BeforeLoadDFMValues;
  tblContactOverview.BeforeLoadDFMValues;
  tblContactOverviewcntid.BeforeLoadDFMValues;
  tblContactOverviewcntcreatedon.BeforeLoadDFMValues;
  tblContactOverviewcntcreatedbyfk.BeforeLoadDFMValues;
  tblContactOverviewcntchangedon.BeforeLoadDFMValues;
  tblContactOverviewcntchangedbyfk.BeforeLoadDFMValues;
  tblContactOverviewcntcompanyfk.BeforeLoadDFMValues;
  tblContactOverviewcntfirstname.BeforeLoadDFMValues;
  tblContactOverviewcntlastname.BeforeLoadDFMValues;
  tblContactOverviewcntprofession.BeforeLoadDFMValues;
  tblContactOverviewcntbirthdateon.BeforeLoadDFMValues;
  tblContactOverviewcntisbirthdateunknown.BeforeLoadDFMValues;
  tblContactOverviewcntgender.BeforeLoadDFMValues;
  tblContactOverviewcntcityofbirthfk.BeforeLoadDFMValues;
  tblContactOverviewcntcityofbirthname.BeforeLoadDFMValues;
  tblContactOverviewcntcityofbirthzip.BeforeLoadDFMValues;
  tblContactOverviewcntnationalityfk.BeforeLoadDFMValues;
  tblContactOverviewcntnationality2.BeforeLoadDFMValues;
  tblContactOverviewcntnationality.BeforeLoadDFMValues;
  tblContactOverviewcntpicturefk.BeforeLoadDFMValues;
  tblContactOverviewcntkbonr.BeforeLoadDFMValues;
  tblContactOverviewcntremark.BeforeLoadDFMValues;
  tblContactOverviewcntpersonfk.BeforeLoadDFMValues;
  tblContactOverviewcntsalutation.BeforeLoadDFMValues;
  tblContactOverviewcntsearchname.BeforeLoadDFMValues;
  tblContactOverviewcntprefixes.BeforeLoadDFMValues;
  tblContactOverviewcntfriendlytitle.BeforeLoadDFMValues;
  tblContactRelationsOverview.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrid.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrcreatedon.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrcreatedbyfk.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrchangedon.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrchangedbyfk.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrfromcontactfk.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrtocontactfk.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrrelationtype.BeforeLoadDFMValues;
  tblContactRelationsOverviewctrrelationtypefk.BeforeLoadDFMValues;
  tblContactTelecomOverview.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttid.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttcreatedon.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttcreatedbyfk.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttchangedon.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttchangedbyfk.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttcontactfk.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttcontacttypefk.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttcontacttype.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttdata.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttremark.BeforeLoadDFMValues;
  tblContactTelecomOverviewcttcorrespondence.BeforeLoadDFMValues;
  dsParameters.BeforeLoadDFMValues;
  dsParametersparid.BeforeLoadDFMValues;
  dsParametersparcreatedon.BeforeLoadDFMValues;
  dsParametersparcreatedbyfk.BeforeLoadDFMValues;
  dsParametersparchangedon.BeforeLoadDFMValues;
  dsParametersparchangedbyfk.BeforeLoadDFMValues;
  dsParametersparparamtype.BeforeLoadDFMValues;
  dsParametersparvaluetype.BeforeLoadDFMValues;
  dsParametersparvaluename.BeforeLoadDFMValues;
  dsParametersparvalue.BeforeLoadDFMValues;
  dsParametersparextra.BeforeLoadDFMValues;
  dsParametersparlanguage.BeforeLoadDFMValues;
  ParameterConnection.BeforeLoadDFMValues;
  CSRConnection.BeforeLoadDFMValues;
  try
    Name := 'dmServer';
    Height := 553;
    Width := 800;
    CompanyConnection.SetParentComponent(Self);
    CompanyConnection.Name := 'CompanyConnection';
    CompanyConnection.URL := 'http://localhost:8099/bin';
    CompanyConnection.DataserviceName := 'CompanyService';
    CompanyConnection.Left := 40;
    CompanyConnection.Top := 448;
    DataConnection.SetParentComponent(Self);
    DataConnection.Name := 'DataConnection';
    DataConnection.URL := 'https://test.dezq.be/server/bin';
    DataConnection.DataserviceName := 'DataService';
    DataConnection.Left := 48;
    DataConnection.Top := 16;
    ContactConnection.SetParentComponent(Self);
    ContactConnection.Name := 'ContactConnection';
    ContactConnection.URL := 'https://test.dezq.be/server/bin';
    ContactConnection.DataserviceName := 'ContactService';
    ContactConnection.Left := 48;
    ContactConnection.Top := 272;
    GeoConnection.SetParentComponent(Self);
    GeoConnection.Name := 'GeoConnection';
    GeoConnection.URL := 'https://test.dezq.be/server/bin';
    GeoConnection.DataserviceName := 'GeoService';
    GeoConnection.Left := 40;
    GeoConnection.Top := 352;
    PetitionConnection.SetParentComponent(Self);
    PetitionConnection.Name := 'PetitionConnection';
    PetitionConnection.URL := 'http://localhost:8099/bin';
    PetitionConnection.DataserviceName := 'PetitionService';
    PetitionConnection.Left := 48;
    PetitionConnection.Top := 152;
    tblContactAddress.SetParentComponent(Self);
    tblContactAddress.Name := 'tblContactAddress';
    tblContactAddress.TableName := 'contactaddress';
    tblContactAddress.DAConnection := PetitionConnection;
    tblContactAddress.DAOptions := [];
    tblContactAddress.Left := 176;
    tblContactAddress.Top := 152;
    tblContactAddressctaid.SetParentComponent(tblContactAddress);
    tblContactAddressctaid.Name := 'tblContactAddressctaid';
    tblContactAddressctaid.FieldName := 'ctaid';
    tblContactAddressctacreatedon.SetParentComponent(tblContactAddress);
    tblContactAddressctacreatedon.Name := 'tblContactAddressctacreatedon';
    tblContactAddressctacreatedon.FieldName := 'ctacreatedon';
    tblContactAddressctacreatedbyfk.SetParentComponent(tblContactAddress);
    tblContactAddressctacreatedbyfk.Name := 'tblContactAddressctacreatedbyfk';
    tblContactAddressctacreatedbyfk.FieldName := 'ctacreatedbyfk';
    tblContactAddressctachangedon.SetParentComponent(tblContactAddress);
    tblContactAddressctachangedon.Name := 'tblContactAddressctachangedon';
    tblContactAddressctachangedon.FieldName := 'ctachangedon';
    tblContactAddressctachangedbyfk.SetParentComponent(tblContactAddress);
    tblContactAddressctachangedbyfk.Name := 'tblContactAddressctachangedbyfk';
    tblContactAddressctachangedbyfk.FieldName := 'ctachangedbyfk';
    tblContactAddressctacontactfk.SetParentComponent(tblContactAddress);
    tblContactAddressctacontactfk.Name := 'tblContactAddressctacontactfk';
    tblContactAddressctacontactfk.FieldName := 'ctacontactfk';
    tblContactAddressctaaddresstypefk.SetParentComponent(tblContactAddress);
    tblContactAddressctaaddresstypefk.Name := 'tblContactAddressctaaddresstypefk';
    tblContactAddressctaaddresstypefk.FieldName := 'ctaaddresstypefk';
    tblContactAddressctaaddresstype.SetParentComponent(tblContactAddress);
    tblContactAddressctaaddresstype.Name := 'tblContactAddressctaaddresstype';
    tblContactAddressctaaddresstype.FieldName := 'ctaaddresstype';
    tblContactAddressctaaddresstype.Size := 10;
    tblContactAddressctastreet.SetParentComponent(tblContactAddress);
    tblContactAddressctastreet.Name := 'tblContactAddressctastreet';
    tblContactAddressctastreet.FieldName := 'ctastreet';
    tblContactAddressctastreet.Size := 50;
    tblContactAddressctastreetnr.SetParentComponent(tblContactAddress);
    tblContactAddressctastreetnr.Name := 'tblContactAddressctastreetnr';
    tblContactAddressctastreetnr.FieldName := 'ctastreetnr';
    tblContactAddressctastreetnr.Size := 50;
    tblContactAddressctacityzip.SetParentComponent(tblContactAddress);
    tblContactAddressctacityzip.Name := 'tblContactAddressctacityzip';
    tblContactAddressctacityzip.FieldName := 'ctacityzip';
    tblContactAddressctacityzip.Size := 15;
    tblContactAddressctacityname.SetParentComponent(tblContactAddress);
    tblContactAddressctacityname.Name := 'tblContactAddressctacityname';
    tblContactAddressctacityname.FieldName := 'ctacityname';
    tblContactAddressctacityname.Size := 100;
    tblContactAddressctacityfk.SetParentComponent(tblContactAddress);
    tblContactAddressctacityfk.Name := 'tblContactAddressctacityfk';
    tblContactAddressctacityfk.FieldName := 'ctacityfk';
    tblContactAddressctaremark.SetParentComponent(tblContactAddress);
    tblContactAddressctaremark.Name := 'tblContactAddressctaremark';
    tblContactAddressctaremark.FieldName := 'ctaremark';
    tblContactAddressctaremark.Size := 255;
    tblContactAddressctacorrespondence.SetParentComponent(tblContactAddress);
    tblContactAddressctacorrespondence.Name := 'tblContactAddressctacorrespondence';
    tblContactAddressctacorrespondence.FieldName := 'ctacorrespondence';
    tblContactAddressctasecret.SetParentComponent(tblContactAddress);
    tblContactAddressctasecret.Name := 'tblContactAddressctasecret';
    tblContactAddressctasecret.FieldName := 'ctasecret';
    tblDebt.SetParentComponent(Self);
    tblDebt.Name := 'tblDebt';
    tblDebt.TableName := 'debt';
    tblDebt.DAConnection := PetitionConnection;
    tblDebt.Params.Clear;
    with tblDebt.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblDebt.DAOptions := [];
    tblDebt.Left := 264;
    tblDebt.Top := 152;
    tblDebtdebid.SetParentComponent(tblDebt);
    tblDebtdebid.Name := 'tblDebtdebid';
    tblDebtdebid.FieldName := 'debid';
    tblDebtdebcreatedon.SetParentComponent(tblDebt);
    tblDebtdebcreatedon.Name := 'tblDebtdebcreatedon';
    tblDebtdebcreatedon.FieldName := 'debcreatedon';
    tblDebtdebcreatedbyfk.SetParentComponent(tblDebt);
    tblDebtdebcreatedbyfk.Name := 'tblDebtdebcreatedbyfk';
    tblDebtdebcreatedbyfk.FieldName := 'debcreatedbyfk';
    tblDebtdebchangedon.SetParentComponent(tblDebt);
    tblDebtdebchangedon.Name := 'tblDebtdebchangedon';
    tblDebtdebchangedon.FieldName := 'debchangedon';
    tblDebtdebchangedbyfk.SetParentComponent(tblDebt);
    tblDebtdebchangedbyfk.Name := 'tblDebtdebchangedbyfk';
    tblDebtdebchangedbyfk.FieldName := 'debchangedbyfk';
    tblDebtdebdossierfk.SetParentComponent(tblDebt);
    tblDebtdebdossierfk.Name := 'tblDebtdebdossierfk';
    tblDebtdebdossierfk.FieldName := 'debdossierfk';
    tblDebtdebrecordtype.SetParentComponent(tblDebt);
    tblDebtdebrecordtype.Name := 'tblDebtdebrecordtype';
    tblDebtdebrecordtype.FieldName := 'debrecordtype';
    tblDebtdebcreditorfk.SetParentComponent(tblDebt);
    tblDebtdebcreditorfk.Name := 'tblDebtdebcreditorfk';
    tblDebtdebcreditorfk.FieldName := 'debcreditorfk';
    tblDebtdebdebttypefk.SetParentComponent(tblDebt);
    tblDebtdebdebttypefk.Name := 'tblDebtdebdebttypefk';
    tblDebtdebdebttypefk.FieldName := 'debdebttypefk';
    tblDebtdebdebttype.SetParentComponent(tblDebt);
    tblDebtdebdebttype.Name := 'tblDebtdebdebttype';
    tblDebtdebdebttype.FieldName := 'debdebttype';
    tblDebtdebdebttype.Size := 10;
    tblDebtdebstarton.SetParentComponent(tblDebt);
    tblDebtdebstarton.Name := 'tblDebtdebstarton';
    tblDebtdebstarton.FieldName := 'debstarton';
    tblDebtdebreference.SetParentComponent(tblDebt);
    tblDebtdebreference.Name := 'tblDebtdebreference';
    tblDebtdebreference.FieldName := 'debreference';
    tblDebtdebreference.Size := 64;
    tblDebtdeboriginalamount.SetParentComponent(tblDebt);
    tblDebtdeboriginalamount.Name := 'tblDebtdeboriginalamount';
    tblDebtdeboriginalamount.FieldName := 'deboriginalamount';
    tblDebtdeboriginalamount.Precision := 12;
    tblDebtdeboriginalamount.Size := 0;
    tblDebtdebcurrentamount.SetParentComponent(tblDebt);
    tblDebtdebcurrentamount.Name := 'tblDebtdebcurrentamount';
    tblDebtdebcurrentamount.FieldName := 'debcurrentamount';
    tblDebtdebcurrentamount.Precision := 12;
    tblDebtdebcurrentamount.Size := 0;
    tblDebtdebmonthlyamount.SetParentComponent(tblDebt);
    tblDebtdebmonthlyamount.Name := 'tblDebtdebmonthlyamount';
    tblDebtdebmonthlyamount.FieldName := 'debmonthlyamount';
    tblDebtdebmonthlyamount.Precision := 12;
    tblDebtdebmonthlyamount.Size := 0;
    tblDebtdebisdisputed.SetParentComponent(tblDebt);
    tblDebtdebisdisputed.Name := 'tblDebtdebisdisputed';
    tblDebtdebisdisputed.FieldName := 'debisdisputed';
    tblDebtdebdisputereason.SetParentComponent(tblDebt);
    tblDebtdebdisputereason.Name := 'tblDebtdebdisputereason';
    tblDebtdebdisputereason.FieldName := 'debdisputereason';
    tblDebtdebdisputereason.Size := 127;
    tblDebtdebdisputecourt.SetParentComponent(tblDebt);
    tblDebtdebdisputecourt.Name := 'tblDebtdebdisputecourt';
    tblDebtdebdisputecourt.FieldName := 'debdisputecourt';
    tblDebtdebdisputecourt.Size := 127;
    tblDebtdebdisputedamount.SetParentComponent(tblDebt);
    tblDebtdebdisputedamount.Name := 'tblDebtdebdisputedamount';
    tblDebtdebdisputedamount.FieldName := 'debdisputedamount';
    tblDebtdebdisputedamount.Precision := 12;
    tblDebtdebdisputedamount.Size := 0;
    tblDebtdebisdelay.SetParentComponent(tblDebt);
    tblDebtdebisdelay.Name := 'tblDebtdebisdelay';
    tblDebtdebisdelay.FieldName := 'debisdelay';
    tblDebtdebdelayamount.SetParentComponent(tblDebt);
    tblDebtdebdelayamount.Name := 'tblDebtdebdelayamount';
    tblDebtdebdelayamount.FieldName := 'debdelayamount';
    tblDebtdebdelayamount.Precision := 12;
    tblDebtdebdelayamount.Size := 0;
    tblDebtdebguarantorfk.SetParentComponent(tblDebt);
    tblDebtdebguarantorfk.Name := 'tblDebtdebguarantorfk';
    tblDebtdebguarantorfk.FieldName := 'debguarantorfk';
    tblDebtdebiscompleted.SetParentComponent(tblDebt);
    tblDebtdebiscompleted.Name := 'tblDebtdebiscompleted';
    tblDebtdebiscompleted.FieldName := 'debiscompleted';
    tblDossier.SetParentComponent(Self);
    tblDossier.Name := 'tblDossier';
    tblDossier.TableName := 'dossier';
    tblDossier.DAConnection := PetitionConnection;
    tblDossier.Params.Clear;
    with tblDossier.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblDossier.DAOptions := [];
    tblDossier.Left := 336;
    tblDossier.Top := 152;
    tblDossierdosid.SetParentComponent(tblDossier);
    tblDossierdosid.Name := 'tblDossierdosid';
    tblDossierdosid.FieldName := 'dosid';
    tblDossierdoscreatedon.SetParentComponent(tblDossier);
    tblDossierdoscreatedon.Name := 'tblDossierdoscreatedon';
    tblDossierdoscreatedon.FieldName := 'doscreatedon';
    tblDossierdoscreatedbyfk.SetParentComponent(tblDossier);
    tblDossierdoscreatedbyfk.Name := 'tblDossierdoscreatedbyfk';
    tblDossierdoscreatedbyfk.FieldName := 'doscreatedbyfk';
    tblDossierdoschangedon.SetParentComponent(tblDossier);
    tblDossierdoschangedon.Name := 'tblDossierdoschangedon';
    tblDossierdoschangedon.FieldName := 'doschangedon';
    tblDossierdoschangedbyfk.SetParentComponent(tblDossier);
    tblDossierdoschangedbyfk.Name := 'tblDossierdoschangedbyfk';
    tblDossierdoschangedbyfk.FieldName := 'doschangedbyfk';
    tblDossierdosclosedon.SetParentComponent(tblDossier);
    tblDossierdosclosedon.Name := 'tblDossierdosclosedon';
    tblDossierdosclosedon.FieldName := 'dosclosedon';
    tblDossierdosdescription.SetParentComponent(tblDossier);
    tblDossierdosdescription.Name := 'tblDossierdosdescription';
    tblDossierdosdescription.FieldName := 'dosdescription';
    tblDossierdosdescription.Size := 127;
    tblDossierdoscompanyfk.SetParentComponent(tblDossier);
    tblDossierdoscompanyfk.Name := 'tblDossierdoscompanyfk';
    tblDossierdoscompanyfk.FieldName := 'doscompanyfk';
    tblDossierdosstatus.SetParentComponent(tblDossier);
    tblDossierdosstatus.Name := 'tblDossierdosstatus';
    tblDossierdosstatus.FieldName := 'dosstatus';
    tblDossierdosdatesent.SetParentComponent(tblDossier);
    tblDossierdosdatesent.Name := 'tblDossierdosdatesent';
    tblDossierdosdatesent.FieldName := 'dosdatesent';
    tblDossierdosclosedbyfk.SetParentComponent(tblDossier);
    tblDossierdosclosedbyfk.Name := 'tblDossierdosclosedbyfk';
    tblDossierdosclosedbyfk.FieldName := 'dosclosedbyfk';
    tblDossierdoscloseddescription.SetParentComponent(tblDossier);
    tblDossierdoscloseddescription.Name := 'tblDossierdoscloseddescription';
    tblDossierdoscloseddescription.FieldName := 'doscloseddescription';
    tblDossierdoscloseddescription.Size := 127;
    tblDossierdosisbankrupt.SetParentComponent(tblDossier);
    tblDossierdosisbankrupt.Name := 'tblDossierdosisbankrupt';
    tblDossierdosisbankrupt.FieldName := 'dosisbankrupt';
    tblDossierdosbankrupton.SetParentComponent(tblDossier);
    tblDossierdosbankrupton.Name := 'tblDossierdosbankrupton';
    tblDossierdosbankrupton.FieldName := 'dosbankrupton';
    tblDossierdosiskbocancel.SetParentComponent(tblDossier);
    tblDossierdosiskbocancel.Name := 'tblDossierdosiskbocancel';
    tblDossierdosiskbocancel.FieldName := 'dosiskbocancel';
    tblDossierdoskbocancelon.SetParentComponent(tblDossier);
    tblDossierdoskbocancelon.Name := 'tblDossierdoskbocancelon';
    tblDossierdoskbocancelon.FieldName := 'doskbocancelon';
    tblDossierdosloginfk.SetParentComponent(tblDossier);
    tblDossierdosloginfk.Name := 'tblDossierdosloginfk';
    tblDossierdosloginfk.FieldName := 'dosloginfk';
    tblDossierdosremarks.SetParentComponent(tblDossier);
    tblDossierdosremarks.Name := 'tblDossierdosremarks';
    tblDossierdosremarks.FieldName := 'dosremarks';
    tblDossierdosreopenedon.SetParentComponent(tblDossier);
    tblDossierdosreopenedon.Name := 'tblDossierdosreopenedon';
    tblDossierdosreopenedon.FieldName := 'dosreopenedon';
    tblDossierdosflags.SetParentComponent(tblDossier);
    tblDossierdosflags.Name := 'tblDossierdosflags';
    tblDossierdosflags.FieldName := 'dosflags';
    tblDossierdosmonthlyamount.SetParentComponent(tblDossier);
    tblDossierdosmonthlyamount.Name := 'tblDossierdosmonthlyamount';
    tblDossierdosmonthlyamount.FieldName := 'dosmonthlyamount';
    tblDossierdosmonthlyamount.Precision := 12;
    tblDossierdosmonthlyamount.Size := 0;
    tblDossierdosiscomplete.SetParentComponent(tblDossier);
    tblDossierdosiscomplete.Name := 'tblDossierdosiscomplete';
    tblDossierdosiscomplete.FieldName := 'dosiscomplete';
    tblDossierdoscasefilecounter.SetParentComponent(tblDossier);
    tblDossierdoscasefilecounter.Name := 'tblDossierdoscasefilecounter';
    tblDossierdoscasefilecounter.FieldName := 'doscasefilecounter';
    tblDossierFile.SetParentComponent(Self);
    tblDossierFile.Name := 'tblDossierFile';
    tblDossierFile.TableName := 'dossierfile';
    tblDossierFile.DAConnection := PetitionConnection;
    tblDossierFile.Params.Clear;
    with tblDossierFile.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'FILEID';
      ParamType := ptInput;
      Value := 0;
    end;
    tblDossierFile.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>dofid</field><parameter type="LargeInt">FILEID</parameter></binaryoperation></where></query>';
    tblDossierFile.DAOptions := [];
    tblDossierFile.Left := 736;
    tblDossierFile.Top := 152;
    tblDossierFiledofid.SetParentComponent(tblDossierFile);
    tblDossierFiledofid.Name := 'tblDossierFiledofid';
    tblDossierFiledofid.FieldName := 'dofid';
    tblDossierFiledofcreatedon.SetParentComponent(tblDossierFile);
    tblDossierFiledofcreatedon.Name := 'tblDossierFiledofcreatedon';
    tblDossierFiledofcreatedon.FieldName := 'dofcreatedon';
    tblDossierFiledofcreatedbyfk.SetParentComponent(tblDossierFile);
    tblDossierFiledofcreatedbyfk.Name := 'tblDossierFiledofcreatedbyfk';
    tblDossierFiledofcreatedbyfk.FieldName := 'dofcreatedbyfk';
    tblDossierFiledofchangedon.SetParentComponent(tblDossierFile);
    tblDossierFiledofchangedon.Name := 'tblDossierFiledofchangedon';
    tblDossierFiledofchangedon.FieldName := 'dofchangedon';
    tblDossierFiledofchangedbyfk.SetParentComponent(tblDossierFile);
    tblDossierFiledofchangedbyfk.Name := 'tblDossierFiledofchangedbyfk';
    tblDossierFiledofchangedbyfk.FieldName := 'dofchangedbyfk';
    tblDossierFiledofdossierfk.SetParentComponent(tblDossierFile);
    tblDossierFiledofdossierfk.Name := 'tblDossierFiledofdossierfk';
    tblDossierFiledofdossierfk.FieldName := 'dofdossierfk';
    tblDossierFiledofsourcefk.SetParentComponent(tblDossierFile);
    tblDossierFiledofsourcefk.Name := 'tblDossierFiledofsourcefk';
    tblDossierFiledofsourcefk.FieldName := 'dofsourcefk';
    tblDossierFiledofsourcetype.SetParentComponent(tblDossierFile);
    tblDossierFiledofsourcetype.Name := 'tblDossierFiledofsourcetype';
    tblDossierFiledofsourcetype.FieldName := 'dofsourcetype';
    tblDossierFiledofdoctypefk.SetParentComponent(tblDossierFile);
    tblDossierFiledofdoctypefk.Name := 'tblDossierFiledofdoctypefk';
    tblDossierFiledofdoctypefk.FieldName := 'dofdoctypefk';
    tblDossierFiledofdoctype.SetParentComponent(tblDossierFile);
    tblDossierFiledofdoctype.Name := 'tblDossierFiledofdoctype';
    tblDossierFiledofdoctype.FieldName := 'dofdoctype';
    tblDossierFiledofdoctype.Size := 10;
    tblDossierFiledoffilename.SetParentComponent(tblDossierFile);
    tblDossierFiledoffilename.Name := 'tblDossierFiledoffilename';
    tblDossierFiledoffilename.FieldName := 'doffilename';
    tblDossierFiledoffilename.Size := 255;
    tblDossierFiledofmime.SetParentComponent(tblDossierFile);
    tblDossierFiledofmime.Name := 'tblDossierFiledofmime';
    tblDossierFiledofmime.FieldName := 'dofmime';
    tblDossierFiledofmime.Size := 64;
    tblDossierFiledofdata.SetParentComponent(tblDossierFile);
    tblDossierFiledofdata.Name := 'tblDossierFiledofdata';
    tblDossierFiledofdata.FieldName := 'dofdata';
    tblDossierFiledofisuploadcomplete.SetParentComponent(tblDossierFile);
    tblDossierFiledofisuploadcomplete.Name := 'tblDossierFiledofisuploadcomplete';
    tblDossierFiledofisuploadcomplete.FieldName := 'dofisuploadcomplete';
    tblDossierFiledofuploaduserfk.SetParentComponent(tblDossierFile);
    tblDossierFiledofuploaduserfk.Name := 'tblDossierFiledofuploaduserfk';
    tblDossierFiledofuploaduserfk.FieldName := 'dofuploaduserfk';
    tblDossierFiledofcasefilenumber.SetParentComponent(tblDossierFile);
    tblDossierFiledofcasefilenumber.Name := 'tblDossierFiledofcasefilenumber';
    tblDossierFiledofcasefilenumber.FieldName := 'dofcasefilenumber';
    tblDossierPersons.SetParentComponent(Self);
    tblDossierPersons.Name := 'tblDossierPersons';
    tblDossierPersons.TableName := 'DossierPersons';
    tblDossierPersons.DAConnection := PetitionConnection;
    tblDossierPersons.DAOptions := [];
    tblDossierPersons.Left := 488;
    tblDossierPersons.Top := 152;
    tblDossierPersonscntid.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntid.Name := 'tblDossierPersonscntid';
    tblDossierPersonscntid.FieldName := 'cntid';
    tblDossierPersonscntcreatedon.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntcreatedon.Name := 'tblDossierPersonscntcreatedon';
    tblDossierPersonscntcreatedon.FieldName := 'cntcreatedon';
    tblDossierPersonscntcreatedbyfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntcreatedbyfk.Name := 'tblDossierPersonscntcreatedbyfk';
    tblDossierPersonscntcreatedbyfk.FieldName := 'cntcreatedbyfk';
    tblDossierPersonscntchangedon.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntchangedon.Name := 'tblDossierPersonscntchangedon';
    tblDossierPersonscntchangedon.FieldName := 'cntchangedon';
    tblDossierPersonscntchangedbyfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntchangedbyfk.Name := 'tblDossierPersonscntchangedbyfk';
    tblDossierPersonscntchangedbyfk.FieldName := 'cntchangedbyfk';
    tblDossierPersonscntcompanyfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntcompanyfk.Name := 'tblDossierPersonscntcompanyfk';
    tblDossierPersonscntcompanyfk.FieldName := 'cntcompanyfk';
    tblDossierPersonscntfirstname.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntfirstname.Name := 'tblDossierPersonscntfirstname';
    tblDossierPersonscntfirstname.FieldName := 'cntfirstname';
    tblDossierPersonscntfirstname.Size := 64;
    tblDossierPersonscntlastname.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntlastname.Name := 'tblDossierPersonscntlastname';
    tblDossierPersonscntlastname.FieldName := 'cntlastname';
    tblDossierPersonscntlastname.Size := 127;
    tblDossierPersonscntprofession.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntprofession.Name := 'tblDossierPersonscntprofession';
    tblDossierPersonscntprofession.FieldName := 'cntprofession';
    tblDossierPersonscntprofession.Size := 64;
    tblDossierPersonscntbirthdateon.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntbirthdateon.Name := 'tblDossierPersonscntbirthdateon';
    tblDossierPersonscntbirthdateon.FieldName := 'cntbirthdateon';
    tblDossierPersonscntisbirthdateunknown.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntisbirthdateunknown.Name := 'tblDossierPersonscntisbirthdateunknown';
    tblDossierPersonscntisbirthdateunknown.FieldName := 'cntisbirthdateunknown';
    tblDossierPersonscntgender.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntgender.Name := 'tblDossierPersonscntgender';
    tblDossierPersonscntgender.FieldName := 'cntgender';
    tblDossierPersonscntgender.Size := 1;
    tblDossierPersonscntcityofbirthfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntcityofbirthfk.Name := 'tblDossierPersonscntcityofbirthfk';
    tblDossierPersonscntcityofbirthfk.FieldName := 'cntcityofbirthfk';
    tblDossierPersonscntcityofbirthname.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntcityofbirthname.Name := 'tblDossierPersonscntcityofbirthname';
    tblDossierPersonscntcityofbirthname.FieldName := 'cntcityofbirthname';
    tblDossierPersonscntcityofbirthname.Size := 100;
    tblDossierPersonscntcityofbirthzip.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntcityofbirthzip.Name := 'tblDossierPersonscntcityofbirthzip';
    tblDossierPersonscntcityofbirthzip.FieldName := 'cntcityofbirthzip';
    tblDossierPersonscntcityofbirthzip.Size := 15;
    tblDossierPersonscntnationalityfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntnationalityfk.Name := 'tblDossierPersonscntnationalityfk';
    tblDossierPersonscntnationalityfk.FieldName := 'cntnationalityfk';
    tblDossierPersonscntnationality2.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntnationality2.Name := 'tblDossierPersonscntnationality2';
    tblDossierPersonscntnationality2.FieldName := 'cntnationality2';
    tblDossierPersonscntnationality2.Size := 2;
    tblDossierPersonscntnationality.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntnationality.Name := 'tblDossierPersonscntnationality';
    tblDossierPersonscntnationality.FieldName := 'cntnationality';
    tblDossierPersonscntnationality.Size := 40;
    tblDossierPersonscntpicturefk.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntpicturefk.Name := 'tblDossierPersonscntpicturefk';
    tblDossierPersonscntpicturefk.FieldName := 'cntpicturefk';
    tblDossierPersonscntkbonr.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntkbonr.Name := 'tblDossierPersonscntkbonr';
    tblDossierPersonscntkbonr.FieldName := 'cntkbonr';
    tblDossierPersonscntkbonr.Size := 10;
    tblDossierPersonscntremark.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntremark.Name := 'tblDossierPersonscntremark';
    tblDossierPersonscntremark.FieldName := 'cntremark';
    tblDossierPersonscntremark.Size := 255;
    tblDossierPersonscntpersonfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntpersonfk.Name := 'tblDossierPersonscntpersonfk';
    tblDossierPersonscntpersonfk.FieldName := 'cntpersonfk';
    tblDossierPersonscntsalutation.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntsalutation.Name := 'tblDossierPersonscntsalutation';
    tblDossierPersonscntsalutation.FieldName := 'cntsalutation';
    tblDossierPersonscntsalutation.Size := 32;
    tblDossierPersonscntsearchname.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntsearchname.Name := 'tblDossierPersonscntsearchname';
    tblDossierPersonscntsearchname.FieldName := 'cntsearchname';
    tblDossierPersonscntsearchname.Size := 50;
    tblDossierPersonscntprefixes.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntprefixes.Name := 'tblDossierPersonscntprefixes';
    tblDossierPersonscntprefixes.FieldName := 'cntprefixes';
    tblDossierPersonscntprefixes.Size := 32;
    tblDossierPersonscntfriendlytitle.SetParentComponent(tblDossierPersons);
    tblDossierPersonscntfriendlytitle.Name := 'tblDossierPersonscntfriendlytitle';
    tblDossierPersonscntfriendlytitle.FieldName := 'cntfriendlytitle';
    tblDossierPersonscntfriendlytitle.Size := 32;
    tblDossierPersonsctaid.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctaid.Name := 'tblDossierPersonsctaid';
    tblDossierPersonsctaid.FieldName := 'ctaid';
    tblDossierPersonsctacreatedon.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctacreatedon.Name := 'tblDossierPersonsctacreatedon';
    tblDossierPersonsctacreatedon.FieldName := 'ctacreatedon';
    tblDossierPersonsctacreatedbyfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctacreatedbyfk.Name := 'tblDossierPersonsctacreatedbyfk';
    tblDossierPersonsctacreatedbyfk.FieldName := 'ctacreatedbyfk';
    tblDossierPersonsctachangedon.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctachangedon.Name := 'tblDossierPersonsctachangedon';
    tblDossierPersonsctachangedon.FieldName := 'ctachangedon';
    tblDossierPersonsctachangedbyfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctachangedbyfk.Name := 'tblDossierPersonsctachangedbyfk';
    tblDossierPersonsctachangedbyfk.FieldName := 'ctachangedbyfk';
    tblDossierPersonsctacontactfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctacontactfk.Name := 'tblDossierPersonsctacontactfk';
    tblDossierPersonsctacontactfk.FieldName := 'ctacontactfk';
    tblDossierPersonsctaaddresstypefk.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctaaddresstypefk.Name := 'tblDossierPersonsctaaddresstypefk';
    tblDossierPersonsctaaddresstypefk.FieldName := 'ctaaddresstypefk';
    tblDossierPersonsctaaddresstype.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctaaddresstype.Name := 'tblDossierPersonsctaaddresstype';
    tblDossierPersonsctaaddresstype.FieldName := 'ctaaddresstype';
    tblDossierPersonsctaaddresstype.Size := 10;
    tblDossierPersonsctastreet.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctastreet.Name := 'tblDossierPersonsctastreet';
    tblDossierPersonsctastreet.FieldName := 'ctastreet';
    tblDossierPersonsctastreet.Size := 50;
    tblDossierPersonsctastreetnr.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctastreetnr.Name := 'tblDossierPersonsctastreetnr';
    tblDossierPersonsctastreetnr.FieldName := 'ctastreetnr';
    tblDossierPersonsctastreetnr.Size := 50;
    tblDossierPersonsctacityzip.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctacityzip.Name := 'tblDossierPersonsctacityzip';
    tblDossierPersonsctacityzip.FieldName := 'ctacityzip';
    tblDossierPersonsctacityzip.Size := 15;
    tblDossierPersonsctacityname.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctacityname.Name := 'tblDossierPersonsctacityname';
    tblDossierPersonsctacityname.FieldName := 'ctacityname';
    tblDossierPersonsctacityname.Size := 100;
    tblDossierPersonsctacityfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctacityfk.Name := 'tblDossierPersonsctacityfk';
    tblDossierPersonsctacityfk.FieldName := 'ctacityfk';
    tblDossierPersonsctaremark.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctaremark.Name := 'tblDossierPersonsctaremark';
    tblDossierPersonsctaremark.FieldName := 'ctaremark';
    tblDossierPersonsctaremark.Size := 255;
    tblDossierPersonsctacorrespondence.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctacorrespondence.Name := 'tblDossierPersonsctacorrespondence';
    tblDossierPersonsctacorrespondence.FieldName := 'ctacorrespondence';
    tblDossierPersonsctasecret.SetParentComponent(tblDossierPersons);
    tblDossierPersonsctasecret.Name := 'tblDossierPersonsctasecret';
    tblDossierPersonsctasecret.FieldName := 'ctasecret';
    tblDossierPersonsdocid.SetParentComponent(tblDossierPersons);
    tblDossierPersonsdocid.Name := 'tblDossierPersonsdocid';
    tblDossierPersonsdocid.FieldName := 'docid';
    tblDossierPersonsdoccivilstatus.SetParentComponent(tblDossierPersons);
    tblDossierPersonsdoccivilstatus.Name := 'tblDossierPersonsdoccivilstatus';
    tblDossierPersonsdoccivilstatus.FieldName := 'doccivilstatus';
    tblDossierPersonsdoccivilstatus.Size := 10;
    tblDossierPersonsdoccivilstatusfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonsdoccivilstatusfk.Name := 'tblDossierPersonsdoccivilstatusfk';
    tblDossierPersonsdoccivilstatusfk.FieldName := 'doccivilstatusfk';
    tblDossierPersonsdocissecondpetitioner.SetParentComponent(tblDossierPersons);
    tblDossierPersonsdocissecondpetitioner.Name := 'tblDossierPersonsdocissecondpetitioner';
    tblDossierPersonsdocissecondpetitioner.FieldName := 'docissecondpetitioner';
    tblDossierPersonsdocmarcon.SetParentComponent(tblDossierPersons);
    tblDossierPersonsdocmarcon.Name := 'tblDossierPersonsdocmarcon';
    tblDossierPersonsdocmarcon.FieldName := 'docmarcon';
    tblDossierPersonsdocmarcon.Size := 10;
    tblDossierPersonsdocmarconfk.SetParentComponent(tblDossierPersons);
    tblDossierPersonsdocmarconfk.Name := 'tblDossierPersonsdocmarconfk';
    tblDossierPersonsdocmarconfk.FieldName := 'docmarconfk';
    tblDossierPersonsdocpersontype.SetParentComponent(tblDossierPersons);
    tblDossierPersonsdocpersontype.Name := 'tblDossierPersonsdocpersontype';
    tblDossierPersonsdocpersontype.FieldName := 'docpersontype';
    tblDossierPersonsdocpersontype.Size := 10;
    tblDossierPersonsdocpersontypefk.SetParentComponent(tblDossierPersons);
    tblDossierPersonsdocpersontypefk.Name := 'tblDossierPersonsdocpersontypefk';
    tblDossierPersonsdocpersontypefk.FieldName := 'docpersontypefk';
    tblExpense.SetParentComponent(Self);
    tblExpense.Name := 'tblExpense';
    tblExpense.TableName := 'expense';
    tblExpense.DAConnection := PetitionConnection;
    tblExpense.Params.Clear;
    with tblExpense.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblExpense.DAOptions := [];
    tblExpense.Left := 176;
    tblExpense.Top := 200;
    tblExpenseexpid.SetParentComponent(tblExpense);
    tblExpenseexpid.Name := 'tblExpenseexpid';
    tblExpenseexpid.FieldName := 'expid';
    tblExpenseexpcreatedon.SetParentComponent(tblExpense);
    tblExpenseexpcreatedon.Name := 'tblExpenseexpcreatedon';
    tblExpenseexpcreatedon.FieldName := 'expcreatedon';
    tblExpenseexpcreatedbyfk.SetParentComponent(tblExpense);
    tblExpenseexpcreatedbyfk.Name := 'tblExpenseexpcreatedbyfk';
    tblExpenseexpcreatedbyfk.FieldName := 'expcreatedbyfk';
    tblExpenseexpchangedon.SetParentComponent(tblExpense);
    tblExpenseexpchangedon.Name := 'tblExpenseexpchangedon';
    tblExpenseexpchangedon.FieldName := 'expchangedon';
    tblExpenseexpchangedbyfk.SetParentComponent(tblExpense);
    tblExpenseexpchangedbyfk.Name := 'tblExpenseexpchangedbyfk';
    tblExpenseexpchangedbyfk.FieldName := 'expchangedbyfk';
    tblExpenseexpdossierfk.SetParentComponent(tblExpense);
    tblExpenseexpdossierfk.Name := 'tblExpenseexpdossierfk';
    tblExpenseexpdossierfk.FieldName := 'expdossierfk';
    tblExpenseexpcategoryfk.SetParentComponent(tblExpense);
    tblExpenseexpcategoryfk.Name := 'tblExpenseexpcategoryfk';
    tblExpenseexpcategoryfk.FieldName := 'expcategoryfk';
    tblExpenseexpcategory.SetParentComponent(tblExpense);
    tblExpenseexpcategory.Name := 'tblExpenseexpcategory';
    tblExpenseexpcategory.FieldName := 'expcategory';
    tblExpenseexpcategory.Size := 10;
    tblExpenseexpkindfk.SetParentComponent(tblExpense);
    tblExpenseexpkindfk.Name := 'tblExpenseexpkindfk';
    tblExpenseexpkindfk.FieldName := 'expkindfk';
    tblExpenseexpkind.SetParentComponent(tblExpense);
    tblExpenseexpkind.Name := 'tblExpenseexpkind';
    tblExpenseexpkind.FieldName := 'expkind';
    tblExpenseexpkind.Size := 10;
    tblExpenseexppaymenton.SetParentComponent(tblExpense);
    tblExpenseexppaymenton.Name := 'tblExpenseexppaymenton';
    tblExpenseexppaymenton.FieldName := 'exppaymenton';
    tblExpenseexppaymentperiodfk.SetParentComponent(tblExpense);
    tblExpenseexppaymentperiodfk.Name := 'tblExpenseexppaymentperiodfk';
    tblExpenseexppaymentperiodfk.FieldName := 'exppaymentperiodfk';
    tblExpenseexppaymentperiod.SetParentComponent(tblExpense);
    tblExpenseexppaymentperiod.Name := 'tblExpenseexppaymentperiod';
    tblExpenseexppaymentperiod.FieldName := 'exppaymentperiod';
    tblExpenseexppaymentperiod.Size := 10;
    tblExpenseexpamount.SetParentComponent(tblExpense);
    tblExpenseexpamount.Name := 'tblExpenseexpamount';
    tblExpenseexpamount.FieldName := 'expamount';
    tblExpenseexpamount.Precision := 12;
    tblExpenseexpamount.Size := 0;
    tblExpenseexpremark.SetParentComponent(tblExpense);
    tblExpenseexpremark.Name := 'tblExpenseexpremark';
    tblExpenseexpremark.FieldName := 'expremark';
    tblExpenseexpremark.Size := 255;
    tblLegalCase.SetParentComponent(Self);
    tblLegalCase.Name := 'tblLegalCase';
    tblLegalCase.TableName := 'legalcase';
    tblLegalCase.DAConnection := PetitionConnection;
    tblLegalCase.Params.Clear;
    with tblLegalCase.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblLegalCase.DAOptions := [];
    tblLegalCase.Left := 264;
    tblLegalCase.Top := 200;
    tblLegalCaselecid.SetParentComponent(tblLegalCase);
    tblLegalCaselecid.Name := 'tblLegalCaselecid';
    tblLegalCaselecid.FieldName := 'lecid';
    tblLegalCaseleccreatedon.SetParentComponent(tblLegalCase);
    tblLegalCaseleccreatedon.Name := 'tblLegalCaseleccreatedon';
    tblLegalCaseleccreatedon.FieldName := 'leccreatedon';
    tblLegalCaseleccreatedbyfk.SetParentComponent(tblLegalCase);
    tblLegalCaseleccreatedbyfk.Name := 'tblLegalCaseleccreatedbyfk';
    tblLegalCaseleccreatedbyfk.FieldName := 'leccreatedbyfk';
    tblLegalCaselecchangedon.SetParentComponent(tblLegalCase);
    tblLegalCaselecchangedon.Name := 'tblLegalCaselecchangedon';
    tblLegalCaselecchangedon.FieldName := 'lecchangedon';
    tblLegalCaselecchangedbyfk.SetParentComponent(tblLegalCase);
    tblLegalCaselecchangedbyfk.Name := 'tblLegalCaselecchangedbyfk';
    tblLegalCaselecchangedbyfk.FieldName := 'lecchangedbyfk';
    tblLegalCaselecdossierfk.SetParentComponent(tblLegalCase);
    tblLegalCaselecdossierfk.Name := 'tblLegalCaselecdossierfk';
    tblLegalCaselecdossierfk.FieldName := 'lecdossierfk';
    tblLegalCaseleccause.SetParentComponent(tblLegalCase);
    tblLegalCaseleccause.Name := 'tblLegalCaseleccause';
    tblLegalCaseleccause.FieldName := 'leccause';
    tblLegalCaseleccause.Size := 255;
    tblLegalCaselecprimaryfk.SetParentComponent(tblLegalCase);
    tblLegalCaselecprimaryfk.Name := 'tblLegalCaselecprimaryfk';
    tblLegalCaselecprimaryfk.FieldName := 'lecprimaryfk';
    tblLegalCaselecopponentfk.SetParentComponent(tblLegalCase);
    tblLegalCaselecopponentfk.Name := 'tblLegalCaselecopponentfk';
    tblLegalCaselecopponentfk.FieldName := 'lecopponentfk';
    tblLegalCaseleclawyerfk.SetParentComponent(tblLegalCase);
    tblLegalCaseleclawyerfk.Name := 'tblLegalCaseleclawyerfk';
    tblLegalCaseleclawyerfk.FieldName := 'leclawyerfk';
    tblLegalCaseleccourt.SetParentComponent(tblLegalCase);
    tblLegalCaseleccourt.Name := 'tblLegalCaseleccourt';
    tblLegalCaseleccourt.FieldName := 'leccourt';
    tblLegalCaseleccourt.Size := 127;
    tblLegalCaselecreference.SetParentComponent(tblLegalCase);
    tblLegalCaselecreference.Name := 'tblLegalCaselecreference';
    tblLegalCaselecreference.FieldName := 'lecreference';
    tblLegalCaselecreference.Size := 127;
    tblLegalCaselecremark.SetParentComponent(tblLegalCase);
    tblLegalCaselecremark.Name := 'tblLegalCaselecremark';
    tblLegalCaselecremark.FieldName := 'lecremark';
    tblLegalCaselecremark.Size := 255;
    tblLegalCaselecamount.SetParentComponent(tblLegalCase);
    tblLegalCaselecamount.Name := 'tblLegalCaselecamount';
    tblLegalCaselecamount.FieldName := 'lecamount';
    tblLegalCaselecamount.Precision := 12;
    tblLegalCaselecamount.Size := 0;
    tblLegalCaseleciscomplete.SetParentComponent(tblLegalCase);
    tblLegalCaseleciscomplete.Name := 'tblLegalCaseleciscomplete';
    tblLegalCaseleciscomplete.FieldName := 'leciscomplete';
    tblPaymentPlan.SetParentComponent(Self);
    tblPaymentPlan.Name := 'tblPaymentPlan';
    tblPaymentPlan.TableName := 'paymentplan';
    tblPaymentPlan.DAConnection := PetitionConnection;
    tblPaymentPlan.Params.Clear;
    with tblPaymentPlan.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblPaymentPlan.DAOptions := [];
    tblPaymentPlan.Left := 328;
    tblPaymentPlan.Top := 200;
    tblPaymentPlanpplid.SetParentComponent(tblPaymentPlan);
    tblPaymentPlanpplid.Name := 'tblPaymentPlanpplid';
    tblPaymentPlanpplid.FieldName := 'pplid';
    tblPaymentPlanpplcreatedon.SetParentComponent(tblPaymentPlan);
    tblPaymentPlanpplcreatedon.Name := 'tblPaymentPlanpplcreatedon';
    tblPaymentPlanpplcreatedon.FieldName := 'pplcreatedon';
    tblPaymentPlanpplcreatedbyfk.SetParentComponent(tblPaymentPlan);
    tblPaymentPlanpplcreatedbyfk.Name := 'tblPaymentPlanpplcreatedbyfk';
    tblPaymentPlanpplcreatedbyfk.FieldName := 'pplcreatedbyfk';
    tblPaymentPlanpplchangedon.SetParentComponent(tblPaymentPlan);
    tblPaymentPlanpplchangedon.Name := 'tblPaymentPlanpplchangedon';
    tblPaymentPlanpplchangedon.FieldName := 'pplchangedon';
    tblPaymentPlanpplchangedbyfk.SetParentComponent(tblPaymentPlan);
    tblPaymentPlanpplchangedbyfk.Name := 'tblPaymentPlanpplchangedbyfk';
    tblPaymentPlanpplchangedbyfk.FieldName := 'pplchangedbyfk';
    tblPaymentPlanppldossierfk.SetParentComponent(tblPaymentPlan);
    tblPaymentPlanppldossierfk.Name := 'tblPaymentPlanppldossierfk';
    tblPaymentPlanppldossierfk.FieldName := 'ppldossierfk';
    tblPaymentPlanDetail.SetParentComponent(Self);
    tblPaymentPlanDetail.Name := 'tblPaymentPlanDetail';
    tblPaymentPlanDetail.TableName := 'paymentplandetail';
    tblPaymentPlanDetail.DAConnection := PetitionConnection;
    tblPaymentPlanDetail.Params.Clear;
    with tblPaymentPlanDetail.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblPaymentPlanDetail.DAOptions := [];
    tblPaymentPlanDetail.Left := 400;
    tblPaymentPlanDetail.Top := 199;
    tblPaymentPlanDetailppdid.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdid.Name := 'tblPaymentPlanDetailppdid';
    tblPaymentPlanDetailppdid.FieldName := 'ppdid';
    tblPaymentPlanDetailppdcreatedon.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdcreatedon.Name := 'tblPaymentPlanDetailppdcreatedon';
    tblPaymentPlanDetailppdcreatedon.FieldName := 'ppdcreatedon';
    tblPaymentPlanDetailppdcreatedbyfk.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdcreatedbyfk.Name := 'tblPaymentPlanDetailppdcreatedbyfk';
    tblPaymentPlanDetailppdcreatedbyfk.FieldName := 'ppdcreatedbyfk';
    tblPaymentPlanDetailppdchangedon.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdchangedon.Name := 'tblPaymentPlanDetailppdchangedon';
    tblPaymentPlanDetailppdchangedon.FieldName := 'ppdchangedon';
    tblPaymentPlanDetailppdchangedbyfk.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdchangedbyfk.Name := 'tblPaymentPlanDetailppdchangedbyfk';
    tblPaymentPlanDetailppdchangedbyfk.FieldName := 'ppdchangedbyfk';
    tblPaymentPlanDetailppdpaymentplanfk.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdpaymentplanfk.Name := 'tblPaymentPlanDetailppdpaymentplanfk';
    tblPaymentPlanDetailppdpaymentplanfk.FieldName := 'ppdpaymentplanfk';
    tblPaymentPlanDetailppdamount.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdamount.Name := 'tblPaymentPlanDetailppdamount';
    tblPaymentPlanDetailppdamount.FieldName := 'ppdamount';
    tblPaymentPlanDetailppdamount.Precision := 12;
    tblPaymentPlanDetailppdamount.Size := 0;
    tblPaymentPlanDetailppdtotalamount.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdtotalamount.Name := 'tblPaymentPlanDetailppdtotalamount';
    tblPaymentPlanDetailppdtotalamount.FieldName := 'ppdtotalamount';
    tblPaymentPlanDetailppdtotalamount.Precision := 12;
    tblPaymentPlanDetailppdtotalamount.Size := 0;
    tblPaymentPlanDetailppdpaymenttofk.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdpaymenttofk.Name := 'tblPaymentPlanDetailppdpaymenttofk';
    tblPaymentPlanDetailppdpaymenttofk.FieldName := 'ppdpaymenttofk';
    tblPaymentPlanDetailppdiban.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdiban.Name := 'tblPaymentPlanDetailppdiban';
    tblPaymentPlanDetailppdiban.FieldName := 'ppdiban';
    tblPaymentPlanDetailppdiban.Size := 34;
    tblPaymentPlanDetailppdname.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdname.Name := 'tblPaymentPlanDetailppdname';
    tblPaymentPlanDetailppdname.FieldName := 'ppdname';
    tblPaymentPlanDetailppdname.Size := 34;
    tblPaymentPlanDetailppdperiodicityfk.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdperiodicityfk.Name := 'tblPaymentPlanDetailppdperiodicityfk';
    tblPaymentPlanDetailppdperiodicityfk.FieldName := 'ppdperiodicityfk';
    tblPaymentPlanDetailppdperiodicity.SetParentComponent(tblPaymentPlanDetail);
    tblPaymentPlanDetailppdperiodicity.Name := 'tblPaymentPlanDetailppdperiodicity';
    tblPaymentPlanDetailppdperiodicity.FieldName := 'ppdperiodicity';
    tblPaymentPlanDetailppdperiodicity.Size := 10;
    tblProperty.SetParentComponent(Self);
    tblProperty.Name := 'tblProperty';
    tblProperty.TableName := 'property';
    tblProperty.DAConnection := PetitionConnection;
    tblProperty.Params.Clear;
    with tblProperty.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblProperty.DAOptions := [];
    tblProperty.Left := 488;
    tblProperty.Top := 200;
    tblPropertyproid.SetParentComponent(tblProperty);
    tblPropertyproid.Name := 'tblPropertyproid';
    tblPropertyproid.FieldName := 'proid';
    tblPropertyprocreatedon.SetParentComponent(tblProperty);
    tblPropertyprocreatedon.Name := 'tblPropertyprocreatedon';
    tblPropertyprocreatedon.FieldName := 'procreatedon';
    tblPropertyprocreatedbyfk.SetParentComponent(tblProperty);
    tblPropertyprocreatedbyfk.Name := 'tblPropertyprocreatedbyfk';
    tblPropertyprocreatedbyfk.FieldName := 'procreatedbyfk';
    tblPropertyprochangedon.SetParentComponent(tblProperty);
    tblPropertyprochangedon.Name := 'tblPropertyprochangedon';
    tblPropertyprochangedon.FieldName := 'prochangedon';
    tblPropertyprochangedbyfk.SetParentComponent(tblProperty);
    tblPropertyprochangedbyfk.Name := 'tblPropertyprochangedbyfk';
    tblPropertyprochangedbyfk.FieldName := 'prochangedbyfk';
    tblPropertyprodossierfk.SetParentComponent(tblProperty);
    tblPropertyprodossierfk.Name := 'tblPropertyprodossierfk';
    tblPropertyprodossierfk.FieldName := 'prodossierfk';
    tblPropertypropropertytype.SetParentComponent(tblProperty);
    tblPropertypropropertytype.Name := 'tblPropertypropropertytype';
    tblPropertypropropertytype.FieldName := 'propropertytype';
    tblPropertypropropertytype.Size := 10;
    tblPropertypropropertytypefk.SetParentComponent(tblProperty);
    tblPropertypropropertytypefk.Name := 'tblPropertypropropertytypefk';
    tblPropertypropropertytypefk.FieldName := 'propropertytypefk';
    tblPropertyproowner.SetParentComponent(tblProperty);
    tblPropertyproowner.Name := 'tblPropertyproowner';
    tblPropertyproowner.FieldName := 'proowner';
    tblPropertyproowner.Size := 10;
    tblPropertyproownerfk.SetParentComponent(tblProperty);
    tblPropertyproownerfk.Name := 'tblPropertyproownerfk';
    tblPropertyproownerfk.FieldName := 'proownerfk';
    tblPropertyproisrealestate.SetParentComponent(tblProperty);
    tblPropertyproisrealestate.Name := 'tblPropertyproisrealestate';
    tblPropertyproisrealestate.FieldName := 'proisrealestate';
    tblPropertyproshortdescription.SetParentComponent(tblProperty);
    tblPropertyproshortdescription.Name := 'tblPropertyproshortdescription';
    tblPropertyproshortdescription.FieldName := 'proshortdescription';
    tblPropertyproshortdescription.Size := 127;
    tblPropertyprodescription.SetParentComponent(tblProperty);
    tblPropertyprodescription.Name := 'tblPropertyprodescription';
    tblPropertyprodescription.FieldName := 'prodescription';
    tblPropertyprocount.SetParentComponent(tblProperty);
    tblPropertyprocount.Name := 'tblPropertyprocount';
    tblPropertyprocount.FieldName := 'procount';
    tblPropertypropurchasedon.SetParentComponent(tblProperty);
    tblPropertypropurchasedon.Name := 'tblPropertypropurchasedon';
    tblPropertypropurchasedon.FieldName := 'propurchasedon';
    tblPropertypronotaryname.SetParentComponent(tblProperty);
    tblPropertypronotaryname.Name := 'tblPropertypronotaryname';
    tblPropertypronotaryname.FieldName := 'pronotaryname';
    tblPropertypronotaryname.Size := 100;
    tblPropertypropurchaseprice.SetParentComponent(tblProperty);
    tblPropertypropurchaseprice.Name := 'tblPropertypropurchaseprice';
    tblPropertypropurchaseprice.FieldName := 'propurchaseprice';
    tblPropertypropurchaseprice.Precision := 12;
    tblPropertypropurchaseprice.Size := 0;
    tblPropertyprocurrentvalue.SetParentComponent(tblProperty);
    tblPropertyprocurrentvalue.Name := 'tblPropertyprocurrentvalue';
    tblPropertyprocurrentvalue.FieldName := 'procurrentvalue';
    tblPropertyprocurrentvalue.Precision := 12;
    tblPropertyprocurrentvalue.Size := 0;
    tblPropertyproisfinanced.SetParentComponent(tblProperty);
    tblPropertyproisfinanced.Name := 'tblPropertyproisfinanced';
    tblPropertyproisfinanced.FieldName := 'proisfinanced';
    tblPropertyproisseized.SetParentComponent(tblProperty);
    tblPropertyproisseized.Name := 'tblPropertyproisseized';
    tblPropertyproisseized.FieldName := 'proisseized';
    tblPropertyproisshared.SetParentComponent(tblProperty);
    tblPropertyproisshared.Name := 'tblPropertyproisshared';
    tblPropertyproisshared.FieldName := 'proisshared';
    tblPropertyproisgone.SetParentComponent(tblProperty);
    tblPropertyproisgone.Name := 'tblPropertyproisgone';
    tblPropertyproisgone.FieldName := 'proisgone';
    tblPropertyprogoneon.SetParentComponent(tblProperty);
    tblPropertyprogoneon.Name := 'tblPropertyprogoneon';
    tblPropertyprogoneon.FieldName := 'progoneon';
    tblPropertyprobuyerfk.SetParentComponent(tblProperty);
    tblPropertyprobuyerfk.Name := 'tblPropertyprobuyerfk';
    tblPropertyprobuyerfk.FieldName := 'probuyerfk';
    tblPropertyproiscomplete.SetParentComponent(tblProperty);
    tblPropertyproiscomplete.Name := 'tblPropertyproiscomplete';
    tblPropertyproiscomplete.FieldName := 'proiscomplete';
    tblPropertyproremark.SetParentComponent(tblProperty);
    tblPropertyproremark.Name := 'tblPropertyproremark';
    tblPropertyproremark.FieldName := 'proremark';
    tblPropertyproremark.Size := 255;
    tblRevenue.SetParentComponent(Self);
    tblRevenue.Name := 'tblRevenue';
    tblRevenue.TableName := 'revenue';
    tblRevenue.DAConnection := PetitionConnection;
    tblRevenue.DAOptions := [];
    tblRevenue.Left := 576;
    tblRevenue.Top := 200;
    tblRevenuerevid.SetParentComponent(tblRevenue);
    tblRevenuerevid.Name := 'tblRevenuerevid';
    tblRevenuerevid.FieldName := 'revid';
    tblRevenuerevcreatedon.SetParentComponent(tblRevenue);
    tblRevenuerevcreatedon.Name := 'tblRevenuerevcreatedon';
    tblRevenuerevcreatedon.FieldName := 'revcreatedon';
    tblRevenuerevcreatedbyfk.SetParentComponent(tblRevenue);
    tblRevenuerevcreatedbyfk.Name := 'tblRevenuerevcreatedbyfk';
    tblRevenuerevcreatedbyfk.FieldName := 'revcreatedbyfk';
    tblRevenuerevchangedon.SetParentComponent(tblRevenue);
    tblRevenuerevchangedon.Name := 'tblRevenuerevchangedon';
    tblRevenuerevchangedon.FieldName := 'revchangedon';
    tblRevenuerevchangedbyfk.SetParentComponent(tblRevenue);
    tblRevenuerevchangedbyfk.Name := 'tblRevenuerevchangedbyfk';
    tblRevenuerevchangedbyfk.FieldName := 'revchangedbyfk';
    tblRevenuerevdossierfk.SetParentComponent(tblRevenue);
    tblRevenuerevdossierfk.Name := 'tblRevenuerevdossierfk';
    tblRevenuerevdossierfk.FieldName := 'revdossierfk';
    tblRevenuerevrevenuetypefk.SetParentComponent(tblRevenue);
    tblRevenuerevrevenuetypefk.Name := 'tblRevenuerevrevenuetypefk';
    tblRevenuerevrevenuetypefk.FieldName := 'revrevenuetypefk';
    tblRevenuerevrevenuetype.SetParentComponent(tblRevenue);
    tblRevenuerevrevenuetype.Name := 'tblRevenuerevrevenuetype';
    tblRevenuerevrevenuetype.FieldName := 'revrevenuetype';
    tblRevenuerevrevenuetype.Size := 5;
    tblRevenuerevamount.SetParentComponent(tblRevenue);
    tblRevenuerevamount.Name := 'tblRevenuerevamount';
    tblRevenuerevamount.FieldName := 'revamount';
    tblRevenuerevamount.Size := 0;
    tblRevenuerevperiodtypefk.SetParentComponent(tblRevenue);
    tblRevenuerevperiodtypefk.Name := 'tblRevenuerevperiodtypefk';
    tblRevenuerevperiodtypefk.FieldName := 'revperiodtypefk';
    tblRevenuerevperiodtype.SetParentComponent(tblRevenue);
    tblRevenuerevperiodtype.Name := 'tblRevenuerevperiodtype';
    tblRevenuerevperiodtype.FieldName := 'revperiodtype';
    tblRevenuerevperiodtype.Size := 2;
    tblRevenuerevviatypefk.SetParentComponent(tblRevenue);
    tblRevenuerevviatypefk.Name := 'tblRevenuerevviatypefk';
    tblRevenuerevviatypefk.FieldName := 'revviatypefk';
    tblRevenuerevviatype.SetParentComponent(tblRevenue);
    tblRevenuerevviatype.Name := 'tblRevenuerevviatype';
    tblRevenuerevviatype.FieldName := 'revviatype';
    tblRevenuerevviatype.Size := 10;
    tblRevenuerevfromfk.SetParentComponent(tblRevenue);
    tblRevenuerevfromfk.Name := 'tblRevenuerevfromfk';
    tblRevenuerevfromfk.FieldName := 'revfromfk';
    tblRevenuerevremark.SetParentComponent(tblRevenue);
    tblRevenuerevremark.Name := 'tblRevenuerevremark';
    tblRevenuerevremark.FieldName := 'revremark';
    tblRevenuerevremark.Size := 255;
    tblRevenuereviscomplete.SetParentComponent(tblRevenue);
    tblRevenuereviscomplete.Name := 'tblRevenuereviscomplete';
    tblRevenuereviscomplete.FieldName := 'reviscomplete';
    tblRevenuerevorigin.SetParentComponent(tblRevenue);
    tblRevenuerevorigin.Name := 'tblRevenuerevorigin';
    tblRevenuerevorigin.FieldName := 'revorigin';
    tblRevenuerevorigin.BlobType := ftWideMemo;
    tblAccountInfo.SetParentComponent(Self);
    tblAccountInfo.Name := 'tblAccountInfo';
    tblAccountInfo.TableName := 'AccountInfo';
    tblAccountInfo.DAConnection := CompanyConnection;
    tblAccountInfo.DAOptions := [];
    tblAccountInfo.Left := 176;
    tblAccountInfo.Top := 448;
    tblCompany.SetParentComponent(Self);
    tblCompany.Name := 'tblCompany';
    tblCompany.TableName := 'company';
    tblCompany.DAConnection := CompanyConnection;
    tblCompany.DAOptions := [];
    tblCompany.Left := 256;
    tblCompany.Top := 448;
    tblCompanycomid.SetParentComponent(tblCompany);
    tblCompanycomid.Name := 'tblCompanycomid';
    tblCompanycomid.FieldName := 'comid';
    tblCompanycomcreatedon.SetParentComponent(tblCompany);
    tblCompanycomcreatedon.Name := 'tblCompanycomcreatedon';
    tblCompanycomcreatedon.FieldName := 'comcreatedon';
    tblCompanycomcreatedbyfk.SetParentComponent(tblCompany);
    tblCompanycomcreatedbyfk.Name := 'tblCompanycomcreatedbyfk';
    tblCompanycomcreatedbyfk.FieldName := 'comcreatedbyfk';
    tblCompanycomchangedon.SetParentComponent(tblCompany);
    tblCompanycomchangedon.Name := 'tblCompanycomchangedon';
    tblCompanycomchangedon.FieldName := 'comchangedon';
    tblCompanycomchangedbyfk.SetParentComponent(tblCompany);
    tblCompanycomchangedbyfk.Name := 'tblCompanycomchangedbyfk';
    tblCompanycomchangedbyfk.FieldName := 'comchangedbyfk';
    tblCompanycomname.SetParentComponent(tblCompany);
    tblCompanycomname.Name := 'tblCompanycomname';
    tblCompanycomname.FieldName := 'comname';
    tblCompanycomname.Size := 100;
    tblCompanycomstreet.SetParentComponent(tblCompany);
    tblCompanycomstreet.Name := 'tblCompanycomstreet';
    tblCompanycomstreet.FieldName := 'comstreet';
    tblCompanycomstreet.Size := 100;
    tblCompanycomhousenr.SetParentComponent(tblCompany);
    tblCompanycomhousenr.Name := 'tblCompanycomhousenr';
    tblCompanycomhousenr.FieldName := 'comhousenr';
    tblCompanycomhousenr.Size := 10;
    tblCompanycomhousenrextra.SetParentComponent(tblCompany);
    tblCompanycomhousenrextra.Name := 'tblCompanycomhousenrextra';
    tblCompanycomhousenrextra.FieldName := 'comhousenrextra';
    tblCompanycomhousenrextra.Size := 10;
    tblCompanycomzip.SetParentComponent(tblCompany);
    tblCompanycomzip.Name := 'tblCompanycomzip';
    tblCompanycomzip.FieldName := 'comzip';
    tblCompanycomzip.Size := 15;
    tblCompanycomcity.SetParentComponent(tblCompany);
    tblCompanycomcity.Name := 'tblCompanycomcity';
    tblCompanycomcity.FieldName := 'comcity';
    tblCompanycomcity.Size := 100;
    tblCompanycomcityfk.SetParentComponent(tblCompany);
    tblCompanycomcityfk.Name := 'tblCompanycomcityfk';
    tblCompanycomcityfk.FieldName := 'comcityfk';
    tblCompanycomcountry.SetParentComponent(tblCompany);
    tblCompanycomcountry.Name := 'tblCompanycomcountry';
    tblCompanycomcountry.FieldName := 'comcountry';
    tblCompanycomcountry.Size := 2;
    tblCompanycomcountryfk.SetParentComponent(tblCompany);
    tblCompanycomcountryfk.Name := 'tblCompanycomcountryfk';
    tblCompanycomcountryfk.FieldName := 'comcountryfk';
    tblCompanycomemail.SetParentComponent(tblCompany);
    tblCompanycomemail.Name := 'tblCompanycomemail';
    tblCompanycomemail.FieldName := 'comemail';
    tblCompanycomemail.Size := 127;
    tblCompanycombankaccount.SetParentComponent(tblCompany);
    tblCompanycombankaccount.Name := 'tblCompanycombankaccount';
    tblCompanycombankaccount.FieldName := 'combankaccount';
    tblCompanycombankaccount.Size := 34;
    tblCompanycomvat.SetParentComponent(tblCompany);
    tblCompanycomvat.Name := 'tblCompanycomvat';
    tblCompanycomvat.FieldName := 'comvat';
    tblCompanycomtelephone.SetParentComponent(tblCompany);
    tblCompanycomtelephone.Name := 'tblCompanycomtelephone';
    tblCompanycomtelephone.FieldName := 'comtelephone';
    tblCompanycomstatus.SetParentComponent(tblCompany);
    tblCompanycomstatus.Name := 'tblCompanycomstatus';
    tblCompanycomstatus.FieldName := 'comstatus';
    tblCompanycompriority.SetParentComponent(tblCompany);
    tblCompanycompriority.Name := 'tblCompanycompriority';
    tblCompanycompriority.FieldName := 'compriority';
    tblCompanycomlanguagefk.SetParentComponent(tblCompany);
    tblCompanycomlanguagefk.Name := 'tblCompanycomlanguagefk';
    tblCompanycomlanguagefk.FieldName := 'comlanguagefk';
    tblCompanycomdatabaseid.SetParentComponent(tblCompany);
    tblCompanycomdatabaseid.Name := 'tblCompanycomdatabaseid';
    tblCompanycomdatabaseid.FieldName := 'comdatabaseid';
    tblCompanyOverview.SetParentComponent(Self);
    tblCompanyOverview.Name := 'tblCompanyOverview';
    tblCompanyOverview.TableName := 'CompanyOverview';
    tblCompanyOverview.DAConnection := CompanyConnection;
    tblCompanyOverview.DAOptions := [];
    tblCompanyOverview.Left := 328;
    tblCompanyOverview.Top := 448;
    tblCompanyOverviewcomid.SetParentComponent(tblCompanyOverview);
    tblCompanyOverviewcomid.Name := 'tblCompanyOverviewcomid';
    tblCompanyOverviewcomid.FieldName := 'comid';
    tblCompanyOverviewcomname.SetParentComponent(tblCompanyOverview);
    tblCompanyOverviewcomname.Name := 'tblCompanyOverviewcomname';
    tblCompanyOverviewcomname.FieldName := 'comname';
    tblCompanyOverviewcomname.Size := 100;
    tblCompanyOverviewcomstreet.SetParentComponent(tblCompanyOverview);
    tblCompanyOverviewcomstreet.Name := 'tblCompanyOverviewcomstreet';
    tblCompanyOverviewcomstreet.FieldName := 'comstreet';
    tblCompanyOverviewcomstreet.Size := 100;
    tblCompanyOverviewcomhousenr.SetParentComponent(tblCompanyOverview);
    tblCompanyOverviewcomhousenr.Name := 'tblCompanyOverviewcomhousenr';
    tblCompanyOverviewcomhousenr.FieldName := 'comhousenr';
    tblCompanyOverviewcomhousenr.Size := 10;
    tblCompanyOverviewcomzip.SetParentComponent(tblCompanyOverview);
    tblCompanyOverviewcomzip.Name := 'tblCompanyOverviewcomzip';
    tblCompanyOverviewcomzip.FieldName := 'comzip';
    tblCompanyOverviewcomzip.Size := 15;
    tblCompanyOverviewcomcity.SetParentComponent(tblCompanyOverview);
    tblCompanyOverviewcomcity.Name := 'tblCompanyOverviewcomcity';
    tblCompanyOverviewcomcity.FieldName := 'comcity';
    tblCompanyOverviewcomcity.Size := 100;
    tblCompanyOverviewcomcountry.SetParentComponent(tblCompanyOverview);
    tblCompanyOverviewcomcountry.Name := 'tblCompanyOverviewcomcountry';
    tblCompanyOverviewcomcountry.FieldName := 'comcountry';
    tblCompanyOverviewcomcountry.Size := 2;
    tblCity.SetParentComponent(Self);
    tblCity.Name := 'tblCity';
    tblCity.TableName := 'City';
    tblCity.DAConnection := GeoConnection;
    tblCity.DAOptions := [];
    tblCity.Left := 176;
    tblCity.Top := 352;
    tblCitycitid.SetParentComponent(tblCity);
    tblCitycitid.Name := 'tblCitycitid';
    tblCitycitid.FieldName := 'citid';
    tblCitycitcreatedon.SetParentComponent(tblCity);
    tblCitycitcreatedon.Name := 'tblCitycitcreatedon';
    tblCitycitcreatedon.FieldName := 'citcreatedon';
    tblCitycitcreatedbyfk.SetParentComponent(tblCity);
    tblCitycitcreatedbyfk.Name := 'tblCitycitcreatedbyfk';
    tblCitycitcreatedbyfk.FieldName := 'citcreatedbyfk';
    tblCitycitchangedon.SetParentComponent(tblCity);
    tblCitycitchangedon.Name := 'tblCitycitchangedon';
    tblCitycitchangedon.FieldName := 'citchangedon';
    tblCitycitchangedbyfk.SetParentComponent(tblCity);
    tblCitycitchangedbyfk.Name := 'tblCitycitchangedbyfk';
    tblCitycitchangedbyfk.FieldName := 'citchangedbyfk';
    tblCitycitcountryfk.SetParentComponent(tblCity);
    tblCitycitcountryfk.Name := 'tblCitycitcountryfk';
    tblCitycitcountryfk.FieldName := 'citcountryfk';
    tblCitycitzip.SetParentComponent(tblCity);
    tblCitycitzip.Name := 'tblCitycitzip';
    tblCitycitzip.FieldName := 'citzip';
    tblCitycitzip.Size := 15;
    tblCitycitname.SetParentComponent(tblCity);
    tblCitycitname.Name := 'tblCitycitname';
    tblCitycitname.FieldName := 'citname';
    tblCitycitname.Size := 100;
    tblCityOverview.SetParentComponent(Self);
    tblCityOverview.Name := 'tblCityOverview';
    tblCityOverview.TableName := 'CityOverview';
    tblCityOverview.DAConnection := GeoConnection;
    tblCityOverview.DAOptions := [];
    tblCityOverview.Left := 256;
    tblCityOverview.Top := 352;
    tblCityOverviewcitID.SetParentComponent(tblCityOverview);
    tblCityOverviewcitID.Name := 'tblCityOverviewcitID';
    tblCityOverviewcitID.FieldName := 'citID';
    tblCityOverviewcitZip.SetParentComponent(tblCityOverview);
    tblCityOverviewcitZip.Name := 'tblCityOverviewcitZip';
    tblCityOverviewcitZip.FieldName := 'citZip';
    tblCityOverviewcitZip.Size := 15;
    tblCityOverviewcitName.SetParentComponent(tblCityOverview);
    tblCityOverviewcitName.Name := 'tblCityOverviewcitName';
    tblCityOverviewcitName.FieldName := 'citName';
    tblCityOverviewcitName.Size := 100;
    tblCityOverviewconID.SetParentComponent(tblCityOverview);
    tblCityOverviewconID.Name := 'tblCityOverviewconID';
    tblCityOverviewconID.FieldName := 'conID';
    tblCityOverviewconISO2.SetParentComponent(tblCityOverview);
    tblCityOverviewconISO2.Name := 'tblCityOverviewconISO2';
    tblCityOverviewconISO2.FieldName := 'conISO2';
    tblCityOverviewconISO2.Size := 2;
    tblCityOverviewconISO3.SetParentComponent(tblCityOverview);
    tblCityOverviewconISO3.Name := 'tblCityOverviewconISO3';
    tblCityOverviewconISO3.FieldName := 'conISO3';
    tblCityOverviewconISO3.Size := 3;
    tblCityOverviewconName.SetParentComponent(tblCityOverview);
    tblCityOverviewconName.Name := 'tblCityOverviewconName';
    tblCityOverviewconName.FieldName := 'conName';
    tblCityOverviewconName.Size := 100;
    tblCountry.SetParentComponent(Self);
    tblCountry.Name := 'tblCountry';
    tblCountry.TableName := 'Country';
    tblCountry.DAConnection := GeoConnection;
    tblCountry.DAOptions := [];
    tblCountry.Left := 352;
    tblCountry.Top := 352;
    tblCountryconid.SetParentComponent(tblCountry);
    tblCountryconid.Name := 'tblCountryconid';
    tblCountryconid.FieldName := 'conid';
    tblCountryconcreatedon.SetParentComponent(tblCountry);
    tblCountryconcreatedon.Name := 'tblCountryconcreatedon';
    tblCountryconcreatedon.FieldName := 'concreatedon';
    tblCountryconcreatedbyfk.SetParentComponent(tblCountry);
    tblCountryconcreatedbyfk.Name := 'tblCountryconcreatedbyfk';
    tblCountryconcreatedbyfk.FieldName := 'concreatedbyfk';
    tblCountryconchangedon.SetParentComponent(tblCountry);
    tblCountryconchangedon.Name := 'tblCountryconchangedon';
    tblCountryconchangedon.FieldName := 'conchangedon';
    tblCountryconchangedbyfk.SetParentComponent(tblCountry);
    tblCountryconchangedbyfk.Name := 'tblCountryconchangedbyfk';
    tblCountryconchangedbyfk.FieldName := 'conchangedbyfk';
    tblCountryconiso2.SetParentComponent(tblCountry);
    tblCountryconiso2.Name := 'tblCountryconiso2';
    tblCountryconiso2.FieldName := 'coniso2';
    tblCountryconiso2.Size := 2;
    tblCountryconiso3.SetParentComponent(tblCountry);
    tblCountryconiso3.Name := 'tblCountryconiso3';
    tblCountryconiso3.FieldName := 'coniso3';
    tblCountryconiso3.Size := 3;
    tblCountryconname.SetParentComponent(tblCountry);
    tblCountryconname.Name := 'tblCountryconname';
    tblCountryconname.FieldName := 'conname';
    tblCountryconname.Size := 100;
    tblCountryOverview.SetParentComponent(Self);
    tblCountryOverview.Name := 'tblCountryOverview';
    tblCountryOverview.TableName := 'CountryOverview';
    tblCountryOverview.DAConnection := GeoConnection;
    tblCountryOverview.DAOptions := [];
    tblCountryOverview.Left := 432;
    tblCountryOverview.Top := 352;
    tblCountryOverviewconID.SetParentComponent(tblCountryOverview);
    tblCountryOverviewconID.Name := 'tblCountryOverviewconID';
    tblCountryOverviewconID.FieldName := 'conID';
    tblCountryOverviewconISO2.SetParentComponent(tblCountryOverview);
    tblCountryOverviewconISO2.Name := 'tblCountryOverviewconISO2';
    tblCountryOverviewconISO2.FieldName := 'conISO2';
    tblCountryOverviewconISO2.Size := 2;
    tblCountryOverviewconISO3.SetParentComponent(tblCountryOverview);
    tblCountryOverviewconISO3.Name := 'tblCountryOverviewconISO3';
    tblCountryOverviewconISO3.FieldName := 'conISO3';
    tblCountryOverviewconISO3.Size := 3;
    tblCountryOverviewconName.SetParentComponent(tblCountryOverview);
    tblCountryOverviewconName.Name := 'tblCountryOverviewconName';
    tblCountryOverviewconName.FieldName := 'conName';
    tblCountryOverviewconName.Size := 100;
    tblContactAddressOverview.SetParentComponent(Self);
    tblContactAddressOverview.Name := 'tblContactAddressOverview';
    tblContactAddressOverview.TableName := 'ContactAddressOverview';
    tblContactAddressOverview.DAConnection := ContactConnection;
    tblContactAddressOverview.Params.Clear;
    with tblContactAddressOverview.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'ContactID';
      ParamType := ptInput;
      Value := Null;
    end;
    with tblContactAddressOverview.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblContactAddressOverview.DAOptions := [];
    tblContactAddressOverview.Left := 312;
    tblContactAddressOverview.Top := 272;
    tblContactAddressOverviewctaid.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctaid.Name := 'tblContactAddressOverviewctaid';
    tblContactAddressOverviewctaid.FieldName := 'ctaid';
    tblContactAddressOverviewctacreatedon.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctacreatedon.Name := 'tblContactAddressOverviewctacreatedon';
    tblContactAddressOverviewctacreatedon.FieldName := 'ctacreatedon';
    tblContactAddressOverviewctacreatedbyfk.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctacreatedbyfk.Name := 'tblContactAddressOverviewctacreatedbyfk';
    tblContactAddressOverviewctacreatedbyfk.FieldName := 'ctacreatedbyfk';
    tblContactAddressOverviewctachangedon.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctachangedon.Name := 'tblContactAddressOverviewctachangedon';
    tblContactAddressOverviewctachangedon.FieldName := 'ctachangedon';
    tblContactAddressOverviewctachangedbyfk.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctachangedbyfk.Name := 'tblContactAddressOverviewctachangedbyfk';
    tblContactAddressOverviewctachangedbyfk.FieldName := 'ctachangedbyfk';
    tblContactAddressOverviewctacontactfk.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctacontactfk.Name := 'tblContactAddressOverviewctacontactfk';
    tblContactAddressOverviewctacontactfk.FieldName := 'ctacontactfk';
    tblContactAddressOverviewctaaddresstypefk.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctaaddresstypefk.Name := 'tblContactAddressOverviewctaaddresstypefk';
    tblContactAddressOverviewctaaddresstypefk.FieldName := 'ctaaddresstypefk';
    tblContactAddressOverviewctaaddresstype.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctaaddresstype.Name := 'tblContactAddressOverviewctaaddresstype';
    tblContactAddressOverviewctaaddresstype.FieldName := 'ctaaddresstype';
    tblContactAddressOverviewctaaddresstype.Size := 10;
    tblContactAddressOverviewctastreet.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctastreet.Name := 'tblContactAddressOverviewctastreet';
    tblContactAddressOverviewctastreet.FieldName := 'ctastreet';
    tblContactAddressOverviewctastreet.Size := 50;
    tblContactAddressOverviewctastreetnr.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctastreetnr.Name := 'tblContactAddressOverviewctastreetnr';
    tblContactAddressOverviewctastreetnr.FieldName := 'ctastreetnr';
    tblContactAddressOverviewctastreetnr.Size := 50;
    tblContactAddressOverviewctacityzip.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctacityzip.Name := 'tblContactAddressOverviewctacityzip';
    tblContactAddressOverviewctacityzip.FieldName := 'ctacityzip';
    tblContactAddressOverviewctacityzip.Size := 15;
    tblContactAddressOverviewctacityname.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctacityname.Name := 'tblContactAddressOverviewctacityname';
    tblContactAddressOverviewctacityname.FieldName := 'ctacityname';
    tblContactAddressOverviewctacityname.Size := 100;
    tblContactAddressOverviewctacityfk.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctacityfk.Name := 'tblContactAddressOverviewctacityfk';
    tblContactAddressOverviewctacityfk.FieldName := 'ctacityfk';
    tblContactAddressOverviewctaremark.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctaremark.Name := 'tblContactAddressOverviewctaremark';
    tblContactAddressOverviewctaremark.FieldName := 'ctaremark';
    tblContactAddressOverviewctaremark.Size := 255;
    tblContactAddressOverviewctacorrespondence.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctacorrespondence.Name := 'tblContactAddressOverviewctacorrespondence';
    tblContactAddressOverviewctacorrespondence.FieldName := 'ctacorrespondence';
    tblContactAddressOverviewctasecret.SetParentComponent(tblContactAddressOverview);
    tblContactAddressOverviewctasecret.Name := 'tblContactAddressOverviewctasecret';
    tblContactAddressOverviewctasecret.FieldName := 'ctasecret';
    tblContactOverview.SetParentComponent(Self);
    tblContactOverview.Name := 'tblContactOverview';
    tblContactOverview.TableName := 'ContactOverview';
    tblContactOverview.DAConnection := ContactConnection;
    tblContactOverview.Params.Clear;
    with tblContactOverview.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblContactOverview.DAOptions := [];
    tblContactOverview.Left := 176;
    tblContactOverview.Top := 272;
    tblContactOverviewcntid.SetParentComponent(tblContactOverview);
    tblContactOverviewcntid.Name := 'tblContactOverviewcntid';
    tblContactOverviewcntid.FieldName := 'cntid';
    tblContactOverviewcntcreatedon.SetParentComponent(tblContactOverview);
    tblContactOverviewcntcreatedon.Name := 'tblContactOverviewcntcreatedon';
    tblContactOverviewcntcreatedon.FieldName := 'cntcreatedon';
    tblContactOverviewcntcreatedbyfk.SetParentComponent(tblContactOverview);
    tblContactOverviewcntcreatedbyfk.Name := 'tblContactOverviewcntcreatedbyfk';
    tblContactOverviewcntcreatedbyfk.FieldName := 'cntcreatedbyfk';
    tblContactOverviewcntchangedon.SetParentComponent(tblContactOverview);
    tblContactOverviewcntchangedon.Name := 'tblContactOverviewcntchangedon';
    tblContactOverviewcntchangedon.FieldName := 'cntchangedon';
    tblContactOverviewcntchangedbyfk.SetParentComponent(tblContactOverview);
    tblContactOverviewcntchangedbyfk.Name := 'tblContactOverviewcntchangedbyfk';
    tblContactOverviewcntchangedbyfk.FieldName := 'cntchangedbyfk';
    tblContactOverviewcntcompanyfk.SetParentComponent(tblContactOverview);
    tblContactOverviewcntcompanyfk.Name := 'tblContactOverviewcntcompanyfk';
    tblContactOverviewcntcompanyfk.FieldName := 'cntcompanyfk';
    tblContactOverviewcntfirstname.SetParentComponent(tblContactOverview);
    tblContactOverviewcntfirstname.Name := 'tblContactOverviewcntfirstname';
    tblContactOverviewcntfirstname.FieldName := 'cntfirstname';
    tblContactOverviewcntfirstname.Size := 64;
    tblContactOverviewcntlastname.SetParentComponent(tblContactOverview);
    tblContactOverviewcntlastname.Name := 'tblContactOverviewcntlastname';
    tblContactOverviewcntlastname.FieldName := 'cntlastname';
    tblContactOverviewcntlastname.Size := 127;
    tblContactOverviewcntprofession.SetParentComponent(tblContactOverview);
    tblContactOverviewcntprofession.Name := 'tblContactOverviewcntprofession';
    tblContactOverviewcntprofession.FieldName := 'cntprofession';
    tblContactOverviewcntprofession.Size := 64;
    tblContactOverviewcntbirthdateon.SetParentComponent(tblContactOverview);
    tblContactOverviewcntbirthdateon.Name := 'tblContactOverviewcntbirthdateon';
    tblContactOverviewcntbirthdateon.FieldName := 'cntbirthdateon';
    tblContactOverviewcntisbirthdateunknown.SetParentComponent(tblContactOverview);
    tblContactOverviewcntisbirthdateunknown.Name := 'tblContactOverviewcntisbirthdateunknown';
    tblContactOverviewcntisbirthdateunknown.FieldName := 'cntisbirthdateunknown';
    tblContactOverviewcntgender.SetParentComponent(tblContactOverview);
    tblContactOverviewcntgender.Name := 'tblContactOverviewcntgender';
    tblContactOverviewcntgender.FieldName := 'cntgender';
    tblContactOverviewcntgender.FixedChar := True;
    tblContactOverviewcntgender.Size := 1;
    tblContactOverviewcntcityofbirthfk.SetParentComponent(tblContactOverview);
    tblContactOverviewcntcityofbirthfk.Name := 'tblContactOverviewcntcityofbirthfk';
    tblContactOverviewcntcityofbirthfk.FieldName := 'cntcityofbirthfk';
    tblContactOverviewcntcityofbirthname.SetParentComponent(tblContactOverview);
    tblContactOverviewcntcityofbirthname.Name := 'tblContactOverviewcntcityofbirthname';
    tblContactOverviewcntcityofbirthname.FieldName := 'cntcityofbirthname';
    tblContactOverviewcntcityofbirthname.Size := 100;
    tblContactOverviewcntcityofbirthzip.SetParentComponent(tblContactOverview);
    tblContactOverviewcntcityofbirthzip.Name := 'tblContactOverviewcntcityofbirthzip';
    tblContactOverviewcntcityofbirthzip.FieldName := 'cntcityofbirthzip';
    tblContactOverviewcntcityofbirthzip.Size := 15;
    tblContactOverviewcntnationalityfk.SetParentComponent(tblContactOverview);
    tblContactOverviewcntnationalityfk.Name := 'tblContactOverviewcntnationalityfk';
    tblContactOverviewcntnationalityfk.FieldName := 'cntnationalityfk';
    tblContactOverviewcntnationality2.SetParentComponent(tblContactOverview);
    tblContactOverviewcntnationality2.Name := 'tblContactOverviewcntnationality2';
    tblContactOverviewcntnationality2.FieldName := 'cntnationality2';
    tblContactOverviewcntnationality2.Size := 2;
    tblContactOverviewcntnationality.SetParentComponent(tblContactOverview);
    tblContactOverviewcntnationality.Name := 'tblContactOverviewcntnationality';
    tblContactOverviewcntnationality.FieldName := 'cntnationality';
    tblContactOverviewcntnationality.Size := 40;
    tblContactOverviewcntpicturefk.SetParentComponent(tblContactOverview);
    tblContactOverviewcntpicturefk.Name := 'tblContactOverviewcntpicturefk';
    tblContactOverviewcntpicturefk.FieldName := 'cntpicturefk';
    tblContactOverviewcntkbonr.SetParentComponent(tblContactOverview);
    tblContactOverviewcntkbonr.Name := 'tblContactOverviewcntkbonr';
    tblContactOverviewcntkbonr.FieldName := 'cntkbonr';
    tblContactOverviewcntkbonr.Size := 10;
    tblContactOverviewcntremark.SetParentComponent(tblContactOverview);
    tblContactOverviewcntremark.Name := 'tblContactOverviewcntremark';
    tblContactOverviewcntremark.FieldName := 'cntremark';
    tblContactOverviewcntremark.Size := 255;
    tblContactOverviewcntpersonfk.SetParentComponent(tblContactOverview);
    tblContactOverviewcntpersonfk.Name := 'tblContactOverviewcntpersonfk';
    tblContactOverviewcntpersonfk.FieldName := 'cntpersonfk';
    tblContactOverviewcntsalutation.SetParentComponent(tblContactOverview);
    tblContactOverviewcntsalutation.Name := 'tblContactOverviewcntsalutation';
    tblContactOverviewcntsalutation.FieldName := 'cntsalutation';
    tblContactOverviewcntsalutation.Size := 32;
    tblContactOverviewcntsearchname.SetParentComponent(tblContactOverview);
    tblContactOverviewcntsearchname.Name := 'tblContactOverviewcntsearchname';
    tblContactOverviewcntsearchname.FieldName := 'cntsearchname';
    tblContactOverviewcntsearchname.Size := 50;
    tblContactOverviewcntprefixes.SetParentComponent(tblContactOverview);
    tblContactOverviewcntprefixes.Name := 'tblContactOverviewcntprefixes';
    tblContactOverviewcntprefixes.FieldName := 'cntprefixes';
    tblContactOverviewcntprefixes.Size := 32;
    tblContactOverviewcntfriendlytitle.SetParentComponent(tblContactOverview);
    tblContactOverviewcntfriendlytitle.Name := 'tblContactOverviewcntfriendlytitle';
    tblContactOverviewcntfriendlytitle.FieldName := 'cntfriendlytitle';
    tblContactOverviewcntfriendlytitle.Size := 32;
    tblContactRelationsOverview.SetParentComponent(Self);
    tblContactRelationsOverview.Name := 'tblContactRelationsOverview';
    tblContactRelationsOverview.TableName := 'ContactRelationsOverview';
    tblContactRelationsOverview.DAConnection := ContactConnection;
    tblContactRelationsOverview.Params.Clear;
    with tblContactRelationsOverview.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'ContactID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblContactRelationsOverview.DAOptions := [];
    tblContactRelationsOverview.Left := 456;
    tblContactRelationsOverview.Top := 272;
    tblContactRelationsOverviewctrid.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrid.Name := 'tblContactRelationsOverviewctrid';
    tblContactRelationsOverviewctrid.FieldName := 'ctrid';
    tblContactRelationsOverviewctrcreatedon.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrcreatedon.Name := 'tblContactRelationsOverviewctrcreatedon';
    tblContactRelationsOverviewctrcreatedon.FieldName := 'ctrcreatedon';
    tblContactRelationsOverviewctrcreatedbyfk.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrcreatedbyfk.Name := 'tblContactRelationsOverviewctrcreatedbyfk';
    tblContactRelationsOverviewctrcreatedbyfk.FieldName := 'ctrcreatedbyfk';
    tblContactRelationsOverviewctrchangedon.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrchangedon.Name := 'tblContactRelationsOverviewctrchangedon';
    tblContactRelationsOverviewctrchangedon.FieldName := 'ctrchangedon';
    tblContactRelationsOverviewctrchangedbyfk.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrchangedbyfk.Name := 'tblContactRelationsOverviewctrchangedbyfk';
    tblContactRelationsOverviewctrchangedbyfk.FieldName := 'ctrchangedbyfk';
    tblContactRelationsOverviewctrfromcontactfk.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrfromcontactfk.Name := 'tblContactRelationsOverviewctrfromcontactfk';
    tblContactRelationsOverviewctrfromcontactfk.FieldName := 'ctrfromcontactfk';
    tblContactRelationsOverviewctrtocontactfk.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrtocontactfk.Name := 'tblContactRelationsOverviewctrtocontactfk';
    tblContactRelationsOverviewctrtocontactfk.FieldName := 'ctrtocontactfk';
    tblContactRelationsOverviewctrrelationtype.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrrelationtype.Name := 'tblContactRelationsOverviewctrrelationtype';
    tblContactRelationsOverviewctrrelationtype.FieldName := 'ctrrelationtype';
    tblContactRelationsOverviewctrrelationtype.Size := 10;
    tblContactRelationsOverviewctrrelationtypefk.SetParentComponent(tblContactRelationsOverview);
    tblContactRelationsOverviewctrrelationtypefk.Name := 'tblContactRelationsOverviewctrrelationtypefk';
    tblContactRelationsOverviewctrrelationtypefk.FieldName := 'ctrrelationtypefk';
    tblContactTelecomOverview.SetParentComponent(Self);
    tblContactTelecomOverview.Name := 'tblContactTelecomOverview';
    tblContactTelecomOverview.TableName := 'ContactTelecomOverview';
    tblContactTelecomOverview.DAConnection := ContactConnection;
    tblContactTelecomOverview.Params.Clear;
    with tblContactTelecomOverview.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'ContactID';
      ParamType := ptInput;
      Value := Null;
    end;
    with tblContactTelecomOverview.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    tblContactTelecomOverview.DAOptions := [];
    tblContactTelecomOverview.Left := 608;
    tblContactTelecomOverview.Top := 272;
    tblContactTelecomOverviewcttid.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttid.Name := 'tblContactTelecomOverviewcttid';
    tblContactTelecomOverviewcttid.FieldName := 'cttid';
    tblContactTelecomOverviewcttcreatedon.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttcreatedon.Name := 'tblContactTelecomOverviewcttcreatedon';
    tblContactTelecomOverviewcttcreatedon.FieldName := 'cttcreatedon';
    tblContactTelecomOverviewcttcreatedbyfk.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttcreatedbyfk.Name := 'tblContactTelecomOverviewcttcreatedbyfk';
    tblContactTelecomOverviewcttcreatedbyfk.FieldName := 'cttcreatedbyfk';
    tblContactTelecomOverviewcttchangedon.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttchangedon.Name := 'tblContactTelecomOverviewcttchangedon';
    tblContactTelecomOverviewcttchangedon.FieldName := 'cttchangedon';
    tblContactTelecomOverviewcttchangedbyfk.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttchangedbyfk.Name := 'tblContactTelecomOverviewcttchangedbyfk';
    tblContactTelecomOverviewcttchangedbyfk.FieldName := 'cttchangedbyfk';
    tblContactTelecomOverviewcttcontactfk.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttcontactfk.Name := 'tblContactTelecomOverviewcttcontactfk';
    tblContactTelecomOverviewcttcontactfk.FieldName := 'cttcontactfk';
    tblContactTelecomOverviewcttcontacttypefk.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttcontacttypefk.Name := 'tblContactTelecomOverviewcttcontacttypefk';
    tblContactTelecomOverviewcttcontacttypefk.FieldName := 'cttcontacttypefk';
    tblContactTelecomOverviewcttcontacttype.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttcontacttype.Name := 'tblContactTelecomOverviewcttcontacttype';
    tblContactTelecomOverviewcttcontacttype.FieldName := 'cttcontacttype';
    tblContactTelecomOverviewcttcontacttype.Size := 10;
    tblContactTelecomOverviewcttdata.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttdata.Name := 'tblContactTelecomOverviewcttdata';
    tblContactTelecomOverviewcttdata.FieldName := 'cttdata';
    tblContactTelecomOverviewcttdata.Size := 255;
    tblContactTelecomOverviewcttremark.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttremark.Name := 'tblContactTelecomOverviewcttremark';
    tblContactTelecomOverviewcttremark.FieldName := 'cttremark';
    tblContactTelecomOverviewcttremark.Size := 255;
    tblContactTelecomOverviewcttcorrespondence.SetParentComponent(tblContactTelecomOverview);
    tblContactTelecomOverviewcttcorrespondence.Name := 'tblContactTelecomOverviewcttcorrespondence';
    tblContactTelecomOverviewcttcorrespondence.FieldName := 'cttcorrespondence';
    dsParameters.SetParentComponent(Self);
    dsParameters.Name := 'dsParameters';
    dsParameters.TableName := 'LanguageParameterOverview';
    dsParameters.DAConnection := ParameterConnection;
    dsParameters.Params.Clear;
    with dsParameters.Params.Add do
    begin
      DataType := ftInteger;
      Name := 'language';
      ParamType := ptInput;
      Value := 0;
    end;
    dsParameters.DAOptions := [];
    dsParameters.Left := 168;
    dsParameters.Top := 80;
    dsParametersparid.SetParentComponent(dsParameters);
    dsParametersparid.Name := 'dsParametersparid';
    dsParametersparid.FieldName := 'parid';
    dsParametersparcreatedon.SetParentComponent(dsParameters);
    dsParametersparcreatedon.Name := 'dsParametersparcreatedon';
    dsParametersparcreatedon.FieldName := 'parcreatedon';
    dsParametersparcreatedbyfk.SetParentComponent(dsParameters);
    dsParametersparcreatedbyfk.Name := 'dsParametersparcreatedbyfk';
    dsParametersparcreatedbyfk.FieldName := 'parcreatedbyfk';
    dsParametersparchangedon.SetParentComponent(dsParameters);
    dsParametersparchangedon.Name := 'dsParametersparchangedon';
    dsParametersparchangedon.FieldName := 'parchangedon';
    dsParametersparchangedbyfk.SetParentComponent(dsParameters);
    dsParametersparchangedbyfk.Name := 'dsParametersparchangedbyfk';
    dsParametersparchangedbyfk.FieldName := 'parchangedbyfk';
    dsParametersparparamtype.SetParentComponent(dsParameters);
    dsParametersparparamtype.Name := 'dsParametersparparamtype';
    dsParametersparparamtype.FieldName := 'parparamtype';
    dsParametersparparamtype.Size := 10;
    dsParametersparvaluetype.SetParentComponent(dsParameters);
    dsParametersparvaluetype.Name := 'dsParametersparvaluetype';
    dsParametersparvaluetype.FieldName := 'parvaluetype';
    dsParametersparvaluename.SetParentComponent(dsParameters);
    dsParametersparvaluename.Name := 'dsParametersparvaluename';
    dsParametersparvaluename.FieldName := 'parvaluename';
    dsParametersparvaluename.Size := 32;
    dsParametersparvalue.SetParentComponent(dsParameters);
    dsParametersparvalue.Name := 'dsParametersparvalue';
    dsParametersparvalue.FieldName := 'parvalue';
    dsParametersparvalue.Size := 127;
    dsParametersparextra.SetParentComponent(dsParameters);
    dsParametersparextra.Name := 'dsParametersparextra';
    dsParametersparextra.FieldName := 'parextra';
    dsParametersparextra.Size := 10;
    dsParametersparlanguage.SetParentComponent(dsParameters);
    dsParametersparlanguage.Name := 'dsParametersparlanguage';
    dsParametersparlanguage.FieldName := 'parlanguage';
    ParameterConnection.SetParentComponent(Self);
    ParameterConnection.Name := 'ParameterConnection';
    ParameterConnection.URL := 'http://localhost:8099/bin';
    ParameterConnection.DataserviceName := 'ParameterService';
    ParameterConnection.Left := 48;
    ParameterConnection.Top := 80;
    CSRConnection.SetParentComponent(Self);
    CSRConnection.Name := 'CSRConnection';
    CSRConnection.URL := 'https://test.dezq.be/server/bin';
    CSRConnection.DataserviceName := 'CSRService';
    CSRConnection.Left := 48;
    CSRConnection.Top := 216;
  finally
    CompanyConnection.AfterLoadDFMValues;
    DataConnection.AfterLoadDFMValues;
    ContactConnection.AfterLoadDFMValues;
    GeoConnection.AfterLoadDFMValues;
    PetitionConnection.AfterLoadDFMValues;
    tblContactAddress.AfterLoadDFMValues;
    tblContactAddressctaid.AfterLoadDFMValues;
    tblContactAddressctacreatedon.AfterLoadDFMValues;
    tblContactAddressctacreatedbyfk.AfterLoadDFMValues;
    tblContactAddressctachangedon.AfterLoadDFMValues;
    tblContactAddressctachangedbyfk.AfterLoadDFMValues;
    tblContactAddressctacontactfk.AfterLoadDFMValues;
    tblContactAddressctaaddresstypefk.AfterLoadDFMValues;
    tblContactAddressctaaddresstype.AfterLoadDFMValues;
    tblContactAddressctastreet.AfterLoadDFMValues;
    tblContactAddressctastreetnr.AfterLoadDFMValues;
    tblContactAddressctacityzip.AfterLoadDFMValues;
    tblContactAddressctacityname.AfterLoadDFMValues;
    tblContactAddressctacityfk.AfterLoadDFMValues;
    tblContactAddressctaremark.AfterLoadDFMValues;
    tblContactAddressctacorrespondence.AfterLoadDFMValues;
    tblContactAddressctasecret.AfterLoadDFMValues;
    tblDebt.AfterLoadDFMValues;
    tblDebtdebid.AfterLoadDFMValues;
    tblDebtdebcreatedon.AfterLoadDFMValues;
    tblDebtdebcreatedbyfk.AfterLoadDFMValues;
    tblDebtdebchangedon.AfterLoadDFMValues;
    tblDebtdebchangedbyfk.AfterLoadDFMValues;
    tblDebtdebdossierfk.AfterLoadDFMValues;
    tblDebtdebrecordtype.AfterLoadDFMValues;
    tblDebtdebcreditorfk.AfterLoadDFMValues;
    tblDebtdebdebttypefk.AfterLoadDFMValues;
    tblDebtdebdebttype.AfterLoadDFMValues;
    tblDebtdebstarton.AfterLoadDFMValues;
    tblDebtdebreference.AfterLoadDFMValues;
    tblDebtdeboriginalamount.AfterLoadDFMValues;
    tblDebtdebcurrentamount.AfterLoadDFMValues;
    tblDebtdebmonthlyamount.AfterLoadDFMValues;
    tblDebtdebisdisputed.AfterLoadDFMValues;
    tblDebtdebdisputereason.AfterLoadDFMValues;
    tblDebtdebdisputecourt.AfterLoadDFMValues;
    tblDebtdebdisputedamount.AfterLoadDFMValues;
    tblDebtdebisdelay.AfterLoadDFMValues;
    tblDebtdebdelayamount.AfterLoadDFMValues;
    tblDebtdebguarantorfk.AfterLoadDFMValues;
    tblDebtdebiscompleted.AfterLoadDFMValues;
    tblDossier.AfterLoadDFMValues;
    tblDossierdosid.AfterLoadDFMValues;
    tblDossierdoscreatedon.AfterLoadDFMValues;
    tblDossierdoscreatedbyfk.AfterLoadDFMValues;
    tblDossierdoschangedon.AfterLoadDFMValues;
    tblDossierdoschangedbyfk.AfterLoadDFMValues;
    tblDossierdosclosedon.AfterLoadDFMValues;
    tblDossierdosdescription.AfterLoadDFMValues;
    tblDossierdoscompanyfk.AfterLoadDFMValues;
    tblDossierdosstatus.AfterLoadDFMValues;
    tblDossierdosdatesent.AfterLoadDFMValues;
    tblDossierdosclosedbyfk.AfterLoadDFMValues;
    tblDossierdoscloseddescription.AfterLoadDFMValues;
    tblDossierdosisbankrupt.AfterLoadDFMValues;
    tblDossierdosbankrupton.AfterLoadDFMValues;
    tblDossierdosiskbocancel.AfterLoadDFMValues;
    tblDossierdoskbocancelon.AfterLoadDFMValues;
    tblDossierdosloginfk.AfterLoadDFMValues;
    tblDossierdosremarks.AfterLoadDFMValues;
    tblDossierdosreopenedon.AfterLoadDFMValues;
    tblDossierdosflags.AfterLoadDFMValues;
    tblDossierdosmonthlyamount.AfterLoadDFMValues;
    tblDossierdosiscomplete.AfterLoadDFMValues;
    tblDossierdoscasefilecounter.AfterLoadDFMValues;
    tblDossierFile.AfterLoadDFMValues;
    tblDossierFiledofid.AfterLoadDFMValues;
    tblDossierFiledofcreatedon.AfterLoadDFMValues;
    tblDossierFiledofcreatedbyfk.AfterLoadDFMValues;
    tblDossierFiledofchangedon.AfterLoadDFMValues;
    tblDossierFiledofchangedbyfk.AfterLoadDFMValues;
    tblDossierFiledofdossierfk.AfterLoadDFMValues;
    tblDossierFiledofsourcefk.AfterLoadDFMValues;
    tblDossierFiledofsourcetype.AfterLoadDFMValues;
    tblDossierFiledofdoctypefk.AfterLoadDFMValues;
    tblDossierFiledofdoctype.AfterLoadDFMValues;
    tblDossierFiledoffilename.AfterLoadDFMValues;
    tblDossierFiledofmime.AfterLoadDFMValues;
    tblDossierFiledofdata.AfterLoadDFMValues;
    tblDossierFiledofisuploadcomplete.AfterLoadDFMValues;
    tblDossierFiledofuploaduserfk.AfterLoadDFMValues;
    tblDossierFiledofcasefilenumber.AfterLoadDFMValues;
    tblDossierPersons.AfterLoadDFMValues;
    tblDossierPersonscntid.AfterLoadDFMValues;
    tblDossierPersonscntcreatedon.AfterLoadDFMValues;
    tblDossierPersonscntcreatedbyfk.AfterLoadDFMValues;
    tblDossierPersonscntchangedon.AfterLoadDFMValues;
    tblDossierPersonscntchangedbyfk.AfterLoadDFMValues;
    tblDossierPersonscntcompanyfk.AfterLoadDFMValues;
    tblDossierPersonscntfirstname.AfterLoadDFMValues;
    tblDossierPersonscntlastname.AfterLoadDFMValues;
    tblDossierPersonscntprofession.AfterLoadDFMValues;
    tblDossierPersonscntbirthdateon.AfterLoadDFMValues;
    tblDossierPersonscntisbirthdateunknown.AfterLoadDFMValues;
    tblDossierPersonscntgender.AfterLoadDFMValues;
    tblDossierPersonscntcityofbirthfk.AfterLoadDFMValues;
    tblDossierPersonscntcityofbirthname.AfterLoadDFMValues;
    tblDossierPersonscntcityofbirthzip.AfterLoadDFMValues;
    tblDossierPersonscntnationalityfk.AfterLoadDFMValues;
    tblDossierPersonscntnationality2.AfterLoadDFMValues;
    tblDossierPersonscntnationality.AfterLoadDFMValues;
    tblDossierPersonscntpicturefk.AfterLoadDFMValues;
    tblDossierPersonscntkbonr.AfterLoadDFMValues;
    tblDossierPersonscntremark.AfterLoadDFMValues;
    tblDossierPersonscntpersonfk.AfterLoadDFMValues;
    tblDossierPersonscntsalutation.AfterLoadDFMValues;
    tblDossierPersonscntsearchname.AfterLoadDFMValues;
    tblDossierPersonscntprefixes.AfterLoadDFMValues;
    tblDossierPersonscntfriendlytitle.AfterLoadDFMValues;
    tblDossierPersonsctaid.AfterLoadDFMValues;
    tblDossierPersonsctacreatedon.AfterLoadDFMValues;
    tblDossierPersonsctacreatedbyfk.AfterLoadDFMValues;
    tblDossierPersonsctachangedon.AfterLoadDFMValues;
    tblDossierPersonsctachangedbyfk.AfterLoadDFMValues;
    tblDossierPersonsctacontactfk.AfterLoadDFMValues;
    tblDossierPersonsctaaddresstypefk.AfterLoadDFMValues;
    tblDossierPersonsctaaddresstype.AfterLoadDFMValues;
    tblDossierPersonsctastreet.AfterLoadDFMValues;
    tblDossierPersonsctastreetnr.AfterLoadDFMValues;
    tblDossierPersonsctacityzip.AfterLoadDFMValues;
    tblDossierPersonsctacityname.AfterLoadDFMValues;
    tblDossierPersonsctacityfk.AfterLoadDFMValues;
    tblDossierPersonsctaremark.AfterLoadDFMValues;
    tblDossierPersonsctacorrespondence.AfterLoadDFMValues;
    tblDossierPersonsctasecret.AfterLoadDFMValues;
    tblDossierPersonsdocid.AfterLoadDFMValues;
    tblDossierPersonsdoccivilstatus.AfterLoadDFMValues;
    tblDossierPersonsdoccivilstatusfk.AfterLoadDFMValues;
    tblDossierPersonsdocissecondpetitioner.AfterLoadDFMValues;
    tblDossierPersonsdocmarcon.AfterLoadDFMValues;
    tblDossierPersonsdocmarconfk.AfterLoadDFMValues;
    tblDossierPersonsdocpersontype.AfterLoadDFMValues;
    tblDossierPersonsdocpersontypefk.AfterLoadDFMValues;
    tblExpense.AfterLoadDFMValues;
    tblExpenseexpid.AfterLoadDFMValues;
    tblExpenseexpcreatedon.AfterLoadDFMValues;
    tblExpenseexpcreatedbyfk.AfterLoadDFMValues;
    tblExpenseexpchangedon.AfterLoadDFMValues;
    tblExpenseexpchangedbyfk.AfterLoadDFMValues;
    tblExpenseexpdossierfk.AfterLoadDFMValues;
    tblExpenseexpcategoryfk.AfterLoadDFMValues;
    tblExpenseexpcategory.AfterLoadDFMValues;
    tblExpenseexpkindfk.AfterLoadDFMValues;
    tblExpenseexpkind.AfterLoadDFMValues;
    tblExpenseexppaymenton.AfterLoadDFMValues;
    tblExpenseexppaymentperiodfk.AfterLoadDFMValues;
    tblExpenseexppaymentperiod.AfterLoadDFMValues;
    tblExpenseexpamount.AfterLoadDFMValues;
    tblExpenseexpremark.AfterLoadDFMValues;
    tblLegalCase.AfterLoadDFMValues;
    tblLegalCaselecid.AfterLoadDFMValues;
    tblLegalCaseleccreatedon.AfterLoadDFMValues;
    tblLegalCaseleccreatedbyfk.AfterLoadDFMValues;
    tblLegalCaselecchangedon.AfterLoadDFMValues;
    tblLegalCaselecchangedbyfk.AfterLoadDFMValues;
    tblLegalCaselecdossierfk.AfterLoadDFMValues;
    tblLegalCaseleccause.AfterLoadDFMValues;
    tblLegalCaselecprimaryfk.AfterLoadDFMValues;
    tblLegalCaselecopponentfk.AfterLoadDFMValues;
    tblLegalCaseleclawyerfk.AfterLoadDFMValues;
    tblLegalCaseleccourt.AfterLoadDFMValues;
    tblLegalCaselecreference.AfterLoadDFMValues;
    tblLegalCaselecremark.AfterLoadDFMValues;
    tblLegalCaselecamount.AfterLoadDFMValues;
    tblLegalCaseleciscomplete.AfterLoadDFMValues;
    tblPaymentPlan.AfterLoadDFMValues;
    tblPaymentPlanpplid.AfterLoadDFMValues;
    tblPaymentPlanpplcreatedon.AfterLoadDFMValues;
    tblPaymentPlanpplcreatedbyfk.AfterLoadDFMValues;
    tblPaymentPlanpplchangedon.AfterLoadDFMValues;
    tblPaymentPlanpplchangedbyfk.AfterLoadDFMValues;
    tblPaymentPlanppldossierfk.AfterLoadDFMValues;
    tblPaymentPlanDetail.AfterLoadDFMValues;
    tblPaymentPlanDetailppdid.AfterLoadDFMValues;
    tblPaymentPlanDetailppdcreatedon.AfterLoadDFMValues;
    tblPaymentPlanDetailppdcreatedbyfk.AfterLoadDFMValues;
    tblPaymentPlanDetailppdchangedon.AfterLoadDFMValues;
    tblPaymentPlanDetailppdchangedbyfk.AfterLoadDFMValues;
    tblPaymentPlanDetailppdpaymentplanfk.AfterLoadDFMValues;
    tblPaymentPlanDetailppdamount.AfterLoadDFMValues;
    tblPaymentPlanDetailppdtotalamount.AfterLoadDFMValues;
    tblPaymentPlanDetailppdpaymenttofk.AfterLoadDFMValues;
    tblPaymentPlanDetailppdiban.AfterLoadDFMValues;
    tblPaymentPlanDetailppdname.AfterLoadDFMValues;
    tblPaymentPlanDetailppdperiodicityfk.AfterLoadDFMValues;
    tblPaymentPlanDetailppdperiodicity.AfterLoadDFMValues;
    tblProperty.AfterLoadDFMValues;
    tblPropertyproid.AfterLoadDFMValues;
    tblPropertyprocreatedon.AfterLoadDFMValues;
    tblPropertyprocreatedbyfk.AfterLoadDFMValues;
    tblPropertyprochangedon.AfterLoadDFMValues;
    tblPropertyprochangedbyfk.AfterLoadDFMValues;
    tblPropertyprodossierfk.AfterLoadDFMValues;
    tblPropertypropropertytype.AfterLoadDFMValues;
    tblPropertypropropertytypefk.AfterLoadDFMValues;
    tblPropertyproowner.AfterLoadDFMValues;
    tblPropertyproownerfk.AfterLoadDFMValues;
    tblPropertyproisrealestate.AfterLoadDFMValues;
    tblPropertyproshortdescription.AfterLoadDFMValues;
    tblPropertyprodescription.AfterLoadDFMValues;
    tblPropertyprocount.AfterLoadDFMValues;
    tblPropertypropurchasedon.AfterLoadDFMValues;
    tblPropertypronotaryname.AfterLoadDFMValues;
    tblPropertypropurchaseprice.AfterLoadDFMValues;
    tblPropertyprocurrentvalue.AfterLoadDFMValues;
    tblPropertyproisfinanced.AfterLoadDFMValues;
    tblPropertyproisseized.AfterLoadDFMValues;
    tblPropertyproisshared.AfterLoadDFMValues;
    tblPropertyproisgone.AfterLoadDFMValues;
    tblPropertyprogoneon.AfterLoadDFMValues;
    tblPropertyprobuyerfk.AfterLoadDFMValues;
    tblPropertyproiscomplete.AfterLoadDFMValues;
    tblPropertyproremark.AfterLoadDFMValues;
    tblRevenue.AfterLoadDFMValues;
    tblRevenuerevid.AfterLoadDFMValues;
    tblRevenuerevcreatedon.AfterLoadDFMValues;
    tblRevenuerevcreatedbyfk.AfterLoadDFMValues;
    tblRevenuerevchangedon.AfterLoadDFMValues;
    tblRevenuerevchangedbyfk.AfterLoadDFMValues;
    tblRevenuerevdossierfk.AfterLoadDFMValues;
    tblRevenuerevrevenuetypefk.AfterLoadDFMValues;
    tblRevenuerevrevenuetype.AfterLoadDFMValues;
    tblRevenuerevamount.AfterLoadDFMValues;
    tblRevenuerevperiodtypefk.AfterLoadDFMValues;
    tblRevenuerevperiodtype.AfterLoadDFMValues;
    tblRevenuerevviatypefk.AfterLoadDFMValues;
    tblRevenuerevviatype.AfterLoadDFMValues;
    tblRevenuerevfromfk.AfterLoadDFMValues;
    tblRevenuerevremark.AfterLoadDFMValues;
    tblRevenuereviscomplete.AfterLoadDFMValues;
    tblRevenuerevorigin.AfterLoadDFMValues;
    tblAccountInfo.AfterLoadDFMValues;
    tblCompany.AfterLoadDFMValues;
    tblCompanycomid.AfterLoadDFMValues;
    tblCompanycomcreatedon.AfterLoadDFMValues;
    tblCompanycomcreatedbyfk.AfterLoadDFMValues;
    tblCompanycomchangedon.AfterLoadDFMValues;
    tblCompanycomchangedbyfk.AfterLoadDFMValues;
    tblCompanycomname.AfterLoadDFMValues;
    tblCompanycomstreet.AfterLoadDFMValues;
    tblCompanycomhousenr.AfterLoadDFMValues;
    tblCompanycomhousenrextra.AfterLoadDFMValues;
    tblCompanycomzip.AfterLoadDFMValues;
    tblCompanycomcity.AfterLoadDFMValues;
    tblCompanycomcityfk.AfterLoadDFMValues;
    tblCompanycomcountry.AfterLoadDFMValues;
    tblCompanycomcountryfk.AfterLoadDFMValues;
    tblCompanycomemail.AfterLoadDFMValues;
    tblCompanycombankaccount.AfterLoadDFMValues;
    tblCompanycomvat.AfterLoadDFMValues;
    tblCompanycomtelephone.AfterLoadDFMValues;
    tblCompanycomstatus.AfterLoadDFMValues;
    tblCompanycompriority.AfterLoadDFMValues;
    tblCompanycomlanguagefk.AfterLoadDFMValues;
    tblCompanycomdatabaseid.AfterLoadDFMValues;
    tblCompanyOverview.AfterLoadDFMValues;
    tblCompanyOverviewcomid.AfterLoadDFMValues;
    tblCompanyOverviewcomname.AfterLoadDFMValues;
    tblCompanyOverviewcomstreet.AfterLoadDFMValues;
    tblCompanyOverviewcomhousenr.AfterLoadDFMValues;
    tblCompanyOverviewcomzip.AfterLoadDFMValues;
    tblCompanyOverviewcomcity.AfterLoadDFMValues;
    tblCompanyOverviewcomcountry.AfterLoadDFMValues;
    tblCity.AfterLoadDFMValues;
    tblCitycitid.AfterLoadDFMValues;
    tblCitycitcreatedon.AfterLoadDFMValues;
    tblCitycitcreatedbyfk.AfterLoadDFMValues;
    tblCitycitchangedon.AfterLoadDFMValues;
    tblCitycitchangedbyfk.AfterLoadDFMValues;
    tblCitycitcountryfk.AfterLoadDFMValues;
    tblCitycitzip.AfterLoadDFMValues;
    tblCitycitname.AfterLoadDFMValues;
    tblCityOverview.AfterLoadDFMValues;
    tblCityOverviewcitID.AfterLoadDFMValues;
    tblCityOverviewcitZip.AfterLoadDFMValues;
    tblCityOverviewcitName.AfterLoadDFMValues;
    tblCityOverviewconID.AfterLoadDFMValues;
    tblCityOverviewconISO2.AfterLoadDFMValues;
    tblCityOverviewconISO3.AfterLoadDFMValues;
    tblCityOverviewconName.AfterLoadDFMValues;
    tblCountry.AfterLoadDFMValues;
    tblCountryconid.AfterLoadDFMValues;
    tblCountryconcreatedon.AfterLoadDFMValues;
    tblCountryconcreatedbyfk.AfterLoadDFMValues;
    tblCountryconchangedon.AfterLoadDFMValues;
    tblCountryconchangedbyfk.AfterLoadDFMValues;
    tblCountryconiso2.AfterLoadDFMValues;
    tblCountryconiso3.AfterLoadDFMValues;
    tblCountryconname.AfterLoadDFMValues;
    tblCountryOverview.AfterLoadDFMValues;
    tblCountryOverviewconID.AfterLoadDFMValues;
    tblCountryOverviewconISO2.AfterLoadDFMValues;
    tblCountryOverviewconISO3.AfterLoadDFMValues;
    tblCountryOverviewconName.AfterLoadDFMValues;
    tblContactAddressOverview.AfterLoadDFMValues;
    tblContactAddressOverviewctaid.AfterLoadDFMValues;
    tblContactAddressOverviewctacreatedon.AfterLoadDFMValues;
    tblContactAddressOverviewctacreatedbyfk.AfterLoadDFMValues;
    tblContactAddressOverviewctachangedon.AfterLoadDFMValues;
    tblContactAddressOverviewctachangedbyfk.AfterLoadDFMValues;
    tblContactAddressOverviewctacontactfk.AfterLoadDFMValues;
    tblContactAddressOverviewctaaddresstypefk.AfterLoadDFMValues;
    tblContactAddressOverviewctaaddresstype.AfterLoadDFMValues;
    tblContactAddressOverviewctastreet.AfterLoadDFMValues;
    tblContactAddressOverviewctastreetnr.AfterLoadDFMValues;
    tblContactAddressOverviewctacityzip.AfterLoadDFMValues;
    tblContactAddressOverviewctacityname.AfterLoadDFMValues;
    tblContactAddressOverviewctacityfk.AfterLoadDFMValues;
    tblContactAddressOverviewctaremark.AfterLoadDFMValues;
    tblContactAddressOverviewctacorrespondence.AfterLoadDFMValues;
    tblContactAddressOverviewctasecret.AfterLoadDFMValues;
    tblContactOverview.AfterLoadDFMValues;
    tblContactOverviewcntid.AfterLoadDFMValues;
    tblContactOverviewcntcreatedon.AfterLoadDFMValues;
    tblContactOverviewcntcreatedbyfk.AfterLoadDFMValues;
    tblContactOverviewcntchangedon.AfterLoadDFMValues;
    tblContactOverviewcntchangedbyfk.AfterLoadDFMValues;
    tblContactOverviewcntcompanyfk.AfterLoadDFMValues;
    tblContactOverviewcntfirstname.AfterLoadDFMValues;
    tblContactOverviewcntlastname.AfterLoadDFMValues;
    tblContactOverviewcntprofession.AfterLoadDFMValues;
    tblContactOverviewcntbirthdateon.AfterLoadDFMValues;
    tblContactOverviewcntisbirthdateunknown.AfterLoadDFMValues;
    tblContactOverviewcntgender.AfterLoadDFMValues;
    tblContactOverviewcntcityofbirthfk.AfterLoadDFMValues;
    tblContactOverviewcntcityofbirthname.AfterLoadDFMValues;
    tblContactOverviewcntcityofbirthzip.AfterLoadDFMValues;
    tblContactOverviewcntnationalityfk.AfterLoadDFMValues;
    tblContactOverviewcntnationality2.AfterLoadDFMValues;
    tblContactOverviewcntnationality.AfterLoadDFMValues;
    tblContactOverviewcntpicturefk.AfterLoadDFMValues;
    tblContactOverviewcntkbonr.AfterLoadDFMValues;
    tblContactOverviewcntremark.AfterLoadDFMValues;
    tblContactOverviewcntpersonfk.AfterLoadDFMValues;
    tblContactOverviewcntsalutation.AfterLoadDFMValues;
    tblContactOverviewcntsearchname.AfterLoadDFMValues;
    tblContactOverviewcntprefixes.AfterLoadDFMValues;
    tblContactOverviewcntfriendlytitle.AfterLoadDFMValues;
    tblContactRelationsOverview.AfterLoadDFMValues;
    tblContactRelationsOverviewctrid.AfterLoadDFMValues;
    tblContactRelationsOverviewctrcreatedon.AfterLoadDFMValues;
    tblContactRelationsOverviewctrcreatedbyfk.AfterLoadDFMValues;
    tblContactRelationsOverviewctrchangedon.AfterLoadDFMValues;
    tblContactRelationsOverviewctrchangedbyfk.AfterLoadDFMValues;
    tblContactRelationsOverviewctrfromcontactfk.AfterLoadDFMValues;
    tblContactRelationsOverviewctrtocontactfk.AfterLoadDFMValues;
    tblContactRelationsOverviewctrrelationtype.AfterLoadDFMValues;
    tblContactRelationsOverviewctrrelationtypefk.AfterLoadDFMValues;
    tblContactTelecomOverview.AfterLoadDFMValues;
    tblContactTelecomOverviewcttid.AfterLoadDFMValues;
    tblContactTelecomOverviewcttcreatedon.AfterLoadDFMValues;
    tblContactTelecomOverviewcttcreatedbyfk.AfterLoadDFMValues;
    tblContactTelecomOverviewcttchangedon.AfterLoadDFMValues;
    tblContactTelecomOverviewcttchangedbyfk.AfterLoadDFMValues;
    tblContactTelecomOverviewcttcontactfk.AfterLoadDFMValues;
    tblContactTelecomOverviewcttcontacttypefk.AfterLoadDFMValues;
    tblContactTelecomOverviewcttcontacttype.AfterLoadDFMValues;
    tblContactTelecomOverviewcttdata.AfterLoadDFMValues;
    tblContactTelecomOverviewcttremark.AfterLoadDFMValues;
    tblContactTelecomOverviewcttcorrespondence.AfterLoadDFMValues;
    dsParameters.AfterLoadDFMValues;
    dsParametersparid.AfterLoadDFMValues;
    dsParametersparcreatedon.AfterLoadDFMValues;
    dsParametersparcreatedbyfk.AfterLoadDFMValues;
    dsParametersparchangedon.AfterLoadDFMValues;
    dsParametersparchangedbyfk.AfterLoadDFMValues;
    dsParametersparparamtype.AfterLoadDFMValues;
    dsParametersparvaluetype.AfterLoadDFMValues;
    dsParametersparvaluename.AfterLoadDFMValues;
    dsParametersparvalue.AfterLoadDFMValues;
    dsParametersparextra.AfterLoadDFMValues;
    dsParametersparlanguage.AfterLoadDFMValues;
    ParameterConnection.AfterLoadDFMValues;
    CSRConnection.AfterLoadDFMValues;
  end;
end;

end.
