unit Forms.Debtmediator;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  Data.DB,
  Module.Contacts,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dataelementmapper,
  libjquery,
  lib.bootstrap,
  Forms.DossierDetail;

type
  TFrmBase = TFrmDossierDetail;

  TfrmDebtmediator = class(TFrmBase)
    procedure OnDeleteMediator(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    FDMContact: TDMContacts;
    procedure CheckDeleteButton;
    procedure ConfigureContact;
    procedure DoAllLoaded(Sender: TObject);
    procedure DoAllSaved(Sender: TObject);
  public
    { Public declarations }
    procedure MakeReadOnly; override;
    property DMContact: TDMContacts read FDMContact;
  protected procedure LoadDFMValues; override; end;

var
  frmDebtmediator: TfrmDebtmediator;

implementation

{$R *.dfm}

uses
  pas2web.dadatasethelper,
  Units.ActionUtils,
  Units.Params,
  Modules.Server,
  Units.Strings,
  Units.Contacts;

procedure TfrmDebtmediator.OnDeleteMediator(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

  procedure DoDeleteRecord;
  begin
    DMContact.DeleteDossierPerson(True);
  end;

begin
  if DMContact.ContactID > 0 then
    DefaultConfirmation.Execute('', @DoDeleteRecord);
end;

procedure TfrmDebtmediator.CheckDeleteButton;
begin
  with alForm['btnDelete'] do
  begin
    if DMContact.ContactID <= 0 then
      addClass('d-none')
    else
      removeClass('d-none')
  end;
end;

procedure TfrmDebtmediator.SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  DMContact.SaveIfValid;
end;

procedure TfrmDebtmediator.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDMContact := CreateContactModule;
end;

procedure TfrmDebtmediator.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FDMContact);
  inherited;
end;

procedure TfrmDebtmediator.ConfigureContact;
const
  RepPersonalFields: TPersonalFieldNames = ('', '', '', '', '', 'rdGender', '', 'edtKBONumber', '', '', 'edtName', '',
    '', '', '');
  RepAddressFields: TAddressFieldNames = ('edtAddress', 'edtNumber', 'edtZip', 'edtTown', '', '', '');
var
  PersonalFields: TPersonalFieldEdits;
  DossierFields: TDossierContactFieldEdits;
  AddressFields: TAddressFieldEdits;
begin
  PersonalFields.Names := RepPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names := RepAddressFields;
  AddressFields.SetFieldsFromNames;
  DossierFields.Names := ['', '', '', ''];
  DossierFields.SetFieldsFromNames;
  DossierFields.BlockNames := ['', '', '', ''];
  DMContact.PersonalFieldInfo := PersonalFields;
  DMContact.WPAddressFieldInfo := AddressFields;
  DMContact.DossierContactFieldInfo := DossierFields;
  DMContact.FormIsReadOnly := Self.FormIsReadOnly;
  DMContact.SearchButtonID := 'btnSelectMediatorContact';
  DMContact.EditContactButtonID := 'btnNewMediatorContact';
  DMContact.ContactDescription := SContactMediator;
  DMContact.OnAllLoaded := DoAllLoaded;
  DMContact.OnAllSaved := DoAllSaved;
  DMContact.OnContactChanged := @OnContactChanged;
end;

procedure TfrmDebtmediator.DoAllSaved(Sender: TObject);
begin
  dmServer.ShowOK(SMediatiorSavedOK);
  DiscardChanges := True;
end;

procedure TfrmDebtmediator.MakeReadOnly;
begin
  inherited;
  DMContact.FormIsReadOnly := Self.FormIsReadOnly;
end;

procedure TfrmDebtmediator.DoAllLoaded(Sender: TObject);
begin
  CheckDeleteButton;
end;

procedure TfrmDebtmediator.WebFormShow(Sender: TObject);
begin
  inherited;
  ConfigureContact;
  DMContact.LoadDossierPersonData(DossierID, 'WARD', [cdpDossierPerson, cdpPersonal, cdpWPaddress], [cdpDossierPerson]);
end;

procedure TfrmDebtmediator.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnDelete';
      Name := 'btnDelete';
      SetEvent(Self, 'OnExecute', 'OnDeleteMediator');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'btnNo';
      PreventDefault := False;
      StopPropagation := False;
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
