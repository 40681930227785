unit lib.filereader;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  js,
  web,
  libjquery;

type
  TJSFileReader = class;

  TJSFileReader = class
    external name 'FileReader' public result: JSValue external name 'result';
    onload: TJSEventHandler external name 'onload';

    constructor new;
    procedure readAsBinaryString(aFile: TJSObject);
  end;

implementation

end.
