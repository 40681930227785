unit Forms.Contact.Address;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper;

type
  TfrmContactAddress = class(TfrmBase)
    dsAddress: TP2WDADataset;
    eacAddress: TPW2DataElementMapper;
    procedure dsAddressAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure OnBtnSaveClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure dsAddressAfterOpen(DataSet: TDataSet);
    procedure OnFieldValidationError(Sender: TObject; Source: TElementAction; dest:
        TField; aError: TElementError);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FContactID : Int64;

    procedure onGetAddressTypeItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string; ValueType: integer; Extra: string; isLast: Boolean);
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmContactAddress: TfrmContactAddress;

implementation

{$R *.dfm}

uses
  Units.ActionUtils, Modules.Server;


procedure TfrmContactAddress.dsAddressAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);
var
  i : Integer;
  aMsg : string;
begin
  aMsg := '';
  for i := Low(Info.Records) to High(Info.Records) do
  begin
    aMsg := aMsg + #13#10 + Info.Records[i].error;
  end;

  if Trim(aMsg) <> '' then
    ShowError(aMsg)
  else
  begin
    window.location.href := '#/contact/edit/' + Inttostr(FContactID) + '/';
  end;
end;

procedure TfrmContactAddress.OnBtnSaveClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if eacAddress.ValidateActions(True) then
  begin
    if dsAddress.isEmpty then
    begin
      dsAddress.Insert;
      dsAddress.FieldByName('ctacreatedon').asDateTime := now;
      dsAddress.FieldByName('ctacreatedbyfk').asLargeInt := Server.UserID;
      dsAddress.FieldByName('ctacontactfk').asLargeInt := FContactID;
    end
    else
      dsAddress.Edit;
    dsAddress.FieldByName('ctachangedon').asDateTime := now;
    dsAddress.FieldByName('ctachangedbyfk').asLargeInt := Server.UserID;
    dsAddress.FieldByName('ctaaddresstypefk').asLargeInt := Server.GetParamId('ADTYP', alForm['edtAddressType'].value);
    dsAddress.FieldByName('ctacorrespondence').asBoolean := alForm['chkCorrespondence'].Checked;
    dsAddress.FieldByName('ctasecret').asBoolean := alForm['chkSecret'].Checked;
    dsAddress.FieldByName('ctacityfk').asLargeInt := StrToIntDef(
      document.getElementById('edtCity').getAttribute('data-cid'), 0);
    eacAddress.ElementsToDataset;
    dsAddress.Post;
    dsAddress.ApplyUpdates;
  end;
end;

procedure TfrmContactAddress.OnFieldValidationError(Sender: TObject;
  Source: TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmContactAddress.onGetAddressTypeItem(RecordID: Integer;
  Name: string; Language: Integer; ParamType, Value: string; ValueType: integer;
  Extra: string; isLast: Boolean);
begin
  alForm['edtAddressType'].InnerHTML := alForm['edtAddressType'].InnerHTML + '<option data-id="' + inttostr(RecordID) + '" value="' + Name + '">' + Value + '</option>';
end;

procedure TfrmContactAddress.dsAddressAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
  begin
    alForm['chkCorrespondence'].checked := DataSet.FieldByName('ctacorrespondence').asBoolean;
    alForm['chkSecret'].checked := DataSet.FieldByName('ctasecret').asBoolean;
    document.getElementById('edtCity').setAttribute('data-cid', DataSet.FieldByName('ctacityfk').asString);
    eacAddress.DatasetToElements;
  end;
end;


procedure TfrmContactAddress.WebFormCreate(Sender: TObject);
begin
  inherited;
  FContactID := -1;

  bindZipLookup('edtZip', 'edtCity');

  Server.GetParameterList('ADTYP', Language, @onGetAddressTypeItem);

  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsAddress.DAConnection := Server.ContactConnection;
end;

procedure TfrmContactAddress.setParams(const Params: TStrings);
begin
  if Assigned(Params) then
  begin
    jquery('#returnBack').attr('href', '#/contact/edit/' + Params.values['CONTACTID'] + '/');
    FContactID := StrToIntDef(Params.values['CONTACTID'], -1);
    dsAddress.ParamByName('CNTID').asLargeInt := FContactID;
    dsAddress.ParamByName('CTAID').asLargeInt := StrToIntDef(Params.values['ADDRESSID'], -1);
  end
  else
  begin
    dsAddress.ParamByName('CNTID').asLargeInt := -1;
    dsAddress.ParamByName('CTAID').asLargeInt := -1;
  end;
end;


procedure TfrmContactAddress.WebFormShow(Sender: TObject);
begin
  dsAddress.Load([], nil)
end;

procedure TfrmContactAddress.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsAddress := TP2WDADataset.Create(Self);
  eacAddress := TPW2DataElementMapper.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsAddress.BeforeLoadDFMValues;
  eacAddress.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'edtAddressType';
      Name := 'edtAddressType';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtStreet';
      Name := 'edtStreet';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtHouseNo';
      Name := 'edtHouseNo';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtZip';
      Name := 'edtZip';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCity';
      Name := 'edtCity';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtRemark';
      Name := 'edtRemark';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'chkCorrespondence';
      Name := 'chkCorrespondence';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'chkSecret';
      Name := 'chkSecret';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'OnBtnSaveClick');
    end;
    dsAddress.SetParentComponent(Self);
    dsAddress.Name := 'dsAddress';
    dsAddress.TableName := 'contactaddress';
    dsAddress.DAConnection := dmServer.ContactConnection;
    dsAddress.Params.Clear;
    with dsAddress.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
    end;
    with dsAddress.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CTAID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsAddress.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>ctacontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation><binaryoperation operator="Equal"><field>ctaid</field><parameter type="LargeInt">CTAID</parameter></binaryoperation></binaryoperation></where></query>';
    dsAddress.DAOptions := [];
    dsAddress.AfterOpen := dsAddressAfterOpen;
    dsAddress.AfterApplyUpdates := dsAddressAfterApplyUpdates;
    dsAddress.Left := 40;
    dsAddress.Top := 80;
    eacAddress.SetParentComponent(Self);
    eacAddress.Name := 'eacAddress';
    eacAddress.ActionLinks.Clear;
    with eacAddress.ActionLinks.Add do
    begin
      ActionName := 'edtAddressType';
      FieldName := 'ctaaddresstype';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacAddress.ActionLinks.Add do
    begin
      ActionName := 'edtStreet';
      FieldName := 'ctastreet';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacAddress.ActionLinks.Add do
    begin
      ActionName := 'edtHouseNo';
      FieldName := 'ctastreetnr';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacAddress.ActionLinks.Add do
    begin
      ActionName := 'edtZip';
      FieldName := 'ctacityzip';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacAddress.ActionLinks.Add do
    begin
      ActionName := 'edtCity';
      FieldName := 'ctacityname';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacAddress.ActionLinks.Add do
    begin
      ActionName := 'edtRemark';
      FieldName := 'ctaremark';
      Options := [];
    end;
    eacAddress.ActionList := alForm;
    eacAddress.Dataset := dsAddress;
    eacAddress.Left := 40;
    eacAddress.Top := 160;
  finally
    alForm.AfterLoadDFMValues;
    dsAddress.AfterLoadDFMValues;
    eacAddress.AfterLoadDFMValues;
  end;
end;

end.
