unit Units.ConfirmDialog;

interface

uses SysUtils, Classes, WebLib.Actions, pas2web.bootstrap;

Type
  TConfirmOKEvent = reference to procedure;

  // Confirmation dialog.
  TConfirmDialog = class(TComponent)
  Private
    FModal : TP2WBSModal;
    FTitleCaption: String;
    FOnOk : TNotifyEvent;
    FYesCaption: String;
    FNoCaption: String;
    FDialogMessage: String;
    procedure ConfigModal;
  Protected
    Procedure MaybeCreateModal;
  Public
    Constructor Create(aOwner : TComponent); override;
    Destructor Destroy; override;
    Procedure Execute(const aMessage : String = ''; aOnOk : TConfirmOKEvent = Nil; aOnCancel : TConfirmOKEvent = Nil);
    Property YesCaption : String Read FYesCaption Write FYesCaption;
    Property NoCaption : String Read FNoCaption Write FNoCaption;
    Property TitleCaption : String Read FTitleCaption Write FTitleCaption;
    Property DialogMessage : String Read FDialogMessage Write FDialogMessage;
    Property OnOK : TNotifyEvent Read FOnOK Write FOnOK;
  end;

implementation

uses Web, Units.ActionUtils, Units.Strings;

{ TConfirmDialog }

constructor TConfirmDialog.Create(aOwner: TComponent);
begin
  inherited;
  YesCaption:=comYes;
  NoCaption:=comNo;
  TitleCaption:=comConfirmation;
  DialogMessage:=comDeleteMessage;
end;

destructor TConfirmDialog.Destroy;
begin
  FreeAndNil(FModal);
  inherited;
end;

procedure TConfirmDialog.Execute(const aMessage : String = ''; aOnOk: TConfirmOKEvent = Nil; aOnCancel : TConfirmOKEvent = Nil);

  Procedure DoShow(Sender : TObject);

  Var
    Msg : String;

  begin
    Msg:=aMessage;
    if Msg='' then
      Msg:=FDialogMessage;
    FModal.Actions['Message'].Value:=Msg;
    FModal.Actions['Title'].Value:=TitleCaption;
    FModal.Actions['OK'].Value:=YesCaption;
    FModal.Actions['Cancel'].Value:=NoCaption;
  end;

  Procedure DoHide (Sender : TObject; CloseEl : TJSHTMLElement; Values : TStrings);
  begin
    if Assigned(CloseEl) and (CloseEl.ID=FModal.OKButtonName) then
      begin
      if Assigned(aOnOK) then
        aOnOK;
      If Assigned(FOnOK) then
        FOnOK(Self);
      end
    else
      if Assigned(aOnCancel) then
        aOnCancel;
  end;

begin
  MaybeCreateModal;
  Fmodal.OnShow:=@DoShow;
  Fmodal.OnHide:=@DoHide;
  Fmodal.Render;
end;

procedure TConfirmDialog.ConfigModal;

begin
  // do not localize these;
  FModal.ElementID:='mdlConfirmation';
  FModal.OKButtonName:='btnConfirmationYes';
  FModal.CancelButtonName:='btnConfirmationNo';
  // FModal.CloseButtonName:='';
  FModal.ShowOnRender:=True;
  FModal.RemoveOnHide:=True;
  FModal.BackDrop:=True;
  FModal.TemplateName:='confirm';
  FModal.ParentID:='modals';
  FModal.Actions.AddAction('Title','lblConfirmationTitle');
  FModal.Actions.AddAction('Message','lblConfirmation');
  FModal.Actions.AddAction('OK',FModal.OKButtonName,heClick);
  FModal.Actions.AddAction('Cancel',FModal.CancelButtonName,heClick);
end;

procedure TConfirmDialog.MaybeCreateModal;
begin
  If FModal=Nil then
    FModal:=TP2WBSModal.Create(Self);
  ConfigModal;
end;

end.
