unit Forms.Signon;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery;

type
  TfrmSignon = class(TfrmBase)
    procedure goBackToAccountInfo(Sender: TObject; Element:
        TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure goBackToOfficeInfo(Sender: TObject; Element:
        TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure DoRegistration(Sender: TObject; Element:
        TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure nextStepClicked(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure nextStep2Clicked(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure SelectLanguage(Sender: TObject; Element: TJSHTMLElementRecord;
      Event: TJSEventParameter);
    procedure OnClickAgreement(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnClickMarketing(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }
    procedure DoTranslate(Lang: String);
    function OfficeInfoIsValid : Boolean;
    function AccountInfoIsValid : Boolean;

    procedure OnCompanyZipChange;
    procedure CheckSubmitEnabled;
  public
    { Public declarations }
    class function NeedsLogin : Boolean; override;
  protected procedure LoadDFMValues; override; end;

var
  frmSignon: TfrmSignon;

implementation

uses
  StrUtils, Units.ActionUtils, Modules.Server, Units.Params,
  lib.formtranslation,
  Units.Logging, Units.DezqValidator;

{$R *.dfm}

procedure TfrmSignon.WebFormCreate(Sender: TObject);
var
  el: TJSElement;
begin
  inherited;
  el := document.getElementById('zip');
  el.addEventListener('keyup',@OnCompanyZipChange);
end;

function TfrmSignon.AccountInfoIsValid: Boolean;
begin
  Result := (not FieldIsEmpty('firstName'));
  Result := (not FieldIsEmpty('lastName')) AND Result;
  Result := (not FieldIsEmpty('phone')) AND Result;

  if not FieldIsEmpty('email') then
  begin
    if (not emailIsValid(Trim(alForm['email'].value))) then
    begin
      DisplayError('email', 'incorrect-value');
      Result := False;
    end;
  end
  else
    Result := False;

  if not FieldIsEmpty('username') then
  begin
    if (Length(Trim(alForm['username'].value)) < 4)
      OR (Length(Trim(alForm['username'].value)) > 20) OR
      (not UserNameIsValid(Trim(alForm['username'].value))) then
    begin
      DisplayError('username', 'incorrect-value');
      Result := False;
    end;
  end
  else
    Result := False;

  if not TDezqValidator.ValidMobileNumber(Trim(alForm['phone'].value)) then
  begin
    DisplayError('phone', 'incorrect-value');
    Result := False;
  end;

end;

procedure TfrmSignon.goBackToAccountInfo(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  jQuery('#stepAccount').closest('li').addClass('active');
  jQuery('#stepConfirmation').closest('li').removeClass('active');
  jQuery('#confirmation').removeClass('active').removeClass('d-stepper-block').addClass('dstepper-none');
  jQuery('#account').addClass('active').addClass('d-stepper-block').removeClass('dstepper-none');
end;

procedure TfrmSignon.goBackToOfficeInfo(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  jQuery('#stepOffice').closest('li').addClass('active');
  jQuery('#stepAccount').closest('li').removeClass('active');
  jQuery('#account').removeClass('active').removeClass('d-stepper-block').addClass('dstepper-none');
  jQuery('#office').addClass('active').addClass('d-stepper-block').removeClass('dstepper-none');
end;

procedure TfrmSignon.OnClickAgreement(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  CheckSubmitEnabled;
end;

procedure TfrmSignon.OnClickMarketing(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  CheckSubmitEnabled;
end;

procedure TfrmSignon.CheckSubmitEnabled;

Var
  agreement, marketing : boolean;

begin
  agreement:=TJSHTMLInputElement(alForm['agreement'].ElementHandle).Checked;
  marketing:=TJSHTMLInputElement(alForm['marketing'].ElementHandle).Checked;
  TJSHTMLButtonElement(alForm['registerBtn'].ElementHandle).Disabled:=Not (Agreement and Marketing);
end;

procedure TfrmSignon.DoRegistration(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
var
  params : TSignonParams;

  procedure OnRegistrationResult(aResult : Boolean; aMessage: string);
  begin
    if aResult then
    begin
      SetElementVisible('successBlock', true);
      SetElementVisible('mainBlock', false);
    end
    else
    begin
      SetElementVisible('errorBlock', true);
      jQuery('#errorMessage').text(aMessage);
    end;
  end;
begin
  //ok lets just save all the staff
  SetElementVisible('errorBlock', false);
  params := TSignonParams.Create;
  try
    params.OfficeName := Trim(alForm['officeName'].value);
    params.Address := Trim(alForm['address'].value);
    params.AddressExtra := Trim(alForm['address2'].value);
    params.HouseNo := Trim(alForm['houseno'].value);
    params.Zip := Trim(alForm['zip'].value);
    params.Town := Trim(alForm['town'].value);
    params.OfficeEmail := Trim(alForm['officeEmail'].value);
    params.VATno := Trim(alForm['vatno'].value);
    params.IBANNo := Trim(alForm['bankno'].value);
    params.OfficePhone := Trim(alForm['officePhone'].value);
    params.FirstName := Trim(alForm['firstName'].value);
    params.LastName := Trim(alForm['lastName'].value);
    params.Email := Trim(alForm['email'].value);
    params.Phone := Trim(alForm['phone'].value);
    params.UserName := Trim(alForm['username'].value);
    Server.DoSignOn(params, @OnRegistrationResult);
  finally
    params.Free;
  end;
end;


procedure TfrmSignon.DoTranslate(Lang: String);
begin
  FormTranslator.Language := Lang;
  FormTranslator.TranslateForm(Self);
end;

class function TfrmSignon.NeedsLogin: Boolean;
begin
  Result:=False;
end;

procedure TfrmSignon.nextStep2Clicked(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);

  procedure phoneCheckResult(aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
      DisplayError('phone', 'incorrect-value')
    else
    begin
      jQuery('#stepAccount').closest('li').removeClass('active').removeClass('error').addClass('success');
      jQuery('#stepConfirmation').closest('li').addClass('active');
      jQuery('#account').removeClass('active').removeClass('d-stepper-block').addClass('dstepper-none');
      jQuery('#confirmation').addClass('active').addClass('d-stepper-block').removeClass('dstepper-none');

      if jQuery('#agreement').prop('checked') <> 'checked' then
        jQuery('#register-submit').prop('disabled','false')
      else
        jQuery('#register-submit').prop('disabled','true');
    end;
  end;
begin
  ClearFormErrors;
  if AccountInfoIsValid then
  begin
     Server.isPhoneNumberValid(Trim(alForm['phone'].value), @phoneCheckResult)
  end
  else
  begin
    jQuery('#stepAccount').closest('li').removeClass('success');
  end;
end;

procedure TfrmSignon.nextStepClicked(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
var
  ResultSumup : Boolean;

  procedure phoneCheckResult(aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
      DisplayError('officePhone', 'incorrect-value')
    else
    begin
      if ResultSumup then
      begin
       jQuery('#stepOffice').closest('li').removeClass('active').removeClass('error').addClass('success');
       jQuery('#stepAccount').closest('li').addClass('active');
       jQuery('#office').removeClass('active').removeClass('d-stepper-block').addClass('dstepper-none');
       jQuery('#account').addClass('active').addClass('d-stepper-block').removeClass('dstepper-none');
      end;
    end;
  end;

  procedure IBANCheckResult(aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
    begin
      DisplayError('bankno', 'incorrect-value');
      ResultSumup := False;
      Server.isPhoneNumberValid(Trim(alForm['officePhone'].value), @phoneCheckResult);
    end
    else
    begin
      Server.isPhoneNumberValid(Trim(alForm['officePhone'].value), @phoneCheckResult);
    end;
  end;

  procedure checkIBAN;
  begin
    if Trim(alForm['bankno'].value) <> '' then
    begin
      Server.isIBANValid(Trim(alForm['bankno'].value), @IBANCheckResult);
    end
    else
      Server.isPhoneNumberValid(Trim(alForm['officePhone'].value), @phoneCheckResult);
  end;

  procedure VATNumberCheckResult(aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
    begin
      DisplayError('vatno', 'incorrect-value');
      ResultSumup := False;
      checkIBAN;
    end
    else
    begin
      checkIBAN;
    end;
  end;

begin
  ClearFormErrors;
  if OfficeInfoIsValid then
  begin
     ResultSumup := True;
     Server.isVATNumberValid(Trim(alForm['vatno'].value), @VATNumberCheckResult);
  end
  else
  begin
    jQuery('#stepOffice').closest('li').removeClass('success');
  end;
end;

function TfrmSignon.OfficeInfoIsValid: Boolean;

begin
  Result := (not FieldIsEmpty('officeName'));
  Result := (not FieldIsEmpty('address')) and Result;
  Result := (not FieldIsEmpty('houseno')) and Result;
  Result := (not FieldIsEmpty('town')) and Result;
  Result := (not FieldIsEmpty('officePhone')) and Result;

  if (not FieldIsEmpty('zip')) and (not zipCodeIsValid(Trim(alForm['zip'].value))) then
  begin
    DisplayError('zip', 'incorrect-value');
    Result := False;
  end;

  if (not FieldIsEmpty('officeEmail')) AND (not emailIsValid(Trim(alForm['officeEmail'].value))) then
  begin
    DisplayError('officeEmail', 'incorrect-value');
    Result := False;
  end;

  if (StringIsNumeric(Trim(alForm['vatno'].value))) AND (Length(Trim(alForm['vatno'].value)) = 9) then
    alForm['vatno'].value := '0' + Trim(alForm['vatno'].value);

  if (not FieldIsEmpty('vatno')) AND
    ((Length(Trim(alForm['vatno'].value)) <> 10) OR
    (not StringIsNumeric(Trim(alForm['vatno'].value)))) then
  begin
    DisplayError('vatno', 'incorrect-value');
    Result := False;
  end;
end;


procedure TfrmSignon.OnCompanyZipChange;
var
  zip : string;

  procedure onGetResult(aResult : String; anID: int64);
  begin
     alForm['town'].value := aResult;
     Log.Log(ltDebug,ClassName,'OnGetResult','result: %s', [aResult]);
  end;

begin
  zip := Trim(alForm['zip'].value);
  Log.Log(ltDebug,ClassName,'OnCompanyZipChange','Zip: %s', [Zip]);
  if (Length(zip) >= 4) AND (Length(zip) < 6) AND zipCodeIsValid(zip)  then
  begin
    Server.getCityFromZip(zip, @onGetResult);
  end;
end;

procedure TfrmSignon.SelectLanguage(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  sLang: string;
begin
  sLang := (Sender as TElementAction).ID;
  sLang := LowerCase(Copy(sLang, Length(sLang)-1, 2));
  case IndexText(sLang, ['nl', 'fr', 'en']) of
    0, 1, 2:
      begin
        Server.SetLocalData(KeyLanguage,sLang);
        Server.LoginLanguage := sLang;
        DoTranslate(sLang);
      end;
  end;

end;

procedure TfrmSignon.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'officeName';
      Name := 'officeName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'address';
      Name := 'address';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'address2';
      Name := 'address2';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'houseno';
      Name := 'houseno';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'zip';
      Name := 'zip';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'town';
      Name := 'town';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'officeEmail';
      Name := 'officeEmail';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'vatno';
      Name := 'vatno';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'bankno';
      Name := 'bankno';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'officePhone';
      Name := 'officePhone';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'nextStep';
      Name := 'nextStep';
      SetEvent(Self, 'OnExecute', 'nextStepClicked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'officeInfo';
      Name := 'officeInfo';
      SetEvent(Self, 'OnExecute', 'goBackToOfficeInfo');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'firstName';
      Name := 'firstName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'lastName';
      Name := 'lastName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'email';
      Name := 'email';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'phone';
      Name := 'phone';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'username';
      Name := 'username';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'nextStep2';
      Name := 'nextStep2';
      SetEvent(Self, 'OnExecute', 'nextStep2Clicked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'accountInfo';
      Name := 'accountInfo';
      SetEvent(Self, 'OnExecute', 'goBackToAccountInfo');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'registerBtn';
      Name := 'registerBtn';
      SetEvent(Self, 'OnExecute', 'DoRegistration');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btn-lang-nl';
      Name := 'btnDutch';
      SetEvent(Self, 'OnExecute', 'SelectLanguage');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btn-lang-fr';
      Name := 'btnFrench';
      SetEvent(Self, 'OnExecute', 'SelectLanguage');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btn-lang-en';
      Name := 'btnEnglish';
      SetEvent(Self, 'OnExecute', 'SelectLanguage');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'marketing';
      Name := 'marketing';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnClickMarketing');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'agreement';
      Name := 'agreement';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnClickAgreement');
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
