unit Forms.CSR.SettlementPlanList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, Units.Types, pas2web.datatables, pas2web.dadatasethelper,
  Forms.DossierDetail;

type
  TFrmBase = TFrmDossierDetail;
  TfrmSettlementPlanList = class(TfrmBase)
    dcSettlements: TP2WDatatable;
    dsSettlements: TP2WDADataset;
    dsSettlementssepvalidfrom: TDateTimeField;
    dsSettlementssepvalidtill: TDateTimeField;
    dsSettlementssepstatus: TIntegerField;
    dsSettlementssepneedsapproval: TBooleanField;
    dsSettlementssepcourtimposed: TBooleanField;
    dsSettlementssepdescription: TStringField;
    dsSettlementssepid: TLargeintField;
    dsSettlementssepdossierfk: TLargeintField;
    procedure dcSettlementsColumns2GetValue(Sender: TObject; Fields:
        TP2WDataTableFieldMap; out aOutput: string);
    procedure dsSettlementsAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FDeleteID : Int64;
    FDoDelete : Boolean;
    FRoute : string;
    function LocateActiveSetlement: Boolean;
    procedure ConfirmDeleteSettlement;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
    procedure DoDelete(const SettlementId: Int64);
  protected procedure LoadDFMValues; override; end;

var
  frmSettlementPlanList: TfrmSettlementPlanList;

implementation

{$R *.dfm}

Uses
  Modules.Server, Units.ActionUtils, lib.bootstrap, 
  libjquery, System.DateUtils;

procedure TfrmSettlementPlanList.ConfirmDeleteSettlement;

  Procedure DoDeleteRecord;
    procedure Response(aSuccess: Boolean; anError: String);
    begin
      if aSuccess then
        window.location.href := FRoute
      else
        Server.ShowError(anError);
    end;
  begin
    Server.DeleteSettlementPlan(FDeleteID, @Response);
  end;

  Procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;

begin
  FDoDelete := False;
  DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
end;

procedure TfrmSettlementPlanList.dcSettlementsColumns2GetValue(Sender: TObject;
    Fields: TP2WDataTableFieldMap; out aOutput: string);
var
  status : TSettlementPlanStatus;
begin
  status.AsInteger := StrToIntDef(string(Fields.GetValueByName('sepstatus')), 0);
  aOutput := status.AsDisplayString;
end;

procedure TfrmSettlementPlanList.DoDelete(const SettlementId: Int64);
begin
  FDeleteID := SettlementId;
  if dsSettlements.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteSettlement;
end;

function TfrmSettlementPlanList.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmSettlementPlanList.dsSettlementsAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
  statement: string;
begin
  aResult := dsSettlements.GetNewRows(False);
  dcSettlements.Data := aResult;
  dcSettlements.RenderTranslated;

  //setup hyperlink label
  if LocateActiveSetlement then
  begin
    statement := Format(jQuery('#activePlan').find('a').text, [
      FormatDateTime('DD/MM/YYYY', dsSettlements.FieldByName('sepvalidfrom').asDateTime),
      FormatDateTime('DD/MM/YYYY', dsSettlements.FieldByName('sepvalidtill').asDateTime)
    ]) ;
    jQuery('#activePlan').css('display', '')
      .find('a').text(statement)
      .attr('href', '#/CSR/' + inttostr(DossierID) + '/settlementplan/edit/' +
      dsSettlements.FieldByName('sepid').asString);
  end
  else
    jQuery('#activePlan').css('display', 'none');
  if FDoDelete then
    ConfirmDeleteSettlement;
end;

function TfrmSettlementPlanList.LocateActiveSetlement: Boolean;
begin
  Result:= False;
  dsSettlements.First;
  while not dsSettlements.eof do
  begin
    if dsSettlements.FieldByName('sepstatus').asInteger = 6 then
    begin
      if (dsSettlements.FieldByName('sepvalidfrom').asDateTime <= now) and
        (now <= dsSettlements.FieldByName('sepvalidtill').asDateTime) then
      begin
        Result := True;
        break;
      end;
    end;
    dsSettlements.Next;
  end;
end;

procedure TfrmSettlementPlanList.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDoDelete := False;
  Server.CSRConnection.Message.ClientID := Server.ClientID;
  dsSettlements.DAConnection := Server.CSRConnection;
end;

procedure TfrmSettlementPlanList.setParams(const Params: TStrings);
begin
  inherited;
  FRoute := DossierURL('settlementplan');
  jQuery('#btnAdd').attr('href',DossierURL('/settlementplan/new'));
  dsSettlements.ParamByName('DOSSIERID').asInteger := DossierID;
  dsSettlements.Load([], nil);
end;

procedure TfrmSettlementPlanList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dcSettlements := TP2WDatatable.Create(Self);
  dsSettlements := TP2WDADataset.Create(Self);
  dsSettlementssepvalidfrom := TDateTimeField.Create(Self);
  dsSettlementssepvalidtill := TDateTimeField.Create(Self);
  dsSettlementssepstatus := TIntegerField.Create(Self);
  dsSettlementssepneedsapproval := TBooleanField.Create(Self);
  dsSettlementssepcourtimposed := TBooleanField.Create(Self);
  dsSettlementssepdescription := TStringField.Create(Self);
  dsSettlementssepid := TLargeintField.Create(Self);
  dsSettlementssepdossierfk := TLargeintField.Create(Self);

  alForm.BeforeLoadDFMValues;
  dcSettlements.BeforeLoadDFMValues;
  dsSettlements.BeforeLoadDFMValues;
  dsSettlementssepvalidfrom.BeforeLoadDFMValues;
  dsSettlementssepvalidtill.BeforeLoadDFMValues;
  dsSettlementssepstatus.BeforeLoadDFMValues;
  dsSettlementssepneedsapproval.BeforeLoadDFMValues;
  dsSettlementssepcourtimposed.BeforeLoadDFMValues;
  dsSettlementssepdescription.BeforeLoadDFMValues;
  dsSettlementssepid.BeforeLoadDFMValues;
  dsSettlementssepdossierfk.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'Yes';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'No';
    end;
    dcSettlements.SetParentComponent(Self);
    dcSettlements.Name := 'dcSettlements';
    dcSettlements.Columns.Clear;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepvalidfrom';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepvalidtill';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepstatus';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcSettlementsColumns2GetValue');
      CSSClassName := 'text-center';
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepneedsapproval';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      CSSClassName := 'text-center';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'disabled';
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepcourtimposed';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      CSSClassName := 'text-center';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'disabled';
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepdescription';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{sepdossierfk}}/settlementplan/edit/{{sepid}}';
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{sepdossierfk}}/settlementplan/delete/{{sepid}}';
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'sepid';
      RenderMode := rmButton;
      ButtonType := btCustom;
      ButtonIconClass := 'fa fa-copy';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{sepdossierfk}}/settlementplan/copy/{{sepid}}';
    end;
    dcSettlements.DataSet := dsSettlements;
    dcSettlements.Language := lEnglish;
    dcSettlements.IsResponsive := True;
    dcSettlements.GridID := 'grdSP';
    dcSettlements.UseFieldIndex := True;
    dcSettlements.ShowSearch := False;
    dcSettlements.ShowNumberOfEntries := False;
    dcSettlements.ShowEntriesInfo := False;
    dcSettlements.Paginate := True;
    dcSettlements.DisplayReadOnly := False;
    dcSettlements.CalculateTableWidth := True;
    dcSettlements.Left := 32;
    dcSettlements.Top := 184;
    dsSettlements.SetParentComponent(Self);
    dsSettlements.Name := 'dsSettlements';
    dsSettlements.TableName := 'settlementplan';
    dsSettlements.Params.Clear;
    with dsSettlements.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsSettlements.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>sepdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsSettlements.DAOptions := [doRefreshAllFields];
    dsSettlements.AfterOpen := dsSettlementsAfterOpen;
    dsSettlements.Left := 32;
    dsSettlements.Top := 112;
    dsSettlementssepvalidfrom.SetParentComponent(dsSettlements);
    dsSettlementssepvalidfrom.Name := 'dsSettlementssepvalidfrom';
    dsSettlementssepvalidfrom.FieldName := 'sepvalidfrom';
    dsSettlementssepvalidtill.SetParentComponent(dsSettlements);
    dsSettlementssepvalidtill.Name := 'dsSettlementssepvalidtill';
    dsSettlementssepvalidtill.FieldName := 'sepvalidtill';
    dsSettlementssepstatus.SetParentComponent(dsSettlements);
    dsSettlementssepstatus.Name := 'dsSettlementssepstatus';
    dsSettlementssepstatus.FieldName := 'sepstatus';
    dsSettlementssepneedsapproval.SetParentComponent(dsSettlements);
    dsSettlementssepneedsapproval.Name := 'dsSettlementssepneedsapproval';
    dsSettlementssepneedsapproval.FieldName := 'sepneedsapproval';
    dsSettlementssepcourtimposed.SetParentComponent(dsSettlements);
    dsSettlementssepcourtimposed.Name := 'dsSettlementssepcourtimposed';
    dsSettlementssepcourtimposed.FieldName := 'sepcourtimposed';
    dsSettlementssepdescription.SetParentComponent(dsSettlements);
    dsSettlementssepdescription.Name := 'dsSettlementssepdescription';
    dsSettlementssepdescription.FieldName := 'sepdescription';
    dsSettlementssepdescription.Size := 127;
    dsSettlementssepid.SetParentComponent(dsSettlements);
    dsSettlementssepid.Name := 'dsSettlementssepid';
    dsSettlementssepid.FieldName := 'sepid';
    dsSettlementssepdossierfk.SetParentComponent(dsSettlements);
    dsSettlementssepdossierfk.Name := 'dsSettlementssepdossierfk';
    dsSettlementssepdossierfk.FieldName := 'sepdossierfk';
  finally
    alForm.AfterLoadDFMValues;
    dcSettlements.AfterLoadDFMValues;
    dsSettlements.AfterLoadDFMValues;
    dsSettlementssepvalidfrom.AfterLoadDFMValues;
    dsSettlementssepvalidtill.AfterLoadDFMValues;
    dsSettlementssepstatus.AfterLoadDFMValues;
    dsSettlementssepneedsapproval.AfterLoadDFMValues;
    dsSettlementssepcourtimposed.AfterLoadDFMValues;
    dsSettlementssepdescription.AfterLoadDFMValues;
    dsSettlementssepid.AfterLoadDFMValues;
    dsSettlementssepdossierfk.AfterLoadDFMValues;
  end;
end;

end.