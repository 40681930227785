unit Forms.Representative;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB, Module.Contacts,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper, libjquery, lib.bootstrap,
  Forms.DossierDetail;

type
  TFrmBase = TFrmDossierDetail;
  TfrmRepresentative = class(TfrmBase)
    procedure OnDeleteMediator(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    FDMContact : TDMContacts;
    procedure CheckDeleteButton;
    procedure ConfigureContact;
    procedure DoAllLoaded(Sender: TObject);
    procedure DoAllSaved(Sender: TObject);
  public
    { Public declarations }
    procedure MakeReadOnly; override;
    Property DMContact : TDMContacts Read FDMContact;
  protected procedure LoadDFMValues; override; end;

var
  frmRepresentative: TfrmRepresentative;

implementation

{$R *.dfm}

uses
  pas2web.dadatasethelper, Units.ActionUtils, Units.Params, Modules.Server, Units.Strings, Units.Contacts;

procedure TfrmRepresentative.OnDeleteMediator(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

  Procedure DoDeleteRecord;

  begin
    dmContact.DeleteDossierPerson(True);
  end;


var
  aName,aMsg : string;

begin
  if dmContact.ContactID>0 then
  begin
    aMsg := JQuery('#msgRepDelete').text();
    With dmContact.dsDossierPerson do
      aName:=FieldByName('cntlastname').asString + ' ' + FieldByName('cntfirstname').asString;
    aMsg := Format(aMsg, [aName]);
    DefaultConfirmation.Execute(aMsg,@DoDeleteRecord);
  end;
end;


procedure TfrmRepresentative.CheckDeleteButton;

begin
  With alForm['btnDelete'] do
    begin
    if dmContact.ContactID<=0 then
      addClass('d-none')
    else
      removeClass('d-none')
    end;
end;

procedure TfrmRepresentative.SaveBtnClick(Sender: TObject; Element:  TJSHTMLElementRecord; Event: TJSEventParameter);

begin
  dmContact.SaveIfValid;
end;

procedure TfrmRepresentative.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDMContact:=CreateContactModule;
end;

procedure TfrmRepresentative.ConfigureContact;

Const
  RepPersonalFields : TPersonalFieldNames = (
    '','','','', '',
    '','','','','','edtName',
    '','','','');


  RepAddressFields : TAddressFieldNames = (
    'edtAddress','edtNumber','edtZip','edtTown','','',''
  );

Var
  PersonalFields : TPersonalFieldEdits;
  DossierFields : TDossierContactFieldEdits;
  AddressFields : TAddressFieldEdits;

begin
  PersonalFields.Names:=RepPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names:=RepAddressFields;
  AddressFields.SetFieldsFromNames;
  DossierFields.Names:=['','','',''];
  DossierFields.SetFieldsFromNames;
  DossierFields.BlockNames:=['','','',''];
  dmContact.PersonalFieldInfo:=PersonalFields;
  dmContact.WPAddressFieldInfo:=AddressFields;
  dmContact.DossierContactFieldInfo:=DossierFields;
  dmContact.FormIsReadOnly:=Self.FormIsReadOnly;
  dmContact.SearchButtonID:='btnSelectRepresentativeContact';
  dmContact.EditContactButtonID:='btnEditRepresentativeContact';
  dmContact.ContactDescription:=SContactRepresentative;
  dmContact.OnAllLoaded:=DoAllLoaded;
  dmContact.OnAllSaved:=DoAllSaved;
  dmContact.OnContactChanged:=@OnContactChanged;
end;

procedure TfrmRepresentative.DoAllLoaded(Sender: TObject);

begin
  CheckDeleteButton;
end;

procedure TfrmRepresentative.DoAllSaved(Sender: TObject);
begin
  dmServer.ShowOK(SRepresentativeSavedOK);
  DiscardChanges := True;
end;

procedure TfrmRepresentative.MakeReadOnly;
begin
  inherited;
  dmContact.FormIsReadOnly:=Self.FormIsReadOnly;
end;

procedure TfrmRepresentative.WebFormDestroy(Sender: TObject);
begin
  inherited;
  FreeAndNil(FDMContact);
end;


procedure TfrmRepresentative.WebFormShow(Sender: TObject);
begin
  inherited;
  ConfigureContact;
  dmContact.LoadDossierPersonData(DossierID,'REPRES',[cdpDossierPerson,cdpPersonal,cdpWPaddress],[cdpDossierPerson]);
end;

procedure TfrmRepresentative.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnDelete';
      Name := 'btnDelete';
      SetEvent(Self, 'OnExecute', 'OnDeleteMediator');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'btnNo';
      PreventDefault := False;
      StopPropagation := False;
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
