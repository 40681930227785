unit Units.Templates;

{$mode objfpc}

interface

uses
  Classes, SysUtils, rtl.TemplateLoader;

type
  TTemplateNotification = Record
    Name : string;
    Event  : TTemplateNotifyEvent;
  end;
  TTemplateNotificationDynArray = Array of TTemplateNotification;

  { TTemplateManager }

  TTemplateManager = Class(TObject)
  Private
    FNotifications : TTemplateNotificationDynArray;
    procedure DoLoad(Sender: TObject; const aTemplate: String);
    function IndexOfTemplateEvent(aName: String): Integer;
  Public
    Procedure SetupTemplates(BaseDir : String = '');
    Procedure ExecuteIfTemplate(aName : String; aEvent : TTemplateNotifyEvent);
  end;

Function TemplateManager : TTemplateManager;

implementation

Procedure TTemplateManager.ExecuteIfTemplate(aName : String; aEvent : TTemplateNotifyEvent);

Var
  N : TTemplateNotification;

begin
  if GlobalTemplates.Templates[aName]<>'' then
    aEvent(GlobalTemplates,aName)
  else
     begin
     N.Name:=aname;
     N.Event:=aEvent;
     FNotifications:=Concat(FNotifications,[N]);
     end;
end;

Function TTemplateManager.IndexOfTemplateEvent (aName : String) : Integer;

begin
  Result:=Length(FNotifications)-1;
  While (Result>=0) and not SameText(FNotifications[Result].Name,aName) do
    Dec(Result);
end;

procedure TTemplateManager.DoLoad(Sender: TObject; const aTemplate: String);


Var
  Idx : Integer;

begin
  Idx:=IndexOfTemplateEvent(aTemplate);
  if Idx<>-1 then
    begin
    FNotifications[Idx].Event(Sender,aTemplate);
    Delete(FNotifications,Idx,1);
    end;
end;

procedure TTemplateManager.SetupTemplates(BaseDir : String = '');


begin
  if BaseDir<>'' then
    begin
    if (BaseDir[Length(BaseDir)]<>'/') then
      BaseDir:=BaseDir+'/'
    end;
  GlobalTemplates.BaseURL:=BaseDir+'Templates/';
  GlobalTemplates.OnLoad:=@DoLoad;
  // GlobalTemplates.LoadTemplates(ToLoad);
end;

Var
  _Manager: TTemplateManager;

function TemplateManager: TTemplateManager;
begin
  if _Manager=Nil then
    _Manager:=TTemplateManager.Create;
  Result:=_Manager;
end;

end.

