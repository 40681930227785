unit Forms.Family;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  Units.Types,
  pas2web.datatables,
  pas2web.dadatasethelper,
  pas2web.dataelementmapper,
  Units.Params,
  lib.bootstrap,
  Units.Contacts,
  Module.Contacts,
  Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;

  TfrmFamily = class(TfrmBase)
    dsChild: TP2WDADataset;
    dcChild: TP2WDatatable;
    dsHouse: TP2WDADataset;
    dcHouse: TP2WDatatable;
    procedure dsChildAfterOpen(DataSet: TDataSet);
    procedure dsHouseAfterOpen(DataSet: TDataSet);
    procedure SaveSpouseClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure OnFirstTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnSecondTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnThirdTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnGenderGetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure OnGetPersonTypeGetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure deleteAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure OnChildDeleteConfirm(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnDeleteCancel(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnInmateDeleteConfirm(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    FDoDeleteChild: Boolean;
    FDoDeleteInmate: Boolean;
    FDeleteID: NativeInt;
    FRoute: string;
    FExPartnerContact: TDMContacts;
    procedure ConfirmDeleteChild;
    procedure ConfirmDeleteInmate;
    procedure ConfigureExpartnerContact;
    procedure DoExPartnerSaved(Sender: TObject);
    { Private declarations }
  public
    procedure MakeReadOnly; override;
    class function NeedsParams: Boolean; override;
    procedure setParams(const Params: TStrings); override;
    procedure DoDeleteChild(const ChildId: NativeInt);
    procedure DoDeleteInmate(const InmateId: NativeInt);
    property ExPartnerContact: TDMContacts read FExPartnerContact;
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmFamily: TfrmFamily;

implementation

uses
  Units.ActionUtils,
  Modules.Server,
  Units.DADatasetUtils,
  Units.strings,
  Units.HTMLUtils;

{$R *.dfm}
{ TfrmFamily }

procedure TfrmFamily.ConfirmDeleteChild;

  procedure DoDeleteRecord;
  begin
    if Locate(dsChild, 'docid', FDeleteID) then
    begin
      dsChild.Delete;
      dsChild.ApplyUpdates;
    end;
  end;

  procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;

begin
  FDoDeleteChild := False;
  if Locate(dsChild, 'docid', FDeleteID) then
    DefaultConfirmation.Execute('', @DoDeleteRecord, @DoCancelDelete);
end;

procedure TfrmFamily.ConfirmDeleteInmate;

  procedure DoDeleteRecord;
  begin
    if Locate(dsHouse, 'docid', FDeleteID) then
    begin
      dsHouse.Delete;
      dsHouse.ApplyUpdates;
    end;
  end;

  procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;

begin
  FDoDeleteInmate := False;
  if Locate(dsHouse, 'docid', FDeleteID) then
    DefaultConfirmation.Execute('', @DoDeleteRecord, @DoCancelDelete);
end;

procedure TfrmFamily.OnGenderGetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('GENDER', string(Fields.GetValueByName('cntgender')));
end;

procedure TfrmFamily.OnGetPersonTypeGetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('PERSTYP', string(Fields.GetValueByName('docpersontype')));
end;

procedure TfrmFamily.DoDeleteChild(const ChildId: NativeInt);
begin
  FDeleteID := ChildId;
  if dsChild.State in [dsInactive] then
    FDoDeleteChild := True
  else
    ConfirmDeleteChild;
end;

procedure TfrmFamily.DoDeleteInmate(const InmateId: NativeInt);
begin
  FDeleteID := InmateId;
  if dsHouse.State in [dsInactive] then
    FDoDeleteInmate := True
  else
    ConfirmDeleteInmate;
end;

procedure TfrmFamily.deleteAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  CheckResolveResults(Info, FRoute);
end;

procedure TfrmFamily.dsChildAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsChild.GetNewRows(False);
  dcChild.Data := aResult;
  dcChild.RenderTranslated;
  if FDoDeleteChild then
    ConfirmDeleteChild;
end;

procedure TfrmFamily.dsHouseAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsHouse.GetNewRows(False);
  dcHouse.Data := aResult;
  dcHouse.RenderTranslated;
  if FDoDeleteInmate then
    ConfirmDeleteInmate;
end;

procedure TfrmFamily.MakeReadOnly;
begin
  inherited;
  ExPartnerContact.FormIsReadOnly := Self.FormIsReadOnly;
end;

class function TfrmFamily.NeedsParams: Boolean;
begin
  Result := True;
end;

procedure TfrmFamily.OnDeleteCancel(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  window.location.href := FRoute;
end;

procedure TfrmFamily.OnChildDeleteConfirm(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if Locate(dsChild, 'docid', FDeleteID) then
  begin
    dsChild.Delete;
    dsChild.ApplyUpdates;
  end;
end;

procedure TfrmFamily.OnFirstTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Family', '1');
end;

procedure TfrmFamily.OnInmateDeleteConfirm(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if Locate(dsHouse, 'docid', FDeleteID) then
  begin
    dsHouse.Delete;
    dsHouse.ApplyUpdates;
  end;
end;

procedure TfrmFamily.OnSecondTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Family', '2');
end;

procedure TfrmFamily.OnThirdTabClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Family', '3');
end;

procedure TfrmFamily.SaveSpouseClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if (ExPartnerContact.ContactID > 0) and ExPartnerContact.IsPartEmpty(cdpPersonal) then
    ExPartnerContact.DeleteDossierPerson(True)
  else
    ExPartnerContact.SaveIfValid;
end;

procedure TfrmFamily.WebFormCreate(Sender: TObject);
begin
  inherited;
  FExPartnerContact := CreateContactModule;
  FExPartnerContact.AllowedContactType := actPerson;
  FDoDeleteChild := False;
  FDoDeleteInmate := False;
  FDeleteID := -1;

  // get selected tab
  if Server.GetLocalData('Family') = '3' then
  begin
    jQuery('a[href="#first"]').removeClass('active');
    jQuery('a[href="#second"]').removeClass('active');
    jQuery('a[href="#third"]').addClass('active');
    jQuery('#first').removeClass('show');
    jQuery('#first').removeClass('active');
    jQuery('#second').removeClass('show');
    jQuery('#second').removeClass('active');
    jQuery('#third').addClass('show');
    jQuery('#third').addClass('active');
  end
  else
    if Server.GetLocalData('Family') = '2' then
    begin
      jQuery('a[href="#first"]').removeClass('active');
      jQuery('a[href="#third"]').removeClass('active');
      jQuery('a[href="#second"]').addClass('active');
      jQuery('#first').removeClass('show');
      jQuery('#first').removeClass('active');
      jQuery('#third').removeClass('show');
      jQuery('#third').removeClass('active');
      jQuery('#second').addClass('show');
      jQuery('#second').addClass('active');
    end
    else
    begin
      jQuery('a[href="#second"]').removeClass('active');
      jQuery('a[href="#third"]').removeClass('active');
      jQuery('a[href="#first"]').addClass('active');
      jQuery('#second').removeClass('show');
      jQuery('#second').removeClass('active');
      jQuery('#third').removeClass('show');
      jQuery('#third').removeClass('active');
      jQuery('#first').addClass('show');
      jQuery('#first').addClass('active');
    end;
  Server.PetitionConnection.Message.ClientID := Server.ClientID;
  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsChild.DAConnection := DossierConnection;
  dsHouse.DAConnection := DossierConnection;
  BindOnChangeEvent;
end;

procedure TfrmFamily.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FExPartnerContact);
  inherited;
end;

procedure TfrmFamily.ConfigureExpartnerContact;
const
  PartnerPersonalFields: TPersonalFieldNames = ('edtName', 'edtFirstname', 'ddtBirth', '', '', 'rdGender', '', '', '',
    '', '', '', '', '', 'mmRemark');
  PartnerIDFields: TContactIDFieldNames = ('edtNationalNumber', '', '', '', '', '', '');
  PartnerAddressFields: TAddressFieldNames = ('edtAddress', 'edtNumber', 'edtZip', 'edtTown', '', '', '');
  PartnerDossierFields: TDossierContactFieldNames = ('', '', '', '');
  PartnerDossierBlocks: TDossierContactFieldNames = ('', '', '', '');

  procedure HandleSearchResult(const SelectedContact: TContactSearchResult; var aAllow: Boolean);
  begin
    aAllow := (SelectedContact.Gender <> 'C');
    if not aAllow then
      dmServer.ShowError(SErrPartnerCanNotBeCompany);
  end;

var
  PersonalFields: TPersonalFieldEdits;
  DossierFields: TDossierContactFieldEdits;
  AddressFields: TAddressFieldEdits;
  IDFields: TContactIDFieldEdits;
begin
  PersonalFields.Names := PartnerPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names := PartnerAddressFields;
  AddressFields.SetFieldsFromNames;
  IDFields.Names := PartnerIDFields;
  IDFields.SetFieldsFromNames;
  DossierFields.Names := PartnerDossierFields;
  DossierFields.SetFieldsFromNames;
  DossierFields.BlockNames := PartnerDossierBlocks;
  // Pass on to partnercontact...
  ExPartnerContact.PersonalFieldInfo := PersonalFields;
  ExPartnerContact.WPAddressFieldInfo := AddressFields;
  ExPartnerContact.OnSearchResultEx := @HandleSearchResult;
  ExPartnerContact.DossierContactFieldInfo := DossierFields;
  ExPartnerContact.ContactIDFieldInfo := IDFields;
  ExPartnerContact.FormIsReadOnly := Self.FormIsReadOnly;
  ExPartnerContact.SearchButtonID := 'btnSelectSpouseContact';
  ExPartnerContact.EditContactButtonID := 'btnEditSpouseContact';
  ExPartnerContact.OnAllSaved := @DoExPartnerSaved;
  ExPartnerContact.OnContactChanged := OnContactChanged;
end;

procedure TfrmFamily.DoExPartnerSaved(Sender: TObject);
begin
  dmServer.ShowOK(SExPartnerSavedOK);
  DiscardChanges := True;
end;

procedure TfrmFamily.WebFormShow(Sender: TObject);
begin
  inherited;
  dsChild.ParamByName('DOSSIERID').asLargeInt := DossierID;
  dsChild.Load([], nil);

  dsHouse.ParamByName('DOSSIERID').asLargeInt := DossierID;
  dsHouse.Load([], nil);

  ConfigureExpartnerContact;
  ExPartnerContact.LoadDossierPersonData(DossierID, 'EXPART', [cdpDossierPerson, cdpPersonal, cdpWPaddress, cdpID],
    [cdpDossierPerson]);

  // extra listener op de tabkes-click
//  jQuery('a[href="#first"]').addEventListener('click', @RedrawGrid, True);
end;

procedure TfrmFamily.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#addFamilyChild').attr('href', DossierURL('relation/new/child'));
  jQuery('#family-inmate').attr('href', DossierURL('relation/new/house'));
  FRoute := DossierURL('relation');
end;

procedure TfrmFamily.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsChild := TP2WDADataset.Create(Self);
  dcChild := TP2WDatatable.Create(Self);
  dsHouse := TP2WDADataset.Create(Self);
  dcHouse := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsChild.BeforeLoadDFMValues;
  dcChild.BeforeLoadDFMValues;
  dsHouse.BeforeLoadDFMValues;
  dcHouse.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtName';
      Name := 'edtName';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtFirstname';
      Name := 'edtFirstname';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := '';
      Name := 'rdGender';
      PreventDefault := False;
      Selector := 'input[name="rdGender"]';
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'ddtBirth';
      Name := 'ddtBirth';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtNationalNumber';
      Name := 'edtNationalNumber';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtAddress';
      Name := 'edtAddress';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtNumber';
      Name := 'edtNumber';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtZip';
      Name := 'edtZip';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtTown';
      Name := 'edtTown';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveSpouseClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSelectSpouseContact';
      Name := 'btnSelectSpouseContact';
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'firstTab';
      PreventDefault := False;
      Selector := 'a[href="#first"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnFirstTabClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'secondTab';
      PreventDefault := False;
      Selector := 'a[href="#second"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnSecondTabClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'thirdTab';
      PreventDefault := False;
      Selector := 'a[href="#third"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnThirdTabClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnChildYes';
      Name := 'btnChildYes';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnChildDeleteConfirm');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnChildNo';
      Name := 'btnChildNo';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnDeleteCancel');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnInmateYes';
      Name := 'btnInmateYes';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnInmateDeleteConfirm');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnInmateNo';
      Name := 'btnInmateNo';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'OnDeleteCancel');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'mmRemark';
      Name := 'mmRemark';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    dsChild.SetParentComponent(Self);
    dsChild.Name := 'dsChild';
    dsChild.TableName := 'DossierPersons';
    dsChild.Params.Clear;
    with dsChild.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsChild.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>docdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Or"><binaryoperation operator="Equal"><field>docpersontype</field><constant type="String">KIND</constant></binaryoperation><binaryoperation operator="Equal"><field>docpersontype</field><constant type="String">AKIND</constant></binaryoperation></binaryoperation></binaryoperation></where></query>';
    dsChild.DAOptions := [doRefreshAllFields];
    dsChild.AfterOpen := dsChildAfterOpen;
    dsChild.AfterApplyUpdates := deleteAfterApplyUpdates;
    dsChild.Left := 40;
    dsChild.Top := 88;
    dcChild.SetParentComponent(Self);
    dcChild.Name := 'dcChild';
    dcChild.Columns.Clear;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntlastname';
      Title := 'Last Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntfirstname';
      Title := 'First Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntgender';
      Title := 'Gender';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGenderGetValue');
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntbirthdateon';
      Title := 'Date Of Birth';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
      DateTimeFormat := 'dd-mm-yyyy';
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'docpersontype';
      Title := 'Child of';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGetPersonTypeGetValue');
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      Width := '50px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{docdossierfk}}/relation/edit/{{cntid}}/child';
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'docdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Width := '50px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{docdossierfk}}/relation/delete/{{docid}}/child';
    end;
    dcChild.DataSet := dsChild;
    dcChild.Language := lEnglish;
    dcChild.IsResponsive := True;
    dcChild.GridID := 'grdChildList';
    dcChild.UseFieldIndex := True;
    dcChild.ShowSearch := False;
    dcChild.ShowNumberOfEntries := False;
    dcChild.ShowEntriesInfo := False;
    dcChild.Paginate := True;
    dcChild.DisplayReadOnly := False;
    dcChild.CalculateTableWidth := True;
    dcChild.Left := 40;
    dcChild.Top := 144;
    dsHouse.SetParentComponent(Self);
    dsHouse.Name := 'dsHouse';
    dsHouse.TableName := 'DossierPersons';
    dsHouse.Params.Clear;
    with dsHouse.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsHouse.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>docdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="In"><field>docpersontype</field><list><constant type="String" null="0">EEGA</constant><constant type="String" null="0">OUDER</constant><constant type="String" null="0">OMPA</constant><constant type="String" null="0">VRIEND</constant><constant type="String" null="0">VKIND</constant><constant type="String" null="0">ANDERE</constant></list></binaryoperation></binaryoperation></where></query>';
    dsHouse.DAOptions := [];
    dsHouse.AfterOpen := dsHouseAfterOpen;
    dsHouse.AfterApplyUpdates := deleteAfterApplyUpdates;
    dsHouse.Left := 120;
    dsHouse.Top := 88;
    dcHouse.SetParentComponent(Self);
    dcHouse.Name := 'dcHouse';
    dcHouse.Columns.Clear;
    with dcHouse.Columns.Add do
    begin
      FieldName := 'cntlastname';
      Title := 'Last Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcHouse.Columns.Add do
    begin
      FieldName := 'cntfirstname';
      Title := 'First Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcHouse.Columns.Add do
    begin
      FieldName := 'cntgender';
      Title := 'Gender';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGenderGetValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcHouse.Columns.Add do
    begin
      FieldName := 'cntbirthdateon';
      Title := 'Date Of Birth';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcHouse.Columns.Add do
    begin
      FieldName := 'docpersontype';
      Title := 'Relationship to applicant';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGetPersonTypeGetValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcHouse.Columns.Add do
    begin
      FieldName := 'cntid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      Width := '50px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{docdossierfk}}/relation/edit/{{cntid}}/house';
    end;
    with dcHouse.Columns.Add do
    begin
      FieldName := 'docdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Width := '50px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{docdossierfk}}/relation/delete/{{docid}}/house';
    end;
    dcHouse.DataSet := dsHouse;
    dcHouse.Language := lEnglish;
    dcHouse.IsResponsive := True;
    dcHouse.GridID := 'grdInmateList';
    dcHouse.UseFieldIndex := True;
    dcHouse.ShowSearch := False;
    dcHouse.ShowNumberOfEntries := False;
    dcHouse.ShowEntriesInfo := False;
    dcHouse.Paginate := True;
    dcHouse.DisplayReadOnly := False;
    dcHouse.CalculateTableWidth := True;
    dcHouse.Left := 120;
    dcHouse.Top := 144;
  finally
    alForm.AfterLoadDFMValues;
    dsChild.AfterLoadDFMValues;
    dcChild.AfterLoadDFMValues;
    dsHouse.AfterLoadDFMValues;
    dcHouse.AfterLoadDFMValues;
  end;
end;

end.
