unit pas2web.dataelementmapper;

interface

uses
  WEBLib.Actions,
  DB,
  SysUtils,
  Classes;

type
  TPW2DataElementMapper = class;
  TP2WDataElementLink = class;
  TP2WDataElementLinks = class;

  EP2WDataElementMapperException = class(Exception);

  TP2WElementOption = (aoReadOnly, aoRequired, aoEmptyValueClearsField);
  TP2WElementOptions = set of TP2WElementOption;
  TElementError = (eeEmpty);

  TApplyFieldValueEvent = procedure(Sender: TObject; Source: TField; Dest: TElementAction; var aHandled: Boolean) of object;
  TApplyElementValueEvent = procedure(Sender: TObject; Source: TElementAction; Dest: TField; var aHandled: Boolean) of object;
  TElementValidationErrorEvent = procedure(Sender: TObject; Source: TElementAction; Dest: TField; aError: TElementError) of object;
  TActionListValidationErrorEvent = procedure(Sender: TObject; aLink: TP2WDataElementLink; Source: TElementAction; Dest: TField; aError: TElementError) of object;
  TElementValidationEvent = procedure(Sender: TObject; Source: TElementAction; var aResult: Boolean) of object;

  TP2WDataElementLink = class(TCollectionItem)
  private
    FFieldName: string;
    FActionName: string;
    FDataAttribute: string;
    FOptions: TP2WElementOptions;
    FOnFieldToAction: TApplyFieldValueEvent;
    FOnActionToField: TApplyElementValueEvent;
    FOnError: TElementValidationErrorEvent;
    FOnValidation: TElementValidationEvent;
    FClearValue: string;
    function GetActionList: TElementActionList;
    function GetDataset: TDataset;
    function GetFormatSettings: TFormatSettings;
  protected
    // These raise an exception if the corresponding object is not found.
    function EnsureDataset: TDataset;
    function EnsureActionlist: TElementActionList;
    function EnsureAction(aActionName: string): TElementAction;
    // Display error for this field/element.
    procedure DoDisplayError(aError: TElementError; aField: TField; aAction: TElementAction); virtual;
    // Apply field to element.
    function DoApplyField(Source: TField; Dest: TElementAction): Boolean; virtual;
    // Apply element to field. Note that validation is not called !
    function DoApplyAction(Source: TElementAction; Dest: TField): Boolean; virtual;
  public
    procedure Assign(Source: TPersistent); override;
    procedure DisplayError(aError: TElementError);
    // Clear element value
    procedure ClearElement;
    // Apply field to element. Returns true if value was applied.
    function ApplyField: Boolean;
    // Apply element to field. Returns true if value was applied.
    function ApplyAction: Boolean;
    // Validate action data. If aDisplayError is True, OnValidationError is triggered if set.
    function ValidateAction(aDisplayError: Boolean): Boolean;
    // Easy action, these can return nil.
    property Dataset: TDataset read GetDataset;
    property ActionList: TElementActionList read GetActionList;
  published
    // Name of the action in the list.
    property ActionName: string read FActionName write FActionName;
    // Name of the field in the list.
    property FieldName: string read FFieldName write FFieldName;
    // Set data-NNN attribute instead of value.
    property DataAttribute: string read FDataAttribute write FDataAttribute;
    // Options
    property Options: TP2WElementOptions read FOptions write FOptions;
    // Value to use when clearing element value. To be used e.g. for Select to select default value.
    property ClearValue: string read FClearValue write FClearValue;
    // Called when applying field to action. If aHandled is set to true, further processing is stopped.
    property OnFieldToAction: TApplyFieldValueEvent read FOnFieldToAction write FOnFieldToAction;
    // Called when applying action to field. If aHandled is set to true, further processing is stopped.
    property OnActionToField: TApplyElementValueEvent read FOnActionToField write FOnActionToField;
    // Called when ValidateAction is false, and aDisplayError is set to true.
    // This is called in addition to Controller.OnValidationError
    property OnValidationError: TElementValidationErrorEvent read FOnError write FOnError;
    // This method is called during the validation to provide an opportunity to define custom validation
    // its overwrites default validation
    property OnValidation: TElementValidationEvent read FOnValidation write FOnValidation;
  end;

  TP2WDataElementLinks = class(TOwnedCollection)
  private
    function GetActionList: TElementActionList;
    function GetDataset: TDataset;
    function GetLink(aIndex: Integer): TP2WDataElementLink;
    procedure SetLink(aIndex: Integer; const Value: TP2WDataElementLink);
    function GetController: TPW2DataElementMapper;
  protected
    // Owner as controller. Can be nil.
    property Controller: TPW2DataElementMapper read GetController;
  public
    // Call clearElement for all links.
    procedure ClearElements;
    // Add new link
    function Add: TP2WDataElementLink; reintroduce;
    // Find index of action name, starts at end. Return -1 if not found.
    function IndexOfAction(const aActionName: string): Integer;
    // Find index of field name, starts at end.  Return -1 if not found.
    function IndexOfField(const aFieldName: string): Integer;
    // Find link by by actionname, starts at end. Return Nil if not found.
    function FindAction(const aActionName: string): TP2WDataElementLink;
    // Find link by fieldname, starts at end. Return Nil if not found.
    function FindField(const aFieldName: string): TP2WDataElementLink;
    // Get link by by actionname, starts at end. Raise exception if not found.
    function GetAction(const aActionName: string): TP2WDataElementLink;
    // Get link by fieldname, starts at end. Raise exception if not found.
    function GetField(const aFieldName: string): TP2WDataElementLink;
    // Easy access. Can return nil
    property ActionList: TElementActionList read GetActionList;
    property Dataset: TDataset read GetDataset;
    // Our links.
    property Links[aIndex: Integer]: TP2WDataElementLink read GetLink write SetLink; default;
  end;

  TPW2DataElementMapper = class(TComponent)
  private
    FDataset: TDataset;
    FActionlist: TElementActionList;
    FActionLinks: TP2WDataElementLinks;
    FOnValidationError: TActionListValidationErrorEvent;
    procedure SetActionList(const Value: TElementActionList);
    procedure SetDataset(const Value: TDataset);
    procedure SetActionLinks(const Value: TP2WDataElementLinks);
  protected
    procedure DoDisplayError(aLink: TP2WDataElementLink; aError: TElementError; aField: TField; aAction: TElementAction);
    function CreateActionLinks: TP2WDataElementLinks;
  public
    constructor Create(aOwner: TComponent); override;
    destructor Destroy; override;
    // Call clearElement for all links.
    procedure ClearElements;
    // Copy data from dataset to action elements
    procedure DatasetToElements;
    // Copy data from action elements to dataset (skips read-only links)
    procedure ElementsToDataset;
    // Check if all elements are OK. Return true if all actions were valid.
    function ValidateActions(DisplayErrors: Boolean): Boolean;
  published
    // The action links
    property ActionLinks: TP2WDataElementLinks read FActionLinks write SetActionLinks;
    // The action list we control
    property ActionList: TElementActionList read FActionlist write SetActionList;
    // The dataset we control
    property Dataset: TDataset read FDataset write SetDataset;
    // Called when ValidateAction is false for an action, and aDisplayError is set to true.
    // This is called in addition to Link.OnValidationError
    property OnValidationError: TActionListValidationErrorEvent read FOnValidationError write FOnValidationError;
  end;

implementation

uses
  Units.ActionUtils,
  JS,
  Web;

resourcestring
  SErrActionRequiresValue = 'Action %s requires a value';
  SErrUnknownField = 'Unknown field name : %s';
  SErrUnknownAction = 'Unknown action name : %s';
  SErrNoDataset = '%s: No dataset found';
  SErrNoActionList = '%s: No dataset found';

  { TPW2DataElementMapper }

function TPW2DataElementMapper.ValidateActions(DisplayErrors: Boolean): Boolean;
var
  I: Integer;
  DoDisplay: Boolean;
begin
  Result := True;
  // Show only first error.
  DoDisplay := DisplayErrors;
  for I := 0 to ActionLinks.Count - 1 do
  begin
    Result := ActionLinks[I].ValidateAction(DoDisplay) and Result;
    Result := DoDisplay and Result;
  end;
end;

procedure TPW2DataElementMapper.ClearElements;
begin
  ActionLinks.ClearElements;
end;

constructor TPW2DataElementMapper.Create(aOwner: TComponent);
begin
  inherited;
  FActionLinks := CreateActionLinks;
end;

function TPW2DataElementMapper.CreateActionLinks: TP2WDataElementLinks;
begin
  Result := TP2WDataElementLinks.Create(Self, TP2WDataElementLink);
end;

procedure TPW2DataElementMapper.DatasetToElements;
var
  I: Integer;
begin
  if Dataset.IsEmpty then
    ClearElements
  else
    for I := 0 to ActionLinks.Count - 1 do
      ActionLinks[I].ApplyField;
end;

destructor TPW2DataElementMapper.Destroy;
begin
  FreeAndNil(FActionLinks);
  inherited;
end;

procedure TPW2DataElementMapper.DoDisplayError(aLink: TP2WDataElementLink; aError: TElementError; aField: TField; aAction: TElementAction);
begin
  if Assigned(FOnValidationError) then
    FOnValidationError(Self, aLink, aAction, aField, aError);
end;

procedure TPW2DataElementMapper.ElementsToDataset;
var
  I: Integer;
begin
  for I := 0 to ActionLinks.Count - 1 do
    if not(aoReadOnly in ActionLinks[I].Options) then
      ActionLinks[I].ApplyAction;
end;

procedure TPW2DataElementMapper.SetActionLinks(const Value: TP2WDataElementLinks);
begin
  FActionLinks.Assign(Value);
end;

procedure TPW2DataElementMapper.SetActionList(const Value: TElementActionList);
begin
  if (FActionlist = Value) then
    exit;
  if Assigned(FActionlist) then
    FActionlist.RemoveFreeNotification(Self);
  FActionlist := Value;
  if Assigned(FActionlist) then
    FActionlist.FreeNotification(Self);
end;

procedure TPW2DataElementMapper.SetDataset(const Value: TDataset);
begin
  if (FDataset = Value) then
    exit;
  if Assigned(FDataset) then
    FDataset.RemoveFreeNotification(Self);
  FDataset := Value;
  if Assigned(FDataset) then
    FDataset.FreeNotification(Self);
end;

{ TP2WDataElementLink }

function TP2WDataElementLink.ApplyAction: Boolean;
var
  F: TField;
  D: TElementAction;
begin
  F := EnsureDataset.FieldByName(FieldName);
  D := EnsureAction(ActionName);
  Result := DoApplyAction(D, F);
end;

function TP2WDataElementLink.ApplyField: Boolean;
var
  F: TField;
  D: TElementAction;
begin
  F := EnsureDataset.FieldByName(FieldName);
  D := EnsureAction(ActionName);
  Result := DoApplyField(F, D);
end;

procedure TP2WDataElementLink.Assign(Source: TPersistent);
var
  Src: TP2WDataElementLink;
begin
  if Source is TP2WDataElementLink then
  begin
    Src := Source as TP2WDataElementLink;
    FieldName := Src.FieldName;
    ActionName := Src.ActionName;
    Options := Src.Options;
    OnFieldToAction := Src.OnFieldToAction;
    OnActionToField := Src.OnActionToField;
  end
  else
    inherited;
end;

procedure TP2WDataElementLink.ClearElement;
var
  Dest: TElementAction;
begin
  Dest := EnsureAction(ActionName);
  if DataAttribute = '' then
    Dest.Value := ClearValue
  else
    if Assigned(Dest.ElementHandle) then
      TJSHTMLELement(Dest.ElementHandle).Dataset[DataAttribute] := undefined;
end;

function TP2WDataElementLink.ValidateAction(aDisplayError: Boolean): Boolean;
var
  a: TElementAction;
begin
  Result := True;
  a := EnsureAction(ActionName);
  if Assigned(FOnValidation) then
    FOnValidation(Self, a, Result)
  else
    if (aoRequired in Options) then
      Result := a.Value <> '';
  if (not Result) and aDisplayError then
    DoDisplayError(eeEmpty, EnsureDataset.FieldByName(FieldName), a);
end;

procedure TP2WDataElementLink.DisplayError(aError: TElementError);
var
  F: TField;
  a: TElementAction;
begin
  F := EnsureDataset.FieldByName(FieldName);
  a := EnsureAction(ActionName);
  DoDisplayError(aError, F, a);
end;

function TP2WDataElementLink.DoApplyAction(Source: TElementAction; Dest: TField): Boolean;
var
  sValue: string;
  V: JSValue;
begin
  Result := False;
  try
  if aoReadOnly in Options then
    exit;

  if Assigned(FOnActionToField) then
    FOnActionToField(Self, Source, Dest, Result);

  if Result then
    Exit;

  if DataAttribute = '' then
    sValue := Source.Value
  else
    if Assigned(Source.ElementHandle) then
    begin
      V := TJSHTMLELement(Source.ElementHandle).Dataset[DataAttribute];
      if isNull(V) or IsUnDefined(V) then
        sValue := ''
      else
        sValue := string(V);
    end;

  if (sValue = '') then
  begin
    if (aoRequired in Options) then
      raise EP2WDataElementMapperException.CreateFmt(SErrActionRequiresValue, [ActionName]);
    if (aoEmptyValueClearsField in Options) then
      Dest.Clear
    else
      Dest.AsString := '';
  end
  else
    if Dest.DataType = ftFloat then
      Dest.AsFloat := StrToFloatDef(sValue, 0, GetFormatSettings)
    else
      Dest.AsString := sValue;
  except
    on E: Exception do
    begin
      console.error('Error in TP2WDataElementLink.DoApplyAction(' + ActionName + ', ' + FieldName + '): ' + E.Message);
      raise;
    end;
  end;
end;

function TP2WDataElementLink.DoApplyField(Source: TField; Dest: TElementAction): Boolean;
var
  sValue: string;
begin
  Result := False;

  if Assigned(FOnFieldToAction) then
    FOnFieldToAction(Self, Source, Dest, Result);

  if Result then
    Exit;

  if Source.DataType = ftFloat then
    sValue := FloatToStr(Source.AsFloat, GetFormatSettings)
  else
    sValue := Source.AsString;

  if DataAttribute = '' then
    Dest.Value := sValue
  else
    if Assigned(Dest.ElementHandle) then
      TJSHTMLELement(Dest.ElementHandle).Dataset[DataAttribute] := sValue;
end;

procedure TP2WDataElementLink.DoDisplayError(aError: TElementError; aField: TField; aAction: TElementAction);
begin
  if Assigned(FOnError) then
    FOnError(Self, aAction, aField, aError);
  if Assigned(Collection) and (Collection is TP2WDataElementLinks) then
    if Assigned((Collection as TP2WDataElementLinks).Controller) then
      (Collection as TP2WDataElementLinks).Controller.DoDisplayError(Self, aError, aField, aAction);
end;

function TP2WDataElementLink.EnsureAction(aActionName: string): TElementAction;
begin
  Result := EnsureActionlist.Actions.Action[ActionName];
  if Result = nil then
    raise EP2WDataElementMapperException.CreateFmt(SErrUnknownAction, [aActionName]);
end;

function TP2WDataElementLink.EnsureActionlist: TElementActionList;
begin
  Result := ActionList;
  if Result = nil then
    raise EP2WDataElementMapperException.CreateFmt(SErrNoActionList, [GetDisplayName]);
end;

function TP2WDataElementLink.EnsureDataset: TDataset;
begin
  Result := Dataset;
  if Result = nil then
    raise EP2WDataElementMapperException.CreateFmt(SErrNoDataset, [GetDisplayName]);
end;

function TP2WDataElementLink.GetActionList: TElementActionList;
begin
  if Assigned(Collection) and (Collection is TP2WDataElementLinks) then
    Result := TP2WDataElementLinks(Collection).ActionList
  else
    Result := nil;
end;

function TP2WDataElementLink.GetDataset: TDataset;
begin
  if Assigned(Collection) and (Collection is TP2WDataElementLinks) then
    Result := TP2WDataElementLinks(Collection).Dataset
  else
    Result := nil;
end;

function TP2WDataElementLink.GetFormatSettings: TFormatSettings;
begin
  Result.Thousandseparator := '';
  Result.Decimalseparator := '.';
  Result.CurrencyDecimals := 2;
end;

{ TElementActionDBLinks }

function TP2WDataElementLinks.Add: TP2WDataElementLink;
begin
  Result := (inherited Add) as TP2WDataElementLink;
end;

procedure TP2WDataElementLinks.ClearElements;
var
  I: Integer;
begin
  for I := 0 to Count - 1 do
    Links[I].ClearElement;
end;

function TP2WDataElementLinks.FindAction(const aActionName: string): TP2WDataElementLink;
var
  Idx: Integer;
begin
  Idx := IndexOfAction(aActionName);
  if Idx = -1 then
    Result := nil
  else
    Result := Links[Idx]
end;

function TP2WDataElementLinks.FindField(const aFieldName: string): TP2WDataElementLink;
var
  Idx: Integer;
begin
  Idx := IndexOfField(aFieldName);
  if Idx = -1 then
    Result := nil
  else
    Result := Links[Idx]
end;

function TP2WDataElementLinks.GetAction(const aActionName: string): TP2WDataElementLink;
begin
  Result := FindAction(aActionName);
  if Result = nil then
    raise EP2WDataElementMapperException.CreateFmt(SErrUnknownAction, [aActionName])
end;

function TP2WDataElementLinks.GetActionList: TElementActionList;
begin
  if Assigned(Owner) and (Owner is TPW2DataElementMapper) then
    Result := TPW2DataElementMapper(Owner).ActionList
  else
    Result := nil;
end;

function TP2WDataElementLinks.GetController: TPW2DataElementMapper;
begin
  if Assigned(Owner) and (Owner is TPW2DataElementMapper) then
    Result := TPW2DataElementMapper(Owner)
  else
    Result := nil;
end;

function TP2WDataElementLinks.GetDataset: TDataset;
begin
  if Assigned(Owner) and (Owner is TPW2DataElementMapper) then
    Result := TPW2DataElementMapper(Owner).Dataset
  else
    Result := nil;
end;

function TP2WDataElementLinks.GetField(const aFieldName: string): TP2WDataElementLink;
begin
  Result := FindField(aFieldName);
  if Result = nil then
    raise EP2WDataElementMapperException.CreateFmt(SErrUnknownField, [aFieldName])
end;

function TP2WDataElementLinks.GetLink(aIndex: Integer): TP2WDataElementLink;
begin
  Result := TP2WDataElementLink(Items[aIndex]);
end;

function TP2WDataElementLinks.IndexOfAction(const aActionName: string): Integer;
begin
  Result := Count - 1;
  while (Result >= 0) and not SameText(Links[Result].ActionName, aActionName) do
    Dec(Result);
end;

function TP2WDataElementLinks.IndexOfField(const aFieldName: string): Integer;
begin
  Result := Count - 1;
  while (Result >= 0) and not SameText(Links[Result].FieldName, aFieldName) do
    Dec(Result);
end;

procedure TP2WDataElementLinks.SetLink(aIndex: Integer; const Value: TP2WDataElementLink);
begin
  Items[aIndex] := Value;
end;

end.
