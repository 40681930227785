unit rosdk;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  Types,
  JS;

type
  TROValue = record
    dataType: string;
    value: JSValue;
  end;

  TROComplexType = class;
  TROEnumType = class;
  TROStructType = class;
  TROArrayType = class;
  TROException = class;
  TROEventSink = class;
  TROClientChannel = class;
  TROHTTPClientChannel = class;
  TROMessage = class;
  TROJSONMessage = class;
  TROBinMessage = class;
  TROBinHeader = class;
  TRORemoteService = class;
  TROService = class;
  TROEventReceiver = class;

  TROUtil = class external name 'RemObjects.UTIL'(TJSObject)
  public
    class function toBase64(const aValue: string): string;
    class function fromBase64(const aValue: string): string;
    class procedure showMessage(const msg: string);
    class procedure showError(const msg: string; e: JSValue);
    class function toJSON(aValue: JSValue): string;
    class function parseJSON(const aValue: string): JSValue;
    class function NewGuid(): string;
    class function GuidToArray(const aGuid: string): TIntegerDynArray;
    class function guidToByteArray(const aGuid: string): string;
    class function zeroPad(const num: string; count: integer): string;
    class function byteArrayToGuid(const byteArray: string): string;
    class function strToByteArray(const str: string): string;
    class function byteArrayToStr(const byteArray: string): string;
    class function byteArrayToUtf16(const byteArray: string): string;
    class function utf16ToByteArray(const str: string): string;
    class function ISO8601toDateTime(const str: string): TJSDate;
    class function dateTimeToSOAPString(aValue: TJSDate): string;
    class function decimalToString(aDecimal: array of integer): string;
    class function stringToDecimal(const aString: string): TIntegerDynArray;
    class function checkArgumentTypes(args: array of JSValue; Types: array of string): Boolean;
  end;

  TROException = class external name 'RemObjects.SDK.ROException'(TJSError);

  TROComplexType = class external name 'RemObjects.SDK.ROComplexType'(TJSObject)
  public
    procedure readFrom(aMessage: TROMessage);
    procedure writeTo(aMessage: TROMessage);
  end;

  { TROEnumType }

  TROEnumType = class external name 'RemObjects.SDK.ROEnumType'(TROComplexType)
  private
    FEnumValues: TStringDynArray;
  public
    value: string;
    procedure fromObject(aObject: TJSObject); overload;
    function toObject(aStoreType: Boolean): TJSObject; overload;
    property enumValues: TStringDynArray read FEnumValues;
  end;

  TROStructType = class external name 'RemObjects.SDK.ROStructType'(TROComplexType)
  public
    procedure fromObject(aObject: TJSObject); overload;
    function toObject(aStoreType: Boolean): TJSObject; overload;
  end;

  TROArrayType = class external name 'RemObjects.SDK.ROArrayType'(TROComplexType)
  public
    procedure fromObject(aObject: array of TJSObject); overload;
    function toObject(aStoreType: Boolean): TJSObjectDynArray; overload;
  end;

  TRODispatchSuccessEvent = reference to procedure(msg: TROMessage);
  TRODispatchFailedEvent = reference to procedure(msg: TROMessage; aError: TJSError);
  TROCallBack = procedure;
  TROOnLoginNeeded = reference to procedure(aCallBack: TROCallBack);

  TROClientChannel = class external name 'RemObjects.SDK.ClientChannel'(TJSObject)
  public
    onLoginNeeded: TROOnLoginNeeded;
    constructor new(aURL: string);
    procedure dispatch(aMessage: TROMessage; onSuccess: TRODispatchSuccessEvent; OnError: TRODispatchFailedEvent);
  end;

  TROHTTPCallback = reference to procedure(aResponse: string; aStatus: integer);

  TROHTTPClientChannel = class external name 'RemObjects.SDK.HTTPClientChannel'(TROClientChannel)
  public
    procedure post(aMessage: TROMessage; isBinary: Boolean; onSuccess, OnError: TROHTTPCallback);
  end;

  TROEventSink = class external name 'RemObjects.SDK.ROEventSink'(TJSObject)
  public
    procedure readEvent(aMessage: TROMessage; aName: string);
  end;

  TROMessage = class external name 'RemObjects.SDK.Message'(TJSObject)
  public
    constructor new;
    function Clone: TROMessage;
    function getClientID: string;
    procedure setClientID(const aValue: string);
    function getErrorMessage: string;
    procedure setErrorResponse(const aResponse: string);
    procedure initialize(const aServiceName, aMethodName: string; aMessageType: integer);
    procedure finalize;
    function requestStream: string; // Dummy
    procedure setResponseStream(const aValue: string);
    function read(const aName, aType: string): TROValue;
    procedure write(const aName, aType: string; aValue: JSValue);
    property ClientID: string read getClientID write setClientID;
    property ErrorMessage: string read getErrorMessage;
  end;

  TROJSONMessage = class external name 'RemObjects.SDK.JSONMessage'(TROMessage);

  TROBinHeader = class external name 'RemObjects.SDK.BinHeader'(TJSObject)
  public
    function asStream: string;
    procedure readFrom(aStream: string);
    function isValidHeader: Boolean;
    function getCompressed: Boolean;
    procedure setCompressed(aValue: Boolean);
    function getMessageType: integer;
    procedure setMessageType(aValue: integer);
    procedure setClientID(aValue: string);
    property Compressed: Boolean read getCompressed write setCompressed;
    property MessageType: integer read getMessageType write setMessageType;
  end;

  TROBinMessage = class external name 'RemObjects.SDK.BinMessage'(TROMessage)
  public
    constructor new;
    procedure writeVariant(aValue: JSValue);
    procedure writeinteger(aValue: integer);
    procedure writeStrWithLength(aValue: string);
    function readByte: Byte;
    function readCompressed: string;
    function readVariant: JSValue;
  end;

  TROEventCallback = reference to procedure(event: TJSObject); // Or TROComplexType ?

  TROEventReceiver = class external name 'RemObjects.SDK.ROEventReceiver'(TJSObject)
  public
    constructor new(aChannel: TROClientChannel; aMessage: TROMessage; aServiceName: string; aTimeOut: integer);
    procedure addHandler(anEventName: string; aCallBack: TROEventCallback);
    procedure setActive(aValue: Boolean);
    function getActive: Boolean;
    function getTimeout: integer;
    procedure setTimeout(aValue: integer);
    procedure intPollServer;
    property Active: Boolean read getActive write setActive;
    property TimeOut: integer read getTimeout write setTimeout;
  end;

  TRORemoteService = class external name 'RemObjects.SDK.RemoteService'(TJSObject)
  public
    constructor new(aChannel: TROClientChannel; aMessage: TROMessage; aServiceName: string);
  end;

  TROService = class external name 'RemObjects.SDK.ROService'(TJSObject)
  public
    constructor new(aService: TRORemoteService);
    constructor new(aChannel: TROClientChannel; aMessage: TROMessage; aServiceName: string);
    function getMessage: TROMessage;
    function getChannel: TROClientChannel;
    function getServiceName: string;
    property message: TROMessage read getMessage;
    property Channel: TROClientChannel read getChannel;
    property ServiceName: string read getServiceName;
  end;

  TROBinaryParser = class external name 'BinaryParser'(TJSObject)
  public
    procedure warn;
    function decodeFloat(data: JSValue; precisionbits, exponentbits: integer): double;
    function encodeFloat(value: double; precisionbits, exponentbits: integer): string;
    function decodeInt(data: JSValue; bits: integer; Signed: Boolean): NativeInt;
    function encodeInt(data: NativeInt; bits: integer; Signed: Boolean): string;
  end;

function ExtractErrorMsg(jsError: TJSError; aFMT: string = ''): string;

// So we can customize the message
var
  UnexpectedErrorMsg: string;

implementation

uses
  Sysutils;

resourcestring
  SDefaultUnexpectedErrorMsg = 'An unexpected error occurred';

function ExtractErrorMsg(jsError: TJSError; aFMT: string = ''): string;
begin
  Result := '';
  if Assigned(jsError) then
  begin
    if isString(jsError.message) then
      Result := jsError.message
    else
      if isClassInstance(jsError) and (TObject(JSValue(jsError))).InheritsFrom(Exception) then
        Result := Exception(JSValue(jsError)).message;
  end;
  if Result = '' then
  begin
    Result := UnexpectedErrorMsg;
    if Result = '' then
      Result := SDefaultUnexpectedErrorMsg;
  end;
  if aFMT <> '' then
    Result := Format(aFMT, [Result]);
end;

end.
