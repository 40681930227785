unit Forms.CSR.Revenue;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Revenue, pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRRevenue = class(TfrmRevenue)
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRRevenue: TfrmCSRRevenue;

implementation

{$R *.dfm}

{ TfrmCSRRevenue }

function TfrmCSRRevenue.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRRevenue.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRRevenue.WebFormCreate(Sender: TObject);
begin
  Inherited;
  dcIncome.Columns[5].ButtonURL:= '#/CSR/{{revdossierfk}}/revenue/edit/{{revid}}/income';
  dcIncome.Columns[6].ButtonURL:= '#/CSR/{{revdossierfk}}/revenue/delete/{{revid}}/income';
  dcDebtor.Columns[6].ButtonURL:= '#/CSR/{{revdossierfk}}/revenue/edit/{{revid}}/debtor';
  dcDebtor.Columns[7].ButtonURL:= '#/CSR/{{revdossierfk}}/revenue/delete/{{revid}}/debtor';
end;

procedure TfrmCSRRevenue.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcIncome.BeforeLoadDFMValues;
  dcDebtor.BeforeLoadDFMValues;
  try
    dcIncome.Columns.Clear;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revrevenuetype';
      Title := 'Kind';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnRevenueTupeGetValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'cntsearchname';
      Title := 'Origin';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revamount';
      Title := 'Net Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revviatype';
      Title := 'Via';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'getVia');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'reviscomplete';
      Title := 'Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'disabled';
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revdossierfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      Width := '40';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{revdossierfk}}/revenue/edit/{{revid}}/income';
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Width := '40';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{revdossierfk}}/revenue/delete/{{revid}}/income';
    end;
    dcDebtor.Columns.Clear;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'cntsearchname';
      Title := 'Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'cntkbonr';
      Title := 'KBO Number';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'ctastreet';
      Title := 'Address';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'ctacityzip';
      Title := 'Postcode';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'ctacityname';
      Title := 'City';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'revamount';
      Title := 'Net Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'revdossierfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      Width := '40';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{revdossierfk}}/revenue/edit/{{revid}}/debtor';
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'revid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Width := '40';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{revdossierfk}}/revenue/delete/{{revid}}/debtor';
    end;
  finally
    dcIncome.AfterLoadDFMValues;
    dcDebtor.AfterLoadDFMValues;
  end;
end;

end.