unit Forms.CSR.Revenue.Debtor;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Revenue.Debtor, Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRRevenueDebtor = class(TfrmRevenueDebtor)
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRRevenueDebtor: TfrmCSRRevenueDebtor;

implementation

{$R *.dfm}

{ TfrmCSRRevenueDebtor }

function TfrmCSRRevenueDebtor.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRRevenueDebtor.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRRevenueDebtor.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRRevenueDebtor.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.