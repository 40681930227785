unit Forms.CSR.Alarms;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset,
  pas2web.dadatasethelper;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmAlarms = class(TfrmBase)
    dsAlarms: TP2WDADataset;
    dcAlarms: TP2WDatatable;
    procedure dsAlarmsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsAlarmsAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    FRoute : string;
    FDoDelete : Boolean;
    FDeleteID: Int64;
    procedure ConfirmDeleteAlarm;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
    procedure DoDelete(const AlarmId: Int64);
  protected procedure LoadDFMValues; override; end;

var
  frmAlarms: TfrmAlarms;

implementation

{$R *.dfm}
uses
  Modules.Server, libjquery;

procedure TfrmAlarms.ConfirmDeleteAlarm;

  Procedure DoDeleteRecord;
  begin
    if dsAlarms.Locate('almid', FDeleteID,[]) then
    begin
      dsAlarms.Delete;
      dsAlarms.ApplyUpdates;
    end;
  end;

  Procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;
begin
  FDoDelete := False;
  if dsAlarms.Locate('almid', FDeleteID,[]) then
  begin
    DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
  end;
end;

procedure TfrmAlarms.DoDelete(const AlarmId: Int64);
begin
  FDeleteID := AlarmId;
  if dsAlarms.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteAlarm;
end;

function TfrmAlarms.DossierPrefix: string;
begin
  Result := 'SCR';
end;

procedure TfrmAlarms.dsAlarmsAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    window.location.href := FRoute;
  end;
end;

procedure TfrmAlarms.dsAlarmsAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsAlarms.GetNewRows(False);
  dcAlarms.Data := aResult;
  dcAlarms.RenderTranslated;
  if FDoDelete then
    ConfirmDeleteAlarm;
end;

procedure TfrmAlarms.setParams(const Params: TStrings);
begin
  inherited;

  FRoute := '#/CSR/' + InttoStr(DossierID) + '/alarm';
  jQuery('#btnAdd').attr('href', FRoute + '/new');

  dsAlarms.ParamByName('DOSSIERID').asInteger := DossierID;
  dsAlarms.Load([], nil);
end;

procedure TfrmAlarms.WebFormCreate(Sender: TObject);
begin
  inherited;
  FRoute := '';
  FDoDelete := False;
  FDeleteID := -1;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsAlarms.DAConnection := Server.CompanyConnection;
end;

procedure TfrmAlarms.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmAlarms.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsAlarms := TP2WDADataset.Create(Self);
  dcAlarms := TP2WDatatable.Create(Self);

  dsAlarms.BeforeLoadDFMValues;
  dcAlarms.BeforeLoadDFMValues;
  try
    dsAlarms.SetParentComponent(Self);
    dsAlarms.Name := 'dsAlarms';
    dsAlarms.TableName := 'alarm';
    dsAlarms.Params.Clear;
    with dsAlarms.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsAlarms.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsAlarms.DAOptions := [doRefreshAllFields];
    dsAlarms.AfterOpen := dsAlarmsAfterOpen;
    dsAlarms.AfterApplyUpdates := dsAlarmsAfterApplyUpdates;
    dsAlarms.Left := 40;
    dsAlarms.Top := 88;
    dcAlarms.SetParentComponent(Self);
    dcAlarms.Name := 'dcAlarms';
    dcAlarms.Columns.Clear;
    with dcAlarms.Columns.Add do
    begin
      FieldName := 'almnamealarm';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcAlarms.Columns.Add do
    begin
      FieldName := 'almdayofmonth';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcAlarms.Columns.Add do
    begin
      FieldName := 'almminamount';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcAlarms.Columns.Add do
    begin
      FieldName := 'almibanotherparty';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcAlarms.Columns.Add do
    begin
      FieldName := 'almid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/alarm/edit/{{almid}}';
    end;
    with dcAlarms.Columns.Add do
    begin
      FieldName := 'coddossierid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/alarm/delete/{{almid}}';
    end;
    dcAlarms.DataSet := dsAlarms;
    dcAlarms.Language := lEnglish;
    dcAlarms.IsResponsive := True;
    dcAlarms.GridID := 'grdAlarms';
    dcAlarms.UseFieldIndex := True;
    dcAlarms.ShowSearch := False;
    dcAlarms.ShowNumberOfEntries := False;
    dcAlarms.ShowEntriesInfo := False;
    dcAlarms.Paginate := True;
    dcAlarms.DisplayReadOnly := False;
    dcAlarms.CalculateTableWidth := True;
    dcAlarms.Left := 40;
    dcAlarms.Top := 168;
  finally
    dsAlarms.AfterLoadDFMValues;
    dcAlarms.AfterLoadDFMValues;
  end;
end;

end.
