unit Forms.CSR.TrustAccount.Movements.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmTrustAccountMovementDetails = class(TfrmBase)
    dsMovement: TP2WDADataset;
    eacMovement: TPW2DataElementMapper;
    procedure dsMovementAfterOpen(DataSet: TDataSet);
    procedure DateTimeToField(Sender: TObject; Source: TField;
        Dest: TElementAction; var aHandled: Boolean);
    procedure OnBackClicked(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FRoute: string;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmTrustAccountMovementDetails: TfrmTrustAccountMovementDetails;

implementation

{$R *.dfm}
uses
  Modules.Server, libjquery, Units.HTMLUtils, Units.ActionUtils;

procedure TfrmTrustAccountMovementDetails.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

function TfrmTrustAccountMovementDetails.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmTrustAccountMovementDetails.dsMovementAfterOpen(DataSet:
    TDataSet);
begin
  eacMovement.DatasetToElements;
  if not DataSet.isEmpty then
    jQuery('#lblCurrency').text(' (' +Dataset.FieldByName('bamcurrencyused').asString + ')');
end;

procedure TfrmTrustAccountMovementDetails.DateTimeToField(
    Sender: TObject; Source: TField; Dest: TElementAction; var aHandled:
    Boolean);
begin
  alForm[dest.name].value := FormatHTMLDate(Source.asDateTime);
  aHandled := True;
end;

procedure TfrmTrustAccountMovementDetails.OnBackClicked(Sender: TObject;
    Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  window.location.href := FRoute;
end;

procedure TfrmTrustAccountMovementDetails.setParams(const Params: TStrings);
begin
  inherited;
  FRoute := '#';
  If Assigned(Params) and (Params.indexOfName('ACCOUNTID')<>-1) and (Params.indexOfName('ID')<>-1) then
  begin
    FRoute := '#/CSR/' + InttoStr(DossierID) + '/trustaccount/' + Params.Values['ACCOUNTID'] + '/movement';
    jQuery('#returnBack').attr('href', FRoute);
    dsMovement.ParamByName('DOSSIERID').asInteger := DossierID;
    dsMovement.ParamByName('BACID').asInteger := StrToIntDef(Params.Values['ACCOUNTID'],-1);
    dsMovement.ParamByName('BAMID').asInteger := StrToIntDef(Params.Values['ID'],-1);
  end;
  dsMovement.Load([], nil);
end;

procedure TfrmTrustAccountMovementDetails.WebFormCreate(Sender: TObject);
begin
  inherited;

  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsMovement.DAConnection := Server.CompanyConnection;
  makeReadOnly;
  jquery('#btnBack').prop('disabled', false);
end;

procedure TfrmTrustAccountMovementDetails.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsMovement := TP2WDADataset.Create(Self);
  eacMovement := TPW2DataElementMapper.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsMovement.BeforeLoadDFMValues;
  eacMovement.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtAmount';
      Name := 'edtAmount';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtEffective';
      Name := 'ddtEffective';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtTransactionOn';
      Name := 'edtTransactionOn';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtOtherParty';
      Name := 'edtOtherParty';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtMessage';
      Name := 'edtMessage';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnBack';
      Name := 'btnBack';
      SetEvent(Self, 'OnExecute', 'OnBackClicked');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtAccount';
      Name := 'edtAccount';
    end;
    dsMovement.SetParentComponent(Self);
    dsMovement.Name := 'dsMovement';
    dsMovement.TableName := 'BankMovement';
    dsMovement.Params.Clear;
    with dsMovement.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsMovement.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BACID';
      ParamType := ptInput;
    end;
    with dsMovement.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BAMID';
      ParamType := ptInput;
    end;
    dsMovement.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>bambankaccountfk</field><parameter type="LargeInt">BACID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>bamid</field><parameter type="LargeInt">BAMID</parameter></binaryoperation></binaryoperation></where></query>';
    dsMovement.DAOptions := [doRefreshAllFields];
    dsMovement.AfterOpen := dsMovementAfterOpen;
    dsMovement.Left := 40;
    dsMovement.Top := 88;
    eacMovement.SetParentComponent(Self);
    eacMovement.Name := 'eacMovement';
    eacMovement.ActionLinks.Clear;
    with eacMovement.ActionLinks.Add do
    begin
      ActionName := 'edtAmount';
      FieldName := 'bamamount';
      Options := [];
    end;
    with eacMovement.ActionLinks.Add do
    begin
      ActionName := 'ddtEffective';
      FieldName := 'bameffectiveon';
      Options := [];
      SetEvent(Self, 'OnFieldToAction', 'DateTimeToField');
    end;
    with eacMovement.ActionLinks.Add do
    begin
      ActionName := 'edtTransactionOn';
      FieldName := 'bamtransactionon';
      Options := [];
      SetEvent(Self, 'OnFieldToAction', 'DateTimeToField');
    end;
    with eacMovement.ActionLinks.Add do
    begin
      ActionName := 'edtMessage';
      FieldName := 'bammessage';
      Options := [];
    end;
    with eacMovement.ActionLinks.Add do
    begin
      ActionName := 'edtOtherParty';
      FieldName := 'bamotherdescr';
      Options := [];
    end;
    with eacMovement.ActionLinks.Add do
    begin
      ActionName := 'edtAccount';
      FieldName := 'bamotherbankaccount';
      Options := [];
    end;
    eacMovement.ActionList := alForm;
    eacMovement.Dataset := dsMovement;
    eacMovement.Left := 40;
    eacMovement.Top := 160;
  finally
    alForm.AfterLoadDFMValues;
    dsMovement.AfterLoadDFMValues;
    eacMovement.AfterLoadDFMValues;
  end;
end;

end.