unit Forms.Contacts;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB, System.Types,
  Datasnap.DBClient, pas2web.dadataset, pas2web.datatables, WebRouter;

type
  TContactRoute = (prUnknown, prContact, prList, prSearch, prAddress, prTelecom);

  TfrmContact = class(TfrmBase)
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FCurrentForm: TfrmBase;
    FParams: TStrings;
    FRoutePattern: string;

    Procedure HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute: Boolean); override;
    procedure showNotFoundError(const show: Boolean);
    procedure createSubForm(AInstanceClass: TFormClass; Params: TStrings; afterFormCreation: TNotifyEvent = nil);
    procedure ShowDetail;
  public
    { Public declarations }
    class function MyRoutes: TStringDynArray; override;
  protected procedure LoadDFMValues; override; end;

const
  SFormContainer = 'subForms';

  formRoutes: Array [TContactRoute] of string = ('', 'contact', 'list', 'search', 'address', 'telecom');

var
  frmContact: TfrmContact;

implementation

{$R *.dfm}

uses
  Modules.Server,
  Forms.Contacts.List,
  Forms.Contact.Detail,
  Forms.Contact.Address,
  Forms.Contact.Telecom
  ;

function GetContactRoute(S: String): TContactRoute;
begin
  Result:=High(TContactRoute);
  while (Result<>prUnknown) and Not SameText(S, formRoutes[Result]) do
    Result:=Pred(Result);
end;

procedure TfrmContact.WebFormDestroy(Sender: TObject);
begin
  inherited;
  if Assigned(FParams) then
    FParams.Free;
end;

procedure TfrmContact.WebFormCreate(Sender: TObject);
begin
  inherited;
  FParams := TStringList.Create;
end;

procedure TfrmContact.createSubForm(AInstanceClass: TFormClass;
  Params: TStrings; afterFormCreation: TNotifyEvent);
var
  lParams: TStrings;
begin
  if Assigned(Params) then
  begin
    lParams := TStringList.Create;
    lParams.AddStrings(Params);
  end;
  if Assigned(FCurrentForm) then
    FCurrentForm.Free;
  Application.CreateForm(AInstanceClass, SFormContainer, FCurrentForm,
    Procedure(Sender: TObject)
    begin
      try
        FCurrentForm.setParams(lParams);
      finally
        if Assigned(lParams) then
          lParams.Free;
      end;
      FCurrentForm.ShowForm;
      if Assigned(afterFormCreation) then
        afterFormCreation(FCurrentForm);
    end);
end;

procedure TfrmContact.HandleRoute(URl: String; aRoute: TRoute; Params: TStrings;
  IsReroute: Boolean);
begin
  Inherited;
  FRoutePattern := aRoute.URLPattern;
  // check if the parameter is correct
  FParams.Clear;
  if Assigned(Params) then
  begin
    FParams.AddStrings(Params);
  end
  else
    showNotFoundError(True);
  if IsReroute then // if the form is already shown, we just need to call the new subform
    ShowDetail;
end;

class function TfrmContact.MyRoutes: TStringDynArray;
begin
  Result:=[
    'contact',
    'contact/list/:TERM',
    'contact/search/:TERM',
    'contact/new',
    'contact/edit/:CONTACTID',
    'contact/:CONTACTID/address/new',
    'contact/:CONTACTID/address/edit/:ADDRESSID',
    'contact/:CONTACTID/address/delete/:ADDRESSID',
    'contact/:CONTACTID/telecom/new',
    'contact/:CONTACTID/telecom/edit/:TELECOMID',
    'contact/:CONTACTID/telecom/delete/:TELECOMID'
  ];
end;

procedure TfrmContact.ShowDetail;
Var
  S: Array of string;
  formName: string;
  aOperation: TCrudOperation;
  aformClass: TBaseFormClass;
  doAfter: Boolean;
  aRoute : TContactRoute;

  procedure AfterCreation(Sender: TObject);

  Var
    aTerm : String;

  begin
    aTerm:=FParams.Values['TERM'];
    Case aRoute of

      prList: (Sender as TfrmContactList).DoSearch(True, aTerm);
      prSearch: (Sender as TfrmContactList).DoSearch(False, aTerm);
      prAddress:
        (Sender as TfrmContactDetail).DoDeleteAddress(FParams.Values['ADDRESSID']);
      prTelecom:
        (Sender as TfrmContactDetail).DoDeleteTelecom(FParams.Values['TELECOMID']);
    End;
  end;

  Procedure DoCreateForm;
  begin
    if doAfter then
      createSubForm(aformClass, FParams, @AfterCreation)
    else
      createSubForm(aformClass, FParams)
  end;

begin
  if (Length(FRoutePattern) > 0) AND (FRoutePattern[Length(FRoutePattern)] = '/') then
    FRoutePattern := Copy(FRoutePattern, 1, Length(FRoutePattern) - 1);
  S:=FRoutePattern.Split('/');


  if Length(S)>3 then
  begin
    formName:=LowerCase(S[2]);
    aOperation:=GetCrudUperation(S[3]);
  end
  else
  begin
    aOperation:=coRead;
    if Length(S) > 1 then
    begin
      if (LowerCase(S[1]) = 'edit') OR (LowerCase(S[1]) = 'new') then
      begin
        aOperation:=GetCrudUperation(S[1]);
        formName := 'contact';
      end
      else
        formName:=LowerCase(S[1]);
    end
    else
      formName := 'list';
  end;
  aformClass:=Nil;
  doAfter:=False;
  // Check form.
  aRoute := GetContactRoute(formName);
  Case aRoute of
    prList, prSearch:
      begin
        aformClass := TfrmContactList;
        doAfter := True;
      end;
    prContact:
      begin
        aformClass:=TfrmContactDetail;
        if aOperation=coDelete then
        begin
          doAfter:=True;
        end;
      end;
    prAddress:
      begin
        aformClass := TfrmContactDetail;
        doAfter := aOperation = coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass := TfrmContactAddress;
      end;
    prTelecom:
      begin
        aformClass := TfrmContactDetail;
        doAfter := aOperation = coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass := TfrmContactTelecom;
      end;
  end;
  if aformClass<>Nil then
    if aFormClass.NeedsParams then
      dmServer.DoOnParamsLoaded(@DoCreateForm)
    else
      DoCreateForm;
end;

procedure TfrmContact.showNotFoundError(const show: Boolean);
begin
  setElementVisible('NotFoundError', show);
  setElementVisible('totalContacts', not show);
end;

procedure TfrmContact.WebFormShow(Sender: TObject);
begin
  inherited;
  ShowDetail;
end;

procedure TfrmContact.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
  finally
  end;
end;

end.
