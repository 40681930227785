unit Forms.CSR.Identity;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Identity, Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions,
  pas2web.dataelementmapper, pas2web.dadatasethelper;

type
  TfrmCSRIdentity = class(TfrmIdentity)
    dsSettlement: TP2WDADataset;
    eacSettlement: TPW2DataElementMapper;
    dsSettlementdststartedon: TDateTimeField;
    dsSettlementdstcourtfk: TLargeintField;
    dsSettlementjudgename: TWideStringField;
    dsCourt: TP2WDADataset;
    procedure dsCourtAfterOpen(DataSet: TDataSet);
    procedure dsSettlementAfterOpen(DataSet: TDataSet);
    procedure OnLocateCourtName(Sender: TObject; Source:
        TField; Dest: TElementAction; var aHandled: Boolean);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure SetStartedOnValue(Sender: TObject;
      Source: TField; Dest: TElementAction; var aHandled: Boolean);
  private
    { Private declarations }
  protected
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRIdentity: TfrmCSRIdentity;

implementation

{$R *.dfm}

uses
  libjquery,
  Units.ActionUtils,
  Units.Types,
  Units.HTMLUtils,
  Modules.Server;

procedure TfrmCSRIdentity.WebFormCreate(Sender: TObject);
begin
  inherited;
  jQuery('#extratab').css('display', '');

  dsSettlement.DAConnection := DossierConnection;

  Server.ParameterConnection.Message.ClientID := Server.ClientID;
  dsCourt.DAConnection := Server.ParameterConnection;
end;

function TfrmCSRIdentity.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRIdentity.DossierPrefix: string;
begin
  Result:='CSR';
end;

procedure TfrmCSRIdentity.dsCourtAfterOpen(DataSet: TDataSet);
begin
  dsSettlement.Load([], nil);
end;

procedure TfrmCSRIdentity.dsSettlementAfterOpen(DataSet: TDataSet);
begin
  eacSettlement.DatasetToElements;
end;

procedure TfrmCSRIdentity.SetStartedOnValue(
  Sender: TObject; Source: TField; Dest: TElementAction; var aHandled: Boolean);
begin
  if not IsEmptyDate(Source.AsDateTime) then
    alForm['ddtStart'].Value := FormatHTMLDate(Source.asDateTime);
  aHandled := True;
end;

procedure TfrmCSRIdentity.OnLocateCourtName(Sender:
    TObject; Source: TField; Dest: TElementAction; var aHandled: Boolean);
begin
  dsCourt.Locate('corid', Source.asInteger, []);
  alForm[Dest.Name].Value := dsCourt.FieldByName('corname').asString;
  aHandled := True;
end;

class function TfrmCSRIdentity.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRIdentity.setParams(const Params: TStrings);
begin
  inherited;
  dsSettlement.ParamByName('DOSSIERID').asInteger := DossierID;
  dsCourt.Load([], nil);
end;

procedure TfrmCSRIdentity.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsSettlement := TP2WDADataset.Create(Self);
  dsSettlementdststartedon := TDateTimeField.Create(Self);
  dsSettlementdstcourtfk := TLargeintField.Create(Self);
  dsSettlementjudgename := TWideStringField.Create(Self);
  eacSettlement := TPW2DataElementMapper.Create(Self);
  dsCourt := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsSettlement.BeforeLoadDFMValues;
  dsSettlementdststartedon.BeforeLoadDFMValues;
  dsSettlementdstcourtfk.BeforeLoadDFMValues;
  dsSettlementjudgename.BeforeLoadDFMValues;
  eacSettlement.BeforeLoadDFMValues;
  dsCourt.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSaveApplicant';
      Name := 'btnSaveApplicant';
      SetEvent(Self, 'OnExecute', 'SaveApplicant');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSavePartner';
      Name := 'btnSavePartner';
      SetEvent(Self, 'OnExecute', 'SavePartner');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'first';
      PreventDefault := False;
      Selector := 'a[href="#first"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'firstTabClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'second';
      PreventDefault := False;
      Selector := 'a[href="#second"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'secondTabClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtDescription';
      Name := 'edtDescription';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtAppRemarks';
      Name := 'edtAppRemarks';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtCourt';
      Name := 'edtCourt';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtJudgeName';
      Name := 'edtJudgeName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtStart';
      Name := 'ddtStart';
    end;
    dsSettlement.SetParentComponent(Self);
    dsSettlement.Name := 'dsSettlement';
    dsSettlement.TableName := 'dossiersettlementoverview';
    dsSettlement.Params.Clear;
    with dsSettlement.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    dsSettlement.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>dstdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsSettlement.DAOptions := [];
    dsSettlement.AfterOpen := dsSettlementAfterOpen;
    dsSettlement.Left := 40;
    dsSettlement.Top := 216;
    dsSettlementdststartedon.SetParentComponent(dsSettlement);
    dsSettlementdststartedon.Name := 'dsSettlementdststartedon';
    dsSettlementdststartedon.FieldName := 'dststartedon';
    dsSettlementdstcourtfk.SetParentComponent(dsSettlement);
    dsSettlementdstcourtfk.Name := 'dsSettlementdstcourtfk';
    dsSettlementdstcourtfk.FieldName := 'dstcourtfk';
    dsSettlementjudgename.SetParentComponent(dsSettlement);
    dsSettlementjudgename.Name := 'dsSettlementjudgename';
    dsSettlementjudgename.FieldName := 'judgename';
    dsSettlementjudgename.Size := 255;
    eacSettlement.SetParentComponent(Self);
    eacSettlement.Name := 'eacSettlement';
    eacSettlement.ActionLinks.Clear;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'edtCourt';
      FieldName := 'dstcourtfk';
      Options := [];
      SetEvent(Self, 'OnFieldToAction', 'OnLocateCourtName');
    end;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'ddtStart';
      FieldName := 'dststartedon';
      Options := [];
      SetEvent(Self, 'OnFieldToAction', 'SetStartedOnValue');
    end;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'edtJudgeName';
      FieldName := 'judgename';
      Options := [];
    end;
    eacSettlement.ActionList := alForm;
    eacSettlement.Dataset := dsSettlement;
    eacSettlement.Left := 48;
    eacSettlement.Top := 272;
    dsCourt.SetParentComponent(Self);
    dsCourt.Name := 'dsCourt';
    dsCourt.TableName := 'CourtOverview';
    dsCourt.DAOptions := [];
    dsCourt.AfterOpen := dsCourtAfterOpen;
    dsCourt.Left := 32;
    dsCourt.Top := 160;
  finally
    alForm.AfterLoadDFMValues;
    dsSettlement.AfterLoadDFMValues;
    dsSettlementdststartedon.AfterLoadDFMValues;
    dsSettlementdstcourtfk.AfterLoadDFMValues;
    dsSettlementjudgename.AfterLoadDFMValues;
    eacSettlement.AfterLoadDFMValues;
    dsCourt.AfterLoadDFMValues;
  end;
end;

end.
