unit Forms.CSR.SettlementPlan.Copy;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  pas2web.dataelementmapper, Data.DB, Datasnap.DBClient, pas2web.dadataset;

type
  TfrmBase = class(TFrmDossierDetail);
  TfrmSettlementPlanCopy = class(TfrmBase)
    dsSettlement: TP2WDADataset;
    eacSettlement: TPW2DataElementMapper;
    procedure dsSettlementAfterOpen(DataSet: TDataSet);
    procedure OnCopySettlementClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure onItemValidationError(Sender: TObject; Source: TElementAction; dest:
        TField; aError: TElementError);
  procedure WebFormDestroy(Sender: TObject);
  procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FSettlementID: NativeInt;
    function OnValidFromChanged(Evt: TEventListenerEvent): Boolean;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix: string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmSettlementPlanCopy: TfrmSettlementPlanCopy;

implementation

Uses
  Modules.Server, libjquery, Units.HTMLUtils, Units.ActionUtils, dateutils,
  Units.Service.Dezq;

{$R *.dfm}

function TfrmSettlementPlanCopy.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmSettlementPlanCopy.dsSettlementAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
  begin
    jQuery('#form').css('display', '');
    if Dataset.FieldByName('sepvalidfrom').asDateTime > now then
      jQuery('#ddtValidFrom').attr('min', FormatHTMLDate( Dataset.FieldByName('sepvalidfrom').asDateTime))
    else
      jQuery('#ddtValidFrom').attr('min', FormatHTMLDate( now ));
    jQuery('#ddtValidFrom').attr('max', FormatHTMLDate( Dataset.FieldByName('sepvalidtill').asDateTime ));
  end
  else
  begin
    jQuery('#settlementNotFound').css('display', '');
  end;
end;

procedure TfrmSettlementPlanCopy.OnCopySettlementClick(Sender: TObject;
    Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  options : TCopySettlementPlanOptions;

  procedure onResponse(anID : Int64; aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
       Server.ShowError(anError)
    else
      window.location.href := '#/CSR/' + InttoStr(DossierID) +
        '/settlementplan/edit/' + InttoStr(anID);
  end;

begin
  ClearFormErrors;
  if eacSettlement.ValidateActions(True) then
  begin
    options.aSourceID := FSettlementID;
    options.Description := alForm['edtDescription'].Value;
    options.StartDate := TJSDate.new(Trim(alForm['ddtValidFrom'].Value));
    Server.CopySettlementPlan(options, @onResponse);
  end;
end;

procedure TfrmSettlementPlanCopy.onItemValidationError(Sender: TObject; Source:
    TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.Name);
end;

function TfrmSettlementPlanCopy.OnValidFromChanged(
  Evt: TEventListenerEvent): Boolean;
var
  startDate : TDateTime;
begin
  if Trim(alForm['ddtValidFrom'].Value) <> '' then
  begin
    startDate := JSDateToDateTime(TJSDate.new(Trim(alForm['ddtValidFrom'].Value)));
    jQuery('#ddtValidTill').val(FormatHTMLDate(IncYear(startDate, 7)));
  end;
  Result := True;
end;

procedure TfrmSettlementPlanCopy.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#returnBack').attr('href',DossierURL('/settlementplan'));
  If Assigned(Params) and (Params.indexOfName('sepID')<>-1) then
  begin
    FSettlementID := StrToIntDef(Params.Values['sepID'],-1);
  end;
  dsSettlement.ParamByName('SEPID').asInteger := FSettlementID;
  dsSettlement.ParamByName('DOSSIERID').asInteger := DossierID;
  dsSettlement.Load([], nil);
end;

procedure TfrmSettlementPlanCopy.WebFormCreate(Sender: TObject);
begin
  inherited;
  FSettlementID := -1;

  Server.CSRConnection.Message.ClientID := Server.ClientID;
  dsSettlement.DAConnection := Server.CSRConnection;

  jQuery('#ddtValidFrom').On_('change', @OnValidFromChanged);

end;

procedure TfrmSettlementPlanCopy.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmSettlementPlanCopy.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsSettlement := TP2WDADataset.Create(Self);
  eacSettlement := TPW2DataElementMapper.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsSettlement.BeforeLoadDFMValues;
  eacSettlement.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtDescription';
      Name := 'edtDescription';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'ddtValidFrom';
      Name := 'ddtValidFrom';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidTill';
      Name := 'ddtValidTill';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnCopy';
      Name := 'btnCopy';
      SetEvent(Self, 'OnExecute', 'OnCopySettlementClick');
    end;
    dsSettlement.SetParentComponent(Self);
    dsSettlement.Name := 'dsSettlement';
    dsSettlement.TableName := 'settlementplan';
    dsSettlement.Params.Clear;
    with dsSettlement.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SEPID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsSettlement.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    dsSettlement.WhereClause := '<?xml version="1.0" encoding="UTF-8"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>sepid</field><parameter type="LargeInt">SEPID</parameter></binaryoperation><binaryoperation operator="Equal"><field>sepdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></binaryoperation></where></query>';
    dsSettlement.DAOptions := [doRefreshAllFields];
    dsSettlement.AfterOpen := dsSettlementAfterOpen;
    dsSettlement.Left := 40;
    dsSettlement.Top := 88;
    eacSettlement.SetParentComponent(Self);
    eacSettlement.Name := 'eacSettlement';
    eacSettlement.ActionLinks.Clear;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'edtDescription';
      FieldName := 'sepdescription';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
    end;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'ddtValidFrom';
      FieldName := 'sepvalidfrom';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
    end;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'ddtValidTill';
      FieldName := 'sepvalidtill';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
    end;
    eacSettlement.ActionList := alForm;
    eacSettlement.Dataset := dsSettlement;
    eacSettlement.Left := 40;
    eacSettlement.Top := 160;
  finally
    alForm.AfterLoadDFMValues;
    dsSettlement.AfterLoadDFMValues;
    eacSettlement.AfterLoadDFMValues;
  end;
end;

end.
