unit Forms.ImportContacts;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, Units.Types, pas2web.datatables,
  //Forms.DossierDetail,
  pas2web.dadatasethelper, WEBLib.REST, Vcl.Controls,
  WEBLib.WebCtrls;

type
  TImportFileType = (ftUnknown, ftCSV, ftJSON);

  TfrmImportContacts = class(TfrmBase)
    dcFiles: TP2WDatatable;
    dsFiles: TP2WDADataset;
    WebHttpRequest: THttpRequest;
    procedure dsFilesAfterOpen(DataSet: TDataSet);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure WebFileUploadUploadFileComplete(Sender: TObject;
      AFileIndex: Integer);
    procedure onbtnMappingClick(Sender: TObject;
      Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebHttpRequestAbort(Sender: TObject);
    procedure WebHttpRequestRequestResponse(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure WebHttpRequestError(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
      var Handled: Boolean);
    procedure WebHttpRequestTimeout(Sender: TObject);
  private
    FRoute : String;
    FDeleteID : Int64;
    FDoDelete : Boolean;
    FDelimiter: char;
    FImportFileNumberofLines: integer;
    FImportFileType : TImportFileType;
    FImportFileAsString : string;
    FImportFileColumnNames:string;
    FImportFileColumnNamesMapped: string;
    FImportFileAsStringWithoutColumnNames: string;
    FImportFileAsStringForSending : string;
    procedure AddToTheListOfImportedFiles(aFilename: string);
    [async]
    procedure UploadFile; async;
    procedure UploadFileInputChange(Sender: TObject);
    procedure GetFileasStringFromJSHTMLFile(ajsFile: TJSHTMLFile);
    procedure FetchColumnsandSend;
    function DecodeBase64(const aEncodedStr: string): string;
    procedure ProcessComingbackFromMapping;
    procedure SetImportMessage(const aMessageStr: string);
    function CountChar(const S: string; const C: Char): Integer;
  public
    property ImportFileNumberofLines: integer read FImportFileNumberofLines;
  protected procedure LoadDFMValues; override; end;

var
  frmImportContacts: TfrmImportContacts;

const
  cExportBestandsnaam = 'DezqContactsImported';
  cJSON = 'json';
  cRESTCallExportContacts = 'fetchContactsImported';
  // constants being used for comm between import and import.mapping
  cLocalDataImportReturn = 'importreturn';
  cLocalDataImportMapping = 'importmapping';
  cLocalDataImportFile = 'importfile';
  cLocalDataImportFileReturn = 'importfilereturn';
  cLocaldataImportDelimiter ='importdelimiter';
  //constants used for restclient
  cURL = 'http://localhost:8099/';
  cElementIdImportMsg = 'lblimportmsg'; // id of the element where errormessage are supposed to go.

implementation

{$R *.dfm}

uses
  libjquery,  Modules.Server, Units.Strings, Units.ActionUtils,
  System.NetEncoding,
  TypInfo, System.StrUtils;

procedure TfrmImportContacts.UploadFile;
var sFileName : string;
    iNumberofRecords: Integer;
    sPostData : string;
    sUrl : string;
begin
  inherited;
  // Send to rest Server
  sPostData := '{"Password": "test","Username": "test"}';
  iNumberofRecords := 0;
  sUrl := cUrl + 'api/login/login';

  WebHttpRequest.Command := httpPOST;
  WebHttpRequest.URL := sUrl;
  WebHttpRequest.PostData := sPostdata;
  WebHttpRequest.Execute;
  try
    // let's run this asynchronously
    await(string, WebHttpRequest.Perform);
  except
    //some error handling
  end;
  // add to the list of exported files
  AddToTheListOfImportedFiles(sFileName);
end;

procedure TfrmImportContacts.AddToTheListOfImportedFiles(aFilename: string);
begin
    exit;
    // vullen we dat hier, of doen we dat in de server, en halen we gewoon op?
    dsFiles.Insert;
    dsFiles.FieldByName('imfcreatedon').asDateTime := now;
    dsFiles.FieldByName('imfcreatedbyfk').asLargeInt := Server.UserID;
    // dsFiles.FieldByName('exfcontactfk').asLargeInt := FContactID;  clientid? hoe doen we de clientseparation?
    dsFiles.FieldByName('imfchangedon').asDateTime := now;
    dsFiles.FieldByName('imfchangedbyfk').asLargeInt := Server.UserID;
    dsFiles.FieldByName('imffilename').asString := aFilename;
    dsFiles.FieldByName('imffiletype').asString := cJSON;
    dsFiles.FieldByName('imfnumberofrecords').asLargeInt := ImportFileNumberofLines;
    dsFiles.Post;
    dsFiles.ApplyUpdates;
end;

procedure TfrmImportContacts.onbtnMappingClick(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  inherited;
  window.location.href :='#importmapping';
end;

procedure TfrmImportContacts.dsFilesAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
//  aResult := dsFiles.GetNewRows(False);
//  dcFiles.Data := aResult;
//  dcFiles.RenderTranslated;
end;

procedure TfrmImportContacts.WebFormDestroy(Sender: TObject);
begin
  inherited;
  nop;
end;

procedure TfrmImportContacts.WebFileUploadUploadFileComplete(Sender: TObject;
  AFileIndex: Integer);
begin
  inherited;
  alform.actions[0].Enabled := True; //btnMapping
end;

procedure TfrmImportContacts.UploadFileInputChange(Sender: TObject);
var
  inputElement: TJSElement;
  files: TJSHTMLFileList;
  i: Integer;
  list: TJSElement;
  listItem: TJSElement;
  elBtnMapping: TJSElement;
  jsFile: TJSHTMLFile;
begin
  SetImportMessage(EmptyStr);
  inputElement := document.querySelector('.fileupload-dropzone');  // Get the file input element
  files := TJSHTMLInputElement(inputElement).files; // Get the uploaded files
  list := document.querySelector('#upimportlist');
  while list.hasChildNodes do list.removeChild(list.firstChild);
  for i := 0 to files.length - 1 do
  begin
    jsFile := files.item(i);
    listItem := document.createElement('li');
    listItem.textContent := files.item(i).name;
    list.appendChild(listItem);
    console.log('File name: ' + files.item(i).name);
    console.log('File type: ' + files.item(i)._type);
    console.log('File size: ' + IntToStr(files.item(i).size) + ' bytes');
    console.log('Last modified: ' + files.item(i).lastModifiedDate.ToDateString);
    GetFileasStringFromJSHTMLFile(jsFile);
  end;
  // Enable the mapping button
  elBtnMapping := document.getElementById('btnMapping');
  if files.length > 0
    then TJSHTMLButtonElement(elBtnMapping).disabled := False
    else TJSHTMLButtonElement(elBtnMapping).disabled := True;
end;

procedure TfrmImportContacts.GetFileasStringFromJSHTMLFile(ajsFile: TJSHTMLFile);
var
  reader: TJSFileReader;
begin
  reader:= TJSFileReader.new;
  // asynchrone filling with anonymous proc, hence this is no function.
  reader.AddEventListener('load', TJSRawEventHandler(
    procedure (Event: TJSEvent)
      begin
        FImportFileAsString:= string(reader.result);
        FetchColumnsandSend;
      end
    ));
  reader.AddEventListener('error', TJSRawEventHandler(procedure (Event: TJSEvent)
    begin
     dmServer.ShowError('Error reading import file '+TJSJSON.Stringify(event));
    end
  ));
  reader.ReadAsDataURL(ajsFile);
end;

procedure TfrmImportContacts.FetchColumnsandSend;
var
  fileData: string;
  fileType: TImportFileType;
  commaPos: Integer;
begin
  if StartsText('data:text/csv;', FImportFileAsString)
    then
      fileType := TImportFileType.ftCSV
    else
      begin
        if StartsText('data:application/json;', FImportFileAsString)
          then fileType := TImportFileType.ftJSON
          else fileType := TImportFileType.ftUnknown;
      end;

  if fileType = TImportFileType.ftUnknown then
    dmServer.ShowError('Error, read import file is of unsupported type.')
  else
    begin
      FImportFileType := fileType; // set the global variable to the detected file type
      commaPos := Pos(',', FImportFileAsString); //always comma, prologue looks like this data:text/csv;Base64,A4bcd6SXRG...
      if commaPos > 0
        then FImportFileAsString := Copy(FImportFileAsString, commaPos + 1, Length(FImportFileAsString) - commaPos);
      // reconvert Base64 encoding to readable text
      FImportFileAsString := DecodeBase64(FImportFileAsString);
      // now establish the Fdelimiter in the decoded content of the string
      if Pos(';', FImportFileAsString) > 0 then
        begin
          FDelimiter := ';';
        end
      else
        begin
          FDelimiter := ',';
        end;
      //Take out only the first line with the column names
      FImportFileColumnNames := Copy(FImportFileAsString, 1, Pos(#13#10, FImportFileAsString) - 1);
      server.setLocalData(cLocalDataImportFile, FImportFileColumnNames);
      FImportFileNumberofLines := CountChar(FImportFileAsString, #10) + 1; // number lines always one more then separators
    end;
end;

function TfrmImportContacts.CountChar(const S: string; const C: Char): Integer;
var
  I: Integer;
begin
  Result := 0;
  for I := 1 to Length(S) do
    if S[I] = C then
      Inc(Result);
end;


function TfrmImportContacts.DecodeBase64(const aEncodedStr: string): string;
var
  decoder: TBase64Encoding;
begin
  decoder := TBase64Encoding.Create;
  try
    Result := decoder.Decode(aEncodedStr);
  finally
    decoder.Free;
  end;
end;


procedure TfrmImportContacts.WebFormCreate(Sender: TObject);
var
  inputElement: TJSElement;
begin
  inherited;
  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsFiles.DAConnection := dmserver.ContactConnection;
  inputElement := document.querySelector('.fileupload-dropzone'); // Get the file input element
  TJSHTMLInputElement(inputElement).addEventListener('change', @UploadFileInputChange); // Add an event handler to the file input element's onChange event
  WebHttpRequest.Headers.Clear;
  WebHttpRequest.Headers.AddPair('Content-Type','application/json');
  WebHttpRequest.Headers.AddPair('Accept','application/json');
end;

procedure TfrmImportContacts.WebFormShow(Sender: TObject);
var iImportReturn: Integer;
begin
  inherited;
  alform.actions[0].Enabled := False; //btnSave
  dsFiles.Load([], nil);
  iImportReturn := StrToIntDef(Server.GetLocalData(cLocalDataImportReturn),0);
  if (iImportReturn = 1) then ProcessComingbackFromMapping;
end;

procedure TfrmImportContacts.WebHttpRequestAbort(Sender: TObject);
begin
  inherited;
  console.log('WebHttpRequestAbort called, sender:' + sender.classname);
  SetImportMessage('Upload file aborted.');
end;

procedure TfrmImportContacts.WebHttpRequestError(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
  var Handled: Boolean);
begin
  inherited;
  console.log(DateTimeToStr(now) + ' : WebHttpRequestError!' );
  SetImportMessage('Error uploading file.');
  Handled := True;
end;

procedure TfrmImportContacts.WebHttpRequestRequestResponse(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; AResponse: string);
begin
  inherited;
  console.log('WebHttpRequestRequestResponse: '+ AResponse);
  SetImportMessage('File uploaded.');
end;

procedure TfrmImportContacts.WebHttpRequestTimeout(Sender: TObject);
begin
  inherited;
  console.log('TfrmImport.WebHttpRequestTimeout');
  SetImportMessage('Upload file timed out.');
end;

procedure TfrmImportContacts.SetImportMessage(const aMessageStr: string);
var
  lblImportMessage: TJSElement;
begin
  lblImportMessage := document.getElementById(cElementIdImportMsg);
  lblImportMessage.innerHTML := aMessageStr;
end;

procedure TfrmImportContacts.ProcessComingbackFromMapping;
var
  iMapped : Integer;
const
  cCancelled = 0;
  cMapped = 1;
begin
  FDelimiter := Server.GetLocalData(cLocaldataImportDelimiter)[0];
  iMapped := StrToIntDef(Server.GetLocalData(cLocalDataImportMapping),0);
  case iMapped of
   cCancelled : begin
                  // fils are wiped out of the uploadlist automatically.
                  nop;
                end;
   cMapped    : begin
                  FImportFileColumnNamesMapped := Server.GetLocalData(cLocalDataImportFileReturn);
                  FImportFileAsStringForSending := FImportFileColumnNamesMapped + #10#13 + FImportFileAsStringWithoutColumnNames;
                  UploadFile;
                end;
   else
     nop; // error
  end;
  Server.SetLocalData(cLocaldataImportDelimiter, EmptyStr);
  Server.SetLocalData(cLocalDataImportReturn, EmptyStr);
  Server.SetLocalData(cLocalDataImportMapping, EmptyStr);
  Server.SetLocalData(cLocalDataImportFile, EmptyStr);
  Server.SetLocalData(cLocalDataImportFileReturn, EmptyStr);
end;

procedure TfrmImportContacts.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dcFiles := TP2WDatatable.Create(Self);
  dsFiles := TP2WDADataset.Create(Self);
  WebHttpRequest := THttpRequest.Create(Self);

  alForm.BeforeLoadDFMValues;
  dcFiles.BeforeLoadDFMValues;
  dsFiles.BeforeLoadDFMValues;
  WebHttpRequest.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnUpload';
      Name := 'btnUpload';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnMapping';
      Name := 'btnMapping';
      SetEvent(Self, 'OnExecute', 'onbtnMappingClick');
    end;
    dcFiles.SetParentComponent(Self);
    dcFiles.Name := 'dcFiles';
    dcFiles.DataSet := dsFiles;
    dcFiles.Language := lEnglish;
    dcFiles.IsResponsive := True;
    dcFiles.GridID := 'grdFiles';
    dcFiles.UseFieldIndex := True;
    dcFiles.ShowSearch := False;
    dcFiles.ShowNumberOfEntries := False;
    dcFiles.ShowEntriesInfo := False;
    dcFiles.Paginate := True;
    dcFiles.DisplayReadOnly := False;
    dcFiles.CalculateTableWidth := True;
    dcFiles.Left := 40;
    dcFiles.Top := 184;
    dsFiles.SetParentComponent(Self);
    dsFiles.Name := 'dsFiles';
    dsFiles.TableName := 'contactaddress';
    dsFiles.DAConnection := dmServer.ContactConnection;
    dsFiles.DAOptions := [doRefreshAllFields];
    dsFiles.AfterOpen := dsFilesAfterOpen;
    dsFiles.Left := 40;
    dsFiles.Top := 104;
    WebHttpRequest.SetParentComponent(Self);
    WebHttpRequest.Name := 'WebHttpRequest';
    WebHttpRequest.Command := httpPOST;
    WebHttpRequest.Headers.BeginUpdate;
    try
      WebHttpRequest.Headers.Clear;
      WebHttpRequest.Headers.Add('Cache-Control=no-cache');
    finally
      WebHttpRequest.Headers.EndUpdate;
    end;
    SetEvent(WebHttpRequest, Self, 'OnAbort', 'WebHttpRequestAbort');
    SetEvent(WebHttpRequest, Self, 'OnError', 'WebHttpRequestError');
    SetEvent(WebHttpRequest, Self, 'OnRequestResponse', 'WebHttpRequestRequestResponse');
    SetEvent(WebHttpRequest, Self, 'OnTimeout', 'WebHttpRequestTimeout');
    WebHttpRequest.Left := 40;
    WebHttpRequest.Top := 264;
  finally
    alForm.AfterLoadDFMValues;
    dcFiles.AfterLoadDFMValues;
    dsFiles.AfterLoadDFMValues;
    WebHttpRequest.AfterLoadDFMValues;
  end;
end;

end.
