unit Forms.CSR.Family;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Family, pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRFamily = class(TfrmFamily)
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
    class function GetHTMLFileName: string; override;
    function DossierPrefix : string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRFamily: TfrmCSRFamily;

implementation

{$R *.dfm}


{ TfrmCSRFamily }


function TfrmCSRFamily.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRFamily.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRFamily.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRFamily.WebFormCreate(Sender: TObject);
begin
  inherited;
  dcChild.Columns[5].ButtonURL:='#/CSR/{{docdossierfk}}/relation/edit/{{cntid}}/child';
  dcChild.Columns[6].ButtonURL:='#/CSR/{{docdossierfk}}/relation/delete/{{docid}}/child';
  dcHouse.Columns[5].ButtonURL:='#/CSR/{{docdossierfk}}/relation/edit/{{cntid}}/house';
  dcHouse.Columns[6].ButtonURL:='#/CSR/{{docdossierfk}}/relation/delete/{{docid}}/house';
end;

procedure TfrmCSRFamily.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcChild.BeforeLoadDFMValues;
  try
    dcChild.Columns.Clear;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntlastname';
      Title := 'Last Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntfirstname';
      Title := 'First Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntgender';
      Title := 'Gender';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGenderGetValue');
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntbirthdateon';
      Title := 'Date Of Birth';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
      DateTimeFormat := 'dd-mm-yyyy';
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'docpersontype';
      Title := 'Child of';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnGetPersonTypeGetValue');
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'cntid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      Width := '50px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{docdossierfk}}/relation/edit/{{cntid}}/child';
    end;
    with dcChild.Columns.Add do
    begin
      FieldName := 'docdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Width := '50px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{docdossierfk}}/relation/delete/{{docid}}/child';
    end;
  finally
    dcChild.AfterLoadDFMValues;
  end;
end;

end.
