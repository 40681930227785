unit Units.Types;

interface

uses
  System.SysUtils;

type
  ECompanyStatusException = Exception;
  EDezq = class(Exception);
  EDezqValidation = class(EDezq);

  TTypeDossier = (tdUnknown, tdPetition, tdCSR);

  TTypeDossierHelper = record helper for TTypeDossier
  private
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
  public
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TDebtRecordType = (dtUnknown, dtDebt, dtSalaryTransfer);

  TSeizureSourceType = (sstUnknown, sstProperty, sstRevenue);

  TTaskType = (tatUnknown, tatCustom, tatCreateTrustAccount, tatCheckSeizures, tatCheckWarranty, tatLetterRegistration,
    tatLetterNBB, tatLetterDebtors, tatLetterWarrantor, tatLetterLivingBudget);

  TTaskTypeHelper = record helper for TTaskType
  private
    function GetAsString: string;
    procedure SetAsInteger(const Value: Integer);
    function ToInteger: Integer;
  public
    property AsInteger: Integer read ToInteger write SetAsInteger;
    property AsString: string read GetAsString;
  end;

  TTaskStatus = (tsUnknown, tsNew, tsSeen, tsAssigned, tsHandled);

  TTaskStatusHelper = record helper for TTaskStatus
  private
    function GetAsString: string;
    procedure SetAsInteger(const Value: Integer);
    function ToInteger: Integer;
  public
    property AsInteger: Integer read ToInteger write SetAsInteger;
    property AsString: string read GetAsString;
  end;

  TPaymentOrderStatus = (posUnknown, posScheduled, posProcessing, posProcessed, posConfirmed);

  TPaymentOrderStatusHelper = record helper for TPaymentOrderStatus
  private
    function GetAsString: string;
    procedure SetAsInteger(const Value: Integer);
    function ToInteger: Integer;
  public
    property AsInteger: Integer read ToInteger write SetAsInteger;
    property AsString: string read GetAsString;
  end;

  TUserStatus = (usNotActive, usNotExists, usBlocked15, usBlocked, usOK);

  TUserStatusHelper = record helper for TUserStatus
  private
    function GetAsString: string;
  public
    property AsString: string read GetAsString;
  end;

  TCompanyStatus = (csUnknown, csSignOn, csActive, csReadOnly, csDeactivated, csRefused);

  TTokenType = (ttUnknown, ttCaptcha, ttResetPassword);

  TJobQueueStatus = (jqsScheduled, jqsSelected, jqsInProgress, jqsFinishOK, jqsFinishNOK, jqsCancelled);
  TJobQueueType = (jqtUnknown, jqtClosePetition, jqtCreateInvoice, jqtSendMail, jqtCodaImport, jqtCodaFileImport,
    jqtPrintjob, jqtSignDocument, jqtCopyPetitionToCSR, jqtPontoRefreshBankAccount, jqtPontoDailyRefreshBankAccounts,
    jqtPontoGeneratePayment, jqtGeneratePaymentOrder);
  TJobQueueTypeSet = set of TJobQueueType;
  TJobType = TJobQueueType;
  TProcessType = (prcUnknown, prcMainService, prcClosePetition, prcCreateInvoice, prcCodaProcessing, prcCodaFileImport,
    prcMailer, prcPrintingService, prcPontoPull, prcPontoPush);

  TCompanyStatusHelper = record helper for TCompanyStatus
  private
    procedure SetAsInteger(const Value: Integer);
    function ToString: string;
    function ToInteger: Integer;
  public
    class function IntToStatus(const Value: Integer): TCompanyStatus; static;
    property AsInteger: Integer read ToInteger write SetAsInteger;
    property AsString: string read ToString;
  end;

  TLetterModelType = (lmUnknown, lmReport, lmMailText, lmMailHTML, lmDataModel);

  TLanguage = (lEnglish, lDutch, lFrench);

  TLanguageHelper = record helper for TLanguage
  private
    procedure SetAsInteger(const Value: Integer);
    function GetAsString: string;
  public
    class function FromString(const aValue: string): TLanguage; static;
    function ToInteger: Integer;
    property AsInteger: Integer read ToInteger write SetAsInteger;
    property AsString: string read GetAsString;
  end;

  TPrintType = (ptUnknown, ptDossier, ptReport);

  TPrintTypeHelper = record helper for TPrintType
  private
    function GetAsString: string;
    procedure SetAsString(const Value: string);
  public
    property AsString: string read GetAsString write SetAsString;
  end;

  TPrintData = class(TObject)
  private
    FPrintType: TPrintType;
    FDossierID: Int64;
    FStampDocumentNumber: Boolean;
    FExtraData: string;
    FUserID: Int64;
    FModelID: Int64;
    FResultID: Int64;
  public
    constructor Create;
    destructor Destroy; override;
    property PrintType: TPrintType read FPrintType write FPrintType;
    property DossierID: Int64 read FDossierID write FDossierID;
    property ModelID: Int64 read FModelID write FModelID;
    property UserID: Int64 read FUserID write FUserID;
    property StampDocumentNumber: Boolean read FStampDocumentNumber write FStampDocumentNumber;
    property ExtraData: string read FExtraData write FExtraData;
    property ResultID: Int64 read FResultID write FResultID;
  end;

  TDossierStatus = (dsUnknown, dsActive, dsSubmitted, dsArchivedCancelled, dsArchivedSubmitted, dsReopened);
  TMessageType = (mtUnknown, mtError, mtWarning, mtInfo);

  TMessageOrigin = (moUnknown, moCoda, moMailer, moOnboarding, moPonto, moPrinting, moDocumentSign, moPayment, moCSR);

  TRoleAuthorization = (raAdmin, raImpersonate, racompanydatawrite, raUserCreate, raJobControl, raApproveSignon,
    raParameterEdit, raDossierCreate, raDossierDataRead, raDossierDataWrite, raDossierFileDownload, raDossierFileUpload,
    raDossierJobControl, raSignon, raDossierFileDelete);
  TRoleAuthorizations = set of TRoleAuthorization;

  TMessageData = record
    CustomerID: Int64;
    LoginID: Int64;
    MsgType: TMessageType;
    MsgOrigin: TMessageOrigin;
    Title: string;
    Data: string;
    ShowOn: TDateTime;
  end;

  TTableKind = (tkUnknown, tkDossier, tkDossierFile, tkContact, tkContactID, tkContactAddress, tkContactTelecom,
    tkRevenue, tkProperty, tkLegalCase, tkDebt, tkDossierContact, tkBudgetPlan, tkSettlementPlan, tkSeizure, tkWarranty,
    tkBankAccount, tkSystemMessage);

  TTableKindHelper = record helper for TTableKind
  private
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
  public
    class function Create(aValue: Integer): TTableKind; static;
    function GetTableName: string;
    function CheckCompanySQL: string;
    function UseMainConnection: Boolean;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TTokenTypeHelper = record helper for TTokenType
  private
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
  public
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  // File source type, field dofSourceType table DossierFile
  TFileSourceType = (fstUnknown, // 0 - unknown
    fstDossier, // 1 - table Dossier
    fstContact, // 2 - Table contact
    fstContactID, // 3 - Table contactID
    fstContactAddress, // 4 - Table ContactAddress
    fstContactTelecom, // 5 - Table ContactTelecom
    fstRevenue, // 6 - Table Revenue, revenuetype<>'DEBT'
    fstProperty, // 7 - Table Property, IsRealEstate = False
    fstLegalCase, // 8 - Table LegalCase
    fstDebt, // 9 - Table Debt, debRecordType=2
    fstLogin, // 10 - Table login (main)
    fstCompany, // 11 - Table company (main)
    fstRealEstate, // 12 - Table Property, isRealEstate = True
    fstIncomeDebt, // 13 - Table Revenue, revenuetype='DEBT'
    fstSalaryTransfer // 14 - Table Debt, debRecordTYpe=2
    );

  TFileSourceTypeHelper = record helper for TFileSourceType
  private
    function GetAsInteger: Integer;
    function GetAsTableKind: TTableKind;
    procedure SetAsInteger(const Value: Integer);
    function GetAsString: string;
    function GetAsDisplayString: string;
  public
    class function Create(aValue: Integer): TFileSourceType; static;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
    property AsTableKind: TTableKind read GetAsTableKind;
    property AsString: string read GetAsString;
    property AsDisplayString: string read GetAsDisplayString;
  end;

  TRoleAuthorizationHelper = record helper for TRoleAuthorization
  private
    function GetBitIndex: Byte;
    function GetBitMask: Int64;
    procedure SetBitIndex(aIndex: Byte);
    procedure SetBitMask(aMask: Int64);
    function GetAsString: string;
  public
    property AsBitIndex: Byte read GetBitIndex write SetBitIndex;
    property AsBitMask: Int64 read GetBitMask write SetBitMask;
    property AsString: string read GetAsString;
  end;

  TByteSet = set of Byte;

  TRoleAuthorizationsHelper = record helper for TRoleAuthorizations
  private
    function GetByteSet: TByteSet;
    procedure SetByteSet(aSet: TByteSet);
    function GetBitMask: Int64;
    procedure SetBitMask(aMask: Int64);
  public
    property AsBitMask: Int64 read GetBitMask write SetBitMask;
    property AsByteSet: TByteSet read GetByteSet write SetByteSet;
  end;

  TDossierStatusHelper = record helper for TDossierStatus
  private
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
    function GetAsString: string;
    procedure SetAsString(const Value: string);
  public
    function IsArchived: Boolean;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
    property AsString: string read GetAsString write SetAsString;
  end;

  TLetterModelTypeHelper = record helper for TLetterModelType
  private
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
  public
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TJobQueueStatusHelper = record helper for TJobQueueStatus
  private
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
    function GetAsDisplayString: string;
    function GetAsString: string;
    procedure SetAsString(const Value: string);
  public
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
    property AsString: string read GetAsString write SetAsString;
    property AsDisplayString: string read GetAsDisplayString;
  end;

  TJobQueueTypeHelper = record helper for TJobQueueType
  private
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
  public
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TDebtRecordTypeHelper = record helper for TDebtRecordType
  private
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
  public
    class function New(aValue: Integer): TDebtRecordType; static;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TDossierFileData = record
  public
    BucketName: string;
    ObjectName: string;
  end;

  TDocumentData = record
    RecordID: Int64;
    Data: TDossierFileData;
    FileName: string;
    DocumentType: string;
    DocumentTypeID: Int64;
    SourceType: TFileSourceType;
    SourceID: Int64;
    DossierID: Int64;
    CaseFileNumber: Int64;
    MimeType: string;
    IsUploadComplete: Boolean;
    function Description(Full: Boolean): string;
  end;

  TReportData = record
    Design: string;
    Name: string;
    Language: TLanguage;
    DataModels: TArray<string>;
  end;

  TTranslateParam = record
    FieldName: string;
    ParamType: string;
  end;

  TTranslateParamArray = TArray<TTranslateParam>;

  TReportDataType = (rdtUnknown, rdtList, rdtCollection, rdtSimpleList);
  TReportDatabaseKind = (rdkUnknown, rdkMain, rdkDossier);

  TReportDataModel = record
    // Name of data model
    Name: string;
    // type of data model
    DataType: TReportDataType;
    // kind of database
    DatabaseKind: TReportDatabaseKind;
    // SQL to be executed
    SQL: string;
    // Fields that are parameter values which must be translated to descriptions.
    TranslateFields: TTranslateParamArray;
    // Parameters to be shown in designer (as JSON)
    Model: string;
  end;

  TReportDataModelArray = array of TReportDataModel;

  TReportParam = record
    Name: string;
    Value: {$IFDEF PAS2JS}JSValue{$ELSE}variant{$ENDIF};
  end;

  TReportParamArray = array of TReportParam;

  TReportDataTypeHelper = record helper for TReportDataType
  private
    function GetAsString: string;
    procedure SetAsString(const Value: string);
  public
    property AsString: string read GetAsString write SetAsString;
  end;

  TReportDatabaseKindHelper = record helper for TReportDatabaseKind
  private
    function GetAsString: string;
    procedure SetAsString(const Value: string);
  public
    property AsString: string read GetAsString write SetAsString;
  end;

  TSignStatus = (sisUnknown, sisSubmitted, sisError, sisSigned, sisDownloaded);

  TSignStatusHelper = record helper for TSignStatus
  private
    function GetAsString: string;
    procedure SetAsString(const Value: string);
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
  public
    property AsString: string read GetAsString write SetAsString;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TSignDocumentKind = (sdkUnknown, sdkUserAgreement);

  TSignDocumentKindHelper = record helper for TSignDocumentKind
  private
    function GetAsString: string;
    procedure SetAsString(const Value: string);
    function GetAsInteger: Integer;
    procedure SetAsInteger(const Value: Integer);
  public
    property AsString: string read GetAsString write SetAsString;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TCheckCopyPetitionJobDataAction = (saUnknown, saCheck, saCopy);

  TCheckCopyPetitionJobDataActionHelper = record helper for TCheckCopyPetitionJobDataAction
  private
    function GetAsInteger: Integer;
    function GetAsString: string;
    procedure SetAsInteger(const Value: Integer);
    procedure SetAsString(const Value: string);
  public
    property AsString: string read GetAsString write SetAsString;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TDuplicateContact = record
    ActionID: Integer;
    SourceContactID: Int64;
    TargetContactID: Int64;
    ContactDescription: string;
    DifferenceDescription: string;
    UseExisting: Boolean;
  end;

  TDuplicateContactArray = array of TDuplicateContact;

  TCheckCopyPetitionJobDataResult = record
    Contacts: TDuplicateContactArray;
    function IndexOfContact(aActionId: Integer): Integer;
  end;

  TCheckCopyPetitionJobData = record
    Action: TCheckCopyPetitionJobDataAction;
    SourceID: Int64;
    ResultID: Int64;
    CheckJobID: Int64;
    CourtID: Int64;
    JudgeContactID: Int64;
    StartDate: TDateTime;
    CSRAdmissionFile: string;
    Result: TCheckCopyPetitionJobDataResult;
  end;

  TSettlementPlanStatus = (spsUnknown, spsSimulation, spsInactive, spsAwaitingDebtorApproval,
    spsAwaitingCreditorApproval, spsAwatingHomologation, spsActive);

  TSettlementPlanStatusHelper = record helper for TSettlementPlanStatus
  private
    function GetAsInteger: Integer;
    function GetAsString: string;
    procedure SetAsInteger(const Value: Integer);
    procedure SetAsString(const Value: string);
    function GetAsDisplayString: string;
  public
    property AsDisplayString: string read GetAsDisplayString;
    property AsString: string read GetAsString write SetAsString;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TBudgetMonthEntryType = (bmeUnknown, bmeDebt, bmeIncome, bmeExpenses);

  TBudgetMonthEntryTypeHelper = record helper for TBudgetMonthEntryType
  private
    function GetAsInteger: Integer;
    function GetAsString: string;
    procedure SetAsInteger(const Value: Integer);
    procedure SetAsString(const Value: string);
  public
    property AsString: string read GetAsString write SetAsString;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

const
  dsArchived = [dsArchivedCancelled, dsArchivedSubmitted];

type
  TPontoPollAction = (ipaPoll, ipaInfo);
  TPontoPushAction = (ipaPush);

  TPontoPollData = record
    BankAccount: Int64;
    Action: TPontoPollAction;
    StartOn: TDateTime;
    OneShot: Boolean;
  end;

  TPontoPushData = record
    PaymentOrder: Int64;
    Action: TPontoPushAction;
    StartOn: TDateTime;
    OneShot: Boolean;
  end;

  TPontoPollActionHelper = record helper for TPontoPollAction
  private
    function GetAsInteger: Integer;
    function GetAsString: string;
    procedure SetAsInteger(const Value: Integer);
    procedure SetAsString(const Value: string);
  public
    property AsString: string read GetAsString write SetAsString;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

  TPontoPushActionHelper = record helper for TPontoPushAction
  private
    function GetAsInteger: Integer;
    function GetAsString: string;
    procedure SetAsInteger(const Value: Integer);
    procedure SetAsString(const Value: string);
  public
    property AsString: string read GetAsString write SetAsString;
    property AsInteger: Integer read GetAsInteger write SetAsInteger;
  end;

function SourceTypeToTableKind(aSourceType: Integer): TTableKind;

function NoStartDate: TDateTime;
function NoEndDate: TDateTime;
function IsEmptyDate(aDate: TDateTime): Boolean;

implementation

uses
  DateUtils,
  StrUtils,
  TypInfo;

const
  CS_ENG = 'en';
  CS_DUT = 'nl';
  CS_FRE = 'fr';

resourcestring
  RES_csSignOn = 'Aanmelden';
  RES_csActive = 'Actief';
  RES_csReadOnly = 'Alleen lezen';
  RES_csDeactivated = 'Gedeactiveerd';
  RES_csRefused = 'Geweigerd';

  RES_dsUnknown = 'Unknown';
  RES_dsActive = 'Actief';
  RES_dsSubmitted = 'Ingediend';
  RES_dsArchivedSubmitted = 'Gearchiveerd, ingediend';
  RES_dsArchivedCancelled = 'Gearchiveerd, geannuleerd';
  RES_dsReopened = 'Heropend';

  Res_SourceTypeDossier = 'Dossier';
  Res_SourceTypeContact = 'Contact';
  Res_SourceTypeContactID = 'Contact identity';
  Res_SourceTypeContactAddress = 'Contact address';
  Res_SourceTypeContactTelecom = 'Contact telecom';
  Res_SourceTypeRevenue = 'Revenue';
  Res_SourceTypeIncomeDebt = 'Revenue through debt';
  Res_SourceTypeSalaryTransfer = 'Salary transfer';
  Res_SourceTypeProperty = 'Property';
  Res_SourceTypeRealEstate = 'Real-Estate';
  Res_SourceTypeLegalCase = 'Legal case';
  Res_SourceTypeDebt = 'Debts';
  Res_SourceTypeLogin = 'Login';
  Res_SourceTypeUnknown = 'Unknown';
  Res_SourceTypeCompany = 'Company';

  Res_spsUnknown = 'Unknown';
  Res_spsSimulation = 'Simulation';
  Res_spsInactive = 'Not active';
  Res_spsAwaitingDebtorApproval = 'Awaiting debtor approval';
  Res_spsAwaitingCreditorApproval = 'Awaiting creditor approval';
  Res_spsAwatingHomologation = 'Awaiting certification';
  Res_spsActive = 'Active';

  Res_jqsScheduled = 'Scheduled';
  Res_jqsSelected = 'Selected for processing';
  Res_jqsInProgress = 'In progress';
  Res_jqsFinishOK = 'Finished OK';
  Res_jqsFinishNOK = 'Finished with errors';
  Res_jqsCancelled = 'Cancelled';

  { TCompanyStatusHelper }

function EmptyDate: TDateTime;
begin
  Result := EncodeDate(1900, 12, 31);
end;

function NoStartDate: TDateTime;
begin
  Result := EncodeDate(1901, 1, 1);
end;

function NoEndDate: TDateTime;
begin
  Result := EncodeDate(2099, 12, 31);
end;

function IsEmptyDate(aDate: TDateTime): Boolean;
begin
  Result := (aDate = 0) or (HoursBetween(aDate, NoStartDate) <= 6) or (HoursBetween(aDate, NoEndDate) <= 6) or
    (HoursBetween(aDate, EmptyDate) <= 6);
end;

class function TCompanyStatusHelper.IntToStatus(const Value: Integer): TCompanyStatus;
begin
  Result := default (TCompanyStatus);
  Result.AsInteger := Value;
end;

procedure TCompanyStatusHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 1) or (Value > Ord(high(TCompanyStatus))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TCompanyStatus', [Value]);
  Self := TCompanyStatus(Value);
end;

function TCompanyStatusHelper.ToInteger: Integer;
begin
  Result := Ord(Self);
end;

function TCompanyStatusHelper.ToString: string;
begin
  case Self of
    csUnknown:
      Result := '?';
    csSignOn:
      Result := RES_csSignOn;
    csActive:
      Result := RES_csActive;
    csReadOnly:
      Result := RES_csReadOnly;
    csDeactivated:
      Result := RES_csDeactivated;
    csRefused:
      Result := RES_csRefused;
  end;
end;

{ TTableKindHelper }

function TTableKindHelper.CheckCompanySQL: string;
const
  SelectCompany: array [TTableKind] of string = ('', // tkUnknown
    'Select dosCompanyFK as comID from Dossier where (dosID=:ID)', // tkDossier
    'Select dosCompanyFK as comID from DossierFile Inner join Dossier on (dofDossierFK=dosID) where (dofID=:ID)',
    // tkossierFile
    'Select cntCompanyFK as comID from Contact where (cntID=:ID)', // tkContact
    'select cntCompanyFK as comID from Contact inner join ContactID on (ctiContactFK=cntID) where (ctiID=:ID)',
    // tkContactID
    'select cntCompanyFK as comID from Contact inner join ContactAddress on (ctaContactFK=cntID) where (ctaID=:ID)',
    // tkContactAddress
    'select cntCompanyFK as comID from Contact inner join ContactTelecom on (cttContactFK=cntID) where (cttID=:ID)',
    // tkContactTelecom
    'Select dosCompanyFK as comID from Revenue Inner join Dossier on (revDossierFK=dosID) where (revID=:ID)',
    // tkRevenue
    'Select dosCompanyFK as comID from Property Inner join Dossier on (proDossierFK=dosID) where (proID=:ID)',
    // tkProperty
    'Select dosCompanyFK as comID from LegalCase Inner join Dossier on (lecDossierFK=dosID) where (lecID=:ID)',
    // tkLegalCase
    'Select dosCompanyFK as comID from Debt Inner join Dossier on (debDossierFK=dosID) where (debID=:ID)', // tkDebt
    'Select dosCompanyFK as comID from Dossier Inner join DossierContact on (docDossierFK=dosID) where (docID=:ID)',
    // tkDossierContact
    'Select dosCompanyFK as comID from Dossier Inner join BudgetPlan on (bplDossierFK=dosID) where (bplID=:ID)',
    // tkBudgetPlan,
    'Select dosCompanyFK as comID from Dossier Inner join SettlementPlan on (sepDossierFK=dosID) where (sepID=:ID)',
    // tkSettlementPlan,
    'Select dosCompanyFK as comID from Dossier Inner join Seizure on (seiDossierFK=dosID) where (seiID=:ID)',
    // tkSeizure,
    'Select dosCompanyFK as comID from Dossier Inner join Warranty on (warDossierFK=dosID) where (warID=:ID)',
    // tkWarrant
    'Select codCompanyFK as comID from CompanyDossier Inner join BankAccount on (bacCompanyDossierFK=codID) where (bacID=:ID)',
    // tkBankAccount
    'Select msgCustomerFK as comID from SystemMessages where (msgID=:ID)' // tkSystemMessage
    );
begin
  Result := SelectCompany[Self];
end;

class function TTableKindHelper.Create(aValue: Integer): TTableKind;
begin
  Result := default (TTableKind);
  Result.AsInteger := aValue;
end;

function TTableKindHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TTableKindHelper.GetTableName: string;
const
  TableNames: array [TTableKind] of string = ('', 'Dossier', 'DossierFile', 'Contact', 'ContactID', 'ContactAddress',
    'ContactTelecom', 'Revenue', 'Property', 'LegalCase', 'Debt', 'DossierContact', 'budgetplan', 'settlementplan',
    'seizure', 'warranty', 'BankAccount', 'SystemMessages');
begin
  Result := TableNames[Self];
end;

procedure TTableKindHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 1) or (Value > Ord(high(TTableKind))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TTableKind', [Value]);
  Self := TTableKind(Value);
end;

function TTableKindHelper.UseMainConnection: Boolean;
begin
  Result := Self in [tkBankAccount, tkSystemMessage];
end;

{ TFileSourceTypeHelper }

class function TFileSourceTypeHelper.Create(aValue: Integer): TFileSourceType;
begin
  Result := default (TFileSourceType);
  Result.AsInteger := aValue;
end;

function TFileSourceTypeHelper.GetAsDisplayString: string;
begin
  case Self of
    fstDossier:
      Result := Res_SourceTypeDossier;
    fstContact:
      Result := Res_SourceTypeContact;
    fstContactID:
      Result := Res_SourceTypeContactID;
    fstContactAddress:
      Result := Res_SourceTypeContactAddress;
    fstContactTelecom:
      Result := Res_SourceTypeContactTelecom;
    fstRevenue:
      Result := Res_SourceTypeRevenue;
    fstProperty:
      Result := Res_SourceTypeProperty;
    fstLegalCase:
      Result := Res_SourceTypeLegalCase;
    fstDebt:
      Result := Res_SourceTypeDebt;
    fstLogin:
      Result := Res_SourceTypeLogin;
    fstCompany:
      Result := Res_SourceTypeCompany;
    fstRealEstate:
      Result := Res_SourceTypeRealEstate;
    fstIncomeDebt:
      Result := Res_SourceTypeIncomeDebt;
    fstSalaryTransfer:
      Result := Res_SourceTypeSalaryTransfer;
  else
    Result := Res_SourceTypeUnknown;
  end;
end;

function TFileSourceTypeHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TFileSourceTypeHelper.GetAsTableKind: TTableKind;
const
  ConvTable: array [TFileSourceType] of TTableKind = (tkUnknown, tkDossier, tkContact, tkContactID, tkContactAddress,
    tkContactTelecom, tkRevenue, tkProperty, tkLegalCase, tkDebt, tkUnknown, tkUnknown, tkProperty, tkRevenue, tkDebt);
begin
  Result := ConvTable[Self];
end;

function TFileSourceTypeHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TFileSourceType), Ord(Self));
end;

procedure TFileSourceTypeHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 1) or (Value > Ord(high(TFileSourceType))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TFileSourceType', [Value]);
  Self := TFileSourceType(Value);
end;

function SourceTypeToTableKind(aSourceType: Integer): TTableKind;
begin
  Result := TFileSourceType.Create(aSourceType).AsTableKind;
end;

{ TRoleAuthorizationsHelper }

function TRoleAuthorizationsHelper.GetBitMask: Int64;
var
  a: TRoleAuthorization;
begin
  Result := 0;
  for a := low(TRoleAuthorization) to high(TRoleAuthorization) do
    if a in Self then
      Result := Result or a.AsBitMask;
end;

function TRoleAuthorizationsHelper.GetByteSet: TByteSet;
var
  a: TRoleAuthorization;
begin
  Result := [];
  for a := low(TRoleAuthorization) to high(TRoleAuthorization) do
    if a in Self then
      Result := Result + [a.AsBitIndex];
end;

procedure TRoleAuthorizationsHelper.SetBitMask(aMask: Int64);
var
  a: TRoleAuthorization;
begin
  Self := [];
  for a := low(TRoleAuthorization) to high(TRoleAuthorization) do
    if (a.AsBitMask and aMask) <> 0 then
      Self := Self + [a];
end;

procedure TRoleAuthorizationsHelper.SetByteSet(aSet: TByteSet);
var
  a: TRoleAuthorization;

begin
  Self := [];
  for a := low(TRoleAuthorization) to high(TRoleAuthorization) do
    if a.AsBitIndex in aSet then
      Self := Self + [a];
end;

{ TRoleAuthorizationHelper }

const
  RoleAuthorizationBits: array [TRoleAuthorization] of Byte = (0 { raAdmin } , 1 { raImpersonate } ,
    2 { racompanydatawrite } , 3 { raUserCreate } , 4 { raJobControl } , 5 { raApproveSignon } , 6 { raParameterEdit } ,
    16 { raDossierCreate } , 17 { raDossierDataRead } , 18 { raDossierDataWrite } , 19 { raDossierFileDownload } ,
    20 { raDossierFileUpload } , 21 { raDossierJobControl } , 22 { raSignon } , 23 { raDossierFileDelete }
    );
  RoleAuthorizationNames: array [TRoleAuthorization] of string = ('Admin', 'Impersonate', 'CompanyDataWrite',
    'UserCreate', 'JobControl', 'ApproveSignon', 'ParameterEdit', 'DossierCreate', 'DossierDataRead',
    'DossierDataWrite', 'DossierFileDownload', 'DossierFileUpload', 'DossierJobControl', 'Signon', 'DossierFileDelete');

function TRoleAuthorizationHelper.GetAsString: string;
begin
  Result := RoleAuthorizationNames[Self];
end;

function TRoleAuthorizationHelper.GetBitIndex: Byte;

begin
  Result := RoleAuthorizationBits[Self];
end;

function TRoleAuthorizationHelper.GetBitMask: Int64;
begin
  Result := 1 shl AsBitIndex;
end;

procedure TRoleAuthorizationHelper.SetBitIndex(aIndex: Byte);
var
  a: TRoleAuthorization;
begin
  for a := low(TRoleAuthorization) to high(TRoleAuthorization) do
    if a.AsBitIndex = aIndex then
      Self := a;
  raise EConvertError.CreateFmt('Invalid role authorization bit index : %d', [aIndex]);
end;

procedure TRoleAuthorizationHelper.SetBitMask(aMask: Int64);
begin

end;

{ TDossierStatusHelper }

function TDossierStatusHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TDossierStatusHelper.GetAsString: string;
begin
  case Self of
    dsUnknown:
      Result := RES_dsUnknown;
    dsActive:
      Result := RES_dsActive;
    dsSubmitted:
      Result := RES_dsSubmitted;
    dsArchivedCancelled:
      Result := RES_dsArchivedCancelled;
    dsArchivedSubmitted:
      Result := RES_dsArchivedSubmitted;
    dsReopened:
      Result := RES_dsReopened;
  end;
end;

function TDossierStatusHelper.IsArchived: Boolean;
begin
  Result := Self in dsArchived;
end;

procedure TDossierStatusHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TDossierStatus))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TDossierStatus', [Value]);
  Self := TDossierStatus(Value);
end;

procedure TDossierStatusHelper.SetAsString(const Value: string);
var
  S: TDossierStatus;
  B: Boolean;
begin
  B := False;
  if (Value = '') then
  begin
    Self := dsUnknown;
    B := true;
  end
  else
    for S := low(TDossierStatus) to high(TDossierStatus) do
      if SameText(S.AsString, Value) then
      begin
        Self := S;
        B := true;
      end;
  if not B then
    raise EConvertError.CreateFmt('%s is not a valid value for TDossierStatus', [Value]);
end;

{ TLetterModelTypeHelper }

function TLetterModelTypeHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

procedure TLetterModelTypeHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TLetterModelType))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TLetterModelType', [Value]);
  Self := TLetterModelType(Value);
end;

{ TTokenTypeHelper }

function TTokenTypeHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

procedure TTokenTypeHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TTokenType))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TTokenType', [Value]);
  Self := TTokenType(Value);
end;

{ TJobQueueStatusHelper }

function TJobQueueStatusHelper.GetAsDisplayString: string;
begin
  case Self of
    jqsScheduled:
      Result := Res_jqsScheduled;
    jqsSelected:
      Result := Res_jqsSelected;
    jqsInProgress:
      Result := Res_jqsInProgress;
    jqsFinishOK:
      Result := Res_jqsFinishOK;
    jqsFinishNOK:
      Result := Res_jqsFinishNOK;
    jqsCancelled:
      Result := Res_jqsCancelled;
  else
    Result := Format('Unknown job queue status: %d', [Ord(Self)]);
  end;
end;

function TJobQueueStatusHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TJobQueueStatusHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TJobQueueStatus), Ord(Self));
end;

procedure TJobQueueStatusHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TJobQueueStatus))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TJobQueueStatus', [Value]);
  Self := TJobQueueStatus(Value);
end;

procedure TJobQueueStatusHelper.SetAsString(const Value: string);
var
  Idx: Integer;
begin
  Idx := GetEnumValue(TypeInfo(TJobQueueStatus), Value);
  if (Idx < 0) or (Idx > Ord(high(TJobQueueStatus))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TJobQueueStatus', [Value]);
  Self := TJobQueueStatus(Idx);
end;

{ TJobQueueTypeHelper }

function TJobQueueTypeHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

procedure TJobQueueTypeHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TJobQueueType))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TJobQueueType', [Value]);
  Self := TJobQueueType(Value);
end;

{ TLanguageHelper }

class function TLanguageHelper.FromString(const aValue: string): TLanguage;
begin
  case IndexText(aValue, [CS_ENG, CS_DUT, CS_FRE]) of
    0:
      Result := lEnglish;
    1:
      Result := lDutch;
    2:
      Result := lFrench;
  else
    Result := lDutch;
  end;
end;

function TLanguageHelper.GetAsString: string;
begin
  case Self of
    lEnglish:
      Result := CS_ENG;
    lDutch:
      Result := CS_DUT;
    lFrench:
      Result := CS_FRE;
  else
    Result := '? ' + IntToStr(Ord(Self));
  end;
end;

procedure TLanguageHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TLanguage))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TLanguage', [Value]);
  Self := TLanguage(Value);
end;

function TLanguageHelper.ToInteger: Integer;
begin
  case Self of
    lEnglish:
      Result := 0;
    lDutch:
      Result := 1;
    lFrench:
      Result := 2;
  else
    Result := 1;
  end;
end;

{ TDebtRecordTypeHelper }

function TDebtRecordTypeHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

class function TDebtRecordTypeHelper.New(aValue: Integer): TDebtRecordType;
begin
  Result := default (TDebtRecordType);
  Result.AsInteger := aValue;
end;

procedure TDebtRecordTypeHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TDebtRecordType))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TDebtRecordType', [Value]);
  Self := TDebtRecordType(Value);
end;

{ TDocumentData }

function TDocumentData.Description(Full: Boolean): string;

begin
  Result := Format('File ID %d, Original Name: %s, Type: %s', [RecordID, FileName, DocumentType]);
  if CaseFileNumber <> 0 then
    Result := Result + Format(', Case File: %d', [CaseFileNumber]);
  if Full then
  begin
    Result := Result + Format(', Bucket: %s, Object: %s', [Data.BucketName, Data.ObjectName]);
    Result := Result + Format(', Source: (ID: %d, Type: %s)', [SourceID, SourceType.AsString]);
  end;
end;

{ TPrintData }

constructor TPrintData.Create;
begin
  //
end;

destructor TPrintData.Destroy;
begin
  //
  inherited;
end;

{ TPrintTypeHelper }

function TPrintTypeHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TPrintType), Ord(Self));
  Result := Copy(Result, 3, Length(Result) - 2);
end;

procedure TPrintTypeHelper.SetAsString(const Value: string);
var
  I: Integer;
  S: string;
begin
  Self := ptUnknown;
  if Value = '' then
    Exit;
  S := Value;
  if LowerCase(Copy(S, 1, 2)) <> 'pt' then
    S := 'pt' + S;
  I := GetEnumValue(TypeInfo(TPrintType), S);
  if I = -1 then
    Exit;
  Self := TPrintType(I);
end;

{ TReportDataTypeHelper }

function TReportDataTypeHelper.GetAsString: string;
begin
  if (Self = rdtUnknown) then
    Result := ''
  else
  begin
    Result := GetEnumName(TypeInfo(TReportDataType), Ord(Self));
    Result := Copy(Result, 4, Length(Result) - 3);
  end;
end;

procedure TReportDataTypeHelper.SetAsString(const Value: string);
var
  S: string;
  I: Integer;
begin
  S := Value;
  if Copy(S, 1, 3) <> 'rdt' then
    S := 'rdt' + S;
  I := GetEnumValue(TypeInfo(TReportDataType), S);
  if I = -1 then
    Self := rdtUnknown
  else
    Self := TReportDataType(I);
end;

{ TReportDataTypeHelper }

function TReportDatabaseKindHelper.GetAsString: string;
begin
  if (Self = rdkUnknown) then
    Result := ''
  else
  begin
    Result := GetEnumName(TypeInfo(TReportDatabaseKind), Ord(Self));
    Result := Copy(Result, 4, Length(Result) - 3);
  end;
end;

procedure TReportDatabaseKindHelper.SetAsString(const Value: string);
var
  S: string;
  I: Integer;
begin
  S := Value;
  if Copy(S, 1, 3) <> 'rdk' then
    S := 'rdk' + S;
  I := GetEnumValue(TypeInfo(TReportDatabaseKind), S);
  if I = -1 then
    Self := rdkUnknown
  else
    Self := TReportDatabaseKind(I);
end;

{ TUserStatusHelper }

function TUserStatusHelper.GetAsString: string;
begin
  case Self of
    usNotActive:
      Result := 'User not active';
    usNotExists:
      Result := 'Combination User/Company does not exist';
    usBlocked15:
      Result := 'User is blocked for 15 minutes';
    usBlocked:
      Result := 'User is blocked';
    usOK:
      Result := 'User is allowed to log in';
  else
    Result := Format('Unknown status (%d)', [Ord(Self)]);
  end;
end;

{ TSignStatusHelper }

function TSignStatusHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TSignStatusHelper.GetAsString: string;
begin
  if (Self = sisUnknown) then
    Result := ''
  else
  begin
    Result := GetEnumName(TypeInfo(TSignStatus), Ord(Self));
    Result := Copy(Result, 4, Length(Result) - 3);
  end;
end;

procedure TSignStatusHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TSignStatus))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TSignStatus', [Value]);
  Self := TSignStatus(Value);
end;

procedure TSignStatusHelper.SetAsString(const Value: string);
var
  S: string;
  I: Integer;
begin
  S := Value;
  if Copy(S, 1, 3) <> 'sis' then
    S := 'sis' + S;
  I := GetEnumValue(TypeInfo(TSignStatus), S);
  if I = -1 then
    Self := sisUnknown
  else
    Self := TSignStatus(I);
end;

{ TSignDocumentKindHelper }

function TSignDocumentKindHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self)
end;

function TSignDocumentKindHelper.GetAsString: string;
begin
  if Self = sdkUnknown then
    Result := ''
  else
  begin
    Result := GetEnumName(TypeInfo(TSignDocumentKind), Ord(Self));
    Result := Copy(Result, 4, Length(Result) - 3)
  end;
end;

procedure TSignDocumentKindHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TSignDocumentKind))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TSignDocumentKind', [Value]);
  Self := TSignDocumentKind(Value);
end;

procedure TSignDocumentKindHelper.SetAsString(const Value: string);
var
  S: string;
  I: Integer;
begin
  S := Value;
  if Copy(S, 1, 3) <> 'sdk' then
    S := 'sdk' + S;
  I := GetEnumValue(TypeInfo(TSignDocumentKind), S);
  if I = -1 then
    Self := sdkUnknown
  else
    Self := TSignDocumentKind(I);
end;

{ TCheckCopyPetitionJobDataResult }

function TCheckCopyPetitionJobDataResult.IndexOfContact(aActionId: Integer): Integer;
begin
  Result := Length(Contacts) - 1;
  while (Result >= 0) and (Contacts[Result].ActionID <> aActionId) do
    Dec(Result);
end;

{ TCheckCopyPetitionJobDataActionHelper }

function TCheckCopyPetitionJobDataActionHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TCheckCopyPetitionJobDataActionHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TCheckCopyPetitionJobDataAction), Ord(Self));
end;

procedure TCheckCopyPetitionJobDataActionHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TCheckCopyPetitionJobDataAction))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TCheckCopyPetitionJobDataAction', [Value]);
  Self := TCheckCopyPetitionJobDataAction(Value);
end;

procedure TCheckCopyPetitionJobDataActionHelper.SetAsString(const Value: string);
var
  I: Integer;
begin
  I := GetEnumValue(TypeInfo(TCheckCopyPetitionJobDataAction), Value);
  if I = -1 then
    raise EConvertError.CreateFmt('"%s" is not a valid value for TCheckCopyPetitionJobDataAction', [Value]);
  Self := TCheckCopyPetitionJobDataAction(I);
end;

{ TTypeDossierHelper }

function TTypeDossierHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

procedure TTypeDossierHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TCheckCopyPetitionJobDataAction))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TTypeDossier', [Value]);
  Self := TTypeDossier(Value);
end;

{ TSettlementPlanStatusHelper }

function TSettlementPlanStatusHelper.GetAsDisplayString: string;
begin
  case Self of
    spsSimulation:
      Result := Res_spsSimulation;
    spsInactive:
      Result := Res_spsInactive;
    spsAwaitingDebtorApproval:
      Result := Res_spsAwaitingDebtorApproval;
    spsAwaitingCreditorApproval:
      Result := Res_spsAwaitingCreditorApproval;
    spsAwatingHomologation:
      Result := Res_spsAwatingHomologation;
    spsActive:
      Result := Res_spsActive;
  else
    Result := Res_spsUnknown;
  end;
end;

function TSettlementPlanStatusHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TSettlementPlanStatusHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TSettlementPlanStatus), Ord(Self));
end;

procedure TSettlementPlanStatusHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TSettlementPlanStatus))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TSettlementPlanStatus', [Value]);
  Self := TSettlementPlanStatus(Value);
end;

procedure TSettlementPlanStatusHelper.SetAsString(const Value: string);
var
  I: Integer;
begin
  I := GetEnumValue(TypeInfo(TSettlementPlanStatus), Value);
  if I = -1 then
    raise EConvertError.CreateFmt('"%s" is not a valid value for TSettlementPlanStatus', [Value]);
  Self := TSettlementPlanStatus(I);
end;

{ TBudgetMonthEntryTypeHelper }

function TBudgetMonthEntryTypeHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TBudgetMonthEntryTypeHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TSettlementPlanStatus), Ord(Self));
end;

procedure TBudgetMonthEntryTypeHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TBudgetMonthEntryType))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TBudgetMonthEntryType', [Value]);
  Self := TBudgetMonthEntryType(Value);
end;

procedure TBudgetMonthEntryTypeHelper.SetAsString(const Value: string);
var
  I: Integer;
begin
  I := GetEnumValue(TypeInfo(TBudgetMonthEntryType), Value);
  if I = -1 then
    raise EConvertError.CreateFmt('"%s" is not a valid value for TBudgetMonthEntryType', [Value]);
  Self := TBudgetMonthEntryType(I);
end;

{ TPontoPollActionHelper }

function TPontoPollActionHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TPontoPollActionHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TPontoPollAction), Ord(Self));
end;

procedure TPontoPollActionHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TPontoPollAction))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TPontoPollAction', [Value]);
  Self := TPontoPollAction(Value);
end;

procedure TPontoPollActionHelper.SetAsString(const Value: string);
var
  I: Integer;
begin
  I := GetEnumValue(TypeInfo(TPontoPollAction), Value);
  if I = -1 then
    I := IndexText(Value, ['poll', 'info']);
  if (I < 0) or (I > Ord(high(TPontoPollAction))) then
    raise EConvertError.CreateFmt('%s is not a valid value for TPontoPollAction', [Value]);
  Self := TPontoPollAction(I);
end;

{ TTaskTypeHelper }

function TTaskTypeHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TTaskType), Ord(Self));
end;

procedure TTaskTypeHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 1) or (Value > Ord(high(TTaskType))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TTaskType', [Value]);
  Self := TTaskType(Value);
end;

function TTaskTypeHelper.ToInteger: Integer;
begin
  Result := Ord(Self);
end;

{ TTaskStatusHelper }

function TTaskStatusHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TTaskStatus), Ord(Self));
end;

procedure TTaskStatusHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 1) or (Value > Ord(high(TTaskStatus))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TTaskStatus', [Value]);
  Self := TTaskStatus(Value);
end;

function TTaskStatusHelper.ToInteger: Integer;
begin
  Result := Ord(Self);
end;

{ TPontoPushActionHelper }

function TPontoPushActionHelper.GetAsInteger: Integer;
begin
  Result := Ord(Self);
end;

function TPontoPushActionHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TPontoPushAction), Ord(Self));
end;

procedure TPontoPushActionHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TPontoPushAction))) then
    raise EConvertError.CreateFmt('%d is not a valid value for TPontoPushAction', [Value]);
  Self := TPontoPushAction(Value);
end;

procedure TPontoPushActionHelper.SetAsString(const Value: string);
var
  I: Integer;
begin
  I := GetEnumValue(TypeInfo(TPontoPushAction), Value);
  if I = -1 then
    I := IndexText(Value, ['poll', 'info']);
  if (I < 0) or (I > Ord(high(TPontoPushAction))) then
    raise EConvertError.CreateFmt('%s is not a valid value for TPontoPushAction', [Value]);
  Self := TPontoPushAction(I);
end;

{ TPaymentOrderStatusHelper }

function TPaymentOrderStatusHelper.GetAsString: string;
begin
  Result := GetEnumName(TypeInfo(TPaymentOrderStatus), Ord(Self));
end;

procedure TPaymentOrderStatusHelper.SetAsInteger(const Value: Integer);
begin
  if (Value < 0) or (Value > Ord(high(TPaymentOrderStatus))) then
    raise EConvertError.CreateFmt('%s is not a valid value for TPaymentOrderStatus', [Value]);
  Self := TPaymentOrderStatus(Value);
end;

function TPaymentOrderStatusHelper.ToInteger: Integer;
begin
  Result := Ord(Self);
end;

end.
