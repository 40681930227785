unit Forms.CSR.TrustAccount.Recurringpayment;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset,
  pas2web.dadatasethelper;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmRecurringPayment = class(TfrmBase)
    dsRecPayments: TP2WDADataset;
    dcRecPayments: TP2WDatatable;
    dsBankAccount: TP2WDADataset;
    procedure dsBankAccountAfterOpen(DataSet: TDataSet);
    procedure dsRecPaymentsAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure dsRecPaymentsAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    FDoDelete : Boolean;
    FRoute : String;
    FDeleteID : Int64;
    procedure ConfirmDeleteRecPayments;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
    procedure DoDelete(const RecurringPaymentId: Int64);
  protected procedure LoadDFMValues; override; end;

var
  frmRecurringPayment: TfrmRecurringPayment;

implementation

{$R *.dfm}

uses
  Modules.Server, libjquery;

procedure TfrmRecurringPayment.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDoDelete := False;
  FRoute := '';
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsRecPayments.DAConnection := Server.CompanyConnection;
  dsBankAccount.DAConnection := Server.CompanyConnection;
end;

procedure TfrmRecurringPayment.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmRecurringPayment.ConfirmDeleteRecPayments;
Procedure DoDeleteRecord;
  begin
    if dsRecPayments.Locate('rpaid', FDeleteID,[]) then
    begin
      dsRecPayments.Delete;
      dsRecPayments.ApplyUpdates;
    end;
  end;

  Procedure DoCancelDelete;
  begin
    window.location.href := FRoute;
  end;
begin
  FDoDelete := False;
  if dsRecPayments.Locate('rpaid', FDeleteID,[]) then
  begin
    DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
  end;
end;

procedure TfrmRecurringPayment.DoDelete(const RecurringPaymentId: Int64);
begin
  FDeleteID := RecurringPaymentId;
  if dsRecPayments.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteRecPayments;
end;

function TfrmRecurringPayment.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmRecurringPayment.dsBankAccountAfterOpen(DataSet: TDataSet);

  procedure onResponse(aResult: Boolean; anError: String);
  begin
    if aResult then
       jQuery('#btnAdd').removeClass('disabled');
  end;
begin
  if not DataSet.isEmpty  then
    Server.IsBankAccountLinkedToPonto(DataSet.FieldByName('bacid').asInteger, @OnResponse)
end;

procedure TfrmRecurringPayment.dsRecPaymentsAfterApplyUpdates(Sender: TDataSet;
    Info: TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    window.location.href := FRoute;
  end;
end;

procedure TfrmRecurringPayment.dsRecPaymentsAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsRecPayments.GetNewRows(False);
  dcRecPayments.Data := aResult;
  dcRecPayments.RenderTranslated;
  if FDoDelete then
    ConfirmDeleteRecPayments;
end;

procedure TfrmRecurringPayment.setParams(const Params: TStrings);
var
  AccountID : NativeInt;
begin
  inherited;
  AccountID := -1;
  If Assigned(Params) and (Params.indexOfName('ACCOUNTID')<>-1) then
  begin
    AccountID := StrToIntDef(Params.Values['ACCOUNTID'],-1);
  end;
  dsRecPayments.ParamByName('DOSSIERID').asInteger := DossierID;
  dsRecPayments.ParamByName('BACID').asInteger := AccountID;
  dsRecPayments.Load([], nil);

  dsBankAccount.ParamByName('DOSSIERID').asInteger := DossierID;
  dsBankAccount.ParamByName('BACID').asInteger := AccountID;
  dsBankAccount.Load([], nil);

  FRoute := '#/CSR/' + InttoStr(DossierID) + '/trustaccount/' + IntToStr(AccountID) +'/recurringpayment/';

  jQuery('#returnBack').attr('href', '#/CSR/' + InttoStr(DossierID) + '/trustaccount/');
  jQuery('#btnAdd').attr('href', FRoute + 'new');
end;

procedure TfrmRecurringPayment.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsRecPayments := TP2WDADataset.Create(Self);
  dcRecPayments := TP2WDatatable.Create(Self);
  dsBankAccount := TP2WDADataset.Create(Self);

  dsRecPayments.BeforeLoadDFMValues;
  dcRecPayments.BeforeLoadDFMValues;
  dsBankAccount.BeforeLoadDFMValues;
  try
    dsRecPayments.SetParentComponent(Self);
    dsRecPayments.Name := 'dsRecPayments';
    dsRecPayments.TableName := 'RecurringPayment';
    dsRecPayments.Params.Clear;
    with dsRecPayments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsRecPayments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BACID';
      ParamType := ptInput;
    end;
    dsRecPayments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>rpabankaccountfk</field><parameter type="LargeInt">BACID</parameter></binaryoperation></binaryoperation></where></query>';
    dsRecPayments.DAOptions := [doRefreshAllFields];
    dsRecPayments.AfterOpen := dsRecPaymentsAfterOpen;
    dsRecPayments.AfterApplyUpdates := dsRecPaymentsAfterApplyUpdates;
    dsRecPayments.Left := 40;
    dsRecPayments.Top := 88;
    dcRecPayments.SetParentComponent(Self);
    dcRecPayments.Name := 'dcRecPayments';
    dcRecPayments.Columns.Clear;
    with dcRecPayments.Columns.Add do
    begin
      FieldName := 'rpavalidstart';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcRecPayments.Columns.Add do
    begin
      FieldName := 'rpavalidstop';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcRecPayments.Columns.Add do
    begin
      FieldName := 'rpapaymentmonths';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcRecPayments.Columns.Add do
    begin
      FieldName := 'rpapaymentdays';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcRecPayments.Columns.Add do
    begin
      FieldName := 'rpaamount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcRecPayments.Columns.Add do
    begin
      FieldName := 'rpamessage';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcRecPayments.Columns.Add do
    begin
      FieldName := 'rpaid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/{{rpabankaccountfk}}/recurringpayment/edit/{{rpaid}}';
    end;
    with dcRecPayments.Columns.Add do
    begin
      FieldName := 'rpabankaccountfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/{{rpabankaccountfk}}/recurringpayment/delete/{{rpaid}}';
    end;
    dcRecPayments.DataSet := dsRecPayments;
    dcRecPayments.Language := lEnglish;
    dcRecPayments.IsResponsive := True;
    dcRecPayments.GridID := 'grdRecPayments';
    dcRecPayments.UseFieldIndex := True;
    dcRecPayments.ShowSearch := False;
    dcRecPayments.ShowNumberOfEntries := False;
    dcRecPayments.ShowEntriesInfo := False;
    dcRecPayments.Paginate := True;
    dcRecPayments.DisplayReadOnly := False;
    dcRecPayments.CalculateTableWidth := True;
    dcRecPayments.Left := 40;
    dcRecPayments.Top := 168;
    dsBankAccount.SetParentComponent(Self);
    dsBankAccount.Name := 'dsBankAccount';
    dsBankAccount.TableName := 'BankAccount';
    dsBankAccount.Params.Clear;
    with dsBankAccount.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    with dsBankAccount.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BACID';
      ParamType := ptInput;
    end;
    dsBankAccount.WhereClause := '<?xml version="1.0" encoding="UTF-8"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>bacid</field><parameter type="LargeInt">BACID</parameter></binaryoperation></binaryoperation></where></query>';
    dsBankAccount.DAOptions := [];
    dsBankAccount.AfterOpen := dsBankAccountAfterOpen;
    dsBankAccount.Left := 544;
    dsBankAccount.Top := 32;
  finally
    dsRecPayments.AfterLoadDFMValues;
    dcRecPayments.AfterLoadDFMValues;
    dsBankAccount.AfterLoadDFMValues;
  end;
end;

end.
