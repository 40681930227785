unit Forms.Realestate;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, lib.bootstrap,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper,
  Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmRealestate = class(TfrmBase)
    dsRealestate: TP2WDADataset;
    dcRealestate: TP2WDatatable;
    dsRealestateproid: TLargeintField;
    dsRealestateprocreatedon: TDateTimeField;
    dsRealestateprocreatedbyfk: TLargeintField;
    dsRealestateprochangedon: TDateTimeField;
    dsRealestateprochangedbyfk: TLargeintField;
    dsRealestateprodossierfk: TLargeintField;
    dsRealestatepropropertytype: TStringField;
    dsRealestatepropropertytypefk: TLargeintField;
    dsRealestateproowner: TStringField;
    dsRealestateproownerfk: TLargeintField;
    dsRealestateproisrealestate: TBooleanField;
    dsRealestateproshortdescription: TStringField;
    dsRealestateprodescription: TBlobField;
    dsRealestateprocount: TIntegerField;
    dsRealestatepropurchasedon: TDateTimeField;
    dsRealestatepronotaryname: TStringField;
    dsRealestatepropurchaseprice: TFMTBCDField;
    dsRealestateprocurrentvalue: TFMTBCDField;
    dsRealestateproisfinanced: TBooleanField;
    dsRealestateproisseized: TBooleanField;
    dsRealestateproisshared: TBooleanField;
    dsRealestateproisgone: TBooleanField;
    dsRealestateprogoneon: TDateTimeField;
    dsRealestateprobuyerfk: TLargeintField;
    dsRealestateproiscomplete: TBooleanField;
    dsRealestateproremark: TStringField;
    dsRealestateprofinancing: TWideStringField;
    procedure dsRealestateAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure dsRealestateAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure dcRealestateColumns0GetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure RenderDescription(Sender: TObject; Data: JSValue; aType: string; row: TJSArray; meta: TJSObject;
      out aOutput: JSValue);
    procedure RenderOwner(Sender: TObject; Data: JSValue; aType: string; row: TJSArray; meta: TJSObject;
      out aOutput: JSValue);
  private
    { Private declarations }
    FDoDelete : Boolean;
    FDeleteID : NativeInt;
    FRoute : string;

//    function Locate(DataSet: TP2WDADataset; fieldName: string; Value: String): Boolean;
    procedure ConfirmDeleteRealestate;

  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDelete(const realestateId: NativeInt);
  protected procedure LoadDFMValues; override; end;

var
  frmRealestate: TfrmRealestate;

implementation

uses
  Modules.Server, Units.DADatasetutils, Units.Strings;

{$R *.dfm}

{ TfrmRealestate }

procedure TfrmRealestate.ConfirmDeleteRealestate;

  Procedure DoDeleteRecord;

  begin
    if dsRealestate.Locate('proid', FDeleteID,[]) then
      begin
      dsRealestate.Delete;
      dsRealestate.ApplyUpdates;
      end;
  end;

  Procedure DoCancelDelete;

  begin
    window.location.href := FRoute;
  end;

begin
  FDoDelete := False;
  if Locate(dsRealestate, 'proid', FDeleteID) then
    DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
end;

procedure TfrmRealestate.dcRealestateColumns0GetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
Var
  Dt : TDateTime;

begin
  dt := JSDateToDateTime(TJSDate(Fields.GetValueByName('propurchasedon')));
  if dt<>EncodeDate(1901,01,01) then
    aOutput:=FormatDateTime('dd-mm-yyyy',dt)
  else
    aOutput:='';
end;

procedure TfrmRealestate.RenderDescription(Sender: TObject; Data: JSValue; aType: string; row: TJSArray; meta: TJSObject;
  out aOutput: JSValue);
begin
  aOutput:='<div style="max-width: 400px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">'+String(Data)+'</div>';
end;

procedure TfrmRealestate.RenderOwner(Sender: TObject; Data: JSValue; aType: string; row: TJSArray; meta: TJSObject;
  out aOutput: JSValue);
Var
  S : String;

begin
  S:=string(Data);
  if S<>'' then
    S := Server.GetParamDisplayValue('PROPOWN', S);
  aOutput:='<div style="max-width: 250px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">'+S+'</div>';
end;

procedure TfrmRealestate.DoDelete(const realestateId: NativeInt);
begin
  FDeleteID := realestateId;
  if dsRealestate.State in [dsInactive] then
    FDoDelete := True
  else
    ConfirmDeleteRealestate;
end;

procedure TfrmRealestate.dsRealestateAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

begin
  CheckResolveResults(Info, FRoute);
end;

procedure TfrmRealestate.dsRealestateAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsRealestate.GetNewRows(False);
  dcRealestate.Data := aResult;
  dcRealestate.RenderTranslated;
  if FDoDelete then
    ConfirmDeleteRealestate;
end;


procedure TfrmRealestate.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDoDelete := False;
  FDeleteID := -1;
  dsRealestate.DAConnection := DossierConnection;
end;

procedure TfrmRealestate.WebFormShow(Sender: TObject);
begin
  inherited;
  dsRealestate.Load([], nil);
end;

procedure TfrmRealestate.setParams(const Params: TStrings);
begin
  Inherited;
  FRoute := DossierURL('/realestate');
  jQuery('#addProperty').attr('href',FRoute+'/new');
  dsRealestate.ParamByName('DossierID').asLargeInt := DossierID;
end;

procedure TfrmRealestate.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsRealestate := TP2WDADataset.Create(Self);
  dsRealestateproid := TLargeintField.Create(Self);
  dsRealestateprocreatedon := TDateTimeField.Create(Self);
  dsRealestateprocreatedbyfk := TLargeintField.Create(Self);
  dsRealestateprochangedon := TDateTimeField.Create(Self);
  dsRealestateprochangedbyfk := TLargeintField.Create(Self);
  dsRealestateprodossierfk := TLargeintField.Create(Self);
  dsRealestatepropropertytype := TStringField.Create(Self);
  dsRealestatepropropertytypefk := TLargeintField.Create(Self);
  dsRealestateproowner := TStringField.Create(Self);
  dsRealestateproownerfk := TLargeintField.Create(Self);
  dsRealestateproisrealestate := TBooleanField.Create(Self);
  dsRealestateproshortdescription := TStringField.Create(Self);
  dsRealestateprodescription := TBlobField.Create(Self);
  dsRealestateprocount := TIntegerField.Create(Self);
  dsRealestatepropurchasedon := TDateTimeField.Create(Self);
  dsRealestatepronotaryname := TStringField.Create(Self);
  dsRealestatepropurchaseprice := TFMTBCDField.Create(Self);
  dsRealestateprocurrentvalue := TFMTBCDField.Create(Self);
  dsRealestateproisfinanced := TBooleanField.Create(Self);
  dsRealestateproisseized := TBooleanField.Create(Self);
  dsRealestateproisshared := TBooleanField.Create(Self);
  dsRealestateproisgone := TBooleanField.Create(Self);
  dsRealestateprogoneon := TDateTimeField.Create(Self);
  dsRealestateprobuyerfk := TLargeintField.Create(Self);
  dsRealestateproiscomplete := TBooleanField.Create(Self);
  dsRealestateproremark := TStringField.Create(Self);
  dsRealestateprofinancing := TWideStringField.Create(Self);
  dcRealestate := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsRealestate.BeforeLoadDFMValues;
  dsRealestateproid.BeforeLoadDFMValues;
  dsRealestateprocreatedon.BeforeLoadDFMValues;
  dsRealestateprocreatedbyfk.BeforeLoadDFMValues;
  dsRealestateprochangedon.BeforeLoadDFMValues;
  dsRealestateprochangedbyfk.BeforeLoadDFMValues;
  dsRealestateprodossierfk.BeforeLoadDFMValues;
  dsRealestatepropropertytype.BeforeLoadDFMValues;
  dsRealestatepropropertytypefk.BeforeLoadDFMValues;
  dsRealestateproowner.BeforeLoadDFMValues;
  dsRealestateproownerfk.BeforeLoadDFMValues;
  dsRealestateproisrealestate.BeforeLoadDFMValues;
  dsRealestateproshortdescription.BeforeLoadDFMValues;
  dsRealestateprodescription.BeforeLoadDFMValues;
  dsRealestateprocount.BeforeLoadDFMValues;
  dsRealestatepropurchasedon.BeforeLoadDFMValues;
  dsRealestatepronotaryname.BeforeLoadDFMValues;
  dsRealestatepropurchaseprice.BeforeLoadDFMValues;
  dsRealestateprocurrentvalue.BeforeLoadDFMValues;
  dsRealestateproisfinanced.BeforeLoadDFMValues;
  dsRealestateproisseized.BeforeLoadDFMValues;
  dsRealestateproisshared.BeforeLoadDFMValues;
  dsRealestateproisgone.BeforeLoadDFMValues;
  dsRealestateprogoneon.BeforeLoadDFMValues;
  dsRealestateprobuyerfk.BeforeLoadDFMValues;
  dsRealestateproiscomplete.BeforeLoadDFMValues;
  dsRealestateproremark.BeforeLoadDFMValues;
  dsRealestateprofinancing.BeforeLoadDFMValues;
  dcRealestate.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
      PreventDefault := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnNo';
      Name := 'btnNo';
      PreventDefault := False;
    end;
    dsRealestate.SetParentComponent(Self);
    dsRealestate.Name := 'dsRealestate';
    dsRealestate.TableName := 'property';
    dsRealestate.Params.Clear;
    with dsRealestate.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DossierID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsRealestate.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>prodossierfk</field><parameter type="LargeInt">DossierID</parameter></binaryoperation><binaryoperation operator="Equal"><field>propropertytype</field><constant type="String">ONRG</constant></binaryoperation></binaryoperation></where></query>';
    dsRealestate.DAOptions := [];
    dsRealestate.AfterOpen := dsRealestateAfterOpen;
    dsRealestate.AfterApplyUpdates := dsRealestateAfterApplyUpdates;
    dsRealestate.Left := 40;
    dsRealestate.Top := 80;
    dsRealestateproid.SetParentComponent(dsRealestate);
    dsRealestateproid.Name := 'dsRealestateproid';
    dsRealestateproid.FieldName := 'proid';
    dsRealestateprocreatedon.SetParentComponent(dsRealestate);
    dsRealestateprocreatedon.Name := 'dsRealestateprocreatedon';
    dsRealestateprocreatedon.FieldName := 'procreatedon';
    dsRealestateprocreatedbyfk.SetParentComponent(dsRealestate);
    dsRealestateprocreatedbyfk.Name := 'dsRealestateprocreatedbyfk';
    dsRealestateprocreatedbyfk.FieldName := 'procreatedbyfk';
    dsRealestateprochangedon.SetParentComponent(dsRealestate);
    dsRealestateprochangedon.Name := 'dsRealestateprochangedon';
    dsRealestateprochangedon.FieldName := 'prochangedon';
    dsRealestateprochangedbyfk.SetParentComponent(dsRealestate);
    dsRealestateprochangedbyfk.Name := 'dsRealestateprochangedbyfk';
    dsRealestateprochangedbyfk.FieldName := 'prochangedbyfk';
    dsRealestateprodossierfk.SetParentComponent(dsRealestate);
    dsRealestateprodossierfk.Name := 'dsRealestateprodossierfk';
    dsRealestateprodossierfk.FieldName := 'prodossierfk';
    dsRealestatepropropertytype.SetParentComponent(dsRealestate);
    dsRealestatepropropertytype.Name := 'dsRealestatepropropertytype';
    dsRealestatepropropertytype.FieldName := 'propropertytype';
    dsRealestatepropropertytype.Size := 10;
    dsRealestatepropropertytypefk.SetParentComponent(dsRealestate);
    dsRealestatepropropertytypefk.Name := 'dsRealestatepropropertytypefk';
    dsRealestatepropropertytypefk.FieldName := 'propropertytypefk';
    dsRealestateproowner.SetParentComponent(dsRealestate);
    dsRealestateproowner.Name := 'dsRealestateproowner';
    dsRealestateproowner.FieldName := 'proowner';
    dsRealestateproowner.Size := 10;
    dsRealestateproownerfk.SetParentComponent(dsRealestate);
    dsRealestateproownerfk.Name := 'dsRealestateproownerfk';
    dsRealestateproownerfk.FieldName := 'proownerfk';
    dsRealestateproisrealestate.SetParentComponent(dsRealestate);
    dsRealestateproisrealestate.Name := 'dsRealestateproisrealestate';
    dsRealestateproisrealestate.FieldName := 'proisrealestate';
    dsRealestateproshortdescription.SetParentComponent(dsRealestate);
    dsRealestateproshortdescription.Name := 'dsRealestateproshortdescription';
    dsRealestateproshortdescription.FieldName := 'proshortdescription';
    dsRealestateproshortdescription.Size := 127;
    dsRealestateprodescription.SetParentComponent(dsRealestate);
    dsRealestateprodescription.Name := 'dsRealestateprodescription';
    dsRealestateprodescription.FieldName := 'prodescription';
    dsRealestateprocount.SetParentComponent(dsRealestate);
    dsRealestateprocount.Name := 'dsRealestateprocount';
    dsRealestateprocount.FieldName := 'procount';
    dsRealestatepropurchasedon.SetParentComponent(dsRealestate);
    dsRealestatepropurchasedon.Name := 'dsRealestatepropurchasedon';
    dsRealestatepropurchasedon.FieldName := 'propurchasedon';
    dsRealestatepronotaryname.SetParentComponent(dsRealestate);
    dsRealestatepronotaryname.Name := 'dsRealestatepronotaryname';
    dsRealestatepronotaryname.FieldName := 'pronotaryname';
    dsRealestatepronotaryname.Size := 100;
    dsRealestatepropurchaseprice.SetParentComponent(dsRealestate);
    dsRealestatepropurchaseprice.Name := 'dsRealestatepropurchaseprice';
    dsRealestatepropurchaseprice.FieldName := 'propurchaseprice';
    dsRealestatepropurchaseprice.Precision := 12;
    dsRealestatepropurchaseprice.Size := 0;
    dsRealestateprocurrentvalue.SetParentComponent(dsRealestate);
    dsRealestateprocurrentvalue.Name := 'dsRealestateprocurrentvalue';
    dsRealestateprocurrentvalue.FieldName := 'procurrentvalue';
    dsRealestateprocurrentvalue.Precision := 12;
    dsRealestateprocurrentvalue.Size := 0;
    dsRealestateproisfinanced.SetParentComponent(dsRealestate);
    dsRealestateproisfinanced.Name := 'dsRealestateproisfinanced';
    dsRealestateproisfinanced.FieldName := 'proisfinanced';
    dsRealestateproisseized.SetParentComponent(dsRealestate);
    dsRealestateproisseized.Name := 'dsRealestateproisseized';
    dsRealestateproisseized.FieldName := 'proisseized';
    dsRealestateproisshared.SetParentComponent(dsRealestate);
    dsRealestateproisshared.Name := 'dsRealestateproisshared';
    dsRealestateproisshared.FieldName := 'proisshared';
    dsRealestateproisgone.SetParentComponent(dsRealestate);
    dsRealestateproisgone.Name := 'dsRealestateproisgone';
    dsRealestateproisgone.FieldName := 'proisgone';
    dsRealestateprogoneon.SetParentComponent(dsRealestate);
    dsRealestateprogoneon.Name := 'dsRealestateprogoneon';
    dsRealestateprogoneon.FieldName := 'progoneon';
    dsRealestateprobuyerfk.SetParentComponent(dsRealestate);
    dsRealestateprobuyerfk.Name := 'dsRealestateprobuyerfk';
    dsRealestateprobuyerfk.FieldName := 'probuyerfk';
    dsRealestateproiscomplete.SetParentComponent(dsRealestate);
    dsRealestateproiscomplete.Name := 'dsRealestateproiscomplete';
    dsRealestateproiscomplete.FieldName := 'proiscomplete';
    dsRealestateproremark.SetParentComponent(dsRealestate);
    dsRealestateproremark.Name := 'dsRealestateproremark';
    dsRealestateproremark.FieldName := 'proremark';
    dsRealestateproremark.Size := 255;
    dsRealestateprofinancing.SetParentComponent(dsRealestate);
    dsRealestateprofinancing.Name := 'dsRealestateprofinancing';
    dsRealestateprofinancing.FieldName := 'profinancing';
    dsRealestateprofinancing.Size := 255;
    dcRealestate.SetParentComponent(Self);
    dcRealestate.Name := 'dcRealestate';
    dcRealestate.Columns.Clear;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'propurchasedon';
      Title := 'Purchased On';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcRealestateColumns0GetValue');
      Visible := True;
      Searchable := True;
      Sortable := True;
      DateTimeFormat := 'DD.MM.YYYY';
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'proshortdescription';
      Title := 'Description';
      RenderMode := rmCustom;
      ButtonType := btEdit;
      SetEvent(Self, 'OnRender', 'RenderDescription');
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'procount';
      Title := 'Count';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'proowner';
      Title := 'Owner';
      RenderMode := rmCustom;
      ButtonType := btEdit;
      SetEvent(Self, 'OnRender', 'RenderOwner');
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'proiscomplete';
      Title := 'Is Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      CheckBoxClassName := 'disabled';
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'prodossierfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{prodossierfk}}/realestate/edit/{{proid}}';
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'proid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{prodossierfk}}/realestate/delete/{{proid}}';
    end;
    dcRealestate.DataSet := dsRealestate;
    dcRealestate.Language := lEnglish;
    dcRealestate.IsResponsive := True;
    dcRealestate.GridID := 'grdRealestate';
    dcRealestate.UseFieldIndex := True;
    dcRealestate.ShowSearch := False;
    dcRealestate.ShowNumberOfEntries := False;
    dcRealestate.ShowEntriesInfo := False;
    dcRealestate.Paginate := True;
    dcRealestate.DisplayReadOnly := False;
    dcRealestate.CalculateTableWidth := True;
    dcRealestate.Left := 48;
    dcRealestate.Top := 152;
  finally
    alForm.AfterLoadDFMValues;
    dsRealestate.AfterLoadDFMValues;
    dsRealestateproid.AfterLoadDFMValues;
    dsRealestateprocreatedon.AfterLoadDFMValues;
    dsRealestateprocreatedbyfk.AfterLoadDFMValues;
    dsRealestateprochangedon.AfterLoadDFMValues;
    dsRealestateprochangedbyfk.AfterLoadDFMValues;
    dsRealestateprodossierfk.AfterLoadDFMValues;
    dsRealestatepropropertytype.AfterLoadDFMValues;
    dsRealestatepropropertytypefk.AfterLoadDFMValues;
    dsRealestateproowner.AfterLoadDFMValues;
    dsRealestateproownerfk.AfterLoadDFMValues;
    dsRealestateproisrealestate.AfterLoadDFMValues;
    dsRealestateproshortdescription.AfterLoadDFMValues;
    dsRealestateprodescription.AfterLoadDFMValues;
    dsRealestateprocount.AfterLoadDFMValues;
    dsRealestatepropurchasedon.AfterLoadDFMValues;
    dsRealestatepronotaryname.AfterLoadDFMValues;
    dsRealestatepropurchaseprice.AfterLoadDFMValues;
    dsRealestateprocurrentvalue.AfterLoadDFMValues;
    dsRealestateproisfinanced.AfterLoadDFMValues;
    dsRealestateproisseized.AfterLoadDFMValues;
    dsRealestateproisshared.AfterLoadDFMValues;
    dsRealestateproisgone.AfterLoadDFMValues;
    dsRealestateprogoneon.AfterLoadDFMValues;
    dsRealestateprobuyerfk.AfterLoadDFMValues;
    dsRealestateproiscomplete.AfterLoadDFMValues;
    dsRealestateproremark.AfterLoadDFMValues;
    dsRealestateprofinancing.AfterLoadDFMValues;
    dcRealestate.AfterLoadDFMValues;
  end;
end;

end.
