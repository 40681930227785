unit Forms.CSR.SettlementPlan.Edit;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  Forms.DossierDetail,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dataelementmapper,
  Units.Params,
  pas2web.bootstrap;

type
  TCreditor = record
    debid: NativeInt;
    creditor: string;
    currentAmount: Double;
  end;

  TCreditors = array of TCreditor;

  TDebtRecord = record
    debId: NativeInt;
    amount: Double;
  end;

  TDebtRecords = array of TDebtRecord;

  TfrmBase = class(TFrmDossierDetail);

  TfrmSetlementPlanEdit = class(TfrmBase)
    dsSettlement: TP2WDADataset;
    eacSettlement: TPW2DataElementMapper;
    dsSettlementDetails: TP2WDADataset;
    mdlDebtDetails: TP2WBSModal;
    dsDebts: TP2WDADataset;
    dsSettlementDebts: TP2WDADataset;
    mdlBudget: TP2WBSModal;
    dsBudgetPlans: TP2WDADataset;
    dsBudgetTotals: TP2WDADataset;
    mdlCopyMonth: TP2WBSModal;
    mdlCertify: TP2WBSModal;
    procedure dsDebtsAfterOpen(DataSet: TDataSet);
    procedure dsSettlementAfterOpen(DataSet: TDataSet);
    procedure dsSettlementDebtsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsSettlementDetailsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsSettlementDetailsAfterOpen(DataSet: TDataSet);
    procedure DoCopyMonth(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure dsSettlementAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure OnCertifyClicked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnCertifyConfirmed(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnDebtDetailsChanged(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnSaveButtonClicked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnShowCopyMonthModal(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure OnSetDateValue(Sender: TObject; Source: TField; Dest: TElementAction; var aHandled: Boolean);
  private
    { Private declarations }
    FSettlementID: NativeInt;
    FDetailsChanged: Boolean;
    FCategories: TCategories;
    FRef: string;
    FCreditors: TCreditors;

    procedure OnGetPlanCatItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string; ValueType: Integer; Extra: string; isLast: Boolean);
    function OnDebtShow(Evt: TEventListenerEvent): Boolean;

    procedure constructDetailsGrid;
    procedure constructCopyMonthOptions(aStartDate, aEndDate: TDateTime);

    procedure SaveSettlement;
    procedure SaveSettlementPlanDetail(aSpdId: Int64; dAmount: Double);
    procedure SaveSettlementPlanDetails;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix: string; override;
    procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmSetlementPlanEdit: TfrmSetlementPlanEdit;

implementation

{$R *.dfm}

uses
  Modules.Server,
  libjquery,
  dateutils,
  Units.ActionUtils,
  Units.HTMLUtils,
  Units.Strings,
  Units.DADatasetUtils,
  Units.Service.Dezq,
  Units.Types;

procedure TfrmSetlementPlanEdit.canHandleRoute(previousURL: string; var allow: Boolean);
begin
  allow := DiscardChanges and (not FDetailsChanged or DiscardFiles);
end;

procedure TfrmSetlementPlanEdit.constructDetailsGrid;
var
  iTotalYears, iStartYear: Integer;
  y, m, d, i, j, k: Word;
  html: string;
  defaultBudgetPlanID: string;
  iRef: Integer;

  function OnDataChange(Evt: TEventListenerEvent): Boolean;
  begin
    FDetailsChanged := True;
    Result := True;
  end;

begin
  defaultBudgetPlanID := dsSettlement.FieldByName('sepdefaultbudgetplanfk').asString;

  iTotalYears := 0;
  DecodeDate(dsSettlement.FieldByName('sepvalidtill').asDateTime, y, m, d);

  if m > 1 then
    inc(iTotalYears, y + 1)
  else
    inc(iTotalYears, y);

  DecodeDate(dsSettlement.FieldByName('sepvalidfrom').asDateTime, y, m, d);
  dec(iTotalYears, y);
  iStartYear := y;

  html := '<tr><th>' + SMonth + '</th><th>' + SDescription + '</th>';
  for i := 1 to iTotalYears do
    html := html + '<th>' + IntToStr(iStartYear + i - 1) + '</th>';
  html := html + '</tr>';
  jQuery('#plan').find('thead').html(html);

  html := '';
  iRef := 1;
  for i := 1 to 12 do
  begin
    for j := low(FCategories) to high(FCategories) do
    begin
      if j = 0 then
        html := html + '<tr data-type="' + FCategories[j].Extra + '" ' + 'data-month="' + IntToStr(i) + '"><td>' + FormatSettings.LongMonthNames[i] + '</td>' + '<td>' + FCategories[j].Value + '</td>'
      else
        html := html + '<tr data-type="' + FCategories[j].Extra + '" ' + 'data-month="' + IntToStr(i) + '"><td></td>' + '<td>' + FCategories[j].Value + '</td>';

      for k := 0 to iTotalYears - 1 do
      begin

        html := html + '<td data-year="' + IntToStr(iStartYear + k) + '">';
        if (EncodeDate(iStartYear + k, i, 1) < dsSettlement.FieldByName('sepvalidtill').asDateTime) and
          (incMonth(EncodeDate(iStartYear + k, i, 1), 1) > dsSettlement.FieldByName('sepvalidfrom').asDateTime) then
        begin
          html := html + '<input type="number" value="" ' + 'data-spdid="-1" data-ref="' + IntToStr(iRef) + '"' + 'data-cat="' + FCategories[j].Name + '" ' + 'data-bid="' + defaultBudgetPlanID + '" '
            + 'data-cid="' + IntToStr(FCategories[j].id) + '" style="display:inline-block;" step="0.01">';
          inc(iRef);

          if FCategories[j].Extra = '1' then
            html := html + '<button class="btn btn-sm btn-primary-outline ml-1" role="debt" style="vertical-align:top;"><i class="fa fa-ellipsis-h"></i></button>';
        end;
        html := html + '</td>';
      end;
      html := html + '</tr>';
    end;
  end;
  jQuery('#plan').find('tbody').html(html);
  jQuery('#plan').find('tbody').find('input').css('max-width', '70px');
  jQuery('#plan').find('input').On_('keyup', @OnDataChange);

  jQuery('button[role="debt"]').On_('click', @OnDebtShow);

  jQuery('#bottomButtonDiv').css('display', '');

  constructCopyMonthOptions(dsSettlement.FieldByName('sepvalidfrom').asDateTime, dsSettlement.FieldByName('sepvalidtill').asDateTime);
end;

procedure TfrmSetlementPlanEdit.constructCopyMonthOptions(aStartDate, aEndDate: TDateTime);
var
  aDate: TDateTime;
  sCopyMonthContent: string;
begin
  sCopyMonthContent := '';

  aDate := aStartDate;
  repeat
    sCopyMonthContent := sCopyMonthContent + '<option value="' + FormatDateTime('YYYYMM', aDate) + '">' + FormatDateTime('YYYY/MM', aDate) + '</option>';
    aDate := incMonth(aDate);
  until aDate >= aEndDate;

  jQuery('#refMonth').html(sCopyMonthContent);
  jQuery('#startMonth').html(sCopyMonthContent);
  jQuery('#endMonth').html(sCopyMonthContent);
  jQuery('#endMonth').val(string(jQuery('#endMonth').find('option:last').val));
end;

function TfrmSetlementPlanEdit.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmSetlementPlanEdit.dsDebtsAfterOpen(DataSet: TDataSet);
var
  sTBodyContent: string;
  dTotalAmount: Double;

  function addToTotalAmount(aIndex: Integer; AElement: TJSElement): Boolean;
  begin
    dTotalAmount := dTotalAmount + StrToFloatDef(string(jQuery(AElement).val), 0);
    Result := True;
  end;

  function calculateTotal(Evt: TEventListenerEvent): Boolean;
  begin
    dTotalAmount := 0;
    jQuery('#mdlDebtDetails').find('input[role="debsubtotal"]').each(@addToTotalAmount);
    jQuery('#mdlDebtDetails').find('input[role="debtotal"]').val(FloatToStr(dTotalAmount));
    Result := True;
  end;

  function CreateDebtsDetailRow(aCreditor, aOutstandingAmount, aPaymentAmount, aSddId, aDebId: string): string;
  begin
    Result := '<tr>' + #13#10 + //
      ' <td>' + aCreditor + '</td>' + #13#10 + //
      ' <td>' + aOutstandingAmount + '</td>' + #13#10 + //
      ' <td>' + #13#10 + //
      '  <input role="debsubtotal" type="number" ' + 'data-sddid="' + aSddId + '" ' + 'data-debid="' + aDebId + '"/>' + #13#10 + //
      ' </td>' + #13#10 + //
      '</tr>';
  end;

begin
  sTBodyContent := '';
  dTotalAmount := 0;

  while not DataSet.eof do
  begin
    sTBodyContent := sTBodyContent + #13#10 + CreateDebtsDetailRow(DataSet.FieldByName('debcreditor').asString, DataSet.FieldByName('debcurrentamount').asString,
      DataSet.FieldByName('debmonthlyamount').asString, '-1', DataSet.FieldByName('debid').asString);
    dTotalAmount := dTotalAmount + DataSet.FieldByName('debmonthlyamount').asFloat;

    SetLength(FCreditors, Length(FCreditors) + 1);
    FCreditors[Length(FCreditors) - 1].debid := DataSet.FieldByName('debid').asInteger;
    FCreditors[Length(FCreditors) - 1].creditor := DataSet.FieldByName('debcreditor').asString;
    FCreditors[Length(FCreditors) - 1].currentAmount := DataSet.FieldByName('debcurrentamount').asFloat;

    DataSet.Next;
  end;

  sTBodyContent := sTBodyContent + #13#10 + '<tr>' + #13#10 + //
    '  <td colspan="2" style="text-align: right;vertical-align:middle">' + //
    '    <strong data-translate="npeTotal">Total:</strong>' + #13#10 + //
    '  </td>' + #13#10 + //
    '  <td>' + #13#10 + //
    '    <input id="edtdebttotalmonthly"  role="debtotal" type="number" value="' + FloatToStr(dTotalAmount) + '"/>' + #13#10 + //
    '  </td>' + #13#10 + //
    '</tr>';

  jQuery('#mdlDebtDetails').find('table').find('tbody').html(sTBodyContent);
  jQuery('#mdlDebtDetails').find('input[role="debsubtotal"]').On_('keyup', @calculateTotal);
end;

procedure TfrmSetlementPlanEdit.dsSettlementAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
  begin
    dsSettlementDetails.Close;
    dsSettlementDetails.ParamByName('SETTLEMENTID').asInteger := FSettlementID;
    dsSettlementDetails.Load([], nil);

    if DataSet.FieldByName('sepsourcefk').asInteger > 0 then
    begin
      jQuery('#btnReset').prop('disabled', false);
    end;

    if DataSet.FieldByName('sepstatus').asInteger = 5 then
    begin
      jQuery('#btnCertify').prop('disabled', false);
    end;

    eacSettlement.DatasetToElements;
  end;
end;

procedure TfrmSetlementPlanEdit.dsSettlementDebtsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    Server.ShowOK(SSettlementPlanDebtDetailsSavedOK);
  end;
end;

procedure TfrmSetlementPlanEdit.dsSettlementDetailsAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  FDetailsChanged := false;
  if CheckResolveResults(Info) then
  begin
    dsSettlementDetailsAfterOpen(dsSettlementDetails);
    Server.ShowOK(SSettlementPlanDetailsSavedOK);
  end;
end;

procedure TfrmSetlementPlanEdit.dsSettlementDetailsAfterOpen(DataSet: TDataSet);
var
  count: Integer;
  tdCell: TJQuery;

  function countItems(aIndex: Integer; AElement: TJSElement): Boolean;
  begin
    inc(count);
    Result := True;
  end;

begin
  constructDetailsGrid;
  DataSet.First;
  while not DataSet.eof do
  begin
    count := 0;
    tdCell := jQuery('#plan').find('tr[data-type="' + DataSet.FieldByName('spdtype').asString + '"][data-month="' + DataSet.FieldByName('spdmonth').asString + '"]')
      .find('td[data-year="' + DataSet.FieldByName('spdyear').asString + '"]').each(@countItems);
    if count > 0 then
    begin
      tdCell.find('input[data-cat="' + DataSet.FieldByName('spdcategory').asString + '"]').attr('value', DataSet.FieldByName('spdamount').asString)
        .attr('data-spdid', DataSet.FieldByName('spdid').asString).attr('data-bid', DataSet.FieldByName('spdbudgetplanfk').asString);
      {TODO -oAG -cGeneral : Hardcoded vervangen!}
      if DataSet.FieldByName('spdcategory').asString = '10' then
        tdCell.find('input[data-cat="' + DataSet.FieldByName('spdcategory').asString + '"]').prop('disabled', True);
    end;
    DataSet.Next;
  end;
end;

procedure TfrmSetlementPlanEdit.DoCopyMonth(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  SMonth, sYear, eMonth, eYear, rMonth, rYear: Integer;
  cYear, cDay, cMonth: Word;
  aDate, eDate: TDateTime;
  val: string;
  cat: string;
  aDebts: TDebtRecords;

  procedure getDebtRecords(aMonth, aYear: Integer);
  var
    i: Integer;
  begin
    SetLength(aDebts, Length(FCreditors));

    for i := Low(FCreditors) to High(FCreditors) do
    begin
      aDebts[i].debid := FCreditors[i].debid;
      aDebts[i].amount := 0;

      if Locate(dsSettlementDetails, ['spdyear', 'spdmonth', 'spdtype'], [aYear, aMonth, 1]) then
        if Locate(dsSettlementDebts, ['sdddebtfk', 'sddsettlementplandetailfk'], [FCreditors[i].debid, dsSettlementDetails.FieldByName('spdid').AsInteger]) then
          aDebts[i].amount := dsSettlementDebts.FieldByName('sddamount').AsFloat;
    end;
  end;

  procedure PostDebtRecordsForCurrentMonthAndYear;
  var
    i: Integer;
  begin
    if Locate(dsSettlementDetails, ['spdyear', 'spdmonth', 'spdtype'], [cYear, cMonth, 1]) then
    begin
      for i := Low(aDebts) to High(aDebts) do
      begin
        if Locate(dsSettlementDebts, ['sdddebtfk', 'sddsettlementplandetailfk'], [aDebts[i].debid, dsSettlementDetails.FieldByName('spdid').AsInteger]) then
        begin
          dsSettlementDebts.Edit;
          dsSettlementDebts.FieldByName('sddamount').AsFloat := aDebts[i].amount;
          dsSettlementDebts.Post;
        end;
      end;
    end;
  end;

  function forEachMonth(aIndex: Integer; AElement: TJSElement): Boolean;
  begin
    val := string(jQuery(AElement).find('td[data-year="' + IntToStr(rYear) + '"]').find('input').val);
    cat := jQuery(AElement).find('td[data-year="' + IntToStr(rYear) + '"]').find('input').attr('data-cat');
    jQuery('#plan').find('tbody').find('tr[data-month="' + IntToStr(cMonth) + '"]').find('td[data-year="' + IntToStr(cYear) + '"]').find('input[data-cat="' + cat + '"]').val(val);
    Result := True;
  end;

begin
  if (StrToIntDef(alForm['refMonth'].Value, 0) >= StrToIntDef(alForm['startMonth'].Value, 0)) and (StrToIntDef(alForm['refMonth'].Value, 0) <= StrToIntDef(alForm['endMonth'].Value, 0)) then
  begin
    jQuery('#error').css('display', '');
  end
  else
  begin
    jQuery('#error').css('display', 'none');
    rYear := StrToInt(Copy(alForm['refMonth'].Value, 1, 4));
    rMonth := StrToInt(Copy(alForm['refMonth'].Value, 5, 2));
    sYear := StrToInt(Copy(alForm['startMonth'].Value, 1, 4));
    SMonth := StrToInt(Copy(alForm['startMonth'].Value, 5, 2));
    eYear := StrToInt(Copy(alForm['endMonth'].Value, 1, 4));
    eMonth := StrToInt(Copy(alForm['endMonth'].Value, 5, 2));

    getDebtRecords(rMonth, rYear);

    aDate := EncodeDate(sYear, SMonth, 1);
    eDate := EncodeDate(eYear, eMonth, 1);
    repeat
      DecodeDate(aDate, cYear, cMonth, cDay);
      jQuery('#plan').find('tbody').find('tr[data-month="' + IntToStr(rMonth) + '"]').each(@forEachMonth);

      PostDebtRecordsForCurrentMonthAndYear;

      aDate := incMonth(aDate);
    until aDate > eDate;

    dsSettlementDebts.ApplyUpdates;

    mdlCopyMonth.Hide;
  end;
end;

procedure TfrmSetlementPlanEdit.dsSettlementAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    Server.ShowOK(SSettlementPlanSavedOK);
    DiscardChanges := True;
  end;
end;

procedure TfrmSetlementPlanEdit.OnCertifyClicked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  jQuery('#chYearlyPayments').prop('checked', false);
  mdlCertify.Show;
end;

procedure TfrmSetlementPlanEdit.OnCertifyConfirmed(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  options: TCSRCertifyOptions;
  procedure onResponse(aSuccess: Boolean; anError: string);
  begin
    if not aSuccess then
    begin
      if anError <> '' then
        Server.ShowError(anError)
      else
        Server.ShowError(SCertifyError);
    end
    else
    begin
      Server.ShowOK(SCertifyOK);
      dsSettlement.Close;
      dsSettlement.Load([], nil);
    end;
  end;

begin
  options.PlanID := FSettlementID;
  options.YearlyPayments := isCheckBoxChecked('chYearlyPayments');
  Server.CertifyPayments(options, @onResponse);
  mdlCertify.Hide;
end;

procedure TfrmSetlementPlanEdit.OnDebtDetailsChanged(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  spdid: Integer;
  sTotalAmount: string;

  function SaveSettlementPlanDebtorDetail(aIndex: Integer; AElement: TJSElement): Boolean;
  var
    amount: Double;
    debid: Integer;
  begin
    amount := StrToFloatDef(string(jQuery(AElement).val), 0);
    debid := StrToIntDef(jQuery(AElement).attr('data-debid'), -1);
    spdid := StrToIntDef(jQuery('input[data-ref="' + FRef + '"]').attr('data-spdid'), -1);

    if Locate(dsSettlementDebts, ['sdddebtfk', 'sddsettlementplandetailfk'], [debid, spdid]) then
    begin
      dsSettlementDebts.Edit;
      dsSettlementDebts.FieldByName('sddchangedon').asDateTime := now;
      dsSettlementDebts.FieldByName('sddchangedbyfk').asInteger := Server.UserId;
      dsSettlementDebts.FieldByName('sddamount').asFloat := amount;
      dsSettlementDebts.Post;
      dsSettlementDebts.ApplyUpdates;
    end
    else
    begin
      console.log('settlemenplandetail debt not found!');
    end;

    Result := True;
  end;

begin
  jQuery('#mdlDebtDetails').find('table').find('tbody').find('input[role="debsubtotal"]').each(@SaveSettlementPlanDebtorDetail);

  sTotalAmount := string(jQuery('#mdlDebtDetails').find('input[role="debtotal"]').val);
  jQuery('input[data-ref="' + FRef + '"]').val(sTotalAmount);
  jQuery('input[data-ref="' + FRef + '"]').prop('disabled', True);
  SaveSettlementPlanDetail(spdid, StrToFloat(sTotalAmount));
  SaveSettlement;
  FRef := '';
  mdlDebtDetails.Hide;
end;

function TfrmSetlementPlanEdit.OnDebtShow(Evt: TEventListenerEvent): Boolean;
var
  iSpdId: Int64;
  dTotalAmount: Double;

  function setValues(aIndex: Integer; AElement: TJSElement): Boolean;
  var
    iDebId: Int64;
  begin
    iDebId := StrToIntDef(jQuery(AElement).attr('data-debid'), -1);
    if Locate(dsSettlementDebts, ['sddsettlementplandetailfk', 'sdddebtfk'], [iSpdId, iDebId]) then
    begin
      console.log('iDebId = ' + iDebId.ToString + ', sdddebtfk = ' + dsSettlementDebts.FieldByName('sdddebtfk').asString);
      console.log('iSpdId = ' + iSpdId.ToString + ', sddsettlementplandetailfk = ' + dsSettlementDebts.FieldByName('sddsettlementplandetailfk').asString);
      console.log('sddid = ' + dsSettlementDebts.FieldByName('sddid').asString);
      console.log('sddamount = ' + dsSettlementDebts.FieldByName('sddamount').asString);
      jQuery(AElement).val(FloatToStr(dsSettlementDebts.FieldByName('sddamount').asFloat)).prop('data-sddid', dsSettlementDebts.FieldByName('sddid').asString);
      dTotalAmount := dTotalAmount + dsSettlementDebts.FieldByName('sddamount').asFloat;
    end
    else
      console.log('no settlementplandebtordetail found!');

    Result := True;
  end;

begin
  iSpdId := StrToIntDef(jQuery(Evt.target).closest('td').find('input').attr('data-spdid'), -1);

  dTotalAmount := 0;
  jQuery('#mdlDebtDetails').find('table').find('tbody').find('input[role="debsubtotal"]').each(@setValues);
  jQuery('#mdlDebtDetails').find('table').find('tbody').find('input[role="debtotal"]').val(FloatToStr(dTotalAmount));

  FRef := jQuery(Evt.target).closest('td').find('input').attr('data-ref');
  mdlDebtDetails.Show;
  Result := True;
end;

procedure TfrmSetlementPlanEdit.OnGetPlanCatItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string; ValueType: Integer; Extra: string; isLast: Boolean);
begin
  SetLength(FCategories, Length(FCategories) + 1);
  FCategories[Length(FCategories) - 1].id := RecordID;
  FCategories[Length(FCategories) - 1].Name := name;
  FCategories[Length(FCategories) - 1].Value := Value;
  FCategories[Length(FCategories) - 1].Extra := Extra;
end;

procedure TfrmSetlementPlanEdit.OnSaveButtonClicked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  SaveSettlement;
  SaveSettlementPlanDetails;
end;

procedure TfrmSetlementPlanEdit.OnSetDateValue(Sender: TObject; Source: TField;
  Dest: TElementAction; var aHandled: Boolean);
begin
  inherited;
  if not IsEmptyDate(Source.asDateTime) then
    Dest.Value := FormatHTMLDate(Source.asDateTime);
  aHandled := True;
end;

procedure TfrmSetlementPlanEdit.OnShowCopyMonthModal(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  mdlCopyMonth.Show;
end;

procedure TfrmSetlementPlanEdit.SaveSettlement;
begin
  if eacSettlement.ValidateActions(True) then
  begin
    if dsSettlement.isEmpty then
    begin
      console.log('something is very wrong, we are inserting a settlement?!');
      dsSettlement.Insert;
      dsSettlement.FieldByName('sepcreatedon').asDateTime := now;
      dsSettlement.FieldByName('sepcreatedbyfk').asInteger := Server.UserId;
      dsSettlement.FieldByName('sepdossierfk').asInteger := DossierID;
    end
    else
    begin
      dsSettlement.Edit;
    end;
    dsSettlement.FieldByName('sepchangedbyfk').asInteger := Server.UserId;
    dsSettlement.FieldByName('sepchangedon').asDateTime := now;
    eacSettlement.ElementsToDataset;
    dsSettlement.Post;
    dsSettlement.ApplyUpdates;
  end;
end;

procedure TfrmSetlementPlanEdit.SaveSettlementPlanDetails;

  function SaveSettlementPlanDetailAmount(aIndex: Integer; AElement: TJSElement): Boolean;
  var
    dAmount: Double;
    iSpdId: Int64;
  begin
    dAmount := StrToFloatDef(string(jQuery(AElement).val), 0);
    iSpdId := StrToIntDef(jQuery(AElement).attr('data-spdid'), -1);

    SaveSettlementPlanDetail(iSpdId, dAmount);

    Result := True;
  end;

begin
  jQuery('#plan').find('tbody').find('input').each(@SaveSettlementPlanDetailAmount);
  dsSettlementDetails.ApplyUpdates;
end;

procedure TfrmSetlementPlanEdit.SaveSettlementPlanDetail(aSpdId: Int64; dAmount: Double);
begin
  if Locate(dsSettlementDetails, 'spdid', aSpdId) then
  begin
    if dAmount <> dsSettlementDetails.FieldByName('spdamount').asFloat then
    begin
      dsSettlementDetails.Edit;
      dsSettlementDetails.FieldByName('spdchangedon').asDateTime := now;
      dsSettlementDetails.FieldByName('spdchangedbyfk').asInteger := Server.UserId;
      dsSettlementDetails.FieldByName('spdamount').asFloat := dAmount;
      dsSettlementDetails.Post;
    end;
  end
  else
    console.log('settlemenplandetail not found!');
end;

procedure TfrmSetlementPlanEdit.setParams(const Params: TStrings);
begin
  inherited;

  jQuery('#returnBack').attr('href', DossierURL('/settlementplan'));
  if Assigned(Params) and (Params.indexOfName('sepID') <> -1) then
  begin
    FSettlementID := StrToIntDef(Params.Values['sepID'], -1);
  end;
  dsSettlement.ParamByName('SEPID').asInteger := FSettlementID;
  dsSettlement.ParamByName('DOSSIERID').asInteger := DossierID;
  dsDebts.ParamByName('SEPID').asInteger := FSettlementID;
  dsSettlementDebts.ParamByName('PLANID').asInteger := FSettlementID;
  dsBudgetPlans.ParamByName('DOSSIERID').asInteger := DossierID;
end;

procedure TfrmSetlementPlanEdit.WebFormCreate(Sender: TObject);

  function OnDataChange(Evt: TEventListenerEvent): Boolean;
  begin
    DiscardChanges := false;
    Result := True;
  end;

begin
  inherited;
  FSettlementID := -1;
  FDetailsChanged := false;

  Server.CSRConnection.Message.ClientID := Server.ClientID;
  dsSettlement.DAConnection := Server.CSRConnection;
  dsSettlementDetails.DAConnection := Server.CSRConnection;
  dsDebts.DAConnection := Server.CSRConnection;
  dsSettlementDebts.DAConnection := Server.CSRConnection;
  dsBudgetPlans.DAConnection := Server.CSRConnection;
  dsBudgetTotals.DAConnection := Server.CSRConnection;

  Server.GetParameterList('PLANCAT', Language, @OnGetPlanCatItem);

  jQuery('#form').find('select').On_('change', @OnDataChange);
end;

procedure TfrmSetlementPlanEdit.WebFormShow(Sender: TObject);
begin
  inherited;
  Server.DoOnParamsLoaded(
    procedure
    begin
      dsSettlement.Load([], nil);
      dsDebts.Load([], nil);
      dsSettlementDebts.Load([], nil);
      dsBudgetPlans.Load([], nil);
    end);
end;

procedure TfrmSetlementPlanEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsSettlement := TP2WDADataset.Create(Self);
  eacSettlement := TPW2DataElementMapper.Create(Self);
  dsSettlementDetails := TP2WDADataset.Create(Self);
  mdlDebtDetails := TP2WBSModal.Create(Self);
  dsDebts := TP2WDADataset.Create(Self);
  dsSettlementDebts := TP2WDADataset.Create(Self);
  mdlBudget := TP2WBSModal.Create(Self);
  dsBudgetPlans := TP2WDADataset.Create(Self);
  dsBudgetTotals := TP2WDADataset.Create(Self);
  mdlCopyMonth := TP2WBSModal.Create(Self);
  mdlCertify := TP2WBSModal.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsSettlement.BeforeLoadDFMValues;
  eacSettlement.BeforeLoadDFMValues;
  dsSettlementDetails.BeforeLoadDFMValues;
  mdlDebtDetails.BeforeLoadDFMValues;
  dsDebts.BeforeLoadDFMValues;
  dsSettlementDebts.BeforeLoadDFMValues;
  mdlBudget.BeforeLoadDFMValues;
  dsBudgetPlans.BeforeLoadDFMValues;
  dsBudgetTotals.BeforeLoadDFMValues;
  mdlCopyMonth.BeforeLoadDFMValues;
  mdlCertify.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtName';
      Name := 'edtName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidFrom';
      Name := 'ddtValidFrom';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidTill';
      Name := 'ddtValidTill';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'OnSaveButtonClicked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'selCategory';
      Name := 'selCategory';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnApplyDebts';
      Name := 'btnApplyDebts';
      SetEvent(Self, 'OnExecute', 'OnDebtDetailsChanged');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave2';
      Name := 'btnSave2';
      SetEvent(Self, 'OnExecute', 'OnSaveButtonClicked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'selBudgetPlan';
      Name := 'selBudgetPlan';
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'selBudgetPlan';
      Name := 'selBudgetPlan';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnApplyBudget';
      Name := 'btnApplyBudget';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnCopyMonths';
      Name := 'btnCopyMonths';
      SetEvent(Self, 'OnExecute', 'OnShowCopyMonthModal');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnCopy';
      Name := 'btnCopy';
      SetEvent(Self, 'OnExecute', 'DoCopyMonth');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'refMonth';
      Name := 'refMonth';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'startMonth';
      Name := 'startMonth';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'endMonth';
      Name := 'endMonth';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnCertify';
      Name := 'btnCertify';
      SetEvent(Self, 'OnExecute', 'OnCertifyClicked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnYes';
      Name := 'btnYes';
      SetEvent(Self, 'OnExecute', 'OnCertifyConfirmed');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnPrint';
      Name := 'btnPrint';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtPaymentOn';
      Name := 'edtPaymentOn';
      PreventDefault := False;
      StopPropagation := False;
    end;
    dsSettlement.SetParentComponent(Self);
    dsSettlement.Name := 'dsSettlement';
    dsSettlement.TableName := 'settlementplan';
    dsSettlement.Params.Clear;
    with dsSettlement.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SEPID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsSettlement.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    dsSettlement.WhereClause := '<?xml version="1.0" encoding="UTF-8"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>sepid</field><parameter type="LargeInt">SEPID</parameter></binaryoperation><binaryoperation operator="Equal"><field>sepdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></binaryoperation></where></query>';
    dsSettlement.DAOptions := [doRefreshAllFields];
    dsSettlement.AfterOpen := dsSettlementAfterOpen;
    dsSettlement.AfterApplyUpdates := dsSettlementAfterApplyUpdates;
    dsSettlement.Left := 40;
    dsSettlement.Top := 88;
    eacSettlement.SetParentComponent(Self);
    eacSettlement.Name := 'eacSettlement';
    eacSettlement.ActionLinks.Clear;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'edtName';
      FieldName := 'sepdescription';
      Options := [aoRequired];
    end;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'ddtValidFrom';
      FieldName := 'sepvalidfrom';
      Options := [aoReadOnly];
      SetEvent(Self, 'OnFieldToAction', 'OnSetDateValue');
    end;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'ddtValidTill';
      FieldName := 'sepvalidtill';
      Options := [aoReadOnly];
      SetEvent(Self, 'OnFieldToAction', 'OnSetDateValue');
    end;
    with eacSettlement.ActionLinks.Add do
    begin
      ActionName := 'edtPaymentOn';
      FieldName := 'seppaymenton';
      Options := [aoReadOnly];
    end;
    eacSettlement.ActionList := alForm;
    eacSettlement.Dataset := dsSettlement;
    eacSettlement.Left := 40;
    eacSettlement.Top := 160;
    dsSettlementDetails.SetParentComponent(Self);
    dsSettlementDetails.Name := 'dsSettlementDetails';
    dsSettlementDetails.TableName := 'settlementplandetail';
    dsSettlementDetails.Params.Clear;
    with dsSettlementDetails.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SETTLEMENTID';
      ParamType := ptInput;
    end;
    dsSettlementDetails.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>spdsettlementplanfk</field><parameter type="LargeInt">SETTLEMENTID</parameter></binaryoperation></where></query>';
    dsSettlementDetails.DAOptions := [];
    dsSettlementDetails.AfterOpen := dsSettlementDetailsAfterOpen;
    dsSettlementDetails.AfterApplyUpdates := dsSettlementDetailsAfterApplyUpdates;
    dsSettlementDetails.Left := 48;
    dsSettlementDetails.Top := 232;
    mdlDebtDetails.SetParentComponent(Self);
    mdlDebtDetails.Name := 'mdlDebtDetails';
    mdlDebtDetails.PreRenderedID := 'mdlDebtDetails';
    mdlDebtDetails.RemoveOnHide := False;
    mdlDebtDetails.BackDrop := True;
    mdlDebtDetails.Left := 544;
    mdlDebtDetails.Top := 40;
    dsDebts.SetParentComponent(Self);
    dsDebts.Name := 'dsDebts';
    dsDebts.TableName := 'DebtOverviewPerSettlementPlan';
    dsDebts.Params.Clear;
    with dsDebts.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SEPID';
      ParamType := ptInput;
    end;
    dsDebts.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>sepid</field><parameter type="LargeInt">SEPID</parameter></binaryoperation><binaryoperation operator="Equal"><field>debrecordtype</field><constant type="Integer">1</constant></binaryoperation></binaryoperation></where></query>';
    dsDebts.DAOptions := [];
    dsDebts.AfterOpen := dsDebtsAfterOpen;
    dsDebts.Left := 536;
    dsDebts.Top := 128;
    dsSettlementDebts.SetParentComponent(Self);
    dsSettlementDebts.Name := 'dsSettlementDebts';
    dsSettlementDebts.TableName := 'settlementplandebtordetail';
    dsSettlementDebts.Params.Clear;
    with dsSettlementDebts.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'PLANID';
      ParamType := ptInput;
    end;
    dsSettlementDebts.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>sepid</field><parameter type="LargeInt">PLANID</parameter></binaryoperation></where></query>';
    dsSettlementDebts.DAOptions := [];
    dsSettlementDebts.AfterApplyUpdates := dsSettlementDebtsAfterApplyUpdates;
    dsSettlementDebts.Left := 536;
    dsSettlementDebts.Top := 192;
    mdlBudget.SetParentComponent(Self);
    mdlBudget.Name := 'mdlBudget';
    mdlBudget.PreRenderedID := 'mdlBudget';
    mdlBudget.RemoveOnHide := False;
    mdlBudget.BackDrop := True;
    mdlBudget.Left := 288;
    mdlBudget.Top := 32;
    dsBudgetPlans.SetParentComponent(Self);
    dsBudgetPlans.Name := 'dsBudgetPlans';
    dsBudgetPlans.TableName := 'budgetplan';
    dsBudgetPlans.Params.Clear;
    with dsBudgetPlans.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    dsBudgetPlans.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>bpldossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsBudgetPlans.DAOptions := [];
    dsBudgetPlans.Left := 288;
    dsBudgetPlans.Top := 88;
    dsBudgetTotals.SetParentComponent(Self);
    dsBudgetTotals.Name := 'dsBudgetTotals';
    dsBudgetTotals.TableName := 'BudgetPlanCategoryTotals';
    dsBudgetTotals.Params.Clear;
    with dsBudgetTotals.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BPLID';
      ParamType := ptInput;
    end;
    dsBudgetTotals.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>bplid</field><parameter type="LargeInt">BPLID</parameter></binaryoperation></where></query>';
    dsBudgetTotals.DAOptions := [];
    dsBudgetTotals.Left := 288;
    dsBudgetTotals.Top := 136;
    mdlCopyMonth.SetParentComponent(Self);
    mdlCopyMonth.Name := 'mdlCopyMonth';
    mdlCopyMonth.PreRenderedID := 'mdlCopyMonths';
    mdlCopyMonth.RemoveOnHide := False;
    mdlCopyMonth.BackDrop := True;
    mdlCopyMonth.Left := 280;
    mdlCopyMonth.Top := 216;
    mdlCertify.SetParentComponent(Self);
    mdlCertify.Name := 'mdlCertify';
    mdlCertify.PreRenderedID := 'mdlCertify';
    mdlCertify.RemoveOnHide := False;
    mdlCertify.BackDrop := True;
    mdlCertify.Left := 48;
    mdlCertify.Top := 304;
  finally
    alForm.AfterLoadDFMValues;
    dsSettlement.AfterLoadDFMValues;
    eacSettlement.AfterLoadDFMValues;
    dsSettlementDetails.AfterLoadDFMValues;
    mdlDebtDetails.AfterLoadDFMValues;
    dsDebts.AfterLoadDFMValues;
    dsSettlementDebts.AfterLoadDFMValues;
    mdlBudget.AfterLoadDFMValues;
    dsBudgetPlans.AfterLoadDFMValues;
    dsBudgetTotals.AfterLoadDFMValues;
    mdlCopyMonth.AfterLoadDFMValues;
    mdlCertify.AfterLoadDFMValues;
  end;
end;

end.
