unit Forms.Realestate.Prop;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper, DateUtils,
  Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmRealestateProperty = class(TfrmBase)
    dsRealestate: TP2WDADataset;
    dsRealestateproid: TLargeintField;
    dsRealestateprocreatedon: TDateTimeField;
    dsRealestateprocreatedbyfk: TLargeintField;
    dsRealestateprochangedon: TDateTimeField;
    dsRealestateprochangedbyfk: TLargeintField;
    dsRealestateprodossierfk: TLargeintField;
    dsRealestatepropropertytype: TStringField;
    dsRealestatepropropertytypefk: TLargeintField;
    dsRealestateproowner: TStringField;
    dsRealestateproownerfk: TLargeintField;
    dsRealestateproisrealestate: TBooleanField;
    dsRealestateproshortdescription: TStringField;
    dsRealestateprodescription: TBlobField;
    dsRealestateprocount: TIntegerField;
    dsRealestatepropurchasedon: TDateTimeField;
    dsRealestatepronotaryname: TStringField;
    dsRealestatepropurchaseprice: TFMTBCDField;
    dsRealestateprocurrentvalue: TFMTBCDField;
    dsRealestateproisfinanced: TBooleanField;
    dsRealestateproisseized: TBooleanField;
    dsRealestateproisshared: TBooleanField;
    dsRealestateproisgone: TBooleanField;
    dsRealestateprogoneon: TDateTimeField;
    dsRealestateprobuyerfk: TLargeintField;
    dsRealestateproiscomplete: TBooleanField;
    dsRealestateproremark: TStringField;
    dsDocuments: TP2WDADataset;
    dsRealestateprofinancing: TWideStringField;
    procedure dsRealestateAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure dsRealestateAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure alFormActions10Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }
    FPosessionId : Int64;

    function FieldsValid: Boolean;
    procedure DoSaveData;
    procedure GetPropertyOwners;
  public
    procedure setParams(const Params: TStrings); override;
    Procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmRealestateProperty: TfrmRealestateProperty;

implementation

uses
  StrUtils, Units.ActionUtils, Units.Params, Modules.Server, Units.DADatasetUtils, Units.HTMLUtils;

{$R *.dfm}

{ TfrmRealestateProperty }

procedure TfrmRealestateProperty.dsRealestateAfterApplyUpdates(Sender:
    TDataSet; Info: TResolveResults);

  procedure uploadsCompleted;
  begin
    DiscardChanges := True;
    window.location.href := DossierURL('/realestate');
  end;

begin
  if CheckResolveResults(Info) then
  begin
    if DefaultDocumentBox.GetAwaitingUploadCount = 0 then
    begin
      DiscardChanges := True;
      window.location.href := DossierURL('/realestate');
    end
    else
      DefaultDocumentBox.DoFileQueueUpload(Sender.FieldByName('proid').asLargeInt,'', @uploadsCompleted);
  end;
end;

procedure TfrmRealestateProperty.WebFormCreate(Sender: TObject);

begin
  inherited;
  FPosessionId := -1;

  dmServer.DoOnParamsLoaded(GetPropertyOwners);

  dsRealestate.DAConnection := DossierConnection;
  BindOnChangeEvent;
end;

procedure TfrmRealestateProperty.GetPropertyOwners;

var
  list : TDezqParameterArray;
  i : Integer;
  S : String;
begin
  list := Server.GetParamList('PROPOWN');
  S:='';
  for i := Low(list) to High(list) do
    with List[i] do
      S:=S+Format('<option value="%s" data-id="%d">%s</option>',[Name,RecordId,Value]);
  alForm['cbOwner'].InnerHTML:=S;
end;

procedure TfrmRealestateProperty.dsRealestateAfterOpen(DataSet: TDataSet);

var
  SourceID : NativeInt;

begin
  inherited;
  SourceID:=-1;
  if not DataSet.isEmpty then
  begin
     With DataSet.FieldByName('propurchasedon') do
      begin
      // 1901-01-01 is empty date
      if Not IsNull and (AsDateTime<>EncodeDate(1901,01,01)) then
        alForm['ddtNotarialDate'].Value:=FormatHTMLDate(DataSet.FieldByName('propurchasedon').asDateTime)
      else
        alForm['ddtNotarialDate'].Value:='';
      end;
    alForm['edtNotary'].value := DataSet.FieldByName('pronotaryname').asString;
    alForm['mmDescription'].value := DataSet.FieldByName('proshortdescription').asString;
    alForm['edtPurchasePrice'].value := DataSet.FieldByName('propurchaseprice').asString;
    alForm['edtCurrentPrice'].value := DataSet.FieldByName('procurrentvalue').asString;
    alForm['mmFinancing'].value := DataSet.FieldByName('profinancing').asString;
    alForm['edtRemark'].value := DataSet.FieldByName('proremark').asString;
    alForm['cbOwner'].value := DataSet.FieldByName('proowner').asString;
    jQuery('#cbOwner').attr('data-id', DataSet.FieldByName('proownerfk').asString);

    setRadiogroupSelectedElement('rdRECompleted', IfThen(DataSet.FieldByName('proiscomplete').asBoolean,'Yes','No'));
    setRadiogroupSelectedElement('rdFinanced', IfThen(Dataset.FieldByName('proisfinanced').asBoolean,'Yes','No'));

    SourceID:=DataSet.FieldByName('proid').asLargeInt;
  end;
  DefaultDocumentBox.LoadDocuments(dsDocuments, DossierID, SourceID, 'uploadList');
  DefaultDocumentBox.setDocBoxParams(12, SourceID, 'PROPPROOF', DossierID);
end;

function TfrmRealestateProperty.FieldsValid: Boolean;
begin
  Result := not FieldIsEmpty('mmDescription');
  Result := not FieldIsEmpty('edtPurchasePrice') and Result;
  Result := isNumeric('edtPurchasePrice') and Result;
  Result := not FieldIsEmpty('edtCurrentPrice') and Result;
  Result := isNumeric('edtCurrentPrice') and Result;
  Result := not FieldIsEmpty('mmFinancing') and Result;
  Result := isSelectedRadioGroup('rdRECompleted') and Result;
end;


procedure TfrmRealestateProperty.alFormActions10Execute(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

Var
  ID : String;

begin
  inherited;
  id := String(jQuery('#cbOwner').find('option[value="' + alForm['cbOwner'].Value + '"]').attr('data-id'));
  if id = '' then
    id := '-1';
  jQuery('#cbOwner').attr('data-id', id);
end;

procedure TfrmRealestateProperty.canHandleRoute(previousURL: string;
  var allow: Boolean);
begin
  allow := DiscardChanges and ((DefaultDocumentBox.GetAwaitingUploadCount = 0) OR DiscardFiles);
end;

procedure TfrmRealestateProperty.DoSaveData;


begin
  With dsRealestate do
  begin
    if FPosessionId > 0 then
    begin
      Edit;
    end
    else
    begin
      Insert;
      FieldByName('procreatedon').asDateTime := now();
      FieldByName('procreatedbyfk').asLargeInt := Server.UserID;
      FieldByName('prodossierfk').asLargeInt := DossierID;
      FieldByName('propropertytype').asString := 'ONRG';
      FieldByName('propropertytypefk').asInteger := Server.GetParamId('PROPTYP', 'ONRG');
      FieldByName('proisrealestate').asBoolean := true;
      FieldByName('procount').asInteger := 1;
      FieldByName('prodescription').asString := '-';

      FieldByName('proisseized').asBoolean := false;
      FieldByName('proisshared').asBoolean := false;
      FieldByName('proisgone').asBoolean := false;
      FieldByName('proisrealestate').asBoolean := true;
    end;

    FieldByName('proowner').asString := alForm['cbOwner'].value;
    FieldByName('proownerfk').asInteger := JSValueToInt(jQuery('#cbOwner').attr('data-id'));

    FieldByName('prochangedon').asDateTime := now();
    FieldByName('prochangedbyfk').asLargeInt := Server.UserID;

    if alForm['ddtNotarialDate'].value<>'' then
      FieldByName('propurchasedon').asDateTime :=ExtractDate(alForm['ddtNotarialDate'].value)
    else
      // The field is required...
      FieldByName('propurchasedon').asDateTime := EncodeDate(1901,01,01);

    FieldByName('pronotaryname').asString := alForm['edtNotary'].value;
    FieldByName('proshortdescription').asString := alForm['mmDescription'].value;
    FieldByName('propurchaseprice').asString := alForm['edtPurchasePrice'].value;
    FieldByName('procurrentvalue').asString := alForm['edtCurrentPrice'].value;
    FieldByName('profinancing').asString := alForm['mmFinancing'].value;
    FieldByName('proremark').asString := alForm['edtRemark'].value;
    FieldByName('proiscomplete').asBoolean := getRadioGroupValue('rdRECompleted') = 'Yes';
    FieldByName('proisfinanced').asBoolean := getRadioGroupValue('rdFinanced') = 'Yes';
    Post;
    ApplyUpdates;
  end;
end;
procedure TfrmRealestateProperty.SaveBtnClick(Sender: TObject;
    Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  ClearFormErrors;
  if FieldsValid then
    dmServer.DoOnParamsLoaded(DoSaveData);
end;

procedure TfrmRealestateProperty.setParams(const Params: TStrings);
begin
  Inherited;
  jQuery('#returnBack').attr('href',DossierURL('/realestate'));
  FPosessionId := StrToInt64Def(Params.Values['POSSESSIONID'], -1);
end;

procedure TfrmRealestateProperty.WebFormShow(Sender: TObject);
begin
  inherited;
  dsRealestate.ParamByName('PROID').AsInteger := FPosessionId;
  dsRealestate.Load([], nil);
end;

procedure TfrmRealestateProperty.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsRealestate := TP2WDADataset.Create(Self);
  dsRealestateproid := TLargeintField.Create(Self);
  dsRealestateprocreatedon := TDateTimeField.Create(Self);
  dsRealestateprocreatedbyfk := TLargeintField.Create(Self);
  dsRealestateprochangedon := TDateTimeField.Create(Self);
  dsRealestateprochangedbyfk := TLargeintField.Create(Self);
  dsRealestateprodossierfk := TLargeintField.Create(Self);
  dsRealestatepropropertytype := TStringField.Create(Self);
  dsRealestatepropropertytypefk := TLargeintField.Create(Self);
  dsRealestateproowner := TStringField.Create(Self);
  dsRealestateproownerfk := TLargeintField.Create(Self);
  dsRealestateproisrealestate := TBooleanField.Create(Self);
  dsRealestateproshortdescription := TStringField.Create(Self);
  dsRealestateprodescription := TBlobField.Create(Self);
  dsRealestateprocount := TIntegerField.Create(Self);
  dsRealestatepropurchasedon := TDateTimeField.Create(Self);
  dsRealestatepronotaryname := TStringField.Create(Self);
  dsRealestatepropurchaseprice := TFMTBCDField.Create(Self);
  dsRealestateprocurrentvalue := TFMTBCDField.Create(Self);
  dsRealestateproisfinanced := TBooleanField.Create(Self);
  dsRealestateproisseized := TBooleanField.Create(Self);
  dsRealestateproisshared := TBooleanField.Create(Self);
  dsRealestateproisgone := TBooleanField.Create(Self);
  dsRealestateprogoneon := TDateTimeField.Create(Self);
  dsRealestateprobuyerfk := TLargeintField.Create(Self);
  dsRealestateproiscomplete := TBooleanField.Create(Self);
  dsRealestateproremark := TStringField.Create(Self);
  dsRealestateprofinancing := TWideStringField.Create(Self);
  dsDocuments := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsRealestate.BeforeLoadDFMValues;
  dsRealestateproid.BeforeLoadDFMValues;
  dsRealestateprocreatedon.BeforeLoadDFMValues;
  dsRealestateprocreatedbyfk.BeforeLoadDFMValues;
  dsRealestateprochangedon.BeforeLoadDFMValues;
  dsRealestateprochangedbyfk.BeforeLoadDFMValues;
  dsRealestateprodossierfk.BeforeLoadDFMValues;
  dsRealestatepropropertytype.BeforeLoadDFMValues;
  dsRealestatepropropertytypefk.BeforeLoadDFMValues;
  dsRealestateproowner.BeforeLoadDFMValues;
  dsRealestateproownerfk.BeforeLoadDFMValues;
  dsRealestateproisrealestate.BeforeLoadDFMValues;
  dsRealestateproshortdescription.BeforeLoadDFMValues;
  dsRealestateprodescription.BeforeLoadDFMValues;
  dsRealestateprocount.BeforeLoadDFMValues;
  dsRealestatepropurchasedon.BeforeLoadDFMValues;
  dsRealestatepronotaryname.BeforeLoadDFMValues;
  dsRealestatepropurchaseprice.BeforeLoadDFMValues;
  dsRealestateprocurrentvalue.BeforeLoadDFMValues;
  dsRealestateproisfinanced.BeforeLoadDFMValues;
  dsRealestateproisseized.BeforeLoadDFMValues;
  dsRealestateproisshared.BeforeLoadDFMValues;
  dsRealestateproisgone.BeforeLoadDFMValues;
  dsRealestateprogoneon.BeforeLoadDFMValues;
  dsRealestateprobuyerfk.BeforeLoadDFMValues;
  dsRealestateproiscomplete.BeforeLoadDFMValues;
  dsRealestateproremark.BeforeLoadDFMValues;
  dsRealestateprofinancing.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtNotarialDate';
      Name := 'ddtNotarialDate';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtNotary';
      Name := 'edtNotary';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'mmDescription';
      Name := 'mmDescription';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtPurchasePrice';
      Name := 'edtPurchasePrice';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtCurrentPrice';
      Name := 'edtCurrentPrice';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'mmFinancing';
      Name := 'mmFinancing';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdRECompleted';
      PreventDefault := False;
      Selector := 'input[name="rdRECompleted"]';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtRemark';
      Name := 'edtRemark';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdFinanced';
      PreventDefault := False;
      Selector := 'input[name="rdFinanced"]';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'cbOwner';
      Name := 'cbOwner';
      SetEvent(Self, 'OnExecute', 'alFormActions10Execute');
    end;
    dsRealestate.SetParentComponent(Self);
    dsRealestate.Name := 'dsRealestate';
    dsRealestate.TableName := 'property';
    dsRealestate.Params.Clear;
    with dsRealestate.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'PROID';
      ParamType := ptInput;
      Value := Null;
    end;
    dsRealestate.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>proid</field><parameter type="LargeInt">PROID</parameter></binaryoperation></where></query>';
    dsRealestate.DAOptions := [];
    dsRealestate.AfterOpen := dsRealestateAfterOpen;
    dsRealestate.AfterApplyUpdates := dsRealestateAfterApplyUpdates;
    dsRealestate.Left := 40;
    dsRealestate.Top := 80;
    dsRealestateproid.SetParentComponent(dsRealestate);
    dsRealestateproid.Name := 'dsRealestateproid';
    dsRealestateproid.FieldName := 'proid';
    dsRealestateprocreatedon.SetParentComponent(dsRealestate);
    dsRealestateprocreatedon.Name := 'dsRealestateprocreatedon';
    dsRealestateprocreatedon.FieldName := 'procreatedon';
    dsRealestateprocreatedbyfk.SetParentComponent(dsRealestate);
    dsRealestateprocreatedbyfk.Name := 'dsRealestateprocreatedbyfk';
    dsRealestateprocreatedbyfk.FieldName := 'procreatedbyfk';
    dsRealestateprochangedon.SetParentComponent(dsRealestate);
    dsRealestateprochangedon.Name := 'dsRealestateprochangedon';
    dsRealestateprochangedon.FieldName := 'prochangedon';
    dsRealestateprochangedbyfk.SetParentComponent(dsRealestate);
    dsRealestateprochangedbyfk.Name := 'dsRealestateprochangedbyfk';
    dsRealestateprochangedbyfk.FieldName := 'prochangedbyfk';
    dsRealestateprodossierfk.SetParentComponent(dsRealestate);
    dsRealestateprodossierfk.Name := 'dsRealestateprodossierfk';
    dsRealestateprodossierfk.FieldName := 'prodossierfk';
    dsRealestatepropropertytype.SetParentComponent(dsRealestate);
    dsRealestatepropropertytype.Name := 'dsRealestatepropropertytype';
    dsRealestatepropropertytype.FieldName := 'propropertytype';
    dsRealestatepropropertytype.Size := 10;
    dsRealestatepropropertytypefk.SetParentComponent(dsRealestate);
    dsRealestatepropropertytypefk.Name := 'dsRealestatepropropertytypefk';
    dsRealestatepropropertytypefk.FieldName := 'propropertytypefk';
    dsRealestateproowner.SetParentComponent(dsRealestate);
    dsRealestateproowner.Name := 'dsRealestateproowner';
    dsRealestateproowner.FieldName := 'proowner';
    dsRealestateproowner.Size := 10;
    dsRealestateproownerfk.SetParentComponent(dsRealestate);
    dsRealestateproownerfk.Name := 'dsRealestateproownerfk';
    dsRealestateproownerfk.FieldName := 'proownerfk';
    dsRealestateproisrealestate.SetParentComponent(dsRealestate);
    dsRealestateproisrealestate.Name := 'dsRealestateproisrealestate';
    dsRealestateproisrealestate.FieldName := 'proisrealestate';
    dsRealestateproshortdescription.SetParentComponent(dsRealestate);
    dsRealestateproshortdescription.Name := 'dsRealestateproshortdescription';
    dsRealestateproshortdescription.FieldName := 'proshortdescription';
    dsRealestateproshortdescription.Size := 127;
    dsRealestateprodescription.SetParentComponent(dsRealestate);
    dsRealestateprodescription.Name := 'dsRealestateprodescription';
    dsRealestateprodescription.FieldName := 'prodescription';
    dsRealestateprocount.SetParentComponent(dsRealestate);
    dsRealestateprocount.Name := 'dsRealestateprocount';
    dsRealestateprocount.FieldName := 'procount';
    dsRealestatepropurchasedon.SetParentComponent(dsRealestate);
    dsRealestatepropurchasedon.Name := 'dsRealestatepropurchasedon';
    dsRealestatepropurchasedon.FieldName := 'propurchasedon';
    dsRealestatepronotaryname.SetParentComponent(dsRealestate);
    dsRealestatepronotaryname.Name := 'dsRealestatepronotaryname';
    dsRealestatepronotaryname.FieldName := 'pronotaryname';
    dsRealestatepronotaryname.Size := 100;
    dsRealestatepropurchaseprice.SetParentComponent(dsRealestate);
    dsRealestatepropurchaseprice.Name := 'dsRealestatepropurchaseprice';
    dsRealestatepropurchaseprice.FieldName := 'propurchaseprice';
    dsRealestatepropurchaseprice.Precision := 12;
    dsRealestatepropurchaseprice.Size := 0;
    dsRealestateprocurrentvalue.SetParentComponent(dsRealestate);
    dsRealestateprocurrentvalue.Name := 'dsRealestateprocurrentvalue';
    dsRealestateprocurrentvalue.FieldName := 'procurrentvalue';
    dsRealestateprocurrentvalue.Precision := 12;
    dsRealestateprocurrentvalue.Size := 0;
    dsRealestateproisfinanced.SetParentComponent(dsRealestate);
    dsRealestateproisfinanced.Name := 'dsRealestateproisfinanced';
    dsRealestateproisfinanced.FieldName := 'proisfinanced';
    dsRealestateproisseized.SetParentComponent(dsRealestate);
    dsRealestateproisseized.Name := 'dsRealestateproisseized';
    dsRealestateproisseized.FieldName := 'proisseized';
    dsRealestateproisshared.SetParentComponent(dsRealestate);
    dsRealestateproisshared.Name := 'dsRealestateproisshared';
    dsRealestateproisshared.FieldName := 'proisshared';
    dsRealestateproisgone.SetParentComponent(dsRealestate);
    dsRealestateproisgone.Name := 'dsRealestateproisgone';
    dsRealestateproisgone.FieldName := 'proisgone';
    dsRealestateprogoneon.SetParentComponent(dsRealestate);
    dsRealestateprogoneon.Name := 'dsRealestateprogoneon';
    dsRealestateprogoneon.FieldName := 'progoneon';
    dsRealestateprobuyerfk.SetParentComponent(dsRealestate);
    dsRealestateprobuyerfk.Name := 'dsRealestateprobuyerfk';
    dsRealestateprobuyerfk.FieldName := 'probuyerfk';
    dsRealestateproiscomplete.SetParentComponent(dsRealestate);
    dsRealestateproiscomplete.Name := 'dsRealestateproiscomplete';
    dsRealestateproiscomplete.FieldName := 'proiscomplete';
    dsRealestateproremark.SetParentComponent(dsRealestate);
    dsRealestateproremark.Name := 'dsRealestateproremark';
    dsRealestateproremark.FieldName := 'proremark';
    dsRealestateproremark.Size := 255;
    dsRealestateprofinancing.SetParentComponent(dsRealestate);
    dsRealestateprofinancing.Name := 'dsRealestateprofinancing';
    dsRealestateprofinancing.FieldName := 'profinancing';
    dsRealestateprofinancing.Size := 255;
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">PROPPROOF</constant></binaryoperation></binaryoperation></where></query>';
    dsDocuments.DAOptions := [];
    dsDocuments.Left := 544;
    dsDocuments.Top := 40;
  finally
    alForm.AfterLoadDFMValues;
    dsRealestate.AfterLoadDFMValues;
    dsRealestateproid.AfterLoadDFMValues;
    dsRealestateprocreatedon.AfterLoadDFMValues;
    dsRealestateprocreatedbyfk.AfterLoadDFMValues;
    dsRealestateprochangedon.AfterLoadDFMValues;
    dsRealestateprochangedbyfk.AfterLoadDFMValues;
    dsRealestateprodossierfk.AfterLoadDFMValues;
    dsRealestatepropropertytype.AfterLoadDFMValues;
    dsRealestatepropropertytypefk.AfterLoadDFMValues;
    dsRealestateproowner.AfterLoadDFMValues;
    dsRealestateproownerfk.AfterLoadDFMValues;
    dsRealestateproisrealestate.AfterLoadDFMValues;
    dsRealestateproshortdescription.AfterLoadDFMValues;
    dsRealestateprodescription.AfterLoadDFMValues;
    dsRealestateprocount.AfterLoadDFMValues;
    dsRealestatepropurchasedon.AfterLoadDFMValues;
    dsRealestatepronotaryname.AfterLoadDFMValues;
    dsRealestatepropurchaseprice.AfterLoadDFMValues;
    dsRealestateprocurrentvalue.AfterLoadDFMValues;
    dsRealestateproisfinanced.AfterLoadDFMValues;
    dsRealestateproisseized.AfterLoadDFMValues;
    dsRealestateproisshared.AfterLoadDFMValues;
    dsRealestateproisgone.AfterLoadDFMValues;
    dsRealestateprogoneon.AfterLoadDFMValues;
    dsRealestateprobuyerfk.AfterLoadDFMValues;
    dsRealestateproiscomplete.AfterLoadDFMValues;
    dsRealestateproremark.AfterLoadDFMValues;
    dsRealestateprofinancing.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
  end;
end;

end.
