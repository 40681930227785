unit Units.SessionTimeout;

{--------------------------------------------------
 stay local storage variable is used to share status of timeout notification modal
 0 - initial state of session timeout modal - displayed
 1 - user clicked stay logged in
 2 - user clicked logout

 based on those values modals in multitab can be managed
 ---------------------------------------------------}

interface

uses
  SysUtils,
  Classes,
  Web,
  pas2web.bootstrap,
  Units.PageHandler,
  DateUtils;

type
  TTimeoutHandler = class(TComponent)
  private
    FModal: TP2WBSModal;
    FDoResolveTimeout: TTimeoutResolver;
    FTimerTick: integer;
    class var _Instance: TTimeoutHandler;
    procedure ShowTimeOutRemaining;
  protected
    procedure DoTimeoutPending(Sender: TObject; resolveTimeout: TTimeoutResolver);
    procedure DoModalHide(Sender: TObject; CloseEl: TJSHTMLElement; Values: TStrings);
  public
    class constructor Create;
    class function Instance: TTimeoutHandler;
    constructor Create(aOwner: TComponent); override;
    destructor Destroy; override;
    property Modal: TP2WBSModal read FModal;
  end;

implementation

uses
  libjquery,
  Units.ServerConfig,
  lib.formtranslation,
  Modules.Server,
  WEBLib.Actions,
  Units.ActionUtils;

{ TTimeoutHandler }

constructor TTimeoutHandler.Create(aOwner: TComponent);
begin
  inherited;
  FModal := TP2WBSModal.Create(Self);
  FModal.TemplateName := TPL_EXPIRATION;
  FModal.ParentID := 'modals';
  FModal.ElementID := 'session-timeout-dialog';
  FModal.OnHide := @DoModalHide;
  FModal.Actions.AddAction('progress', 'expiration-progress');
  FModal.Actions.AddAction('countdown', 'expiration-countdown');
  // These will set the 'hide' element on close. We actually just need the cancel.
  FModal.Actions.AddAction('Cancel', 'session-timeout-dialog-keepalive', heclick);
  FModal.Actions.AddAction('OK', 'session-timeout-dialog-logout', heclick);
  PageHandler.OnTimeoutPending := @DoTimeoutPending;
end;

class constructor TTimeoutHandler.Create;
begin
  _Instance := TTimeoutHandler.Create(nil);
end;

destructor TTimeoutHandler.Destroy;
begin
  FreeAndNil(FModal);
  inherited;
end;

procedure TTimeoutHandler.DoModalHide(Sender: TObject; CloseEl: TJSHTMLElement; Values: TStrings);

var
  res: TTimeoutResolution;

begin
  window.ClearInterval(FTimerTick);
  res := torLogout;
  if Assigned(CloseEl) and (CloseEl.ID = 'session-timeout-dialog-keepalive') then
  begin
    res := torPing;
    Server.SetLocalData('stay', '1');
  end
  else
    Server.SetLocalData('stay', '2');
  if Server.GetLocalData('stay') = '1' then
  begin
    Server.SetLocalData('LastCall', InttoStr(DateTimeToUnix(now)));
    FDoResolveTimeout(torPing);
  end
  else
    FDoResolveTimeout(res);
end;

procedure TTimeoutHandler.ShowTimeOutRemaining;

var
  secs: integer;
  pct: integer;

begin
  if Server.GetLocalData('stay') <> '0' then
  begin
    Modal.Hide;
  end
  else
  begin
    secs := dmServer.SecondsTillTimeout;
    if secs < 0 then
      Modal.Hide;
    FModal.Actions['countdown'].Value := InttoStr(secs);
    with ServerConfig do
      pct := Round((secs) / (DoLogoutTimeout - DoWarnLogoutTimeout) * 100);
    JQuery('#expiration-countdown').css('width', InttoStr(pct) + '%');
  end;
end;

procedure TTimeoutHandler.DoTimeoutPending(Sender: TObject; resolveTimeout: TTimeoutResolver);
begin
  Server.SetLocalData('stay', '0');
  FDoResolveTimeout := resolveTimeout;
  if not FModal.IsRendered then
    FModal.Render
  else
    FModal.Show;
  // We must always translate, because the user and hence can change between renderings
  FormTranslator.TranslateBelow(FModal.Element, 'sessiontimeout');
  ShowTimeOutRemaining;
  FTimerTick := window.setInterval(ShowTimeOutRemaining, 1000);
  FModal.Show;
end;

class function TTimeoutHandler.Instance: TTimeoutHandler;
begin
  Result := _Instance;
end;

end.
