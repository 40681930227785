unit Forms.CSR;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery,
  Modules.Server, lib.bootstrap, Units.Params, types, Data.DB, WebRouter,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper, System.Generics.Collections;

type

  TCSRRoute = (crUnknown, crIdentity, crRelation, crRevenue, crRealestate, crPossession, crDebt,
    crExpense, crSalarytransfer, crLegalcase, crRepresentative, crArchivePetition,
    crSettlementPlan, crTrustAccount, crAlarm, crFiles);

  TfrmCSR = class(TfrmBase)
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
    FCurrentForm: TfrmBase;
    FParams: TStrings;
    // FSearchContactResultCallBack: TSearchContactResultCallBack;
    FRoutePattern: string;
    FPropertyType: TDictionary<String, Int64>;
    FAllowNavigation : Boolean;
    FDestintionRoute : string;
    Procedure HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute: Boolean); override;
    procedure setupMenu(const dossierId: string);
    procedure makeActive(const linkId: String);
    procedure createLinkForm(AInstanceClass: TFormClass; Params: TStrings; afterFormCreation: TNotifyEvent = nil);
    procedure saveForm(SaveType: TPetitionDetailSaveType; Params: THTMLFormParams; onErrorCallback: TOnFailCallBack);
    procedure showNotFoundError(const show: Boolean);
    procedure OnPetitionerFound(contactData: TContactSearchResult;
      ContactTelecomItems: TContactTelecomItems;
      ContactAddressItems: TContactAddressItems; isLast: Boolean);
    procedure OnPetitionerNotFound;
    procedure onGetPropTypeItem(RecordID: Integer; Name: String;
      Language: Integer; ParamType, Value: string; ValueType: Integer;
      Extra: string; isLast: Boolean);
    procedure ShowCSRDetail;
  public
    { Public declarations }
    class function MyRoutes: TStringDynArray; override;
    Procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSR: TfrmCSR;

implementation

uses
  Units.Strings,
  Forms.CSR.Identity,
  Forms.CSR.Family,
  Forms.CSR.Family.Child,
  Forms.CSR.Family.Inmate,
  Forms.CSR.Revenue,
  Forms.CSR.Revenue.Income,
  Forms.CSR.Revenue.Debtor,
  Forms.CSR.Realestate,
  Forms.CSR.Realestate.Prop,
  Forms.CSR.Possession,
  Forms.CSR.Possession.Prop,
  Forms.CSR.Debt,
  Forms.CSR.Debt.Details,
  Forms.CSR.Expenses,
  Forms.CSR.Expense.Detail,
  Forms.CSR.Salarytransfer,
  Forms.CSR.Salarytransfer.Detail,
  Forms.CSR.Legalcase,
  Forms.CSR.Legalcase.Detail,
  Forms.CSR.Representative,
  Forms.CSR.SettlementPlanList,
  Forms.CSR.SettlementPlan.New,
  Forms.CSR.BudgetPlan,
  Forms.CSR.SettlementPlan.Edit,
  Forms.CSR.SettlementPlan.Copy,
  Forms.CSR.TrustAccount,
  Forms.CSR.TrustAccount.Details,
  Forms.CSR.TrustAccount.PaymentOrder,
  Forms.CSR.TrustAccount.PaymentOrder.Detail,
  Forms.CSR.TrustAccount.Movements,
  Forms.CSR.TrustAccount.Movements.Detail,
  Forms.CSR.TrustAccount.Recurringpayment,
  Forms.CSR.TrustAccount.Recurringpayment.Detail,
  Forms.CSR.Alarms,
  Forms.CSR.Alarms.Detail,
  Forms.CSR.Files,
  Forms.CSR.Files.Detail,
  {
  Forms.ArchivePetition
  }
  Units.Logging,
  Units.ActionUtils,
  lib.formtranslation
  ;

const
  SFormContainer = 'linkContent';

  LinkNames: array [TCSRRoute] of string
    = ('', 'identity', 'family', 'income', 'realestate', 'property', 'debt',
    'costs-expenses', 'wage', 'legal', 'representative','archive-petition', 'settlementplan',
    'trustaccount', 'alarm', 'file');
  formRoutes: Array [TCSRRoute] of string
    = ('', 'identity', 'relation', 'revenue', 'realestate', 'possession', 'debt',
    'expense', 'salarytransfer', 'legalcase', 'representative','archivepetition',
    'settlementplan', 'trustaccount', 'alarm', 'file');

{$R *.dfm}

  { TfrmCSR }

procedure TfrmCSR.WebFormDestroy(Sender: TObject);
begin
  Log.log(ltDebug,ClassName,'WebFormDestroy','Destroying TDossierForm');
  FreeAndNil(FCurrentForm);
  FreeAndNil(FPropertyType);
  FreeAndNil(FParams);
  inherited;
end;

procedure TfrmCSR.WebFormCreate(Sender: TObject);
begin
  inherited;
  FAllowNavigation := True;
  FDestintionRoute := '';
  FParams := TStringList.Create;
  FPropertyType := TDictionary<String, Int64>.Create;
  Server.GetParameterList('PROPTYP', Language, @onGetPropTypeItem);
end;

procedure TfrmCSR.canHandleRoute(previousURL: string; var allow: Boolean);
begin
  inherited;
  if Assigned(FCurrentForm) then
  begin
    FDestintionRoute := previousURL;
    FCurrentForm.canHandleRoute(previousURL, allow);
    FAllowNavigation := allow;
  end;
end;

procedure TfrmCSR.createLinkForm(AInstanceClass: TFormClass;
  Params: TStrings; afterFormCreation: TNotifyEvent);
var
  lParams: TStrings;
begin
  if Assigned(Params) then
  begin
    lParams := TStringList.Create;
    lParams.AddStrings(Params);
  end;
  if Assigned(FCurrentForm) then
    FCurrentForm.Free;
  Application.CreateForm(AInstanceClass, SFormContainer, FCurrentForm,
    Procedure(Sender: TObject)
    begin
      try
        FCurrentForm.setParams(lParams);
        FCurrentForm.onSave := saveForm;
      finally
        if Assigned(lParams) then
          lParams.Free;
      end;
      FCurrentForm.ShowForm;
      if Assigned(afterFormCreation) then
        afterFormCreation(FCurrentForm);
    end);
end;


procedure TfrmCSR.makeActive(const linkId: String);
begin
  jQuery('a.active').removeClass('active');
  jQuery('#' + linkId).addClass('active');
end;

class function TfrmCSR.MyRoutes: TStringDynArray;
begin
  Result:=[
    'CSR/:DOSSIERID/identity',
    'CSR/:DOSSIERID/relation',
  // child tab
  'CSR/:DOSSIERID/relation/new/child',
    'CSR/:DOSSIERID/relation/edit/:RELATIONID/child',
    'CSR/:DOSSIERID/relation/delete/:RELATIONID/child',
  // adults tabs
  'CSR/:DOSSIERID/relation/new/house',
    'CSR/:DOSSIERID/relation/edit/:RELATIONID/house',
    'CSR/:DOSSIERID/relation/delete/:RELATIONID/house',
  // revenue (income)
  'CSR/:DOSSIERID/revenue',
    'CSR/:DOSSIERID/revenue/new/income',
    'CSR/:DOSSIERID/revenue/edit/:REVENUEID/income',
    'CSR/:DOSSIERID/revenue/delete/:REVENUEID/income',
    'CSR/:DOSSIERID/revenue/new/debtor',
    'CSR/:DOSSIERID/revenue/edit/:REVENUEID/debtor',
    'CSR/:DOSSIERID/revenue/delete/:REVENUEID/debtor',
  // realestate
  'CSR/:DOSSIERID/realestate',
    'CSR/:DOSSIERID/realestate/new',
    'CSR/:DOSSIERID/realestate/edit/:POSSESSIONID',
    'CSR/:DOSSIERID/realestate/delete/:POSSESSIONID',
  // possession (movable-property)
  'CSR/:DOSSIERID/possession',
    'CSR/:DOSSIERID/possession/new',
    'CSR/:DOSSIERID/possession/edit/:POSSESSIONID',
    'CSR/:DOSSIERID/possession/delete/:POSSESSIONID',

  // depts
  'CSR/:DOSSIERID/debt',
    'CSR/:DOSSIERID/debt/new',
    'CSR/:DOSSIERID/debt/edit/:DEBTID',
    'CSR/:DOSSIERID/debt/delete/:DEBTID',
  // expenses
  'CSR/:DOSSIERID/expense',
    'CSR/:DOSSIERID/expense/new',
    'CSR/:DOSSIERID/expense/edit/:EXPENSEID',
    'CSR/:DOSSIERID/expense/delete/:EXPENSEID',
  // salarytransfer
  'CSR/:DOSSIERID/salarytransfer',
    'CSR/:DOSSIERID/salarytransfer/new',
    'CSR/:DOSSIERID/salarytransfer/edit/:TRANSFERID',
    'CSR/:DOSSIERID/salarytransfer/delete/:TRANSFERID',
  // legalcase
  'CSR/:DOSSIERID/legalcase',
    'CSR/:DOSSIERID/legalcase/new',
    'CSR/:DOSSIERID/legalcase/edit/:LEGALCASEID',
    'CSR/:DOSSIERID/legalcase/delete/:LEGALCASEID',
  // Representative
  'CSR/:DOSSIERID/representative',
  // archive petition
  'CSR/:DOSSIERID/archivepetition',
  //settlement plan
  'CSR/:DOSSIERID/settlementplan',
    'CSR/:DOSSIERID/settlementplan/new',
    'CSR/:DOSSIERID/settlementplan/edit/:sepID',
    'CSR/:DOSSIERID/settlementplan/delete/:sepID',
    'CSR/:DOSSIERID/settlementplan/copy/:sepID',

  //budget plan
  '/CSR/:DOSSIERID/settlementplan/:SETTLEMENTPLANID/budgetplan/:BUDGETPLANID',
  //'/CSR/:DOSSIERID/budgetplan/:BUDGETPLANID'

  //trust accounts
  '/CSR/:DOSSIERID/trustaccount',
    '/CSR/:DOSSIERID/trustaccount/new',
    '/CSR/:DOSSIERID/trustaccount/edit/:ID',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/paymentorder',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/paymentorder/new',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/paymentorder/edit/:ID',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/paymentorder/delete/:ID',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/movement',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/movement/:ID',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/recurringpayment',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/recurringpayment/new',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/recurringpayment/edit/:ID',
    '/CSR/:DOSSIERID/trustaccount/:ACCOUNTID/recurringpayment/delete/:ID',
   //Alarms
   '/CSR/:DOSSIERID/alarm',
    '/CSR/:DOSSIERID/alarm/edit/:ID',
    '/CSR/:DOSSIERID/alarm/delete/:ID',
    '/CSR/:DOSSIERID/alarm/new',
   //files
   '/CSR/:DOSSIERID/file',
   '/CSR/:DOSSIERID/file/new',
   '/CSR/:DOSSIERID/file/edit/:FILEID',
   '/CSR/:DOSSIERID/file/delete/:FILEID'
  ];
end;

Function GetCSRRoute(S: String): TCSRRoute;

begin
  Result:=High(TCSRRoute);
  while (Result<>crUnknown) and Not SameText(S, formRoutes[Result]) do
    Result:=Pred(Result);
end;


procedure TfrmCSR.onGetPropTypeItem(RecordID: Integer; Name: String;
Language: Integer; ParamType, Value: string; ValueType: Integer;
Extra: string; isLast: Boolean);
begin
  if not FPropertyType.ContainsKey(Name) then
    FPropertyType.Add(Name, RecordID);
end;

procedure TfrmCSR.OnPetitionerFound(contactData: TContactSearchResult;
ContactTelecomItems: TContactTelecomItems;
ContactAddressItems: TContactAddressItems; isLast: Boolean);
begin
  if contactData.RecordID > 0 then
  begin
    showNotFoundError(False);

    alForm['debtorName'].Value := contactData.Lastname + ' ' + contactData.FirstName;

    // if Assigned(FCurrentForm) then
    // FCurrentForm.Free;

    setupMenu(FParams.Values['DOSSIERID']);
    ShowCSRDetail;
  end
  else
    showNotFoundError(True);
end;

Procedure TfrmCSR.ShowCSRDetail;

var
  bDeleteOnTabOne: Boolean;
  aRoute: TCSRRoute;

  procedure AfterCreation(Sender: TObject);

  begin
    Case aRoute of
      crRevenue:
        if bDeleteOnTabOne then
          (Sender as TfrmCSRRevenue).DoDeleteIncome(StrToIntDef(FParams.Values['REVENUEID'],-1))
        else
          (Sender as TfrmCSRRevenue).DoDeleteDebtor(StrToIntDef(FParams.Values['REVENUEID'],-1));
      crRelation:
        if bDeleteOnTabOne then
          (Sender as TfrmCSRFamily).DoDeleteChild(StrToIntDef(FParams.Values['RELATIONID'],-1))
        else
          (Sender as TfrmCSRFamily).DoDeleteInmate(StrToIntDef(FParams.Values['RELATIONID'],-1));
      crRealestate:
        (Sender as TfrmCSRRealestate).DoDelete(StrToIntDef(FParams.Values['POSSESSIONID'],-1));
      crPossession:
        (Sender as TfrmCSRPossession).DoDelete(StrToIntDef(FParams.Values['POSSESSIONID'],-1));
      crDebt:
        (Sender as TfrmCSRDebt).DoDelete(StrToIntDef(FParams.Values['DEBTID'],-1));
      crExpense:
        (Sender as TfrmCSRExpenses).DoDelete(StrToIntDef(FParams.Values['EXPENSEID'],-1));
      crSalarytransfer:
        (Sender as TfrmCSRSalarytransfer).DoDelete(StrToIntDef(FParams.Values['TRANSFERID'],-1));
      crLegalcase:
        (Sender as TfrmCSRLegalcase).DoDelete(StrToIntDef(FParams.Values['LEGALCASEID'],-1));
      crSettlementPlan:
        (Sender as TfrmSettlementPlanList).DoDelete(StrToIntDef(FParams.Values['sepID'],-1));
      crTrustAccount:
        begin
          if (Sender is TfrmCSRPaymentOrder) then
            (Sender as TfrmCSRPaymentOrder).DoDelete(StrToIntDef(FParams.Values['ID'],-1));
          if (Sender is TfrmRecurringPayment) then
            (Sender as TfrmRecurringPayment).DoDelete(StrToIntDef(FParams.Values['ID'],-1));
        end;
      crAlarm: (Sender as TfrmAlarms).DoDelete(StrToIntDef(FParams.Values['ID'],-1));
      crFiles: (Sender as TfrmCSRFiles).DoDelete(StrToIntDef(FParams.Values['FILEID'],-1));
    End;

  end;


Var
  S: Array of string;
  aSubClass, formName: string;
  aOperation: TCrudOperation;
  aformClass: TBaseFormClass;
  doAfter: Boolean;

  Procedure DoCreateForm;
  begin
    if doAfter then
      createLinkForm(aformClass, FParams, @AfterCreation)
    else
      createLinkForm(aformClass, FParams)
  end;


begin
  if (Length(FRoutePattern) > 0) AND (FRoutePattern[Length(FRoutePattern)] = '/') then
    FRoutePattern := Copy(FRoutePattern, 1, Length(FRoutePattern) - 1);
  S:=FRoutePattern.Split('/');
  // Check first 2
  If (Length(S)<3) or not (SameText(S[0], 'CSR') and SameText(S[1], ':DOSSIERID')) then
  begin
    FreeAndNil(FCurrentForm);
    exit;
  end;
  formName:=LowerCase(S[2]);
  // Check operation, if any.
  if Length(S)>3 then
    aOperation:=GetCrudUperation(S[3])
  else
    aOperation:=coRead;
  aformClass:=Nil;
  if Length(S)>4 then
    aSubClass:=S[Length(S)-1]
  else
    aSubClass:='';
  doAfter:=False;

  if(Length(S) > 4) and ((LowerCase(S[2]) = 'settlementplan') or (LowerCase(S[2]) = 'trustaccount')) then
  begin
    aSubClass := LowerCase(S[4]);
    if Length(S)> 5 then
        aOperation:=GetCrudUperation(S[5]);
  end;

  // Check form.
  aRoute:=GeTCSRRoute(formName);
  Case aRoute of
    crIdentity:
      begin
        aformClass:=TfrmCSRIdentity;
      end;
    crRelation:
      begin
        aformClass:=TfrmCSRFamily;
        if aOperation=coDelete then
        begin
          doAfter:=True;
        end;
        If (aSubClass='child') then
        begin
          if aOperation<>coDelete then
            aformClass:=TfrmCSRFamilyChild
          else
            bDeleteOnTabOne := True;
        end
        else If (aSubClass='house') then
        begin
          if aOperation<>coDelete then
            aformClass:=TfrmCSRFamilyInmate
          else
            bDeleteOnTabOne := False;
        end;
      end;
    crRevenue:
      begin
        aformClass:=TfrmCSRRevenue;
        if aOperation=coDelete then
        begin
          doAfter:=True;
        end;
        if aSubClass='income' then
        begin
          if aOperation<>coDelete then
            aformClass:=TfrmCSRRevenueIncome
          else
            bDeleteOnTabOne := True;
        end
        else if aSubClass='debtor' then
        begin
          if aOperation<>coDelete then
            aformClass:=TfrmCSRRevenueDebtor
          else
            bDeleteOnTabOne := False;
        end;
      end;
    crRealestate:
      begin
        aformClass:=TfrmCSRRealestate;
        doAfter:=aOperation=coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass:=TfrmCSRRealestateProperty;
      end;
    crPossession:
      begin
        aformClass:=TfrmCSRPossession;
        doAfter:=aOperation=coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass:=TfrmCSRPossessionProperty;
      end;
    crDebt:
      begin
        aformClass:=TfrmCSRDebt;
        doAfter:=aOperation=coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass:=TfrmCSRDebtDetails;
      end;
    crExpense:
      begin
        aformClass:=TfrmCSRExpenses;
        doAfter:=aOperation=coDelete;
        if (aOperation in [coNew, coEdit]) then
          aformClass:=TfrmCSRExpenseDetail;
      end;
    crSalarytransfer:
      begin
        aformClass:=TfrmCSRSalarytransfer;
        doAfter:=aOperation=coDelete;
        if aOperation in [coNew, coEdit] then
          aformClass:=TfrmCSRSalarytransferDetail;
      end;
    crLegalcase:
      begin
        aformClass:=TfrmCSRLegalcase;
        doAfter:=aOperation=coDelete;
        if aOperation in [coNew, coEdit] then
          aformClass:=TfrmCSRLegalcaseDetail;
      end;
    crRepresentative:
      begin
        aformClass:=TfrmCSRRepresentative;
      end;
    crSettlementPlan:
      begin
        if aSubClass <> 'budgetplan' then
        begin
          doAfter:=aOperation=coDelete;
          case aOperation of
            coNew: aformClass := TfrmSetlementPlanNew;
            coEdit: aformClass := TfrmSetlementPlanEdit;
            coCopy: aformClass := TfrmSettlementPlanCopy;
            else aformClass:=TfrmSettlementPlanList;
          end;
        end
        else
        begin
          aformClass:=TfrmBudgetPlan;
          doAfter := False;
        end;
      end;
    crTrustAccount:
      begin
        if aSubClass = 'paymentorder' then
        begin
          aformClass := TfrmCSRPaymentOrder;
          doAfter := aOperation=coDelete;
          if aOperation in [coNew, coEdit] then
            aformClass:=TfrmCSRPaymentOrderDetail;
        end
        else if aSubClass = 'movement' then
        begin
          aformClass := TfrmTrustAccountMovements;
          doAfter := False;
          if Length(S)> 5 then
            aformClass:=TfrmTrustAccountMovementDetails;
        end
        else if aSubClass = 'recurringpayment' then
        begin
          aformClass := TfrmRecurringPayment;
          doAfter := aOperation=coDelete;
          if aOperation in [coNew, coEdit] then
            aformClass:=TfrmRecurringPaymentDetail;
        end
        else
        begin
          aformClass:=TfrmTrustAccount;
          doAfter:=aOperation=coDelete;
          if aOperation in [coNew, coEdit] then
            aformClass:=TfrmTrustAccountDetail;
        end;
      end;
    crAlarm:
      begin
        aformClass:=TfrmAlarms;
        doAfter:=aOperation=coDelete;
        if aOperation in [coNew, coEdit] then
            aformClass:= TfrmAlarmDetail;
      end;
    crFiles:
      begin
        aformClass:=TfrmCSRFiles;
        doAfter:=aOperation=coDelete;
        if aOperation in [coNew, coEdit] then
            aformClass:=TfrmCSRFileDetails;
      end;
{
    crArchivePetition:
      begin
        aformClass:=TfrmArchivePetition;
      end;
}

  end;

  if LinkNames[aRoute]<>'' then
    makeActive(LinkNames[aRoute]);
  if aformClass<>Nil then
    if aFormClass.NeedsParams then
      dmServer.DoOnParamsLoaded(@DoCreateForm)
    else
      DoCreateForm;
end;

procedure TfrmCSR.OnPetitionerNotFound;
begin
  showNotFoundError(True);
end;


Procedure TfrmCSR.HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute: Boolean);

  procedure OnConfirm;
  begin
    if Assigned(FCurrentForm) then
    begin
      FCurrentForm.DiscardChanges := True;
      FCurrentForm.DiscardFiles := True;
    end;
    window.location.href := ('#/' + FDestintionRoute).Replace('//', '/');
  end;

begin
  if not FAllowNavigation then
  begin
    DefaultConfirmation.execute(SDiscardChangesMessage, @OnConfirm, nil);
    Exit;
  end;

  Inherited;
  Log.Log(ltinfo,ClassName,'HandleRoute','Handling URL: '+Url);
  FRoutePattern := aRoute.URLPattern;
  // check if the parameter is correct
  FParams.Clear;

  if Assigned(Params) then
  begin
    FParams.AddStrings(Params);
    Server.GetPetitionContact(StrToInt(FParams.Values['DOSSIERID']), 'SCH', False,
      @OnPetitionerFound, @OnPetitionerNotFound);
  end;

  if IsReroute then // if the form is already shown, we just need to call the new subform
    ShowCSRDetail;

  //reset storage values if pettitioner changed
  if Server.GetLocalData('petitioner') <> Params.Values['DOSSIERID'] then
  begin
    Server.SetLocalData('petitioner', Params.Values['DOSSIERID']);
    Server.SetLocalData('Family', '1');
    Server.SetLocalData('Revenue', '1');
    Server.SetLocalData('Identity', '1');
  end;
end;

procedure TfrmCSR.saveForm(SaveType: TPetitionDetailSaveType;
Params: THTMLFormParams; onErrorCallback: TOnFailCallBack);
begin
  alForm['petitionerName'].Value := (Params as TPersonIdentityParams).Lastname +
    ' ' + (Params as TPersonIdentityParams).FirstName;
end;

procedure TfrmCSR.setupMenu(const dossierId: string);

  Procedure SetURL(aTag,aPage : String);

  begin
    jQuery('#'+aTag).attr('href', '#/CSR/'+dossierId+'/'+aPage);
  end;

Var
  R : TCSRRoute;

begin
  For R:=Succ(Low(TCSRRoute)) to High(TCSRRoute) do
    SetURL(Linknames[R],FormRoutes[R]);
end;


procedure TfrmCSR.showNotFoundError(const show: Boolean);
begin
  setElementVisible('settlementError', show);
  setElementVisible('header', not show);
  setElementVisible('settlementData', not show);
end;


procedure TfrmCSR.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'creditor';
      Name := 'creditor';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'DebtorName';
      Name := 'DebtorName';
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
