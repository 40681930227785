unit Forms.CompanyProfile;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  pas2web.dadatasethelper, WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Modules.Server, pas2web.dataelementmapper, Data.DB, Datasnap.DBClient,
  pas2web.dadataset;

type
  TfrmCompanyProfile = class(TfrmBase)
    dsCompany: TP2WDADataset;
    dsCompanycomid: TLargeintField;
    dsCompanycomcreatedon: TDateTimeField;
    dsCompanycomcreatedbyfk: TLargeintField;
    dsCompanycomchangedon: TDateTimeField;
    dsCompanycomchangedbyfk: TLargeintField;
    dsCompanycomname: TWideStringField;
    dsCompanycomstreet: TWideStringField;
    dsCompanycomhousenr: TWideStringField;
    dsCompanycomhousenrextra: TWideStringField;
    dsCompanycomzip: TWideStringField;
    dsCompanycomcity: TWideStringField;
    dsCompanycomcityfk: TLargeintField;
    dsCompanycomcountry: TWideStringField;
    dsCompanycomcountryfk: TLargeintField;
    dsCompanycomemail: TWideStringField;
    dsCompanycombankaccount: TWideStringField;
    dsCompanycomvat: TWideStringField;
    dsCompanycomtelephone: TWideStringField;
    dsCompanycomlanguagefk: TLargeintField;
    eacCompany: TPW2DataElementMapper;
    procedure DoResetErrors(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoSave(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure dsCompanyAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject); override;
    procedure DoIsEmailValid(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure DoEmailInvalid(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
    procedure DoIsVATValid(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure DoIsZipValid(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure DoZipInvalid(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
    procedure DoVatInvalid(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
    procedure dsCompanyAfterOpen(DataSet: TDataSet);
  private
    procedure DoSaveCompanyProfile;
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmCompanyProfile: TfrmCompanyProfile;

implementation

uses Units.ActionUtils, Units.DaDatasetUtils, Units.Strings;

{$R *.dfm}

procedure TfrmCompanyProfile.DoSave(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

  procedure IBANCheckResult(aSuccess: Boolean; anError: String);
  begin
    if aSuccess then
      DoSaveCompanyProfile
    else
      DisplayError('edtIBAN', 'incorrect-value');
  end;

  procedure VATNumberCheckResult(aSuccess: Boolean; anError: String);
  begin
    if aSuccess then
      Server.isIBANValid(Trim(alForm['edtIBAN'].value), @IBANCheckResult)
    else
      DisplayError('edtVAT', 'incorrect-value');
  end;

  procedure phoneCheckResult(aSuccess: Boolean; anError: String);
  begin
    if aSuccess then
      Server.isVATNumberValid(Trim(alForm['edtVAT'].value), @VATNumberCheckResult)
    else
      DisplayError('edtPhone', 'incorrect-value');
  end;

var
  aResult : Boolean;

begin
  ClearFormErrors;
  aResult := eacCompany.ValidateActions(True);
  if aResult then
    Server.isPhoneNumberValid(Trim(alForm['edtPhone'].value), @phoneCheckResult);
end;

procedure TfrmCompanyProfile.dsCompanyAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

Var
  aMsg : String;

begin
  aMsg := Info.AsString;
  if aMsg = ''  then
    ShowSuccess(SDataSavedOK)
  else
    ShowError(aMsg);
end;

procedure TfrmCompanyProfile.dsCompanyAfterOpen(DataSet: TDataSet);
begin
  inherited;
  if dsCompany.IsEmpty then
    SetElementEnabled('btnSave',False)
  else
    eacCompany.DatasetToElements;
end;

procedure TfrmCompanyProfile.DoIsEmailValid(Sender: TObject; Source: TElementAction; var aResult: Boolean);

Var
  Tel : String;

begin
  Tel:=Trim(Source.Value);
  if (Tel<>'') then
    aResult:=emailIsValid(Tel);
end;

procedure TfrmCompanyProfile.DoIsVATValid(Sender: TObject; Source: TElementAction; var aResult: Boolean);

Var
  VAT : String;
begin
  inherited;
  VAT:=Trim(Source.Value);
  if StringIsNumeric(VAT) AND (Length(VAT) = 9) then
    begin
    Vat:='0'+Vat;
    Source.value := '0' + VAT;
    end;
  aResult:=(Length(VAT)=10) and StringIsNumeric(VAT);
end;

procedure TfrmCompanyProfile.DoIsZipValid(Sender: TObject; Source: TElementAction; var aResult: Boolean);
begin
  aResult:=zipCodeIsValid(Trim(Source.value));
end;

procedure TfrmCompanyProfile.DoEmailInvalid(Sender: TObject; Source: TElementAction; dest: TField;
  aError: TElementError);
begin
  DisplayError(Source.ID, 'incorrect-value');
end;

procedure TfrmCompanyProfile.WebFormCreate(Sender: TObject);
begin
  inherited;
  bindZipLookup('edtZip', 'edtCity');
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsCompany.DAConnection := Server.CompanyConnection;
end;

procedure TfrmCompanyProfile.WebFormShow(Sender: TObject);
begin
  inherited;
  dsCompany.Load([],Nil);
end;

procedure TfrmCompanyProfile.DoSaveCompanyProfile;

begin
  dsCompany.Edit;
  dsCompanycomChangedOn.AsDateTime:=Now;
  dsCompanycomChangedbyFk.AsLargeInt:=dmServer.UserId;
  eacCompany.ElementsToDataset;
  dsCompany.Post;
  dsCompany.applyUpdates;
end;

procedure TfrmCompanyProfile.DoVatInvalid(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.ID, 'incorrect-value');
end;

procedure TfrmCompanyProfile.DoZipInvalid(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.ID, 'incorrect-value');
end;

procedure TfrmCompanyProfile.DoResetErrors(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  ClearFormErrors;
end;

procedure TfrmCompanyProfile.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsCompany := TP2WDADataset.Create(Self);
  dsCompanycomid := TLargeintField.Create(Self);
  dsCompanycomcreatedon := TDateTimeField.Create(Self);
  dsCompanycomcreatedbyfk := TLargeintField.Create(Self);
  dsCompanycomchangedon := TDateTimeField.Create(Self);
  dsCompanycomchangedbyfk := TLargeintField.Create(Self);
  dsCompanycomname := TWideStringField.Create(Self);
  dsCompanycomstreet := TWideStringField.Create(Self);
  dsCompanycomhousenr := TWideStringField.Create(Self);
  dsCompanycomhousenrextra := TWideStringField.Create(Self);
  dsCompanycomzip := TWideStringField.Create(Self);
  dsCompanycomcity := TWideStringField.Create(Self);
  dsCompanycomcityfk := TLargeintField.Create(Self);
  dsCompanycomcountry := TWideStringField.Create(Self);
  dsCompanycomcountryfk := TLargeintField.Create(Self);
  dsCompanycomemail := TWideStringField.Create(Self);
  dsCompanycombankaccount := TWideStringField.Create(Self);
  dsCompanycomvat := TWideStringField.Create(Self);
  dsCompanycomtelephone := TWideStringField.Create(Self);
  dsCompanycomlanguagefk := TLargeintField.Create(Self);
  eacCompany := TPW2DataElementMapper.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsCompany.BeforeLoadDFMValues;
  dsCompanycomid.BeforeLoadDFMValues;
  dsCompanycomcreatedon.BeforeLoadDFMValues;
  dsCompanycomcreatedbyfk.BeforeLoadDFMValues;
  dsCompanycomchangedon.BeforeLoadDFMValues;
  dsCompanycomchangedbyfk.BeforeLoadDFMValues;
  dsCompanycomname.BeforeLoadDFMValues;
  dsCompanycomstreet.BeforeLoadDFMValues;
  dsCompanycomhousenr.BeforeLoadDFMValues;
  dsCompanycomhousenrextra.BeforeLoadDFMValues;
  dsCompanycomzip.BeforeLoadDFMValues;
  dsCompanycomcity.BeforeLoadDFMValues;
  dsCompanycomcityfk.BeforeLoadDFMValues;
  dsCompanycomcountry.BeforeLoadDFMValues;
  dsCompanycomcountryfk.BeforeLoadDFMValues;
  dsCompanycomemail.BeforeLoadDFMValues;
  dsCompanycombankaccount.BeforeLoadDFMValues;
  dsCompanycomvat.BeforeLoadDFMValues;
  dsCompanycomtelephone.BeforeLoadDFMValues;
  dsCompanycomlanguagefk.BeforeLoadDFMValues;
  eacCompany.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'DoSave');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtName';
      Name := 'edtName';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtStreet';
      Name := 'edtStreet';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtHouseNo';
      Name := 'edtHouseNo';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtHouseNoExtra';
      Name := 'edtHouseNoExtra';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtZip';
      Name := 'edtZip';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtCity';
      Name := 'edtCity';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtIBAN';
      Name := 'edtIBAN';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtVAT';
      Name := 'edtVAT';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtEmail';
      Name := 'edtEmail';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtPhone';
      Name := 'edtPhone';
      SetEvent(Self, 'OnExecute', 'DoResetErrors');
    end;
    dsCompany.SetParentComponent(Self);
    dsCompany.Name := 'dsCompany';
    dsCompany.TableName := 'CompanyProfile';
    dsCompany.Params.Clear;
    with dsCompany.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
      Value := Null;
    end;
    dsCompany.DAOptions := [];
    dsCompany.AfterOpen := dsCompanyAfterOpen;
    dsCompany.AfterApplyUpdates := dsCompanyAfterApplyUpdates;
    dsCompany.Left := 40;
    dsCompany.Top := 96;
    dsCompanycomid.SetParentComponent(dsCompany);
    dsCompanycomid.Name := 'dsCompanycomid';
    dsCompanycomid.FieldName := 'comid';
    dsCompanycomcreatedon.SetParentComponent(dsCompany);
    dsCompanycomcreatedon.Name := 'dsCompanycomcreatedon';
    dsCompanycomcreatedon.FieldName := 'comcreatedon';
    dsCompanycomcreatedbyfk.SetParentComponent(dsCompany);
    dsCompanycomcreatedbyfk.Name := 'dsCompanycomcreatedbyfk';
    dsCompanycomcreatedbyfk.FieldName := 'comcreatedbyfk';
    dsCompanycomchangedon.SetParentComponent(dsCompany);
    dsCompanycomchangedon.Name := 'dsCompanycomchangedon';
    dsCompanycomchangedon.FieldName := 'comchangedon';
    dsCompanycomchangedbyfk.SetParentComponent(dsCompany);
    dsCompanycomchangedbyfk.Name := 'dsCompanycomchangedbyfk';
    dsCompanycomchangedbyfk.FieldName := 'comchangedbyfk';
    dsCompanycomname.SetParentComponent(dsCompany);
    dsCompanycomname.Name := 'dsCompanycomname';
    dsCompanycomname.FieldName := 'comname';
    dsCompanycomname.Size := 100;
    dsCompanycomstreet.SetParentComponent(dsCompany);
    dsCompanycomstreet.Name := 'dsCompanycomstreet';
    dsCompanycomstreet.FieldName := 'comstreet';
    dsCompanycomstreet.Size := 100;
    dsCompanycomhousenr.SetParentComponent(dsCompany);
    dsCompanycomhousenr.Name := 'dsCompanycomhousenr';
    dsCompanycomhousenr.FieldName := 'comhousenr';
    dsCompanycomhousenr.Size := 10;
    dsCompanycomhousenrextra.SetParentComponent(dsCompany);
    dsCompanycomhousenrextra.Name := 'dsCompanycomhousenrextra';
    dsCompanycomhousenrextra.FieldName := 'comhousenrextra';
    dsCompanycomhousenrextra.Size := 10;
    dsCompanycomzip.SetParentComponent(dsCompany);
    dsCompanycomzip.Name := 'dsCompanycomzip';
    dsCompanycomzip.FieldName := 'comzip';
    dsCompanycomzip.Size := 15;
    dsCompanycomcity.SetParentComponent(dsCompany);
    dsCompanycomcity.Name := 'dsCompanycomcity';
    dsCompanycomcity.FieldName := 'comcity';
    dsCompanycomcity.Size := 100;
    dsCompanycomcityfk.SetParentComponent(dsCompany);
    dsCompanycomcityfk.Name := 'dsCompanycomcityfk';
    dsCompanycomcityfk.FieldName := 'comcityfk';
    dsCompanycomcountry.SetParentComponent(dsCompany);
    dsCompanycomcountry.Name := 'dsCompanycomcountry';
    dsCompanycomcountry.FieldName := 'comcountry';
    dsCompanycomcountry.Size := 2;
    dsCompanycomcountryfk.SetParentComponent(dsCompany);
    dsCompanycomcountryfk.Name := 'dsCompanycomcountryfk';
    dsCompanycomcountryfk.FieldName := 'comcountryfk';
    dsCompanycomemail.SetParentComponent(dsCompany);
    dsCompanycomemail.Name := 'dsCompanycomemail';
    dsCompanycomemail.FieldName := 'comemail';
    dsCompanycomemail.Size := 127;
    dsCompanycombankaccount.SetParentComponent(dsCompany);
    dsCompanycombankaccount.Name := 'dsCompanycombankaccount';
    dsCompanycombankaccount.FieldName := 'combankaccount';
    dsCompanycombankaccount.Size := 34;
    dsCompanycomvat.SetParentComponent(dsCompany);
    dsCompanycomvat.Name := 'dsCompanycomvat';
    dsCompanycomvat.FieldName := 'comvat';
    dsCompanycomtelephone.SetParentComponent(dsCompany);
    dsCompanycomtelephone.Name := 'dsCompanycomtelephone';
    dsCompanycomtelephone.FieldName := 'comtelephone';
    dsCompanycomlanguagefk.SetParentComponent(dsCompany);
    dsCompanycomlanguagefk.Name := 'dsCompanycomlanguagefk';
    dsCompanycomlanguagefk.FieldName := 'comlanguagefk';
    eacCompany.SetParentComponent(Self);
    eacCompany.Name := 'eacCompany';
    eacCompany.ActionLinks.Clear;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtName';
      FieldName := 'comname';
      Options := [aoRequired];
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtStreet';
      FieldName := 'comstreet';
      Options := [aoRequired];
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtHouseNo';
      FieldName := 'comhousenr';
      Options := [aoRequired];
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtHouseNoExtra';
      FieldName := 'comhousenrextra';
      Options := [];
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtZip';
      FieldName := 'comzip';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'DoZipInvalid');
      SetEvent(Self, 'OnValidation', 'DoIsZipValid');
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtCity';
      FieldName := 'comcity';
      Options := [aoRequired];
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtIBAN';
      FieldName := 'combankaccount';
      Options := [aoRequired];
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtVAT';
      FieldName := 'comvat';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'DoVatInvalid');
      SetEvent(Self, 'OnValidation', 'DoIsVATValid');
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtEmail';
      FieldName := 'comemail';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'DoEmailInvalid');
      SetEvent(Self, 'OnValidation', 'DoIsEmailValid');
    end;
    with eacCompany.ActionLinks.Add do
    begin
      ActionName := 'edtPhone';
      FieldName := 'comtelephone';
      Options := [aoRequired];
    end;
    eacCompany.ActionList := alForm;
    eacCompany.Dataset := dsCompany;
    eacCompany.Left := 120;
    eacCompany.Top := 96;
  finally
    alForm.AfterLoadDFMValues;
    dsCompany.AfterLoadDFMValues;
    dsCompanycomid.AfterLoadDFMValues;
    dsCompanycomcreatedon.AfterLoadDFMValues;
    dsCompanycomcreatedbyfk.AfterLoadDFMValues;
    dsCompanycomchangedon.AfterLoadDFMValues;
    dsCompanycomchangedbyfk.AfterLoadDFMValues;
    dsCompanycomname.AfterLoadDFMValues;
    dsCompanycomstreet.AfterLoadDFMValues;
    dsCompanycomhousenr.AfterLoadDFMValues;
    dsCompanycomhousenrextra.AfterLoadDFMValues;
    dsCompanycomzip.AfterLoadDFMValues;
    dsCompanycomcity.AfterLoadDFMValues;
    dsCompanycomcityfk.AfterLoadDFMValues;
    dsCompanycomcountry.AfterLoadDFMValues;
    dsCompanycomcountryfk.AfterLoadDFMValues;
    dsCompanycomemail.AfterLoadDFMValues;
    dsCompanycombankaccount.AfterLoadDFMValues;
    dsCompanycomvat.AfterLoadDFMValues;
    dsCompanycomtelephone.AfterLoadDFMValues;
    dsCompanycomlanguagefk.AfterLoadDFMValues;
    eacCompany.AfterLoadDFMValues;
  end;
end;

end.
