unit Forms.SubmitPetition;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery,
  lib.bootstrap, Units.Types, pas2web.datatables, Data.DB, pas2web.dadatasethelper,
  Datasnap.DBClient, pas2web.dadataset, pas2web.bootstrap, WEBLib.ExtCtrls, Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmSubmitPetition = class(TfrmBase)
    dsDocuments: TP2WDADataset;
    dcDocuments: TP2WDatatable;
    dsDocumentsdofid: TLargeintField;
    dsDocumentsdofcreatedon: TDateTimeField;
    dsDocumentsdofcreatedbyfk: TLargeintField;
    dsDocumentsdofchangedon: TDateTimeField;
    dsDocumentsdofchangedbyfk: TLargeintField;
    dsDocumentsdofdossierfk: TLargeintField;
    dsDocumentsdofsourcefk: TLargeintField;
    dsDocumentsdofsourcetype: TSmallintField;
    dsDocumentsdofdoctypefk: TLargeintField;
    dsDocumentsdofdoctype: TWideStringField;
    dsDocumentsdoffilename: TWideStringField;
    dsDocumentsdofmime: TWideStringField;
    dsDocumentsdofdata: TBlobField;
    dsDocumentsdofisuploadcomplete: TBooleanField;
    dsDocumentsdofuploaduserfk: TLargeintField;
    dsDocumentsdofcasefilenumber: TIntegerField;
    tmrCheck: TTimer;

    procedure DoDownloadPetition(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure DoRegeneratePetition(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure WebFormCreate(Sender: TObject);  reintroduce;
    procedure dsDocumentsAfterOpen(DataSet: TDataSet);
    procedure DoDownloadClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure tmrCheckTimer(Sender: TObject);
    procedure GetSourceTypeValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure GetDocumenType(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
  private
    { Private declarations }
    FCheckErrorCount : integer;
    FInCheckCall : Boolean;
    FSubmitJobID : NativeInt;
    fLocateDossier : Boolean;
    FDossierFileID : NativeInt;
    FDossierFileName : String;
    procedure LoadFiles;
    procedure StartStatusCheck;
    Procedure DoLocateDossier;
    procedure downloadFile(aURL, aFileName: string);
  public
    { Public declarations }
    procedure MakeReadOnly; override;
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmSubmitPetition: TfrmSubmitPetition;

implementation

{$R *.dfm}

uses
  rosdk, pas2web.da, Units.ActionUtils, Units.HTMLUtils, Modules.Server,Units.DADatasetUtils, units.strings ;


procedure TfrmSubmitPetition.downloadFile(aURL : string; aFileName : string);

var
  link: TJSHTMLElement;

begin
  link:=TJSHTMLElement(document.createElement('a'));
  link['href']:=aURL;
  link['download']:=aFileName;
  link['target']:='_blank';
  link.click();
end;

procedure TfrmSubmitPetition.GetDocumenType(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('DOCTYP', string(Fields.GetValueByName('dofdoctype')));
end;

procedure TfrmSubmitPetition.DoDownloadClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

Var
  aFileName : string;

    Procedure DoSuccess(aURL: string);

    begin
      DownloadFile(aURL,aFileName);
    end;

    Procedure DoFail(response: TJSOBject; fail: TJSError);

    begin
      dmServer.ShowError(ExtractErrorMsg(Fail,SErrGetFileURL));
    end;


Var
  dID : NativeInt;

begin
  dID:=StrToInt64Def(String(TJSHTMLElement(Event.JSEvent.CurrentTarget).Dataset['fileid']),-1);
  if dID<=0 then exit;
  if dsDocuments.Locate('dofID',did,[]) then
    aFileName:=dsDocumentsdoffilename.AsString
  else
    aFileName:='';
  dmServer.DocumentService.GetDownloadURl(dID,@DoSuccess,@DoFail);
end;

procedure TfrmSubmitPetition.DoDownloadPetition(Sender: TObject; Element:  TJSHTMLElementRecord; Event: TJSEventParameter);

Var
  aFileName : string;

    Procedure DoSuccess(aURL: string);

    begin
      DownloadFile(aURL,aFileName);
    end;

    Procedure DoFail(response: TJSOBject; fail: TJSError);

    begin
      dmServer.ShowError(ExtractErrorMsg(Fail,SErrGetFileURL));
    end;


Var
  dID : NativeInt;

begin
  dID:=FDossierFileID;
  if dID<=0 then exit;
  aFileName:=FDossierFileName;
  dmServer.DocumentService.GetDownloadURl(dID,@DoSuccess,@DoFail);
end;

procedure TfrmSubmitPetition.DoLocateDossier;

Var
  aID : NativeInt;
  aFileName : String;

begin
  aID:=-1;
  With dsDocuments do
    begin
    First;
    While not EOF do
      begin
      if SameText(dsDocumentsdofdoctype.AsString,'DOSSIER') and (dsDocumentsdofid.AsLargeInt>aID) then
        begin
        aID:=dsDocumentsdofid.AsLargeInt;
        aFileName:=dsDocumentsdofFileName.AsString;
        end;
      Next;
      end;
    end;
  if aID=-1 then
    dmServer.ShowError(SErrNoDossierCreated)
  else
    begin
    FDossierFileID:=aID;
    FDossierFileName:=aFileName;
    end;
  SetElementEnabled('btnDownloadPetition',FDossierFileID>0);
end;


procedure TfrmSubmitPetition.StartStatusCheck;

begin
  tmrCheck.enabled:=True;
end;

procedure TfrmSubmitPetition.tmrCheckTimer(Sender: TObject);

    Procedure DoSuccess(aResult : NativeInt);

    var
      aStatus : TJobQueueStatus;

    begin
      FinCheckCall:=False;
      aStatus.AsInteger:=aResult;
      if (aStatus in [jqsScheduled, jqsSelected, jqsInProgress]) then
        exit;
      tmrCheck.Enabled:=False;
      if aStatus<>jqsFinishOK then
        dmServer.ShowError(SErrGeneratingPetitionFailed)
      else
        begin
        fLocateDossier:=true;
        LoadFiles;
        end;
    end;

    Procedure DoFail(response: TJSOBject; fail: TJSError);

    Var
      Msg : String;

    begin
      Inc(FCheckErrorCount);
      FinCheckCall:=False;
      Msg:=ExtractErrorMsg(Fail,SErrFailedToGetJobStatus);
      if FCheckErrorCount>=10 then
        begin
        tmrCheck.Enabled:=False;
        Msg:=Msg+sLineBreak+'<br>'+SErrTooManyErrors;
        end;
      dmServer.ShowError(Msg);
    end;

begin
  if FInCheckCall then exit;
  if FSubmitJobID<=0 then exit;
  FInCheckCall:=True;
  dmServer.JobService.GetJobStatus(FSubmitJobID,@DoSuccess,@DoFail);
end;

procedure TfrmSubmitPetition.DoRegeneratePetition(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);

    procedure DoSuccess(anID : Int64);
    begin
      FSubmitJobID:=anID;
      StartStatusCheck;
      dmServer.ShowOK(SVerzoekSchriftGenerated);
    end;

    Procedure DoFail(response: TJSOBject; fail: TJSError);

    begin
      dmServer.ShowError(ExtractErrorMsg(Fail));
    end;

begin
  if FormIsReadonly then
    exit;
  If DossierID>0 then
    begin
    FCheckErrorCount:=0;
    Server.PetitionService.SubmitPetition(DossierID, @DoSuccess, @DoFail)
    end;
end;

procedure TfrmSubmitPetition.dsDocumentsAfterOpen(DataSet: TDataSet);

var
  aResult: TJSArray;

begin
  Inherited;
  aResult := dsDocuments.GetNewRows(False);
  dcDocuments.Data := aResult;
  dcDocuments.RenderTranslated;
  alForm['DownloadFile'].Bind;
  if FLocateDossier then
    DoLocateDossier;
end;

procedure TfrmSubmitPetition.GetSourceTypeValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);

Var
  FT : TFilesourceType;

begin
  FT.AsInteger:=Integer(Fields.GetValueByName('dofsourcetype'));
  aOutput := ft.AsDisplayString;
end;

procedure TfrmSubmitPetition.LoadFiles;

begin
  dsDocuments.Close;
  dsDocuments.ParamByname('DossierID').AsLargeInt:=DossierID;
  dsDocuments.Load([],Nil);
end;

procedure TfrmSubmitPetition.MakeReadOnly;
begin
  inherited;
  SetElementEnabled('btnRegeneratePetition',False);
  jQuery('#btnSubmitPetition').prop('href','javascript:void(0)').addClass('disabled');
end;

procedure TfrmSubmitPetition.setParams(const Params: TStrings);
begin
  inherited;
  LoadFiles;
end;

procedure TfrmSubmitPetition.WebFormCreate(Sender: TObject);
begin
  inherited;
  dmServer.PetitionConnection.Message.ClientID:=dmServer.LoginService.Message.ClientID;
//  dmServer.DocumentService.Message.ClientID:=dmServer.LoginService.Message.ClientID;
  dsDocuments.DAConnection:=dmServer.PetitionConnection;
  With dsDocuments.WhereClauseBuilder do
    dsDocuments.WhereClause:=GetWhereClause(
      NewBinaryExpression('dossierfile','dofdossierfk','DOSSIERID',datLargeInt,dboEqual)
    );
end;

procedure TfrmSubmitPetition.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsDocuments := TP2WDADataset.Create(Self);
  dsDocumentsdofid := TLargeintField.Create(Self);
  dsDocumentsdofcreatedon := TDateTimeField.Create(Self);
  dsDocumentsdofcreatedbyfk := TLargeintField.Create(Self);
  dsDocumentsdofchangedon := TDateTimeField.Create(Self);
  dsDocumentsdofchangedbyfk := TLargeintField.Create(Self);
  dsDocumentsdofdossierfk := TLargeintField.Create(Self);
  dsDocumentsdofsourcefk := TLargeintField.Create(Self);
  dsDocumentsdofsourcetype := TSmallintField.Create(Self);
  dsDocumentsdofdoctypefk := TLargeintField.Create(Self);
  dsDocumentsdofdoctype := TWideStringField.Create(Self);
  dsDocumentsdoffilename := TWideStringField.Create(Self);
  dsDocumentsdofmime := TWideStringField.Create(Self);
  dsDocumentsdofdata := TBlobField.Create(Self);
  dsDocumentsdofisuploadcomplete := TBooleanField.Create(Self);
  dsDocumentsdofuploaduserfk := TLargeintField.Create(Self);
  dsDocumentsdofcasefilenumber := TIntegerField.Create(Self);
  dcDocuments := TP2WDatatable.Create(Self);
  tmrCheck := TTimer.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  dsDocumentsdofid.BeforeLoadDFMValues;
  dsDocumentsdofcreatedon.BeforeLoadDFMValues;
  dsDocumentsdofcreatedbyfk.BeforeLoadDFMValues;
  dsDocumentsdofchangedon.BeforeLoadDFMValues;
  dsDocumentsdofchangedbyfk.BeforeLoadDFMValues;
  dsDocumentsdofdossierfk.BeforeLoadDFMValues;
  dsDocumentsdofsourcefk.BeforeLoadDFMValues;
  dsDocumentsdofsourcetype.BeforeLoadDFMValues;
  dsDocumentsdofdoctypefk.BeforeLoadDFMValues;
  dsDocumentsdofdoctype.BeforeLoadDFMValues;
  dsDocumentsdoffilename.BeforeLoadDFMValues;
  dsDocumentsdofmime.BeforeLoadDFMValues;
  dsDocumentsdofdata.BeforeLoadDFMValues;
  dsDocumentsdofisuploadcomplete.BeforeLoadDFMValues;
  dsDocumentsdofuploaduserfk.BeforeLoadDFMValues;
  dsDocumentsdofcasefilenumber.BeforeLoadDFMValues;
  dcDocuments.BeforeLoadDFMValues;
  tmrCheck.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnDownloadPetition';
      Name := 'btnDownoadPetition';
      SetEvent(Self, 'OnExecute', 'DoDownloadPetition');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnRegeneratePetition';
      Name := 'btnRegeneratePetition';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoRegeneratePetition');
    end;
    with alForm.Actions.Add do
    begin
      Enabled := False;
      Event := heNone;
      ID := 'btnSubmitPetition';
      Name := 'btnSubmitPetition';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnRegenerateYes';
      Name := 'btnRegenerateYes';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSubmitYes';
      Name := 'btnSubmitYes';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'grdFiles';
      Name := 'grdFiles';
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'DownloadFile';
      Selector := '[data-fileid]';
      SetEvent(Self, 'OnExecute', 'DoDownloadClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'petitionerName';
      Name := 'petitionerName';
    end;
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></binaryoperation></where></query> ';
    dsDocuments.DAOptions := [];
    dsDocuments.AfterOpen := dsDocumentsAfterOpen;
    dsDocuments.Left := 56;
    dsDocuments.Top := 168;
    dsDocumentsdofid.SetParentComponent(dsDocuments);
    dsDocumentsdofid.Name := 'dsDocumentsdofid';
    dsDocumentsdofid.FieldName := 'dofid';
    dsDocumentsdofcreatedon.SetParentComponent(dsDocuments);
    dsDocumentsdofcreatedon.Name := 'dsDocumentsdofcreatedon';
    dsDocumentsdofcreatedon.FieldName := 'dofcreatedon';
    dsDocumentsdofcreatedbyfk.SetParentComponent(dsDocuments);
    dsDocumentsdofcreatedbyfk.Name := 'dsDocumentsdofcreatedbyfk';
    dsDocumentsdofcreatedbyfk.FieldName := 'dofcreatedbyfk';
    dsDocumentsdofchangedon.SetParentComponent(dsDocuments);
    dsDocumentsdofchangedon.Name := 'dsDocumentsdofchangedon';
    dsDocumentsdofchangedon.FieldName := 'dofchangedon';
    dsDocumentsdofchangedbyfk.SetParentComponent(dsDocuments);
    dsDocumentsdofchangedbyfk.Name := 'dsDocumentsdofchangedbyfk';
    dsDocumentsdofchangedbyfk.FieldName := 'dofchangedbyfk';
    dsDocumentsdofdossierfk.SetParentComponent(dsDocuments);
    dsDocumentsdofdossierfk.Name := 'dsDocumentsdofdossierfk';
    dsDocumentsdofdossierfk.FieldName := 'dofdossierfk';
    dsDocumentsdofsourcefk.SetParentComponent(dsDocuments);
    dsDocumentsdofsourcefk.Name := 'dsDocumentsdofsourcefk';
    dsDocumentsdofsourcefk.FieldName := 'dofsourcefk';
    dsDocumentsdofsourcetype.SetParentComponent(dsDocuments);
    dsDocumentsdofsourcetype.Name := 'dsDocumentsdofsourcetype';
    dsDocumentsdofsourcetype.FieldName := 'dofsourcetype';
    dsDocumentsdofdoctypefk.SetParentComponent(dsDocuments);
    dsDocumentsdofdoctypefk.Name := 'dsDocumentsdofdoctypefk';
    dsDocumentsdofdoctypefk.FieldName := 'dofdoctypefk';
    dsDocumentsdofdoctype.SetParentComponent(dsDocuments);
    dsDocumentsdofdoctype.Name := 'dsDocumentsdofdoctype';
    dsDocumentsdofdoctype.FieldName := 'dofdoctype';
    dsDocumentsdofdoctype.Size := 10;
    dsDocumentsdoffilename.SetParentComponent(dsDocuments);
    dsDocumentsdoffilename.Name := 'dsDocumentsdoffilename';
    dsDocumentsdoffilename.FieldName := 'doffilename';
    dsDocumentsdoffilename.Size := 255;
    dsDocumentsdofmime.SetParentComponent(dsDocuments);
    dsDocumentsdofmime.Name := 'dsDocumentsdofmime';
    dsDocumentsdofmime.FieldName := 'dofmime';
    dsDocumentsdofmime.Size := 64;
    dsDocumentsdofdata.SetParentComponent(dsDocuments);
    dsDocumentsdofdata.Name := 'dsDocumentsdofdata';
    dsDocumentsdofdata.FieldName := 'dofdata';
    dsDocumentsdofisuploadcomplete.SetParentComponent(dsDocuments);
    dsDocumentsdofisuploadcomplete.Name := 'dsDocumentsdofisuploadcomplete';
    dsDocumentsdofisuploadcomplete.FieldName := 'dofisuploadcomplete';
    dsDocumentsdofuploaduserfk.SetParentComponent(dsDocuments);
    dsDocumentsdofuploaduserfk.Name := 'dsDocumentsdofuploaduserfk';
    dsDocumentsdofuploaduserfk.FieldName := 'dofuploaduserfk';
    dsDocumentsdofcasefilenumber.SetParentComponent(dsDocuments);
    dsDocumentsdofcasefilenumber.Name := 'dsDocumentsdofcasefilenumber';
    dsDocumentsdofcasefilenumber.FieldName := 'dofcasefilenumber';
    dcDocuments.SetParentComponent(Self);
    dcDocuments.Name := 'dcDocuments';
    dcDocuments.Columns.Clear;
    with dcDocuments.Columns.Add do
    begin
      FieldName := 'doffilename';
      Title := 'doffilename';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDocuments.Columns.Add do
    begin
      FieldName := 'dofdoctype';
      Title := 'dofdoctype';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'GetDocumenType');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDocuments.Columns.Add do
    begin
      FieldName := 'dofsourcetype';
      Title := 'dofsourcetype';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'GetSourceTypeValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDocuments.Columns.Add do
    begin
      FieldName := 'dofchangedon';
      Title := 'dofchangedon';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd"/"mm"/"yyyy hh":"nn';
    end;
    with dcDocuments.Columns.Add do
    begin
      FieldName := 'dofcasefilenumber';
      Title := 'dofcasefilenumber';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDocuments.Columns.Add do
    begin
      FieldName := 'dofid';
      Title := 'dofid';
      RenderMode := rmButton;
      ButtonType := btCustom;
      ButtonIconClass := 'fa fa-download';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'data-fileid={{dofid}}';
    end;
    dcDocuments.DataSet := dsDocuments;
    dcDocuments.Language := lEnglish;
    dcDocuments.IsResponsive := True;
    dcDocuments.GridID := 'grdFiles';
    dcDocuments.UseFieldIndex := True;
    dcDocuments.ShowSearch := False;
    dcDocuments.ShowNumberOfEntries := False;
    dcDocuments.ShowEntriesInfo := False;
    dcDocuments.Paginate := True;
    dcDocuments.DisplayReadOnly := False;
    dcDocuments.CalculateTableWidth := True;
    dcDocuments.Left := 56;
    dcDocuments.Top := 104;
    tmrCheck.SetParentComponent(Self);
    tmrCheck.Name := 'tmrCheck';
    tmrCheck.Enabled := False;
    SetEvent(tmrCheck, Self, 'OnTimer', 'tmrCheckTimer');
    tmrCheck.Left := 200;
    tmrCheck.Top := 176;
  finally
    alForm.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
    dsDocumentsdofid.AfterLoadDFMValues;
    dsDocumentsdofcreatedon.AfterLoadDFMValues;
    dsDocumentsdofcreatedbyfk.AfterLoadDFMValues;
    dsDocumentsdofchangedon.AfterLoadDFMValues;
    dsDocumentsdofchangedbyfk.AfterLoadDFMValues;
    dsDocumentsdofdossierfk.AfterLoadDFMValues;
    dsDocumentsdofsourcefk.AfterLoadDFMValues;
    dsDocumentsdofsourcetype.AfterLoadDFMValues;
    dsDocumentsdofdoctypefk.AfterLoadDFMValues;
    dsDocumentsdofdoctype.AfterLoadDFMValues;
    dsDocumentsdoffilename.AfterLoadDFMValues;
    dsDocumentsdofmime.AfterLoadDFMValues;
    dsDocumentsdofdata.AfterLoadDFMValues;
    dsDocumentsdofisuploadcomplete.AfterLoadDFMValues;
    dsDocumentsdofuploaduserfk.AfterLoadDFMValues;
    dsDocumentsdofcasefilenumber.AfterLoadDFMValues;
    dcDocuments.AfterLoadDFMValues;
    tmrCheck.AfterLoadDFMValues;
  end;
end;

end.