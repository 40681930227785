unit Forms.Revenue;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.datatables, pas2web.dadatasethelper,
  lib.bootstrap, Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmRevenue = class(TfrmBase)
    dsIncome: TP2WDADataset;
    dcIncome: TP2WDatatable;
    dcDebtor: TP2WDatatable;
    dsDebtor: TP2WDADataset;

    procedure OnAddressLineGetValue(Sender: TObject; Fields:
        TP2WDataTableFieldMap; out aOutput: string);
    procedure OnRevenueTupeGetValue(Sender: TObject; Fields:
        TP2WDataTableFieldMap; out aOutput: string);
    procedure dsDebtorAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsDebtorAfterOpen(DataSet: TDataSet);
    procedure dsIncomeAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsIncomeAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;

    procedure firstTabClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure secondTabClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure getVia(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
  private
    { Private declarations }
    FDoDeleteIncome : Boolean;
    FDoDeleteDebtor : Boolean;
    FDeleteID: NativeInt;
    FRoute : string;
    procedure ConfirmDeleteIncome;
    procedure ConfirmDeleteDebtor;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoDeleteIncome(const IncomeId : NativeInt);
    procedure DoDeleteDebtor(const debtorId : NativeInt);
  protected procedure LoadDFMValues; override; end;

var
  frmRevenue: TfrmRevenue;

implementation

{$R *.dfm}

uses
  Modules.Server, Units.DADatasetUtils, Units.ActionUtils, Units.Strings;

{ TfrmRevenue }

procedure TfrmRevenue.ConfirmDeleteDebtor;

  Procedure DoDeleteRecord;

  begin
    if dsDebtor.Locate('revid', FDeleteID,[]) then
      begin
      dsDebtor.Delete;
      dsDebtor.ApplyUpdates;
      end;
  end;

  Procedure DoCancelDelete;

  begin
    window.location.href := FRoute;
  end;

begin
  FDoDeleteDebtor := False;
  if Locate(dsDebtor, 'revid', FDeleteID) then
    DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
end;

procedure TfrmRevenue.ConfirmDeleteIncome;

  Procedure DoDeleteRecord;

  begin
    if dsIncome.Locate('revid', FDeleteID,[]) then
      begin
      dsIncome.Delete;
      dsIncome.ApplyUpdates;
      end;
  end;

  Procedure DoCancelDelete;

  begin
    window.location.href := FRoute;
  end;

begin
  FDoDeleteIncome := False;
  if Locate(dsIncome, 'revid', FDeleteID) then
    DefaultConfirmation.Execute('',@DoDeleteRecord,@DoCancelDelete);
end;

procedure TfrmRevenue.getVia(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('REVVIA', string(Fields.GetValueByName('revviatype')));
end;

procedure TfrmRevenue.OnAddressLineGetValue(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);
begin
  aOutput := string(Fields.GetValueByName('ctastreet')) + ', ' +
    string(Fields.GetValueByName('ctastreetnr'));
end;

procedure TfrmRevenue.OnRevenueTupeGetValue(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);
begin
  aOutput := Server.GetParamDisplayValue('REVTYP', string(Fields.GetValueByName('revrevenuetype')));
end;

procedure TfrmRevenue.DoDeleteDebtor(const debtorId: NativeInt);
begin
  FDeleteID := debtorId;
  if dsDebtor.State in [dsInactive] then
    FDoDeleteDebtor := True
  else
    ConfirmDeleteDebtor;
end;

procedure TfrmRevenue.DoDeleteIncome(const IncomeId : NativeInt);
begin
  FDeleteID := IncomeId;
  if dsIncome.State in [dsInactive] then
    FDoDeleteIncome := True
  else
    ConfirmDeleteIncome;
end;

procedure TfrmRevenue.dsDebtorAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);

begin
  CheckResolveResults(Info,FRoute);
end;

procedure TfrmRevenue.dsDebtorAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsDebtor.GetNewRows(False);
  dcDebtor.Data := aResult;
  dcDebtor.RenderTranslated;
  if FDoDeleteDebtor then
    ConfirmDeleteDebtor;
end;

procedure TfrmRevenue.dsIncomeAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);

begin
  CheckResolveResults(Info,FRoute);
end;

procedure TfrmRevenue.dsIncomeAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsIncome.GetNewRows(False);
  dcIncome.Data := aResult;
  dcIncome.RenderTranslated;
  if FDoDeleteIncome then
    ConfirmDeleteIncome;
end;

procedure TfrmRevenue.firstTabClick(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Revenue', '1');
end;

procedure TfrmRevenue.WebFormCreate(Sender: TObject);
begin
  inherited;

  FDoDeleteIncome := False;
  FDoDeleteDebtor := False;
  FDeleteID := -1;

  dsIncome.DAConnection := DossierConnection;
  dsDebtor.DAConnection := DossierConnection;

  //get selected tab
  if Server.GetLocalData('Revenue') = '2' then
  begin
    jQuery('a[href="#first"]').removeClass('active');
    jQuery('a[href="#second"]').addClass('active');
    jQuery('#first').removeClass('show');
    jQuery('#first').removeClass('active');
    jQuery('#second').addClass('show');
    jQuery('#second').addClass('active');
  end
  else
  begin
    jQuery('a[href="#second"]').removeClass('active');
    jQuery('a[href="#first"]').addClass('active');
    jQuery('#second').removeClass('show');
    jQuery('#second').removeClass('active');
    jQuery('#first').addClass('show');
    jQuery('#first').addClass('active');
  end;
end;

procedure TfrmRevenue.WebFormShow(Sender: TObject);
begin
  inherited;
  dsIncome.ParamByName('DossierID').asLargeInt := DossierID;
  dsDebtor.ParamByName('DossierID').asLargeInt := DossierID;
  dsIncome.Load([], nil);
  dsDebtor.Load([], nil);
end;

procedure TfrmRevenue.secondTabClick(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  Server.SetLocalData('Revenue', '2');
end;

procedure TfrmRevenue.setParams(const Params: TStrings);


begin
  Inherited;
  jQuery('#addIncome').attr('href',DossierURL('/revenue/new/income'));
  jQuery('#addDebtor').attr('href',DossierURL('/revenue/new/debtor'));
  FRoute :=DossierURL('/revenue');
  dsIncome.ParamByName('DossierID').asLargeInt := DossierID;
  dsDebtor.ParamByName('DossierID').asLargeInt := DossierID;
end;

procedure TfrmRevenue.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsIncome := TP2WDADataset.Create(Self);
  dcIncome := TP2WDatatable.Create(Self);
  dcDebtor := TP2WDatatable.Create(Self);
  dsDebtor := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsIncome.BeforeLoadDFMValues;
  dcIncome.BeforeLoadDFMValues;
  dcDebtor.BeforeLoadDFMValues;
  dsDebtor.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'first';
      PreventDefault := False;
      Selector := 'a[href="#first"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'firstTabClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'second';
      PreventDefault := False;
      Selector := 'a[href="#second"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'secondTabClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnIncYes';
      Name := 'btnIncYes';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnIncNo';
      Name := 'btnIncNo';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnDebYes';
      Name := 'btnDebYes';
      PreventDefault := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnDebNo';
      Name := 'btnDebNo';
      PreventDefault := False;
      StopPropagation := False;
    end;
    dsIncome.SetParentComponent(Self);
    dsIncome.Name := 'dsIncome';
    dsIncome.TableName := 'revenue';
    dsIncome.Params.Clear;
    with dsIncome.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DossierID';
      ParamType := ptInput;
    end;
    dsIncome.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>revdossierfk</field><parameter type="LargeInt">DossierID</parameter></binaryoperation><binaryoperation operator="NotEqual"><field>revrevenuetype</field><constant type="String" null="">DEBT</constant></binaryoperation></binaryoperation></where></query>';
    dsIncome.DAOptions := [];
    dsIncome.AfterOpen := dsIncomeAfterOpen;
    dsIncome.AfterApplyUpdates := dsIncomeAfterApplyUpdates;
    dsIncome.Left := 40;
    dsIncome.Top := 96;
    dcIncome.SetParentComponent(Self);
    dcIncome.Name := 'dcIncome';
    dcIncome.Columns.Clear;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revrevenuetype';
      Title := 'Kind';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnRevenueTupeGetValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'cntsearchname';
      Title := 'Origin';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revamount';
      Title := 'Net Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revviatype';
      Title := 'Via';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'getVia');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'reviscomplete';
      Title := 'Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revdossierfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      Width := '40';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{revdossierfk}}/revenue/edit/{{revid}}/income';
    end;
    with dcIncome.Columns.Add do
    begin
      FieldName := 'revid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Width := '40';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{revdossierfk}}/revenue/delete/{{revid}}/income';
    end;
    dcIncome.DataSet := dsIncome;
    dcIncome.Language := lEnglish;
    dcIncome.IsResponsive := True;
    dcIncome.GridID := 'grdIncome';
    dcIncome.UseFieldIndex := True;
    dcIncome.ShowSearch := False;
    dcIncome.ShowNumberOfEntries := False;
    dcIncome.ShowEntriesInfo := False;
    dcIncome.Paginate := True;
    dcIncome.DisplayReadOnly := False;
    dcIncome.CalculateTableWidth := True;
    dcIncome.Left := 40;
    dcIncome.Top := 160;
    dcDebtor.SetParentComponent(Self);
    dcDebtor.Name := 'dcDebtor';
    dcDebtor.Columns.Clear;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'cntsearchname';
      Title := 'Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'cntkbonr';
      Title := 'KBO Number';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'ctastreet';
      Title := 'Address';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'OnAddressLineGetValue');
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'ctacityzip';
      Title := 'Postcode';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'ctacityname';
      Title := 'City';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'revamount';
      Title := 'Net Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'revdossierfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      Width := '40';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{revdossierfk}}/revenue/edit/{{revid}}/debtor';
    end;
    with dcDebtor.Columns.Add do
    begin
      FieldName := 'revid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Width := '40';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{revdossierfk}}/revenue/delete/{{revid}}/debtor';
    end;
    dcDebtor.DataSet := dsDebtor;
    dcDebtor.Language := lEnglish;
    dcDebtor.IsResponsive := True;
    dcDebtor.GridID := 'grdDebtor';
    dcDebtor.UseFieldIndex := True;
    dcDebtor.ShowSearch := False;
    dcDebtor.ShowNumberOfEntries := False;
    dcDebtor.ShowEntriesInfo := False;
    dcDebtor.Paginate := True;
    dcDebtor.DisplayReadOnly := False;
    dcDebtor.CalculateTableWidth := True;
    dcDebtor.Left := 128;
    dcDebtor.Top := 160;
    dsDebtor.SetParentComponent(Self);
    dsDebtor.Name := 'dsDebtor';
    dsDebtor.TableName := 'revenue';
    dsDebtor.Params.Clear;
    with dsDebtor.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DossierID';
      ParamType := ptInput;
    end;
    dsDebtor.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>revdossierfk</field><parameter type="LargeInt">DossierID</parameter></binaryoperation><binaryoperation operator="Equal"><field>revrevenuetype</field><constant type="String" null="">DEBT</constant></binaryoperation></binaryoperation></where></query>';
    dsDebtor.DAOptions := [];
    dsDebtor.AfterOpen := dsDebtorAfterOpen;
    dsDebtor.AfterApplyUpdates := dsDebtorAfterApplyUpdates;
    dsDebtor.Left := 128;
    dsDebtor.Top := 96;
  finally
    alForm.AfterLoadDFMValues;
    dsIncome.AfterLoadDFMValues;
    dcIncome.AfterLoadDFMValues;
    dcDebtor.AfterLoadDFMValues;
    dsDebtor.AfterLoadDFMValues;
  end;
end;

end.
