{$IFDEF PAS2JS}
{$MODE objfpc}
{$MODESWITCH externalclass}
{$ENDIF}
{ ---------------------------------------------------------------------

  !!! DO NOT Use the Delphi code formatter on this unit. !!!
  It contains constructs that are unique to pas2js, and the codeformatter messes them up.

  --------------------------------------------------------------------- }

unit Units.Service.Dezq;

interface

uses
  {$IFNDEF PAS2JS}jsDelphiSystem,{$ENDIF} types, js, rosdk, dasdk;

Type
  TROSuccesProcedureEvent = reference to Procedure;
  TROSuccesEvent = reference to Procedure(res: JSValue);
  TROFailedEvent = reference to Procedure(response: TJSOBject; fail: TJSError);

  TROVariantArray = class {$IFDEF PAS2JS}external name 'DezqLibrary.TVariantArray'{$ENDIF}(TROArrayType)
    function toObject: TStringDynArray; reintroduce;
  end;

  TROStringArray = class {$IFDEF PAS2JS} external name 'DezqLibrary.TStringArray' {$ENDIF} (TROArrayType)
    procedure fromObject(aItem: JSValue); reintroduce; overload;
    procedure fromArray(aItem: TJSArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
    function toObject: TStringDynArray; reintroduce;
    function toArray : TStringDynArray; {$IFDEF PAS2JS}external name 'toObject';{$ENDIF}
  end;
  TROStringArrayResult = Reference to Procedure(aResult: TROStringArray);

  TAccount = record
    Email: String;
    MobileNr: String;
    Password: String;
    Username: String;
  end;

  TROAccount = class {$IFDEF PAS2JS} external name 'DezqLibrary.TAccount'{$ENDIF}(TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TAccount); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TAccount; reintroduce;
  Public
    Email: TROValue;
    MobileNr: TROValue;
    Password: TROValue;
    Username: TROValue;
  end;

  TPersonal = record
    Additional: String;
    Address: String;
    FirstName: String;
    HouseNr: String;
    LastName: String;
    Town: String;
    Zip: String;
  end;

  TROPersonal = class {$IFDEF PAS2JS}  external name 'DezqLibrary.TPersonal' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TPersonal); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TPersonal; reintroduce;
  Public
    Additional: TROValue;
    Address: TROValue;
    Firstname: TROValue;
    HouseNr: TROValue;
    Lastname: TROValue;
    Town: TROValue;
    Zip: TROValue;
  end;

  TOffice = Record
    Additional: String;
    Address: String;
    BankAccount: String;
    Email: String;
    HouseNr: String;
    Name: String;
    Telephone: String;
    Town: String;
    VAT: String;
    Zip: String;
  end;

  TROOffice = class {$IFDEF PAS2JS} external name 'DezqLibrary.TOffice' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TOffice); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TOffice; reintroduce;
  Public
    Additional: TROValue;
    Address: TROValue;
    BankAccount: TROValue;
    Email: TROValue;
    HouseNr: TROValue;
    Name: TROValue;
    Telephone: TROValue;
    Town: TROValue;
    VAT: TROValue;
    Zip: TROValue;
  end;

  TRegistration = Record
    Account: TAccount;
    Office: TOffice;
    Personal: TPersonal;
  end;

  TRORegistration = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TRegistration' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TRegistration); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TOffice; reintroduce;
  Public
    Account: TROValue;
    Office: TROValue;
    Personal: TROValue;
  end;

  TDezqUser = Record
    Attributes: Array of JSValue;
    FirstName: String;
    LastName: String;
    Office: String;
    Privileges: Array of String;
    SessionID: String;
    UserData: JSValue;
    UserID: String;
    Role : String;
    Rights : NativeInt;
    OfficeID : NativeInt;
  end;

  TRODezqUser = Class  {$IFDEF PAS2JS}  external name 'DezqLibrary.TDezqUser' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TDezqUser); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TDezqUser; reintroduce;
  Public
    Attributes: TROValue;
    FirstName: TROValue;
    LastName: TROValue;
    Office: TROValue;
    Privileges: TROValue;
    SessionID: TROValue;
    UserData: TROValue;
    UserID: TROValue;
    Role : TROValue;
    Rights : TROValue;
    OfficeID : TROValue;
  end;

  TPasswordResetRequest = record
    UserName: String;
    OfficeName: String;
    Email: String;
  end;

  TROPasswordResetRequest = Class  {$IFDEF PAS2JS}  external name 'DezqLibrary.TPasswordResetRequest' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TPasswordResetRequest); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TPasswordResetRequest; reintroduce;
  Public
    UserName: TROValue;
    OfficeName: TROValue;
    Email: TROValue;
  end;

  TDocument = record
    DocBucket : String;
    DocCreatedOn : TJSDate;
    DocDossier : NativeInt;
    DocExtension : String;
    DocId : NativeInt;
    DocIsComplete : Boolean;
    DocName : String;
  end;

  TRODocument = Class  {$IFDEF PAS2JS} external name 'DezqLibrary.TDocument' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TDocument); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TDocument; reintroduce;
  Public
    DocBucket : TROValue;
    DocCreatedOn : TROValue;
    DocDossier : TROValue;
    DocExtension : TROValue;
    DocId : TROValue;
    DocIsComplete : TROValue;
    DocName : TROValue;
  end;

  TCodaImportStatus = record
    AccountsProcessed : Integer;
    EntriesProcessed : Integer;
    ErrorStrings : Array of String;
    Status : Integer;
  end;

  TROCodaImportStatus = Class  {$IFDEF PAS2JS} external name 'DezqLibrary.TCodaImportStatus' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCodaImportStatus); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TCodaImportStatus; reintroduce;
  Public
    AccountsProcessed : TROValue;
    EntriesProcessed : TROValue;
    ErrorStrings : TROValue;
    Status : TROValue;
  end;

  TPetitionCloseData = record
    CloseStatus : string;
    PetitionID : NativeInt;
    Remarks : String;
  end;

  TROPetitionCloseData = Class  {$IFDEF PAS2JS} external name 'DezqLibrary.TPetitionCloseData'{$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TPetitionCloseData); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TPetitionCloseData; reintroduce;
  Public
    CloseStatus : TROValue;
    PetitionID : TROValue;
    Remarks : TROValue;
  end;

  TCreatePetition = record
    AddressCityName : string;
    AddressCityZip : string;
    AddressNr : string;
    AddressStreet : string;
    BirthCityName : string;
    BirthCityZip : string;
    CardNumber : String;
    CardRemark : String;
    CardType : String;
    CardChipNo : String;
    CardValidFrom : TJSDate;
    CardValidTill : TJSDate;
    DateOfBirth : TJSDate;
    Description : String;
    Gender : String;
    FirstName: String;
    LastName : String;
    Nationality : String;
    NIDNR : String;
    Picture : String;
  end;

  TROCreatePetition = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TCreatePetition' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCreatePetition); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TCreatePetition; reintroduce;
  Public
    AddressCityName : TROValue;
    AddressCityZip : TROValue;
    AddressNr : TROValue;
    AddressStreet : TROValue;
    BirthCityName : TROValue;
    BirthCityZip : TROValue;
    CardNumber : TROValue;
    CardRemark : TROValue;
    CardType : TROValue;
    CardChipNo : TROValue;
    CardValidFrom : TROValue;
    CardValidTill : TROValue;
    DateOfBirth : TROValue;
    Description : TROValue;
    Gender : TROValue;
    FirstName: TROValue;
    LastName : TROValue;
    Nationality : TROValue;
    NIDNR : TROValue;
    Picture : TROValue;
  end;

  TCountry = record
    ISO2 : string;
    ISO3 : string;
    Name : string;
    RecordID : NativeInt;
  end;

  TROCountry = Class {$IFDEF PAS2JS}  external name 'DezqLibrary.Country' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCountry); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TCountry; reintroduce;
  Public
    ISO2 : TROValue;
    ISO3 : TROValue;
    Name : TROValue;
    RecordID : TROValue;
  end;

  TCity = record
    Country : TCountry;
    Zip : string;
    Name : string;
    RecordID : NativeInt;
  end;

  TROCity = Class{$IFDEF PAS2JS}  external name 'DezqLibrary.City' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCity); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TCity; reintroduce;
  Public
    Country : TROValue;
    Zip : TROValue;
    Name : TROValue;
    RecordID : TROValue;
  end;

  TParameter = record
    RecordID : NativeInt;
    Name : String;
    Language : integer;
    ParamType : string;
    Value : string;
    ValueType : integer;
    Extra : string;
  end;
  TParameterDynArray = Array of TParameter;

  TROParameterArray = class {$IFDEF PAS2JS} external name 'DezqLibrary.ParameterArray' {$ENDIF} (TROArrayType)
  Public
    function toObject: TParameterDynArray; reintroduce;
  end;

  TReportParameter = record
    Name :  String;
    Value : JSValue;
  end;
  TReportParameterDynArray = Array of TReportParameter;

  TROReportParameterArray = class {$IFDEF PAS2JS} external name 'DezqLibrary.TReportParameterArray' {$ENDIF} (TROArrayType)
  Public
    constructor new;
    function toObject: TReportParameterDynArray; reintroduce;
    procedure fromArray(aItem: TReportParameterDynArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
  end;

  TROReportParameter =  Class {$IFDEF PAS2JS}  external name 'DezqLibrary.TReportParameter' {$ENDIF} (TROStructType)
    Name :  TROValue;
    Value : TROValue;
    procedure fromObject(aItem: TReportParameter); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TReportParameter; reintroduce;
  End;

  TROParameter =  Class {$IFDEF PAS2JS}  external name 'DezqLibrary.TParameter' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TParameter); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TParameter; reintroduce;
  Public
    RecordID : TROValue;
    Name : TROValue;
    Language : TROValue;
    ParamType : TROValue;
    Value : TROValue;
    ValueType : TROValue;
    Extra : TROValue;
  end;



  TContactTelecom = record
    RecordID: Int64;
    TelecomType: String;
    Data: String;
    Remark: String;
  end;
  TContactTelecomArray = array of TContactTelecom;

  TROContactTelecom = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TContactTelecom' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TContactTelecom); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TContactTelecom; reintroduce;
  Public
    RecordID: TROValue;
    TelecomType: TROValue;
    Data: TROValue;
    Remark: TROValue;
  end;

  TROContactTelecomArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TContactTelecomArray' {$ENDIF} (TROArrayType)
  Public
    function toObject: TContactTelecomArray; reintroduce;
  end;

  TContactAddress = record
    RecordID: Int64;
    AddressType: String;
    Street: String;
    StreetNr: String;
    CityZIP: String;
    CityName: String;
    CityID: Int64;
    Remark: String;
    Secret: Boolean;
    Correspondence: Boolean;
  end;
  TContactAddressArray = array of TContactAddress;

  TROContactAddress = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TContactAddress'  {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TContactAddress); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TContactAddress; reintroduce;
  Public
    RecordID: TROValue;
    AddressType: TROValue;
    Street: TROValue;
    StreetNr: TROValue;
    CityZIP: TROValue;
    CityName: TROValue;
    CityID: TROValue;
    Remark: TROValue;
    Secret: TROValue;
    Correspondence: TROValue;
  end;

  TROContactAddressArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TContactAddressArray' {$ENDIF} (TROArrayType)
  Public
    function toObject: TContactAddressArray; reintroduce;
  end;

  TContact = record
    RecordID: Int64;
    FirstName: String;
    Lastname: String;
    Profession: String;
    BirthDateOn: TJSDate;
    IsBirthDateUnknown: Boolean;
    Gender: String;
    CityOfBirthID: Int64;
    CityOfBirthZIP: String;
    CityOfBirthName: String;
    NationalityID: Int64;
    Nationality2: String;
    Nationality: String;
    KBONR: String;
    Remark: String;
    Salutation: String;
    SearchName: String;
    Prefixes: String;
    FriendlyTitle: String;
    Telecom: TContactTelecomArray;
    Addresses: TContactAddressArray;
  end;

  TROContact = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TContact' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TContact); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TContact; reintroduce;
  Public
    RecordID: TROValue;
    FirstName: TROValue;
    Lastname: TROValue;
    Profession: TROValue;
    BirthDateOn: TROValue;
    IsBirthDateUnknown: TROValue;
    Gender: TROValue;
    CityOfBirthID: TROValue;
    CityOfBirthZIP: TROValue;
    CityOfBirthName: TROValue;
    NationalityID: TROValue;
    Nationality2: TROValue;
    Nationality: TROValue;
    KBONR: TROValue;
    Remark: TROValue;
    Salutation: TROValue;
    SearchName: TROValue;
    Prefixes: TROValue;
    FriendlyTitle: TROValue;
    Telecom: TROValue;
    Addresses: TROValue;
  end;
  TContactArray = Array of TContact;

  TROContactArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TContactArray' {$ENDIF} (TROArrayType)
  Public
    function toObject: TContactArray; reintroduce;
  end;

  TRelatedContact = record
    RecordID: Int64;
    FirstName: String;
    Lastname: String;
    Profession: String;
    BirthDateOn: TJSDate;
    IsBirthDateUnknown: Boolean;
    Gender: String;
    CityOfBirthID: Int64;
    CityOfBirthZIP: String;
    CityOfBirthName: String;
    NationalityID: Int64;
    Nationality2: String;
    Nationality: String;
    KBONR: String;
    Remark: String;
    Salutation: String;
    SearchName: String;
    Prefixes: String;
    FriendlyTitle: String;
    RelationType : String;
    Telecom: TContactTelecomArray;
    Addresses: TContactAddressArray;
  end;
  TRelatedContactArray = Array of TRelatedContact;

  TRORelatedContact = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TRelatedContact'  {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TContact); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TContact; reintroduce;
  Public
    RelationType : TROValue;
    RecordID: TROValue;
    FirstName: TROValue;
    Lastname: TROValue;
    Profession: TROValue;
    BirthDateOn: TROValue;
    IsBirthDateUnknown: TROValue;
    Gender: TROValue;
    CityOfBirthID: TROValue;
    CityOfBirthZIP: TROValue;
    CityOfBirthName: TROValue;
    NationalityID: TROValue;
    Nationality2: TROValue;
    Nationality: TROValue;
    KBONR: TROValue;
    Remark: TROValue;
    Salutation: TROValue;
    SearchName: TROValue;
    Prefixes: TROValue;
    FriendlyTitle: TROValue;
    Telecom: TROValue;
    Addresses: TROValue;
  end;

  TRORelatedContactArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TRelatedContactArray' {$ENDIF} (TROArrayType)
    function toObject: TRelatedContactArray; reintroduce;
  end;

  TContactSearchOptions = record
    CaseSensitive : Boolean;
    IsRegexp : Boolean;
    Recurse : Boolean;
    SearchAddress : Boolean;
    SearchTelecom : Boolean;
    LastNameInitialOnly : Boolean;
    StartOnly  : Boolean;
  end;

  TROContactSearchOptions = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TContactSearchOptions'  {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TContactSearchOptions); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TContactSearchOptions; reintroduce;
  Public
    CaseSensitive : TROValue;
    IsRegexp : TROValue;
    Recurse : TROValue;
    SearchAddress : TROValue;
    SearchTelecom : TROValue;
    LastNameInitialOnly : TROValue;
    StartOnly  : TROValue;
  end;

  TDossierCheck = record
    TableName: String;
    Remark: String;
    RecordID: NativeInt;
    MustBeFixed: Boolean;
  end;
  TDossierCheckArray = Array of TDossierCheck;

  TRODossierCheck = class {$IFDEF PAS2JS} external name 'DezqLibrary.TDossierCheck'{$ENDIF}(TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TDossierCheck); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TAccount; reintroduce;
  Public
    TableName: TROValue;
    Remark: TROValue;
    RecordID: TROValue;
    MustBeFixed: TROValue;
  end;

  TRODossierCheckArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TDossierArray' {$ENDIF} (TROArrayType)
    function toObject: TDossierCheckArray; reintroduce;
  end;

  TProfileData = record
    AvatarData : String;
    AvatarURL : String;
    Email : String;
    FirstName : String;
    LastName : String;
    LoginName : String;
    Mobile : String;
    NewPassword : String;
    OldPassword : String;
    Role : String;
  end;



  TROProfileData = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TProfileData' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TProfileData); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TProfileData; reintroduce;
  Public
    AvatarData : TROValue;
    AvatarURL : TROValue;
    Email : TROValue;
    FirstName : TROValue;
    LastName : TROValue;
    LoginName : TROValue;
    Mobile : TROValue;
    NewPassword : TROValue;
    OldPassword : TROValue;
    Role : TROValue;
  end;

  TPetitionSearchCriteria = record
    NIDNR : String;
  end;

  TROPetitionSearchCriteria = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TSearchPetitionCriteria' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TPetitionSearchCriteria); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TPetitionSearchCriteria; reintroduce;
  Public
    NIDNR: TROValue;
  End;

  TCheckPetitionCopyAction = record
    ActionID: Integer;
    SourceContactID: NativeInt;
    TargetContactID: NativeInt;
    ContactDescription: String;
    DifferenceDescription: String;
    UseExisting: Boolean;
  end;

  TROCheckPetitionCopyAction = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TCheckPetitionCopyAction' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCheckPetitionCopyAction); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TCheckPetitionCopyAction; reintroduce;
  Public
    ActionID: TROValue;
    SourceContactID: TROValue;
    TargetContactID: TROValue;
    ContactDescription: TROValue;
    DifferenceDescription: TROValue;
    UseExisting: TROValue;
  end;

  TCheckPetitionCopyActionArray = Array of TCheckPetitionCopyAction;

  TROCheckPetitionCopyActionArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TCheckPetitionCopyActionArray' {$ENDIF} (TROArrayType)
    function toObject: TCheckPetitionCopyActionArray; reintroduce;
    procedure fromObject(aItem: JSValue); reintroduce; overload;
    procedure fromArray(aItem: TJSArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
    procedure fromArray(aItem: TCheckPetitionCopyActionArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
  end;

  TSetPetitionCopyAction = Record
    ActionID: Integer;
    UseExisting: Boolean;
  end;

  TSetPetitionCopyActionArray = Array of TSetPetitionCopyAction;

  TCheckPetitionCopyResult = Record
    DuplicateContacts: TCheckPetitionCopyActionArray;
  End;

  TROCheckPetitionCopyResult = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TCheckPetitionCopyResult' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCheckPetitionCopyResult); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TCheckPetitionCopyResult; reintroduce;
  Public
    DuplicateContacts: TROValue;
  End;

  TROSetPetitionCopyAction = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TSetPetitionCopyAction' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TSetPetitionCopyAction); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TSetPetitionCopyAction; reintroduce;
  Public
    ActionID: TROValue;
    UseExisting: TROValue;
  End;

  TROSetPetitionCopyActionArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TSetPetitionCopyActionArray' {$ENDIF} (TROArrayType)
  Public
    constructor new;
    function toObject: TSetPetitionCopyActionArray; reintroduce;
    procedure fromObject(aItem: JSValue); reintroduce; overload;
    procedure fromArray(aItem: TJSArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
    procedure fromArray(aItem: TSetPetitionCopyActionArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
  end;

  TCopyPetitionToCSROptions = Record
    SourceID: NativeInt;
    CopyJobID: NativeInt;
    CourtID: NativeInt;
    JudgeContactID: NativeInt;
    StartDate: TJSDate;
    CSRAdmissionFile: String;
  End;

  TROCopyPetitionToCSROptions = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TCopyPetitionToCSROptions' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCopyPetitionToCSROptions); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TCopyPetitionToCSROptions; reintroduce;
  Public
    SourceID: TROValue;
    CopyJobID: TROValue;
    CourtID: TROValue;
    JudgeContactID: TROValue;
    StartDate: TROValue;
    CSRAdmissionFile: TROValue;
  End;

  TCSRCreateData = Record
    ContactID: NativeInt;
    Description: String;
    CourtID: NativeInt;
    JudgeContactID: NativeInt;
    StartDate: TJSDate;
    CSRAdmissionFile: String;
  End;

  TROCSRCreateData = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TCSRCreateData' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCSRCreateData); reintroduce; overload;
    procedure fromObject(aItem: TJSOBject); reintroduce; overload;
    function toObject: TCSRCreateData; reintroduce;
  Public
    ContactID: TROValue;
    Description: TROValue;
    CourtID: TROValue;
    JudgeContactID: TROValue;
    StartDate: TROValue;
    CSRAdmissionFile: TROValue;
  End;

  TCopySettlementPlanOptions = Record
    aSourceID: NativeInt;
    Description: String;
    StartDate: TJSDate;
  End;

  TROCopySettlementPlanOptions = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TCopySettlementPlanOptions' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCopySettlementPlanOptions); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TCopySettlementPlanOptions; reintroduce;
  Public
    aSourceID: TROValue;
    Description: TROValue;
    StartDate: TROValue;
  End;

  TBudgetPlanMonthEntry = record
    EntryType : Integer;
    Category: String;
    Amount: Double;
  end;
  TBudgetPlanMonthEntryArray = Array of TBudgetPlanMonthEntry;

  TROBudgetPlanMonthEntry = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TBudgetPlanMonthEntry' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TBudgetPlanMonthEntry); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TBudgetPlanMonthEntry; reintroduce;
  Public
    EntryType : TROValue;
    Category: TROValue;
    Amount: TROValue;
  End;

  TROBudgetPlanMonthEntryArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TBudgetPlanMonthEntryArray' {$ENDIF} (TROArrayType)
  Public
    function toObject: TBudgetPlanMonthEntryArray; reintroduce;
    procedure fromObject(aItem: JSValue); reintroduce; overload;
    procedure fromArray(aItem: TJSArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
    procedure fromArray(aItem: TBudgetPlanMonthEntryArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
  end;

  TPetitionCreditor = record
    Amount : Double;
    Contact : TContact;
    CurrentAmount : Double;
    RecordID : NativeInt;
  end;

  TROPetitionCreditor = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TPetitionCreditor' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TPetitionCreditor); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TPetitionCreditor; reintroduce;
  Public
    Amount : TROValue;
    Contact : TROValue;
    CurrentAmount : TROValue;
    RecordID : TROValue;
  end;

  TPetitionCreditorArray = array of TPetitionCreditor;

  TROPetitionCreditorArray = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TPetitionCreditorArray' {$ENDIF} (TROArrayType)
  Public
    function toObject: TPetitionCreditorArray; reintroduce;
    procedure fromObject(aItem: JSValue); reintroduce; overload;
    procedure fromArray(aItem: TJSArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
    procedure fromArray(aItem: TPetitionCreditorArray); reintroduce; overload;{$IFDEF PAS2JS}external name 'fromObject';{$ENDIF}
  end;

  TSearchPetitionResult = record
    Creditors : TPetitionCreditorArray;
    DossierID : NativeInt;
    NIDNR : String;
    Person : TContact;
  end;

  TROSearchPetitionResult = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TSearchPetitionResult' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TSearchPetitionResult); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TSearchPetitionResult; reintroduce;
  Public
    Creditors : TROValue;
    DossierID : TROValue;
    NIDNR : TROValue;
    Person : TROValue;
  end;


  TSettlementPlanCreateOptions = Record
    DossierID : Int64;
    SourceID: Int64;
    PaymentOnDay: Integer;
    Description: String;
    ValidFrom: TJSDate;
    BudgetPlanSourceID: Int64;
    BudgetPlanMonthTemplate: TBudgetPlanMonthEntryArray;
    CopyMonthTemplate: Boolean;
  End;

  TROSettlementPlanCreateOptions = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TSettlementPlanCreateOptions' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TSettlementPlanCreateOptions); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TSettlementPlanCreateOptions; reintroduce;
  Public
    DossierID: TROValue;
    SourceID: TROValue;
    PaymentOnDay: TROValue;
    Description: TROValue;
    ValidFrom: TROValue;
    BudgetPlanSourceID: TROValue;
    BudgetPlanMonthTemplate: TROValue;
    CopyMonthTemplate: TROValue;
  End;


  TSettlementPlanCreateResult = Record
    SettlementPlanID: Int64;
    BudgetPlanID: Int64;
  End;

  TROSettlementPlanCreateResult = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TSettlementPlanCreateResult' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TSettlementPlanCreateResult); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TSettlementPlanCreateResult; reintroduce;
  Public
    SettlementPlanID: TROValue;
    BudgetPlanID: TROValue;
  End;

  TCSRCertifyOptions = record
    PlanID : NativeInt;
    YearlyPayments : Boolean;
  end;

  TROCSRCertifyOptions = Class {$IFDEF PAS2JS} external name 'DezqLibrary.TCSRCertifyOptions' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TCSRCertifyOptions); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TCSRCertifyOptions; reintroduce;
  Public
    PlanID: TROValue;
    YearlyPayments : TROValue;
  End;

  TRODezqError = class {$IFDEF PAS2JS} external name 'DezqLibrary.EDezqError' {$ENDIF} (TROException);

  TMessageStatistics = record
    UnreadCompany: Integer;
    TotalCompany: Integer;
    UnreadUser: Integer;
    TotalUser: Integer;
    LastCompanyMessage: String;
    LastUserMessage: String;
  end;

  TROMessageStatistics = Class {$IFDEF PAS2JS} external name 'DezqLibrary.MessageStatistics' {$ENDIF} (TROStructType)
  Public
    constructor new;
    procedure fromObject(aItem: TMessageStatistics); reintroduce; overload;
    procedure fromObject(aItem: TJSObject); reintroduce; overload;
    function toObject: TMessageStatistics; reintroduce;
  Public
    UnreadCompany: TROValue;
    TotalCompany: TROValue;
    UnreadUser: TROValue;
    TotalUser: TROValue;
    LastCompanyMessage: String;
    LastUserMessage: String;
  end;


  TVoidResult = Reference to Procedure();
  TStringResult = Reference to Procedure(aResult : String);
  TBoolResult = Reference to Procedure(aResult : Boolean);
  TNativeIntResult = Reference to Procedure(aResult : NativeInt);

  TContactResult = reference to procedure (aResult : TROContact);
  TContactTelecomArrayResult = reference to procedure (aResult : TROContactTelecomArray);
  TContactAddressArrayResult = reference to procedure (aResult : TROContactAddressArray);
  TContactArrayResult = reference to procedure (aResult : TROContactArray);
  TRelatedContactArrayResult = reference to procedure (aResult : TRORelatedContactArray);
  TCSRSearchPetitionResult = reference to procedure (aResult : TROSearchPetitionResult);
  TROMessageStatisticsResult = Reference to procedure (aResult : TROMessageStatistics);

  TDezqBaseService = class {$IFDEF PAS2JS} external name 'DezqLibrary.BaseService' {$ENDIF} (TP2WDABaseLoginService)
  Public
    Procedure Ping(OnSuccess: TStringResult; OnFailed: TROFailedEvent);
  end;

  TDezqBaseDataService = class  {$IFDEF PAS2JS} external name 'DezqLibrary.BaseDataService' {$ENDIF}(TP2WDADataAbstractService)
  Public
    Procedure Ping(OnSuccess: TStringResult; OnFailed: TROFailedEvent);
  end;


  TDezqLoginSuccessEvent = reference to Procedure(Result: Boolean; UserInfo: TRODezqUser);
  TProfileDataResult = reference to procedure (aResult : TROProfileData);

  TDezqLoginService = class {$IFDEF PAS2JS} external name 'DezqLibrary.LoginService'  {$ENDIF}(TP2WDABaseLoginService)
  Public
    Procedure DezqLogin(aUserName, aPassword, aOffice: String; OnSuccess: TDezqLoginSuccessEvent; OnFailed: TROFailedEvent);
    Procedure RequestPasswordReset(aRequest: TROPasswordResetRequest; OnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure GetSessionUserInfo(OnSuccess: TDezqLoginSuccessEvent; OnFailed: TROFailedEvent);
    Procedure SetPassword(const aKey,aNewPassword : String; UseOldPassword : Boolean; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
    procedure GetProfileData(OnSuccess: TProfileDataResult; OnFailed: TROFailedEvent);
    procedure SetProfileData(aProfile: TROProfileData; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
    Procedure GetLoginRoles(OnSuccess: TROStringArrayResult; OnFailed: TROFailedEvent);
    Procedure SetUserLanguage(const aLanguage : String; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
  end;

  TDezqIsValidIBANEvent = reference to Procedure(aResult: Boolean; aError: string);

  TDezqNumberService = class {$IFDEF PAS2JS} external name 'DezqLibrary.NumberService'  {$ENDIF} (TDezqBaseService)
  Public
    Procedure IsValidVAT(aCountry, aVATNr: String; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
    Procedure IsValidIBAN(aIBAN: String; OnSuccess: TDezqIsValidIBANEvent; OnFailed: TROFailedEvent);
    Procedure IsValidPhoneNumber(aPhoneNumber: String; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
    Procedure IsValidNIDNR(aIDNR, aGender: String; aDateTime : TJSDate; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
  end;


  TDezqRegisterService = class {$IFDEF PAS2JS}  external name 'DezqLibrary.RegisterService' {$ENDIF}(TDezqBaseService)
  Public
    Procedure Signon(aRegistration: TRORegistration; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
  end;

  TDocInitiateUploadEvent = reference to procedure (uploadURL : String; objectID : Integer);

  TDezqDocumentService = class {$IFDEF PAS2JS} external name 'DezqLibrary.DocumentService'  {$ENDIF}(TDezqBaseDataService)
  Public
    Procedure GetDownloadUrl(aDossierFileId: NativeInt; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
    procedure DocumentUploadInit(aDossierFileID : NativeInt; aOnSuccess : TStringResult; OnFailed: TROFailedEvent);
    procedure DocumentUploadDone(aDossierFileID : NativeInt; aOnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    procedure DocumentDelete(aDossierFileID : NativeInt; aOnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    procedure CreateNewDocument(DossierID : NativeInt; SourceType : integer; SourceID : NativeInt; DocumentType : String; AFileName : string; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
  end;

  TDezqSettlementService = class {$IFDEF PAS2JS} external name 'DezqLibrary.SettlementService' {$ENDIF}(TROService)
  end;

  TDezqCompanyService = class {$IFDEF PAS2JS} external name 'DezqLibrary.CompanyService' {$ENDIF}(TDezqBaseDataService)
  Public
    procedure SignOn(aRegistration: TRORegistration; aOnSuccess: TStringResult; OnFailed: TROFailedEvent);
    procedure ApproveSignon(aID: NativeInt; aApprove: Boolean; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    procedure MakeReadOnly(aCompanyID: NativeInt; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    procedure DeActivate(aCompanyID: NativeInt; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    procedure CheckEmailAndUserName(const aLoginID: NativeInt; const aUserName, aEmail: String; aOnSuccess: TStringResult; OnFailed: TROFailedEvent);
    procedure GetCompanyDossierID(const aDossierID: NativeInt; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    procedure GetMessageStatistics(aFrom, aTo: TJSDate; aOnSuccess: TROMessageStatisticsResult; OnFailed: TROFailedEvent);
    procedure SetMessageStatus(aID: NativeInt; aIsRead: Boolean; aOnSuccess: TROSuccesProcedureEvent; OnFailed: TROFailedEvent);
    procedure DeleteMessage(aID: NativeInt; aOnSuccess: TVoidResult; OnFailed: TROFailedEvent);
    procedure ConnectBankAccount(const aBankAccountID: NativeInt; const aDossierID: NativeInt; OnSuccess: TVoidResult; OnFailed: TROFailedEvent);
  end;

  TDossierCheckArrayResult = reference to procedure (aErrors : TRODossierCheckArray);

  TDezqPetitionService = class {$IFDEF PAS2JS} external name 'DezqLibrary.PetitionService' {$ENDIF} (TDezqBaseDataService)
  Public
    Procedure ArchivePetition(aPetition : TROPetitionCloseData; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure SubmitPetition(aPetitionID : NativeInt; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure ReOpenPetition(aCompanyID : NativeInt; aPetitionID : NativeInt; aReason : String; aOnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    Procedure CheckComplete(aPetitionID : NativeInt; aOnSuccess : TDossierCheckArrayResult; OnFailed: TROFailedEvent);
    Procedure CreatePetition(const aPetitionData: TROCreatePetition; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure CreatePetitionFromContact(aContactID : NativeInt; aDescription : String; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure GetDossierContact(aDossierID : NativeInt; aContactType : String; aRecurse: Boolean; aOnSuccess : TContactArrayResult; OnFailed: TROFailedEvent);
    procedure CheckLegalReference(aDossier: NativeInt; aReference: String; aLecID: NativeInt; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure GetPetitionStatus(aDossierID : NativeInt; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure FastAddPossessions(aDossierID : NativeInt; aOwner : String; aPossessions: TROStringArray; OnSuccess : TNativeIntResult;OnFailed: TROFailedEvent);
  end;

  TJobStatusExResult = Reference to procedure (aResult : NativeInt; aError : String);

  TDezqJobService = class {$IFDEF PAS2JS} external name 'DezqLibrary.JobService' {$ENDIF}(TDezqBaseDataService)
  Public
    Procedure CancelJob(aJobID : NativeInt; aReason : String; aOnSuccess : TVoidResult;OnFailed: TROFailedEvent);
    Procedure GetJobStatus(aJobID : NativeInt; aOnSuccess : TNativeIntResult;OnFailed: TROFailedEvent);
    Procedure GetJobStatusEx(aJobID : NativeInt; aOnSuccess : TJobStatusExResult;OnFailed: TROFailedEvent);
  end;

  TDezqMailService = class {$IFDEF PAS2JS} external name 'DezqLibrary.MailService'  {$ENDIF} (TDezqBaseDataService)
  Public
    // Will still change.
    Procedure SendMail(aAddress : String; OnSuccess : TBoolResult; OnFailed: TROFailedEvent);
  end;

  TCodaImportStatusResult = Reference to procedure (aResult : TROCodaImportStatus);

  TDezqCodaService = class {$IFDEF PAS2JS} external name 'DezqLibrary.CodaService' {$ENDIF} (TDezqBaseService)
  Public
    Procedure ImportCodaFile(aFileName, aFileContents : String; aInitStart : Boolean; OnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure GetCodaImportStatus(aJobID : NativeInt; OnSuccess : TCodaImportStatusResult; OnFailed: TROFailedEvent);
  end;

  TCityResult = Reference to procedure (aResult : TROCity);

  TDezqGeoService = class {$IFDEF PAS2JS}  external name 'DezqLibrary.GeoService' {$ENDIF}(TDezqBaseDataService)
  Public
    Procedure GetCityDataFromZip(aCountryISO,aCityZip : String; OnSuccess: TCityResult; OnFailed: TROFailedEvent);
  end;

  TDezqGreCaptchaService = class {$IFDEF PAS2JS}  external name 'DezqLibrary.GreCaptchaService' {$ENDIF}(TDezqBaseDataService)
  Public
    Procedure GetCityDataFromZip(aCaptchaData : String; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
  end;

  TROParameterArrayResult = reference to procedure(Res : TROParameterArray);
  TROParameterResult = reference to procedure(Res : TROParameter);
  TRODateTimeResult = reference to procedure(Res : TJSDate);
  TFloatResult = reference to procedure(Res : Double);

  TDezqParameterService = class {$IFDEF PAS2JS}  external name 'DezqLibrary.ParameterService' {$ENDIF} (TDezqBaseDataService)
  Public
    procedure GetParameter(const aType: String; const aName: String; const aLanguage: Integer; OnSuccess : TROParameterResult; OnFailed: TROFailedEvent);
    procedure GetParameterList(const aType: String; const aLanguage: Integer; OnSuccess : TROParameterArrayResult; OnFailed: TROFailedEvent);
    procedure GetRawParameter(const aType: String; const aName: String; const aLanguage: Integer; OnSuccess : String; OnFailed: TROFailedEvent);
    procedure GetIntegerParameter(const aType: String; const aName: String; OnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    procedure GetStringParameter(const aType: String; const aName: String; const aLanguage: Integer; OnSuccess : TStringResult; OnFailed: TROFailedEvent);
    procedure GetBooleanParameter(const aType: String; const aName: String; OnSuccess : TBoolResult; OnFailed: TROFailedEvent);
    procedure GetDateTimeParameter(const aType: String; const aName: String; OnSuccess : TRODateTimeResult; OnFailed: TROFailedEvent);
    procedure GetURLParameter(const aType: String; const aName: String; OnSuccess : TStringResult; OnFailed: TROFailedEvent);
    procedure GetEmailParameter(const aType: String; const aName: String; OnSuccess : TStringResult; OnFailed: TROFailedEvent);
    procedure GetFloatParameter(const aType: String; const aName: String; OnSuccess : TFloatResult; OnFailed: TROFailedEvent);
    procedure SetStringParameter(const aType: String; const aName: String; const aLanguage: Integer; const aValue: String; OnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    procedure SetIntegerParameter(const aType: String; const aName: String; const aValue: Integer; OnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    procedure SetBooleanParameter(const aType: String; const aName: String; const aValue: Boolean; OnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    procedure SetDateTimeParameter(const aType: String; const aName: String; const aValue: TJSDate; OnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    procedure SetURLParameter(const aType: String; const aName: String; const aValue: String; OnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    procedure SetEmailParameter(const aType: String; const aName: String; const aValue: String; OnSuccess : TVoidResult; OnFailed: TROFailedEvent);
    procedure SetFloatParameter(const aType: String; const aName: String; const aValue: Double; OnSuccess : TVoidResult; OnFailed: TROFailedEvent);
  end;

  // TDezqGetDownloadUrlEvent = Reference to Procedure (aResult : Boolean);

  TDezqContactService = class {$IFDEF PAS2JS} external name 'DezqLibrary.ContactService' {$ENDIF} (TDezqBaseDataService)
    procedure GetContactData(const aContactID: Int64; const Recurse: Boolean; aSuccess: TContactResult; OnFailed: TROFailedEvent);
    procedure GetContactTelecomData(const aContactID: Int64; aSuccess: TContactTelecomArrayResult; OnFailed: TROFailedEvent);
    procedure GetContactAddressData(const aContactID: Int64; aSuccess: TContactAddressArrayResult; OnFailed: TROFailedEvent);
    procedure GetContactsRelatedTo(const aContactID: Int64; const aType: String; const Recurse: Boolean; aSuccess: TRelatedContactArrayResult; OnFailed: TROFailedEvent);
    procedure SimpleSearchContacts(const aSearchTerm: String; const aRecurse: Boolean; const aLastNameInitialOnly: Boolean;  aSuccess: TContactArrayResult; OnFailed: TROFailedEvent);
    procedure SearchContacts(const aSearchTerm: String; const aOptions: TROContactSearchOptions; aSuccess: TContactArrayResult; OnFailed: TROFailedEvent);
    procedure RelateContacts(const aFromContact: Int64; const aToContact: Int64; const aType: String; aSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    procedure UnrelateContacts(const aFromContact: Int64; const aToContact: Int64; const aType: String; aSuccess: TBoolResult; OnFailed: TROFailedEvent);
  end;


  TDezqReportService = class {$IFDEF PAS2JS}  external name 'DezqLibrary.ReportService' {$ENDIF} (TDezqBaseService)
  Public
    Procedure GetReportDesign(const aID: NativeInt; aSuccess : TStringResult; OnFailed: TROFailedEvent);
    Procedure SaveReportDesign(const aID: NativeInt; const aDesign: String;aSuccess : TBoolResult; OnFailed: TROFailedEvent);
    Procedure GetDataModels(aSucces: TROStringArrayResult; OnFailed: TROFailedEvent);
    Procedure GetDataModelDetail(const aName: String;aSuccess : TBoolResult; OnFailed: TROFailedEvent);
    Procedure RunReport(const aID: NativeInt; const aDossierID : NativeInt; const Params: TROReportParameterArray ;aSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure RunReportByName(const AName: String; const aDossierID : NativeInt; aLanguage : string; const Params: TROReportParameterArray ;aSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure GetReportID(const AName: String; aLanguage : string; aSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure CreateDataModel(const aName, aSQL: String; const useDossierDB,useList : Boolean; aStartID : Integer; aSuccess : TStringResult; OnFailed: TROFailedEvent);
  end;

  TROCheckPetitionCopyResultResult = reference to procedure(aResult : TROCheckPetitionCopyResult);
  TROSearchPetitionResultResult = reference to procedure(aResult : TROSearchPetitionResult);
  TROSettlementPlanCreateResultResult = reference to procedure(aResult : TROSettlementPlanCreateResult);

  TCSRService = class {$IFDEF PAS2JS}  external name 'DezqLibrary.CSRService' {$ENDIF} (TDezqBaseDataService)
  Public
    Procedure CheckExistingPetition(const aParams: TROPetitionSearchCriteria; aSuccess : TROSearchPetitionResultResult; OnFailed: TROFailedEvent);
    Procedure CheckCopyPetition(const aPetitionID: NativeInt; aSuccess: TNativeIntResult;OnFailed: TROFailedEvent);
    Procedure GetCheckCopyPetitionToCSRResult(const aJobID: NativeInt; aSuccess : TROCheckPetitionCopyResultResult; OnFailed: TROFailedEvent);
    procedure SetPetitionCopyActions(const ajobID: NativeInt; const aActions: TROSetPetitionCopyActionArray; aSuccess : TVoidResult; OnFailed: TROFailedEvent);
    Procedure GetCopiedCSRID(const aCopyJobID: NativeInt; aSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure CopyPetitionToCSR(const aOptions: TROCopyPetitionToCSROptions; aSuccess: TNativeIntResult;OnFailed: TROFailedEvent);
    Procedure CreateCSR(const aData: TROCSRCreateData; aSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure CopySettlementPlan(const aOptions: TROCopySettlementPlanOptions; aSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure DeleteSettlementPlan(const aSourceID: NativeInt; aSuccess : TBoolResult; OnFailed: TROFailedEvent);
    Procedure CreateSettlementPlan(const aOptions: TROSettlementPlanCreateOptions; aSuccess : TROSettlementPlanCreateResultResult; OnFailed: TROFailedEvent);
    Procedure Certify(aOptions : TROCSRCertifyOptions;aSuccess : TBoolResult; OnFailed: TROFailedEvent);
  end;

  TPontoService = class {$IFDEF PAS2JS}  external name 'DezqLibrary.PontoService' {$ENDIF} (TDezqBaseService)
  Public
    Procedure GetPontoLinkURL(aOnSuccess : TStringResult; OnFailed: TROFailedEvent);
    Procedure GetIsPontoAccountLinked(aCompanyID: NativeInt; aOnSuccess : TBoolResult; OnFailed: TROFailedEvent);
    Procedure GetIsBankAccountLinkedToPonto(aBankaccountID: NativeInt; aOnSuccess : TBoolResult; OnFailed: TROFailedEvent);
    Procedure GetBankAccountPontoInfo(aBankaccountID: NativeInt; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure CheckBankAccountTransactions(const aBankAccountID: NativeInt; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
    Procedure PreparePayments(const aBankAccountID: NativeInt; const aPaymentID: NativeInt; aOnSuccess : TNativeIntResult; OnFailed: TROFailedEvent);
  end;

implementation

{$IFNDEF PAS2JS}

{ TDezqReportService }

procedure TDezqReportService.CreateDataModel(const aName, aSQL: String; const useDossierDB, useList: Boolean; aStartID: Integer;
  aSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqReportService.GetDataModelDetail(const aName: String; aSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqReportService.GetDataModels(aSucces: TROStringArrayResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqReportService.GetReportDesign(const aID: NativeInt; aSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqReportService.RunReport(const aID, aDossierID: NativeInt; const Params: TROReportParameterArray;
  aSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqReportService.SaveReportDesign(const aID: NativeInt; const aDesign: String; aSuccess: TBoolResult;
  OnFailed: TROFailedEvent);
begin

end;

{ TDezqContactService }

procedure TDezqContactService.GetContactAddressData(const aContactID: Int64; aSuccess: TContactAddressArrayResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqContactService.GetContactData(const aContactID: Int64; const Recurse: Boolean; aSuccess: TContactResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqContactService.GetContactsRelatedTo(const aContactID: Int64; const aType: String; const Recurse: Boolean;
  aSuccess: TRelatedContactArrayResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqContactService.GetContactTelecomData(const aContactID: Int64; aSuccess: TContactTelecomArrayResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqContactService.RelateContacts(const aFromContact, aToContact: Int64; const aType: String; aSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqContactService.SearchContacts(const aSearchTerm: String; const aOptions: TContactSearchOptions;
  aSuccess: TContactArrayResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqContactService.SimpleSearchContacts(const aSearchTerm: String; const aRecurse, aLastNameInitialOnly: Boolean;
  aSuccess: TContactArrayResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqContactService.UnrelateContacts(const aFromContact, aToContact: Int64; const aType: String; aSuccess: TBoolResult;
  OnFailed: TROFailedEvent);
begin

end;


{ TROVariantArray }

function TROVariantArray.toObject: TStringDynArray;
begin

end;

{ TROStringArray }

function TROStringArray.toArray: TStringDynArray;
begin

end;

function TROStringArray.toObject: TStringDynArray;
begin

end;

{ TROAccount }

procedure TROAccount.fromObject(aItem: TAccount);
begin

end;

procedure TROAccount.fromObject(aItem: TJSOBject);
begin

end;

constructor TROAccount.new;
begin

end;

function TROAccount.toObject: TAccount;
begin

end;

{ TROPersonal }

procedure TROPersonal.fromObject(aItem: TPersonal);
begin

end;

procedure TROPersonal.fromObject(aItem: TJSOBject);
begin

end;

constructor TROPersonal.new;
begin

end;

function TROPersonal.toObject: TPersonal;
begin

end;

{ TROOffice }

procedure TROOffice.fromObject(aItem: TOffice);
begin

end;

procedure TROOffice.fromObject(aItem: TJSOBject);
begin

end;

constructor TROOffice.new;
begin

end;

function TROOffice.toObject: TOffice;
begin

end;

{ TRORegistration }

procedure TRORegistration.fromObject(aItem: TRegistration);
begin

end;

procedure TRORegistration.fromObject(aItem: TJSOBject);
begin

end;

constructor TRORegistration.new;
begin

end;

function TRORegistration.toObject: TOffice;
begin

end;

{ TRODezqUser }

procedure TRODezqUser.fromObject(aItem: TDezqUser);
begin

end;

procedure TRODezqUser.fromObject(aItem: TJSObject);
begin

end;

constructor TRODezqUser.new;
begin

end;

function TRODezqUser.toObject: TDezqUser;
begin

end;

{ TROPasswordResetRequest }

procedure TROPasswordResetRequest.fromObject(aItem: TPasswordResetRequest);
begin

end;

procedure TROPasswordResetRequest.fromObject(aItem: TJSOBject);
begin

end;

constructor TROPasswordResetRequest.new;
begin

end;

function TROPasswordResetRequest.toObject: TPasswordResetRequest;
begin

end;

{ TRODocument }

procedure TRODocument.fromObject(aItem: TDocument);
begin

end;

procedure TRODocument.fromObject(aItem: TJSOBject);
begin

end;

constructor TRODocument.new;
begin

end;

function TRODocument.toObject: TDocument;
begin

end;

{ TROCodaImportStatus }

procedure TROCodaImportStatus.fromObject(aItem: TCodaImportStatus);
begin

end;

procedure TROCodaImportStatus.fromObject(aItem: TJSOBject);
begin

end;

constructor TROCodaImportStatus.new;
begin

end;

function TROCodaImportStatus.toObject: TCodaImportStatus;
begin

end;

{ TROPetitionCloseData }

procedure TROPetitionCloseData.fromObject(aItem: TPetitionCloseData);
begin

end;

procedure TROPetitionCloseData.fromObject(aItem: TJSOBject);
begin

end;

constructor TROPetitionCloseData.new;
begin

end;

function TROPetitionCloseData.toObject: TPetitionCloseData;
begin

end;

{ TROCreatePetition }

procedure TROCreatePetition.fromObject(aItem: TCreatePetition);
begin

end;

procedure TROCreatePetition.fromObject(aItem: TJSOBject);
begin

end;

constructor TROCreatePetition.new;
begin

end;

function TROCreatePetition.toObject: TCreatePetition;
begin

end;

{ TROCountry }

procedure TROCountry.fromObject(aItem: TCountry);
begin

end;

procedure TROCountry.fromObject(aItem: TJSOBject);
begin

end;

constructor TROCountry.new;
begin

end;

function TROCountry.toObject: TCountry;
begin

end;

{ TROCity }

procedure TROCity.fromObject(aItem: TCity);
begin

end;

procedure TROCity.fromObject(aItem: TJSOBject);
begin

end;

constructor TROCity.new;
begin

end;

function TROCity.toObject: TCity;
begin

end;

{ TROParameterArray }

function TROParameterArray.toObject: TParameterDynArray;
begin

end;

{ TROReportParameterArray }

procedure TROReportParameterArray.fromArray(aItem: TReportParameterDynArray);
begin

end;

function TROReportParameterArray.toObject: TReportParameterDynArray;
begin

end;

{ TROReportParameter }

procedure TROReportParameter.fromObject(aItem: TReportParameter);
begin

end;

procedure TROReportParameter.fromObject(aItem: TJSOBject);
begin

end;

function TROReportParameter.toObject: TReportParameter;
begin

end;

{ TROParameter }

procedure TROParameter.fromObject(aItem: TParameter);
begin

end;

procedure TROParameter.fromObject(aItem: TJSOBject);
begin

end;

constructor TROParameter.new;
begin

end;

function TROParameter.toObject: TParameter;
begin

end;

{ TROContactTelecom }

procedure TROContactTelecom.fromObject(aItem: TContactTelecom);
begin

end;

procedure TROContactTelecom.fromObject(aItem: TJSOBject);
begin

end;

constructor TROContactTelecom.new;
begin

end;

function TROContactTelecom.toObject: TContactTelecom;
begin

end;

{ TROContactTelecomArray }

function TROContactTelecomArray.toObject: TContactTelecomArray;
begin

end;

{ TROContactAddress }

procedure TROContactAddress.fromObject(aItem: TContactAddress);
begin

end;

procedure TROContactAddress.fromObject(aItem: TJSOBject);
begin

end;

constructor TROContactAddress.new;
begin

end;

function TROContactAddress.toObject: TContactAddress;
begin

end;

{ TROContactAddressArray }

function TROContactAddressArray.toObject: TContactAddressArray;
begin

end;

{ TROContact }

procedure TROContact.fromObject(aItem: TContact);
begin

end;

procedure TROContact.fromObject(aItem: TJSOBject);
begin

end;

constructor TROContact.new;
begin

end;

function TROContact.toObject: TContact;
begin

end;

{ TROContactArray }

function TROContactArray.toObject: TContactArray;
begin

end;

{ TRORelatedContact }

procedure TRORelatedContact.fromObject(aItem: TContact);
begin

end;

procedure TRORelatedContact.fromObject(aItem: TJSOBject);
begin

end;

constructor TRORelatedContact.new;
begin

end;

function TRORelatedContact.toObject: TContact;
begin

end;

{ TRORelatedContactArray }

function TRORelatedContactArray.toObject: TRelatedContactArray;
begin

end;

{ TROContactSearchOptions }

procedure TROContactSearchOptions.fromObject(aItem: TContactSearchOptions);
begin

end;

procedure TROContactSearchOptions.fromObject(aItem: TJSOBject);
begin

end;

constructor TROContactSearchOptions.new;
begin

end;

function TROContactSearchOptions.toObject: TContactSearchOptions;
begin

end;

{ TRODossierCheck }

procedure TRODossierCheck.fromObject(aItem: TDossierCheck);
begin

end;

procedure TRODossierCheck.fromObject(aItem: TJSOBject);
begin

end;

constructor TRODossierCheck.new;
begin

end;

function TRODossierCheck.toObject: TAccount;
begin

end;

{ TRODossierCheckArray }

function TRODossierCheckArray.toObject: TDossierCheckArray;
begin

end;

{ TROProfileData }

procedure TROProfileData.fromObject(aItem: TProfileData);
begin

end;

procedure TROProfileData.fromObject(aItem: TJSOBject);
begin

end;

constructor TROProfileData.new;
begin

end;

function TROProfileData.toObject: TProfileData;
begin

end;

{ TDezqBaseService }

procedure TDezqBaseService.Ping(OnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqBaseDataService }

procedure TDezqBaseDataService.Ping(OnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqLoginService }

procedure TDezqLoginService.DezqLogin(aUserName, aPassword, aOffice: String; OnSuccess: TDezqLoginSuccessEvent;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqLoginService.GetLoginRoles(OnSuccess: TROStringArrayResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqLoginService.GetProfileData(OnSuccess: TProfileDataResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqLoginService.GetSessionUserInfo(OnSuccess: TDezqLoginSuccessEvent; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqLoginService.RequestPasswordReset(aRequest: TROPasswordResetRequest; OnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqLoginService.SetPassword(const aKey, aNewPassword: String; UseOldPassword: Boolean; OnSuccess: TStringResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqLoginService.SetProfileData(aProfile: TROProfileData; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqNumberService }

procedure TDezqNumberService.IsValidIBAN(aIBAN: String; OnSuccess: TDezqIsValidIBANEvent; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqNumberService.IsValidNIDNR(aIDNR, aGender: String; aDateTime: TJSDate; OnSuccess: TBoolResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqNumberService.IsValidPhoneNumber(aPhoneNumber: String; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqNumberService.IsValidVAT(aCountry, aVATNr: String; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqRegisterService }

procedure TDezqRegisterService.Signon(aRegistration: TRORegistration; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqDocumentService }

procedure TDezqDocumentService.CreateNewDocument(DossierID: NativeInt; SourceType: integer; SourceID: NativeInt; DocumentType,
  AFileName: string; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqDocumentService.DocumentDelete(aDossierFileID: NativeInt; aOnSuccess: TVoidResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqDocumentService.DocumentUploadDone(aDossierFileID: NativeInt; aOnSuccess: TVoidResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqDocumentService.DocumentUploadInit(aDossierFileID: NativeInt; aOnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqDocumentService.GetDownloadUrl(aDossierFileId: NativeInt; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqCompanyService }

procedure TDezqCompanyService.ApproveSignon(aID: NativeInt; aApprove: Boolean; aOnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqCompanyService.CheckEmailAndUserName(const aLoginID: NativeInt; const aUserName, aEmail: String;
  aOnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqCompanyService.DeActivate(aCompanyID: NativeInt; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqCompanyService.MakeReadOnly(aCompanyID: NativeInt; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqCompanyService.SignOn(aRegistration: TRORegistration; aOnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqPetitionService }

procedure TDezqPetitionService.ArchivePetition(aPetition: TROPetitionCloseData; aOnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqPetitionService.CheckComplete(aPetitionID: NativeInt; aOnSuccess: TDossierCheckArrayResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqPetitionService.CheckLegalReference(aDossier: NativeInt; aReference: String; aLecID: NativeInt;
  aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqPetitionService.CreatePetition(const aPetitionData: TROCreatePetition; aOnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqPetitionService.FastAddPossessions(aDossierID: NativeInt; aOwner: String; aPossessions: TROStringArray;
  OnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqPetitionService.GetDossierContact(aDossierID: NativeInt; aContactType: String; aRecurse: Boolean;
  aOnSuccess: TContactArrayResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqPetitionService.GetPetitionStatus(aDossierID: NativeInt; aOnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqPetitionService.ReOpenPetition(aCompanyID, aPetitionID: NativeInt; aReason: String; aOnSuccess: TVoidResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqPetitionService.SubmitPetition(aPetitionID: NativeInt; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqJobService }

procedure TDezqJobService.CancelJob(aJobID: NativeInt; aReason: String; aOnSuccess: TVoidResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqJobService.GetJobStatus(aJobID: NativeInt; aOnSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqMailService }

procedure TDezqMailService.SendMail(aAddress: String; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqCodaService }

procedure TDezqCodaService.GetCodaImportStatus(aJobID: NativeInt; OnSuccess: TCodaImportStatusResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqCodaService.ImportCodaFile(aFileName, aFileContents: String; aInitStart: Boolean; OnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

{ TDezqGeoService }

procedure TDezqGeoService.GetCityDataFromZip(aCountryISO, aCityZip: String; OnSuccess: TCityResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqGreCaptchaService }

procedure TDezqGreCaptchaService.GetCityDataFromZip(aCaptchaData: String; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

{ TDezqParameterService }

procedure TDezqParameterService.GetBooleanParameter(const aType, aName: String; OnSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetDateTimeParameter(const aType, aName: String; OnSuccess: TRODateTimeResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetEmailParameter(const aType, aName: String; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetFloatParameter(const aType, aName: String; OnSuccess: TFloatResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetIntegerParameter(const aType, aName: String; OnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetParameter(const aType, aName: String; const aLanguage: Integer; OnSuccess: TROParameterResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetParameterList(const aType: String; const aLanguage: Integer; OnSuccess: TROParameterArrayResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetRawParameter(const aType, aName: String; const aLanguage: Integer; OnSuccess: String;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetStringParameter(const aType, aName: String; const aLanguage: Integer; OnSuccess: TStringResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.GetURLParameter(const aType, aName: String; OnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.SetBooleanParameter(const aType, aName: String; const aValue: Boolean; OnSuccess: TVoidResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.SetDateTimeParameter(const aType, aName: String; const aValue: TJSDate; OnSuccess: TVoidResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.SetEmailParameter(const aType, aName, aValue: String; OnSuccess: TVoidResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.SetFloatParameter(const aType, aName: String; const aValue: Double; OnSuccess: TVoidResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.SetIntegerParameter(const aType, aName: String; const aValue: Integer; OnSuccess: TVoidResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.SetStringParameter(const aType, aName: String; const aLanguage: Integer; const aValue: String;
  OnSuccess: TVoidResult; OnFailed: TROFailedEvent);
begin

end;

procedure TDezqParameterService.SetURLParameter(const aType, aName, aValue: String; OnSuccess: TVoidResult;
  OnFailed: TROFailedEvent);
begin

end;

{ TPontoService }

procedure TPontoService.CheckBankAccountTransactions(const aBankAccountID: NativeInt; aOnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TPontoService.GetBankAccountPontoInfo(aBankaccountID: NativeInt; aOnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TPontoService.GetPontoLinkURL(aOnSuccess: TStringResult; OnFailed: TROFailedEvent);
begin

end;

procedure TPontoService.GetIsBankAccountLinkedToPonto(aBankaccountID: NativeInt;
  aOnSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

procedure TPontoService.GetIsPontoAccountLinked(aCompanyID: NativeInt; aOnSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

procedure TPontoService.PreparePayments(const aBankAccountID, aPaymentID: NativeInt; aOnSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

{ TCSRService }

procedure TCSRService.CheckCopyPetition(const aPetitionID: NativeInt; aSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.CheckExistingPetition(const aParams: TROPetitionSearchCriteria; aSuccess: TCSRSearchPetitionResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.CopyPetitionToCSR(const aOptions: TROCopyPetitionToCSROptions; aSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.CopySettlementPlan(const aOptions: TROCopySettlementPlanOptions; aSuccess: TNativeIntResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.CreateCSR(const aData: TROCSRCreateData; aSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.CreateSettlementPlan(const aOptions: TROSettlementPlanCreateOptions;
  aSuccess: TROSettlementPlanCreateResultResult; OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.DeleteSettlementPlan(const aSourceID: NativeInt; aSuccess: TBoolResult; OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.GetCheckCopyPetitionToCSRResult(const aJobID: NativeInt; aSuccess: TROCheckPetitionCopyResultResult;
  OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.GetCopiedCSRID(const aCopyJobID: NativeInt; aSuccess: TNativeIntResult; OnFailed: TROFailedEvent);
begin

end;

procedure TCSRService.SetPetitionCopyActions(const ajobID: NativeInt; const aActions: TROSetPetitionCopyActionArray;
  aSuccess: TVoidResult; OnFailed: TROFailedEvent);
begin

end;


{$ENDIF}


end.
