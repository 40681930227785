unit Units.DocumentBox;

interface

uses
  Classes,
  pas2web.dadataset,
  Units.Params,
  Data.DB,
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  Modules.Server,
  Units.ConfirmDialog;

type
  TDocumentUploadStarted = Reference to procedure(const xhr: TJSXMLHttpRequest; refId: string);
  TDocumentUploadCompleted = Reference to procedure(const aResult, isCancelled: Boolean; RecordID: Int64; refId: string);
  TUploadProgressEvent = Reference to procedure(const aSize, aCompleted: Int64; refId: string);

  TFileRecord = record
    aFile: TJSObject;
    DocType: string;
    SourceType: Integer;
    refId: string;
    isCancelled: Boolean;
    isUploading: Boolean;
    uploadedSize: Int64;
    xhr: TJSXMLHttpRequest;
  end;

  TFileQueue = array of TFileRecord;

  TDocumentBox = class(TComponent)
  private
    FActiveUploadingFileCount: Integer;
    FFileQueue: TFileQueue;
    FMultiDocBox: Boolean;
    FDocumentType: string;
    FSourceType: Integer;
    FSourceTypeID: Int64;
    FDocDossierID: Int64;
    FFormIsReadOnly: Boolean;
    FConfirm: TConfirmDialog;
    FFileBase64: string;
    FNeedBase64: Boolean;
    FAcceptFileType: string;
    function OnFileDeleteClicked(Event: TEventListenerEvent): Boolean;
    function OnFileUpdateClicked(Evt: TEventListenerEvent): Boolean;
    function OnFileDownloadClicked(Evt: TEventListenerEvent): Boolean;

    function makeId(len: Integer): string;
    function getErrorMsg(error: string): string;
    function OnFileAdded(Evt: TEventListenerEvent; Data: JSValue): Boolean;
    function OnFileUploadProcessAlways(Evt: TEventListenerEvent; Data: JSValue): Boolean;

    function OnFileUploadAlways(Evt: TEventListenerEvent; Data: JSValue): Boolean;

    function OnFileDrop(Evt: TEventListenerEvent; Data: JSValue): Boolean;
    function OnFileDragOver(Evt: TEventListenerEvent; Data: JSValue): Boolean;
    function OnFileDragLeave(Evt: TEventListenerEvent; Data: JSValue): Boolean;

    function OnFileUploadClick(Evt: TEventListenerEvent): Boolean;
    function DoRemoveItem(Evt: TEventListenerEvent): Boolean;
    function isFileCancelled(const refId: string): Boolean;
  public
    constructor create(aOwner: TComponent); override;
    destructor destroy; override;
    // Remove file from upload queue.
    procedure removeFromQueue(refId: string);
    procedure SaveFile(aFileObj: TJSObject; aPetitionID, SourceID, aFileID: Int64; SourceType: Integer; DocumentType: string; callback: TDocumentUploadCompleted; indexRef: string = '';
      progressEvent: TUploadProgressEvent = nil; OnUploadStarted: TDocumentUploadStarted = nil);

    procedure updateFileItem(const uploadSucceded, isCancelled: Boolean; refId: string; fileID: Int64; fileName: string);
    procedure resetFileUploadProgress(const elementId: string);
    procedure createFileList(const DataSet: TDataSet; listElementId: string; hideRemoveButton: Boolean);
    procedure LoadDocuments(dsDocuments: TP2WDADataset; petitionID, srcid: Int64; listHolder: string; hideRemoveButton: Boolean = false);
    // file management related procedures and functions
    function inFileQueue(refId: string): Boolean; //
    procedure AddFileToQueue(const aFile: TJSObject; DocType: string; SourceType: Integer; refId: string);
    function GetAwaitingUploadCount: Integer;
    function GetFileQueueItem(const index: Integer): TFileRecord;
    function UpdateFilesUploadSize(const refId: string; uploadedSize: Int64): Int64;
    procedure SetFilesUploadHandler(const refId: string; xhr: TJSXMLHttpRequest);
    function GetProcessingFilesSize: Int64;
    function GetProcessingFilesProgress: Int64;
    procedure AbortFileUpload(const refId: string);

    procedure OnFileUploadProgress(const aSize, aCompleted: Int64; refId: string);
    procedure DoFileQueueUpload(aRecordID: Int64; returnURL: string; callback: TEmptyCallback = nil);
    procedure setDocBoxParams(const aSourceType: Integer; aSourceTypeID: Int64; aDocumentType: string; aDossierID: Int64);

    procedure AttachmentHandler(uploadElement: TJSObject);

    property MultiDocBox: Boolean read FMultiDocBox write FMultiDocBox;
    property FormIsReadOnly: Boolean read FFormIsReadOnly write FFormIsReadOnly;
    property NeedBase64: Boolean read FNeedBase64 write FNeedBase64;
    property FileBase64: string read FFileBase64;
  end;

const
  cMaxNumberOfFiles = 'Maximum number of files exceeded';
  cAcceptFileTypes = 'File type not allowed';
  cMaxFileSize = 'File is too large';
  cMinFileSize = 'File is too small';

implementation

uses
  Units.Strings,
  Units.Logging,
  libjquery,
  lib.bootstrap,
  lib.filereader,
  lib.jqueryhelpers;


constructor TDocumentBox.create(aOwner: TComponent);
var
  uploadOptions: TJSObject;
  reg: TJSRegexp;
  element: TJSObject;
begin
  inherited;

  FActiveUploadingFileCount := 0;
  SetLength(FFileQueue, 0);
  FSourceType := -1;
  FSourceTypeID := -1;
  FDocumentType := '';
  FDocDossierID := -1;
  FMultiDocBox := True;

  FAcceptFileType := 'pdf';

  reg := TJSRegexp.new('(\.|\/)(pdf)$', 'i');
  uploadOptions := TJSObject.new;
  uploadOptions.Properties['url'] := '';
  uploadOptions.Properties['dropZone'] := document.getElementById('dropzone'); // null;
  uploadOptions.Properties['dataType'] := 'json';
  uploadOptions.Properties['autoUpload'] := false;
  uploadOptions.Properties['acceptFileTypes'] := reg;
  uploadOptions.Properties['maxFileSize'] := 100000000;
  uploadOptions.Properties['disableImageResize'] := TJSRegexp.new('Android(?!.*Chrome)|Opera', 'i').test(window.navigator.userAgent);
  uploadOptions.Properties['previewMaxWidth'] := 32;
  uploadOptions.Properties['previewMaxHeight'] := 32;
  uploadOptions.Properties['previewCrop'] := false;

  jquery('#fileupload-customInput, #fileupload-btn, #fileupload-dropzone, .fileupload-dropzone').fileupload(uploadOptions);

//  jquery('#fileupload-dropzone').fileupload('option', 'dropZone', jquery('#dropzone'));

  element := TJSObject(jquery('#fileupload-customInput, #fileupload-btn, #fileupload-dropzone, .fileupload-dropzone'));
  AttachmentHandler(element);

  FConfirm := TConfirmDialog.create(Self);
  FFileBase64 := '';
  FNeedBase64 := false;
end;

procedure TDocumentBox.createFileList(const DataSet: TDataSet; listElementId: string; hideRemoveButton: Boolean);
var
  html, disabled: string;
  i: Integer;
begin
  Console.Log('createFileList');
  if FormIsReadOnly then
    disabled := 'disabled=""'
  else
    disabled := '';

  if not DataSet.isEmpty then
  begin
    html := '';
    i := 1;
    while not DataSet.Eof do
    begin
      html := html + '<div class="list-group-item"> ' +
                     '  <div class="list-group-item-figure">' +
                     '    <div class="tile bg-success"><i class="far fa-file-alt"></i> ' +
                     '    </div> ' +
                     '  </div>' +
                     '  <div class="list-group-item-body"> ' +
                     '    <div class="media align-items-center">' +
                     '      <div class="media-body">' +
                     '         <a href="javascript: void(0);" role="download" file-id="' + IntToStr(DataSet.FieldByName('dofid').asLargeInt) + '">' + DataSet.FieldByName('doffilename').asString + '</a>' +
                     '      </div>' +
                     '      <div class="media-actions">' +
                     '        <button role="updateFile" file-id="' + IntToStr(DataSet.FieldByName('dofid').asLargeInt) + '" ' +
                     '           class="btn btn-sm btn-secondary up mr-2" id="upd_' + IntToStr(i) +
                     '" idx="' + IntToStr(i) + '" ' + disabled + '>' +
                     '          <i class="fas fa-cloud-upload-alt"></i>' +
                     '        </button>';

      if not hideRemoveButton then
        html := html + '        <button role="delete" file-id="' + IntToStr(DataSet.FieldByName('dofid').asLargeInt) + '" ' +
                       '           class="btn btn-sm btn-secondary" idx="' + IntToStr(i) + '" id="btn_' + IntToStr(i) + '"  ' + disabled + '>' +
                       '           <i class="far fa-trash-alt"></i>' +
                       '        </button> ';

      html := html + '</div> ' +
                     '    </div> ' +
                     '  </div> ' +
                     '</div>';
      DataSet.Next;
      inc(i);
    end;

    jquery('#' + listElementId).html(html);
    // bind events

    jquery('#' + listElementId).find('button[role="updateFile"]').On_('click', @OnFileUpdateClicked);
    jquery('#' + listElementId).find('button[role="delete"]').On_('click', @OnFileDeleteClicked);
    jquery('#' + listElementId).find('a[role="download"]').On_('click', @OnFileDownloadClicked);
  end;
end;

destructor TDocumentBox.destroy;
begin
  FreeAndNil(FConfirm);
  inherited;
end;

procedure TDocumentBox.DoFileQueueUpload(aRecordID: Int64; returnURL: string; callback: TEmptyCallback);
var
  i: Integer;
  item: TFileRecord;

  procedure OnItemUploadCompleted(const aResult, isCancelled: Boolean; RecordID: Int64; refId: string);
  begin
    if aResult then
      Dec(FActiveUploadingFileCount);
    updateFileItem(aResult, isCancelled, refId, RecordID, '');
    if FActiveUploadingFileCount = 0 then
    begin
      if returnURL <> '' then
        window.location.href := returnURL;
      if Assigned(callback) then
        callback;
    end;
  end;

  procedure OnBeforeUploadStart(const xhr: TJSXMLHttpRequest; refId: string);
  begin
    SetFilesUploadHandler(refId, xhr);
  end;

begin
  Console.Log('DoFileQueueuUpload');
  FActiveUploadingFileCount := GetAwaitingUploadCount;
  for i := FActiveUploadingFileCount - 1 downto 0 do
  begin
    item := GetFileQueueItem(i);
    SaveFile(item.aFile, FDocDossierID, aRecordID, -1, item.SourceType, item.DocType, @OnItemUploadCompleted, item.refId, @OnFileUploadProgress, @OnBeforeUploadStart);
  end;
end;

procedure TDocumentBox.LoadDocuments(dsDocuments: TP2WDADataset; petitionID, srcid: Int64; listHolder: string; hideRemoveButton: Boolean);

  procedure LoadList(DataSet: TDataSet);
  begin
    createFileList(DataSet, listHolder, hideRemoveButton);
  end;

begin
  Console.Log('LoadDocuments');
  Server.PetitionConnection.Message.ClientID := Server.ClientID;

  dsDocuments.DAConnection := Server.PetitionConnection;
  dsDocuments.AfterOpen := LoadList;
  dsDocuments.ParamByName('DOSSIERID').asLargeInt := petitionID;
  dsDocuments.ParamByName('SRCID').asLargeInt := srcid;
  dsDocuments.Load([], nil);
end;

function TDocumentBox.makeId(len: Integer): string;
const
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
var
  i: Integer;
begin
  Result := '';
  for i := 1 to len do
    Result := Result + characters[Random(Length(characters)) + 1];
end;

function TDocumentBox.OnFileUploadProcessAlways(Evt: TEventListenerEvent; Data: JSValue): Boolean;
var
  sID, sRemoveID: string;
  iIdx: Integer;
  aFile: TJSObject;
  sImg: string;
  aReader: TJSFileReader;

  procedure SetImg;
  begin
    sImg := '<div class="list-group-item-figure">' + sImg + '</div>';
    jquery('div.list-group-item[grp-id="' + sID + '"]').html(sImg + jquery('div.list-group-item[grp-id="' + sID + '"]').html);
  end;

  function AfterFileLoaded(Event: TEventListenerEvent): Boolean;
  var
    parent: TJQuery;
  begin
    FFileBase64 := window.btoa(string(aReader.Result));

    parent := jquery('div.list-group-item[grp-id="' + sID + '"]').find('button.up').parent;
    jquery('div.list-group-item[grp-id="' + sID + '"]').find('button.up').remove();
    parent.html('<button class="btn btn-sm btn-secondary rm"><i class="far fa-trash-alt"></i></button>');
    parent.find('button').On_('click', @DoRemoveItem);
    Result := True;
  end;

begin
  Console.Log('OnFileUploadProcessAlways');

  sID := string(TJSObject(Data).Properties['grp-id']);
  iIdx := StrToIntDef(string(TJSObject(Data).Properties['index']), 0);
  aFile := TJSObject(TJSArray(TJSObject(Data).Properties['files']).Elements[iIdx]);

  sImg := '<div class="tile bg-success"><i class="far fa-file-alt"></i></div>';

  if (iIdx + 1 = TJSArray(TJSObject(Data).Properties['files']).Length) then
  begin
    if TJSObject(TJSObject(Data).Properties['files']).Properties['error'] = undefined then
    begin
      jquery('div[grp-id="' + sID + '"]').find('button').html('<i class="fas fa-cloud-upload-alt"></i>')
        .prop('disabled', (TJSObject(TJSObject(Data).Properties['files']).Properties['error'] <> undefined) and (TJSObject(TJSObject(Data).Properties['files']).Properties['error'] <> ''));

      SetImg;

      if FNeedBase64 then
      begin
        aReader := TJSFileReader.new;
        aReader.onLoad := @AfterFileLoaded;
        aReader.readAsBinaryString(aFile);
      end;

      jquery('div.list-group-item[grp-id="' + sID + '"]').find('button.up').Data('afile', aFile).On_('click', @OnFileUploadClick).trigger('click');
    end
    else
    begin
      sImg := '<div class="tile bg-danger"><i class="far fa-minus-square"></i></div>';
      sRemoveID := makeId(10);
      jquery('div.list-group-item[grp-id="' + sID + '"]')
        .find('.media-actions')
        .html('<button class="btn btn-sm btn-secondary rm" data-id="' + sRemoveID + '">' + '<i class="far fa-trash-alt"></i></button>');

      SetImg;

      jquery('div.list-group-item[grp-id="' + sID + '"]')
        .find('div.list-group-item-body')
        .html(jquery('div.list-group-item[grp-id="' + sID + '"]').find('div.list-group-item-body')
        .html + '<p class="list-group-item-text text-red">' + getErrorMsg(string(aFile.Properties['error'])) + '</p>');

      jquery('button[data-id="' + sRemoveID + '"]').On_('click', @DoRemoveItem);
    end;
  end;
  Result := True;
end;

procedure TDocumentBox.AttachmentHandler(uploadElement: TJSObject);

  function OnFileUploadProgressAll(Evt: TEventListenerEvent; Data: JSValue): Boolean;
  var
    progress: Integer;
  begin
    Console.Log('OnFileUploadProgressAll');
    progress := Trunc((StrToFloatDef(string(TJSObject(Data).Properties['loaded']), 0) / StrToFloatDef(string(TJSObject(Data).Properties['total']), 1)) * 100);
    jquery('#progress').addClass('show').children().css('width', IntToStr(progress) + '%');
    Result := True;
  end;

  function OnFileUploadSubmit(Evt: TEventListenerEvent; Data: JSValue): Boolean;
  begin
    Console.Log('OnFileUploadSubmit');
    Result := True;
  end;

begin
  jquery(uploadElement).OnWithData('fileuploadadd', @OnFileAdded)
                       .OnWithData('fileuploadprocessalways', @OnFileUploadProcessAlways)
                       .OnWithData('fileuploadalways', @OnFileUploadAlways)
                       .OnWithData('fileuploadprogressall', @OnFileUploadProgressAll)
                       .OnWithData('fileuploadsubmit', @OnFileUploadSubmit)
                       .OnWithData('fileuploaddrop', @OnFileDrop)
                       .OnWithData('dragover', @OnFileDragOver)
                       .OnWithData('dragleave', @OnFileDragLeave);
end;

function TDocumentBox.OnFileDrop(Evt: TEventListenerEvent;
  Data: JSValue): Boolean;
begin
  Console.Log('OnFileDrop');
  jquery('#dropzone').removeClass('hover');

  Result := True; // is nodig anders doet ie geen add
end;

function TDocumentBox.OnFileAdded(Evt: TEventListenerEvent; Data: JSValue): Boolean;
var
  sID: string;
  sHTML: string;
  iMaxBtnIdx: Integer;
  iCurBtnIdx: Integer;
  iIdx: Integer;
  aFile: TJSObject;
  sName: string;
begin
  Console.Log('OnFileAdded');
  Console.Log(Data);

  iMaxBtnIdx := 0;

  for iIdx := 0 to TJSArray(jquery('button[idx]')).Length - 1 do
  begin
    iCurBtnIdx := StrToIntDef(jquery(TJSElement(TJSArray(jquery('button[idx]')).Elements[iIdx])).attr('idx'), -1);
    if iCurBtnIdx > iMaxBtnIdx then
      iMaxBtnIdx := iCurBtnIdx;
  end;

  for iIdx := 0 to TJSArray(TJSObject(Data).Properties['files']).Length - 1 do
  begin
    sID := makeId(10);

    aFile := TJSObject(TJSArray(TJSObject(Data).Properties['files']).Elements[iIdx]);
    sName := string(aFile.Properties['name']);

    sHTML := '<div class="list-group-item" grp-id="' + sID + '">' +
             '  <div class="list-group-item-body">' +
             '    <div class="media align-items-center">' +
             '      <div class="media-body">' + sName +
             '      </div>' +
             '      <div class="media-actions">' +
             '        <button class="btn btn-sm btn-secondary up" disabled idx="' + IntToStr(iMaxBtnIdx + iIdx + 1) + '">' + SProcessing +
             '        </button>' +
             '      </div>' +
             '    </div>' +
             '  </div>' +
             '</div>';

    TJSObject(Data).Properties['grp-id'] := sID;
    jquery('#' + jquery(Evt.target).attr('data-upload-list')).prepend(sHTML);
  end;

  Result := True; // anders gaat ie nie verder
end;

function TDocumentBox.OnFileUploadAlways(Evt: TEventListenerEvent;
  Data: JSValue): Boolean;
begin
  Console.Log('OnFileUploadAlways');
end;

function TDocumentBox.OnFileUploadClick(Evt: TEventListenerEvent): Boolean;
var
  aFile: TJSObject;
  index: string;
  fileName: string;
  DocumentType: string;
  fileID: Integer;

  procedure OnUploadCompleted(const aResult, isCancelled: Boolean; RecordID: Int64; refId: string);
  begin
    Dec(FActiveUploadingFileCount);
    updateFileItem(aResult, isCancelled, refId, RecordID, fileName);
    if FActiveUploadingFileCount = 0 then
      resetFileUploadProgress(jquery('button[idx="' + refId + '"]').closest('div[class*="card"').find('div[class*="progress"]').attr('id'));
  end;

  procedure OnBeforeUploadStart(const xhr: TJSXMLHttpRequest; refId: string);
  begin
    SetFilesUploadHandler(refId, xhr);
  end;

  function OnCancelUpload(e: TEventListenerEvent): Boolean;
  begin
    AbortFileUpload(index);
    Dec(FActiveUploadingFileCount);
    removeFromQueue(index);
    jquery('button.up[idx="' + index + '"]').off('click').html('<i class="fas fa-cloud-upload-alt"></i>').On_('click', @OnFileUploadClick);
    jquery('button.up[idx="' + index + '"]').closest('.list-group-item-body').find('p').remove();
    Result := True;
  end;

begin
  Console.Log('OnFileUploadClick');
  if LowerCase(string(Evt.target.Properties['nodeName'])) = 'button' then
  begin
    aFile := jquery(Evt.target).Data('afile');
    index := jquery(Evt.target).attr('idx');
  end
  else
  begin
    aFile := jquery(Evt.target).closest('button').Data('afile');
    index := jquery(Evt.target).closest('button').attr('idx');
  end;
  fileID := -1;
  if jquery('button[idx="' + index + '"]').attr('file-id') <> undefined then
    fileID := StrToIntDef(jquery('button[idx="' + index + '"]').attr('file-id'), -1);

  fileName := string(aFile.Properties['name']);

  jquery('button.up[idx="' + index + '"]').off('click').html('<i class="far fa-trash-alt"></i>').On_('click', @OnCancelUpload);

  {if FMultiDocBox then
   begin

   documentType := JQuery('button[idx="' + index + '"]').closest('div[class*="card"').find('div[class*="fileinput-dropzone"]').attr('data-doctype');

   if documentType = undefined then
   raise Exception.Create('Error: data-doctype attribute is not set for document box');
   end
   else
   documentType := FDocumentType;}

  DocumentType := jquery('button[idx="' + index + '"]').closest('div[class*="card"').find('div[class*="fileinput-dropzone"]').attr('data-doctype');

  if DocumentType = undefined then
    DocumentType := FDocumentType;

  if not inFileQueue(index) then
  begin

//    if FSourceTypeID < 0 then
//    begin
      // just place everything in queue
      AddFileToQueue(aFile, DocumentType, FSourceType, index);
      jquery('button[idx="' + index + '"]').closest('.list-group-item-body').find('p').remove();
      jquery('button[idx="' + index + '"]').closest('.list-group-item-body').html(jquery('button[idx="' + index + '"]').closest('.list-group-item-body').html() +
        '<p class="list-group-item-text text-blue">' + SFileEnqueued + '</p>');
      jquery('button[idx="' + index + '"]').Data('afile', aFile).On_('click', @OnCancelUpload);
//    end
//    else
//    begin
//      if FActiveUploadingFileCount = 0 then
//        resetFileUploadProgress(jquery('button[idx="' + index + '"]').closest('div[class*="card"').find('div[class*="progress"]').attr('id'));
//
//      inc(FActiveUploadingFileCount);
//
//      // add file to queue anyway its will be marked as completed
//      AddFileToQueue(aFile, DocumentType, FSourceType, index);
//
//      SaveFile(aFile, FDocDossierID, FSourceTypeID, fileID, FSourceType, DocumentType, @OnUploadCompleted, index, @OnFileUploadProgress, @OnBeforeUploadStart);
//    end;
  end;

  Result := True;
end;

function TDocumentBox.DoRemoveItem(Evt: TEventListenerEvent): Boolean;
begin
  jquery(Evt.target).parents('.list-group-item').remove();
  if FNeedBase64 or not FMultiDocBox then
    jquery('.fileupload-dropzone').prop('disabled', false).css('cursor', '');
  Result := True;
end;

function TDocumentBox.OnFileDeleteClicked(Event: TEventListenerEvent): Boolean;
var
  aFileID: Int64;

  procedure OnFileDeleteCallback(aResult: Boolean; error: string);
  begin
    if aResult then
    begin
      jquery('button[file-id="' + IntToStr(aFileID) + '"]').closest('.list-group-item').remove();
    end
    else
      dmServer.ShowError(error);
  end;

  procedure DoConfirm;
  begin
    if aFileID > 0 then
      Server.DeleteDocument(aFileID, @OnFileDeleteCallback)
    else
      OnFileDeleteCallback(True, '');
    Result := false;
  end;

  procedure showConfirmationMessage(const aMessage: string);
  begin
    FConfirm.Execute(aMessage, @DoConfirm);
  end;

var
  Msg: string;
begin
  Console.Log('OnFileDeleteClicked');
  if jquery(Event.target).attr('file-id') <> undefined then
    aFileID := StrToIntDef(jquery(Event.target).attr('file-id'), -1)
  else
    aFileID := StrToIntDef(jquery(Event.target).parent().attr('file-id'), -1);
  // FN:=JQuery(Event.target).closest('.list-group-item-body').find('.media-body').text();
  Msg := comDeleteMessage;
  showConfirmationMessage(Msg);
  Result := false;
end;

function TDocumentBox.OnFileDownloadClicked(Evt: TEventListenerEvent): Boolean;
var
  fileID: Int64;
  fileName: string;

  procedure downloadFile(filePath: string);
  var
    link: TJSHTMLElement;
  begin
    link := TJSHTMLElement(document.createElement('a'));
    link['href'] := filePath;
    link['download'] := fileName;
    link['target'] := '_blank';
    link.click();
  end;

  procedure onGetUrlResult(aResult: Boolean; aURL: string);
  begin
    if aResult then
      downloadFile(aURL);
  end;

begin
  Console.Log('OnFileDownloadClicked');
  fileID := StrToIntDef(jquery(Evt.target).attr('file-id'), -1);
  fileName := jquery(Evt.target).text();
  jquery(Evt.target).addClass('disabled');
  if fileID > -1 then
    Server.GetFileUrl(fileID, @onGetUrlResult);
  jquery(Evt.target).addClass('');
  Result := false;
end;

function TDocumentBox.OnFileDragLeave(Evt: TEventListenerEvent;
  Data: JSValue): Boolean;
begin
  jquery('#dropzone').removeClass('hover');
end;

function TDocumentBox.OnFileDragOver(Evt: TEventListenerEvent;
  Data: JSValue): Boolean;
begin
  // hover
  jquery('#dropzone').addClass('hover');
end;

function TDocumentBox.OnFileUpdateClicked(Evt: TEventListenerEvent): Boolean;

  function isValidExtension(fileName: string): Boolean;
  var
    lst: TStringList;
    i: Integer;
  begin
    Result := false;
    lst := TStringList.create;
    try
      lst.Delimiter := '|';
      lst.StrictDelimiter := True;
      lst.DelimitedText := FAcceptFileType;
      for i := 0 to lst.count - 1 do
      begin
        Result := fileName.EndsWith('.' + lst.Strings[0], True);
        if Result then
          break;
      end;
    finally
      lst.Free;
    end;
  end;

  function OnFileSelected(Evet: TEventListenerEvent): Boolean;
  var
    aFile: TJSObject;
    button: TJQuery;
  begin
    aFile := TJSObject(TJSArray(jquery('#fileupdate').prop('files')).Elements[0]);
    if aFile <> undefined then
    begin
      button := jquery('button.up[idx="' + jquery('#fileupdate').attr('idx') + '"]');
      if isValidExtension(string(aFile.Properties['name'])) then
      begin
        jquery('#fileupdate').off('change');
        button.Data('afile', aFile).off('click').On_('click', @OnFileUploadClick).trigger('click');
      end
      else
      begin
        button.closest('.list-group-item-body').find('p').remove();
        TJQuery(button.closest('.list-group-item-body')).append('<p class="list-group-item-text text-red">' + SAcceptFileTypes + '</p>');
      end;
      jquery('#fileupdate').val('');
    end;
    Result := True;
  end;

begin
  Console.Log('OnFileUpdateClicked');
  Log.Log(ltDebug, ClassName, 'OnFileUpdateClicked', TJSElement(Evt.target).tagName);

  if jquery(Evt.target).attr('idx') = undefined then
  begin
    jquery('#fileupdate').attr('idx', jquery(Evt.target).parent().attr('idx'));
    jquery('#fileupdate').attr('file-id', jquery(Evt.target).parent().attr('file-id'));
  end
  else
  begin
    jquery('#fileupdate').attr('idx', jquery(Evt.target).attr('idx'));
    jquery('#fileupdate').attr('file-id', jquery(Evt.target).attr('file-id'));
  end;
  document.dispatchEvent(TJSEvent.new('SelectFile'));

  jquery('#fileupdate').On_('change', @OnFileSelected);

  Result := True;
end;

procedure TDocumentBox.resetFileUploadProgress(const elementId: string);
begin
  jquery('#' + elementId).removeClass('show').children().css('width', 0);
end;

procedure TDocumentBox.updateFileItem(const uploadSucceded, isCancelled: Boolean; refId: string; fileID: Int64; fileName: string);
var
  Msg: string;
begin
  if not isCancelled then
    Msg := Format(SFileUploadFailed, [fileName])
  else
    Msg := Format(SFileUploadCancelled, [fileName]);

  if uploadSucceded then
  begin
    if fileName = '' then
      fileName := jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').find('.media-body').find('a').text();

    jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').find('.media-body').html('<a id="d' + refId + '" href="javascript: void(0);" role="download" file-id="' + IntToStr(fileID) +
      '">' + fileName + '</a>');

    jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').find('p').remove();

    jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').html(jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').html() +
      '<p class="list-group-item-text text-success">' + SUploadSuccess + '</p>');

    jquery('button[idx="' + refId + '"]').parent().html('<button role="updateFile" file-id="' + IntToStr(fileID) + '" ' + 'class="btn btn-sm btn-secondary up mr-2" idx="' + refId + '">' +
      '<i class="fas fa-cloud-upload-alt"></i></button><button role="delete" ' + 'file-id="' + IntToStr(fileID) + '" class="btn btn-sm btn-secondary" idx="' + refId + '" id="btn_' + refId +
      '"><i class="far fa-trash-alt"></i></button>');

    jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').find('.media-body').find('a[role="download"]').On_('click', @OnFileDownloadClicked);

    jquery('button.up[idx="' + refId + '"]').closest('.list-group-item-body').find('button[role="updateFile"]').On_('click', @OnFileUpdateClicked);

    jquery('#btn_' + refId).On_('click', @OnFileDeleteClicked);
  end
  else
  begin
    jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').find('p').remove();
    jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').html(jquery('button[idx="' + refId + '"]').closest('.list-group-item-body').html() +
      '<p class="list-group-item-text text-red">' + Msg + '</p>');
    jquery('button[idx="' + refId + '"]').parent().html('<button role="updateFile" file-id="' + IntToStr(fileID) + '" ' + 'class="btn btn-sm btn-secondary up mr-2" idx="' + refId + '">' +
      '<i class="fas fa-cloud-upload-alt"></i></button><button role="delete" ' + 'file-id="' + IntToStr(fileID) + '" class="btn btn-sm btn-secondary" idx="' + refId + '" id="btn_' + refId +
      '"><i class="far fa-trash-alt"></i></button>');

    jquery('button.up[idx="' + refId + '"]').closest('.list-group-item-body').find('button[role="updateFile"]').On_('click', @OnFileUpdateClicked);

    jquery('#btn_' + refId).On_('click', @OnFileDeleteClicked);
    if not isCancelled then
      Server.DeleteDocument(fileID, nil);
  end;
  removeFromQueue(refId);
end;

procedure TDocumentBox.SaveFile(aFileObj: TJSObject; aPetitionID, SourceID, aFileID: Int64; SourceType: Integer; DocumentType: string; callback: TDocumentUploadCompleted; indexRef: string;
  progressEvent: TUploadProgressEvent; OnUploadStarted: TDocumentUploadStarted);
var
  fileID: Int64;
  fileName: string;
  isUpdate: Boolean;

  procedure DoUploadFile(aURL: string);
  var
    xhr: TJSXMLHttpRequest;
    fileSize: LongInt;
    mimeType: string;
    isAborted: Boolean;

    procedure OnUploadError(Event: TJSProgressEvent);
    begin
      Log.Log(ltDebug, ClassName, 'OnUploadLoadError', 'Possible network error during upload: %s', [TJSJSON.stringify(Event)]);
    end;

    procedure OnUploadLoadend(Event: TJSProgressEvent);
    begin
      Log.Log(ltDebug, ClassName, 'OnUploadLoadend', 'Upload Complete');
    end;

    procedure OnUploadProgress(Event: TJSProgressEvent);
    begin
      if Assigned(progressEvent) then
        try
          progressEvent(fileSize, Event.loaded, indexRef);
        except
          Log.Log(ltDebug, ClassName, 'progressEvent', 'Error during progress');
        end;
    end;

    procedure OnUploadTimeout(Event: TJSProgressEvent);
    begin
      Log.Log(ltError, ClassName, 'OnUploadTimeout', 'timeout error');
      callback(false, isAborted, fileID, indexRef);
    end;

    procedure OnUploadAbort(Event: TJSProgressEvent);
    begin
      isAborted := True;
      callback(false, isAborted, fileID, indexRef);
      Server.DeleteFileReference(fileID);
    end;

    procedure OnUploadDone(Event: TJSProgressEvent);

      procedure onEndUploadResult(aResult: Boolean; anError: string);
      begin
        if aResult then
          callback(True, isAborted, fileID, indexRef)
        else
          callback(True, isAborted, fileID, indexRef);
        if isUpdate then
          Server.UpdateFileReference(fileID, fileName);
      end;

    begin
      Log.Log(ltDebug, ClassName, 'OnUploadDone', 'Message: [%d] %s ', [xhr.Status, xhr.responseText]);
      if not isAborted then
      begin
        if (xhr.Status div 100) = 2 then
        begin
          Server.endUpload(fileID, @onEndUploadResult);
          removeFromQueue(indexRef);
        end
        else
          callback(false, isAborted, fileID, indexRef);
      end;
    end;

  begin
    isAborted := false;
    fileSize := Integer(aFileObj.Properties['size']);
    mimeType := string(aFileObj.Properties['type']);

    xhr := TJSXMLHttpRequest.new;

    xhr.open('PUT', aURL);
    xhr.setRequestHeader('Content-Type', mimeType);
    // add the upload event listeners needed to monitor the uploads
    xhr.upload.addEventListener('loadend', @OnUploadLoadend);
    xhr.upload.addEventListener('progress', @OnUploadProgress);
    xhr.upload.addEventListener('error', @OnUploadError);
    xhr.upload.addEventListener('timeout', @OnUploadTimeout);
    xhr.upload.addEventListener('abort', @OnUploadAbort);

    // Only calls after everything is complete
    xhr.addEventListener('load', @OnUploadDone);

    if Assigned(OnUploadStarted) then
      OnUploadStarted(xhr, indexRef);

    xhr.send(aFileObj);
  end;

  procedure OnGetUploadLinkSuccess(aResult: string);
  begin
    // perform ajax request to upload a file if it is not cancelled
    if not isFileCancelled(indexRef) then
      DoUploadFile(aResult)
    else
      updateFileItem(false, True, indexRef, fileID, fileName);
  end;

  procedure OnGetUploadLinkFail(aSuccess: Boolean; anError: string);
  begin
    callback(false, false, -1, indexRef);
  end;

  procedure onCreationResult(anID: Int64; aSuccess: Boolean; anError: string);
  begin
    if aSuccess then
    begin
      fileID := anID;
      // init file upload and get the link

      Server.startUpload(fileID, @OnGetUploadLinkSuccess, @OnGetUploadLinkFail);
    end
    else
    begin
      callback(false, false, -1, indexRef);
    end;
  end;

begin
  fileName := string(aFileObj.Properties['name']);
  if aFileID = -1 then // new file is created
  begin
    isUpdate := false;
    Server.CreateNewFile(aPetitionID, SourceType, SourceID, DocumentType, fileName, @onCreationResult);
  end
  else
  begin
    isUpdate := True;
    onCreationResult(aFileID, True, '');
  end;
end;

procedure TDocumentBox.removeFromQueue(refId: string);
var
  i, j: Integer;
begin
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if FFileQueue[i].refId = refId then
    begin
      for j := i + 1 to high(FFileQueue) do
        FFileQueue[j - 1] := FFileQueue[j];
      SetLength(FFileQueue, Length(FFileQueue) - 1);
      break;
    end;
  end;
end;

procedure TDocumentBox.OnFileUploadProgress(const aSize, aCompleted: Int64; refId: string);
var
  totalSize, completed: Int64;
  progress: Double;
begin
  totalSize := GetProcessingFilesSize;
  UpdateFilesUploadSize(refId, aCompleted);
  completed := GetProcessingFilesProgress;

  progress := Round((completed / totalSize * 100) * 100) / 100;

  jquery('button[idx="' + refId + '"]').closest('div[class*="card"').find('div[class*="progress"]').addClass('show').children().css('width', FloatToStr(progress) + '%');
end;

function TDocumentBox.inFileQueue(refId: string): Boolean;
var
  i: Integer;
begin
  Result := false;
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if FFileQueue[i].refId = refId then
    begin
      Result := True;
      break;
    end;
  end;
end;

function TDocumentBox.isFileCancelled(const refId: string): Boolean;
var
  i: Integer;
begin
  Result := false;
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if FFileQueue[i].refId = refId then
    begin
      Result := FFileQueue[i].isCancelled;
      break;
    end;
  end;
end;

procedure TDocumentBox.AbortFileUpload(const refId: string);
var
  i: Integer;
begin
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if FFileQueue[i].refId = refId then
    begin
      if FFileQueue[i].xhr <> nil then
      begin
        FFileQueue[i].xhr.abort;
      end;
      FFileQueue[i].isCancelled := True;
      break;
    end;
  end;
end;

procedure TDocumentBox.AddFileToQueue(const aFile: TJSObject; DocType: string; SourceType: Integer; refId: string);
var
  i: Integer;
begin
  SetLength(FFileQueue, Length(FFileQueue) + 1);
  i := Length(FFileQueue) - 1;
  FFileQueue[i].aFile := aFile;
  FFileQueue[i].DocType := DocType;
  FFileQueue[i].SourceType := SourceType;
  FFileQueue[i].refId := refId;
  FFileQueue[i].isCancelled := false;
  FFileQueue[i].isUploading := false;
  FFileQueue[i].uploadedSize := 0;
  FFileQueue[i].xhr := nil;
end;

function TDocumentBox.GetProcessingFilesSize: Int64;
var
  i: Integer;
begin
  Result := 0;
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if Assigned(FFileQueue[i].xhr) then
      Result := Result + StrToIntDef(string(FFileQueue[i].aFile.Properties['size']), 0);
  end;
end;

function TDocumentBox.GetAwaitingUploadCount: Integer;
var
  i: Integer;
begin
  Result := 0;
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if (FFileQueue[i].aFile.Properties['size'] <> IntToStr(FFileQueue[i].uploadedSize)) and not FFileQueue[i].isCancelled then
      inc(Result);
  end;
end;

function TDocumentBox.getErrorMsg(error: string): string;
begin
  Result := '';
  if error = cMaxNumberOfFiles then
    Result := SMaxNumberOfFiles;
  if error = cAcceptFileTypes then
    Result := SAcceptFileTypes;
  if error = cMaxFileSize then
    Result := SMaxFileSize;
  if error = cMinFileSize then
    Result := SMinFileSize;
end;

function TDocumentBox.GetFileQueueItem(const index: Integer): TFileRecord;
begin
  if index >= GetAwaitingUploadCount then
    raise Exception.create('The index for file in queue is invalid')
  else
    Result := FFileQueue[index];
end;

function TDocumentBox.UpdateFilesUploadSize(const refId: string; uploadedSize: Int64): Int64;
var
  i: Integer;
begin
  Result := 0;
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if FFileQueue[i].refId = refId then
    begin
      FFileQueue[i].uploadedSize := uploadedSize;
      Result := FFileQueue[i].uploadedSize;
      break;
    end;
  end;
end;

function TDocumentBox.GetProcessingFilesProgress: Int64;
var
  i: Integer;
begin
  Result := 0;
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if Assigned(FFileQueue[i].xhr) then
      Result := Result + FFileQueue[i].uploadedSize;
  end;
end;

procedure TDocumentBox.setDocBoxParams(const aSourceType: Integer; aSourceTypeID: Int64; aDocumentType: string; aDossierID: Int64);
begin
  FSourceType := aSourceType;
  FSourceTypeID := aSourceTypeID;
  FDocumentType := aDocumentType;
  FDocDossierID := aDossierID;
end;

procedure TDocumentBox.SetFilesUploadHandler(const refId: string; xhr: TJSXMLHttpRequest);
var
  i: Integer;
begin
  for i := low(FFileQueue) to high(FFileQueue) do
  begin
    if FFileQueue[i].refId = refId then
    begin
      FFileQueue[i].xhr := xhr;
      break;
    end;
  end;
end;

end.
