unit Forms.Debt.Details;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  DateUtils,
  pas2web.dadatasethelper,
  Module.Contacts,
  Forms.DossierDetail;

type
  TFrmBase = TFrmDossierDetail;

  TfrmDebtDetails = class(TFrmBase)
    dsDebt: TP2WDADataset;
    dsDocuments: TP2WDADataset;
    dsDocDisput: TP2WDADataset;
    dsDocDelay: TP2WDADataset;
    dsDocGuarantor: TP2WDADataset;
    dsDebtdebid: TLargeintField;
    dsDebtdebcreatedon: TDateTimeField;
    dsDebtdebcreatedbyfk: TLargeintField;
    dsDebtdebchangedon: TDateTimeField;
    dsDebtdebchangedbyfk: TLargeintField;
    dsDebtdebdossierfk: TLargeintField;
    dsDebtdebrecordtype: TSmallintField;
    dsDebtdebcreditorfk: TLargeintField;
    dsDebtdebdebttypefk: TLargeintField;
    dsDebtdebdebttype: TWideStringField;
    dsDebtdebstarton: TDateTimeField;
    dsDebtdebreference: TWideStringField;
    dsDebtdeboriginalamount: TFMTBCDField;
    dsDebtdebcurrentamount: TFMTBCDField;
    dsDebtdebmonthlyamount: TFMTBCDField;
    dsDebtdebisdisputed: TBooleanField;
    dsDebtdebdisputereason: TWideStringField;
    dsDebtdebdisputecourt: TWideStringField;
    dsDebtdebdisputedamount: TFMTBCDField;
    dsDebtdebisdelay: TBooleanField;
    dsDebtdebdelayamount: TFMTBCDField;
    dsDebtdebguarantorfk: TLargeintField;
    dsDebtdebiscompleted: TBooleanField;
    dsDebtdebRemark: TWideStringField;
    dsDebtdebpaymentiban: TStringField;
    dsDebtdebpaymentmessage: TStringField;
    dsDebtdebpaymentmessagestructured: TBooleanField;
    procedure dsDebtAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure isDelayedChecked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure isDisputedChecked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure dsDebtAfterOpen(DataSet: TDataSet);
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }

    FDebtId: Int64;
    FDMContactCreditor: TDMContacts;
    FDMContactGuarantor: TDMContacts;

    function FieldsValid: Boolean;
    procedure DoSaveDebtForm;
    procedure LoadCreditor(aContactID: NativeInt);
    procedure LoadGuarantor(aContactID: NativeInt);
    procedure SetupCreditorContact;
    procedure SetupGuarantorContact;
    procedure DoOnCreditorSaved(Sender: TObject);
    procedure DoOnGuarantorSaved(Sender: TObject);
    function OnStructuredMessageCheckBoxClicked(Evt: TEventListenerEvent): Boolean;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure MakeReadOnly; override;
    property DMContactCreditor: TDMContacts read FDMContactCreditor;
    property DMContactGuarantor: TDMContacts read FDMContactGuarantor;
    procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmDebtDetails: TfrmDebtDetails;

implementation

uses
  Modules.Server,
  Units.ActionUtils,
  Units.Params,
  Units.HTMLUtils,
  Units.DADatasetUtils,
  Units.Strings,
  Units.Contacts;

{$R *.dfm}
{ TfrmDebtDetails }

procedure TfrmDebtDetails.SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

  function GuarantorOK: Boolean;
  begin
    // Last name can be non-empty -> contact OK,
    // Last name can be empty -> no contact.
    // First name is filled -> last name must be filled.
    Result := Trim(DMContactGuarantor.getFieldValue(pfFirstName)) = '';
    if not Result then
    begin
      Result := Trim(DMContactGuarantor.getFieldValue(pfLastName)) <> '';
      if not Result then
        DMContactGuarantor.ContactFieldsValid(True);
    end;
  end;

  procedure checkGuarantorAndSave;
  begin
    if GuarantorOK then
      DMContactCreditor.SaveIfValid; // Will trigger the rest.
  end;

  procedure OnResponse(aSuccess: Boolean; anError: string);
  begin
    if aSuccess then
      checkGuarantorAndSave
    else
      DisplayError('edtPaymentIBAN', '', anError);
  end;

begin
  ClearFormErrors;
  if FieldsValid then
    if DossierPrefix = 'CSR' then
      Server.isIBANValid(alForm['edtPaymentIBAN'].value, @OnResponse)
    else
      checkGuarantorAndSave;
end;

procedure TfrmDebtDetails.WebFormCreate(Sender: TObject);
begin
  inherited;

  dsDebt.DAConnection := DossierConnection;
  FDMContactCreditor := CreateContactModule;
  FDMContactCreditor.Name := 'ContactCreditor';
  FDMContactGuarantor := CreateContactModule;
  FDMContactGuarantor.Name := 'ContactGuarantor';
  FDMContactGuarantor.AllowedContactType := actPerson;
  DefaultDocumentBox.MultiDocBox := True;
  dmServer.DoOnParamsLoaded(
    procedure
    begin
      Self.FillParamComboBOx('debtType', 'DEBTTYP');
      BindOnChangeEvent;
    end);
  jQuery('#cbPaymentMessageStructured').On_('change', @OnStructuredMessageCheckBoxClicked);
  BindOnChangeEvent;
end;

procedure TfrmDebtDetails.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FDMContactCreditor);
  FreeAndNil(FDMContactGuarantor);
  inherited;
end;

procedure TfrmDebtDetails.DoSaveDebtForm;

  procedure SetPointer(const aName: string; aValue: NativeInt);
  begin
    with dsDebt.FieldByName(aName) do
      if aValue = -1 then
        Clear
      else
        asLargeInt := aValue;
  end;

var
  typefk: string;
begin
  if FDebtId > 0 then
  begin
    dsDebt.Edit;
  end
  else
  begin
    dsDebt.Insert;
    dsDebt.FieldByName('debcreatedon').asDateTime := now;
    dsDebt.FieldByName('debcreatedbyfk').asLargeInt := Server.UserID;
  end;
  dsDebt.FieldByName('debchangedon').asDateTime := now;
  dsDebt.FieldByName('debchangedbyfk').asLargeInt := Server.UserID;
  dsDebt.FieldByName('debdossierfk').asLargeInt := DossierID;
  dsDebt.FieldByName('debrecordtype').asInteger := 1;
  SetPointer('debguarantorfk', DMContactGuarantor.ContactID);
  SetPointer('debcreditorfk', DMContactCreditor.ContactID);

  typefk := jQuery('option[value="' + alForm['debtType'].value + '"]').attr('data-id');
  if typefk = undefined then
    dsDebt.FieldByName('debdebttypefk').asInteger := 0
  else
    dsDebt.FieldByName('debdebttypefk').AsString := typefk;
  dsDebt.FieldByName('debdebttype').AsString := alForm['debtType'].value;

  dsDebt.FieldByName('debstarton').asDateTime := ExtractDate(alForm['ddtStartOn'].value);

  dsDebt.FieldByName('debreference').AsString := alForm['txtReference'].value;
  dsDebt.FieldByName('debremark').AsString := alForm['mmRemark'].value;
  dsDebt.FieldByName('deboriginalamount').asFloat := StrToFloatDef(alForm['edtOriginalAmount'].value, 0.0,
    GetFormatSettings);
  dsDebt.FieldByName('debcurrentamount').asFloat := StrToFloatDef(alForm['edtCurrentAmount'].value, 0.0,
    GetFormatSettings);

  if DossierPrefix = 'CSR' then
  begin
    dsDebt.FieldByName('debpaymentiban').AsString := alForm['edtPaymentIBAN'].value;
    dsDebt.FieldByName('debpaymentmessage').AsString := alForm['edtPaymentMessage'].value;
    dsDebt.FieldByName('debpaymentmessagestructured').asBoolean := isCheckBoxChecked('cbPaymentMessageStructured');
  end
  else
  begin
    dsDebt.FieldByName('debpaymentiban').AsString := '';
    dsDebt.FieldByName('debpaymentmessage').AsString := '';
    dsDebt.FieldByName('debpaymentmessagestructured').asBoolean := False;
  end;
  dsDebt.FieldByName('debmonthlyamount').asFloat := 0.0;
  dsDebt.FieldByName('debisdisputed').asBoolean := alForm['isDispute'].Checked;

  if alForm['isDispute'].Checked then
  begin
    dsDebt.FieldByName('debdisputereason').AsString := alForm['edtDisputeReason'].value;
    dsDebt.FieldByName('debdisputecourt').AsString := alForm['edtCourtText'].value;
    dsDebt.FieldByName('debdisputedamount').asFloat := StrToFloatDef(alForm['edtDisputeAmount'].value, 0.0,
      GetFormatSettings);
  end
  else
  begin
    dsDebt.FieldByName('debdisputereason').AsString := '';
    dsDebt.FieldByName('debdisputecourt').AsString := '';
    dsDebt.FieldByName('debdisputedamount').AsString := '0';
  end;
  dsDebt.FieldByName('debisdelay').asBoolean := alForm['isDelay'].Checked;
  if alForm['isDelay'].Checked then
    dsDebt.FieldByName('debdelayamount').AsString := alForm['edtDelayAmount'].value
  else
    dsDebt.FieldByName('debdelayamount').AsString := '0';
  dsDebt.FieldByName('debiscompleted').asBoolean := getRadioGroupValue('rdDebtCompleted') = 'Yes';
  dsDebt.Post;
  dsDebt.ApplyUpdates;
end;

procedure TfrmDebtDetails.dsDebtAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

  procedure uploadsCompleted;
  begin
    DiscardChanges := True;
    window.location.href := DossierURL('/debt');
  end;

begin
  if CheckResolveResults(Info) then
  begin
    dmServer.ShowOK(SDataSavedOK);
    if DefaultDocumentBox.GetAwaitingUploadCount = 0 then
    begin
      DiscardChanges := True;
      window.location.href := DossierURL('/debt');
    end
    else
      DefaultDocumentBox.DoFileQueueUpload(Sender.FieldByName('debid').asLargeInt, '', @uploadsCompleted);
  end;
end;

procedure TfrmDebtDetails.dsDebtAfterOpen(DataSet: TDataSet);
var
  SourceID: NativeInt;
begin
  SourceID := -1;
  if not DataSet.IsEmpty then
  begin
    alForm['debtType'].value := DataSet.FieldByName('debdebttype').AsString;
    alForm['ddtStartOn'].value := FormatHTMLDate(DataSet.FieldByName('debstarton').asDateTime);
    alForm['txtReference'].value := DataSet.FieldByName('debreference').AsString;
    alForm['mmRemark'].value := dsDebt.FieldByName('debremark').AsString;

    alForm['edtOriginalAmount'].value := FloatToStr(DataSet.FieldByName('deboriginalamount').asFloat, GetFormatSettings);
    alForm['edtCurrentAmount'].value := FloatToStr(DataSet.FieldByName('debcurrentamount').asFloat, GetFormatSettings);
    // alForm['edtMonthlyAmount'].Value :=
    // DataSet.FieldByName('debmonthlyamount').AsString;
    alForm['isDispute'].Checked := DataSet.FieldByName('debisdisputed').asBoolean;
    SetElementVisible('disputeBlock', alForm['isDispute'].Checked);

    alForm['edtDisputeReason'].value := DataSet.FieldByName('debdisputereason').AsString;
    alForm['edtCourtText'].value := DataSet.FieldByName('debdisputecourt').AsString;
    alForm['edtDisputeAmount'].value := DataSet.FieldByName('debdisputedamount').AsString;
    alForm['edtPaymentIBAN'].value := DataSet.FieldByName('debpaymentiban').AsString;
    jQuery('#cbPaymentMessageStructured').prop('checked', DataSet.FieldByName('debpaymentmessagestructured').asBoolean);
    alForm['edtPaymentMessage'].value := DataSet.FieldByName('debpaymentmessage').AsString;
    if DataSet.FieldByName('debpaymentmessagestructured').asBoolean then
      jQuery('#edtPaymentMessage').trigger('mask');

    alForm['isDelay'].Checked := DataSet.FieldByName('debisdelay').asBoolean;
    SetElementVisible('delayBlock', alForm['isDelay'].Checked);
    alForm['edtDelayAmount'].value := DataSet.FieldByName('debdelayamount').AsString;
    if DataSet.FieldByName('debiscompleted').asBoolean then
      setRadiogroupSelectedElement('rdDebtCompleted', 'Yes')
    else
      setRadiogroupSelectedElement('rdDebtCompleted', 'No');

    LoadCreditor(DataSet.FieldByName('debcreditorfk').asLargeInt);
    LoadGuarantor(DataSet.FieldByName('debguarantorfk').asLargeInt);

    SourceID := DataSet.FieldByName('debid').asLargeInt;
  end
  else
  begin
    LoadCreditor(-1);
    LoadGuarantor(-1);
  end;
  DefaultDocumentBox.LoadDocuments(dsDocuments, DossierID, SourceID, 'uploadList');
  DefaultDocumentBox.LoadDocuments(dsDocDisput, DossierID, SourceID, 'uploadListDisput');
  DefaultDocumentBox.LoadDocuments(dsDocDelay, DossierID, SourceID, 'uploadListDelay');
  DefaultDocumentBox.LoadDocuments(dsDocGuarantor, DossierID, SourceID, 'uploadListGuarantor');
  DefaultDocumentBox.setDocBoxParams(9, SourceID, '' { multidoc doesnt need this param } , DossierID);
end;

procedure TfrmDebtDetails.canHandleRoute(previousURL: string; var allow: Boolean);
begin
  allow := DiscardChanges and ((DefaultDocumentBox.GetAwaitingUploadCount = 0) or DiscardFiles);
end;

procedure TfrmDebtDetails.DoOnCreditorSaved(Sender: TObject);
var
  aLastName: string;
begin
  aLastName := Trim(DMContactGuarantor.getFieldValue(pfLastName));
  // Empty -> delete reference.
  if (aLastName = '') then
  begin
    DoSaveDebtForm;
  end
  else
    DMContactGuarantor.SaveIfValid;
end;

procedure TfrmDebtDetails.SetupCreditorContact;
const
  FromPersonalFields: TPersonalFieldNames = ('', '', '', '', '', '', '', '', '', '', 'edtCreditorName', '', '', '', '');
var
  PersonalFields: TPersonalFieldEdits;
begin
  PersonalFields.Names := FromPersonalFields;
  PersonalFields.SetFieldsFromNames;
  PersonalFields.FirstNameNotRequired := True;
  DMContactCreditor.PersonalFieldInfo := PersonalFields;
  DMContactCreditor.SearchButtonID := 'btnSelectCreditor';
  DMContactCreditor.FormIsReadOnly := Self.FormIsReadOnly;
  DMContactCreditor.EditContactButtonID := 'btnNewCreditor';
  DMContactCreditor.ContactDescription := SContactCreditor;
  DMContactCreditor.OnAllSaved := @DoOnCreditorSaved;
  DMContactCreditor.OnContactChanged := @OnContactChanged;
end;

procedure TfrmDebtDetails.DoOnGuarantorSaved(Sender: TObject);
begin
  DoSaveDebtForm;
end;

procedure TfrmDebtDetails.SetupGuarantorContact;
const
  FromPersonalFields: TPersonalFieldNames = ('', '', '', '', '', '', '', '', '', '', 'edtGuarantorName', '',
    '', '', '');
var
  PersonalFields: TPersonalFieldEdits;
begin
  PersonalFields.Names := FromPersonalFields;
  PersonalFields.SetFieldsFromNames;
  PersonalFields.FirstNameNotRequired := True;
  DMContactGuarantor.PersonalFieldInfo := PersonalFields;
  DMContactGuarantor.FormIsReadOnly := Self.FormIsReadOnly;
  DMContactGuarantor.SearchButtonID := 'btnSelectGuarantorContact';
  DMContactGuarantor.EditContactButtonID := 'btnNewGuarantorContact';

  DMContactGuarantor.ContactDescription := SContactGuarantor;
  DMContactGuarantor.OnAllSaved := @DoOnGuarantorSaved;
  DMContactGuarantor.OnContactChanged := @OnContactChanged;
end;

procedure TfrmDebtDetails.LoadCreditor(aContactID: NativeInt);
begin
  SetupCreditorContact;
  DMContactCreditor.LoadContactData(aContactID, [cdpPersonal], []);
end;

procedure TfrmDebtDetails.LoadGuarantor(aContactID: NativeInt);
begin
  SetupGuarantorContact;
  DMContactGuarantor.LoadContactData(aContactID, [cdpPersonal], []);
end;

procedure TfrmDebtDetails.MakeReadOnly;
begin
  inherited;
  DMContactCreditor.FormIsReadOnly := Self.FormIsReadOnly;
  DMContactGuarantor.FormIsReadOnly := Self.FormIsReadOnly;
end;

function TfrmDebtDetails.OnStructuredMessageCheckBoxClicked(Evt: TEventListenerEvent): Boolean;
begin
  if isCheckBoxChecked('cbPaymentMessageStructured') then
  begin
    jQuery('#edtPaymentMessage').trigger('mask');
  end
  else
    jQuery('#edtPaymentMessage').trigger('unmask');
  Result := True;
end;

function TfrmDebtDetails.FieldsValid: Boolean;
// Only debt fields need to be checked.
begin
  Result := True;
  Result := not FieldIsEmpty('debtType') and Result;
  Result := not FieldIsEmpty('ddtStartOn') and Result;
  Result := not FieldIsEmpty('txtReference') and Result;
  Result := not FieldIsEmpty('edtOriginalAmount') and Result;
  Result := isNumeric('edtOriginalAmount') and Result;
  Result := not FieldIsEmpty('edtCurrentAmount') and Result;
  Result := isNumeric('edtCurrentAmount') and Result;

  if DossierPrefix = 'CSR' then
  begin
    // Result := not FieldIsEmpty('edtMonthlyAmount') and Result;
    // Result := isNumeric('edtMonthlyAmount') and Result;

    Result := not FieldIsEmpty('edtPaymentIBAN') and Result;

    if not FieldIsEmpty('edtPaymentMessage') and isCheckBoxChecked('cbPaymentMessageStructured') then
      Result := isValidStructuredMessage('edtPaymentMessage', alForm['edtPaymentMessage'].value);
  end;

  if isCheckBoxChecked('isDispute') then
  begin
    Result := not FieldIsEmpty('edtDisputeReason') and Result;
    Result := not FieldIsEmpty('edtCourtText') and Result;
    Result := not FieldIsEmpty('edtDisputeAmount') and Result;
    Result := isNumeric('edtDisputeAmount') and Result;
  end;
  if isCheckBoxChecked('isDelay') then
  begin
    Result := not FieldIsEmpty('edtDelayAmount') and Result;
    Result := isNumeric('edtDelayAmount') and Result;
  end;
  Result := isSelectedRadioGroup('rdDebtCompleted') and Result;
end;

procedure TfrmDebtDetails.isDelayedChecked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  SetElementVisible('delayBlock', alForm['isDelay'].Checked);
end;

procedure TfrmDebtDetails.isDisputedChecked(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  SetElementVisible('disputeBlock', alForm['isDispute'].Checked);
end;

procedure TfrmDebtDetails.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#returnBack').attr('href', DossierURL('/debt'));
  FDebtId := StrToIntDef(Params.Values['DEBTID'], -1);
  dsDebt.ParamByName('DEBID').asInteger := FDebtId;
  dsDebt.Load([], nil);
end;

procedure TfrmDebtDetails.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsDebt := TP2WDADataset.Create(Self);
  dsDebtdebid := TLargeintField.Create(Self);
  dsDebtdebcreatedon := TDateTimeField.Create(Self);
  dsDebtdebcreatedbyfk := TLargeintField.Create(Self);
  dsDebtdebchangedon := TDateTimeField.Create(Self);
  dsDebtdebchangedbyfk := TLargeintField.Create(Self);
  dsDebtdebdossierfk := TLargeintField.Create(Self);
  dsDebtdebrecordtype := TSmallintField.Create(Self);
  dsDebtdebcreditorfk := TLargeintField.Create(Self);
  dsDebtdebdebttypefk := TLargeintField.Create(Self);
  dsDebtdebdebttype := TWideStringField.Create(Self);
  dsDebtdebstarton := TDateTimeField.Create(Self);
  dsDebtdebreference := TWideStringField.Create(Self);
  dsDebtdeboriginalamount := TFMTBCDField.Create(Self);
  dsDebtdebcurrentamount := TFMTBCDField.Create(Self);
  dsDebtdebmonthlyamount := TFMTBCDField.Create(Self);
  dsDebtdebisdisputed := TBooleanField.Create(Self);
  dsDebtdebdisputereason := TWideStringField.Create(Self);
  dsDebtdebdisputecourt := TWideStringField.Create(Self);
  dsDebtdebdisputedamount := TFMTBCDField.Create(Self);
  dsDebtdebisdelay := TBooleanField.Create(Self);
  dsDebtdebdelayamount := TFMTBCDField.Create(Self);
  dsDebtdebguarantorfk := TLargeintField.Create(Self);
  dsDebtdebiscompleted := TBooleanField.Create(Self);
  dsDebtdebRemark := TWideStringField.Create(Self);
  dsDebtdebpaymentiban := TStringField.Create(Self);
  dsDebtdebpaymentmessage := TStringField.Create(Self);
  dsDebtdebpaymentmessagestructured := TBooleanField.Create(Self);
  dsDocuments := TP2WDADataset.Create(Self);
  dsDocDisput := TP2WDADataset.Create(Self);
  dsDocDelay := TP2WDADataset.Create(Self);
  dsDocGuarantor := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsDebt.BeforeLoadDFMValues;
  dsDebtdebid.BeforeLoadDFMValues;
  dsDebtdebcreatedon.BeforeLoadDFMValues;
  dsDebtdebcreatedbyfk.BeforeLoadDFMValues;
  dsDebtdebchangedon.BeforeLoadDFMValues;
  dsDebtdebchangedbyfk.BeforeLoadDFMValues;
  dsDebtdebdossierfk.BeforeLoadDFMValues;
  dsDebtdebrecordtype.BeforeLoadDFMValues;
  dsDebtdebcreditorfk.BeforeLoadDFMValues;
  dsDebtdebdebttypefk.BeforeLoadDFMValues;
  dsDebtdebdebttype.BeforeLoadDFMValues;
  dsDebtdebstarton.BeforeLoadDFMValues;
  dsDebtdebreference.BeforeLoadDFMValues;
  dsDebtdeboriginalamount.BeforeLoadDFMValues;
  dsDebtdebcurrentamount.BeforeLoadDFMValues;
  dsDebtdebmonthlyamount.BeforeLoadDFMValues;
  dsDebtdebisdisputed.BeforeLoadDFMValues;
  dsDebtdebdisputereason.BeforeLoadDFMValues;
  dsDebtdebdisputecourt.BeforeLoadDFMValues;
  dsDebtdebdisputedamount.BeforeLoadDFMValues;
  dsDebtdebisdelay.BeforeLoadDFMValues;
  dsDebtdebdelayamount.BeforeLoadDFMValues;
  dsDebtdebguarantorfk.BeforeLoadDFMValues;
  dsDebtdebiscompleted.BeforeLoadDFMValues;
  dsDebtdebRemark.BeforeLoadDFMValues;
  dsDebtdebpaymentiban.BeforeLoadDFMValues;
  dsDebtdebpaymentmessage.BeforeLoadDFMValues;
  dsDebtdebpaymentmessagestructured.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  dsDocDisput.BeforeLoadDFMValues;
  dsDocDelay.BeforeLoadDFMValues;
  dsDocGuarantor.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'creditorLastName';
      Name := 'creditorLastName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'creditorFirstName';
      Name := 'creditorFirstName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'debtType';
      Name := 'debtType';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtStartOn';
      Name := 'ddtStartOn';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'txtReference';
      Name := 'txtReference';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtOriginalAmount';
      Name := 'edtOriginalAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtCurrentAmount';
      Name := 'edtCurrentAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'guarantorLastName';
      Name := 'guarantorLastName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'guarantorName';
      Name := 'guarantorName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'isDispute';
      Name := 'isDispute';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'isDisputedChecked');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtDisputeReason';
      Name := 'edtDisputeReason';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtCourtText';
      Name := 'edtCourtText';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtDisputeAmount';
      Name := 'edtDisputeAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'isDelay';
      Name := 'isDelay';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'isDelayedChecked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtDelayAmount';
      Name := 'edtDelayAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSelectCreditor';
      Name := 'btnSelectCreditor';
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdDebtCompleted';
      PreventDefault := False;
      Selector := 'input[name="rdDebtCompleted"]';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSelectGuarantorContact';
      Name := 'btnSelectGuarantorContact';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'mmRemark';
      Name := 'mmRemark';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtMonthlyAmount';
      Name := 'edtMonthlyAmount';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtPaymentIBAN';
      Name := 'edtPaymentIBAN';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtPaymentMessage';
      Name := 'edtPaymentMessage';
      PreventDefault := False;
      StopPropagation := False;
    end;
    dsDebt.SetParentComponent(Self);
    dsDebt.Name := 'dsDebt';
    dsDebt.TableName := 'debt';
    dsDebt.Params.Clear;
    with dsDebt.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DEBID';
      ParamType := ptInput;
    end;
    dsDebt.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>debid</field><parameter type="LargeInt">DEBID</parameter></binaryoperation></where></query>';
    dsDebt.DAOptions := [];
    dsDebt.AfterOpen := dsDebtAfterOpen;
    dsDebt.AfterApplyUpdates := dsDebtAfterApplyUpdates;
    dsDebt.Left := 40;
    dsDebt.Top := 80;
    dsDebtdebid.SetParentComponent(dsDebt);
    dsDebtdebid.Name := 'dsDebtdebid';
    dsDebtdebid.FieldName := 'debid';
    dsDebtdebcreatedon.SetParentComponent(dsDebt);
    dsDebtdebcreatedon.Name := 'dsDebtdebcreatedon';
    dsDebtdebcreatedon.FieldName := 'debcreatedon';
    dsDebtdebcreatedbyfk.SetParentComponent(dsDebt);
    dsDebtdebcreatedbyfk.Name := 'dsDebtdebcreatedbyfk';
    dsDebtdebcreatedbyfk.FieldName := 'debcreatedbyfk';
    dsDebtdebchangedon.SetParentComponent(dsDebt);
    dsDebtdebchangedon.Name := 'dsDebtdebchangedon';
    dsDebtdebchangedon.FieldName := 'debchangedon';
    dsDebtdebchangedbyfk.SetParentComponent(dsDebt);
    dsDebtdebchangedbyfk.Name := 'dsDebtdebchangedbyfk';
    dsDebtdebchangedbyfk.FieldName := 'debchangedbyfk';
    dsDebtdebdossierfk.SetParentComponent(dsDebt);
    dsDebtdebdossierfk.Name := 'dsDebtdebdossierfk';
    dsDebtdebdossierfk.FieldName := 'debdossierfk';
    dsDebtdebrecordtype.SetParentComponent(dsDebt);
    dsDebtdebrecordtype.Name := 'dsDebtdebrecordtype';
    dsDebtdebrecordtype.FieldName := 'debrecordtype';
    dsDebtdebcreditorfk.SetParentComponent(dsDebt);
    dsDebtdebcreditorfk.Name := 'dsDebtdebcreditorfk';
    dsDebtdebcreditorfk.FieldName := 'debcreditorfk';
    dsDebtdebdebttypefk.SetParentComponent(dsDebt);
    dsDebtdebdebttypefk.Name := 'dsDebtdebdebttypefk';
    dsDebtdebdebttypefk.FieldName := 'debdebttypefk';
    dsDebtdebdebttype.SetParentComponent(dsDebt);
    dsDebtdebdebttype.Name := 'dsDebtdebdebttype';
    dsDebtdebdebttype.FieldName := 'debdebttype';
    dsDebtdebdebttype.Size := 10;
    dsDebtdebstarton.SetParentComponent(dsDebt);
    dsDebtdebstarton.Name := 'dsDebtdebstarton';
    dsDebtdebstarton.FieldName := 'debstarton';
    dsDebtdebreference.SetParentComponent(dsDebt);
    dsDebtdebreference.Name := 'dsDebtdebreference';
    dsDebtdebreference.FieldName := 'debreference';
    dsDebtdebreference.Size := 64;
    dsDebtdeboriginalamount.SetParentComponent(dsDebt);
    dsDebtdeboriginalamount.Name := 'dsDebtdeboriginalamount';
    dsDebtdeboriginalamount.FieldName := 'deboriginalamount';
    dsDebtdeboriginalamount.Precision := 12;
    dsDebtdeboriginalamount.Size := 0;
    dsDebtdebcurrentamount.SetParentComponent(dsDebt);
    dsDebtdebcurrentamount.Name := 'dsDebtdebcurrentamount';
    dsDebtdebcurrentamount.FieldName := 'debcurrentamount';
    dsDebtdebcurrentamount.Precision := 12;
    dsDebtdebcurrentamount.Size := 0;
    dsDebtdebmonthlyamount.SetParentComponent(dsDebt);
    dsDebtdebmonthlyamount.Name := 'dsDebtdebmonthlyamount';
    dsDebtdebmonthlyamount.FieldName := 'debmonthlyamount';
    dsDebtdebmonthlyamount.Precision := 12;
    dsDebtdebmonthlyamount.Size := 0;
    dsDebtdebisdisputed.SetParentComponent(dsDebt);
    dsDebtdebisdisputed.Name := 'dsDebtdebisdisputed';
    dsDebtdebisdisputed.FieldName := 'debisdisputed';
    dsDebtdebdisputereason.SetParentComponent(dsDebt);
    dsDebtdebdisputereason.Name := 'dsDebtdebdisputereason';
    dsDebtdebdisputereason.FieldName := 'debdisputereason';
    dsDebtdebdisputereason.Size := 127;
    dsDebtdebdisputecourt.SetParentComponent(dsDebt);
    dsDebtdebdisputecourt.Name := 'dsDebtdebdisputecourt';
    dsDebtdebdisputecourt.FieldName := 'debdisputecourt';
    dsDebtdebdisputecourt.Size := 127;
    dsDebtdebdisputedamount.SetParentComponent(dsDebt);
    dsDebtdebdisputedamount.Name := 'dsDebtdebdisputedamount';
    dsDebtdebdisputedamount.FieldName := 'debdisputedamount';
    dsDebtdebdisputedamount.Precision := 12;
    dsDebtdebdisputedamount.Size := 0;
    dsDebtdebisdelay.SetParentComponent(dsDebt);
    dsDebtdebisdelay.Name := 'dsDebtdebisdelay';
    dsDebtdebisdelay.FieldName := 'debisdelay';
    dsDebtdebdelayamount.SetParentComponent(dsDebt);
    dsDebtdebdelayamount.Name := 'dsDebtdebdelayamount';
    dsDebtdebdelayamount.FieldName := 'debdelayamount';
    dsDebtdebdelayamount.Precision := 12;
    dsDebtdebdelayamount.Size := 0;
    dsDebtdebguarantorfk.SetParentComponent(dsDebt);
    dsDebtdebguarantorfk.Name := 'dsDebtdebguarantorfk';
    dsDebtdebguarantorfk.FieldName := 'debguarantorfk';
    dsDebtdebiscompleted.SetParentComponent(dsDebt);
    dsDebtdebiscompleted.Name := 'dsDebtdebiscompleted';
    dsDebtdebiscompleted.FieldName := 'debiscompleted';
    dsDebtdebRemark.SetParentComponent(dsDebt);
    dsDebtdebRemark.Name := 'dsDebtdebRemark';
    dsDebtdebRemark.FieldName := 'debRemark';
    dsDebtdebRemark.Size := 255;
    dsDebtdebpaymentiban.SetParentComponent(dsDebt);
    dsDebtdebpaymentiban.Name := 'dsDebtdebpaymentiban';
    dsDebtdebpaymentiban.DisplayWidth := 34;
    dsDebtdebpaymentiban.FieldName := 'debpaymentiban';
    dsDebtdebpaymentiban.Size := 34;
    dsDebtdebpaymentmessage.SetParentComponent(dsDebt);
    dsDebtdebpaymentmessage.Name := 'dsDebtdebpaymentmessage';
    dsDebtdebpaymentmessage.FieldName := 'debpaymentmessage';
    dsDebtdebpaymentmessage.Size := 140;
    dsDebtdebpaymentmessagestructured.SetParentComponent(dsDebt);
    dsDebtdebpaymentmessagestructured.Name := 'dsDebtdebpaymentmessagestructured';
    dsDebtdebpaymentmessagestructured.FieldName := 'debpaymentmessagestructured';
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">DEBTPROOF</constant></binaryoperation></binaryoperation></where></query>';
    dsDocuments.DAOptions := [];
    dsDocuments.Left := 32;
    dsDocuments.Top := 152;
    dsDocDisput.SetParentComponent(Self);
    dsDocDisput.Name := 'dsDocDisput';
    dsDocDisput.TableName := 'dossierfile';
    dsDocDisput.Params.Clear;
    with dsDocDisput.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocDisput.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocDisput.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">DISPPROOF</constant></binaryoperation></binaryoperation></where></query>';
    dsDocDisput.DAOptions := [];
    dsDocDisput.Left := 32;
    dsDocDisput.Top := 216;
    dsDocDelay.SetParentComponent(Self);
    dsDocDelay.Name := 'dsDocDelay';
    dsDocDelay.TableName := 'dossierfile';
    dsDocDelay.Params.Clear;
    with dsDocDelay.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocDelay.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocDelay.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">DELPROOF</constant></binaryoperation></binaryoperation></where></query>';
    dsDocDelay.DAOptions := [];
    dsDocDelay.Left := 32;
    dsDocDelay.Top := 288;
    dsDocGuarantor.SetParentComponent(Self);
    dsDocGuarantor.Name := 'dsDocGuarantor';
    dsDocGuarantor.TableName := 'dossierfile';
    dsDocGuarantor.Params.Clear;
    with dsDocGuarantor.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocGuarantor.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocGuarantor.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">GUARPROOF</constant></binaryoperation></binaryoperation></where></query>';
    dsDocGuarantor.DAOptions := [];
    dsDocGuarantor.Left := 32;
    dsDocGuarantor.Top := 344;
  finally
    alForm.AfterLoadDFMValues;
    dsDebt.AfterLoadDFMValues;
    dsDebtdebid.AfterLoadDFMValues;
    dsDebtdebcreatedon.AfterLoadDFMValues;
    dsDebtdebcreatedbyfk.AfterLoadDFMValues;
    dsDebtdebchangedon.AfterLoadDFMValues;
    dsDebtdebchangedbyfk.AfterLoadDFMValues;
    dsDebtdebdossierfk.AfterLoadDFMValues;
    dsDebtdebrecordtype.AfterLoadDFMValues;
    dsDebtdebcreditorfk.AfterLoadDFMValues;
    dsDebtdebdebttypefk.AfterLoadDFMValues;
    dsDebtdebdebttype.AfterLoadDFMValues;
    dsDebtdebstarton.AfterLoadDFMValues;
    dsDebtdebreference.AfterLoadDFMValues;
    dsDebtdeboriginalamount.AfterLoadDFMValues;
    dsDebtdebcurrentamount.AfterLoadDFMValues;
    dsDebtdebmonthlyamount.AfterLoadDFMValues;
    dsDebtdebisdisputed.AfterLoadDFMValues;
    dsDebtdebdisputereason.AfterLoadDFMValues;
    dsDebtdebdisputecourt.AfterLoadDFMValues;
    dsDebtdebdisputedamount.AfterLoadDFMValues;
    dsDebtdebisdelay.AfterLoadDFMValues;
    dsDebtdebdelayamount.AfterLoadDFMValues;
    dsDebtdebguarantorfk.AfterLoadDFMValues;
    dsDebtdebiscompleted.AfterLoadDFMValues;
    dsDebtdebRemark.AfterLoadDFMValues;
    dsDebtdebpaymentiban.AfterLoadDFMValues;
    dsDebtdebpaymentmessage.AfterLoadDFMValues;
    dsDebtdebpaymentmessagestructured.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
    dsDocDisput.AfterLoadDFMValues;
    dsDocDelay.AfterLoadDFMValues;
    dsDocGuarantor.AfterLoadDFMValues;
  end;
end;

end.
