unit Forms.Revenue.Income;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  Units.DADatasetUtils,
  Forms.DossierDetail,
  Module.Contacts, pas2web.dataelementmapper;

type
  TfrmBase = TfrmDossierDetail;

  TfrmRevenueIncome = class(TfrmBase)
    dsIncome: TP2WDADataset;
    dsDocuments: TP2WDADataset;
    procedure dsIncomeAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsIncomeAfterOpen(DataSet: TDataSet);
    procedure SaveBtnclick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    FRevenueID: Int64;
    FIncomeFrom: string;
    FdmContact: TdmContacts;
    function FieldsValid: Boolean;
    procedure SaveIncomeData;
    procedure OnContactSaved(Sender: TObject);
  public
    { Public declarations }
    procedure MakeReadOnly; override;
    class function NeedsParams: Boolean; override;
    procedure setParams(const Params: TStrings); override;
    procedure SetupContact;
    property dmContact: TdmContacts read FdmContact;
    procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmRevenueIncome: TfrmRevenueIncome;

implementation

uses
  pas2web.dadatasethelper,
  Modules.Server,
  Units.ActionUtils,
  Units.Params,
  Units.Strings,
  Units.HTMLUtils,
  Units.Logging,
  Units.Contacts;

{$R *.dfm}

procedure TfrmRevenueIncome.SaveBtnclick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  ClearFormErrors;
  SaveIncomeData;
end;

procedure TfrmRevenueIncome.OnContactSaved(Sender: TObject);
begin
  // actual save
  if FRevenueID > 0 then
    dsIncome.Edit
  else
  begin
    dsIncome.Insert;
    dsIncome.FieldByName('revcreatedon').asDateTime := now;
    dsIncome.FieldByName('revcreatedbyfk').asLargeInt := Server.UserID;
    dsIncome.FieldByName('revdossierfk').asLargeInt := DossierID;
    dsIncome.FieldByName('cntlastname').asString := Trim(alForm['edtName'].value);
    dsIncome.FieldByName('cntfirstname').asString := Trim(alForm['edtFirstName'].value);
  end;
  dsIncome.FieldByName('revchangedon').asDateTime := now;
  dsIncome.FieldByName('revchangedbyfk').asLargeInt := Server.UserID;
  dsIncome.FieldByName('revrevenuetype').asString := Trim(alForm['cbIncomeType'].value);
  dsIncome.FieldByName('revrevenuetypefk').asInteger := Server.GetParamId('REVTYP', Trim(alForm['cbIncomeType'].value));
  // let op: bedrag moet geformat worden...
  dsIncome.FieldByName('revamount').AsFloat := StrToFloatDef(Trim(alForm['edtAmount'].Value), 0, GetFormatSettings);
  dsIncome.FieldByName('revperiodtypefk').asInteger := Server.GetParamId('REVPER', Trim(alForm['cbPeriod'].value));
  dsIncome.FieldByName('revperiodtype').asString := Trim(alForm['cbPeriod'].value);
  dsIncome.FieldByName('revviatypefk').asInteger := Server.GetParamId('REVVIA', getRadioGroupValue('incomeFrom'));
  dsIncome.FieldByName('revviatype').asString := getRadioGroupValue('incomeFrom');
  dsIncome.FieldByName('revremark').asString := Trim(alForm['edtRemark'].value);
  dsIncome.FieldByName('reviscomplete').asBoolean := getRadioGroupValue('rdIncomeComplete') = 'Yes';
  dsIncome.FieldByName('revfromfk').asLargeInt := dmContact.ContactID;
  dsIncome.Post;
  dsIncome.ApplyUpdates;
end;

procedure TfrmRevenueIncome.SaveIncomeData;
begin
  if FieldsValid then
    dmContact.SaveIfValid
  else
    dmContact.ContactFieldsValid(True);
end;

procedure TfrmRevenueIncome.WebFormCreate(Sender: TObject);

  procedure FillCombos;
  begin
    FillParamCombobox('cbPeriod', 'REVPER');
    FillParamCombobox('cbIncomeType', 'REVTYP', ['DEBT']);
    FillRadioListBlock('incomeFromBlock', 'incomeFrom', 'REVVIA', '', '', []);
    alForm['incomeFrom'].Bind;
    if FIncomeFrom <> '' then // this will be triggered only when dataset is processed before loading of options
      setRadiogroupSelectedElement('incomeFrom', FIncomeFrom)
  end;

begin
  inherited;
  FdmContact := CreateContactModule;
  FRevenueID := -1;
  FIncomeFrom := '';
  // JQuery(document).On_('beforeunload', @OnBeforeDocumentUnload);
  dsIncome.DAConnection := DossierConnection;
  dmServer.DoOnParamsLoaded(@FillCombos);
  BindOnChangeEvent;
end;

procedure TfrmRevenueIncome.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FdmContact);
  inherited;
end;

procedure TfrmRevenueIncome.WebFormShow(Sender: TObject);
begin
  inherited;
  dsIncome.Close;
  dsIncome.ParamByName('REVID').asInteger := FRevenueID;
  dsIncome.Load([], nil);
end;

procedure TfrmRevenueIncome.canHandleRoute(previousURL: string; var allow: Boolean);
begin
  allow := DiscardChanges and ((DefaultDocumentBox.GetAwaitingUploadCount = 0) or DiscardFiles);
end;

procedure TfrmRevenueIncome.dsIncomeAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

  procedure uploadsCompleted;
  begin
    DiscardChanges := True;
    window.location.href := DossierURL('/revenue');
  end;

begin
  if CheckResolveResults(Info) then
  begin
    if DefaultDocumentBox.GetAwaitingUploadCount = 0 then
      window.setTimeout(
        procedure
        begin
          DiscardChanges := True;
          window.location.href := DossierURL('/revenue')
        end, 50)
    else
      DefaultDocumentBox.DoFileQueueUpload(Sender.FieldByName('revid').asLargeInt, '', @uploadsCompleted);
  end;
end;

procedure TfrmRevenueIncome.dsIncomeAfterOpen(DataSet: TDataSet);
var
  SourceID, ContactID: NativeInt;
begin
  SourceID := -1;
  if not DataSet.isEmpty then
  begin
    alForm['cbIncomeType'].value := DataSet.FieldByName('revrevenuetype').asString;
    alForm['edtAmount'].value := FloatToStr(DataSet.FieldByName('revamount').asFloat, GetFormatSettings);
    alForm['cbPeriod'].value := DataSet.FieldByName('revperiodtype').asString;
    alForm['edtName'].value := DataSet.FieldByName('cntlastname').asString;
    alForm['edtFirstName'].value := DataSet.FieldByName('cntfirstname').asString;
    alForm['edtRemark'].value := DataSet.FieldByName('revremark').asString;
    FIncomeFrom := DataSet.FieldByName('revviatype').asString;
    setRadiogroupSelectedElement('incomeFrom', DataSet.FieldByName('revviatype').asString);
    if DataSet.FieldByName('reviscomplete').asBoolean then
      setRadiogroupSelectedElement('rdIncomeComplete', 'Yes')
    else
      setRadiogroupSelectedElement('rdIncomeComplete', 'No');
    SourceID := DataSet.FieldByName('revid').asLargeInt;
    ContactID := DataSet.FieldByName('revfromfk').asInteger;
  end
  else
    ContactID := -1;

  DefaultDocumentBox.LoadDocuments(dsDocuments, DossierID, SourceID, 'uploadList');
  DefaultDocumentBox.setDocBoxParams(6, SourceID, 'REVPROOF', DossierID);
  SetupContact;
  dmContact.LoadContactData(ContactID, [cdpPersonal, cdpWPAddress], []);
end;

function TfrmRevenueIncome.FieldsValid: Boolean;
begin
  Result := not FieldIsEmpty('cbIncomeType');
  Result := not FieldIsEmpty('cbPeriod') and Result;
  Result := not FieldIsEmpty('edtAmount') and Result;
  // isNumeric checks for positive...
  Result := isNumeric('edtAmount') and Result;
  Result := isSelectedRadioGroup('incomeFrom') and Result;
  Result := isSelectedRadioGroup('rdIncomeComplete') and Result;
end;

procedure TfrmRevenueIncome.MakeReadOnly;
begin
  inherited;
  dmContact.FormIsReadOnly := Self.FormIsReadOnly;
end;

class function TfrmRevenueIncome.NeedsParams: Boolean;
begin
  Result := True;
end;

procedure TfrmRevenueIncome.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#returnBack').attr('href', DossierURL('/revenue'));
  FRevenueID := StrToIntDef(Params.Values['REVENUEID'], -1);
end;

procedure TfrmRevenueIncome.SetupContact;
const
  FromPersonalFields: TPersonalFieldNames = ('', '', '', '', '', '', '', '', '', '', 'edtName', '', '', '', '');
  FromAddressFields: TAddressFieldNames = ('edtAddress', 'edtNumber', 'edtZip', 'edtTown', '', '', '');
var
  PersonalFields: TPersonalFieldEdits;
  AddressFields: TAddressFieldEdits;
begin
  PersonalFields.Names := FromPersonalFields;
  PersonalFields.SetFieldsFromNames;
  PersonalFields.FirstNameNotRequired := True;
  AddressFields.Names := FromAddressFields;
  AddressFields.SetFieldsFromNames;
  dmContact.PersonalFieldInfo := PersonalFields;
  dmContact.WPAddressFieldInfo := AddressFields;
  dmContact.FormIsReadOnly := Self.FormIsReadOnly;
  dmContact.SearchButtonID := 'btnSelectIncomeContact';
  dmContact.EditContactButtonID := 'btnNewIncomeContact';
  dmContact.ContactDescription := SContactDebtor;
  dmContact.OnAllSaved := @OnContactSaved;
  dmContact.OnContactChanged := @OnContactChanged;
end;

procedure TfrmRevenueIncome.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsIncome := TP2WDADataset.Create(Self);
  dsDocuments := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsIncome.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'cbIncomeType';
      Name := 'cbIncomeType';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtName';
      Name := 'edtName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtFirstName';
      Name := 'edtFirstName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtAmount';
      Name := 'edtAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'cbPeriod';
      Name := 'cbPeriod';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnclick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdIncomeComplete';
      PreventDefault := False;
      Selector := 'input[name="rdIncomeComplete"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtRemark';
      Name := 'edtRemark';
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'incomeFrom';
      PreventDefault := False;
      Selector := 'incomeFromBlock input[name="incomeFrom"]';
      StopPropagation := False;
    end;
    dsIncome.SetParentComponent(Self);
    dsIncome.Name := 'dsIncome';
    dsIncome.TableName := 'revenue';
    dsIncome.DAConnection := dmServer.CSRConnection;
    dsIncome.Params.Clear;
    with dsIncome.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'REVID';
      ParamType := ptInput;
    end;
    dsIncome.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="AND"><binaryoperation operator="Equal"><field>revid</field><parameter type="LargeInt">REVID</parameter></binaryoperation><binaryoperation operator="NotEqual"><field>revrevenuetype</field><constant type="String">DEBT</constant></binaryoperation></binaryoperation></where></query>';
    dsIncome.DAOptions := [];
    dsIncome.AfterOpen := dsIncomeAfterOpen;
    dsIncome.AfterApplyUpdates := dsIncomeAfterApplyUpdates;
    dsIncome.Left := 40;
    dsIncome.Top := 96;
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">REVPROOF</constant></binaryoperation></binaryoperation></where></query>';
    dsDocuments.DAOptions := [];
    dsDocuments.Left := 536;
    dsDocuments.Top := 48;
  finally
    alForm.AfterLoadDFMValues;
    dsIncome.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
  end;
end;

end.
