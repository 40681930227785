unit Units.FormManager;

interface

uses
  System.Classes, System.SysUtils, Types, Web, WEBLib.Forms, WebRouter, Units.Logging;

Type
  EForms = Class(Exception);

  TFormRegistration = Class;

  { TBaseForm }

  TParamsReceivedEvent = reference to procedure(aRoutePattern: string; Params: TStrings; isReroute : Boolean);

  TBaseForm = Class(TForm)
  Public
    class procedure HideAllForms(ExceptForm: TBaseForm=nil);
    procedure HideAllOtherForms;
  Protected
    FParamsReceivedEvent: TParamsReceivedEvent;
    Procedure DoShowForm; virtual;
    Procedure EnterMethod(aMethod: String);
    Procedure ExitMethod(aMethod: String);
    Procedure DoLog(Msg: String; aType: TLogType = ltInfo); overload;
    Procedure DoLog(Fmt: String; Args: Array of const; aType: TLogType = ltInfo); overload;
  Protected
    Class Procedure ShowHide(El: TJSHTMLElement; aVisible: Boolean);
    Procedure HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute : Boolean); virtual;
    procedure CanHandleRoute(previousURL: string; var allow: Boolean); virtual;
  Public
    Constructor Create(aOwner: TComponent); override;
    procedure ShowForm;
    Class Function NeedsLogin : Boolean; virtual;
    Class Function MyRoutes: TStringDynArray; virtual;
    Class function FormName: String; virtual;
    Class Function RegisterForm: TFormRegistration;
    Class Function FindElement(aID: String): TJSHTMLElement;
    Class Function GetElement(aID: String): TJSHTMLElement;
    property OnParamsReceived : TParamsReceivedEvent read  FParamsReceivedEvent write FParamsReceivedEvent;
  end;

  TBaseFormClass = Class of TBaseForm;

  { TFormRegistration }

  TFormRegistration = Class(TCollectionItem)
  private
    FFormClass: TBaseFormClass;
    FFormName: String;
    FRoutes : Array of TRoute;
    function GetRoute(aIndex : Integer): TRoute ;
    function GetRouteCount: Integer;
  Protected
    Instance: TBaseForm;
    Procedure AddRoute(aRoute : TRoute);
  Public
    Function HasRoute(aRoute : TRoute) : Boolean;
    Function NeedsLogin : Boolean;
    Property FormName: String Read FFormName Write FFormName;
    Property FormClass: TBaseFormClass Read FFormClass Write FFormClass;
    Property Routes[aIndex : integer] : TRoute Read GetRoute;
    Property RouteCount : Integer Read GetRouteCount;
  end;

  { TFormRegistrations }

  TFormRegistrations = Class(TCollection)
  private
    function GetReg(aIndex: integer): TFormRegistration;
  Public
    Function Add(aFormName: String): TFormRegistration; overload;
    Function IndexOfRoute(aRoute : TRoute) : integer;
    Function IndexOfForm(aFormName: String): integer;
    Function FindForm(aFormName: String): TFormRegistration;
    Function GetForm(aFormName: String): TFormRegistration;
    Function FindFormByRoute(aRoute : TRoute): TFormRegistration;
    Function GetFormByRoute(aRoute : TRoute): TFormRegistration;
    Property Registrations[aIndex: integer]: TFormRegistration Read GetReg; default;
  end;

  { TFormManager }

  TFormManager = Class(TComponent)
  Private
    Class var _Instance: TFormManager;
    class function ExtractFormName(const URl: String): String;
  Private
    FCurrentForm: TBaseForm;
    FRegs: TFormRegistrations;
    FPreviousRoute : string;
    function GetFormCount: integer;
    function GetRegistration(aIndex: integer): TFormRegistration;
  Protected
    Function CreateRegistrations: TFormRegistrations; virtual;
  Public
    Constructor Create(aOwner: TComponent); override;
    Class Function Instance: TFormManager;
    Function RouteNeedsLogin(Const aRoute : String) : Boolean;
    procedure ShowRoute(URl: String; aRoute: TRoute; Params: TStrings);
    Function RegisterForm(aClass: TBaseFormClass; AName: String = ''): TFormRegistration;
    Property CurrentForm: TBaseForm Read FCurrentForm;
    Property FormCount: integer Read GetFormCount;
    Property Registrations[aIndex: integer]: TFormRegistration Read GetRegistration; default;
  end;

Function FormManager: TFormManager;

implementation

uses
  Rtl.TemplateLoader, Units.Strings, libjquery, Units.ServerConfig;

const
  SFormParent = 'form-parent';


function FormManager: TFormManager;
begin
  Result:=TFormManager.Instance;
end;

{ TFormRegistrations }

function TFormRegistrations.GetReg(aIndex: integer): TFormRegistration;
begin
  Result:=TFormRegistration(Items[aIndex]);
end;

function TFormRegistrations.Add(aFormName: String): TFormRegistration;

Var
  S : String;

begin
  if IndexOfForm(aFormName)<>-1 then
    begin
    S:=Format(SErrDuplicateForm, [aFormName]);
    Log.Log(ltError,ClassName,'Add',S);
    Raise EForms.Create(S);
    end;
  Result:=TFormRegistration(Inherited Add);
  Result.FormName:=aFormName;
end;

function TFormRegistrations.IndexOfForm(aFormName: String): integer;
begin
  Result:=Count-1;
  While (Result>=0) and Not SameText(GetReg(Result).FormName, aFormName) do
    Dec(Result);
end;

function TFormRegistrations.IndexOfRoute(aRoute: TRoute): integer;
begin
  Result:=Count-1;
  While (Result>=0) and Not Registrations[Result].HasRoute(aRoute) do
    Dec(Result);
end;

function TFormRegistrations.FindForm(aFormName: String): TFormRegistration;
Var
  I: integer;
begin
  I:=IndexOfForm(aFormName);
  if I=-1 then
    Result:=Nil
  else
    Result:=GetReg(I);
end;

function TFormRegistrations.FindFormByRoute(aRoute: TRoute): TFormRegistration;
Var
  I: integer;
begin
  I:=IndexOfRoute(aRoute);
  if I=-1 then
    Result:=Nil
  else
    Result:=GetReg(I);
end;

function TFormRegistrations.GetForm(aFormName: String): TFormRegistration;
begin
  Result:=FindForm(aFormName);
  if Result=Nil then
    Raise EForms.CreateFmt(SErrUnknownForm, [aFormName]);
end;

function TFormRegistrations.GetFormByRoute(aRoute: TRoute): TFormRegistration;
begin
  Result:=FindFormByRoute(aRoute);
  if Result=Nil then
    Raise EForms.CreateFmt(SErrUnknownRoute, [aRoute.URLPattern]);
end;

{ TBaseForm }

class function TBaseForm.FormName: String;

Var
  I: integer;

begin
  Result:=ClassName;
  if SameText(Copy(Result, 1, 4), 'Tfrm') then
    Delete(Result, 1, 4)
  else if SameText(Copy(Result, 1, 1), 'T') then
    Delete(Result, 1, 1);
  I:=Pos('Form', Result);
  if (I>0) and (I=Length(Result)-3) then
    Result:=Copy(Result, 1, I-1);
end;

class function TBaseForm.RegisterForm: TFormRegistration;

Var
  S : String;

begin
  Result:=FormManager.RegisterForm(Self);
  for S in MyRoutes do
  begin
    Log.Debug(ClassName,'RegisterForm','Registering route: '+S);
    Result.AddRoute(Router.RegisterRoute(S, @FormManager.ShowRoute));
  end;
end;

class function TBaseForm.FindElement(aID: String): TJSHTMLElement;

begin
  Result:=TJSHTMLElement(document.getElementById(aID));
end;

class function TBaseForm.GetElement(aID: String): TJSHTMLElement;
begin
  Result:=FindElement(aID);
  If Result=Nil then
    Raise EForms.CreateFmt('No such element : %s', [aID]);
end;

procedure TBaseForm.HideAllOtherForms;
begin
  HideAllForms(Self);
end;

class procedure TBaseForm.HideAllForms(ExceptForm: TBaseForm = Nil);

Var
  I: integer;
  C: TComponent;
begin
  GetElement(SFormParent).InnerHTml:='';
  For I:=FormManager.ComponentCount-1 downto 0 do
  begin
    C:=FormManager.Components[I];
    if (C is TBaseForm) and (C<>ExceptForm) then
      C.Free;
  end;
end;

procedure TBaseForm.DoShowForm;

begin
  Show;
end;

procedure TBaseForm.EnterMethod(aMethod: String);
begin
  Log.EnterMethod(ClassName, aMethod);
end;

procedure TBaseForm.ExitMethod(aMethod: String);
begin
  Log.ExitMethod(ClassName, aMethod);
end;

procedure TBaseForm.DoLog(Msg: String; aType: TLogType = ltInfo);
begin
  Log.Add(aType, ClassName, '', Msg);
end;

procedure TBaseForm.DoLog(Fmt: String; Args: array of const; aType: TLogType = ltInfo);
begin
  DoLog(Format(Fmt, Args), aType);
end;

class procedure TBaseForm.ShowHide(El: TJSHTMLElement; aVisible: Boolean);

begin
  if Assigned(El) then
    If aVisible then
      El.style.RemoveProperty('display')
    else
      El.style.SetProperty('display', 'none');
end;


procedure TBaseForm.ShowForm;

begin
  DoShowForm;
end;

class function TBaseForm.MyRoutes: TStringDynArray;
begin
  Result:=[LowerCase(FormName)];
end;

class function TBaseForm.NeedsLogin: Boolean;
begin
  Result:=True;
end;

procedure TBaseForm.HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute : Boolean);

begin
  if Assigned(FParamsReceivedEvent) then
    FParamsReceivedEvent(aRoute.URLPattern, Params, IsReroute);
  if URl='' then ; // Silence compiler warning
end;

procedure TBaseForm.CanHandleRoute(previousURL: string; var allow: Boolean);
begin
  allow := True;
end;

constructor TBaseForm.Create(aOwner: TComponent);
begin
  inherited Create(aOwner);
end;

{ TFormManager }

class function TFormManager.Instance: TFormManager;
begin
  if _Instance=Nil then
    _Instance:=TFormManager.Create(Nil);
  Result:=_Instance;
end;

class function TFormManager.ExtractFormName(const URl: String): String;

Var
  P: integer;

begin
  Result:=URl;
  if (Result<>'') and (Result[1]='/') then
    Delete(Result, 1, 1);
  P:=Pos('/', Result);
  if P<>0 then
    Result:=Copy(Result, 1, P-1);
end;

function TFormManager.GetFormCount: integer;
begin
  Result:=FRegs.Count;
end;

function TFormManager.GetRegistration(aIndex: integer): TFormRegistration;
begin
  Result:=FRegs[aIndex];
end;

function TFormManager.CreateRegistrations: TFormRegistrations;
begin
  Result:=TFormRegistrations.Create(TFormRegistration);
end;

constructor TFormManager.Create(aOwner: TComponent);
begin
  inherited Create(aOwner);
  FRegs:=CreateRegistrations;
  Application.AppContainer:=SFormParent;
  FPreviousRoute := '';
end;

procedure TFormManager.ShowRoute(URl: String; aRoute: TRoute; Params: TStrings);

Var
  F: TFormRegistration;
  FN: String;
  lParams: TStrings;
  frm : TBaseForm;
  canContinue: Boolean;
begin
  Log.log(ltDebug,ClassName,'ShowRoute','In route %s -> form %s',[ Url,ExtractFormName(Url)]);
  F:=Nil;
  FN:=ExtractFormName(URl);

  if Assigned(FCurrentForm) and (FPreviousRoute <> URl) then
  begin
    FCurrentForm.CanHandleRoute(Url, canContinue);
    if not canContinue then
    begin
      window.location.href := ('#/' + FPreviousRoute).Replace('//', '/');
      Exit;
    end;
  end;

 // When back is pressed and we came from login form. Don't look for route.
  if FN='' then
    FN:='login'
  else
    F:=FRegs.FindFormByRoute(aRoute);
  // If no route or login, look based on name.
  if (F=Nil) then
    F:=FRegs.FindForm(FN);
  If (F=Nil) then
    F:=FRegs.GetForm('error');
  if Assigned(FCurrentForm) then
  begin
    if not FCurrentForm.InheritsFrom(F.FormClass) then
      begin
      // Use temp var so if an exception happens, the next round does not again cause an error.
      frm:=FCurrentForm;
      FCurrentForm:=Nil;
      FreeAndNil(frm);
      end;
  end;
  JQuery('body').removeClass('modal-open');
  JQuery('.modal-backdrop').Remove;
  JQuery('#page-navigator a').removeClass('active');
  JQuery('#page-nav-'+FN).addClass('active');
  JQuery('#page-nav-'+FN).removeClass('disabled');
  if Assigned(FCurrentForm) then
  begin
    // The current form is the correct form for this URL
    FCurrentForm.HandleRoute(URl, aRoute, Params, True);
  end
  else
  begin
    Log.Log(ltDebug,ClassName,'ShowRoute','Creating form %s of class : %s',[F.FormName, F.FormClass.ClassName]);
    if Assigned(Params) then
    begin
      lParams:=TStringList.Create;
      lParams.AddStrings(Params);
    end;
    Application.CreateForm(F.FormClass, SFormParent, FCurrentForm,
      Procedure(Sender: TObject)
      begin
        FCurrentForm:=TBaseForm(Sender);
        try
          FCurrentForm.HandleRoute(URl, aRoute, lParams,False);
        finally
          if Assigned(lParams) then
            lParams.Free;
        end;
        FCurrentForm.ShowForm;
      end);

  end;
  FPreviousRoute := URl;
end;

function TFormManager.RegisterForm(aClass: TBaseFormClass; AName: String): TFormRegistration;
begin
  if AName='' then
    AName:=aClass.FormName;
  Log.Log(ltDebug,ClassName,'RegisterForm','Form name: '+AName);
  Result:=FRegs.Add(AName);
  Result.FormClass:=aClass;
end;

function TFormManager.RouteNeedsLogin(const aRoute: String): Boolean;

Var
  FN: String;
  Def : TFormRegistration;

begin
  Def:=Nil;
  FN:=ExtractFormName(aRoute);
  if FN<>'' then
    Def:=FRegs.FindForm(FN);
  Result:=(Def<>Nil) and Def.NeedsLogin;
end;

{ TFormRegistration }

procedure TFormRegistration.AddRoute(aRoute: TRoute);

Var
  Len: integer;

begin
  Len:=Length(FRoutes);
  SetLength(FRoutes,Len+1);
  FRoutes[Len]:=aRoute;
end;

function TFormRegistration.GetRoute(aIndex : Integer) : TRoute;
begin
  If (aIndex<0) or (aIndex>=Length(FRoutes)) then
    Raise EForms.CreateFmt('Index out of range: %d not in [0..%d]',[aIndex,Length(FRoutes)-1]);
  Result:=FRoutes[aIndex];
end;

function TFormRegistration.GetRouteCount: Integer;
begin
  Result:=Length(FRoutes);
end;

function TFormRegistration.HasRoute(aRoute: TRoute): Boolean;
Var
  I : integer;

begin
  Result:=False;
  I:=RouteCount-1;
  While (Not Result) and (I>=0) do
    begin
    Result:=(FRoutes[I]=aRoute);
    Dec(I);
    end;
end;

function TFormRegistration.NeedsLogin: Boolean;
begin
  Result:=Assigned(FFormClass) and (FFormClass.NeedsLogin);
end;

end.
