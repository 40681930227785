unit Forms.CSR.BudgetPlan;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper, Units.Params,
  Types, WebRouter;

type
  TFrmBase = TFrmDossierDetail;
  TfrmBudgetPlan = class(TfrmBase)
    dsBudgetPlan: TP2WDADataset;
    eacBudgetPlan: TPW2DataElementMapper;
    dsBudgetPlanDetails: TP2WDADataset;
    dsReset: TP2WDADataset;
    procedure dsBudgetPlanAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure dsBudgetPlanAfterOpen(DataSet: TDataSet);
    procedure dsBudgetPlanDetailsAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure dsBudgetPlanDetailsAfterOpen(DataSet: TDataSet);
    procedure dsResetAfterOpen(DataSet: TDataSet);
    procedure onItemValidationError(Sender: TObject; Source: TElementAction; dest:
        TField; aError: TElementError);
    procedure onPrintPlan(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure onResetAmounts(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure onSaveBudgetDetails(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FBudgetPlanId : NativeInt;
    FSettlementID : NativeInt;
    FCategories : TCategories;
    FIgnoreSettlemetID : Boolean;
    FPrinting : Boolean;
    procedure onGetExCatItem(RecordID: Integer; Name: string;
      Language: Integer; ParamType, Value: string; ValueType: integer;
      Extra: string; isLast: Boolean);
    procedure onGetExKindItem(RecordID: Integer; Name: string;
      Language: Integer; ParamType, Value: string; ValueType: integer;
      Extra: string; isLast: Boolean);
    function OnTriggerLine(Evt: TEventListenerEvent): Boolean;
    function OnValidFromChanged(Evt: TEventListenerEvent): Boolean;

    Procedure HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute: Boolean); override;

    procedure DoPrintJob;
    procedure PrintDone(Sender: TObject);
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix: string; override;
    class function MyRoutes: TStringDynArray; override;
  protected procedure LoadDFMValues; override; end;

var
  frmBudgetPlan: TfrmBudgetPlan;

implementation

{$R *.dfm}
uses
  Modules.Server, libjquery, dateutils, Units.ActionUtils, Units.HTMLUtils,
  Units.Strings, Units.DADatasetUtils, Units.Service.Dezq;

procedure TfrmBudgetPlan.DoPrintJob;

  procedure OnResponse(JobID : Int64; aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
    begin
      Server.ShowError(anError);
      jQuery('#btnPrint').find('i').css('display', 'none');
      jQuery('#btnPrint').prop('disabled', false);
    end
    else
    begin
      jQuery('#btnPrint').find('i').css('display', '');
      jQuery('#btnPrint').prop('disabled', true);
      HandlePrintJob(JobID);
    end;
  end;
var
  params: TReportParameterDynArray;
begin
  setLength(params, 1);
  params[0].Name := 'BudgetPlanID';
  params[0].Value := FBudgetPlanId;

  Server.printReport(DossierID, 'BUDGETPLAN', params, @OnResponse);
end;

function TfrmBudgetPlan.DossierPrefix: string;
begin
  Result:='CSR';
end;

procedure TfrmBudgetPlan.dsBudgetPlanAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    Server.ShowOK(SBudgetPlanSavedOK);
  end;
end;

procedure TfrmBudgetPlan.dsBudgetPlanAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
  begin
    if Dataset.FieldByName('bplsourcefk').asInteger > 0 then
    begin
      jQuery('#btnReset').prop('disabled', false);
      jQuery('#btnReset2').prop('disabled', false);
    end;

    eacBudgetPlan.DatasetToElements;
    dsBudgetPlanDetails.ParamByName('BPLANID').asInteger := FBudgetPlanId;
    dsBudgetPlanDetails.Load([], nil);

    jQuery('#ddtValidFrom').attr('min', FormatHTMLDate( Dataset.FieldByName('bplvalidfrom').asDateTime));

  end
  else
  begin
    jQuery('#settlementNotFound').css('display', '');
    jQuery('#form').css('display', 'none');
  end;
end;

procedure TfrmBudgetPlan.dsBudgetPlanDetailsAfterApplyUpdates(Sender: TDataSet;
    Info: TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    if FPrinting  then
      DoPrintJob
    else
    begin
      Server.ShowOK(SBudgetPlanDetailsSavedOK);
      if not FIgnoreSettlemetID then
      begin
        if FSettlementID > 0 then
          window.location.href := '#/CSR/' + IntToStr(DossierID) + '/settlementplan/edit/' + IntToStr(FSettlementID)
        else
          window.location.href := '#/CSR/' + IntToStr(DossierID) + '/identity';
      end
      else
        window.location.href := '#/budgetplans';
    end;
  end;
end;

procedure TfrmBudgetPlan.dsBudgetPlanDetailsAfterOpen(DataSet: TDataSet);
var
  html : string;
  i, j: Integer;
begin
  html := '';
  for i := Low(FCategories) to High(FCategories) do
  begin
    html := html + '<tr><td style="width:10px; padding-left: 0;"><div style="cursor: pointer;" data-cat="' +
      FCategories[i].Name + '" ' +
      'role="trigger"><i class="fa fa-minus"></i></div></td><td colspan="2"><strong>' +
        FCategories[i].Value  + '</strong></td></tr>';
    for j := Low(FCategories[i].kinds) to High(FCategories[i].kinds) do
    begin
      html := html + '<tr data-cat="' + FCategories[i].Name +
      '" data-cid="' + IntToStr(FCategories[i].id) + '"><td></td><td style="padding-left: 30px">' +
      FCategories[i].kinds[j].Value  + '</td><td>' +
      '<input data-id="-1" data-kind="' + FCategories[i].kinds[j].Name + '" ' +
      'data-kid="' + IntToStr(FCategories[i].kinds[j].id) + '" type="number" value="0" step="0.01">'
      + '</td></tr>';
    end;
  end;
  jQuery('#plan').html(html);

  while not DataSet.eof do
  begin
    jQuery('tr[data-cat="' + DataSet.FieldByName('bpdcategory').asString + '"]')
      .find('input[data-kind="' + DataSet.FieldByName('bpdkind').asString + '"]')
      .attr('data-id', DataSet.FieldByName('bpdid').asString)
      .val(DataSet.FieldByName('bpdamount').asString);
    DataSet.Next;
  end;

  if html <> '' then
    jQuery('#secondSave').css('display', '');
  jQuery('div[role="trigger"]').On_('click', @OnTriggerLine);
end;

procedure TfrmBudgetPlan.dsResetAfterOpen(DataSet: TDataSet);
begin
  while not DataSet.eof do
  begin
    jQuery('input[data-id="' + DataSet.FieldByName('bpdid').asString + '"]')
      .val(DataSet.FieldByName('bpdamount').asString);
    DataSet.Next;
  end;
end;

procedure TfrmBudgetPlan.HandleRoute(URl: String; aRoute: TRoute;
  Params: TStrings; IsReroute: Boolean);
begin
  inherited;
  FIgnoreSettlemetID := True;
  jQuery('#brone').css('display', 'none');
  jQuery('#brtwo').css('display', '');
  setParams(Params);
end;

class function TfrmBudgetPlan.MyRoutes: TStringDynArray;
begin
  Result := ['/CSR/:DOSSIERID/budgetplan/:BUDGETPLANID'];
end;

procedure TfrmBudgetPlan.onGetExCatItem(RecordID: Integer; Name: string;
  Language: Integer; ParamType, Value: string; ValueType: integer;
  Extra: string; isLast: Boolean);
begin
  SetLength(FCategories, Length(FCategories) + 1);
  FCategories[Length(FCategories) - 1].id := RecordID;
  FCategories[Length(FCategories) - 1].Name := Name;
  FCategories[Length(FCategories) - 1].Value := Value;
  if isLast then
    Server.GetParameterList('EXKIND', Language, @onGetExKindItem);
end;

procedure TfrmBudgetPlan.onGetExKindItem(RecordID: Integer; Name: string;
  Language: Integer; ParamType, Value: string; ValueType: integer;
  Extra: string; isLast: Boolean);
var
  i : Integer;
begin
  for i := Low(FCategories) to High(FCategories) do
  begin
    if FCategories[i].Name = Extra then
    begin
      SetLength(FCategories[i].kinds, Length(FCategories[i].kinds) + 1);
      FCategories[i].kinds[Length(FCategories[i].kinds) - 1].id := RecordID;
      FCategories[i].kinds[Length(FCategories[i].kinds) - 1].Name := Name;
      FCategories[i].kinds[Length(FCategories[i].kinds) - 1].Value := Value;
    end;
  end;
  if isLast then
    dsBudgetPlan.Load([], nil);
end;

procedure TfrmBudgetPlan.onItemValidationError(Sender: TObject; Source:
    TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmBudgetPlan.onPrintPlan(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  FPrinting := True;
  jQuery('#btnSave2').trigger('click');
end;

procedure TfrmBudgetPlan.onResetAmounts(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  dsReset.Close;
  dsReset.ParamByName('ID').asInteger := dsBudgetPlan.FieldByName('bplsourcefk').asInteger;
  dsReset.Load([], nil);
end;

procedure TfrmBudgetPlan.onSaveBudgetDetails(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
var
  ids : Array of NativeInt;
  i : Integer;

  function processAmounts(aIndex: Integer; AElement: TJSElement): Boolean;
  var
    amount: Double;
    id : Integer;
  begin
    amount := StrToFloatDef(string(jQuery(AElement).val), 0);
    id := StrToIntDef(jQuery(AElement).attr('data-id'), -1);
    if amount = 0 then
    begin
      if id > 0 then
      begin
        SetLength(ids, Length(ids) + 1);
        ids[Length(ids) - 1] := id;
      end;
    end
    else
    begin
      if Locate(dsBudgetPlanDetails, 'bpdid', id) then
      begin
        dsBudgetPlanDetails.Edit;
        dsBudgetPlanDetails.FieldByName('bpdchangedon').asDateTime := now;
        dsBudgetPlanDetails.FieldByName('bpdchangedbyfk').asInteger := Server.UserId;
        dsBudgetPlanDetails.FieldByName('bpdamount').asFloat := amount;
        dsBudgetPlanDetails.Post;
      end
      else
      begin
        dsBudgetPlanDetails.Insert;
        dsBudgetPlanDetails.FieldByName('bpdcreatedon').asDateTime := now;
        dsBudgetPlanDetails.FieldByName('bpdcreatedbyfk').asInteger := Server.UserId;
        dsBudgetPlanDetails.FieldByName('bpdchangedon').asDateTime := now;
        dsBudgetPlanDetails.FieldByName('bpdchangedbyfk').asInteger := Server.UserId;
        dsBudgetPlanDetails.FieldByName('bpdbudgetplanfk').asInteger := FBudgetPlanId;
        dsBudgetPlanDetails.FieldByName('bpdamount').asFloat := amount;
        dsBudgetPlanDetails.FieldByName('bpdcategory').asString := jQuery(AElement).closest('tr').attr('data-cat');
        dsBudgetPlanDetails.FieldByName('bpdcategoryfk').asInteger := StrToIntDef(jQuery(AElement).closest('tr').attr('data-cid'), -1);
        dsBudgetPlanDetails.FieldByName('bpdkind').asString := jQuery(AElement).attr('data-kind');
        dsBudgetPlanDetails.FieldByName('bpdkindfk').asInteger := StrToIntDef(jQuery(AElement).attr('data-kid'), -1);
        dsBudgetPlanDetails.Post;
      end;
    end;
    Result := True;
  end;
begin
  jQuery('input[data-id]').each(@processAmounts);
  for i := Low(ids) to High(ids) do
  begin
    if Locate(dsBudgetPlanDetails, 'bpdid', ids[i]) then
      dsBudgetPlanDetails.Delete;
  end;

  dsBudgetPlanDetails.ApplyUpdates;
end;

function TfrmBudgetPlan.OnTriggerLine(Evt: TEventListenerEvent): Boolean;
begin

  if jQuery(Evt.target).hasClass('fa-minus') then
  begin
    jQuery(Evt.target).closest('table')
      .find('tr[data-cat="' + jQuery(Evt.target).closest('div').attr('data-cat') + '"]')
      .css('display', 'none');
    jQuery(Evt.target).removeClass('fa-minus').addClass('fa-plus');
  end
  else
  begin
    jQuery(Evt.target).closest('table')
      .find('tr[data-cat="' + jQuery(Evt.target).closest('div').attr('data-cat') + '"]')
      .css('display', '');
    jQuery(Evt.target).removeClass('fa-plus').addClass('fa-minus');
  end;
  Result := True;
end;

function TfrmBudgetPlan.OnValidFromChanged(Evt: TEventListenerEvent): Boolean;
var
  startDate : TDateTime;
begin
  if Trim(alForm['ddtValidFrom'].Value) <> '' then
  begin
    startDate := JSDateToDateTime(TJSDate.new(Trim(alForm['ddtValidFrom'].Value)));
    jQuery('#ddtValidTill').val(FormatHTMLDate(IncYear(startDate, 7)));
  end;
  Result := True;
end;

procedure TfrmBudgetPlan.PrintDone(Sender: TObject);
begin
  jQuery('#btnPrint').find('i').css('display', 'none');
  jQuery('#btnPrint').prop('disabled', false);
end;

procedure TfrmBudgetPlan.SaveBtnClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if eacBudgetPlan.ValidateActions(True) then
  begin
    if dsBudgetPlan.isEmpty then
    begin
      dsBudgetPlan.Insert;
      dsBudgetPlan.FieldByName('bplcreatedon').asDateTime := now;
      dsBudgetPlan.FieldByName('bplcreatedbyfk').asInteger := Server.UserID;
      dsBudgetPlan.FieldByName('bpldossierfk').asInteger := DossierID;
    end
    else
    begin
      dsBudgetPlan.Edit;
    end;
    dsBudgetPlan.FieldByName('bplchangedbyfk').asInteger := Server.UserID;
    dsBudgetPlan.FieldByName('bplchangedon').asDateTime := now;
    eacBudgetPlan.ElementsToDataset;
    dsBudgetPlan.Post;
    dsBudgetPlan.ApplyUpdates;
  end;
end;

procedure TfrmBudgetPlan.WebFormCreate(Sender: TObject);
begin
  inherited;
  FIgnoreSettlemetID := False;
  FPrinting := False;

  Server.CSRConnection.Message.ClientID := Server.ClientID;
  dsBudgetPlan.DAConnection := Server.CSRConnection;
  dsBudgetPlanDetails.DAConnection := Server.CSRConnection;

  Server.GetParameterList('EXCAT', Language, @onGetExCatItem);

  jQuery('#ddtValidFrom').On_('change', @OnValidFromChanged);

  OnPrintJobDone := PrintDone;
end;


procedure TfrmBudgetPlan.setParams(const Params: TStrings);
begin
  inherited;
  FSettlementID := -1;

  If Assigned(Params) and (Params.indexOfName('BUDGETPLANID')<>-1) then
    FBudgetPlanId := StrToIntDef(Params.Values['BUDGETPLANID'],-1);

  If Assigned(Params) and (Params.indexOfName('SETTLEMENTPLANID')<>-1) then
    FSettlementID := StrToIntDef(Params.Values['SETTLEMENTPLANID'],-1);

  if not FIgnoreSettlemetID then
  begin
    dsBudgetPlan.ParamByName('SettlementPlanID').asInteger := FSettlementID;
    jQuery('#returnBack').attr('href',DossierURL('/settlementplan'));
  end
  else
  begin
    dsBudgetPlan.TableName := 'budgetplan';
    dsBudgetPlan.Params.RemoveParam(dsBudgetPlan.Params.ParamByName('SettlementPlanID'));
    jQuery('#returnBack2').attr('href', '#/budgetplans');
  end;
  dsBudgetPlan.ParamByName('BPLANID').asInteger := FBudgetPlanId;
  dsBudgetPlan.ParamByName('DOSSIERID').asInteger := DossierID;

  if FBudgetPlanId > -1 then
    jQuery('#btnPrint').css('display', '');
end;

procedure TfrmBudgetPlan.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsBudgetPlan := TP2WDADataset.Create(Self);
  eacBudgetPlan := TPW2DataElementMapper.Create(Self);
  dsBudgetPlanDetails := TP2WDADataset.Create(Self);
  dsReset := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsBudgetPlan.BeforeLoadDFMValues;
  eacBudgetPlan.BeforeLoadDFMValues;
  dsBudgetPlanDetails.BeforeLoadDFMValues;
  dsReset.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtName';
      Name := 'edtName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidFrom';
      Name := 'ddtValidFrom';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtValidTill';
      Name := 'ddtValidTill';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'Action4';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave2';
      Name := 'btnSave2';
      SetEvent(Self, 'OnExecute', 'onSaveBudgetDetails');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnReset2';
      Name := 'btnReset2';
      SetEvent(Self, 'OnExecute', 'onResetAmounts');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnPrint';
      Name := 'btnPrint';
      SetEvent(Self, 'OnExecute', 'onPrintPlan');
    end;
    dsBudgetPlan.SetParentComponent(Self);
    dsBudgetPlan.Name := 'dsBudgetPlan';
    dsBudgetPlan.TableName := 'SettlementPlanBudgetPlans';
    dsBudgetPlan.Params.Clear;
    with dsBudgetPlan.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BPLANID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsBudgetPlan.Params.Add do
    begin
      DataType := ftInteger;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    with dsBudgetPlan.Params.Add do
    begin
      DataType := ftInteger;
      Name := 'SettlementPlanID';
      ParamType := ptInput;
    end;
    dsBudgetPlan.WhereClause := '<?xml version="1.0" encoding="UTF-8"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>bplid</field><parameter type="LargeInt">BPLANID</parameter></binaryoperation><binaryoperation operator="Equal"><field>bpldossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></binaryoperation></where></query>';
    dsBudgetPlan.DAOptions := [doRefreshAllFields];
    dsBudgetPlan.AfterOpen := dsBudgetPlanAfterOpen;
    dsBudgetPlan.AfterApplyUpdates := dsBudgetPlanAfterApplyUpdates;
    dsBudgetPlan.Left := 40;
    dsBudgetPlan.Top := 88;
    eacBudgetPlan.SetParentComponent(Self);
    eacBudgetPlan.Name := 'eacBudgetPlan';
    eacBudgetPlan.ActionLinks.Clear;
    with eacBudgetPlan.ActionLinks.Add do
    begin
      ActionName := 'edtName';
      FieldName := 'bplname';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
    end;
    with eacBudgetPlan.ActionLinks.Add do
    begin
      ActionName := 'ddtValidFrom';
      FieldName := 'bplvalidfrom';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
      ClearValue := 'bplvalidfrom';
    end;
    with eacBudgetPlan.ActionLinks.Add do
    begin
      ActionName := 'ddtValidTill';
      FieldName := 'bplvalidtill';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
    end;
    eacBudgetPlan.ActionList := alForm;
    eacBudgetPlan.Dataset := dsBudgetPlan;
    eacBudgetPlan.Left := 40;
    eacBudgetPlan.Top := 160;
    dsBudgetPlanDetails.SetParentComponent(Self);
    dsBudgetPlanDetails.Name := 'dsBudgetPlanDetails';
    dsBudgetPlanDetails.TableName := 'budgetplandetail';
    dsBudgetPlanDetails.Params.Clear;
    with dsBudgetPlanDetails.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'BPLANID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsBudgetPlanDetails.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>bpdbudgetplanfk</field><parameter type="LargeInt">BPLANID</parameter></binaryoperation></where></query>';
    dsBudgetPlanDetails.DAOptions := [doRefreshAllFields];
    dsBudgetPlanDetails.AfterOpen := dsBudgetPlanDetailsAfterOpen;
    dsBudgetPlanDetails.AfterApplyUpdates := dsBudgetPlanDetailsAfterApplyUpdates;
    dsBudgetPlanDetails.Left := 40;
    dsBudgetPlanDetails.Top := 232;
    dsReset.SetParentComponent(Self);
    dsReset.Name := 'dsReset';
    dsReset.TableName := 'budgetplandetail';
    dsReset.Params.Clear;
    with dsReset.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'ID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsReset.WhereClause := '<?xml version="1.0" encoding="UTF-8"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>bpdid</field><parameter type="LargeInt">ID</parameter></binaryoperation></where></query>';
    dsReset.DAOptions := [doRefreshAllFields];
    dsReset.AfterOpen := dsResetAfterOpen;
    dsReset.Left := 584;
    dsReset.Top := 16;
  finally
    alForm.AfterLoadDFMValues;
    dsBudgetPlan.AfterLoadDFMValues;
    eacBudgetPlan.AfterLoadDFMValues;
    dsBudgetPlanDetails.AfterLoadDFMValues;
    dsReset.AfterLoadDFMValues;
  end;
end;

end.
