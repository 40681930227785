unit Forms.Contacts.List;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, pas2web.dadatasethelper,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, libjquery;

type
  TfrmContactList = class(TfrmBase)
    dsContacts: TP2WDADataset;
    dsContactscntid: TLargeintField;
    dsContactscntcreatedon: TDateTimeField;
    dsContactscntcreatedbyfk: TLargeintField;
    dsContactscntchangedon: TDateTimeField;
    dsContactscntchangedbyfk: TLargeintField;
    dsContactscntcompanyfk: TLargeintField;
    dsContactscntfirstname: TStringField;
    dsContactscntlastname: TStringField;
    dsContactscntprofession: TStringField;
    dsContactscntbirthdateon: TDateTimeField;
    dsContactscntisbirthdateunknown: TBooleanField;
    dsContactscntgender: TStringField;
    dsContactscntcityofbirthfk: TLargeintField;
    dsContactscntcityofbirthname: TStringField;
    dsContactscntcityofbirthzip: TStringField;
    dsContactscntnationalityfk: TLargeintField;
    dsContactscntnationality2: TStringField;
    dsContactscntnationality: TStringField;
    dsContactscntpicturefk: TLargeintField;
    dsContactscntkbonr: TStringField;
    dsContactscntremark: TStringField;
    dsContactscntpersonfk: TLargeintField;
    dsContactscntsalutation: TStringField;
    dsContactscntsearchname: TStringField;
    dsContactscntprefixes: TStringField;
    dsContactscntfriendlytitle: TStringField;
    dcGrid: TP2WDatatable;
    procedure OnDoSearch(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
    // FTerm : string;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    procedure DoSearch(aLastNameInitialOnly: Boolean; aTerm: String);
  protected procedure LoadDFMValues; override; end;

var
  frmContactList: TfrmContactList;

implementation

uses
  Modules.Server, Units.ActionUtils;

{$R *.dfm}

procedure TfrmContactList.OnDoSearch(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if Length(alForm['searchTerm'].value) > 1 then
    window.location.href := '#/contact/search/' + alForm['searchTerm'].value;
end;

procedure TfrmContactList.WebFormCreate(Sender: TObject);
begin
  inherited;
  dsContacts.DAConnection:=dmServer.ContactConnection;
end;

procedure TfrmContactList.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmContactList.setParams(const Params: TStrings);

begin
  inherited;
  // if Params.Values['TERM'] <> '' then
  //   FTerm := Params.Values['TERM'] + '%';
end;

procedure TfrmContactList.DoSearch(aLastNameInitialOnly: Boolean;
  aTerm: String);
  procedure onGetResult(contactDataArr : TJSArray);
  begin
    dcGrid.Data := contactDataArr;
    dcGrid.RenderTranslated;

    JQuery('#totalContacts').css('display', '');
    JQuery('#total').text(
        IntToStr(contactDataArr.flength)
    );
  end;

begin
  if aTerm = '' then
  begin
    aTerm:=String(dmServer.GetLocalData('ContactsSearchTerm'));
    if aTerm='' then
      aTerm:='A';
    aLastNameInitialOnly := length(aTerm)=1;
  end;

  jQuery('a[class*="page-link"]').removeClass('bg-yellow');
  if (Length(aTerm) = 1) or (aTerm='*other*') then
    jQuery('a[href*="/list/' + aTerm + '"]').AddClass('bg-yellow');
  Server.SetLocalData('ContactsSearchTerm',aTerm);
  if aTerm='*other*' then
    Server.SearchOtherContacts(@onGetResult)
  else
    Server.SimpleSearchContactsForDatatable(aTerm, aLastNameInitialOnly, @onGetResult);
end;

procedure TfrmContactList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsContacts := TP2WDADataset.Create(Self);
  dsContactscntid := TLargeintField.Create(Self);
  dsContactscntcreatedon := TDateTimeField.Create(Self);
  dsContactscntcreatedbyfk := TLargeintField.Create(Self);
  dsContactscntchangedon := TDateTimeField.Create(Self);
  dsContactscntchangedbyfk := TLargeintField.Create(Self);
  dsContactscntcompanyfk := TLargeintField.Create(Self);
  dsContactscntfirstname := TStringField.Create(Self);
  dsContactscntlastname := TStringField.Create(Self);
  dsContactscntprofession := TStringField.Create(Self);
  dsContactscntbirthdateon := TDateTimeField.Create(Self);
  dsContactscntisbirthdateunknown := TBooleanField.Create(Self);
  dsContactscntgender := TStringField.Create(Self);
  dsContactscntcityofbirthfk := TLargeintField.Create(Self);
  dsContactscntcityofbirthname := TStringField.Create(Self);
  dsContactscntcityofbirthzip := TStringField.Create(Self);
  dsContactscntnationalityfk := TLargeintField.Create(Self);
  dsContactscntnationality2 := TStringField.Create(Self);
  dsContactscntnationality := TStringField.Create(Self);
  dsContactscntpicturefk := TLargeintField.Create(Self);
  dsContactscntkbonr := TStringField.Create(Self);
  dsContactscntremark := TStringField.Create(Self);
  dsContactscntpersonfk := TLargeintField.Create(Self);
  dsContactscntsalutation := TStringField.Create(Self);
  dsContactscntsearchname := TStringField.Create(Self);
  dsContactscntprefixes := TStringField.Create(Self);
  dsContactscntfriendlytitle := TStringField.Create(Self);
  dcGrid := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsContacts.BeforeLoadDFMValues;
  dsContactscntid.BeforeLoadDFMValues;
  dsContactscntcreatedon.BeforeLoadDFMValues;
  dsContactscntcreatedbyfk.BeforeLoadDFMValues;
  dsContactscntchangedon.BeforeLoadDFMValues;
  dsContactscntchangedbyfk.BeforeLoadDFMValues;
  dsContactscntcompanyfk.BeforeLoadDFMValues;
  dsContactscntfirstname.BeforeLoadDFMValues;
  dsContactscntlastname.BeforeLoadDFMValues;
  dsContactscntprofession.BeforeLoadDFMValues;
  dsContactscntbirthdateon.BeforeLoadDFMValues;
  dsContactscntisbirthdateunknown.BeforeLoadDFMValues;
  dsContactscntgender.BeforeLoadDFMValues;
  dsContactscntcityofbirthfk.BeforeLoadDFMValues;
  dsContactscntcityofbirthname.BeforeLoadDFMValues;
  dsContactscntcityofbirthzip.BeforeLoadDFMValues;
  dsContactscntnationalityfk.BeforeLoadDFMValues;
  dsContactscntnationality2.BeforeLoadDFMValues;
  dsContactscntnationality.BeforeLoadDFMValues;
  dsContactscntpicturefk.BeforeLoadDFMValues;
  dsContactscntkbonr.BeforeLoadDFMValues;
  dsContactscntremark.BeforeLoadDFMValues;
  dsContactscntpersonfk.BeforeLoadDFMValues;
  dsContactscntsalutation.BeforeLoadDFMValues;
  dsContactscntsearchname.BeforeLoadDFMValues;
  dsContactscntprefixes.BeforeLoadDFMValues;
  dsContactscntfriendlytitle.BeforeLoadDFMValues;
  dcGrid.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'searchTerm';
      Name := 'searchTerm';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'search';
      Name := 'search';
      SetEvent(Self, 'OnExecute', 'OnDoSearch');
    end;
    dsContacts.SetParentComponent(Self);
    dsContacts.Name := 'dsContacts';
    dsContacts.TableName := 'ContactOverview';
    dsContacts.Params.Clear;
    with dsContacts.Params.Add do
    begin
      DataType := ftString;
      Name := 'LETTER';
      ParamType := ptInput;
      Value := 'A';
    end;
    dsContacts.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Like"><field>cntlastname</field><parameter type="String" null="A">LETTER</parameter></binaryoperation></where></query>';
    dsContacts.DAOptions := [];
    dsContacts.Left := 208;
    dsContacts.Top := 184;
    dsContactscntid.SetParentComponent(dsContacts);
    dsContactscntid.Name := 'dsContactscntid';
    dsContactscntid.FieldName := 'cntid';
    dsContactscntcreatedon.SetParentComponent(dsContacts);
    dsContactscntcreatedon.Name := 'dsContactscntcreatedon';
    dsContactscntcreatedon.FieldName := 'cntcreatedon';
    dsContactscntcreatedbyfk.SetParentComponent(dsContacts);
    dsContactscntcreatedbyfk.Name := 'dsContactscntcreatedbyfk';
    dsContactscntcreatedbyfk.FieldName := 'cntcreatedbyfk';
    dsContactscntchangedon.SetParentComponent(dsContacts);
    dsContactscntchangedon.Name := 'dsContactscntchangedon';
    dsContactscntchangedon.FieldName := 'cntchangedon';
    dsContactscntchangedbyfk.SetParentComponent(dsContacts);
    dsContactscntchangedbyfk.Name := 'dsContactscntchangedbyfk';
    dsContactscntchangedbyfk.FieldName := 'cntchangedbyfk';
    dsContactscntcompanyfk.SetParentComponent(dsContacts);
    dsContactscntcompanyfk.Name := 'dsContactscntcompanyfk';
    dsContactscntcompanyfk.FieldName := 'cntcompanyfk';
    dsContactscntfirstname.SetParentComponent(dsContacts);
    dsContactscntfirstname.Name := 'dsContactscntfirstname';
    dsContactscntfirstname.FieldName := 'cntfirstname';
    dsContactscntfirstname.Size := 64;
    dsContactscntlastname.SetParentComponent(dsContacts);
    dsContactscntlastname.Name := 'dsContactscntlastname';
    dsContactscntlastname.FieldName := 'cntlastname';
    dsContactscntlastname.Size := 127;
    dsContactscntprofession.SetParentComponent(dsContacts);
    dsContactscntprofession.Name := 'dsContactscntprofession';
    dsContactscntprofession.FieldName := 'cntprofession';
    dsContactscntprofession.Size := 64;
    dsContactscntbirthdateon.SetParentComponent(dsContacts);
    dsContactscntbirthdateon.Name := 'dsContactscntbirthdateon';
    dsContactscntbirthdateon.FieldName := 'cntbirthdateon';
    dsContactscntisbirthdateunknown.SetParentComponent(dsContacts);
    dsContactscntisbirthdateunknown.Name := 'dsContactscntisbirthdateunknown';
    dsContactscntisbirthdateunknown.FieldName := 'cntisbirthdateunknown';
    dsContactscntgender.SetParentComponent(dsContacts);
    dsContactscntgender.Name := 'dsContactscntgender';
    dsContactscntgender.FieldName := 'cntgender';
    dsContactscntgender.FixedChar := True;
    dsContactscntgender.Size := 1;
    dsContactscntcityofbirthfk.SetParentComponent(dsContacts);
    dsContactscntcityofbirthfk.Name := 'dsContactscntcityofbirthfk';
    dsContactscntcityofbirthfk.FieldName := 'cntcityofbirthfk';
    dsContactscntcityofbirthname.SetParentComponent(dsContacts);
    dsContactscntcityofbirthname.Name := 'dsContactscntcityofbirthname';
    dsContactscntcityofbirthname.FieldName := 'cntcityofbirthname';
    dsContactscntcityofbirthname.Size := 100;
    dsContactscntcityofbirthzip.SetParentComponent(dsContacts);
    dsContactscntcityofbirthzip.Name := 'dsContactscntcityofbirthzip';
    dsContactscntcityofbirthzip.FieldName := 'cntcityofbirthzip';
    dsContactscntcityofbirthzip.Size := 15;
    dsContactscntnationalityfk.SetParentComponent(dsContacts);
    dsContactscntnationalityfk.Name := 'dsContactscntnationalityfk';
    dsContactscntnationalityfk.FieldName := 'cntnationalityfk';
    dsContactscntnationality2.SetParentComponent(dsContacts);
    dsContactscntnationality2.Name := 'dsContactscntnationality2';
    dsContactscntnationality2.FieldName := 'cntnationality2';
    dsContactscntnationality2.Size := 2;
    dsContactscntnationality.SetParentComponent(dsContacts);
    dsContactscntnationality.Name := 'dsContactscntnationality';
    dsContactscntnationality.FieldName := 'cntnationality';
    dsContactscntnationality.Size := 40;
    dsContactscntpicturefk.SetParentComponent(dsContacts);
    dsContactscntpicturefk.Name := 'dsContactscntpicturefk';
    dsContactscntpicturefk.FieldName := 'cntpicturefk';
    dsContactscntkbonr.SetParentComponent(dsContacts);
    dsContactscntkbonr.Name := 'dsContactscntkbonr';
    dsContactscntkbonr.FieldName := 'cntkbonr';
    dsContactscntkbonr.Size := 10;
    dsContactscntremark.SetParentComponent(dsContacts);
    dsContactscntremark.Name := 'dsContactscntremark';
    dsContactscntremark.FieldName := 'cntremark';
    dsContactscntremark.Size := 255;
    dsContactscntpersonfk.SetParentComponent(dsContacts);
    dsContactscntpersonfk.Name := 'dsContactscntpersonfk';
    dsContactscntpersonfk.FieldName := 'cntpersonfk';
    dsContactscntsalutation.SetParentComponent(dsContacts);
    dsContactscntsalutation.Name := 'dsContactscntsalutation';
    dsContactscntsalutation.FieldName := 'cntsalutation';
    dsContactscntsalutation.Size := 32;
    dsContactscntsearchname.SetParentComponent(dsContacts);
    dsContactscntsearchname.Name := 'dsContactscntsearchname';
    dsContactscntsearchname.FieldName := 'cntsearchname';
    dsContactscntsearchname.Size := 50;
    dsContactscntprefixes.SetParentComponent(dsContacts);
    dsContactscntprefixes.Name := 'dsContactscntprefixes';
    dsContactscntprefixes.FieldName := 'cntprefixes';
    dsContactscntprefixes.Size := 32;
    dsContactscntfriendlytitle.SetParentComponent(dsContacts);
    dsContactscntfriendlytitle.Name := 'dsContactscntfriendlytitle';
    dsContactscntfriendlytitle.FieldName := 'cntfriendlytitle';
    dsContactscntfriendlytitle.Size := 32;
    dcGrid.SetParentComponent(Self);
    dcGrid.Name := 'dcGrid';
    dcGrid.Columns.Clear;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'cntsearchname';
      Title := 'Search Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'cntfirstname';
      Title := 'First Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'cntlastname';
      Title := 'Last Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'cntkbonr';
      Title := 'KBO Nr';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'cntbirthdateon';
      Title := 'Birth Date';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'cntid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/contact/edit/{{0}}/';
    end;
    dcGrid.DataSet := dsContacts;
    dcGrid.Language := lEnglish;
    dcGrid.IsResponsive := False;
    dcGrid.GridID := 'grdContacts';
    dcGrid.UseFieldIndex := True;
    dcGrid.ShowSearch := False;
    dcGrid.ShowNumberOfEntries := False;
    dcGrid.ShowEntriesInfo := False;
    dcGrid.Paginate := True;
    dcGrid.DisplayReadOnly := False;
    dcGrid.CalculateTableWidth := True;
    dcGrid.Left := 208;
    dcGrid.Top := 248;
  finally
    alForm.AfterLoadDFMValues;
    dsContacts.AfterLoadDFMValues;
    dsContactscntid.AfterLoadDFMValues;
    dsContactscntcreatedon.AfterLoadDFMValues;
    dsContactscntcreatedbyfk.AfterLoadDFMValues;
    dsContactscntchangedon.AfterLoadDFMValues;
    dsContactscntchangedbyfk.AfterLoadDFMValues;
    dsContactscntcompanyfk.AfterLoadDFMValues;
    dsContactscntfirstname.AfterLoadDFMValues;
    dsContactscntlastname.AfterLoadDFMValues;
    dsContactscntprofession.AfterLoadDFMValues;
    dsContactscntbirthdateon.AfterLoadDFMValues;
    dsContactscntisbirthdateunknown.AfterLoadDFMValues;
    dsContactscntgender.AfterLoadDFMValues;
    dsContactscntcityofbirthfk.AfterLoadDFMValues;
    dsContactscntcityofbirthname.AfterLoadDFMValues;
    dsContactscntcityofbirthzip.AfterLoadDFMValues;
    dsContactscntnationalityfk.AfterLoadDFMValues;
    dsContactscntnationality2.AfterLoadDFMValues;
    dsContactscntnationality.AfterLoadDFMValues;
    dsContactscntpicturefk.AfterLoadDFMValues;
    dsContactscntkbonr.AfterLoadDFMValues;
    dsContactscntremark.AfterLoadDFMValues;
    dsContactscntpersonfk.AfterLoadDFMValues;
    dsContactscntsalutation.AfterLoadDFMValues;
    dsContactscntsearchname.AfterLoadDFMValues;
    dsContactscntprefixes.AfterLoadDFMValues;
    dsContactscntfriendlytitle.AfterLoadDFMValues;
    dcGrid.AfterLoadDFMValues;
  end;
end;

end.
