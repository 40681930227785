unit Forms.DossierDetail;

interface

uses
  System.SysUtils,
  System.Classes,
  Forms.Base,
  Modules.Server,
  pas2web.dadataset,
  Module.Contacts;

type
  TFrmDossierDetail = class(Tfrmbase)
  private
    FDossierID: NativeInt;
  protected
    procedure SetDossierID(const Value: NativeInt); virtual;
    class function FixCSRHTMLFile(const aFileName: string): string;
  public
    class function NeedReadOnlyCheck: Boolean; virtual;
    procedure setParams(const Params: TStrings); override;
    procedure MakeReadonly; override;
    // Create contact datamodule
    function CreateContactModule: TDMContacts;
    function DossierConnection: TP2WDAConnection; virtual;
    function PetitionConnection: TP2WDAConnection; virtual;
    function CSRConnection: TP2WDAConnection; virtual;
    function DossierPrefix: string; virtual;
    function DossierURL(aSuffix: string): string;
    property DossierID: NativeInt read FDossierID write SetDossierID;
  end;

implementation

uses
  pas2web.datatables,
  
dialogs;

{ TFrmDossierDetail }

function TFrmDossierDetail.CreateContactModule: TDMContacts;
begin
  Server.ContactConnection.Message.ClientID := Server.ClientID;
  Result := TDMContacts.Create(Self);
  Result.DossierConnection := DossierConnection;
end;

function TFrmDossierDetail.CSRConnection: TP2WDAConnection;
begin
  dmServer.CSRConnection.Message.ClientID := dmServer.ClientID;
  Result := dmServer.CSRConnection;
end;

function TFrmDossierDetail.DossierConnection: TP2WDAConnection;
begin
  Result := PetitionConnection;
end;

function TFrmDossierDetail.DossierPrefix: string;
begin
  Result := 'dossier';
end;

function TFrmDossierDetail.DossierURL(aSuffix: string): string;
begin
  if (aSuffix <> '') and (aSuffix[1] <> '/') then
    aSuffix := '/' + aSuffix;
  Result := Format('#%s/%d', [DossierPrefix, DossierID]) + aSuffix;
end;

class function TFrmDossierDetail.FixCSRHTMLFile(const aFileName: string): string;
begin
  Result := StringReplace(aFileName, 'CSR.', '', [rfIgnoreCase]);
end;

procedure TFrmDossierDetail.MakeReadonly;
var
  I: Integer;
begin
  inherited;
  for I := 0 to ComponentCount - 1 do
    if Components[I] is TP2WDatatable then
    begin
      Writeln('Setting ', Components[I].Name, ' to read-only');
      TP2WDatatable(Components[I]).DisplayReadonly := True;
    end;
end;

class function TFrmDossierDetail.NeedReadOnlyCheck: Boolean;
begin
  Result := True;
end;

function TFrmDossierDetail.PetitionConnection: TP2WDAConnection;
begin
  dmServer.PetitionConnection.Message.ClientID := dmServer.ClientID;
  Result := dmServer.PetitionConnection;
end;

procedure TFrmDossierDetail.SetDossierID(const Value: NativeInt);
begin
  FDossierID := Value;
  if NeedReadOnlyCheck then
    CheckReadOnly(Value);
end;

procedure TFrmDossierDetail.setParams(const Params: TStrings);
begin
  inherited;
  if Assigned(Params) and (Params.indexOfName('DossierID') <> -1) then
    DossierID := StrToIntDef(Params.Values['DossierID'], -1);
end;

end.
