unit Forms.Files.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  Data.DB, Datasnap.DBClient, pas2web.dadataset;

type
  TfrmBase = TfrmDossierDetail;
  TfrmFileDetails = class(TfrmBase)
    dsDocument: TP2WDADataset;
    dsFile: TP2WDADataset;
    procedure dsFileAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FileID : NativeInt;
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmFileDetails: TfrmFileDetails;

implementation

{$R *.dfm}

uses
  Units.ActionUtils, libjquery, Units.Types, Modules.Server;

procedure TfrmFileDetails.dsFileAfterOpen(DataSet: TDataSet);

begin
  if not DataSet.isEmpty and (FileID > -1) then
  begin
    dsDocument.ParamByName('FID').asInteger := FileID;
    DefaultDocumentBox.setDocBoxParams(
      DataSet.FieldByName('dofsourcetype').asInteger,
      DataSet.FieldByName('dofsourcefk').asInteger,
      DataSet.FieldByName('dofdoctype').asString,
      DossierID
    );
    DefaultDocumentBox.LoadDocuments(
      dsDocument, DossierID, DataSet.FieldByName('dofsourcefk').asInteger,
      'uploadList', true);

    alForm['fileName'].value := DataSet.FieldByName('doffilename').asString;
    alForm['fileType'].value := DataSet.FieldByName('dofmime').asString;
    alForm['docType'].value := Server.GetParamDisplayValue('DOCTYP', DataSet.FieldByName('dofdoctype').asString);
    alForm['createdOn'].value := FormatDateTime('dd/mm/yyyy', DataSet.FieldByName('dofcreatedon').asDateTime);
    alForm['changedOn'].value := FormatDateTime('dd/mm/yyyy', DataSet.FieldByName('dofchangedon').asDateTime);
    jquery('#info').css('display', '');
  end;
end;

procedure TfrmFileDetails.setParams(const Params: TStrings);
begin
  inherited;
  jquery('#returnBack').attr('href', DossierURL('/file'));
  FileID := StrToIntDef(Params.Values['FILEID'], -1);
  if FileID = -1 then
    DefaultDocumentBox.setDocBoxParams(1, DossierID, 'VARIA', DossierID)
  else
  begin
    jQuery('.fileinput-dropzone').css('display', 'none');
    dsFile.ParamByName('FID').asInteger := FileID;
    dsFile.ParamByName('DOSSIERID').asInteger := DossierID;
  end;
end;

procedure TfrmFileDetails.WebFormCreate(Sender: TObject);
begin
  inherited;
  DefaultDocumentBox.MultiDocBox := False;
  dsFile.DAConnection := DossierConnection;
end;

procedure TfrmFileDetails.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmFileDetails.WebFormShow(Sender: TObject);
begin
  inherited;
  server.DoOnParamsLoaded(procedure
    begin
      dsFile.Load([], nil);
    end);
end;

procedure TfrmFileDetails.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsDocument := TP2WDADataset.Create(Self);
  dsFile := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsDocument.BeforeLoadDFMValues;
  dsFile.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'fileName';
      Name := 'fileName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'fileType';
      Name := 'fileType';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'docType';
      Name := 'docType';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'createdOn';
      Name := 'createdOn';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'changedOn';
      Name := 'changedOn';
    end;
    dsDocument.SetParentComponent(Self);
    dsDocument.Name := 'dsDocument';
    dsDocument.TableName := 'dossierfile';
    dsDocument.Params.Clear;
    with dsDocument.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocument.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocument.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'FID';
      ParamType := ptInput;
    end;
    dsDocument.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofid</field><parameter type="LargeInt">FID</parameter></binaryoperation></binaryoperation></where></query>';
    dsDocument.DAOptions := [];
    dsDocument.Left := 536;
    dsDocument.Top := 48;
    dsFile.SetParentComponent(Self);
    dsFile.Name := 'dsFile';
    dsFile.TableName := 'dossierfile';
    dsFile.Params.Clear;
    with dsFile.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsFile.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'FID';
      ParamType := ptInput;
    end;
    dsFile.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofid</field><parameter type="LargeInt">FID</parameter></binaryoperation></binaryoperation></where></query>';
    dsFile.DAOptions := [];
    dsFile.AfterOpen := dsFileAfterOpen;
    dsFile.Left := 32;
    dsFile.Top := 96;
  finally
    alForm.AfterLoadDFMValues;
    dsDocument.AfterLoadDFMValues;
    dsFile.AfterLoadDFMValues;
  end;
end;

end.