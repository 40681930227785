unit Forms.CSR.Expense.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Expense.Detail, pas2web.dataelementmapper,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRExpenseDetail = class(TfrmExpenseDetail)
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRExpenseDetail: TfrmCSRExpenseDetail;

implementation

{$R *.dfm}

{ TfrmCSRExpenseDetail }

function TfrmCSRExpenseDetail.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRExpenseDetail.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRExpenseDetail.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRExpenseDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.