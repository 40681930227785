unit Module.Country;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper;

type
  TdmCountry = class(TDataModule)
    dsCountry: TP2WDADataset;
    dsCountryconID: TLargeintField;
    dsCountryconISO2: TWideStringField;
    dsCountryconISO3: TWideStringField;
    dsCountryconName: TWideStringField;
    procedure WebDataModuleCreate(Sender: TObject);
  private
  public
    { Public declarations }
    procedure LoadData(OnAfterOpen: TDataSetNotifyEvent);
  protected procedure LoadDFMValues; override; end;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

uses
  Modules.Server;


procedure TdmCountry.LoadData(OnAfterOpen: TDataSetNotifyEvent);
begin
  dsCountry.AfterOpen := OnAfterOpen;
  if dsCountry.Active then
    begin
    dsCountry.First;
    if Assigned(OnAfterOpen) then
      OnAfterOpen(dsCountry);
    end
  else
    dsCountry.Load([], nil);
end;

procedure TdmCountry.WebDataModuleCreate(Sender: TObject);
begin
  Server.GeoConnection.Message.ClientID := Server.ClientID;
  dsCountry.DAConnection := Server.GeoConnection;
  dsCountry.ParamByName('Language').AsInteger:=Server.NumericLanguage;
end;

procedure TdmCountry.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsCountry := TP2WDADataset.Create(Self);
  dsCountryconID := TLargeintField.Create(Self);
  dsCountryconISO2 := TWideStringField.Create(Self);
  dsCountryconISO3 := TWideStringField.Create(Self);
  dsCountryconName := TWideStringField.Create(Self);

  dsCountry.BeforeLoadDFMValues;
  dsCountryconID.BeforeLoadDFMValues;
  dsCountryconISO2.BeforeLoadDFMValues;
  dsCountryconISO3.BeforeLoadDFMValues;
  dsCountryconName.BeforeLoadDFMValues;
  try
    Name := 'dmCountry';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 150;
    Width := 215;
    dsCountry.SetParentComponent(Self);
    dsCountry.Name := 'dsCountry';
    dsCountry.TableName := 'LocalizedCountryOverview';
    dsCountry.Params.Clear;
    with dsCountry.Params.Add do
    begin
      DataType := ftInteger;
      Name := 'Language';
      ParamType := ptInput;
    end;
    dsCountry.DAOptions := [];
    dsCountry.Left := 24;
    dsCountry.Top := 16;
    dsCountryconID.SetParentComponent(dsCountry);
    dsCountryconID.Name := 'dsCountryconID';
    dsCountryconID.FieldName := 'conID';
    dsCountryconISO2.SetParentComponent(dsCountry);
    dsCountryconISO2.Name := 'dsCountryconISO2';
    dsCountryconISO2.FieldName := 'conISO2';
    dsCountryconISO2.Size := 2;
    dsCountryconISO3.SetParentComponent(dsCountry);
    dsCountryconISO3.Name := 'dsCountryconISO3';
    dsCountryconISO3.FieldName := 'conISO3';
    dsCountryconISO3.Size := 3;
    dsCountryconName.SetParentComponent(dsCountry);
    dsCountryconName.Name := 'dsCountryconName';
    dsCountryconName.FieldName := 'conName';
    dsCountryconName.Size := 100;
  finally
    dsCountry.AfterLoadDFMValues;
    dsCountryconID.AfterLoadDFMValues;
    dsCountryconISO2.AfterLoadDFMValues;
    dsCountryconISO3.AfterLoadDFMValues;
    dsCountryconName.AfterLoadDFMValues;
  end;
end;

end.
