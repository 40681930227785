unit Forms.Legalcase.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper, Units.DADatasetUtils,
  Forms.DossierDetail, Module.Contacts;

type
  TfrmBase = TfrmDossierDetail;
  TfrmLegalcaseDetail = class(TfrmBase)
    dsLegalCase: TP2WDADataset;
    dsPrimaryParty: TP2WDADataset;
    eacLegalCase: TPW2DataElementMapper;
    dsDocuments: TP2WDADataset;
    procedure dsLegalCaseAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsLegalCaseAfterOpen(DataSet: TDataSet);
    procedure dsPrimaryPartyAfterOpen(DataSet: TDataSet);
    procedure SaveBtnClicked(Sender: TObject; Element:
        TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure OnFieldValidationError(Sender: TObject; Source:
        TElementAction; dest: TField; aError: TElementError);
    procedure OnAmountValidation(Sender: TObject; Source:
        TElementAction; var aResult: Boolean);
    procedure OnRadioGroupValidation(Sender: TObject;
        Source: TElementAction; var aResult: Boolean);
    procedure ReadRadioGroupValue(Sender: TObject;
      Source: TElementAction; dest: TField; var aHandled: Boolean);
    procedure WriteRadioGroupValue(Sender: TObject;
      Source: TField; Dest: TElementAction; var aHandled: Boolean);
    procedure WebFormDestroy(Sender: TObject);
  private
    FDMContactOpponent: TDMContacts;
    FDMContactLawyer: TDMContacts;

    { Private declarations }

    procedure DoSaveLegalCaseForm;
    procedure LoadOpponent(const ContactID: Int64);
    procedure LoadLawyer(const ContactID: Int64);
    procedure SetupOpponent;
    procedure SetupLawyer;
    procedure DoLawyerSaved(Sender: TObject);
    procedure DoOpponentSaved(Sender: TObject);
  public
    { Public declarations }
    procedure MakeReadOnly; override;
    procedure setParams(const Params: TStrings); override;
    Property DMContactOpponent : TDMContacts Read FDMContactOpponent;
    Property DMContactLawyer : TDMContacts Read FDMContactLawyer;
    Procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmLegalcaseDetail: TfrmLegalcaseDetail;

implementation

{$R *.dfm}
uses
  pas2web.dadatasethelper, Units.ActionUtils, Units.Params, Modules.Server, Units.Strings, Units.Contacts;

{ TfrmLegalcaseDetail }

procedure TfrmLegalcaseDetail.SaveBtnClicked(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
var
  aval : Boolean;
  lid : Int64;

  procedure OnCheckLegalReferenceResult(aResult : Boolean; error : String);
  begin
    if aResult then
      dmContactOpponent.SaveIfValid
    else
      DisplayError('edtReference', 'invalid-feedback', error);
  end;

begin
  aVal:=False;
  if DMContactOpponent.ContactFieldsValid(True) then
    if DMContactLawyer.ContactFieldsValid(True) then
      aVal:= eacLegalCase.ValidateActions(True);
  if dsLegalCase.isEmpty then
    lid := -1
  else
    lid := dsLegalCase.FieldByName('lecid').asInteger;
  if aval then
    Server.CheckLegalReference(DossierID, Trim(alForm['edtReference'].value), lid, @OnCheckLegalReferenceResult);
end;


procedure TfrmLegalcaseDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDMContactLawyer:=CreateContactModule;
  FDMContactLawyer.Name:='dmContactLawyer';
  FDMContactOpponent:=CreateContactModule;
  FDMContactOpponent.Name:='dmContactOpponent';
  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsLegalCase.DAConnection := DossierConnection;
  dsPrimaryParty.DAConnection := Server.PetitionConnection;
  BindOnChangeEvent;
end;

procedure TfrmLegalcaseDetail.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FDMContactOpponent);
  FreeAndNil(FDMContactLawyer);
  inherited;
end;


procedure TfrmLegalcaseDetail.DoSaveLegalCaseForm;


begin
  if dsLegalCase.isEmpty then
  begin
    dsLegalCase.Insert;
    dsLegalCase.FieldByName('leccreatedon').asDateTime := now;
    dsLegalCase.FieldByName('leccreatedbyfk').asLargeInt := Server.UserID;
    dsLegalCase.FieldByName('lecdossierfk').asInteger := DossierID;
  end
  else
    dsLegalCase.Edit;
  dsLegalCase.FieldByName('lecchangedon').asDateTime := now;
  dsLegalCase.FieldByName('lecchangedbyfk').asLargeInt := Server.UserID;
  dsLegalCase.FieldByName('lecopponentfk').asInteger := dmContactOpponent.ContactID;
  dsLegalCase.FieldByName('leclawyerfk').asInteger := dmContactLawyer.ContactID;
  eacLegalCase.ElementsToDataset;
  dsLegalCase.Post;
  dsLegalCase.ApplyUpdates;
end;

procedure TfrmLegalcaseDetail.dsLegalCaseAfterApplyUpdates(Sender: TDataSet;
    Info: TResolveResults);

procedure uploadsCompleted;
  begin
    DiscardChanges := True;
    window.location.href := DossierURL('/legalcase');
  end;

begin
  if CheckResolveResults(info) then
  begin
    if DefaultDocumentBox.GetAwaitingUploadCount = 0 then
    begin
      DiscardChanges := True;
      window.location.href := DossierURL('/legalcase')
    end
    else
      DefaultDocumentBox.DoFileQueueUpload(Sender.FieldByName('lecid').asLargeInt,'', @uploadsCompleted);
  end;
end;

procedure TfrmLegalcaseDetail.dsLegalCaseAfterOpen(DataSet: TDataSet);

Var
  SourceID : NativeInt;

begin
  SourceID:=-1;
  if not DataSet.isEmpty then
  begin
    LoadOpponent(DataSet.FieldByName('lecopponentfk').asInteger);
    LoadLawyer(DataSet.FieldByName('leclawyerfk').asInteger);
    SourceID:=DataSet.FieldByName('lecid').asLargeInt
  end
  else
  begin
    LoadOpponent(-1);
    LoadLawyer(-1);
  end;
  DefaultDocumentBox.LoadDocuments(dsDocuments, DossierID, SourceID, 'uploadList');
  DefaultDocumentBox.setDocBoxParams(8, SourceID, 'LEGAL', DossierID);
  if Locate(dsPrimaryParty, 'docpersontype', 'SCH') then
    eacLegalCase.ActionLinks.Links[0].ClearValue := dsPrimaryParty.FieldByName('cntid').asString;
  eacLegalCase.DatasetToElements;
end;

procedure TfrmLegalcaseDetail.dsPrimaryPartyAfterOpen(DataSet: TDataSet);

Var
  S : String;

begin
  S:='';
  With Dataset do
    while not Eof do
    begin
    S:=S+Format( '<option value="%s">%s %s</option>',[
      FieldByName('cntid').asString,
      FieldByName('cntlastname').asString,
      FieldByName('cntfirstname').asString]);
    Next;
    end;
  With alForm['cbPrimary'] do
    innerHTML:=innerHTML+S;
  dsLegalCase.Load([], nil);
end;

procedure TfrmLegalcaseDetail.LoadLawyer(const ContactID: Int64);
begin
  SetupLawyer;
  dmContactLawyer.LoadContactData(ContactID,[cdpPersonal,cdpWPAddress],[]);
end;

procedure TfrmLegalcaseDetail.SetupOpponent;

Const
  RepPersonalFields : TPersonalFieldNames = (
    '','','','', '',
    '','','','','','edtOName',
    '','','','');


  RepAddressFields : TAddressFieldNames = (
    'edtOStreet','edtOHouseNo','edtOZip','edtOCity','', '', ''
  );

Var
  PersonalFields : TPersonalFieldEdits;
  AddressFields : TAddressFieldEdits;

begin
  PersonalFields.Names:=RepPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names:=RepAddressFields;
  AddressFields.SetFieldsFromNames;
  dmContactOpponent.PersonalFieldInfo:=PersonalFields;
  dmContactOpponent.WPAddressFieldInfo:=AddressFields;
  dmContactOpponent.FormIsReadOnly:=Self.FormIsReadOnly;

  dmContactOpponent.SearchButtonID:='selectOppenentBtn';
  dmContactOpponent.EditContactButtonID:='newOppenentBtn';
  dmContactOpponent.ContactDescription:=SContactOpponent;
  dmContactOpponent.OnAllSaved:=@DoOpponentSaved;
  dmContactOpponent.OnContactChanged := @OnContactChanged;
end;

procedure TfrmLegalcaseDetail.SetupLawyer;

Const
  RepPersonalFields : TPersonalFieldNames = (
    '','','','', '',
    '','','','','','edtLName',
    '','','','');


  RepAddressFields : TAddressFieldNames = (
    'edtLStreet','edtLHouseNo','edtLZip','edtLCity','', '', ''
  );

Var
  PersonalFields : TPersonalFieldEdits;
  AddressFields : TAddressFieldEdits;

begin
  PersonalFields.Names:=RepPersonalFields;
  PersonalFields.SetFieldsFromNames;
  AddressFields.Names:=RepAddressFields;
  AddressFields.SetFieldsFromNames;
  dmContactLawyer.PersonalFieldInfo:=PersonalFields;
  dmContactLawyer.WPAddressFieldInfo:=AddressFields;
  dmContactLawyer.FormIsReadOnly:=Self.FormIsReadOnly;
  dmContactLawyer.SearchButtonID:='selectLawyerBtn';
  dmContactLawyer.EditContactButtonID:='newLawyerBtn';
  dmContactLawyer.ContactDescription:=SContactLawyer;
  dmContactLawyer.OnAllSaved:=@DoLawyerSaved;
  dmContactLawyer.OnContactChanged := @OnContactChanged;
end;

procedure TfrmLegalcaseDetail.DoOpponentSaved(Sender : TObject);

begin
  dmContactLawyer.SaveIfValid;
end;

procedure TfrmLegalcaseDetail.canHandleRoute(previousURL: string;
  var allow: Boolean);
begin
  allow := DiscardChanges and ((DefaultDocumentBox.GetAwaitingUploadCount = 0) OR DiscardFiles);
end;

procedure TfrmLegalcaseDetail.DoLawyerSaved(Sender : TObject);

begin
  DoSaveLegalCaseForm;
end;

procedure TfrmLegalcaseDetail.LoadOpponent(const ContactID: Int64);
begin
  SetupOpponent;
  dmContactOpponent.LoadContactData(ContactID,[cdpPersonal,cdpWPAddress],[]);
end;

procedure TfrmLegalcaseDetail.MakeReadOnly;
begin
  inherited;
  dmContactOpponent.FormIsReadOnly:=Self.FormIsReadOnly;
  dmContactLawyer.FormIsReadOnly:=Self.FormIsReadOnly;
end;

procedure TfrmLegalcaseDetail.OnAmountValidation(Sender: TObject;
  Source: TElementAction; var aResult: Boolean);
begin
  aResult := isNumeric('edtAmount') and (StrToFloatDef(alForm['edtAmount'].value, 0) > 0);
end;

procedure TfrmLegalcaseDetail.OnFieldValidationError(Sender: TObject;
  Source: TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmLegalcaseDetail.OnRadioGroupValidation(Sender: TObject;
  Source: TElementAction; var aResult: Boolean);
begin
  aResult := isSelectedRadioGroup(Source.Name);
end;

procedure TfrmLegalcaseDetail.ReadRadioGroupValue(Sender: TObject;
  Source: TElementAction; dest: TField; var aHandled: Boolean);
begin
  dest.asBoolean := getRadioGroupValue(Source.Name) = 'Yes';
  aHandled := True;
end;

procedure TfrmLegalcaseDetail.setParams(const Params: TStrings);
begin
  Inherited;
  jQuery('#returnBack').attr('href',DossierURL('/legalcase'));
  dsLegalCase.ParamByName('DOSSIERID').asLargeInt := DossierID;
  dsLegalCase.ParamByName('LECID').aslargeInt := strToIntDef(Params.Values['LEGALCASEID'], -1);
  dsPrimaryParty.ParamByName('DOSSIERID').asLargeInt := DossierID;
end;

procedure TfrmLegalcaseDetail.WebFormShow(Sender: TObject);
begin
  inherited;
  dsPrimaryParty.Load([], nil);

end;

procedure TfrmLegalcaseDetail.WriteRadioGroupValue(Sender: TObject;
  Source: TField; Dest: TElementAction; var aHandled: Boolean);
begin
  if Source.asBoolean then
    setRadiogroupSelectedElement(Dest.DisplayName, 'Yes')
  else
    setRadiogroupSelectedElement(Dest.DisplayName, 'No');
  aHandled := True;
end;

procedure TfrmLegalcaseDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsLegalCase := TP2WDADataset.Create(Self);
  dsPrimaryParty := TP2WDADataset.Create(Self);
  eacLegalCase := TPW2DataElementMapper.Create(Self);
  dsDocuments := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsLegalCase.BeforeLoadDFMValues;
  dsPrimaryParty.BeforeLoadDFMValues;
  eacLegalCase.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'cbPrimary';
      Name := 'cbPrimary';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtAmount';
      Name := 'edtAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtCause';
      Name := 'edtCause';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtReference';
      Name := 'edtReference';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtCourt';
      Name := 'edtCourt';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtRemark';
      Name := 'edtRemark';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdGroupCompleted';
      PreventDefault := False;
      Selector := 'input[name="rdGroupCompleted"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClicked');
    end;
    dsLegalCase.SetParentComponent(Self);
    dsLegalCase.Name := 'dsLegalCase';
    dsLegalCase.TableName := 'legalcase';
    dsLegalCase.Params.Clear;
    with dsLegalCase.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsLegalCase.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'LECID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsLegalCase.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>lecdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>lecid</field><parameter type="LargeInt">LECID</parameter></binaryoperation></binaryoperation></where></query>';
    dsLegalCase.DAOptions := [];
    dsLegalCase.AfterOpen := dsLegalCaseAfterOpen;
    dsLegalCase.AfterApplyUpdates := dsLegalCaseAfterApplyUpdates;
    dsLegalCase.Left := 40;
    dsLegalCase.Top := 88;
    dsPrimaryParty.SetParentComponent(Self);
    dsPrimaryParty.Name := 'dsPrimaryParty';
    dsPrimaryParty.TableName := 'DossierPersons';
    dsPrimaryParty.Params.Clear;
    with dsPrimaryParty.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsPrimaryParty.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>docdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="In"><field>docpersontype</field><list><constant type="String">SCH</constant><constant type="String">PART</constant></list></binaryoperation></binaryoperation></where></query>';
    dsPrimaryParty.DAOptions := [];
    dsPrimaryParty.AfterOpen := dsPrimaryPartyAfterOpen;
    dsPrimaryParty.Left := 104;
    dsPrimaryParty.Top := 24;
    eacLegalCase.SetParentComponent(Self);
    eacLegalCase.Name := 'eacLegalCase';
    eacLegalCase.ActionLinks.Clear;
    with eacLegalCase.ActionLinks.Add do
    begin
      ActionName := 'cbPrimary';
      FieldName := 'lecprimaryfk';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacLegalCase.ActionLinks.Add do
    begin
      ActionName := 'edtAmount';
      FieldName := 'lecamount';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
      SetEvent(Self, 'OnValidation', 'OnAmountValidation');
      ClearValue := '0';
    end;
    with eacLegalCase.ActionLinks.Add do
    begin
      ActionName := 'edtCause';
      FieldName := 'leccause';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacLegalCase.ActionLinks.Add do
    begin
      ActionName := 'edtReference';
      FieldName := 'lecreference';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacLegalCase.ActionLinks.Add do
    begin
      ActionName := 'edtCourt';
      FieldName := 'leccourt';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacLegalCase.ActionLinks.Add do
    begin
      ActionName := 'edtRemark';
      FieldName := 'lecremark';
      Options := [];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacLegalCase.ActionLinks.Add do
    begin
      ActionName := 'rdGroupCompleted';
      FieldName := 'leciscomplete';
      DataAttribute := 'i';
      Options := [aoRequired];
      SetEvent(Self, 'OnFieldToAction', 'WriteRadioGroupValue');
      SetEvent(Self, 'OnActionToField', 'ReadRadioGroupValue');
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
      SetEvent(Self, 'OnValidation', 'OnRadioGroupValidation');
    end;
    eacLegalCase.ActionList := alForm;
    eacLegalCase.Dataset := dsLegalCase;
    eacLegalCase.Left := 40;
    eacLegalCase.Top := 160;
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">LEGAL</constant></binaryoperation></binaryoperation></where></query>';
    dsDocuments.DAOptions := [];
    dsDocuments.Left := 512;
    dsDocuments.Top := 312;
  finally
    alForm.AfterLoadDFMValues;
    dsLegalCase.AfterLoadDFMValues;
    dsPrimaryParty.AfterLoadDFMValues;
    eacLegalCase.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
  end;
end;

end.
