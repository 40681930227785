unit lib.formtranslation;

interface

uses
  Classes,
  WEBLib.Forms,
  pas2web.htmltranslator,
  Units.FormManager,
  JS,
  Web,
  types,
  libjquery,
  lib.bootstrap,
  lib.jqueryhelpers;

type
  TP2WFormTranslator = class(TP2WHTMLTranslator)
  private
    class var _Instance: TP2WFormTranslator;
  private
    FLoadScope: string;
    FInfoRoot: TJSObject;
    FLoadForm: TWebForm;
    FEnableInformation: Boolean;
    function GetClarification(const aScope, aName: string): string;
    procedure CreatePopovers(aContext: TJSElement; const aScope: string);
    procedure CheckInformationScope;
    function GetInfoKeys(const aScope: string): TStringDynArray;
  protected
    procedure SetLanguage(const Value: string); override;
    function CreatePopoverOptions(const aScope: string): TP2WBootstrapPopoverOptions;
    procedure RegisterPopovers(aContext: TJSElement; const aScope: string);
    procedure DoLanguageLoaded; override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure AddInformationBelow(aEl: TJSHTMLElement; aScope: string = '');
  public
    class constructor Create;
    class function Instance: TP2WFormTranslator;
    procedure TranslateForm(aForm: TWebForm; aScope: string = '');
    procedure AddInformation(aForm: TWebForm; aScope: string = '');
    property EnableInformation: Boolean read FEnableInformation write FEnableInformation;
    property InfoKeys[aScope: string]: TStringDynArray read GetInfoKeys;
  end;

function FormTranslator: TP2WFormTranslator;

implementation

uses
  SysUtils;

resourcestring
  SErrNoLanguageSet = 'No language set';

function FormTranslator: TP2WFormTranslator;
begin
  Result := TP2WFormTranslator.Instance;
end;

{ TP2WFormTranslator }

function TP2WFormTranslator.GetClarification(const aScope, aName: string): string;
begin
  Result := GetMessageByName(FInfoRoot, aScope, aName);
end;

function TP2WFormTranslator.CreatePopoverOptions(const aScope: string): TP2WBootstrapPopoverOptions;

  function ContentFunction(arg: jsValue): jsValue;
  var
    aStr: string;
  begin
    aStr := GetClarification(aScope, string(TJSHTMLElement(JSThis).DataSet['translate']));
    Result := aStr;
  end;

var
  aDelayOptions: TP2WBootstrapPopoverDelayOptions;
begin
  aDelayOptions := TP2WBootstrapPopoverDelayOptions.new();
  aDelayOptions.show := 500;
  aDelayOptions.hide := 3000;
  Result := TP2WBootstrapPopoverOptions.new();
  Result.html := True;
  Result.placement := 'auto';
  Result.trigger := 'hover';
  Result.contentFn := @ContentFunction;
  Result.delayObj := aDelayOptions;
end;

procedure TP2WFormTranslator.RegisterPopovers(aContext: TJSElement; const aScope: string);
begin
  TJQuery(JQuery('[data-toggle="popover"][data-info]', aContext)).PopOver(TP2WBootstrapPopoverOptions(CreatePopoverOptions(aScope)));
end;

procedure TP2WFormTranslator.SetLanguage(const Value: string);
begin
  inherited;
  CheckInformationScope;
end;

function TP2WFormTranslator.GetInfoKeys(const aScope: string): TStringDynArray;
var
  aScopeObj: TJSObject;
begin
  aScopeObj := GetScope(FInfoRoot, aScope);
  if aScopeObj <> nil then
    Result := TJSObject.keys(aScopeObj);
end;

procedure TP2WFormTranslator.CreatePopovers(aContext: TJSElement; const aScope: string);
var
  aNames: TStringDynArray;
  i: integer;
begin
  aNames := InfoKeys[aScope];
  for i := 0 to high(aNames) do
  begin
    if aContext <> nil then
      JQuery('[data-translate="' + aNames[i] + '"]', aContext).attr('data-toggle', 'popover').attr('data-info', '').addClass('hovericon');
  end;
end;

procedure TP2WFormTranslator.AddInformation(aForm: TWebForm; aScope: string);
begin
  if aScope = '' then
    aScope := LowerCase(aForm.UnitName);
  AddInformationBelow(TJSHTMLElement(document.GetElementById(aForm.FormContainer)), aScope);
end;

procedure TP2WFormTranslator.AddInformationBelow(aEl: TJSHTMLElement; aScope: string);
begin
  if aEl = nil then
  begin
    Writeln('AddinformationBelow called with empty element and scope ' + aScope);
    Exit;
  end;
  CreatePopovers(aEl, aScope);
  RegisterPopovers(aEl, aScope);
end;

class constructor TP2WFormTranslator.Create;
begin
  _Instance := TP2WFormTranslator.Create(nil);
end;

procedure TP2WFormTranslator.CheckInformationScope;

begin
  FInfoRoot := GetScope('__information__');
  if FInfoRoot = nil then
    Writeln('No __information__ scope found in language: ' + Language);
end;

procedure TP2WFormTranslator.DoLanguageLoaded;
var
  Frm: TWebForm;
  aScope: string;
begin
  CheckInformationScope;
  if (FLoadForm <> nil) then
  begin
    FLoadForm.RemoveFreeNotification(Self);
    Frm := FLoadForm;
    aScope := FLoadScope;
    FLoadScope := '';
    FLoadForm := nil;
    TranslateBelow(Frm.ElementHandle, aScope);
    if EnableInformation then
      AddInformationBelow(Frm.ElementHandle, aScope);
  end;
  inherited;
end;

class function TP2WFormTranslator.Instance: TP2WFormTranslator;
begin
  Result := _Instance;
end;

procedure TP2WFormTranslator.Notification(AComponent: TComponent; Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FLoadForm) then
    FLoadForm := nil;
end;

procedure TP2WFormTranslator.TranslateForm(aForm: TWebForm; aScope: string = '');
begin
  if aScope = '' then
    aScope := LowerCase(aForm.UnitName);
  // We're still loading
  if (Language = '') then
  begin
    if (loadingLanguage <> '') then
    begin
      FLoadScope := aScope;
      FLoadForm := aForm;
      if Assigned(aForm) then
        aForm.FreeNotification(Self);
    end
    else
      raise EHTMLTranslator.Create(SErrNoLanguageSet);
  end
  else
  begin
    TranslateBelow(aForm.ElementHandle, aScope);
    if EnableInformation then
      AddInformationBelow(aForm.ElementHandle, aScope);
  end;
end;

end.
