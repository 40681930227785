unit Forms.Start;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions,
  libjquery, lib.fullcalendar;

type
  TfrmStart = class(TfrmBase)
    procedure WebFormCreate(Sender: TObject);
    procedure DoCalendarNextClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoCalendarPrevClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure DoCalendarTodayClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    FCalendar: TP2WFCFullCalendar;
    FCalendarList: TP2WFCFullCalendar;

  Public
    procedure ShowCalendar;
    procedure ShowCalendarList;
  protected procedure LoadDFMValues; override; end;

implementation

Uses Units.ActionUtils, Modules.Server;

{$R *.dfm}

procedure TfrmStart.WebFormCreate(Sender: TObject);
begin
  inherited;
end;

{ TfrmStart }

procedure TfrmStart.ShowCalendar;
var
  Opts: TP2WFCFullCalendarOptions;
begin
  Entermethod('ShowCalendar');
  Opts := TP2WFCFullCalendarOptions.new;
  With Opts do
  begin
    themeSystem := 'bootstrap';
    headerToolbar := TP2WFCCalendarHeaderFooterOptions.new;
//    plugins := [fcInteractionPlugin, fcBootstrapPlugin, fcListPlugin, fcDayGridPlugin];
    with headerToolbar do
    begin
      start := 'title';
      center := '';
      end_ := 'timeGridDay,timeGridWeek,dayGridMonth'
    end;

//    buttonText := TButtonText.new;
//    with buttonText do
//    begin
//      month := 'Maand';
//      week := 'Week';
//      day := 'Dag';
//    end;

    initialView := fcViewMonth;
    heightStr := '100%';
    // navLinks: true,
    // can click day/week names to navigate views
    // editable: true,
    eventLimit := true;
    locale := 'nl';
    // allow "more" link when too many events
    eventsStr:='assets/data/events.json';
  end;
  DoLog('Created options');
  FCalendar := TP2WFCFullCalendar.new(TJSHTMLElement(alForm['Calendar'].ElementHandle), Opts);
  FCalendar.render;
  Exitmethod('ShowCalendar');
end;

procedure TfrmStart.ShowCalendarList;

  (*
  Procedure RenderListEvent(Info: TCalendarEventRenderInfo);
  var
    I: Integer;
    NL: TJSNodeList;
  begin
    NL:=Info.el.querySelectorAll('.fc-event-dot');
    for I:=0 to NL.length-1 do
      TJSHTMLElement(NL.item(I)).style.setProperty('background-color', Info.Event.borderColor);
  end;
  *)

var
  Opts: TP2WFCFullCalendarOptions;
begin
  Entermethod('ShowCalendarList');
  Opts:=TP2WFCFullCalendarOptions.new;
  With Opts do
  begin
    themeSystem := 'bootstrap';
    headerToolbarBool := false;
//    plugins := [fcBootstrapPlugin, fcListPlugin, fcDayGridPlugin];
    initialView := 'listMonth';
    heightStr := '100%';
    // navLink false,
    // can click day/week names to navigate views
    // editable is part of the interaction plugin
//    eventLimit:=false;
    locale:='nl-be';
    // allow "more" link when too many events
//    eventsStr := 'assets/data/events.json';
//    eventRender := @RenderListEvent
  end;
  DoLog('Created options');
  FCalendarList:=TP2WFCFullCalendar.new(TJSHTMLElement(alForm['CalendarList'].ElementHandle), Opts);
  FCalendarList.render;
  FCalendarList.today;
  Exitmethod('ShowCalendarList');
end;

procedure TfrmStart.WebFormShow(Sender: TObject);
var
  aUser : string;
begin
  Entermethod('WebFormShow');
  inherited;
  //ShowCalendarList;
  //ShowCalendar;
  aUser := dmServer.UserInfo.FirstName + ' ' + dmServer.UserInfo.LastName;
  alForm['StartUser'].Value := aUser;
  Exitmethod('WebFormShow');
end;


procedure TfrmStart.DoCalendarNextClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  FCalendar.next;
  FCalendarList.next;
end;

procedure TfrmStart.DoCalendarPrevClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  FCalendar.Prev;
  FCalendarList.Prev;
end;

procedure TfrmStart.DoCalendarTodayClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  FCalendar.today;
  FCalendarList.today;
end;

procedure TfrmStart.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'calendar-next';
      Name := 'CalendarNext';
      SetEvent(Self, 'OnExecute', 'DoCalendarNextClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'calendar-prev';
      Name := 'CalendarPrev';
      SetEvent(Self, 'OnExecute', 'DoCalendarPrevClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'calendar-today';
      Name := 'CalendarToday';
      SetEvent(Self, 'OnExecute', 'DoCalendarTodayClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'calendar-list';
      Name := 'CalendarList';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'calendar';
      Name := 'Calendar';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'startuser';
      Name := 'startuser';
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
