unit Forms.Customer;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper;

type
  TfrmCustomer = class(TfrmBase)
    dsCompany: TP2WDADataset;
    dsCompanycomid: TLargeintField;
    dsCompanycomname: TWideStringField;
    dsCompanycomstreet: TWideStringField;
    dsCompanycomhousenr: TWideStringField;
    dsCompanycomzip: TWideStringField;
    dsCompanycomcity: TWideStringField;
    dsCompanycomcountry: TWideStringField;
    dcGrid: TP2WDatatable;
    procedure WebFormCreate(Sender: TObject); override;
    procedure dsCompanyAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmCustomer: TfrmCustomer;

implementation

{$R *.dfm}

uses
  Modules.Server, Units.ActionUtils, pas2web.da;

procedure TfrmCustomer.dsCompanyAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
  iCnt: Integer;
begin
  inherited;
  aResult := dsCompany.GetNewRows(False);
  dcGrid.Data := aResult;
  dcGrid.RenderTranslated;

  iCnt := dsCompany.RecordCount;

  alForm['actTotal'].Value := IntToStr(iCnt);
end;

procedure TfrmCustomer.WebFormCreate(Sender: TObject);
var
  aExpression: TP2WDAExpression;
begin
  inherited;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;

  aExpression := dsCompany.WhereClauseBuilder.NewBinaryExpression('company', 'comstatus', dboNotEqual, 1, datLargeInt);

  dsCompany.DAConnection := Server.CompanyConnection;
  dsCompany.WhereClause := dsCompany.WhereClauseBuilder.GetWhereClause(aExpression);
  dsCompany.Load([], nil);
end;

procedure TfrmCustomer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsCompany := TP2WDADataset.Create(Self);
  dsCompanycomid := TLargeintField.Create(Self);
  dsCompanycomname := TWideStringField.Create(Self);
  dsCompanycomstreet := TWideStringField.Create(Self);
  dsCompanycomhousenr := TWideStringField.Create(Self);
  dsCompanycomzip := TWideStringField.Create(Self);
  dsCompanycomcity := TWideStringField.Create(Self);
  dsCompanycomcountry := TWideStringField.Create(Self);
  dcGrid := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsCompany.BeforeLoadDFMValues;
  dsCompanycomid.BeforeLoadDFMValues;
  dsCompanycomname.BeforeLoadDFMValues;
  dsCompanycomstreet.BeforeLoadDFMValues;
  dsCompanycomhousenr.BeforeLoadDFMValues;
  dsCompanycomzip.BeforeLoadDFMValues;
  dsCompanycomcity.BeforeLoadDFMValues;
  dsCompanycomcountry.BeforeLoadDFMValues;
  dcGrid.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'total';
      Name := 'actTotal';
    end;
    dsCompany.SetParentComponent(Self);
    dsCompany.Name := 'dsCompany';
    dsCompany.TableName := 'CompanyOverview';
    dsCompany.DAConnection := dmServer.CompanyConnection;
    dsCompany.DAOptions := [];
    dsCompany.AfterOpen := dsCompanyAfterOpen;
    dsCompany.Left := 216;
    dsCompany.Top := 56;
    dsCompanycomid.SetParentComponent(dsCompany);
    dsCompanycomid.Name := 'dsCompanycomid';
    dsCompanycomid.FieldName := 'comid';
    dsCompanycomname.SetParentComponent(dsCompany);
    dsCompanycomname.Name := 'dsCompanycomname';
    dsCompanycomname.FieldName := 'comname';
    dsCompanycomname.Size := 100;
    dsCompanycomstreet.SetParentComponent(dsCompany);
    dsCompanycomstreet.Name := 'dsCompanycomstreet';
    dsCompanycomstreet.FieldName := 'comstreet';
    dsCompanycomstreet.Size := 100;
    dsCompanycomhousenr.SetParentComponent(dsCompany);
    dsCompanycomhousenr.Name := 'dsCompanycomhousenr';
    dsCompanycomhousenr.FieldName := 'comhousenr';
    dsCompanycomhousenr.Size := 10;
    dsCompanycomzip.SetParentComponent(dsCompany);
    dsCompanycomzip.Name := 'dsCompanycomzip';
    dsCompanycomzip.FieldName := 'comzip';
    dsCompanycomzip.Size := 15;
    dsCompanycomcity.SetParentComponent(dsCompany);
    dsCompanycomcity.Name := 'dsCompanycomcity';
    dsCompanycomcity.FieldName := 'comcity';
    dsCompanycomcity.Size := 100;
    dsCompanycomcountry.SetParentComponent(dsCompany);
    dsCompanycomcountry.Name := 'dsCompanycomcountry';
    dsCompanycomcountry.FieldName := 'comcountry';
    dsCompanycomcountry.Size := 2;
    dcGrid.SetParentComponent(Self);
    dcGrid.Name := 'dcGrid';
    dcGrid.Columns.Clear;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comname';
      Title := 'Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comstreet';
      Title := 'Street';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comhousenr';
      Title := 'House n'#176;
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comzip';
      Title := 'Zip';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comcity';
      Title := 'City';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comcountry';
      Title := 'Country';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/signonapproval/{{comid}}';
    end;
    dcGrid.DataSet := dsCompany;
    dcGrid.Language := lEnglish;
    dcGrid.IsResponsive := True;
    dcGrid.GridID := 'grdCompany';
    dcGrid.UseFieldIndex := True;
    dcGrid.ShowSearch := False;
    dcGrid.ShowNumberOfEntries := False;
    dcGrid.ShowEntriesInfo := False;
    dcGrid.Paginate := True;
    dcGrid.DisplayReadOnly := False;
    dcGrid.CalculateTableWidth := True;
    dcGrid.Left := 216;
    dcGrid.Top := 144;
  finally
    alForm.AfterLoadDFMValues;
    dsCompany.AfterLoadDFMValues;
    dsCompanycomid.AfterLoadDFMValues;
    dsCompanycomname.AfterLoadDFMValues;
    dsCompanycomstreet.AfterLoadDFMValues;
    dsCompanycomhousenr.AfterLoadDFMValues;
    dsCompanycomzip.AfterLoadDFMValues;
    dsCompanycomcity.AfterLoadDFMValues;
    dsCompanycomcountry.AfterLoadDFMValues;
    dcGrid.AfterLoadDFMValues;
  end;
end;

end.