unit pas2web.htmltranslator;

interface

uses {$IFNDEF PAS2JS}jsdelphisystem, {$ENDIF} SysUtils,
  JS,
  Web,
  Classes;

type
  TLanguageLoadedEvent = procedure(Sender: TObject; aLanguage: string) of object;
  TLanguageLoadErrorEvent = procedure(Sender: TObject; aLanguague, aErrorMessage: string) of object;

  // Are all languages in a single file or in multiple files
  TFileMode = (fmSingle, fmMultiple, fmNone);
  EHTMLTranslator = class(Exception);

  TP2WHTMLTranslator = class(TComponent)
  private
    FLanguage: string;
    FOnLanguageLoaded: TLanguageLoadedEvent;
    FCurrLanguageStrings: TJSObject;
    FDefaultScope: TJSObject;
    FLanguages: TJSObject;
    FLanguageFileURL: string;
    FLanguageVarName: string;
    FFileMode: TFileMode;
    FOnLanguageLoadError: TLanguageLoadErrorEvent;
    FDefaultScopeName: string;
    FLoadingLanguage: string;
    FSeeAlsoProperty: string;
    procedure LoadLanguageFile(aLanguage: string);
  protected
    function GetSeeAlsoScope(aScope: TJSObject): TJSObject;
    procedure SetLanguage(const Value: string); virtual;
    procedure DoLanguageLoaded; virtual;
    property LoadingLanguage: string read FLoadingLanguage;
    procedure Translate(aEl: TJSHTMLElement; aScope, aSeealsoScope: TJSObject); overload;
    procedure TranslateBelow(aEl: TJSHTMLElement; aScope, aSeealsoScope: TJSObject); overload;
  public
    procedure Translate(aEl: TJSHTMLElement; aScope: string = ''); overload;
    procedure TranslateBelow(aEl: TJSHTMLElement; aScope: string = ''); overload;
    // Search message name in current root, scope
    function GetMessageByName(const aScope, aName: string): string; overload;
    // Search scope in provided root, use to search message in found scope
    function GetMessageByName(aRoot: TJSObject; const aScope, aName: string): string; overload;
    // Search in aScope for message aName
    function GetMessageByName(aScope: TJSObject; aName: string): string; overload;
    function GetMessageByName(aScope, aSeealsoScope: TJSObject; aName: string): string; overload;
    function HasLanguage(aLanguage: string): Boolean;
    function GetScope(const aScope: string): TJSObject; overload;
    function GetScope(aRoot: TJSObject; const aScope: string): TJSObject; overload;
    property CurrLanguageStrings: TJSObject read FCurrLanguageStrings;
  published
    // Default scope to use when looking for translations
    property DefaultScope: string read FDefaultScopeName write FDefaultScopeName;
    // Seealso property name;
    // When set, indicates a scope in which to continue searching.
    // This can be used for form inheritance; SeealsoProperty can be set to continue searching in the inherited scope.
    property SeeAlsoProperty: string read FSeeAlsoProperty write FSeeAlsoProperty;
    // Language name (will be lowercased)
    property Language: string read FLanguage write SetLanguage;
    // File mode, single or multi
    property FileMode: TFileMode read FFileMode write FFileMode;
    // File to load language from. If FileMode=fmMultiple, a format with the language code is done (use %s)
    // Example:
    // /js/languages/lang-%s.json
    property LanguageFileURL: string read FLanguageFileURL write FLanguageFileURL;
    property LanguageVarName: string read FLanguageVarName write FLanguageVarName;
    // When the language is succesfully loaded.
    property OnLanguageLoaded: TLanguageLoadedEvent read FOnLanguageLoaded write FOnLanguageLoaded;
    // When an error occurs during loading of a language.
    property OnLanguageLoadError: TLanguageLoadErrorEvent read FOnLanguageLoadError write FOnLanguageLoadError;
  end;

implementation

uses
  Types,
  StrUtils;

resourcestring
  SErrNoSuchLanguage = 'No such language: %s.';
  SErrNoSuchLanguageInFile = 'No language "%s" found in URL "%s".';
  SErrFailedToLoadURL = 'Failed to load language file from URL: "%s".';
  SErrUnknownError = 'Unknown error loading language from URL "%s": %s.';
  SErrNoLanguageSelected = 'Cannot translate, no language selected.';

procedure TP2WHTMLTranslator.DoLanguageLoaded;
begin
  FLoadingLanguage := '';
  if Assigned(FOnLanguageLoaded) then
    FOnLanguageLoaded(Self, FLanguage);
end;

function TP2WHTMLTranslator.GetScope(const aScope: string): TJSObject;
begin
  Result := GetScope(FCurrLanguageStrings, aScope);
end;

function TP2WHTMLTranslator.GetScope(aRoot: TJSObject; const aScope: string): TJSObject;
begin
  if (aScope = '') then
    Result := aRoot
  else
    if Assigned(aRoot) and isObject(aRoot[aScope]) and Assigned(TJSObject(aRoot[aScope])) then
      Result := TJSObject(aRoot[aScope])
    else
      Result := nil;
end;

function TP2WHTMLTranslator.GetMessageByName(const aScope, aName: string): string;
begin
  Result := GetMessageByName(FCurrLanguageStrings, aScope, aName);
end;

function TP2WHTMLTranslator.GetMessageByName(aRoot: TJSObject; const aScope, aName: string): string;
begin
  Result := GetMessageByName(GetScope(aRoot, aScope), aName)
end;

function TP2WHTMLTranslator.GetSeeAlsoScope(aScope: TJSObject): TJSObject;
begin
  if Assigned(aScope) and (SeeAlsoProperty <> '') and IsString(aScope[SeeAlsoProperty]) then
    Result := GetScope(FCurrLanguageStrings, string(aScope[SeeAlsoProperty]))
  else
    Result := nil;
end;

function TP2WHTMLTranslator.GetMessageByName(aScope: TJSObject; aName: string): string;
var
  aScope2: TJSObject;
begin
  aScope2 := GetSeeAlsoScope(aScope);
  Result := GetMessageByName(aScope, aScope2, aName);
end;

function TP2WHTMLTranslator.GetMessageByName(aScope, aSeealsoScope: TJSObject; aName: string): string; overload;
var
  DoDefault: Boolean;
begin
  Result := '';
  DoDefault := not Assigned(aScope);
  if not DoDefault then
  begin
    if IsString(aScope[aName]) then
      Result := string(aScope[aName])
    else
      if Assigned(aSeealsoScope) then
      begin
        if IsString(aSeealsoScope[aName]) then
          Result := string(aSeealsoScope[aName])
        else
          DoDefault := True;
      end;
  end;
  if DoDefault and Assigned(FDefaultScope) and IsString(FDefaultScope[aName]) then
    Result := string(FDefaultScope[aName]);
end;

function TP2WHTMLTranslator.HasLanguage(aLanguage: string): Boolean;
begin
  aLanguage := LowerCase(aLanguage);
  Result := False;
  if FLanguages = nil then
    exit;
  if (FileMode = fmSingle) or (FileMode = fmNone) then
    Result := isObject(FLanguages[aLanguage])
  else
    Result := (sameText(aLanguage, FLanguage))
end;

procedure TP2WHTMLTranslator.LoadLanguageFile(aLanguage: string);
var
  URL: string;

  procedure DoLoadError(aMsg: string);
  begin
    if Assigned(FOnLanguageLoadError) then
      FOnLanguageLoadError(Self, aLanguage, aMsg);
  end;

  function LoadCurrentLanguage: JSValue;
  var
    aVal: JSValue;
  begin
    if FLanguages = nil then
      exit;
    if (FileMode = fmSingle) or (FileMode = fmNone) then
      FCurrLanguageStrings := TJSObject(FLanguages[aLanguage])
    else
      FCurrLanguageStrings := TJSObject(FLanguages);
    if not Assigned(FCurrLanguageStrings) then
      DoLoadError(Format(SErrNoSuchLanguageInFile, [aLanguage, URL]));
    FDefaultScope := nil;
    if DefaultScope <> '' then
    begin
      aVal := FCurrLanguageStrings[DefaultScope];
      if isObject(aVal) then
        FDefaultScope := TJSObject(aVal)
    end;
    FLanguage := aLanguage;
    Result := fDefaultScope;
    DoLanguageLoaded;
  end;

  function LoadLanguageJson(Value: JSValue): JSValue;
  begin
    try
      FLanguages := TJSJSON.parseObject(string(Value));
    except
      on er: TJSError do
        DoLoadError(Format(SErrUnknownError, [URL, er.message]));
      on e: TJSObject do
        DoLoadError(Format(SErrUnknownError, [URL, TJSJSON.stringify(e)]));
    end;
    Result := LoadCurrentLanguage;
  end;

  function doOK(response: JSValue): JSValue;
  var
    Res: TJSResponse absolute response;
  begin
    Result := Null;
    if (Res.status <> 200) then
    begin
      DoLoadError(Format(SErrUnknownError, [URL, Res.StatusText]));
    end
    else
      Res.text._then(@LoadLanguageJson);
  end;

  function doFail(response{%H-} : JSValue): JSValue;
  begin
    Result := Null;
    DoLoadError(Format(SErrFailedToLoadURL, [URL]));
  end;

begin
  if FileMode = fmNone then
  begin
    FLanguages := TJSObject(window[LanguageVarName]);
    LoadCurrentLanguage;
  end
  else
  begin
    FLoadingLanguage := aLanguage;
    if FileMode = fmSingle then
      URL := LanguageFileURL
    else
      URL := Format(LanguageFileURL, [aLanguage]);
    window.Fetch(URL)._then(@doOK).catch(@doFail);
  end;
end;

procedure TP2WHTMLTranslator.SetLanguage(const Value: string);
var
  NewLanguage: string;
begin
  NewLanguage := LowerCase(Value);
  if NewLanguage = FLanguage then
    exit;
  if ((FileMode = fmSingle) or (FileMode = fmNone)) and Assigned(FLanguages) then
  begin
    if not Assigned(TJSObject(FLanguages[NewLanguage])) then
      raise EHTMLTranslator.CreateFmt(SErrNoSuchLanguage, [Value]);
    FCurrLanguageStrings := TJSObject(FLanguages[NewLanguage]);
    FLanguage := NewLanguage;
  end
  else
    LoadLanguageFile(NewLanguage);
end;

procedure TP2WHTMLTranslator.Translate(aEl: TJSHTMLElement; aScope: string = '');
var
  aScope1, aScope2: TJSObject;
begin
  aScope1 := GetScope(aScope);
  aScope2 := GetSeeAlsoScope(aScope1);
  Translate(aEl, aScope1, aScope2);
end;

procedure TP2WHTMLTranslator.Translate(aEl: TJSHTMLElement; aScope, aSeealsoScope: TJSObject); overload;
var
  Terms: TStringDynArray;
  T, S, aAttr, aValue: string;
  P: Integer;
begin
  T := string(aEl.Dataset.Properties['translate']);
  if T = '' then
    exit;
  Terms := T.Split(';');
  for S in Terms do
  begin
    P := Pos('-', S);
    if P = 0 then
      P := Length(S) + 1;
    aAttr := Copy(S, P + 1, Length(S) - P);
    aValue := GetMessageByName(aScope, aSeealsoScope, S);
    if (aValue <> '') then
      if aAttr = '' then
        aEl.InnerText := aValue
      else
        aEl[aAttr] := aValue;
  end;
end;

procedure TP2WHTMLTranslator.TranslateBelow(aEl: TJSHTMLElement; aScope: string = '');
var
  aScope1, aScope2: TJSObject;
begin
  aScope1 := GetScope(aScope);
  aScope2 := GetSeeAlsoScope(aScope1);
  TranslateBelow(aEl, aScope1, aScope2);
end;

procedure TP2WHTMLTranslator.TranslateBelow(aEl: TJSHTMLElement; aScope, aSeealsoScope: TJSObject); overload;
var
  NL: TJSNodeList;
  e: TJSHTMLElement;
  I: Integer;
begin
  if not Assigned(FCurrLanguageStrings) then
    raise EHTMLTranslator.Create(SErrNoLanguageSelected);
  NL := aEl.querySelectorAll('[data-translate]');
  for I := 0 to NL.Length - 1 do
  begin
    e := TJSHTMLElement(NL.Nodes[I]);
    Translate(e, aScope, aSeealsoScope);
  end;
end;

end.
