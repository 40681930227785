unit pas2web.bootstrap;

interface

uses
  libjquery,
  lib.bootstrap,
  Js,
  web,
  SysUtils,
  Classes,
  rtl.TemplateLoader,
  WebLib.Controls,
  WebLib.Actions;

type
  TP2WBSModalHideEvent = procedure(Sender: TObject; CloseEl: TJSHTMLElement; Values: TStrings) of object;

  TP2WBSModal = class(TComponent)
  private
    FHideEl: TJSHTMLElement;
    FBackDrop: Boolean;
    FFocus: Boolean;
    FKeyBoard: Boolean;
    FOnHide: TP2WBSModalHideEvent;
    FShowOnRender: Boolean;
    FTemplate: string;
    FTemplateName: string;
    FParentID: string;
    FActions: TWebElementActionList;
    FElement: TJSHTMLElement;
    FElementID: string;
    FRemoveOnHide: Boolean;
    FOKButtonName: string;
    FCancelButtonName: string;
    FCloseButtonName: string;
    FOnRender: TNotifyEvent;
    FOnShow: TNotifyEvent;
    procedure HideClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    function GetIsRendered: Boolean;
    procedure CheckElement(DoClear: Boolean);
    function GetPreRenderedID: string;
    procedure SetPreRenderedID(const Value: string);
    procedure CheckRemove;
  protected
    function BootstrapHide(Event: TJSEvent): Boolean;
    function DoRenderHTML(aParent, aElement: TJSHTMLElement): TJSHTMLElement;
    function GetTemplateHTML: string;
    procedure RefreshReferences;
    procedure Loaded; override;
  public
    constructor Create(aOwner: TComponent); override;
    destructor Destroy; override;
    procedure GetValues(aList: TStrings);
    procedure Render;
    procedure Show;
    procedure Hide;
    property Element: TJSHTMLElement read FElement;
    property IsRendered: Boolean read GetIsRendered;
    // Id of the modal toplevel element. If not set, the first child is used.
    property ElementID: string read FElementID write FElementID;
  published
    property Actions: TWebElementActionList read FActions;
    // Where to render the bootstrap modal
    property ParentID: string read FParentID write FParentID;
    // Workaround for TMS Web core oddity
    property PreRenderedID: string read GetPreRenderedID write SetPreRenderedID;
    property ShowOnRender: Boolean read FShowOnRender write FShowOnRender default False;
    property RemoveOnHide: Boolean read FRemoveOnHide write FRemoveOnHide default True;
    property BackDrop: Boolean read FBackDrop write FBackDrop default False;
    property KeyBoard: Boolean read FKeyBoard write FKeyBoard default False;
    property Focus: Boolean read FFocus write FFocus default False;
    property OKButtonName: string read FOKButtonName write FOKButtonName;
    property CancelButtonName: string read FCancelButtonName write FCancelButtonName;
    property CloseButtonName: string read FCloseButtonName write FCloseButtonName;
    // Template gets precedence over templatename;
    property Template: string read FTemplate write FTemplate;
    property TemplateName: string read FTemplateName write FTemplateName;
    property OnHide: TP2WBSModalHideEvent read FOnHide write FOnHide;
    property OnRender: TNotifyEvent read FOnRender write FOnRender;
    property OnShow: TNotifyEvent read FOnShow write FOnShow;
  end;

implementation

uses
  lib.jqueryhelpers;

{ TP2WBSModal }

procedure TP2WBSModal.Hide;
begin
  if Assigned(Element) then
    jQuery(Element).ModalHide;
end;

procedure TP2WBSModal.HideClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  // Writeln('In hide click');
  FHideEl := TJSHTMLElement(Event.JSEvent.currentTarget);
  Hide;
end;

procedure TP2WBSModal.Loaded;
begin
  inherited;
  CheckElement(True);
  if Element <> nil then
    Render;
end;

procedure TP2WBSModal.GetValues(aList: TStrings);
var
  I: integer;
  el: TJSElement;
  Inp: TJSHTMLInputElement absolute el;
  Sel: TJSHTMLSelectElement absolute el;
  Area: TJSHTMLTextAreaElement absolute el;
begin
  for I := 0 to Actions.Actions.Count - 1 do
  begin
    el := Actions.Actions[I].ElementHandle;
    if (el is TJSHTMLInputElement) then
    begin
      if Inp.name <> '' then
        aList.Values[Inp.name] := Inp.Value
      else
        aList.Values[Inp.id] := Inp.Value;
    end
    else
      if (el is TJSHTMLSelectElement) then
        aList.Values[Sel.name] := Sel.Value
      else
        if (el is TJSHTMLTextAreaElement) then
          aList.Values[Area.name] := Area.Value
        else
          if Assigned(el) then
            aList.Values[Actions.Actions[I].name] := el.InnerText;
  end;
end;

procedure TP2WBSModal.CheckRemove;
var
  E: TJSHTMLElement;
begin
  if FRemoveOnHide then
  begin
    E := Element;
    FElement := nil;
    if Assigned(E) and Assigned(E.ParentElement) then
      E.ParentElement.RemoveChild(E);
  end;
end;

function TP2WBSModal.BootstrapHide(Event: TJSEvent): Boolean;
var
  L: TStrings;
begin
  // Writeln('In bootstraphide callback ', assigned(onhide));
  // Note that this can still be called after the bootstrap modal is destroyed.
  Result := False;
  if Assigned(OnHide) then
  begin
    L := TStringList.Create;
    GetValues(L);
    if L.Count = 0 then
      FreeAndNil(L);
    try
      OnHide(Self, FHideEl, L);
    finally
      L.Free;
    end;
  end;
  CheckRemove;
  // Sometimes it gets stuck so we force remove it because it messes up the scrollbars...
  jQuery('body').removeClass('modal-open');
end;

destructor TP2WBSModal.Destroy;
begin
  CheckRemove;
  FreeAndNil(FActions);
  inherited;
end;

function TP2WBSModal.DoRenderHTML(aParent, aElement: TJSHTMLElement): TJSHTMLElement;
type
  TJSEventHandler = reference to function(Event: TJSEvent): Boolean;
var
  EH: TJSEventHandler;
begin
  FHideEl := nil;
  Result := aElement;
  jQuery(Result).modal(New(['backdrop', BackDrop, 'keyboard', KeyBoard, 'focus', Focus, 'show', ShowOnRender]));
  EH := @BootstrapHide;
  if EH = nil then; // Silence compiler warning

  // TMS Software's version of libjquery does not have "on" yet.
  asm
    $(Result).on('hidden.bs.modal',EH);
  end;
end;

function TP2WBSModal.GetIsRendered: Boolean;
begin
  CheckElement(False);
  Result := (FElement <> nil);
end;

function TP2WBSModal.GetPreRenderedID: string;
begin
  Result := ElementID;
end;

function TP2WBSModal.GetTemplateHTML: string;
begin
  Result := FTemplate;
  if Result = '' then
    Result := globalTemplates.Templates[TemplateName];
end;

procedure TP2WBSModal.RefreshReferences;
var
  I: integer;
  N: string;
  O, Cl, Ca: TElementAction;

  function DoCheck(A: TElementAction; const aName: string): TElementAction;
  begin
    if A <> nil then
      Result := A
    else
    begin
      Result := Actions.Actions.Add;
      Result.name := aName;
      Result.Selector := '[name=' + aName + ']';
      Result.Event := heClick;
      Result.PreventDefault := False;
      Result.StopPropagation := False;
      Result.Bind;
    end;
  end;

begin
  if OKButtonName <> '' then
    O := Actions.Actions.FindByName(OKButtonName);
  if CancelButtonName <> '' then
    Ca := Actions.Actions.FindByName(CancelButtonName);
  if CloseButtonName <> '' then
    Cl := Actions.Actions.FindByName(CloseButtonName);
  for I := 0 to Actions.Actions.Count - 1 do
  begin
    N := Actions.Actions[I].name;
    Actions.Actions[I].Bind;
    if (O = nil) and SameText(N, 'OK') or SameText(N, 'btnOK') then
      O := Actions.Actions[I]
    else
      if (Cl = nil) and SameText(N, 'Close') or SameText(N, 'BtnClose') then
        Cl := Actions.Actions[I]
      else
        if (Ca = nil) and SameText(N, 'Cancel') or SameText(N, 'BtnCancel') then
          Ca := Actions.Actions[I];
  end;
  O := DoCheck(O, 'OK');
  O.OnExecute := @HideClick;
  Cl := DoCheck(Cl, 'Close');
  Cl.OnExecute := @HideClick;
  Ca := DoCheck(Ca, 'Cancel');
  Ca.OnExecute := @HideClick;
end;

constructor TP2WBSModal.Create(aOwner: TComponent);
begin
  inherited;
  FActions := TWebElementActionList.Create(Self);
  FRemoveOnHide := True;
end;

procedure TP2WBSModal.CheckElement(DoClear: Boolean);
begin
  FElement := nil;
  if (ElementID <> '') then
    FElement := TJSHTMLElement(document.GetElementByID(ElementID));
end;

procedure TP2WBSModal.Render;
var
  el: TJSHTMLElement;
begin
  CheckElement(True);
  if (FElement = nil) then
  begin
    el := TJSHTMLElement(document.GetElementByID(ParentID));
    if not Assigned(el) then
      raise Exception.CreateFmt('Modal Parent "%s" does not exist', [ParentID]);
    el.InnerHTML := GetTemplateHTML;
    CheckElement(False);
    if (FElement = nil) then
      FElement := TJSHTMLElement(el.FirstElementChild);
  end;
  DoRenderHTML(el, FElement);
  RefreshReferences;
  if Assigned(FOnRender) then
    FOnRender(Self);
  if ShowOnRender and Assigned(FOnShow) then
    FOnShow(Self);
end;

procedure TP2WBSModal.SetPreRenderedID(const Value: string);
begin
  ElementID := Value;
end;

procedure TP2WBSModal.Show;
begin
  FHideEl := nil;
  if not IsRendered then
    Render;
  jQuery(Element).ModalShow;
  jQuery(Element).modal('handleUpdate');
  if Assigned(FOnShow) then
    FOnShow(Self);
end;

end.
