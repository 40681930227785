unit Forms.Signonapproval.Detail;

interface

uses
  SysUtils, Classes, Types, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Datasnap.DBClient,
  Data.DB, pas2web.dadataset, pas2web.dadatasethelper, WebRouter;

type
  TfrmSignonapprovalDetail = class(TfrmBase)
    dsCompany: TP2WDADataset;
    dsCompanycomid: TLargeintField;
    dsCompanycomcreatedon: TDateTimeField;
    dsCompanycomcreatedbyfk: TLargeintField;
    dsCompanycomchangedon: TDateTimeField;
    dsCompanycomchangedbyfk: TLargeintField;
    dsCompanycomname: TStringField;
    dsCompanycomstreet: TStringField;
    dsCompanycomhousenr: TStringField;
    dsCompanycomhousenrextra: TStringField;
    dsCompanycomzip: TStringField;
    dsCompanycomcity: TStringField;
    dsCompanycomcityfk: TLargeintField;
    dsCompanycomcountry: TStringField;
    dsCompanycomcountryfk: TLargeintField;
    dsCompanycomemail: TStringField;
    dsCompanycombankaccount: TStringField;
    dsCompanycomvat: TStringField;
    dsCompanycomtelephone: TStringField;
    dsCompanycomstatus: TSmallintField;
    dsCompanycompriority: TSmallintField;
    dsCompanycomlanguagefk: TLargeintField;
    dsCompanycomdatabaseid: TSmallintField;
    dsPerson: TP2WDADataset;
    dsPersonperid: TLargeintField;
    dsPersonperfirstname: TWideStringField;
    dsPersonperlastname: TWideStringField;
    dsPersonperemail: TWideStringField;
    dsPersonpermobile: TWideStringField;
    dsPersonlogname: TWideStringField;
    dsPersonlogcompanyfk: TLargeintField;
    dsPersonlorname: TWideStringField;
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure dsCompanyAfterOpen(DataSet: TDataSet);
    procedure dsPersonAfterOpen(DataSet: TDataSet);
    procedure actSaveExecute(Sender: TObject;
      Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    FParam: string;
    Procedure HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute : Boolean); override;
    procedure DoOnSuccess(aResult : NativeInt);
    procedure DoOnFailed(response: TJSOBject; fail: TJSError);
    { Private declarations }
  public
    procedure setParams(const Params: TStrings); override;
    class function MyRoutes: TStringDynArray; override;
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmSignonapprovalDetail: TfrmSignonapprovalDetail;

implementation

{$R *.dfm}

uses
  libjquery, Modules.Server, Units.ActionUtils, pas2web.da, Units.Toasts, Units.Strings;

procedure TfrmSignonapprovalDetail.dsCompanyAfterOpen(DataSet: TDataSet);
begin
  inherited;
  alForm['actOfficeName'].Value := dsCompany.FieldByName('comname').AsString;
  alForm['actAddress'].Value := dsCompany.FieldByName('comstreet').AsString;
  alForm['actHouseno'].Value := dsCompany.FieldByName('comhousenr').AsString;
  alForm['actAddress2'].Value := dsCompany.FieldByName('comhousenrextra').AsString;
  alForm['actZip'].Value := dsCompany.FieldByName('comzip').AsString;
  alForm['actCity'].Value := dsCompany.FieldByName('comcity').AsString;
  alForm['actOfficeEmail'].Value := dsCompany.FieldByName('comemail').AsString;
  alForm['actVatno'].Value := dsCompany.FieldByName('comvat').AsString;
  alForm['actBankno'].Value := dsCompany.FieldByName('combankaccount').AsString;
  alForm['actOfficePhone'].Value := dsCompany.FieldByName('comtelephone').AsString;
end;

procedure TfrmSignonapprovalDetail.dsPersonAfterOpen(DataSet: TDataSet);
begin
  inherited;
  alForm['actFirstName'].Value := dsPerson.FieldByName('perfirstname').AsString;
  alForm['actLastName'].Value := dsPerson.FieldByName('perlastname').AsString;
  alForm['actEmail'].Value := dsPerson.FieldByName('peremail').AsString;
  alForm['actPhone'].Value := dsPerson.FieldByName('permobile').AsString;
  alForm['actUsername'].Value := dsPerson.FieldByName('logname').AsString;
end;

class function TfrmSignonapprovalDetail.MyRoutes: TStringDynArray;
begin
  Result := ['/signonapproval/:CUSTOMERID'];
end;

procedure TfrmSignonapprovalDetail.actSaveExecute(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  inherited;
  if isCheckBoxChecked('checkApprove') then
    Server.CompanyService.ApproveSignon(StrToIntDef(FParam, -1), True, @DoOnSuccess, @DoOnFailed);
end;

procedure TfrmSignonapprovalDetail.DoOnFailed(response: TJSOBject;
  fail: TJSError);
begin
  Toasts.ShowToast(SError, SErrLoadingDatasetTitle, cDanger);
end;

procedure TfrmSignonapprovalDetail.DoOnSuccess(aResult: NativeInt);
begin
  Router.RouteRequest('signonapproval', true);
end;

Procedure TfrmSignonapprovalDetail.HandleRoute(URl: String; aRoute: TRoute; Params: TStrings; IsReroute : Boolean);
var
  aExpression: TP2WDAExpression;
begin
  if Assigned(Params) then
  begin
    FParam := Params.Values['CUSTOMERID'];

    aExpression := dsCompany.WhereClauseBuilder.NewBinaryExpression('company', 'comid', dboEqual, StrToIntDef(FParam, -1), datLargeInt);
    dsCompany.WhereClause := dsCompany.WhereClauseBuilder.GetWhereClause(aExpression);
    dsCompany.Load([], nil);

    aExpression := dsPerson.WhereClauseBuilder.NewBinaryExpressionList(
                    [
                      dsPerson.WhereClauseBuilder.NewBinaryExpression('login', 'logcompanyfk', dboEqual, StrToIntDef(FParam, -1), datLargeInt),
                      dsPerson.WhereClauseBuilder.NewBinaryExpression('loginrole', 'lorname', dboEqual, 'SignOn', datWideString)
                    ], dboAnd);

    dsPerson.WhereClause := dsPerson.WhereClauseBuilder.GetWhereClause(aExpression);
    dsPerson.Load([], nil);
  end;
end;

procedure TfrmSignonapprovalDetail.setParams(const Params: TStrings);
begin
  if Assigned(Params) then
  begin
    jQuery('#returnBack').attr('href','#/' + Params.Values['CUSTOMERID']);
  end;
end;

procedure TfrmSignonapprovalDetail.WebFormCreate(Sender: TObject);
begin
  FParam := '';
  bindZipLookup('zip', 'town');

  Server.CompanyConnection.Message.ClientID := Server.ClientID;

  dsCompany.DAConnection := Server.CompanyConnection;
  dsPerson.DAConnection := Server.CompanyConnection;
end;

procedure TfrmSignonapprovalDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsCompany := TP2WDADataset.Create(Self);
  dsCompanycomid := TLargeintField.Create(Self);
  dsCompanycomcreatedon := TDateTimeField.Create(Self);
  dsCompanycomcreatedbyfk := TLargeintField.Create(Self);
  dsCompanycomchangedon := TDateTimeField.Create(Self);
  dsCompanycomchangedbyfk := TLargeintField.Create(Self);
  dsCompanycomname := TStringField.Create(Self);
  dsCompanycomstreet := TStringField.Create(Self);
  dsCompanycomhousenr := TStringField.Create(Self);
  dsCompanycomhousenrextra := TStringField.Create(Self);
  dsCompanycomzip := TStringField.Create(Self);
  dsCompanycomcity := TStringField.Create(Self);
  dsCompanycomcityfk := TLargeintField.Create(Self);
  dsCompanycomcountry := TStringField.Create(Self);
  dsCompanycomcountryfk := TLargeintField.Create(Self);
  dsCompanycomemail := TStringField.Create(Self);
  dsCompanycombankaccount := TStringField.Create(Self);
  dsCompanycomvat := TStringField.Create(Self);
  dsCompanycomtelephone := TStringField.Create(Self);
  dsCompanycomstatus := TSmallintField.Create(Self);
  dsCompanycompriority := TSmallintField.Create(Self);
  dsCompanycomlanguagefk := TLargeintField.Create(Self);
  dsCompanycomdatabaseid := TSmallintField.Create(Self);
  dsPerson := TP2WDADataset.Create(Self);
  dsPersonperid := TLargeintField.Create(Self);
  dsPersonperfirstname := TWideStringField.Create(Self);
  dsPersonperlastname := TWideStringField.Create(Self);
  dsPersonperemail := TWideStringField.Create(Self);
  dsPersonpermobile := TWideStringField.Create(Self);
  dsPersonlogname := TWideStringField.Create(Self);
  dsPersonlogcompanyfk := TLargeintField.Create(Self);
  dsPersonlorname := TWideStringField.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsCompany.BeforeLoadDFMValues;
  dsCompanycomid.BeforeLoadDFMValues;
  dsCompanycomcreatedon.BeforeLoadDFMValues;
  dsCompanycomcreatedbyfk.BeforeLoadDFMValues;
  dsCompanycomchangedon.BeforeLoadDFMValues;
  dsCompanycomchangedbyfk.BeforeLoadDFMValues;
  dsCompanycomname.BeforeLoadDFMValues;
  dsCompanycomstreet.BeforeLoadDFMValues;
  dsCompanycomhousenr.BeforeLoadDFMValues;
  dsCompanycomhousenrextra.BeforeLoadDFMValues;
  dsCompanycomzip.BeforeLoadDFMValues;
  dsCompanycomcity.BeforeLoadDFMValues;
  dsCompanycomcityfk.BeforeLoadDFMValues;
  dsCompanycomcountry.BeforeLoadDFMValues;
  dsCompanycomcountryfk.BeforeLoadDFMValues;
  dsCompanycomemail.BeforeLoadDFMValues;
  dsCompanycombankaccount.BeforeLoadDFMValues;
  dsCompanycomvat.BeforeLoadDFMValues;
  dsCompanycomtelephone.BeforeLoadDFMValues;
  dsCompanycomstatus.BeforeLoadDFMValues;
  dsCompanycompriority.BeforeLoadDFMValues;
  dsCompanycomlanguagefk.BeforeLoadDFMValues;
  dsCompanycomdatabaseid.BeforeLoadDFMValues;
  dsPerson.BeforeLoadDFMValues;
  dsPersonperid.BeforeLoadDFMValues;
  dsPersonperfirstname.BeforeLoadDFMValues;
  dsPersonperlastname.BeforeLoadDFMValues;
  dsPersonperemail.BeforeLoadDFMValues;
  dsPersonpermobile.BeforeLoadDFMValues;
  dsPersonlogname.BeforeLoadDFMValues;
  dsPersonlogcompanyfk.BeforeLoadDFMValues;
  dsPersonlorname.BeforeLoadDFMValues;
  try
    Width := 409;
    Height := 379;
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'officeName';
      Name := 'actOfficeName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'address';
      Name := 'actAddress';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'houseno';
      Name := 'actHouseno';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'address2';
      Name := 'actAddress2';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'zip';
      Name := 'actZip';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'city';
      Name := 'actCity';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'officeEmail';
      Name := 'actOfficeEmail';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'vatno';
      Name := 'actVatno';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'bankno';
      Name := 'actBankno';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'officePhone';
      Name := 'actOfficePhone';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'firstName';
      Name := 'actFirstName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'lastName';
      Name := 'actLastName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'email';
      Name := 'actEmail';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'phone';
      Name := 'actPhone';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'username';
      Name := 'actUsername';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'save';
      Name := 'actSave';
      SetEvent(Self, 'OnExecute', 'actSaveExecute');
    end;
    dsCompany.SetParentComponent(Self);
    dsCompany.Name := 'dsCompany';
    dsCompany.TableName := 'company';
    dsCompany.DAOptions := [];
    dsCompany.AfterOpen := dsCompanyAfterOpen;
    dsCompany.Left := 40;
    dsCompany.Top := 112;
    dsCompanycomid.SetParentComponent(dsCompany);
    dsCompanycomid.Name := 'dsCompanycomid';
    dsCompanycomid.FieldName := 'comid';
    dsCompanycomcreatedon.SetParentComponent(dsCompany);
    dsCompanycomcreatedon.Name := 'dsCompanycomcreatedon';
    dsCompanycomcreatedon.FieldName := 'comcreatedon';
    dsCompanycomcreatedbyfk.SetParentComponent(dsCompany);
    dsCompanycomcreatedbyfk.Name := 'dsCompanycomcreatedbyfk';
    dsCompanycomcreatedbyfk.FieldName := 'comcreatedbyfk';
    dsCompanycomchangedon.SetParentComponent(dsCompany);
    dsCompanycomchangedon.Name := 'dsCompanycomchangedon';
    dsCompanycomchangedon.FieldName := 'comchangedon';
    dsCompanycomchangedbyfk.SetParentComponent(dsCompany);
    dsCompanycomchangedbyfk.Name := 'dsCompanycomchangedbyfk';
    dsCompanycomchangedbyfk.FieldName := 'comchangedbyfk';
    dsCompanycomname.SetParentComponent(dsCompany);
    dsCompanycomname.Name := 'dsCompanycomname';
    dsCompanycomname.FieldName := 'comname';
    dsCompanycomname.Size := 100;
    dsCompanycomstreet.SetParentComponent(dsCompany);
    dsCompanycomstreet.Name := 'dsCompanycomstreet';
    dsCompanycomstreet.FieldName := 'comstreet';
    dsCompanycomstreet.Size := 100;
    dsCompanycomhousenr.SetParentComponent(dsCompany);
    dsCompanycomhousenr.Name := 'dsCompanycomhousenr';
    dsCompanycomhousenr.FieldName := 'comhousenr';
    dsCompanycomhousenr.Size := 10;
    dsCompanycomhousenrextra.SetParentComponent(dsCompany);
    dsCompanycomhousenrextra.Name := 'dsCompanycomhousenrextra';
    dsCompanycomhousenrextra.FieldName := 'comhousenrextra';
    dsCompanycomhousenrextra.Size := 10;
    dsCompanycomzip.SetParentComponent(dsCompany);
    dsCompanycomzip.Name := 'dsCompanycomzip';
    dsCompanycomzip.FieldName := 'comzip';
    dsCompanycomzip.Size := 15;
    dsCompanycomcity.SetParentComponent(dsCompany);
    dsCompanycomcity.Name := 'dsCompanycomcity';
    dsCompanycomcity.FieldName := 'comcity';
    dsCompanycomcity.Size := 100;
    dsCompanycomcityfk.SetParentComponent(dsCompany);
    dsCompanycomcityfk.Name := 'dsCompanycomcityfk';
    dsCompanycomcityfk.FieldName := 'comcityfk';
    dsCompanycomcountry.SetParentComponent(dsCompany);
    dsCompanycomcountry.Name := 'dsCompanycomcountry';
    dsCompanycomcountry.FieldName := 'comcountry';
    dsCompanycomcountry.Size := 2;
    dsCompanycomcountryfk.SetParentComponent(dsCompany);
    dsCompanycomcountryfk.Name := 'dsCompanycomcountryfk';
    dsCompanycomcountryfk.FieldName := 'comcountryfk';
    dsCompanycomemail.SetParentComponent(dsCompany);
    dsCompanycomemail.Name := 'dsCompanycomemail';
    dsCompanycomemail.FieldName := 'comemail';
    dsCompanycomemail.Size := 127;
    dsCompanycombankaccount.SetParentComponent(dsCompany);
    dsCompanycombankaccount.Name := 'dsCompanycombankaccount';
    dsCompanycombankaccount.FieldName := 'combankaccount';
    dsCompanycombankaccount.Size := 34;
    dsCompanycomvat.SetParentComponent(dsCompany);
    dsCompanycomvat.Name := 'dsCompanycomvat';
    dsCompanycomvat.FieldName := 'comvat';
    dsCompanycomtelephone.SetParentComponent(dsCompany);
    dsCompanycomtelephone.Name := 'dsCompanycomtelephone';
    dsCompanycomtelephone.FieldName := 'comtelephone';
    dsCompanycomstatus.SetParentComponent(dsCompany);
    dsCompanycomstatus.Name := 'dsCompanycomstatus';
    dsCompanycomstatus.FieldName := 'comstatus';
    dsCompanycompriority.SetParentComponent(dsCompany);
    dsCompanycompriority.Name := 'dsCompanycompriority';
    dsCompanycompriority.FieldName := 'compriority';
    dsCompanycomlanguagefk.SetParentComponent(dsCompany);
    dsCompanycomlanguagefk.Name := 'dsCompanycomlanguagefk';
    dsCompanycomlanguagefk.FieldName := 'comlanguagefk';
    dsCompanycomdatabaseid.SetParentComponent(dsCompany);
    dsCompanycomdatabaseid.Name := 'dsCompanycomdatabaseid';
    dsCompanycomdatabaseid.FieldName := 'comdatabaseid';
    dsPerson.SetParentComponent(Self);
    dsPerson.Name := 'dsPerson';
    dsPerson.TableName := 'AccountInfo';
    dsPerson.DAConnection := dmServer.CompanyConnection;
    dsPerson.DAOptions := [];
    dsPerson.AfterOpen := dsPersonAfterOpen;
    dsPerson.Left := 40;
    dsPerson.Top := 168;
    dsPersonperid.SetParentComponent(dsPerson);
    dsPersonperid.Name := 'dsPersonperid';
    dsPersonperid.FieldName := 'perid';
    dsPersonperfirstname.SetParentComponent(dsPerson);
    dsPersonperfirstname.Name := 'dsPersonperfirstname';
    dsPersonperfirstname.FieldName := 'perfirstname';
    dsPersonperfirstname.Size := 64;
    dsPersonperlastname.SetParentComponent(dsPerson);
    dsPersonperlastname.Name := 'dsPersonperlastname';
    dsPersonperlastname.FieldName := 'perlastname';
    dsPersonperlastname.Size := 64;
    dsPersonperemail.SetParentComponent(dsPerson);
    dsPersonperemail.Name := 'dsPersonperemail';
    dsPersonperemail.FieldName := 'peremail';
    dsPersonperemail.Size := 128;
    dsPersonpermobile.SetParentComponent(dsPerson);
    dsPersonpermobile.Name := 'dsPersonpermobile';
    dsPersonpermobile.FieldName := 'permobile';
    dsPersonpermobile.Size := 25;
    dsPersonlogname.SetParentComponent(dsPerson);
    dsPersonlogname.Name := 'dsPersonlogname';
    dsPersonlogname.FieldName := 'logname';
    dsPersonlogname.Size := 100;
    dsPersonlogcompanyfk.SetParentComponent(dsPerson);
    dsPersonlogcompanyfk.Name := 'dsPersonlogcompanyfk';
    dsPersonlogcompanyfk.FieldName := 'logcompanyfk';
    dsPersonlorname.SetParentComponent(dsPerson);
    dsPersonlorname.Name := 'dsPersonlorname';
    dsPersonlorname.FieldName := 'lorname';
    dsPersonlorname.Size := 32;
  finally
    alForm.AfterLoadDFMValues;
    dsCompany.AfterLoadDFMValues;
    dsCompanycomid.AfterLoadDFMValues;
    dsCompanycomcreatedon.AfterLoadDFMValues;
    dsCompanycomcreatedbyfk.AfterLoadDFMValues;
    dsCompanycomchangedon.AfterLoadDFMValues;
    dsCompanycomchangedbyfk.AfterLoadDFMValues;
    dsCompanycomname.AfterLoadDFMValues;
    dsCompanycomstreet.AfterLoadDFMValues;
    dsCompanycomhousenr.AfterLoadDFMValues;
    dsCompanycomhousenrextra.AfterLoadDFMValues;
    dsCompanycomzip.AfterLoadDFMValues;
    dsCompanycomcity.AfterLoadDFMValues;
    dsCompanycomcityfk.AfterLoadDFMValues;
    dsCompanycomcountry.AfterLoadDFMValues;
    dsCompanycomcountryfk.AfterLoadDFMValues;
    dsCompanycomemail.AfterLoadDFMValues;
    dsCompanycombankaccount.AfterLoadDFMValues;
    dsCompanycomvat.AfterLoadDFMValues;
    dsCompanycomtelephone.AfterLoadDFMValues;
    dsCompanycomstatus.AfterLoadDFMValues;
    dsCompanycompriority.AfterLoadDFMValues;
    dsCompanycomlanguagefk.AfterLoadDFMValues;
    dsCompanycomdatabaseid.AfterLoadDFMValues;
    dsPerson.AfterLoadDFMValues;
    dsPersonperid.AfterLoadDFMValues;
    dsPersonperfirstname.AfterLoadDFMValues;
    dsPersonperlastname.AfterLoadDFMValues;
    dsPersonperemail.AfterLoadDFMValues;
    dsPersonpermobile.AfterLoadDFMValues;
    dsPersonlogname.AfterLoadDFMValues;
    dsPersonlogcompanyfk.AfterLoadDFMValues;
    dsPersonlorname.AfterLoadDFMValues;
  end;
end;

end.