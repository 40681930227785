unit Forms.Signonapproval;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.CDS, pas2web.datatables,
  pas2web.dadatasethelper;

type
  TfrmSignonapproval = class(TfrmBase)
    dsApproval: TP2WDADataset;
    dcGrid: TP2WDatatable;
    dsApprovalcomid: TLargeintField;
    dsApprovalcomname: TWideStringField;
    dsApprovalcomstreet: TWideStringField;
    dsApprovalcomhousenr: TWideStringField;
    dsApprovalcomzip: TWideStringField;
    dsApprovalcomcity: TWideStringField;
    dsApprovalcomcountry: TWideStringField;
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure dsApprovalAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmSignonapproval: TfrmSignonapproval;

implementation

{$R *.dfm}

uses
  Modules.Server, Units.ActionUtils, pas2web.da;

procedure TfrmSignonapproval.dsApprovalAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
  I: Integer;
begin
  inherited;
  aResult := dsApproval.GetNewRows(False);
  dcGrid.Data := aResult;
  dcGrid.RenderTranslated;

  I := dsApproval.RecordCount;

  alForm['actTotal'].Value := IntToStr(I);
end;

procedure TfrmSignonapproval.WebFormCreate(Sender: TObject);
var
  aExpression: TP2WDAExpression;
begin
  inherited;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;

  aExpression := dsApproval.WhereClauseBuilder.NewBinaryExpression('company', 'comstatus', dboEqual, 1, datLargeInt);

  dsApproval.DAConnection := Server.CompanyConnection;
  dsApproval.WhereClause := dsApproval.WhereClauseBuilder.GetWhereClause(aExpression);
  dsApproval.Load([], nil);
end;

procedure TfrmSignonapproval.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsApproval := TP2WDADataset.Create(Self);
  dsApprovalcomid := TLargeintField.Create(Self);
  dsApprovalcomname := TWideStringField.Create(Self);
  dsApprovalcomstreet := TWideStringField.Create(Self);
  dsApprovalcomhousenr := TWideStringField.Create(Self);
  dsApprovalcomzip := TWideStringField.Create(Self);
  dsApprovalcomcity := TWideStringField.Create(Self);
  dsApprovalcomcountry := TWideStringField.Create(Self);
  dcGrid := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsApproval.BeforeLoadDFMValues;
  dsApprovalcomid.BeforeLoadDFMValues;
  dsApprovalcomname.BeforeLoadDFMValues;
  dsApprovalcomstreet.BeforeLoadDFMValues;
  dsApprovalcomhousenr.BeforeLoadDFMValues;
  dsApprovalcomzip.BeforeLoadDFMValues;
  dsApprovalcomcity.BeforeLoadDFMValues;
  dsApprovalcomcountry.BeforeLoadDFMValues;
  dcGrid.BeforeLoadDFMValues;
  try
    Width := 572;
    Height := 408;
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'total';
      Name := 'actTotal';
    end;
    dsApproval.SetParentComponent(Self);
    dsApproval.Name := 'dsApproval';
    dsApproval.TableName := 'CompanyOverview';
    dsApproval.DAConnection := dmServer.CompanyConnection;
    dsApproval.DAOptions := [];
    dsApproval.AfterOpen := dsApprovalAfterOpen;
    dsApproval.Left := 216;
    dsApproval.Top := 56;
    dsApprovalcomid.SetParentComponent(dsApproval);
    dsApprovalcomid.Name := 'dsApprovalcomid';
    dsApprovalcomid.FieldName := 'comid';
    dsApprovalcomname.SetParentComponent(dsApproval);
    dsApprovalcomname.Name := 'dsApprovalcomname';
    dsApprovalcomname.FieldName := 'comname';
    dsApprovalcomname.Size := 100;
    dsApprovalcomstreet.SetParentComponent(dsApproval);
    dsApprovalcomstreet.Name := 'dsApprovalcomstreet';
    dsApprovalcomstreet.FieldName := 'comstreet';
    dsApprovalcomstreet.Size := 100;
    dsApprovalcomhousenr.SetParentComponent(dsApproval);
    dsApprovalcomhousenr.Name := 'dsApprovalcomhousenr';
    dsApprovalcomhousenr.FieldName := 'comhousenr';
    dsApprovalcomhousenr.Size := 10;
    dsApprovalcomzip.SetParentComponent(dsApproval);
    dsApprovalcomzip.Name := 'dsApprovalcomzip';
    dsApprovalcomzip.FieldName := 'comzip';
    dsApprovalcomzip.Size := 15;
    dsApprovalcomcity.SetParentComponent(dsApproval);
    dsApprovalcomcity.Name := 'dsApprovalcomcity';
    dsApprovalcomcity.FieldName := 'comcity';
    dsApprovalcomcity.Size := 100;
    dsApprovalcomcountry.SetParentComponent(dsApproval);
    dsApprovalcomcountry.Name := 'dsApprovalcomcountry';
    dsApprovalcomcountry.FieldName := 'comcountry';
    dsApprovalcomcountry.Size := 2;
    dcGrid.SetParentComponent(Self);
    dcGrid.Name := 'dcGrid';
    dcGrid.Columns.Clear;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comname';
      Title := 'Name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comstreet';
      Title := 'Street';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comhousenr';
      Title := 'House n'#176;
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comzip';
      Title := 'Zip';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comcity';
      Title := 'City';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comcountry';
      Title := 'Country';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcGrid.Columns.Add do
    begin
      FieldName := 'comid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/signonapproval/{{comid}}';
    end;
    dcGrid.DataSet := dsApproval;
    dcGrid.Language := lEnglish;
    dcGrid.IsResponsive := True;
    dcGrid.GridID := 'grdApproval';
    dcGrid.UseFieldIndex := True;
    dcGrid.ShowSearch := False;
    dcGrid.ShowNumberOfEntries := False;
    dcGrid.ShowEntriesInfo := False;
    dcGrid.Paginate := True;
    dcGrid.DisplayReadOnly := False;
    dcGrid.CalculateTableWidth := True;
    dcGrid.Left := 216;
    dcGrid.Top := 144;
  finally
    alForm.AfterLoadDFMValues;
    dsApproval.AfterLoadDFMValues;
    dsApprovalcomid.AfterLoadDFMValues;
    dsApprovalcomname.AfterLoadDFMValues;
    dsApprovalcomstreet.AfterLoadDFMValues;
    dsApprovalcomhousenr.AfterLoadDFMValues;
    dsApprovalcomzip.AfterLoadDFMValues;
    dsApprovalcomcity.AfterLoadDFMValues;
    dsApprovalcomcountry.AfterLoadDFMValues;
    dcGrid.AfterLoadDFMValues;
  end;
end;

end.
