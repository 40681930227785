unit Units.Params;

interface

uses
  System.SysUtils, JS, pas2web.dadataset;

type

  TSearchResultField = (srfRecordID, srfFirstName, srfLastname, srfProfession,
    srfBirthDateOn, srfIsBirthDateUnknown, srfGender, srfCityOfBirthID,
    srfCityOfBirthZIP, srfCityOfBirthName, srfNationalityID, srfNationality2,
    srfNationality, srfKBONR, srfRemark, srfSalutation, srfSearchName,
    srfPrefixes, srfFriendlyTitle);

  TSearchResultFieldSet = set of TSearchResultField;

  TContactAddressField = (cafRecordID, cafAddressTypeID, cafAddressType, 
    cafStreet, cafStreetNr, cafCityZIP, cafCityName, cafCityID, cafRemark, 
    cafSecret, cafCorrespondence);

  TContactAddressFieldSet = set of TContactAddressField; 

  TContactTelecomItem = record
    RecordID: Int64;
    TelecomType: String;
    Data: String;
    Remark: String;
  end;
  TContactTelecomItems = array of TContactTelecomItem;

  TContactAddressItem = record
    RecordID: Int64;
    AddressTypeID: Integer;
    AddressType: String;
    Street: String;
    StreetNr: String;
    CityZIP: String;
    CityName: String;
    CityID: Int64;
    Remark: String;
    Secret: Boolean;
    Correspondence: Boolean;
  end;
  TContactAddressItems = array of TContactAddressItem;

  TContactAddressItemHelper = Record Helper for TContactAddressItem
    // Return empty record with supplied fields filled and leaves all other fields intact.
    procedure Create(Const anAddressTypeID: Integer; anAddressType, aStreet,
      aStreetNr, aCityZIP, aCityName: String);
    //all fields defined in the fieldsToUpdate will be assigned if [] is passed all fields are assigned
    procedure ToContactAddressDataset(aDataset : TP2WDADataset; fieldsToUpdate : TContactAddressFieldSet);
  End;

  TContactSearchResult = record
    RecordID: Int64;
    FirstName: String;
    Lastname: String;
    Profession: String;
    BirthDateOn: TJSDate;
    IsBirthDateUnknown: Boolean;
    Gender: String;
    CityOfBirthID: Int64;
    CityOfBirthZIP: String;
    CityOfBirthName: String;
    NationalityID: Int64;
    Nationality2: String;
    Nationality: String;
    KBONR: String;
    Remark: String;
    Salutation: String;
    SearchName: String;
    Prefixes: String;
    FriendlyTitle: String;
  end;

  TContactSearchResultHelper = Record Helper for TContactSearchResult
     // Return empty record with the 2 supplied fields filled and leaves all other fields intact.
    procedure Create(Const aFirstName,aLastName : String);
    //all fields defined in the fieldsToUpdate will be assigned if [] is passed all fields are assigned
    procedure ToContactDataset(aDataset : TP2WDADataset; fieldsToUpdate : TSearchResultFieldSet);
  End;

  THTMLFormParams = class
  end;

  TSignonParams = class(THTMLFormParams)
    public
      OfficeName : string;
      Address : string;
      AddressExtra : string;
      HouseNo : string;
      Zip : string;
      Town : string;
      OfficeEmail : string;
      VATno : string;
      IBANNo : string;
      OfficePhone : string;
      FirstName : string;
      LastName : string;
      Email : string;
      Phone : string;
      UserName : string;
  end;

  TCreatePetitionParams = class(THTMLFormParams)
    public
      AddressCityName : string;
      AddressCityZip : string;
      AddressNr : string;
      AddressStreet : string;
      BirthCityName : string;
      BirthCityZip : string;
      CardNumber : String;
      CardRemark : String;
      CardType : String;
      CardChipNo : String;
      CardValidFrom : TJSDate;
      CardValidTill : TJSDate;
      DateOfBirth : TJSDate;
      Description : String;
      Gender : String;
      FirstName: String;
      LastName : String;
      Nationality : String;
      NIDNR : String;
      Picture : String;
  end;


  TDezqParameter = record
    RecordID : NativeInt;
    Name : String;
    Language : integer;
    ParamType : string;
    Value : string;
    ValueType : integer;
    Extra : string;
  end;
  TDezqParameterArray = Array of TDezqParameter;

  TDezqParameterType = record
    ParamType : string;
    ParamList : TDezqParameterArray;
  end;

  TDezqParameterTypeArray = Array of TDezqParameterType;

  TPersonIdentityParams = class(THTMLFormParams)
    public
      RecordID: Int64;
      FirstName: String;
      LastName : String;
      DateOfBirth : TJSDate;
      BirthCityZip : string;
      BirthCityName : string;
      NIDNR : String;
      Gender : String;
      Nationality : String;

      ContactAddressID : Int64;
      AddressStreet : string;
      AddressNr : string;
      AddressCityZip : string;
      AddressCityName : string;

      TelecomID : Int64;
      PhoneNo : string;
      GSMNo: string;
      Email: string;

      CardType : String;
      CardNumber : String;
      CardValidFrom : TJSDate;
      CardValidTill : TJSDate;
      Picture : String;

      Profession : string;
      CivilStatus : string;
      MarriageContractType : string;

      ResidentialAddressID : Int64;
      ResidentialAddressStreet : string;
      ResidentialAddressNr : string;
      ResidentialAddressCityZip : string;
      ResidentialAddressCityName : string;

      ChipNo : string; //????????
  end;

  TCSRCreateParams = record
    ContactID: NativeInt;
    Description: String;
    CourtID: NativeInt;
    JudgeContactID: NativeInt;
    StartDate: TJSDate;
    CSRAdmissionFile: String;
  end;

  TParValue = record
    id: NativeInt;
    Name: string;
    Value: String;
  end;

  TParValues = array of TParValue;

  TCategory = record
    id: NativeInt;
    Name: string;
    Value: String;
    Extra: string;
    kinds: TParValues;
  end;

  TCategories = array of TCategory;


implementation

{ TContactSearchResultHelper }

procedure TContactSearchResultHelper.Create(const aFirstName, aLastName: String);
begin
  RecordID :=  0;
  FirstName := aFirstName;
  Lastname := aLastName;
  Profession := '';
  BirthDateOn := nil;
  IsBirthDateUnknown := False;
  Gender := '?';
  CityOfBirthID := 0;
  CityOfBirthZIP := '';
  CityOfBirthName := '';
  NationalityID := 0;
  Nationality2 := '';
  Nationality := '';
  KBONR := '';
  Remark := '';
  Salutation := '';
  SearchName := '';
  Prefixes := '';
  FriendlyTitle := '';
end;

procedure TContactSearchResultHelper.ToContactDataset(aDataset: TP2WDADataset; fieldsToUpdate : TSearchResultFieldSet);
var
  allFields : Boolean;

  Function DoField(aField : TSearchResultField) : Boolean; overload;
  begin
    Result:=allFields OR (aField in fieldsToUpdate);
  end;

  Function DoField(aField : TSearchResultField; aName,aValue : String) : Boolean; overload;
  begin
    Result:=DoField(aField);
    if Result then
      aDataset.FieldByName(aName).AsString:=aValue;
  end;

  Function DoField(aField : TSearchResultField; aName : String; aValue: NativeInt) : Boolean; overload;
  begin
    Result:=DoField(aField);
    if Result then
      aDataset.FieldByName(aName).AsLargeInt:=aValue;
  end;

begin
  allFields := fieldsToUpdate = [];
  if (RecordID > 0) then
    DoField(srfRecordID,'cntid',RecordID);

  DoField(srfFirstName,'cntfirstname',FirstName);
  DoField(srfLastname,'cntlastname',Lastname);
  DoField(srfProfession,'cntprofession',Profession);
  if Assigned(BirthDateOn) AND DoField(srfBirthDateOn) then
    aDataset.FieldByName('cntbirthdateon').AsDateTime :=
      EncodeDate(BirthDateOn.FullYear, BirthDateOn.Month + 1, BirthDateOn.Date); ;
  if DoField(srfIsBirthDateUnknown) then
    aDataset.FieldByName('cntisbirthdateunknown').AsBoolean := IsBirthDateUnknown;
  DoField(srfGender,'cntgender',Gender);
  DoField(srfCityOfBirthID,'cntcityofbirthfk',CityOfBirthID);
  DoField(srfCityOfBirthZIP,'cntcityofbirthzip',CityOfBirthZIP);
  DoField(srfCityOfBirthName,'cntcityofbirthname',CityOfBirthName);
  DoField(srfNationalityID,'cntnationalityfk',NationalityID);
  DoField(srfNationality2,'cntnationality2',Nationality2);
  DoField(srfNationality,'cntnationality',Nationality);
  if AllFields then
    aDataset.FieldByName('cntpicturefk').AsInteger := 0;
  DoField(srfKBONR,'cntkbonr',KBONR);
  DoField(srfRemark,'cntremark',Remark);
  if allFields then
    aDataset.FieldByName('cntpersonfk').AsInteger := 0;
  DoField(srfSalutation,'cntsalutation',Salutation);
  DoField(srfSearchName,'cntsearchname',SearchName);
  DoField(srfPrefixes,'cntprefixes',Prefixes);
  DoField(srfFriendlyTitle,'cntfriendlytitle',FriendlyTitle);
end;

{ TContactAddressItemHelper }

procedure TContactAddressItemHelper.Create(const anAddressTypeID: Integer;
  anAddressType, aStreet, aStreetNr, aCityZIP, aCityName: String);
begin
  RecordID := 0;
  AddressTypeID := anAddressTypeID;
  AddressType := anAddressType;
  Street := aStreet;
  StreetNr := aStreetNr;
  CityZIP := aCityZIP;
  CityName := aCityName;
  CityID := 0;
  Remark := '';
  Secret := False;
  Correspondence := False;
end;

procedure TContactAddressItemHelper.ToContactAddressDataset(aDataset: TP2WDADataset;
  fieldsToUpdate: TContactAddressFieldSet);
var
  allFields : Boolean;

  Function DoField(aField : TContactAddressField) : Boolean; overload;
  begin
    Result:=allFields OR (aField in fieldsToUpdate);
  end;

  Function DoField(aField : TContactAddressField; aName: String; aValue : Boolean) : Boolean; overload;
  begin
    Result:=DoField(aField);
    if Result then
      aDataset.FieldByName(aName).asBoolean:=aValue;
  end;

  Function DoField(aField : TContactAddressField; aName,aValue : String) : Boolean; overload;
  begin
    Result:=DoField(aField);
    if Result then
      aDataset.FieldByName(aName).AsString:=aValue;
  end;

  Function DoField(aField : TContactAddressField; aName : String; aValue: NativeInt) : Boolean; overload;
  begin
    Result:=DoField(aField);
    if Result then
      aDataset.FieldByName(aName).AsLargeInt:=aValue;
  end;

  
begin
  allFields := fieldsToUpdate = [];
  if (RecordID > 0) AND (allFields OR (cafRecordID in fieldsToUpdate)) then
    DoField(cafRecordID, 'ctaid', RecordID);
  DoField(cafAddressTypeID, 'ctaaddresstypefk', AddressTypeID);
  DoField(cafAddressType, 'ctaaddresstype', AddressType);
  DoField(cafStreet, 'ctastreet', Street);
  DoField(cafStreetNr, 'ctastreetnr', StreetNr);
  DoField(cafCityzip, 'ctacityzip', CityZIP);  
  DoField(cafCityName, 'ctacityname', CityName);
  DoField(cafCityID, 'ctacityfk', CityID);
  DoField(cafRemark, 'ctaremark', Remark);
  DoField(cafSecret, 'ctasecret', Secret);
  DoField(cafCorrespondence, 'ctacorrespondence', Correspondence);
end;

end.
