unit Forms.CSR.Representative;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Representative, WEBLib.Actions, pas2web.dadataset;

type
  TfrmCSRRepresentative = class(TfrmRepresentative)
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRRepresentative: TfrmCSRRepresentative;

implementation

{$R *.dfm}

{ TForm2 }

function TfrmCSRRepresentative.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRRepresentative.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRRepresentative.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRRepresentative.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.