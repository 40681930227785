unit Forms.CSR.Copy;

interface

uses
  Types, System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, WEBLib.ExtCtrls,
  Data.DB, Datasnap.DBClient, pas2web.dadataset, Module.Contacts, Units.Params, Units.Contacts;

type
  TfrmCSRCopy = class(TfrmBase)
    dsCourt: TP2WDADataset;
    dsContactId: TP2WDADataset;
    dsContact: TP2WDADataset;
    procedure onStartCopyClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure dsContactAfterOpen(DataSet: TDataSet);
    procedure dsContactIdAfterOpen(DataSet: TDataSet);
    procedure dsCourtAfterOpen(DataSet: TDataSet);
    procedure onShow(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FPetitionID: NativeInt;
    FTimerId : NativeInt;
    FJobId : NativeInt;

    FDMJudgeContact: TDMContacts;
    FJudgeID : NativeInt;
    procedure prepare(PetitionID: NativeInt);
    procedure startTimer;
    procedure stopTimer;
    procedure onTimer;
    procedure CheckPetitonCopyResults;
    procedure SetupJudgeContact;
    procedure onJudgeSaved(Sender: TObject);
    procedure onJudgeSearchResult(const SelectedContact : TContactSearchResult);
    function CourtInfoIsValid: Boolean;
    procedure startCopyTimer;
    procedure onCopyTimer;
    procedure GetCopiedCSRID;
  public
    { Public declarations }
    class function MyRoutes: TStringDynArray; override;
    class function NeedsParams : Boolean; override;
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRCopy: TfrmCSRCopy;

implementation

{$R *.dfm}

uses
  Modules.Server, Units.Strings, Units.Service.Dezq, Units.Types,
  libjquery, Units.ActionUtils;

{ TfrmCSRCopy }

procedure TfrmCSRCopy.onStartCopyClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
var
  copyActions : TSetPetitionCopyActionArray;
  options : TCopyPetitionToCSROptions;

  procedure OnCopyStartResponse(anID : Int64; aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
      Server.ShowError(anError)
    else
    begin
      FJobId := anId;
      //make tab 2 visible
      jQuery('#myTabContent')
        .find('div.tab-pane')
        .removeClass('active')
        .removeClass('show');
      jQuery('#secondTab')
        .AddClass('active')
        .AddClass('show');
      startCopyTimer;
    end;
  end;

  procedure onResponse(success: Boolean; error: string);
  begin
    if not success then
      Server.ShowError(error)
    else
    begin
      options.SourceID := FPetitionID;
      options.CopyJobID := FJobID;
      options.CourtID := StrToInT(alForm['selCourt'].value);
      options.JudgeContactID := FJudgeID;
      options.StartDate := TJSDate.New(alForm['ddtStart'].value);
      options.CSRAdmissionFile := DefaultDocumentBox.FileBase64;

      Server.StartCSRCopy(options, @OnCopyStartResponse);
    end;
  end;

  function processElement(aIndex : Integer; AElement : TJSElement): Boolean;
  var
    index : Integer;
  begin
    index := Length(copyActions);
    SetLength(copyActions, index + 1);
    copyActions[index].ActionID := StrToInt(aElement.getAttribute('value'));
    copyActions[index].UseExisting := True;
    Result := True;
  end;
begin
  jQuery('input[role="use-exisitng"]:checked').each(@processElement);

  //validate fields
  if CourtInfoIsValid then
    Server.SetPetitionFieldsToCopy(FJobID, copyActions, @onResponse);
end;

procedure TfrmCSRCopy.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FDMJudgeContact);
  inherited;
end;

procedure TfrmCSRCopy.WebFormCreate(Sender: TObject);
begin
  inherited;
  FTimerId := -1;
  FJobId := -1;
  FJudgeID := -1;

  DefaultDocumentBox.NeedBase64 := True;

  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsContactId.DAConnection := Server.ContactConnection;
  dsContact.DAConnection := Server.ContactConnection;

  FDMJudgeContact:= TDMContacts.Create(nil);
  FDMJudgeContact.AllowedContactType:=actPerson;
  FDMJudgeContact.DossierConnection := Server.ContactConnection;
  FDMJudgeContact.Name := 'JudgeContact';
  SetupJudgeContact;

  Server.ParameterConnection.Message.ClientID := Server.ClientID;
  dsCourt.DAConnection := Server.ParameterConnection;
  dsCourt.Load([], nil);

  jQuery('#btnOk').prop('disabled', false);
end;

procedure TfrmCSRCopy.CheckPetitonCopyResults;

  Procedure onFail(anError: String);
  begin
    Server.ShowError(anError);
  end;

  procedure onSuccess(PetitionCopyResult: TCheckPetitionCopyResult);
  var
    i : Integer;
    html : string;
  begin
    for i := Low(PetitionCopyResult.DuplicateContacts) to High(PetitionCopyResult.DuplicateContacts) do
    begin
      html := '<tr><td>' + IntToStr(PetitionCopyResult.DuplicateContacts[i].ActionID) + '</td>' +
        '<td><input type="checkbox" role="use-exisitng" value="' +
        IntToStr(PetitionCopyResult.DuplicateContacts[i].ActionID) + '"></td>' +
        '<td>' + PetitionCopyResult.DuplicateContacts[i].ContactDescription + '</td>' +
        '<td>' + PetitionCopyResult.DuplicateContacts[i].DifferenceDescription + '</td>' +
        '</tr>';
    end;
    jQuery('#grdDuplicates').find('tbody').html(html);
    jQuery('#grdDuplicates').css('display', '');
    jQuery('#loading').css('display', 'none');
  end;
begin
  Server.OnPetitionCopyFieldsReady(FJobId, @onSuccess, @onFail);
end;

function TfrmCSRCopy.CourtInfoIsValid: Boolean;
begin
  Result := not FieldIsEmpty('selCourt');
  Result := not FieldisEmpty('edtJudgeName') and Result;
  Result := not FieldIsEmpty('ddtStart') and Result;
  Result := (DefaultDocumentBox.FileBase64 <> '') and Result;
  if DefaultDocumentBox.FileBase64 = '' then
    jQuery('#admissionFormRequired').css('display', 'block');
end;

procedure TfrmCSRCopy.dsContactAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
    jQuery('#petitionerName').text(DataSet.FieldByName('cntfirstname').asString + ' ' +
      DataSet.FieldByName('cntlastname').asString);
end;

procedure TfrmCSRCopy.dsContactIdAfterOpen(DataSet: TDataSet);
begin
  if not DataSet.isEmpty then
  begin
    dsContact.Close;
    dsContact.ParamByName('CNTID').asInteger := DataSet.fieldByName('cticontactfk').asInteger;
    dsContact.Load([], nil);
  end;
end;

procedure TfrmCSRCopy.dsCourtAfterOpen(DataSet: TDataSet);
var
  aHTML: String;
begin
  aHTML:='';
  while not Dataset.Eof do
  begin
    aHTML:=aHTML+Format('<option value="%s">%s</option>',
      [
        DataSet.FieldByName('corid').asString,
        DataSet.FieldByName('corname').asString
      ]
    );
    Dataset.Next;
  end;
  alForm['selCourt'].InnerHTML := aHTML
end;

procedure TfrmCSRCopy.GetCopiedCSRID;

  procedure OnResponse(newPetitionID : Int64; aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
    begin
      Server.ShowError(anError);
    end
    else
    begin
      FPetitionID := newPetitionID;
      jQuery('#btnShow').prop('disabled', false);
      jQuery('#lblCopying').css('display', 'none');
      jQuery('#lblCopyReady').css('display', '');
    end;
  end;

begin
  Server.GetCopiedCSRID(FJobID, @onResponse);
end;

class function TfrmCSRCopy.MyRoutes: TStringDynArray;
begin
  Result:=  [
    '/CSR/new/copy/:DossierID/:NIDNR'
  ];
end;

class function TfrmCSRCopy.NeedsParams: Boolean;
begin
  Result := True;
end;

procedure TfrmCSRCopy.onCopyTimer;

  procedure OnResponse(Status : Int64; aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
    begin
      Server.ShowError(anError);
      stopTimer;
    end
    else
    begin
       if Status = Ord(jqsFinishOK) then
       begin
        GetCopiedCSRID;
        stopTimer;
       end;
       if Status = Ord(jqsCancelled) then
       begin
          Server.ShowError(SErrJobOperationCancelled);
          stopTimer;
       end;
    end;
  end;
begin
  //check the status of copy job
  if FJobId > -1 then
  begin
    Server.GetJobStatusEx(FJobID, @OnResponse);
  end;
end;

procedure TfrmCSRCopy.onJudgeSaved(Sender: TObject);
begin
  FJudgeID := FDMJudgeContact.ContactID;
end;

procedure TfrmCSRCopy.onJudgeSearchResult(
  const SelectedContact: TContactSearchResult);
begin
  FJudgeID := SelectedContact.RecordID;
end;

procedure TfrmCSRCopy.onShow(Sender: TObject; Element: TJSHTMLElementRecord;
    Event: TJSEventParameter);
begin
  window.location.href := '#/CSR/' + IntToStr(FPetitionID) +'/identity';
end;

procedure TfrmCSRCopy.onTimer;
  procedure OnResponse(Status : Int64; aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
    begin
      Server.ShowError(anError);
      stopTimer;
    end
    else
    begin
       if Status = Ord(jqsFinishOK) then
       begin
        CheckPetitonCopyResults;
        stopTimer;
       end;
       if Status = Ord(jqsCancelled) then
       begin
         Server.ShowError(SErrJobOperationCancelled);
         stopTimer;
       end;
    end;
  end;
begin
  //check the status of job
  if FJobId > -1 then
  begin
    Server.GetJobStatusEx(FJobID, @OnResponse);
  end;
end;

procedure TfrmCSRCopy.prepare(PetitionID: NativeInt);
  procedure OnResponse(anID : Int64; aSuccess: Boolean; anError: String);
  begin
    if not aSuccess then
      Server.ShowError(anError)
    else
    begin
      FJobId := anID;
      startTimer;
    end;
  end;
begin
  Server.GetPetitionCopyFields(PetitionID, @OnResponse);
end;

procedure TfrmCSRCopy.setParams(const Params: TStrings);
begin
  FPetitionID := -1;
  If Assigned(Params) and (Params.indexOfName('DossierID')<>-1) then
  begin
    FPetitionID := StrToIntDef(Params.Values['DossierID'], -1);
    prepare(FPetitionID);
    If Assigned(Params) and (Params.indexOfName('NIDNR')<>-1) then
    begin
      dsContactId.ParamByName('NIDNR').asString := Params.Values['NIDNR'];
      dsContactId.Load([], nil);
    end;
  end
  else
    Server.ShowError(SErrInvalidUrl)
end;

procedure TfrmCSRCopy.SetupJudgeContact;
Const
  JudgePersonalFields : TPersonalFieldNames = (
    'edtJudgeName','','','', '',
    '','','','','','',
    '','','','');

  procedure HandleSearchResult(const SelectedContact: TContactSearchResult; var aAllow : Boolean);
  begin
    aAllow:=(SelectedContact.Gender<>'C');
    if not aAllow  then
      dmServer.ShowError(SErrJudgeCanNotBeCompany);
  end;
Var
  PersonalFields : TPersonalFieldEdits;
begin
  PersonalFields.Names := JudgePersonalFields;
  PersonalFields.SetFieldsFromNames;
  PersonalFields.FirstNameNotRequired:=True;
  FDMJudgeContact.PersonalFieldInfo:=PersonalFields;
  FDMJudgeContact.FormIsReadOnly:=Self.FormIsReadonly;
  FDMJudgeContact.OnSearchResultEx:=HandleSearchResult;
  FDMJudgeContact.ContactDescription:=SContactJudge;
  FDMJudgeContact.EditContactButtonID:='btnNewJudgeContact';
  FDMJudgeContact.SearchButtonID:='btnSelectJudgeContact';
  FDMJudgeContact.OnAllSaved := onJudgeSaved;
  FDMJudgeContact.OnSearchResult := onJudgeSearchResult;
  FDMJudgeContact.LoadContactData(-1,[cdpPersonal],[]);

end;

procedure TfrmCSRCopy.startCopyTimer;
begin
  FTimerId := window.setInterval(@onCopyTimer, 1000);
end;

procedure TfrmCSRCopy.startTimer;
begin
  FTimerId := window.setInterval(@onTimer, 1000);
end;

procedure TfrmCSRCopy.stopTimer;
begin
  if FTimerId > -1 then
    window.clearInterval(FTimerId);
end;

procedure TfrmCSRCopy.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsCourt := TP2WDADataset.Create(Self);
  dsContactId := TP2WDADataset.Create(Self);
  dsContact := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsCourt.BeforeLoadDFMValues;
  dsContactId.BeforeLoadDFMValues;
  dsContact.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'selCourt';
      Name := 'selCourt';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtJudgeName';
      Name := 'edtJudgeName';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'ddtStart';
      Name := 'ddtStart';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnOk';
      Name := 'btnOk';
      SetEvent(Self, 'OnExecute', 'onStartCopyClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnShow';
      Name := 'btnShow';
      SetEvent(Self, 'OnExecute', 'onShow');
    end;
    dsCourt.SetParentComponent(Self);
    dsCourt.Name := 'dsCourt';
    dsCourt.TableName := 'CourtOverview';
    dsCourt.DAOptions := [];
    dsCourt.AfterOpen := dsCourtAfterOpen;
    dsCourt.Left := 40;
    dsCourt.Top := 152;
    dsContactId.SetParentComponent(Self);
    dsContactId.Name := 'dsContactId';
    dsContactId.TableName := 'contactid';
    dsContactId.Params.Clear;
    with dsContactId.Params.Add do
    begin
      DataType := ftString;
      Name := 'NIDNR';
      ParamType := ptInput;
    end;
    dsContactId.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>ctinidnr</field><parameter type="String">NIDNR</parameter></binaryoperation></where></query>';
    dsContactId.DAOptions := [doRefreshAllFields];
    dsContactId.AfterOpen := dsContactIdAfterOpen;
    dsContactId.Left := 40;
    dsContactId.Top := 80;
    dsContact.SetParentComponent(Self);
    dsContact.Name := 'dsContact';
    dsContact.TableName := 'contact';
    dsContact.Params.Clear;
    with dsContact.Params.Add do
    begin
      DataType := ftInteger;
      Name := 'CNTID';
      ParamType := ptInput;
    end;
    dsContact.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>cntid</field><parameter type="Integer">CNTID</parameter></binaryoperation></where></query>';
    dsContact.DAOptions := [doRefreshAllFields];
    dsContact.AfterOpen := dsContactAfterOpen;
    dsContact.Left := 40;
    dsContact.Top := 216;
  finally
    alForm.AfterLoadDFMValues;
    dsCourt.AfterLoadDFMValues;
    dsContactId.AfterLoadDFMValues;
    dsContact.AfterLoadDFMValues;
  end;
end;

end.
