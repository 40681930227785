unit lib.jstree;


{$mode objfpc}
{$modeswitch externalclass}

interface

uses types, js, web, libjquery;

type
  TP2WJTJSTreeState = class external name 'Object' (TJSObject)
    opened: Boolean;
    disabled: Boolean;
    selected: Boolean;
  end;

  TP2WJTJSTreeAltNode = class external name 'Object' (TJSObject)
    id_: String; external name 'id';
    parent: String;
    text: String;
    icon: String;
    state: TP2WJTJSTreeState;
    li_attr: TJSObject; // TODO
    a_attr: TJSObject; // TODO
  end;
  TP2WJTJSTreeAltNodeDynArray = Array of TP2WJTJSTreeAltNode;

  TP2WJTJSTreeAltCore = class external name 'Object' (TJSObject)
    data: TP2WJTJSTreeAltNodeDynArray;
  end;

  TP2WJTJSTreeObj = class external name 'Object' (TJSObject)
    altCore: TP2WJTJSTreeAltCore; external name 'core';
    function jquery() : TJQuery; external name '$';
    function on_(aEvent : string; aHandler : TJSRawEventHandler) : TP2WJTJSTreeObj; external name 'on';
  end;

implementation

end.
