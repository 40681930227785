unit Forms.CSR.TrustAccount;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper;

type
  TfrmBase = class(TFrmDossierDetail);
  TfrmTrustAccount = class(TfrmBase)
    dsBankAccount: TP2WDADataset;
    dcBankAccount: TP2WDatatable;
    procedure WebFormDestroy(Sender: TObject);
    procedure dsBankAccountAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix: string; override;
  protected procedure LoadDFMValues; override; end;

var
  frmTrustAccount: TfrmTrustAccount;

implementation

{$R *.dfm}

uses
  Modules.Server, Units.ActionUtils,  libjquery;

{ TfrmTrustAccount }

procedure TfrmTrustAccount.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmTrustAccount.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsBankAccount.DAConnection := Server.CompanyConnection;
end;

function TfrmTrustAccount.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmTrustAccount.dsBankAccountAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
begin
  aResult := dsBankAccount.GetNewRows(False);
  dcBankAccount.Data := aResult;
  dcBankAccount.RenderTranslated;
end;

procedure TfrmTrustAccount.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#addAccount').attr('href',DossierURL('/trustaccount')+'/new');
  dsBankAccount.ParamByName('DOSSIERID').asInteger := DossierID;
  dsBankAccount.Load([], nil);
end;

procedure TfrmTrustAccount.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsBankAccount := TP2WDADataset.Create(Self);
  dcBankAccount := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsBankAccount.BeforeLoadDFMValues;
  dcBankAccount.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'total';
      Name := 'actTotal';
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'Action1';
    end;
    dsBankAccount.SetParentComponent(Self);
    dsBankAccount.Name := 'dsBankAccount';
    dsBankAccount.TableName := 'BankAccount';
    dsBankAccount.DAConnection := dmServer.CompanyConnection;
    dsBankAccount.Params.Clear;
    with dsBankAccount.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    with dsBankAccount.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CompanyID';
      ParamType := ptInput;
    end;
    dsBankAccount.WhereClause := '<?xml version="1.0" encoding="UTF-8"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsBankAccount.DAOptions := [];
    dsBankAccount.AfterOpen := dsBankAccountAfterOpen;
    dsBankAccount.Left := 40;
    dsBankAccount.Top := 96;
    dcBankAccount.SetParentComponent(Self);
    dcBankAccount.Name := 'dcBankAccount';
    dcBankAccount.Columns.Clear;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacname';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'baciban';
      Title := 'Close Status';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacbalance';
      Title := 'Closed On';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'baclastpontocheckon';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Width := 'auto';
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '10px';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/edit/{{bacid}}';
      ExtraAttributes := 'title="Edit"';
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacid';
      RenderMode := rmButton;
      ButtonType := btCustom;
      ButtonIconClass := 'fas fa-file-invoice-dollar';
      Width := '10px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/{{bacid}}/movement';
      ExtraAttributes := 'title="Transactions"';
    end;
    with dcBankAccount.Columns.Add do
    begin
      FieldName := 'bacid';
      RenderMode := rmButton;
      ButtonType := btCustom;
      ButtonIconClass := 'fas fa-hand-holding-usd';
      Width := '10px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{coddossierid}}/trustaccount/{{bacid}}/paymentorder';
      ExtraAttributes := 'title="Payment Order"';
    end;
    dcBankAccount.DataSet := dsBankAccount;
    dcBankAccount.Language := lEnglish;
    dcBankAccount.IsResponsive := True;
    dcBankAccount.GridID := 'grdTrustAccounts';
    dcBankAccount.UseFieldIndex := True;
    dcBankAccount.ShowSearch := False;
    dcBankAccount.ShowNumberOfEntries := False;
    dcBankAccount.ShowEntriesInfo := False;
    dcBankAccount.Paginate := True;
    dcBankAccount.DisplayReadOnly := False;
    dcBankAccount.CalculateTableWidth := True;
    dcBankAccount.Left := 40;
    dcBankAccount.Top := 160;
  finally
    alForm.AfterLoadDFMValues;
    dsBankAccount.AfterLoadDFMValues;
    dcBankAccount.AfterLoadDFMValues;
  end;
end;

end.