unit Forms.WIP;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery,
  pas2web.datatables, Data.DB, Datasnap.DBClient, System.Types;

type
  TfrmWIP = class(TfrmBase)
  private
    { Private declarations }
  public
    class function MyRoutes: TStringDynArray; override;
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmWIP: TfrmWIP;

implementation

{$R *.dfm}

{ TfrmWIP }

class function TfrmWIP.MyRoutes: TStringDynArray;
begin
  Result:=[
    'workflows',
    'settings',
    'userroles',
    'userauthorizations',
    'customerprofile',
    'letters',
    'faq',
    'helpcenter',
    'privacy',
    'support'];
end;

procedure TfrmWIP.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.
