unit Forms.Profile;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  Types,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  Units.Service.Dezq;

type
  TfrmProfile = class(TfrmBase)
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure DoSaveProfileData(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoSelectAvatar(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    FAvatarFileName, FAvatarData: string;
    FUserRole: string;
    procedure ShowProfile(aProfile: TProfileData);
    procedure ShowRoles(aRoles: TJSStringDynArray);
    function GetProfileData: TProfileData;
    procedure SaveProfileData;
    procedure GetAvatarFile(aFile: TJSHTMLFile; CallSave: Boolean);
  public
    { Public declarations }
    class function MyRoutes: TStringDynArray; override;
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmProfile: TfrmProfile;

implementation

uses
  ROSDK,
  Modules.Server,
  Units.Strings,
  Units.ActionUtils;

{$R *.dfm}

procedure TfrmProfile.ShowRoles(aRoles: TJSStringDynArray);
const
  Selected: array [Boolean] of string = ('', 'selected');
var
  aHTML, aRole: string;
begin
  aHTML := '';
  for aRole in aRoles do
    aHTML := aHTML + '<option ' + Selected[SameText(aRole, FUserRole)] + ' value="' + aRole + '">' + aRole + '</option>'
      + sLineBreak;
  alForm['edtRole'].ElementHandle.InnerHTML := aHTML;
end;

procedure TfrmProfile.GetAvatarFile(aFile: TJSHTMLFile; CallSave: Boolean);
var
  reader: TJSFileReader;
begin
  FAvatarFileName := aFile.Name;
  reader := TJSFileReader.New();
  reader.AddEventListener('load', TJSRawEventHandler(
    procedure(Event: TJSEvent)
    begin
      // We do not do the transformation here, but only when passing it to server.
      // The reason is:
      // we still need to use the full data URL for setting the URL of the title avatar when save is successful.
      FAvatarData := string(reader.Result);
      alForm['imgAvatarEdit'].Value := FAvatarData;
      if CallSave then
        SaveProfileData;
    end));
  reader.AddEventListener('error', TJSRawEventHandler(
    procedure(Event: TJSEvent)
    begin
      dmServer.ShowError('Error reading avatar data ' + TJSJSON.Stringify(Event));
    end));
  reader.ReadAsDataURL(aFile);
end;

procedure TfrmProfile.DoSelectAvatar(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  inp: TJSHTMLInputElement;
begin
  inp := TJSHTMLInputElement(alForm['edtAvatarFile'].ElementHandle);
  if inp.Files.Length = 1 then
    GetAvatarFile(inp.Files.Item(0), False);
end;

procedure TfrmProfile.DoSaveProfileData(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  inp: TJSHTMLInputElement;
begin
  inherited;
  inp := TJSHTMLInputElement(alForm['edtAvatarFile'].ElementHandle);
  if (inp.Files.Length <> 1) then
  begin
    FAvatarData := '';
    SaveProfileData;
  end
  else
  begin
    // Already available
    if (FAvatarData <> '') then
      SaveProfileData
    else
      GetAvatarFile(inp.Files.Item(0), True)
  end;
end;

procedure TfrmProfile.SaveProfileData;

  procedure DoProfileSuccess(aResult: Boolean);
  begin
    if aResult then
    begin
      dmServer.ShowOK(SSavedProfileData);
      if FAvatarData <> '' then
        alForm['imgAvatar'].Value := FAvatarData;
    end
    else
      dmServer.ShowError(Format(SErrSavingProfileData, [SErrUnknown]));
  end;

  procedure DoProfileFail(aResponse: TJSObject; aFail: TJSError);
  begin
    dmServer.ShowError(ExtractErrorMsg(aFail, SErrSavingProfileData));
  end;

var
  aData: TROProfileData;
begin
  aData := TROProfileData.New;
  aData.fromObject(GetProfileData);
  dmServer.LoginService.SetProfileData(aData, @DoProfileSuccess, @DoProfileFail);
end;

function TfrmProfile.GetProfileData: TProfileData;
var
  Res: string;
  resStr: TJSString absolute Res;
  lenMod: Integer;
begin
  with Result do
  begin
    FirstName := alForm['edtFirstName'].Value;
    LastName := alForm['edtLastName'].Value;
    Email := alForm['edtEmail'].Value;
    Mobile := alForm['edtMobile'].Value;
    LoginName := alForm['edtLogin'].Value;
    Role := alForm['edtRole'].Value;
    NewPassword := alForm['edtNewPassword'].Value;
    OldPassword := alForm['edtCurrentPassword'].Value;
    if Self.FAvatarData <> '' then
    begin
      Res := FAvatarData;
      AvatarData := resStr.replace(TJSRegExp.New('^data:(.*,)?'), '');
      lenMod := Length(AvatarData) mod 4;
      if (lenMod > 0) then
        AvatarData := AvatarData + StringOfChar('=', 4 - lenMod);
      AvatarURL := FAvatarFileName;
    end;
  end;
end;

class function TfrmProfile.MyRoutes: TStringDynArray;
begin
  Result := ['/profile'];
end;

procedure TfrmProfile.setParams(const Params: TStrings);
begin
  inherited;
  // nothing at the moment. Tabs have been removed
end;

procedure TfrmProfile.ShowProfile(aProfile: TProfileData);
begin
  FUserRole := aProfile.Role;
  alForm['lblName'].Value := aProfile.FirstName + ' ' + aProfile.LastName;
  alForm['lblFunction'].Value := aProfile.Role;
  alForm['edtFirstName'].Value := aProfile.FirstName;
  alForm['edtLastName'].Value := aProfile.LastName;
  alForm['edtEmail'].Value := aProfile.Email;
  alForm['edtMobile'].Value := aProfile.Mobile;
  alForm['edtLogin'].Value := aProfile.LoginName;
  alForm['edtRole'].Value := aProfile.Role;
  if (aProfile.AvatarURL <> '') then
  begin
    alForm['imgAvatar'].Value := aProfile.AvatarURL;
    alForm['imgAvatarEdit'].Value := aProfile.AvatarURL;
  end;
  SetComboboxValue('edtRole', FUserRole);
end;

procedure TfrmProfile.WebFormCreate(Sender: TObject);

  procedure DoRolesSuccess(aResult: TROStringArray);
  begin
    ShowRoles(aResult.toArray);
  end;

  procedure DoRolesFail(aResponse: TJSObject; aFail: TJSError);
  begin
    dmServer.ShowError(ExtractErrorMsg(aFail, SErrFailedToGetRoles));
  end;

  procedure DoProfileSuccess(aResult: TROProfileData);
  begin
    ShowProfile(aResult.ToObject)
  end;

  procedure DoProfileFail(aResponse: TJSObject; aFail: TJSError);
  begin
    dmServer.ShowError(ExtractErrorMsg(aFail, SErrFailedToGetProfileData));
  end;

begin
  inherited;
  dmServer.LoginService.GetLoginRoles(@DoRolesSuccess, @DoRolesFail);
  dmServer.LoginService.GetProfileData(@DoProfileSuccess, @DoProfileFail);
end;

procedure TfrmProfile.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtEmail';
      Name := 'edtEmail';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtMobile';
      Name := 'edtMobile';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtFirstName';
      Name := 'edtFirstName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtLastName';
      Name := 'edtLastName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtLogin';
      Name := 'edtLogin';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtRole';
      Name := 'edtRole';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtCurrentPassword';
      Name := 'edtCurrentPassword';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtNewPassword';
      Name := 'edtNewPassword';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'DoSaveProfileData');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'imgAvatar';
      Name := 'imgAvatar';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'imgAvatarEdit';
      Name := 'imgAvatarEdit';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'lblName';
      Name := 'lblName';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'lblFunction';
      Name := 'lblFunction';
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'edtAvatarFile';
      Name := 'edtAvatarFile';
      SetEvent(Self, 'OnExecute', 'DoSelectAvatar');
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
