unit Forms.Possession.Prop;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery, Data.DB,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper, Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmPossessionProperty = class(TfrmBase)
    dsPosession: TP2WDADataset;
    dsPosessionproid: TLargeintField;
    dsPosessionprocreatedon: TDateTimeField;
    dsPosessionprocreatedbyfk: TLargeintField;
    dsPosessionprochangedon: TDateTimeField;
    dsPosessionprochangedbyfk: TLargeintField;
    dsPosessionprodossierfk: TLargeintField;
    dsPosessionpropropertytype: TStringField;
    dsPosessionpropropertytypefk: TLargeintField;
    dsPosessionproowner: TStringField;
    dsPosessionproownerfk: TLargeintField;
    dsPosessionproisrealestate: TBooleanField;
    dsPosessionproshortdescription: TStringField;
    dsPosessionprodescription: TBlobField;
    dsPosessionprocount: TIntegerField;
    dsPosessionpropurchasedon: TDateTimeField;
    dsPosessionpronotaryname: TStringField;
    dsPosessionpropurchaseprice: TFMTBCDField;
    dsPosessionprocurrentvalue: TFMTBCDField;
    dsPosessionproisfinanced: TBooleanField;
    dsPosessionproisseized: TBooleanField;
    dsPosessionproisshared: TBooleanField;
    dsPosessionproisgone: TBooleanField;
    dsPosessionprogoneon: TDateTimeField;
    dsPosessionprobuyerfk: TLargeintField;
    dsPosessionproiscomplete: TBooleanField;
    dsPosessionproremark: TStringField;
    dsDocuments: TP2WDADataset;
    dsPosessionprofinancing: TWideStringField;
    procedure dsPosessionAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsPosessionAfterOpen(DataSet: TDataSet);
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure DoOwnerChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    { Private declarations }
    FPosessionId : Int64;

    function FieldsValid: Boolean;

    procedure GetPropertyTypes;
    //procedure GetPropertyOwners;
  public
    { Public declarations }
    class function NeedsParams : Boolean; override;
    procedure setParams(const Params: TStrings); override;
    Procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmPossessionProperty: TfrmPossessionProperty;

implementation

uses
  Modules.Server, Units.ActionUtils, Units.Params, Units.DADatasetUtils, Units.HTMLUtils;

{$R *.dfm}

{ TfrmPossessionProperty }



procedure TfrmPossessionProperty.canHandleRoute(previousURL: string;
  var allow: Boolean);
begin
  allow := DiscardChanges and ((DefaultDocumentBox.GetAwaitingUploadCount = 0) OR DiscardFiles);
end;

procedure TfrmPossessionProperty.DoOwnerChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
Var
  ID : String;

begin
  id := String(jQuery('#cbPropOwner').find('option[value="' + alForm['cbPropOwner'].Value + '"]').attr('data-id'));
  if id = '' then
    id := '-1';
  jQuery('#cbPropOwner').attr('data-id', id);
end;

procedure TfrmPossessionProperty.dsPosessionAfterApplyUpdates(Sender: TDataSet;
    Info: TResolveResults);

  procedure uploadsCompleted;
  begin
    DiscardChanges := True;
    window.location.href := DossierURL('/possession');
  end;

begin
  if CheckResolveResults(Info) then
  begin
    if DefaultDocumentBox.GetAwaitingUploadCount = 0 then
    begin
      DiscardChanges := True;
      window.location.href := DossierURL('/possession');
    end
    else
      DefaultDocumentBox.DoFileQueueUpload(Sender.FieldByName('proid').asLargeInt,'', @uploadsCompleted);
  end;
end;

procedure TfrmPossessionProperty.SaveBtnClick(Sender: TObject;
    Element: TJSHTMLElementRecord; Event: TJSEventParameter);

begin
  if FieldsValid then
  begin
    if FPosessionId > 0 then
    begin
      dsPosession.Edit;
    end
    else
    begin
      dsPosession.Insert;
      dsPosession.FieldByName('procreatedon').asDateTime := now();
      dsPosession.FieldByName('procreatedbyfk').asLargeInt := Server.UserID;
      dsPosession.FieldByName('prodossierfk').asLargeInt := DossierID;
      dsPosession.FieldByName('proisrealestate').asBoolean := False;

      dsPosession.FieldByName('proisfinanced').asBoolean := false;
      dsPosession.FieldByName('proisshared').asBoolean := false;
      dsPosession.FieldByName('propurchasedon').asDateTime := now;
      dsPosession.FieldByName('profinancing').asString:='';
    end;

    dsPosession.FieldByName('prochangedon').asDateTime := now();
    dsPosession.FieldByName('prochangedbyfk').asLargeInt := Server.UserID;

    dsPosession.FieldByName('procount').asInteger := strToInt(alForm['edtQuantity'].value);
    dsPosession.FieldByName('prodescription').asString := alForm['mmDescription'].value;
    dsPosession.FieldByName('proisseized').asBoolean := getRadioGroupValue('rdConfiscated') = 'Yes';
    dsPosession.FieldByName('proisgone').asBoolean := getRadioGroupValue('rdStolen') = 'Yes';

    dsPosession.FieldByName('propropertytype').asString := alForm['cbPropType'].value;
    dsPosession.FieldByName('propropertytypefk').asInteger := 0;
    dsPosession.FieldByName('proowner').asString := ''; //alForm['cbPropOwner'].value;
    dsPosession.FieldByName('proownerfk').asInteger := 0; //JSValueToInt(jQuery('#cbPropOwner').attr('data-id'));

    dsPosession.FieldByName('pronotaryname').asString := '-';
    dsPosession.FieldByName('proshortdescription').asString := alForm['edtShortDescription'].value;
    dsPosession.FieldByName('propurchaseprice').asFloat := 0;
    dsPosession.FieldByName('procurrentvalue').asFloat := StrToFloatDef(alForm['edtCurrentPrice'].value,0.0, GetFormatSettings);
    dsPosession.FieldByName('proremark').asString := alForm['mmRemark'].value;
    if (alForm['ddtStolenDate'].value='') then
      dsPosession.FieldByName('progoneon').Clear
    else
    begin
      dsPosession.FieldByName('progoneon').AsDateTime:=ExtractDate(alForm['ddtStolenDate'].value);
    end;
    dsPosession.FieldByName('proiscomplete').asBoolean := getRadioGroupValue('rdPropertyEntrance') = 'Yes';
    dsPosession.Post;
    dsPosession.ApplyUpdates;
  end;
end;

procedure TfrmPossessionProperty.WebFormCreate(Sender: TObject);

begin
  inherited;
  FPosessionId := -1;

  dmServer.DoOnParamsLoaded(GetPropertyTypes);
  //dmServer.DoOnParamsLoaded(GetPropertyOwners);
  dsPosession.DAConnection := DossierConnection;
  BindOnChangeEvent;
end;

procedure TfrmPossessionProperty.dsPosessionAfterOpen(DataSet: TDataSet);

var
  SourceID : NativeInt;

begin
  SourceID:=-1;
  if not DataSet.isEmpty then
  begin
    alForm['ddtStolenDate'].value:=FormatHTMLDate(dsPosession.FieldByName('progoneon').AsDateTime);
    alForm['cbPropType'].value := DataSet.FieldByName('propropertytype').asString;
    jQuery('#cbPropType').attr('data-id', DataSet.FieldByName('propropertytypefk').asString);
    alForm['edtShortDescription'].value := DataSet.FieldByName('proshortdescription').asString;
    alForm['mmDescription'].value := DataSet.FieldByName('prodescription').asString;
    alForm['edtQuantity'].value := DataSet.FieldByName('procount').asString;
    alForm['edtCurrentPrice'].value := DataSet.FieldByName('procurrentvalue').asString;
    if DataSet.FieldByName('proisseized').asBoolean then
      setRadiogroupSelectedElement('rdConfiscated', 'Yes')
    else
      setRadiogroupSelectedElement('rdConfiscated', 'No');
    alForm['cbPropOwner'].value := DataSet.FieldByName('proowner').asString;
    jQuery('#cbPropOwner').attr('data-id', DataSet.FieldByName('proownerfk').asString);
    alForm['mmRemark'].value := DataSet.FieldByName('proremark').asString;
    if DataSet.FieldByName('proiscomplete').asBoolean then
      setRadiogroupSelectedElement('rdPropertyEntrance', 'Yes')
    else
      setRadiogroupSelectedElement('rdPropertyEntrance', 'No');
    if DataSet.FieldByName('proisgone').asBoolean then
      setRadiogroupSelectedElement('rdStolen', 'Yes')
    else
      setRadiogroupSelectedElement('rdStolen', 'No');
    SourceID:=DataSet.FieldByName('proid').asLargeInt;
  end;
  DefaultDocumentBox.LoadDocuments(dsDocuments, DossierID, SourceID , 'uploadList');
  DefaultDocumentBox.setDocBoxParams(7, SourceID, 'PROPPROOF', DossierID);
end;

function TfrmPossessionProperty.FieldsValid: Boolean;

Var
  F : Double;

begin
  Result := not FieldIsEmpty('cbPropType');
  Result := not FieldIsEmpty('edtShortDescription') and Result;
  Result := not FieldIsEmpty('mmDescription') and Result;
  Result := not FieldIsEmpty('edtQuantity') and Result;
  Result := isNumeric('edtQuantity') and Result;
  if Not FieldIsEmpty('edtCurrentPrice',False) then
    Result := TryStrToFloat(alForm['edtCurrentPrice'].Value,F) and Result;
//  Result := not FieldIsEmpty('cbPropOwner') and Result;
//  Result := not FieldIsEmpty('mmRemark') and Result;
  Result := isSelectedRadioGroup('rdConfiscated') and Result;
  Result := isSelectedRadioGroup('rdPropertyEntrance') and Result;
  Result := isSelectedRadioGroup('rdStolen') and Result;
end;

//procedure TfrmPossessionProperty.GetPropertyOwners;
//var
//  list : TDezqParameterArray;
//  i : Integer;
//  S : String;
//begin
//  list := Server.GetParamList('PROPOWN');
//  S:='';
//  for i := Low(list) to High(list) do
//  begin
//    S := S +
//       '<option value="' + list[i].Name + '" data-id="' + intToStr(list[i].RecordID) +'">' + list[i].Value + '</option>';
//  end;
//  alForm['cbPropOwner'].InnerHTML:=S;
//end;

procedure TfrmPossessionProperty.GetPropertyTypes;
var
  list : TDezqParameterArray;
  i : Integer;
  S : String;
begin
  list := Server.GetParamList('PROPTYP');
  S:='';
  for i := Low(list) to High(list) do
  begin
    if list[i].Name <> 'ONRG' then
      S := S +
         '<option value="' + list[i].Name + '" data-id="' + intToStr(list[i].RecordID) +'">' + list[i].Value + '</option>';
  end;
  alForm['cbPropType'].InnerHTML:=S;
end;

class function TfrmPossessionProperty.NeedsParams: Boolean;
begin
  Result:=True;
end;

procedure TfrmPossessionProperty.setParams(const Params: TStrings);
begin
  Inherited;
  jQuery('#returnBack').attr('href',DossierURL('/possession'));
  FPosessionId := StrToInt64Def(Params.Values['POSSESSIONID'], -1);
end;

procedure TfrmPossessionProperty.WebFormShow(Sender: TObject);
begin
  inherited;
  dsPosession.ParamByName('PROID').AsInteger := FPosessionId;
  dsPosession.Load([], nil);
end;

procedure TfrmPossessionProperty.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsPosession := TP2WDADataset.Create(Self);
  dsPosessionproid := TLargeintField.Create(Self);
  dsPosessionprocreatedon := TDateTimeField.Create(Self);
  dsPosessionprocreatedbyfk := TLargeintField.Create(Self);
  dsPosessionprochangedon := TDateTimeField.Create(Self);
  dsPosessionprochangedbyfk := TLargeintField.Create(Self);
  dsPosessionprodossierfk := TLargeintField.Create(Self);
  dsPosessionpropropertytype := TStringField.Create(Self);
  dsPosessionpropropertytypefk := TLargeintField.Create(Self);
  dsPosessionproowner := TStringField.Create(Self);
  dsPosessionproownerfk := TLargeintField.Create(Self);
  dsPosessionproisrealestate := TBooleanField.Create(Self);
  dsPosessionproshortdescription := TStringField.Create(Self);
  dsPosessionprodescription := TBlobField.Create(Self);
  dsPosessionprocount := TIntegerField.Create(Self);
  dsPosessionpropurchasedon := TDateTimeField.Create(Self);
  dsPosessionpronotaryname := TStringField.Create(Self);
  dsPosessionpropurchaseprice := TFMTBCDField.Create(Self);
  dsPosessionprocurrentvalue := TFMTBCDField.Create(Self);
  dsPosessionproisfinanced := TBooleanField.Create(Self);
  dsPosessionproisseized := TBooleanField.Create(Self);
  dsPosessionproisshared := TBooleanField.Create(Self);
  dsPosessionproisgone := TBooleanField.Create(Self);
  dsPosessionprogoneon := TDateTimeField.Create(Self);
  dsPosessionprobuyerfk := TLargeintField.Create(Self);
  dsPosessionproiscomplete := TBooleanField.Create(Self);
  dsPosessionproremark := TStringField.Create(Self);
  dsPosessionprofinancing := TWideStringField.Create(Self);
  dsDocuments := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsPosession.BeforeLoadDFMValues;
  dsPosessionproid.BeforeLoadDFMValues;
  dsPosessionprocreatedon.BeforeLoadDFMValues;
  dsPosessionprocreatedbyfk.BeforeLoadDFMValues;
  dsPosessionprochangedon.BeforeLoadDFMValues;
  dsPosessionprochangedbyfk.BeforeLoadDFMValues;
  dsPosessionprodossierfk.BeforeLoadDFMValues;
  dsPosessionpropropertytype.BeforeLoadDFMValues;
  dsPosessionpropropertytypefk.BeforeLoadDFMValues;
  dsPosessionproowner.BeforeLoadDFMValues;
  dsPosessionproownerfk.BeforeLoadDFMValues;
  dsPosessionproisrealestate.BeforeLoadDFMValues;
  dsPosessionproshortdescription.BeforeLoadDFMValues;
  dsPosessionprodescription.BeforeLoadDFMValues;
  dsPosessionprocount.BeforeLoadDFMValues;
  dsPosessionpropurchasedon.BeforeLoadDFMValues;
  dsPosessionpronotaryname.BeforeLoadDFMValues;
  dsPosessionpropurchaseprice.BeforeLoadDFMValues;
  dsPosessionprocurrentvalue.BeforeLoadDFMValues;
  dsPosessionproisfinanced.BeforeLoadDFMValues;
  dsPosessionproisseized.BeforeLoadDFMValues;
  dsPosessionproisshared.BeforeLoadDFMValues;
  dsPosessionproisgone.BeforeLoadDFMValues;
  dsPosessionprogoneon.BeforeLoadDFMValues;
  dsPosessionprobuyerfk.BeforeLoadDFMValues;
  dsPosessionproiscomplete.BeforeLoadDFMValues;
  dsPosessionproremark.BeforeLoadDFMValues;
  dsPosessionprofinancing.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'cbPropType';
      Name := 'cbPropType';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtShortDescription';
      Name := 'edtShortDescription';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'mmDescription';
      Name := 'mmDescription';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtQuantity';
      Name := 'edtQuantity';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'cbPropOwner';
      Name := 'cbPropOwner';
      SetEvent(Self, 'OnExecute', 'DoOwnerChange');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'mmRemark';
      Name := 'mmRemark';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdConfiscated';
      PreventDefault := False;
      Selector := 'input[name="rdConfiscated"]';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdPropertyEntrance';
      PreventDefault := False;
      Selector := 'input[name="rdPropertyEntrance"]';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtStolenDate';
      Name := 'ddtStolenDate';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtCurrentPrice';
      Name := 'edtCurrentPrice';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    dsPosession.SetParentComponent(Self);
    dsPosession.Name := 'dsPosession';
    dsPosession.TableName := 'property';
    dsPosession.Params.Clear;
    with dsPosession.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'PROID';
      ParamType := ptInput;
      Value := Null;
    end;
    dsPosession.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>proid</field><parameter type="LargeInt">PROID</parameter></binaryoperation></where></query>';
    dsPosession.DAOptions := [];
    dsPosession.AfterOpen := dsPosessionAfterOpen;
    dsPosession.AfterApplyUpdates := dsPosessionAfterApplyUpdates;
    dsPosession.Left := 40;
    dsPosession.Top := 80;
    dsPosessionproid.SetParentComponent(dsPosession);
    dsPosessionproid.Name := 'dsPosessionproid';
    dsPosessionproid.FieldName := 'proid';
    dsPosessionprocreatedon.SetParentComponent(dsPosession);
    dsPosessionprocreatedon.Name := 'dsPosessionprocreatedon';
    dsPosessionprocreatedon.FieldName := 'procreatedon';
    dsPosessionprocreatedbyfk.SetParentComponent(dsPosession);
    dsPosessionprocreatedbyfk.Name := 'dsPosessionprocreatedbyfk';
    dsPosessionprocreatedbyfk.FieldName := 'procreatedbyfk';
    dsPosessionprochangedon.SetParentComponent(dsPosession);
    dsPosessionprochangedon.Name := 'dsPosessionprochangedon';
    dsPosessionprochangedon.FieldName := 'prochangedon';
    dsPosessionprochangedbyfk.SetParentComponent(dsPosession);
    dsPosessionprochangedbyfk.Name := 'dsPosessionprochangedbyfk';
    dsPosessionprochangedbyfk.FieldName := 'prochangedbyfk';
    dsPosessionprodossierfk.SetParentComponent(dsPosession);
    dsPosessionprodossierfk.Name := 'dsPosessionprodossierfk';
    dsPosessionprodossierfk.FieldName := 'prodossierfk';
    dsPosessionpropropertytype.SetParentComponent(dsPosession);
    dsPosessionpropropertytype.Name := 'dsPosessionpropropertytype';
    dsPosessionpropropertytype.FieldName := 'propropertytype';
    dsPosessionpropropertytype.Size := 10;
    dsPosessionpropropertytypefk.SetParentComponent(dsPosession);
    dsPosessionpropropertytypefk.Name := 'dsPosessionpropropertytypefk';
    dsPosessionpropropertytypefk.FieldName := 'propropertytypefk';
    dsPosessionproowner.SetParentComponent(dsPosession);
    dsPosessionproowner.Name := 'dsPosessionproowner';
    dsPosessionproowner.FieldName := 'proowner';
    dsPosessionproowner.Size := 10;
    dsPosessionproownerfk.SetParentComponent(dsPosession);
    dsPosessionproownerfk.Name := 'dsPosessionproownerfk';
    dsPosessionproownerfk.FieldName := 'proownerfk';
    dsPosessionproisrealestate.SetParentComponent(dsPosession);
    dsPosessionproisrealestate.Name := 'dsPosessionproisrealestate';
    dsPosessionproisrealestate.FieldName := 'proisrealestate';
    dsPosessionproshortdescription.SetParentComponent(dsPosession);
    dsPosessionproshortdescription.Name := 'dsPosessionproshortdescription';
    dsPosessionproshortdescription.FieldName := 'proshortdescription';
    dsPosessionproshortdescription.Size := 127;
    dsPosessionprodescription.SetParentComponent(dsPosession);
    dsPosessionprodescription.Name := 'dsPosessionprodescription';
    dsPosessionprodescription.FieldName := 'prodescription';
    dsPosessionprocount.SetParentComponent(dsPosession);
    dsPosessionprocount.Name := 'dsPosessionprocount';
    dsPosessionprocount.FieldName := 'procount';
    dsPosessionpropurchasedon.SetParentComponent(dsPosession);
    dsPosessionpropurchasedon.Name := 'dsPosessionpropurchasedon';
    dsPosessionpropurchasedon.FieldName := 'propurchasedon';
    dsPosessionpronotaryname.SetParentComponent(dsPosession);
    dsPosessionpronotaryname.Name := 'dsPosessionpronotaryname';
    dsPosessionpronotaryname.FieldName := 'pronotaryname';
    dsPosessionpronotaryname.Size := 100;
    dsPosessionpropurchaseprice.SetParentComponent(dsPosession);
    dsPosessionpropurchaseprice.Name := 'dsPosessionpropurchaseprice';
    dsPosessionpropurchaseprice.FieldName := 'propurchaseprice';
    dsPosessionpropurchaseprice.Precision := 12;
    dsPosessionpropurchaseprice.Size := 0;
    dsPosessionprocurrentvalue.SetParentComponent(dsPosession);
    dsPosessionprocurrentvalue.Name := 'dsPosessionprocurrentvalue';
    dsPosessionprocurrentvalue.FieldName := 'procurrentvalue';
    dsPosessionprocurrentvalue.Precision := 12;
    dsPosessionprocurrentvalue.Size := 0;
    dsPosessionproisfinanced.SetParentComponent(dsPosession);
    dsPosessionproisfinanced.Name := 'dsPosessionproisfinanced';
    dsPosessionproisfinanced.FieldName := 'proisfinanced';
    dsPosessionproisseized.SetParentComponent(dsPosession);
    dsPosessionproisseized.Name := 'dsPosessionproisseized';
    dsPosessionproisseized.FieldName := 'proisseized';
    dsPosessionproisshared.SetParentComponent(dsPosession);
    dsPosessionproisshared.Name := 'dsPosessionproisshared';
    dsPosessionproisshared.FieldName := 'proisshared';
    dsPosessionproisgone.SetParentComponent(dsPosession);
    dsPosessionproisgone.Name := 'dsPosessionproisgone';
    dsPosessionproisgone.FieldName := 'proisgone';
    dsPosessionprogoneon.SetParentComponent(dsPosession);
    dsPosessionprogoneon.Name := 'dsPosessionprogoneon';
    dsPosessionprogoneon.FieldName := 'progoneon';
    dsPosessionprobuyerfk.SetParentComponent(dsPosession);
    dsPosessionprobuyerfk.Name := 'dsPosessionprobuyerfk';
    dsPosessionprobuyerfk.FieldName := 'probuyerfk';
    dsPosessionproiscomplete.SetParentComponent(dsPosession);
    dsPosessionproiscomplete.Name := 'dsPosessionproiscomplete';
    dsPosessionproiscomplete.FieldName := 'proiscomplete';
    dsPosessionproremark.SetParentComponent(dsPosession);
    dsPosessionproremark.Name := 'dsPosessionproremark';
    dsPosessionproremark.FieldName := 'proremark';
    dsPosessionproremark.Size := 255;
    dsPosessionprofinancing.SetParentComponent(dsPosession);
    dsPosessionprofinancing.Name := 'dsPosessionprofinancing';
    dsPosessionprofinancing.FieldName := 'profinancing';
    dsPosessionprofinancing.Size := 255;
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">PROPPROOF</constant></binaryoperation></binaryoperation></where></query>';
    dsDocuments.DAOptions := [];
    dsDocuments.Left := 544;
    dsDocuments.Top := 40;
  finally
    alForm.AfterLoadDFMValues;
    dsPosession.AfterLoadDFMValues;
    dsPosessionproid.AfterLoadDFMValues;
    dsPosessionprocreatedon.AfterLoadDFMValues;
    dsPosessionprocreatedbyfk.AfterLoadDFMValues;
    dsPosessionprochangedon.AfterLoadDFMValues;
    dsPosessionprochangedbyfk.AfterLoadDFMValues;
    dsPosessionprodossierfk.AfterLoadDFMValues;
    dsPosessionpropropertytype.AfterLoadDFMValues;
    dsPosessionpropropertytypefk.AfterLoadDFMValues;
    dsPosessionproowner.AfterLoadDFMValues;
    dsPosessionproownerfk.AfterLoadDFMValues;
    dsPosessionproisrealestate.AfterLoadDFMValues;
    dsPosessionproshortdescription.AfterLoadDFMValues;
    dsPosessionprodescription.AfterLoadDFMValues;
    dsPosessionprocount.AfterLoadDFMValues;
    dsPosessionpropurchasedon.AfterLoadDFMValues;
    dsPosessionpronotaryname.AfterLoadDFMValues;
    dsPosessionpropurchaseprice.AfterLoadDFMValues;
    dsPosessionprocurrentvalue.AfterLoadDFMValues;
    dsPosessionproisfinanced.AfterLoadDFMValues;
    dsPosessionproisseized.AfterLoadDFMValues;
    dsPosessionproisshared.AfterLoadDFMValues;
    dsPosessionproisgone.AfterLoadDFMValues;
    dsPosessionprogoneon.AfterLoadDFMValues;
    dsPosessionprobuyerfk.AfterLoadDFMValues;
    dsPosessionproiscomplete.AfterLoadDFMValues;
    dsPosessionproremark.AfterLoadDFMValues;
    dsPosessionprofinancing.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
  end;
end;

end.
