unit lib.datatables;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  types,
  js,
  web,
  libjquery;

const
  OrderAsc = 'asc';
  OrderDesc = 'desc';

  PagingNumbers = 'numbers';
  PagingSimple = 'simple';
  PagingSimpleNumbers = 'simple_numbers';
  PagingFull = 'full';
  PagingFullNumbers = 'full_numbers';
  PagingFirstLastNumbers = 'first_last_numbers';

  AutoFillFocusClick = 'click';
  AutoFillFocusFocus = 'focus';
  AutoFillFocushover = 'hover';

  FixedColumnsHeightMatchNone = 'none';
  FixedColumnsHeightMatchSemiAuto = 'semiauto';
  FixedColumnsHeightMatchAuto = 'auto';

  SelectOptionsItemsRow = 'row';
  SelectOptionsItemsColumn = 'column';
  SelectOptionsItemsCell = 'cell';

  SelectOptionsStyleAPI = 'api';
  SelectOptionsStyleSingle = 'single';
  SelectOptionsStyleMulti = 'multi';
  SelectOptionsStyleMultiShitf = 'multi+shift';

type
  // Trunk compatibility
  //TJSRawEventHandler = TJSEventHandler;

  TJSDataTableAPI = class;
  TJSDTOButton = class;
  TJSDataTableEditor = TJSObject;

  TJSDTOSearchOptions = class external name 'Object'(TJSObject)
  public
    search: string;
    caseInsensitive: Boolean;
    regex: Boolean;
    smart: Boolean;
  end;

  TJSDTOSearchOptionsDynArray = array of TJSDTOSearchOptions;

  TJSDTOLanguagePaginateOptions = class external name 'Object'(TJSObject)
  public
    first: string;
    previous: string;
    next: string;
    last: string;
  end;

  TJSDTOLanguageAriaOptions = class external name 'Object'(TJSObject)
  public
    paginate: TJSDTOLanguagePaginateOptions;
    sortAscending: string;
  end;

  TJSDTOLanguageAutoFillOptions = class external name 'Object'(TJSObject)
  public
    button: string;
    cancel: string;
    fill: string;
    fillHorizontal: string;
    fillVertical: string;
    increment: string;
    info: string;
  end;

  TJSDTOLanguageSelectOptions = class external name 'Object'(TJSObject)
  public
    cells: string;
    cellsObj: TJSObject; external name 'cells';
    columns: string;
    columnsObj: TJSObject; external name 'columns';
    rows: string;
    rowsObj: TJSObject; external name 'rows';
  end;

  TJSDTOLanguageOptions = class external name 'Object'(TJSObject)
  public
    aria: TJSDTOLanguageAriaOptions;
    autoFill: TJSDTOLanguageAutoFillOptions;
    decimal: string;
    emptyTable: string;
    info: string;
    infoEmpty: string;
    infoFiltered: string;
    infoPostFix: string;
    lengthMenu: string;
    loadingRecords: string;
    paginate: TJSDTOLanguagePaginateOptions;
    processing: string;
    search: string;
    searchPlaceholder: string;
    thousands: string;
    url: string;
    zeroRecords: string;
    select: TJSDTOLanguageSelectOptions;
  end;

  TJSDTOAutoFillOptions = class external name 'Object'(TJSObject)
  public
    alwaysAsk: Boolean;
    columns: string;
    columnsIndex: TIntegerDynArray; external name 'columns';
    editor: TJSDataTableEditor;
    enable: Boolean;
    focus: string;
    focusNull: jsValue external name 'focus';
    horizontal: Boolean;
    update: Boolean;
    vertical: Boolean;
  end;

  TJSDTOButtonActionCallBack = reference to procedure(e: TJSEvent; dt: TJSDataTableAPI; node: TJQuery; Config: TJSDTOButton);
  TJSDTOButtonAvailableCallBack = reference to function(dt: TJSDataTableAPI; Config: TJSDTOButton): Boolean;
  TJSDTOButtonDestroyCallBack = reference to procedure(dt: TJSDataTableAPI; node: TJQuery; Config: TJSDTOButton);
  TJSDTOButtonInitCallBack = reference to procedure(dt: TJSDataTableAPI; node: TJQuery; Config: TJSDTOButton);
  TJSDTOButtonTitleCallBack = reference to function(dt: TJSDataTableAPI; node: TJQuery; Config: TJSDTOButton): string;

  TJSDataTableOptionsButtonAttr = class external name 'Object'(TJSObject)
  public
    title: string;
    id: string;
  end;

  TJSDTOButtonKeyOptions = class external name 'Object'(TJSObject)
  public
    key: string;
    shift: Boolean;
    altkey: Boolean;
    ctrlKey: Boolean;
    metaKey: Boolean;
  end;

  TJSDataTableButtonDomBase = class external name 'Object'(TJSObject)
  public
    tag: string;
    classname: string;
  end;

  TJSDataTableButtonDomButtonOptions = class external name 'Object'(TJSDataTableButtonDomBase)
  public
    disabled: string;
    active: string;
  end;

  TJSDataTableButtonDomOption = class external name 'Object'(TJSObject)
  public
    button: TJSDataTableButtonDomButtonOptions;
    buttonObj: TJSObject; external name 'button';
    buttonLiner: TJSDataTableButtonDomBase;
    buttonLinerObj: TJSObject; external name 'buttonLiner';
    collection: TJSDataTableButtonDomBase;
    collectionObj: TJSObject; external name 'collection';
    container: TJSDataTableButtonDomBase;
    containerObj: TJSObject; external name 'container';
  end;

  TJSDTOButton = class external name 'Object'(TJSObject)
  public
    action: TJSDTOButtonActionCallBack;
    attr: TJSDataTableOptionsButtonAttr;
    available: TJSDTOButtonAvailableCallBack;
    classname: string;
    destroy: TJSDTOButtonDestroyCallBack;
    enabled: Boolean;
    extend: string;
    init: TJSDTOButtonInitCallBack;
    key: string;
    keyObj: TJSDTOButtonKeyOptions; external name 'key';
    name: string;
    namespace: string;
    tag: string;
    text: string;
    titleAttr: string;
    titleAttrCallBack: TJSDTOButtonTitleCallBack; external name 'titleAttr';
    dom: TJSDataTableButtonDomOption;
  end;

  TJSDTOButtonDynArray = array of TJSDTOButton;

  TJSDTOButtons = class external name 'Object'(TJSObject)
  public
    buttonsStringArray: TStringDynArray; external name 'buttons';
    buttonsMixedArray: TJSValueDynArray; external name 'buttons';
    ButtonsObjArray: TJSDTOButtonDynArray; external name 'buttons';
    bame: string;
  end;

  TJSDTOColReorderOptions = class external name 'Object'(TJSObject)
  public
    enable: Boolean;
    fixedColumnsLeft: Integer;
    fixedColumnsRight: Integer;
    order: TIntegerDynArray;
    realtime: Boolean;
  end;

  TJSDTOFixedColumnsOptions = class external name 'Object'(TJSObject)
  public
    heightMatch: string;
    leftColumns: Integer;
    rightColumns: Integer;
  end;

  TJSDTOFixedHeaderOptions = class external name 'Object'(TJSObject)
  public
    footer: Boolean;
    footerOffset: Integer;
    header: Boolean;
    headerOffset: Integer;
  end;

  TJSDTOKeyTableOptions = class external name 'Object'(TJSObject)
  public
    blurable: Boolean;
    classname: string;
    clipboardOrthogonal: string;
    columns: string;
    editOnFocus: Boolean;
    editor: TJSDataTableEditor;
    focus: string;
    keysArray: TStringDynArray; external name 'keys';
    keysNull: jsValue; external name 'keys';
    tabIndex: Integer;
  end;

  TJSDTOResponsiveBreakPoint = record
    name: string;
    width: Integer;
  end;

  TJSDTOResponsiveBreakPointDynArray = array of TJSDTOResponsiveBreakPoint;

  TJSRenderColumns = record
    title: string;
    data: string;
    hidden: Boolean;
    columnIndex: Integer;
    rowIndex: Integer;
  end;

  TJSRenderColumnsDynArray = array of TJSRenderColumns;

  TJSDTOResponsiveDetailsDisplayCallbackRender = function: string;
  TJSDTOResponsiveDetailsDisplayCallback = reference to function(row: TJSDataTableAPI; upate: Boolean; Render: TJSDTOResponsiveDetailsDisplayCallbackRender): Boolean;

  TJSDTOResponsiveDetailsRenderBoolCallback = reference to function(api: TJSDataTableAPI; rowIndex: Integer; columns: TJSRenderColumnsDynArray): Boolean;
  TJSDTOResponsiveDetailsRenderStringCallback = reference to function(api: TJSDataTableAPI; rowIndex: Integer; columns: TJSRenderColumnsDynArray): string;

  TJSDTOResponsiveDetails = class external name 'Object'(TJSObject)
  public
    display: TJSDTOResponsiveDetailsDisplayCallback;
    renderBool: TJSDTOResponsiveDetailsRenderBoolCallback; external name 'render';
    renderString: TJSDTOResponsiveDetailsRenderBoolCallback; external name 'render';
    target: Integer;
    targetStr: string; external name 'target';
    type_: string; external name 'type';
    orthogonal: string;
  end;

  TJSDTOResponsiveOptions = class external name 'Object'(TJSObject)
  public
    breakpoints: TJSDTOResponsiveBreakPointDynArray;
    details: Boolean;
    detailsObj: TJSDTOResponsiveDetails; external name 'details';
  end;

  TJSDTORowReorderOptions = class external name 'Object'(TJSObject)
  public
    dataSrc: Integer;
    dataSrcStr: string; external name 'dataSrc';
    editor: TJSDataTableEditor;
    selector: string;
    snapX: Boolean;
    snapXint: Integer; external name 'snapX';
    update: Boolean;
  end;

  TJSDTOScrollerOptions = class external name 'Object'(TJSObject)
  public
    boundaryScale: Double;
    displayBuffer: Integer;
    loadingIndicator: Boolean;
    rowHeight: Integer;
    rowHeightStr: string; external name 'rowHeight';
    serverWait: Integer;
  end;

  TJSDTOSelectOptions = class external name 'Object'(TJSObject)
  public
    blurable: Boolean;
    classname: string;
    info: Boolean;
    items: string;
    selector: string;
    style: string;
    toggleable: Boolean;
  end;

  TJSDTOColumnCreatedCellCallBack = reference to procedure(Cell: TJSNode; cellData, rowData: jsValue; rowIndex, ColIndex: Integer);
  TJSDTOColumnObjectDataCallBack = reference to function(row: TJSObject; aType: string; aSet: jsValue; meta: TJSObject): jsValue;
  TJSDTOColumnArrayDataCallBack = reference to function(row: TJSArray; aType: string; aSet: jsValue; meta: TJSObject): jsValue;
  TJSDTOColumnObjectRenderCallBack = reference to function(data: jsValue; aType: string; row: TJSObject; meta: TJSObject): jsValue;
  TJSDTOColumnArrayRenderCallBack = reference to function(data: jsValue; aType: string; row: TJSArray; meta: TJSObject): jsValue;

  TJSDTOColumn = class external name 'Object'(TJSObject)
  public
    cellType: string;
    classname: string;
    contentPadding: string;
    createdCell: TJSDTOColumnCreatedCellCallBack;
    data: string;
    dataInt: Integer; external name 'data';
    dataNull: jsValue; external name 'data';
    dataObj: TJSObject; external name 'data';
    dataObjectFunction: TJSDTOColumnObjectDataCallBack; external name 'data';
    dataArrayFunction: TJSDTOColumnArrayDataCallBack; external name 'data';
    defaultContent: string;
    name: string;
    orderData: Integer;
    orderDataArray: TIntegerDynArray; external name 'orderData';
    orderDataType: string;
    orderSequence: TStringDynArray;
    orderable: Boolean;
    Render: Integer;
    renderStr: string; external name 'render';
    renderObj: string; external name 'render';
    renderObjectFunction: TJSDTOColumnObjectRenderCallBack; external name 'render';
    renderArrayFunction: TJSDTOColumnArrayRenderCallBack; external name 'render';
    searchable: Boolean;
    title: string;
    type_: string; external name 'type';
    visible: Boolean;
    width: string;
    targets: Integer;
    targetsStr: string; external name 'targets';
    targetsIntArray: TIntegerDynArray; external name 'targets';
    targetsStringArray: TStringDynArray; external name 'targets';
  end;

  TJSDTOColumnDynArray = array of TJSDTOColumn;

  TJSDTORowGroupEndRenderCallBack = reference to function(rows: TJSDataTableAPI; Group: string; level: Integer): jsValue;

  TJSDTORowGroup = class external name 'Object'(TJSObject)
  public
    classname: string;
    dataSrc: Integer;
    dataSrcStr: string; external name 'dataSrc';
    dataSrcStrArray: TStringDynArray; external name 'dataSrc';
    dataSrcIntegerArray: TIntegerDynArray; external name 'dataSrc';
    emptyDataGroup: string;
    emptyDataGroupNull: jsValue; external name 'emptyDataGroup';
    enable: Boolean;
    endClass: string;
    endRenderNull: jsValue; external name 'endRender';
    endRender: TJSDTORowGroupEndRenderCallBack;
    startClassName: string;
    startRenderNull: jsValue; external name 'sraerRender';
    startRender: TJSDTORowGroupEndRenderCallBack;
  end;

  TJSDTOAjaxCallBack = reference to function(data: TJSObject): TJSArray;

  TJSDTOAjax = class external name 'Object'(TJSAjaxSettings)
  public
    dataSrc: string;
    dataSrcCallBack: TJSDTOAjaxCallBack; external name 'dataSrc';
  end;

  TJSDTODrawCallback = reference to procedure(settings: TJSObject);
  TJSDTOCreatedRowObjectRenderCallBack = reference to function(row: TJSNode; data: TJSObject; dataIndex: Integer): jsValue;
  TJSDTOCreatedRowArrayRenderCallBack = reference to function(row: TJSNode; data: TJSArray; dataIndex: Integer): jsValue;

  TJSDataTableOptions = class external name 'Object'(TJSObject)
  public
    ajax: string;
    ajaxObj: TJSDTOAjax; external name 'ajax';
    data: TJSArray;
    autoFill: Boolean;
    autoFillObj: TJSDTOAutoFillOptions; external name 'autoFill';
    autoWidth: Boolean;
    deferRender: Boolean;
    info: Boolean;
    lengthChange: Boolean;
    ordering: Boolean;
    paging: Boolean;
    processing: Boolean;
    scrollX: Boolean;
    scrollY: string;
    searching: Boolean;
    serverSide: Boolean;
    stateSave: Boolean;
    deferLoading: Integer;
    deferLoadingArray: TIntegerDynArray; external name 'deferLoading';
    destroy: Boolean;
    displayStart: Integer;
    dom: string;
    lengthMenu: TIntegerDynArray;
    lengthMenuJSValue: TJSValueDynArray; external name 'lengthMenu';
    order: array of TJSValueDynArray;
    orderCellsTop: Boolean;
    orderClasses: Boolean;
    orderFixed: TJSValueDynArray;
    orderFixedObj: TJSObject; external name 'orderFixed';
    orderMulti: Boolean;
    pageLength: Integer;
    pagingType: string;
    renderer: string;
    rendererObj: TJSObject; external name 'renderer';
    retrieve: Boolean;
    rowId: string;
    scrollCollapse: Boolean;
    search: TJSDTOSearchOptions;
    searchCols: TJSDTOSearchOptionsDynArray;
    searchDelay: Boolean;
    stateDuration: Boolean;
    stripeClasses: TStringDynArray;
    tabIndex: Integer;
    language: TJSDTOLanguageOptions;
    languageObj: TJSObject; external name 'language';
    buttons: Boolean;
    buttonsArray: TStringDynArray; external name 'buttons';
    buttonsMixedArray: TJSValueDynArray; external name 'buttons';
    ButtonsObj: TJSDTOButtons; external name 'buttons';
    ButtonsObjArray: TJSDTOButtonDynArray; external name 'buttons';
    colReorder: Boolean;
    colReorderObj: TJSDTOColReorderOptions; external name 'colReorder';
    fixedColumns: Boolean;
    fixedColumnsObj: TJSDTOFixedColumnsOptions; external name 'fixedColumns';
    fixedHeader: Boolean;
    fixedHeaderObj: TJSDTOFixedHeaderOptions; external name 'fixedHeader';
    keyTable: Boolean;
    keyTableObj: TJSDTOKeyTableOptions; external name 'keyTable';
    responsive: Boolean;
    responsiveObj: TJSDTOResponsiveOptions; external name 'responsive';
    rowReorder: Boolean;
    rowReorderObj: TJSDTORowReorderOptions; external name 'rowReorder';
    scroller: Boolean;
    scrollerObj: TJSDTOScrollerOptions; external name 'scroller';
    select: Boolean;
    selectObj: TJSDTOSelectOptions; external name 'select';
    columnDefs: TJSDTOColumnDynArray;
    columns: TJSDTOColumnDynArray;
    rowGroup: Boolean;
    rowGroupObj: TJSDTORowGroup; external name 'rowGroup';
    drawCallback: TJSDTODrawCallback;
    createdRowObject: TJSDTOCreatedRowObjectRenderCallBack; external name 'createdRow';
    createdRowArray: TJSDTOCreatedRowArrayRenderCallBack; external name 'createdRow';
  end;

  TDTAjaxReloadCallBack = reference to procedure(aJSON: TJSObject);

  TDTAjax = class external name 'Object'(TJSObject)
  public
    function json: TJSObject;
    function params: TJSObject;
    function url: string;
    function url(aURL: string): TJSDataTableAPI;
    function reload(): TJSDataTableAPI;
    function reload(callback: TDTAjaxReloadCallBack): TJSDataTableAPI;
    function reload(callback: TDTAjaxReloadCallBack; resetPaging: Boolean): TJSDataTableAPI;
  end;

  TDTOrderListener = reference to procedure;

  TDTOrderOptions = class external name 'Object'(TJSObject)
  public
    function fixed: TJSObject;
    function fixed(aOrder: TJSObject): TJSDataTableAPI;
    function listener(node: TJSNode; Index: Integer; listener: TDTOrderListener = nil): TJSDataTableAPI;
    function listener(node: TJQuery; Index: Integer; listener: TDTOrderListener = nil): TJSDataTableAPI;
    function listener(node: string; Index: Integer; listener: TDTOrderListener = nil): TJSDataTableAPI;
  end;

  TDTPageInfo = class external name 'Object'(TJSObject)
  public
    page: Integer;
    pages: Integer;
    start: Integer;
    end_: Integer; external name 'end';
    length: Integer;
    recordsTotal: Integer;
    recordsDisplay: Integer;
    serverSide: Boolean;
  end;

  TDTPageOptions = class external name 'Object'(TJSObject)
  public
    function info: TDTPageInfo;
    function len: Integer;
    function len(aLength: Integer): TJSDataTableAPI;
  end;

  TDTStateOptions = class external name 'Object'(TJSObject)
  public
    function clear: TJSDataTableAPI;
    function loaded: TJSObject;
    function save: TJSDataTableAPI;
  end;

  TDTSelectorModifier = class external name 'Object'(TJSObject)
  public
    order: string;
    page: string;
    search: string;
  end;

  TJSCellSelectorCallback = reference to function(aIndex: Integer; aData: jsValue; aCell: TJSNode): Boolean;

  TDTCellIndexData = class external name 'Object'(TJSObject)
  public
    row: Integer;
    column: Integer;
    columnVisible: Integer;
  end;

  TJSDataTableCellAPI = class external name 'Object'(TJSObject)
  public
    function cache(): TJSDataTableAPI;
    function cache(aType: string): TJSDataTableAPI;
    function data: jsValue;
    function data(aValue: jsValue): TJSDataTableAPI;
    function Index: TDTCellIndexData;
    function invalidate: TJSDataTableAPI;
    function invalidate(aSource: string): TJSDataTableAPI;
    function node: TJSHTMLelement;
    function Render: jsValue;
    function Render(aType: string): jsValue;
  end;

  TJSDatatableOnDrawCompleteEventHandler = function (e: TJSEvent; settings: TJSDataTableAPI): Boolean of object;

  TJSDataTableAPI = class external name 'DataTable'(TJSObject)
  public
    context: TJSObject;
    selector: TJSObject;
    ajax: TDTAjax;
    pageAPI: TDTPageOptions; external name 'page';
    orderAPI: TDTOrderOptions; external name 'order';
    stateAPI: TDTStateOptions; external name 'state';
    function tables(): jsValue;
    function table(): jsValue;
    function draw(paging: Boolean): TJSDataTableAPI;
    function draw(paging: string): TJSDataTableAPI;
    function page(): Integer;
    function page(aPage: Integer): TJSDataTableAPI;
    function rows(): TJSDataTableAPI;
    function row(): TJSDataTableAPI;
    function row(selector: jsValue): TJSDataTableAPI;
    function columns(): TJSDataTableAPI;
    function column(): TJSDataTableAPI;
    function cells(): TJSDataTableAPI;
    function Cell(): TJSDataTableCellAPI;
    function Cell(Modifier: TDTSelectorModifier): TJSDataTableCellAPI;
    function Cell(selector: string): TJSDataTableCellAPI;
    function Cell(selector: string; Modifier: TDTSelectorModifier): TJSDataTableCellAPI;
    function Cell(selector: TJSNode): TJSDataTableCellAPI;
    function Cell(selector: TJSNode; Modifier: TDTSelectorModifier): TJSDataTableCellAPI;
    function Cell(selector: TJQuery): TJSDataTableCellAPI;
    function Cell(selector: TJQuery; Modifier: TDTSelectorModifier): TJSDataTableCellAPI;
    function Cell(selector: TJSObject): TJSDataTableCellAPI;
    function Cell(selector: TJSObject; Modifier: TDTSelectorModifier): TJSDataTableCellAPI;
    function Cell(selector: TJSValueDynArray): TJSDataTableCellAPI;
    function Cell(selector: TJSValueDynArray; Modifier: TDTSelectorModifier): TJSDataTableCellAPI;
    function Cell(selector: TJSCellSelectorCallback): TJSDataTableCellAPI;
    function Cell(selector: TJSCellSelectorCallback; Modifier: TDTSelectorModifier): TJSDataTableCellAPI;
    // Defining all combinations would result in 36 function definitions...
    function Cell(CellSelector, RowSelector: jsValue; Modifier: TDTSelectorModifier): TJSDataTableCellAPI;

    function order(): TJSValueDynArray;
    function order(aOrder: TJSValueDynArray): TJSDataTableAPI; varargs;
    function search(): string;
    function search(aValue: string; aRegexp: Boolean): TJSDataTableAPI;
    function search(aValue: string; aRegexp: Boolean; smart: Boolean): TJSDataTableAPI;
    function search(aValue: string; aRegexp: Boolean; smart: Boolean; caseIns: Boolean): TJSDataTableAPI;

    function state(): TJSObject;
    function jquery(): TJQuery; external name '$';
    function on_(aEvent: string; aHandler: TJSRawEventHandler): TJSDataTableAPI; external name 'on';
    function On_(aevent: string; aHandler: TJSDatatableOnDrawCompleteEventHandler): TJQuery; external name 'on'; overload;
    function one(aEvent: string; aHandler: TJSRawEventHandler): TJSDataTableAPI;
    function off(aEvent: string): TJSDataTableAPI;
    function off(aEvent: string; aHandler: TJSRawEventHandler): TJSDataTableAPI;
    function clear(): TJSDataTableAPI;
    function settings(): TJSDataTableAPI;
    function init(): TJSObject;
    function data(): TJSDataTableAPI;
    function destroy(remove: Boolean): TJSDataTableAPI;
    function destroy(): TJSDataTableAPI;
    function i18n(token: string; def: string): string;
    function i18n(token: string; def: TJSObject): string;
    function i18n(token: string; def: string; Numeric: Double): string;
    function i18n(token: string; def: TJSObject; Numeric: Double): string;
    function i18n(token: string; def: string; Numeric: NativeInt): string;
    function i18n(token: string; def: TJSObject; Numeric: NativeInt): string;
    // next getPrototypeOf ...
    function r(arg1: jsValue; arg2: jsValue): jsValue;
    function any(): jsValue;
    function concat(arg1: jsValue): jsValue;
    function count(): jsValue;
    function each(arg1: jsValue): jsValue;
    function eq(arg1: jsValue): jsValue;
    function filter(arg1: jsValue): jsValue;
    function flatten(): jsValue;
    function join(arg1: jsValue): jsValue;
    function indexOf(arg1: jsValue): jsValue;
    function iterator(arg1: jsValue; arg2: jsValue; arg3: jsValue; arg4: jsValue): jsValue;
    function lastIndexOf(arg1: jsValue): jsValue;
    length: Double;
    function map(arg1: jsValue): jsValue;
    function pluck(arg1: jsValue): jsValue;
    function pop(): jsValue;
    function push(arg1: jsValue): jsValue;
    function reduce(arg1: jsValue): jsValue;
    function reduceRight(arg1: jsValue): jsValue;
    function reverse(): jsValue;
    function shift(): jsValue;
    function slice(): jsValue;
    function sort(arg1: jsValue): jsValue;
    function splice(arg1: jsValue; arg2: jsValue): jsValue;
    function toArray(): jsValue;
    function toDollar(): jsValue; external name 'to$';
    function toJQuery(): jsValue;
    function unique(): jsValue;
    function unshift(arg1: jsValue): jsValue;
    function rowsAdd(data: TJSArray): TJSDataTableAPI; external name 'rows.add';
    function responsiveRecalc: TJSDataTableAPI; external name 'responsive.recalc';
    // next getPrototypeOf ...
  end;

  TJSDataTableJQuery = class external name 'JQuery'(TJQuery)
  public
    function api: TJSDataTableAPI;
  end;

implementation

end.
