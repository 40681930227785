unit Units.Strings;

{$mode objfpc}

interface

uses
  Classes, SysUtils;

Resourcestring

  SError = 'Fout';
  SSucces = 'Succes';

  SErrCreatingDocument = 'Server error when creating new document: %s';
  SErrInitalizingDocumentUpload = 'Server error when initializing document upload: %s';
  SErrGetCityDataFromZip = 'Server error when getting city data from ZIP code: %s';
  SErrGetFileURL = 'Server error when getting URL to download file: %s';
  SErrSearchOperationFailed = 'Search operation for %s failed:';

  SErrLogoutFailedMessage = 'Logout call failed (Error: "%s"), logging out anyway.';
  SErrLogoutFailedTitle = 'Logout failed.';
  SVerzoekSchriftGenerated = 'Petition document is being generated. The download button will become active when it is ready.';
  SErrNoDossierCreated = 'No petition document was created!';

  SErrLoginFailedTitle = 'Login failed';
  SErrLoginFailedMessage = 'Invalid combination username/password';
  SErrLoadingDatasetTitle = 'Error loading data';
  SErrLoadingDatasetMsg = 'Error loading data "%s" from server : %s';
  SErrDuplicateForm = 'Duplicate form name: %s';
  SErrUnknownForm = 'Unknown form name: %s';
  SErrUnknownRoute = 'Unknown form route: %s';
  SErrNoFormTemplate = 'No such form template: %s';
  SErrLoginRequestFailed = 'The server failed to answer the login request: %s';

  SPasswordResetTitle = 'Password Reset';
  SUserPasswordResetSuccessMessage = 'A password reset link was sent to new user';
  SPasswordResetSuccessMessage = 'Password reset link was sent to your email';
  SErrPasswordResetFailedTitle = 'Password Reset';
  SErrPasswordResetRequestFailed = 'The server failed to answer password reset request: %s';
  SErrPasswordChangeFailedTitle = 'Set new password';
  SErrPasswordChangeFailed = 'Your password could not be changed: %s';
  SPasswordChangeTitle = 'Password change';
  SPasswordChangeSuccess = 'Your password has been changed';
  SPasswordsMustMatch = 'Cannot change password: Password and confirm password differ!';
  SPasswordMinLength = 'Password must contain at least 8 characters';
  SPasswordResetMailSent = 'The password reset mail has been resent.';
  SFileEnqueued = 'File is enqueued and will be uploaded once the data is saved.';

  SErrSignonFailed = 'Sign-on failed';
  SErrSignonRequestFailedError = 'The sign-on request failed due to the following error: %s';
  SErrNoLanguageSet = 'No language set';

  SSignonOK = 'Sign-on successful';
  SSignonSuccessMessage = 'A confirmation mail has been sent to you with a password-reset link.'#13#10+
                          'Please check your mail.';
  SErrFailedToGetProfileData = 'Failed to retrieve profile data: %s';
  SErrFailedToGetRoles = 'Failed to get roles: %s';
  SErrSavingProfileData = 'Failed to save profile data: %s';
  SSavedProfileData = 'Successfully saved profile data';
  SErrUnknown = 'Unknown error';
  SErrCannotSaveUser = 'Cannot save user data: %s';
  SErrCouldNotSavePersonData = 'Cannot save person data: %s';
  SErrCouldNotSaveLoginData = 'Cannot save login data: %s';

  SErrUserNotFound = 'User %d not found';
  SErrCannotDeleteSelf = 'User cannot delete itself.';
  SErrInvalidUrl = 'The route is not valid, please check the url in your browser';

  SErrJobOperationCancelled =  'Operation was cancelled';

  SYear = 'Year';
  SMonth = 'Month';
  SDescription = 'Description';
  SLoading = 'Loading...';
  SNoData = 'No data available';
  SBankAccountOK = 'Bank Account was saved successfully';
  SStructuredMessageError = 'Structured Message is not valid';
  SNegativeAmountError = 'Amount has to be greater than 0';
  SPaymentOrderSaveOK = 'Payment Order was saved successfully';
  SOrderDeletionError = 'This order cannot be deleted.';
  SMaxCountAmountError = 'Maximum payments has to be greater than 0';
  SValidTillError = 'Vilid Till has to be greater than Valid from';

  SErrTillAfterFrom = '"Valid till" date must be after "valid from" date';

  SConfirmFileDelete = 'Are you sure you wish to delete selected file "%s" ?';
  SFileUploadFailed = 'Failed to upload the file "%s".';
  SFileUploadCancelled = 'The upload of file "%s" was cancelled.';

  SErrAvatarSaveFailedPetitionStarted = 'The petition was created, but the petitioner picture was not saved, please try selecting it again.';

  comDeleteMessage = 'Are you sure you wish to delete the selected item?';
  comYes = 'Yes';
  comNo = 'No';
  comConfirmation = 'Confirmation';
  npeNationalNumber = 'National Number';
  npeFieldIsInvalid = 'Value for ''%s'' is invalid';

  SPetitionerFotoNotSaved = 'Failed to save pettitioner photo.';
  SDataSavedOK = 'The data was saved.';
  SDataDeletedOK = 'The data was deleted successfully.';
  SErrFailedToLoadDataset = 'Failed to load data for %s: %s';
  SPartnerPhotoSaveFailed =  'Failed to save partner photo.';

  SPartnerSavedOK = 'Partner data saved.';
  SRepresentativeSavedOK = 'Representative data saved.';
  SMediatiorSavedOK = 'Mediator data saved';
  SExPartnerSavedOK = 'Ex-Partner data saved.';
  SInmateSavedOK = 'Inmate data saved.';
  SStatementsSavedOK = 'Statements data saved.';
  SChildSavedOK = 'Child data saved.';
  SPetitionerSavedOK = 'Petitioner data saved.';
  SmsgExpartnerSaved = 'Ex-partner data saved.';

  SErrGeneratingPetitionFailed = 'Failed to generate petition PDF';
  SErrFailedToGetJobStatus = 'Failed to get job status: %s';
  SErrTooManyErrors = 'Too many errors, stopping jobstatus poll';

  SErrNoItemsSelectedNoPossessions = 'No items selected, not creating possessions';
  SCountPossessionsCreated = '%d possessions created.';

  SErrPartnerCanNotBeCompany = 'Partner cannot be a contact of type company.';
  SPhotoSaveFailed = 'Failed to save picture for %s';
  SContact = 'Contact';
  SEditCompanyContact = 'Edit company contact';
  SEditPersonContact = 'Edit contact person';
  SContactInmate = 'Inmate';
  SContactMediator = 'Mediator';
  SContactGuarantor = 'Guarantor';
  SContactCreditor = 'Creditor';
  SContactDebtor = 'Debtor';
  SContactChild = 'Child';
  SContactOpponent = 'Opponent';
  SContactLawyer = 'Lawyer';
  SContactRepresentative = 'Legal representative';
  SContactJudge = 'Judge';

  SConfirmArchivePetition =  'Are you sure you wish to archive petition "%s"?';

  SErrJudgeCanNotBeCompany = 'Judge cannot be a contact of type company.';

  SBudgetPlanSavedOK = 'Budget plan data saved.';
  SBudgetPlanDetailsSavedOK = 'Budget plan details data saved.';
  SSettlementPlanSavedOK = 'Settlement plan data saved.';
  SSettlementPlanDetailsSavedOK = 'Settlement plan details data saved.';
  SSettlementPlanDebtDetailsSavedOK = 'Settlement plan debt details data saved.';
  SCertifyOK = 'Payment instructions for the creditors were created successfully';
  SCertifyError = 'Failed to create payment instructions for the creditors';
  
  SDefaultPlan = 'Default plan';
  SCopyOf = 'Copy Of';
  
  SCheckBalanceAlreadyStarted = 'The balance is already being checked';
  SStartedCheckBalance = 'Requesting account balance for account %s';
  SCheckBalanceMessage = 'Account balance for account %s retrieval: %s';

  SLinkAccountAlreadyStarted = 'The link with Ponto is already being checked.';
  SStartedLinkAccount = 'Checking existence of bank account %s at Ponto.';
  SLinkAccountMessage = 'Checking existence of %s verified: %s';

  SStartedCheckBalanceEmpty = 'Successfully started checking balance';
  SCheckBalanceMessageEmpty = 'Check Balance completed with status: %s';

  SRecurringPaymentSaveOK = 'Recurring Payment data saved.';
  SUserUnreadMessages = 'User Unread messages %s';
  SCompanyUnreadMessages = 'Company Unread messages %s';

  SMarkAsRead = 'Mark as read';
  SMarkAsUnRead = 'Mark as unread';
  SDelete = 'Delete';


  SDayOfMonthError = 'Day of Month has to be between 1 and 30';
  SCSRAlarmSaveOK = 'Alarm was saved successfully';
  SProcessing = 'Processing...';
  SMaxNumberOfFiles = 'Maximum number of files exceeded';
  SAcceptFileTypes = 'File type not allowed';
  SMaxFileSize = 'File is too large';
  SMinFileSize = 'File is too small';
  SUploadSuccess = 'Successfully uploaded file';


  SErrInvalidDate = 'Invalid date: "%s"';
  SErrDateInFuture = 'Date cannot be in future: %s';

  SErrReportCancelled =  'Report generation cancelled';
  SFileDeleteConfirmation = 'Really delete document %s ?';

  SDiscardChangesMessage = 'You have unsaved changes. Do you wish to discard the changes and continue?';
  SErrFailedToGetPontoStatus = 'Failed to get Ponto link status: %s';
  SErrFailedToGetPontoLink = 'Failed to get Ponto link URL: %s';

implementation

end.
