unit Forms.Login;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TfrmLogin = class(TfrmBase)
    procedure DoLoginClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); override;
    procedure SelectLanguage(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    procedure DoTranslate(Lang: string);
    { Private declarations }
  public
    class function NeedsLogin: Boolean; override;
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmLogin: TfrmLogin;

implementation

uses
  StrUtils,
  Units.ActionUtils,
  lib.formtranslation,
  Modules.Server,
  rsTranslate,
  pas2web.datatables;

{$R *.dfm}

procedure TfrmLogin.DoLoginClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  UN, pwd, comp: string;
begin
  EnterMethod('btnLoginClick');
  UN := alForm['inputUser'].value;
  pwd := alForm['inputPassword'].value;
  comp := alForm['inputCompany'].value;
  Server.LoginLanguage := FormTranslator.Language;
  Server.DoLogin(UN, pwd, comp);
  Server.SetLocalData('LoginCompany', comp);
  ExitMethod('btnLoginClick');
end;

class function TfrmLogin.NeedsLogin: Boolean;
begin
  Result := False;
end;

procedure TfrmLogin.SelectLanguage(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  EID: string;
begin
  EID := (Sender as TElementAction).ID;
  EID := LowerCase(Copy(EID, Length(EID) - 1, 2));
  case IndexText(EID, ['nl', 'fr', 'en']) of
    0, 1, 2:
      begin
        Server.SetLocalData(KeyLanguage, EID);
        Server.LoginLanguage := EID;
        DoTranslate(EID);
      end;
  end;
end;

procedure TfrmLogin.WebFormCreate(Sender: TObject);
var
  Lang: string;
begin
  inherited;
  alForm['inputCompany'].value := Server.GetLocalData('LoginCompany');
  Lang := Server.GetLocalData(KeyLanguage);
  if (Lang <> '') then
    DoTranslate(Lang);
end;

procedure TfrmLogin.DoTranslate(Lang: string);
var
  DTLanguage, ResStrings: TJSObject;
begin
  FormTranslator.EnableInformation := True;
  FormTranslator.Language := Lang;
  FormTranslator.TranslateForm(Self);
  ResStrings := FormTranslator.GetScope('__resourcestrings__');
  if (ResStrings <> nil) then
    rsTranslate.Translate(ResStrings);
  DTLanguage := FormTranslator.GetScope('__datatables__');
  if DTLanguage <> nil then
    TP2WDatatable.DefaultLanguage := DTLanguage;
end;

procedure TfrmLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnLogin';
      Name := 'btnLogin';
      SetEvent(Self, 'OnExecute', 'DoLoginClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'inputCompany';
      Name := 'inputCompany';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'inputPassword';
      Name := 'inputPassword';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'inputUser';
      Name := 'inputUser';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btn-lang-nl';
      Name := 'btnDutch';
      SetEvent(Self, 'OnExecute', 'SelectLanguage');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btn-lang-fr';
      Name := 'btnFrench';
      SetEvent(Self, 'OnExecute', 'SelectLanguage');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btn-lang-en';
      Name := 'btnEnglish';
      SetEvent(Self, 'OnExecute', 'SelectLanguage');
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
