unit Forms.CompanyUsers;

interface

uses
  Types, System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls, pas2web.dadatasethelper,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset,
  Modules.Server, pas2web.bootstrap;

type
  TfrmCompanyUserList = class(TfrmBase)
    dsUsers: TP2WDADataset;
    dcUsers: TP2WDatatable;
    dsUserslogid: TLargeintField;
    dsUserslogcompanyfk: TLargeintField;
    dsUserslogpersonfk: TLargeintField;
    dsUserslogrolefk: TLargeintField;
    dsUserslogname: TWideStringField;
    dsUserslogactive: TBooleanField;
    dsUsersperid: TLargeintField;
    dsUsersperfirstname: TWideStringField;
    dsUsersperlastname: TWideStringField;
    dsUsersperemail: TWideStringField;
    dsUserspermobile: TWideStringField;
    dsUserslorname: TWideStringField;
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormDestroy(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure dsUsersAfterOpen(DataSet: TDataSet);
    procedure dsUsersAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
  private
    FDeleteID : Int64;
    procedure ConfirmDeleteUser;
  public
    class function MyRoutes : TStringDynArray; override;
    class function NeedsParams : Boolean; override;
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmCompanyUserList: TfrmCompanyUserList;

implementation

uses Units.ActionUtils, Units.DADatasetUtils,  Units.Strings;

{$R *.dfm}

procedure TfrmCompanyUserList.dsUsersAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

Var
  Msg : String;

begin
  msg:=Info.AsString;
  if Msg<>'' then
    dmServer.ShowError(Msg)
  else
    begin
    ShowSuccess(SDataDeletedOK);
    dsUsers.Close;
    dsUsers.Load([],Nil);
    end;
end;

procedure TfrmCompanyUserList.ConfirmDeleteUser;

  Procedure DoDeleteRecord;

  begin
    if dsUsers.Locate('logid', FDeleteID,[]) then
      begin
      dsUsers.Delete;
      dsUsers.ApplyUpdates;
      end;
  end;

begin
  if Locate(dsUsers, 'logid', FDeleteID) then
    DefaultConfirmation.Execute('',@DoDeleteRecord);
end;


procedure TfrmCompanyUserList.dsUsersAfterOpen(DataSet: TDataSet);
begin
  dcUsers.Data := dsUsers.GetNewRows(False);
  dcUsers.RenderTranslated;
  if (FDeleteID>0) then
    begin
    if FDeleteID=dmServer.UserID then
      dmServer.ShowError(SErrCannotDeleteSelf)
    else
      ConfirmDeleteUser;
    end;
end;

class function TfrmCompanyUserList.MyRoutes: TStringDynArray;
begin
  Result:=['/companyuser/',
           '/companyuser/delete/:ID'
  ]
end;

class function TfrmCompanyUserList.NeedsParams: Boolean;
begin
  Result:=True;
end;

procedure TfrmCompanyUserList.setParams(const Params: TStrings);
begin
  inherited;
  FDeleteID:=StrToIntDef(Params.Values['ID'],0);
end;

procedure TfrmCompanyUserList.WebFormCreate(Sender: TObject);
begin
  Inherited;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsUsers.DAConnection := Server.CompanyConnection;
end;

procedure TfrmCompanyUserList.WebFormDestroy(Sender: TObject);
begin
  Inherited;
  //
end;

procedure TfrmCompanyUserList.WebFormShow(Sender: TObject);
begin
  dsUsers.Load([],Nil);
end;

procedure TfrmCompanyUserList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsUsers := TP2WDADataset.Create(Self);
  dsUserslogid := TLargeintField.Create(Self);
  dsUserslogcompanyfk := TLargeintField.Create(Self);
  dsUserslogpersonfk := TLargeintField.Create(Self);
  dsUserslogrolefk := TLargeintField.Create(Self);
  dsUserslogname := TWideStringField.Create(Self);
  dsUserslogactive := TBooleanField.Create(Self);
  dsUsersperid := TLargeintField.Create(Self);
  dsUsersperfirstname := TWideStringField.Create(Self);
  dsUsersperlastname := TWideStringField.Create(Self);
  dsUsersperemail := TWideStringField.Create(Self);
  dsUserspermobile := TWideStringField.Create(Self);
  dsUserslorname := TWideStringField.Create(Self);
  dcUsers := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsUsers.BeforeLoadDFMValues;
  dsUserslogid.BeforeLoadDFMValues;
  dsUserslogcompanyfk.BeforeLoadDFMValues;
  dsUserslogpersonfk.BeforeLoadDFMValues;
  dsUserslogrolefk.BeforeLoadDFMValues;
  dsUserslogname.BeforeLoadDFMValues;
  dsUserslogactive.BeforeLoadDFMValues;
  dsUsersperid.BeforeLoadDFMValues;
  dsUsersperfirstname.BeforeLoadDFMValues;
  dsUsersperlastname.BeforeLoadDFMValues;
  dsUsersperemail.BeforeLoadDFMValues;
  dsUserspermobile.BeforeLoadDFMValues;
  dsUserslorname.BeforeLoadDFMValues;
  dcUsers.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'addUser';
      Name := 'addUser';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'grdDebt';
      Name := 'grdDebt';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'deleteUserLabel';
      Name := 'deleteUserLabel';
    end;
    dsUsers.SetParentComponent(Self);
    dsUsers.Name := 'dsUsers';
    dsUsers.TableName := 'CompanyUserOverview';
    dsUsers.DAConnection := dmServer.CompanyConnection;
    dsUsers.DAOptions := [];
    dsUsers.AfterOpen := dsUsersAfterOpen;
    dsUsers.AfterApplyUpdates := dsUsersAfterApplyUpdates;
    dsUsers.Left := 40;
    dsUsers.Top := 80;
    dsUserslogid.SetParentComponent(dsUsers);
    dsUserslogid.Name := 'dsUserslogid';
    dsUserslogid.FieldName := 'logid';
    dsUserslogcompanyfk.SetParentComponent(dsUsers);
    dsUserslogcompanyfk.Name := 'dsUserslogcompanyfk';
    dsUserslogcompanyfk.FieldName := 'logcompanyfk';
    dsUserslogpersonfk.SetParentComponent(dsUsers);
    dsUserslogpersonfk.Name := 'dsUserslogpersonfk';
    dsUserslogpersonfk.FieldName := 'logpersonfk';
    dsUserslogrolefk.SetParentComponent(dsUsers);
    dsUserslogrolefk.Name := 'dsUserslogrolefk';
    dsUserslogrolefk.FieldName := 'logrolefk';
    dsUserslogname.SetParentComponent(dsUsers);
    dsUserslogname.Name := 'dsUserslogname';
    dsUserslogname.FieldName := 'logname';
    dsUserslogname.Size := 100;
    dsUserslogactive.SetParentComponent(dsUsers);
    dsUserslogactive.Name := 'dsUserslogactive';
    dsUserslogactive.FieldName := 'logactive';
    dsUsersperid.SetParentComponent(dsUsers);
    dsUsersperid.Name := 'dsUsersperid';
    dsUsersperid.FieldName := 'perid';
    dsUsersperfirstname.SetParentComponent(dsUsers);
    dsUsersperfirstname.Name := 'dsUsersperfirstname';
    dsUsersperfirstname.FieldName := 'perfirstname';
    dsUsersperfirstname.Size := 64;
    dsUsersperlastname.SetParentComponent(dsUsers);
    dsUsersperlastname.Name := 'dsUsersperlastname';
    dsUsersperlastname.FieldName := 'perlastname';
    dsUsersperlastname.Size := 64;
    dsUsersperemail.SetParentComponent(dsUsers);
    dsUsersperemail.Name := 'dsUsersperemail';
    dsUsersperemail.FieldName := 'peremail';
    dsUsersperemail.Size := 128;
    dsUserspermobile.SetParentComponent(dsUsers);
    dsUserspermobile.Name := 'dsUserspermobile';
    dsUserspermobile.FieldName := 'permobile';
    dsUserspermobile.Size := 25;
    dsUserslorname.SetParentComponent(dsUsers);
    dsUserslorname.Name := 'dsUserslorname';
    dsUserslorname.FieldName := 'lorname';
    dsUserslorname.Size := 32;
    dcUsers.SetParentComponent(Self);
    dcUsers.Name := 'dcUsers';
    dcUsers.Columns.Clear;
    with dcUsers.Columns.Add do
    begin
      FieldName := 'logname';
      Title := 'Login name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcUsers.Columns.Add do
    begin
      FieldName := 'perfirstname';
      Title := 'First name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcUsers.Columns.Add do
    begin
      FieldName := 'perlastname';
      Title := 'Last name';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcUsers.Columns.Add do
    begin
      FieldName := 'lorname';
      Title := 'Role';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcUsers.Columns.Add do
    begin
      FieldName := 'logactive';
      Title := 'Active';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcUsers.Columns.Add do
    begin
      FieldName := 'logid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/companyuser/edit/{{logid}}';
    end;
    with dcUsers.Columns.Add do
    begin
      FieldName := 'logid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/companyuser/delete/{{logid}}';
    end;
    dcUsers.DataSet := dsUsers;
    dcUsers.Language := lEnglish;
    dcUsers.IsResponsive := False;
    dcUsers.GridID := 'grdUsers';
    dcUsers.UseFieldIndex := True;
    dcUsers.ShowSearch := False;
    dcUsers.ShowNumberOfEntries := False;
    dcUsers.ShowEntriesInfo := False;
    dcUsers.Paginate := True;
    dcUsers.DisplayReadOnly := False;
    dcUsers.CalculateTableWidth := True;
    dcUsers.Left := 40;
    dcUsers.Top := 152;
  finally
    alForm.AfterLoadDFMValues;
    dsUsers.AfterLoadDFMValues;
    dsUserslogid.AfterLoadDFMValues;
    dsUserslogcompanyfk.AfterLoadDFMValues;
    dsUserslogpersonfk.AfterLoadDFMValues;
    dsUserslogrolefk.AfterLoadDFMValues;
    dsUserslogname.AfterLoadDFMValues;
    dsUserslogactive.AfterLoadDFMValues;
    dsUsersperid.AfterLoadDFMValues;
    dsUsersperfirstname.AfterLoadDFMValues;
    dsUsersperlastname.AfterLoadDFMValues;
    dsUsersperemail.AfterLoadDFMValues;
    dsUserspermobile.AfterLoadDFMValues;
    dsUserslorname.AfterLoadDFMValues;
    dcUsers.AfterLoadDFMValues;
  end;
end;

end.