unit Forms.CSR.Debt.Details;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Debt.Details, Data.DB, Datasnap.DBClient,
  pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRDebtDetails = class(TfrmDebtDetails)
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRDebtDetails: TfrmCSRDebtDetails;

implementation

{$R *.dfm}

{ TfrmCSRDebtDetails }
uses
  libjquery;

procedure TfrmCSRDebtDetails.WebFormCreate(Sender: TObject);
begin
  inherited;
  jQuery('#monthlyAmount').css('display', '');
  jQuery('#accountInfo').css('display', '');
end;

function TfrmCSRDebtDetails.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRDebtDetails.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRDebtDetails.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRDebtDetails.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'creditorLastName';
      Name := 'creditorLastName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'creditorFirstName';
      Name := 'creditorFirstName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'debtType';
      Name := 'debtType';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'ddtStartOn';
      Name := 'ddtStartOn';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'txtReference';
      Name := 'txtReference';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtOriginalAmount';
      Name := 'edtOriginalAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtCurrentAmount';
      Name := 'edtCurrentAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'guarantorLastName';
      Name := 'guarantorLastName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'guarantorName';
      Name := 'guarantorName';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'isDispute';
      Name := 'isDispute';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'isDisputedChecked');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtDisputeReason';
      Name := 'edtDisputeReason';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtCourtText';
      Name := 'edtCourtText';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtDisputeAmount';
      Name := 'edtDisputeAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'isDelay';
      Name := 'isDelay';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'isDelayedChecked');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtDelayAmount';
      Name := 'edtDelayAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSelectCreditor';
      Name := 'btnSelectCreditor';
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdDebtCompleted';
      PreventDefault := False;
      Selector := 'input[name="rdDebtCompleted"]';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSelectGuarantorContact';
      Name := 'btnSelectGuarantorContact';
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'mmRemark';
      Name := 'mmRemark';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtPaymentIBAN';
      Name := 'edtPaymentIBAN';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtPaymentMessage';
      Name := 'edtPaymentMessage';
      PreventDefault := False;
      StopPropagation := False;
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.