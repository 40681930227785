unit Forms.CSR.Alarms.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Forms.DossierDetail,
  pas2web.dataelementmapper, Data.DB, Datasnap.DBClient, pas2web.dadataset;

type
  TfrmBase = Class(TFrmDossierDetail);
  TfrmAlarmDetail = class(TfrmBase)
    dsAlarm: TP2WDADataset;
    eacAlarm: TPW2DataElementMapper;
    dsBankAccount: TP2WDADataset;
    procedure dsAlarmAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure OnSaveClicked(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure dsAlarmAfterOpen(DataSet: TDataSet);
    procedure dsBankAccountAfterOpen(DataSet: TDataSet);
    procedure OnItemError(Sender: TObject; Source: TElementAction; dest: TField;
        aError: TElementError);
    procedure OnMonthValidation(Sender: TObject; Source: TElementAction; var
        aResult: Boolean);
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    function SaveIfValidIban(const IBAN: string): Boolean;
    procedure DoSave;
    { Private declarations }
  public
    procedure setParams(const Params: TStrings); override;
    function DossierPrefix : string; override;
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmAlarmDetail: TfrmAlarmDetail;

implementation

{$R *.dfm}
uses
  Modules.Server, libjquery, Units.ActionUtils, Units.Strings;

procedure TfrmAlarmDetail.OnSaveClicked(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  ClearFormErrors;

  if eacAlarm.ValidateActions(True) then
    SaveIfValidIBAN(Trim(alForm['edtIbanOtherParty'].value));
end;

procedure TfrmAlarmDetail.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TfrmAlarmDetail.DoSave;
begin
  if dsAlarm.isEmpty then
  begin
    dsAlarm.Insert;
    dsAlarm.FieldByName('almcreatedon').asDateTime := now;
    dsAlarm.FieldByName('almcreatedbyfk').asInteger := Server.UserID;
    dsAlarm.FieldByName('almcompanyfk').asInteger := Server.UserInfo.OfficeID;
    dsAlarm.FieldByName('almminamount').asFloat := 0.0;
  end
  else
    dsAlarm.Edit;
  dsAlarm.FieldByName('almchangedon').asDateTime := now;
  dsAlarm.FieldByName('almchangedbyfk').asInteger := Server.UserID;
  eacAlarm.ElementsToDataset;
  dsAlarm.Post;
  dsAlarm.ApplyUpdates;
end;

function TfrmAlarmDetail.DossierPrefix: string;
begin
  Result := 'CSR';
end;

procedure TfrmAlarmDetail.dsAlarmAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);
begin
  if CheckResolveResults(Info) then
  begin
    DiscardChanges := True;
    Server.ShowOK(SCSRAlarmSaveOK);
    window.location.href := DossierURL('/alarm');
  end;
end;

procedure TfrmAlarmDetail.dsAlarmAfterOpen(DataSet: TDataSet);
begin
  eacAlarm.DatasetToElements;
end;

procedure TfrmAlarmDetail.dsBankAccountAfterOpen(DataSet: TDataSet);
var
  html : string;
begin
  html := '';
  while not DataSet.eof  do
  begin
    html := html + '<option value="' + DataSet.FieldByName('bacid').asString + '">' +
      DataSet.FieldByName('bacname').asString + ' [ ' + DataSet.FieldByName('baciban').asString +
      ' ]</option>';
    DataSet.Next;
  end;
  jQuery('#edtBankAccount').html(html);
  dsAlarm.Load([], nil);
end;

function TfrmAlarmDetail.SaveIfValidIban(const IBAN: string): Boolean;

  procedure CheckResult(aSuccess: Boolean; aError: string);
  begin
    if not aSuccess then
      DisplayError('edtIbanOtherParty', 'incorrect-value')
    else
      DoSave;
  end;

begin
  if IBAN <> '' then
    Server.isIBANValid(IBAN, @CheckResult)
  else
    DoSave; // leeg is ook goed
end;

procedure TfrmAlarmDetail.OnItemError(Sender: TObject; Source: TElementAction;
    dest: TField; aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmAlarmDetail.OnMonthValidation(Sender: TObject; Source:
    TElementAction; var aResult: Boolean);
begin
  aResult := (StrToIntDef(alForm[Source.Name].Value, 0) > 0) and
    (StrToIntDef(alForm[Source.Name].Value, 0) < 31);
  if not aResult then
    DisplayError(Source.Name, '', SDayOfMonthError);
end;

procedure TfrmAlarmDetail.setParams(const Params: TStrings);
var
  id : NativeInt;
begin
  inherited;
  jQuery('#returnBack').attr('href',DossierURL('/alarm'));
  If Assigned(Params) and (Params.indexOfName('ID')<>-1) then
  begin
    id := StrToIntDef(Params.Values['ID'],-1);
  end;
  dsAlarm.ParamByName('DOSSIERID').asInteger := DossierID;
  dsAlarm.ParamByName('ALMID').asInteger := id;
  dsBankAccount.ParamByName('DOSSIERID').asInteger := DossierID;
  dsBankAccount.Load([], nil);
end;

procedure TfrmAlarmDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.CompanyConnection.Message.ClientID := Server.ClientID;
  dsAlarm.DAConnection := Server.CompanyConnection;
  dsBankAccount.DAConnection := Server.CompanyConnection;
  BindOnChangeEvent;
end;

procedure TfrmAlarmDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsAlarm := TP2WDADataset.Create(Self);
  eacAlarm := TPW2DataElementMapper.Create(Self);
  dsBankAccount := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsAlarm.BeforeLoadDFMValues;
  eacAlarm.BeforeLoadDFMValues;
  dsBankAccount.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtBankAccount';
      Name := 'edtBankAccount';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtDayOfMonth';
      Name := 'edtDayOfMonth';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtMessage';
      Name := 'edtMessage';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtIbanOtherParty';
      Name := 'edtIbanOtherParty';
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtNameAlarm';
      Name := 'edtNameAlarm';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnOk';
      Name := 'btnOk';
      SetEvent(Self, 'OnExecute', 'OnSaveClicked');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'edtAmount';
      Name := 'edtAmount';
    end;
    dsAlarm.SetParentComponent(Self);
    dsAlarm.Name := 'dsAlarm';
    dsAlarm.TableName := 'alarm';
    dsAlarm.Params.Clear;
    with dsAlarm.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsAlarm.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'ALMID';
      ParamType := ptInput;
    end;
    dsAlarm.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>almid</field><parameter type="LargeInt">ALMID</parameter></binaryoperation></binaryoperation></where></query>';
    dsAlarm.DAOptions := [doRefreshAllFields];
    dsAlarm.AfterOpen := dsAlarmAfterOpen;
    dsAlarm.AfterApplyUpdates := dsAlarmAfterApplyUpdates;
    dsAlarm.Left := 40;
    dsAlarm.Top := 88;
    eacAlarm.SetParentComponent(Self);
    eacAlarm.Name := 'eacAlarm';
    eacAlarm.ActionLinks.Clear;
    with eacAlarm.ActionLinks.Add do
    begin
      ActionName := 'edtBankAccount';
      FieldName := 'almbankaccountfk';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnItemError');
    end;
    with eacAlarm.ActionLinks.Add do
    begin
      ActionName := 'edtDayOfMonth';
      FieldName := 'almdayofmonth';
      Options := [aoRequired,aoEmptyValueClearsField];
      SetEvent(Self, 'OnValidation', 'OnMonthValidation');
      ClearValue := '0';
    end;
    with eacAlarm.ActionLinks.Add do
    begin
      ActionName := 'edtMessage';
      FieldName := 'almmessage';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnItemError');
    end;
    with eacAlarm.ActionLinks.Add do
    begin
      ActionName := 'edtNameAlarm';
      FieldName := 'almnamealarm';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnItemError');
    end;
    with eacAlarm.ActionLinks.Add do
    begin
      ActionName := 'edtIbanOtherParty';
      FieldName := 'almibanotherparty';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnItemError');
    end;
    with eacAlarm.ActionLinks.Add do
    begin
      ActionName := 'edtAmount';
      FieldName := 'almminamount';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnItemError');
    end;
    eacAlarm.ActionList := alForm;
    eacAlarm.Dataset := dsAlarm;
    eacAlarm.Left := 40;
    eacAlarm.Top := 160;
    dsBankAccount.SetParentComponent(Self);
    dsBankAccount.Name := 'dsBankAccount';
    dsBankAccount.TableName := 'BankAccount';
    dsBankAccount.Params.Clear;
    with dsBankAccount.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
    end;
    dsBankAccount.WhereClause := '<?xml version="1.0" encoding="UTF-8"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>coddossierid</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation></where></query>';
    dsBankAccount.DAOptions := [];
    dsBankAccount.AfterOpen := dsBankAccountAfterOpen;
    dsBankAccount.Left := 40;
    dsBankAccount.Top := 240;
  finally
    alForm.AfterLoadDFMValues;
    dsAlarm.AfterLoadDFMValues;
    eacAlarm.AfterLoadDFMValues;
    dsBankAccount.AfterLoadDFMValues;
  end;
end;

end.
