unit pas2web.dadatasethelper;

interface

uses
  pas2web.dadataset,
  DB,
  JSONDataset,
  js,
  pas2web.da;

const
  ftWideMemo = ftMemo;
  ftSmallint = ftInteger;

type
  TJSValueDynArray = array of JSValue;
  TWideStringField = TStringField;
  /// TWideMemoField = TStringField;
  TSmallintField = TIntegerField;
  TFMTBCDField = TFloatField;
  TBCDField = TFloatField;

  THackDataset = class(TP2WDADataset)
  public
    property Rows;
    property DAFields: TP2WDAFieldArray read GetDAFields;
  end;

  TP2WDADataSetHelper = class helper for TP2WDADataset
  public
    function AddParam(const aName, aValue: string): TParam; overload;
    function AddParam(const aName: string; aInt: Integer): TParam; overload;
    function AddParam(const aName: string; aDate: TDateTime): TParam; overload;
    function GetNewRows(AsObjects: Boolean): TJSArray;
  end;

  // Helper for TBlobfield/TMemoField

  TBlobType = ftBlob .. ftMemo;

  TBlobFieldHelper = class helper for TBlobField
  private
    function GetBlobType: TBlobType;
    procedure SetBlobType(aValue: TBlobType);
  public
    property BlobType: TBlobType read GetBlobType write SetBlobType;
  end;

  TResolveResultsHelper = record helper for TResolveResults
  private
    function GetAsString: string;
  public
    property AsString: string read GetAsString;
  end;

implementation

{ TBlobFieldHelper }

function TBlobFieldHelper.GetBlobType: TBlobType;
begin
  result := ftBlob;
end;

procedure TBlobFieldHelper.SetBlobType(aValue: TBlobType);
begin
  //
end;

{ TP2WDADataSetHelper }

function TP2WDADataSetHelper.AddParam(const aName: string; aInt: Integer): TParam;
begin
  if Params = nil then
    Params := TParams.Create(Self);

  result := Params.CreateParam(TFieldType.ftInteger, aName, TParamType.ptInput);
  result.Value := aInt;
end;

function TP2WDADataSetHelper.AddParam(const aName: string; aDate: TDateTime): TParam;
begin
  if Params = nil then
    Params := TParams.Create(Self);

  result := Params.CreateParam(TFieldType.ftDateTime, aName, TParamType.ptInput);
  result.Value := aDate;
end;

function TP2WDADataSetHelper.GetNewRows(AsObjects: Boolean): TJSArray;
var
  newRows: TJSArray;
  arrRecord: TJSValueDynArray;
  newRowObject: TJSObject;
  i, j: Integer;
  iLen: Integer;
begin
  iLen := 0;
  if Assigned(THackDataset(Self).Rows) then
    iLen := THackDataset(Self).Rows.Length;

  newRows := TJSArray.new(iLen);

  for i := 0 to iLen - 1 do
  begin
    arrRecord := TP2WDADataRow(THackDataset(Self).Rows.Elements[i])._new;
    if AsObjects then
    begin
      newRowObject := TJSObject.new;
      for j := 0 to Length(arrRecord) - 1 do
      begin
        newRowObject.Properties[THackDataset(Self).DAFields[j].name] := arrRecord[j];
      end;
      newRows.Elements[i] := newRowObject;
    end
    else
    begin
      newRows.Elements[i] := arrRecord;
    end;
  end;
  result := newRows;
end;

function TP2WDADataSetHelper.AddParam(const aName, aValue: string): TParam;
begin
  if Params = nil then
    Params := TParams.Create(Self);

  result := Params.CreateParam(TFieldType.ftString, aName, TParamType.ptInput);
  result.Value := aValue;
end;

{ TResolveResultsHelper }

function TResolveResultsHelper.GetAsString: string;
var
  i: Integer;
begin
  result := '';
  for i := low(Records) to high(Records) do
    if Records[i].error <> '' then
    begin
      if result <> '' then
        result := result + #10;
      result := result + Records[i].error;
    end;
end;

end.
