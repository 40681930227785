unit lib.jqueryhelpers;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  js,
  web,
  libjquery,
  lib.bootstrap,
  lib.filereader,
  lib.datatables,
  lib.jstree;

type
  TProcedureCallback = procedure;
  TProcedureCallbackEvent = procedure of object;

  TJSDataEventHandler = function(Event: TEventListenerEvent; data: JSValue): Boolean of object;
  TPrependCallback = reference to function(elementOfArray: NativeInt; Html: string): JSValue;

  TP2WJQueryHelper = class helper for libjquery.TJQuery
  public
    // Basic JQuery 
    function append(content: string): TJQuery; external name 'append';

    function click: TJQuery; external name 'click'; overload;
    function click(aHandler: TJSRawEventHandler): TJQuery; external name 'click'; overload;
    function click(aHandler: TJSEventHandler): TJQuery; external name 'click'; overload;
    function click(aData: TJSObject; aHandler: TJSEventHandler): TJQuery; external name 'click'; overload;
    function click(aData: TJSObject; aHandler: TJSRawEventHandler): TJQuery; external name 'click'; overload;

    function fadeOut: TJQuery; external name 'fadeout'; overload;
    function fadeOut(milliseconds: Integer; aHandler: TProcedureCallback): TJQuery; external name 'fadeout'; overload;
    function fadeOut(milliseconds: Integer; aHandler: TProcedureCallbackEvent): TJQuery; external name 'fadeout'; overload;
    function fadeIn: TJQuery; external name 'fadein'; overload;
    function fadeIn(milliseconds: Integer; aHandler: TProcedureCallback): TJQuery; external name 'fadein'; overload;
    function fadeIn(milliseconds: Integer; aHandler: TProcedureCallbackEvent): TJQuery; external name 'fadein'; overload;

    function off: TJQuery; external name 'off'; overload;
    function off(events: string): TJQuery; external name 'off'; overload;
    function off(events: string; aHandler: TJSEventHandler): TJQuery; external name 'off'; overload;
    function off(events: string; selector: string; aHandler: TJSEventHandler): TJQuery; external name 'off'; overload;
    function off(events: string; selector: string; data: JSValue; aHandler: TJSEventHandler): TJQuery; external name 'off'; overload;

    function On_(events: string; aHandler: TJSEventHandler): TJQuery; external name 'on'; overload;
    function On_(events: string; selector: string; aHandler: TJSRawEventHandler): TJQuery; external name 'on'; overload;
    function On_(events: string; selector: string; data: JSValue; aHandler: TJSEventHandler): TJQuery; external name 'on'; overload;
    function On_(events: TJSObject; selector: string; data: JSValue): TJQuery; external name 'on'; overload;
    function On_(events: TJSObject; data: JSValue): TJQuery; external name 'on'; overload;
    function On_(events: TJSObject): TJQuery; external name 'on'; overload;
    function OnWithData(events: string; aHandler: TJSDataEventHandler): TJQuery; external name 'on';

    function prepend(content: string): TJQuery; external name 'prepend'; overload;
    function prepend(Content1, Content2: string): TJQuery; external name 'prepend'; overload;
    function prepend(aHandler: TPrependCallback): TJQuery; external name 'prepend'; overload;

    function remove(const selector: string): TJQuery; external name 'remove'; overload;
    function remove(): TJQuery; external name 'remove'; overload;

    function trigger(aEventName: string): TJQuery; external name 'trigger'; overload;
    function trigger(aEvent: TJSEvent): TJQuery; external name 'trigger'; overload;

    // UploadBox 
    procedure fileupload(Cmd: string); {$IFDEF pas2js}external name 'fileupload'; {$ENDIF} overload;
    procedure fileupload(opts: TJSObject); {$IFDEF pas2js}external name 'fileupload'; {$ENDIF}overload;
    procedure fileupload(eerst, tweetst: string; iets: TJQuery); {$IFDEF pas2js}external name 'fileupload'; {$ENDIF}overload;

    // Bootstrap
    procedure modal; external name 'modal';
    procedure modal(aCommand: string); external name 'modal';
    procedure modal(aOptions: TJSObject); external name 'modal';
    procedure modal(aOptions: TP2WBootstrapModalOptions); external name 'modal';
    procedure ModalToggle;
    procedure ModalShow;
    procedure ModalHide;
    procedure ModalDispose;
    procedure ModalHandleUpdate;
    procedure Toast; external name 'toast';
    procedure Toast(aCommand: string); external name 'toast';
    procedure Toast(aOptions: TJSObject); external name 'toast';
    procedure ToastShow;
    procedure ToastHide;
    procedure ToastDispose;
    procedure Tab; external name 'tab';
    procedure Tab(aCommand: string); external name 'tab';
    procedure TabShow;
    procedure TabDispose;
    procedure ToolTip; external name 'tooltip';
    procedure ToolTip(aOptions: TJSObject); external name 'tooltip';
    procedure ToolTip(aOptions: TP2WBootstrapTooltipOptions); external name 'tooltip';
    procedure ToolTip(aCommand: string); external name 'tooltip';
    procedure ToolTipShow;
    procedure ToolTipHide;
    procedure ToolTipToggle;
    procedure ToolTipDispose;
    procedure ToolTipEnable;
    procedure ToolTipDisable;
    procedure ToolTipToggleEnabled;
    procedure ToolTipUpdate;
    procedure Alert; external name 'alert';
    procedure Alert(aCommand: string); external name 'alert';
    procedure AlertClose;
    procedure AlertDispose;
    procedure Button(aCommand: string); external name 'button';
    procedure ButtonDispose;
    procedure ButtonToggle;
    procedure Carousel; external name 'carousel';
    procedure Carousel(aCommand: string); external name 'carousel';
    procedure Carousel(options: TJSObject); external name 'carousel';
    procedure Carousel(options: TP2WBootstrapCarouselOptions); external name 'carousel';
    procedure Carousel(aIndex: Integer); external name 'carousel';
    procedure CarouselCycle;
    procedure CarouselPause;
    procedure CarouselPrev;
    procedure CarouselNext;
    procedure CarouselDispose;
    procedure DropDown; external name 'dropdown';
    procedure DropDown(aCommand: string); external name 'dropdown';
    procedure DropDown(options: TJSObject); external name 'dropdown';
    procedure DropDown(options: TP2WBootstrapDropDownOptions); external name 'dropdown';
    procedure DropDownToggle;
    procedure DropDownShow;
    procedure DropDownHide;
    procedure DropDownUpdate;
    procedure DropDownDispose;
    procedure PopOver; external name 'popover';
    procedure PopOver(aCommand: string); external name 'popover';
    procedure PopOver(options: TJSObject); external name 'popover';
    procedure PopOver(options: TP2WBootstrapPopoverOptions); external name 'popover';
    procedure PopOverToggle;
    procedure PopOverShow;
    procedure PopOverHide;
    procedure PopOverDispose;
    procedure PopOverEnable;
    procedure PopOverDisable;
    procedure PopOverToggleEnabled;
    procedure PopOverUpdate;

    class procedure SessionTimeOut(aOptions : TSessionTimeoutOptions); external name 'sessionTimeout'; static;

    // Datatables
    function dataTableAPI: TJSDataTableAPI; external name 'DataTable'; overload;
    function dataTableAPI(options: TJSObject): TJSDataTableAPI; external name 'DataTable'; overload;
    function dataTable: TJSDataTableJQuery; external name 'dataTable';
    
    // JSTree
    function jstree : TP2WJTJSTreeObj; external name 'jstree'; overload;
    function jstree(aTree: TP2WJTJSTreeObj) : TP2WJTJSTreeObj; external name 'jstree'; overload;
  end;


procedure InitSessionTimeOut(aOptions : TSessionTimeoutOptions);

implementation

procedure InitSessionTimeOut(aOptions : TSessionTimeoutOptions);
begin
  TJQuery.SessionTimeOut(aOptions);
end;

{ TP2WJQueryHelper }

procedure TP2WJQueryHelper.ModalToggle;
begin
  modal('toggle');
end;

procedure TP2WJQueryHelper.ModalShow;
begin
  modal('show');
end;

procedure TP2WJQueryHelper.ModalHide;
begin
  modal('hide');
end;

procedure TP2WJQueryHelper.ModalDispose;
begin
  modal('dispose');
end;

procedure TP2WJQueryHelper.ModalHandleUpdate;
begin
  modal('handleupdate');
end;

procedure TP2WJQueryHelper.ToastShow;
begin
  Toast('show');
end;

procedure TP2WJQueryHelper.ToastHide;
begin
  Toast('hide');
end;

procedure TP2WJQueryHelper.ToastDispose;
begin
  Toast('dispose');
end;

procedure TP2WJQueryHelper.TabShow;
begin
  Tab('show');
end;

procedure TP2WJQueryHelper.TabDispose;
begin
  Tab('dispose');
end;

procedure TP2WJQueryHelper.ToolTipShow;
begin
  ToolTip('show');
end;

procedure TP2WJQueryHelper.ToolTipHide;
begin
  ToolTip('hide');
end;

procedure TP2WJQueryHelper.ToolTipToggle;
begin
  ToolTip('toggle');
end;

procedure TP2WJQueryHelper.ToolTipDispose;
begin
  ToolTip('dispose');
end;

procedure TP2WJQueryHelper.ToolTipEnable;
begin
  ToolTip('enable');
end;

procedure TP2WJQueryHelper.ToolTipDisable;
begin
  ToolTip('disable');
end;

procedure TP2WJQueryHelper.ToolTipToggleEnabled;
begin
  ToolTip('toggleEnabled');
end;

procedure TP2WJQueryHelper.ToolTipUpdate;
begin
  ToolTip('update');
end;

procedure TP2WJQueryHelper.AlertClose;
begin
  Alert('close');
end;

procedure TP2WJQueryHelper.AlertDispose;
begin
  Alert('dispose');
end;

procedure TP2WJQueryHelper.ButtonDispose;
begin
  Button('dispose');
end;

procedure TP2WJQueryHelper.ButtonToggle;
begin
  Button('toggle');
end;

procedure TP2WJQueryHelper.CarouselCycle;
begin
  Carousel('cycle');
end;

procedure TP2WJQueryHelper.CarouselPause;
begin
  Carousel('pause');
end;

procedure TP2WJQueryHelper.CarouselPrev;
begin
  Carousel('prev');
end;

procedure TP2WJQueryHelper.CarouselNext;
begin
  Carousel('next');
end;

procedure TP2WJQueryHelper.CarouselDispose;
begin
  Carousel('dispose');
end;

procedure TP2WJQueryHelper.DropDownToggle;
begin
  DropDown('toggle');
end;

procedure TP2WJQueryHelper.DropDownShow;
begin
  DropDown('show');
end;

procedure TP2WJQueryHelper.DropDownHide;
begin
  DropDown('hide');
end;

procedure TP2WJQueryHelper.DropDownUpdate;
begin
  DropDown('update');
end;

procedure TP2WJQueryHelper.DropDownDispose;
begin
  DropDown('dispose');
end;

procedure TP2WJQueryHelper.PopOverDisable;
begin
  PopOver('disable');
end;

procedure TP2WJQueryHelper.PopOverDispose;
begin
  PopOver('dispose');
end;

procedure TP2WJQueryHelper.PopOverEnable;
begin
  PopOver('enable');
end;

procedure TP2WJQueryHelper.PopOverHide;
begin
  PopOver('hide');
end;

procedure TP2WJQueryHelper.PopOverShow;
begin
  PopOver('show');
end;

procedure TP2WJQueryHelper.PopOverToggle;
begin
  PopOver('toggle');
end;

procedure TP2WJQueryHelper.PopOverToggleEnabled;
begin
  PopOver('toggleEnabled');
end;

procedure TP2WJQueryHelper.PopOverUpdate;
begin
  PopOver('enabled');
end;

end.
