unit Forms.Salarytransfer.Detail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Data.DB, Module.Contacts, pas2web.dadatasethelper,
  Datasnap.DBClient, pas2web.dadataset, pas2web.dataelementmapper, libjquery, lib.bootstrap,
  Forms.DossierDetail;

type
  TfrmBase = TfrmDossierDetail;
  TfrmSalarytransferDetail = class(TfrmBase)
    dsSalary: TP2WDADataset;
    dsSalarydebid: TLargeintField;
    dsSalarydebcreatedon: TDateTimeField;
    dsSalarydebcreatedbyfk: TLargeintField;
    dsSalarydebchangedon: TDateTimeField;
    dsSalarydebchangedbyfk: TLargeintField;
    dsSalarydebdossierfk: TLargeintField;
    dsSalarydebrecordtype: TSmallintField;
    dsSalarydebcreditorfk: TLargeintField;
    dsSalarydebdebttypefk: TLargeintField;
    dsSalarydebdebttype: TStringField;
    dsSalarydebstarton: TDateTimeField;
    dsSalarydebreference: TStringField;
    dsSalarydeboriginalamount: TFMTBCDField;
    dsSalarydebcurrentamount: TFMTBCDField;
    dsSalarydebmonthlyamount: TFMTBCDField;
    dsSalarydebisdisputed: TBooleanField;
    dsSalarydebdisputereason: TStringField;
    dsSalarydebdisputecourt: TStringField;
    dsSalarydebdisputedamount: TFMTBCDField;
    dsSalarydebisdelay: TBooleanField;
    dsSalarydebdelayamount: TFMTBCDField;
    dsSalarydebguarantorfk: TLargeintField;
    dsSalarydebiscompleted: TBooleanField;
    eacSalary: TPW2DataElementMapper;
    dsDocuments: TP2WDADataset;
    dsSalarydebRemark: TWideStringField;
    procedure SelectContact(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure dsSalaryAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsSalaryAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure ReadRadioGroupValue(Sender: TObject; Source: TElementAction; dest: TField; var aHandled: Boolean);
    procedure WriteRadioGroupValue(Sender: TObject; Source: TField; Dest: TElementAction; var aHandled: Boolean);
    procedure OnRadioGroupValidation(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure onItemValidationError(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
    procedure OnAmountValidation(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure WebFormDestroy(Sender: TObject);
  private
    FDMContact : TDMContacts;
    { Private declarations }
    procedure LoadCreditorContact(const contactID : Int64);
    procedure SaveSalaryData;
    procedure DoOnContactSaved(Sender: TObject);
    procedure ConfigureContact;
  public
    procedure MakeReadOnly; override;
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
    property DMContact : TDMContacts Read FDMContact;
    Procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
  protected procedure LoadDFMValues; override; end;

var
  frmSalarytransferDetail: TfrmSalarytransferDetail;

implementation

uses
  Modules.Server, Units.ActionUtils, Units.Params,
  Units.DADatasetUtils, Units.Contacts, Units.Strings;

{$R *.dfm}

{ TfrmSalarytransferDetail }

procedure TfrmSalarytransferDetail.SelectContact(Sender: TObject;
    Element: TJSHTMLElementRecord; Event: TJSEventParameter);

 procedure HandleSearchResult(const SelectedContact : TContactSearchResult);
  begin
    LoadCreditorContact(SelectedContact.RecordID);
  end;

begin
  dmContact.OnSearchResult:=@HandleSearchResult;
  dmContact.SearchContact;
end;

procedure TfrmSalarytransferDetail.SaveBtnClick(Sender: TObject;
    Element: TJSHTMLElementRecord; Event: TJSEventParameter);

Var
  Res : Boolean;

begin
  res := eacSalary.ValidateActions(True);
  if res then
    dmContact.SaveIfValid;
end;


procedure TfrmSalarytransferDetail.SaveSalaryData;
begin
  if dsSalary.isEmpty then
  begin
    dsSalary.Insert;
    dsSalary.FieldByName('debcreatedon').asDateTime := now;
    dsSalary.FieldByName('debcreatedbyfk').AsLargeInt := Server.UserID;

    dsSalary.FieldByName('debdossierfk').AsLargeInt :=  DossierID;
    dsSalary.FieldByName('debrecordtype').asInteger := 2;
    dsSalary.FieldByName('debdebttypefk').asInteger := 0;
    dsSalary.FieldByName('debdebttype').asString := '';
    dsSalary.FieldByName('debstarton').asDateTime := now;
    dsSalary.FieldByName('debreference').asString := '';
    dsSalary.FieldByName('debcurrentamount').asFloat := 0.0;
    dsSalary.FieldByName('debisdisputed').asBoolean := False;
    dsSalary.FieldByName('debdisputereason').asString := '';
    dsSalary.FieldByName('debdisputecourt').asString := '';
    dsSalary.FieldByName('debdisputedamount').asFloat := 0.0;

    dsSalary.FieldByName('debisdelay').asBoolean := False;
    dsSalary.FieldByName('debdelayamount').asFloat := 0.0;
  end
  else
    dsSalary.Edit;
  dsSalary.FieldByName('debchangedon').asDateTime := now;
  dsSalary.FieldByName('debchangedbyfk').asLargeInt := Server.UserID;
  dsSalary.FieldByName('debcreditorfk').asInteger := dmContact.ContactID;

  eacSalary.ElementsToDataset;
  dsSalary.Post;
  dsSalary.ApplyUpdates;
end;


procedure TfrmSalarytransferDetail.dsSalaryAfterApplyUpdates(Sender: TDataSet;
    Info: TResolveResults);

  procedure uploadsCompleted;
  begin
    DiscardChanges := True;
    window.location.href := DossierURL('/salarytransfer');
  end;

begin
  if CheckResolveResults(Info) then
  begin
    if DefaultDocumentBox.GetAwaitingUploadCount = 0 then
    begin
      DiscardChanges := True;
      window.location.href := DossierURL('/salarytransfer');
    end
    else
      DefaultDocumentBox.DoFileQueueUpload(Sender.FieldByName('debid').asLargeInt,'', @uploadsCompleted);
  end;
end;

procedure TfrmSalarytransferDetail.dsSalaryAfterOpen(DataSet: TDataSet);

Var
  SourceID : NativeInt;

begin
  inherited;
  SourceID:=-1;
  if not DataSet.isEmpty then
  begin
    LoadCreditorContact(Dataset.FieldByName('debcreditorfk').asInteger);
    SourceID:=DataSet.FieldByName('debid').asLargeInt
  end
  else
    LoadCreditorContact(-1);
  DefaultDocumentBox.LoadDocuments(dsDocuments, DossierID, SourceID, 'uploadList');
  DefaultDocumentBox.setDocBoxParams(14, SourceID, 'SALTRANS', DossierID);
  eacSalary.DatasetToElements;
end;

procedure TfrmSalarytransferDetail.DoOnContactSaved(Sender : TObject);

begin
  SaveSalaryData;
end;

procedure TfrmSalarytransferDetail.canHandleRoute(previousURL: string;
  var allow: Boolean);
begin
  allow := DiscardChanges and ((DefaultDocumentBox.GetAwaitingUploadCount = 0) OR DiscardFiles);
end;

procedure TfrmSalarytransferDetail.ConfigureContact;


Const
  FromPersonalFields : TPersonalFieldNames = (
    '','','','', '',
    '','','','','','edtCreditorName',
    '','','','');

Var
  PersonalFields : TPersonalFieldEdits;

begin
  PersonalFields.Names:=FromPersonalFields;
  PersonalFields.SetFieldsFromNames;
  PersonalFields.FirstNameNotRequired:=True;
  dmContact.PersonalFieldInfo:=PersonalFields;
  dmContact.FormIsReadOnly:=Self.FormIsReadOnly;
  dmContact.SearchButtonID:='btnSelectCreditor';
  dmContact.EditContactButtonID:='btnNewCreditor';
  dmContact.ContactDescription:=SContactCreditor;
  dmContact.OnAllSaved:=@DoOnContactSaved;
  dmContact.OnContactChanged:=@OnContactChanged;
end;

procedure TfrmSalarytransferDetail.LoadCreditorContact(const contactID: Int64);
begin
  ConfigureContact;
  dmContact.LoadContactData(ContactID,[cdpPersonal],[]);
end;

procedure TfrmSalarytransferDetail.MakeReadOnly;
begin
  inherited;
  dmContact.FormIsReadOnly:=Self.FormIsReadOnly;
end;

procedure TfrmSalarytransferDetail.OnAmountValidation(Sender: TObject;
  Source: TElementAction; var aResult: Boolean);
begin
  aResult := isNumeric('edtMonthlyAmount') and (StrToFloatDef(alForm['edtMonthlyAmount'].value, 0) > 0);
end;

procedure TfrmSalarytransferDetail.onItemValidationError(Sender: TObject;
  Source: TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmSalarytransferDetail.OnRadioGroupValidation(Sender: TObject;
  Source: TElementAction; var aResult: Boolean);
begin
  aResult := isSelectedRadioGroup(Source.Name);
end;

procedure TfrmSalarytransferDetail.ReadRadioGroupValue(Sender: TObject;
  Source: TElementAction; dest: TField; var aHandled: Boolean);
begin
  dest.asBoolean := getRadioGroupValue(Source.Name) = 'Yes';
  aHandled := True;
end;

procedure TfrmSalarytransferDetail.setParams(const Params: TStrings);
begin
  Inherited;
  jQuery('#returnBack').attr('href',DossierURL('/salarytransfer'));
  dsSalary.ParamByName('DEBID').AsInteger := StrToInt64Def(Params.Values['TRANSFERID'], -1);
end;


procedure TfrmSalarytransferDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  FDMContact:=CreateContactModule;
  dsSalary.DAConnection := DossierConnection;
  BindOnChangeEvent;
end;

procedure TfrmSalarytransferDetail.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FDMContact);
  inherited;
end;

procedure TfrmSalarytransferDetail.WebFormShow(Sender: TObject);
begin
  inherited;
  ConfigureContact;
  dsSalary.Load([], nil);
end;

procedure TfrmSalarytransferDetail.WriteRadioGroupValue(Sender: TObject;
  Source: TField; Dest: TElementAction; var aHandled: Boolean);
begin
  if Source.asBoolean then
    setRadiogroupSelectedElement(Dest.DisplayName, 'Yes')
  else
    setRadiogroupSelectedElement(Dest.DisplayName, 'No');
  aHandled := True;
end;

procedure TfrmSalarytransferDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsSalary := TP2WDADataset.Create(Self);
  dsSalarydebid := TLargeintField.Create(Self);
  dsSalarydebcreatedon := TDateTimeField.Create(Self);
  dsSalarydebcreatedbyfk := TLargeintField.Create(Self);
  dsSalarydebchangedon := TDateTimeField.Create(Self);
  dsSalarydebchangedbyfk := TLargeintField.Create(Self);
  dsSalarydebdossierfk := TLargeintField.Create(Self);
  dsSalarydebrecordtype := TSmallintField.Create(Self);
  dsSalarydebcreditorfk := TLargeintField.Create(Self);
  dsSalarydebdebttypefk := TLargeintField.Create(Self);
  dsSalarydebdebttype := TStringField.Create(Self);
  dsSalarydebstarton := TDateTimeField.Create(Self);
  dsSalarydebreference := TStringField.Create(Self);
  dsSalarydeboriginalamount := TFMTBCDField.Create(Self);
  dsSalarydebcurrentamount := TFMTBCDField.Create(Self);
  dsSalarydebmonthlyamount := TFMTBCDField.Create(Self);
  dsSalarydebisdisputed := TBooleanField.Create(Self);
  dsSalarydebdisputereason := TStringField.Create(Self);
  dsSalarydebdisputecourt := TStringField.Create(Self);
  dsSalarydebdisputedamount := TFMTBCDField.Create(Self);
  dsSalarydebisdelay := TBooleanField.Create(Self);
  dsSalarydebdelayamount := TFMTBCDField.Create(Self);
  dsSalarydebguarantorfk := TLargeintField.Create(Self);
  dsSalarydebiscompleted := TBooleanField.Create(Self);
  dsSalarydebRemark := TWideStringField.Create(Self);
  eacSalary := TPW2DataElementMapper.Create(Self);
  dsDocuments := TP2WDADataset.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsSalary.BeforeLoadDFMValues;
  dsSalarydebid.BeforeLoadDFMValues;
  dsSalarydebcreatedon.BeforeLoadDFMValues;
  dsSalarydebcreatedbyfk.BeforeLoadDFMValues;
  dsSalarydebchangedon.BeforeLoadDFMValues;
  dsSalarydebchangedbyfk.BeforeLoadDFMValues;
  dsSalarydebdossierfk.BeforeLoadDFMValues;
  dsSalarydebrecordtype.BeforeLoadDFMValues;
  dsSalarydebcreditorfk.BeforeLoadDFMValues;
  dsSalarydebdebttypefk.BeforeLoadDFMValues;
  dsSalarydebdebttype.BeforeLoadDFMValues;
  dsSalarydebstarton.BeforeLoadDFMValues;
  dsSalarydebreference.BeforeLoadDFMValues;
  dsSalarydeboriginalamount.BeforeLoadDFMValues;
  dsSalarydebcurrentamount.BeforeLoadDFMValues;
  dsSalarydebmonthlyamount.BeforeLoadDFMValues;
  dsSalarydebisdisputed.BeforeLoadDFMValues;
  dsSalarydebdisputereason.BeforeLoadDFMValues;
  dsSalarydebdisputecourt.BeforeLoadDFMValues;
  dsSalarydebdisputedamount.BeforeLoadDFMValues;
  dsSalarydebisdelay.BeforeLoadDFMValues;
  dsSalarydebdelayamount.BeforeLoadDFMValues;
  dsSalarydebguarantorfk.BeforeLoadDFMValues;
  dsSalarydebiscompleted.BeforeLoadDFMValues;
  dsSalarydebRemark.BeforeLoadDFMValues;
  eacSalary.BeforeLoadDFMValues;
  dsDocuments.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'edtOriginalAmount';
      Name := 'edtOriginalAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtMonthlyAmount';
      Name := 'edtMonthlyAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := '';
      Name := 'rdTransferComplete';
      PreventDefault := False;
      Selector := 'input[name="rdTransferComplete"]';
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'mmRemark';
      Name := 'mmRemark';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    dsSalary.SetParentComponent(Self);
    dsSalary.Name := 'dsSalary';
    dsSalary.TableName := 'debt';
    dsSalary.Params.Clear;
    with dsSalary.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DEBID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsSalary.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>debid</field><parameter type="LargeInt">DEBID</parameter></binaryoperation><binaryoperation operator="Equal"><field>debrecordtype</field><constant type="Integer">2</constant></binaryoperation></binaryoperation></where></query>';
    dsSalary.DAOptions := [doRefreshAllFields];
    dsSalary.AfterOpen := dsSalaryAfterOpen;
    dsSalary.AfterApplyUpdates := dsSalaryAfterApplyUpdates;
    dsSalary.Left := 40;
    dsSalary.Top := 88;
    dsSalarydebid.SetParentComponent(dsSalary);
    dsSalarydebid.Name := 'dsSalarydebid';
    dsSalarydebid.FieldName := 'debid';
    dsSalarydebcreatedon.SetParentComponent(dsSalary);
    dsSalarydebcreatedon.Name := 'dsSalarydebcreatedon';
    dsSalarydebcreatedon.FieldName := 'debcreatedon';
    dsSalarydebcreatedbyfk.SetParentComponent(dsSalary);
    dsSalarydebcreatedbyfk.Name := 'dsSalarydebcreatedbyfk';
    dsSalarydebcreatedbyfk.FieldName := 'debcreatedbyfk';
    dsSalarydebchangedon.SetParentComponent(dsSalary);
    dsSalarydebchangedon.Name := 'dsSalarydebchangedon';
    dsSalarydebchangedon.FieldName := 'debchangedon';
    dsSalarydebchangedbyfk.SetParentComponent(dsSalary);
    dsSalarydebchangedbyfk.Name := 'dsSalarydebchangedbyfk';
    dsSalarydebchangedbyfk.FieldName := 'debchangedbyfk';
    dsSalarydebdossierfk.SetParentComponent(dsSalary);
    dsSalarydebdossierfk.Name := 'dsSalarydebdossierfk';
    dsSalarydebdossierfk.FieldName := 'debdossierfk';
    dsSalarydebrecordtype.SetParentComponent(dsSalary);
    dsSalarydebrecordtype.Name := 'dsSalarydebrecordtype';
    dsSalarydebrecordtype.FieldName := 'debrecordtype';
    dsSalarydebcreditorfk.SetParentComponent(dsSalary);
    dsSalarydebcreditorfk.Name := 'dsSalarydebcreditorfk';
    dsSalarydebcreditorfk.FieldName := 'debcreditorfk';
    dsSalarydebdebttypefk.SetParentComponent(dsSalary);
    dsSalarydebdebttypefk.Name := 'dsSalarydebdebttypefk';
    dsSalarydebdebttypefk.FieldName := 'debdebttypefk';
    dsSalarydebdebttype.SetParentComponent(dsSalary);
    dsSalarydebdebttype.Name := 'dsSalarydebdebttype';
    dsSalarydebdebttype.FieldName := 'debdebttype';
    dsSalarydebdebttype.Size := 10;
    dsSalarydebstarton.SetParentComponent(dsSalary);
    dsSalarydebstarton.Name := 'dsSalarydebstarton';
    dsSalarydebstarton.FieldName := 'debstarton';
    dsSalarydebreference.SetParentComponent(dsSalary);
    dsSalarydebreference.Name := 'dsSalarydebreference';
    dsSalarydebreference.FieldName := 'debreference';
    dsSalarydebreference.Size := 64;
    dsSalarydeboriginalamount.SetParentComponent(dsSalary);
    dsSalarydeboriginalamount.Name := 'dsSalarydeboriginalamount';
    dsSalarydeboriginalamount.FieldName := 'deboriginalamount';
    dsSalarydeboriginalamount.Precision := 12;
    dsSalarydeboriginalamount.Size := 0;
    dsSalarydebcurrentamount.SetParentComponent(dsSalary);
    dsSalarydebcurrentamount.Name := 'dsSalarydebcurrentamount';
    dsSalarydebcurrentamount.FieldName := 'debcurrentamount';
    dsSalarydebcurrentamount.Precision := 12;
    dsSalarydebcurrentamount.Size := 0;
    dsSalarydebmonthlyamount.SetParentComponent(dsSalary);
    dsSalarydebmonthlyamount.Name := 'dsSalarydebmonthlyamount';
    dsSalarydebmonthlyamount.FieldName := 'debmonthlyamount';
    dsSalarydebmonthlyamount.Precision := 12;
    dsSalarydebmonthlyamount.Size := 0;
    dsSalarydebisdisputed.SetParentComponent(dsSalary);
    dsSalarydebisdisputed.Name := 'dsSalarydebisdisputed';
    dsSalarydebisdisputed.FieldName := 'debisdisputed';
    dsSalarydebdisputereason.SetParentComponent(dsSalary);
    dsSalarydebdisputereason.Name := 'dsSalarydebdisputereason';
    dsSalarydebdisputereason.FieldName := 'debdisputereason';
    dsSalarydebdisputereason.Size := 127;
    dsSalarydebdisputecourt.SetParentComponent(dsSalary);
    dsSalarydebdisputecourt.Name := 'dsSalarydebdisputecourt';
    dsSalarydebdisputecourt.FieldName := 'debdisputecourt';
    dsSalarydebdisputecourt.Size := 127;
    dsSalarydebdisputedamount.SetParentComponent(dsSalary);
    dsSalarydebdisputedamount.Name := 'dsSalarydebdisputedamount';
    dsSalarydebdisputedamount.FieldName := 'debdisputedamount';
    dsSalarydebdisputedamount.Precision := 12;
    dsSalarydebdisputedamount.Size := 0;
    dsSalarydebisdelay.SetParentComponent(dsSalary);
    dsSalarydebisdelay.Name := 'dsSalarydebisdelay';
    dsSalarydebisdelay.FieldName := 'debisdelay';
    dsSalarydebdelayamount.SetParentComponent(dsSalary);
    dsSalarydebdelayamount.Name := 'dsSalarydebdelayamount';
    dsSalarydebdelayamount.FieldName := 'debdelayamount';
    dsSalarydebdelayamount.Precision := 12;
    dsSalarydebdelayamount.Size := 0;
    dsSalarydebguarantorfk.SetParentComponent(dsSalary);
    dsSalarydebguarantorfk.Name := 'dsSalarydebguarantorfk';
    dsSalarydebguarantorfk.FieldName := 'debguarantorfk';
    dsSalarydebiscompleted.SetParentComponent(dsSalary);
    dsSalarydebiscompleted.Name := 'dsSalarydebiscompleted';
    dsSalarydebiscompleted.FieldName := 'debiscompleted';
    dsSalarydebRemark.SetParentComponent(dsSalary);
    dsSalarydebRemark.Name := 'dsSalarydebRemark';
    dsSalarydebRemark.FieldName := 'debRemark';
    dsSalarydebRemark.Size := 255;
    eacSalary.SetParentComponent(Self);
    eacSalary.Name := 'eacSalary';
    eacSalary.ActionLinks.Clear;
    with eacSalary.ActionLinks.Add do
    begin
      ActionName := 'edtMonthlyAmount';
      FieldName := 'debmonthlyamount';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
      SetEvent(Self, 'OnValidation', 'OnAmountValidation');
      ClearValue := '0';
    end;
    with eacSalary.ActionLinks.Add do
    begin
      ActionName := 'edtOriginalAmount';
      FieldName := 'deboriginalamount';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
      SetEvent(Self, 'OnValidation', 'OnAmountValidation');
      ClearValue := '0';
    end;
    with eacSalary.ActionLinks.Add do
    begin
      ActionName := 'rdTransferComplete';
      FieldName := 'debiscompleted';
      DataAttribute := 'i';
      Options := [aoRequired];
      SetEvent(Self, 'OnFieldToAction', 'WriteRadioGroupValue');
      SetEvent(Self, 'OnActionToField', 'ReadRadioGroupValue');
      SetEvent(Self, 'OnValidationError', 'onItemValidationError');
      SetEvent(Self, 'OnValidation', 'OnRadioGroupValidation');
    end;
    with eacSalary.ActionLinks.Add do
    begin
      ActionName := 'mmRemark';
      FieldName := 'debRemark';
      Options := [];
    end;
    eacSalary.ActionList := alForm;
    eacSalary.Dataset := dsSalary;
    eacSalary.Left := 40;
    eacSalary.Top := 152;
    dsDocuments.SetParentComponent(Self);
    dsDocuments.Name := 'dsDocuments';
    dsDocuments.TableName := 'dossierfile';
    dsDocuments.Params.Clear;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'DOSSIERID';
      ParamType := ptInput;
      Value := 0;
    end;
    with dsDocuments.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'SRCID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsDocuments.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="And"><binaryoperation operator="Equal"><field>dofdossierfk</field><parameter type="LargeInt">DOSSIERID</parameter></binaryoperation><binaryoperation operator="Equal"><field>dofsourcefk</field><parameter type="LargeInt">SRCID</parameter></binaryoperation></binaryoperation><binaryoperation operator="Equal"><field>dofdoctype</field><constant type="String">SALTRANS</constant></binaryoperation></binaryoperation></where></query>';
    dsDocuments.DAOptions := [];
    dsDocuments.Left := 568;
    dsDocuments.Top := 152;
  finally
    alForm.AfterLoadDFMValues;
    dsSalary.AfterLoadDFMValues;
    dsSalarydebid.AfterLoadDFMValues;
    dsSalarydebcreatedon.AfterLoadDFMValues;
    dsSalarydebcreatedbyfk.AfterLoadDFMValues;
    dsSalarydebchangedon.AfterLoadDFMValues;
    dsSalarydebchangedbyfk.AfterLoadDFMValues;
    dsSalarydebdossierfk.AfterLoadDFMValues;
    dsSalarydebrecordtype.AfterLoadDFMValues;
    dsSalarydebcreditorfk.AfterLoadDFMValues;
    dsSalarydebdebttypefk.AfterLoadDFMValues;
    dsSalarydebdebttype.AfterLoadDFMValues;
    dsSalarydebstarton.AfterLoadDFMValues;
    dsSalarydebreference.AfterLoadDFMValues;
    dsSalarydeboriginalamount.AfterLoadDFMValues;
    dsSalarydebcurrentamount.AfterLoadDFMValues;
    dsSalarydebmonthlyamount.AfterLoadDFMValues;
    dsSalarydebisdisputed.AfterLoadDFMValues;
    dsSalarydebdisputereason.AfterLoadDFMValues;
    dsSalarydebdisputecourt.AfterLoadDFMValues;
    dsSalarydebdisputedamount.AfterLoadDFMValues;
    dsSalarydebisdelay.AfterLoadDFMValues;
    dsSalarydebdelayamount.AfterLoadDFMValues;
    dsSalarydebguarantorfk.AfterLoadDFMValues;
    dsSalarydebiscompleted.AfterLoadDFMValues;
    dsSalarydebRemark.AfterLoadDFMValues;
    eacSalary.AfterLoadDFMValues;
    dsDocuments.AfterLoadDFMValues;
  end;
end;

end.
