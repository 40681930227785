unit Forms.Expense.Detail;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dataelementmapper,
  Forms.DossierDetail;

type
  TFrmBase = TFrmDossierDetail;

  TfrmExpenseDetail = class(TFrmBase)
    dsExpense: TP2WDADataset;
    eacExpense: TPW2DataElementMapper;
    procedure dsExpenseAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsExpenseAfterOpen(DataSet: TDataSet);
    procedure OnAmountValidation(Sender: TObject; Source: TElementAction; var aResult: Boolean);
    procedure OnCategoryChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnKindChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnPeriodicityChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure OnFieldValidationError(Sender: TObject; Source: TElementAction; dest: TField; aError: TElementError);
    procedure SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }

    procedure DoPeriodicyChange;
    procedure DoSaveExpenseForm;
    procedure GetCategories;
    procedure GetKinds(const category: string);
    procedure GetPeriods;
  public
    class function NeedsParams: Boolean; override;
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmExpenseDetail: TfrmExpenseDetail;

implementation

uses
  pas2web.dadatasethelper,
  Modules.Server,
  Units.ActionUtils,
  Units.Params;

{$R *.dfm}
{ TfrmExpenseDetail }

procedure TfrmExpenseDetail.OnCategoryChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  id: string;
begin
  id := jQuery('#cbCategory').find('option[value="' + alForm['cbCategory'].Value + '"]').attr('data-id');
  if id = undefined then
    id := '-1';
  jQuery('#cbCategory').attr('data-id', id);
  GetKinds(alForm['cbCategory'].Value);
end;

procedure TfrmExpenseDetail.SaveBtnClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if eacExpense.ValidateActions(True) then
    DoSaveExpenseForm;
end;

procedure TfrmExpenseDetail.WebFormCreate(Sender: TObject);
begin
  inherited;

  GetCategories;
  GetPeriods;
  GetKinds(alForm['cbCategory'].Value);

  dsExpense.DAConnection := DossierConnection;
  BindOnChangeEvent;
end;

procedure TfrmExpenseDetail.DoSaveExpenseForm;
begin
  if dsExpense.IsEmpty then
  begin
    dsExpense.Insert;
    dsExpense.FieldByName('expcreatedon').asDateTime := now;
    dsExpense.FieldByName('expcreatedbyfk').asLargeInt := Server.UserID;
    dsExpense.FieldByName('expdossierfk').asLargeInt := DossierID;
  end
  else
    dsExpense.Edit;
  dsExpense.FieldByName('expchangedon').asDateTime := now;
  dsExpense.FieldByName('expchangedbyfk').asLargeInt := Server.UserID;

  eacExpense.ElementsToDataset;
  dsExpense.Post;
  dsExpense.ApplyUpdates;
end;

procedure TfrmExpenseDetail.dsExpenseAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);

  procedure onSuccess;
  begin
    DiscardChanges := True;
    window.location.href := DossierURL('/expense');
  end;

begin
  CheckResolveResults(Info, @onSuccess);
end;

procedure TfrmExpenseDetail.OnAmountValidation(Sender: TObject; Source: TElementAction; var aResult: Boolean);
begin
  aResult := isNumeric('edtExpenseAmount') and (StrToFloatDef(alForm['edtExpenseAmount'].Value, 0) > 0);
end;

procedure TfrmExpenseDetail.OnFieldValidationError(Sender: TObject; Source: TElementAction; dest: TField;
  aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmExpenseDetail.OnKindChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
var
  id: string;
begin
  id := jQuery('#cbKind').find('option[value="' + alForm['cbKind'].Value + '"]').attr('data-id');
  if id = undefined then
    id := '-1';
  jQuery('#cbKind').attr('data-id', id);
end;

procedure TfrmExpenseDetail.OnPeriodicityChange(Sender: TObject; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
var
  id: string;
begin
  id := jQuery('#cbPeriodicity').find('option[value="' + alForm['cbPeriodicity'].Value + '"]').attr('data-id');
  if id = undefined then
    id := '-1';
  jQuery('#cbPeriodicity').attr('data-id', id);
  DoPeriodicyChange;
end;

procedure TfrmExpenseDetail.GetCategories;
var
  list: TDezqParameterArray;
  i: Integer;
  id: string;
begin
  list := Server.GetParamList('EXCAT');
  for i := low(list) to high(list) do
  begin
    alForm['cbCategory'].InnerHTML := alForm['cbCategory'].InnerHTML + '<option value="' + list[i].Name + '" data-id="'
      + InttoStr(list[i].RecordID) + '">' + list[i].Value + '</option>';
  end;
  id := jQuery('#cbCategory').find('option[value="' + alForm['cbCategory'].Value + '"]').attr('data-id');
  if id = undefined then
    id := '-1';
  jQuery('#cbCategory').attr('data-id', id);
end;

procedure TfrmExpenseDetail.GetKinds(const category: string);
var
  list: TDezqParameterArray;
  i: Integer;
  id: string;
begin
  list := Server.GetParamList('EXKIND');
  alForm['cbKind'].InnerHTML := '';
  for i := low(list) to high(list) do
  begin
    if (list[i].Extra = '') or (list[i].Extra = category) then
      alForm['cbKind'].InnerHTML := alForm['cbKind'].InnerHTML + '<option data-id="' + InttoStr(list[i].RecordID) +
        '" value="' + list[i].Name + '">' + list[i].Value + '</option>'
  end;
  id := jQuery('#cbKind').find('option[value="' + alForm['cbKind'].Value + '"]').attr('data-id');
  if id = undefined then
    id := '-1';
  jQuery('#cbKind').attr('data-id', id);
end;

procedure TfrmExpenseDetail.GetPeriods;
var
  list: TDezqParameterArray;
  i: Integer;
  id: string;
begin
  list := Server.GetParamList('REVPER');
  for i := low(list) to high(list) do
  begin
    alForm['cbPeriodicity'].InnerHTML := alForm['cbPeriodicity'].InnerHTML + '<option data-id="' +
      InttoStr(list[i].RecordID) + '" value="' + list[i].Name + '">' + list[i].Value + '</option>';
  end;
  DoPeriodicyChange;
  id := jQuery('#cbPeriodicity').find('option[value="' + alForm['cbPeriodicity'].Value + '"]').attr('data-id');
  if id = undefined then
    id := '-1';
  jQuery('#cbPeriodicity').attr('data-id', id);
end;

class function TfrmExpenseDetail.NeedsParams: Boolean;
begin
  Result := True;
end;

procedure TfrmExpenseDetail.DoPeriodicyChange;
var
  i: Integer;
  top: Integer;
  val: string;
begin
  if alForm['cbPeriodicity'].Value = 'Y' then
    top := 12
  else
    if alForm['cbPeriodicity'].Value = 'M' then
      top := 31
    else
      if alForm['cbPeriodicity'].Value = 'W' then
        top := 7
      else
        exit;

  alForm['cbPaymentOn'].InnerHTML := '';
  for i := 1 to top do
  begin
    if alForm['cbPeriodicity'].Value = 'Y' then
      val := FormatSettings.LongMonthNames[i]
    else
      val := InttoStr(i);
    alForm['cbPaymentOn'].InnerHTML := alForm['cbPaymentOn'].InnerHTML + '<option value="' + InttoStr(i) + '">' + val +
      '</option>';
  end;

end;

procedure TfrmExpenseDetail.dsExpenseAfterOpen(DataSet: TDataSet);
begin
  eacExpense.DatasetToElements; // this one to setup category and kind
  GetKinds(alForm['cbCategory'].Value);
  eacExpense.DatasetToElements; // this one to assign kind
end;

procedure TfrmExpenseDetail.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#returnBack').attr('href', DossierURL('expense'));
  dsExpense.ParamByName('expid').AsInteger := StrToInt64Def(Params.Values['EXPENSEID'], -1);
end;

procedure TfrmExpenseDetail.WebFormShow(Sender: TObject);
begin
  inherited;
  dsExpense.Load([], nil);
end;

procedure TfrmExpenseDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsExpense := TP2WDADataset.Create(Self);
  eacExpense := TPW2DataElementMapper.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsExpense.BeforeLoadDFMValues;
  eacExpense.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtExpenseAmount';
      Name := 'edtExpenseAmount';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'cbCategory';
      Name := 'cbCategory';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'cbKind';
      Name := 'cbKind';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'cbPeriodicity';
      Name := 'cbPeriodicity';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'cbPaymentOn';
      Name := 'cbPaymentOn';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      Event := heFocus;
      ID := 'edtRemartks';
      Name := 'edtRemartks';
      SetEvent(Self, 'OnExecute', 'DoClearError');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'SaveBtnClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'cbCategory';
      Name := 'cbCategory_change';
      SetEvent(Self, 'OnExecute', 'OnCategoryChange');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'cbKind';
      Name := 'cbKind_change';
      SetEvent(Self, 'OnExecute', 'OnKindChange');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'cbPeriodicity';
      Name := 'cbPeriodicity_change';
      SetEvent(Self, 'OnExecute', 'OnPeriodicityChange');
    end;
    dsExpense.SetParentComponent(Self);
    dsExpense.Name := 'dsExpense';
    dsExpense.TableName := 'expense';
    dsExpense.Params.Clear;
    with dsExpense.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'expid';
      ParamType := ptInput;
      Value := 0;
    end;
    dsExpense.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="Equal"><field>expid</field><parameter type="LargeInt">expid</parameter></binaryoperation></where></query>';
    dsExpense.DAOptions := [doRefreshAllFields];
    dsExpense.AfterOpen := dsExpenseAfterOpen;
    dsExpense.AfterApplyUpdates := dsExpenseAfterApplyUpdates;
    dsExpense.Left := 40;
    dsExpense.Top := 88;
    eacExpense.SetParentComponent(Self);
    eacExpense.Name := 'eacExpense';
    eacExpense.ActionLinks.Clear;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'edtExpenseAmount';
      FieldName := 'expamount';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
      SetEvent(Self, 'OnValidation', 'OnAmountValidation');
      ClearValue := '0';
    end;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'cbCategory';
      FieldName := 'expcategory';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'cbCategory';
      FieldName := 'expcategoryfk';
      DataAttribute := 'id';
      Options := [];
    end;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'cbKind';
      FieldName := 'expkind';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'cbKind';
      FieldName := 'expkindfk';
      DataAttribute := 'id';
      Options := [];
    end;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'cbPeriodicity';
      FieldName := 'exppaymentperiod';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'cbPeriodicity';
      FieldName := 'exppaymentperiodfk';
      DataAttribute := 'id';
      Options := [];
    end;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'cbPaymentOn';
      FieldName := 'exppaymenton';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacExpense.ActionLinks.Add do
    begin
      ActionName := 'edtRemartks';
      FieldName := 'expremark';
      Options := [];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    eacExpense.ActionList := alForm;
    eacExpense.Dataset := dsExpense;
    eacExpense.Left := 40;
    eacExpense.Top := 152;
  finally
    alForm.AfterLoadDFMValues;
    dsExpense.AfterLoadDFMValues;
    eacExpense.AfterLoadDFMValues;
  end;
end;

end.
