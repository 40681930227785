unit Forms.ListPetitions;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, units.types,
  pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.dadatasethelper;

type
  TfrmPetition = class(TfrmBase)
    dsPetitions: TP2WDADataset;
    dcPetitions: TP2WDatatable;
    dsPetitionsdosid: TLargeintField;
    dsPetitionsdoscreatedon: TDateTimeField;
    dsPetitionsdosclosedon: TDateTimeField;
    dsPetitionsdosdescription: TStringField;
    dsPetitionsdosstatus: TSmallintField;
    procedure dcPetitionsstatusGetValue(Sender: TObject; Fields:
        TP2WDataTableFieldMap; out aOutput: string);
    procedure dsPetitionsAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmPetition: TfrmPetition;

implementation

Uses
  Modules.Server, Units.ActionUtils;

{$R *.dfm}

procedure TfrmPetition.dcPetitionsstatusGetValue(Sender: TObject; Fields:
    TP2WDataTableFieldMap; out aOutput: string);

Var
  T : TDossierStatus;

begin
  T.AsInteger:=strtoInt(string(Fields.GetValueByName('dosstatus')));
  aOutput:=T.asString;

{  case  of
    0 : aOutput := 'Unknown';
    1 : aOutput := 'Active';
    2 : aOutput := 'Completed (all data present)';
    3 : aOutput := 'Closed (no case)';
    4 : aOutput := 'Closed (court case)';
    5 : aOutput := 'Reopened (court case)';
  end;}
end;

procedure TfrmPetition.dsPetitionsAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
  iCnt,iStatus: Integer;
  aID : NativeInt;
  S : TDossierStatus;

begin
  aResult := dsPetitions.GetNewRows(False);
  dcPetitions.Data := aResult;
  dcPetitions.RenderTranslated;

  iCnt := dsPetitions.RecordCount;
  alForm['actTotal'].Value := IntToStr(iCnt);
  dsPetitions.First;
  While not dsPetitions.EOF do
    begin
    aID:=dsPetitionsdosid.asLargeInt;
    iStatus:=dsPetitionsdosstatus.AsInteger;
    S.AsInteger:=iStatus;
    dmServer.MarkPetitionReadOnly(aID,S.IsArchived or (S=dsUnknown));
    dsPetitions.Next;
    end;
  dsPetitions.First;
end;

procedure TfrmPetition.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.PetitionConnection.Message.ClientID := Server.ClientID;
  dsPetitions.DAConnection := Server.PetitionConnection;
  dsPetitions.Load([], nil);
end;

procedure TfrmPetition.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsPetitions := TP2WDADataset.Create(Self);
  dsPetitionsdosid := TLargeintField.Create(Self);
  dsPetitionsdoscreatedon := TDateTimeField.Create(Self);
  dsPetitionsdosclosedon := TDateTimeField.Create(Self);
  dsPetitionsdosdescription := TStringField.Create(Self);
  dsPetitionsdosstatus := TSmallintField.Create(Self);
  dcPetitions := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsPetitions.BeforeLoadDFMValues;
  dsPetitionsdosid.BeforeLoadDFMValues;
  dsPetitionsdoscreatedon.BeforeLoadDFMValues;
  dsPetitionsdosclosedon.BeforeLoadDFMValues;
  dsPetitionsdosdescription.BeforeLoadDFMValues;
  dsPetitionsdosstatus.BeforeLoadDFMValues;
  dcPetitions.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'total';
      Name := 'actTotal';
    end;
    dsPetitions.SetParentComponent(Self);
    dsPetitions.Name := 'dsPetitions';
    dsPetitions.TableName := 'dossier';
    dsPetitions.DAOptions := [];
    dsPetitions.AfterOpen := dsPetitionsAfterOpen;
    dsPetitions.Left := 184;
    dsPetitions.Top := 56;
    dsPetitionsdosid.SetParentComponent(dsPetitions);
    dsPetitionsdosid.Name := 'dsPetitionsdosid';
    dsPetitionsdosid.FieldName := 'dosid';
    dsPetitionsdoscreatedon.SetParentComponent(dsPetitions);
    dsPetitionsdoscreatedon.Name := 'dsPetitionsdoscreatedon';
    dsPetitionsdoscreatedon.FieldName := 'doscreatedon';
    dsPetitionsdoscreatedon.DisplayFormat := 'dd/mm/yyyy';
    dsPetitionsdosclosedon.SetParentComponent(dsPetitions);
    dsPetitionsdosclosedon.Name := 'dsPetitionsdosclosedon';
    dsPetitionsdosclosedon.FieldName := 'dosclosedon';
    dsPetitionsdosclosedon.DisplayFormat := 'dd/mm/yyyy';
    dsPetitionsdosdescription.SetParentComponent(dsPetitions);
    dsPetitionsdosdescription.Name := 'dsPetitionsdosdescription';
    dsPetitionsdosdescription.FieldName := 'dosdescription';
    dsPetitionsdosdescription.Size := 127;
    dsPetitionsdosstatus.SetParentComponent(dsPetitions);
    dsPetitionsdosstatus.Name := 'dsPetitionsdosstatus';
    dsPetitionsdosstatus.FieldName := 'dosstatus';
    dcPetitions.SetParentComponent(Self);
    dcPetitions.Name := 'dcPetitions';
    dcPetitions.Columns.Clear;
    with dcPetitions.Columns.Add do
    begin
      FieldName := 'dosdescription';
      Title := 'Description';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcPetitions.Columns.Add do
    begin
      FieldName := 'doscreatedon';
      Title := 'Created On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcPetitions.Columns.Add do
    begin
      FieldName := 'dosstatus';
      Title := 'Close Status';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcPetitionsstatusGetValue');
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcPetitions.Columns.Add do
    begin
      FieldName := 'dosclosedon';
      Title := 'Closed On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcPetitions.Columns.Add do
    begin
      FieldName := 'dosid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{dosid}}/identity';
    end;
    dcPetitions.DataSet := dsPetitions;
    dcPetitions.Language := lEnglish;
    dcPetitions.IsResponsive := False;
    dcPetitions.GridID := 'grdPetitions';
    dcPetitions.UseFieldIndex := True;
    dcPetitions.ShowSearch := False;
    dcPetitions.ShowNumberOfEntries := False;
    dcPetitions.ShowEntriesInfo := False;
    dcPetitions.Paginate := True;
    dcPetitions.CalculateTableWidth := True;
    dcPetitions.Left := 32;
    dcPetitions.Top := 112;
  finally
    alForm.AfterLoadDFMValues;
    dsPetitions.AfterLoadDFMValues;
    dsPetitionsdosid.AfterLoadDFMValues;
    dsPetitionsdoscreatedon.AfterLoadDFMValues;
    dsPetitionsdosclosedon.AfterLoadDFMValues;
    dsPetitionsdosdescription.AfterLoadDFMValues;
    dsPetitionsdosstatus.AfterLoadDFMValues;
    dcPetitions.AfterLoadDFMValues;
  end;
end;

end.