unit Forms.ChangePassword;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Controls, WEBLib.Actions, Types;

type
  TfrmChangePassword = class(TfrmBase)
    procedure DoPassResetClick(Sender: TObject; Element: TJSHTMLElementRecord;
        Event: TJSEventParameter);
    procedure DoPwdChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
  private
    FKey : String;
    procedure CheckSamePwd;
  public
    procedure setParams(const Params: TStrings); override;
    Class Function NeedsLogin : Boolean; override;
    Class Function NeedsParams : Boolean; override;
    Class Function MyRoutes : TStringDynArray; override;
  protected procedure LoadDFMValues; override; end;

var
  frmResetPassword: TfrmChangePassword;

implementation

Uses Units.ActionUtils, Modules.Server, rosdk, units.strings;

{$R *.dfm}

procedure TfrmChangePassword.CheckSamePwd;

Var
  aNewPassword,aConfirmPasswd: String;
begin
  aNewPassword:=alForm['edtPassword1'].Value;
  if Length(aNewPassword)<8 then
    DisplayError('edtPassword1','',SPasswordMinLength,False)
  else
    begin
    aConfirmPasswd:=alForm['edtPassword2'].Value;
    if (aNewPassword<>aConfirmPasswd) and (aConfirmPasswd<>'') then
      DisplayError('edtPassword2','',SPasswordsMustMatch,False)
    else
      ClearFormErrors;
    end;
end;

procedure TfrmChangePassword.DoPwdChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  ClearFormErrors;
  CheckSamePwd;
end;


class function TfrmChangePassword.MyRoutes: TStringDynArray;

begin
  Result:=[
    'changepassword/:KEY',
    'changepassword/:KEY/*',
    'changepassword'
  ];
end;

procedure TfrmChangePassword.DoPassResetClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);

  Procedure DoResetFailed(aResponse: TJSObject; aFail: TJSError);
  begin
    dmServer.ShowError(ExtractErrorMsg(aFail,SErrPasswordChangeFailed));
  end;

  Procedure DoResetOK(aResult : string);
  begin
    if aResult='' then
     dmServer.ShowOK(SPasswordChangeSuccess)
    else
     dmServer.ShowError(Format(SErrPasswordChangeFailed, [aResult]));
  end;

var
  aNewPassword, aKey : String;
  UsePWD : Boolean;
begin
  aNewPassword:=alForm['edtPassword1'].Value;
  if aNewPassword<>alForm['edtPassword2'].Value then
    begin
    dmServer.ShowError(SPasswordsMustMatch);
    exit;
    end;
  aKey:=FKey;
  UsePWD:=aKey='';
  if UsePWD then
    akey:=alForm['edtCurrentPassword'].Value;
  dmServer.LoginService.SetPassword(aKey,aNewPassword,UsePWD,@DoResetOK,@DoResetFailed);
end;

class function TfrmChangePassword.NeedsLogin: Boolean;
begin
  Result:=False;
end;

class function TfrmChangePassword.NeedsParams: Boolean;
begin
  Result:=True;
end;

procedure TfrmChangePassword.setParams(const Params: TStrings);
begin
  Inherited;
  FKey:=Params.Values['Key'];
  if (Pos('%7B', UpperCase(FKey)) = 1) and (Pos('%7D', UpperCase(FKey)) = Length(FKey) - 2 ) then
    FKey := '{' + Copy(FKey, 4, Length(FKey) - 6 )+ '}';
end;

procedure TfrmChangePassword.WebFormShow(Sender: TObject);
begin
  if (FKey<>'') then
    alForm['btnReset'].RemoveClass('d-none')
  else
    if (dmServer.UserID>0) then
      alForm['edtCurrentPassword'].RemoveClass('d-none')
    else
      alForm['btnReset'].AddClass('d-none');
end;

procedure TfrmChangePassword.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnReset';
      Name := 'btnReset';
      SetEvent(Self, 'OnExecute', 'DoPassResetClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heInput;
      ID := 'edtPassword1';
      Name := 'edtPassword1';
      SetEvent(Self, 'OnExecute', 'DoPwdChange');
    end;
    with alForm.Actions.Add do
    begin
      Event := heInput;
      ID := 'edtPassword2';
      Name := 'edtPassword2';
      SetEvent(Self, 'OnExecute', 'DoPwdChange');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtCurrentPassword';
      Name := 'edtCurrentPassword';
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
