unit Forms.Error;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, Types;

type
  TfrmError = class(TfrmBase)
  private
    { Private declarations }
  public
    class function MyRoutes: TStringDynArray; override;
  protected procedure LoadDFMValues; override; end;

var
  frmError: TfrmError;

implementation

{$R *.dfm}
{ TfrmError }

class function TfrmError.MyRoutes: TStringDynArray;
begin
  Result:=['*'];
end;

procedure TfrmError.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.
