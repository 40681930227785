unit Forms.CSR.Debt;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Debt, Data.DB, Datasnap.DBClient,
  pas2web.dadataset, pas2web.datatables, WEBLib.Actions;

type
  TfrmCSRDebt = class(TfrmDebt)
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRDebt: TfrmCSRDebt;

implementation

{$R *.dfm}

{ TfrmCSRDebt }

function TfrmCSRDebt.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRDebt.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRDebt.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRDebt.WebFormCreate(Sender: TObject);
begin
  Inherited;
  dcDebts.Columns[7].ButtonURL:='#/CSR/{{debdossierfk}}/debt/edit/{{debid}}';
  dcDebts.Columns[8].ButtonURL:='#/CSR/{{debdossierfk}}/debt/delete/{{debid}}';
end;

procedure TfrmCSRDebt.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcDebts.BeforeLoadDFMValues;
  try
    dcDebts.Columns.Clear;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debcreditor';
      Title := 'Creditor';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debdebttype';
      Title := 'Debt Type';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debstarton';
      Title := 'Start On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'deboriginalamount';
      Title := 'Original Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debcurrentamount';
      Title := 'Current Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,##0.00';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debguarantor';
      Title := 'Guarantor';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debiscompleted';
      Title := 'Is Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'disabled';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{debdossierfk}}/debt/edit/{{debid}}';
    end;
    with dcDebts.Columns.Add do
    begin
      FieldName := 'debdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{debdossierfk}}/debt/delete/{{debid}}';
    end;
  finally
    dcDebts.AfterLoadDFMValues;
  end;
end;

end.