unit Forms.CSR.Revenue.Income;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Revenue.Income,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  WEBLib.Actions;

type
  TfrmCSRRevenueIncome = class(TfrmRevenueIncome)
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection: TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRRevenueIncome: TfrmCSRRevenueIncome;

implementation

{$R *.dfm}
{ TfrmCSRRevenueIncome }

function TfrmCSRRevenueIncome.DossierConnection: TP2WDAConnection;
begin
  Result := CSRConnection;
end;

function TfrmCSRRevenueIncome.DossierPrefix: string;
begin
  Result := 'CSR';
end;

class function TfrmCSRRevenueIncome.GetHTMLFileName: string;
begin
  Result := FixCSRHTMLFile(inherited GetHTMLFileName);
end;

procedure TfrmCSRRevenueIncome.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.
