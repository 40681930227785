unit Forms.Base;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Dialogs,
  WEBLib.Forms,
  Units.FormManager,
  WEBLib.Actions,
  Data.DB,
  WEBLib.Storage,
  libjquery,
  Units.Params,
  strutils,
  Units.Logging,
  WebRouter,
  Units.DocumentBox,
  Modules.Server,
  Units.ConfirmDialog;

const
  // aliases so we don't need to include Units.Logging in descendents
  ltInfo = Units.Logging.ltInfo;
  ltSQL = Units.Logging.ltSQL;
  ltTrace = Units.Logging.ltTrace;
  ltCall = Units.Logging.ltCall;
  ltError = Units.Logging.ltError;
  ltDebug = Units.Logging.ltDebug;

type
  // alias so we don't need to include Units.Logging in descendents
  TLogType = Units.Logging.TLogType;

  TCrudOperation = (coRead, coNew, coEdit, coDelete, coCopy);
  TPetitionDetailSaveType = (pstApplicant, pstPartner, pstMinorChild, pstSameHouseAdult, pstSeperateSpouse, pstIncome, pstDebtor, pstRealestate, pstPossession, pstDebt, pstExpense, pstSalaryTransfer,
    pstLegalCase, pstDebtMediator, pstStatement, pstClosePetition);

  TOnFailCallBack = reference to procedure(error: string);

  TSaveDataEvent = reference to procedure(SaveType: TPetitionDetailSaveType; Params: THTMLFormParams; onErrorCallback: TOnFailCallBack);
  TReadOnlyCallback = reference to procedure(aPetitionID: NativeInt; aReadonly: Boolean);

  TForm = TBaseForm;

  TfrmBase = class(TForm)
    alForm: TElementActionList;
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject); virtual;
    procedure DoClearError(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    FFormIsReadOnly: Boolean;
    FDocumentBox: TDocumentbox;
    FSaveDataEvent: TSaveDataEvent;
    FLastTick: TDateTime;
    FDefaultConfirmation: TConfirmDialog;
    // message handling procedures and vars
    FTimerId: NativeInt;
    FDiscardChanges: Boolean;
    FDiscardFiles: Boolean;
    FPrintJobID: NativeInt;
    FPrintTimerID: NativeInt;
    FPrintJobDoneEvent: TNotifyEvent;
    procedure startTimer;
    procedure stopTimer;
    procedure checkMessages;

    procedure startPrinterTimer;
    procedure stopPrinterTimer;
    procedure onPrinterTimer;
  protected
    // Fill a combobox with a parameter list
    procedure FillParamCombobox(const aElName, aParamType: string; aExclude: array of string = []);
    // Fill a radiolist with a parameter list
    procedure FillRadioListBlock(const aElName, aGroupName, aParamType: string; aSelected, aDefault: string; aLimit: array of string);
    // Called for all datasets, sets OnLoadFail handler if not set
    procedure SetupDataset(aDataset: TDataset); virtual;
    // Return true if EmailAddress is a correct email address.
    function emailIsValid(EmailAddress: string): Boolean;
    // Return true if zip code is 4 or 5 characters and only contains numbers
    function zipCodeIsValid(zipCode: string): Boolean;
    // Return true if value only contains numbers
    function StringIsNumeric(const value: string): Boolean;
    // Return true if value only contains correct username characters (alphanumerical and _ @ . #);
    function UserNameIsValid(const value: string): Boolean;
    // Return true if value only contains a correctly formatted national number.
    function NationalIDFormatIsValid(const value: string): Boolean;
    // Quick check whether value contains birthday as start.
    function NationalIDBirthDateIsValid(const value: string; birthDay: TDate = 0): Boolean;
    // Quick check whether value contains birthday has a correct checksum
    function NationalIDCCIsValid(const value: string): Boolean;
    // Check if Value is a correct date
    function DateStringIsValid(const value, AFormat: string; ADateSeparator: char): Boolean;
    // Check if a value for radiogroup with given name is selected. If not and ShowError is false, show error message.
    function isSelectedRadioGroup(const groupName: string; showError: Boolean = True): Boolean;
    // Get value of radiogroup with given name
    function getRadioGroupValue(const groupName: string): string;
    // set selected element of radiogroup with given name to element with given Value
    procedure setRadiogroupSelectedElement(const groupName, value: string);
    // Set value of combobox with id ElmID to value.
    procedure setComboboxValue(const elmId, value: string);
    // return true if alForm[aName] element with given name
    function FieldIsEmpty(const aName: string; showError: Boolean = True): Boolean;
    // Return a string "Field aField is invalid"  display this error in aIdent if given.
    function FieldIsInvalid(const aField: string; aIdent: string = ''): string;
    // Mark element as valid (add CSS 'is-valid', remove CSS 'is-invalid')
    procedure SetValid(const aElement: TJSHTMLElement; const IsValid: Boolean); overload;
    // Mark alForm[element] as valid (add CSS 'is-valid', remove CSS 'is-invalid')
    procedure SetValid(const aElement: string; const IsValid: Boolean); overload;
    // Display error message for input tag with id Ident, show existing error message element with error class errorClass.
    // errorClass defaults to invalid-feedback
    // If Message is given, use the text as error text.
    // if UseCol is true, additionally search for col- as tag enclosing the input tag.
    procedure DisplayError(const ident: string; errorClass: string = ''; aMessage: string = ''; UseCol: Boolean = True);
    // Undo the effect of DisplayError.
    // if no error classes are given then invalid-feedback and incorrect-value are used.
    procedure HideError(const ident: string; errorClasses: array of string; UseCol: Boolean = True);
    // use non-empty value in input element with id zipElmId to execute server call to search for city.
    // if there is a result, set input element with cityElmId to corresponding city based.
    // if there is no result, mark postcode as invalid.
    procedure setCityName(const zipElmId, cityElmId: string);
    // Mark elements zipElmId and cityElmId as forming a pair for which postcode lookup must be performed.
    procedure bindZipLookup(const zipElmId, cityElmId: string);
    // Clear all form errors (classes invalid-feedback, incorrect-value)
    procedure ClearFormErrors;
    // Check if alForm[aName] contains a positive numeric value and returns the result of the check.
    // If showError is true, classes invalid-feedback is enabled
    function isNumeric(const aName: string; showError: Boolean = True): Boolean;
    // Check if input with ID elmId contains a value.
    function isCheckBoxChecked(const elmId: string): Boolean;
    // Enable or disable HTML element with ID elementId
    procedure SetElementEnabled(const elementId: string; isEnabled: Boolean);
    // Show or hide HTML element with ID elementId
    procedure SetElementVisible(const elementId: string; isVisible: Boolean);
    // Add a key-up listener to element with elementID
    procedure addKeyUpListener(const elementId: string; callback: TEmptyCallback);
    // Get user language as numerical value (0=EN, 1=NL, 2=FR)
    function GetLanguage: Integer;
    // Override TMS webcore behaviour ?
    function HandleKeyDown(Event: TJSKeyBoardEvent): Boolean; override;
    // When removeFormat is false, format number as NNNNNN.NNN.NN and return result.
    // When removeFormat is true, remove all . and - from number and return result.
    function reformatNationalNo(const number: string; RemoveFormat: Boolean = False): string;
    // Check whether S is one of New,Edit,delete
    function GetCrudUperation(S: string): TCrudOperation;

    // Log to Log.Log with indicated type, method and message. Current form classname is also passed on.
    procedure DoLog(aType: TLogType; const aMethod, aMessage: string); overload;
    procedure DoLog(aType: TLogType; const aMethod, AFormat: string; Args: array of const); overload;
    function ValidityFromToDate(aDate: string; IsStart: Boolean): TDateTime;
    function ValidityFromToJSDate(aDate: string; IsStart: Boolean): TJSDate;
    // Standard data-loading fail error message.
    procedure DoDataLoadFail(DataSet: TDataset; ID: Integer; const ErrorMsg: string); virtual;
    // Check result of resolve operation: Return true if no errors occurred.
    // If errors occured, call ShowError with collected error messages and return false.
    // If aOnSuccessURL is given, and no errors occured navigate browser to given URL
    // If aOnSuccessProcedure is given, and no errors occured, call AOnSuccessProcedure.
    function CheckResolveResults(Info: TResolveResults): Boolean; overload;
    function CheckResolveResults(Info: TResolveResults; AOnSuccessURL: string): Boolean; overload;
    function CheckResolveResults(Info: TResolveResults; AOnSuccessProcedure: TEmptyCallback): Boolean; overload;
    // check if the string contains only letters
    function isLetters(value: string): Boolean;
    // check if the passed value is correctly formatted structured message
    function isValidStructuredMessage(ident, value: string): Boolean;

    // procedure to handle report printing process
    procedure HandlePrintJob(jobId: NativeInt);
    procedure nop;
  public

    // Override this to let HandleRoute call SetParams.
    procedure HandleRoute(URl: string; aRoute: TRoute; Params: TStrings; IsReroute: Boolean); override;
    // override this to allow or disable route naviagation
    procedure canHandleRoute(previousURL: string; var allow: Boolean); override;
    // Call setparams if NeedsParams is true.
    class function NeedsParams: Boolean; virtual;
    // Mark form as read-only: all edit elements are disabled.
    procedure MakeReadonly; virtual;
    // Disable upload boxes
    procedure DisableUploadBoxes;
    // Check if the petition is read-only. If yes, call aCallback (asynchronously)
    procedure CheckReadOnly(aDossierID: NativeInt; aCallback: TReadOnlyCallback = nil);
    // Show error message. (calls dmServer.ShowError)
    procedure showError(const Msg: string); virtual;
    // Show success message. (calls dmServer.ShowOK). If message is empty, show 'Data is saved successfully'
    procedure ShowSuccess(const aMsg: string = ''); virtual;
    // For debugging purposes: Write timer tick to console : elapsed time since login.
    procedure TimerTick(const Msg: string);
    // Set params detected in route
    procedure setParams(const Params: TStrings); virtual;
    // Callback when data was saved. Not used in this form.
    property OnSave: TSaveDataEvent read FSaveDataEvent write FSaveDataEvent;
    // Currently selected language as integer (0=EN, 1=NL, 2=FR)
    property Language: Integer read GetLanguage;
    // Default document box
    property DefaultDocumentBox: TDocumentbox read FDocumentBox;
    // Default confirmation dialog
    property DefaultConfirmation: TConfirmDialog read FDefaultConfirmation;
    // Was the form marked read-only ?
    property FormIsReadOnly: Boolean read FFormIsReadOnly;
    // this value is used to specify whether to discard changes or not
    property DiscardChanges: Boolean read FDiscardChanges write FDiscardChanges;
    // ignore added files
    property DiscardFiles: Boolean read FDiscardFiles write FDiscardFiles;
    // procedure to bind change event to all form elements
    procedure BindOnChangeEvent;
    // procedure is called when a contact is changed in contact module
    procedure OnContactChanged(Sender: TObject); virtual;
    // print job is done
    property OnPrintJobDone: TNotifyEvent read FPrintJobDoneEvent write FPrintJobDoneEvent;
  protected procedure LoadDFMValues; override; end;

  TBaseFormClass = class of TfrmBase;

implementation

uses
  pas2web.dadatasethelper,
  pas2web.datatables,
  DateUtils,
  Units.ActionUtils,
  lib.formtranslation,
  Units.Strings,
  Units.ServerConfig,
  Units.Types,
  Units.HTMLUtils,
  Units.Pagehandler,
  pas2web.dadataset,
  Units.DADatasetUtils,
  lib.bootstrap,
  lib.jqueryhelpers,
  Units.Service.Dezq;

{$R *.dfm}

procedure TfrmBase.WebFormDestroy(Sender: TObject);
begin
  if Assigned(DefaultDocumentBox) then
  begin
    document.dispatchEvent(TJSEvent.new('ClearList'));
  end;
  stopTimer;
  stopPrinterTimer;
end;

procedure TfrmBase.WebFormCreate(Sender: TObject);
var
  I: Integer;
begin
  FDocumentBox := TDocumentbox.Create(Self);
  FDefaultConfirmation := TConfirmDialog.Create(Self);
  FDiscardChanges := True;
  FDiscardFiles := False;
  if FormTranslator.Language <> '' then
  begin
    FormTranslator.EnableInformation := True;
    FormTranslator.TranslateForm(Self);
  end;

  // jQuery('[data-toggle="popover"]').popover;

  // TODO setup language param for dossier
  for I := 0 to ComponentCount - 1 do
  begin
    if Components[I] is TDataset then
      SetupDataset(Components[I] as TDataset);
  end;

  FTimerId := -1;
  if not ServerConfig.DoSkipCheckMessageTimer then
  begin
    checkMessages; // initial call
    startTimer;
  end;

  Server.SetLocalData('LastCall', InttoStr(DateTimeToUnix(now)));

  for I := 0 to alForm.Actions.Count - 1 do
  begin
    alForm.Actions[I].StopPropagation := False;
  end;

  FPrintJobID := -1;;
  FPrintTimerID := -1;
end;

procedure TfrmBase.SetupDataset(aDataset: TDataset);
begin
  if aDataset.OnLoadFail = nil then
    aDataset.OnLoadFail := @DoDataLoadFail;
end;

procedure TfrmBase.addKeyUpListener(const elementId: string; callback: TEmptyCallback);
var
  el: TJSElement;
begin
  el := document.getElementById(elementId);
  if Assigned(el) then
    el.addEventListener('keyup', callback);
end;

procedure TfrmBase.BindOnChangeEvent;

  function OnDataChange(Evt: TEventListenerEvent): Boolean;
  begin
    DiscardChanges := False;
  end;

begin
  jQuery('select').On_('change', @OnDataChange);
  jQuery('input, textarea').On_('keyup', @OnDataChange);
end;

procedure TfrmBase.bindZipLookup(const zipElmId, cityElmId: string);
var
  el: TJSElement;

  procedure onKeyUp;
  begin
    setCityName(zipElmId, cityElmId);
  end;

begin
  el := document.getElementById(zipElmId);
  el.addEventListener('keyup', @onKeyUp);
  document.getElementById(cityElmId).setAttribute('data-cid', '0');
end;

procedure TfrmBase.canHandleRoute(previousURL: string; var allow: Boolean);
begin
  allow := DiscardChanges;
end;

procedure TfrmBase.checkMessages;

  procedure OnSuccess(aResult: TMessageStatistics);
  var
    html: string;
  begin
    jQuery('#mesCount').removeClass('d-none');
    if aResult.UnreadCompany + aResult.UnreadUser > 0 then
      jQuery('#mesCount').text(InttoStr(aResult.UnreadCompany + aResult.UnreadUser))
    else
      jQuery('#mesCount').text('');

    html := '<div class="dropdown-arrow"></div>' + '<h6 class="dropdown-header"><a href="#/Messages/company">' + Format(SCompanyUnreadMessages, [InttoStr(aResult.UnreadCompany)]) + '</a></h6>';
    if aResult.LastCompanyMessage <> '' then
    begin
      html := html + '<div class="perfect-scrollbar mt-2">' + '<div class="user-avatar" style="vertical-align:top;"><img style="margin-left: 0.75rem;" src="assets/images/mes.png" alt=""></div>' +
        '<div class="dropdown-item-body" style="width:90%;display:inline-block; padding-left: 1.5rem;padding-right: 1rem;">' + '<p class="text">' + aResult.LastCompanyMessage + '</p></div>' +
        '</div>';
    end;
    html := html + '<h6 class="dropdown-header"><a href="#/Messages/user">' + Format(SUserUnreadMessages, [InttoStr(aResult.UnreadUser)]) + '</a></h6>';
    if aResult.LastUserMessage <> '' then
    begin
      html := html + '<div class="perfect-scrollbar mt-2">' + '<div class="user-avatar" style="vertical-align:top;"><img style="margin-left: 0.75rem;" src="assets/images/mes.png" alt=""></div>' +
        '<div class="dropdown-item-body" style="width:90%;display:inline-block; padding-left: 1.5rem;padding-right: 1rem;">' + '<p class="text">' + aResult.LastUserMessage + '</p></div>' + '</div>';
    end;

    jQuery('#mesCount').closest('li').find('div.dropdown-menu').html(html);

  end;

  procedure OnFail(aSuccess: Boolean; anError: string);
  begin
    // do nothing
  end;

begin
  if PageHandler.LoggedIn then
    Server.GetMessagesStats(incDay(now, -31), incDay(now, 1), @OnSuccess, @OnFail)
end;

procedure TfrmBase.CheckReadOnly(aDossierID: NativeInt; aCallback: TReadOnlyCallback);

  procedure ReadonlyResult(Sender: TObject; aCallDossierID: NativeInt; aReadonly: Boolean);
  begin
    if (aCallDossierID = aDossierID) then
    begin
      if aReadonly then
        MakeReadonly;
      if Assigned(aCallback) then
        aCallback(aCallDossierID, aReadonly);
    end;
  end;

begin
  dmServer.CheckPetitionReadOnly(aDossierID, @ReadonlyResult);
end;

function TfrmBase.CheckResolveResults(Info: TResolveResults): Boolean;
begin
  Result := dmServer.CheckResolveResults(Info);
end;

function TfrmBase.CheckResolveResults(Info: TResolveResults; AOnSuccessProcedure: TEmptyCallback): Boolean;
begin
  Result := dmServer.CheckResolveResults(Info, AOnSuccessProcedure);
end;

function TfrmBase.CheckResolveResults(Info: TResolveResults; AOnSuccessURL: string): Boolean;
begin
  Result := CheckResolveResults(Info);
  if Result then
    window.location.href := AOnSuccessURL;
end;

procedure TfrmBase.ClearFormErrors;
begin
  jQuery('.invalid-feedback').css('display', 'none');
  jQuery('.incorrect-value').css('display', 'none');
end;

function TfrmBase.DateStringIsValid(const value, AFormat: string; ADateSeparator: char): Boolean;
begin
  Result := True;
  try
    StrToDate(value, AFormat, ADateSeparator);
  except
    Result := False;
  end;
end;

procedure TfrmBase.HideError(const ident: string; errorClasses: array of string; UseCol: Boolean = True);
begin
  Units.HTMLUtils.HideError(ident, errorClasses, UseCol);
end;

procedure TfrmBase.DisplayError(const ident: string; errorClass, aMessage: string; UseCol: Boolean = True);
var
  erClass: string;
begin
  if errorClass = '' then
    erClass := 'invalid-feedback'
  else
    erClass := errorClass;
  if UseCol then
    jQuery('#' + ident).closest('div[class^="col-"]').find('.' + erClass).css('display', 'block');
  jQuery('#' + ident).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'block');
  if aMessage <> '' then
  begin
    if UseCol then
      jQuery('#' + ident).closest('div[class^="col-"]').find('.' + erClass).text(aMessage);
    jQuery('#' + ident).closest('div[class^="form-group"]').find('.' + erClass).text(aMessage);
  end;
  jQuery('li.step.active').addClass('error');
end;

procedure TfrmBase.DoClearError(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if Element.Element.ID = '' then
    exit;
  jQuery('#' + Element.Element.ID).closest('div[class^="col-"]').find('.invalid-feedback').css('display', 'none');
  jQuery('#' + Element.Element.ID).closest('div[class^="form-group"]').find('.invalid-feedback').css('display', 'none');

  if Element.Element.getAttribute('type') = 'radio' then
  begin
    jQuery('#' + Element.Element.ID).closest('div[class^="form-row"]').find('.invalid-feedback').css('display', 'none');
    jQuery('#' + Element.Element.ID).closest('div[class^="form-row"]').find('.incorrect-value').css('display', 'none');
  end;
  // jQuery('#' + Element.element.id).parent().find('.invalid-feedback').css('display', 'none');
  // jQuery('#' + Element.element.id).parent().find('.incorrect-value').css('display', 'none');
end;

procedure TfrmBase.DoDataLoadFail(DataSet: TDataset; ID: Integer; const ErrorMsg: string);
var
  Msg, aDetail, aName: string;
begin
  aName := DataSet.Name;
  if aName = '' then
    aName := DataSet.ClassName;
  if DataSet is TP2WDADataset then
    aName := aName + ' (' + TP2WDADataset(DataSet).TableName + ')';
  aDetail := '(Detail: ' + ErrorMsg + ')';
  Msg := Format(SErrFailedToLoadDataset, [aName, aDetail]);
  log.log(ltError, ClassName, 'DoDataLoadFail', Msg);
  dmServer.showError(Msg);
end;

procedure TfrmBase.DoLog(aType: TLogType; const aMethod, AFormat: string; Args: array of const);
begin
  log.log(aType, ClassName, aMethod, AFormat, Args);
end;

procedure TfrmBase.DoLog(aType: TLogType; const aMethod, aMessage: string);
begin
  log.log(aType, ClassName, aMethod, aMessage);
end;

function TfrmBase.emailIsValid(EmailAddress: string): Boolean;

  function CheckAllowed(const S: string): Boolean;
  var
    I: Integer;
  begin
    Result := False;
    for I := 1 to Length(S) do
    begin
      // illegal char - no valid address
      if not(S[I] in ['a' .. 'z', 'A' .. 'Z', '0' .. '9', '_', '-', '.', '+']) then
        exit;
    end;
    Result := True;
  end;

var
  I: Integer;
  namePart, serverPart: string;
begin
  Result := False;

  I := Pos('@', EmailAddress);
  if (I = 0) then
    exit;

  if (Pos('..', EmailAddress) > 0) or (Pos('@@', EmailAddress) > 0) or (Pos('.@', EmailAddress) > 0) then
    exit;

  if (Pos('.', EmailAddress) = 1) or (Pos('@', EmailAddress) = 1) then
    exit;

  namePart := Copy(EmailAddress, 1, I - 1);
  serverPart := Copy(EmailAddress, I + 1, Length(EmailAddress));
  if (Length(namePart) = 0) or (Length(serverPart) < 5) then
    exit; // too short

  I := Pos('.', serverPart);
  // must have dot and at least 3 places from end
  if (I = 0) or (I > (Length(serverPart) - 2)) then
    exit;

  Result := CheckAllowed(namePart) and CheckAllowed(serverPart);
end;

function TfrmBase.FieldIsEmpty(const aName: string; showError: Boolean): Boolean;
begin
  Result := False;
  if Trim(alForm[aName].value) = '' then
  begin
    if showError then
      DisplayError(alForm[aName].ID);
    Result := True;
  end
  else
    if showError then
      HideError(alForm[aName].ID, []);
end;

function TfrmBase.FieldIsInvalid(const aField: string; aIdent: string): string;
begin
  Result := Format(npeFieldIsInvalid, [aField]);
  if aIdent <> '' then
    DisplayError(aIdent, '', Result)
end;

function TfrmBase.GetCrudUperation(S: string): TCrudOperation;
begin
  case IndexText(LowerCase(S), ['new', 'edit', 'delete', 'copy']) of
    0:
      Result := coNew;
    1:
      Result := coEdit;
    2:
      Result := coDelete;
    3:
      Result := coCopy;
  else
    Result := coRead;
  end;
end;

function TfrmBase.GetLanguage: Integer;
begin
  Result := TLanguage.FromString(dmServer.UserLanguage).ToInteger;
end;

function TfrmBase.getRadioGroupValue(const groupName: string): string;
begin
  Result := string(jQuery('input[name="' + groupName + '"]:checked').val());
  if Result = 'undefined' then
    Result := '';
end;

function TfrmBase.HandleKeyDown(Event: TJSKeyBoardEvent): Boolean;
begin
  Result := True;
  //
end;

procedure TfrmBase.HandlePrintJob(jobId: NativeInt);
begin
  FPrintJobID := jobId;
  startPrinterTimer;
end;

procedure TfrmBase.HandleRoute(URl: string; aRoute: TRoute; Params: TStrings; IsReroute: Boolean);
begin
  inherited;
  if NeedsParams then
    setParams(Params);
end;

function TfrmBase.NationalIDBirthDateIsValid(const value: string; birthDay: TDate): Boolean;
var
  myYear, myMonth, myDay: Word;
begin
  Result := NationalIDFormatIsValid(value);
  if not Result then
    exit;
  if birthDay > 0 then
  begin
    DecodeDate(birthDay, myYear, myMonth, myDay);
    if StrToInt(Copy(value, 1, 2)) <> myYear mod 100 then
    begin
      Result := False;
      exit;
    end;
    if StrToInt(Copy(value, 4, 2)) <> myMonth then
    begin
      Result := False;
      exit;
    end;
    if StrToInt(Copy(value, 7, 2)) <> myDay then
    begin
      Result := False;
      exit;
    end;
  end
  else
  begin
    try
      // try to convert data to TDateTime in case exception is raised the date is not valid
      EncodeDate(StrToInt('19' + Copy(value, 1, 2)), // year is 2 digit make it 4
        StrToInt(Copy(value, 4, 2)), // month
        StrToInt(Copy(value, 7, 2)) // day
        );
    except
      Result := False;
      exit;
    end;
  end;
end;

function TfrmBase.NationalIDCCIsValid(const value: string): Boolean;
var
  sNumber: string;
begin
  Result := NationalIDFormatIsValid(value);
  if not Result then
    exit;
  sNumber := StringReplace(StringReplace(value, '.', '', [rfReplaceAll]), '-', '', [rfReplaceAll]);
  Result := (StrToInt(sNumber) div 97) = 1;
end;

function TfrmBase.NationalIDFormatIsValid(const value: string): Boolean;
begin
  // number format YY.MM.DD-NNN.CC
  Result := True;
  if (value[3] <> '.') or (value[6] <> '.') or (value[9] <> '-') or (value[13] <> '.') then
  begin
    Result := False;
    exit;
  end;
  if not StringIsNumeric(StringReplace(StringReplace(value, '.', '', [rfReplaceAll]), '-', '', [rfReplaceAll])) then
  begin
    Result := False;
    exit;
  end;
end;

class function TfrmBase.NeedsParams: Boolean;
begin
  Result := False;
end;

procedure TfrmBase.nop;
begin
  // no operation, placeholder
end;

procedure TfrmBase.OnContactChanged(Sender: TObject);
begin
  DiscardChanges := False;
end;

procedure TfrmBase.onPrinterTimer;

  procedure OnResponse(Status: Int64; aSuccess: Boolean; anError: string);
  var
    link: TJSHTMLElement;
  begin
    if not aSuccess then
    begin
      Server.showError(anError);
      stopPrinterTimer;
    end
    else
    begin
      if Status = Ord(jqsFinishOK) then
      begin
        link := TJSHTMLElement(document.createElement('a'));
        link['href'] := Server.ServerURL + 'PDF/by-job/' + InttoStr(FPrintJobID) + '?SessionID=' + Server.ClientID;
        link['download'] := InttoStr(FPrintJobID) + '.pdf';
        link['target'] := '_blank';
        link.click();

        stopPrinterTimer;
        FPrintJobID := -1;
      end;
      if Status = Ord(jqsCancelled) then
      begin
        Server.showError(SErrReportCancelled);
        stopPrinterTimer;
        FPrintJobID := -1;
      end;
    end;
  end;

begin
  // check the status of job
  if FPrintJobID > -1 then
  begin
    Server.GetJobStatusEx(FPrintJobID, @OnResponse);
  end;
end;

function TfrmBase.reformatNationalNo(const number: string; RemoveFormat: Boolean): string;
begin
  Result := dmServer.reformatNationalNo(number, RemoveFormat);
end;

procedure TfrmBase.setCityName(const zipElmId, cityElmId: string);
var
  zip: string;

  procedure OnGetCityResult(aResult: string; anID: Int64);
  begin
    alForm[cityElmId].value := aResult;
    jQuery('#' + cityElmId).attr('data-cid', InttoStr(anID));
    if Trim(alForm[cityElmId].value) <> '' then
      jQuery('#' + cityElmId).parent().find('.invalid-feedback').css('display', 'none');
  end;

begin
  zip := Trim(alForm[zipElmId].value);
  if (zip = '0') or (zip = '') then
    jQuery('#' + cityElmId).parent().find('.invalid-feedback').css('display', 'none')
  else
    if zipCodeIsValid(zip) then
      Server.getCityFromZip(zip, @OnGetCityResult);
end;

procedure TfrmBase.setComboboxValue(const elmId, value: string);
begin
  jQuery('#' + elmId).find('option[value="' + value + '"]').prop('selected', True);
end;

procedure TfrmBase.SetElementEnabled(const elementId: string; isEnabled: Boolean);
begin
  jQuery('#' + elementId).prop('disabled', not isEnabled)
end;

procedure TfrmBase.setParams(const Params: TStrings);
begin
  // empty implementation
end;

procedure TfrmBase.setRadiogroupSelectedElement(const groupName, value: string);
begin
  jQuery('input[name="' + groupName + '"]').prop('checked', False);
  jQuery('input[name="' + groupName + '"][value="' + value + '"]').prop('checked', True);
end;

procedure TfrmBase.SetElementVisible(const elementId: string; isVisible: Boolean);
begin
  if isVisible then
    jQuery('#' + elementId).css('display', '')
  else
    jQuery('#' + elementId).css('display', 'none');
end;

procedure TfrmBase.SetValid(const aElement: string; const IsValid: Boolean);
begin
  SetValid(TJSHTMLElement(alForm[aElement].ElementHandle), IsValid);
end;

procedure TfrmBase.showError(const Msg: string);
begin
  dmServer.showError(Msg);
end;

procedure TfrmBase.ShowSuccess(const aMsg: string = '');
var
  OKMsg: string;
begin
  OKMsg := aMsg;
  if OKMsg = '' then
    OKMsg := SDataSavedOK;
  dmServer.ShowOK(SDataSavedOK);
end;

procedure TfrmBase.startPrinterTimer;
begin
  FPrintTimerID := window.setInterval(@onPrinterTimer, 1000);
end;

procedure TfrmBase.startTimer;
begin
  FTimerId := window.setInterval(@checkMessages, 160000);
end;

procedure TfrmBase.stopPrinterTimer;
begin
  if FPrintTimerID > -1 then
    window.clearInterval(FPrintTimerID);
  if Assigned(FPrintJobDoneEvent) then
    FPrintJobDoneEvent(Self);
end;

procedure TfrmBase.stopTimer;
begin
  if FTimerId > -1 then
    window.clearInterval(FTimerId);
end;

procedure TfrmBase.SetValid(const aElement: TJSHTMLElement; const IsValid: Boolean);
begin
  if IsValid then
  begin
    aElement.addClass('is-valid');
    aElement.removeClass('is-invalid');
  end
  else
  begin
    aElement.addClass('is-invalid');
    aElement.removeClass('is-valid');
  end;
end;

function TfrmBase.StringIsNumeric(const value: string): Boolean;
const
  CHARS = ['0' .. '9'];
var
  I: Integer;
begin
  Result := True;
  for I := 1 to Length(value) do
  begin
    if not(value[I] in CHARS) then
    begin
      Result := False;
      break;
    end;
  end;
end;

procedure TfrmBase.TimerTick(const Msg: string);
var
  N: TDateTime;
  S1: string;
begin
  N := now;
  S1 := InttoStr(MillisecondsBetween(N, Pagehandler.LoginTime));
  if FLastTick <> 0 then
    S1 := S1 + '/' + InttoStr(MillisecondsBetween(N, FLastTick));
  FLastTick := N;
  log.log(ltDebug, ClassName, 'TimerTick', '[%s] : %s', [S1, Msg]);
end;

function TfrmBase.UserNameIsValid(const value: string): Boolean;
const
  CHARS = ['0' .. '9', 'a' .. 'z', 'A' .. 'Z', '_', '@', '.', '#'];
var
  I: Integer;
begin
  Result := True;
  for I := 1 to Length(value) do
  begin
    if not(value[I] in CHARS) then
    begin
      Result := False;
      break;
    end;
  end;
end;

function TfrmBase.ValidityFromToDate(aDate: string; IsStart: Boolean): TDateTime;
begin
  aDate := Trim(aDate);
  if aDate <> '' then
    Result := JSDateToDateTime(TJSDate.new(aDate))
  else
    if IsStart then
      Result := NoStartDate
    else
      Result := NoEndDate;
end;

function TfrmBase.ValidityFromToJSDate(aDate: string; IsStart: Boolean): TJSDate;
begin
  aDate := Trim(aDate);
  if aDate <> '' then
    Result := TJSDate.new(aDate)
  else
    if IsStart then
      Result := DateTimeToJSDate(NoStartDate)
    else
      Result := DateTimeToJSDate(NoEndDate);
end;

function TfrmBase.isCheckBoxChecked(const elmId: string): Boolean;
begin
  Result := string(jQuery('#' + elmId + ':checked').val()) <> 'undefined';
end;

function TfrmBase.isLetters(value: string): Boolean;
const
  CHARS = ['a' .. 'z', 'A' .. 'Z'];
var
  I: Integer;
begin
  Result := True;
  for I := 1 to Length(value) do
  begin
    if not(value[I] in CHARS) then
    begin
      Result := False;
      break;
    end;
  end;
end;

function TfrmBase.isNumeric(const aName: string; showError: Boolean): Boolean;
var
  testAmount: Double;
begin
  testAmount := 0;
  Result := TryStrToFloat(alForm[aName].value, testAmount);
  Result := (testAmount > 0) and Result;
  if showError then
    if not Result then
      DisplayError(alForm[aName].ID, 'invalid-feedback')
    else
      HideError(alForm[aName].ID, ['invalid-feedback'])
end;

function TfrmBase.isSelectedRadioGroup(const groupName: string; showError: Boolean): Boolean;

const
  BlockNone: array [Boolean] of string = ('block', 'none');

begin
  Result := getRadioGroupValue(groupName) <> '';
  if showError then
    jQuery('input[name="' + groupName + '"]').parent().parent().parent().find('.invalid-feedback').css('display', BlockNone[Result]);
end;

function TfrmBase.isValidStructuredMessage(ident, value: string): Boolean;
begin
  Result := True;
  value := StringReplace(value, '+', '', [rfReplaceAll]);
  value := StringReplace(value, '/', '', [rfReplaceAll]);
  if Length(value) <> 12 then
    Result := False
  else
  begin
    if StrToIntDef(Copy(value, 1, 10), 0) mod 97 <> StrToIntDef(Copy(value, 11, 2), -1) then
    begin
      if (StrToIntDef(Copy(value, 1, 10), -1) mod 97 = 0) then
      begin
        if StrToIntDef(Copy(value, 11, 2), -1) <> 97 then
          Result := False;
      end
      else
        Result := False;
    end;
  end;
  if not Result then
    DisplayError(ident, '', SStructuredMessageError);
end;

procedure TfrmBase.MakeReadonly;
begin
  log.log(ltDebug, ClassName, 'MakeReadonly', 'Disabling form ' + ClassName);
  FFormIsReadOnly := True;
  jQuery('#form-parent textarea').prop('disabled', True);
  jQuery('#form-parent input').prop('disabled', True);
  jQuery('#form-parent button').prop('disabled', True);
  jQuery('#form-parent select').prop('disabled', True);
  jQuery('#form-parent a[data-action="add"]').prop('href', 'javascript:void(0)').addClass('disabled');
  jQuery('#form-parent a[role="download"]').Off('click');
  jQuery('#form-parent button[role="updateFile"]').prop('disabled', True);
  jQuery('#form-parent button[role="delete"]').prop('disabled', True);
  DefaultDocumentBox.FormIsReadOnly := True;
  window.SetTimeout(@DisableUploadBoxes, 200);
end;

procedure TfrmBase.DisableUploadBoxes;

begin
  jQuery('#fileupload-dropzone, .fileupload-dropzone').fileupload('disable');
end;

function TfrmBase.zipCodeIsValid(zipCode: string): Boolean;
begin
  Result := True;
  zipCode := Trim(zipCode);
  if (Length(zipCode) < 4) or (Length(zipCode) > 5) then
    Result := False
  else
    Result := StringIsNumeric(zipCode);
end;

procedure TfrmBase.FillParamCombobox(const aElName, aParamType: string; aExclude: array of string = []);

  function allowParam(p: TDezqParameter): Boolean;

  begin
    Result := (IndexText(p.Name, aExclude) = -1);
  end;

var
  aList: TDezqParameterArray;
  aParam: TDezqParameter;
  aHTML: string;

begin
  aHTML := '';
  aList := dmServer.GetParamList(aParamType);
  for aParam in aList do
    if allowParam(aParam) then
      with aParam do
        aHTML := aHTML + Format('<option data-id="%d" value="%s">%s</option>', [RecordID, name, value]);
  alForm[aElName].InnerHTML := aHTML
end;

procedure TfrmBase.FillRadioListBlock(const aElName, aGroupName, aParamType: string; aSelected: string = ''; aDefault: string = ''; aLimit: array of string = []);

const
  html = '<div class="custom-control custom-control-inline custom-radio">' + '  <input data-id="%d" type="radio" class="custom-control-input" name="%s" id="msp%s%d" %s value="%s"> ' +
    '  <label class="custom-control-label" for="msp%s%d">%s</label>' + '</div>';

var
  aList: TDezqParameterArray;
  aParam: TDezqParameter;
  aChecked, aHTML: string;
  aAction: TELementAction;

  function allowParam: Boolean;

  begin
    Result := (Length(aLimit) = 0) or (IndexText(aParam.Name, aLimit) <> -1);
  end;

begin
  aHTML := jQuery('#' + aElName).html;
  aList := dmServer.GetParamList(aParamType);
  for aParam in aList do
    if allowParam then
      with aParam do
      begin
        if (aSelected = name) or ((aSelected = '') and (aDefault <> '') and (name = aDefault)) then
          aChecked := 'checked=""'
        else
          aChecked := '';
        aHTML := aHTML + Format(html, [RecordID, aGroupName, aGroupName, RecordID, aChecked, name, aGroupName, RecordID, value]);
      end;
  // group: rdPMSystem
  // aElName: PartMarriageSystemBlock
  jQuery('#' + aElName).html(aHTML);
  aAction := alForm.Actions.Add;
  aAction.Name := aElName;
  aAction.Event := heNone;
  aAction.Selector := '#' + aElName + ' input[name="' + aGroupName + '"]';
  aAction.Bind;
end;

procedure TfrmBase.LoadDFMValues;
begin
  inherited LoadDFMValues;

  alForm := TElementActionList.Create(Self);

  alForm.BeforeLoadDFMValues;
  try
    Name := 'frmBase';
    Width := 640;
    Height := 480;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    alForm.SetParentComponent(Self);
    alForm.Name := 'alForm';
    alForm.Left := 40;
    alForm.Top := 24;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
