unit Units.DezqValidator;

interface

uses
  System.SysUtils;

resourcestring
  SErrMobileLength = 'Invalid mobile phone length: %s';
  SErrMobilePrefix = 'Invalid mobile phone prefix: %s';
  SErrInvalidPhoneChar = 'Invalid mobile phone character %s at pos %d in %s';

type
  TDezqValidator = class(TObject)
  public
    class function CheckMobileNumber(S: string): string;
    class function IsDigit(S: string; idx: Integer): Boolean;
    class function ValidMobileNumber(S: string): Boolean;
  end;

implementation

{ TDezqValidator }

class function TDezqValidator.CheckMobileNumber(S: string): string;
const
{$IFDEF PAS2JS}
  ValidPhoneChars: array [0 .. 16] of Char = ('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '.', '/', '(', ')',
    '-', '+');
{$ELSE}
  ValidPhoneChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '.', '/', '(', ')', '-', '+'];
{$ENDIF}
var
  I: Integer;
  R: string;
  intl: Boolean;
begin
  Result := '';

  for I := 1 to Length(S) do
    if not CharInSet(S[I], ValidPhoneChars) then
      Result := Format(SErrInvalidPhoneChar, [S[I], I, S]);

  R := S;
  intl := False;

  // Niet numerieke karakters eruit. Indien +, bijhouden.
  for I := Length(R) downto 1 do
    if not(IsDigit(R, I)) then
    begin
      intl := R[I] = '+';
      Delete(R, I, 1);
    end;

  // Check op eerste 2, moet 46 47 48 49 zijn. Dus prefix bepalen.
  if not intl then // Geval 32479xxyyzz of 0479xxyyzz
  begin
    if Copy(R, 1, 2) = '32' then // Geval 32479xxyyzz
      Delete(R, 1, 2);
    if Copy(R, 1, 1) = '0' then
      Delete(R, 1, 1);
    if Length(R) < 9 then
    begin
      Result := Format(SErrMobileLength, [R]);
      Exit;
    end;
    R := Copy(R, 1, 2); // prefix.
  end
  else
  begin // geval +NN-enz.
    if Copy(R, 1, 2) = '32' then
    begin // belgisch nummer, check.
      Delete(R, 1, 2);
      if Copy(R, 1, 1) = '0' then
        Delete(R, 1, 1);
      R := Copy(R, 1, 2);
    end
    else // Buitenlands nummer, geen check.
      R := '';
  end;

  if (R <> '') and ((R[1] <> '4') or not(CharInSet(R[2], ['6', '7', '8', '9']))) then
    Result := Format(SErrMobilePrefix, [R]);
end;

class function TDezqValidator.IsDigit(S: string; idx: Integer): Boolean;
begin
  Result := CharInSet(S[idx], ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
end;

class function TDezqValidator.ValidMobileNumber(S: string): Boolean;
begin
  Result := (CheckMobileNumber(S) = '');
end;

end.
