unit pas2web.dadataset;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  Types,
  Classes,
  DB,
  jsonDataset,
  JS,
  rosdk,
  pas2web.da,
  dasdk;

type
  // Server sends data as a regular string.
  TWideMemoField = class(TStringField)
  private
    FBlobType: TFieldType;
  public
    property BlobType: TFieldType read FBlobType write FBlobType;
  end;

  EDADataset = class(EDatabaseError);
  TP2WDAConnection = class;

  { TP2WDAWhereClauseBuilder }

  TP2WDAWhereClauseBuilder = class
  public
    class function NewBinaryExpression(aLeft, aRight: TP2WDAExpression; anOp: TP2WDABinaryOperator): TP2WDAExpression; overload;
    class function NewBinaryExpression(aLeft: TP2WDAExpression; anOp: TP2WDABinaryOperator; const aValue: JSValue): TP2WDAExpression; overload;
    class function NewBinaryExpression(aLeft: TP2WDAExpression; anOp: TP2WDABinaryOperator; const aValue: JSValue; aType: TP2WDADataType): TP2WDAExpression; overload;
    class function NewBinaryExpression(const aTableName, aFieldName: string; anOp: TP2WDABinaryOperator; const aJSValue: JSValue; aType: TP2WDADataType): TP2WDAExpression; overload;
    class function NewBinaryExpression(const aTableName, aFieldName: string; anOp: TP2WDABinaryOperator; const aJSValue: JSValue): TP2WDAExpression; overload;
    class function NewBinaryExpression(const aTableName, aFieldName: string; const aParameterName: string; aParameterType: TP2WDADataType; anOp: TP2WDABinaryOperator): TP2WDAExpression; overload;
    class function NewBinaryExpressionList(const aExpressions: array of TP2WDAExpression; anOp: TP2WDABinaryOperator): TP2WDAExpression;
    class function NewUnaryExpression(anExpression: TP2WDAExpression; anOp: TP2WDAUnaryOperator): TP2WDAExpression;
    class function NewConstant(const aValue: JSValue): TP2WDAExpression; overload;
    class function NewConstant(const aValue: JSValue; aType: TP2WDADataType): TP2WDAExpression; overload;
    class function NewDateTimeConstant(const aValue: TJSDate): TP2WDAExpression; overload;
    class function NewDateTimeConstant(const aValue: TDateTime): TP2WDAExpression; overload;
    class function NewList(const aValues: array of TP2WDAExpression): TP2WDAExpression;
    class function NewParameter(const aParameterName: string; aParameterType: TP2WDADataType = datUnknown): TP2WDAExpression;
    class function NewField(const aTableName, aFieldName: string): TP2WDAExpression;
    class function NewNull: TP2WDAExpression;
    class function NewIsNotNull: TP2WDAExpression; overload;
    class function NewIsNotNull(const aTableName, aFieldName: string): TP2WDAExpression; overload;
    class function NewIsNull(const aTableName, aFieldName: string): TP2WDAExpression; overload;
    class function NewMacro(const aName: string): TP2WDAExpression; overload;
    class function NewMacro(const aName: string; const aValues: array of TP2WDAExpression): TP2WDAExpression; overload;
    class function NewBetweenExpression(aExpression, aLower, aUpper: TP2WDAExpression): TP2WDAExpression; overload;
    class function NewBetweenExpression(const aExprTableName, aExprFieldName: string; aLower, aUpper: TP2WDAExpression): TP2WDAExpression; overload;
    class function NewBetweenExpression(const aExprTableName, aExprFieldName: string; aLowerValue, aUpperValue: JSValue; aValuesDataType: TP2WDADataType): TP2WDAExpression; overload;
    class function GetWhereClause(aExpression: TP2WDAExpression): string;
  end;

type
  TP2WDADataRow = class external name 'Object'(TJSObject)
  public
    _new: TJSValueDynArray;
    _old: TJSValueDynArray;
    _calc: TJSObject;
  end;

  TDaDataRowArray = array of TP2WDADataRow;

  TResolvedRow = class external name 'Object'(TJSObject)
  public
    changes: TP2WDAChange;
    fields: TLogFieldArray;
  end;

  { TP2WDAArrayFieldMapper }

  TP2WDAArrayFieldMapper = class(TJSONArrayFieldMapper)
  private
    FIndexMap: TJSObject;
  protected
    function GetFieldIndex(aFieldName: string): Integer;
    procedure SetFieldIndex(aFieldName: string; aValue: Integer);
  public
    constructor Create;
    procedure RemoveField(const FieldName: string; FieldIndex: Integer; Row: JSValue); override;
    procedure SetJSONDataForField(const FieldName{%H-} : string; FieldIndex: Integer; Row, Data: JSValue); override;
    function GetJSONDataForField(const FieldName{%H-} : string; FieldIndex: Integer; Row: JSValue): JSValue; override;
    function GetJSONDataForField(F: TField; Row: JSValue): JSValue; override; overload;
    procedure SetJSONDataForField(F: TField; Row, Data: JSValue); override; overload;
    function CreateRow: JSValue; override;
    property FieldIndexes[aName: string]: Integer read GetFieldIndex write SetFieldIndex;
  end;

  { TP2WDADataset }
  TP2WDADatasetOption = (doRefreshAllFields);
  TP2WDADatasetOptions = set of TP2WDADatasetOption;

  TP2WDADataset = class(TBaseJSONDataset)
  private
    FDAOptions: TP2WDADatasetOptions;
    FParams: TParams;
    FTableName: string;
    FDAConnection: TP2WDAConnection;
    FWhereClause: string;
    FWhereClauseBuilder: TP2WDAWhereClauseBuilder;
    function DataTypeToFieldType(s: string): TFieldType;
    procedure SetParams(aValue: TParams);
  protected
    function DoResolveRecordUpdate(anUpdate: TRecordUpdateDescriptor): Boolean; override;
    procedure MetaDataToFieldDefs; override;
    procedure InternalEdit; override;
    procedure InternalDelete; override;
    // These operate on metadata received from DA.
    function GetDAFields: TP2WDAFieldArray;
    function GetExcludedFields: TNativeIntDynArray;
    function GetLoggedFields: TLogFieldArray;
    function GetKeyFields: TStringDynArray;
  public
    constructor Create(aOwner: TComponent); override;
    destructor Destroy; override;
    function ConvertToDateTime(aField: TField; aValue: JSValue; ARaiseException: Boolean): TDateTime; override;
    function ConvertDateTimeToNative(aField: TField; aValue: TDateTime): JSValue; override;
    function DoGetDataProxy: TDataProxy; override;
    function ParamByName(const aName: string): TParam;
    function FindParam(const aName: string): TParam;
    property WhereClauseBuilder: TP2WDAWhereClauseBuilder read FWhereClauseBuilder;
    // DA is index based. So create array field mapper.
    function CreateFieldMapper: TJSONFieldMapper; override;
    procedure CreateFieldDefs(a: TJSArray);
  published
    property TableName: string read FTableName write FTableName;
    property DAConnection: TP2WDAConnection read FDAConnection write FDAConnection;
    property Params: TParams read FParams write SetParams;
    property WhereClause: string read FWhereClause write FWhereClause;
    property DAOptions: TP2WDADatasetOptions read FDAOptions write FDAOptions;
    property OnRecordResolved;
    property OnLoadFail;
    property BeforeOpen;
    property AfterOpen;
    property BeforeClose;
    property AfterClose;
    property BeforeInsert;
    property AfterInsert;
    property BeforeEdit;
    property AfterEdit;
    property BeforePost;
    property AfterPost;
    property BeforeCancel;
    property AfterCancel;
    property BeforeDelete;
    property AfterDelete;
    property BeforeScroll;
    property AfterScroll;
    property OnCalcFields;
    property OnDeleteError;
    property OnEditError;
    property OnFilterRecord;
    property OnNewRecord;
    property OnPostError;
  end;

  TP2WDADataRequest = class(TDataRequest)
  public
    procedure doSuccess(res: JSValue);
    procedure DoFail(response: TROMessage; fail: TjsError);
  end;

  { TP2WDADataProxy }

  TP2WDADataProxy = class(TDataProxy)
  private
    FConnection: TP2WDAConnection;
    function ConvertParams(DADS: TP2WDADataset): TP2WDADataParameterDataArray;
    procedure ProcessUpdateResult(res: JSValue; aBatch: TRecordUpdateBatch);
  protected
    function GetDataRequestClass: TDataRequestClass; override;
  public
    function DoGetData(aRequest: TDataRequest): Boolean; override;
    function ProcessUpdateBatch(aBatch: TRecordUpdateBatch): Boolean; override;
    property Connection: TP2WDAConnection read FConnection write FConnection;
  end;

  TP2WDAMessageType = (mtAuto, // autodetect from URL
    mtBin, // use BinMessage
    mtJSON); // Use JSONMessage.
  TP2WDAStreamerType = (stJSON, stBin);

  { TP2WDAConnection }
  TLoginFailedEvent = Reference to procedure(Msg: TROMessage; Err: string);

  TP2WDAConnection = class(TComponent)
  private
    FDataService: TP2WDADataAbstractService;
    FDataserviceName: string;
    FLoginService: TP2WDASimpleLoginService;
    FLoginServiceName: string;
    FMessageType: TP2WDAMessageType;
    FMessage: TROMessage;
    FChannel: TROHTTPClientChannel;
    FOnLoginFailed: TLoginFailedEvent;
    FOnLogin: TP2WDALoginSuccessEvent;
    FOnLogout: TP2WDASuccessEvent;
    FOnLogoutailed: TP2WDAFailedEvent;
    FOnLogoutFailed: TP2WDAFailedEvent;
    FStreamerType: TP2WDAStreamerType;
    FURL: string;
    procedure ClearConnection;
    function GetChannel: TROHTTPClientChannel;
    function GetClientID: string;
    function GetDataService: TP2WDADataAbstractService;
    function GetLoginService: TP2WDASimpleLoginService;
    function GetMessage: TROMessage;
    procedure SetDataserviceName(aValue: string);
    procedure SetLoginServiceName(aValue: string);
    procedure SetMessageType(aValue: TP2WDAMessageType);
    procedure SetURL(aValue: string);
    procedure DoLoginFailed(Msg: TROMessage; aErr: TjsError);
  protected
    procedure CreateChannelAndMessage; virtual;
    function DetectMessageType(const aURL: string): TP2WDAMessageType; virtual;
    function CreateDataService: TP2WDADataAbstractService; virtual;
    function CreateLoginService: TP2WDASimpleLoginService; virtual;
    function CreateStreamer: TP2WDADataStreamer;
    function InterpretMessage(aRes: JSValue): string;
  public
    constructor Create(aOwner: TComponent); override;
    destructor Destroy; override;
    // Returns a non-auto MessageType, but raises exception if it cannot be determined;
    function EnsureMessageType: TP2WDAMessageType;
    // Returns DataService, but raises exception if it is nil;
    function EnsureDataservice: TP2WDADataAbstractService;
    // Returns SimpleLoginService, but raises exception if it is nil;
    function EnsureLoginservice: TP2WDASimpleLoginService;
    // Call this to login. This is an asynchronous call, check the result using OnLoginOK and OnLoginFailed calls.
    procedure Login(aUserName, aPassword: string);
    procedure LoginEx(aLoginString: string);
    procedure Logout;
    // You can set this. If you didn't set this, and URL is filled, an instance will be created.
    property DataService: TP2WDADataAbstractService read GetDataService write FDataService;
    // You can set this. If you didn't set this, and URL is filled, an instance will be created.
    property LoginService: TP2WDASimpleLoginService read GetLoginService write FLoginService;
    // You can get this to use in other service constructors
    property Channel: TROHTTPClientChannel read GetChannel;
    property message: TROMessage read GetMessage;
    // Get client ID
    property ClientID: string read GetClientID;
  published
    // If set, this is the message type that will be used when auto-creating the service. Setting this while dataservice is Non-Nil will remove the reference
    property MessageType: TP2WDAMessageType read FMessageType write SetMessageType;
    // if set, URL is used to create a DataService. Setting this while dataservice is Non-Nil will remove the reference
    property URL: string read FURL write SetURL;
    // DataServiceName is used to create a DataService. Setting this while dataservice is Non-Nil will remove the reference
    property DataserviceName: string read FDataserviceName write SetDataserviceName;
    // LoginServiceName is used to create a login service. Setting this while loginservice is Non-Nil will remove the reference
    property LoginServiceName: string read FLoginServiceName write SetLoginServiceName;
    // Called when login call is executed.
    property OnLogin: TP2WDALoginSuccessEvent read FOnLogin write FOnLogin;
    // Called when login call failed. When call was executed but user is wrong OnLogin is called !
    property OnLoginCallFailed: TLoginFailedEvent read FOnLoginFailed write FOnLoginFailed;
    // Called when logout call is executed.
    property OnLogout: TP2WDASuccessEvent read FOnLogout write FOnLogout;
    // Called when logout call failed.
    property OnLogOutCallFailed: TP2WDAFailedEvent read FOnLogoutailed write FOnLogoutFailed;
    // Streamertype : format of the data package in the message.
    property StreamerType: TP2WDAStreamerType read FStreamerType write FStreamerType;
  end;

implementation

uses
  strutils,
  sysutils;

resourcestring
  SErrInvalidDate = '%s is not a valid date value for %s';

  { TP2WDAArrayFieldMapper }

constructor TP2WDAArrayFieldMapper.Create;
begin
  FIndexMap := TJSObject.New;
end;

function TP2WDAArrayFieldMapper.GetFieldIndex(aFieldName: string): Integer;
begin
  aFieldName := LowerCase(aFieldName);
  if FIndexMap.HasOwnProperty(aFieldName) then
    Result := Integer(FIndexMap[aFieldName])
  else
    Result := -1;
end;

procedure TP2WDAArrayFieldMapper.SetFieldIndex(aFieldName: string; aValue: Integer);
begin
  aFieldName := LowerCase(aFieldName);
  FIndexMap[aFieldName] := aValue;
end;

procedure TP2WDAArrayFieldMapper.RemoveField(const FieldName: string; FieldIndex: Integer; Row: JSValue);
begin
  inherited RemoveField(FieldName, FieldIndex, TP2WDADataRow(Row)._new);
end;

procedure TP2WDAArrayFieldMapper.SetJSONDataForField(const FieldName: string; FieldIndex: Integer; Row, Data: JSValue);
begin
  inherited SetJSONDataForField(FieldName, FieldIndex, TP2WDADataRow(Row)._new, Data);
end;

function TP2WDAArrayFieldMapper.GetJSONDataForField(const FieldName: string; FieldIndex: Integer; Row: JSValue): JSValue;
begin
  Result := inherited GetJSONDataForField(FieldName, FieldIndex, TP2WDADataRow(Row)._new);
end;

function TP2WDAArrayFieldMapper.GetJSONDataForField(F: TField; Row: JSValue): JSValue;
var
  I: Integer;
begin
  if F.fieldKind = fkCalculated then
    Result := TJSObject(TP2WDADataRow(Row)._calc).Properties[F.FieldName]
  else
  begin
    I := FieldIndexes[F.FieldName];
    if I = -1 then
      DatabaseErrorFmt('Cannot determine index of field %s', [F.FieldName]);
    Result := GetJSONDataForField(F.FieldName, I, Row);
  end;
end;

procedure TP2WDAArrayFieldMapper.SetJSONDataForField(F: TField; Row, Data: JSValue);
begin
  if F.fieldKind = fkCalculated then
    TJSObject(TP2WDADataRow(Row)._calc).Properties[F.FieldName] := Data
  else
    inherited;
end;

function TP2WDAArrayFieldMapper.CreateRow: JSValue;
begin
  Result := TP2WDADataRow.New;
  with TP2WDADataRow(Result) do
  begin
    _new := [];
    _old := [];
    _calc := TJSObject.New;
  end;
end;

{ TP2WDAWhereClauseBuilder }

class function TP2WDAWhereClauseBuilder.NewBinaryExpression(aLeft, aRight: TP2WDAExpression; anOp: TP2WDABinaryOperator): TP2WDAExpression;
begin
  Result := TP2WDABinaryExpression.New(aLeft, aRight, BinaryOperatorNames[anOp]);
end;

class function TP2WDAWhereClauseBuilder.NewBinaryExpression(aLeft: TP2WDAExpression; anOp: TP2WDABinaryOperator; const aValue: JSValue): TP2WDAExpression;
begin
  Result := TP2WDABinaryExpression.New(aLeft, NewConstant(aValue), BinaryOperatorNames[anOp]);
end;

class function TP2WDAWhereClauseBuilder.NewBinaryExpression(aLeft: TP2WDAExpression; anOp: TP2WDABinaryOperator; const aValue: JSValue; aType: TP2WDADataType): TP2WDAExpression;
begin
  Result := TP2WDABinaryExpression.New(aLeft, NewConstant(aValue, aType), BinaryOperatorNames[anOp]);
end;

class function TP2WDAWhereClauseBuilder.NewBinaryExpression(const aTableName, aFieldName: string; anOp: TP2WDABinaryOperator; const aJSValue: JSValue; aType: TP2WDADataType): TP2WDAExpression;
begin
  Result := TP2WDABinaryExpression.New(NewField(aTableName, aFieldName), NewConstant(aJSValue, aType), BinaryOperatorNames[anOp])
end;

class function TP2WDAWhereClauseBuilder.NewBinaryExpression(const aTableName, aFieldName: string; anOp: TP2WDABinaryOperator; const aJSValue: JSValue): TP2WDAExpression;
begin
  Result := TP2WDABinaryExpression.New(NewField(aTableName, aFieldName), NewConstant(aJSValue), BinaryOperatorNames[anOp])
end;

class function TP2WDAWhereClauseBuilder.NewBinaryExpression(const aTableName, aFieldName: string; const aParameterName: string; aParameterType: TP2WDADataType; anOp: TP2WDABinaryOperator): TP2WDAExpression;
begin
  Result := TP2WDABinaryExpression.New(NewField(aTableName, aFieldName), NewParameter(aParameterName, aParameterType), BinaryOperatorNames[anOp])
end;

class function TP2WDAWhereClauseBuilder.NewBinaryExpressionList(const aExpressions: array of TP2WDAExpression; anOp: TP2WDABinaryOperator): TP2WDAExpression;
var
  I, len: Integer;
begin
  len := Length(aExpressions);
  case len of
    0:
      Result := nil;
    1:
      Result := aExpressions[0];
  else
    Result := NewBinaryExpression(aExpressions[0], aExpressions[1], anOp);
    for I := 2 to len - 1 do
      Result := NewBinaryExpression(Result, aExpressions[I], anOp);
  end;
end;

class function TP2WDAWhereClauseBuilder.NewUnaryExpression(anExpression: TP2WDAExpression; anOp: TP2WDAUnaryOperator): TP2WDAExpression;
begin
  Result := TP2WDAUnaryExpression.New(anExpression, UnaryOperatorNames[anOp]);
end;

class function TP2WDAWhereClauseBuilder.NewConstant(const aValue: JSValue): TP2WDAExpression;
begin
  Result := TP2WDAConstantExpression.New(JSValueToDataTypeName(aValue), aValue, Ord(IsNull(aValue)));
end;

class function TP2WDAWhereClauseBuilder.NewDateTimeConstant(const aValue: TJSDate): TP2WDAExpression; overload;
begin
  Result := TP2WDAConstantExpression.New(DataTypeNames[datDateTime], Trunc(aValue.Time / 1000), 0);
end;

class function TP2WDAWhereClauseBuilder.NewDateTimeConstant(const aValue: TDateTime): TP2WDAExpression; overload;
begin
  Result := NewDateTimeConstant(DateTimeToJSDate(aValue));
  // Result:=TP2WDAConstantExpression.New(DataTypeNames[datDateTime],DateTimeToJSDate(('yyyy"-"mm"-"dd"T"hh":"nn":"ss',aValue),0);
end;

class function TP2WDAWhereClauseBuilder.NewConstant(const aValue: JSValue; aType: TP2WDADataType): TP2WDAExpression;
begin
  Result := TP2WDAConstantExpression.New(JSValueToDataTypeName(aValue), aValue, Ord(IsNull(aValue)));
end;

class function TP2WDAWhereClauseBuilder.NewList(const aValues: array of TP2WDAExpression): TP2WDAExpression;
begin
  Result := TP2WDAListExpression.New(aValues);
end;

class function TP2WDAWhereClauseBuilder.NewParameter(const aParameterName: string; aParameterType: TP2WDADataType): TP2WDAExpression;
begin
  Result := TP2WDAParameterExpression.New(aParameterName, DataTypeNames[aParameterType], 0);
end;

class function TP2WDAWhereClauseBuilder.NewField(const aTableName, aFieldName: string): TP2WDAExpression;
var
  aName: string;
begin
  aName := aFieldName;
  if aTableName <> '' then
    aName := aTableName + '.' + aName;
  Result := TP2WDAFieldExpression.New(aName);
end;

class function TP2WDAWhereClauseBuilder.NewNull: TP2WDAExpression;
begin
  Result := TP2WDANullExpression.New;
end;

class function TP2WDAWhereClauseBuilder.NewIsNotNull: TP2WDAExpression;
begin
  Result := NewUnaryExpression(TP2WDANullExpression.New, duoNot);
end;

class function TP2WDAWhereClauseBuilder.NewIsNotNull(const aTableName, aFieldName: string): TP2WDAExpression;
begin
  Result := NewBinaryExpression(NewField(aTableName, aFieldName), NewIsNotNull, dboEqual);
end;

class function TP2WDAWhereClauseBuilder.NewIsNull(const aTableName, aFieldName: string): TP2WDAExpression;
begin
  Result := NewBinaryExpression(NewField(aTableName, aFieldName), TP2WDANullExpression.New, dboEqual);
end;

class function TP2WDAWhereClauseBuilder.NewMacro(const aName: string): TP2WDAExpression;
begin
  Result := TP2WDAMacroExpression.New(aName);
end;

class function TP2WDAWhereClauseBuilder.NewMacro(const aName: string; const aValues: array of TP2WDAExpression): TP2WDAExpression;
begin
  Result := TP2WDAMacroExpression.New(aName); // ??
end;

class function TP2WDAWhereClauseBuilder.NewBetweenExpression(aExpression, aLower, aUpper: TP2WDAExpression): TP2WDAExpression;
begin
  Result := TP2WDABetweenExpression.New(aExpression, aLower, aUpper);
end;

class function TP2WDAWhereClauseBuilder.NewBetweenExpression(const aExprTableName, aExprFieldName: string; aLower, aUpper: TP2WDAExpression): TP2WDAExpression;
begin
  Result := NewBetweenExpression(NewField(aExprTableName, aExprFieldName), aLower, aUpper);
end;

class function TP2WDAWhereClauseBuilder.NewBetweenExpression(const aExprTableName, aExprFieldName: string; aLowerValue, aUpperValue: JSValue; aValuesDataType: TP2WDADataType): TP2WDAExpression;
begin
  Result := NewBetweenExpression(NewField(aExprTableName, aExprFieldName), NewConstant(aLowerValue, aValuesDataType), NewConstant(aUpperValue, aValuesDataType));
end;

class function TP2WDAWhereClauseBuilder.GetWhereClause(aExpression: TP2WDAExpression): string;
var
  DW: TP2WDADynamicWhere;
begin
  DW := TP2WDADynamicWhere.New(aExpression);
  try
    Result := DW.toXml
  finally
    DW := nil;
  end;
end;

{ TP2WDAConnection }

function TP2WDAConnection.GetDataService: TP2WDADataAbstractService;
begin
  if (FDataService = nil) then
    FDataService := CreateDataService;
  Result := FDataService;
end;

function TP2WDAConnection.GetLoginService: TP2WDASimpleLoginService;
begin
  if (FLoginService = nil) then
    FLoginService := CreateLoginService;
  Result := FLoginService;
end;

function TP2WDAConnection.GetMessage: TROMessage;
begin
  CreateChannelAndMessage;
  Result := FMessage;
end;

procedure TP2WDAConnection.SetDataserviceName(aValue: string);
begin
  if FDataserviceName = aValue then
    Exit;
  ClearConnection;
  FDataserviceName := aValue;
end;

procedure TP2WDAConnection.SetLoginServiceName(aValue: string);
begin
  if FLoginServiceName = aValue then
    Exit;
  FLoginServiceName := aValue;
end;

procedure TP2WDAConnection.SetMessageType(aValue: TP2WDAMessageType);
begin
  if FMessageType = aValue then
    Exit;
  ClearConnection;
  FMessageType := aValue;
end;

procedure TP2WDAConnection.ClearConnection;
begin
  FDataService := nil;
  FChannel := nil;
  FMessage := nil;
end;

function TP2WDAConnection.GetChannel: TROHTTPClientChannel;
begin
  CreateChannelAndMessage;
  Result := FChannel;
end;

function TP2WDAConnection.GetClientID: string;
begin
  if Assigned(FMessage) then
    Result := FMessage.ClientID
  else
    Result := '';
end;

procedure TP2WDAConnection.SetURL(aValue: string);
begin
  if FURL = aValue then
    Exit;
  ClearConnection;
  FURL := aValue;
end;

procedure TP2WDAConnection.CreateChannelAndMessage;
begin
  if (FChannel = nil) then
    FChannel := TROHTTPClientChannel.New(URL);
  if (FMessage = nil) then
    case EnsureMessageType of
      mtBin:
        FMessage := TROBINMessage.New;
      mtJSON:
        FMessage := TROJSONMessage.New;
    end;
end;

function TP2WDAConnection.DetectMessageType(const aURL: string): TP2WDAMessageType;
var
  s: string;
begin
  s := aURL;
  Delete(s, 1, RPos('/', s));
  case LowerCase(s) of
    'bin':
      Result := mtBin;
    'json':
      Result := mtJSON;
  else
    raise EDADataset.Create(name + ': Could not determine message type from URL: ' + aURL);
  end;
end;

function TP2WDAConnection.CreateDataService: TP2WDADataAbstractService;
begin
  Result := nil;
  if URL = '' then
    Exit;
  CreateChannelAndMessage;
  Result := TP2WDADataAbstractService.New(FChannel, FMessage, DataserviceName);
end;

function TP2WDAConnection.CreateLoginService: TP2WDASimpleLoginService;
begin
  Result := nil;
  if URL = '' then
    Exit;
  CreateChannelAndMessage;
  Result := TP2WDASimpleLoginService.New(FChannel, FMessage, LoginServiceName);
end;

function TP2WDAConnection.CreateStreamer: TP2WDADataStreamer;
begin
  case StreamerType of
    stJSON:
      Result := TP2WDAJSONDataStreamer.New;
    stBin:
      Result := TP2WDABIN2DataStreamer.New;
  end;
end;

function TP2WDAConnection.InterpretMessage(aRes: JSValue): string;
begin
  Result := string(aRes);
  if (EnsureMessageType = mtJSON) then
    Result := TROUtil.Frombase64(Result);
end;

constructor TP2WDAConnection.Create(aOwner: TComponent);
begin
  inherited Create(aOwner);
  FDataserviceName := 'DataService';
  FLoginServiceName := 'LoginService';
  MessageType := mtBin;
  StreamerType := stBin;
end;

destructor TP2WDAConnection.Destroy;
begin
  ClearConnection;
  inherited Destroy;
end;

function TP2WDAConnection.EnsureMessageType: TP2WDAMessageType;
begin
  Result := MessageType;
  if Result = mtAuto then
    Result := DetectMessageType(URL);
end;

function TP2WDAConnection.EnsureDataservice: TP2WDADataAbstractService;
begin
  Result := DataService;
  if (Result = nil) then
    raise EDADataset.Create('No data service available. ');
end;

function TP2WDAConnection.EnsureLoginservice: TP2WDASimpleLoginService;
begin
  Result := LoginService;
  if (Result = nil) then
    raise EDADataset.Create('No login service available. ');
end;

procedure TP2WDAConnection.DoLoginFailed(Msg: TROMessage; aErr: TjsError);
var
  ErrMsg: string;
begin
  if Assigned(FOnLoginFailed) then
  begin
    if IsObject(aErr) then
      if TJSObject(aErr).HasOwnProperty('message') then
        ErrMsg := aErr.Message
      else
        ErrMsg := 'Error object: ' + TJSJSON.Stringify(aErr)
    else
      if IsString(aErr) then
        ErrMsg := string(JSValue(aErr))
      else
        ErrMsg := 'Unknown error';
    FOnLoginFailed(Msg, ErrMsg);
  end;
end;

procedure TP2WDAConnection.Login(aUserName, aPassword: string);
begin
  EnsureLoginservice.Login(aUserName, aPassword, FOnLogin, @DoLoginFailed);
end;

procedure TP2WDAConnection.LoginEx(aLoginString: string);
begin
  EnsureLoginservice.LoginEx(aLoginString, FOnLogin, @DoLoginFailed);
end;

procedure TP2WDAConnection.Logout;
begin
  EnsureLoginservice.Logout(FOnLogout, FOnLogoutFailed);
end;

{ TP2WDADataset }

function TP2WDADataset.DataTypeToFieldType(s: string): TFieldType;
const
  FieldStrings: array [TFieldType] of string = ('', 'String', 'Integer', 'LargeInt', 'Boolean', 'Float', 'Date', 'Time', 'DateTime', 'AutoInc', 'Blob', 'Memo', 'FixedChar', 'Variant', 'Dataset'
      ,'WideString' // For newer versions of WebCore
    );
begin
  if (Copy(s, 1, 3) = 'dat') then
    system.Delete(s, 1, 3);
  Result := high(TFieldType);
  while (Result > ftUnknown) and not SameText(FieldStrings[Result], s) do
    Result := Pred(Result);
  if Result = ftUnknown then
    case LowerCase(s) of
      'widememo', 'widestring':
        Result := ftString;
      'currency':
        Result := ftFloat;
      'decimal':
        Result := ftFloat;
      'smallint':
        Result := ftInteger;
      'largeautoinc':
        Result := ftLargeInt;
    else
      writeln('Unknown field type:', s)
    end;
end;

procedure TP2WDADataset.SetParams(aValue: TParams);
begin
  if FParams = aValue then
    Exit;
  FParams.Assign(aValue);
end;

function TP2WDADataset.DoResolveRecordUpdate(anUpdate: TRecordUpdateDescriptor): Boolean;
var
  rIdx, I: Integer;
  Fld: TField;
  ResRow: TResolvedRow;
  aRow: JSValue;
begin
  Result := True;
  if Assigned(anUpdate.ServerData) and (anUpdate.Status <> usDeleted) then
  begin
    rIdx := NativeInt(anUpdate.Bookmark.Data);
    if not(rIdx >= 0) and (rIdx < Rows.Length) then
      Exit;
    // Apply new values
    aRow := Rows[rIdx];
    ResRow := TResolvedRow(anUpdate.ServerData);
    with ResRow do
      for I := 0 to Length(fields) - 1 do
        if (doRefreshAllFields in DAOptions) or (changes.old[I] <> changes.new_[I]) then
        begin
          Fld := FieldByName(fields[I].Name);
          FieldMapper.SetJSONDataForField(Fld, aRow, changes.new_[I]);
        end;
    TP2WDADataRow(aRow)._old := [];
  end;
end;

function TP2WDADataset.ConvertToDateTime(aField: TField; aValue: JSValue; ARaiseException: Boolean): TDateTime;
begin
  Result := 0;
  if isDate(aValue) then
    Result := JSDateToDateTime(TJSDate(aValue))
  else
    if IsString(aValue) then
      Result := inherited ConvertToDateTime(aField, aValue, ARaiseException)
    else
      if ARaiseException then
        DatabaseErrorFmt(SErrInvalidDate, [string(aValue), aField.FieldName], Self);
end;

function TP2WDADataset.ConvertDateTimeToNative(aField: TField; aValue: TDateTime): JSValue;
begin
  Result := DateTimeToJSDate(aValue);
end;

procedure TP2WDADataset.MetaDataToFieldDefs;
begin
  if not isArray(Metadata['fields']) then
    Exit;
  CreateFieldDefs(TJSArray(Metadata['fields']));
end;

procedure TP2WDADataset.InternalEdit;
var
  D: TP2WDADataRow;
begin
  inherited;
  D := TP2WDADataRow(ActiveBuffer.Data);
  if Length(D._old) = 0 then
  begin
    asm
      this.FEditRow._old=this.FEditRow._new.slice();
    end;
  end;
  if not isDefined(D._new) then
    ActiveBuffer.Data := FieldMapper.CreateRow
end;

procedure TP2WDADataset.InternalDelete;
begin
  inherited InternalDelete;
  asm
    var len=this.FDeletedRows.length-1;
    this.FDeletedRows[len]._old=this.FDeletedRows[len]._new.slice();
  end;
end;

function TP2WDADataset.GetDAFields: TP2WDAFieldArray;
begin
  if Assigned(Metadata) and Metadata.HasOwnProperty('fields') and isArray(Metadata['fields']) then
    Result := TP2WDAFieldArray(Metadata['fields'])
  else
    Result := nil;
end;

function TP2WDADataset.GetExcludedFields: TNativeIntDynArray;
var
  Flds: TP2WDAFieldArray;
  I: Integer;
begin
  Result := [];
  Flds := GetDAFields;
  for I := 0 to Length(Flds) - 1 do
    if not Flds[I].logChanges then
      TJSArray(Result).Push(I);
end;

function TP2WDADataset.GetKeyFields: TStringDynArray;
var
  Flds: TP2WDAFieldArray;
  I, aLen: Integer;
begin
  Result := [];
  Flds := GetDAFields;
  aLen := 0;
  SetLength(Result, Length(Flds));
  for I := 0 to Length(Flds) - 1 do
  begin
    if Flds[I].HasOwnProperty('inPrimaryKey') and SameText(Flds[I].inPrimaryKey, 'True') then
    begin
      Result[aLen] := Flds[I].Name;
      Inc(aLen);
    end;
  end;
  SetLength(Result, aLen);
end;

function TP2WDADataset.GetLoggedFields: TLogFieldArray;
var
  Flds: TP2WDAFieldArray;
  I, aLen: Integer;
begin
  Result := [];
  Flds := GetDAFields;
  aLen := 0;
  SetLength(Result, Length(Flds));
  for I := 0 to Length(Flds) - 1 do
  begin
    if Flds[I].logChanges then
    begin
      Result[aLen].Name := Flds[I].Name;
      Result[aLen].datatype := Flds[I].type_;
      Inc(aLen);
    end;
  end;
  SetLength(Result, aLen);
end;

function TP2WDADataset.DoGetDataProxy: TDataProxy;
begin
  Result := TP2WDADataProxy.Create(Self);
  TP2WDADataProxy(Result).Connection := DAConnection;
end;

function TP2WDADataset.ParamByName(const aName: string): TParam;
begin
  Result := FParams.ParamByName(aName);
end;

function TP2WDADataset.FindParam(const aName: string): TParam;
begin
  Result := FParams.FindParam(aName);
end;

constructor TP2WDADataset.Create(aOwner: TComponent);
begin
  inherited;
  DataProxy := nil;
  FParams := TParams.Create(Self);
  FWhereClauseBuilder := TP2WDAWhereClauseBuilder.Create;
end;

destructor TP2WDADataset.Destroy;
begin
  FreeAndNil(FWhereClauseBuilder);
  FreeAndNil(FParams);
  inherited;
end;

procedure TP2WDADataset.CreateFieldDefs(a: TJSArray);
var
  I: Integer;
  F: TP2WDAField;
  FO: TJSObject absolute F;
  fn, dt: string;
  fs: Integer;
  FT: TFieldType;
  req: Boolean;
begin
  FieldDefs.Clear;
  for I := 0 to a.Length - 1 do
  begin
    F := TP2WDAField(a.Elements[I]);
    fn := F.Name;
    // The JSON streamer does not create all properties :(
    if FO.HasOwnProperty('size') then
    begin

      if IsString(FO['size']) then
        fs := StrToInt(string(FO['size']))
      else
        if isNumber(FO['size']) then
          fs := F.Size
        else
          fs := 0;
    end
    else
      fs := 0;
    if FO.HasOwnProperty('type') then
      dt := F.type_
    else
      dt := 'string';
    if FO.HasOwnProperty('required') then
      req := F.Required
    else
      req := false;
    FT := DataTypeToFieldType(dt);
    if (FT = ftBlob) and (fs = 0) then
      fs := 1;
    // Writeln('FieldDef : ',fn,', ',ft,', ',fs);
    FieldDefs.Add(fn, FT, fs, req);
    TP2WDAArrayFieldMapper(FieldMapper).FieldIndexes[fn] := I;
  end;
end;

function TP2WDADataset.CreateFieldMapper: TJSONFieldMapper;
begin
  Result := TP2WDAArrayFieldMapper.Create;
end;

{ TP2WDADataProxy }

function TP2WDADataProxy.ConvertParams(DADS: TP2WDADataset): TP2WDADataParameterDataArray;
var
  I: Integer;
begin
  Result := nil;
  if DADS.Params.Count = 0 then
    Exit;
  SetLength(Result, DADS.Params.Count);
  for I := 0 to DADS.Params.Count - 1 do
  begin
    Result[I].Name := DADS.Params[I].Name;
    Result[I].Value := DADS.Params[I].Value;
    // Writeln('Set param ',Result[i].Name,' to ',Result[i].Value);
  end;
end;

function TP2WDADataProxy.DoGetData(aRequest: TDataRequest): Boolean;
var
  TN: TP2WDAStringArray;
  TIA: TP2WDATableRequestInfoArray;
  TID: TP2WDATableRequestInfoV5Data;
  TI: TP2WDATableRequestInfoV5;
  Srt: TP2WDAColumnSortingData;
  R: TP2WDADataRequest;
  DADS: TP2WDADataset;
  PA: TP2WDADataParameterDataArray;
  DS: TP2WDADataAbstractService;
begin
  // DA does not support this option...
  if loAtEOF in aRequest.LoadOptions then
    Exit(false);
  DADS := aRequest.Dataset as TP2WDADataset;
  R := aRequest as TP2WDADataRequest;
  if (Connection = nil) then
    raise EDADataset.Create(DADS.Name + ': Cannot get data without connection');
  if (DADS.TableName = '') then
    raise EDADataset.Create(DADS.Name + ': Cannot get data without tablename');
  DS := Connection.EnsureDataservice;
  TN := TP2WDAStringArray.New;
  TN.fromObject([DADS.TableName]);
  TID.maxRecords := -1;
  TID.IncludeSchema := True;
  Srt.FieldName := '';
  Srt.SortDirection := 'Ascending';
  TID.Sorting := Srt;
  TID.UserFilter := '';
  if DADS.WhereClause <> '' then
    TID.WhereClause := DADS.WhereClause;
  PA := ConvertParams(DADS);
  if Length(PA) > 0 then
    TID.Parameters := PA;
  TIA := TP2WDATableRequestInfoArray.New;
  // We need to manually fill the array
  TI := TP2WDATableRequestInfoV5.New;
  TI.fromObject(TID);
  TJSArray(TIA.items).Push(TI);
  DS.GetData(TN, TIA, @R.doSuccess, @R.DoFail);
  Result := True;
end;

function TP2WDADataProxy.GetDataRequestClass: TDataRequestClass;
begin
  Result := TP2WDADataRequest;
end;

procedure TP2WDADataProxy.ProcessUpdateResult(res: JSValue; aBatch: TRecordUpdateBatch);
var
  I: Integer;
  aDelta: TP2WDADelta;
  aDeltas: TP2WDADeltas;
  aStreamer: TP2WDADataStreamer;
  C: TP2WDAChange;
  ResolvedRow: TResolvedRow;
begin
  aStreamer := Connection.CreateStreamer;
  aStreamer.Stream := Connection.InterpretMessage(res);
  aStreamer.initializeRead;
  aDeltas := aStreamer.ReadDelta;
  if Length(aDeltas.deltas) > 1 then
  begin
    for I := 0 to aBatch.List.Count - 1 do
      aBatch.List[I].ResolveFailed('More than 1 delta in result');
    Exit;
  end;
  aDelta := aDeltas.deltas[0];
  for C in aDelta.Data do
  begin
    case C.Status of
      'failed':
        aBatch.List[C.recid].ResolveFailed(C.Message);
      'resolved':
        begin
          ResolvedRow := TResolvedRow.New;
          ResolvedRow.changes := C;
          ResolvedRow.fields := aDelta.LoggedFields;
          aBatch.List[C.recid].Resolve(ResolvedRow);
        end;
    end;
  end;
  for I := 0 to aBatch.List.Count - 1 do
    if aBatch.List[I].ResolveStatus = rsResolving then
      aBatch.List[I].Resolve(Null);
  if Assigned(aBatch.OnResolve) then
    aBatch.OnResolve(Self, aBatch);
end;

function TP2WDADataProxy.ProcessUpdateBatch(aBatch: TRecordUpdateBatch): Boolean;

  procedure UpdateSuccess(res: JSValue);
  begin
    ProcessUpdateResult(res, aBatch)
  end;

  procedure UpdateFailure(response: TROMessage; Err: TjsError);
  var
    I: Integer;
    aDesc: TRecordUpdateDescriptor;
  begin
    for I := 0 to aBatch.List.Count - 1 do
    begin
      aDesc := aBatch.List[I];
      if Assigned(aDesc) then
        aDesc.ResolveFailed(extractErrorMsg(Err));
    end;
    if Assigned(aBatch.OnResolve) then
      aBatch.OnResolve(Self, aBatch);
  end;

  procedure ExcludeItems(aList: TNativeIntDynArray; aValue: TJSValueDynArray);
  var
    I: Integer;
  begin
    // Backwards or index will shift with each delete!
    for I := Length(aList) - 1 downto 0 do
      TJSArray(aValue).Splice(aList[I], 1);
  end;

const
  ChangeTypes: array [TUpdateStatus] of string = ('update', 'insert', 'delete');
var
  lDataset: TP2WDADataset;
  excludedFields: TNativeIntDynArray;
  I: Integer;
  aDesc: TRecordUpdateDescriptor;
  aDelta: TP2WDADelta;
  aDeltas: TP2WDADeltas;
  aChange: TP2WDAChange;
  DS: TP2WDADataAbstractService;
  DStr: TP2WDADataStreamer;
  s: string;

  function CopyArray(aRow: TJSArray): TJSValueDynArray;
  // DA expects the dates to be Javascript Date objects.
  // When a row was edited, it is written as a string.
  // So we convert them here.
  var
    I: Integer;
  begin
    Result := TJSValueDynArray(aRow.slice());
    for I := 0 to lDataset.fields.Count - 1 do
      if (lDataset.fields[I].datatype in [ftDate, ftTime, ftDateTime]) and IsString(Result[I]) then
        Result[I] := TJSDate.New(string(Result[I]))
  end;

begin
  lDataset := TP2WDADataset(aBatch.Dataset);
  aDeltas := TP2WDADeltas.New;
  aDelta := TP2WDADelta.New;
  aDelta.Name := lDataset.TableName;
  aDelta.keyFields := lDataset.GetKeyFields;
  aDelta.LoggedFields := lDataset.GetLoggedFields;
  excludedFields := lDataset.GetExcludedFields;
  TJSArray(aDeltas.deltas).Push(aDelta);
  for I := 0 to aBatch.List.Count - 1 do
  begin
    aDesc := aBatch.List[I];
    aChange := TP2WDAChange.New;
    aChange.Status := 'pending';
    case aDesc.Status of
      usInserted:
        begin
          aChange.new_ := CopyArray(TJSArray(TP2WDADataRow(aDesc.Data)._new));
        end;
      usDeleted:
        begin
          aChange.new_ := CopyArray(TJSArray(TP2WDADataRow(aDesc.Data)._new));
          aChange.old := CopyArray(TJSArray(TP2WDADataRow(aDesc.Data)._old));
          if Length(aChange.old) = 0 then
            aChange.old := CopyArray(TJSArray(TP2WDADataRow(aDesc.Data)._new));
          // Actually should be all null...
        end;
      usModified:
        begin
          aChange.old := CopyArray(TJSArray(TP2WDADataRow(aDesc.Data)._old));
          aChange.new_ := CopyArray(TJSArray(TP2WDADataRow(aDesc.Data)._new));
        end;
    end;
    ExcludeItems(excludedFields, aChange.new_);
    ExcludeItems(excludedFields, aChange.old);
    aChange.changeType := ChangeTypes[aDesc.Status];
    aChange.recid := I;
    TJSArray(aDelta.Data).Push(aChange);
  end;
  DStr := Connection.CreateStreamer;
  DStr.initializeWrite;
  DStr.writeDelta(aDeltas);
  DStr.finalizeWrite;
  s := DStr.Stream;
  DS := Connection.EnsureDataservice;
  DS.UpdateData(s, @UpdateSuccess, @UpdateFailure);
  Result := True;
end;

{ TP2WDADataRequest }

procedure TP2WDADataRequest.DoFail(response: TROMessage; fail: TjsError);
var
  O: TJSObject;
  s: TStringDynArray;
  Msg: string;
  I: Integer;
begin
  if IsObject(fail) then
  begin
    O := TJSObject(JSValue(fail));
    s := TJSObject.getOwnPropertyNames(O);
    for I := 0 to Length(s) - 1 do
    begin
      Msg := Msg + sLineBreak + s[I];
      Msg := Msg + ' : ' + string(O[s[I]]);
    end;
  end
  else
    Msg := TJSJSON.Stringify(fail);
  Success := rrFail;
  ErrorMsg := Msg;
  DoAfterRequest;
end;

procedure TP2WDADataRequest.doSuccess(res: JSValue);
var
  s: string;
  Rows: TDaDataRowArray;
  aRow: TP2WDADataRow;
  DADS: TP2WDADataset;
  DStr: TP2WDADataStreamer;
  dt: TP2WDADatatable;
  I: Integer;
begin
  // Writeln('Data loaded, dataset active: ',Dataset.Active);
  DADS := Dataset as TP2WDADataset;
  if not Assigned(DADS.DAConnection) then
    raise EDADataset.Create(DADS.Name + ': Cannot process response, connection not available');
  DStr := DADS.DAConnection.CreateStreamer;
  s := DADS.DAConnection.InterpretMessage(res);
  DStr.Stream := s;
  DStr.initializeRead;
  dt := TP2WDADatatable.New;
  dt.Name := DADS.TableName;
  DStr.ReadDataset(dt);
  // Writeln('Row count : ',Length(DT.rows));
  SetLength(Rows, Length(dt.Rows));
  for I := 0 to Length(dt.Rows) - 1 do
  begin
    aRow := TP2WDADataRow.New;
    aRow._new := TJSValueDynArray(dt.Rows[I].__newValues);
    aRow._old := [];
    aRow._calc := TJSObject.New;
    Rows[I] := aRow;
  end;
  (Dataset as TP2WDADataset).Metadata := New(['fields', TJSArray(dt.fields)]);
  // Data:=aJSON['data'];
  (Dataset as TP2WDADataset).Rows := TJSArray(Rows);
  Success := rrOK;
  DoAfterRequest;
end;

end.
