unit Forms.ListCSR;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  units.types,
  pas2web.datatables,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dadatasethelper;

type
  TfrmCSRList = class(TfrmBase)
    dsSettlements: TP2WDADataset;
    dcSettlements: TP2WDatatable;
    dsSettlementsdosid: TLargeintField;
    dsSettlementsdoscreatedon: TDateTimeField;
    dsSettlementsdosclosedon: TDateTimeField;
    dsSettlementsdosdescription: TStringField;
    dsSettlementsdosstatus: TSmallintField;
    procedure dcSettlementsstatusGetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
    procedure dsSettlementsAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmCSRList: TfrmCSRList;

implementation

uses
  Modules.Server,
  units.ActionUtils;

{$R *.dfm}

procedure TfrmCSRList.dcSettlementsstatusGetValue(Sender: TObject; Fields: TP2WDataTableFieldMap; out aOutput: string);
var
  T: TDossierStatus;
begin
  T.AsInteger := strtoInt(string(Fields.GetValueByName('dosstatus')));
  aOutput := T.asString;

  {  case  of
    0 : aOutput := 'Unknown';
   1 : aOutput := 'Active';
   2 : aOutput := 'Completed (all data present)';
   3 : aOutput := 'Closed (no case)';
   4 : aOutput := 'Closed (court case)';
   5 : aOutput := 'Reopened (court case)';
   end;}
end;

procedure TfrmCSRList.dsSettlementsAfterOpen(DataSet: TDataSet);
var
  aResult: TJSArray;
  iCnt, iStatus: Integer;
  aID: NativeInt;
  S: TDossierStatus;

begin
  aResult := dsSettlements.GetNewRows(False);
  dcSettlements.Data := aResult;
  dcSettlements.RenderTranslated;

  iCnt := dsSettlements.RecordCount;
  alForm['actTotal'].Value := IntToStr(iCnt);
  dsSettlements.First;
  while not dsSettlements.EOF do
  begin
    aID := dsSettlementsdosid.asLargeInt;
    iStatus := dsSettlementsdosstatus.AsInteger;
    S.AsInteger := iStatus;
    dmServer.MarkPetitionReadOnly(aID, S.IsArchived or (S = dsUnknown));
    dsSettlements.Next;
  end;
  dsSettlements.First;
end;

procedure TfrmCSRList.WebFormCreate(Sender: TObject);
begin
  inherited;
  Server.CSRConnection.Message.ClientID := Server.ClientID;
  dsSettlements.DAConnection := Server.CSRConnection;
  dsSettlements.Load([], nil);
end;

procedure TfrmCSRList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsSettlements := TP2WDADataset.Create(Self);
  dsSettlementsdosid := TLargeintField.Create(Self);
  dsSettlementsdoscreatedon := TDateTimeField.Create(Self);
  dsSettlementsdosclosedon := TDateTimeField.Create(Self);
  dsSettlementsdosdescription := TStringField.Create(Self);
  dsSettlementsdosstatus := TSmallintField.Create(Self);
  dcSettlements := TP2WDatatable.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsSettlements.BeforeLoadDFMValues;
  dsSettlementsdosid.BeforeLoadDFMValues;
  dsSettlementsdoscreatedon.BeforeLoadDFMValues;
  dsSettlementsdosclosedon.BeforeLoadDFMValues;
  dsSettlementsdosdescription.BeforeLoadDFMValues;
  dsSettlementsdosstatus.BeforeLoadDFMValues;
  dcSettlements.BeforeLoadDFMValues;
  try
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'total';
      Name := 'actTotal';
    end;
    dsSettlements.SetParentComponent(Self);
    dsSettlements.Name := 'dsSettlements';
    dsSettlements.TableName := 'dossier';
    dsSettlements.DAOptions := [];
    dsSettlements.AfterOpen := dsSettlementsAfterOpen;
    dsSettlements.Left := 184;
    dsSettlements.Top := 56;
    dsSettlementsdosid.SetParentComponent(dsSettlements);
    dsSettlementsdosid.Name := 'dsSettlementsdosid';
    dsSettlementsdosid.FieldName := 'dosid';
    dsSettlementsdoscreatedon.SetParentComponent(dsSettlements);
    dsSettlementsdoscreatedon.Name := 'dsSettlementsdoscreatedon';
    dsSettlementsdoscreatedon.FieldName := 'doscreatedon';
    dsSettlementsdoscreatedon.DisplayFormat := 'dd/mm/yyyy';
    dsSettlementsdosclosedon.SetParentComponent(dsSettlements);
    dsSettlementsdosclosedon.Name := 'dsSettlementsdosclosedon';
    dsSettlementsdosclosedon.FieldName := 'dosclosedon';
    dsSettlementsdosclosedon.DisplayFormat := 'dd/mm/yyyy';
    dsSettlementsdosdescription.SetParentComponent(dsSettlements);
    dsSettlementsdosdescription.Name := 'dsSettlementsdosdescription';
    dsSettlementsdosdescription.FieldName := 'dosdescription';
    dsSettlementsdosdescription.Size := 127;
    dsSettlementsdosstatus.SetParentComponent(dsSettlements);
    dsSettlementsdosstatus.Name := 'dsSettlementsdosstatus';
    dsSettlementsdosstatus.FieldName := 'dosstatus';
    dcSettlements.SetParentComponent(Self);
    dcSettlements.Name := 'dcSettlements';
    dcSettlements.Columns.Clear;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'dosdescription';
      Title := 'Description';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'doscreatedon';
      Title := 'Created On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'dosstatus';
      Title := 'Close Status';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcSettlementsstatusGetValue');
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'dosclosedon';
      Title := 'Closed On';
      RenderMode := rmDateTime;
      ButtonType := btEdit;
      Visible := True;
      Searchable := True;
      Sortable := True;
      DateTimeFormat := 'dd/mm/yyyy';
    end;
    with dcSettlements.Columns.Add do
    begin
      FieldName := 'dosid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/CSR/{{dosid}}/identity';
    end;
    dcSettlements.DataSet := dsSettlements;
    dcSettlements.Language := lEnglish;
    dcSettlements.IsResponsive := True;
    dcSettlements.GridID := 'grdCSR';
    dcSettlements.UseFieldIndex := True;
    dcSettlements.ShowSearch := False;
    dcSettlements.ShowNumberOfEntries := False;
    dcSettlements.ShowEntriesInfo := False;
    dcSettlements.Paginate := True;
    dcSettlements.DisplayReadOnly := False;
    dcSettlements.CalculateTableWidth := True;
    dcSettlements.Left := 32;
    dcSettlements.Top := 112;
  finally
    alForm.AfterLoadDFMValues;
    dsSettlements.AfterLoadDFMValues;
    dsSettlementsdosid.AfterLoadDFMValues;
    dsSettlementsdoscreatedon.AfterLoadDFMValues;
    dsSettlementsdosclosedon.AfterLoadDFMValues;
    dsSettlementsdosdescription.AfterLoadDFMValues;
    dsSettlementsdosstatus.AfterLoadDFMValues;
    dcSettlements.AfterLoadDFMValues;
  end;
end;

end.
