unit Units.Contacts;

interface

uses
  System.SysUtils,
  pas2web.dadataset,
  TypInfo,
  Types,
  Units.Params;

const
  UnknownProfileURL = '/assets/images/avatars/unknown-profile.jpg';
  EditPrefix = 'edit_';

type
  EContact = class(Exception);

  // Entity type
  TEntityType = (etPerson, etCompany);

  TSearchContactResultCallBack = Reference to procedure(const SelectedContact: TContactSearchResult);
  TSearchContactResultCallExBack = Reference to procedure(const SelectedContact: TContactSearchResult; var aAllow: Boolean);
  TSearchContactEvent = Reference to procedure(searchResultCallBack: TSearchContactResultCallBack);

  TPersonalField = (pfLastName, pfFirstName, pfDateOfBirth, pfBirthCityZip, pfBirthCityName, pfGender, pfNationality, pfKBO, pfProfession, pfSalutation, pfSearchname, pfPrefixes, pfFriendlytitle,
    pfPicturefk, pfRemark);

  TPersonalFields = set of TPersonalField;
  TPersonalFieldNames = array [TPersonalField] of string;

  TPersonalFieldEdits = record
    Fields: TPersonalFields;
    Names: TPersonalFieldNames;
    FirstNameNotRequired: Boolean;
    Dataset: TP2WDADataset;
    procedure SetFieldsFromNames;
    procedure SetupModalFields(aType: TEntityType = etCompany);
  end;

  TAddressField = (afStreet, afHouseNr, afCityZip, afCityName, afRemark, afCorrespondence, afSecret);
  TAddressFields = set of TAddressField;
  TAddressFieldNames = array [TAddressField] of string;

  TAddressFieldEdits = record
    Fields: TAddressFields;
    Names: TAddressFieldNames;
    Dataset: TP2WDADataset;
    procedure SetFieldsFromNames;
    procedure SetupModalFields;
  end;

  TTelecomField = (tfTelephone, tfMobile, tfEmail);
  TTelecomFields = set of TTelecomField;
  TTelecomFieldNames = array [TTelecomField] of string;

  TTelecomFieldEdits = record
    Fields: TTelecomFields;
    RequiredFields: TTelecomFields;
    Names: TTelecomFieldNames;
    Dataset: TP2WDADataset;
    procedure SetFieldsFromNames;
    procedure SetupModalFields;
  end;

  TDossierContactField = (dcfMarriageContract, dcfCivilStatus, dcfSecondPetitioner, dcfPersonType);
  TDossierContactFields = set of TDossierContactField;
  TDossierContactFieldNames = array [TDossierContactField] of string;

  TDossierContactFieldEdits = record
    Fields: TDossierContactFields;
    Names: TDossierContactFieldNames;
    BlockNames: TDossierContactFieldNames;
    // use existing HTML for these blocks
    UseExistingBlocks: TDossierContactFields;
    PersonTypeLimit: TStringDynArray;
    Dataset: TP2WDADataset;
    procedure SetFieldsFromNames;
  end;

  TContactIDField = (cifNIDNR, cifCardNo, cifCardType, cifChipNo, cifValidFrom, cifValidTo, cifRemark);
  TContactIDFields = set of TContactIDField;
  TContactIDFieldNames = array [TContactIDField] of string;

  TContactIDFieldEdits = record
    Fields: TContactIDFields;
    Names: TContactIDFieldNames;
    Dataset: TP2WDADataset;
    procedure SetFieldsFromNames;
    procedure SetupModalFields;
  end;

  TContactDataPart = (cdpPersonal, cdpWPAddress, cdpVPAddress, cdpID, cdpTelecom, cdpPicture, cdpDossierPerson);
  TContactDataParts = set of TContactDataPart;

  TContactDataPartsHelper = {$IFDEF PAS2JS} type {$ELSE} record {$ENDIF} helper for TContactDataParts
  private
    function GetAsString: string;
  public
    property asString: string read GetAsString;
  end;

  TContactDataPartHelper = {$IFDEF PAS2JS} type {$ELSE} record {$ENDIF} helper for TContactDataPart
  private
    function GetAsString: string;
  public
    property asString: string read GetAsString;
  end;

  TSaveErrorNotifyEvent =
  procedure(Sender: TObject; aPart: TContactDataPart) of object;
  TPartLoadNotifyEvent =
  procedure(Sender: TObject; aPart: TContactDataPart) of object;

  // How was the data loaded
  TContactSource = (csPersonal, csDossierPersonType, csDossierPersonContact);

const

  ContactFieldNames: TPersonalFieldNames = ('cntLastName', 'cntFirstName', 'cntBirthDateOn', 'cntCityOfBirthZip', 'cntCityOfBirthName', 'cntGender', 'cntNationality2', 'cntKBONr', 'cntProfession',
    'cntsalutation', 'cntsearchname', 'cntprefixes', 'cntfriendlytitle', 'cntpicturefk', 'cntRemark');

  AddressFieldNames: TAddressFieldNames = ('ctaStreet', 'ctaStreetNr', 'ctaCityZip', 'ctaCityName', 'ctaRemark', 'ctacorrespondence', 'ctasecret');

  IDFieldNames: TContactIDFieldNames = ('ctinidnr', 'cticardnr', 'cticardtype', 'ctichipnr', 'ctivalidfrom', 'ctivalidto', 'ctiremark');

  DossierContactFieldNames: TDossierContactFieldNames = ('docMarCon', 'docCivilStatus', 'docIsSecondPetitioner', 'docPersonType');

  TeleComStdNames: TTelecomFieldNames = ('Tel', 'GSM', 'Email');

  // person, company
  ModalPersonalEditNames: array [TEntityType] of TPersonalFieldNames = (('edt_PersLastName', 'edt_PersFirstName', 'edt_PersBirthDate', 'edt_PersBirthPlaceZip', 'edt_PersBirthPlace', 'rd_Gender',
    'edt_PersNationality', ''{KBO}, ''{profession}, 'edt_PersSalutation', 'edt_PersSearchName', '' {prefixes} , 'edt_PersFriendlytitle', '' {picture}, ''{contact remark}),
    ('edt_CompLastName', '' {FirstName}, ''{PersBirthDate}, '' {BirthPlaceZip}, '' {BirthPlace}, '' {Gender}, '' {Nationality}, 'edt_CompKBO'{KBO}, ''{profession}, '' {Salutation},
    'edt_CompSearchName', '' {prefixes} , ''{Friendlytitle}, '' {picture}, '' {contact remark}));

  ModalAddressEditNames: TAddressFieldNames = ('edt_AddressStreet', 'edt_HouseNr', 'edt_AddressZip', 'edt_AddressCity', '', 'chk_AddressCorrespondence', 'chk_AddressSecret');

  ModalContactIDEditNames: TContactIDFieldNames = ('edt_PersNationalNumber', 'edt_PersCardNo', 'edt_PersCardType', 'edt_PersAppChipNo', 'edt_PersCardValidFrom', 'edt_PersCardValidTo',
    'edt_Remark' {ctiremark});

  ModalTeleComEditNames: TTelecomFieldNames = ('edt_TelTelecomAddressNr', 'edt_MobileTelecomAddressNr', 'edt_EmailTelecomAddressNr');

implementation

{ TContactDataPartsHelper }

function TContactDataPartsHelper.GetAsString: string;
var
  T: TContactDataPart;
begin
  Result := '';
  for T in TContactDataPart do
    if T in Self then
    begin
      if Result <> '' then
        Result := Result + ', ';
      Result := Result + T.asString;
    end;
end;

{ TContactDataPartHelper }

function TContactDataPartHelper.GetAsString: string;
begin
  Result := GetEnumName(typeInfo(TContactDataPart), Ord(Self));
  Result := Copy(Result, 4, Length(Result) - 3);
end;

{ TPersonalFieldEdits }

procedure TPersonalFieldEdits.SetFieldsFromNames;
var
  F: TPersonalField;
  S: TPersonalFields;
begin
  S := [];
  for F in TPersonalField do
    if (Names[F] <> '') then
      Include(S, F);
  Fields := S;
end;

procedure TPersonalFieldEdits.SetupModalFields(aType: TEntityType = etCompany);
var
  F: TPersonalField;
begin
  for F in TPersonalField do
    Names[F] := ModalPersonalEditNames[aType][F];
  SetFieldsFromNames;
end;

{ TAddressFieldEdits }

procedure TAddressFieldEdits.SetFieldsFromNames;
var
  F: TAddressField;
  S: TAddressFields;
begin
  S := [];
  for F in TAddressField do
    if (Names[F] <> '') then
      Include(S, F);
  Fields := S;
end;

procedure TAddressFieldEdits.SetupModalFields;
var
  F: TAddressField;
begin
  for F in TAddressField do
    Names[F] := ModalAddressEditNames[F];
  SetFieldsFromNames;
end;

{ TTelecomFieldEdits }

procedure TTelecomFieldEdits.SetFieldsFromNames;
var
  F: TTelecomField;
  S: TTelecomFields;
begin
  S := [];
  for F in TTelecomField do
    if (Names[F] <> '') then
      Include(S, F);
  Fields := S;
end;

procedure TTelecomFieldEdits.SetupModalFields;
var
  F: TTelecomField;
begin
  for F in TTelecomField do
    Names[F] := ModalTeleComEditNames[F];
  SetFieldsFromNames;
end;

{ TDossierContactFieldEdits }

procedure TDossierContactFieldEdits.SetFieldsFromNames;
var
  F: TDossierContactField;
  S: TDossierContactFields;
begin
  S := [];
  for F in TDossierContactField do
    if (Names[F] <> '') then
      Include(S, F);
  Fields := S;
end;

{ TContactIDFieldEdits }

procedure TContactIDFieldEdits.SetFieldsFromNames;
var
  F: TContactIDField;
  S: TContactIDFields;
begin
  S := [];
  for F in TContactIDField do
    if (Names[F] <> '') then
      Include(S, F);
  Fields := S;
end;

procedure TContactIDFieldEdits.SetupModalFields;
var
  F: TContactIDField;
begin
  for F in TContactIDField do
    Names[F] := ModalContactIDEditNames[F];
  SetFieldsFromNames;
end;

end.
