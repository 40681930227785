unit Forms.Contact.Telecom;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Base, WEBLib.Actions, libjquery,
  pas2web.dataelementmapper, Data.DB, Datasnap.DBClient, pas2web.dadataset;

type
  TfrmContactTelecom = class(TfrmBase)
    dsTelecom: TP2WDADataset;
    eacTelecom: TPW2DataElementMapper;
    procedure dsTelecomAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure dsTelecomAfterOpen(DataSet: TDataSet);
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure WebFormShow(Sender: TObject);
    procedure OnFieldValidationError(Sender: TObject; Source: TElementAction; dest:
        TField; aError: TElementError);
    procedure OnSaveClick(Sender: TObject; Element: TJSHTMLElementRecord; Event:
        TJSEventParameter);
  private
    { Private declarations }
    FContactID : Int64;

    procedure onGetTelecomTypeItem(RecordID: Integer; Name: string; Language: Integer; ParamType, Value: string; ValueType: integer; Extra: string; isLast: Boolean);
  public
    { Public declarations }
    procedure setParams(const Params: TStrings); override;
  protected procedure LoadDFMValues; override; end;

var
  frmContactTelecom: TfrmContactTelecom;

implementation

{$R *.dfm}

uses
  Units.ActionUtils, Modules.Server;

procedure TfrmContactTelecom.dsTelecomAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);
var
  i : Integer;
  aMsg : string;
begin
  aMsg := '';
  for i := Low(Info.Records) to High(Info.Records) do
  begin
    aMsg := aMsg + #13#10 + Info.Records[i].error;
  end;

  if Trim(aMsg) <> '' then
    ShowError(aMsg)
  else
  begin
    window.location.href := '#/contact/edit/' + Inttostr(FContactID) + '/';
  end;
end;

procedure TfrmContactTelecom.dsTelecomAfterOpen(DataSet: TDataSet);
begin
  if not Dataset.isEmpty then
  begin
    alForm['chkCorrespondence'].checked := DataSet.FieldByName('cttcorrespondence').asBoolean;
    eacTelecom.DatasetToElements;
  end;
end;

procedure TfrmContactTelecom.WebFormCreate(Sender: TObject);
begin
  inherited;
  FContactID := -1;

  Server.GetParameterList('CONTYP', Language, @onGetTelecomTypeItem);

  Server.ContactConnection.Message.ClientID := Server.ClientID;
  dsTelecom.DAConnection := Server.ContactConnection;
end;

procedure TfrmContactTelecom.OnFieldValidationError(Sender: TObject;
  Source: TElementAction; dest: TField; aError: TElementError);
begin
  DisplayError(Source.Name);
end;

procedure TfrmContactTelecom.onGetTelecomTypeItem(RecordID: Integer;
  Name: string; Language: Integer; ParamType, Value: string; ValueType: integer;
  Extra: string; isLast: Boolean);
begin
  alForm['edtTelecomType'].InnerHTML := alForm['edtTelecomType'].InnerHTML + '<option data-id="' + inttostr(RecordID) + '" value="' + Name + '">' + Value + '</option>';
end;

procedure TfrmContactTelecom.OnSaveClick(Sender: TObject; Element:
    TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if eacTelecom.ValidateActions(True) then
  begin
    if dsTelecom.isEmpty then
    begin
      dsTelecom.Insert;
      dsTelecom.FieldByName('cttcreatedon').asDateTime := now;
      dsTelecom.FieldByName('cttcreatedbyfk').asLargeInt := Server.UserID;
      dsTelecom.FieldByName('cttcontactfk').asLargeInt := FContactID;
    end
    else
      dsTelecom.Edit;
    dsTelecom.FieldByName('cttchangedon').asDateTime := now;
    dsTelecom.FieldByName('cttchangedbyfk').asLargeInt := Server.UserID;
    dsTelecom.FieldByName('cttcontacttypefk').asLargeInt := Server.GetParamId('CONTYP', alForm['edtTelecomType'].value);
    dsTelecom.FieldByName('cttcorrespondence').asBoolean := alForm['chkCorrespondence'].Checked;
    eacTelecom.ElementsToDataset;
    dsTelecom.Post;
    dsTelecom.ApplyUpdates;
  end;
end;

procedure TfrmContactTelecom.setParams(const Params: TStrings);
begin
  if Assigned(Params) then
  begin
    jquery('#returnBack').attr('href', '#/contact/edit/' + Params.values['CONTACTID'] + '/');
    FContactID := StrToIntDef(Params.values['CONTACTID'], -1);
    dsTelecom.ParamByName('CNTID').asLargeInt := FContactID;
    dsTelecom.ParamByName('CTTID').asLargeInt := StrToIntDef(Params.values['TELECOMID'], -1);
  end
  else
  begin
    dsTelecom.ParamByName('CNTID').asLargeInt := -1;
    dsTelecom.ParamByName('CTTID').asLargeInt := -1;
  end;
end;

procedure TfrmContactTelecom.WebFormShow(Sender: TObject);
begin
  Inherited;
  dsTelecom.Load([], nil);
end;

procedure TfrmContactTelecom.LoadDFMValues;
begin
  inherited LoadDFMValues;

  dsTelecom := TP2WDADataset.Create(Self);
  eacTelecom := TPW2DataElementMapper.Create(Self);

  alForm.BeforeLoadDFMValues;
  dsTelecom.BeforeLoadDFMValues;
  eacTelecom.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'edtTelecomType';
      Name := 'edtTelecomType';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtTelecomData';
      Name := 'edtTelecomData';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'edtRemark';
      Name := 'edtRemark';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'chkCorrespondence';
      Name := 'chkCorrespondence';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'OnSaveClick');
    end;
    dsTelecom.SetParentComponent(Self);
    dsTelecom.Name := 'dsTelecom';
    dsTelecom.TableName := 'contacttelecom';
    dsTelecom.DAConnection := dmServer.ContactConnection;
    dsTelecom.Params.Clear;
    with dsTelecom.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CNTID';
      ParamType := ptInput;
    end;
    with dsTelecom.Params.Add do
    begin
      DataType := ftLargeint;
      Name := 'CTTID';
      ParamType := ptInput;
      Value := 0;
    end;
    dsTelecom.WhereClause := '<?xml version="1.0"?><query xmlns="http://www.remobjects.com/schemas/dataabstract/queries/5.0" version="5.0"><where><binaryoperation operator="And"><binaryoperation operator="Equal"><field>cttcontactfk</field><parameter type="LargeInt">CNTID</parameter></binaryoperation><binaryoperation operator="Equal"><field>cttid</field><parameter type="LargeInt">CTTID</parameter></binaryoperation></binaryoperation></where></query>';
    dsTelecom.DAOptions := [];
    dsTelecom.AfterOpen := dsTelecomAfterOpen;
    dsTelecom.AfterApplyUpdates := dsTelecomAfterApplyUpdates;
    dsTelecom.Left := 40;
    dsTelecom.Top := 80;
    eacTelecom.SetParentComponent(Self);
    eacTelecom.Name := 'eacTelecom';
    eacTelecom.ActionLinks.Clear;
    with eacTelecom.ActionLinks.Add do
    begin
      ActionName := 'edtTelecomType';
      FieldName := 'cttcontacttype';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacTelecom.ActionLinks.Add do
    begin
      ActionName := 'edtTelecomData';
      FieldName := 'cttdata';
      Options := [aoRequired];
      SetEvent(Self, 'OnValidationError', 'OnFieldValidationError');
    end;
    with eacTelecom.ActionLinks.Add do
    begin
      ActionName := 'edtRemark';
      FieldName := 'cttremark';
      Options := [];
    end;
    eacTelecom.ActionList := alForm;
    eacTelecom.Dataset := dsTelecom;
    eacTelecom.Left := 40;
    eacTelecom.Top := 144;
  finally
    alForm.AfterLoadDFMValues;
    dsTelecom.AfterLoadDFMValues;
    eacTelecom.AfterLoadDFMValues;
  end;
end;

end.
