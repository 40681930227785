unit Forms.CSR.Realestate;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Realestate, pas2web.datatables, Data.DB, Datasnap.DBClient, pas2web.dadataset, WEBLib.Actions;

type
  TfrmCSRRealestate = class(TfrmRealestate)
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    function DossierPrefix: string; override;
    class function GetHTMLFileName: string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRRealestate: TfrmCSRRealestate;

implementation

{$R *.dfm}

{ TfrmCSRRealestate }

function TfrmCSRRealestate.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRRealestate.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRRealestate.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRRealestate.WebFormCreate(Sender: TObject);
begin
  Inherited;
  dcRealEstate.Columns[5].ButtonURL:='#/CSR/{{prodossierfk}}/realestate/edit/{{proid}}';
  dcRealEstate.Columns[6].ButtonURL:='#/CSR/{{prodossierfk}}/realestate/delete/{{proid}}';
end;

procedure TfrmCSRRealestate.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcRealestate.BeforeLoadDFMValues;
  try
    dcRealestate.Columns.Clear;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'propurchasedon';
      Title := 'Purchased On';
      RenderMode := rmCustomValue;
      ButtonType := btEdit;
      SetEvent(Self, 'OnGetValue', 'dcRealestateColumns0GetValue');
      Visible := True;
      Searchable := True;
      Sortable := True;
      DateTimeFormat := 'DD.MM.YYYY';
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'proshortdescription';
      Title := 'Description';
      RenderMode := rmCustom;
      ButtonType := btEdit;
      SetEvent(Self, 'OnRender', 'RenderDescription');
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'procount';
      Title := 'Count';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := True;
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'proowner';
      Title := 'Owner';
      RenderMode := rmCustom;
      ButtonType := btEdit;
      SetEvent(Self, 'OnRender', 'RenderOwner');
      Visible := True;
      Searchable := True;
      Sortable := True;
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'proiscomplete';
      Title := 'Is Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'disabled';
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'prodossierfk';
      RenderMode := rmButton;
      ButtonType := btEdit;
      ButtonIconClass := 'far fa-edit';
      CSSClassName := 'align-middle text-right';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{prodossierfk}}/realestate/edit/{{proid}}';
    end;
    with dcRealestate.Columns.Add do
    begin
      FieldName := 'proid';
      RenderMode := rmButton;
      ButtonType := btDelete;
      ButtonIconClass := 'fas fa-trash-alt';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{prodossierfk}}/realestate/delete/{{proid}}';
    end;
  finally
    dcRealestate.AfterLoadDFMValues;
  end;
end;

end.