unit Forms.CSR.Salarytransfer;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Forms.Salarytransfer, Data.DB, Datasnap.DBClient, pas2web.dadataset, pas2web.datatables,
  WEBLib.Actions;

type
  TfrmCSRSalarytransfer = class(TfrmSalarytransfer)
    procedure WebFormCreate(Sender: TObject); reintroduce;
  private
    { Private declarations }
  public
    class function GetHTMLFileName: string; override;
    function DossierPrefix : string; override;
    function DossierConnection : TP2WDAConnection; override;
  protected procedure LoadDFMValues; override; end;

var
  frmCSRSalarytransfer: TfrmCSRSalarytransfer;

implementation

{$R *.dfm}

{ TForm2 }

function TfrmCSRSalarytransfer.DossierConnection: TP2WDAConnection;
begin
  Result:=CSRConnection;
end;

function TfrmCSRSalarytransfer.DossierPrefix: string;
begin
  Result:='CSR';
end;

class function TfrmCSRSalarytransfer.GetHTMLFileName: string;
begin
  Result:=FixCSRHTMLFile(Inherited GetHTMLFileName);
end;

procedure TfrmCSRSalarytransfer.WebFormCreate(Sender: TObject);
begin
  inherited;
  dcSalary.Columns[4].ButtonURL:='#/CSR/{{debdossierfk}}/salarytransfer/edit/{{debid}}';
  dcSalary.Columns[5].ButtonURL:='#/CSR/{{debdossierfk}}/salarytransfer/delete/{{debid}}';
end;

procedure TfrmCSRSalarytransfer.LoadDFMValues;
begin
  inherited LoadDFMValues;


  dcSalary.BeforeLoadDFMValues;
  try
    dcSalary.Columns.Clear;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debcreditor';
      Title := 'Creditor';
      RenderMode := rmText;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'deboriginalamount';
      Title := 'Original Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,###.00';
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debmonthlyamount';
      Title := 'Monthly Amount';
      RenderMode := rmNumeric;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      NumericFormat := '###,###.00';
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debiscompleted';
      Title := 'Is Complete';
      RenderMode := rmCheckBox;
      ButtonType := btEdit;
      Visible := True;
      Searchable := False;
      Sortable := False;
      ExtraAttributes := 'disabled';
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debid';
      RenderMode := rmButton;
      ButtonType := btEdit;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{debdossierfk}}/salarytransfer/edit/{{debid}}';
    end;
    with dcSalary.Columns.Add do
    begin
      FieldName := 'debdossierfk';
      RenderMode := rmButton;
      ButtonType := btDelete;
      Width := '40px';
      Visible := True;
      Searchable := False;
      Sortable := False;
      ButtonURL := '#/dossier/{{debdossierfk}}/salarytransfer/delete/{{debid}}';
    end;
  finally
    dcSalary.AfterLoadDFMValues;
  end;
end;

end.