unit Units.ServerConfig;

{$IFDEF PAS2JS}
{$mode objfpc}
{$modeswitch externalclass}
{$ENDIF}
{ ---------------------------------------------------------------------
  Here we define an external class with server settings.
  There is no real class, this is just a typed definition of a Javascript object.

  The sole instance of this class is included in index.html, see:

  <script src="js/serverconfig.js"></script>
  --------------------------------------------------------------------- }

interface

uses JS;

Type
  TUILanguage = record
    name: string;
    code: string;
  end;

  TUILanguageArray = Array of TUILanguage;

  TServerConfig = class {$IFDEF PAS2JS} external name 'Object'{$ENDIF} (TJSObject)
    ServerURL: String;
    ShowLog: Boolean;
    ShowDebugLog: Boolean;
    ShowErrorLog: Boolean;
    UILanguages: TUILanguageArray;
    WarnLogoutTimeout : NativeInt;
    LogoutTimeout : NativeInt;
    UseJSONMessage : Boolean;
    SkipCheckMessageTimer : Boolean;
  end;

  TServerConfigHelper = Class Helper for TServerConfig
    Function DoShowLog: Boolean;
    Function DoDebugLog: Boolean;
    Function DoErrorLog: Boolean;
    Function DoWarnLogoutTimeout : NativeInt;
    Function DoLogoutTimeout : NativeInt;
    Function DoUseJSONMessage : Boolean;
    Function DoSkipCheckMessageTimer : Boolean;
  End;

Var
  // Refer to the included instance in the index.html page.
  ServerConfig: TServerConfig; {$IFDEF PAS2JS }external name 'DezqConfig'; {$ENDIF}

implementation

Const
  DefaultWarnLogoutTimeout = 10*60;
  DefaultLogoutTimeout = 12*60;
  DefaultUseJSONMessage = False;
  DefaultSkipCheckMessageTimer = False;

{ TServerConfigHelper }

function TServerConfigHelper.DoDebugLog: Boolean;
begin
  Result:=Assigned(Self) and HasOwnProperty('ShowDebugLog') and ShowDebugLog;
end;

function TServerConfigHelper.DoErrorLog: Boolean;
begin
  Result:=Assigned(Self) and HasOwnProperty('ShowErrorLog') and ShowErrorLog;
end;

function TServerConfigHelper.DoShowLog: Boolean;
begin
  Result:=Assigned(Self) and HasOwnProperty('ShowLog') and ShowLog;
end;

function TServerConfigHelper.DoSkipCheckMessageTimer: Boolean;
begin
  if Assigned(Self) and HasOwnProperty('SkipCheckMessageTimer') then
    Result:=SkipCheckMessageTimer
  else
    Result:=DefaultSkipCheckMessageTimer;
end;

function TServerConfigHelper.DoUseJSONMessage: Boolean;
begin
  if Assigned(Self) and HasOwnProperty('UseJSONMessage') then
    Result:=UseJSONMessage
  else
    Result:=DefaultUseJSONMessage;
end;

Function TServerConfigHelper.DoWarnLogoutTimeout : NativeInt;

begin
  if Assigned(Self) and HasOwnProperty('WarnLogoutTimeout') then
    Result:=WarnLogoutTimeout
  else
    Result:=DefaultWarnLogoutTimeout;

end;

Function TServerConfigHelper.DoLogoutTimeout : NativeInt;

begin
  if Assigned(Self) and HasOwnProperty('LogoutTimeout') then
    Result:=LogoutTimeout
  else
    Result:=DefaultLogoutTimeout;
end;


end.
