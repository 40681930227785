unit Forms.Family.Child;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Forms.Base,
  WEBLib.Actions,
  libjquery,
  Data.DB,
  Datasnap.DBClient,
  pas2web.dadataset,
  pas2web.dataelementmapper,
  Forms.DossierDetail,
  Units.Contacts,
  Module.Contacts;

type
  TfrmBase = TfrmDossierDetail;

  TfrmFamilyChild = class(TfrmBase)
    procedure WebFormCreate(Sender: TObject); reintroduce;
    procedure btnSaveClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
  private
    { Private declarations }
    FRelationID: Int64;
    FdmContact: TdmContacts;
    procedure DoAllSaved(Sender: TObject);
    procedure DoAllLoaded(Sender: TObject);
    procedure SetupChildContact;
    procedure DoCheckEditButton;
  public
    procedure OnContactChanged(Sender: TObject); override;
    procedure MakeReadOnly; override;
    procedure setParams(const Params: TStrings); override;
    property dmContact: TdmContacts read FdmContact;
  protected procedure LoadDFMValues; override; end;

var
  frmFamilyChild: TfrmFamilyChild;

implementation

uses
  Units.Strings,
  pas2web.dadatasethelper,
  Units.ActionUtils,
  Modules.Server,
  Units.Params,
  Units.DADatasetUtils,
  Units.Logging,
  Units.HTMLUtils;

{$R *.dfm}

{ TfrmFamilyChild }

procedure TfrmFamilyChild.WebFormCreate(Sender: TObject);
begin
  inherited;
  FdmContact := CreateContactModule;
  BindOnChangeEvent;
  FdmContact.AllowedContactType := actPerson;
end;

procedure TfrmFamilyChild.WebFormDestroy(Sender: TObject);
begin
  FreeAndNil(FdmContact);
  inherited;
end;

procedure TfrmFamilyChild.btnSaveClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  dmContact.SaveIfValid;
end;

procedure TfrmFamilyChild.setParams(const Params: TStrings);
begin
  inherited;
  jQuery('#backtoFamily').attr('href', DossierURL('/relation'));
  FRelationID := StrToIntDef(Params.Values['RELATIONID'], -1);
end;

procedure TfrmFamilyChild.SetupChildContact;
const
  IMPersonalFields: TPersonalFieldNames = ('edtLastName', 'edtFirstname', 'ddtBirth', '', '', 'rdGender', '', '', '',
    '', '', '', '', '', 'mmRemark');
  IMDossierFields: TDossierContactFieldNames = ('', '', '', 'rdKind');
  IMDossierBlocks: TDossierContactFieldNames = ('', '', '', 'relationShipBlock');
var
  PersonalFields: TPersonalFieldEdits;
  DossierFields: TDossierContactFieldEdits;
begin
  PersonalFields.Names := IMPersonalFields;
  PersonalFields.SetFieldsFromNames;
  DossierFields.Names := IMDossierFields;
  DossierFields.SetFieldsFromNames;
  DossierFields.BlockNames := IMDossierBlocks;
  DossierFields.UseExistingBlocks := [dcfPersonType];

  dmContact.PersonalFieldInfo := PersonalFields;
  dmContact.DossierContactFieldInfo := DossierFields;
  dmContact.FormIsReadOnly := Self.FormIsReadOnly;
  dmContact.SearchButtonID := 'btnSelectMinorChildContact';
  dmContact.EditContactButtonID := 'btnEditMinorChildContact';
  dmContact.ContactDescription := SContactChild;
  dmContact.OnAllSaved := @DoAllSaved;
  dmContact.OnContactChanged := OnContactChanged;
  dmContact.OnAllLoaded := DoAllLoaded;
end;

procedure TfrmFamilyChild.DoAllLoaded(Sender: TObject);
begin
  DoCheckEditButton;
end;

procedure TfrmFamilyChild.DoAllSaved(Sender: TObject);
begin
//  dmServer.ShowOK(SChildSavedOK);
  DiscardChanges := True;
  window.location.href := DossierURL('/relation');
end;

procedure TfrmFamilyChild.DoCheckEditButton;
begin
  Log.Log(ltDebug, ClassName, 'TfrmFamilyChild', 'Hier ist');

  with alForm['btnEditMinorChildContact'] do
  begin
    if dmContact.ContactID <= 0 then
      addClass('d-none')
    else
      removeClass('d-none')
  end;
end;

procedure TfrmFamilyChild.MakeReadOnly;
begin
  inherited;
  dmContact.FormIsReadOnly := Self.FormIsReadOnly;
end;

procedure TfrmFamilyChild.OnContactChanged(Sender: TObject);
begin
  inherited;
  DoCheckEditButton;
end;

procedure TfrmFamilyChild.WebFormShow(Sender: TObject);
begin
  inherited;
  SetupChildContact;
  dmContact.LoadDossierPersonData(DossierID, FRelationID, [cdpDossierPerson, cdpPersonal], [cdpDossierPerson])
end;

procedure TfrmFamilyChild.LoadDFMValues;
begin
  inherited LoadDFMValues;


  alForm.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      SetEvent(Self, 'OnExecute', 'btnSaveClick');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'btnEditMinorChildContact';
      Name := 'btnEditMinorChildContact';
      PreventDefault := False;
      StopPropagation := False;
    end;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
